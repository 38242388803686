import { ReadTransaction } from 'replicache'

import { Prefix, SyncedRecord } from '@laserfocus/shared/models'

export type RootSyncKeys = 'account' | 'lead' | 'opportunity' | 'contact' | 'task' | 'event'
export type RootSyncState = Partial<Record<RootSyncKeys, SyncedRecord>>

export async function queryRootSyncState(tx: ReadTransaction, rootId: string) {
    const syncStatesScan = tx.scan({ prefix: [Prefix.SyncStatus, rootId].join('/') })
    const syncStatesWithKey = await syncStatesScan.entries().toArray()
    const syncStates = Object.fromEntries(
        syncStatesWithKey.map(([key, syncState]) => {
            const relation = key.split('/')[2]
            return [relation, syncState]
        })
    ) as RootSyncState

    return syncStates
}

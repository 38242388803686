import { useNavigate } from 'react-router'
import { useObserver } from 'mobx-react-lite'
import { useEffect } from 'react'

import { RoutePaths } from '@laserfocus/client/util-routing'
import { Spinner } from '@laserfocus/ui/beam'
import { useRootStore } from '@laserfocus/client/root-store-context'

export function IndexPage() {
    const navigate = useNavigate()
    const rootStore = useRootStore<{ isAuthenticated: boolean }>()
    const isAuthenticated = useObserver(() => rootStore.isAuthenticated)
    useEffect(() => {
        if (window.location.pathname.includes('login')) {
            return
        }
        if (isAuthenticated) {
            navigate(RoutePaths.Home, { replace: true })
        } else {
            navigate(RoutePaths.Landing, { replace: true })
        }
    }, [isAuthenticated, navigate])

    return <Spinner fullscreen />
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function MailOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M2.88 4.88A3 3 0 015 4h14a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V8.01 7a3 3 0 01.88-2.12zM4 9.87V17a1 1 0 001 1h14a1 1 0 001-1V9.87l-6.33 4.22a3 3 0 01-3.33 0L4 9.87zm16-2.4l-7.44 4.96a1 1 0 01-1.11 0L4 7.46V7a1 1 0 011-1h14a1 1 0 011 1v.46z"
            />
        </SvgIcon>
    )
}

import { matchSorter } from 'match-sorter'
import { useMemo } from 'react'
import { useSubscribe } from 'replicache-react'
import { keyBy } from 'lodash'

import { Button, Table } from '@laserfocus/ui/beam'
import { ExclamationOutlinedIcon, CheckFilledIcon } from '@laserfocus/ui/icons'
import { Prefix, Role } from '@laserfocus/shared/models'
import { HighlightedCellValue, SelectCell } from '@laserfocus/client/ui-shared-datagrid'
import { updateUser } from '@laserfocus/client/data-access-shared'
import { getClient } from '@laserfocus/client/replicache'
import { InviteUserRow, useUsersToInvite } from '@laserfocus/client/ui-shared'
import { useUserId } from '@laserfocus/client/feature-auth'

export function AssignRoleForm({ searchTerm }: { searchTerm?: string }) {
    const roles = useRoles()
    const rows = useUserRoleRows(roles)

    const roleOptions = useMemo(
        () => [
            { value: '', label: '—' },
            ...roles.map((r) => ({ value: r.name, label: r.label || r.name })),
        ],
        [roles]
    )

    const filtered = searchTerm
        ? matchSorter(rows, searchTerm, {
              keys: ['user.Name', 'user.Username', 'lfUser.role', 'role.label', 'status'],
          })
        : rows
    return (
        <div className="m-6">
            <Table.Frame className="max-w-4xl">
                <Table className="min-w-full divide-y divide-grey-700/10">
                    <Table.Head>
                        <tr>
                            <Table.HeadCell className="pl-4 w-1/3">Name</Table.HeadCell>
                            <Table.HeadCell className="pl-4 w-1/3">Role</Table.HeadCell>
                            <Table.HeadCell className="w-1/3">Signed Up</Table.HeadCell>
                        </tr>
                    </Table.Head>
                    <Table.Body>
                        {filtered.map((row) => (
                            <UserRow
                                key={row.user.Id}
                                row={row}
                                options={roleOptions}
                                searchTerm={searchTerm}
                            />
                        ))}
                    </Table.Body>
                </Table>
            </Table.Frame>
        </div>
    )
}

function useRoles() {
    const rep = getClient()
    const roles = useSubscribe(
        rep,
        async (tx) => {
            return tx.scan({ prefix: Prefix.Role }).values().toArray() as Promise<Role[]>
        },
        [],
        []
    )
    return roles
}

function useUserRoleRows(roles: Role[]) {
    const userId = useUserId()
    const rolesByName = keyBy(roles, 'name')

    const userInviteRows = useUsersToInvite(userId!, true)
    const withRole = useMemo(() => {
        return userInviteRows.map((row) => ({
            ...row,
            role: row.lfUser?.role ? rolesByName[row.lfUser?.role] : undefined,
        }))
    }, [rolesByName, userInviteRows])
    return withRole
}

interface RowData extends InviteUserRow {
    role?: Role
}

function UserRow({
    row,
    options,
    searchTerm,
}: {
    searchTerm?: string
    row: RowData
    options: Array<{ label: string; value: string }>
}) {
    const myId = useUserId()

    const { user, lfUser, role, status, invite } = row
    const disableMeChangingFromAdmin =
        myId === user.Id && (role?.name === 'admin' || role?.name === 'sales-admin')
    return (
        <Table.Row>
            <Table.Cell className="pl-4">
                <HighlightedCellValue value={user.Name ?? ''} searchTerm={searchTerm} />
            </Table.Cell>
            <Table.Cell className="flex">
                <SelectCell
                    searchTerm={searchTerm}
                    options={options}
                    readOnly={disableMeChangingFromAdmin}
                    name="userrole"
                    value={lfUser?.role ?? null}
                    updateValue={(value) => updateUser(user.Id, { role: value ?? null })}
                    size="large"
                />
                {lfUser && !lfUser.role ? (
                    <div className="pl-4" title="Please assign a role to this user">
                        <ExclamationOutlinedIcon className="text-yellow-300 w-6 h-6" />
                    </div>
                ) : (
                    lfUser &&
                    lfUser.role &&
                    !role && (
                        <div className="pl-4" title="This role dos not exist anymore">
                            <ExclamationOutlinedIcon className="text-yellow-300 w-6 h-6" />
                        </div>
                    )
                )}
            </Table.Cell>
            <Table.Cell>
                {status === 'signed up' && (
                    <span className="flex items-center">
                        <HighlightedCellValue value="Signed up" searchTerm={searchTerm} />
                        <CheckFilledIcon className="text-green-500 w-6 h-6 ml-1" />
                    </span>
                )}
                {status === 'pending invite from colleague' ||
                status === 'pending invite from me' ? (
                    <HighlightedCellValue value="Pending invite" searchTerm={searchTerm} />
                ) : null}
                {status === 'not invited' && (
                    <Button onClick={invite} size="small">
                        Invite
                    </Button>
                )}
            </Table.Cell>
        </Table.Row>
    )
}

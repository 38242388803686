import { useMemo, useState } from 'react'
import clsx from 'clsx'

import { Button, CompactDatePicker, ConfirmPrompt, Select, toast } from '@laserfocus/ui/beam'
import {
    EditOutlinedIcon,
    RescheduleOutlinedIcon,
    PinOutlinedIcon,
    Delete2OutlinedIcon,
    MoreOutlinedIcon,
} from '@laserfocus/ui/icons'
import {
    rescheduleEvent,
    rescheduleTask,
    pinActivity,
    unpinActivity,
    deleteActivity,
} from '@laserfocus/client/data-access-shared'
import { ActivityUtil, TaskUtil } from '@laserfocus/client/model'
import { getActivityRescheduleMessage } from '@laserfocus/client/util-formatter'
import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'
import {
    dateToDateString,
    isTruthy,
    OptimisticTaskId,
    TaskId,
    TaskIdSchema,
} from '@laserfocus/shared/models'
import { logger } from '@laserfocus/ui/logger'

import type { ActivityData } from '../modules/usePersonActivitiesRCQuery'
import { useRootObject } from '../modules/PersonContext'

type ActivityActionsProps = {
    activity: ActivityData
    startEditing(): void
}

export function ActivityActions(props: ActivityActionsProps) {
    const { activity } = props
    const canEdit = !ActivityUtil.isReadOnly(activity)
    const canReschedule = ActivityUtil.isOpen(activity) && canEdit
    const canPin = !ActivityUtil.isOpen(activity)

    return canEdit || canReschedule || canPin ? (
        <ActivityActionsInner
            {...props}
            canEdit={canEdit}
            canReschedule={canReschedule}
            canPin={canPin}
        />
    ) : null
}

interface ActivityActionsInnerProps extends ActivityActionsProps {
    canEdit: boolean
    canReschedule: boolean
    canPin: boolean
}

function ActivityActionsInner({
    activity,
    startEditing,
    canEdit,
    canPin,
    canReschedule,
}: ActivityActionsInnerProps) {
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false)
    const [isMoreSelectOpen, setIsMoreSelectOpen] = useState(false)

    const rootId = activity.RootId || activity.AccountId || activity.WhoId!
    const shouldBeVisible = isDatepickerOpen || isMoreSelectOpen

    return (
        <div
            className={clsx(
                'group-hover:not-sr-only group-focus-within:not-sr-only',
                !shouldBeVisible && 'sr-only'
            )}
        >
            <div className="grid gap-2 grid-flow-col absolute top-1 right-1">
                {canReschedule && (
                    <RescheduleAction
                        activity={activity as ActivityData}
                        isDatepickerOpen={isDatepickerOpen}
                        setIsDatepickerOpen={setIsDatepickerOpen}
                    />
                )}
                {canPin && rootId ? (
                    activity.isPinned ? (
                        <Button
                            size="small"
                            iconComponent={PinOutlinedIcon}
                            iconClassName="text-blue-500"
                            onClick={() => {
                                unpinActivity(rootId, activity.Id)
                            }}
                        >
                            Pinned
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            iconComponent={PinOutlinedIcon}
                            onClick={() => {
                                pinActivity(rootId, activity.Id)
                            }}
                        >
                            Pin
                        </Button>
                    )
                ) : null}
                <MoreSelect
                    isOpen={isMoreSelectOpen}
                    setIsOpen={setIsMoreSelectOpen}
                    canEdit={canEdit}
                    startEditing={startEditing}
                    deleteActivity={() => deleteActivity(activity.Id)}
                />
            </div>
        </div>
    )
}

interface RescheduleActionProps {
    activity: ActivityData
    isDatepickerOpen: boolean
    setIsDatepickerOpen(updater: boolean | ((prev: boolean) => boolean)): void
}

function RescheduleAction({
    activity,
    isDatepickerOpen,
    setIsDatepickerOpen,
}: RescheduleActionProps) {
    const root = useRootObject()
    const { location } = useAnalyticsContext()
    function handleDateSubmit(date: Date) {
        setIsDatepickerOpen(false)

        const reschedule = ActivityUtil.isEvent(activity)
            ? rescheduleEvent({
                  eventId: activity.Id,
                  dateTime: date.toISOString(),
              })
            : rescheduleTask({
                  taskId: activity.Id as TaskId | OptimisticTaskId,
                  date: dateToDateString(date),
                  dateTime: hasTime(date) ? date.toISOString() : undefined,
              })
        reschedule.then(
            () => {
                const name = root?.Name

                if (date && name) {
                    toast.success(
                        getActivityRescheduleMessage(date, name, ActivityUtil.isEvent(activity))
                    )
                }
                Analytics.trackEvent({
                    event: 'activity_rescheduled',
                    location: location || 'person_details',
                })
            },
            (e: Error) => {
                logger.error(e)
                toast.error({ title: 'Something went wrong' })
            }
        )
    }

    const suggestOptions = useMemo(() => ({ allow_past_dates: false, min_date: new Date() }), [])

    const initialDate = ActivityUtil.isEvent(activity)
        ? activity.StartDateTime
            ? new Date(activity.StartDateTime)
            : undefined
        : TaskUtil.getOpenDate(activity)

    return (
        <CompactDatePicker
            isOpen={isDatepickerOpen}
            initialDate={initialDate}
            onDateSubmit={handleDateSubmit}
            onCancel={() => setIsDatepickerOpen(false)}
            suggestOptions={suggestOptions}
        >
            <Button
                size="small"
                iconComponent={RescheduleOutlinedIcon}
                onClick={() => setIsDatepickerOpen(true)}
            >
                Reschedule
            </Button>
        </CompactDatePicker>
    )
}

function hasTime(d: Date) {
    const noTime =
        d.getHours() === 0 &&
        d.getMinutes() === 0 &&
        d.getSeconds() === 0 &&
        d.getMilliseconds() === 0
    return !noTime
}

interface MoreSelectProps {
    isOpen: boolean
    setIsOpen(isOpen: boolean): void
    canEdit: boolean
    startEditing(): void
    deleteActivity(): void
}

function MoreSelect({ canEdit, isOpen, setIsOpen, startEditing, deleteActivity }: MoreSelectProps) {
    const [isDeleteConfirmPromptOpen, setIsDeleteConfirmPromptOpen] = useState(false)

    return (
        <>
            <Select
                isOpen={isOpen}
                onCancel={() => setIsOpen(false)}
                options={[
                    canEdit && {
                        iconComponent: EditOutlinedIcon,
                        label: 'Edit',
                        value: 'edit',
                        action: startEditing,
                    },
                    {
                        iconComponent: Delete2OutlinedIcon,
                        label: 'Delete',
                        value: 'delete',
                        action: () => setIsDeleteConfirmPromptOpen(true),
                        className: 'text-red-500',
                    },
                ].filter(isTruthy)}
                onSubmit={({ action }) => {
                    setIsOpen(false)
                    action()
                }}
            >
                <Button
                    title="More"
                    size="small"
                    onClick={() => setIsOpen(true)}
                    iconComponent={MoreOutlinedIcon}
                />
            </Select>
            <ConfirmPrompt
                show={isDeleteConfirmPromptOpen}
                title="Delete Activity"
                description="Are you sure you want to delete the activity?"
                submitButtonTitle="Delete"
                onCancel={() => setIsDeleteConfirmPromptOpen(false)}
                onSubmit={() => {
                    setIsDeleteConfirmPromptOpen(false)
                    deleteActivity()
                }}
            />
        </>
    )
}

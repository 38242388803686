import clsx from 'clsx'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { CheckOutlinedIcon } from '@laserfocus/ui/icons'
import { Button, StaticMultiSelect, StaticSelect } from '@laserfocus/ui/beam'
import { logger } from '@laserfocus/ui/logger'
import { Analytics } from '@laserfocus/client/util-analytics'

import {
    CONNECTION_TYPE_OPTIONS,
    RELEVANT_OBJECT_OPTIONS,
    ROLE_OPTIONS,
    useOnboarding,
} from '../OnboardingContext'
import { captureTrait, captureAnonymousTrait } from '../store/onboarding-analytics'

import { EnterpriseSecureLink } from './EnterpriseSecureLink'

type StepKey = 'create' | 'role' | 'connect' | 'select-object'

interface Step {
    key: StepKey
    title: string
    description: string | null
    content: React.ReactNode
    nextStepLabel: string | null
    nextStepUrl: string | null
    onSubmit?: () => void
}

interface OnboardingStepsProps {
    stepKey: StepKey
}

export function OnboardingSteps({ stepKey }: OnboardingStepsProps) {
    const { relevantObjects, relevantSteps, stepIndex, nextStepLabel, nextStepUrl, onSubmit } =
        useOnboardingSteps(stepKey)
    return (
        <div className="grid gap-4 grid-cols-[auto,22rem]">
            <h1 className="col-start-2 font-bold font-serif text-2xl">Furnish your sales home</h1>
            <div className="col-span-2" />
            {relevantSteps.map(({ title, description, content }, index) => {
                const isPastStep = index < stepIndex
                const isCurrentStep = index === stepIndex
                return (
                    <Fragment key={index}>
                        <div
                            className={clsx(
                                'grid place-content-center w-[1.875rem] h-[1.875rem] rounded-lg shadow-lg text-sm font-bold',
                                index > stepIndex && 'opacity-75 text-grey-700/50'
                            )}
                        >
                            {isPastStep ? (
                                <CheckOutlinedIcon className="w-4 h-4 text-green-500" />
                            ) : (
                                index + 1
                            )}
                        </div>
                        <div className={clsx('grid gap-1 pt-0.5', isCurrentStep && 'pb-6')}>
                            <h2
                                className={clsx(
                                    'text-lg font-semibold',
                                    !isCurrentStep && 'text-grey-700/40'
                                )}
                            >
                                {title}
                            </h2>
                            {isCurrentStep && (
                                <>
                                    <p>{description}</p>
                                    {content}
                                </>
                            )}
                        </div>
                    </Fragment>
                )
            })}
            <div className="col-span-2" />
            {nextStepUrl === '/signup/load-create-overview' && relevantObjects.length === 0 ? (
                <Button
                    title="Please select an object first"
                    disabled
                    variant="primary"
                    size="large"
                    className="col-start-2 justify-self-start"
                >
                    {nextStepLabel}
                </Button>
            ) : (
                <Button
                    as={Link}
                    to={nextStepUrl!}
                    variant="primary"
                    size="large"
                    className="col-start-2 justify-self-start"
                    onClick={onSubmit}
                >
                    {nextStepLabel}
                </Button>
            )}
        </div>
    )
}

function useOnboardingSteps(stepKey: string) {
    const { relevantObjects, startedConnectedWithSalesforce, selectedRole, userId } =
        useOnboarding()
    const ONBOARDING_STEPS: Step[] = [
        {
            key: 'create',
            title: 'Create your account',
            description: null,
            content: null,
            nextStepLabel: null,
            nextStepUrl: '/signup/select-role',
        },
        {
            key: 'role',
            title: 'Define your company role',
            description: 'We give you the tools you need for your tasks.',
            content: <StepSelectRoleContent />,
            nextStepLabel: 'Next step ->',
            nextStepUrl: startedConnectedWithSalesforce
                ? '/signup/specify-relevant-objects'
                : '/signup/connect-salesforce',
            onSubmit: async () => {
                try {
                    if (userId) {
                        await captureTrait({ traits: { role: selectedRole } })
                    } else {
                        const anonymousId = await Analytics.getAnonymousId()
                        const email = localStorage.getItem('lf:onboarding:email') ?? undefined
                        await captureAnonymousTrait({
                            anonymousId,
                            traits: {
                                role: selectedRole,
                                email,
                            },
                        })
                    }
                } catch (e: any) {
                    logger.error(e)
                }
            },
        },
        {
            key: 'connect',
            title: 'Connect with Salesforce',
            description:
                'Access your data securely through our interface without storing it with us.',
            content: <StepConnectSalesforce />,
            nextStepLabel: 'Proceed to Salesforce ->',
            nextStepUrl: '/login',
        },
        {
            key: 'select-object',
            title: 'Specify objects you are working on',
            description:
                'Laserfocus will remind you each day about your most important tasks and deals.',
            content: <StepSpecifyObjects />,
            nextStepLabel: 'Create an overview',
            nextStepUrl: '/signup/load-create-overview',
        },
    ]
    const relevantSteps = ONBOARDING_STEPS.filter((a) => {
        if (a.key === 'connect' && startedConnectedWithSalesforce && stepKey !== 'connect') {
            return false
        }
        return true
    })

    const stepIndex = relevantSteps.findIndex((a) => a.key === stepKey)
    const { nextStepLabel, nextStepUrl, onSubmit } = relevantSteps[stepIndex]!
    return {
        relevantObjects,
        relevantSteps,
        nextStepLabel,
        nextStepUrl,
        onSubmit,
        stepIndex,
    }
}

function StepSelectRoleContent() {
    const { selectedRole, setSelectedRole } = useOnboarding()

    return (
        <StaticSelect
            options={ROLE_OPTIONS}
            selectedOptionValue={selectedRole}
            className="w-[12.25rem] mt-4"
            onChange={(option) => setSelectedRole(option.value)}
        />
    )
}

function StepConnectSalesforce() {
    const { connectionType, setConnectionType } = useOnboarding()

    return (
        <>
            <StaticSelect
                options={CONNECTION_TYPE_OPTIONS}
                selectedOptionValue={connectionType}
                className="w-[12.875rem] my-4"
                onChange={(option) => setConnectionType(option.value)}
            />
            {connectionType === 'demo-data' && (
                <div className="p-3 text-sm font-medium grid gap-1 rounded-xl bg-grey-700 text-white mb-4 -mt-4">
                    <p>
                        You can also try Laserfocus with demo data by creating a developer account
                        at Salesforce for free.
                    </p>
                    <ol className="list-decimal pl-5 grid gap-1">
                        <li>
                            {'Create a free Salesforce Developer Account page '}
                            <a
                                href="https://developer.salesforce.com/signup"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="underline outline-none focus-visible:ring rounded"
                            >
                                here
                            </a>
                            .
                        </li>
                        <li>Use these credentials to log into Laserfocus.</li>
                    </ol>
                </div>
            )}
            <EnterpriseSecureLink className="text-left" />
        </>
    )
}

function StepSpecifyObjects() {
    const { relevantObjects, setRelevantObjects } = useOnboarding()

    return (
        <StaticMultiSelect
            options={RELEVANT_OBJECT_OPTIONS}
            selectedOptionValues={relevantObjects}
            className="w-[13.25rem] mt-4"
            onChange={setRelevantObjects}
        />
    )
}

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { ModalInput } from '@laserfocus/ui/beam'
import { LockOutlinedIcon } from '@laserfocus/ui/icons'

import { TableCellProps } from './cell.types'
import {
    CLASS_NAME_INPUT_BUTTON,
    CLASS_NAME_INPUT_BUTTON_DARK,
    CLASS_NAME_INPUT_BUTTON_LIGHT,
    CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK,
    CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT,
    CLASS_NAME_LOCK_ICON,
    CLASS_NAME_LOCK_ICON_DARK,
} from './shared-class-names'
import { HighlightedCellValue } from './HighlightedCellValue'

interface TextCellProps<ValueType extends string | number = string>
    extends TableCellProps<ValueType> {
    multiline?: boolean
    allowLineBreaks?: boolean
    variant?: 'spacious' | 'dense'
    placeholder?: string
    hint?: string
    toDisplayValue?(v: ValueType | null): string
    parseValue?(v: string | null): ValueType | null
    useDisplayValueAsInput?: boolean
}

export function TextCell<ValueType extends string | number = string>({
    name,
    value,
    readOnly,
    updateValue,

    variant = 'spacious',
    multiline,
    allowLineBreaks,
    searchTerm,
    tableRef,

    placeholder = '—',
    theme,
    hint,
    parseValue,
    toDisplayValue,
    useDisplayValueAsInput,
}: TextCellProps<ValueType>) {
    const [isInputOpen, setIsInputOpen] = useState(false)

    const displayValue = toDisplayValue ? toDisplayValue(value) : value?.toString() ?? ''
    const [inputValue, setInputValue] = useState(
        useDisplayValueAsInput ? displayValue : value?.toString() ?? ''
    )

    function close() {
        setIsInputOpen(false)

        const parsed = parseValue ? parseValue(inputValue) : inputValue ?? null
        if (parsed !== value) {
            updateValue(parsed as ValueType)
        }
    }

    function open() {
        setInputValue(useDisplayValueAsInput ? displayValue : value?.toString() ?? '')
        setIsInputOpen(true)
    }

    return (
        <ModalInput
            name={name}
            isOpen={isInputOpen}
            value={inputValue}
            multiline={multiline}
            maxRows={20}
            overflowBoundaryRef={tableRef}
            onChange={(event) => {
                setInputValue(event.target.value)
            }}
            onClose={close}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault()
                    close()
                } else if (event.key === 'Enter' && event.shiftKey && !allowLineBreaks) {
                    event.preventDefault()
                }
            }}
            hint={
                multiline && allowLineBreaks ? (
                    <div className={clsx(theme === 'dark' && 'text-white/60')}>
                        <b>Enter</b> to confirm
                        <br />
                        <b>Shift + Enter</b> to add a new line
                    </div>
                ) : undefined
            }
            placeholder={placeholder}
            containerClassName={clsx(theme === 'dark' && 'bg-grey-650')}
            className={clsx(theme === 'dark' && 'bg-grey-650')}
            inputClassName={clsx('w-[18.75rem]', theme === 'dark' && 'text-white')}
            isSpacious={variant === 'dense' ? false : true}
            offsetCorrection={variant === 'dense' ? [0, 8] : undefined}
        >
            <button
                title={hint || displayValue}
                disabled={readOnly}
                className={twMerge(
                    CLASS_NAME_INPUT_BUTTON,
                    theme === 'dark' ? CLASS_NAME_INPUT_BUTTON_DARK : CLASS_NAME_INPUT_BUTTON_LIGHT,
                    !displayValue
                        ? theme === 'dark'
                            ? CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK
                            : CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT
                        : null
                )}
                onClick={open}
            >
                {displayValue ? (
                    <HighlightedCellValue
                        value={displayValue}
                        searchTerm={searchTerm}
                        className={clsx(theme === 'dark' && 'text-white')}
                        toDisplayValue={toDisplayValue}
                    />
                ) : (
                    placeholder
                )}
                {readOnly && (
                    <LockOutlinedIcon
                        className={twMerge(
                            CLASS_NAME_LOCK_ICON,
                            theme === 'dark' && CLASS_NAME_LOCK_ICON_DARK
                        )}
                    />
                )}
            </button>
        </ModalInput>
    )
}

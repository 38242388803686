import clsx from 'clsx'
import { useMemo } from 'react'

import { getLocale } from '@laserfocus/ui/util-locale'

import { useCounterContext, FALLBACK_QUOTA } from './CounterContext'
import { StatLabel, StatsCard, StatValue } from './StatsCard'
import { WORK_HOURS_PER_MONTH } from './ROICard'

const currencyFormatter = new Intl.NumberFormat(getLocale(), {
    // style: 'currency',
    // currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
})

export function HourWorthCard() {
    const { quota } = useCounterContext()

    const calcQuota = typeof quota === 'number' ? quota : FALLBACK_QUOTA
    const valuePerHour = calcQuota / WORK_HOURS_PER_MONTH
    const formattedValue = useMemo(() => {
        return currencyFormatter.format(valuePerHour)
    }, [valuePerHour])

    return (
        <StatsCard className="bg-green-50">
            <StatLabel>What is one hour of your time worth:</StatLabel>
            <StatValue className={clsx(!quota && 'text-grey-700/60')}>$ {formattedValue}</StatValue>
        </StatsCard>
    )
}

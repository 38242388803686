import { useEffect, useRef, useState } from 'react'
import { ulid } from 'ulid'
import { useObserver } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import {
    AccountProps,
    FilterCondition,
    LeadProps,
    OpportunityProps,
} from '@laserfocus/shared/models'
import { BridgeStackStore, useBridgeStackStore } from '@laserfocus/client/store-shared'
import { usePrevious } from '@laserfocus/ui/hooks'
import {
    useStackActions,
    getVisibleColumns,
    Column,
    getAllColumns,
} from '@laserfocus/client/feature-table'
import { mutateStack } from '@laserfocus/client/data-access-shared'
import { useObjectPool } from '@laserfocus/client/root-store-context'
import { fractional } from '@laserfocus/shared/util-common'
import { BaseError } from '@laserfocus/shared/util-error'
import { captureException } from '@laserfocus/ui/error-reporting'

import { RelevantObject, useOnboarding } from '../../OnboardingContext'

const VISIBLE_COLUMNS_BY_SALES_OBJECT: Record<RelevantObject, string[]> = {
    Opportunity: ['Account.Name', 'Name', 'NextActivity.Subject', 'NextActivity.Date', 'StageName'],
    Account: ['Name', 'Industry', 'NextActivity.Subject', 'NextActivity.Date', 'OwnerId'],
    Lead: ['Company', 'Name', 'NextActivity.Subject', 'NextActivity.Date', 'Status'],
}

type OnboardingSalesObject = OpportunityProps | AccountProps | LeadProps

interface TableDataStateLoading {
    isLoading: true
    data: null
}
export interface TableData {
    title: string
    setTitle(title: string): void
    saveView(title: string): void
    salesObjectType: 'Opportunity' | 'Account' | 'Lead'
    rows: OnboardingSalesObject[]
    visibleColumns: Column[]
    columnSections: Array<{
        label: string
        columns: Column[]
    }>
}
interface TableDataStateSuccess {
    isLoading: false
    /** null means that no filter with records was found */
    data: TableData | null
}

class OnboardingError extends BaseError {
    isOperational = true
}

export function useOnboardingTableData(): TableDataStateLoading | TableDataStateSuccess {
    const navigate = useNavigate()
    const bridgeStackStore = useBridgeStackStore()
    const { filterToCheck, setNextFilter, stackId } = useFiltersToCheck(bridgeStackStore)
    const [hasFoundData, setFoundData] = useState(false)

    const objectPool = useObjectPool()

    const { rows, stack, title, isFetching, errorMessage, didFetch, fetchableStack } = useObserver(
        () => {
            if (!stackId) {
                return {
                    rows: [] as OnboardingSalesObject[],
                    isFetching: Boolean(filterToCheck),
                }
            }

            const fetchableStack = bridgeStackStore.fetchableStacksById.get(stackId)

            if (!fetchableStack) {
                return {
                    rows: [] as OnboardingSalesObject[],
                    isFetching: false,
                }
            }

            return {
                rows: fetchableStack.sortedData as OnboardingSalesObject[],
                stack: fetchableStack.stack,
                title: fetchableStack.stack.title,
                isFetching: fetchableStack.isInitiallyLoading,
                didFetch: fetchableStack.didFirstFetch,
                errorMessage: fetchableStack.errorMessage,
                fetchableStack,
            }
        }
    )
    useEffect(() => {
        if (fetchableStack) {
            return fetchableStack.onMount()
        }
    }, [fetchableStack])

    console.log('onboardingTableData columns', Object.keys(stack?.visibleColumnMap || {}).length)

    const shouldLoadNextFilter =
        !hasFoundData && filterToCheck && didFetch && !isFetching && rows.length === 0

    const hasError = Boolean(errorMessage)

    useEffect(() => {
        if (errorMessage) {
            captureException(new OnboardingError(errorMessage))
            navigate('/signup/lean-back')
        } else if (shouldLoadNextFilter) {
            setNextFilter()
        } else if (didFetch && rows.length > 0) {
            setFoundData(true)
        }
    }, [
        isFetching,
        setNextFilter,
        shouldLoadNextFilter,
        didFetch,
        rows.length,
        navigate,
        errorMessage,
    ])

    const actions = useStackActions(stack, null, 'user')

    const { visibleColumns, columnSections } = useObserver(() => {
        if (!stack || !actions) {
            return {
                visibleColumns: [],
                columnSections: [],
            }
        }
        const visibleColumns = getVisibleColumns(stack, actions, objectPool)
        const columnSections = getAllColumns(stack, actions, objectPool)
            .map((columnsObject) => ({
                label: columnsObject.salesObjectType,
                columns: columnsObject.columns,
            }))
            .filter(({ columns }) => columns.length)
        return { visibleColumns, columnSections }
    })

    if (isFetching || shouldLoadNextFilter) {
        return {
            isLoading: true,
            data: null,
        }
    }

    if (hasError) {
        // Actually waiting for a redirect
        return {
            isLoading: true,
            data: null,
        }
    }

    if (!filterToCheck || !stack || !actions) {
        return {
            isLoading: false,
            data: null,
        }
    }

    return {
        isLoading: false,
        data: {
            title: title!,
            setTitle: actions.setTitle,
            saveView: (title) => {
                let visibleColumnMap = {
                    ...stack.visibleColumnMap,
                }
                Object.keys(stack.filterConditions).forEach((fieldName) => {
                    if (!visibleColumnMap[fieldName]) {
                        const orderKey = fractional.getOrderKeyForEnd(visibleColumnMap)
                        visibleColumnMap[fieldName] = orderKey
                    }
                })
                mutateStack.createStack({
                    ...stack,
                    icon: '🌎',
                    color: '#EBF1FE',
                    visibleColumnMap,
                    title,
                    isFavorite: true,
                })
            },
            salesObjectType: filterToCheck.salesObjectType,
            rows,
            visibleColumns,
            columnSections,
        },
    }
}

interface FilterData {
    title: string
    salesObjectType: RelevantObject
    conditions: Record<string, FilterCondition>
}

let version = 0

function useFiltersToCheck(bridgeStackStore: BridgeStackStore) {
    const [stackId, setStackId] = useState<string | null>(null)
    const { relevantObjects } = useOnboarding()
    const previousRelevantObjects = usePrevious(relevantObjects, relevantObjects)
    const [filtersToCheck, setFiltersToCheck] = useState(() =>
        createFiltersToCheck(relevantObjects)
    )
    const filterToCheck = filtersToCheck[0]
    const isMountRef = useRef(true)

    function receiveCurrentStack(filterToCheck?: FilterData) {
        if (!filterToCheck) {
            return
        }

        const stackId = ulid()

        bridgeStackStore.receiveStackFromBackend(
            {
                id: stackId,
                title: filterToCheck.title,
                sobject: filterToCheck.salesObjectType,
                filterConditions: filterToCheck.conditions,
                visibleColumnMap: fractional.getOrderedMap(
                    VISIBLE_COLUMNS_BY_SALES_OBJECT[filterToCheck.salesObjectType]
                ),
                columnAggregates: {},
                createdDate: new Date().toISOString(),
                modifiedDate: new Date().toISOString(),
                columnWidths: {},
                __typename: 'UserStack',
                version: version++,
            },
            { fetchEmptyConditions: true }
        )

        setStackId(stackId)
    }

    function setNextFilter() {
        const nextFilter = filtersToCheck.filter((filter, index) => index > 0)[0]
        setFiltersToCheck((filters) => filters.filter((filter, index) => index > 0))
        receiveCurrentStack(nextFilter)
    }

    useEffect(() => {
        if (isMountRef.current) {
            isMountRef.current = false
            receiveCurrentStack(filterToCheck)
        } else if (hashObjects(relevantObjects) !== hashObjects(previousRelevantObjects)) {
            const filtersToCheck = createFiltersToCheck(relevantObjects)
            const remainingFiltersToCheck = filtersToCheck.filter(
                (a) => !previousRelevantObjects.includes(a.salesObjectType)
            )
            const nextFilterToCheck = remainingFiltersToCheck[0]
            receiveCurrentStack(nextFilterToCheck)
            setFiltersToCheck(remainingFiltersToCheck)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relevantObjects])

    return {
        filterToCheck,
        setNextFilter,
        stackId,
    }
}

function toConditionMap(...conditions: FilterCondition[]): Record<string, FilterCondition> {
    return Object.fromEntries(conditions.map((c) => [c.fieldName, c]))
}

function createFiltersToCheck(relevantObjects: RelevantObject[]) {
    const iAmTheOwner: FilterCondition = {
        fieldName: 'OwnerId',
        fieldType: 'reference',
        operator: 'EQ',
        values: ['$me'],
    }
    const openOpportunities: FilterCondition = {
        fieldName: 'IsClosed',
        fieldType: 'boolean',
        operator: 'EQ',
        values: [false],
    }
    const openLeads: FilterCondition = {
        fieldName: 'IsConverted',
        fieldType: 'boolean',
        operator: 'EQ',
        values: [false],
    }
    const filtersToCheck: FilterData[] = [
        {
            title: 'My Custom Stack',
            salesObjectType: 'Opportunity',
            conditions: toConditionMap(iAmTheOwner, openOpportunities),
        },
        {
            title: 'My Custom Stack',
            salesObjectType: 'Account',
            conditions: toConditionMap(iAmTheOwner),
        },
        {
            title: 'My Custom Stack',
            salesObjectType: 'Lead',
            conditions: toConditionMap(iAmTheOwner, openLeads),
        },
        {
            title: 'My Custom Stack',
            salesObjectType: 'Opportunity',
            conditions: toConditionMap(openOpportunities),
        },
        {
            title: 'My Custom Stack',
            salesObjectType: 'Account',
            conditions: {},
        },
        {
            title: 'My Custom Stack',
            salesObjectType: 'Lead',
            conditions: toConditionMap(openLeads),
        },
    ]
    return filtersToCheck.filter((f) => relevantObjects.includes(f.salesObjectType))
}

function hashObjects(relevant: string[]) {
    return relevant.sort().join('-')
}

import { useCallback, useMemo } from 'react'

import {
    useRegisterEverboardingHint,
    EverboardingHintConfig,
    useConditionalTrack,
    EverboardingCounters,
} from '@laserfocus/client/feature-everboarding'
import { Stack } from '@laserfocus/shared/models'

export function useTablePageEverboarding(stack?: Stack, isOverlayOpen?: boolean) {
    const preset = stack?.preset

    // Laserfocus Method
    const laserfocusMethodHint = useMemo((): EverboardingHintConfig => {
        return {
            name: 'everboarding_stack_lfmethod_reminder',
            shouldShow: () => {
                return !isOverlayOpen && preset === 'laserfocus-method'
            },
        }
    }, [isOverlayOpen, preset])
    useConditionalTrack(
        'everboarding_stack_lfmethod_view',
        useCallback(
            (counters: EverboardingCounters) => {
                const currentCount = counters?.['everboarding_stack_lfmethod_view'] || 0
                return currentCount < 10 && preset === 'laserfocus-method'
            },
            [preset]
        )
    )
    useRegisterEverboardingHint(laserfocusMethodHint)

    // Past Close Dates
    const pastCloseDateHint = useMemo((): EverboardingHintConfig => {
        return {
            name: 'everboarding_stack_pastclosedate_change',
            shouldShow: () => {
                return !isOverlayOpen && preset === 'past-close-date'
            },
        }
    }, [isOverlayOpen, preset])

    useConditionalTrack(
        'everboarding_stack_pastclosedate_view',
        useCallback(
            (counters: EverboardingCounters) => {
                const currentCount = counters?.['everboarding_stack_pastclosedate_view'] || 0
                return currentCount < 10 && preset === 'past-close-date'
            },
            [preset]
        )
    )
    useRegisterEverboardingHint(pastCloseDateHint)

    // Non Preset Table
    const customizeFieldsHint = useMemo((): EverboardingHintConfig => {
        return {
            name: 'everboarding_stack_customize_fields',
            shouldShow: () => {
                return !isOverlayOpen && !preset
            },
        }
    }, [isOverlayOpen, preset])
    const overlayHint = useMemo((): EverboardingHintConfig => {
        return {
            name: 'everboarding_stack_overlay',
            shouldShow: (counters, previousHint) => {
                return !isOverlayOpen && !preset && !previousHint
            },
        }
    }, [isOverlayOpen, preset])

    const bulkEditHint = useMemo((): EverboardingHintConfig => {
        return {
            name: 'everboarding_stack_bulk_edit',
            shouldShow: (counters: EverboardingCounters, previousHint) => {
                const stackViewTotal =
                    0 +
                    (counters?.['everboarding_stack_view'] || 0) +
                    (counters?.['everboarding_stack_pastclosedate_view'] || 0) +
                    (counters?.['everboarding_stack_lfmethod_view'] || 0)
                return !isOverlayOpen && stackViewTotal > 10 && !previousHint
            },
        }
    }, [isOverlayOpen])

    useRegisterEverboardingHint(customizeFieldsHint)
    useRegisterEverboardingHint(overlayHint)
    useRegisterEverboardingHint(bulkEditHint)
    useConditionalTrack(
        'everboarding_stack_view',
        useCallback(
            (counters: EverboardingCounters) => {
                const currentCount = counters?.['everboarding_stack_view'] || 0
                return currentCount < 10 && !preset
            },
            [preset]
        )
    )
}

import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef } from 'react'

import { SearchInput } from '../input/search-input'

import { ModalContainer } from './ModalContainer'
import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'
import { ModalOverlay } from './ModalOverlay'
import { ModalStickyHeading } from './ModalStickyHeading'
import { ModalTabs, TabButton } from './ModalTabs'

interface ModalProps {
    show: boolean
    onClose(value: boolean): void
    children: React.ReactNode
}

export function Modal({ show, onClose, children }: ModalProps) {
    const lastShownChildrenRef = useRef(children)

    if (show) {
        lastShownChildrenRef.current = children
    }

    return (
        <Transition show={show} appear as={Fragment}>
            <Dialog
                onClose={onClose}
                className="fixed z-10 inset-0 overflow-y-auto"
                data-testid="modal-overlay"
            >
                {lastShownChildrenRef.current}
            </Dialog>
        </Transition>
    )
}

Modal.Container = ModalContainer
Modal.Footer = ModalFooter
Modal.Header = ModalHeader
Modal.Overlay = ModalOverlay
Modal.SearchInput = SearchInput
Modal.StickyHeading = ModalStickyHeading
Modal.Tabs = ModalTabs
Modal.TabButton = TabButton

const WIDTH_STYLES = {
    small: /*tw:*/ 'w-[9.75rem]',
    large: /*tw:*/ 'w-[15.875rem]',
}

export type Size = keyof typeof WIDTH_STYLES | 'unset' | number
interface SizeContainerProps {
    children: React.ReactNode
    size?: Size
}

export function SizeContainer({ size = 'small', ...props }: SizeContainerProps) {
    if (size === 'unset') {
        return <>{props.children}</>
    }

    return (
        <div
            {...props}
            className={WIDTH_STYLES[size as keyof typeof WIDTH_STYLES]}
            style={typeof size === 'number' ? { width: size } : undefined}
        />
    )
}

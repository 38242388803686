import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'

import { RadioButton } from '@laserfocus/ui/beam'
type ModeRadioGroupProps = {
    value: string
    onChange(value: string): void
    options: Array<{ label?: string; value: string }>
    disabled?: boolean
    tooltip?: string
}

export function ModeRadioGroup({
    value,
    onChange,
    options,
    disabled,
    tooltip,
}: ModeRadioGroupProps) {
    return (
        <RadioGroup
            value={value}
            onChange={onChange}
            className="grid justify-start gap-4 grid-flow-col"
            disabled={disabled}
            title={tooltip}
        >
            {options.map(({ label, value }) => (
                <RadioGroup.Option value={value} key={value} className="outline-none">
                    {({ checked, active, disabled }) => (
                        <div className="grid gap-2 grid-flow-col justify-start items-center">
                            <RadioButton
                                checked={Boolean(checked)}
                                focused={active}
                                disabled={disabled}
                            />

                            <label className={clsx(disabled && 'text-grey-700/60')}>
                                {label || value}
                            </label>
                        </div>
                    )}
                </RadioGroup.Option>
            ))}
        </RadioGroup>
    )
}

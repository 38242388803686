const EVENT_TO_CONVERSION_MAP = {
    signup_salesforce_clicked: 5213049,
    user_signup_email_fe: 5213057,
    signup_email_salesforce_clicked: 5213049,
    user_signup_fe: 5213065,
}

export function trackLinkedInConversion(event: string) {
    const conversion = EVENT_TO_CONVERSION_MAP[event]
    if (conversion && window.lintrk) {
        window.lintrk('track', { conversion_id: conversion })
    }
}

declare global {
    interface Window {
        lintrk: (fn: string, payload: Record<string, any>) => void
    }
}

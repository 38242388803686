export const PERSON_TASK_FRAGMENT = `
  fragment PersonTask on Task {
    Id
    Subject
    Description
    ActivitySubtype
    ActivityDateTime
    CompletedDateTime
    ReminderDateTime
    LastModifiedDate
    Status
    Outcome
    CreatedDate
    Phone
    AlternateDetailId
    IsClosed
    WhoId
    AccountId
    WhatId
    OwnerId
    Owner {
      Id
      Name
    }
    Snippet
  }
`

import React from 'react'

import { TableHead, TableRow } from '@laserfocus/client/feature-table'
import { EmptyState } from '@laserfocus/ui/beam'

import { Row } from './useSettingsTableData'
import { CellsLoading } from './CellsLoading'
import { TableFooter } from './TableFooter'

interface StackTableProps {
    rows: Row[]
    isLoading: boolean
    tableHeadKey: string
}

export function StackTable({ rows, isLoading, tableHeadKey }: StackTableProps) {
    return (
        <div
            role="table"
            className="grid overflow-x-auto text-sm leading-[1.4] font-medium relative bg-white w-full isolate"
        >
            <TableHead top={0} key={tableHeadKey} />

            {isLoading ? (
                <CellsLoading />
            ) : rows.length === 0 ? (
                <NoDataContent />
            ) : (
                rows.map((row, rowIndex) => <TableRow index={rowIndex} translateY={0} relative />)
            )}
            <TableFooter />
        </div>
    )
}

function NoDataContent() {
    return (
        <div className="w-screen my-6">
            <EmptyState
                size="medium"
                emojis={['📇', '🎯', '📂']}
                title="No data with this filter for you"
                subtitle={
                    <>
                        This is fine if you have a filter on e.g.{' '}
                        <span className=" text-black">Owner</span> for{' '}
                        <span className=" text-black">"me"</span>. Your Salespeople will probably
                        have relevant data.
                    </>
                }
            ></EmptyState>
        </div>
    )
}

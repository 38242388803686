import { Toaster as ReactHotToaster } from 'react-hot-toast'

import { CheckFilledIcon, ExclamationFilledIcon } from '@laserfocus/ui/icons'
import { theme } from '@laserfocus/ui/tailwindcss'

if (process.env.NODE_ENV !== 'production') {
    ReactHotToaster.displayName = 'ReactHotToaster'
}

export function Toaster() {
    return (
        <ReactHotToaster
            position="bottom-right"
            toastOptions={{
                duration: 5000,
                style: {
                    boxShadow: 'none',
                    color: theme.colors.white,
                    backgroundColor: theme.colors.grey[700],
                    padding: '12px 16px',
                    borderRadius: '16px',
                    margin: '16px',
                },
                success: {
                    icon: (
                        <CheckFilledIcon className="min-w-[1.5rem] w-6 h-6 mr-3 text-green-300" />
                    ),
                },
                error: {
                    icon: (
                        <ExclamationFilledIcon className="min-w-[1.5rem] w-6 h-6 mr-3 text-red-500" />
                    ),
                    duration: 8000,
                },
            }}
        />
    )
}

import {
    createFieldGroup,
    createOrgFieldGroup,
    deleteFieldGroup,
    removeField,
    setFieldPosition,
    updateFieldGroup,
    updateFieldGroupsOrder,
    updateOrgFieldGroupsOrder,
    shareFieldGroup,
    addField,
    updateOrgFieldGroup,
    deactivateOrgFieldGroup,
    addFieldToOrgFieldGroup,
    removeOrgFieldGroupField,
    setOrgFieldGroupFieldPosition,
    resetFieldGroup,
    activateOrgFieldGroup,
    deleteOrgFieldGroup,
} from './salesobject-fields/fieldgroup-repository'
import {
    createSObject,
    updateSObject,
    discardFailedMutation,
    convertLead,
    bulkUpdateSObject,
} from './sales/sobject-repository'
import {
    changeStackColor,
    changeStackIcon,
    createStack,
    createStackFromView,
    deleteStack,
    deactivateStack,
    favoriteStack,
    hideColumn,
    removeFilter,
    saveStack,
    setColumnAggregate,
    setColumnsOrder,
    setFilterCondition,
    setSorting,
    setTitle,
    setColumnWidth,
    shareStack,
    unfavoriteStack,
    updateStack,
    setStackRowColor,
    resetStack,
    showColumn,
    duplicateStack,
    setTiming,
} from './stack/stack-repository'

// Sales Object
export const mutateSObject = {
    createSObject,
    updateSObject,
    discardFailedMutation,
    convertLead,
    bulkUpdateSObject,
}
export { mutators as salesObjectMutators } from './sales/sobject-repository'

// Stacks
export const mutateStack = {
    changeStackColor,
    changeStackIcon,
    createStack,
    createStackFromView,
    deleteStack,
    deactivateStack,
    favoriteStack,
    hideColumn,
    removeFilter,
    saveStack,
    setColumnsOrder,
    setFilterCondition,
    setSorting,
    setTitle,
    setColumnWidth,
    shareStack,
    unfavoriteStack,
    updateStack,
    setStackRowColor,
    resetStack,
    setColumnAggregate,
    showColumn,
    duplicateStack,
    setTiming,
}
export {
    mutators as stackMutators,
    useStackById,
    useStacks,
    useStackRowsById,
    queryCanCreateStack,
} from './stack/stack-repository'
export { combineUserOrgStacks, cleanStack, combineStacks } from './stack/org-user-stacks'

// Notes
export {
    createNote,
    deleteNote,
    updateContentDocument,
    updateNoteBody,
    updateNoteTitle,
    pinNote,
    unpinNote,
    mutators as noteMutators,
} from './notes/notes-repository'
export {
    mutators as noteTemplateMutators,
    useNoteTemplates,
    createNoteTemplate,
    updateNoteTemplate,
    deleteNoteTemplate,
    queryCanCreateNoteTemplate,
} from './notes/note-templates-repository'

export {
    mutators as activityCounterMutators,
    countActivity,
    useOrgActivityCounterSince,
    useActivityCounterForUserBetween,
} from './activity/activity-counter-repo'
export { withCounters } from './activity/mutation-with-counter'

// FieldGroup
export {
    mutators as fieldGroupMutators,
    useFieldGroups,
    makeQueryCanCreateFieldGroup,
} from './salesobject-fields/fieldgroup-repository'

export const mutateFieldGroup = {
    createFieldGroup,
    createOrgFieldGroup,
    deleteFieldGroup,
    deleteOrgFieldGroup,
    deactivateOrgFieldGroup,
    activateOrgFieldGroup,
    updateOrgFieldGroup,
    removeField,
    addField,
    addFieldToOrgFieldGroup,
    removeOrgFieldGroupField,
    setFieldPosition,
    setOrgFieldGroupFieldPosition,
    updateFieldGroup,
    updateFieldGroupsOrder,
    updateOrgFieldGroupsOrder,
    shareFieldGroup,
    resetFieldGroup,
}

export {
    completeTask,
    uncompleteTask,
    updateActivity,
    updateEvent,
    updateTask,
    logActivity,
    rescheduleEvent,
    rescheduleTask,
    scheduleTask,
    pinActivity,
    unpinActivity,
    mutators as activityMutators,
    deleteActivity,
    attachActivity,
    createTask,
} from './activity/activity-client-mutations'
export * as activityQuery from './activity/activity-client-queries'

export { updateEmoji, mutators as salesExtensionMutators } from './sales/salesextension-repository'

// Synced Root
export {
    visitRoot,
    prefetchRoots,
    mutators as syncedRootsMutators,
    ensureContactSynced,
    ensureOpportunitySynced,
    ensureObjectOfTypeExists,
    getRequiredKeys,
    syncNames,
} from './person/synced-roots-client-mutations'
export { queryRootSyncState } from './person/synced-root-model'
export type { RootSyncState } from './person/synced-root-model'

export { getErrorMessage, shouldShowHelperMessage } from './transaction/format-toast'
export {
    ActivitiesMetadataProvider,
    useActivitiesMetadataContext,
} from './ActivitiesMetadata/ActivitiesMetadataContext'

export { mutators as settingsMutators, setQuota } from './settings/settings-repository'

export {
    useUsersSubscription,
    useColleagues,
    useInvites,
    useLFUsers,
    useMyRole,
    inviteColleagues,
    mutators as userMutators,
    deleteRole,
    createRole,
    updateRole,
    updateUser,
} from './user/user-repository'

export { sortUsers } from './user/sort-colleagues'

export { useSubscription } from './subscription/subscription-repository'

import { CheckOutlinedIcon } from '@laserfocus/ui/icons'

export function FeatureList({ features }: { features: string[] }) {
    return (
        <ul className="grid gap-2">
            {features.map((feature) => (
                <li key={feature} className="text-[0.9rem] leading-normal flex">
                    <div className="text-blue-500 bg-blue-50 flex-none grid place-items-center w-[1.2rem] h-[1.2rem] rounded-full mr-2 mt-[0.05rem]">
                        <CheckOutlinedIcon className="w-[0.8rem] h-[0.8rem]" />
                    </div>
                    {feature}
                </li>
            ))}
        </ul>
    )
}

import { useParams } from 'react-router'

import { PersonPageContainer } from '@laserfocus/client/feature-person-details'
import { OverdueNavigationProvider } from '@laserfocus/client/feature-stacks'
import { wrapAuthenticated } from '@laserfocus/client/feature-auth'

export function TodayPersonPage() {
    const { personId } = useParams<{ personId: string }>()
    return (
        <OverdueNavigationProvider rootId={personId}>
            <PersonPageContainer personId={personId} />
        </OverdueNavigationProvider>
    )
}

export default wrapAuthenticated(TodayPersonPage)

import type { WriteTransaction, JSONValue, Replicache, ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'
import { ulid } from 'ulid'
import { mapValues, omit } from 'lodash'

import { getClient } from '@laserfocus/client/replicache'
import {
    StackInput,
    Stack,
    StackMutatorSchema,
    applyValidators,
    Prefix,
    StackUpdate,
    StackRows,
    UserStack,
    OrgStack,
    Aggregation,
    Sorting,
    FilterCondition,
    StackTiming,
    OrgStackInputExtension,
    Identity,
    LFUser,
} from '@laserfocus/shared/models'
import { z } from '@laserfocus/shared/decoder'
import { assert } from '@laserfocus/shared/util-error'
import { toast } from '@laserfocus/ui/beam'

import { FREE_PLAN_LIMITS, shouldAllowProAction } from '../subscription/subscription-repository'

import { combineUserOrgStacks, combineStacks } from './org-user-stacks'

export type ReplicacheClient = Replicache<typeof mutators>

export function useStacks(showAll?: boolean) {
    const rep = getClient()
    const { didLoad, combined, forRole, didPull, role } = useSubscribe<{
        didPull: boolean
        didLoad: boolean
        combined: Stack[]
        forRole: Stack[]
        role?: string | null
    }>(
        rep,
        async (tx: ReadTransaction) => {
            const { combined, forRole, didPull } = await queryStacks(tx)

            return {
                didLoad: true,
                role,
                combined,
                forRole,
                didPull,
            }
        },
        {
            didPull: false,
            didLoad: false,
            role: null,
            combined: [],
            forRole: [],
        },
        []
    )

    return {
        didPull,
        didLoad,
        stacks: showAll ? combined : forRole,
    }
}
async function queryStacks(tx: ReadTransaction) {
    let role: string | null = null
    const stacks = (await tx
        .scan({ prefix: `${Prefix.Stack}/` })
        .entries()
        .toArray()) as Array<[string, UserStack]>

    const me = (await tx.get('identity')) as Identity
    if (me) {
        const lfUser = (await tx.get([Prefix.LFUser, me.Id].join('/'))) as LFUser | undefined
        role = lfUser?.role ?? null
    }
    const onlyNewSchema = stacks
        .filter(
            ([key, stack]) => key.startsWith(Prefix.StackUser) || key.startsWith(Prefix.StackOrg)
        )
        .map(([key, stack]) => stack)

    const notDeleted = onlyNewSchema.filter((a) => !a.deleted)
    const combined = combineUserOrgStacks(notDeleted)
    const forRole = combined.filter((st) => shouldShowStackToUser(st, role))
    return {
        didPull: stacks.some(([key]) => key.startsWith(Prefix.StackOrg)),
        combined,
        forRole,
        role,
    }
}

function shouldShowStackToUser(stack: Stack, userRole?: string | null) {
    if (!stack.roles?.length) {
        return true
    }
    if (stack.isExtendedFromOrg || stack.__typename === 'UserStack') {
        return true
    }
    if (!userRole) {
        return true
    }
    return stack.roles.includes(userRole)
}

export function useStackById(stackId: string): Stack | null {
    const rep = getClient()
    return useSubscribe<Stack | null>(
        rep,
        async (tx: ReadTransaction) => {
            const [orgStack, userStack] = (await Promise.all([
                tx.get(`${Prefix.StackOrg}/${stackId}`),
                tx.get(`${Prefix.StackUser}/${stackId}`),
            ])) as [OrgStack | null, UserStack | null]
            if (orgStack && userStack) {
                return combineStacks(orgStack, userStack)
            }

            return (orgStack || userStack) as unknown as Stack | null
        },
        null,
        [stackId]
    )
}

export function useStackRowsById(stackId?: string): {
    colors?: Record<string, string | null | undefined>
} {
    const rep = getClient()
    const stackrows = useSubscribe<StackRows | null>(
        rep,
        async (tx: ReadTransaction) => {
            if (!stackId) {
                return null
            }
            const stackrows = await tx.get(`${Prefix.StackRow}/${stackId}`)
            return stackrows as unknown as StackRows | null
        },
        null,
        [stackId]
    )
    return stackrows || { colors: {} }
}

export async function duplicateStack(newId: string, stack: Stack, type: 'org' | 'user') {
    const nextStack: StackInput = {
        ...omit(stack, 'id', 'preset', 'lockedColumnNames'),
        filterConditions: mapValues(stack.filterConditions, (condition) =>
            omit(condition, 'isLocked')
        ),
    }

    const createdStack = await createStack(
        nextStack,
        {
            id: newId,
            type,
        },
        undefined,
        stack.id
    )
    return createdStack
}

type CreatedStack = StackInput & { id: string }
export async function createStack(
    values: StackInput,
    identifier?: Identifier,
    extension?: OrgStackInputExtension,
    duplicatedFromId?: string
): Promise<CreatedStack | undefined> {
    const rep = getClient<typeof mutators>()

    const finalIdentifier = {
        id: identifier?.id || ulid(),
        type: identifier?.type || 'user',
    }

    const canCreate = await checkCanCreateStack()
    if (canCreate) {
        const tasks: Promise<unknown>[] = [saveStack(values, finalIdentifier, extension)]
        if (duplicatedFromId) {
            const existing = await rep.query((tx) => getStackRows(tx, duplicatedFromId))
            if (existing) {
                const cleanedColors = Object.fromEntries(
                    Object.entries(existing.colors).filter(([key, value]) => value)
                ) as Record<string, string>
                tasks.push(
                    rep.mutate.setStackRows({
                        id: finalIdentifier.id,
                        type: 'user',
                        values: { colors: cleanedColors },
                        optimistic: {
                            modifiedDate: new Date().toISOString(),
                        },
                    })
                )
            }
        }
        await Promise.all(tasks)
        return {
            id: finalIdentifier.id,
            ...values,
        }
    }
}

async function checkCanCreateStack() {
    const rep = getClient<typeof mutators>()

    const allowed = await rep.query(queryCanCreateStack)

    if (!allowed) {
        toast.warn({
            title: `You can only create ${FREE_PLAN_LIMITS.STACKS} custom stacks`,
        })
    }
    return allowed
}
export async function queryCanCreateStack(tx: ReadTransaction) {
    const [{ combined }, hasPro] = await Promise.all([queryStacks(tx), shouldAllowProAction(tx)])
    if (hasPro) {
        return true
    }
    const onlyUserStacks = combined.filter(
        (a) => a.__typename === 'UserStack' && !a.isExtendedFromOrg
    )
    if (onlyUserStacks.length < FREE_PLAN_LIMITS.NOTE_TEMPLATES) {
        return true
    }
    return false
}

type Identifier = {
    type: 'user' | 'org'
    id: string
}
export async function saveStack(
    values: StackInput,
    identifier: Identifier,
    extension?: OrgStackInputExtension
) {
    const { id, type } = identifier
    if (extension) {
        assert(type === 'org', 'Can only provide stack extension if saving for an Org')
    }
    const rep = getClient<typeof mutators>()
    const input = {
        id,
        type,
        values,
        extension,
        optimistic: {
            ...getOptimistic(),
            createdDate: new Date().toISOString(),
        },
    }
    await rep.mutate.saveStack(input)
}

export async function shareStack(input: z.infer<typeof StackMutatorSchema.shareStack>) {
    const rep = getClient<typeof mutators>()
    rep.mutate.shareStack(omit(input, 'timing'))
}

export async function setTitle(id: string, title: string, type: 'user' | 'org' = 'user') {
    return updateStack(id, { title }, type)
}

export async function updateStack(id: string, update: StackUpdate, type: 'user' | 'org' = 'user') {
    const rep = getClient<typeof mutators>()
    await rep.mutate.updateStack({
        id,
        type,
        values: update,
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function hideColumn(id: string, columnName: string, type: 'user' | 'org' = 'user') {
    return getClient<typeof mutators>().mutate.hideColumn({
        id,
        type,
        values: { columnName },
        optimistic: getOptimistic(),
    })
}

export async function showColumn(
    id: string,
    values: { columnName: string; position: string },
    type: 'user' | 'org' = 'user'
) {
    return getClient<typeof mutators>().mutate.showColumn({
        id,
        type,
        values,
        optimistic: getOptimistic(),
    })
}

export async function setColumnsOrder(
    id: string,
    visibleColumnMap: Record<string, string>,
    type: 'user' | 'org' = 'user'
) {
    const rep = getClient<typeof mutators>()
    await rep.mutate.setColumnsOrder({
        id,
        type,
        values: { visibleColumnMap },
        optimistic: getOptimistic(),
    })
}

export async function setColumnWidth(
    id: string,
    values: { columnName: string; width: number },
    type: 'user' | 'org' = 'user'
) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.setColumnWidth({
        id,
        type,
        values,
        optimistic: getOptimistic(),
    })
}

export async function setColumnAggregate(
    id: string,
    values: { columnName: string; aggregation: Aggregation },
    type: 'user' | 'org' = 'user'
) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.setColumnAggregate({
        id,
        type,
        values,
        optimistic: getOptimistic(),
    })
}

export async function setSorting(id: string, sorting: Sorting, type: 'user' | 'org' = 'user') {
    const rep = getClient<typeof mutators>()
    return rep.mutate.setSorting({
        id,
        type,
        values: { sorting },
        optimistic: getOptimistic(),
    })
}

export async function setTiming(
    id: string,
    timing: StackTiming | null,
    type: 'user' | 'org' = 'user'
) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.setTiming({
        id,
        type,
        values: { timing },
        optimistic: getOptimistic(),
    })
}

export async function setFilterCondition(
    id: string,
    condition: FilterCondition,
    type: 'user' | 'org' = 'user'
) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.setFilterCondition({
        id,
        type,
        values: { condition },
        optimistic: getOptimistic(),
    })
}

export async function removeFilter(id: string, columnName: string, type: 'user' | 'org' = 'user') {
    const rep = getClient<typeof mutators>()
    return rep.mutate.removeFilter({
        id,
        type,
        values: { columnName },
        optimistic: getOptimistic(),
    })
}

export async function deleteStack(stackId: string) {
    const rep = getClient<typeof mutators>()

    await rep.mutate.deleteStack({ id: stackId, type: 'user', optimistic: getOptimistic() })
}

export async function deactivateStack(stackId: string) {
    const rep = getClient<typeof mutators>()

    await rep.mutate.deactivateStack({ id: stackId, type: 'org', optimistic: getOptimistic() })
}

export async function resetStack(stackId: string) {
    const rep = getClient<typeof mutators>()

    await rep.mutate.resetStack({ id: stackId, type: 'user', optimistic: getOptimistic() })
}

export async function favoriteStack(stackId: string) {
    return getClient<typeof mutators>().mutate.favoriteStack({
        id: stackId,
        type: 'user',
        optimistic: getOptimistic(),
    })
}
export async function unfavoriteStack(stackId: string) {
    return getClient<typeof mutators>().mutate.unfavoriteStack({
        id: stackId,
        type: 'user',
        optimistic: getOptimistic(),
    })
}

export async function changeStackColor(id: string, color: string, type: 'user' | 'org' = 'user') {
    return getClient<typeof mutators>().mutate.updateStack({
        id,
        type,
        values: { color },
        optimistic: getOptimistic(),
    })
}
export async function changeStackIcon(id: string, icon: string, type: 'user' | 'org' = 'user') {
    return getClient<typeof mutators>().mutate.updateStack({
        id,
        type,
        values: { icon },
        optimistic: getOptimistic(),
    })
}

export async function createStackFromView(
    sobjectType: 'Lead' | 'Account' | 'Contact' | 'Opportunity',
    viewId: string
) {
    const rep = getClient<typeof mutators>()
    const id = ulid()
    await rep.mutate.createStackFromView({ stackId: id, sobjectType, viewId })
    return id
}

export async function setStackRowColor(args: z.infer<typeof StackMutatorSchema.setStackRowColor>) {
    const rep = getClient<typeof mutators>()
    await rep.mutate.setStackRowColor(args)
}

export const mutators = applyValidators(StackMutatorSchema, {
    async saveStack(tx, args) {
        const stack: StackInput & {
            id: string
            __typename: 'OrgStack' | 'UserStack'
            version: number
        } = {
            id: args.id,
            __typename: args.type === 'org' ? 'OrgStack' : 'UserStack',
            ...args.values,
            ...args.optimistic,
            ...args.extension,
            version: 1,
        }
        if (args.type === 'org') {
            return putOrgStack(tx, stack)
        }
        return putUserStack(tx, stack)
    },
    async updateStack(tx, args) {
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, (stack: OrgStack) => ({
                ...stack,
                ...args.optimistic,
                ...args.values,
            }))
        } else {
            await updateUserStack(tx, args.id, (stack) => ({
                ...stack,
                ...args.optimistic,
                ...args.values,
            }))
        }
    },
    async shareStack(tx, args) {
        // NOOP
    },
    async createStackFromView(tx, args) {
        return tx.put(`process/createviewfromstack/${args.stackId}`, args)
    },

    async showColumn(tx, args) {
        const updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            visibleColumnMap: {
                ...stack?.visibleColumnMap,
                [args.values.columnName]: args.values.position,
            },
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async hideColumn(tx, args) {
        const updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            visibleColumnMap: {
                ...stack?.visibleColumnMap,
                [args.values.columnName]: null,
            },
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async setColumnsOrder(tx, args) {
        const updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            visibleColumnMap: {
                ...stack?.visibleColumnMap,
                ...args.values.visibleColumnMap,
            },
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async setColumnWidth(tx, args) {
        const updater: Updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            columnWidths: {
                ...stack?.columnWidths,
                [args.values.columnName]: args.values.width,
            },
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async setColumnAggregate(tx, args) {
        const updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            columnAggregates: {
                ...stack?.columnAggregates,
                [args.values.columnName]: args.values.aggregation,
            },
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async setSorting(tx, args) {
        const updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            sorting: args.values.sorting,
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async setTiming(tx, args) {
        const updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            timing: args.values.timing,
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async setFilterCondition(tx, args) {
        const updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            filterConditions: {
                ...stack?.filterConditions,
                [args.values.condition.fieldName]: args.values.condition,
            },
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async removeFilter(tx, args) {
        const updater = (stack: OrgStack | UserStack | undefined) => ({
            ...stack,
            filterConditions: {
                ...omit(stack?.filterConditions, args.values.columnName),
            },
        })
        if (args.type === 'org') {
            await updateOrgStack(tx, args.id, updater as OrgStackUpdater)
        } else {
            await updateUserStack(tx, args.id, updater as UserStackUpdater)
        }
    },
    async deleteStack(tx, args) {
        await tx.del(getUserKey(args.id))
    },
    async favoriteStack(tx, args) {
        await updateUserStack(tx, args.id, (stack: UserStack | undefined) => ({
            ...stack,
            isFavorite: true,
        }))
    },
    async unfavoriteStack(tx, args) {
        await updateUserStack(tx, args.id, (stack: UserStack | undefined) => ({
            ...stack,
            isFavorite: false,
        }))
    },
    async setStackRowColor(tx, args) {
        const existing = await getStackRows(tx, args.id)
        const updated: StackRows = {
            ...existing,
            id: args.id,
            colors: {
                ...(existing?.colors || {}),
                [args.values.recordId]: args.values.color,
            },
            __typename: 'stackrows',
        }
        await putStackRows(tx, updated)
    },
    async setStackRows(tx, args) {
        return putStackRows(tx, {
            id: args.id,
            colors: args.values.colors,
            __typename: 'stackrows',
        })
    },
    async resetStack(tx, args) {
        await tx.del(getUserKey(args.id))
    },
    async deactivateStack(tx, args) {
        await updateOrgStack(tx, args.id, (stack: OrgStack) => ({
            ...stack,
            deactivated: true,
        }))
    },
})

async function getStackRows(tx: ReadTransaction, id: string): Promise<StackRows | undefined> {
    return tx.get([Prefix.StackRow, id].join('/')) as Promise<StackRows | undefined>
}
async function putStackRows(tx: WriteTransaction, stackrows: StackRows) {
    return tx.put([Prefix.StackRow, stackrows.id].join('/'), stackrows)
}

function getUserStack(tx: ReadTransaction, id: string): Promise<UserStack | undefined> {
    return tx.get(getUserKey(id)) as Promise<UserStack | undefined>
}

function getOrgStack(tx: ReadTransaction, id: string): Promise<OrgStack | undefined> {
    return tx.get(getOrgKey(id)) as Promise<OrgStack | undefined>
}

async function putUserStack(tx: WriteTransaction, input: Partial<StackInput> & { id: string }) {
    await tx.put(getUserKey(input.id), input as unknown as JSONValue)
}

async function putOrgStack(tx: WriteTransaction, input: StackInput & { id: string }) {
    await tx.put(getOrgKey(input.id), input as unknown as JSONValue)
}

type Updater = OrgStackUpdater | UserStackUpdater
type OrgStackUpdater = (stack: OrgStack) => StackInput
type UserStackUpdater = (stack?: UserStack) => Partial<StackInput>

async function updateOrgStack(tx: WriteTransaction, id: string, updater: OrgStackUpdater) {
    const stack = await getOrgStack(tx, id)
    if (stack) {
        const updated = {
            id,
            __typename: 'OrgStack',
            ...updater(stack),
            version: stack.version + 1,
        }
        await putOrgStack(tx, updated)
    }
}

async function updateUserStack(tx: WriteTransaction, id: string, updater: UserStackUpdater) {
    const userStack = await getUserStack(tx, id)
    const updatedStack = {
        id,
        __typename: 'UserStack',
        ...updater(userStack),
        version: (userStack?.version || 0) + 1,
    }
    if (userStack) {
        return putUserStack(tx, updatedStack)
    } else {
        const orgStack = await getOrgStack(tx, id)
        if (orgStack) {
            // only save partial userStack, if there is an orgstack
            await putUserStack(tx, updatedStack)
        }
    }
}

function getUserKey(id: string) {
    return [Prefix.StackUser, id].join('/')
}
function getOrgKey(id: string) {
    return [Prefix.StackOrg, id].join('/')
}

function getOptimistic() {
    return {
        modifiedDate: new Date().toISOString(),
    }
}

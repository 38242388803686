import { useState } from 'react'
import { ulid } from 'ulid'
import { Link } from 'react-router-dom'

import {
    getMyAccountsStack,
    getMyLeadsStack,
    getMyContactsStack,
    getMyOpportunityStack,
    Stack,
    StackInput,
} from '@laserfocus/shared/models'
import { mutateStack } from '@laserfocus/client/data-access-shared'
import { StackModel } from '@laserfocus/client/model'
import { Button, Card, SpinnerInline } from '@laserfocus/ui/beam'
import { AddOutlinedIcon, ArrowRight2OutlinedIcon } from '@laserfocus/ui/icons'

import { SObjectAvatar } from './StackModalCards'
import { useCreateStackContext } from './CreateStackContext'

type StackSobject = 'Lead' | 'Account' | 'Opportunity' | 'Contact'
type CreatedStack = {
    id: string
    sobject: StackSobject
}
export function CreateStackBody({ stacks }: { stacks: Stack[] }) {
    const { type, makePath, extension } = useCreateStackContext()
    const [created, setCreated] = useState<Array<CreatedStack>>([])

    const stacksById = Object.fromEntries(stacks.map((a) => [a.id, a]))

    const sobjects: StackSobject[] = ['Lead', 'Opportunity', 'Account', 'Contact']

    function createStack(sobject: StackSobject) {
        const id = ulid()
        setCreated((prev) => [
            ...prev,
            {
                id,
                sobject,
            },
        ])
        switch (sobject) {
            case 'Account':
                const accountInput: StackInput = {
                    ...getMyAccountsStack(),
                    title: `Unnamed Account Stack`,
                }
                return mutateStack.createStack(accountInput, { type, id }, extension)
            case 'Lead':
                const leadInput: StackInput = {
                    ...getMyLeadsStack(),
                    title: `Unnamed Lead Stack`,
                }
                return mutateStack.createStack(leadInput, { type, id }, extension)
            case 'Contact':
                const contactInput: StackInput = {
                    ...getMyContactsStack(),
                    title: `Unnamed Contact Stack`,
                }
                return mutateStack.createStack(contactInput, { type, id }, extension)
            case 'Opportunity':
                const opportunityInput: StackInput = {
                    ...getMyOpportunityStack(),
                    title: `Unnamed Opportunity Stack`,
                }
                return mutateStack.createStack(opportunityInput, { type, id }, extension)
        }
    }

    return (
        <div className="px-8 py-6 min-h-[21.75rem] max-h-[50vh] overflow-y-auto ">
            <div className="grid gap-2 w-full">
                {sobjects.map((sobject) => {
                    const createdItem = created.find((a) => a.sobject === sobject)
                    const isCreating = Boolean(createdItem)
                    const createdId = createdItem?.id
                    const createdSlug: string | null =
                        createdId && stacksById[createdId]
                            ? StackModel.getSlug(stacksById[createdId])
                            : null

                    return (
                        <Card className="px-4 py-3 w-full" key={sobject}>
                            <div className="grid grid-flow-col grid-cols-[auto,1fr,auto]  items-center">
                                <div>
                                    <SObjectAvatar sobject={sobject} />
                                </div>
                                <div className="flex-1 ml-3 font-semibold">{sobject}</div>
                                <div>
                                    {createdSlug ? (
                                        <Button
                                            as={Link}
                                            to={makePath(createdSlug)}
                                            iconComponent={ArrowRight2OutlinedIcon}
                                            iconPosition="right"
                                        >
                                            {type === 'user' ? 'Go to Stack' : 'Configure Stack'}
                                        </Button>
                                    ) : (
                                        <Button
                                            iconComponent={
                                                isCreating ? SpinnerInline : AddOutlinedIcon
                                            }
                                            onClick={() => createStack(sobject)}
                                            disabled={isCreating}
                                        >
                                            Add
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Card>
                    )
                })}
            </div>
        </div>
    )
}

/* eslint-disable no-console */

export type LevelLabel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal'
type ConsoleFallback = 'log' | 'error'
type ConsoleLevels = 'trace' | 'debug' | 'info' | 'warn' | 'error'

type LoggerOptions = {
    level?: LevelLabel
    transmit?: Transmit
    transform?: (...msg: any[]) => any[]
}

type ChildLoggerOptions = {
    name?: string
    level?: LevelLabel
}

type Transmit = {
    level: LevelLabel
    send: (level: LevelLabel, ...msg: any[]) => void
}

const defaultOptions = {
    level: 'info',
} as const

export class Logger {
    level: LevelLabel
    trace!: (...msg: any[]) => void
    debug!: (...msg: any[]) => void
    info!: (...msg: any[]) => void
    warn!: (...msg: any[]) => void
    error!: (...msg: any[]) => void
    fatal!: (...msg: any[]) => void

    constructor(options: LoggerOptions = {}) {
        this.level = options.level || defaultOptions.level

        function makeLogger(logger: Logger, level: LevelLabel, fallback: ConsoleFallback) {
            const loggerLevelValue = logger.levelValue
            const currentValue = levelMapping[level]
            if (loggerLevelValue > currentValue) {
                logger[level] = noop
                return
            }
            const log = hasLevel(level) ? console[level] : console[fallback]
            let transmitSend = noop
            if (options.transmit) {
                const transmitLevelValue = levelMapping[options.transmit.level || 'error']
                if (transmitLevelValue <= currentValue) {
                    transmitSend = (...msg: any[]) => options.transmit?.send(level, ...msg)
                }
            }
            function logFunction(...msg: any[]) {
                const transformed = options.transform ? options.transform(...msg) : msg
                log!(...transformed)
                transmitSend(...transformed)
            }
            logger[level] = logFunction
        }
        makeLogger(this, 'trace', 'log')
        makeLogger(this, 'debug', 'log')
        makeLogger(this, 'info', 'log')
        makeLogger(this, 'warn', 'log')
        makeLogger(this, 'error', 'log')
        makeLogger(this, 'fatal', 'error')
    }

    get levelValue() {
        const val = levelMapping[this.level]
        return val
    }

    child(options: ChildLoggerOptions = {}) {
        const { name, level } = options
        const finalOptions: LoggerOptions = {
            level: level || this.level,
        }
        if (name) {
            finalOptions.transform = (...msg: any[]) => [name, ...msg]
        }
        return new Logger(finalOptions)
    }
}

function hasLevel(level: LevelLabel): level is ConsoleLevels {
    return Boolean((console as any)[level])
}

export const levelMapping: Record<LevelLabel, number> = {
    trace: 10,
    debug: 20,
    info: 30,
    warn: 40,
    error: 50,
    fatal: 60,
}
function noop(args?: any) {}

import { action, computed, observable, toJS } from 'mobx'
import { isEqual } from 'lodash'
import PQueue from 'p-queue'

import { assert } from '@laserfocus/shared/util-error'
import {
    isContact,
    isLead,
    isOpportunity,
    SnapshotListener,
    SnapShotter,
} from '@laserfocus/shared/models'

export const queue = new PQueue({ concurrency: 1 })

export type StoredModel = {
    Id: string
    __typename: string
}
type LowerCaseProps = {
    id: string
    __typename: string
}
export type ModelProps = StoredModel | LowerCaseProps

interface ModelConstructor<PropTypes extends ModelProps = ModelProps> {
    new (props: PropTypes, pool: ObjectPool): Model | StoredModel
}

export interface ModelExtension {
    Id: string
    onSnapshot<Snapshot>(
        makeSnapshot: SnapShotter<Model, Snapshot>,
        listener: SnapshotListener<Snapshot>,
        options?: {
            fireImmediately?: boolean
        }
    ): () => void
    props: StoredModel
}

export type Model = StoredModel & ModelExtension

type DeleteListener<T extends ModelProps> = (props: T) => void
type CreateListener<T extends StoredModel> = (props: T) => void
type UpdateListener<M extends StoredModel = StoredModel> = (
    current: M,
    previous: M,
    changeSet: Partial<M>
) => void

export class ObjectPool {
    @observable data: Map<string, Map<string, StoredModel | Model>> = new Map()

    @observable models = new Map<string, ModelConstructor<any>>()

    updateListener: Map<string, Set<UpdateListener<StoredModel>>> = new Map()
    createListener: Map<string, Set<CreateListener<StoredModel>>> = new Map()
    deleteListener: Map<string, Set<DeleteListener<ModelProps>>> = new Map()

    /**
     * These are to globally track loading progress. (shared between RelationshipLoaders)
     */
    @observable loadingIds = new Map<string, Set<string>>()
    @observable notInReplicacheIds = new Map<string, Set<string>>()

    registerModel<PropTypes extends ModelProps>(name: string, ctor: ModelConstructor<PropTypes>) {
        this.models.set(name, ctor)
    }

    attachAll<Props extends ModelProps>(props: Props[], priority = 10) {
        return queue.addAll(props.map((p) => () => this.attach(p), { priority }))
    }

    @action
    attach<Props extends ModelProps>(props: Props) {
        const id = getId(props)
        assert(id, 'Models does not have an Id')
        assert(props.__typename, 'Models does not have  atypename')

        const previous = this.data.get(props.__typename)?.get(id)
        if (previous) {
            const hasListener = this.updateListener.get(props.__typename)?.size
            if (hasListener) {
                const previousProps: StoredModel = isModel(previous)
                    ? toJS(previous.props)
                    : toJS(previous)
                const changeSet = applyUpdate(previous, props)
                this.notifyUpdated(previous, previousProps, changeSet)
            } else {
                applyUpdate(previous, props)
            }
        } else {
            const ctor = this.models.get(props.__typename)
            const model = ctor ? new ctor(props, this as any) : toStored(props)
            if (!this.data.has(props.__typename)) {
                this.data.set(props.__typename, new Map())
            }
            this.data.get(props.__typename)!.set(id, model)
            this.notifyCreated(model)
        }
    }

    @action
    detach(props: ModelProps) {
        this.data.get(props.__typename)?.delete(getId(props))
    }

    @action
    detachAllOfType(type: string) {
        this.data.set(type, new Map())
    }

    get<M extends ModelProps = ModelProps>(objectType: string, id: string) {
        return this.data.get(objectType)?.get(id) as M
    }

    getSingle<M extends ModelProps = ModelProps>(type: string): M | undefined {
        const all = this.getAll<M>(type)
        assert(all.size <= 1, `Found more than 1 of ${type}`)
        return Array.from(all.values())[0]
    }

    getAll<M extends ModelProps = ModelProps>(objectType: string) {
        const valueMap = this.data.get(objectType) || new Map()
        return valueMap as Map<string, M>
    }

    onUpdated<M extends StoredModel = StoredModel>(
        objectType: string,
        listener: UpdateListener<M>
    ) {
        if (!this.updateListener.has(objectType)) {
            this.updateListener.set(objectType, new Set())
        }
        this.updateListener.get(objectType)?.add(listener as any)
        return () => {
            this.updateListener.get(objectType)?.delete(listener as any)
        }
    }

    onCreated<M extends StoredModel = StoredModel>(
        objectType: string,
        listener: CreateListener<M>
    ) {
        if (!this.createListener.has(objectType)) {
            this.createListener.set(objectType, new Set())
        }
        this.createListener.get(objectType)?.add(listener as any)
        return () => {
            this.createListener.get(objectType)?.delete(listener as any)
        }
    }

    onDeleted<M extends ModelProps = ModelProps>(objectType: string, listener: DeleteListener<M>) {
        if (!this.deleteListener.has(objectType)) {
            this.deleteListener.set(objectType, new Set())
        }
        this.deleteListener.get(objectType)?.add(listener as any)
        return () => {
            this.deleteListener.get(objectType)?.delete(listener as any)
        }
    }

    notifyUpdated(
        current: StoredModel | Model,
        previous: StoredModel,
        changed: Partial<ModelProps>
    ) {
        const listeners = this.updateListener.get(current.__typename)
        assert(listeners, `Calling notify for ${current.__typename} without having a listener`)
        listeners?.forEach((fn) => fn(current, previous, changed))
    }

    notifyCreated(model: StoredModel) {
        const listeners = this.createListener.get(model.__typename)
        listeners?.forEach((fn) => fn(model))
    }

    notifyDeleted(model: ModelProps) {
        const listeners = this.deleteListener.get(model.__typename)
        listeners?.forEach((fn) => fn(model))
    }

    // Going to start adding indexes here in order to make the replicache migration easier
    @computed
    get activitiesByOpp() {
        const byOppId: Record<string, Record<string, ModelProps>> = {}

        const activityObjects = ['Task', 'Event']

        for (const activityObject of activityObjects) {
            for (const activity of this.getAll(activityObject).values()) {
                const whatId = (activity as any).WhatId
                if (whatId && isOpportunity(whatId)) {
                    if (!byOppId[whatId]) {
                        byOppId[whatId] = {}
                    }
                    byOppId[whatId][getId(activity)] = activity
                }
            }
        }
        return byOppId
    }

    @computed
    get activitiesByLead() {
        const byLeadId: Record<string, Record<string, ModelProps>> = {}

        const activityObjects = ['Task', 'Event']

        for (const activityObject of activityObjects) {
            for (const activity of this.getAll(activityObject).values()) {
                const whoId = (activity as any).WhoId
                if (whoId && isLead(whoId)) {
                    if (!byLeadId[whoId]) {
                        byLeadId[whoId] = {}
                    }
                    byLeadId[whoId][getId(activity)] = activity
                }
            }
        }
        return byLeadId
    }

    @computed
    get activitiesByAccount() {
        const byAccountId: Record<string, Record<string, ModelProps>> = {}

        const activityObjects = ['Task', 'Event']

        for (const activityObject of activityObjects) {
            for (const activity of this.getAll(activityObject).values()) {
                const accountId = (activity as any).AccountId
                if (accountId) {
                    if (!byAccountId[accountId]) {
                        byAccountId[accountId] = {}
                    }
                    byAccountId[accountId][getId(activity)] = activity
                }
            }
        }
        return byAccountId
    }

    @computed
    get activitiesByContact() {
        const byContactId: Record<string, Record<string, ModelProps>> = {}

        const activityObjects = ['Task', 'Event']

        for (const activityObject of activityObjects) {
            for (const activity of this.getAll(activityObject).values()) {
                const whoId = (activity as any).WhoId
                if (whoId && isContact(whoId)) {
                    if (!byContactId[whoId]) {
                        byContactId[whoId] = {}
                    }
                    byContactId[whoId][getId(activity)] = activity
                }
            }
        }
        return byContactId
    }
}

export function applyUpdate<M extends ModelProps>(model: M, next: Partial<M>) {
    const changed: Record<string, any> = {}

    for (const property in next) {
        if (property === 'attributes') {
            continue
        }
        const previousValue = model[property]
        const nextValue = next[property]
        if (Array.isArray(nextValue)) {
            if (!isEqual(previousValue, nextValue)) {
                changed[property] = nextValue
                model[property] = nextValue as any
            }
        } else if (nextValue !== previousValue) {
            changed[property] = nextValue
            model[property] = nextValue as any
        }
    }

    return changed
}

function isModel(model: ModelProps | Model): model is Model {
    return typeof (model as Model).onSnapshot !== 'undefined'
}

export function getId(props: ModelProps): string {
    return (props as any).Id || (props as any).id
}

function isLowercaseProps(props: ModelProps): props is LowerCaseProps {
    return Boolean((props as LowerCaseProps).id)
}

function toStored(props: ModelProps): StoredModel {
    if (isLowercaseProps(props)) {
        return {
            Id: props.id,
            ...props,
        }
    }
    return props
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function CheckFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M12 21.6l-.012-.001c5.292 0 9.6-4.308 9.6-9.6 0-5.304-4.308-9.6-9.6-9.612h-.012a9.597 9.597 0 00-9.6 9.6c0 5.292 4.296 9.6 9.6 9.588L12 21.6zm4.44-11.152v-.012a1.21 1.21 0 00-.036-1.704 1.188 1.188 0 00-1.668 0l-3.96 3.948-1.56-1.56a1.199 1.199 0 00-1.704.024A1.184 1.184 0 007.5 12.8l2.4 2.4-.012-.012a1.195 1.195 0 001.692 0l4.8-4.8.06.06z"
            />
        </SvgIcon>
    )
}

import { z } from '@laserfocus/shared/decoder'

import { DateString, DateTimeString, DateTimeStringSchema } from '../base/date.types'
import { OrgId, UserId } from '../base/id.types'
import { ActivityMutatorSchema } from '../sales/activity-mutations-schema'
import { NotesMutatorSchema } from '../sales/notes.types'
import { SObjectMutatorSchema } from '../sales/sobject-mutations-schema'
import { StackMutatorSchema } from '../stack/stack-mutation.types'

import {
    everboardingHintNameSchema,
    everboardingTrackNameSchema,
    EverboardingHintName,
    EverboardingTrackName,
} from './everboarding.types'

export type ActivityCounterItem = {
    userId: UserId
    orgId: OrgId
    date: DateString
    lastModifiedDate: DateTimeString
    // Counter strings are now just the mutation names
    counters: Record<string, number>
}

export type CountedMutationName =
    | 'record_viewed_overlay'
    | 'record_viewed_full'
    | keyof typeof ActivityMutatorSchema
    | Exclude<keyof typeof SObjectMutatorSchema, 'discardFailedMutation'>
    | keyof typeof NotesMutatorSchema
    | keyof typeof StackMutatorSchema
    | EverboardingHintName
    | EverboardingTrackName

const stackmutatorNames: Array<keyof typeof StackMutatorSchema> = Object.keys(
    StackMutatorSchema
) as Array<keyof typeof StackMutatorSchema>

const countActivityNames = z
    .enum(['record_viewed_overlay', 'record_viewed_full', ...stackmutatorNames])
    .or(everboardingHintNameSchema)
    .or(everboardingTrackNameSchema)

export const ActivityCounterMutatorSchema = {
    countActivity: z.object({
        name: countActivityNames,
        date: DateTimeStringSchema,
    }),
}

export const COUNTER_IGNORED_MUTATIONS = [
    'visitRoot',
    'prefetchRoots',
    'discardFailedMutation',
    'countActivity',
]

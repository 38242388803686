import { useState, useEffect, SetStateAction, Dispatch } from 'react'

export function usePersistedState<T>(
    key: string,
    fallbackInitialState?: T,
    parse?: (value: string) => T
): [T, Dispatch<SetStateAction<T>>] {
    const finalKey = `lf:${key}`
    const [state, setState] = useState<T>(() => {
        const storedInitial = localStorage.getItem(finalKey)
        if (storedInitial) {
            return parse ? parse(storedInitial) : (storedInitial as unknown as T)
        }
        return fallbackInitialState
    })
    useEffect(() => localStorage.setItem(finalKey, `${state}`), [finalKey, state])

    return [state, setState]
}

import { useActivitiesMetadataContext } from '@laserfocus/client/data-access-shared'
import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'

import { ActivityForm } from '../Activity/ActivityForm'

import useLogContactActivity from './modules/useLogActivityMutation'

interface LogActivityProps {
    rootId: string
}

export function LogActivity({ rootId }: LogActivityProps) {
    const logActivity = useLogContactActivity()
    const metadata = useActivitiesMetadataContext()
    const { location } = useAnalyticsContext()

    return (
        <ActivityForm
            localStorageCacheKey={rootId}
            onSubmit={async ({ subject, description, type }) => {
                await logActivity({
                    subject,
                    description,
                    type,
                })
                Analytics.trackEvent({
                    event: 'activity_logged',
                    location: location || ('person_details' as const),
                })
            }}
            defaultType={metadata?.taskTypeDefaultValue}
            defaultPriority={metadata?.taskPriorityDefaultValue}
            noPriority
        />
    )
}

import { twMerge } from 'tailwind-merge'

import { TextPrimarySmall } from '../typography/texts'

export interface DividerProps {
    children?: React.ReactNode
    className?: string
}

export function Divider({ className, children }: DividerProps) {
    return (
        <div
            className={twMerge(
                'grid gap-3 items-center grid-cols-[1fr,auto,1fr] before:h-px before:bg-grey-700/10 after:h-px after:bg-grey-700/10',
                className
            )}
        >
            <TextPrimarySmall>{children}</TextPrimarySmall>
        </div>
    )
}

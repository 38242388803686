import 'reflect-metadata'
import type { Replicache } from 'replicache'
import { logger } from '@sentry/utils'

import type { ObjectPool, QueryStatus } from '@laserfocus/client/data-layer'
import type { User } from '@laserfocus/shared/models'
import { Prefix } from '@laserfocus/shared/models'

export type Dependencies = {
    objectPool: ObjectPool
    replicache: Replicache
    queryStatus: QueryStatus
}

export class BridgeUserStore {
    objectPool: ObjectPool
    replicache: Replicache
    queryStatus: QueryStatus

    reactions: Array<() => void> = []

    constructor({ objectPool, replicache, queryStatus }: Dependencies) {
        this.objectPool = objectPool
        this.replicache = replicache
        this.queryStatus = queryStatus
    }

    onInit() {
        this.queryUsersOnce()
        this.queryGroupsOnce()
    }

    onDestroy() {
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    async queryUsersOnce() {
        if (this.queryStatus.getStatus('users') === 'none') {
            try {
                this.queryStatus.startOnce('users')
                const users = await this.replicache.query(
                    (tx) =>
                        tx
                            .scan({ prefix: `${Prefix.User}/` })
                            .values()
                            .toArray() as Promise<User[]>
                )

                await this.objectPool.attachAll(users, 25)
                this.queryStatus.finish('groups')
            } catch (e: any) {
                logger.error(e)
                this.queryStatus.reset('users')
            }
        }
    }
    async queryGroupsOnce() {
        if (this.queryStatus.getStatus('groups') === 'none') {
            try {
                this.queryStatus.startOnce('groups')
                const groups = await this.replicache.query(
                    (tx) =>
                        tx
                            .scan({ prefix: `${Prefix.Group}/` })
                            .values()
                            .toArray() as Promise<User[]>
                )

                groups.forEach((group) => this.objectPool.attach(group))
                this.queryStatus.finish('groups')
            } catch (e: any) {
                logger.error(e)
                this.queryStatus.reset('groups')
            }
        }
    }
}

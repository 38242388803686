import { useLayoutEffect, useRef } from 'react'

interface InViewProps extends React.ComponentPropsWithoutRef<'div'> {
    root?: Root
    rootMargin?: string
    threshold?: number | number[]
    onIntersect(entry: IntersectionObserverEntry): void
}

type Root = React.RefObject<Element> | ExtractKeysOfValueType<HTMLElement, Element | null> | null
type ExtractKeysOfValueType<T, K> = { [I in keyof T]: T[I] extends K ? I : never }[keyof T]

export function InView({ root, rootMargin, threshold, onIntersect, ...props }: InViewProps) {
    const onIntersectRef = useRef(onIntersect)
    onIntersectRef.current = onIntersect

    const nodeRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const node = nodeRef.current

        if (!node) {
            return
        }

        let isFirstCallback = true

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (isFirstCallback) {
                    isFirstCallback = false

                    const { x, y, width, height } = entry.boundingClientRect

                    if (!x && !y && !width && !height) {
                        // Forces callback to be called again
                        observer.unobserve(node)
                        observer.observe(node)
                        return
                    }
                }

                return onIntersectRef.current(entry)
            },
            {
                root: getRootElement(root, node),
                rootMargin,
                threshold,
            }
        )

        observer.observe(node)
        return () => observer.unobserve(node)
    }, [root, rootMargin, threshold])

    return <div ref={nodeRef} {...props} />
}

function getRootElement(root: Root | undefined, node: HTMLElement) {
    if (typeof root === 'string') {
        return node[root]
    }
    return root?.current
}

import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema, MonthStringSchema } from '../base/date.types'
import { OrgIdSchema, UserIdSchema } from '../base/id.types'

export const QuotaSchema = z.object({
    userId: UserIdSchema,
    orgId: OrgIdSchema,
    amount: z.number().nonnegative(),
    date: MonthStringSchema,
})

export const QuotaRecordSchema = QuotaSchema.extend({
    createdDate: DateTimeStringSchema,
    modifiedDate: DateTimeStringSchema,
    __typename: z.literal('quota'),
})

export type Quota = z.infer<typeof QuotaSchema>
export type QuotaRecord = z.infer<typeof QuotaRecordSchema>

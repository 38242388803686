import { useAccount, useCurrentContact, useLead } from '@laserfocus/client/feature-person-details'
import { LeadModel, isLead, AccountModel, ContactModel } from '@laserfocus/shared/models'
import {
    AccountContainer,
    LeadContainer,
    PersonIcon,
} from '@laserfocus/client/feature-person-details'

interface OverlayDetailsProps {
    rootId: string
    overlayKey: string
    isInTable: boolean
}

export function OverlayDetails({ rootId, overlayKey, isInTable }: OverlayDetailsProps) {
    const account = useAccount()
    const lead = useLead()
    const currentContact = useCurrentContact()

    return (
        <OverlayDetailsInner
            // Necessary to reset state of `useNewNote` hook
            key={overlayKey}
            account={account ?? undefined}
            lead={lead ?? undefined}
            contact={currentContact}
            rootId={rootId}
            isInTable={isInTable}
        />
    )
}

interface OverlayDetailsInnerProps {
    account?: AccountModel
    lead?: LeadModel
    contact?: ContactModel
    rootId: string
    isInTable: boolean
}

function OverlayDetailsInner({
    account,
    lead,
    contact,
    rootId,
    isInTable,
}: OverlayDetailsInnerProps) {
    const title = account?.Name || lead?.Company || (lead || contact)?.Name

    return (
        <>
            <div className="px-2 grid grid-flow-col gap-1 grid-cols-[auto,minmax(0,1fr)] items-start">
                <PersonIcon id={rootId} />
                {title && (
                    <h1 className="font-serif text-[2rem] leading-[1.2] pt-[0.1875rem]">{title}</h1>
                )}
            </div>
            <div className="px-2">
                {isLead(rootId) ? (
                    <LeadContainer personId={rootId as any} isInOverlay isInTable={isInTable} />
                ) : (
                    <AccountContainer accountId={rootId} isInOverlay isInTable={isInTable} />
                )}
            </div>
        </>
    )
}

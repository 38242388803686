import { FilterMapper, SObjectType } from '@laserfocus/shared/models'
import { apolloClient, getQueryError } from '@laserfocus/client/data-access-apollo'
import {
    CONTACTS_BY_FILTER_QUERY,
    ACCOUNTS_BY_FILTER_QUERY,
    LEADS_BY_FILTER_QUERY,
    OPPORTUNITIES_BY_FILTER_QUERY,
    GET_MORE_BY_CURSOR,
} from '@laserfocus/shared/data-access-gql'
import type {
    AccountDTO,
    ContactDTO,
    LeadDTO,
    OpportunityDTO,
    FilterQueryResult,
    FilterCondition,
} from '@laserfocus/shared/models'
import { logger } from '@laserfocus/ui/logger'

export type FetchResult =
    | FilterQueryResult<LeadDTO>
    | FilterQueryResult<AccountDTO>
    | FilterQueryResult<ContactDTO>
    | FilterQueryResult<OpportunityDTO>

const EmptyResponse = {
    done: true,
    totalSize: 0,
    records: [],
}
export class FocusDataRepository {
    async fetchData(sobject: SObjectType, conditions: FilterCondition[]): Promise<FetchResult> {
        switch (sobject) {
            case 'Lead':
                return this.fetchLeads(conditions)
            case 'Account':
                return this.fetchAccounts(conditions)
            case 'Contact':
                return this.fetchContacts(conditions)
            case 'Opportunity':
                return this.fetchOpportunities(conditions)
            default:
                logger.warn(`${sobject} is not supported for the filter`)
                return EmptyResponse
        }
    }

    async fetchLeads(conditions: FilterCondition[]): Promise<FilterQueryResult<LeadDTO>> {
        const filter = FilterMapper.serializeFilter({
            sobject: 'Lead',
            conditions,
        })
        const res = await apolloClient.query({
            query: LEADS_BY_FILTER_QUERY,
            variables: {
                filter,
            },
        })
        if (res.errors) {
            throw new Error(getQueryError(res.errors))
        }

        const rawLeads = res.data?.getLeadsByFilter || EmptyResponse
        return rawLeads
    }

    async fetchAccounts(conditions: FilterCondition[]): Promise<FilterQueryResult<AccountDTO>> {
        const filter = FilterMapper.serializeFilter({
            sobject: 'Account',
            conditions,
        })
        const res = await apolloClient.query({
            query: ACCOUNTS_BY_FILTER_QUERY,
            variables: {
                filter,
            },
        })
        if (res.errors) {
            throw new Error(getQueryError(res.errors))
        }

        const rawAccounts = res.data?.getAccountsByFilter || EmptyResponse
        return rawAccounts
    }

    async fetchOpportunities(
        conditions: FilterCondition[]
    ): Promise<FilterQueryResult<OpportunityDTO>> {
        const filter = FilterMapper.serializeFilter({
            sobject: 'Opportunity',
            conditions,
        })
        const res = await apolloClient.query({
            query: OPPORTUNITIES_BY_FILTER_QUERY,
            variables: {
                filter,
            },
        })
        if (res.errors) {
            throw new Error(getQueryError(res.errors))
        }
        const rawOpps = res.data?.getOpportunitiesByFilter || EmptyResponse
        return rawOpps
    }

    async fetchContacts(conditions: FilterCondition[]): Promise<FilterQueryResult<ContactDTO>> {
        const filter = FilterMapper.serializeFilter({
            sobject: 'Contact',
            conditions,
        })
        const res = await apolloClient.query({
            query: CONTACTS_BY_FILTER_QUERY,
            variables: {
                filter,
            },
        })
        if (res.errors) {
            throw new Error(getQueryError(res.errors))
        }

        const rawContacts = res.data?.getContactsByFilter || EmptyResponse
        return rawContacts
    }

    async fetchMore(
        sobject: 'Lead' | 'Account' | 'Opportunity' | 'Contact',
        cursor: string
    ): Promise<FetchResult> {
        const res = await apolloClient.query({
            query: GET_MORE_BY_CURSOR,
            variables: {
                sobject,
                cursor,
            },
        })
        if (res.errors) {
            throw new Error(getQueryError(res.errors))
        }
        const result = res.data?.getMoreByCursor || EmptyResponse
        return result
    }
}

import { sortBy } from 'lodash'
import { matchSorter } from 'match-sorter'
import { useState } from 'react'
import { ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { ListView, Stack } from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { mutateStack } from '@laserfocus/client/data-access-shared'
import { Button, Card, Modal, SpinnerInline } from '@laserfocus/ui/beam'
import { makeStackPath } from '@laserfocus/client/util-routing'
import {
    AddOutlinedIcon,
    ArrowRight2OutlinedIcon,
    ExclamationOutlinedIcon,
} from '@laserfocus/ui/icons'
import { logger } from '@laserfocus/ui/logger'
import { StackModel } from '@laserfocus/client/model'

import { Avatar, LoadingCards, SObjectAvatar } from './StackModalCards'

type CreatedStackFromView = {
    viewId: string
    stackId: string
}

export function ImportViewBody({ stacks }: { stacks: Stack[] }) {
    const [searchValue, setSearchValue] = useState('')
    const searchInputRef = useAutoFocusedRef<HTMLInputElement>(true)
    const [createdStacksFromViews, setCreatedStacksFromViews] = useState<CreatedStackFromView[]>([])
    const creatingStacksFromView = useSubscribe(
        getClient(),
        (tx) =>
            tx
                .scan({
                    prefix: 'process/createviewfromstack/',
                })
                .values()
                .toArray() as Promise<Array<{ viewId: string; stackId: string }>>,
        [],
        []
    )

    const stacksById = Object.fromEntries(stacks.map((a) => [a.id, a]))
    const loadingByViewId = Object.fromEntries(
        creatingStacksFromView.filter((a) => !stacksById[a.stackId]).map((a) => [a.viewId, true])
    )

    const createdByViewId = Object.fromEntries(
        createdStacksFromViews
            .filter((a) => stacksById[a.stackId])
            .map((a) => [a.viewId, stacksById[a.stackId]])
    )

    const { isLoading, listViews } = useSalesforceViews()

    const filteredOptions = searchValue
        ? matchSorter(listViews, searchValue, {
              keys: ['Name', 'DeveloperName', 'SobjectType'],
          })
        : sortBy(listViews, 'LastViewedDate')

    return (
        <>
            <Modal.SearchInput
                ref={searchInputRef}
                placeholder="Search for view"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                reset={() => setSearchValue('')}
                className="mt-4 mb-4"
            />
            <div className="border-t border-grey-700/20" />

            <div className="px-8 py-6 min-h-[21.75rem] max-h-[50vh] overflow-y-auto ">
                <div className="grid gap-2 w-full">
                    {isLoading || listViews.length === 0 ? (
                        <LoadingCards />
                    ) : (
                        filteredOptions.map((view: ListView, index) => {
                            const loading = loadingByViewId[view.Id]
                            const createdStack = createdByViewId[view.Id]
                            return (
                                <ListViewCard
                                    key={view.Id}
                                    name={view.Name}
                                    sobject={view.SobjectType}
                                    issues={view.issues}
                                    createStack={async () => {
                                        const stackId = await mutateStack.createStackFromView(
                                            view.SobjectType as 'Lead' | 'Account' | 'Opportunity',
                                            view.Id
                                        )
                                        setCreatedStacksFromViews((prev) => [
                                            ...prev,
                                            { stackId, viewId: view.Id },
                                        ])
                                    }}
                                    isLoading={loading}
                                    createdStackSlug={
                                        createdStack ? StackModel.getSlug(createdStack) : undefined
                                    }
                                />
                            )
                        })
                    )}
                </div>
            </div>
        </>
    )
}

type SalesforceViewSubscription = {
    isLoading: boolean
    listViews: any[]
}

function useSalesforceViews(): SalesforceViewSubscription {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction): Promise<SalesforceViewSubscription> => {
            const listViews = await tx.scan({ prefix: 'listview' }).values().toArray()
            const relevantListViews = listViews.filter((a: any) =>
                ['Account', 'Opportunity', 'Lead'].includes(a.SobjectType)
            )
            return {
                isLoading: false,
                listViews: relevantListViews,
            }
        },
        {
            isLoading: true,
            listViews: [],
        },
        []
    )
}

export type ListViewCardProps = {
    sobject: 'Lead' | 'Account' | 'Opportunity' | string
    name: string
    isSelected?: boolean
    issues: { level: string }[]
    createStack: () => void
    isLoading?: boolean
    createdStackSlug?: string
}

function ListViewCard({
    name,
    sobject,
    isSelected,
    issues,
    createStack,
    isLoading,
    createdStackSlug,
}: ListViewCardProps) {
    const [showNotConvertableNote, setShowNotConvertableNote] = useState(false)
    const isConvertable =
        !issues.filter((i) => i.level === 'error').length &&
        ['Lead', 'Account', 'Opportunity'].includes(sobject)
    return (
        <Card
            className={clsx('px-4 py-3 w-full', {
                'ring-blue-500': isSelected,
            })}
        >
            <div className="grid grid-flow-col grid-cols-[auto,1fr,auto]  items-center">
                <div>
                    <SObjectAvatar sobject={sobject} />
                </div>
                <div className="flex-1 ml-3 font-semibold">
                    {sobject} - {name}
                </div>
                <div>
                    {createdStackSlug ? (
                        <Button
                            as={Link}
                            to={makeStackPath(createdStackSlug)}
                            iconComponent={ArrowRight2OutlinedIcon}
                            iconPosition="right"
                        >
                            Go to Stack
                        </Button>
                    ) : isConvertable ? (
                        <Button
                            iconComponent={isLoading ? SpinnerInline : AddOutlinedIcon}
                            onClick={createStack}
                            disabled={isLoading}
                        >
                            Add
                        </Button>
                    ) : (
                        <Button
                            iconComponent={ExclamationOutlinedIcon}
                            onClick={() => {
                                logger.error('Trying to add a View that is not supported', {
                                    sobject,
                                    name,
                                    issues,
                                })
                                setShowNotConvertableNote(true)
                            }}
                            disabled={isLoading}
                        >
                            Add
                        </Button>
                    )}
                </div>
            </div>
            {showNotConvertableNote && (
                <div className="text-red-500/80 mt-2 text-sm pl-14 font-medium">
                    This is a custom view and only available for Business accounts. Feel free to get
                    in contact with us via:{' '}
                    <a href="mailto:sales@laserfocus.io" className="font-semibold hover:underline">
                        sales@laserfocus.io
                    </a>
                </div>
            )}
        </Card>
    )
}

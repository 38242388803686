import { useParams } from 'react-router'

import { useBodyClassName } from '@laserfocus/ui/util-react'
import { useQuery } from '@laserfocus/client/util-routing'
import { EmptyState } from '@laserfocus/ui/beam'

export function ErrorPage() {
    const { errorCode } = useParams()
    const query = useQuery()
    const { description } = query

    useBodyClassName('transition-colors duration-[3s] bg-red-100')

    const parsedDescription = Array.isArray(description) ? description.join(' ') : description

    return (
        <div className="h-screen">
            <EmptyState
                variant="light"
                emojis={['🌋', '☁️', '💥']}
                title="Error from Salesforce"
                subtitle={<Subtitle code={errorCode} description={parsedDescription} />}
            />
        </div>
    )
}

function Subtitle({ code, description }: { code?: string; description?: string }) {
    if (!code && !description) {
        return null
    }
    return (
        <div>
            {code && (
                <>
                    <span className="font-semibold">{code}:</span>
                    <br />
                </>
            )}
            {description}
        </div>
    )
}

import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema } from '../base/date.types'
import { makeBaseRecordSchema } from '../base/singletable.types'
import { UserIdSchema } from '../base/id.types'

import { InviteSourceSchema } from './invites-schema'

export const LFUserSchema = z.object({
    userId: UserIdSchema,
    role: z.string().nullish(),
    signedUp: z.boolean().nullish(),
    isAdmin: z.boolean().nullish(),
})
export type LFUser = z.infer<typeof LFUserSchema>

export const UserUpdateSchema = LFUserSchema.omit({ userId: true })
export type UserUpdate = z.infer<typeof UserUpdateSchema>

export const RoleSchema = z.object({
    name: z.string(),
    label: z.string(),
})
export const RoleRecordSchema = RoleSchema.merge(makeBaseRecordSchema('role'))
export type RoleRecord = z.infer<typeof RoleRecordSchema>
export type Role = z.infer<typeof RoleSchema>
export const RoleUpdateSchema = RoleSchema.omit({ name: true })
export type RoleUpdate = z.infer<typeof RoleUpdateSchema>

export const UserMutatorSchema = {
    inviteColleague: z.object({
        values: z.object({
            invitees: z.array(UserIdSchema),
            source: InviteSourceSchema,
        }),
        optimistic: z.object({
            lastModifiedDate: DateTimeStringSchema.nullish(),
            inviter: UserIdSchema,
        }),
    }),
    updateUser: z.object({
        id: UserIdSchema,
        values: UserUpdateSchema,
        optimistic: z.object({
            modifiedDate: DateTimeStringSchema.nullish(),
        }),
    }),
    createRole: z.object({
        values: RoleSchema,
        optimistic: z.object({
            createdDate: DateTimeStringSchema.nullish(),
            modifiedDate: DateTimeStringSchema.nullish(),
        }),
    }),
    updateRole: z.object({
        name: z.string(),
        values: RoleUpdateSchema,
        optimistic: z.object({
            createdDate: DateTimeStringSchema.nullish(),
            modifiedDate: DateTimeStringSchema.nullish(),
        }),
    }),
    deleteRole: z.object({
        name: z.string(),
    }),
}

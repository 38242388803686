import { action, computed, extendObservable, reaction, runInAction } from 'mobx'
import { omit, sortBy } from 'lodash'

import { ModelExtension, ObjectPool } from '@laserfocus/client/data-layer'
import type {
    ContactFields,
    ContactProps,
    DateTimeString,
    SnapshotListener,
    SnapShotter,
} from '@laserfocus/shared/models'

import { getLastActivityDate, getNextActivity } from '../activity/activity-util'
import { TaskModel } from '../activity/TaskModel'
import { EventModel } from '../activity/EventModel'

const ContactPropKeys = new Set<string>()

export class ContactModel implements ContactFields, ModelExtension {
    objectPool: ObjectPool
    __typename = 'Contact' as const
    Id: string

    Name?: string
    FirstName?: string
    LastName?: string
    Title?: string
    Phone?: string
    HomePhone?: string
    MobilePhone?: string
    OtherPhone?: string
    AvatarUrl?: string
    Description?: string
    Department?: string
    Email?: string
    MailingStreet?: string
    MailingState?: string
    MailingPostalCode?: string
    MailingCity?: string
    MailingCountry?: string
    MailingGeocodeAccuracy?: string
    MailingLatitude?: number
    MailingLongitude?: number
    OtherStreet?: string
    OtherState?: string
    OtherPostalCode?: string
    OtherCity?: string
    OtherCountry?: string
    OtherGeocodeAccuracy?: string
    OtherLatitude?: number
    OtherLongitude?: number
    OwnerId?: string
    AccountId?: string
    CurrencyIsoCode?: string

    constructor(props: ContactProps, objectPool: ObjectPool) {
        this.objectPool = objectPool

        extendObservable(this, omit(props, 'LastActivityDate'))
        this._LastActivityDate = props.LastActivityDate
        this.Id = props.Id
        const keys = Object.keys(props)
        keys.forEach((key) => ContactPropKeys.add(key))
    }

    @computed get props(): ContactProps {
        const keys = Array.from(ContactPropKeys.values())
        const props = Object.fromEntries(keys.map((key) => [key, this[key as keyof ContactModel]]))
        return {
            Id: this.Id,
            __typename: 'Contact',
            ...props,
        }
    }

    _previousSnapshot: Map<number, any> = new Map()
    _snapshotCounter = 0

    @action
    onSnapshot<Snapshot>(
        makeSnapshot: SnapShotter<ContactModel, Snapshot>,
        listener: SnapshotListener<Snapshot>,
        options?: {
            fireImmediately?: boolean
        }
    ): () => void {
        const index = this._snapshotCounter++
        const previousSnapshot = makeSnapshot(this as any)
        this._previousSnapshot.set(index, previousSnapshot)
        const discard = reaction(
            () => makeSnapshot(this as any),
            (current) => {
                listener(this._previousSnapshot.get(index), current)
                runInAction(() => {
                    this._previousSnapshot.set(index, current)
                })
            },
            options
        )
        return () => {
            this._previousSnapshot.delete(index)
            discard()
        }
    }

    /**
     * ------------------ Activity API --------------------
     */
    _LastActivityDate?: DateTimeString | undefined

    @computed
    get Activities(): Array<TaskModel | EventModel> {
        const contactModel = this.objectPool.activitiesByContact[this.Id] || {}
        return Object.values(contactModel) as Array<TaskModel | EventModel>
    }

    @computed
    get OpenActivities() {
        const openActivitiees = this.Activities.filter((a) => a.isOpen)
        return sortBy(openActivitiees, 'OpenDate', 'CreatedDate').reverse()
    }

    @computed
    get ActivityHistory() {
        const activityHistory = this.Activities.filter((a) => !a.isOpen)
        return sortBy(activityHistory, 'ClosedDate', 'CreatedDate').reverse()
    }

    @computed
    get NextActivity() {
        return getNextActivity(this)
    }

    @computed
    get LastActivity() {
        return this.ActivityHistory[0]
    }

    @computed
    get LastActivityDate() {
        return getLastActivityDate(this)
    }

    set LastActivityDate(d) {
        this._LastActivityDate = d
    }

    /**
     * ------------------ Relationship --------------------
     */

    @computed
    get Account() {
        return this.AccountId ? this.objectPool.get('Account', this.AccountId) : undefined
    }
}

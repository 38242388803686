import { createContext, useContext } from 'react'

import { Emoji } from './get-emojis'

type WidthUpdater = (width: number) => number
interface EmojiDataContextValue {
    categories: EmojiDataCategory[]
    allEmojiRows: Emoji[][]
    onSubmit(emoji: string): void
    setWidth(updater: WidthUpdater): void
}

interface EmojiDataCategory {
    category: string
    emojiRows: Emoji[][]
    isEmpty: boolean
}

const EmojiDataContext = createContext<EmojiDataContextValue>(null!)

export const EmojiDataProvider = EmojiDataContext.Provider

export function useEmojiData() {
    return useContext(EmojiDataContext)
}

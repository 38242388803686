import gql from 'graphql-tag'

export const SEARCH_QUERY = gql`
    query search($searchTerm: String!, $sobject: String) {
        search(searchTerm: $searchTerm, sobject: $sobject) {
            Id
            Name
            Company
            AccountId
            AccountName
            SObjectType
            OwnerId
            IsClosed
        }
    }
`

import { mean, max, min, sum } from 'lodash'

import { Aggregation } from '@laserfocus/shared/models'

type Value = string | number | undefined | null | boolean | string[]
type Sobject = 'Opportunity' | 'Lead' | 'Contact' | 'Account'

export function aggregateValues(
    aggregation: Aggregation | undefined | null,
    values: Value[]
): number | undefined {
    switch (aggregation) {
        case 'average':
            return getAggregateNumber(values, mean)
        case 'count-all':
            return values.length
        case 'count-empty':
            return values.filter((a) => a === undefined || a === null).length
        case 'count-non-empty':
            return values.filter((a) => a !== undefined && a !== null).length
        case 'count-unique':
            return new Set([...values]).size
        case 'max':
            return getAggregateNumber(values, max)
        case 'min':
            return getAggregateNumber(values, min)
        case 'sum':
            return sum(values)
        case 'range':
            const rangeMaxV = getAggregateNumber(values, max)
            const rangeMinV = getAggregateNumber(values, min)
            if (rangeMaxV && rangeMinV) {
                return rangeMaxV - rangeMinV
            }
            return undefined
        case 'percent-empty':
            const emptyCount = values.filter((a) => a === undefined || a === null).length
            return emptyCount / values.length
        case 'percent-non-empty':
            const notEmptyCount = values.filter((a) => a !== undefined && a !== null).length
            return notEmptyCount / values.length
    }
}

const GENERAL_AGGREGATIONS: Record<string, Aggregation> = {
    'NextActivity.Subject': 'count-empty',
}

const DEFAULT_AGGREGATIONS_BY_OBJECT: Record<Sobject, Record<string, Aggregation>> = {
    Lead: {
        ...GENERAL_AGGREGATIONS,
        Company: 'count-all',
    },
    Opportunity: {
        ...GENERAL_AGGREGATIONS,
        'Account.Name': 'count-all',
        Amount: 'sum',
    },
    Account: {
        ...GENERAL_AGGREGATIONS,
        Name: 'count-all',
    },
    Contact: {
        ...GENERAL_AGGREGATIONS,
        'Account.Name': 'count-all',
    },
}

export function getFallbackAggregation(sobject: Sobject, fieldName: string) {
    return DEFAULT_AGGREGATIONS_BY_OBJECT[sobject][fieldName]
}

function getAggregateNumber<T = Value>(
    values: T[],
    compute: (values?: T[] | null) => T | undefined
) {
    const value = compute(values)
    if (typeof value === 'number') {
        return value
    }
    return undefined
}

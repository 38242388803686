import { useMemo } from 'react'
import { startOfWeek, getWeek, addDays } from 'date-fns'

// We use a fixed amount of weeks for the calendar so the datepicker doesn't change size while changing months. A month usually spans around 4 or 5 weeks but is guaranteed to span over a maximum of 6 weeks.
const WEEKS_IN_DATEPICKER_MONTH = 6
const DAYS_IN_WEEK = 7

export type Weekday = 0 | 1 | 2 | 3 | 4 | 5 | 6

interface UseMonthCalendarProps {
    year: number
    month: number
    firstDayOfWeek?: Weekday
}
interface UseMonthCalendarResult {
    weekdays: Weekday[]
    weeks: Week[]
}
interface Week {
    weekNumber: number
    days: Day[]
}
interface Day {
    date: Date
    month: number
    day: number
}

export function useMonthCalendar({
    year,
    month,
    firstDayOfWeek = 0,
}: UseMonthCalendarProps): UseMonthCalendarResult {
    return useMemo(
        () => ({
            weekdays: getWeekdays(firstDayOfWeek),
            weeks: getWeeks({ year, month, firstDayOfWeek }),
        }),
        [year, month, firstDayOfWeek]
    )
}

function getWeekdays(firstDayOfWeek: Weekday): Weekday[] {
    return Array.from(Array(DAYS_IN_WEEK).keys()).map(
        (index) => (firstDayOfWeek + index) % DAYS_IN_WEEK
    ) as Weekday[]
}

interface GetWeekdaysProps {
    year: number
    month: number
    firstDayOfWeek: Weekday
}

function getWeeks({ year, month, firstDayOfWeek: weekStartsOn }: GetWeekdaysProps): Week[] {
    /**
     * There are three main week numbering systems:
     * 1. ISO-8601 (used in EU and most of other European countries, most of Asia and Oceania): Week starts on Monday, first week contains January 4.
     * 2. Middle Eastern (used in much of the Middle East): Week starts on Saturday, first week contains January 1.
     * 3. Western traditional (used in Canada, United States, Japan, Taiwan, Thailand, Hong Kong, Macau, Israel, Egypt, South Africa, the Philippines, and most of Latin America): Week starts on Sunday, first week contains January 1.
     * @see https://wikipedia.org/wiki/Week#Week_numbering
     */
    const firstWeekContainsDate = weekStartsOn === 1 ? 4 : 1
    const firstWeekDate = startOfWeek(new Date(year, month, 1), { weekStartsOn })
    const week = Array.from(Array(DAYS_IN_WEEK).keys())

    return Array.from(Array(WEEKS_IN_DATEPICKER_MONTH).keys()).map((index) => {
        const firstDayOfWeek = addDays(firstWeekDate, index * 7)
        const weekNumber = getWeek(firstDayOfWeek, { weekStartsOn, firstWeekContainsDate })
        const days = week.map((index) => {
            const date = addDays(firstDayOfWeek, index)

            return {
                date,
                month: date.getMonth(),
                day: date.getDate(),
            }
        })

        return {
            weekNumber,
            days,
        }
    })
}

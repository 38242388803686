import { twMerge } from 'tailwind-merge'

import type { CardColor } from '../Cards/Card'

type CircleColor = CardColor | 'grey'

const colorClass: Record<CircleColor, string> = {
    yellow: 'text-yellow-400/80 hover:text-yellow-400',
    blue: 'text-blue-500/80 hover:text-blue-500',
    grey: 'text-grey-700/80 hover:text-grey-700',
    green: 'text-green-500/80 hover:text-green/500',
    red: 'text-red-500/80 hover:text-red-500',
}

type CircleProps = { percent: number; radius: number; thickness?: number; color: CircleColor }

export function PercentCircle({ percent, radius, thickness = 8, color = 'grey' }: CircleProps) {
    const circumference = radius * 2 * Math.PI
    const offset = circumference - (percent / 100) * circumference
    const size = radius * 2 + 2 * thickness
    const center = radius + thickness
    const fontSize = Math.round((radius - thickness) * 0.8)

    return (
        <div className={twMerge('relative m-auto overflow-hidden rounded-full', colorClass[color])}>
            <svg style={{ height: size, width: size }}>
                <circle
                    className="text-grey-700/20"
                    strokeWidth={thickness}
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx={center}
                    cy={center}
                />
                <circle
                    className="transition-[stroke-dashoffset] duration-500 xs"
                    strokeWidth={thickness}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={offset}
                    strokeLinecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx={center}
                    cy={center}
                />
            </svg>
            <span
                className="absolute inset-0 grid place-items-center text-grey-700 font-serif ml-1"
                style={{ fontSize }}
            >
                {Math.round(percent)}%
            </span>
        </div>
    )
}

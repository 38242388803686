import clsx from 'clsx'

import type { Address } from '@laserfocus/shared/models'

import { FormControlProps, SalesObject } from '../get-form-control'

import { FormControlString } from './FormControlString'

export function FormControlAddress<T extends SalesObject = SalesObject>({
    fieldMetadata,
    id,
    name,
    ...props
}: FormControlProps<T>) {
    const [prefixPath] = (name || fieldMetadata.name).split('Address')

    function getControlProps(suffix: keyof Address) {
        return {
            name: `${prefixPath}${suffix}`,
            id: `${id}-${suffix}`,
            fieldMetadata: fieldMetadata.addressChildMetadata[suffix] || fieldMetadata,
            ...props,
        }
    }

    const needsGap = props.variant === 'border'

    return (
        <div className={clsx(needsGap && 'grid gap-2')}>
            <FormControlString {...getControlProps('Street')} placeholder="Street" />
            <FormControlString {...getControlProps('PostalCode')} placeholder="Postal code" />

            <div className={clsx('grid grid-cols-[2fr,1fr]', needsGap && 'gap-2')}>
                <FormControlString {...getControlProps('City')} placeholder="City" />
                <FormControlString {...getControlProps('State')} placeholder="State" />
            </div>
            <FormControlString {...getControlProps('Country')} placeholder="Country" />
        </div>
    )
}

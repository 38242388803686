import { endOfYesterday } from 'date-fns'
import { computed } from 'mobx'
import { sortBy, uniqBy } from 'lodash'
import { ReadTransaction } from 'replicache'

import { PoolQuery } from '@laserfocus/client/data-layer'
import { TaskModel, EventModel } from '@laserfocus/client/model'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { Identity, NewTask } from '@laserfocus/shared/models'

import { ActivityView, Dependencies } from './ActivityView'

export type RootStoreInjection = {
    overdueView?: OverdueView
}

export function useOverdueView() {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.overdueView!
}

export class OverdueView extends ActivityView {
    eventQuery?: PoolQuery<EventModel>
    taskQuery: PoolQuery<TaskModel>

    constructor(deps: Dependencies) {
        super(deps)

        this.taskQuery = new PoolQuery(this.objectPool, {
            rootObject: 'Task',
            match(model, { userId }) {
                if (model.IsClosed) {
                    return false
                }
                const openDate = model.OpenDate
                if (openDate) {
                    return endOfYesterday() > openDate && model.OwnerId === userId
                }
                return false
            },
        })
    }

    _onMount(): void {
        this.queryOpenTasksOnce()
    }

    @computed
    get isLoading() {
        return this.queryStatus.getStatus('my-open-tasks') === 'running'
    }

    async queryOpenTasksOnce() {
        if (this.queryStatus.getStatus('my-open-tasks') === 'none') {
            this.queryStatus.startOnce('my-open-tasks')
            const taskResult = await this.replicache.query(async (tx: ReadTransaction) => {
                const identity = (await tx.get('identity')) as unknown as Identity
                if (!identity) {
                    return {
                        type: 'none',
                    } as const
                }
                const userId = identity.Id
                const prefix = [userId, 'OPEN'].join('#')

                const myOpenTasks = (await tx
                    .scan({ indexName: 'openTasksByUser', prefix })
                    .values()
                    .toArray()) as NewTask[]
                return {
                    type: 'success',
                    tasks: myOpenTasks,
                } as const
            })
            if (taskResult.type === 'none') {
                this.queryStatus.reset('my-open-tasks')
            } else {
                taskResult.tasks.forEach((task) => this.objectPool.attach(task))
                this.queryStatus.finish('my-open-tasks')
            }
        }
    }

    @computed
    get data() {
        const tasks = uniqBy([...this.taskQuery.data, ...this.completedTasks], 'Id')
        return sortBy(tasks, 'OpenDate')
    }
}

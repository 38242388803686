import { useSubscribe } from 'replicache-react'
import type { ReadTransaction } from 'replicache'

import {
    NewEvent,
    NewTask,
    NewActivity,
    Prefix,
    SyncedRecord,
    User,
} from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { EventUtil, ActivityUtil } from '@laserfocus/client/model'
import { RootSyncState } from '@laserfocus/client/data-access-shared'

export type TaskData = NewTask & WithOwner
export type ActivityData = NewActivity & WithOwner & { isPinned: boolean }

type WithOwner = {
    Owner?: { Name?: string | null }
}

export default function usePersonActivities(
    personId: string,
    syncState: RootSyncState,
    pinnedActivities: string[]
) {
    const { tasks, events, users } = useActivities(personId)
    const pinnedById = Object.fromEntries(pinnedActivities.map((a) => [a, true]))
    const usersById = Object.fromEntries(users.map((u) => [u.Id, u]))
    const open = getOpenActivities(tasks, events)
    const history = getActivityHistory(tasks, events)
    const isLoadingHistory = shouldShowLoadingIndicator(history, syncState.task, syncState.event)
    return {
        open: open.map((a) => withOwnerAndPinned(a, usersById, pinnedById)),
        history: history.map((a) => withOwnerAndPinned(a, usersById, pinnedById)),
        isLoadingHistory,
    }
}

function getOpenActivities(tasks: NewTask[], events: NewEvent[]) {
    const openTasks = tasks.filter((a) => !a.IsClosed)
    const openEvents = events.filter((e) => !EventUtil.isOver(e))

    const open = [...openTasks, ...openEvents].sort(ActivityUtil.activitySorter)
    return open
}

function getActivityHistory(tasks: NewTask[], events: NewEvent[]) {
    const taskHistory = tasks.filter((t) => t.IsClosed)
    const eventHistory = events.filter((e) => EventUtil.isOver(e))
    const sorted = [...taskHistory, ...eventHistory].sort(ActivityUtil.activitySorter)
    return sorted
}

function withOwnerAndPinned(
    activity: NewActivity,
    usersById: Record<string, User>,
    pinnedActivities: Record<string, boolean>
): ActivityData {
    const owner = activity.OwnerId ? usersById[activity.OwnerId] : undefined
    return {
        ...activity,
        Owner: owner,
        isPinned: Boolean(pinnedActivities[activity.Id]),
    }
}

/**
 * We only want to show the loading indicator if there is NO history item
 * and it never loaded before
 */
function shouldShowLoadingIndicator(
    history: Array<NewTask | NewEvent>,
    taskSyncState?: SyncedRecord,
    eventSyncState?: SyncedRecord
) {
    if (history.length > 0) {
        return false
    }
    return !(taskSyncState?.didLoad && eventSyncState?.didLoad)
}

function useActivities(rootId: string) {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            const tasks = (await tx
                .scan({ indexName: 'tasksByRootId', prefix: rootId })
                .values()
                .toArray()) as NewTask[]

            const events = (await tx
                .scan({ indexName: 'eventsByRootId', prefix: rootId })
                .values()
                .toArray()) as NewEvent[]

            const users = (await tx
                .scan({ prefix: `${Prefix.User}/` })
                .values()
                .toArray()) as User[]

            return {
                tasks,
                events,
                users,
            }
        },
        {
            events: [],
            tasks: [],
            users: [],
        },
        [rootId]
    )
}

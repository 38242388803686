import { useMemo } from 'react'
import { useSubscribe } from 'replicache-react'
import clsx from 'clsx'

import { Delete2OutlinedIcon, FolderOutlinedIcon } from '@laserfocus/ui/icons'
import { getClient } from '@laserfocus/client/replicache'
import { OrgStack, Prefix, Stack } from '@laserfocus/shared/models'
import { LoadingStackCard, StackCard } from '@laserfocus/client/feature-stacks'
import { LayoutPaddingVertical } from '@laserfocus/client/layout'
import { StackModel } from '@laserfocus/client/model'
import { mutateStack } from '@laserfocus/client/data-access-shared'
import { useIsLocked, LockedFeatureOverlay } from '@laserfocus/client/feature-subscription'

import { RoleSelect } from '../roles/RoleSelect'
import { useRoleContext } from '../roles/RoleContext'
import { SettingsPageHeader } from '../layout/SettingsPageHeader'

import { CreateStackButton } from './CreateStackButton'
import { makeStackUrl } from './stack-settings-util'

export function StacksListSettingsPage() {
    const { selectedRole } = useRoleContext()
    return (
        <div>
            <SettingsPageHeader
                title="Stacks"
                iconComponent={FolderOutlinedIcon}
                className="gap-2 justify-between"
                left={
                    <div className="grid grid-flow-col gap-2 items-center mt-1">
                        <span>for</span>
                        <RoleSelect className="min-w-[220px]" />
                    </div>
                }
                right={<CreateStackButton role={selectedRole} />}
            />
            <StackListSettingsBody />
        </div>
    )
}

function StackListSettingsBody() {
    const { selectedRole } = useRoleContext()
    const { stacks, isLoading } = useOrgStacks(selectedRole)

    const { isLocked, subscription } = useIsLocked(true, 'team')

    if (isLoading) {
        return (
            <div className="my-6">
                <LayoutPaddingVertical maxWidth="stackWidth">
                    <div className="grid gap-6 grid-cols-2">
                        <LoadingStackCard />
                        <LoadingStackCard />
                        <LoadingStackCard />
                        <LoadingStackCard />
                        <LoadingStackCard />
                        <LoadingStackCard />
                    </div>
                </LayoutPaddingVertical>
            </div>
        )
    }

    return (
        <div className="py-6">
            <LayoutPaddingVertical maxWidth="stackWidth">
                <LockedFeatureOverlay
                    isLocked={isLocked}
                    subscription={subscription}
                    lockedFeature="organisation Stacks"
                    lockedClassName={clsx(
                        'bg-white/40',
                        shiftLockedMessage(stacks.length) && 'pb-[10%]'
                    )}
                    plan="team"
                >
                    <div className="grid gap-6 grid-cols-2">
                        {stacks.map((st) => (
                            <StackCard
                                key={st.id}
                                title={st.title}
                                sobject={st.sobject}
                                icon={StackModel.getIcon(st)}
                                color={getSettingsStackColor(st)}
                                linkTo={makeStackUrl(StackModel.getSlug(st))}
                                actions={[
                                    {
                                        label: 'Deactivate',
                                        value: 'deactivate',
                                        iconComponent: Delete2OutlinedIcon,
                                        onClick: () => mutateStack.deactivateStack(st.id),
                                        destructive: true,
                                    },
                                ]}
                            />
                        ))}
                    </div>
                </LockedFeatureOverlay>
            </LayoutPaddingVertical>
        </div>
    )
}

function shiftLockedMessage(stackCount: number) {
    if (stackCount <= 2) {
        return false
    }
    const mod = stackCount % 4
    return mod === 1 || mod === 2
}

function getSettingsStackColor(stack: Stack) {
    // Same as the background, so we need to darken it
    if (stack.color === '#FFF7E8') {
        return 'rgba(255, 201, 98, 0.15)'
    }
    return StackModel.getColor(stack)
}

function useOrgStacks(selectedRole: string | null) {
    const rep = getClient()
    const { stacks, isLoading } = useSubscribe<{
        stacks: OrgStack[]
        isLoading: boolean
    }>(
        rep,
        async (tx) => {
            const stacks = (await tx
                .scan({ prefix: `${Prefix.StackOrg}/` })
                .values()
                .toArray()) as OrgStack[]

            return {
                stacks: stacks || [],
                isLoading: false,
            }
        },
        {
            stacks: [],
            isLoading: true,
        }
    )

    const sortedStacks = useMemo(() => {
        const activatedStacks = stacks.filter((a) => !a.deactivated)
        const roleStacks = activatedStacks.filter(
            (a) => !selectedRole || !a.roles?.length || a.roles?.includes(selectedRole)
        )
        return roleStacks
    }, [stacks, selectedRole])

    return {
        stacks: sortedStacks,
        isLoading,
    }
}

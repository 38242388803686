import { useSubscribe } from 'replicache-react'

import { getClient } from '@laserfocus/client/replicache'
import { LFUser, Prefix, Role } from '@laserfocus/shared/models'

export function useRoleIssueCount() {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx) => {
            const [users, roles] = (await Promise.all([
                tx.scan({ prefix: Prefix.LFUser }).values().toArray(),
                tx.scan({ prefix: Prefix.Role }).values().toArray(),
            ])) as [LFUser[], Role[]]

            const withoutRole = users.filter((a) => !a.role)
            const existingRoles = new Set(roles.map((a) => a.name))

            const withNotExistingRole = users.filter((u) => u.role && !existingRoles.has(u.role))
            return withoutRole.length + withNotExistingRole.length
        },
        0,
        []
    )
}

export type SearchResult =
    | LeadSearchResult
    | OpportunitySearchResult
    | ContactSearchResult
    | AccountSearchResult

export function isLeadResult(r: SearchResult): r is LeadSearchResult {
    return r.SObjectType === 'Lead'
}

export function isOpportunityResult(r: SearchResult): r is OpportunitySearchResult {
    return r.SObjectType === 'Opportunity'
}
export function isContactResult(r: SearchResult): r is ContactSearchResult {
    return r.SObjectType === 'Contact'
}

export function isAccountResult(r: SearchResult): r is AccountSearchResult {
    return r.SObjectType === 'Account'
}

interface BaseSearchResult {
    Id: string
    Name: string
    OwnerId?: string
    SObjectType: 'Account' | 'Opportunity' | 'Lead' | 'Contact'
}
export interface LeadSearchResult extends BaseSearchResult {
    Company: string
    SObjectType: 'Lead'
    Email: string | undefined
    Website: string | undefined
}

export interface OpportunitySearchResult extends BaseSearchResult {
    AccountName?: string
    AccountId?: string
    SObjectType: 'Opportunity'
    IsClosed: boolean
}

export interface ContactSearchResult extends BaseSearchResult {
    AccountName?: string
    AccountId?: string
    SObjectType: 'Contact'
    Email: string | undefined
}

export interface AccountSearchResult extends BaseSearchResult {
    SObjectType: 'Account'
    Website: string | undefined
}

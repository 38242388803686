export function FieldLabel({
    htmlFor,
    children,
    required,
}: {
    htmlFor?: string
    children: React.ReactNode
    required?: boolean
}) {
    return (
        <label
            htmlFor={htmlFor}
            className="text-sm font-medium leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
        >
            {children}
            {required && (
                <span title="Required" className="px-1 -mr-1 select-none text-red-500">
                    •
                </span>
            )}
        </label>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

/** 16px version */
export function ListOrderedIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 32 32" width={16} height={16} {...props}>
            <path d="M16 22H30V24H16zM16 8H30V10H16zM8 12L8 4 6 4 6 5 4 5 4 7 6 7 6 12 4 12 4 14 6 14 8 14 10 14 10 12 8 12zM10 28H4V24a2 2 0 012-2H8V20H4V18H8a2 2 0 012 2v2a2 2 0 01-2 2H6v2h4z" />
        </SvgIcon>
    )
}

import { Routes as RouterRoutes, Route } from 'react-router'

import { RoutePaths } from '@laserfocus/client/util-routing'
import {
    OAuthCallbackPage,
    LoginPage,
    LogoutPage,
    AccountNotActivePage,
} from '@laserfocus/client/feature-auth'
import { TodayPage, OverduePage, UpcomingPage, StatsPage } from '@laserfocus/client/feature-stacks'
import { OnboardingRouter, LoginLandingPage } from '@laserfocus/client/feature-onboarding'
import { TablePage } from '@laserfocus/client/feature-table'
import { CrashScreen } from '@laserfocus/ui/crash-screen'
import { NotFoundPage, ErrorPage } from '@laserfocus/client/ui-shared'
import { ReportsPage } from '@laserfocus/client/feature-reports'
import { SettingsRoutes } from '@laserfocus/client/feature-settings'
import { SingleNotePage } from '@laserfocus/client/feature-person-details'

import HomePage from '../Home/HomePage'
import PersonPage from '../Pages/PersonPage'
import TodayPersonPage from '../Pages/TodayPersonPage'
import OverduePersonPage from '../Pages/OverduePersonPage'
import UpcomingPersonPage from '../Pages/UpcomingPersonPage'
import StackPersonPage from '../Pages/StackPersonPage'
import { IndexPage } from '../Pages/IndexPage'
import { Playground } from '../Pages/Playground'
import { ResetPage } from '../Pages/ResetPage'

import { ScrollToTop } from './ScrollToTop'

export default function Routes() {
    return (
        <ScrollToTop>
            <RouterRoutes>
                <Route element={<IndexPage />} index />
                <Route element={<LoginLandingPage />} path={RoutePaths.Landing} />
                <Route element={<LoginPage />} path="login" />
                <Route element={<OAuthCallbackPage />} path="oauth/callback" />
                <Route element={<LogoutPage />} path="logout" />
                <Route element={<AccountNotActivePage />} path="message/account-not-active" />
                <Route element={<ErrorPage />} path="message/:errorCode" />
                <Route element={<CrashScreen />} path="oops" />
                <Route element={<OnboardingRouter />} path="signup/*" />

                {/* AUTHENTICATED */}
                <Route element={<HomePage />} path={RoutePaths.Home} />
                <Route element={<ReportsPage />} path="dashboard" />
                <Route element={<TodayPage />} path={RoutePaths.Today} />
                <Route element={<OverduePage />} path={RoutePaths.Overdue} />
                <Route element={<UpcomingPage />} path={RoutePaths.Upcoming} />
                <Route element={<TablePage />} path="table/:stackSlug" />
                <Route element={<StatsPage />} path="stats" />
                <Route element={<ResetPage />} path="reset" />

                <Route element={<PersonPage />} path={RoutePaths.Person} />
                <Route element={<SingleNotePage />} path="/notes/:noteId" />
                <Route element={<TodayPersonPage />} path={RoutePaths.TodayPerson} />
                <Route element={<OverduePersonPage />} path={RoutePaths.OverduePerson} />
                <Route element={<UpcomingPersonPage />} path={RoutePaths.UpcomingPerson} />
                <Route element={<StackPersonPage />} path={RoutePaths.StackPerson} />
                <Route element={<SettingsRoutes />} path="settings/*" />

                <Route element={<Playground />} path="play" />
                <Route element={<NotFoundPage />} path="*" />
            </RouterRoutes>
        </ScrollToTop>
    )
}

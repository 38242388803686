import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ContactFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M12 10.8h-.01a3.6 3.6 0 000-7.2h-.01a3.6 3.6 0 100 7.2H12zM3.6 21.6a8.4 8.4 0 1116.79-.02H3.59l.01.02z" />
        </SvgIcon>
    )
}

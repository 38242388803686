import { useLayoutEffect, useReducer } from 'react'
import { isSameMonth, startOfMonth } from 'date-fns'

interface Month {
    year: number
    month: number
    date: Date
}

export function useMonth(
    initialMonthDate = new Date(),
    startDate: Date | null,
    endDate: Date | null
) {
    const [month, dispatch] = useReducer(monthReducer, initialMonthDate, getMonth)

    // Needed in case selected dates are changed externally. Is a noop if selected dates are changed internally within this lib.
    useLayoutEffect(() => {
        dispatch({ type: 'sync external', startDate, endDate })
    }, [startDate, endDate])

    function setMonth(date: Date) {
        dispatch({ type: 'change month', date })
    }

    return [month, setMonth] as const
}

interface ChangeMonthAction {
    type: 'change month'
    date: Date | null
}

/** Only for use within `useMonth` */
interface SyncExternalAction {
    type: 'sync external'
    startDate: Date | null
    endDate: Date | null
}

function monthReducer(currentMonth: Month, action: ChangeMonthAction | SyncExternalAction) {
    const activeMonthDate = currentMonth.date

    switch (action.type) {
        case 'change month': {
            const { date } = action
            if (!date) {
                return currentMonth
            }

            if (date && isSameMonth(date, activeMonthDate)) {
                return currentMonth
            }

            return getMonth(date)
        }

        case 'sync external': {
            const { startDate, endDate } = action

            if (
                // startDate exists and is not in active month, no endDate
                (startDate && !endDate && !isSameMonth(startDate, activeMonthDate)) ||
                // Both dates exist and are not in active month
                (startDate &&
                    endDate &&
                    !isSameMonth(startDate, activeMonthDate) &&
                    !isSameMonth(endDate, activeMonthDate)) ||
                // No startDate, endDate exists and is not in active month
                (!startDate && endDate && !isSameMonth(endDate, activeMonthDate))
            ) {
                return getMonth((startDate || endDate)!)
            }

            return currentMonth
        }

        default: {
            return currentMonth
        }
    }
}

function getMonth(date: Date): Month {
    const dateStartOfMonth = startOfMonth(date)
    return {
        year: dateStartOfMonth.getFullYear(),
        month: dateStartOfMonth.getMonth(),
        date: dateStartOfMonth,
    }
}

export interface SvgIconProps extends React.ComponentPropsWithoutRef<'svg'> {}

export function SvgIcon(props: SvgIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden
            {...props}
        />
    )
}

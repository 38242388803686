import { useMemo } from 'react'
import { useSubscribe } from 'replicache-react'
import { sortBy } from 'lodash'

import { CopyTextOutlinedIcon } from '@laserfocus/ui/icons'
import { FieldGroups } from '@laserfocus/client/feature-person-details'
import { Modal } from '@laserfocus/ui/beam'
import { getClient } from '@laserfocus/client/replicache'
import { OrgFieldGroup, Prefix } from '@laserfocus/shared/models'
import { usePersistedState } from '@laserfocus/ui/hooks'
import { LockedFeatureOverlay, useIsLocked } from '@laserfocus/client/feature-subscription'

import { RoleSelect } from '../roles/RoleSelect'
import { useRoleContext } from '../roles/RoleContext'
import { SettingsPageHeader } from '../layout/SettingsPageHeader'

import { FieldGroupContainer } from './FieldGroupContainer'

export function FieldGroupsSettingsPage() {
    return (
        <div>
            <SettingsPageHeader
                title="Field groups"
                iconComponent={CopyTextOutlinedIcon}
                className="gap-2 justify-start"
                left={
                    <div className="grid grid-flow-col gap-2 items-center mt-1">
                        <span>for</span>
                        <RoleSelect className="min-w-[220px]" />
                    </div>
                }
            />

            <FieldGroupSettingsBody />
        </div>
    )
}

type Tab = 'Lead field groups' | 'Account field groups'
const tabs: Tab[] = ['Lead field groups', 'Account field groups']

function FieldGroupSettingsBody() {
    const { selectedRole } = useRoleContext()
    const [currentTab, setCurrentTab] = usePersistedState<Tab>(
        'lf:settings:fieldgroupTab',
        'Lead field groups'
    )
    const rootObject = currentTab === 'Lead field groups' ? 'Lead' : 'Account'
    const { fieldGroups, isLoading } = useOrgFieldGroups(rootObject, selectedRole)

    const { isLocked, subscription } = useIsLocked(true, 'team')

    return (
        <>
            <Modal.Tabs tabs={tabs} currentTab={currentTab} onTabClick={setCurrentTab} />
            <FieldGroupContainer rootObject={rootObject}>
                <div className="p-6 max-w-xl">
                    <LockedFeatureOverlay
                        isLocked={isLocked}
                        subscription={subscription}
                        lockedFeature="organisation Field Groups"
                        plan="team"
                    >
                        <FieldGroups
                            fieldGroups={fieldGroups}
                            isLoadingSobject={isLoading}
                            isInOverlay={false}
                            rootObjectType={rootObject}
                            orgOrUser="org"
                            orgContext={{ selectedRole: selectedRole ?? undefined }}
                        />
                    </LockedFeatureOverlay>
                </div>
            </FieldGroupContainer>
        </>
    )
}

function useOrgFieldGroups(rootObject: 'Account' | 'Lead', selectedRole: string | null) {
    const rep = getClient()
    const { fieldGroups, isLoading } = useSubscribe<{
        fieldGroups: OrgFieldGroup[]
        isLoading: boolean
    }>(
        rep,
        async (tx) => {
            const fieldGroups = (await tx
                .scan({ prefix: `${Prefix.FieldGroupOrg}/` })
                .values()
                // Right now we only have user fieldgroups
                .toArray()) as OrgFieldGroup[]
            const rawFieldGroups = fieldGroups || []

            return {
                fieldGroups: rawFieldGroups,
                isLoading: false,
            }
        },
        {
            fieldGroups: [],
            isLoading: true,
        }
    )

    const sortedFieldGroups = useMemo(() => {
        const objectFieldgroups = fieldGroups.filter((a) => a.rootObject === rootObject)
        const roleObjectFieldGroups = objectFieldgroups
            .filter((a) => !a.roles || (selectedRole && a.roles?.includes(selectedRole)))
            .filter((fg) => !fg.deleted)
        return sortBy(roleObjectFieldGroups, ({ deactivated }) => (deactivated ? 1 : 0), 'orderKey')
    }, [fieldGroups, rootObject, selectedRole])

    return {
        fieldGroups: sortedFieldGroups,
        isLoading,
    }
}

import { createContext, useContext, useEffect, useState } from 'react'

import { useUserId } from '@laserfocus/client/feature-auth'

import { useOnboardingStore } from './store/OnboardingStore'

type Role =
    | 'account-executive'
    | 'sales-manager'
    | 'customer-success'
    | 'account-manager'
    | 'sales-development'
    | 'other'
type ConnectionType = 'own-data' | 'demo-data'
export type RelevantObject = 'Opportunity' | 'Account' | 'Lead'

interface Option<T extends string> {
    value: T
    label: string
}

export const ROLE_OPTIONS: Option<Role>[] = [
    { value: 'account-executive', label: 'Account Executive' },
    { value: 'sales-manager', label: 'Sales Manager' },
    { value: 'sales-development', label: 'Sales Development' },
    { value: 'customer-success', label: 'Customer Success' },
    { value: 'account-manager', label: 'Account Manager' },
    { value: 'other', label: 'Other' },
]

export const CONNECTION_TYPE_OPTIONS: Option<ConnectionType>[] = [
    { value: 'own-data', label: 'Use your Salesforce data' },
    { value: 'demo-data', label: 'Try with demo data' },
]

export const RELEVANT_OBJECT_OPTIONS: Option<RelevantObject>[] = [
    { value: 'Opportunity', label: 'Opportunities' },
    { value: 'Account', label: 'Accounts' },
    { value: 'Lead', label: 'Leads' },
]

interface OnboardingContextValue {
    selectedRole: Role
    setSelectedRole(role: Role): void
    connectionType: ConnectionType
    setConnectionType(connectionType: ConnectionType): void
    relevantObjects: RelevantObject[]
    setRelevantObjects(specifiedObjects: RelevantObject[]): void
    startedConnectedWithSalesforce: boolean
    userId: string | null
}

const OnboardingContext = createContext<OnboardingContextValue>(null!)

interface OnboardingProviderProps {
    children: React.ReactNode
}

export function OnboardingProvider({ children }: OnboardingProviderProps) {
    const [startedConnectedWithSalesforce] = useState<boolean>(() => {
        const previousRun = localStorage.getItem('lf:onboarding:startedConnectedWithSalesforce')
        if (previousRun) {
            return previousRun === 'true'
        }
        return Boolean(localStorage.getItem('lf:token'))
    })
    useEffect(() => {
        localStorage.setItem(
            'lf:onboarding:startedConnectedWithSalesforce',
            `${startedConnectedWithSalesforce}`
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const userId = useUserId(true)

    const onboardingStore = useOnboardingStore()
    useEffect(() => {
        if (userId) {
            onboardingStore.runChecks()
        }
    }, [onboardingStore, userId])

    const [selectedRole, setSelectedRole] = useState(ROLE_OPTIONS[0]!.value)
    const [connectionType, setConnectionType] = useState(CONNECTION_TYPE_OPTIONS[0]!.value)
    const [relevantObjects, setRelevantObjects] = useState<RelevantObject[]>([])

    return (
        <OnboardingContext.Provider
            value={{
                startedConnectedWithSalesforce,
                selectedRole,
                setSelectedRole,
                connectionType,
                setConnectionType,
                relevantObjects,
                setRelevantObjects,
                userId,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    )
}

export function useOnboarding() {
    return useContext(OnboardingContext)
}

import { useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { SuggestOptions, DateSuggest } from '@laserfocus/vendor/time-suggest'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { getDateNarrow, getTimeTabular, getWeekDay } from '@laserfocus/ui/util-locale'
import {
    getDefaultDateSuggestions,
    createDateSuggest,
    EmptySuggest,
} from '@laserfocus/client/util-date'
import { CalendarOutlinedIcon } from '@laserfocus/ui/icons'

import { DropdownInput } from '../controls/DropdownInput'
import { useDropdownKeyboardNavigation } from '../Select/useDropdownKeyboardNavigation'

interface CompactDatePickerSelectProps {
    initialDate?: Date | null
    dateOnly: boolean | undefined
    onDateSubmit(date: Date | null): void
    openCalendar(): void
    suggestOptions?: SuggestOptions
    allowEmpty?: boolean
}

export function CompactDatePickerSelect({
    initialDate,
    dateOnly,
    onDateSubmit,
    openCalendar,
    suggestOptions,
    allowEmpty,
}: CompactDatePickerSelectProps) {
    const [placeholder] = useState(() => getRandomPlaceholder(dateOnly))
    const [inputValue, setInputValue] = useState('')

    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)
    const suggest = useMemo(
        () =>
            createDateSuggest({
                allowEmpty,
                ...suggestOptions,
            }),
        [allowEmpty, suggestOptions]
    )

    const suggestions = useMemo(
        () =>
            (inputValue
                ? suggest(inputValue)
                : getDefaultDateSuggestions(dateOnly, Boolean(initialDate && allowEmpty), 5)
            ).slice(0, 5),
        [inputValue, suggest, dateOnly, initialDate, allowEmpty]
    ) as Array<DateSuggest | EmptySuggest>

    const { hoveredOptionIndex, setHoveredOptionIndex } = useDropdownKeyboardNavigation({
        optionsLength: suggestions.length + 1,
        resetKey: inputValue,
        submit: (optionIndex) =>
            optionIndex === suggestions.length
                ? openCalendar()
                : onDateSubmit(suggestions[optionIndex]!.date ?? null),
    })

    return (
        <>
            <DropdownInput
                ref={inputRef}
                aria-label="Search date"
                placeholder={placeholder}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                className="focus-visible:ring mb-2"
            />
            <div className="grid gap-1">
                {suggestions.map(({ date, formatted }, index) => (
                    <CompactDatePickerOption
                        key={index}
                        isHovered={index === hoveredOptionIndex}
                        setIsHovered={() => setHoveredOptionIndex(index)}
                        onClick={() => onDateSubmit(date ?? null)}
                    >
                        {formatted}
                        {date && (
                            <span className="text-xs text-white/60 text-right float-right pt-[0.1875rem] ml-2">
                                {dateOnly
                                    ? `${getWeekDay(date)}, ${getDateNarrow(date)}`
                                    : `${getWeekDay(date)}, ${getDateNarrow(
                                          date
                                      )}, ${getTimeTabular(date)}`}
                            </span>
                        )}
                    </CompactDatePickerOption>
                ))}
                {suggestions.length !== 0 && <div className="border-t border-white/10" />}
                <CompactDatePickerOption
                    className="grid grid-flow-col justify-between items-center"
                    isHovered={suggestions.length === hoveredOptionIndex}
                    setIsHovered={() => setHoveredOptionIndex(suggestions.length)}
                    onClick={openCalendar}
                >
                    {dateOnly ? 'Pick date' : 'Pick date & time'}
                    <CalendarOutlinedIcon className="w-4 h-4 text-white/60" />
                </CompactDatePickerOption>
            </div>
        </>
    )
}

function getRandomPlaceholder(dateOnly: boolean | undefined) {
    const queries = dateOnly
        ? ['tmr', 'in 3 days', 'mar 13', 'dec 2022', 'next friday', 'next week']
        : [
              '9am',
              'in 3 days',
              'mar 13',
              'dec 2022',
              'next friday at 9am',
              'next week',
              'at 3pm in new york',
          ]

    const query = queries[Math.floor(Math.random() * queries.length)]!

    return `Try ${query}`
}

interface CompactDatePickerOptionProps {
    children: React.ReactNode
    className?: string
    isHovered: boolean
    setIsHovered(): void
    onClick(): void
}

function CompactDatePickerOption({
    children,
    className,
    isHovered,
    setIsHovered,
    onClick,
}: CompactDatePickerOptionProps) {
    return (
        <button
            className={twMerge(
                'px-2 py-1 rounded-md outline-none focus-visible:ring tranistion-shadow tabular-nums text-left text-sm font-medium',
                isHovered && 'bg-white/10',
                className
            )}
            onMouseEnter={setIsHovered}
            onFocus={setIsHovered}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

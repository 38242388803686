import { getCountry } from '../shared'

// Data from https://github.com/unicode-cldr/cldr-core/blob/2c2d91fa0c581b7f3a35fc748a1ce88e070ac550/supplemental/weekData.json#L59
import firstDayOfWeekByCountry from './first-day-of-week-by-country.json'

export function getFirstDayOfWeek() {
    const country = getCountry()

    return (firstDayOfWeekByCountry[country as keyof typeof firstDayOfWeekByCountry] ??
        firstDayOfWeekByCountry.default) as 0 | 1 | 2 | 3 | 4 | 5 | 6
}

import { ReadTransaction, WriteTransaction } from 'replicache'
import { z } from 'zod'
import { useSubscribe } from 'replicache-react'
import { format } from 'date-fns'
import produce from 'immer'

import { getClient } from '@laserfocus/client/replicache'
import {
    ActivityCounterMutatorSchema,
    applyValidators,
    DATE_FORMAT,
    Prefix,
    UserId,
    ActivityCounterItem,
    DateTimeString,
    Identity,
    DateString,
} from '@laserfocus/shared/models'

export function useActivityCounterForUserBetween(
    userId?: UserId,
    start?: DateString,
    end?: DateString
) {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            if (!userId) {
                return []
            }
            const prefix = [Prefix.ActivityCounter, userId].join('/')
            const startKey = [Prefix.ActivityCounter, userId, start].join('/')
            const endKey = end ? [Prefix.ActivityCounter, userId, end].join('/') : undefined
            let counterItems: ActivityCounterItem[] = []
            const items = tx
                .scan({
                    prefix,
                    start: {
                        key: startKey,
                        exclusive: false,
                    },
                })
                .entries()

            try {
                for await (const [key, item] of items) {
                    counterItems.push(item as ActivityCounterItem)
                    if (endKey && key >= endKey) {
                        break
                    }
                }
            } catch (e: any) {
                if (e && e.message === `Cannot read properties of undefined (reading 'return')`) {
                    // This is apparently a bug how async iterables are compiled, can be ignored
                } else {
                    throw e
                }
            }

            return counterItems
        },
        [],
        [userId, start, end]
    )
}

export function useOrgActivityCounterSince(since: Date) {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            const start = format(since, DATE_FORMAT)
            const items = await tx
                .scan({
                    indexName: 'orgActivityCounterByDate',
                    start: {
                        key: start,
                        exclusive: false,
                    },
                })
                .values()
                .toArray()
            return items as unknown[] as ActivityCounterItem[]
        },
        [],
        [since]
    )
}

export function countActivity(
    name: z.infer<typeof ActivityCounterMutatorSchema.countActivity>['name']
) {
    const client = getClient<typeof mutators>()
    return client.mutate.countActivity({ date: new Date().toISOString(), name })
}

export const mutators = applyValidators(ActivityCounterMutatorSchema, {
    async countActivity(tx, args) {
        return updateCounter(tx, args.name, args.date)
    },
})

export async function updateCounter(tx: WriteTransaction, name: string, date: DateTimeString) {
    const identity = (await tx.get('identity')) as unknown as Identity | null
    if (!identity) {
        return
    }
    const dateKey = format(new Date(date), DATE_FORMAT) as DateString
    const key = [Prefix.ActivityCounter, (identity as Identity).Id, dateKey].join('/')
    const existing = (await tx.get(key)) as unknown as ActivityCounterItem | null
    if (existing) {
        const updated = produce(existing, (draft: ActivityCounterItem) => {
            if (!draft.counters[name]) {
                draft.counters[name] = 0
            }
            draft.counters[name] = draft.counters[name]! + 1
        })
        return tx.put(key, updated)
    } else {
        const item: ActivityCounterItem = {
            date: dateKey,
            userId: identity.Id,
            orgId: identity.OrgId,
            lastModifiedDate: new Date().toISOString(),
            counters: {
                [name]: 1,
            },
        }
        return tx.put(key, item)
    }
}

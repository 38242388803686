import { z } from '@laserfocus/shared/decoder'

import { CacheInfo } from '../base/cache.types'

export const MainRecordType = '012000000000000AAA'

const sobjectTypes = [
    z.literal('Lead'),
    z.literal('Account'),
    z.literal('Contact'),
    z.literal('Opportunity'),
    z.literal('EmailMessage'),
    z.literal('Task'),
    z.literal('Event'),
    z.literal('User'),
    z.literal('Group'),
    z.literal('Activity'),
] as [
    z.ZodLiteral<'Lead'>,
    z.ZodLiteral<'Account'>,
    z.ZodLiteral<'Contact'>,
    z.ZodLiteral<'Opportunity'>,
    z.ZodLiteral<'EmailMessage'>,
    z.ZodLiteral<'Task'>,
    z.ZodLiteral<'Event'>,
    z.ZodLiteral<'User'>,
    z.ZodLiteral<'Group'>,
    z.ZodLiteral<'Activity'>
]
export const sobjectTypeSchema = z.union(sobjectTypes)
export type SObjectType = z.infer<typeof sobjectTypeSchema>

const clientTypes = z.enum([
    'Stack',
    'FieldMetadata',
    'RecordType',
    'Identity',
    'FieldView',
    'FieldGroup',
    'RelationTableColumn',
    'SalesObjectExtension',
])

export type ClientTypes = z.infer<typeof clientTypes>

const objectTypeSchema = z.union([...sobjectTypes, clientTypes])
export type ObjectType = z.infer<typeof objectTypeSchema>
export type ObjectPoolType = Exclude<ObjectType, 'FieldView' | 'User' /* | 'Stack' */>

export type ReferenceType = SObjectType | 'RecordType' | 'User'

/**
 * @see: https://developer.salesforce.com/docs/atlas.en-us.234.0.object_reference.meta/object_reference/field_types.htm
 */
const fieldTypeSchema = z.union([
    z.literal('string'),
    z.literal('boolean'),
    /**
     * @deprecated
     * Int does not seem to be used. Since all numbers without right hand decimals turn out to be
     * double(X,0)
     */
    z.literal('int'),
    z.literal('double'),
    z.literal('date'),
    z.literal('datetime'),
    z.literal('base64'),
    z.literal('id'),
    z.literal('reference'),
    z.literal('currency'),
    z.literal('textarea'),
    z.literal('percent'),
    z.literal('phone'),
    z.literal('url'),
    z.literal('email'),
    z.literal('combobox'),
    z.literal('picklist'),
    z.literal('multipicklist'),
    z.literal('anyType'),
    z.literal('location'),
    // the following are not found in official documentation| but still occur when describing an sobject
    z.literal('time'),
    z.literal('encryptedstring'),
    z.literal('address'),
    z.literal('complexvalue'),
])

export type FieldType = z.infer<typeof fieldTypeSchema>

export type ObjectMetadata<T = FieldMetadata> = CacheInfo & {
    name: SObjectType
    recordTypes: ObjectRecordType[]
    fields: T[]
    __typename: 'ObjectMetadata'
}

type ObjectRecordType = {
    name?: string
    id: string
}

/**
 * @see: https://developer.salesforce.com/docs/atlas.en-us.234.0.api.meta/api/sforce_api_calls_describesobjects_describesobjectresult.htm#field
 */
export type BaseFieldMetadata = {
    objectName: SObjectType
    name: string
    fieldType: FieldType

    label: string

    referenceTo: ReferenceType[]
    controllerFieldName?: string | null
    // controllingFieldNames: string[]
    inlineHelpText?: string | null
    picklistValues: PicklistValue[] | null
    /**
     * Whether the picklist’s values are limited to only the values defined by a Salesforce admin. Values are true or false.
     */
    restrictedPicklist?: boolean
    defaultValue?: string | boolean | null

    /**
     * For string fields, the maximum size of the field in number of Unicode characters (not bytes).
     */
    length?: number
    /**
     * For double fields, indicates the maximum number of digits that can be stored, on both sides of the decimal point.
     */
    precision?: number
    /**
     * For double fields, indicates the number of digits to the right of the decimal point.
     */
    scale?: number

    updateable: boolean
    createable: boolean
    required: boolean
    editableForNew?: boolean
    editableForUpdate?: boolean
    inCreateLayout?: boolean
    /**
     * Were not yet loading these layouts
     * We probably should to have a better default "fieldView"
     */
    // inEditLayout: boolean,
    // inViewLayout: boolean

    /**
     * This is for fields that are deprecated either by salesforce
     *      Currently the only deprecated field is 'Opportunity.ForecatsCategory'
     * We could later use this also for "deleted" fields
     */
    deprecated?: boolean
}

export interface FieldMetadata extends BaseFieldMetadata {
    isCustomField: boolean
}

export interface FieldMetadataProps extends FieldMetadata {
    Id: string
    __typename: 'FieldMetadata'
}

export type PicklistValue = {
    attributes?: PicklistValueAttributes
    label: string | null
    /**
     * Index of controlling Field Value
     */
    validFor?: number[]
    value: string
}

export interface RecordType extends CacheInfo {
    objectName: string
    id: string
    name: string
    picklists: RecordTypePicklist[]
    __typename: 'RecordType'
}

export type RecordTypePicklist = {
    fieldName: string
    defaultValue?: string
    picklistValues: PicklistValue[]
}

export type PicklistValueAttributes = LeadStatusAttributes | OpportunityStageAttributes | null

export type LeadStatusAttributes = {
    converted?: boolean
    picklistAtrributesValueType: 'LeadStatus'
}

export type OpportunityStageAttributes = {
    closed: boolean
    defaultProbability: number
    forecastCategoryName: string
    picklistAtrributesValueType: 'OpportunityStage'
    won: boolean
}

import { useController, UseControllerProps, FieldValues, FieldPath } from 'react-hook-form'

import { Input, InputProps } from '@laserfocus/ui/beam'

type FormInputProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> &
    InputProps & {
        defaultErrorMessage?: string
    }

export function FormInput<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: FormInputProps<TFieldValues, TName>) {
    const {
        name,
        rules,
        shouldUnregister,
        defaultValue,
        control,
        defaultErrorMessage,
        ...inputProps
    } = props
    const {
        field,
        fieldState: { error },
    } = useController({ name, rules, shouldUnregister, defaultValue, control })
    return (
        <Input
            {...field}
            error={error ? error?.message || defaultErrorMessage || 'invalid' : undefined}
            {...inputProps}
        />
    )
}

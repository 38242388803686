import clsx from 'clsx'

import { useBulkEdit } from '../BulkEdit/bulkedit-context'
import { Column, useTableDataContext } from '../table-context'
import { BulkEditCell } from '../BulkEdit/Cells/BulkEditCell'

import { AggregateSelect } from './AggregateSelect'
import { TABLE_FOOTER_HEIGHT } from './TableFooter'

interface TableHeadCellProps {
    column: Column
    widthScaleFactor: number
    isHoveringRow: boolean
    setIsHoveringRow: (hovering: boolean) => void
}
export const FOOTER_CELL_CLASSES =
    'group font-medium text-sm text-grey-700/60 text-left grid grid-cols-1 grid-flow-col items-center'

export function TableFooterCell({
    column,
    widthScaleFactor,
    isHoveringRow,
    setIsHoveringRow,
}: TableHeadCellProps) {
    const { isStickyLeftActive } = useTableDataContext()!
    const { bulkEditTotal, selectedRecordIds } = useBulkEdit()

    const showBorder = isStickyLeftActive || bulkEditTotal !== 'none'

    return (
        <div
            role="cell"
            className={clsx(
                FOOTER_CELL_CLASSES,
                column.isStickyLeft && showBorder && 'border-r',
                column.isStickyLeft
                    ? bulkEditTotal !== 'none'
                        ? 'bg-grey-700 sticky left-[62px] z-10'
                        : 'bg-white sticky left-0 border-x-grey-700/10 z-10'
                    : undefined,
                bulkEditTotal !== 'none' && selectedRecordIds.length && 'border-white/20'
            )}
            style={{
                // -1 in order to compensate for the border-t of the parent
                height: TABLE_FOOTER_HEIGHT,
                width: column.width * widthScaleFactor,
            }}
        >
            {bulkEditTotal !== 'none' ? (
                <div className="px-2">
                    <BulkEditCell column={column} />
                </div>
            ) : column.hideAggregation ? null : (
                <div
                    className="px-2 py-1 whitespace-nowrap overflow-x-hidden text-ellipsis"
                    title={column.label}
                >
                    <AggregateSelect
                        column={column}
                        showEmpty={isHoveringRow}
                        setIsHoveringRow={setIsHoveringRow}
                    />
                </div>
            )}
        </div>
    )
}

import { useEffect } from 'react'

export function useTitle(title: string) {
    useEffect(() => {
        document.title = title ? `${title} | Laserfocus` : `Laserfocus`
        return () => {
            document.title = `Laserfocus`
        }
    }, [title])
}

import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { pickEmoji } from '@laserfocus/client/util-formatter'
import { AccountId, isContactModel, isOpportunityModel, LeadId } from '@laserfocus/shared/models'
import { ColorPicker, EmojiPicker } from '@laserfocus/ui/emoji-picker'
import { updateEmoji, mutateStack } from '@laserfocus/client/data-access-shared'
import { useSalesobjectEmoji } from '@laserfocus/client/store-shared'

import { Row, useTableDataContext } from '../table-context'
import { useBulkEdit } from '../BulkEdit/bulkedit-context'

import { BulkEditToggleCell } from './BulkEditToggleCell'

interface AvatarCellProps {
    salesObject: Row
    color?: string | null
}

export const AvatarCell = observer(function AvatarCell({ salesObject, color }: AvatarCellProps) {
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)
    const context = useTableDataContext()
    const { bulkEditTotal, toggleEditingRecord, selectedRecordIds } = useBulkEdit()
    const stackId = context?.stackId

    const rootId = getRootId(salesObject)
    const emoji = useSalesobjectEmoji(rootId)

    if (bulkEditTotal !== 'none') {
        return (
            <BulkEditToggleCell
                checked={selectedRecordIds.includes(salesObject.Id)}
                toggle={() => toggleEditingRecord(salesObject.Id)}
            />
        )
    }

    return (
        <EmojiPicker
            isOpen={isEmojiPickerOpen}
            topContent={
                <ColorPicker
                    selectedColor={color ?? undefined}
                    onSubmit={(color) => {
                        setIsEmojiPickerOpen(false)
                        mutateStack.setStackRowColor({
                            id: stackId,
                            type: 'user',
                            values: {
                                color,
                                recordId: salesObject.Id,
                            },
                            optimistic: {
                                modifiedDate: new Date().toISOString(),
                            },
                        })
                    }}
                    allowNull
                />
            }
            onSubmit={(emoji) => {
                setIsEmojiPickerOpen(false)
                if (rootId) {
                    updateEmoji(rootId as LeadId | AccountId, emoji)
                }
            }}
            onCancel={() => setIsEmojiPickerOpen(false)}
        >
            <button
                title="Change emoji"
                disabled={!rootId}
                className={clsx(
                    'font-emoji outline-none cursor-pointer w-[1.875rem] h-[1.875rem] rounded-full bg-grey-700/5 transition hover:bg-grey-700/10 focus-visible:ring disabled:bg-grey-700/5 disabled:cursor-default',
                    isEmojiPickerOpen && 'ring'
                )}
                onClick={() => setIsEmojiPickerOpen(true)}
            >
                {rootId ? emoji || pickEmoji(rootId) : pickEmoji(salesObject.Id)}
            </button>
        </EmojiPicker>
    )
})

function getRootId(salesObject: Row): string | undefined {
    if (isOpportunityModel(salesObject) || isContactModel(salesObject)) {
        return salesObject.AccountId
    }
    return salesObject.Id
}

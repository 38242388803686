export const Identity = 'identity'
export const User = 'user'
export const LFUser = 'lfuser'
export const Role = 'role'
export const Group = 'group'

export const FieldGroup = 'fieldgroup'
export const FieldGroupOrg = 'fieldgroup/org'
export const FieldGroupUser = 'fieldgroup/user'
export const Stack = 'stack'
export const StackUser = 'stack/user'
export const StackOrg = 'stack/org'
export const StackRow = 'stackrows'

export const UserSubscription = 'subscription/user'
export const OrgSubscription = 'subscription/org'

export const ObjectMetadata = 'objectmetadata'
export const RecordType = 'recordtype'

export const Error = 'queryerror'
export const FailedTransaction = 'failedtransaction'

export const Task = 'task'
export const Event = 'event'
export const ContentNote = 'contentnote'

export const Lead = 'lead'
export const Account = 'account'
export const Opportunity = 'opportunity'
export const Contact = 'contact'

export const SyncStatus = 'syncstatus'
export const Name = 'name'
export const SyncStatusActivityHistorySuffix = 'activityhistory'

export const SalesExtension = 'salesextension'
export const PinnedActivities = 'pinnedactivity'
/**
 * @deprecated: Use Org and User Templates
 */
export const NoteTemplate = 'notetemplate'
export const NoteTemplateUser = 'notetemplate/user'
export const NoteTemplateOrg = 'notetemplate/org'

export const ActivityCounter = 'activitycounter'

export const Quota = 'quota'
export const UserInvite = 'invite'

// Value Prefixes
export const OrgSettings = 'settings/org'
export const UserSettings = 'settings/user'
export const Release = 'release'

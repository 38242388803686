import { useState } from 'react'

import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'
import { updateActivity } from '@laserfocus/client/data-access-shared'
import { ActivityUtil, TaskUtil } from '@laserfocus/client/model'

import type { ActivityData } from '../modules/usePersonActivitiesRCQuery'

import { ActivityDateIcon } from './ActivityDateIcon'
import { ActivityBody } from './ActivityBody'
import { ActivityForm } from './ActivityForm'

interface ActivityItemProps {
    activity: ActivityData
}

export const ActivityItem = function ActivityItem({ activity }: ActivityItemProps) {
    const [isEditing, setIsEditing] = useState(false)
    const { location } = useAnalyticsContext()

    return (
        <div
            data-testid="activity-element"
            className="grid grid-flow-col rounded-[0.625rem] p-2 transition-colors hover:bg-grey-700/5 grid-cols-[auto,1fr] group"
        >
            <ActivityDateIcon
                date={ActivityUtil.getDate(activity)}
                overdue={ActivityUtil.isTask(activity) && TaskUtil.isOverdue(activity)}
                className="mr-3"
                pinned={activity.isPinned}
            />
            {isEditing ? (
                <ActivityForm
                    activityType={ActivityUtil.isEvent(activity) ? 'Event' : 'Task'}
                    defaultSubject={activity.Subject || ''}
                    defaultDescription={activity.Description || ''}
                    defaultType={(activity as any).Type}
                    defaultPriority={(activity as any).Priority}
                    onSubmit={({ subject, description, type, priority }) => {
                        return updateActivity({
                            activityId: activity.Id,
                            subject,
                            description,
                            type,
                            priority,
                        }).then(() => {
                            setIsEditing(false)
                            Analytics.trackEvent({
                                event: 'activity_edited',
                                location: location || 'person_details',
                            })
                        })
                    }}
                    onCancel={() => setIsEditing(false)}
                    focusSubjectOnMount
                />
            ) : (
                <ActivityBody activity={activity} startEditing={() => setIsEditing(true)} />
            )}
        </div>
    )
}

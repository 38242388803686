import { useSubscribe } from 'replicache-react'

import { getClient } from '@laserfocus/client/replicache'
import { Identity } from '@laserfocus/shared/models'

export function useIdentity(allowUnauthenticated?: boolean): Identity | null {
    const client = getClient({ ignoreMissingClient: allowUnauthenticated })
    return useSubscribe(client, (tx) => tx.get('identity') as Promise<Identity | null>, null, [])
}

export function useOrgId() {
    const user = useIdentity()
    return user?.OrgId
}

export function useUserId(allowUnauthenticated?: boolean) {
    return useIdentity(allowUnauthenticated)?.Id
}

export function useInstanceUrl(postFix?: string) {
    const user = useIdentity()
    const instanceUrl = user?.InstanceUrl
    return [instanceUrl, postFix].filter((f) => !!f).join('/')
}

export function useIsUnit21() {
    const { isLoading, user } = useSubscribe<{ isLoading: boolean; user?: Identity | null }>(
        getClient(),
        async (tx) => {
            const user = (await tx.get('identity')) as unknown as Identity
            return {
                user,
                isLoading: false,
            }
        },
        { isLoading: true, user: null },
        []
    )

    if (user) {
        return {
            isLoading: false,
            isUnit21:
                user.OrgId === '00D4W000008HyB7UAK' ||
                user.OrgId === '00D1i0000008fNmEAI' ||
                user.OrgId === '00D3h000003yzKUEAY',
        }
    }

    return {
        isLoading,
    }
}

type UnknownArrayOrObject = unknown[] | Record<string, unknown>

// https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
export function getDirtyValues<T = UnknownArrayOrObject>(
    dirtyFields: UnknownArrayOrObject | boolean,
    allValues: T
): Partial<T> {
    // NOTE: Recursive function.

    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues
    }

    // Here, we have an object.
    const child = Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [
            key,
            getDirtyValues(dirtyFields[key], allValues[key]),
        ])
    )
    return child as Partial<T>
}

/**
 * This Metadata is used for the Table to show Activity Fields
 * We could hardcode Task & Event Fields into the client
 */

import { FieldMetadataProps } from '@laserfocus/shared/models'

const common = {
    required: false,
    updateable: true,
    createable: true,
    isCustomField: false,
    referenceTo: [],
    picklistValues: null,
    __typename: 'FieldMetadata' as const,
}

export const ActivityTableMetadata: FieldMetadataProps[] = [
    {
        Id: 'Task.Subject',
        name: 'Subject',
        objectName: 'Task',
        fieldType: 'string',
        label: 'Subject',
        ...common,
    },
    {
        Id: 'Task.ActivityDateTime',
        name: 'ActivityDateTime',
        objectName: 'Task',
        fieldType: 'date',
        label: 'Due Date',
        ...common,
    },
]

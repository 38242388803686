import { useRootStore } from '@laserfocus/client/root-store-context'
interface UserNotification {
    title: string
    description?: string
}
export interface SendUserNotification {
    (notification: UserNotification): void
    success(notification: UserNotification): void
    error(notification: UserNotification): void
}

export interface RootStoreInjection {
    userNotificationStore: UserNotificationStore
}

export function useUserNotificationStore() {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.userNotificationStore
}

export class UserNotificationStore {
    private sendUserNotification: SendUserNotification

    constructor(sendUserNotification: SendUserNotification) {
        this.sendUserNotification = sendUserNotification
    }

    success(notification: UserNotification) {
        this.sendUserNotification.success(notification)
    }

    error(notification: UserNotification) {
        this.sendUserNotification.error(notification)
    }
}

import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Transition } from '@headlessui/react'

import { CloseOutlinedIcon } from '@laserfocus/ui/icons'

import { DropdownButton } from '../dropdowns/controls/DropdownButton'

interface NotificationProps {
    className?: string
    iconComponent?: React.ComponentType<{ className?: string }>
    iconClassName?: string
    children?: React.ReactNode
    visible: boolean
    dismiss?: () => void
}
export function Notification({
    className,
    iconComponent,
    iconClassName,
    children,
    visible,
    dismiss,
}: NotificationProps) {
    const Icon = iconComponent
    return (
        <Transition
            show={visible}
            appear
            className={twMerge(
                'shadow-none text-white bg-grey-700 px-4 py-3 rounded-2xl m-4',
                className
            )}
        >
            <div
                className={clsx(
                    'grid grid-flow-col',
                    dismiss ? 'grid-cols-[auto,1fr,auto]' : 'grid-cols-[auto,1fr]'
                )}
            >
                {Icon && (
                    <div className="self-center">
                        <Icon className={clsx('min-w-[1.5rem] w-6 h-6 mr-3', iconClassName)} />
                    </div>
                )}
                <div>{children}</div>
                {dismiss && (
                    <div>
                        <DropdownButton onClick={dismiss} className="flex-none w-[unset] p-1 ml-3">
                            <CloseOutlinedIcon className="w-4 h-4" />
                        </DropdownButton>
                    </div>
                )}
            </div>
        </Transition>
    )
}

Notification.Title = ({
    children,
    className,
}: {
    children?: React.ReactNode
    className?: string
}) => <div className={twMerge('flex flex-col justify-center', className)}>{children}</div>

Notification.Description = ({
    children,
    className,
}: {
    children?: React.ReactNode
    className?: string
}) => (
    <div
        className={twMerge(
            'max-w-[256px] block pt-1 text-xs leading-[1.3] text-white/60',
            className
        )}
    >
        {children}
    </div>
)

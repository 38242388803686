import { useEffect, useState, useRef } from 'react'

import { useDebounced } from './useDebounced'

interface UseSyncedInputStateOptions {
    debounceTimeout?: number
}

/**
 * Creates local child state which syncs back to parent state debounced.
 * This is useful if we want to drive inputs by state which is too slow to drive UI, like an async DB
 */
export function useSyncedInputState(
    parentValue: string,
    setParentValue: (value: string) => void,
    { debounceTimeout = 3000 }: UseSyncedInputStateOptions = {}
) {
    const debouncedSetParentValue = useDebounced(setParentValue, debounceTimeout)

    const [childValue, setChildValue] = useState(parentValue)
    const [isFocused, setIsFocused] = useState(false)

    /**
     * This is a hack, when blurring, we switch temporarily to a stale parentValue
     * since Replicache does not propagate the result fast enought. This will change
     * in Replicache 9.0
     */
    const blurTimeoutRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
        return () => {
            if (blurTimeoutRef.current) {
                clearTimeout(blurTimeoutRef.current)
            }
        }
    }, [])

    // Flush pending parent state updates when component gets unmounted
    useEffect(() => debouncedSetParentValue.flush, [debouncedSetParentValue])

    return {
        value: isFocused ? childValue : parentValue,
        onChange(event: { target: { value: string } }) {
            setChildValue(event.target.value)
            debouncedSetParentValue(event.target.value)
        },
        onFocus() {
            setChildValue(parentValue)
            setIsFocused(true)
            if (blurTimeoutRef.current) {
                clearTimeout(blurTimeoutRef.current)
            }
        },
        onBlur() {
            blurTimeoutRef.current = setTimeout(() => setIsFocused(false), 500)
            debouncedSetParentValue.flush()
        },
    }
}

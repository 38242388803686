import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { twMerge } from 'tailwind-merge'

import { ModalInput } from '@laserfocus/ui/beam'
import {
    CallOutlinedIcon,
    ExternalLinkOutlinedIcon,
    LockOutlinedIcon,
    MailOutlinedIcon,
} from '@laserfocus/ui/icons'
import { HighlightedCellValue } from '@laserfocus/client/ui-shared-datagrid'

import { Column } from '../table-context'

import { TableCellProps } from './TableCell'
import { CLASS_NAME_INPUT_BUTTON_EMPTY, CLASS_NAME_LOCK_ICON } from './shared-class-names'

export const TextCellWithAction = observer(function TextCellWithAction({
    salesObject,
    column,
    searchTerm,
    tableRef,
}: TableCellProps) {
    const [isInputOpen, setIsInputOpen] = useState(false)

    const value = (column.getValue(salesObject) as string | null) ?? ''
    const [inputValue, setInputValue] = useState(value)
    const isEditable = column.isEditable(salesObject)

    function close() {
        setIsInputOpen(false)
        if (inputValue !== value) {
            column.updateValue(salesObject, inputValue)
        }
    }

    function open() {
        setInputValue(value)
        setIsInputOpen(true)
    }

    return (
        <ModalInput
            name={column.key}
            isOpen={isInputOpen}
            value={inputValue}
            overflowBoundaryRef={tableRef}
            onChange={(event) => setInputValue(event.target.value)}
            onClose={close}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault()
                    close()
                }
            }}
            inputClassName="w-[18.75rem]"
        >
            <div className="rounded-md grid grid-cols-[minmax(0,1fr),auto] hover:bg-grey-700/5 group-inner">
                <button
                    title={value}
                    disabled={!isEditable}
                    className={twMerge(
                        'grid grid-cols-[minmax(0,1fr),auto,auto] items-center px-2 py-[0.3125rem] outline-none leading-[1.15] cursor-pointer text-left rounded-md transition tabular-nums focus-visible:ring disabled:cursor-default',
                        !value && CLASS_NAME_INPUT_BUTTON_EMPTY
                    )}
                    onClick={open}
                >
                    <HighlightedCellValue value={value} searchTerm={searchTerm} />

                    {!isEditable && <LockOutlinedIcon className={CLASS_NAME_LOCK_ICON} />}
                </button>
                {getAction(value, column)}
            </div>
        </ModalInput>
    )
})

const iconByColumnType: Partial<
    Record<Column['type'], React.ComponentType<{ className?: string }>>
> = {
    phone: CallOutlinedIcon,
    url: ExternalLinkOutlinedIcon,
    email: MailOutlinedIcon,
}

function getAction(value: string, column: Column) {
    if (value === '') {
        return null
    }

    const Icon = iconByColumnType[column.type]

    if (!Icon) {
        return null
    }

    return (
        <a
            title={getActionTitle(value, column)}
            href={getActionHref(value, column)}
            target="_blank"
            rel="noopener noreferrer"
            className="block text-grey-700/20 rounded-md outline-none hover:!bg-blue-700 focus-visible:ring group-inner-hover:text-white group-inner-hover:bg-blue-500"
        >
            <Icon className="w-4 h-4 p-[0.4375rem] box-content" />
        </a>
    )
}

function getActionHref(value: string, column: Column) {
    if (column.type === 'phone') {
        return `tel:${value}`
    }

    if (column.type === 'email') {
        return `mailto:${value}`
    }

    if (column.type === 'url') {
        return value.startsWith('http://') || value.startsWith('https://')
            ? value
            : `https://${value}`
    }

    return ''
}

function getActionTitle(value: string, column: Column) {
    if (column.type === 'phone') {
        return `Call ${value}`
    }

    if (column.type === 'email') {
        return `Mail to ${value}`
    }

    if (column.type === 'url') {
        return `Open ${value} in new tab`
    }

    return ''
}

import {
    StackMutatorSchema,
    SyncedRootsMutatorSchema,
    ActivityMutatorSchema,
    FieldGroupMutatorSchema,
    SObjectMutatorSchema,
    NotesMutatorSchema,
    Mutations,
    SyncModule,
    NoteTemplateMutatorSchema,
    ActivityCounterMutatorSchema,
    SettingsMutatorSchema,
    QuerySchedule,
    isTruthy,
} from '@laserfocus/shared/models'

const QUERY_ALL = 'ALL' as const
const QUERY_SKIP = 'SKIP' as const

export type Mutation =
    | Mutations<typeof StackMutatorSchema>
    | Mutations<typeof SyncedRootsMutatorSchema>
    | Mutations<typeof ActivityMutatorSchema>
    | Mutations<typeof FieldGroupMutatorSchema>
    | Mutations<typeof SObjectMutatorSchema>
    | Mutations<typeof NotesMutatorSchema>
    | Mutations<typeof NoteTemplateMutatorSchema>
    | Mutations<typeof ActivityCounterMutatorSchema>
    | Mutations<typeof SettingsMutatorSchema>

const fieldGroupMutations = Object.keys(FieldGroupMutatorSchema) as Array<
    keyof typeof FieldGroupMutatorSchema
>

const stackMutations: Array<keyof typeof StackMutatorSchema> = [
    'updateStack',
    'saveStack',
    'shareStack',
    'showColumn',
    'hideColumn',
    'deleteStack',
    'resetStack',
    'deactivateStack',
    'setColumnsOrder',
    'setColumnWidth',
    'setSorting',
    'setFilterCondition',
    'removeFilter',
    'favoriteStack',
    'unfavoriteStack',
    'createStackFromView',
    'setColumnAggregate',
]

export function shouldQuerySpecific(mutations: Mutation[]): QuerySchedule {
    const myId = (window as any).$store?.sessionStore?.myId
    const orgId = (window as any).$store?.sessionStore.me?.OrgId
    const plans: QuerySchedule[] = mutations.map((mut): QuerySchedule => {
        switch (mut.name) {
            // Activity
            case 'completeTask':
            case 'uncompleteTask':
            case 'rescheduleTask':
            case 'updateTask':
                return { current: ['openTaskResult', 'taskHistoryResult', 'failedTransactions'] }
            case 'rescheduleEvent':
            case 'updateEvent':
                return { current: ['openEventResult', 'eventHistoryResult', 'failedTransactions'] }
            case 'deleteActivity':
                //@TODO: could limit this more with check on activityId
                return {
                    current: [
                        'openTaskResult',
                        'taskHistoryResult',
                        'openEventResult',
                        'eventHistoryResult',
                        'taskDeletedResult',
                        'eventDeletedResult',
                        'failedTransactions',
                    ],
                }
            case 'logActivity':
                return { current: ['taskHistoryResult', 'failedTransactions'] }
            case 'scheduleTask':
                return { current: ['openTaskResult', 'failedTransactions'] }
            case 'pinActivity':
            case 'unpinActivity':
            case 'pinNote':
            case 'unpinNote': {
                return { current: ['pinned', 'failedTransactions'] }
            }
            // Note Template
            case 'createNoteTemplate':
            case 'updateNoteTemplate':
            case 'deleteNoteTemplate':
                return { current: ['noteTemplates', 'failedTransactions'] }
        }
        if (fieldGroupMutations.includes(mut.name as keyof typeof FieldGroupMutatorSchema)) {
            return { current: ['fieldGroups', 'failedTransactions'] }
        }
        if (stackMutations.includes(mut.name as keyof typeof StackMutatorSchema)) {
            return { current: ['stacks', 'failedTransactions'] }
        }

        switch (mut.name) {
            case 'updateObject':
            case 'createObject': {
                switch (mut.args.sobject) {
                    case 'Lead':
                        return { current: ['leadResult', 'failedTransactions'] }
                    case 'Account':
                        return { current: ['accountResult', 'failedTransactions'] }
                    case 'Opportunity':
                        return {
                            current: [
                                'opportunityResult',
                                'failedTransactions',
                                myId === '0053h000002bGTzAAM' ? 'taskHistoryResult' : undefined,
                            ].filter(isTruthy) as SyncModule[],
                        }
                    case 'Contact':
                        return {
                            current: [
                                'contactResult',
                                'failedTransactions',
                                // Clean instance
                                orgId === '00D3h000003yzKUEAY' ? 'openTaskResult' : undefined,
                            ].filter(isTruthy) as SyncModule[],
                        }
                }
                break
            }
            case 'discardFailedMutation':
                return { current: ['failedTransactions'] }
            case 'syncNames':
                return {
                    current: ['nameResult'],
                }
            case 'visitRoot':
            case 'prefetchRoots':
                return {
                    current: [
                        'taskHistoryResult',
                        'eventHistoryResult',
                        'leadResult',
                        'accountResult',
                        'opportunityResult',
                        'contactResult',
                        'contentnotes',
                    ],
                }
            case 'convertLead':
                return {
                    current: [
                        'failedTransactions',
                        'leadResult',
                        'accountResult',
                        'opportunityResult',
                        'contactResult',
                    ],
                }
            // Notes
            case 'updateNoteBody':
            case 'updateNoteTitle':
            case 'updateContentDocument':
            case 'createNote':
                return { current: ['contentnotes', 'failedTransactions'] }
            case 'deleteNote':
                return { current: ['contentnotesDeleted', 'failedTransactions'] }
            // Activity Counter
            case 'countActivity':
                return { current: 'SKIP', next: ['activityCounter'] }
            // Quota
            case 'setQuota':
                return { current: ['quota', 'failedTransactions'] }
            case 'setStackRowColor':
            case 'setStackRows':
                return { current: ['stackrows', 'failedTransactions'] }
        }

        return {
            current: 'ALL',
        }
    })

    if (plans.find((a) => a.current === QUERY_ALL)) {
        return {
            current: QUERY_ALL,
        }
    }

    const hasSkip = plans.some((a) => a.current === 'SKIP')

    const allCurrent = [
        ...new Set(plans.flatMap((f) => (f.current === QUERY_SKIP ? [] : f.current))),
    ] as SyncModule[]

    const next = [
        ...new Set(
            plans.flatMap((p) => {
                return (p.current === 'SKIP' ? p.next : []) || []
            })
        ),
    ]
    if (hasSkip && allCurrent.length === 0) {
        return {
            current: QUERY_SKIP,
            next,
        }
    }

    return {
        current: [...allCurrent, ...next],
    }
}

import { Path, useController } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { Input } from '@laserfocus/ui/beam'
import { getCurrencyFormatter } from '@laserfocus/client/util-formatter'
import { useCurrency } from '@laserfocus/client/feature-auth'

import { FormControlProps, SalesObject } from '../get-form-control'
import { useFormControlContext } from '../FormControlContext'

import { getControllerRules, getInputProps, useStateReducer } from './formcontrol-util'

export const FormControlCurrency = observer(function FormControlCurrency<
    T extends SalesObject = SalesObject
>({ fieldMetadata, id, variant, control, submit, name }: FormControlProps<T>) {
    const { field, fieldState } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })

    const { currencyIsoCode } = useFormControlContext()
    const orgCurrency = useCurrency()
    const currencyFormatter = getCurrencyFormatter(currencyIsoCode || orgCurrency)

    const [state, setState] = useStateReducer({
        isFocused: false,
        inputValue: field.value ? field.value.toString() : '',
    })
    useEffect(() => {
        if (!state.isFocused) {
            setState({ inputValue: field.value ? field.value.toString() : '' })
        }
    }, [field.value, setState, state.isFocused])

    return (
        <Input
            id={id}
            variant={variant}
            disabled={!fieldMetadata.updateable}
            {...getInputProps(field, fieldState, state, setState, submit, {
                formatDisplayValue: (v) => (v ? currencyFormatter.format(v) : ''),
                transformValue,
            })}
        />
    )
})

function transformValue(v: string | null): number | null {
    if (!v) {
        return null
    }
    const parsed = parseFloat(v)
    return round(parsed)
}
function round(value: number, decimals: number = 2) {
    const valueString = (value + 'e' + decimals) as unknown as number
    return Number(Math.round(valueString) + 'e-' + decimals)
}

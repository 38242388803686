import { ParseReturnType, z, ZodNull } from 'zod'

import { setFallback } from './fallbacks-store'
import { reportFallbacks } from './report-fallbacks'

/**
 * Validates schema passed as argument and silently replaces incorrect input value with fallback value. Fallback value must conform to schema type. If schema is validated with `decode()`, triggered fallbacks here get reproted alongside data or error.
 */
export function withFallback<Schema extends z.Schema<any>>(
    schema: Schema,
    fallbackValue: z.infer<Schema>
): Schema {
    const zodFallback = z.unknown()

    zodFallback._parse = (ctx): ParseReturnType<z.infer<Schema>> => {
        // We want to "swallow" fallbacks reported in this parse because they get reported to the parent already when schema gets validated before triggering the fallback.
        const parsed = reportFallbacks(() => schema.safeParse(ctx.data, { path: ctx.path }))

        if (parsed.success) {
            return parsed.data
        }

        setFallback((parsed as any).error)
        if (parsed.fallback) {
            setFallback(parsed.fallback)
        }
        return {
            status: 'valid',
            value: fallbackValue,
        }
    }

    return zodFallback.or(schema) as unknown as Schema
}

/**
 * Works similar to `withFallback()` with two differences:
 * 1. Fallback value is hardcoded to null and doesn't need to be passed as argument
 * 2. Although null value is part of output type, a null value does *not* pass validation and gets reported as fallback in `decode()`.
 */
export function withNullFallback<Schema extends z.Schema<any>>(schema: Schema) {
    return withFallback(schema, null) as unknown as z.ZodUnion<[Schema, ZodNull]>
}

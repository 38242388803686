// Fork of trie-search with TypeScript definitions. More info: https://github.com/joshjung/trie-search/issues/30
import { TrieSearch } from '@committed/trie-search'

import { emojisData as allEmojis } from '@laserfocus/shared/emoji-data'

interface EmojiCategory {
    category: string
    emojis: Emoji[]
}

export interface Emoji {
    name: string
    emoji: string
    keywords: string[]
}

let emojiTrie: TrieSearch<Emoji>

export function getEmojis(searchValue: string): EmojiCategory[] {
    if (!searchValue) {
        return allEmojis
    }

    if (!emojiTrie) {
        emojiTrie = createEmojiTrie()
    }

    return [
        {
            category: 'Search results',
            emojis: emojiTrie.get(searchValue),
        },
    ]
}

// Initialising the trie is an expensive operation, so we want to do it only if necessary and store the trie in memory afterwards.
function createEmojiTrie() {
    const emojiTrie = new TrieSearch<Emoji>(undefined, { indexField: 'emoji' })

    allEmojis.forEach(({ emojis }) => {
        emojis.forEach((emojiObject) => {
            const { emoji, name, keywords } = emojiObject

            emojiTrie.map(name, emojiObject)
            emojiTrie.map(emoji, emojiObject)

            keywords.forEach((keyword) => {
                emojiTrie.map(keyword, emojiObject)
            })
        })
    })

    return emojiTrie
}

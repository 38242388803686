import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function SignalHighFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M6.23 12H5.02c-.84 0-1.52.68-1.52 1.52v5.46c0 .84.68 1.52 1.52 1.52h1.21c.84 0 1.52-.68 1.52-1.52v-5.46c0-.84-.68-1.52-1.52-1.52Zm6.37-3.64h-1.2c-.85 0-1.53.68-1.53 1.52v9.1c0 .84.68 1.52 1.52 1.52h1.22c.84 0 1.52-.68 1.52-1.52v-9.1c0-.84-.68-1.52-1.52-1.52Zm6.38-4.86h-1.21c-.84 0-1.52.68-1.52 1.52v13.96c0 .84.68 1.52 1.52 1.52h1.21c.84 0 1.52-.68 1.52-1.52V5.02c0-.84-.68-1.52-1.52-1.52Z" />
        </SvgIcon>
    )
}

import 'reflect-metadata'
import { action, computed, observable, runInAction, when } from 'mobx'
import type { ReadTransaction, Replicache } from 'replicache'

import { ObjectPool, QueryStatus } from '@laserfocus/client/data-layer'
import { getStackTemplates, Identity, Prefix } from '@laserfocus/shared/models'
import type { OrgStack, Stack } from '@laserfocus/shared/models'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { combineUserOrgStacks } from '@laserfocus/client/data-access-shared'
import { assert } from '@laserfocus/shared/util-error'

import { RCFetchableStack } from './legacy-fetching/RCFetchableStack'
import type { FetchableStackOptions } from './legacy-fetching/RCFetchableStack'
import { mutablyUpdateStack } from './update-stack'

type Dependencies = {
    objectPool: ObjectPool
    replicache: Replicache
    queryStatus: QueryStatus
}
export class BridgeStackStore {
    objectPool: ObjectPool
    replicache: Replicache
    queryStatus: QueryStatus

    reactions: Array<() => void> = []

    @observable orgStacksById: Map<string, OrgStack> = new Map()
    @observable fetchableOrgStacksById: Map<string, RCFetchableStack> = new Map()

    @observable baseStacksById: Map<string, Stack> = new Map()
    @observable fetchableStacksById: Map<string, RCFetchableStack> = new Map()
    @observable initiallyLoaded = false

    constructor({ objectPool, replicache, queryStatus }: Dependencies) {
        this.objectPool = objectPool
        this.replicache = replicache
        this.queryStatus = queryStatus
    }

    @computed
    get userId() {
        return this.objectPool.getSingle<Identity>('Identity')?.Id
    }

    onInit() {
        this.observeReplicache()
        this.initTemplates()
    }

    onDestroy() {
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    async runCheck(): Promise<unknown> {
        await when(() =>
            Boolean(
                Object.keys(this.baseStacksById).length &&
                    Object.keys(this.fetchableStacksById).length
            )
        )

        const stacks = Array.from(this.fetchableStacksById.values())
        const tasks = []

        const leadStack = stacks.find((s) => s.stack.sobject === 'Lead')
        const opportunityStack = stacks.find((s) => s.stack.sobject === 'Opportunity')
        const accountStack = stacks.find((s) => s.stack.sobject === 'Account')

        if (leadStack && leadStack.currentRecordIds.length === 0) {
            tasks.push(leadStack.prefetch().then(() => leadStack.stack.title))
        }
        if (opportunityStack && opportunityStack.currentRecordIds.length === 0) {
            tasks.push(opportunityStack.prefetch().then(() => opportunityStack.stack.title))
        }
        if (accountStack && accountStack.currentRecordIds.length === 0) {
            tasks.push(accountStack.prefetch().then(() => accountStack.stack.title))
        }
        return Promise.all(tasks)
    }

    observeReplicache() {
        this.reactions.push(
            this.replicache.subscribe(
                async (tx: ReadTransaction) => {
                    const stacks = await tx
                        .scan({ prefix: `${Prefix.Stack}/` })
                        .entries()
                        .toArray()
                    const onlyNewSchema = stacks
                        .filter(
                            ([key, stack]) =>
                                key.startsWith(Prefix.StackUser) || key.startsWith(Prefix.StackOrg)
                        )
                        .map(([key, stack]) => stack)
                    return (onlyNewSchema as Stack[]) || []
                },
                {
                    onData: (data: Stack[]) => {
                        if (data.length) {
                            runInAction(() => (this.initiallyLoaded = true))
                        }
                        const merged = combineUserOrgStacks(data)
                        merged.forEach((stack) => this.receiveStackFromBackend(stack))
                        const orgStacks = data.filter((a) => a.__typename === 'OrgStack')
                        orgStacks.forEach((stack) => this.receiveOrgStackFromBackend(stack))
                    },
                }
            )
        )
    }

    @action
    initTemplates() {
        const templates = getStackTemplates()
        templates.forEach((template) => {
            const withId: Stack = {
                version: 1,
                createdDate: new Date().toISOString(),
                modifiedDate: new Date().toISOString(),
                __typename: 'UserStack',
                columnAggregates: {},
                columnWidths: {},

                ...template,
                id: `template-${template.preset}`,
            }
            this.receiveStackFromBackend(withId)
        })
    }

    @action
    receiveStackFromBackend(stack: Stack, options?: FetchableStackOptions) {
        const existing = this.baseStacksById.get(stack.id)
        // This will prevent the real update to overwrite the backend update.
        if (!existing) {
            this.baseStacksById.set(stack.id, stack)
        } else {
            mutablyUpdateStack(existing, stack)
        }
        const fetchable = this.fetchableStacksById.get(stack.id)
        if (!fetchable) {
            this.initFetchableStackFromStack(stack, options)
        }
    }

    @action
    receiveOrgStackFromBackend(stack: OrgStack) {
        // const log = stack.id === '01G92506BY8N0W563XR2C4AWGD'
        // log && console.log('Receiving da stack')
        const existing = this.orgStacksById.get(stack.id)
        if (!existing) {
            // log && console.log('initially setting the stack')
            this.orgStacksById.set(stack.id, stack)
        } else {
            mutablyUpdateStack(existing, stack)
        }
    }

    @action
    initFetchableStackFromOrgStack(stack: Stack, options?: FetchableStackOptions) {
        if (this.fetchableOrgStacksById.has(stack.id)) {
            return
        }
        const fetchable = new RCFetchableStack(
            {
                objectPool: this.objectPool,
                replicache: this.replicache,
                queryStatus: this.queryStatus,
            },
            () => this.orgStacksById.get(stack.id)!,
            options
        )
        fetchable.onInit()
        this.fetchableOrgStacksById.set(stack.id, fetchable)
    }

    initFetchableStackFromStack(stack: Stack, options?: FetchableStackOptions) {
        if (this.fetchableStacksById.has(stack.id)) {
            return
        }
        const fetchable = new RCFetchableStack(
            {
                objectPool: this.objectPool,
                replicache: this.replicache,
                queryStatus: this.queryStatus,
            },
            () => this.getStack(stack.id),
            options
        )
        fetchable.onInit()
        this.fetchableStacksById.set(stack.id, fetchable)
    }

    getStack(stackId: string): Stack {
        const baseStack = this.baseStacksById.get(stackId)
        assert(baseStack, `Stack not found: ${stackId}`)
        return baseStack
    }
}

export function useBridgeStackStore() {
    const rootStore = useRootStore<{ stackBridge: BridgeStackStore }>()
    return rootStore.stackBridge
}

import { FeatureList } from '@laserfocus/client/ui-shared'
import { Button, Modal } from '@laserfocus/ui/beam'
import { ExternalLinkOutlinedIcon } from '@laserfocus/ui/icons'

import { CALENDLY_LINK } from './ProPlanModal'

interface InviteCoworkerModalProps {
    show: boolean
    closeModal: () => void
}
export function TeamPlanModal({ show, closeModal, ...rest }: InviteCoworkerModalProps) {
    return (
        <Modal show={show} onClose={closeModal}>
            <Modal.Overlay />
            <Modal.Container>
                <Modal.Header close={closeModal} border>
                    Upgrade to Team
                </Modal.Header>
                <div className="mx-8 my-3 grid gap-2">
                    <div className="mt-2 border-2 border-grey-700/10 p-4 pt-6">
                        <div className="mb-2">
                            Team includes all amazing features from the Pro plan and more
                        </div>
                        <div className="grid grid-flow-col p-2">
                            <div>
                                <h2 className="font-bold mb-2">Pro</h2>
                                <FeatureList
                                    features={[
                                        'Custom stacks',
                                        'Schedule your stacks',
                                        'Note templates',
                                        'Custom field groups',
                                        'Bulk Edit',
                                    ]}
                                />
                            </div>
                            <div>
                                <h2 className="font-bold mb-2">Team</h2>
                                <FeatureList
                                    features={[
                                        'Role-based prcesses',
                                        'Org Stacks',
                                        'Org Note templates',
                                        'Org field groups',
                                        'Automated workflows',
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="text-center">
                            Do you want to use Laserfocus for your Team?
                        </div>
                    </div>
                    <div className="mt-2">
                        <Button
                            variant="primary"
                            type="submit"
                            className="w-full justify-center"
                            as="a"
                            href={CALENDLY_LINK}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Schedule a Demo
                            <ExternalLinkOutlinedIcon className="ml-1 w-3 h-3" />
                        </Button>
                    </div>
                </div>
            </Modal.Container>
        </Modal>
    )
}

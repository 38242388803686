import { mapValues } from 'lodash'

import { AccountFields } from '../sales/account.types'
import { BaseActivityFields, EventFields, TaskFields } from '../sales/activity.types'
import { ContactFields } from '../sales/contact.types'
import { LeadFields } from '../sales/lead.types'
import { OpportunityFields } from '../sales/opportunity.types'

import { SObjectType } from './metadata.types'

type LeadFieldKeys = 'Id' | keyof LeadFields

type Invalid<T> = ['Needs to be all of', T]
const arrayOfAll =
    <T>() =>
    <U extends T[]>(...array: U & ([T] extends [U[number]] ? unknown : Invalid<T>[])) =>
        array
const arrayOfAllLeadFields = arrayOfAll<LeadFieldKeys>()

export const LEAD_FIELDS = arrayOfAllLeadFields(
    'CreatedDate',
    'CreatedById',
    'LastModifiedDate',
    'LastModifiedById',
    // SF Standard'
    'Id',
    'Status',
    'FirstName',
    'LastName',
    'Name',
    'Title',
    'Company',
    'City',
    'Email',
    'Phone',
    'MobilePhone',
    'Website',
    'LeadSource',
    'Description',
    'Rating',
    'IsConverted',
    'LastActivityDate',
    'AvatarUrl',
    'CurrencyIsoCode',
    'CountryCode',

    'Street',
    'State',
    'PostalCode',
    'City',
    'Country',
    'GeocodeAccuracy',
    'Latitude',
    'Longitude',

    'RecordTypeId',

    'ConvertedAccountId',
    'ConvertedContactId',
    'ConvertedOpportunityId',
    'OwnerId'
)

type AccountFieldKeys = 'Id' | keyof AccountFields
const arrayOfAllAccountFields = arrayOfAll<AccountFieldKeys>()
export const ACCOUNT_FIELDS = arrayOfAllAccountFields(
    'CreatedDate',
    'CreatedById',
    'LastModifiedDate',
    'LastModifiedById',
    'Id',
    'Name',
    'Phone',
    'Website',

    'CurrencyIsoCode',

    'ShippingStreet',
    'ShippingState',
    'ShippingPostalCode',
    'ShippingCity',
    'ShippingCountry',
    'ShippingGeocodeAccuracy',
    'ShippingLatitude',
    'ShippingLongitude',

    'BillingStreet',
    'BillingState',
    'BillingPostalCode',
    'BillingCity',
    'BillingCountry',
    'BillingGeocodeAccuracy',
    'BillingLatitude',
    'BillingLongitude',

    'RecordTypeId',
    'LastActivityDate',

    'OwnerId'
)

type ContactFieldKeys = 'Id' | keyof ContactFields
const arrayOfAllContactFields = arrayOfAll<ContactFieldKeys>()
export const CONTACT_FIELDS = arrayOfAllContactFields(
    'CreatedDate',
    'CreatedById',
    'LastModifiedDate',
    'LastModifiedById',
    'Id',
    'AccountId',
    'Name',
    'HomePhone',
    'MobilePhone',
    'OtherPhone',

    'OwnerId',
    'FirstName',
    'LastName',
    'Email',
    'Phone',
    'Title',
    'Department',
    'Description',
    'AvatarUrl',
    'LastActivityDate',
    'RecordTypeId',

    'CurrencyIsoCode',

    'MailingStreet',
    'MailingState',
    'MailingPostalCode',
    'MailingCity',
    'MailingCountry',
    'MailingGeocodeAccuracy',
    'MailingLatitude',
    'MailingLongitude',

    'OtherStreet',
    'OtherState',
    'OtherPostalCode',
    'OtherCity',
    'OtherCountry',
    'OtherGeocodeAccuracy',
    'OtherLatitude',
    'OtherLongitude'
)
type OpportunityFieldKeys = 'Id' | keyof OpportunityFields
const arrayOfAllOpportunityFields = arrayOfAll<OpportunityFieldKeys>()
export const OPPORTUNITY_FIELDS = arrayOfAllOpportunityFields(
    // BaseFields
    'Id',
    'CurrencyIsoCode',
    'CreatedDate',
    'CreatedById',
    'LastModifiedDate',
    'LastModifiedById',
    'RecordTypeId',
    // Fields
    'Name',
    'StageName',
    'IsClosed',
    'IsWon',
    'Probability',
    'Amount',
    'ExpectedRevenue',
    'LastActivityDate',
    'CloseDate',

    'LastStageChangeDate',
    'CurrencyIsoCode',
    'HasOpportunityLineItem',

    // RelationShips
    'OwnerId',
    'AccountId',
    'OwnerId'
)

type TaskFieldKeys = 'Id' | 'TaskSubtype' | keyof BaseActivityFields | keyof TaskFields
const arrayOfAllTaskFields = arrayOfAll<TaskFieldKeys>()
export const TASK_FIELDS = arrayOfAllTaskFields(
    'CreatedDate',
    'CreatedById',
    'LastModifiedDate',
    'LastModifiedById',
    'RecordTypeId',
    'CurrencyIsoCode',

    'Id',
    'Subject',
    'Description',
    'IsClosed',
    'Phone',
    'Outcome',
    'Subtype',

    'WhoId',
    'OwnerId',
    'WhatId',
    'AccountId',
    'AlternateDetailId',
    // "ActivityDate",
    'ActivityDateTime',
    'ReminderDateTime',
    'Status',
    'CompletedDateTime',

    'TaskSubtype'
)

type EventFieldKeys = 'Id' | 'EventSubtype' | keyof BaseActivityFields | keyof EventFields
const arrayOfAllEventFields = arrayOfAll<EventFieldKeys>()
export const EVENT_FIELDS = arrayOfAllEventFields(
    'CreatedDate',
    'CreatedById',
    'LastModifiedDate',
    'LastModifiedById',
    'RecordTypeId',
    'CurrencyIsoCode',

    'Id',
    'Subject',
    'Description',
    'IsClosed',
    'Phone',
    'Outcome',
    'Subtype',

    'WhoId',
    'OwnerId',
    'WhatId',
    'AccountId',
    'AlternateDetailId',
    // "ActivityDate",
    'StartDateTime',
    'EndDateTime',

    'EventSubtype'
)

export const ACTIVITY_FIELDS = ['ActivitySubtype', ...TASK_FIELDS, ...EVENT_FIELDS]

export const EMAIL_MESSAGE_FIELDS = ['EmailMessageId']

/**
 * Name Relation
 * https://developer.salesforce.com/docs/atlas.en-us.sfFieldRef.meta/sfFieldRef/salesforce_field_reference_Name.htm
 */
export const WHO_FIELDS = [
    'Alias',
    'Email',
    'FirstName',
    'Id',
    'IsActive',
    'LastName',
    'LastReferencedDate',
    'LastViewedDate',
    'Name',
    'NameOrAlias',
    'Phone',
    'ProfileId',
    'RecordTypeId',
    'Title',
    'Type',
    'Username',
    'UserRoleId',
]

export const STANDARD_FIELDS: Record<SObjectType, string[]> = {
    Lead: LEAD_FIELDS,
    Opportunity: OPPORTUNITY_FIELDS,
    Contact: CONTACT_FIELDS,
    Account: ACCOUNT_FIELDS,
    Activity: ACTIVITY_FIELDS,
    Task: ACTIVITY_FIELDS,
    Event: ACTIVITY_FIELDS,
    EmailMessage: EMAIL_MESSAGE_FIELDS,
    User: [],
    Group: [],
    // [SObjectType.Product]: []
}

const BLOCKLIST_ALL_OBJECTS = ['CloneSourceId']

const BLOCKLIST_FIELD_MAP: Record<SObjectType, string[]> = {
    Lead: ['Address'],

    /**
     * MH:
     * This list is from a salesforce codebase
     * https://github.com/SalesforceFoundation/NPSP/blob/ef653636c3fe021844f625e06c95e4b62fd7974a/src/classes/CRLP_RollupUI_SVC.cls#L43-L58
     * I unfortunately don't know how to categorically prevent the issues by these fields
     *
     * It seems that those fields are returned from the UI Api, but when querying the user does not have access
     * to them. When using a "describe" call, those fields are not returned (tested for LastStageChangeDate)
     */
    Opportunity: [
        'OpportunityScoreId',
        'OpportunityScore',
        'TopInsightId',
        'TopInsight',
        'ActivityMetricId',
        'ActivityMetricId',
        'ActivityMetricRollupId',
        'ActivityMetricRollup',
        // New fields apparently having same issue
        // 'LastStageChangeDate',
    ],
    Contact: ['MailingAddress', 'OtherAddress'],
    Account: ['BillingAddress', 'ShippingAddress'],
    Activity: [],
    Task: [],
    Event: [],
    EmailMessage: [],
    User: [],
    Group: [],
}

export const BLOCKLIST_FIELDS = mapValues(BLOCKLIST_FIELD_MAP, (value) => [
    ...value,
    ...BLOCKLIST_ALL_OBJECTS,
])

import { twMerge } from 'tailwind-merge'

interface FormErrorsProps {
    errors: string[]
    className?: string
}

export function FormErrors({ errors, className }: FormErrorsProps) {
    if (!errors || errors.length === 0) {
        return null
    }
    return (
        <ul className={twMerge('list-disc pl-3', className)}>
            {errors.map((msg) => (
                <li className="text-sm font-medium leading-[1.5] text-red-500">{msg}</li>
            ))}
        </ul>
    )
}

import { Switch, SwitchProps } from '@laserfocus/ui/beam'
import { LockOutlinedIcon } from '@laserfocus/ui/icons'

import { TableCellProps } from './cell.types'

type SwitchCellProps = Omit<TableCellProps, 'value' | 'updateValue'> &
    Omit<SwitchProps, 'checked' | 'value'> & {
        value: boolean | null
        updateValue(v: boolean): void
    }

export function SwitchCell({ value, readOnly, updateValue, name, ...rest }: SwitchCellProps) {
    const switchJsx = (
        <Switch
            name={name}
            checked={value as boolean}
            disabled={readOnly}
            onChange={(event) => {
                updateValue(event.target.checked)
            }}
            {...rest}
        />
    )

    return readOnly ? (
        <div className="grid h-full items-center justify-start grid-flow-col group-inner">
            {switchJsx}
            <LockOutlinedIcon className="hidden group-inner-hover:block w-4 h-4 text-grey-700/60" />
        </div>
    ) : (
        switchJsx
    )
}

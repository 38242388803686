import clsx from 'clsx'

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ArrowLeft2OutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M11.2 4.3c.41.38.41 1.02 0 1.4L5.67 11h16.3c.58 0 1.05.45 1.05 1s-.47 1-1.05 1H5.67l5.53 5.3c.41.38.41 1.02 0 1.4-.4.4-1.07.4-1.48 0l-7.32-7a.97.97 0 0 1 0-1.4l7.32-7c.41-.4 1.07-.4 1.48 0Z" />
        </SvgIcon>
    )
}

export function ArrowRight2OutlinedIcon(props: SvgIconProps) {
    return <ArrowLeft2OutlinedIcon {...props} className={clsx('rotate-180', props.className)} />
}

import { useEffect, useState } from 'react'

import { useHotKey } from '@laserfocus/ui/util-keyboard'

interface UseKeyboardNavigationProps {
    optionsLength: number
    resetKey: string | undefined
    submit?(optionIndex: number): void
}

export function useDropdownKeyboardNavigation({
    optionsLength,
    resetKey,
    submit,
}: UseKeyboardNavigationProps) {
    const [hoveredOptionIndex, setHoveredOptionIndex] = useState(0)

    useEffect(() => {
        setHoveredOptionIndex(0)
    }, [resetKey])

    useHotKey(
        'down',
        (event) => {
            event.preventDefault()
            setHoveredOptionIndex(getNextOptionIndex(optionsLength, hoveredOptionIndex, 1))
        },
        { global: true }
    )

    useHotKey(
        'up',
        (event) => {
            event.preventDefault()
            setHoveredOptionIndex(getNextOptionIndex(optionsLength, hoveredOptionIndex, -1))
        },
        { global: true }
    )

    useHotKey(
        'enter',
        () => {
            if (hoveredOptionIndex < optionsLength) {
                submit?.(hoveredOptionIndex)
            }
        },
        { global: true }
    )

    return {
        hoveredOptionIndex,
        setHoveredOptionIndex,
    }
}

function getNextOptionIndex(optionsLength: number, currentOptionIndex: number, offset: 1 | -1) {
    const actualCurrentOptionIndex = (() => {
        if (currentOptionIndex >= 0 && currentOptionIndex < optionsLength) {
            return currentOptionIndex
        }
        return offset <= 0 ? 0 : -1
    })()

    const newIndex = (actualCurrentOptionIndex + offset + optionsLength) % optionsLength

    return newIndex
}

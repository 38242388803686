import { TextCell as BaseTextCell } from '@laserfocus/client/ui-shared-datagrid'

import { parse, toDisplayValue } from '../../TableCell/TimeCell'

import { BulkEditCellProps } from './BulkEditCell'

interface BulkEditTextCellProps extends BulkEditCellProps {
    multiline?: boolean
    allowLineBreaks?: boolean
}

export function BulkEditTimeCell({
    column,
    isEditable,
    updateValue,
    value,
    hasMultipleValues,
    ...rest
}: BulkEditTextCellProps) {
    return (
        <BaseTextCell
            name={column.key}
            updateValue={updateValue}
            value={value}
            placeholder={hasMultipleValues ? 'multiple' : '—'}
            readOnly={!isEditable}
            theme="dark"
            variant="dense"
            toDisplayValue={toDisplayValue}
            parseValue={parse}
            useDisplayValueAsInput
            {...rest}
        />
    )
}

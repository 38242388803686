import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useHotKey, useListNavigation } from '@laserfocus/ui/util-keyboard'
import { CommandLine } from '@laserfocus/ui/beam'
import { useSearchStore, sortSearchResults } from '@laserfocus/client/feature-search'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import {
    isAccountResult,
    isContactResult,
    isLeadResult,
    isOpportunityResult,
    SearchResult,
} from '@laserfocus/shared/models'
import { useUserId } from '@laserfocus/client/feature-auth'

import { StepComponentProps, StaticStepState } from '../step-component-types'
import { useSelectOnMount } from '../useSelectOnMount'

import { SelectParentOption } from './SelectParentOption'

export interface StepSelectParentState extends StaticStepState {
    inputValue: string
    selectedIndex: number
    WhatId: string | undefined
    WhoId: string | undefined
    AccountId: string | undefined
    Name: string | undefined
}

export const StepSelectParent = observer(function StepSelectParent({
    stepState,
    submit,
}: StepComponentProps<StepSelectParentState>) {
    const [inputValue, setInputValue] = useState(stepState?.inputValue || '')
    const [selectedIndex, setSelectedIndex] = useState(stepState?.selectedIndex || 0)
    const searchStore = useSearchStore()
    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)

    useSelectOnMount(inputRef)

    const shouldSearch = inputValue.length > 1

    useEffect(() => {
        shouldSearch && searchStore.debouncedSearch(inputValue)
    }, [shouldSearch, inputValue, searchStore])

    const { currentData, currentSearchTerm } = searchStore

    const userId = useUserId()
    const sortedOptions = useMemo(() => {
        const options = shouldSearch ? currentData : []

        const sorted = sortSearchResults(options, currentSearchTerm, userId ?? undefined, {
            forTaskCreation: true,
        })
        return sorted
    }, [currentData, currentSearchTerm, shouldSearch, userId])

    function handleSubmit(index: number) {
        const optionToSubmit = sortedOptions[index]

        if (!optionToSubmit) {
            return
        }

        submit({
            breadCrumbLabel: optionToSubmit.Name,
            inputValue,
            selectedIndex,
            ...getObjectIds(optionToSubmit),
        })
    }

    useListNavigation(setSelectedIndex, sortedOptions.length)
    useHotKey(
        'enter',
        (event) => {
            event.preventDefault()
            handleSubmit(selectedIndex)
        },
        { global: true }
    )

    return (
        <>
            <CommandLine.Input
                ref={inputRef}
                placeholder="Search for leads, accounts, opps, etc."
                aria-label="Search for leads, accounts, opps, etc."
                value={inputValue}
                onChange={(event) => {
                    setInputValue(event.target.value)
                    setSelectedIndex(0)
                }}
                isLoading={searchStore.isLoading}
            />
            {sortedOptions.length > 0 && (
                <CommandLine.Content>
                    {sortedOptions.map((searchResult, index) => {
                        return (
                            <SelectParentOption
                                key={searchResult.Id}
                                searchResult={searchResult}
                                onClick={() => handleSubmit(index)}
                                isSelected={index === selectedIndex}
                                select={() => setSelectedIndex(index)}
                            />
                        )
                    })}
                </CommandLine.Content>
            )}
        </>
    )
})

function getObjectIds(sr: SearchResult) {
    const WhatId =
        isOpportunityResult(sr) || isAccountResult(sr)
            ? sr.Id
            : (sr as { AccountId?: string }).AccountId
    const WhoId = isLeadResult(sr) || isContactResult(sr) ? sr.Id : undefined
    const AccountId = isAccountResult(sr) ? sr.Id : (sr as { AccountId?: string }).AccountId
    const Name = sr.Name

    return { WhoId, WhatId, AccountId, Name }
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function FavoriteOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M11.999 3.232a1 1 0 01-.271.422l-1.346 4.173-.006.016A2.01 2.01 0 018.48 9.21H3.576h.001l3.977 2.887c.68.489.974 1.343.765 2.14a1.004 1.004 0 01-.028.108l-1.51 4.681-.001.003.003-.002 3.985-2.883a2.006 2.006 0 012.346.014l.002.001 3.979 2.889-1.51-4.668a1.864 1.864 0 01-.009-1.177c.13-.389.378-.695.626-.942a.998.998 0 01.21-.161l3.9-2.83H15.41a1.982 1.982 0 01-1.902-1.375l-1.508-4.663zm1.902-.619C13.38 1 11.34.791 10.424 1.983a1 1 0 00-.436.55L8.483 7.2a.028.028 0 01-.008.01H3.57c-1.924 0-2.747 2.479-1.166 3.62v.001l3.977 2.886.004.003v.003l-.001.01a.992.992 0 00-.018.064L4.878 18.41c-.594 1.834 1.498 3.378 3.076 2.24l.001-.002 3.975-2.876h.002l.01.007 3.977 2.887h.002c1.549 1.13 3.683-.368 3.08-2.226l-.952.308.95-.31m-1.498-4.635a1.04 1.04 0 01.046-.055l3.937-2.857-.585-.81.587.809c1.58-1.142.757-3.62-1.167-3.62h-4.913l-1.505-4.657m1.511 4.675l-.002-.005-.001-.002-.002-.003a.04.04 0 00-.008-.008l-.002-.001.01.001.003.013.002.005z"
            />
        </SvgIcon>
    )
}

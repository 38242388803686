import { addDays, endOfToday, isToday } from 'date-fns'
import { ReadTransaction } from 'replicache'
import { computed } from 'mobx'
import { sortBy, uniqBy } from 'lodash'

import { Identity, NewEvent, NewTask } from '@laserfocus/shared/models'
import { PoolQuery } from '@laserfocus/client/data-layer'
import { TaskModel, EventModel } from '@laserfocus/client/model'
import { useRootStore } from '@laserfocus/client/root-store-context'

import { ActivityView, Dependencies } from './ActivityView'

export type RootStoreInjection = {
    upcomingView?: UpcomingView
}

export function useUpcomingView() {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.upcomingView!
}

export class UpcomingView extends ActivityView {
    taskQuery: PoolQuery<TaskModel>
    eventQuery: PoolQuery<EventModel>

    constructor(deps: Dependencies) {
        super(deps)

        this.taskQuery = new PoolQuery(this.objectPool, {
            rootObject: 'Task',
            match(model, { userId }) {
                if (model.IsClosed) {
                    return false
                }
                const openDate = model.OpenDate
                if (openDate) {
                    return endOfToday() < openDate && model.OwnerId === userId
                }
                return false
            },
        })
        this.eventQuery = new PoolQuery(this.objectPool, {
            rootObject: 'Event',
            match(model, { userId }) {
                const openDate = model.OpenDate
                if (openDate) {
                    return endOfToday() < openDate && model.OwnerId === userId
                }
                return false
            },
        })
    }

    @computed
    get isLoading() {
        return (
            this.queryStatus.getStatus('my-open-tasks') === 'running' ||
            this.queryStatus.getStatus('my-upcoming-events') === 'running'
        )
    }

    _onMount() {
        this.queryOpenTasksOnce()
        this.queryUpcomingEventsOnce()
    }

    async queryOpenTasksOnce() {
        if (this.queryStatus.getStatus('my-open-tasks') === 'none') {
            this.queryStatus.startOnce('my-open-tasks')
            const taskResult = await this.replicache.query(async (tx: ReadTransaction) => {
                const identity = (await tx.get('identity')) as unknown as Identity
                if (!identity) {
                    return {
                        type: 'none',
                    } as const
                }
                const userId = identity.Id
                const prefix = [userId, 'OPEN'].join('#')

                const myOpenTasks = (await tx
                    .scan({ indexName: 'openTasksByUser', prefix })
                    .values()
                    .toArray()) as NewTask[]
                return {
                    type: 'success',
                    tasks: myOpenTasks,
                } as const
            })
            if (taskResult.type === 'none') {
                this.queryStatus.reset('my-open-tasks')
            } else {
                taskResult.tasks.forEach((task) => this.objectPool.attach(task))
                this.queryStatus.finish('my-open-tasks')
            }
        }
    }

    async queryUpcomingEventsOnce() {
        if (this.queryStatus.getStatus('my-upcoming-events') === 'none') {
            this.queryStatus.startOnce('my-upcoming-events')
            const result = await this.replicache.query(async (tx: ReadTransaction) => {
                const identity = (await tx.get('identity')) as unknown as Identity
                if (!identity) {
                    return {
                        type: 'none',
                    } as const
                }
                const userId = identity.Id
                const datePrefix = new Date().toISOString().split('T')[0]
                const prefix = [userId, datePrefix].join('#')

                const myOpenTodayEvents = (await tx
                    .scan({
                        indexName: 'eventsByOwnerAndStartDateTime',
                        start: {
                            key: prefix,
                        },
                    })
                    .values()
                    .toArray()) as NewEvent[]
                return {
                    type: 'success',
                    events: myOpenTodayEvents,
                } as const
            })
            if (result.type === 'none') {
                this.queryStatus.reset('my-upcoming-events')
            } else {
                result.events.forEach((model) => this.objectPool.attach(model))
                this.queryStatus.finish('my-upcoming-events')
            }
        }
    }

    @computed
    get data() {
        const today = uniqBy(
            [...this.taskQuery.data, ...this.eventQuery.data, ...this.completedTasks],
            'Id'
        )
        return sortBy(today, 'OpenDate')
    }
}

import { Navigate } from 'react-router'
import clsx from 'clsx'

import { useIsUnit21 } from '@laserfocus/client/feature-auth'
import { UserOutlinedIcon } from '@laserfocus/ui/icons'
import { Spinner } from '@laserfocus/ui/beam'

import { SettingsPageHeader } from '../layout/SettingsPageHeader'

import { ImpersonateUser } from './ImpersonateUser'

export function ImpersonatePage() {
    const { isLoading, isUnit21 } = useIsUnit21()
    if (isLoading) return <Spinner />
    if (!isUnit21) return <Navigate to="/settings" replace={true} />
    return (
        <div>
            <SettingsPageHeader title="Impersonate" iconComponent={UserOutlinedIcon} />
            <div className="m-6">
                <ImpersonationHint className="mb-4" />
                <ImpersonateUser />
            </div>
        </div>
    )
}

function ImpersonationHint({ className }: { className?: string }) {
    return (
        <p className={clsx('text-base text-grey-700/60', className)}>
            Impersonation only works for users, that already signed up for Laserfocus.
        </p>
    )
}

import { debounce } from 'lodash'

import {
    deleteNote,
    pinNote,
    unpinNote,
    updateContentDocument,
    updateNoteBody,
    updateNoteTitle,
} from '@laserfocus/client/data-access-shared'
import { Analytics } from '@laserfocus/client/util-analytics'
import { OptimisticNoteId } from '@laserfocus/shared/models'

import { NoteProps } from './Note'
import { OptimisticClientNote, UpdateNoteValues } from './useNewNote'
import { ClientNote } from './useRootNotes'

export function getDraftNoteProps(
    note: OptimisticClientNote,
    rootId: string,
    updateNote: (id: OptimisticNoteId, values: UpdateNoteValues) => void,
    deleteNote: (id: OptimisticNoteId) => void
): Omit<NoteProps, 'currentId'> {
    return {
        title: note.Title!,
        bodyHtml: note.Body,
        setTitle: (Title: string) => updateNote(note.optimisticId!, { Title }),
        setBodyHtml: (Body: string) => updateNote(note.optimisticId!, { Body }),
        deleteNote: () => deleteNote(note.optimisticId!),
        isPrivate: note.SharingPrivacy === 'N',
        toggleIsPrivate: () => {
            const SharingPrivacy = note.SharingPrivacy === 'N' ? 'P' : 'N'
            updateNote(note.optimisticId!, {
                SharingPrivacy,
            })
            if (note.didSubmitNote) {
                updateContentDocument(note.optimisticId, {
                    SharingPrivacy,
                })
            }
        },
        isPinned: note.isPinned,
        toggleIsPinned: () => {
            updateNote(note.optimisticId!, {
                isPinned: !note.isPinned,
            })
            if (note.didSubmitNote) {
                if (note.isPinned) {
                    return unpinNote(rootId, note.optimisticId)
                }
                return pinNote(rootId, note.optimisticId)
            }
        },
        applyTemplate: (body) => updateNote(note.optimisticId!, { Body: body }),
        canOpenNote: false,
        openNote: () => {
            throw new Error('Open Note should not be called on draft notes')
        },
    }
}

export function getNoteProps(
    note: ClientNote,
    rootId: string | null,
    deleteDraftNote: (id: OptimisticNoteId) => void,
    analyticsLocation?: 'list_overlay' | 'table_overlay' | 'single_note'
): Omit<NoteProps, 'currentId'> {
    const trackEdit = debounce(() => {
        Analytics.trackEvent({
            event: 'note_edited',
            location: analyticsLocation || 'person_details',
            title: note.Title ?? undefined,
        })
    }, 5000)
    return {
        title: note.Title!,
        bodyHtml: note.Body,
        setTitle: (title: string) => {
            updateNoteTitle(note.Id, title).then(trackEdit)
        },
        setBodyHtml: (bodyHtml: string) => {
            updateNoteBody(note.Id, bodyHtml).then(trackEdit)
        },
        deleteNote: () => {
            if (note.optimisticId) {
                deleteDraftNote(note.optimisticId)
            }
            deleteNote(note.Id)
        },
        isPrivate: note.SharingPrivacy === 'N',
        toggleIsPrivate: () =>
            updateContentDocument(note.Id, {
                SharingPrivacy: note.SharingPrivacy === 'N' ? 'P' : 'N',
            }),
        isPinned: note.isPinned,
        toggleIsPinned: () => {
            if (!rootId) {
                return
            }
            if (note.isPinned) {
                return unpinNote(rootId, note.Id)
            }
            return pinNote(rootId, note.Id)
        },
        applyTemplate: (body) => updateNoteBody(note.Id, body),
        canOpenNote: true,
        openNote: () => {
            window.open(`/notes/${note.Id}`, '_blank', 'popup=1,height=600,width=640')
        },
    }
}

export function getSingleWindowNoteProps(note: ClientNote, rootId: string | null) {
    return {
        ...getNoteProps(
            note,
            rootId,
            () => {
                throw new Error('Delete Draft note should not be called on SingleNotePage')
            },
            'single_note'
        ),
        canOpenNote: false,
    }
}

import { matchSorter } from 'match-sorter'
import { useMemo, useState } from 'react'

import { DropdownInput, useDropdownKeyboardNavigation } from '@laserfocus/ui/beam'
import { useAutoFocusedRef, useScrolledIntoViewRef } from '@laserfocus/ui/util-react'

import { ColumnsSection } from './types'
import { ColumnsListCheckbox } from './ColumnsListCheckbox'

interface ColumnsListProps {
    columnSections: ColumnsSection[]
    hideIcons?: boolean
}

export function ColumnsList({ columnSections, hideIcons }: ColumnsListProps) {
    const [searchValue, setSearchValue] = useState('')

    const searchInputRef = useAutoFocusedRef<HTMLInputElement>(true)

    const filteredColumnSections = useMemo(() => {
        if (!searchValue) {
            return columnSections
        }
        return columnSections
            .map((c) => ({
                ...c,
                columns: matchSorter(c.columns, searchValue, { keys: ['label'] }),
            }))
            .filter(({ columns }) => columns.length)
    }, [columnSections, searchValue])
    const filteredColumns = useMemo(
        () => filteredColumnSections.flatMap((s) => s.columns),
        [filteredColumnSections]
    )

    const { hoveredOptionIndex, setHoveredOptionIndex } = useDropdownKeyboardNavigation({
        optionsLength: filteredColumns.length,
        resetKey: searchValue,
        submit: (index) => filteredColumns[index]!.toggleIsShown(),
    })

    const hoveredOptionRef = useScrolledIntoViewRef<HTMLDivElement>(hoveredOptionIndex)

    return (
        <div role="listbox" className="w-60">
            <div className="p-2">
                <DropdownInput
                    ref={searchInputRef}
                    placeholder="Search fields"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                />
            </div>
            {filteredColumnSections.length > 0 && (
                <div className="px-2 pb-2 max-h-[25rem] box-content overflow-y-auto">
                    {filteredColumnSections.map(({ label, columns }, index) => {
                        const previousColumnsLength = filteredColumnSections
                            .slice(0, index)
                            .reduce((a, c) => a + c.columns.length, 0)

                        return (
                            <div key={index} className="relative grid gap-1">
                                <div className="sticky top-0 mt-3 py-1 px-1.5 text-xs font-medium leading-[1.2] text-white/60 bg-grey-700/80 z-10">
                                    {label}
                                </div>
                                {columns.map((column, columnIndex) => {
                                    const hoverIndex = previousColumnsLength + columnIndex
                                    const isHovered =
                                        previousColumnsLength + columnIndex === hoveredOptionIndex
                                    const setIsHovered = () => setHoveredOptionIndex(hoverIndex)

                                    return (
                                        <div
                                            key={columnIndex}
                                            className="-mb-2"
                                            onMouseMove={setIsHovered}
                                        >
                                            <div
                                                ref={isHovered ? hoveredOptionRef : undefined}
                                                className="pb-2"
                                            >
                                                <ColumnsListCheckbox
                                                    column={column}
                                                    isHovered={isHovered}
                                                    setIsHovered={setIsHovered}
                                                    hideIcons={hideIcons}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

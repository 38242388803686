import { DateCell as BaseDateCell } from '@laserfocus/client/ui-shared-datagrid'

import { BulkEditCellProps } from './BulkEditCell'

interface BulkEditDateCellProps extends BulkEditCellProps {
    dateOnly?: boolean
}

export const BulkEditDateCell = function DateCell({
    column,
    isEditable,
    updateValue,
    value,
    hasMultipleValues,
    dateOnly,
}: BulkEditDateCellProps) {
    return (
        <BaseDateCell
            name={column.key}
            updateValue={updateValue}
            value={value}
            dateOnly={dateOnly}
            readOnly={!isEditable}
            placeholder={hasMultipleValues ? 'multiple' : undefined}
            theme="dark"
        />
    )
}

import { useRef } from 'react'
import { Transition } from '@headlessui/react'

import { ConvertLeadContextProvider } from '@laserfocus/client/feature-person-details'
import { AnalyticsContext } from '@laserfocus/client/util-analytics'

import { OverlayDetails } from './OverlayDetails'
import { OverlayContainer } from './OverlayContainer'
import { AttachActivity } from './AttachActivity'
import { OverlayNavbar } from './OverlayNavbar'

export interface OverlayData {
    overlayKey: string
    rootId?: string
    contactId?: string
    opportunityId?: string
    activityId?: string
}

interface OverlayProps {
    overlayData: OverlayData | undefined
    pathPrefix?: 'today' | 'overdue' | 'upcoming'
    stackSlug?: string
    closeOverlay(): void
    goToPreviousObject(): void
    goToNextObject(): void
}

export function Overlay({ overlayData: overlayDataFromProps, ...restProps }: OverlayProps) {
    // Necessary because we want to hold onto overlayData during leave transition
    const overlayData = useLatest(overlayDataFromProps)

    return (
        <Transition
            show={Boolean(overlayDataFromProps)}
            enter="transition-transform duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            className="fixed z-10 top-0 inset-y-0 right-0 w-[38rem]"
        >
            {/* We know that OverlayContent is only rendered when activity is not null */}
            <OverlayContent overlayData={overlayData!} {...restProps} />
        </Transition>
    )
}

function useLatest<T>(thing: T) {
    const thingRef = useRef<T>(thing)

    if (thing) {
        thingRef.current = thing
    }

    return thingRef.current
}

interface OverlayContentProps {
    pathPrefix?: 'today' | 'overdue' | 'upcoming'
    stackSlug?: string
    overlayData: OverlayData
    closeOverlay(): void
    goToPreviousObject(): void
    goToNextObject(): void
}

function OverlayContent(props: OverlayContentProps) {
    const { stackSlug, overlayData } = props
    const { rootId, contactId, opportunityId, activityId, overlayKey } = overlayData
    return (
        <OverlayContainer rootId={rootId} contactId={contactId} opportunityId={opportunityId}>
            <ConvertLeadContextProvider>
                <AnalyticsContext.Provider
                    value={{ location: stackSlug ? 'table_overlay' : 'list_overlay' }}
                >
                    <article className="h-full overflow-y-auto rounded-l-3xl bg-white ring-1 ring-grey-700/5 shadow-[0px_24px_156px_rgba(123,137,167,0.08),0px_10.0266px_65.1731px_rgba(123,137,167,0.0575083),0px_5.36071px_34.8446px_rgba(123,137,167,0.0476886),0px_3.00517px_19.5336px_rgba(123,137,167,0.04),0px_1.59602px_10.3742px_rgba(123,137,167,0.0323114),0px_0.664142px_4.31692px_rgba(123,137,167,0.0224916)] px-11 py-8">
                        <div className="grid gap-8 content-start">
                            <OverlayNavbar {...props} />
                            {rootId ? (
                                <OverlayDetails
                                    overlayKey={overlayKey}
                                    rootId={rootId}
                                    isInTable={Boolean(stackSlug)}
                                />
                            ) : activityId ? (
                                <AttachActivity activityId={activityId} />
                            ) : null}
                        </div>
                    </article>
                </AnalyticsContext.Provider>
            </ConvertLeadContextProvider>
        </OverlayContainer>
    )
}

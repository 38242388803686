import { z } from '@laserfocus/shared/decoder'

import { UserIdSchema } from '../base/id.types'
import {
    makeBaseRecordSchema,
    CreateOptimisticSchema,
    UpdateOptimisticSchema,
} from '../base/singletable.types'

const FieldGroupBaseSchema = z.object({
    id: z.string(),

    rootObject: z.union([z.literal('Lead'), z.literal('Account')]),
    orderKey: z.string().optional(),
    title: z.string(),
    /**
     * VisibleFieldOrderMap is something we need to merge
     * on the client between org & user fieldgroup
     */
    visibleFieldOrderMap: z.record(z.string(), z.string()).default({}),
})

export const OrgFieldGroupSchema = FieldGroupBaseSchema.extend({
    deactivated: z.boolean().optional(),
    roles: z.array(z.string()).default([]).optional(),
}).merge(makeBaseRecordSchema('OrgFieldGroup'))

export type OrgFieldGroup = z.infer<typeof OrgFieldGroupSchema>

export type FieldGroup = OrgFieldGroup &
    UserFieldGroup & {
        lockedFieldNames?: Set<string>
        orgDeactivated?: boolean
        resetToDelete?: boolean
        isExtended?: boolean
    }

export const UserFieldGroupSchema = FieldGroupBaseSchema.extend({
    deleted: z.boolean().optional(),
}).merge(makeBaseRecordSchema('UserFieldGroup'))

export type UserFieldGroup = z.infer<typeof UserFieldGroupSchema>

export const UserFieldGroupInputSchema = UserFieldGroupSchema.omit({
    __typename: true,
    createdDate: true,
    modifiedDate: true,
    deleted: true,
})

export type UserFieldGroupInput = z.infer<typeof UserFieldGroupInputSchema>

export const UserFieldGroupUpdateSchema = UserFieldGroupInputSchema.omit({
    id: true,
    rootObject: true,
    visibleFieldOrderMap: true,
    isOrgFieldGroup: this,
}).partial()

export type UserFieldGroupUpdate = z.infer<typeof UserFieldGroupUpdateSchema>

export const OrgFieldGroupInputSchema = OrgFieldGroupSchema.omit({
    __typename: true,
    createdDate: true,
    modifiedDate: true,
    deleted: true,
})
export type OrgFieldGroupInput = z.infer<typeof OrgFieldGroupInputSchema>

export const OrgFieldGroupUpdateSchema = OrgFieldGroupInputSchema.omit({
    id: true,
    rootObject: true,
    visibleFieldOrderMap: true,
}).partial()
export type OrgFieldGroupUpdate = z.infer<typeof OrgFieldGroupUpdateSchema>

export const SetFieldPositionSchema = z.object({
    fieldName: z.string(),
    fieldOrderKey: z.string(),
})

export type SetFieldPosition = z.infer<typeof SetFieldPositionSchema>

const AddOrSetFieldPosition = z.object({
    id: z.string(),
    values: SetFieldPositionSchema,
    optimistic: UpdateOptimisticSchema,
})

export const FieldGroupMutatorSchema = {
    createOrgFieldGroup: z.object({
        values: OrgFieldGroupInputSchema,
        optimistic: CreateOptimisticSchema,
    }),
    createFieldGroup: z.object({
        values: UserFieldGroupInputSchema,
        optimistic: CreateOptimisticSchema,
    }),
    shareFieldGroup: z.object({
        userIds: z.array(UserIdSchema),
        fieldGroup: UserFieldGroupInputSchema,
    }),
    updateOrgFieldGroup: z.object({
        id: z.string(),
        values: OrgFieldGroupUpdateSchema,
        optimistic: UpdateOptimisticSchema,
    }),
    updateFieldGroup: z.object({
        id: z.string(),
        values: UserFieldGroupUpdateSchema,
        optimistic: UpdateOptimisticSchema,
    }),
    setOrgFieldGroupFieldPosition: AddOrSetFieldPosition,
    setFieldGroupFieldPosition: AddOrSetFieldPosition,
    removeOrgFieldGroupField: z.object({
        id: z.string(),
        values: z.object({
            fieldName: z.string(),
        }),
        optimistic: UpdateOptimisticSchema,
    }),
    removeFieldGroupField: z.object({
        id: z.string(),
        values: z.object({
            fieldName: z.string(),
        }),
        optimistic: UpdateOptimisticSchema,
    }),
    deactivateOrgFieldGroup: z.object({
        id: z.string(),
    }),
    activateOrgFieldGroup: z.object({
        id: z.string(),
    }),
    resetFieldGroup: z.object({
        id: z.string(),
    }),
    deleteFieldGroup: z.object({
        id: z.string(),
    }),
    deleteOrgFieldGroup: z.object({
        id: z.string(),
    }),
    updateFieldGroupsOrder: z.object({
        values: z.record(z.string(), z.string()),
        optimistic: UpdateOptimisticSchema,
    }),
    updateOrgFieldGroupsOrder: z.object({
        values: z.record(z.string(), z.string()),
        optimistic: UpdateOptimisticSchema,
    }),
}

import clsx from 'clsx'

import { ContentEditable } from '@laserfocus/ui/beam'
import { useSyncedInputState } from '@laserfocus/ui/util-react'
import { getLocale } from '@laserfocus/ui/util-locale'

import { useCounterContext, FALLBACK_QUOTA } from './CounterContext'
import { StatLabel, StatsCard, StatValue } from './StatsCard'

const currencyFormatter = new Intl.NumberFormat(getLocale(), {
    // style: 'currency',
    // currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
})

export function QuotaCard() {
    const { quota, setQuota } = useCounterContext()
    function onRateChange(value: string) {
        if (!Number.isNaN(parseInt(value))) {
            setQuota(parseInt(value))
        }
    }

    const calcQuota = typeof quota === 'number' ? quota : FALLBACK_QUOTA
    const { onBlur, onChange, onFocus, value } = useSyncedInputState(`${calcQuota}`, onRateChange)

    return (
        <StatsCard className="bg-grey-50">
            <StatLabel>What is your quota this month:</StatLabel>
            <StatValue className="flex">
                <span className="py-1">$</span>
                <ContentEditable
                    maxLength={6}
                    value={value}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onValueChange={(value) => onChange({ target: { value } })}
                    className={clsx(
                        'hover:bg-grey-700/5 focus-within:ring rounded-lg grid grid-flow-col transition',
                        !quota && 'text-grey-700/60'
                    )}
                    formatDisplayValue={(v) => currencyFormatter.format(parseFloat(v))}
                />
            </StatValue>
        </StatsCard>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function FavoriteFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path d="M9.05 2.93a1 1 0 011.9 0l1.07 3.29a1 1 0 00.95.69h3.46a1 1 0 01.6 1.8l-2.8 2.04a1 1 0 00-.37 1.12l1.07 3.3a1 1 0 01-1.54 1.11l-2.8-2.03a1 1 0 00-1.18 0l-2.8 2.03a1 1 0 01-1.54-1.12l1.07-3.29a1 1 0 00-.36-1.12l-2.8-2.03a1 1 0 01.59-1.81h3.46a1 1 0 00.95-.69l1.07-3.3z" />
        </SvgIcon>
    )
}

import { forwardRef, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { twMerge } from 'tailwind-merge'

import { UseDatepickerResult } from '@laserfocus/vendor/react-datepicker-hooks'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { getDateFromUserDateString, getDateFromUserTimeString } from '@laserfocus/ui/util-date'

import { DropdownInput } from '../controls/DropdownInput'

import { overrideTime } from './util'

interface DatepickerInputsProps extends Pick<UseDatepickerResult, 'onTimeSelect' | 'onDateSelect'> {
    className?: string
    selectedDate: Date | null
    focusOnMount?: boolean
    dateOnly?: boolean
}

const EM_DASH = '—'

export function DatepickerInputs({
    className,
    selectedDate,
    focusOnMount,
    dateOnly,
    onTimeSelect,
    onDateSelect,
}: DatepickerInputsProps) {
    const formattedValues = useMemo(
        () => ({
            date: selectedDate ? format(selectedDate, 'E, MMM d, y') : EM_DASH,
            time: selectedDate ? format(selectedDate, 'h:mm a') : EM_DASH,
        }),
        [selectedDate]
    )
    const dateInputRef = useAutoFocusedRef<HTMLInputElement>(focusOnMount)

    return (
        <div className={twMerge('grid', !dateOnly && 'grid-cols-[1fr,5.3125rem] gap-2', className)}>
            <DatepickerInput
                ref={dateInputRef}
                aria-label="Date"
                placeholder="5/25/2021"
                blurValue={formattedValues.date}
                onChange={(event) => {
                    const date = getDateFromUserDateString(event.target.value)

                    if (date) {
                        overrideTime(onDateSelect, dateOnly)(date)
                    }
                }}
            />
            {!dateOnly && (
                <DatepickerInput
                    aria-label="Time"
                    placeholder="9:30am"
                    blurValue={formattedValues.time}
                    onChange={(event) => {
                        const date = getDateFromUserTimeString(
                            event.target.value,
                            selectedDate ?? new Date()
                        )

                        if (date) {
                            onTimeSelect(date)
                        }
                    }}
                />
            )}
        </div>
    )
}

interface DatepickerInputProps extends React.ComponentPropsWithoutRef<typeof DropdownInput> {
    blurValue: string
}

const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>(function DatepickerInput(
    { blurValue, onChange, ...props },
    ref
) {
    // const { value, onChange, reset } = useInputMask({ mask, onMaskSatisfied })
    const [value, setValue] = useState('')
    const [isFocused, setIsFocused] = useState(false)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value)
        onChange?.(event)
    }

    function handleFocus() {
        setIsFocused(true)
    }

    function handleBlur() {
        setIsFocused(false)
        setValue('')
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.stopPropagation()
            event.currentTarget.blur()
        }
    }

    return (
        <DropdownInput
            ref={ref}
            {...props}
            className={blurValue === EM_DASH && !isFocused ? 'text-white/60' : undefined}
            value={isFocused ? value : blurValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
        />
    )
})

import { twMerge } from 'tailwind-merge'

export function CommandLineContent({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
    return (
        <div
            {...props}
            className={twMerge('max-h-96 overflow-y-auto p-2 border-t border-white/10', className)}
        />
    )
}

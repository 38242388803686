import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function UserOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M12 4a3 3 0 100 6 3 3 0 000-6zm-3.536-.536a5 5 0 117.071 7.071 5 5 0 01-7.07-7.07zm-2.12 11.88A8 8 0 0120 21a1 1 0 01-1 1H5a1 1 0 01-1-1 8 8 0 012.343-5.657zM12 15a6 6 0 00-5.916 5h11.832A6 6 0 0012 15z" />
        </SvgIcon>
    )
}

import { useEffect } from 'react'
import { useMutation } from 'react-apollo'

import { CREATE_CHECKOUT_SESSION_MUTATION } from '@laserfocus/shared/data-access-gql'
import { Button, ButtonProps, SpinnerInline, toast } from '@laserfocus/ui/beam'
import { logger } from '@laserfocus/ui/logger'
import { Analytics } from '@laserfocus/client/util-analytics'

type CheckoutButtonProps = Omit<ButtonProps, 'onClick'> & {
    returnUrl?: string
}

export function CheckoutButton({
    returnUrl,
    disabled,
    iconComponent,
    ...rest
}: CheckoutButtonProps) {
    const [mutateFunction, { data, loading, error }] = useMutation<{
        createCheckoutSession: { error: string; url: string }
    }>(CREATE_CHECKOUT_SESSION_MUTATION, {
        variables: {
            input: { returnUrl },
        },
    })
    useEffect(() => {
        if (error || data?.createCheckoutSession.error) {
            const m = data?.createCheckoutSession.error || 'Could not create Link to Portal'
            logger.error(error || data?.createCheckoutSession.error)
            toast.error({ title: m })
        } else if (data?.createCheckoutSession.url) {
            const link = data.createCheckoutSession.url
            window.location.href = link
        }
    }, [data, error])

    return (
        <Button
            iconComponent={loading ? SpinnerInline : undefined}
            disabled={loading}
            onClick={() => {
                Analytics.trackEvent({
                    event: 'payment_checkout_clicked',
                    payload: {
                        // eslint-disable-next-line no-restricted-globals
                        location: location.pathname,
                    },
                })
                mutateFunction()
            }}
            {...rest}
        />
    )
}

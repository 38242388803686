import { useEffect } from 'react'
import { useMutation } from 'react-apollo'

import { CREATE_PORTAL_LINK_MUTATION } from '@laserfocus/shared/data-access-gql'
import { Button, ButtonProps, SpinnerInline, toast } from '@laserfocus/ui/beam'
import { logger } from '@laserfocus/ui/logger'

export function PortalButton({ disabled, iconComponent, ...rest }: Omit<ButtonProps, 'onClick'>) {
    const [mutateFunction, { data, loading, error }] = useMutation<{
        createPortalLink: { error: string; url: string }
    }>(CREATE_PORTAL_LINK_MUTATION)
    useEffect(() => {
        if (error || data?.createPortalLink.error) {
            const m = data?.createPortalLink.error || 'Unexpected Error during checkout'
            logger.error(error || data?.createPortalLink.error)
            toast.error({ title: m })
        } else if (data?.createPortalLink.url) {
            const link = data.createPortalLink.url
            window.location.href = link
        }
    }, [data, error])

    return (
        <Button
            iconComponent={loading ? SpinnerInline : undefined}
            disabled={loading}
            onClick={() => mutateFunction()}
            {...rest}
        />
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function HiddenOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M2.2 2.3a1 1 0 011.5 0l3 3A11 11 0 0122.5 12v.3a11 11 0 01-3.6 5.2l2.7 2.7a1 1 0 11-1.4 1.5L16.6 18l-3.2-3.3L9 10.6l-6.9-7a1 1 0 010-1.3zM15.4 14l2 2a9 9 0 003-4A9 9 0 0012 6c-1.4 0-2.6.2-3.8.8l1.7 1.7a4 4 0 015.5 5.5zM14 12.5L11.5 10a2 2 0 012.4 2.4zM4.6 8.2c.4.3.5 1 .2 1.4-.6.7-1 1.5-1.3 2.4a9 9 0 0010.2 5.8 1 1 0 01.3 2l-2 .2a11 11 0 01-10.5-7.6 1 1 0 010-.7c.4-1.2 1-2.3 1.7-3.3a1 1 0 011.4-.2z" />
        </SvgIcon>
    )
}

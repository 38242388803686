import clsx from 'clsx'

import { Modal } from '../Modal'

interface CommandLineModalProps {
    show: boolean
    children: React.ReactNode
    close(): void
    className?: string
}

export function CommandLineModal({ show, children, close, className }: CommandLineModalProps) {
    return (
        <Modal show={show} onClose={close}>
            <Modal.Overlay />
            <Modal.Container
                className={clsx('w-[31.875rem] bg-grey-700 text-white', className)}
                variableHeight
            >
                {children}
            </Modal.Container>
        </Modal>
    )
}

import gql from 'graphql-tag'

import { LEAD_FRAGMENT } from '../person/lead-fragments'
import { PERSON_TASK_FRAGMENT } from '../person/person-task-fragment'
import { PERSON_EVENT_FRAGMENT } from '../person/person-event-fragment'
import {
    ACCOUNT_FRAGMENT,
    CONTACT_FRAGMENT,
    OPPORTUNITY_FRAGMENT,
} from '../person/account-fragments'

export const GET_MORE_BY_CURSOR = gql`
    ${PERSON_TASK_FRAGMENT}
    ${PERSON_EVENT_FRAGMENT}
    ${LEAD_FRAGMENT}
    ${ACCOUNT_FRAGMENT}
    ${CONTACT_FRAGMENT}
    ${OPPORTUNITY_FRAGMENT}
    query getMoreByCursor($sobject: FilterSobject!, $cursor: String!) {
        getMoreByCursor(sobject: $sobject, cursor: $cursor) {
            done
            totalSize
            nextCursor
            records {
                ... on Lead {
                    ...LeadHeader
                    OpenActivities {
                        ... on Task {
                            ...PersonTask
                        }
                        ... on Event {
                            ...PersonEvent
                        }
                    }
                }
                ... on Account {
                    ...AccountHeader
                    OpenActivities {
                        ... on Task {
                            ...PersonTask
                        }
                        ... on Event {
                            ...PersonEvent
                        }
                    }
                }
                ... on Contact {
                    ...AccountContact
                    Account {
                        ...AccountHeader
                    }
                    OpenActivities {
                        ... on Task {
                            ...PersonTask
                        }
                        ... on Event {
                            ...PersonEvent
                        }
                    }
                }
                ... on Opportunity {
                    ...AccountOpportunity
                    Account {
                        ...AccountHeader
                    }
                    OpenActivities {
                        ... on Task {
                            ...PersonTask
                        }
                        ... on Event {
                            ...PersonEvent
                        }
                    }
                }
            }
        }
    }
`

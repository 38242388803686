import { createContext, useContext, useReducer } from 'react'

export enum Hint {
    AddMoreColumns = 'add more columns',
    CloseColumnsDropdown = 'close columns dropdown',
    SetFilter = 'set filter',
    CloseFilterDropdown = 'close filter dropdown',
    // AddOrEditTask = 'add or edit task',
    SaveView = 'save view',
    FinishSavingView = 'finish saving view',
    GoToOverview = 'go to overview',
    None = 'none',
}

interface TableHintsContextValue {
    currentHint: Hint
    hideHint(hint: Hint): void
}

const TableHintsContext = createContext<TableHintsContextValue>({
    currentHint: Hint.None,
    hideHint() {},
})

interface TableHintsContextProviderProps {
    children: React.ReactNode
}

export function TableHintsContextProvider({ children }: TableHintsContextProviderProps) {
    const [hints, hideHint] = useReducer(
        (hints: Hint[], hintToHide: Hint) => (hintToHide === hints[0] ? hints.slice(1) : hints),
        [
            Hint.AddMoreColumns,
            Hint.CloseColumnsDropdown,
            Hint.SetFilter,
            Hint.CloseFilterDropdown,
            // Hint.AddOrEditTask,
            Hint.SaveView,
            Hint.FinishSavingView,
            Hint.GoToOverview,
        ]
    )

    const currentHint = hints[0] ?? Hint.None

    return (
        <TableHintsContext.Provider value={{ currentHint, hideHint }}>
            {children}
        </TableHintsContext.Provider>
    )
}

export function useTableHintsContext() {
    return useContext(TableHintsContext)
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function EditOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M17.497 4.355c-.085.103-.19.19-.31.252l-.582.583 2.14 2.14.797-.796c.587-.6.579-1.548.005-2.123a1.496 1.496 0 00-2.05-.056zm-.167 4.389l-2.138-2.138L3.98 17.832v2.166h2.087L17.33 8.744zm-1.06-5.972a3.498 3.498 0 014.686.22l.004.003c1.363 1.363 1.355 3.572.005 4.944l-.006.006-1.35 1.349a1.003 1.003 0 01-.315.315L7.187 21.705a1 1 0 01-.707.293h-3.5a1 1 0 01-1-1v-3.58a1 1 0 01.293-.707l13.73-13.748a1 1 0 01.267-.19z" />
        </SvgIcon>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function LineOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M4 12c0-.6.4-1 1-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
        </SvgIcon>
    )
}

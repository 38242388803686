import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function AccountFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.8 4.8a2.4 2.4 0 012.39-2.41h9.6-.01a2.39 2.39 0 012.4 2.39v14.4h-.02c.66-.02 1.2.52 1.2 1.18 0 .66-.54 1.2-1.2 1.2h-3.6a1.2 1.2 0 01-1.2-1.2v-2.4c0-.68-.55-1.2-1.2-1.2h-2.42c-.67 0-1.2.52-1.2 1.2v2.4c0 .65-.54 1.2-1.2 1.2H4.73a1.2 1.2 0 01-1.2-1.22c0-.67.53-1.2 1.2-1.2V4.74l.07.06zM8.4 6h2.4v2.4H8.4V6zm2.4 4.8H8.4v2.4h2.4v-2.4zM13.2 6h2.4v2.4h-2.4V6zm2.4 4.8h-2.4v2.4h2.4v-2.4z"
            />
        </SvgIcon>
    )
}

import { useBodyClassName } from '@laserfocus/ui/util-react'
import { useMyRole } from '@laserfocus/client/data-access-shared'
import { useIsUnit21 } from '@laserfocus/client/feature-auth'
import { Spinner } from '@laserfocus/ui/beam'

import { SettingsSidebar } from './SettingsSidebar'

export const SIDEBAR_WIDTH = 256

export function SettingsLayout({ children }: { children: React.ReactNode }) {
    // Doing it as body class, so we can overwrite it in children
    useBodyClassName('bg-yellow-50  ')

    const { isLoading: isLoadingUnit21, isUnit21 } = useIsUnit21()
    const { isLoading, role } = useMyRole()
    const showLoading = isUnit21 && isLoading
    const hideAdmin = !isLoadingUnit21 && isUnit21 && role !== 'admin' && role !== 'sales-admin'
    if (showLoading) {
        return <Spinner />
    }
    return (
        <>
            <div className="min-h-screen">
                {/* Static sidebar for desktop */}
                <div className="w-64 h-screen fixed">
                    <SettingsSidebar hideAdmin={hideAdmin} />
                </div>

                {/* Content area */}
                <div className="pl-64 w-full">
                    <main className="flex-1">{hideAdmin ? null : children}</main>
                </div>
            </div>
        </>
    )
}

import 'reflect-metadata'
import {
    observable,
    computed,
    reaction,
    action,
    toJS,
    runInAction,
    flow,
    isFlowCancellationError,
    extendObservable,
} from 'mobx'
import type { CancellablePromise } from 'mobx/lib/api/flow'
import { get, orderBy } from 'lodash'
import { ReadTransaction, Replicache } from 'replicache'

import { fractional } from '@laserfocus/shared/util-common'
import type {
    LeadDTO,
    AccountDTO,
    OpportunityDTO,
    ContactDTO,
    Stack,
    Sorting,
    FilterCondition,
    StackRows,
    Identifier,
    LeadProps,
    Identity,
    NewTask,
    NewEvent,
} from '@laserfocus/shared/models'
import { isTruthy } from '@laserfocus/shared/models'
import {
    normalizeLeads,
    normalizeOpportunities,
    normalizeAccounts,
    normalizeContacts,
    getComputedColumn,
    OpportunityModel,
    FieldMetadata,
    LeadModel,
    AccountModel,
    ContactModel,
} from '@laserfocus/client/model'
import { assert, logger as rootLogger } from '@laserfocus/ui/logger'
import { canContainNewResults, isFilterEqual, Prefix } from '@laserfocus/shared/models'
import { OpportunityUtil } from '@laserfocus/client/model'
import { getClient } from '@laserfocus/client/replicache'
import { theme } from '@laserfocus/ui/tailwindcss'
import {
    Model,
    ModelProps,
    ObjectPool,
    QueryStatus,
    TrieSnapshotter,
} from '@laserfocus/client/data-layer'

import { makeFilter, RecordRow, ValueComparableCondition } from './make-filter'
import { FocusDataRepository } from './FocusDataRepository'
import type { FetchResult } from './FocusDataRepository'
import { sortByReference } from './sortby-reference'
import { getTrieOptions, makeSnapshotter } from './stack-search'

type SalesModel = LeadModel | AccountModel | ContactModel | OpportunityModel

const SORTED_COLORS = [
    theme.colors.grey[50],
    theme.colors.purple[50],
    theme.colors.blue[50],
    theme.colors.green[50],
    theme.colors.yellow[50],
    theme.colors.red[100],
].reverse()

export const STACK_MAX_FETCH_RECORDS = 2000

const logger = rootLogger.child({
    name: 'FetchableStack',
    // level: 'info',
})

type ReactionSet = {
    conditions: FilterCondition[]
}

type FetchableStackDependencies = {
    objectPool: ObjectPool
    queryStatus: QueryStatus
    replicache: Replicache
}

export type FetchableStackOptions = {
    // The onboarding allows fetching empty conditions
    fetchEmptyConditions: boolean
}

export class RCFetchableStack {
    repo: FocusDataRepository
    objectPool: ObjectPool
    queryStatus: QueryStatus
    replicache: Replicache
    getStack: () => Stack
    reactions: Array<() => void> = []

    currentFetch?: CancellablePromise<void>

    @observable isInitialized = false
    @observable isMounted = false

    @observable didFirstFetch = false
    @observable didFinishFetch = false
    @observable serverCount: null | number = null

    @observable isLoading = false
    @observable errorMessage?: string
    @observable fetchedRecordIds: string[] = []
    @observable currentRecordIds: string[] = []

    @observable options?: FetchableStackOptions

    fetchedConditions: Array<FilterCondition> = []
    previousConditions: FilterCondition[] = []

    focusListTableScrollOffset?: number

    trieSnapshotter: TrieSnapshotter

    constructor(
        { objectPool, queryStatus, replicache }: FetchableStackDependencies,
        getStack: () => Stack,
        options?: FetchableStackOptions
    ) {
        assert(!!getStack(), "Can't create a fetchable Stack without a stack")
        this.objectPool = objectPool
        this.replicache = replicache || getClient()
        this.queryStatus = queryStatus
        this.getStack = getStack
        this.repo = new FocusDataRepository()
        this.options = options
        this.trieSnapshotter = new TrieSnapshotter<Model, Identifier>(
            () => this.currentRecords,
            getTrieOptions(this),
            makeSnapshotter(this),
            this.stack.id
        )
    }

    @computed
    get userId() {
        return this.objectPool.getSingle<Identity>('Identity')?.Id
    }

    @computed
    get stack(): Stack {
        return this.getStack()
    }

    @action
    onInit() {
        if (this.isInitialized) {
            return
        }

        this.queryStackRowsOnce()
        this.trieSnapshotter.onInit()
        this.isInitialized = true
    }

    @action
    onMount() {
        this.onInit()
        if (this.isMounted) {
            return
        }
        this.isMounted = true
        if (this.fetchedRecordIds.length > 0) {
            this.updateRecords()
        }
        this.reactions.push(
            reaction<ReactionSet>(
                () => ({
                    // toJS is necessary to observe nested changes (e.g. just adding a value)
                    // The problem is, this is triggered DURING columnResizie. not idea WHY
                    conditions: toJS(this.conditions),
                }),
                this.changed,
                {
                    fireImmediately: true,
                }
            )
        )
        this.reactions.push(reaction(() => this.stack.sorting, this.updateRecordSorting))
        this.reactions.push(
            reaction(
                () => ({
                    options: getTrieOptions(this),
                    snapshotter: makeSnapshotter(this),
                }),
                ({ options, snapshotter }) => {
                    this.trieSnapshotter.update(snapshotter, options)
                },
                {
                    fireImmediately: true,
                }
            )
        )
        this.querySobjectsOnce()
        this.queryAllOpenTasksOnce()
        this.queryAllUpcomingEventsOnce()

        return () => this.onUnmount()
    }

    @action
    onUnmount() {
        this.isMounted = false
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    async querySobjectsOnce() {
        const queryName = `all-${this.stack.sobject.toLowerCase()}` as
            | 'all-lead'
            | 'all-opportunity'
            | 'all-contact'
            | 'all-account'
        if (this.queryStatus.getStatus(queryName) === 'none') {
            this.queryStatus.startOnce(queryName)
            const records = await this.replicache.query(async (tx: ReadTransaction) => {
                return tx
                    .scan({ prefix: `${this.stack.sobject.toLowerCase()}/` })
                    .values()
                    .toArray() as unknown as Promise<LeadProps[]>
            })

            records.forEach((row) => this.objectPool.attach(row))
            this.queryStatus.finish(queryName)
        }
    }

    async queryStackRowsOnce() {
        if (this.queryStatus.getStatus('stackrows') === 'none') {
            this.queryStatus.startOnce('stackrows')
            const stackrows = await this.replicache.query(async (tx: ReadTransaction) => {
                return tx
                    .scan({ prefix: `${Prefix.StackRow}/` })
                    .values()
                    .toArray() as Promise<StackRows[]>
            })

            stackrows.forEach((row) =>
                this.objectPool.attach({
                    ...row,
                    __typename: 'stackrows',
                })
            )
            this.queryStatus.finish('stackrows')
        }
    }

    async queryAllOpenTasksOnce() {
        if (this.queryStatus.getStatus('all-open-tasks') === 'none') {
            this.queryStatus.startOnce('all-open-tasks')
            const tasks = await this.replicache.query(async (tx: ReadTransaction) => {
                const myOpenTasks = (await tx
                    .scan({ indexName: 'openTasks', prefix: 'OPEN' })
                    .values()
                    .toArray()) as NewTask[]
                return myOpenTasks
            })

            await this.objectPool.attachAll(tasks, 8)
            this.queryStatus.finish('all-open-tasks')
        }
    }

    async queryAllUpcomingEventsOnce() {
        if (this.queryStatus.getStatus('all-upcoming-events') === 'none') {
            this.queryStatus.startOnce('all-upcoming-events')
            const events = await this.replicache.query(async (tx: ReadTransaction) => {
                const datePrefix = new Date().toISOString().split('T')[0]

                const myOpenTodayEvents = (await tx
                    .scan({
                        indexName: 'eventsByStartDateTime',
                        start: {
                            key: datePrefix,
                        },
                    })
                    .values()
                    .toArray()) as NewEvent[]
                return myOpenTodayEvents
            })

            await this.objectPool.attachAll(events, 8)
            this.queryStatus.finish('all-upcoming-events')
        }
    }

    @computed
    get sobject() {
        return this.stack.sobject
    }

    @computed
    get columns() {
        return fractional.sortByOrderKey(this.stack.visibleColumnMap)
    }

    @action.bound
    changed(changeSet: ReactionSet) {
        logger.debug('-------CHANGED----------')
        const { conditions } = changeSet
        if (!this.isMounted) {
            return
        }

        const conditionsToFetch = conditions.filter((a) => !a.isComputed)

        const previousConditions = this.previousConditions

        if (!this.didFirstFetch) {
            logger.debug('Refetching since we never fetched before')
            return this.refetch(conditionsToFetch)
        }
        if (!previousConditions) {
            logger.debug('Refetching due to unset previous Conditions')
            return this.refetch(conditionsToFetch)
        }

        if (isFilterEqual(previousConditions, conditions)) {
            logger.debug('Conditions havent changed')
            return
        }

        const didNotLoadAllBefore = Boolean(
            this.serverCount &&
                this.serverCount >= STACK_MAX_FETCH_RECORDS &&
                this.currentRecordIds.length < this.serverCount
        )
        const needsRefetchNow =
            didNotLoadAllBefore || canContainNewResults(this.fetchedConditions, conditionsToFetch)
        logger.debug(
            'Filter Changed',
            'needsRefetch',
            needsRefetchNow,
            'fetchedConditions',
            this.fetchedConditions,
            'new conditions',
            conditions,
            'new fetchable',
            conditionsToFetch
        )
        this.previousConditions = toJS(conditions)
        if (needsRefetchNow) {
            logger.debug('Need a refetch', this.fetchedConditions, conditionsToFetch)
            return this.refetch(conditionsToFetch, previousConditions, didNotLoadAllBefore)
        }
        this.updateRecords()
    }

    @computed
    get conditions(): FilterCondition[] {
        return Object.values(this.stack.filterConditions || {})
    }

    prefetch() {
        const conditionsToFetch = this.conditions.filter((a) => !a.isComputed)
        return this.refetch(conditionsToFetch, undefined, true)
    }

    @computed
    get trieOptions() {
        return getTrieOptions(this)
    }

    async refetch(
        conditions: FilterCondition[],
        rollbackConditions?: FilterCondition[],
        forceFetch: boolean = false
    ) {
        try {
            runInAction(() => {
                this.errorMessage = undefined
                this.serverCount = null
                this.previousConditions = toJS(conditions)
            })

            if (!this.shouldRefetch(conditions, forceFetch)) {
                if (forceFetch) {
                    logger.warn('I should not refetch although its a forced fetch')
                }
                return false
            }
            if (this.isLoading && this.currentFetch) {
                logger.debug('Cancelling previous fetch')
                this.currentFetch.cancel()
            }
            runInAction(() => {
                this.errorMessage = undefined
            })
            const currentFetch = this.doRefetch(conditions)
            runInAction(() => {
                this.currentFetch = currentFetch
            })
            await currentFetch
        } catch (e: any) {
            if (!isFlowCancellationError(e)) {
                logger.error(e)
                if (rollbackConditions) {
                    this.previousConditions = rollbackConditions
                }
                runInAction(() => (this.errorMessage = e.message))
            }
        }
    }

    shouldRefetch(conditions: FilterCondition[], forceFetch: boolean) {
        if (conditions.length === 0 && !this.options?.fetchEmptyConditions) {
            return false
        }
        if (!this.didFirstFetch && this.isMounted) {
            return true
        }
        if (forceFetch) {
            return true
        }
        if (!this.isMounted) {
            logger.debug('Current Stack is not shown')
            return false
        }
        if (conditions.length === 0) {
            logger.debug('Currently no conditions set')
            return false
        }
        if (this.didFetchFilterExtend(conditions)) {
            logger.debug('Refetching because of filter has a wider resultset')
            return true
        }
        logger.debug('no refetch, because no reason for a refetch')
        return false
    }

    didFetchFilterExtend(newFilter: Array<FilterCondition>) {
        const didExtend = canContainNewResults(this.fetchedConditions, newFilter)
        if (didExtend) {
            logger.debug(this.fetchedConditions, 'filter extended', newFilter)
        } else {
            logger.debug(this.fetchedConditions, 'filter NOT extended', newFilter)
        }
        return didExtend
    }

    doRefetch = flow(function* (this: RCFetchableStack, conditions: Array<FilterCondition>) {
        if (this.isLoading) {
            logger.warn('Triggering a new refetch while still waiting is not great i guess')
        }

        const previousConditions = this.fetchedConditions

        try {
            logger.debug('doRefetch', conditions)
            this.isLoading = true
            this.didFirstFetch = false
            this.didFinishFetch = false
            this.fetchedConditions = toJS(conditions)

            let data: FetchResult = yield this.repo.fetchData(this.sobject!, conditions)
            this.didFirstFetch = true
            yield this.getDataFromServer(data)
            logger.debug('doRefetch', 'didFirstFetch', 'nextCursor', data.nextCursor)
            while (data.nextCursor && data.totalSize <= STACK_MAX_FETCH_RECORDS) {
                data = yield this.repo.fetchMore(this.sobject, data.nextCursor)
                yield this.getDataFromServer(data, true)
            }
            this.didFinishFetch = true
            logger.debug('doRefetch-DONE')
        } catch (e: unknown) {
            this.fetchedConditions = previousConditions
            throw e
        } finally {
            logger.debug('doRefetch-FINALLY')
            this.isLoading = false
        }
    })

    async getDataFromServer(response: FetchResult, more?: boolean) {
        const records = response.records
        this.serverCount = response.totalSize
        const timer = `RCFechableStack-getDataFromServer-${this.sobject}-${records.length}`
        console.time(timer)
        if (this.sobject === 'Lead') {
            const leads = Object.values(normalizeLeads(records as LeadDTO[]))
            await this.objectPool.attachAll(leads, this.isMounted ? 15 : 5)
        } else if (this.sobject === 'Account') {
            const accounts = Object.values(normalizeAccounts(records as AccountDTO[]))
            await this.objectPool.attachAll(accounts, this.isMounted ? 15 : 5)
        } else if (this.sobject === 'Opportunity') {
            const opps = Object.values(normalizeOpportunities(records as OpportunityDTO[]))
            await this.objectPool.attachAll(opps, this.isMounted ? 15 : 5)
        } else if (this.sobject === 'Contact') {
            const contacts = Object.values(normalizeContacts(records as ContactDTO[]))
            await this.objectPool.attachAll(contacts, this.isMounted ? 15 : 5)
        } else {
            logger.error(new Error('I just received data from server without a sobject'))
            return
        }
        console.timeEnd(timer)

        const ids = records.map((a) => a.Id)
        if (more) {
            this.fetchedRecordIds = [...this.fetchedRecordIds, ...ids]
        } else {
            this.fetchedRecordIds = ids
        }

        this.updateRecords(true)
    }

    @computed
    get fetchedRecords(): RecordRow[] {
        return this.fetchedRecordIds
            .map((id) => this.objectPool.get(this.sobject!, id) as RecordRow)
            .filter((a) => a)
    }

    @computed
    get allObjects() {
        if (!this.sobject) {
            return []
        }

        return Array.from(this.objectPool.getAll<ModelProps & RecordRow>(this.sobject).values())
    }

    @computed
    get usedRelations(): string[] {
        const conditions = (this.valueCompareConditions || [])
            .filter((a) => a.path.includes('.'))
            .map((a) => a.path.split('.')[0])
        const columns = (this.columns || [])
            .filter((a) => a.includes('.'))
            .map((a) => a.split('.')[0])
        const combined = [...new Set([...conditions, ...columns])]
        return combined
    }

    /**
     * We are using an imperative API here, to not just "recalculate"
     * All items in the Table. So we are calling updates manually on it.
     * This might be a premature optimization and we can potentially
     * just use a compouted prop in the future.
     * @param justFetched
     */
    @action
    updateRecords(justFetched = false) {
        logger.debug(this.stack.title!, 'updateRecords', justFetched)
        const allData = justFetched ? this.fetchedRecords : this.allObjects
        const timer = `updateRecords-${this.sobject}-${allData.length}${justFetched ? '-just' : ''}`
        console.time(timer)
        const filterFunc = makeFilter(this.valueCompareConditions)
        const filtered = allData.filter(filterFunc)
        // @TODO: bring some safety measures back, to check allData with filteredData
        // But only when also checking if the conditions are "queryable"
        // if (justFetched) {
        //     filtered = allData
        // } else {
        //     filtered = allData.filter(filterFunc)
        // }

        const sorting = this.stack.sorting
        const ids = filtered.map((r) => r.Id)
        let currentRecordIds = sorting ? ids : ids.sort()
        this.currentRecordIds = currentRecordIds as string[]
        if (sorting) {
            this.updateRecordSorting(sorting)
        }
        console.timeEnd(timer)
    }

    @action.bound
    updateRecordSorting(sorting?: Sorting) {
        const filtered = this.currentRecords
        if (!sorting) {
            return
        }

        const sortedRecords = this.sortRecords(filtered, sorting)
        this.currentRecordIds = sortedRecords.map((r) => r.Id as string)
    }

    sortRecords(records: SalesModel[], sorting?: Sorting): SalesModel[] {
        if (!sorting) {
            return records
        }

        if (sorting?.fieldName === 'color') {
            const reference =
                sorting.direction === 'ASC' ? SORTED_COLORS : [...SORTED_COLORS].reverse()
            const colorSorted = sortByReference(
                records,
                'color',
                reference,
                sorting.direction === 'DESC'
            )
            return colorSorted
        }

        const direction = sorting.direction === 'ASC' ? 'asc' : 'desc'
        const sortingField = this.getField(sorting.fieldName)
        if (sortingField && sortingField.isPicklist) {
            const referenceOrder = sortingField.picklistOptions.map((o) => o.value)
            const fieldPath = this.getPath(sortingField)
            const finalReference =
                sorting.direction === 'ASC' ? referenceOrder : referenceOrder.reverse()
            return sortByReference(records, fieldPath, finalReference)
        } else if (sortingField) {
            const fieldPath = this.getPath(sortingField)
            return orderBy(
                records,
                [
                    (r) => {
                        const v = get(r, fieldPath)
                        if (typeof v === 'string') {
                            return v.toLowerCase()
                        }
                        return v
                    },
                ],
                [direction]
            )
        }

        const computedColumn = getComputedColumn(this.sobject, sorting.fieldName)
        if (computedColumn) {
            const sorted = orderBy(
                records,
                (record) => computedColumn.getValue(record as any, this.objectPool),
                [direction]
            )
            return sorted as SalesModel[]
        }

        logger.warn(
            'Could not find the field in updateSorting for',
            sorting.fieldName,
            'from Object',
            this.stack.sobject
        )

        return orderBy(records, [sorting.fieldName], [direction])
    }

    @computed
    get currentRecords(): SalesModel[] {
        const fromPool = this.currentRecordIds.map((id) =>
            this.objectPool.get<SalesModel>(this.sobject!, id)
        )
        const onlyFound = fromPool.filter(isTruthy)
        const withColor = onlyFound.map((a) => {
            const store = this
            if (this.stackRows) {
                extendObservable(a, {
                    get color() {
                        const c = store.stackRows.colors?.[a.Id]
                        return c
                    },
                })
            }
            return a
        })
        return withColor as unknown as SalesModel[]
    }

    @computed
    get stackRows() {
        return this.objectPool.get<StackRows>('stackrows', this.stack.id)
    }

    @computed
    get sortedData(): SalesModel[] {
        return this.currentRecords as unknown as SalesModel[]
    }

    @computed
    get valueCompareConditions(): ValueComparableCondition[] {
        const combined = this.conditions.map((cond) => {
            const field = this.getField(cond.fieldName)

            assert(
                Boolean(field) || cond.isComputed,
                `Did not find the FieldMetadata for ${this.stack.sobject}.${cond.fieldName}`
            )

            if (cond.isComputed) {
                return {
                    ...cond,
                    path: cond.fieldName,
                }
            }

            const path = field
                ? [this.stack.sobject !== field.objectName && field.objectName, field.name]
                      .filter(Boolean)
                      .join('.')
                : cond.fieldName

            if (cond.fieldType === 'reference' && field && field.isUserReference) {
                return {
                    ...cond,
                    path,
                    values: Array.from(
                        new Set(
                            (cond.values as string[]).map((v: string) => {
                                if (v === '$me') {
                                    return this.userId
                                }
                                return v
                            })
                        )
                    ),
                }
            }
            return {
                ...cond,
                path,
            }
        })
        return combined as ValueComparableCondition[]
    }

    getPath(field: FieldMetadata) {
        if (field.objectName === this.stack.sobject) {
            return field.name
        }
        return `${field.objectName}.${field.name}`
    }

    getField(fieldName: string): FieldMetadata | undefined {
        const parts = fieldName.split('.')
        const withoutCustomFields = parts.filter((a) => a !== 'CustomFields').join('.')
        if (parts[0] && ['Account', 'Opportunity', 'Lead', 'Contact'].includes(parts[0])) {
            return this.objectPool.get<FieldMetadata>('FieldMetadata', withoutCustomFields)
        }
        return this.objectPool.get<FieldMetadata>(
            'FieldMetadata',
            `${this.sobject}.${withoutCustomFields}`
        )
    }

    /* ------------ VISUAL CONTROLS ----------- */

    @action.bound
    setFocusListTableScrollOffset(offset: number) {
        this.focusListTableScrollOffset = offset
    }

    @computed
    get forecastValue() {
        if (this.stack.sobject === 'Opportunity') {
            if (
                this.serverCount &&
                this.serverCount >= STACK_MAX_FETCH_RECORDS &&
                this.currentRecordIds.length < this.serverCount
            ) {
                return null
            }
            const opps = this.sortedData as unknown as OpportunityModel[]
            return opps.reduce<number>((sum: number, opp: OpportunityModel): number => {
                const expectedRevenue = OpportunityUtil.getExpectedRevenue(opp) || 0
                return sum + expectedRevenue
            }, 0)
        }
        return null
    }

    @computed
    get forecastCurrency() {
        if (this.stack.sobject === 'Opportunity') {
            return this.sortedData.find((a) => a.CurrencyIsoCode)?.CurrencyIsoCode
        }
        return null
    }

    @computed
    get hasComputedColumnFilter(): boolean {
        return Boolean(Object.values(this.stack.filterConditions || {}).find((a) => a.isComputed))
    }

    @computed
    get recordCount(): number | undefined {
        if (this.hasComputedColumnFilter) {
            if (this.didFinishFetch) {
                return this.currentRecordIds.length
            }
            return undefined
        }
        if (
            this.serverCount &&
            this.serverCount >= STACK_MAX_FETCH_RECORDS &&
            this.currentRecordIds.length < this.serverCount
        ) {
            return this.serverCount
        }
        if (this.didFinishFetch) {
            return this.currentRecordIds.length
        }
        if (this.didFirstFetch) {
            return this.serverCount ?? undefined
        }
        return undefined
    }

    @computed
    get isInitiallyLoading(): boolean {
        return this.isLoading && !this.didFirstFetch
    }

    @computed
    get isLoadingMore(): boolean {
        return this.isLoading && !this.didFinishFetch && this.didFirstFetch
    }
}

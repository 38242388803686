import { sortBy } from 'lodash'
import { useSubscribe } from 'replicache-react'

import { useFieldGroups, useMyRole } from '@laserfocus/client/data-access-shared'
import { HeadingWithIcon } from '@laserfocus/client/ui-shared'
import { FolderFilledIcon } from '@laserfocus/ui/icons'
import { getClient } from '@laserfocus/client/replicache'
import { Identity, LFUser, Prefix } from '@laserfocus/shared/models'

import { useRootObjectType } from '../modules/PersonContext'

import { FieldGroups } from './FieldGroups/FieldGroups'

interface RightSideProps {
    isLoading: boolean
    isInOverlay?: boolean
}

export function RightSide({ isLoading, isInOverlay }: RightSideProps) {
    const rootObjectType = useRootObjectType()
    const { fieldGroups, isLoading: isLoadingFieldGroups } = useRightSideFieldGroups(rootObjectType)
    if (isLoadingFieldGroups) {
        return null
    }

    return (
        <div className={isInOverlay ? 'px-2' : 'grid gap-4'}>
            {!isInOverlay && (
                <HeadingWithIcon iconComponent={FolderFilledIcon}>Field Groups</HeadingWithIcon>
            )}
            <FieldGroups
                fieldGroups={fieldGroups}
                isLoadingSobject={isLoading}
                isInOverlay={Boolean(isInOverlay)}
                rootObjectType={rootObjectType}
                orgOrUser="user"
            />
        </div>
    )
}

function useRightSideFieldGroups(rootObjectType: 'Lead' | 'Account' | null) {
    const { isLoading: fieldGroupsLoading, fieldGroups: allFieldGroups } = useFieldGroups()

    const { role, isLoading: isLoadingRole } = useMyRole()

    const filtered = allFieldGroups
        .filter((g) => g.rootObject === rootObjectType)
        .filter((g) => {
            if (g.__typename === 'UserFieldGroup' || g.isExtended) {
                return true
            }
            return role && g.roles?.includes(role)
        })
        .map((g) => {
            // If we extended a fieldgroup and the role of that fieldgroup was changed, then we cant delete it anymore
            if (g.isExtended && !(role && g.roles?.includes(role))) {
                return {
                    ...g,
                    orgDeactivated: g.deactivated,
                    resetToDelete: true,
                }
            }
            return g
        })
    const fieldGroups = sortBy(filtered, 'orderKey')

    return {
        isLoading: isLoadingRole || fieldGroupsLoading || !rootObjectType,
        fieldGroups,
    }
}

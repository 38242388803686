import { useCallback, useEffect } from 'react'

import { toast } from '@laserfocus/ui/beam'
import { shouldShowHelperMessage, getErrorMessage } from '@laserfocus/client/data-access-shared'
import {
    shouldShowErrorMessage,
    isMutationErrorResponse,
    FailedMutation,
    Identity,
} from '@laserfocus/shared/models'
import { getMutationErrorMessage } from '@laserfocus/client/util-formatter'

import { useFailedMutationScope } from './FailedMutationContext'

export function useFailedMutationToast(user: Identity | null) {
    const { onError } = useFailedMutationScope()
    const onFailedMutation = useCallback(
        async (failedMutation: FailedMutation) => {
            if (shouldShowHelperMessage(failedMutation)) {
                const msg = await getErrorMessage(failedMutation, user)
                if (msg) {
                    toast.error(msg)
                    return
                }
            }
            if (shouldShowErrorMessage(failedMutation) || isMutationErrorResponse(failedMutation)) {
                const message = getMutationErrorMessage(failedMutation)
                if (failedMutation.level === 'warn') {
                    toast.warn(message)
                } else {
                    toast.error(message)
                }
            } else {
                toast.error({ title: 'Something went wrong' })
            }
        },
        [user]
    )
    useEffect(() => {
        return onError(onFailedMutation)
    }, [onError, onFailedMutation])
}

import queryString from 'query-string-original'

import { navigate } from '@laserfocus/client/util-routing'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { logger } from '@laserfocus/ui/logger'
import { Config } from '@laserfocus/shared/config-env'

let token: string | null
export function getToken() {
    if (!token) {
        token = localStorage.getItem('lf:token')
    }
    return token
}

export function setToken(_token: string) {
    localStorage.setItem('lf:token', _token)
    token = _token
}
if (typeof window !== 'undefined') {
    ;(window as any).$setToken = setToken
}

export function redirectToLogin() {
    const source = window.location.pathname
    if (!source.includes(RoutePaths.Login) && !source.includes(RoutePaths.Landing)) {
        /**
         * We have flaky tests where for some reason we run into unauthenticated requests during e2e.
         * The reauthentication redirect leads to Cypress hanging until timeout. This is so we can exit early
         * and have a better understanding where the unauthenticated request comes from.
         * The localStorage check is to have an escape hatch from this behaviour from within Cypress.
         */
        if ((window as any).Cypress) {
            throw new Error(
                `RedirectToLogin: I am unauthenticated, and my current token is ${shortenToken(
                    localStorage.getItem('lf:token')
                )}, message. Source: ${source}`
            )
        }

        const query = queryString.stringify({ source })

        const to = `/login?${query}`
        logger.info(`Redirecting to ${to}`)
        navigate(to)
    } else {
        logger.warn(`Unauthenticated and not redirecting. Source: ${source} `)
    }
}

const { GRAPHQL_ENDPOINT } = Config

class MissingRefreshToken extends Error {
    code: 'MISSING_REFRESH_TOKEN'
    tabVisible: string
    path: string
    constructor(msg = 'I dont have an refreshToken to refresh') {
        super(msg)
        this.name = 'MissingRefreshToken'
        this.code = 'MISSING_REFRESH_TOKEN'
        this.tabVisible = document.visibilityState
        this.path = document.location.pathname
    }
}

export async function identifyUser(accessToken: string): Promise<string> {
    const fetchResult = await fetch(GRAPHQL_ENDPOINT as string, {
        method: 'POST',
        headers: {
            Accepts: 'application/json',
            'content-type': 'application/json',
            Authorization: accessToken,
        },
        body: JSON.stringify({
            operationName: 'me',
            query: `query me { me { Id } }
            `,
        }),
    })
    const parsed = await fetchResult.json()
    const myId = parsed.data.me.Id
    return myId
}

export async function refreshToken(): Promise<string> {
    const refreshToken = localStorage.getItem('lf:refreshToken')
    if (!refreshToken) {
        throw new MissingRefreshToken()
    }

    const previousToken = getToken()
    const fetchResult = await fetch(GRAPHQL_ENDPOINT as string, {
        method: 'POST',
        headers: {
            Accepts: 'application/json',
            'content-type': 'application/json',
            Authorization: previousToken!,
        },
        body: JSON.stringify({
            operationName: 'refresh',
            query: `mutation refresh($refreshToken: String!) { refresh(refreshToken: $refreshToken) }
            `,
            variables: {
                refreshToken,
            },
        }),
    })
    const parsed = await fetchResult.json()
    const accessToken = parsed.data.refresh

    setToken(accessToken)
    return accessToken
}

function shortenToken(token: string | null) {
    if (token) {
        return token.substring(0, 6) + '...' + token.substr(-6)
    }
    return 'NO_TOKEN'
}

import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema } from '../base/date.types'

import { QuotaSchema } from './quota-schema'

export const orgSettingsSchema = z.object({
    defaultCurrencyIsoCode: z.string().optional(),
    name: z.string().optional(),
    orgId: z.string(),
})

export const OrgSettingsRecordSchema = orgSettingsSchema.extend({
    createdDate: DateTimeStringSchema,
    modifiedDate: DateTimeStringSchema,
    __typename: z.literal('orgsettings'),
})

export type OrgSettings = z.infer<typeof orgSettingsSchema>
export type OrgSettingsRecord = z.infer<typeof OrgSettingsRecordSchema>

export const userSettingsSchema = z.object({
    defaultCurrencyIsoCode: z.string().optional(),
    language: z.string().optional(),
    locale: z.string().optional(),
    timezone: z.string().optional(),
    orgId: z.string(),
    userId: z.string(),
})

export const UserSettingsRecordSchema = userSettingsSchema.extend({
    createdDate: DateTimeStringSchema,
    modifiedDate: DateTimeStringSchema,
    __typename: z.literal('usersettings'),
})

export type UserSettings = z.infer<typeof userSettingsSchema>
export type UserSettingsRecord = z.infer<typeof UserSettingsRecordSchema>

export const SettingsMutatorSchema = {
    setQuota: z.object({
        values: QuotaSchema.pick({
            amount: true,
            date: true,
        }),
        optimistic: z
            .object({
                lastModifiedDate: DateTimeStringSchema,
            })
            .partial()
            .optional(),
    }),
}

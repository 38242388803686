import {
    createContext,
    Dispatch,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from 'react'

import { FailedMutation, Prefix } from '@laserfocus/shared/models'
import {
    AddOp,
    ChangeOp,
    DeleteOp,
    getClient,
    isAddOp,
    isChangeOp,
    isDeleteOp,
} from '@laserfocus/client/replicache'
import { useSubscription } from '@laserfocus/ui/hooks'

import {
    FailedMutationFilter,
    FailedMutationReducerState,
    InitialReducerState,
    MutationErrorAction,
    mutationErrorReducer,
} from './FailedMutationReducer'

/**
 * Features for error handling
 * 1. Context driven: Child can "consume" errors, if not the error is bubbled up
 * 2. When an error was once dispatched to a node (in the context tree) it does not bubble up
 *    after the node was removed (this might lead to swallowed errors that are dispatched to a node during unmount and then never
 *    surfaces. Maybe Later: ackknowledge them)
 * 3. An error stays in memory, even if it is deleted in replicache (we need the context e.g. for the Success Screen of the error modal)
 * 4. If it is handled or ignored in the UI it can be manually discarded
 */

type FailedMutationContextType = {
    state: FailedMutationReducerState
    dispatch: Dispatch<MutationErrorAction>
    parent: string
}

const FailedMutationContext = createContext<FailedMutationContextType>({
    state: InitialReducerState,
    dispatch: () => {},
    parent: 'root',
})

type FailedMutationRootContextProps = {
    children: React.ReactNode
}

export function FailedMutationRootContextProvider({ children }: FailedMutationRootContextProps) {
    const [state, dispatch] = useReducer(mutationErrorReducer, InitialReducerState)
    useEffect(() => {
        const rep = getClient()
        return rep.experimentalWatch(
            (diff) => {
                const updates = diff.filter((d) => isAddOp(d) || isChangeOp(d)) as unknown as Array<
                    ChangeOp<FailedMutation> | AddOp<FailedMutation>
                >

                const deletes = diff.filter(isDeleteOp) as unknown as Array<
                    DeleteOp<{ mutationId: number }>
                >

                dispatch({
                    type: 'ErrorMutationsLoaded',
                    added: updates.map((a) => a.newValue),
                    removed: deletes.map((a) => a.oldValue.mutationId),
                })
            },
            {
                prefix: Prefix.FailedTransaction,
            }
        )
    }, [])

    return (
        <FailedMutationContext.Provider
            value={useMemo(() => ({ state, dispatch, parent: 'root' }), [state, dispatch])}
        >
            {children}
        </FailedMutationContext.Provider>
    )
}

type MutationContextProps = {
    name: string
    children: React.ReactNode
    filter?: FailedMutationFilter
}

export function FailedMutationScope({ children, filter, name }: MutationContextProps) {
    const { state, dispatch, parent } = useContext(FailedMutationContext)
    const parentNode = state.nodes[parent]
    useEffect(() => {
        if (parentNode) {
            dispatch({
                type: 'ErrorContextNodeAdded',
                node: {
                    name,
                    parent,
                    filter,
                },
            })
        }
        return
    }, [name, filter, parentNode, dispatch, parent])
    useEffect(() => {
        return () => dispatch({ type: 'ErrorContextNodeUnmounted', nodeName: name })
    }, [dispatch, name])

    return (
        <FailedMutationContext.Provider
            value={useMemo(
                () => ({
                    parent: name,
                    state,
                    dispatch,
                }),
                [dispatch, name, state]
            )}
        >
            {children}
        </FailedMutationContext.Provider>
    )
}

export function useFailedMutationScope() {
    const { state, dispatch, parent } = useContext(FailedMutationContext)
    const alreadyEmitted = useRef(new Set<number>())
    const { subscribe, emit } = useSubscription<FailedMutation>()

    const failedMap = state.failedByNode[parent]
    const failedMutations = useMemo(() => Object.values(failedMap || {}), [failedMap])

    useEffect(() => {
        failedMutations.forEach((mut) => {
            if (!alreadyEmitted.current.has(mut.mutationId)) {
                alreadyEmitted.current.add(mut.mutationId)
                emit(mut)
            }
        })
    }, [emit, failedMutations])

    const discard = useCallback(
        (mutationId: number) => {
            dispatch({
                type: 'DiscardFailedMutation',
                mutationId,
            })
        },
        [dispatch]
    )

    return {
        failedMutations,
        discard,
        onError: subscribe,
    }
}

import { useMemo, useState } from 'react'
import { isEqual } from 'lodash'

import { DropdownMultiSelect } from '@laserfocus/ui/beam'
import { isTruthy, Operator } from '@laserfocus/shared/models'

import { Column, useTableRows } from '../../Table/table-context'
import { textInputs } from '../get-filter-input'

import { FilterInputActions } from './FilterInputActions'
import { FilterInputProps } from './types'

export function PicklistInput({ column, operator }: FilterInputProps) {
    const [inputValues, setInputvalues] = useState<string[]>(
        (column.filterCondition?.values as string[] | undefined) ?? []
    )
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false)

    function saveFilter() {
        column.setFilterCondition({
            operator,
            values: inputValues.length ? inputValues : [''],
        })
    }

    const isCreatableAllowed = textInputs.includes(column.type as typeof textInputs[number])
    const { options, optionsMap } = useOptions({ column, isCreatableAllowed, inputValues })
    const isPristineResult = isPristine(inputValues, column, operator)

    return (
        <>
            <DropdownMultiSelect
                isOpen={isMultiSelectOpen}
                setIsOpen={setIsMultiSelectOpen}
                placeholder="Select one or multiple"
                options={options}
                onChange={(selectedOptions) => setInputvalues(selectedOptions.map((o) => o.value))}
                selectedOptions={inputValues.map((v) => optionsMap[v]).filter(isTruthy)}
                onCreatableSubmit={
                    isCreatableAllowed
                        ? (value) => setInputvalues(inputValues.concat(value))
                        : undefined
                }
                bottomAction={
                    isPristineResult
                        ? undefined
                        : {
                              label: 'Set filter',
                              onClick: () => {
                                  setIsMultiSelectOpen(false)
                                  saveFilter()
                              },
                          }
                }
            />
            <FilterInputActions
                column={column}
                saveFilter={isPristineResult ? undefined : saveFilter}
                onRemoveFilterCondition={() => setInputvalues([])}
            />
        </>
    )
}

interface UseOptionsProps {
    column: Column
    isCreatableAllowed: boolean
    inputValues: string[]
}

function useOptions({ column, isCreatableAllowed, inputValues }: UseOptionsProps) {
    const { rows } = useTableRows()
    const memoized = useMemo(() => {
        const options = column.getFilterOptions(rows).map((o) => ({
            ...o,
            label: o.label ?? '',
            className: o.inactive || !o.value ? 'text-white/60' : undefined,
        }))

        const optionsMap = Object.fromEntries(options.map((o) => [o.value, o]))

        return { options, optionsMap }
    }, [column, rows])

    if (isCreatableAllowed) {
        const createdOptions = inputValues
            .filter((v) => !memoized.optionsMap[v])
            .sort()
            .map((v) => ({ label: v, value: v, className: 'text-white/60' }))

        if (createdOptions.length) {
            return {
                options: memoized.options.concat(createdOptions),
                optionsMap: {
                    ...memoized.optionsMap,
                    ...Object.fromEntries(createdOptions.map((o) => [o.value, o])),
                },
            }
        }
    }

    return memoized
}

function isPristine(inputValues: string[], column: Column, operator: Operator) {
    const { filterCondition } = column

    if (!filterCondition) {
        return false
    }

    return operator === filterCondition.operator && isEqual(inputValues, filterCondition.values)
}

import { useState } from 'react'
import clsx from 'clsx'

import { ButtonProps } from '../../../button/Button'
import { Select } from '../../Select/Select'

import { SelectButton } from './SelectButton'

interface DropdownSelectProps<T extends Partial<StaticOption>> {
    placeholder: string
    options: readonly Option<T>[]
    selectedOption?: Option<T>
    onChange(option: Option<T>): void
    className?: string
    buttonProps?: ButtonProps
}

type Option<T extends Partial<StaticOption>> = T & StaticOption
interface StaticOption {
    value: string
    label: string
}

export function DropdownSelect<T extends Partial<StaticOption>>({
    placeholder,
    options,
    selectedOption,
    onChange,
    className,
}: DropdownSelectProps<T>) {
    const [isOpen, setIsOpen] = useState(false)

    function close() {
        setIsOpen(false)
    }

    return (
        <Select
            className={clsx('bg-grey-650', className)}
            hideArrow
            syncWidth
            isOpen={isOpen}
            onCancel={close}
            options={options}
            onSubmit={(option) => {
                close()
                onChange(option)
            }}
        >
            <SelectButton
                placeholder={placeholder}
                selectedOptionLabel={selectedOption?.label}
                onClick={() => setIsOpen((v) => !v)}
            />
        </Select>
    )
}

import clsx from 'clsx'

interface ModalTabsProps<Tabs extends readonly string[]> {
    tabs: Tabs
    currentTab: Tabs[number]
    onTabClick(tab: Tabs[number]): void
}

export function ModalTabs<Tabs extends readonly string[]>({
    tabs,
    currentTab,
    onTabClick,
}: ModalTabsProps<Tabs>) {
    return (
        <div className="px-8 grid grid-flow-col gap-6 justify-start border-b border-grey-700/10 bg-clip-padding">
            {tabs.map((tabLabel) => (
                <TabButton
                    key={tabLabel}
                    isSelected={tabLabel === currentTab}
                    onClick={() => onTabClick(tabLabel)}
                >
                    {tabLabel}
                </TabButton>
            ))}
        </div>
    )
}

interface TabButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    isSelected: boolean
}

export function TabButton({ isSelected, ...props }: TabButtonProps) {
    return (
        <button
            className={clsx(
                'p-1 -mx-1 rounded-md mb-1.5 text-sm leading-[1.3] relative overflow-visible transition outline-none after:absolute after:left-0 after:w-full after:-bottom-1.5 after:h-1 after:border-b-2 after:transition focus-visible:ring',
                isSelected
                    ? 'text-grey-700 after:border-grey-700'
                    : 'text-grey-700/40 after:border-transparent hover:text-grey-700'
            )}
            {...props}
        />
    )
}

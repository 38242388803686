import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { omit } from 'lodash'

import { getDirtyValues } from '@laserfocus/client/util-form'
import { Analytics } from '@laserfocus/client/util-analytics'
import { mutateSObject } from '@laserfocus/client/data-access-shared'
import {
    AccountModel,
    ContactModel,
    FailedMutation,
    LeadModel,
    OpportunityModel,
} from '@laserfocus/shared/models'

export type SalesObject = LeadModel | AccountModel | ContactModel | OpportunityModel

export type SObjectMutation = {
    id: number
    name: string
    args: {
        values: Record<string, any>
    }
}

export function useErrorForm(
    salesObject: SalesObject,
    failedMutation?: FailedMutation<SObjectMutation>
) {
    // const didMount = useRef<boolean>()
    const {
        control,
        handleSubmit,
        formState: { dirtyFields, isValid, isDirty, isSubmitting, errors },
        setValue,
        reset,
        setError,
        clearErrors,
    } = useForm<typeof salesObject>({
        // We need to initialize it with the correct values, since the FormControl has its internal state
        // in order to trigger display values, where the values are cached.
        defaultValues: salesObject,
        criteriaMode: 'all',
        // Since we validate on Blur, this would Lock the focus
        shouldFocusError: false,
        // resolver,
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    })

    const hasDirtyFields = Object.keys(dirtyFields).length > 0
    const isRealDirty = isDirty || hasDirtyFields

    useEffect(() => {
        /**
         * MH: while debuggin i saw that values (mainly null values)
         * are not set when field is not yet registered
         *
         * Warning: If anything here is broken, keep in mind that the FormControlXXX components
         * have an internal react state in between as well
         */
        setTimeout(() => {
            if (!salesObject.hasUncommittedChanges) {
                clearErrors()
                reset(salesObject)

                const payload = failedMutation?.mutation.args.values || {}
                Object.entries(payload).forEach(([key, value]) => {
                    // dont touch the field as well, because that prevents the field being added to dirtyFields
                    // resetField(key, {
                    //     keepDirty: true,
                    //     defaultValue: value,
                    // })
                    setValue(key, value, { shouldDirty: true })
                })

                failedMutation?.fieldErrors.forEach((fieldError) => {
                    setError(fieldError.fieldName, {
                        type: 'salesforce',
                        message: fieldError.message,
                    })
                })
            }
        }, 10)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesObject, failedMutation])

    const submit = handleSubmit(async (data) => {
        const fieldValues = omit(data, '__typename')
        const changeSet = getDirtyValues(dirtyFields, fieldValues)
        // FormControlTime is not triggering onChange, which does not update isDirty
        const realDirty = isDirty || Object.keys(changeSet).length
        const valid = isValid || Object.keys(errors).length === 0
        if (!realDirty || !valid) {
            return
        }
        const failedMutationId = failedMutation?.mutationId
        return mutateSObject
            .updateSObject(
                salesObject.__typename,
                salesObject.Id,
                changeSet,
                failedMutationId
                    ? {
                          failedMutationId,
                      }
                    : undefined
            )
            .then((r) => {
                Analytics.trackEvent({
                    event: 'record_edited',
                    location: 'person_details_error_modal',
                    recordType: Analytics.parseSalesObject(salesObject.__typename)!,
                    fields: Object.keys(changeSet),
                })
            })
    })
    return { control, submit, isDirty: isRealDirty, isSubmitting, isValid }
}

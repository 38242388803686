import { Transition } from '@headlessui/react'

interface MessageScreenProps {
    title: string
    children: React.ReactNode
    action?: React.ReactNode
}

export function MessageScreen({ title, children, action }: MessageScreenProps) {
    return (
        <Transition
            show
            appear
            enter="transition duration-300"
            enterFrom="opacity-0 translate-y-5"
            enterTo="opacity-1 translate-y-0"
        >
            <div className="grid gap-[1.875rem] text-center min-h-screen w-[35.625rem] mx-auto content-center">
                <h1 className="font-serif text-5xl font-bold">{title}</h1>
                <p className="text-2xl leading-normal">{children}</p>
                {action && <div>{action}</div>}
            </div>
        </Transition>
    )
}

import { UserSubscription } from '@laserfocus/shared/models'
import { Modal } from '@laserfocus/ui/beam'

import { LockedFeatureOverlay } from './LockedFeatureOverlay'

export function LockedFeatureModal({
    show,
    closeModal,
    title,
    lockedFeature,
    subscription,
}: {
    show: boolean
    closeModal: () => void
    title: string
    lockedFeature: string
    subscription: UserSubscription
}) {
    return (
        <Modal show={show} onClose={closeModal}>
            <Modal.Overlay />
            <Modal.Container className="w-[29rem]">
                <Modal.Header close={closeModal} border>
                    {title}
                </Modal.Header>
                <div>
                    <LockedFeatureOverlay
                        isLocked
                        lockedFeature={lockedFeature}
                        subscription={subscription}
                        hideOverlay
                        onModalClose={closeModal}
                    >
                        <div className="h-40 min-h-40" />
                    </LockedFeatureOverlay>
                </div>
            </Modal.Container>
        </Modal>
    )
}

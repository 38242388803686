import { isEqual, difference } from 'lodash'

import { Stack } from '@laserfocus/shared/models'

export function mutablyUpdateStack(existing: Stack, update: Stack) {
    const updates = [
        updateSingleProperty(existing, update, 'title'),
        updateSingleProperty(existing, update, 'icon'),
        updateSingleProperty(existing, update, 'color'),
        updateSingleProperty(existing, update, 'deactivated'),
        updateSingleProperty(existing, update, 'deleted'),
        updateSingleProperty(existing, update, 'createdDate'),
        updateSingleProperty(existing, update, 'description'),
        updateSingleProperty(existing, update, 'helpUrl'),
        updateSingleProperty(existing, update, 'isExtendedFromOrg'),
        updateSingleProperty(existing, update, 'isFavorite'),
        updateSingleProperty(existing, update, 'modifiedDate'),
        updateSingleProperty(existing, update, 'preset'),
        updateSingleProperty(existing, update, 'sobject'),
        updateSingleProperty(existing, update, 'version'),

        updateObjectProperty(existing, update, 'timing'),
        updateObjectProperty(existing, update, 'sorting'),

        updateArrayProperty(existing, update, 'lockedColumnNames'),

        updateMapProperty(existing, update, 'columnAggregates'),
        updateMapProperty(existing, update, 'columnWidths'),
        updateMapProperty(existing, update, 'filterConditions'),
        updateMapProperty(existing, update, 'visibleColumnMap'),
    ]
    return updates.some(Boolean)
}

type PropertyKey = keyof Stack
function updateSingleProperty(existing: Stack, update: Stack, property: PropertyKey) {
    let updated = false
    if (existing[property] !== update[property]) {
        updated = true
        existing[property as 'description'] = update[property] as string
    }
    return updated
}

function updateObjectProperty(existing: Stack, update: Stack, property: 'timing' | 'sorting') {
    let updated = false
    const e = existing[property]
    const u = update[property]
    if (!isEqual(e, u)) {
        updated = true
        existing[property] = u as any
    }
    return updated
}

function updateArrayProperty(existing: Stack, update: Stack, property: 'lockedColumnNames') {
    const e = existing[property]
    const u = update[property]
    let updated = false
    if (!isEqual(e, u)) {
        updated = true
        existing[property] = u
    }
    return updated
}

function updateMapProperty(
    existing: Stack,
    update: Stack,
    property: 'visibleColumnMap' | 'filterConditions' | 'columnAggregates' | 'columnWidths'
) {
    let updated = false
    if (!existing[property]) {
        existing[property] = {}
    }
    const existingMap = existing[property]
    const updatedMap = update[property] || {}
    // Added and Updated
    Object.entries(updatedMap).forEach(([key, value]) => {
        const existingValue = existingMap[key]
        if (!existingValue || !isEqual(existingValue, value)) {
            updated = true
            existingMap[key] = value
        }
    })

    // Removed
    const removedKeys = difference(Object.keys(existingMap), Object.keys(updatedMap))
    removedKeys.forEach((key) => {
        updated = true
        delete existingMap[key]
    })
    return updated
}

import { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { useParams } from 'react-router'

import { NotFoundPage } from '@laserfocus/client/ui-shared'
import { wrapAuthenticated } from '@laserfocus/client/feature-auth'
import { EmptyState } from '@laserfocus/ui/beam'
import { useBridgeStackStore } from '@laserfocus/client/store-shared'
import { StackModel } from '@laserfocus/client/model'
import { FilterOutlinedIcon } from '@laserfocus/ui/icons'
import { Overlay, useOverlay } from '@laserfocus/client/feature-overlay'
import {
    ContactFields,
    FailedMutation,
    isContact,
    isLeadModel,
    isOpportunity,
    OpportunityFields,
} from '@laserfocus/shared/models'
import { FailedMutationScope } from '@laserfocus/client/shared-error'

import { TablePageLayout } from './TablePageLayout'
import { TableErrorModal } from './TableErrorModal'
import { Table } from './Table/Table'
import { useTable } from './useTable'
import { useTablePageEverboarding } from './useTablePageEverboarding'
import { TableNoResults } from './Table/TableNoResults'
import { ImportFromSalesforceButton } from './Table/ImportFromSalesforceButton'

export const TablePage = wrapAuthenticated(
    observer(function TablePage() {
        const { stackSlug = '' } = useParams()
        const stackId = StackModel.parseIdFromSlug(stackSlug)

        const stackBridge = useBridgeStackStore()
        const tableProps = useTable(stackId, stackBridge)
        const tableStack = tableProps.type === 'success' ? tableProps?.stack : undefined

        const { overlayObject, ...overlayBag } = useOverlay(
            tableProps?.rows || [],
            'selectedObject'
        )
        useTablePageEverboarding(tableStack, Boolean(overlayObject))

        const filterFailedMutation = useCallback(
            (f: FailedMutation) => {
                return Boolean(
                    tableStack?.sobject &&
                        ['updateObject'].includes(f.mutation.name) &&
                        f.target?.object === tableStack.sobject
                )
            },
            [tableStack?.sobject]
        )

        if (tableProps.type === 'no-stack') {
            return null
        }
        if (tableProps.type === 'final-no-stack') {
            return (
                <NotFoundPage title="Maybe you’ve landed in a black hole. Or this stack simply doesn’t exist." />
            )
        }

        const {
            stack,
            rows,
            columns,

            setColumnsOrder,
            resetStack,
            updateStack,
            duplicateStack,
            setIsFavorite,
            setSorting,
            setTitle,
            deleteStack,
            searchTerm,
            setSearchTerm,
            count,
            serverCount,
            hasFetchError,
        } = tableProps

        const isLoading = rows.length > 10 ? false : tableProps.isLoading
        const isLoadingMore = (tableProps.isLoading || tableProps.isLoadingMore) && !isLoading

        // console.log('STATE', 'isLoading', isLoading, 'isLoadingMore', isLoadingMore)

        return (
            <FailedMutationScope name="TablePage" filter={filterFailedMutation}>
                {/* w-min: Necessary to grow container to table width which might be higher than window width to make horizontally sticky navbar and page header work*/}
                <div className={clsx('w-min', overlayObject && 'pr-[38rem]')}>
                    <TablePageLayout
                        stack={stack}
                        resetStack={resetStack}
                        duplicateStack={duplicateStack}
                        setIsFavorite={setIsFavorite}
                        setTitle={setTitle}
                        deleteStack={deleteStack}
                        rows={rows}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        loadedCount={count}
                        totalCount={serverCount}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        isOverlayOpen={Boolean(overlayObject)}
                        updateStack={updateStack}
                    >
                        <TableErrorModal />
                        <Table
                            columns={columns}
                            rows={rows}
                            stackId={stackId!}
                            stack={stack}
                            setColumnsOrder={setColumnsOrder}
                            searchTerm={searchTerm}
                            setSorting={setSorting}
                            sorting={stack.sorting}
                            isLoading={isLoading}
                            tableMessageBody={
                                Object.keys(stack.filterConditions).length === 0 ? (
                                    <NoFilter />
                                ) : hasFetchError ? (
                                    <FetchError />
                                ) : (
                                    !isLoading && rows.length === 0 && <TableNoResults />
                                )
                            }
                            overlayObjectId={overlayObject?.Id}
                        />
                    </TablePageLayout>
                    <Overlay
                        {...overlayBag}
                        stackSlug={stackSlug}
                        overlayData={
                            overlayObject
                                ? (() => {
                                      const objectId = overlayObject.Id
                                      if (isLeadModel(overlayObject)) {
                                          const rootId =
                                              overlayObject.ConvertedAccountId || objectId
                                          return {
                                              overlayKey: rootId,
                                              rootId,
                                              contactId: overlayObject.ConvertedContactId,
                                              opportunityId: overlayObject.ConvertedOpportunityId,
                                          }
                                      }
                                      if (isContact(objectId)) {
                                          return {
                                              overlayKey: objectId,
                                              rootId: ((overlayObject as ContactFields).AccountId ??
                                                  undefined) as string | undefined,
                                              contactId: overlayObject.Id,
                                          }
                                      }
                                      if (isOpportunity(objectId)) {
                                          return {
                                              overlayKey: objectId,
                                              rootId: (
                                                  (overlayObject as OpportunityFields) ?? undefined
                                              ).AccountId as string | undefined,
                                              opportunityId: overlayObject.Id,
                                          }
                                      }
                                      return {
                                          overlayKey: objectId,
                                          rootId: objectId,
                                      }
                                  })()
                                : undefined
                        }
                    />
                </div>
            </FailedMutationScope>
        )
    })
)

function NoFilter() {
    return (
        <EmptyState
            size="medium"
            emojis={['📇', '🎯', '📂']}
            title="You have too much data"
            subtitle={
                <>
                    So it would be better to set a filter. <br />
                    Hover over any column and click the filter button{' '}
                    <FilterOutlinedIcon className="h-4 w-4 inline mb-[2px]" /> to set a filter.
                </>
            }
        >
            <div className="text-grey-700/60">Or</div>
            <ImportFromSalesforceButton />
        </EmptyState>
    )
}

function FetchError() {
    return (
        <EmptyState
            variant="light"
            size="medium"
            emojis={['🌋', '🌪', '💥']}
            title="Oh no!"
            subtitle="An unexpected error happened while loading your data. Maybe try changing your filters."
            action={{
                label: 'Back to overview',
                href: '/home',
            }}
        />
    )
}

import { FieldType } from '@laserfocus/shared/models'
import { ObjectPool, StoredModel } from '@laserfocus/client/data-layer'

import type { FieldMetadata } from '../metadata/MetadataModel'
import { OpportunityModel } from '../sales/OpportunityModel'

type TableSObjectName = 'Lead' | 'Account' | 'Opportunity' | 'Contact'

export type ComputedColumn<SObject extends StoredModel = StoredModel> = {
    name: string
    label: string
    fieldType: FieldType
    objectName: TableSObjectName
    dependentOn: string[]
    getValue: (
        root: SObject,
        objectPool: ObjectPool
    ) => string | number | boolean | null | undefined
    getActual?: (root: SObject, objectPool: ObjectPool) => unknown
    isComputed: true
    hideFilter?: boolean
    hideAggregation?: boolean
    isEditable?: boolean
}

export function isComputedColumn(f: FieldMetadata | ComputedColumn): f is ComputedColumn {
    return Boolean((f as ComputedColumn).isComputed)
}

const NextActivityColumns: Omit<ComputedColumn, 'objectName'>[] = [
    {
        name: 'NextActivity.Subject',
        label: 'Next Task: Title',
        dependentOn: [],
        fieldType: 'string' as FieldType,
        isComputed: true,
        isEditable: true,
        getActual: (salesObject: any, objectPool: ObjectPool) => {
            return salesObject.NextActivity
        },
        getValue: (salesObject: any, objectPool: ObjectPool) => {
            const activity = salesObject.NextActivity
            if (!activity) {
                return null
            }
            return activity.Subject
        },
    },
    {
        name: 'NextActivity.Date',
        label: 'Next Task: Date',
        dependentOn: [],
        fieldType: 'datetime' as FieldType,
        isComputed: true,
        isEditable: true,
        getActual: (salesObject: any, objectPool: ObjectPool) => {
            return salesObject.NextActivity
        },
        getValue: (salesObject: any, objectPool: ObjectPool) => {
            const activity = salesObject.NextActivity
            if (!activity) {
                return undefined
            }
            return (
                activity.Date ||
                activity.ActivityDateTime ||
                activity.StartDateTime ||
                activity.ReminderDateTime
            )
        },
    },
]

function makeOpenInSalesforceColumn(
    sobject: 'Lead' | 'Account' | 'Opportunity' | 'Contact'
): ComputedColumn {
    return {
        name: 'LF_OpenInSalesforce',
        label: `Open ${sobject} in SF`,
        dependentOn: ['Id'],
        isComputed: true,
        fieldType: 'url',
        getValue: (model) => {
            return model?.Id
        },
        hideFilter: true,
        hideAggregation: true,
        objectName: sobject,
    }
}

const OpportunityComputedColumns: ComputedColumn<OpportunityModel>[] = [
    ...NextActivityColumns,
    makeOpenInSalesforceColumn('Opportunity'),
    {
        name: 'StageAge',
        label: 'Stage Age',
        dependentOn: ['LastStageChangeDate'],
        fieldType: 'int',
        getValue: (opp: OpportunityModel) => {
            return opp.StageAge as number
        },
        isComputed: true,
    } as Omit<ComputedColumn<OpportunityModel>, 'objectName'>,
].map((o) => ({
    ...o,
    objectName: 'Opportunity',
}))

const LeadComputedColumns: ComputedColumn[] = [
    ...NextActivityColumns,
    makeOpenInSalesforceColumn('Lead'),
].map((o) => ({
    ...o,
    objectName: 'Lead',
}))

const AccountComputedColumns: ComputedColumn[] = [
    ...NextActivityColumns,
    makeOpenInSalesforceColumn('Account'),
].map((o) => ({
    ...o,
    objectName: 'Account',
}))

const ContactComputedColumns: ComputedColumn[] = [
    ...NextActivityColumns,
    makeOpenInSalesforceColumn('Contact'),
].map((o) => ({
    ...o,
    objectName: 'Contact',
}))

export function getComputedColumn(sobject: TableSObjectName, fieldName: string) {
    const objectColumns = ComputedColumns[sobject]
    return objectColumns.find((a) => a.name === fieldName)
}

export const ComputedColumns = {
    Lead: LeadComputedColumns,
    Account: AccountComputedColumns,
    Contact: ContactComputedColumns,
    Opportunity: OpportunityComputedColumns as ComputedColumn[],
}

import { memo, useRef, useState } from 'react'
import { useVirtual } from 'react-virtual'
import clsx from 'clsx'
import { Transition } from '@headlessui/react'

import type { Sorting, Stack } from '@laserfocus/shared/models'
import { StackModel } from '@laserfocus/client/model'
import { theme } from '@laserfocus/ui/tailwindcss'
import { useWindowSize } from '@laserfocus/ui/util-react'
import { Spinner } from '@laserfocus/ui/beam'

import { TABLE_PAGE_HEADER_HEIGHT } from '../TablePageHeader/TablePageHeader'

import { AVATAR_COLUMN_WIDTH, Column, Row, TableDataProvider } from './table-context'
import { TableHead, TABLE_HEAD_HEIGHT } from './TableHead/TableHead'
import { TableRow, TABLE_ROW_HEIGHT } from './TableRow'
import { TableFooter, TABLE_FOOTER_HEIGHT } from './TableFooter/TableFooter'
import { BulkEditContextProvider } from './BulkEdit/bulkedit-context'

export interface TableProps {
    columns: Column[]
    rows: Row[]
    stackId: string
    stack?: Stack
    setColumnsOrder(columnKeys: string[]): void
    setSorting(sorting: Sorting): void
    sorting?: Sorting
    searchTerm: string
    tableMessageBody?: React.ReactNode
    overlayObjectId?: string
    isLoading: boolean
}

export function Table({
    columns,
    rows,
    stackId,
    stack,
    setColumnsOrder,
    setSorting,
    sorting,
    searchTerm,
    tableMessageBody,
    overlayObjectId,
    isLoading,
}: TableProps) {
    const [isStickyLeftActive, setIsStickyLeftActive] = useState(false)
    const { width } = useWindowSize()

    const renderedRows = tableMessageBody ? [] : rows
    const tableHeadKey = columns.map((c) => c.key).join()

    return (
        <>
            <BulkEditContextProvider sobject={stack?.sobject ?? null} rows={rows || []}>
                <TableDataProvider
                    value={{
                        columns,
                        rows: renderedRows,
                        stackId,
                        tableWidth: width,
                        setColumnsOrder,
                        setSorting,
                        sorting,
                        isStickyLeftActive,
                        setIsStickyLeftActive,
                        gridTemplateColumns: `${AVATAR_COLUMN_WIDTH}px ${columns
                            .map((column) => `minmax(${column.width}px, ${column.width}fr)`)
                            .join(' ')}`,
                        tableHeadBackgroundColor: stack
                            ? StackModel.getColor(stack)
                            : theme.colors.white,
                        searchTerm,
                        overlayObjectId,
                    }}
                >
                    <VirtualizedTable
                        isLoading={isLoading}
                        tableHeadKey={tableHeadKey}
                        itemCount={renderedRows.length}
                    />
                </TableDataProvider>
            </BulkEditContextProvider>
            {tableMessageBody && (
                <div
                    className={clsx(
                        'sticky left-0',
                        overlayObjectId ? 'w-[calc(100vw-38rem)]' : 'w-screen'
                    )}
                >
                    {tableMessageBody}
                </div>
            )}
        </>
    )
}

const bodyRef = { current: typeof document !== 'undefined' ? document.body : undefined }
const documentRef = { current: typeof document !== 'undefined' ? document : undefined }
const getRowSize = () => TABLE_ROW_HEIGHT

interface VirtualizedTableProps {
    itemCount: number
    tableHeadKey: string
    isLoading: boolean
}

const VirtualizedTable = memo(function VirtualizedTable({
    itemCount,
    tableHeadKey,
    isLoading,
}: VirtualizedTableProps) {
    const tableRef = useRef<HTMLDivElement>(null)
    const { virtualItems, totalSize } = useVirtual({
        size: itemCount,
        parentRef: bodyRef,
        estimateSize: getRowSize,
        onScrollElement: documentRef as any,
        // This function leads to a rerender on every scroll, which makes the footer janky
        scrollOffsetFn: () => window.scrollY - TABLE_PAGE_HEADER_HEIGHT,
        paddingEnd: isLoading || !itemCount ? 0 : TABLE_FOOTER_HEIGHT,
    })

    return (
        <div
            role="table"
            // w-min: Necessary to grow container to table width which might be higher than window width to make horizontally sticky navbar and page header work
            className="relative isolate bg-white text-sm leading-[1.4] font-medium w-min"
            style={{
                height: totalSize + TABLE_HEAD_HEIGHT,
                marginBottom: TABLE_FOOTER_HEIGHT,
            }}
            ref={tableRef}
        >
            {/* Key is necessary to prevent flicker after columns were rearranged and because a special gate to hell is opened in the react-spring state when the amount of columns is changed in a rerender. */}
            <TableHead key={tableHeadKey} />
            <div role="rowgroup" className="relative">
                {virtualItems.map((virtualRow) => (
                    <TableRow
                        key={virtualRow.index}
                        index={virtualRow.index}
                        translateY={virtualRow.start}
                    />
                ))}
            </div>
            {itemCount > 0 ? (
                <TableFooter key={`${tableHeadKey}-footer`} totalSize={totalSize} />
            ) : undefined}

            {isLoading && (
                <TableOverlay blur>
                    <Spinner />
                </TableOverlay>
            )}
        </div>
    )
})

interface TableOverlayProps {
    children: React.ReactNode
    blur?: boolean
}

function TableOverlay({ children, blur }: TableOverlayProps) {
    return (
        <>
            {blur && (
                <Transition
                    show
                    appear
                    enter="transition duration-500"
                    enterFrom="backdrop-blur-none"
                    className="absolute left-0 top-0 w-full h-full backdrop-filter backdrop-blur"
                />
            )}
            <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-40 rounded-full overflow-hidden">
                {children}
            </div>
        </>
    )
}

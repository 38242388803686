import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import copyToClipboard from 'copy-to-clipboard'

import { Button, Input, InputButton, SpinnerInline } from '@laserfocus/ui/beam'
import { apolloClient } from '@laserfocus/client/data-access-apollo'
import { IMPERSONATE_MUTATION } from '@laserfocus/shared/data-access-gql'
import { ClipboardListIcon, CheckFilledIcon } from '@laserfocus/ui/icons'

import { UserSelect } from './UserSelect'

export function ImpersonateUser() {
    const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined)
    const [token, setToken] = useState<string | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    return (
        <div className="grid grid-flow-row gap-4">
            <div className="grid grid-flow-col gap-2 justify-start items-center">
                <div
                    role="alert"
                    className={clsx('text-sm', 'pb-[0.3125rem]', 'pt-1', 'text-grey-700/60')}
                >
                    User
                </div>
                <div className="mr-2">
                    <UserSelect selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
                </div>
                {selectedUser && (
                    <GenerateLinkButton
                        userId={selectedUser}
                        setToken={setToken}
                        setErrorMessage={setErrorMessage}
                    />
                )}
            </div>
            <div>
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                {token && selectedUser && <RenderLinkButton userId={selectedUser} token={token} />}
            </div>
        </div>
    )
}

function GenerateLinkButton({
    userId,
    setToken,
    setErrorMessage,
}: {
    userId: string
    setToken: (token: string | undefined) => void
    setErrorMessage: (error: string | undefined) => void
}) {
    const [isLoading, setIsLoading] = useState(false)

    async function onClick() {
        setIsLoading(true)
        setToken(undefined)
        setErrorMessage(undefined)
        const result = await apolloClient.mutate({
            mutation: IMPERSONATE_MUTATION,
            variables: { userId },
        })

        const r = result.data.impersonate
        if (r.success && r.token) {
            setToken(r.token)
        } else if (r.error) {
            setErrorMessage(r.error)
        }
        setIsLoading(false)
    }
    return (
        <Button
            onClick={onClick}
            iconComponent={isLoading ? SpinnerInline : undefined}
            iconPosition="right"
        >
            Generate Link
        </Button>
    )
}

function RenderLinkButton({ userId, token }: { userId: string; token: string }) {
    // eslint-disable-next-line no-restricted-globals
    const url = `${location.protocol}//${location.host}/oauth/callback?token=${token}&userId=${userId}`

    const { isCopied, copy } = useCopyToClipboard(url)

    return (
        <>
            <div
                role="alert"
                className={clsx('text-sm', 'pb-[0.3125rem]', 'pt-1', 'text-grey-700/60')}
            >
                Copy this link and open it in a new anonymous tab
            </div>
            <Input
                className="w-80"
                readOnly
                variant="border"
                value={url}
                button={
                    <InputButton
                        disabled={isCopied}
                        icon={isCopied ? CheckFilledIcon : ClipboardListIcon}
                        onClick={isCopied ? undefined : copy}
                    />
                }
            />
        </>
    )
}

function useCopyToClipboard(value: string) {
    const [copied, setCopied] = useState(false)
    const copyRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
        return () => {
            clearTimeout(copyRef.current)
        }
    }, [])

    function copy() {
        copyToClipboard(value)
        setCopied(true)
        copyRef.current = setTimeout(() => {
            setCopied(false)
        }, 2000)
    }
    return { copy, isCopied: copied }
}

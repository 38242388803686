import { createContext, useContext } from 'react'

import { makeStackPath } from '@laserfocus/client/util-routing'
import { OrgStackInputExtension } from '@laserfocus/shared/models'

interface CreateStackContextType {
    type: 'user' | 'org'
    makePath: (slug: string) => string
    showHints: boolean
    allowImport: boolean
    extension?: OrgStackInputExtension
}
export const CreateStackContext = createContext<CreateStackContextType>({
    type: 'user',
    makePath: makeStackPath,
    showHints: true,
    allowImport: true,
})

export function useCreateStackContext() {
    return useContext(CreateStackContext)
}

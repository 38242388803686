import queryString from 'querystring'

import { matchPath } from 'react-router'
import { forEach } from 'lodash'

import { navigate } from './history'

export type ActivityStack = 'today' | 'overdue' | 'upcoming'

export type PersonQueryParams = {
    opportunityId?: string | null
    contactId?: string | null
    activityId?: string | null
}

export const RoutePaths = {
    Landing: '/hello',
    Login: '/login',
    Signup: '/signup',
    OnboardingTable: '/signup/create-overview',
    OnboardingSpecifyObject: '/signup/specify-relevant-objects',
    Onboarding: '/signup/select-role',
    ApiDisabled: '/signup/api-disabled',
    AccountNotActive: '/message/account-not-active',
    Home: '/home',
    Today: '/today',
    Stack: '/table/:stackSlug',
    Overdue: '/overdue',
    Upcoming: '/upcoming',
    TodayPerson: '/today/:personId',
    OverduePerson: '/overdue/:personId',
    UpcomingPerson: '/upcoming/:personId',
    // StackPerson: "/stack/:stackSlug/person/:personId",
    Person: '/person/:personId',
    StackPerson: '/stack/:stackSlug/person/:personId',
}

export const goToHome = () => {
    navigate(RoutePaths.Home)
}

export const makeTodayPath = () => RoutePaths.Today
export const goToTodayPage = () => {
    navigate(makeTodayPath())
}

export const makeOverduePath = () => RoutePaths.Overdue
export const goToOverdue = () => {
    navigate(makeOverduePath())
}

export const makeUpcomingPath = () => RoutePaths.Upcoming
export const goToUpcoming = () => {
    navigate(makeUpcomingPath())
}

export const makeActivityStackPath = (stack: ActivityStack) => `/${stack}`
export const goToActivityStackPage = (stack: ActivityStack) => {
    navigate(makeActivityStackPath(stack))
}

export const makeStackPath = (stackSlug: string) => makeUrl(RoutePaths.Stack, { stackSlug })
export const goToStackPage = (stackSlug: string) => {
    navigate(makeStackPath(stackSlug))
}

export const makePersonPath = (personId: string, params?: PersonQueryParams) =>
    makeUrl(RoutePaths.Person, { personId }, params)
export const goToPersonPage = (personId: string, params?: PersonQueryParams) => {
    navigate(makePersonPath(personId, params))
}

export const makeTodayPersonPath = (personId: string, params?: PersonQueryParams) =>
    makeUrl(RoutePaths.TodayPerson, { personId }, params)
export const goToTodayPersonPage = (personId: string, params?: PersonQueryParams) => {
    navigate(makeTodayPersonPath(personId, params))
}

export const makeOverduePersonPath = (personId: string, params?: PersonQueryParams) =>
    makeUrl(RoutePaths.OverduePerson, { personId }, params)
export const goToOverduePersonPage = (personId: string, params?: PersonQueryParams) => {
    navigate(makeOverduePersonPath(personId, params))
}

export const makeUpcomingPersonPath = (personId: string, params?: PersonQueryParams) =>
    makeUrl(RoutePaths.UpcomingPerson, { personId }, params)
export const goToUpcomingPersonPage = (personId: string, params?: PersonQueryParams) => {
    navigate(makeUpcomingPersonPath(personId, params))
}

export const makeStackPersonPath = (
    stackSlug: string,
    personId: string,
    params?: PersonQueryParams
) => makeUrl(RoutePaths.StackPerson, { stackSlug, personId }, params)
export const goToStackPersonPage = (
    stackSlug: string,
    personId: string,
    params?: PersonQueryParams
) => {
    navigate(makeStackPersonPath(stackSlug, personId, params))
}

export const isOnFocusPage = () => Boolean(matchPath(RoutePaths.Stack, window.location.pathname))

// export const goToOpenItemPage = (itemId: string, params?) =>
//     navigate(makeUrl(`/inbox/open/${itemId}`, params));
// export const goToFocusItemPage = (itemId, params?) =>
//     navigate(makeUrl(`/inbox/focus/person/${itemId}`, params));

export const makePersonDetailsPath = (
    personId: string,
    params?: PersonQueryParams,
    prefix?: ActivityStack
) => {
    if (prefix === 'today') {
        return makeTodayPersonPath(personId, params)
    } else if (prefix === 'overdue') {
        return makeOverduePersonPath(personId, params)
    } else if (prefix === 'upcoming') {
        return makeUpcomingPersonPath(personId, params)
    }
    return _makePersonDetailsPath(personId, params)
}

const _makePersonDetailsPath = (personId: string, params?: PersonQueryParams) => {
    const matchToday = matchPath(RoutePaths.Today, window.location.pathname)
    if (matchToday) {
        return makeTodayPersonPath(personId, params)
    }
    const matchOverdue = matchPath(RoutePaths.Overdue, window.location.pathname)
    if (matchOverdue) {
        return makeOverduePersonPath(personId, params)
    }
    const matchUpcoming = matchPath(RoutePaths.Upcoming, window.location.pathname)
    if (matchUpcoming) {
        return makeUpcomingPersonPath(personId, params)
    }
    return makePersonPath(personId, params)
}
export const goToPersonDetails = (
    personId: string,
    params?: PersonQueryParams,
    prefix?: ActivityStack
) => {
    navigate(makePersonDetailsPath(personId, params, prefix))
}

export function makeUrl(
    path: string,
    routeParams: Record<string, string>,
    queryParams?: Record<string, string | null | undefined>
) {
    let result = path
    forEach(routeParams, (value, key) => {
        const fullKey = `:${key}`
        result = result.replace(fullKey, value)
    })
    if (result.includes(':')) {
        throw new Error(`I could not replace all paths. Remaining: ${result}`)
    }
    if (queryParams) {
        const cleanQueryParams = JSON.parse(JSON.stringify(queryParams))
        result = `${result}?${queryString.stringify(cleanQueryParams)}`
    }
    return result
}

type RouteMatch =
    | {
          uri: string
          params: Record<string, string>
          route: {
              path: string
          }
      }
    | undefined
const match = (path: string, currentPath?: string): RouteMatch =>
    matchPath(path, currentPath || window.location.pathname) as unknown as RouteMatch

export function getLocation(currentPath?: string) {
    if (match(RoutePaths.Home, currentPath)) {
        return 'home'
    } else if (match(RoutePaths.Today, currentPath)) {
        return 'today'
    } else if (match(RoutePaths.Overdue, currentPath)) {
        return 'overdue'
    } else if (match(RoutePaths.Upcoming, currentPath)) {
        return 'upcoming'
    } else if (match(RoutePaths.Stack, currentPath)) {
        return 'table'
    } else if (
        match(RoutePaths.TodayPerson, currentPath) ||
        match(RoutePaths.OverduePerson, currentPath) ||
        match(RoutePaths.UpcomingPerson, currentPath) ||
        match(RoutePaths.Person, currentPath) ||
        match(RoutePaths.StackPerson, currentPath)
    ) {
        return 'person_details'
    }
}

export function getPersonId(currentPath?: string) {
    const personMatch =
        match(RoutePaths.TodayPerson, currentPath) ||
        match(RoutePaths.OverduePerson, currentPath) ||
        match(RoutePaths.UpcomingPerson, currentPath) ||
        match(RoutePaths.Person, currentPath) ||
        match(RoutePaths.StackPerson, currentPath)
    return personMatch?.params.personId
}

declare global {
    interface Window {
        getLocation: typeof getLocation
    }
}
if (typeof window !== 'undefined') {
    window.getLocation = getLocation
}

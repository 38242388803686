import { twMerge } from 'tailwind-merge'

import { Analytics } from '@laserfocus/client/util-analytics'
import { LockOutlinedIcon } from '@laserfocus/ui/icons'

interface EnterpriseSecureLinkProps {
    className?: string
}

export function EnterpriseSecureLink({ className }: EnterpriseSecureLinkProps) {
    return (
        <div
            className={twMerge(
                'text-sm font-medium leading-tight text-grey-700/60 text-center',
                className
            )}
        >
            <LockOutlinedIcon className="w-[18px] h-[18px] align-text-bottom mr-1 inline" />
            {'Secure for Enterprise. '}
            <a
                href="https://security.laserfocus.io"
                target="_blank"
                rel="noopener noreferrer"
                className="underline outline-none focus-visible:ring rounded"
                onClick={() => {
                    Analytics.trackEvent({
                        event: 'signup_security_clicked',
                        payload: {
                            source: 'enterprise-secure-link',
                            location: window.location.pathname,
                        },
                    })
                }}
            >
                Learn more
            </a>
        </div>
    )
}

import { twMerge } from 'tailwind-merge'
import Highlighter from 'react-highlight-words'

import { useActivitiesMetadataContext } from '@laserfocus/client/data-access-shared'
import { ActivityUtil } from '@laserfocus/client/model'
import { isTruthy, NewActivity } from '@laserfocus/shared/models'
import { CalendarFilledIcon } from '@laserfocus/ui/icons'

interface TaskTagsProps {
    activity: NewActivity
    className?: string
    hidePriority?: boolean
    searchTerm?: string
}

export function ActivityTags({ activity, className, hidePriority, searchTerm }: TaskTagsProps) {
    const isTask = ActivityUtil.isTask(activity)
    const isEvent = ActivityUtil.isEvent(activity)

    const metadata = useActivitiesMetadataContext()
    const selectedPriority = isTask
        ? metadata?.taskPriorities?.find((t) => t.value === activity.Priority)
        : undefined
    const selectedType = isTask
        ? metadata?.taskTypes?.find((t) => t.value === activity.Type)
        : isEvent
        ? metadata?.eventTypes?.find((t) => t.value === activity.Type)
        : undefined
    const hasDefaultPriority = selectedPriority?.value === metadata?.taskPriorityDefaultValue
    const shouldHidePriority = hidePriority || hasDefaultPriority
    // const hasTypeOrPriorityMetadata =
    //     selectedType || (metadata?.taskPriorities && !shouldHidePriority)

    const hasPriorityToShow = selectedPriority && !shouldHidePriority

    if (!isEvent && !hasPriorityToShow && !selectedType) {
        return null
    }

    return (
        <div
            className={twMerge(
                'pt-3 flex items-center gap-4 flex-wrap text-xs leading-none text-grey-700/60 font-medium',
                className
            )}
        >
            {isEvent && (
                <div className="flex items-center">
                    <CalendarFilledIcon className="w-3 h-3 flex-none mr-0.5" />
                    <Highlighter
                        autoEscape
                        highlightClassName="bg-blue-500/20 text-blue-700 rounded"
                        searchWords={[searchTerm].filter(isTruthy)}
                        textToHighlight="Event"
                    />
                </div>
            )}
            {selectedPriority &&
                !shouldHidePriority &&
                (() => {
                    const Icon = selectedPriority.iconComponent

                    return (
                        <div className="flex items-center">
                            {Icon && <Icon className="w-3 h-3 flex-none mr-0.5" />}
                            <Highlighter
                                autoEscape
                                highlightClassName="bg-blue-500/20 text-blue-700 rounded"
                                searchWords={[searchTerm].filter(isTruthy)}
                                textToHighlight={selectedPriority.label || ''}
                            />
                        </div>
                    )
                })()}
            {selectedType &&
                (() => {
                    const Icon = selectedType.iconComponent

                    return (
                        <div className="flex items-center">
                            {Icon && <Icon className="w-3 h-3 flex-none mr-0.5" />}
                            <Highlighter
                                autoEscape
                                highlightClassName="bg-blue-500/20 text-blue-700 rounded"
                                searchWords={[searchTerm].filter(isTruthy)}
                                textToHighlight={selectedType.label || ''}
                            />
                        </div>
                    )
                })()}
        </div>
    )
}

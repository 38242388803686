import { isBefore, isAfter, isSameDay, addMonths, isEqual, isSameMinute, isValid } from 'date-fns'

import { useMonth } from '../shared/useMonth'
import { isWithinDayRange } from '../shared/utils'

export const DEFAULT_TIME = {
    hours: 8,
    minutes: 0,
    seconds: 0,
} as const

export interface UseDatepickerProps {
    date: Date | null
    minDate?: Date
    maxDate?: Date
    onDateChange(date: Date): void
    initialVisibleMonth?: Date
}
export type UseDatepickerResult = ReturnType<typeof useDatepicker>

export function useDatepicker({
    date: selectedDate,
    minDate,
    maxDate,
    onDateChange,
    initialVisibleMonth,
}: UseDatepickerProps) {
    const [activeMonth, setActiveMonth] = useMonth(
        selectedDate || initialVisibleMonth,
        selectedDate,
        null
    )

    function canSelectDate(date: Date | null): boolean {
        return Boolean(date && isWithinDayRange(date, minDate, maxDate))
    }

    function canSelectTime(date: Date | null): date is Date {
        return Boolean(
            !date ||
                (isValid(date) &&
                    (!minDate || isAfter(date, minDate) || isEqual(date, minDate)) &&
                    (!maxDate || isBefore(date, maxDate) || isEqual(date, maxDate)))
        )
    }

    return {
        activeMonth,

        isDateSelected(date: Date | null): boolean {
            return selectedDate && date ? isSameDay(date, selectedDate) : selectedDate == date
        },

        isTimeSelected(date: Date | null): boolean {
            return selectedDate && date ? isSameMinute(date, selectedDate) : selectedDate == date
        },

        canSelectDate,

        canSelectTime,

        onDateSelect(dateWithoutTime: Date): void {
            if (canSelectDate(dateWithoutTime)) {
                const date = new Date(
                    dateWithoutTime.getFullYear(),
                    dateWithoutTime.getMonth(),
                    dateWithoutTime.getDate(),
                    ...(selectedDate
                        ? [
                              selectedDate.getHours(),
                              selectedDate.getMinutes(),
                              selectedDate.getSeconds(),
                              selectedDate.getMilliseconds(),
                          ]
                        : [DEFAULT_TIME.hours, DEFAULT_TIME.minutes, DEFAULT_TIME.seconds])
                )

                onDateChange(date)
                setActiveMonth(date)
            }
        },

        onTimeSelect(date: Date | null): void {
            if (canSelectTime(date)) {
                onDateChange(date)
                setActiveMonth(date)
            }
        },

        goToPreviousMonth(): void {
            setActiveMonth(addMonths(activeMonth.date, -1))
        },

        goToNextMonth(): void {
            setActiveMonth(addMonths(activeMonth.date, 1))
        },

        goToPreviousYear(amountOfYears = 1): void {
            setActiveMonth(addMonths(activeMonth.date, amountOfYears * -12))
        },

        goToNextYear(amountOfYears = 1): void {
            setActiveMonth(addMonths(activeMonth.date, amountOfYears * 12))
        },
    }
}

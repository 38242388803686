import { StrictMode, Suspense } from 'react'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { useObserver } from 'mobx-react-lite'

import { getHistory } from '@laserfocus/client/util-routing'
import { ErrorBoundary } from '@laserfocus/client/ui-shared'
import { Toaster, Spinner } from '@laserfocus/ui/beam'
import { ApolloProvider } from '@laserfocus/client/data-access-apollo'
import { TableUIStateContextProvider } from '@laserfocus/client/feature-table'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { ActivitiesMetadataProvider } from '@laserfocus/client/data-access-shared'
import { ErrorContainer } from '@laserfocus/client/shared-error'
import { UpdateNotification, useUserId } from '@laserfocus/client/feature-auth'
import { EverboardingContextProvider } from '@laserfocus/client/feature-everboarding'
import { Window } from '@laserfocus/client/desktop'
import { usePrefetchOpenActivityRoots } from '@laserfocus/client/store-shared'

import { RootStoreProvider } from './store/RootStoreContainer'
import Routes from './Router/Routes'

function AuthenticatedContainer({ children }) {
    const rootStore = useRootStore<{ isAuthenticated: boolean }>()
    const isAuthenticated = useObserver(() => rootStore.isAuthenticated)

    if (isAuthenticated) {
        return <AuthenticatedApp>{children}</AuthenticatedApp>
    }
    return children
}

function AuthenticatedApp({ children }) {
    const userId = useUserId()
    usePrefetchOpenActivityRoots(userId)
    return (
        <ActivitiesMetadataProvider>
            <ErrorContainer>
                <EverboardingContextProvider>
                    <UpdateNotification />
                    {children}
                </EverboardingContextProvider>
            </ErrorContainer>
        </ActivitiesMetadataProvider>
    )
}

export default function AppContainer() {
    return (
        <StrictMode>
            <Window>
                <HistoryRouter history={getHistory()}>
                    <RootStoreProvider>
                        <TableUIStateContextProvider>
                            <AuthenticatedContainer>
                                <ErrorBoundary>
                                    <Toaster />
                                    <ApolloProvider>
                                        <Suspense fallback={<Spinner fullscreen />}>
                                            <Routes />
                                        </Suspense>
                                    </ApolloProvider>
                                </ErrorBoundary>
                            </AuthenticatedContainer>
                        </TableUIStateContextProvider>
                    </RootStoreProvider>
                </HistoryRouter>
            </Window>
        </StrictMode>
    )
}

import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { Spinner } from '@laserfocus/ui/beam'

import App from '../app/App'

export function Index() {
    const router = useRouter()
    const [render, setRender] = useState(false)

    useEffect(() => {
        /**
         * We need to wait until next router is finished, otherwise we redirect to /home and then
         * next router becomes ready and redirects back to /
         */
        if (typeof window !== 'undefined' && router.isReady) {
            setRender(true)
        }
    }, [router.isReady])
    return render ? <App /> : <Spinner fullscreen />
}

export default Index

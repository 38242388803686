import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function OpportunityOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.739 2.89c-.806-1.283-2.69-1.24-3.435.08L7.58 7.79 4.505 5.637c-1.446-1.012-3.4.21-3.122 1.953l1.365 8.567a1 1 0 0 0 .987.843h16.53a1 1 0 0 0 .987-.843l1.344-8.437c.283-1.78-1.748-2.998-3.184-1.908l-2.6 1.972-3.073-4.894Zm1.378 5.958a2 2 0 0 0 2.903.53l2.6-1.973L19.41 15H4.59l-1.23-7.724 3.074 2.153a2 2 0 0 0 2.888-.655l2.724-4.82 3.072 4.894Z"
                fill="currentColor"
            />
            <path d="M4 20a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2H4Z" fill="currentColor" />
        </SvgIcon>
    )
}

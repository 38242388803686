import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { Button } from './button/Button'

export type ConfirmPromptProps = {
    show: boolean
    title: string
    description: string | React.ReactNode
    submitButtonTitle: string
    onCancel(): void
    onSubmit(): void | Promise<unknown>
}

export function ConfirmPrompt({
    show,
    title,
    description,
    submitButtonTitle,
    onCancel,
    onSubmit,
}: ConfirmPromptProps) {
    return (
        <Transition show={show} as={Fragment}>
            <Dialog onClose={onCancel} className="fixed z-20 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 w-full bg-black/30" />
                    </Transition.Child>
                    <Transition.Child
                        enter="duration-300 ease-out"
                        enterFrom="scale-95 opacity-0"
                        enterTo="scale-100 opacity-100"
                        leave="duration-75 ease-out"
                        leaveFrom="scale-100 opacity-100"
                        leaveTo="scale-95 opacity-0"
                    >
                        <div className="relative max-w-sm mx-auto bg-grey-700 text-white rounded-[0.625rem] p-4 grid grid-flow-row gap-4">
                            <Dialog.Title>{title}</Dialog.Title>
                            <Dialog.Description className="text-white/50">
                                {description}
                            </Dialog.Description>
                            <div className="grid justify-end grid-flow-col gap-4">
                                <Button
                                    variant="quaternary"
                                    className="bg-white/5"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={onSubmit}>
                                    {submitButtonTitle}
                                </Button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

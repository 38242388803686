import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { wrapAuthenticated } from '@laserfocus/client/feature-auth'
import { LayoutPadding, LayoutPaddingVertical, NavBar, PageHeader } from '@laserfocus/client/layout'
import { TaskList } from '@laserfocus/client/ui-shared-task'
import { useTitle } from '@laserfocus/ui/util-react'
import { isContact, isOpportunity, NewActivity, TaskId } from '@laserfocus/shared/models'
import { useIsMounted } from '@laserfocus/ui/hooks'
import { Overlay, useOverlay } from '@laserfocus/client/feature-overlay'
import {
    useRegisterEverboardingHint,
    EverboardingHintConfig,
    useConditionalTrackUntilCount,
} from '@laserfocus/client/feature-everboarding'
import { EventModel, TaskModel } from '@laserfocus/client/model'
import { SearchInputButton } from '@laserfocus/client/ui-shared'
import { Button } from '@laserfocus/ui/beam'

import { AddTaskButton } from '../TaskModal/AddTaskButton'
import { StackIconAvatar } from '../stack-overview/StackIconAvatar'

import { EmptyStack } from './EmptyStack'
import { LoadingStack } from './LoadingStack'
import { useOverdueActivities } from './useActivityStack'
import { FilterButton, useFilter } from './FilterButton'

const OverdueLayout = observer(function OverdueLayout() {
    const isMounted = useIsMounted()
    const {
        activities: overdue,
        loading,
        completeTask,
        dismissTask,
        searchTerm,
        setSearchTerm,
    } = useOverdueActivities()
    const { filteredActivities, ...filterButtonProps } = useFilter(overdue, true)
    useTitle(`Overdue${overdue.length ? ` - ${overdue.length}` : ''}`)

    const { overlayObject: overlayActivity, ...overlayBag } = useOverlay(
        filteredActivities,
        'selectedActivity'
    )
    useActivitystackEverboarding(Boolean(overlayActivity))

    return (
        <>
            <div className={overlayActivity ? 'w-[calc(100%-38rem)]' : 'w-full'}>
                <NavBar breadcrumbs={[{ title: 'Overdue' }]} bg="red" />
                <LayoutPaddingVertical maxWidth="stackWidth">
                    <PageHeader
                        title="Overdue"
                        icon={<StackIconAvatar icon="🔥" />}
                        actions={
                            <>
                                <SearchInputButton value={searchTerm} onChange={setSearchTerm} />
                                <FilterButton {...filterButtonProps} />
                                <AddTaskButton />
                            </>
                        }
                    />
                    <LayoutPadding top bottom>
                        {isMounted && (
                            <OverduePage
                                setSearchTerm={setSearchTerm}
                                searchTerm={searchTerm}
                                activities={filteredActivities}
                                loading={Boolean(loading)}
                                overlayActivity={overlayActivity}
                                toggleOverlayForActivity={overlayBag.toggleOverlayForActivity}
                                completeTask={completeTask}
                                dismissTask={dismissTask}
                            />
                        )}
                    </LayoutPadding>
                </LayoutPaddingVertical>
            </div>
            <Overlay
                {...overlayBag}
                overlayData={
                    overlayActivity
                        ? {
                              overlayKey: overlayActivity.Id,
                              rootId: overlayActivity.RootId ?? undefined,
                              contactId: isContact(overlayActivity.WhoId)
                                  ? overlayActivity.WhoId
                                  : undefined,
                              opportunityId: isOpportunity(overlayActivity.WhatId)
                                  ? overlayActivity.WhatId
                                  : undefined,
                              activityId: overlayActivity.Id,
                          }
                        : undefined
                }
            />
        </>
    )
})

function OverduePageWrapper() {
    return <OverdueLayout />
}

const AuthenticatedOverduePage = wrapAuthenticated(OverduePageWrapper)
export default AuthenticatedOverduePage

interface OverduePageProps {
    activities: Array<TaskModel | EventModel>

    loading: boolean
    overlayActivity?: NewActivity
    toggleOverlayForActivity?(id: string): void
    completeTask: (id: TaskId) => Promise<void>
    dismissTask: (id: TaskId) => void
    searchTerm?: string
    setSearchTerm: (searchTerm: string) => void
}

export function OverduePage({
    activities,
    loading,
    overlayActivity,
    toggleOverlayForActivity,
    completeTask,
    dismissTask,
    searchTerm,
    setSearchTerm,
}: OverduePageProps) {
    if (loading) {
        return <LoadingStack />
    }

    if (activities.length === 0) {
        if (searchTerm) {
            return (
                <EmptyStack
                    heading="No tasks found"
                    description="Clear your search to see which other tasks are available"
                    button={<Button onClick={() => setSearchTerm('')}>Clear Search</Button>}
                />
            )
        }
        return (
            <EmptyStack
                heading={
                    <>
                        You are up to date,
                        <br />
                        that's pretty great.
                    </>
                }
                description={
                    <>
                        If you have nothing else to do,
                        <br />
                        you can set quick reminders, too …
                    </>
                }
                button={<AddTaskButton label="Add Tasks" />}
            />
        )
    }

    return (
        <>
            <TaskList
                searchTerm={searchTerm}
                activities={activities}
                pathPrefix="overdue"
                completeTask={completeTask}
                dismissTask={dismissTask}
                overlayActivity={overlayActivity}
                toggleOverlayForActivity={toggleOverlayForActivity}
            />
        </>
    )
}

export function useActivitystackEverboarding(overlayOpen: boolean) {
    const completeHint: EverboardingHintConfig = useMemo(
        () => ({
            name: 'everboarding_activity_list_activity_action',
            shouldShow: (counters) => {
                return !counters?.everboarding_activity_list_activity_action && !overlayOpen
            },
        }),
        [overlayOpen]
    )
    useConditionalTrackUntilCount('everboarding_overdue_view', 30)
    useRegisterEverboardingHint(completeHint)
}

import {
    Events,
    isStackClicked,
    isActivityCompleted,
    isActivityRescheduled,
    isActivityScheduled,
    isBreadcrumbClicked,
    isDetailsClicked,
    isRecordEdited,
    isSearchClicked,
    isActivityStack,
    isTableStack,
    isActivityCreated,
    isActivityEdited,
    isActivityBulkEdited,
    StackNames,
    isFiedsCustomized,
    isFieldGroupCreated,
    isNoteCreated,
    isNoteEdited,
    isNoteTemplateUsed,
    isFilterChanged,
    isColumnsChanged,
    isGenericEvent,
    isOverlay,
    isErrorModal,
    isOverlayExpanded,
    isOverlayOpened,
    TableStacks,
} from './domain-events'

type SegmentPayload = {
    event: string
    payload: Record<string, any>
}

export function mapEvent(baseEvent: Events): SegmentPayload {
    if (isGenericEvent(baseEvent)) {
        return {
            ...baseEvent,
            payload: baseEvent.payload || {},
        }
    }

    const { event, ...payload } = baseEvent

    if (isStackClicked(baseEvent)) {
        const stackName = getStackName(baseEvent.stack)
        return {
            event: `home_${stackName}_clicked`,
            payload,
        }
    } else if (
        isDetailsClicked(baseEvent) ||
        isRecordEdited(baseEvent) ||
        isFiedsCustomized(baseEvent) ||
        isFieldGroupCreated(baseEvent) ||
        isNoteCreated(baseEvent) ||
        isNoteEdited(baseEvent) ||
        isNoteTemplateUsed(baseEvent) ||
        isOverlayOpened(baseEvent)
    ) {
        const locationType = getLocationType(baseEvent.location)
        return {
            event: `${locationType}_${baseEvent.event}`,
            payload,
        }
    } else if (isOverlayExpanded(baseEvent)) {
        return {
            event: `${baseEvent.location}_expanded`,
            payload,
        }
    } else if (isBreadcrumbClicked(baseEvent) || isSearchClicked(baseEvent)) {
        return {
            event,
            payload,
        }
    } else if (
        isActivityScheduled(baseEvent) ||
        isActivityEdited(baseEvent) ||
        isActivityCreated(baseEvent) ||
        isActivityBulkEdited(baseEvent) ||
        isActivityRescheduled(baseEvent) ||
        isActivityCompleted(baseEvent) ||
        isFilterChanged(baseEvent) ||
        isColumnsChanged(baseEvent)
    ) {
        const locationType = getLocationType(baseEvent.location)
        const finalLocation = locationType === 'home' ? 'list' : locationType
        if (finalLocation === 'person_details' || finalLocation === 'overlay') {
            return toGenericActivityAction(finalLocation, baseEvent)
        }
        return {
            event: `${finalLocation}_${baseEvent.event}`,
            payload,
        }
    }
    return {
        event,
        payload,
    }
}

export function parseStackFromSobject(
    sobject: 'Lead' | 'Account' | 'Contact' | 'Opportunity'
): TableStacks {
    switch (sobject) {
        case 'Lead':
            return 'lead_stack'
        case 'Account':
            return 'account_stack'
        case 'Contact':
            return 'contact_stack'
        case 'Opportunity':
            return 'opportunity_stack'
    }
}

function getLocationType(name: string) {
    if (isActivityStack(name)) {
        return 'list'
    } else if (isTableStack(name)) {
        return 'table'
    } else if (isOverlay(name)) {
        return 'overlay'
    } else if (isErrorModal(name)) {
        return 'error_modal'
    }
    return name
}

function getStackName(name: StackNames | string) {
    if (isActivityStack(name) || isTableStack(name)) {
        return name
    }
    return 'custom_stack'
}

function toGenericActivityAction(location: 'overlay' | 'person_details', baseEvent: Events) {
    const { event, ...payload } = baseEvent
    return {
        event: `${location}_activity_action`,
        payload: {
            action_type: event,
            ...payload,
        },
    }
}

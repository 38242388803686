import { isValid, parse } from 'date-fns'

const dateRegex = /^(?<month>[01]?\d)\/(?<day>[0-3]?\d)\/(?<year>\d{4})$/

export function getDateFromUserDateString(datgeString: string, dateToFill = new Date()) {
    const match = dateRegex.exec(datgeString)

    if (match) {
        const month = match.groups!.month!.padStart(2, '0')
        const day = match.groups!.day!.padStart(2, '0')
        const year = match.groups!.year
        const newSelectedDate = parse(`${month}/${day}/${year}`, 'MM/dd/y', new Date())

        if (isValid(newSelectedDate)) {
            return newSelectedDate
        }
    }

    return null
}

import { format } from 'date-fns'
import { useController, Path } from 'react-hook-form'
import { useEffect } from 'react'

import { Input } from '@laserfocus/ui/beam'
import {
    getDateFromTimeString,
    getDateFromUserTimeString,
    getTimeStringFromDate,
} from '@laserfocus/ui/util-date'

import { FormControlProps, SalesObject } from '../get-form-control'

import { getControllerRules, getInputProps, useStateReducer } from './formcontrol-util'

export function FormControlTime<T extends SalesObject = SalesObject>({
    fieldMetadata,
    id,
    variant,
    control,
    submit,
    name,
}: FormControlProps<T>) {
    const { field, fieldState } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })
    const [state, setState] = useStateReducer({
        isFocused: false,
        inputValue: parseValue((field.value as string | null) || ''),
    })
    useEffect(() => {
        if (!state.isFocused) {
            setState({ inputValue: field.value ? field.value.toString() : '' })
        }
    }, [field.value, setState, state.isFocused])
    return (
        <Input
            id={id}
            variant={variant}
            disabled={!fieldMetadata.updateable}
            {...getInputProps(field, fieldState, state, setState, submit, {
                parseValue,
                transformValue,
            })}
        />
    )
}

function fromModelValue(v: string | null): Date | null {
    // Not 100% sure about the timezone part, maybe it should be just XX
    // Format: '08:00:00.000Z'
    return v ? getDateFromTimeString(v) : null
}

function toString(v: Date | null) {
    return v ? format(v, 'HH:mm') : null
}

function toModelValue(d?: Date | null) {
    return d ? getTimeStringFromDate(d) : null
}

function parseValue(v: string | null): string {
    return toString(fromModelValue(v)) || ''
}

function transformValue(v: string): string | null {
    if (!v) {
        return null
    }
    const asDate = getDateFromUserTimeString(v)
    if (asDate) {
        return toModelValue(asDate)
    }
    return v
}

import RequireAuth from './RequireAuth'
import { SettingsProvider } from './container/SettingsContainer'

type ComponentType<Props> = React.FunctionComponent<Props>

export function wrapAuthenticated<Props>(Component: ComponentType<Props>) {
    return (props: Props) => (
        <Authenticated>
            <Component {...props} />
        </Authenticated>
    )
}

export function Authenticated({ children }: { children: React.ReactNode }) {
    return (
        <RequireAuth>
            <SettingsProvider>{children}</SettingsProvider>
        </RequireAuth>
    )
}

import { Replicache } from 'replicache'
import { action, computed, observable } from 'mobx'

import {
    AccountModel,
    ContactModel,
    isAccount,
    isContact,
    isLead,
    isTruthy,
    LeadModel,
    NewActivity,
} from '@laserfocus/shared/models'
import {
    PoolQuery,
    TrieSnapshotter,
    RelationshipLoader,
    ObjectPool,
    QueryStatus,
} from '@laserfocus/client/data-layer'
import {
    TaskModel,
    EventModel,
    activityMinisearchOptions,
    makeActivitySnapshot,
    ActivitySearchIndex,
} from '@laserfocus/client/model'

type ActivityModel = TaskModel | EventModel

export type Dependencies = {
    objectPool: ObjectPool
    replicache: Replicache
    queryStatus: QueryStatus
}

export abstract class ActivityView {
    objectPool: ObjectPool
    replicache: Replicache
    queryStatus: QueryStatus

    reactions: Array<() => void> = []
    mountReactions: Array<() => void> = []

    trieSnapshotter: TrieSnapshotter<ActivityModel, ActivitySearchIndex>
    leadLoader: RelationshipLoader<NewActivity, LeadModel>
    contactLoader: RelationshipLoader<NewActivity, ContactModel>
    accountLoader: RelationshipLoader<NewActivity, AccountModel>

    abstract taskQuery: PoolQuery<TaskModel>
    abstract eventQuery?: PoolQuery<EventModel>
    abstract isLoading: boolean

    constructor({ objectPool, queryStatus, replicache }: Dependencies) {
        this.objectPool = objectPool
        this.replicache = replicache
        this.queryStatus = queryStatus
        this.trieSnapshotter = new TrieSnapshotter(
            () => this.data,
            activityMinisearchOptions,
            makeActivitySnapshot
        )
        this.leadLoader = new RelationshipLoader(
            { objectPool, replicache },
            {
                foreignModel: 'Lead',
                getForeignKey(task) {
                    return isLead(task.WhoId) ? task.WhoId : undefined
                },
                getData: () => this.data,
            }
        )
        this.contactLoader = new RelationshipLoader(
            { objectPool, replicache },
            {
                foreignModel: 'Contact',
                getForeignKey(task) {
                    return isContact(task.WhoId) ? task.WhoId : undefined
                },
                getData: () => this.data,
            }
        )
        this.accountLoader = new RelationshipLoader(
            { objectPool, replicache },
            {
                foreignModel: 'Account',
                getForeignKey(task) {
                    return isAccount(task.AccountId) ? task.AccountId : undefined
                },
                getData: () => this.data,
            }
        )
    }

    onInit() {
        this.taskQuery.onInit()
        this.eventQuery?.onInit()
    }

    onDestroy() {
        this.taskQuery.onDestroy()
        this.eventQuery?.onDestroy()
    }

    @action
    onMount() {
        this._onMount()
        this.leadLoader.onMount()
        this.accountLoader.onMount()
        this.contactLoader.onMount()
        this.trieSnapshotter.onInit()
        this.completedTaskIds = new Set()
        return () => this.onUnmount()
    }

    abstract _onMount(): void
    abstract data: ActivityModel[]

    onUnmount() {
        this.leadLoader.onUnmount()
        this.accountLoader.onUnmount()
        this.contactLoader.onUnmount()
        this.trieSnapshotter.onDestroy()
    }

    get trie() {
        return this.trieSnapshotter.trie
    }

    @observable completedTaskIds = new Set<string>()

    @action
    completeTask(taskId: string) {
        this.completedTaskIds.add(taskId)
    }

    @action
    dismissTask(taskId: string) {
        this.completedTaskIds.delete(taskId)
    }

    @computed
    get completedTasks() {
        return Array.from(this.completedTaskIds)
            .map((taskId) => this.objectPool.get<TaskModel>('Task', taskId))
            .filter(isTruthy)
    }
}

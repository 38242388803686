import { useSubscribe } from 'replicache-react'

import { AccountId, LeadId, Prefix, SalesObjectExtension } from '@laserfocus/shared/models'
import { pickEmoji } from '@laserfocus/client/util-formatter'
import { getClient } from '@laserfocus/client/replicache'

export function useSalesobjectEmoji(rootId: LeadId | AccountId | string | undefined) {
    const extension = useSubscribe(
        getClient(),
        async (tx) => {
            const key = [Prefix.SalesExtension, rootId].join('/')
            const extension = await tx.get(key)
            return extension as SalesObjectExtension | null
        },
        null,
        [rootId]
    ) as SalesObjectExtension | null
    return extension?.emoji || pickEmoji(rootId)
}

import { runInAction } from 'mobx'
import { set, omit } from 'lodash'
import { ulid } from 'ulid'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import type {
    Sorting,
    Stack,
    FilterCondition,
    StackInput,
    StackUpdate,
    Aggregation,
} from '@laserfocus/shared/models'
import { mutateStack } from '@laserfocus/client/data-access-shared'
import { fractional } from '@laserfocus/shared/util-common'
import { makeStackPath } from '@laserfocus/client/util-routing'
import { StackModel } from '@laserfocus/client/model'
import { assert } from '@laserfocus/ui/logger'

export type StackActions = NonNullable<ReturnType<typeof useStackActions>>

export function useStackActions(
    stack: Stack | undefined,
    persistedStack: Stack | null,
    type: 'org' | 'user'
) {
    const navigate = useNavigate()
    const duplicateTemplate = useCallback(
        async (id: string, update: Partial<StackInput>) => {
            assert(type === 'user', 'Duplicate Template should not be called for orgs')
            if (!stack) {
                return
            }
            const finalStack = omit(
                {
                    ...stack,
                    title: `(Copy of) ${stack.title}`,
                    isFavorite: true,
                    ...update,
                },
                'preset'
            )
            const created = await mutateStack.duplicateStack(id, finalStack, 'user')
            if (created) {
                navigate(makeStackPath(StackModel.getSlug({ ...finalStack, id: created.id })))
            }
        },
        [navigate, stack, type]
    )
    if (!stack) {
        return null
    }
    const isTemplate = !persistedStack && stack.id.split('-')[0] === 'template'

    return {
        duplicate: async (title: string) => {
            const id = persistedStack?.id === stack.id ? ulid() : stack.id
            const nextStack = {
                ...stack,
                title,
            }
            return mutateStack.duplicateStack(id, nextStack, type)
        },
        setTitle: async (title: string) => {
            if (isTemplate) {
                const id = ulid()
                await duplicateTemplate(id, { title })
            } else if (persistedStack) {
                return mutateStack.setTitle(persistedStack.id, title, type)
            } else {
                runInAction(() => {
                    stack.title = title
                })
            }
        },
        updateStack: async (update: StackUpdate) => {
            if (isTemplate) {
                const id = ulid()
                await duplicateTemplate(id, update)
            } else if (persistedStack) {
                return mutateStack.updateStack(persistedStack.id, update, type)
            } else {
                runInAction(() => {
                    if (stack) {
                        Object.entries(update).forEach(([key, value]) => {
                            set(stack, key, value)
                        })
                    }
                })
            }
        },
        reset: () => {
            if (isTemplate) {
            }
            return mutateStack.resetStack(stack.id)
        },
        showColumn: async (columnName: string) => {
            const position = fractional.getOrderKeyForEnd(stack.visibleColumnMap)
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                await duplicateTemplate(id, {})
            }
            if (id) {
                return mutateStack.showColumn(id, { columnName, position }, type)
            }
            runInAction(() => {
                stack.visibleColumnMap[columnName] = position
            })
        },
        hideColumn: async (columnName: string) => {
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                await duplicateTemplate(id, {})
            }
            if (id) {
                return mutateStack.hideColumn(id, columnName, type)
            }
            runInAction(() => {
                delete stack.visibleColumnMap[columnName]
            })
        },
        async setColumnsOrder(columnNames: string[]) {
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                await duplicateTemplate(id, {})
            }
            if (id) {
                return mutateStack.setColumnsOrder(id, fractional.getOrderedMap(columnNames), type)
            }
            runInAction(() => {
                stack.visibleColumnMap = {
                    ...stack.visibleColumnMap,
                    ...fractional.getOrderedMap(columnNames),
                }
            })
        },
        async setColumnsWidth(columnName: string, width: number) {
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                await duplicateTemplate(id, {})
            }
            if (id) {
                return mutateStack.setColumnWidth(id, { columnName, width }, type)
            }
            runInAction(() => {
                stack.columnWidths[columnName] = width
            })
        },
        async setColumnAggregation(columnName: string, aggregation: Aggregation) {
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                await duplicateTemplate(id, {})
            }
            if (id) {
                return mutateStack.setColumnAggregate(id, { columnName, aggregation }, type)
            }
            runInAction(() => {
                stack.columnAggregates[columnName] = aggregation
            })
        },
        async setSorting(sorting: Sorting) {
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                await duplicateTemplate(id, {})
            }
            if (id) {
                return mutateStack.setSorting(id, sorting, type)
            }
            runInAction(() => {
                stack.sorting = sorting
            })
        },
        async setFilterCondition(condition: FilterCondition) {
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                await duplicateTemplate(id, {})
            }
            if (id) {
                return mutateStack.setFilterCondition(id, condition, type)
            }
            runInAction(() => {
                stack.filterConditions[condition.fieldName] = condition
            })
        },
        async removeFilter(columnName: string) {
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                await duplicateTemplate(id, {})
            }
            if (id) {
                return mutateStack.removeFilter(id, columnName, type)
            }
            runInAction(() => {
                delete stack.filterConditions[columnName]
            })
        },
        async setIsFavorite(isFavorite: boolean) {
            assert(type === 'user', 'Only user stacks can be favorited')
            const id = isTemplate ? ulid() : persistedStack?.id
            if (isTemplate && id && stack) {
                // Will be favoorited by default
                await duplicateTemplate(id, {})
            } else if (id) {
                if (isFavorite) {
                    return mutateStack.favoriteStack(id)
                } else {
                    return mutateStack.unfavoriteStack(id)
                }
            } else {
                runInAction(() => {
                    stack.isFavorite = isFavorite
                })
            }
        },
        deleteStack() {
            assert(type === 'user', 'Only user stacks can be deleted')
            if (isTemplate) {
                return Promise.resolve()
            }
            return mutateStack.deleteStack(stack.id)
        },
    }
}

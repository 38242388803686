import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function CopyTextOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8 11a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM9 15a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2H9Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.172 3.172A4 4 0 0 1 15.873 5H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h1.127a4 4 0 0 1 1.045-1.828ZM12 4a2 2 0 0 0-2 2 1 1 0 0 1-1 1H7a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-2a1 1 0 0 1-1-1 2 2 0 0 0-2-2Z"
                fill="currentColor"
            />
        </SvgIcon>
    )
}

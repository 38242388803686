import { MultiSelect } from '../../Select/MultiSelect'

import { SelectButton } from './SelectButton'

interface DropdownMultiSelectProps<T extends Partial<StaticOption>> {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    placeholder: string
    options: readonly Option<T>[]
    selectedOptions?: readonly Option<T>[]
    bottomAction?: {
        label: string
        onClick(): void
    }
    onChange(options: readonly Option<T>[]): void
    onCreatableSubmit?(value: string): void
}

type Option<T extends Partial<StaticOption>> = T & StaticOption
interface StaticOption {
    value: string
    label: string
    className?: string
}

export function DropdownMultiSelect<T extends Partial<StaticOption>>({
    isOpen,
    setIsOpen,
    placeholder,
    options,
    selectedOptions = [],
    bottomAction,
    onChange,
    onCreatableSubmit,
}: DropdownMultiSelectProps<T>) {
    function close() {
        setIsOpen(false)
    }

    return (
        <MultiSelect
            isOpen={isOpen}
            hideArrow
            syncWidth
            onCancel={close}
            className="bg-grey-650"
            searchKeys={['label']}
            options={options}
            initialOptionValues={selectedOptions.map((o) => o.value)}
            onSubmit={onChange}
            onCreatableSubmit={onCreatableSubmit}
            bottomAction={bottomAction}
        >
            <SelectButton
                placeholder={placeholder}
                selectedOptionLabel={getSelectedOptionLabel(selectedOptions)}
                badge={selectedOptions.length > 1 ? selectedOptions.length : undefined}
                onClick={() => setIsOpen((v) => !v)}
            />
        </MultiSelect>
    )
}

function getSelectedOptionLabel(selectedOptions: readonly StaticOption[]) {
    if (selectedOptions.length > 1) {
        return 'Multiple selected'
    }
    if (selectedOptions.length === 1) {
        return selectedOptions[0]!.label
    }
    return undefined
}

import gql from 'graphql-tag'

import { LEAD_FRAGMENT } from '../person/lead-fragments'
import { PERSON_TASK_FRAGMENT } from '../person/person-task-fragment'
import { PERSON_EVENT_FRAGMENT } from '../person/person-event-fragment'

export const LEADS_BY_FILTER_QUERY = gql`
    ${PERSON_TASK_FRAGMENT}
    ${PERSON_EVENT_FRAGMENT}
    ${LEAD_FRAGMENT}
    query getLeadsByFilter($filter: FilterV3!, $sorting: Sorting) {
        getLeadsByFilter(filter: $filter, sorting: $sorting) {
            done
            totalSize
            nextCursor
            records {
                ...LeadHeader
                OpenActivities {
                    ... on Task {
                        ...PersonTask
                    }
                    ... on Event {
                        ...PersonEvent
                    }
                }
            }
        }
    }
`

import {
    PatchOperation,
    PullerResult,
    ClientStateNotFoundResponse,
    PullResponseOK,
    PullResponse,
} from 'replicache'

import { Config } from '@laserfocus/shared/config-env'
import { getToken } from '@laserfocus/client/data-access-apollo'

const { REPLICACHE_ENDPOINT_PATH } = Config

interface PaginatedResponse extends PullResponseOK {
    nextPage?: string
}

type Page = {
    patch: PatchOperation[]
    nextPage?: string
}

export async function paginatePullResponse(result: PullerResult): Promise<PullerResult> {
    const response = result.response
    if (!response) {
        return result
    }
    if (isRCErrorResponse(response)) {
        return result
    }
    let nextCursor = (response as PaginatedResponse)?.nextPage
    if (!nextCursor) {
        return result
    }
    let additionalPatches = []
    while (nextCursor) {
        const page: Page = await fetchPage(nextCursor)
        const patches = page?.patch || []
        additionalPatches.push(...patches)
        nextCursor = page?.nextPage
    }

    return {
        ...result,
        response: {
            ...response,
            patch: [...response.patch, ...additionalPatches],
        },
    }
}

async function fetchPage(cursor: string) {
    const url = `${REPLICACHE_ENDPOINT_PATH}/continue-pull?cursor=${cursor}`
    const fetchResult = await fetch(url, {
        method: 'POST',
        headers: {
            Accepts: 'application/json',
            'content-type': 'application/json',
            Authorization: getToken()!,
        },
        body: '',
    })
    const parsed = await fetchResult.json()
    return parsed as Page
}

function isRCErrorResponse(response: PullResponse): response is ClientStateNotFoundResponse {
    return (response as ClientStateNotFoundResponse).error === 'ClientStateNotFound'
}

import { useSubscribe } from 'replicache-react'
import { useParams } from 'react-router-dom'

import { getClient } from '@laserfocus/client/replicache'
import {
    Prefix,
    NoteRelation,
    isLead,
    isAccount,
    Account,
    Lead,
    PinnedActivities,
    ContentNote,
    ContentNoteContent,
} from '@laserfocus/shared/models'
import { EmojiAvatar } from '@laserfocus/client/ui-shared'
import { useSalesobjectEmoji } from '@laserfocus/client/store-shared'
import { Spinner, EmptyState } from '@laserfocus/ui/beam'
import { useBodyClassName, useTitle } from '@laserfocus/ui/util-react'

import { Note } from './Note'
import { getSingleWindowNoteProps } from './note-props'
import { ClientNote } from './useRootNotes'

export function SingleNotePage() {
    const { noteId } = useParams()
    const { note, rootName, rootId, didLoad } = useNote(noteId)
    useBodyClassName('bg-yellow-50')
    const emoji = useSalesobjectEmoji(rootId ?? undefined)
    if (!didLoad) {
        return <Spinner fullscreen />
    }
    if (!noteId || !note) {
        return <NoteNotFound />
    }
    if (!note) {
        return <div> Wrong url or could not find note</div>
    }

    return (
        <div className="bg-white">
            <div className="flex items-center bg-yellow-50 p-4">
                <EmojiAvatar emoji={emoji} />
                <h2 className="text-xl font-serif ml-3">{rootName}</h2>
            </div>
            <div className="p-4">
                <Note {...getSingleWindowNoteProps(note, rootId ?? null)} />
            </div>
        </div>
    )
}

type NoteContext = {
    note: ClientNote | null
    rootName: string | null
    rootId?: string | null
    didLoad: boolean
}
function useNote(noteId?: string): NoteContext {
    const rep = getClient()
    return useSubscribe<NoteContext>(
        rep,
        async (tx) => {
            if (!noteId) {
                return { note: null, rootId: null, rootName: null, didLoad: true }
            }
            const [meta, content, relations] = await Promise.all([
                tx.get(
                    [Prefix.ContentNote, 'meta', noteId].join('/')
                ) as Promise<ContentNote | null>,
                tx.get(
                    [Prefix.ContentNote, 'content', noteId].join('/')
                ) as Promise<ContentNoteContent | null>,
                tx
                    .scan({ indexName: 'notesRelationByNote', prefix: noteId })
                    .values()
                    .toArray() as Promise<Array<NoteRelation>>,
            ])

            const relationIds = relations.map((a) => a.sobjectId)
            const leadId = relationIds.find((id) => isLead(id))
            const accountId = relationIds.find((id) => isAccount(id))

            const rootId = accountId || leadId

            const pinnedPromise = tx.get([Prefix.PinnedActivities, rootId].join('/'))
            let rootName: string = ''
            if (accountId) {
                const account = (await tx.get(
                    [Prefix.Account, accountId].join('/')
                )) as unknown as Account
                rootName = account?.Name || ''
            } else if (leadId) {
                const lead = (await tx.get([Prefix.Lead, leadId].join('/'))) as unknown as Lead
                rootName = lead.Company || lead.Name || ''
            }

            const pinned = (await pinnedPromise) as PinnedActivities | null
            const isPinned = Boolean(pinned?.notes?.includes(noteId))

            // let

            // const rootId = '5'

            const note: ClientNote | null =
                meta && content
                    ? {
                          ...meta,
                          Body: content.Body,
                          isPinned,
                      }
                    : null

            return {
                note,
                rootId: accountId || leadId,
                rootName,
                didLoad: true,
            }
        },
        { note: null, rootId: null, rootName: null, didLoad: false },
        [noteId]
    )
}

function NoteNotFound() {
    useTitle('404 - Note not found')
    useBodyClassName('bg-grey-700')

    return (
        <div className="h-screen">
            <EmptyState
                variant="dark"
                emojis={['🕳', '📄', '👀']}
                title="Oh no!"
                subtitle="Note not found. Try closing this window and open the note again"
                action={{
                    label: 'close window',
                    onClick: () => window.close(),
                }}
            />
        </div>
    )
}

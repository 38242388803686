import React from 'react'

import { useUserId, wrapAuthenticated } from '@laserfocus/client/feature-auth'
import { useTitle } from '@laserfocus/ui/util-react'
import { useActivityCounterForUserBetween } from '@laserfocus/client/data-access-shared'

import { CounterContextProvider, TIMEFRAMES, useCounterContext } from './CounterContext'
import { StatsLayout } from './StatsLayout'
import { ClicksSavedCard, TimeSavedCard } from './SavedCard'
import { ROICard } from './ROICard'
import { QuotaCard } from './QuotaCard'
import { HourWorthCard } from './HourWorthCard'

export const StatsPage = wrapAuthenticated(function StatsPage() {
    useTitle(`Your Laserfocus Stats`)
    return (
        <CounterContextProvider>
            <StatsLayout>
                <StatsPageInner />
            </StatsLayout>
        </CounterContextProvider>
    )
})

function StatsPageInner() {
    const activityCounter = useCurrentCounters()

    return (
        <div>
            <div className="grid grid-cols-2 gap-6">
                <QuotaCard />
                <ROICard counters={activityCounter} />
                <TimeSavedCard counters={activityCounter} />
                <HourWorthCard />
                <ClicksSavedCard counters={activityCounter} />
            </div>
        </div>
    )
}

function useCurrentCounters() {
    const userId = useUserId()
    const { timeframe } = useCounterContext()
    const period = TIMEFRAMES.find((t) => t.value === timeframe)

    const counterItems = useActivityCounterForUserBetween(
        userId,
        period?.getStart(),
        period?.getEnd()
    )
    return counterItems
}

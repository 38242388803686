import { Switch } from '@laserfocus/ui/beam'

import { Column } from '../Table/table-context'

interface ShowColumnSwitchProps {
    column: Column
}

export function ShowColumnSwitch({ column }: ShowColumnSwitchProps) {
    const showColumnId = `edit-columns-${column.key}-show-column`

    return (
        <div className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-2">
            <Switch
                checked={column.isShown}
                id={showColumnId}
                onDarkBackground
                onChange={column.toggleIsShown}
                className="p-0"
            />
            <label
                htmlFor={showColumnId}
                className="text-white/60 text-xs leading-[1.2] font-medium cursor-pointer"
            >
                Show column
            </label>
        </div>
    )
}

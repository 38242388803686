import { useMemo } from 'react'

import {
    inviteColleagues,
    useColleagues,
    useInvites,
    useLFUsers,
} from '@laserfocus/client/data-access-shared'
import { LFUser, User, UserId, UserInvite } from '@laserfocus/shared/models'
import { Analytics } from '@laserfocus/client/util-analytics'
import { toast } from '@laserfocus/ui/beam'
import { logger } from '@laserfocus/ui/logger'

export type InviteStatus =
    | 'not invited'
    | 'pending invite from me'
    | 'pending invite from colleague'
    | 'signed up'
export type InviteUserRow = {
    user: User
    lfUser?: LFUser
    alreadyUser: boolean
    alreadyInvited: boolean
    inviter?: User
    invite: () => void
    status: InviteStatus
}

export function useUsersToInvite(userId: UserId, keepMyself?: boolean) {
    const users = useColleagues(keepMyself)
    const lfUsers = useLFUsers()
    const invites = useInvites()

    const activeWithoutMe = users.filter((u) => u.IsActive)

    const usersById = Object.fromEntries(users.map((u) => [u.Id, u]))
    const lfUsersById = Object.fromEntries(lfUsers.map((u) => [u.userId, u]))
    const invitesByInvitee = useMemo(() => {
        const byInvitee: Record<UserId, UserInvite> = {}
        invites.forEach((invite) => {
            const existingInvite = byInvitee[invite.invitee]
            if (existingInvite && existingInvite.inviter !== userId && invite.inviter === userId) {
                byInvitee[invite.invitee] = invite
            } else if (!existingInvite) {
                byInvitee[invite.invitee] = invite
            }
        })
        return byInvitee
    }, [invites, userId])

    const rows: InviteUserRow[] = useMemo(() => {
        function invite(invitee: UserId) {
            inviteColleagues([invitee], userId).then(
                () => {
                    Analytics.trackEvent({
                        event: 'coworker_invited_fe',
                        payload: {
                            userCount: 1,
                            invitee,
                        },
                    })

                    const singleUser = usersById[invitee[0]]
                    if (singleUser) {
                        toast.success({
                            title: `Invite sent`,
                            description: `You just invited ${singleUser?.Name}`,
                        })
                    } else {
                        toast.success({
                            title: `Invite sent`,
                        })
                    }
                },
                (e) => {
                    logger.error(e)
                    toast.error({ title: 'Error while inviting your coworker' })
                }
            )
        }
        return activeWithoutMe.map((user) => {
            const existingInvite = invitesByInvitee[user.Id]
            const inviter = usersById[existingInvite?.inviter]
            const lfUser = lfUsersById[user.Id]
            const alreadyUser = Boolean(lfUser?.signedUp)
            const alreadyInvited = Boolean(existingInvite)
            return {
                user,
                lfUser,
                alreadyUser,
                alreadyInvited,
                inviter,
                invite: existingInvite ? () => {} : () => invite(user.Id),
                status: getInviteState(
                    alreadyUser,
                    alreadyInvited,
                    userId,
                    inviter?.Id || existingInvite?.inviter
                ),
            }
        })
    }, [activeWithoutMe, invitesByInvitee, lfUsersById, userId, usersById])

    return rows
}

function getInviteState(
    alreadyUser: boolean,
    alreadyInvited: boolean,
    myId: UserId,
    inviterUserId?: UserId
): InviteStatus {
    if (alreadyUser) {
        return 'signed up'
    }
    if (alreadyInvited) {
        if (inviterUserId === myId) {
            return 'pending invite from me'
        }
        return 'pending invite from colleague'
    }
    return 'not invited'
}

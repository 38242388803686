import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import { ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'

import { Prefix } from '@laserfocus/shared/models'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { getClient } from '@laserfocus/client/replicache'

const API_DISABLED_CODE = 'API_DISABLED_FOR_ORG'
const BLOCKED_ORG = 'INACTIVE_USER'
const BLOCKED_USER = 'BLOCKED_USER'

type GlobalError = {
    code: string
}
export function useGlobalError() {
    const navigate = useNavigate()
    const client = getClient()
    const globalError = useSubscribe(
        client,
        async (tx: ReadTransaction) => {
            const err = (await tx.get([Prefix.Error, 'global'].join('/'))) as GlobalError
            return err ?? null
        },
        null,
        []
    )
    useEffect(() => {
        if (globalError) {
            if (globalError.code === BLOCKED_ORG || globalError.code === BLOCKED_USER) {
                navigate(RoutePaths.AccountNotActive)
            } else if (globalError.code === API_DISABLED_CODE) {
                navigate(RoutePaths.ApiDisabled)
            }
        }
    }, [globalError, navigate])
}

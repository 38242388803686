import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema } from '../base/date.types'
import { UserIdSchema } from '../base/id.types'

import { Optional } from './type-utills'

export type Id = string
export type EmailAddress = string
export type Url = string // 'https://laserfoucs-dev-ed--c.documentforce.com/profilephoto/005/F'
export type Path = string // '/profilephoto/005/B'
export type PhoneNumber = string
export type Country = string
export type Currency = string

export type Timezone = string // 'Europe/Berlin'
export type LanguageLocale = string // 'en_US'
export type Locale = string //'de_DE_EURO' OR

export type Address = {
    City: string | null
    Country: string | null
    GeocodeAccuracy: string | null
    Latitude: number | null
    Longitude: number | null
    PostalCode: string | null
    State: string | null
    Street: string | null
}

export type DomainPrimitive = Id | EmailAddress | Url | Path | PhoneNumber | Country
export type Primitive = Date | boolean | string | number | undefined | null | DomainPrimitive

export type FieldValue = Primitive | string[]

export type DTOValue = string | number | null | boolean | string[]

export type CustomFields = Record<string, FieldValue>

export type ModelCustomFields = Record<string, Exclude<FieldValue, Date>>

export type WithCustomFields = {
    CustomFields?: CustomFields
}
export type CustomFieldsDTO = Record<string, DTOValue>
export type WithCustomFieldsDTO = {
    CustomFields?: CustomFieldsDTO
}

export interface Identifier {
    Id: string
}

export interface NameRelation {
    Id: string
    Name?: string
}

export const BaseFieldsSchema = z.object({
    CreatedDate: DateTimeStringSchema,
    CreatedById: UserIdSchema,
    LastModifiedDate: DateTimeStringSchema,
    LastModifiedById: UserIdSchema,
    RecordTypeId: z.string().optional(),
    CurrencyIsoCode: z.string().optional(),
})

export type BaseFields = z.infer<typeof BaseFieldsSchema>
export interface WithType<T extends string = string> {
    __typename: T
}

type TypeToString<ToReplace, T> = {
    [K in keyof T]: ToReplace extends T[K] ? string : T[K]
}
export type AllowNull<T> = {
    [K in keyof T]: Optional<T[K]>
}
type DateToString<T> = TypeToString<Date, T>

export type ToDTO<T> = AllowNull<DateToString<T>>

export type SnapShotter<Model, Snapshot> = (model: Model) => Snapshot
export type SnapshotListener<Snapshot> = (previous: Snapshot, next: Snapshot) => void

import { useCallback, useState } from 'react'

import { UserGroupOutlinedIcon } from '@laserfocus/ui/icons'
import { Modal, SearchInput } from '@laserfocus/ui/beam'

import { SettingsPageHeader } from '../layout/SettingsPageHeader'

import { DefineRolesTab } from './DefineRolesTab'
import { AssignRoleForm } from './AssignRolesTab'

type Tab = 'Assign roles' | 'Define roles'
const tabs: Tab[] = ['Assign roles', 'Define roles']

export function SettingsRolesPage() {
    const [currentTab, setCurrentTab] = useState<Tab>('Assign roles')
    const [searchTerm, setSearchValue] = useState('')
    const selectTab = useCallback((tab: Tab) => {
        setCurrentTab(tab)
    }, [])
    return (
        <div>
            <SettingsPageHeader
                title="Roles"
                iconComponent={UserGroupOutlinedIcon}
                right={
                    <div className="w-[245px] grid grid-flow-col gap-2 mt-1">
                        <SearchInput
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(event) => setSearchValue(event.target.value)}
                            reset={() => setSearchValue('')}
                            className="mx-0 w-full"
                        />
                    </div>
                }
            />

            <Modal.Tabs tabs={tabs} currentTab={currentTab} onTabClick={selectTab} />
            {currentTab === 'Assign roles' && <AssignRoleForm searchTerm={searchTerm} />}
            {currentTab === 'Define roles' && <DefineRolesTab searchTerm={searchTerm} />}
        </div>
    )
}

import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

interface LayoutPaddingProps {
    top?: boolean
    bottom?: boolean
    'data-testid'?: string
    className?: string
    children: React.ReactNode
}

export function LayoutPadding({ top, bottom, className, ...props }: LayoutPaddingProps) {
    return <div {...props} className={twMerge(top && 'pt-6', bottom && 'pb-24', className)} />
}

interface LayoutPaddingVerticalProps {
    'data-testid'?: string
    maxWidth?: 'stackWidth' | 'detailWidth' | 'reportWidth'
    children: React.ReactNode
}

export function LayoutPaddingVertical({ maxWidth, ...props }: LayoutPaddingVerticalProps) {
    return (
        <div
            {...props}
            className={clsx(
                'box-content px-6 mx-auto',
                {
                    stackWidth: 'max-w-[40.625rem]',
                    detailWidth: 'max-w-[75rem]',
                    reportWidth: 'max-w-[60rem]',
                }[maxWidth!]
            )}
        />
    )
}

import { Control, useController, UseFormWatch } from 'react-hook-form'
import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'

import { getClient } from '@laserfocus/client/replicache'
import { OpportunityModel } from '@laserfocus/shared/models'

import { ConvertLeadFormState } from './ConvertLeadModal'
import { ModeRadioGroup } from './ModeRadioGroup'
import { SObjectSearchSelect, SObjectSelect } from './SObjectSelect'
import { MetadataField, useFields } from './MetadataField'

export function OpportunitySectionBody({
    control,
    watch,
}: {
    control: Control<ConvertLeadFormState>
    watch: UseFormWatch<ConvertLeadFormState>
}) {
    const { field } = useController({ control, name: 'opportunityCreateOrChoose' })
    const existingAccountId = watch('existingAccount.Id')

    return (
        <div className="grid gap-4 py-4 px-8">
            <ModeRadioGroup
                value={field.value}
                onChange={field.onChange}
                options={[
                    { value: 'create', label: 'Create new' },
                    { value: 'choose', label: 'Choose existing' },
                    { value: 'none', label: 'None' },
                ]}
            />
            {field.value === 'choose' &&
                (existingAccountId ? (
                    <OpportunitySelect control={control} accountId={existingAccountId} />
                ) : (
                    <SObjectSearchSelect
                        control={control}
                        name="existingOpportunity"
                        label="Opportunity"
                        sobject="Opportunity"
                    />
                ))}
            {field.value === 'create' && <CreateOpportunitySection control={control} />}
        </div>
    )
}

function CreateOpportunitySection({ control }: { control: Control<ConvertLeadFormState> }) {
    // const fields = useFields('Opportunity', ['Name', 'Amount', 'Close Date', 'OwnerId'])
    const fields = useFields('Opportunity', ['Name'])
    return (
        <div className="grid gap-2 grid-cols-[1fr,2fr]">
            {fields.map((field) => (
                <MetadataField
                    key={field.fullName}
                    control={control}
                    fieldMetadata={field}
                    name={`newOpportunity.${field.name}`}
                />
            ))}
        </div>
    )
}

function OpportunitySelect({
    control,
    accountId,
}: {
    control: Control<ConvertLeadFormState>
    accountId: string
}) {
    const accountOpps = useSubscribe(
        getClient(),
        (tx: ReadTransaction) => {
            return tx
                .scan({ indexName: 'opportunitiesByAccountId', prefix: accountId })
                .values()
                .toArray() as Promise<OpportunityModel[]>
        },
        [],
        [accountId]
    )

    return (
        <SObjectSelect
            name="existingOpportunity"
            control={control}
            label="Opportunity"
            options={accountOpps}
        />
    )
}

import gql from 'graphql-tag'

import { ACCOUNT_FRAGMENT, CONTACT_FRAGMENT } from '../person/account-fragments'
import { PERSON_TASK_FRAGMENT } from '../person/person-task-fragment'
import { PERSON_EVENT_FRAGMENT } from '../person/person-event-fragment'

export const CONTACTS_BY_FILTER_QUERY = gql`
    ${ACCOUNT_FRAGMENT}
    ${PERSON_EVENT_FRAGMENT}
    ${PERSON_TASK_FRAGMENT}
    ${CONTACT_FRAGMENT}
    query getContactsByFilter($filter: FilterV3!, $sorting: Sorting) {
        getContactsByFilter(filter: $filter, sorting: $sorting) {
            done
            totalSize
            nextCursor
            records {
                ...AccountContact
                Account {
                    ...AccountHeader
                }
                OpenActivities {
                    ... on Task {
                        ...PersonTask
                    }
                    ... on Event {
                        ...PersonEvent
                    }
                }
            }
        }
    }
`

import { SvgIcon, SvgIconProps } from '../svg-icon'

export function SalesforceLogoIcon({ ...props }: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 16" {...props}>
            <path
                fill="currentColor"
                d="M10.0795 1.74945C10.8179 0.984615 11.8465 0.501099 12.9806 0.501099C14.4927 0.501099 15.8025 1.34505 16.5058 2.59341C17.1124 2.32088 17.7894 2.17143 18.5014 2.17143C21.2267 2.17143 23.4245 4.3956 23.4245 7.13846C23.4245 9.88132 21.2179 12.1055 18.5014 12.1055C18.1674 12.1055 17.8421 12.0703 17.5344 12.0088C16.919 13.1077 15.741 13.8549 14.3872 13.8549C13.8245 13.8549 13.2883 13.7231 12.8135 13.4945C12.1894 14.9626 10.73 16 9.03331 16C7.26628 16 5.75419 14.8835 5.17397 13.3099C4.91903 13.3626 4.65529 13.389 4.39156 13.389C2.28167 13.389 0.576172 11.6659 0.576172 9.52967C0.576172 8.10549 1.34101 6.85714 2.48386 6.18901C2.2465 5.65275 2.11463 5.05494 2.11463 4.42198C2.10584 1.98681 4.10145 0 6.5454 0C7.98716 0 9.27068 0.685714 10.0795 1.74945Z"
            />
        </SvgIcon>
    )
}

import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

interface PortalProps {
    children: React.ReactNode
    parent?: HTMLElement
}

/**
 * Creates <div> element to be used for a React Portal.
 *
 * The node is detached during first render and will be inserted into the DOM after all effects of child nodes were executed. If a child component requires to be attached to the DOM tree immediately when mounted, for example to measure a DOM node, or uses 'autoFocus' in a descendant, add state to component and only render the children when component is inserted in the DOM tree.
 */
export function Portal({ children, parent = document.body }: PortalProps) {
    const [portalNode] = useState(() => document.createElement('div'))

    useEffect(() => {
        parent.appendChild(portalNode)
        return () => void parent.removeChild(portalNode)
    }, [portalNode, parent])

    return createPortal(children, portalNode)
}

import { Editor } from '@tiptap/react'
import { twMerge } from 'tailwind-merge'

import {
    BoldIcon,
    Delete2OutlinedIcon,
    HiddenOutlinedIcon,
    ItalicIcon,
    ListOrderedIcon,
    ListUnorderedIcon,
    StrikethroughIcon,
    UnderlineIcon,
    VisibleOutlinedIcon,
    PinFilledIcon,
    PinOutlinedIcon,
    ExternalLinkOutlinedIcon,
} from '@laserfocus/ui/icons'
import { isTruthy } from '@laserfocus/shared/models'

import { NoteTemplateSelect } from './NoteTemplateSelect'
import { ToolbarButton, ToolbarButtonProps } from './ToolbarButton'

interface NoteToolbarProps {
    editor: Editor
    deleteNote(): void
    isPrivate: boolean
    toggleIsPrivate(): void
    isPinned: boolean
    toggleIsPinned(): void
    canOpenNote: boolean
    openNote(): void
    applyTemplate(body: string): void
    isNoteTemplateSelectOpen: boolean
    setIsNoteTemplateSelectOpen: React.Dispatch<React.SetStateAction<boolean>>
    className?: string
}

export function NoteToolbar({
    editor,
    deleteNote,
    isPrivate,
    toggleIsPrivate,
    isPinned,
    toggleIsPinned,
    applyTemplate,
    isNoteTemplateSelectOpen,
    setIsNoteTemplateSelectOpen,
    canOpenNote,
    openNote,
    className,
}: NoteToolbarProps) {
    const noteActions: ToolbarButtonProps[] = [
        {
            icon: isPrivate ? HiddenOutlinedIcon : VisibleOutlinedIcon,
            title: isPrivate ? 'Note is private, make shared' : 'Note is shared, make private',
            onClick: toggleIsPrivate,
        },
        {
            icon: isPinned ? PinFilledIcon : PinOutlinedIcon,
            title: isPinned ? 'Note is pinned, unpin it' : 'Note is not pinned, pin it',
            iconClassName: isPinned ? 'text-blue-500' : undefined,
            onClick: toggleIsPinned,
        },
        {
            icon: Delete2OutlinedIcon,
            title: 'Delete note',
            onClick: deleteNote,
            iconClassName: 'text-red-500',
        },
        canOpenNote && {
            icon: ExternalLinkOutlinedIcon,
            title: 'Open in new window',
            onClick: openNote,
        },
    ].filter(isTruthy)

    return (
        <div
            className={twMerge(
                'flex gap-1',
                !isNoteTemplateSelectOpen &&
                    'invisible group-hover:visible group-focus-within:visible',
                className
            )}
        >
            <EditorFormatButtons editor={editor} />
            <NoteTemplateSelect
                submit={applyTemplate}
                isNoteTemplateSelectOpen={isNoteTemplateSelectOpen}
                setIsNoteTemplateSelectOpen={setIsNoteTemplateSelectOpen}
            />
            <div className="w-px bg-grey-700/10" />
            {noteActions.map((action) => (
                <ToolbarButton key={action.title} {...action} />
            ))}
        </div>
    )
}

interface EditorFormatButtonsProps {
    editor: Editor | null
}

export function EditorFormatButtons({ editor }: EditorFormatButtonsProps) {
    const editorActions: ToolbarButtonProps[] = [
        {
            icon: BoldIcon,
            title: 'Bold',
            onClick: () => editor?.chain().focus().toggleBold().run(),
            isActive: editor?.isActive('bold'),
        },
        {
            icon: ItalicIcon,
            title: 'Italic',
            onClick: () => editor?.chain().focus().toggleItalic().run(),
            isActive: editor?.isActive('italic'),
        },
        {
            icon: StrikethroughIcon,
            title: 'Strikethrough',
            onClick: () => editor?.chain().focus().toggleStrike().run(),
            isActive: editor?.isActive('strike'),
        },
        {
            icon: UnderlineIcon,
            title: 'Underline',
            onClick: () => editor?.chain().focus().toggleUnderline().run(),
            isActive: editor?.isActive('underline'),
        },
        {
            icon: ListUnorderedIcon,
            title: 'Unordered list',
            onClick: () => editor?.chain().focus().toggleBulletList().run(),
            isActive: editor?.isActive('bulletList'),
        },
        {
            icon: ListOrderedIcon,
            title: 'Ordered list',
            onClick: () => editor?.chain().focus().toggleOrderedList().run(),
            isActive: editor?.isActive('orderedList'),
        },
    ]
    return (
        <>
            {editorActions.map((action) => (
                <ToolbarButton key={action.title} {...action} />
            ))}
        </>
    )
}

import { getClient } from '@laserfocus/client/replicache'
import { applyValidators, Prefix, SettingsMutatorSchema, Quota } from '@laserfocus/shared/models'

export function setQuota(update: Pick<Quota, 'amount' | 'date'>) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.setQuota({
        values: update,
        optimistic: {
            lastModifiedDate: new Date().toISOString(),
        },
    })
}

export const mutators = applyValidators(SettingsMutatorSchema, {
    async setQuota(tx, args) {
        const key = [Prefix.Quota, args.values.date].join('/')
        const existing = (await tx.get(key)) || {}

        const updated = {
            ...(existing as Quota),
            ...args.values,
            ...args.optimistic,
        }
        await tx.put(key, updated)
    },
})

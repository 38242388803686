import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ResetOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M5.1 10.72l1.65-1.88a1 1 0 111.5 1.31l-3.5 4a1 1 0 01-1.58-.1l-3-4.5a1 1 0 111.66-1.11l1.3 1.94A10.5 10.5 0 1113.5 22.5a1 1 0 110-2 8.5 8.5 0 10-8.4-9.78z" />
        </SvgIcon>
    )
}

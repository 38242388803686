import { twMerge } from 'tailwind-merge'

export function PlanChip({ children, className }: { children: string; className?: string }) {
    return (
        <span
            className={twMerge(
                'bg-blue-500 rounded-md px-2 py-1 leading-[1.3] font-bold text-[10px] text-white',
                className
            )}
        >
            {children}
        </span>
    )
}

import { matchSorter, rankings } from 'match-sorter'

import type { DateSuggest } from '@laserfocus/vendor/time-suggest'
import {
    SFDateConstantValue,
    SFDateConstants,
    getDurationForConstant,
} from '@laserfocus/shared/models'

import { dateSuggest } from './date-suggest'
import { DateConstantLabel } from './sf-dates'

export type RelativeSuggest = {
    startDate: Date
    endDate: Date
    value: SFDateConstantValue
    formatted: string
    formattedValue: string
    relative: true
}
export type EmptySuggest = {
    date: null
    formatted: string
    tags: string[]
}
export const EMPTY_DATE_SUGGEST_OPTION: EmptySuggest = {
    date: null,
    formatted: 'EMPTY',
    tags: ['clear', 'null', 'remove', 'nothing', 'none', 'never'],
}

export type FilterDateSuggest = DateSuggest | RelativeSuggest | EmptySuggest

export function filterDateSuggest(input: string): FilterDateSuggest[] {
    const dateOptions = dateSuggest(input)
    const relativeOptions = relativeSuggest(input)

    const relativeFormatted = relativeOptions.map((o) => o.formatted)
    const cleanedDateOptions = dateOptions.filter((o) => !relativeFormatted.includes(o.formatted))

    return [...relativeOptions, ...cleanedDateOptions]
}

export function isRelative(option?: FilterDateSuggest): option is RelativeSuggest {
    return (option as RelativeSuggest)?.relative
}

export function isDateSuggest(option?: FilterDateSuggest): option is DateSuggest {
    return Boolean((option as DateSuggest).iso8601 && (option as DateSuggest).tokens)
}
export const RelativeDateOptions = [
    makeOption(SFDateConstants.TODAY),
    makeOption(SFDateConstants.TOMORROW),
    makeOption(SFDateConstants.THIS_WEEK),
    makeOption(SFDateConstants.NEXT_WEEK),
    makeOption(SFDateConstants.LAST_WEEK),
    makeOption('NEXT_2_WEEKS'),
    makeOption('LAST_2_WEEKS'),

    makeOption(SFDateConstants.THIS_MONTH),
    makeOption(SFDateConstants.NEXT_MONTH),
    makeOption(SFDateConstants.LAST_MONTH),

    // Use Year and Quarter instead of Fiscal Year and Fiscal Quarter
    makeOption(SFDateConstants.THIS_QUARTER),
    makeOption(SFDateConstants.NEXT_QUARTER),
    makeOption(SFDateConstants.LAST_QUARTER),

    makeOption(SFDateConstants.THIS_YEAR),
    makeOption(SFDateConstants.NEXT_YEAR),
    makeOption(SFDateConstants.LAST_YEAR),

    makeOption(SFDateConstants.LAST_90_DAYS),
    makeOption(SFDateConstants.NEXT_90_DAYS),
]

export function relativeSuggest(input: string): RelativeSuggest[] {
    const options = RelativeDateOptions.map((m) => {
        const duration = getDurationForConstant(m.value)
        const [startDate, endDate] = duration
        return {
            startDate,
            endDate,
            formatted: m.label.toUpperCase(),
            value: m.value,
            formattedValue: 'TODO',
            relative: true as const,
        }
    })
    const matches = matchSorter(options, input, {
        keys: ['formatted'],
        threshold: rankings.ACRONYM,
        // This baseSort function will use the original index of items as the tie breaker
        baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    })
    return matches
}

function makeOption(value: SFDateConstantValue) {
    return { value, label: `${DateConstantLabel[value]}` }
}

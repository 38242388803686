import type { Options } from 'minisearch'
import { action, observable, reaction, runInAction } from 'mobx'

import type { Identifier, SnapshotListener, SnapShotter } from '@laserfocus/shared/models'

import { trace } from './util-trace'
import type { Model } from './ObjectPool'
import { LaserSearch } from './LaserSearch'

export class TrieSnapshotter<M extends Model = Model, Snapshot extends Identifier = Identifier> {
    id = Math.random()

    @observable loading: boolean = false
    @observable initiallyLoaded = false

    getData: () => Array<M>
    trie: LaserSearch<Snapshot>

    reactions: Array<() => void> = []
    makeSnapshot: SnapShotter<M, Snapshot>

    trieOptions: Options<Snapshot>
    name?: string

    constructor(
        getData: () => M[],
        trieOptions: Options<Snapshot>,
        makeSnapshot: SnapShotter<M, Snapshot>,
        name?: string
    ) {
        this.getData = getData
        this.trieOptions = trieOptions
        this.trie = new LaserSearch(this.trieOptions)
        this.makeSnapshot = makeSnapshot
        this.name = name
    }

    @action
    onInit() {
        this.trie = new LaserSearch(this.trieOptions)
        this.loading = true
        this.reactions.push(this.observeModelSnapshots())
    }
    onDestroy() {
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    observeModelSnapshots() {
        const objectSubscriptions = new Map<string, () => void>()

        const unsubscribe = reaction(
            this.getData,
            (models) => {
                trace(
                    () => {
                        models.forEach((model) => {
                            if (!objectSubscriptions.has(model.Id)) {
                                const listener: SnapshotListener<Snapshot> = this.index.bind(this)
                                objectSubscriptions.set(
                                    model.Id,
                                    model.onSnapshot(this.makeSnapshot as any, listener, {
                                        fireImmediately: true,
                                    })
                                )
                            }
                        })
                        this.loading = false
                        this.initiallyLoaded = true
                    },
                    {
                        name: `TrieSnapshot-Subscribe-${this.name}`,
                        itemCount: models.length,
                    }
                )
            },
            { fireImmediately: true, delay: 5, requiresObservable: true }
        )
        return () => {
            unsubscribe()
            let current
            const effects = Array.from(objectSubscriptions.values())
            while ((current = effects.pop())) {
                current()
            }
        }
    }

    @action
    update(makeSnapshot: SnapShotter<M, Snapshot>, options: Options<Snapshot>) {
        this.trieOptions = options
        this.makeSnapshot = makeSnapshot
        this.reindex()
    }

    @action
    reindex() {
        this.loading = true
        const nextTrie = new LaserSearch(this.trieOptions)
        nextTrie.addAllAsync(this.getData().map(this.makeSnapshot)).then(() => {
            runInAction(() => {
                this.trie = nextTrie
                this.loading = false
            })
        })
    }

    index(previous: Snapshot, current: Snapshot) {
        trace(
            () => {
                if (this.trie.has(previous.Id)) {
                    this.trie.remove(previous)
                }
                this.trie.add(current)
            },
            {
                name: `TrieSnapshot-Index-${this.name}`,
                itemCount: 1,
            }
        )
    }
}

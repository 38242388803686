import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { Select, SelectProps } from '@laserfocus/ui/beam'
import { LockOutlinedIcon } from '@laserfocus/ui/icons'

import { HighlightedCellValue } from './HighlightedCellValue'
import { TableCellProps } from './cell.types'
import {
    CLASS_NAME_INPUT_BUTTON,
    CLASS_NAME_INPUT_BUTTON_LIGHT,
    CLASS_NAME_INPUT_BUTTON_DARK,
    CLASS_NAME_INPUT_BUTTON_SELECTED,
    CLASS_NAME_LOCK_ICON,
    CLASS_NAME_LOCK_ICON_DARK,
    CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT,
    CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK,
} from './shared-class-names'

const EM_DASH = '—'

type SelectCellProps = TableCellProps &
    Pick<SelectProps, 'size'> & {
        options: Array<{ inactive?: boolean; label: string; value: string }>
        placeholder?: string
    }

export const SelectCell = function SelectCell({
    name,
    value,
    readOnly,
    updateValue,
    options,
    searchTerm,
    size,
    theme,
    placeholder = EM_DASH,
}: SelectCellProps) {
    const [isSelectOpen, setIsSelectOpen] = useState(false)

    const selectedOptionValue = (value as string | null) || ''
    const optionLabel = options.find((option) => option.value === selectedOptionValue)?.label
    const selectedOptionLabel = optionLabel || selectedOptionValue

    const activeOptions = options.filter((a) => !a.inactive)

    const hasEmptyOptionSelected =
        !selectedOptionLabel ||
        selectedOptionLabel === placeholder ||
        selectedOptionLabel === EM_DASH

    return (
        <Select
            className={twMerge(theme === 'dark' && 'bg-grey-650')}
            arrowClassName={twMerge(theme === 'dark' && 'after:bg-grey-650')}
            isOpen={isSelectOpen}
            initialOptionValue={selectedOptionValue}
            options={activeOptions}
            onSubmit={(option) => {
                setIsSelectOpen(false)
                if (selectedOptionValue !== option.value) {
                    updateValue(option.value)
                }
            }}
            onCancel={() => setIsSelectOpen(false)}
            searchKeys={['label']}
            size={size}
        >
            <button
                title={hasEmptyOptionSelected ? undefined : selectedOptionLabel!}
                disabled={readOnly}
                className={twMerge(
                    CLASS_NAME_INPUT_BUTTON,
                    theme === 'dark' ? CLASS_NAME_INPUT_BUTTON_DARK : CLASS_NAME_INPUT_BUTTON_LIGHT,
                    hasEmptyOptionSelected
                        ? theme === 'dark'
                            ? CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK
                            : CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT
                        : null,
                    isSelectOpen && CLASS_NAME_INPUT_BUTTON_SELECTED
                )}
                onClick={() => setIsSelectOpen(true)}
            >
                {hasEmptyOptionSelected ? (
                    placeholder
                ) : (
                    <HighlightedCellValue
                        value={selectedOptionLabel}
                        searchTerm={searchTerm}
                        className={clsx(theme === 'dark' && 'text-white')}
                    />
                )}
                {readOnly && (
                    <LockOutlinedIcon
                        className={twMerge(
                            CLASS_NAME_LOCK_ICON,
                            theme === 'dark' && CLASS_NAME_LOCK_ICON_DARK
                        )}
                    />
                )}
            </button>
        </Select>
    )
}

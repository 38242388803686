import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function PinOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M16 3a1 1 0 110 2v4.8l1.9 3.8.1.4v2c0 .6-.4 1-1 1h-4v4a1 1 0 11-2 0v-4H7a1 1 0 01-1-1v-2l.1-.4L8 9.8V5a1 1 0 010-2h8zm-6 2v5l-.1.4L8 14.2v.8h8v-.8l-1.9-3.8a1 1 0 01-.1-.4V5h-4z" />
        </SvgIcon>
    )
}

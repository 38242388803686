import { useEffect, useRef, useState, ForwardedRef, useMemo } from 'react'
import Highlighter from 'react-highlight-words'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { Card, Checkbox } from '@laserfocus/ui/beam'
import { renderDateTime } from '@laserfocus/client/util-formatter'
import { PersonAvatar } from '@laserfocus/client/ui-shared'
import { goToPersonDetails } from '@laserfocus/client/util-routing'
import { Analytics } from '@laserfocus/client/util-analytics'
import { isContact, isTruthy, TaskId } from '@laserfocus/shared/models'
import { ActivityUtil, EventModel, TaskModel } from '@laserfocus/client/model'
import { useSalesobjectEmoji } from '@laserfocus/client/store-shared'
import { useForkRef } from '@laserfocus/ui/util-react'

import { TaskActions } from './TaskActions'
import { TaskActionsAfterComplete } from './TaskActionsAfterComplete'
import { ActivityTags } from './ActivityTags'

export interface TaskProps {
    activity: TaskModel | EventModel
    isSelected: boolean
    isInOverlay: boolean
    pathPrefix?: 'today' | 'overdue' | 'upcoming'
    toggleOverlayForActivity?(id: string): void
    toggleSelect(): void
    completeTask(id: TaskId): Promise<void>
    reschedule(date: Date): Promise<unknown>
    dismissTask(id: TaskId): void
    searchTerm?: string
}

export const Task = observer(
    function Task(
        {
            activity,
            isSelected,
            isInOverlay,
            pathPrefix,
            toggleOverlayForActivity,
            toggleSelect,
            dismissTask,
            completeTask,
            reschedule,
            searchTerm,
        }: TaskProps,
        parentRef: ForwardedRef<HTMLDivElement> | null
    ) {
        const personName = activity.Who?.Name
        const companyName = activity.Account?.Name || (activity.Lead as any)?.Company

        const containerRef = useRef<HTMLDivElement>(null)
        const ref = useForkRef(parentRef, containerRef)

        const subject = activity.Subject || '(No Subject)'
        const date = renderDateTime(ActivityUtil.getDate(activity))

        const [isDatepickerOpen, setIsDatepickerOpen] = useState(false)

        const commonHighlighterProps = useMemo(
            () => ({
                autoEscape: true,

                highlightClassName: 'bg-blue-500/20 text-blue-700 rounded',
                searchWords: [searchTerm].filter(isTruthy),
            }),
            [searchTerm]
        )

        function handleClick() {
            const mainLocation = Analytics.getLocation() as
                | 'home'
                | 'today'
                | 'overdue'
                | 'upcoming'
            const location = mainLocation === 'home' ? mainLocation : pathPrefix || mainLocation!

            if (toggleOverlayForActivity) {
                toggleOverlayForActivity(activity.Id)
                Analytics.trackEvent({
                    event: 'overlay_opened',
                    location,
                })
            } else if (activity.RootId) {
                Analytics.trackEvent({
                    event: 'details_clicked',
                    location,
                })
                goToPersonDetails(
                    activity.RootId,
                    {
                        activityId: activity.Id,
                        contactId: isContact(activity.WhoId) ? activity.WhoId : undefined,
                    },
                    pathPrefix
                )
            }
        }

        const emoji = useSalesobjectEmoji(activity.RootId ?? undefined)

        const missingObjectFallbackEmoji = !(personName || companyName)
            ? ActivityUtil.isEvent(activity)
                ? '🗓️'
                : '📌'
            : undefined

        const classNameVisibleOnHover = isDatepickerOpen
            ? undefined
            : /* tw: */ 'sr-only group-hover:not-sr-only group-focus-within:not-sr-only'
        const classNameHiddenOnHover = isDatepickerOpen
            ? /* tw: */ 'opacity-0'
            : /* tw: */ 'group-hover:opacity-0 group-focus-within:opacity-0'

        const isTask = ActivityUtil.isTask(activity)

        const isAttached = Boolean(personName || companyName)

        useEffect(() => {
            if (isInOverlay) {
                containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        }, [isInOverlay])

        const headingText = isAttached
            ? [personName, companyName].filter(Boolean).join(' · ')
            : subject
        return (
            <div ref={ref} data-testid="activitystack-activityitem" className="group relative">
                <Card
                    onClick={handleClick}
                    className={clsx(
                        'px-3 py-2.5 transition outline-none focus-visible:ring focus-visible:ring-blue-500',
                        isTask && activity.IsClosed ? 'opacity-50' : 'opacity-100',
                        toggleOverlayForActivity || activity.RootId ? 'cursor-pointer' : undefined,
                        isInOverlay && 'ring ring-blue-500/60'
                    )}
                    role="button"
                    tabIndex={0}
                    onKeyDown={
                        isInOverlay
                            ? (event) =>
                                  (event.key === 'ArrowDown' || event.key === 'ArrowUp') &&
                                  event.currentTarget.blur()
                            : undefined
                    }
                >
                    <div className="flex items-center">
                        <div className="relative flex-none">
                            <PersonAvatar
                                emoji={emoji || missingObjectFallbackEmoji}
                                id={activity.RootId!}
                                className={clsx(
                                    isSelected
                                        ? 'opacity-0'
                                        : !isTask
                                        ? undefined
                                        : classNameHiddenOnHover
                                )}
                            />
                            {isTask && (
                                <div
                                    className={isSelected ? undefined : classNameVisibleOnHover}
                                    onClick={(event) => event.stopPropagation()}
                                >
                                    <div className="top-0 left-0 w-full h-full absolute grid place-content-center">
                                        <div className="p-4">
                                            <Checkbox
                                                disabled={!isTask}
                                                title="Select activity"
                                                checked={isSelected}
                                                onChange={toggleSelect}
                                                // We only want to blur when the checkbox was clicked with the mouse as opposed to with the keyboard. We don't want to blur onClick because onClick is triggered by pressing space as well
                                                onMouseUp={(event) => event.currentTarget.blur()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <h4
                            title={headingText}
                            className="grow text-base leading-[1.4] font-medium pl-2 pr-3.5 overflow-hidden text-ellipsis whitespace-nowrap"
                        >
                            <Highlighter
                                {...commonHighlighterProps}
                                textToHighlight={headingText}
                            />
                        </h4>
                        <div className="flex-none text-xs leading-[1.2] font-medium text-grey-700/60 group-hover:opacity-0 group-focus-within:opacity-0">
                            {date}
                        </div>
                    </div>
                    {isAttached && (
                        <div className="pt-0.5 pl-10 text-grey-700/60 text-sm leading-[1.4] font-medium">
                            <Highlighter {...commonHighlighterProps} textToHighlight={subject} />
                        </div>
                    )}

                    <ActivityTags activity={activity} searchTerm={searchTerm} className="pl-10" />
                </Card>

                <div className={classNameVisibleOnHover}>
                    <div className="grid grid-flow-col items-center gap-2 absolute right-0 top-0 px-3 py-2.5">
                        {isTask && activity.IsClosed ? (
                            <TaskActionsAfterComplete
                                activity={activity}
                                dismissTask={dismissTask}
                            />
                        ) : (
                            <TaskActions
                                activity={activity}
                                isDatepickerOpen={isDatepickerOpen}
                                openDatepicker={() => setIsDatepickerOpen(true)}
                                closeDatepicker={() => setIsDatepickerOpen(false)}
                                completeTask={completeTask}
                                reschedule={reschedule}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    },
    { forwardRef: true }
)

import clsx from 'clsx'

export default function LoadingActivities() {
    return (
        <div className="grid gap-1">
            <ActivitySkeleton />
            <ActivitySkeleton />
            <ActivitySkeleton />
            <LoadingDivider />

            <ActivitySkeleton longDescription />
            <ActivitySkeleton />
            <ActivitySkeleton longDescription />
            <ActivitySkeleton />
            <ActivitySkeleton longDescription />
            <ActivitySkeleton />
            <LoadingDivider />
            <ActivitySkeleton longDescription />
            <ActivitySkeleton />
            <ActivitySkeleton />
        </div>
    )
}

type ActivitySkeletonProps = {
    longDescription?: boolean
}
export function ActivitySkeleton({ longDescription = false }: ActivitySkeletonProps) {
    return (
        <div aria-busy="true" aria-live="polite" className="flex p-2">
            <div className="h-[2.125rem] w-[2.125rem] rounded-[0.625rem] bg-grey-700/5 mr-3 flex-shrink-0 flex-grow-0" />
            <div
                className={clsx(
                    'w-full rounded-[0.625rem] bg-grey-700/5',
                    longDescription ? 'h-[4.25rem]' : 'h-[2.125rem]'
                )}
            />
        </div>
    )
}

export function LoadingDivider() {
    return (
        <div className="py-4 pl-[3.375rem] pr-2 w-full">
            <div className="bg-grey-700/5 w-full h-0.5 max-h-0.5" />
        </div>
    )
}

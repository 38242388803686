import { groupBy, set } from 'lodash'

import {
    ComputedColumn,
    FieldMetadata,
    isComputedColumn,
    ComputedColumns,
    LABEL_OVERWRITES,
} from '@laserfocus/client/model'
import {
    FieldType,
    Stack,
    FilterCondition,
    SObjectType,
    isTruthy,
    Operator,
    ModelCustomFields,
    Aggregation,
    OpportunityFields,
    ContactFields,
} from '@laserfocus/shared/models'
import { HiddenFields } from '@laserfocus/shared/models'
import { ObjectPool, StoredModel } from '@laserfocus/client/data-layer'
import { toast } from '@laserfocus/ui/beam'
import { mutateSObject } from '@laserfocus/client/data-access-shared'
import { Analytics } from '@laserfocus/client/util-analytics'
import { fractional } from '@laserfocus/shared/util-common'

import { Column, Row, RowData, SelectOption } from './Table/table-context'
import { StackActions } from './useStackActions'
import { textInputs } from './ColumnFilters/get-filter-input'
import { aggregateValues, getFallbackAggregation } from './aggregate-columns'

const STICKY_LEFT_COLUMN_KEY_BY_SALES_OBJECT = {
    Account: 'Name',
    Lead: 'Company',
    Opportunity: 'Account.Name',
    Contact: 'Account.Name',
}

type TableSobject = 'Lead' | 'Account' | 'Opportunity' | 'Contact'
type SalesObject = Row

export type ColumnStackActions = Pick<
    StackActions,
    | 'hideColumn'
    | 'showColumn'
    | 'setFilterCondition'
    | 'removeFilter'
    | 'setSorting'
    | 'setColumnsWidth'
    | 'setColumnAggregation'
>

export function getVisibleColumns(
    stack: Stack,
    actions: ColumnStackActions,
    objectPool: ObjectPool
): Column[] {
    const stickyLeftColumnKey = STICKY_LEFT_COLUMN_KEY_BY_SALES_OBJECT[stack.sobject]
    const visibleColumnNamesWithSticky = Array.from(
        new Set([stickyLeftColumnKey, ...fractional.sortByOrderKey(stack.visibleColumnMap)])
    )
    const allColumns = getAllColumns(stack, actions, objectPool, visibleColumnNamesWithSticky)

    const accountColumns =
        allColumns.find(({ salesObjectType }) => salesObjectType === 'Account')?.columns || []
    const accountColumnsByKey = Object.fromEntries(
        accountColumns.map((column) => [column.key, column])
    )

    const salesObjectColumns =
        allColumns.find(({ salesObjectType }) => salesObjectType === stack.sobject)?.columns || []
    const salesObjectColumnsByKey = Object.fromEntries(
        salesObjectColumns.map((column) => [column.key, column])
    )

    return visibleColumnNamesWithSticky
        .map((columnName: string) => {
            if (columnName.startsWith('Account.')) {
                return accountColumnsByKey[columnName]
            }
            return salesObjectColumnsByKey[columnName]
        })
        .filter(isTruthy)
}

export function getAllColumns(
    stack: Stack,
    stackActions: ColumnStackActions,
    objectPool: ObjectPool,
    visibleFields?: string[]
) {
    const allAttachedFields = Array.from(objectPool.getAll<FieldMetadata>('FieldMetadata').values())

    const computedFields = getAvailableComputedColumns(allAttachedFields)
    const allFields = [...allAttachedFields, ...computedFields]

    let relevantFields = allFields
    if (visibleFields) {
        const accountFields = visibleFields
            .filter(Boolean)
            .filter((name) => name.startsWith('Account.'))
            .map((f) => f.split('.')[1])

        relevantFields = allFields.filter(({ objectName, name }) => {
            if (stack.sobject === objectName) {
                return visibleFields.includes(name)
            } else if (
                ['Contact', 'Opportunity'].includes(stack.sobject) &&
                objectName === 'Account'
            ) {
                return accountFields.includes(name)
            }
            return false
        })
    }

    const metadataMap = getMetadataMap(relevantFields)

    const salesObjectType = stack.sobject
    const shownColumns = stack.visibleColumnMap
    const columnWidths = stack.columnWidths || {}

    const salesObjectColumns = (metadataMap[salesObjectType] || [])
        .map((field) =>
            getColumnFromField({
                field,
                objectPool,
                shownColumns,
                columnWidths,
                stack,
                stackActions,
            })
        )
        .filter(isTruthy)
        .sort(columnsSorter)

    const allColumns = [
        {
            salesObjectType,
            columns: salesObjectColumns,
        },
    ]

    if (['Contact', 'Opportunity'].includes(salesObjectType)) {
        const accountColumns = (metadataMap.Account || [])
            .map((field) =>
                getColumnFromField({
                    field,
                    objectPool,
                    shownColumns,
                    columnWidths,
                    stack,
                    stackActions,
                    prefix: 'Account',
                })
            )
            .filter(isTruthy)
            .sort(columnsSorter)

        allColumns.push({
            salesObjectType: 'Account',
            columns: accountColumns,
        })
    }

    return allColumns
}

function getAvailableComputedColumns(metadata: FieldMetadata[]) {
    const bySobjectByField = byObjectByName(metadata)
    /**
     * I am directly using them, since there is no need
     * to put them into mobx
     */
    const allComputedFields = [...Object.values(ComputedColumns).flat()]

    const availableComputedFields = allComputedFields.filter((com) => {
        const objectFields = bySobjectByField[com.objectName]
        return com.dependentOn.every((dependingOn) => objectFields[dependingOn])
    })
    return availableComputedFields
}

function byObjectByName(
    metadata: FieldMetadata[]
): Record<TableSobject, Record<string, FieldMetadata>> {
    const result: Record<TableSobject, Record<string, FieldMetadata>> = {
        Lead: {},
        Account: {},
        Opportunity: {},
        Contact: {},
    }
    metadata.forEach((field) => set(result, `${field.objectName}.${field.name}`, field))
    return result
}

function getColumnFromField({
    field,
    objectPool,
    shownColumns,
    columnWidths,
    stack,
    prefix,
    stackActions,
}: {
    field: FieldMetadata | ComputedColumn
    objectPool: ObjectPool
    shownColumns: Record<string, string | null>
    columnWidths: Record<string, number>
    stack: Stack
    stackActions: ColumnStackActions
    prefix?: string
}): Column | null {
    const key = [prefix, field.name].filter(Boolean).join('.')
    const getActual = (salesObject: StoredModel) => {
        if (prefix) {
            if (prefix === 'Account') {
                const account = salesObject as ContactFields
                return account.AccountId
                    ? objectPool.get('Account', account.AccountId as string)
                    : null
            }
            console.warn('Trying to access a relation in the table, that is not expected', prefix)
            return (salesObject as any)?.[prefix] || null
        }
        return salesObject
    }

    if (isComputedColumn(field)) {
        return createComputedColumn({
            stack,
            key,
            field,
            isShown: Boolean(shownColumns[key]),
            width: columnWidths[key],
            stackActions,
            getActual,
            objectPool,
        })
    }

    return createColumn({
        stack,
        key,
        field,
        isShown: Boolean(shownColumns[key]),
        width: columnWidths[key],
        stackActions,
        getActual,
        objectPool,
    })
}

export interface CreateComputedColumnProps {
    key: string
    stack: Stack
    field: ComputedColumn
    isShown: boolean
    width?: number
    stackActions: ColumnStackActions
    getActual: (salesObject: SalesObject) => SalesObject | null
    objectPool: ObjectPool
}

function createComputedColumn({
    key,
    stack,
    field,
    isShown,
    width,
    stackActions,
    getActual,
    objectPool,
}: CreateComputedColumnProps): Column {
    const isStickyLeft = key === STICKY_LEFT_COLUMN_KEY_BY_SALES_OBJECT[stack.sobject]

    function toggleIsShown() {
        if (isStickyLeft) {
            return
        }

        if (isShown) {
            stackActions.hideColumn(key)
        } else {
            stackActions.showColumn(key)
        }
    }

    const condition = stack.filterConditions[key]
    const filterCondition = condition
        ? { operator: condition.operator as Operator, values: condition.values }
        : null

    const sorting = ((): 'ASC' | 'DESC' | null => {
        if (stack.sorting?.fieldName === key) {
            return stack.sorting.direction
        }
        return null
    })()

    const aggregation = stack.columnAggregates?.[key] || getFallbackAggregation(stack.sobject, key)

    const getValue = (salesObject: SalesObject) =>
        field.getValue(getActual(salesObject)!, objectPool)

    return {
        key,
        isStickyLeft,
        label: field.label,
        type: field.fieldType,
        isReferenceList: false,
        isSearchReference: false,
        isShown,
        width: width || 150,
        sorting,
        filterCondition,
        setFilterCondition: (rawCondition) => {
            const condition = {
                ...rawCondition,
                fieldName: key,
                fieldType: field.fieldType,
                isComputed: true,
            } as FilterCondition
            stackActions.setFilterCondition(condition)
        },
        removeFilterCondition: () => {
            stackActions.removeFilter(key)
        },
        toggleIsShown,
        setWidth: (newWidth: number) => {
            if (width === newWidth) {
                return
            }
            stackActions.setColumnsWidth(key, newWidth)
        },
        isEditable: (salesObject) => {
            return Boolean(field.isEditable)
        },
        getValue,
        getActual(salesObject) {
            return field.getActual?.(salesObject, objectPool)
        },
        updateValue(salesObject, value) {
            return Promise.resolve()
        },
        bulkUpdateValue() {
            return Promise.resolve()
        },
        getSelectOptions: (salesObject) => [],
        getFilterOptions: (rows: SalesObject[]) => {
            if (textInputs.includes(field.fieldType as typeof textInputs[number])) {
                return [
                    {
                        value: '',
                        label: '—',
                    },
                    ...Array.from(new Set(rows.map(getValue).filter(isTruthy)))
                        .sort()
                        .map((v) => ({
                            value: v as string,
                            label: `${v}`,
                        })),
                ]
            }
            return []
        },
        sortAscending: () => stackActions.setSorting({ fieldName: key, direction: 'ASC' }),
        sortDescending: () => stackActions.setSorting({ fieldName: key, direction: 'DESC' }),
        columnLocked: Boolean(stack.lockedColumnNames?.includes(key)),
        conditionLocked: Boolean(condition?.isLocked),
        aggregation,
        setAggregation: (aggregation: Aggregation) => {
            stackActions.setColumnAggregation(key, aggregation)
        },
        getAggregatedValue: (rows: SalesObject[]) => {
            return aggregateValues(aggregation, rows.map(getValue))
        },
        hideAggregation: field.hideAggregation,
        hideFilter: field.hideFilter,
    }
}

export interface CreateColumnProps<Row extends StoredModel = StoredModel> {
    key: string
    stack: Stack
    getActual: (salesObject: Row) => Row | null
    field: {
        objectName: SObjectType
        name: string
        label: string
        fieldType: FieldType
        length?: number
        scale?: number
        precision?: number
        isReferenceList?: boolean
        isSearchReference?: boolean
        isUserReference?: boolean
        updateable: boolean
        getPicklistOptions: InstanceType<typeof FieldMetadata>['getPicklistOptions']
        fullName: string
    }
    isShown: boolean
    width?: number
    stackActions: ColumnStackActions
    objectPool: ObjectPool
}

function createColumn({
    key,
    field,
    getActual,
    isShown,
    stack,
    width,
    stackActions,
    objectPool,
}: CreateColumnProps): Column {
    const isStickyLeft = key === STICKY_LEFT_COLUMN_KEY_BY_SALES_OBJECT[stack.sobject]

    function toggleIsShown() {
        if (isStickyLeft) {
            return
        }

        if (isShown) {
            stackActions.hideColumn(key)
        } else {
            stackActions.showColumn(key)
        }
        Analytics.trackEvent({
            event: 'columns_changed',
            location: Analytics.parseStackFromSobject(stack.sobject),
        })
    }

    const condition = stack.filterConditions[key]
    const filterCondition = condition
        ? { operator: condition.operator, values: condition.values }
        : null

    const aggregation = stack.columnAggregates?.[key] || getFallbackAggregation(stack.sobject, key)

    const sorting = ((): 'ASC' | 'DESC' | null => {
        if (stack.sorting?.fieldName === key) {
            return stack.sorting.direction
        }
        return null
    })()

    const getValue = (salesObject: Row) => {
        const actual = getActual(salesObject) as any
        const value = actual?.[field.name] ?? null
        if (field.fieldType === 'multipicklist' && typeof value === 'string') {
            return value?.split(';') || []
        }
        return value
    }
    return {
        key,
        isStickyLeft,
        label: getLabel(field),
        type: field.fieldType,
        fieldLength: field.length,
        fieldPrecision: field.precision,
        fieldScale: field.scale,
        isReferenceList: Boolean(field.isReferenceList),
        isSearchReference: Boolean(field.isSearchReference),
        isShown,
        width: width || 150,
        sorting,
        filterCondition,
        setFilterCondition: (rawCondition) => {
            const condition = {
                ...rawCondition,
                fieldName: key,
                fieldType: field.fieldType,
            } as FilterCondition
            stackActions.setFilterCondition(condition)
            Analytics.trackEvent({
                event: 'filter_changed',
                field: key,
                location: Analytics.parseStackFromSobject(stack.sobject),
            })
        },
        removeFilterCondition: () => {
            stackActions.removeFilter(key)
            Analytics.trackEvent({
                event: 'filter_changed',
                field: key,
                location: Analytics.parseStackFromSobject(stack.sobject),
            })
        },
        toggleIsShown,
        setWidth: (newWidth: number) => {
            if (width === newWidth) {
                return
            }
            stackActions.setColumnsWidth(key, newWidth)
        },
        isEditable: (salesObject) => {
            const actual = getActual(salesObject)
            if (
                actual &&
                ['Opportunity.Amount', 'Opportunity.TotalOpportunityQuantity'].includes(
                    field.fullName
                )
            ) {
                const opp = actual as OpportunityFields
                return !Boolean(opp.HasOpportunityLineItem)
            }
            return field.updateable && Boolean(actual)
        },
        getHint: (salesObject) => {
            const actual = getActual(salesObject)
            if (
                actual &&
                ['Opportunity.Amount', 'Opportunity.TotalOpportunityQuantity'].includes(
                    field.fullName
                )
            ) {
                return `Cant be modified, since it's calculated from the assigned products`
            }
        },
        getValue,
        updateValue(salesObject, value) {
            const actual = getActual(salesObject)
            if (actual) {
                if ((actual as any)[field.name] === value) {
                    //@TODO: Return the previous update promise
                    return Promise.resolve()
                }
                const changeSet: ModelCustomFields = {
                    [field.name]: value,
                }
                const type = actual.__typename as TableSobject
                /**
                 * When updating this directly we are not getting the delta for the onUpdate
                 * in the objectPool. Which is required to do the correct changes to miniSearch
                 */

                // ObjectPool refactor check objectPool.updateExisting(type, actual.Id, changeSet)
                return mutateSObject
                    .updateSObject(type, actual.Id, changeSet)
                    .then(() => {
                        Analytics.trackEvent({
                            event: 'record_edited',
                            location: Analytics.parseStackFromSobject(type),
                            recordType: Analytics.parseSalesObject(type),
                            fields: Object.keys(changeSet),
                            status: ((salesObject as any).Status ||
                                (salesObject as any).StageName) as string | undefined,
                        })
                    })
                    .catch(() => toast.error({ title: `Could not update ${field.label}.` }))
            }
            return Promise.resolve()
        },
        bulkUpdateValue(salesObjects, value) {
            if (!salesObjects.length) {
                return Promise.resolve()
            }
            const actuals = salesObjects.map(getActual).filter(isTruthy)
            const firstActual = actuals[0]
            if (!firstActual) {
                return Promise.resolve()
            }

            const type = firstActual.__typename as TableSobject

            const changeSet: ModelCustomFields = {
                [field.name]: value,
            }

            return mutateSObject.bulkUpdateSObject(
                type,
                actuals.map((a) => a.Id),
                changeSet
            )
        },
        // This is to select the values
        getSelectOptions: (salesObject) => {
            return field.getPicklistOptions(
                salesObject ? (getActual(salesObject) as any)?.RecordTypeId : undefined
            )
        },

        // This is to select filter options
        getFilterOptions: (rows: SalesObject[]) => {
            if (textInputs.includes(field.fieldType as typeof textInputs[number])) {
                return [
                    {
                        value: '',
                        label: '—',
                    },
                    ...Array.from(new Set(rows.map(getValue).filter(isTruthy)))
                        .sort()
                        .map((v) => ({
                            value: v,
                            label: v,
                        })),
                ] as SelectOption[]
            }

            if (field.isReferenceList) {
                if (field.isUserReference) {
                    const me = {
                        label: 'Me',
                        value: '$me',
                    }
                    const allUsers = field.getPicklistOptions()
                    const activeUsers = allUsers.filter((a) => !a.inactive)
                    const inactiveUsers = allUsers.filter((a) => a.inactive)
                    return [me, ...activeUsers, ...inactiveUsers] as SelectOption[]
                }
                return field.getPicklistOptions() as SelectOption[]
            }

            const picklistOptions = field.getPicklistOptions()
            const picklistValues = new Set(picklistOptions.map((a) => a.value))
            const rowValues = rows.flatMap(getValue)

            const uniqueValues = [...new Set(rowValues)].filter(
                (value) => value && !picklistValues.has(value as string)
            )
            const inactiveOptions = uniqueValues.map((v) => ({
                value: v,
                label: `${v}`,
                inactive: true,
            })) as SelectOption[]
            const final = [...picklistOptions, ...inactiveOptions]
            return final
        },
        sortAscending: () => stackActions.setSorting({ fieldName: key, direction: 'ASC' }),
        sortDescending: () => stackActions.setSorting({ fieldName: key, direction: 'DESC' }),
        columnLocked: Boolean(stack.lockedColumnNames?.includes(key)),
        conditionLocked: Boolean(condition?.isLocked),
        aggregation,
        setAggregation: (aggregation: Aggregation) => {
            stackActions.setColumnAggregation(key, aggregation)
        },
        getAggregatedValue: (rows: SalesObject[]) => {
            return aggregateValues(aggregation, rows.map(getValue))
        },
    }
}

function getMetadataMap(allFieldsAndRelations: Array<FieldMetadata | ComputedColumn>) {
    const fieldsByObject = groupBy(allFieldsAndRelations, 'objectName') as Partial<
        Record<TableSobject, Array<FieldMetadata | ComputedColumn>>
    >

    Object.entries(fieldsByObject).forEach(([salesObjectType, fields]) => {
        fields.sort(HiddenFields.makeSorter(salesObjectType as TableSobject))
    })

    return fieldsByObject
}

function columnsSorter(a: Column, b: Column) {
    return a.label.localeCompare(b.label)
}

function getLabel(field: { objectName: SObjectType; name: string; label: string }) {
    const { objectName, name, label } = field
    const objectLabels = LABEL_OVERWRITES[objectName as keyof typeof LABEL_OVERWRITES] as Record<
        string,
        string
    >
    const customLabel = objectLabels?.[name]
    return customLabel || label
}

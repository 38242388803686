import clsx from 'clsx'

export function RadioButton({
    checked,
    className,
    focused,
    disabled,
    ...props
}: React.ComponentPropsWithoutRef<'span'> & {
    checked: boolean
    focused: boolean
    disabled: boolean
}) {
    return (
        <div
            className={clsx(
                'h-5 w-5 transition block rounded-full',
                focused && !disabled && 'ring'
            )}
        >
            <div
                className={clsx(
                    'h-5 w-5 transition cursor-pointer appearance-none rounded-full shadow-[0_0_0_4px_hsl(0_0%_100%)_inset,0_0_0_4px_hsl(0_0%_100%)_inset]',
                    checked && !disabled && 'animate-radiomark  bg-blue-500',
                    checked && disabled && 'animate-radiomark bg-grey-700/20',
                    !focused && 'ring-1 ring-grey-700/20',
                    className
                )}
                {...props}
            />
        </div>
    )
}

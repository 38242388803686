import { slugify, Stack } from '@laserfocus/shared/models'
import { theme } from '@laserfocus/ui/tailwindcss'

export function isPreset(stack: Pick<Stack, 'id' | 'preset'>) {
    return Boolean(stack.preset)
}

export function getSlug(stack: Pick<Stack, 'id' | 'title'>) {
    return [stack.title ? slugify(stack.title) : 'unnamed', stack.id].join('-')
}

export function parseIdFromSlug(slug?: string) {
    if (!slug) {
        return null
    }
    const parts = slug.split('-')
    const presetUserIdSeperator = parts.includes('preset')
    if (presetUserIdSeperator) {
        const idStartIndex = parts.lastIndexOf('preset')
        const idParts = parts.slice(idStartIndex)
        const idPartHasUserId = idParts.find(
            (part) => part.indexOf('005') === 0 && part.length === 18
        )
        if (idPartHasUserId) {
            return idParts.join('-')
        }
    }
    if (parts[0] === 'template') {
        return slug
    }
    return parts[parts.length - 1] ?? null
}

export function getColor(stack: Stack): string {
    return (
        stack.color ||
        getColorById(stack.id) ||
        getColorBySalesObjectType(stack.sobject) ||
        theme.colors.blue[50]
    )
}

export function getIcon(stack: Stack) {
    return stack.icon || getIconById(stack.id) || getIconByObjectType(stack.sobject)
}

export function canUserDeleteStack(stack: Stack): boolean {
    if (stack.__typename === 'OrgStack' || stack.isExtendedFromOrg) {
        return Boolean(stack.deactivated)
    }
    return !Boolean(stack.preset)
}

function getColorById(stackId?: string): string | undefined {
    if (stackId?.includes('past-close-date')) {
        return theme.colors.blue[50]
    } else if (stackId?.includes('my-leads')) {
        return theme.colors.grey[50]
    } else if (stackId?.includes('my-open-opps')) {
        return theme.colors.green[50]
    } else if (stackId?.includes('my-accounts')) {
        return theme.colors.purple[50]
    }
}

function getColorBySalesObjectType(
    salesObjectType: 'Account' | 'Lead' | 'Opportunity' | 'Contact'
) {
    switch (salesObjectType) {
        case 'Account':
            return theme.colors.purple[50]
        case 'Lead':
            return theme.colors.grey[50]
        case 'Opportunity':
            return theme.colors.green[50]
        case 'Contact':
            return theme.colors.yellow[50]
    }
}

function getIconByObjectType(salesObjectType: 'Account' | 'Lead' | 'Opportunity' | 'Contact') {
    switch (salesObjectType) {
        case 'Account':
            return '🏛'
        case 'Lead':
            return '🪆'
        case 'Opportunity':
            return '👑'
        case 'Contact':
            return '👤'
    }
}

function getIconById(stackId?: string) {
    if (stackId?.includes('past-close-date')) {
        return '🕰'
    } else if (stackId?.includes('my-leads')) {
        return '🪆'
    } else if (stackId?.includes('my-open-opps')) {
        return '👑'
    } else if (stackId?.includes('my-accounts')) {
        return '🏛'
    }
}

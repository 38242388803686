import { cloneElement, useState } from 'react'
import { usePopper } from 'react-popper'
import { Placement, ComputedPlacement } from '@popperjs/core'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { useBodyScrollLock, useForkRef } from '@laserfocus/ui/util-react'
import { Portal } from '@laserfocus/ui/util-react'
import { useHotKey } from '@laserfocus/ui/util-keyboard'

import { isTruthy } from '../../util'

export interface DropdownProps extends DropdownPanelProps {
    isOpen?: boolean
}

export function Dropdown({ isOpen, ...props }: DropdownProps) {
    return isOpen ? <DropdownPanel {...props} /> : props.children
}

interface DropdownPanelProps {
    children: React.ReactElement<RefAttribute> & RefAttribute
    content: React.ReactNode
    hideArrow?: boolean
    placement?: Placement
    className?: string
    arrowClassName?: string
    minWidth?: number
    minHeight?: number
    syncWidth?: boolean
    onCancel?(): void
}
interface RefAttribute {
    ref?: ((element: HTMLElement) => void) | null
}

function DropdownPanel({
    children,
    content,
    placement,
    hideArrow = false,
    className,
    arrowClassName,
    minWidth,
    minHeight,
    syncWidth,
    onCancel,
}: DropdownPanelProps) {
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
    const clonedChildRef = useForkRef(children.ref, setReferenceElement)

    const { styles, attributes, state } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            !hideArrow && {
                name: 'arrow',
                options: {
                    element: arrowElement,
                    // To prevent arrow from getting out of dropdown due to its borderRadius
                    padding: 17,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, hideArrow ? 4 : 12],
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    // This is to have additional space for 1 button appearing
                    padding: { left: 8, top: 8, right: 8, bottom: 36 },
                    altAxis: true,
                    tether: false,
                },
            },

            {
                name: 'flip',
                options: {
                    padding: 8,
                },
            },
        ].filter(isTruthy),
    })
    const { arrowPlacement, shouldHideArrow } = getArrowPlacement(attributes, state)

    useBodyScrollLock(true)
    useHotKey('esc', () => onCancel?.(), { global: true })

    return (
        <>
            {cloneElement(children, { ref: clonedChildRef })}
            <Portal>
                <div
                    onClick={onCancel}
                    data-testid="dropdown-overlay"
                    className="fixed top-0 left-0 w-full h-full z-10"
                    // Temporarily fix dropdown clicks triggering dialog close when rendered inside Headless UI Dialog. More info: https://github.com/tailwindlabs/headlessui/pull/989
                    onMouseDown={(event) => event.stopPropagation()}
                />
                <div
                    ref={setPopperElement}
                    style={
                        minHeight || minWidth || syncWidth
                            ? {
                                  ...styles.popper,
                                  minHeight,
                                  minWidth,
                                  width:
                                      syncWidth && referenceElement
                                          ? referenceElement.offsetWidth
                                          : styles.popper?.width,
                              }
                            : styles.popper
                    }
                    className={clsx(
                        'z-10 pointer-events-none',
                        minHeight && arrowPlacement === 'bottom' && 'grid items-end',
                        minWidth && arrowPlacement === 'right' && 'grid justify-end'
                    )}
                    // Temporarily fix dropdown clicks triggering dialog close when rendered inside Headless UI Dialog. More info: https://github.com/tailwindlabs/headlessui/pull/989
                    onMouseDown={(event) => event.stopPropagation()}
                >
                    {!hideArrow && (
                        <div
                            ref={setArrowElement}
                            className={twMerge(
                                'absolute w-[0.8125rem] h-[0.8125rem] z-[-1] after:absolute after:w-[0.8125rem] after:h-[0.8125rem] after:bg-grey-700 after:rounded-tl-[0.3125rem] after:rounded-br-[0.3125rem]',
                                shouldHideArrow && 'hidden',
                                ARROW_STYLES_PER_PLACEMENT[arrowPlacement],
                                arrowClassName
                            )}
                            style={styles.arrow}
                        />
                    )}
                    <div
                        className={twMerge(
                            'bg-grey-700 text-white rounded-[0.625rem] pointer-events-auto',
                            typeof content === 'string' && 'p-2',
                            className
                        )}
                        onClick={(event) => event.stopPropagation()}
                    >
                        {content}
                    </div>
                </div>
            </Portal>
        </>
    )
}

type ArrowPlacement = 'top' | 'right' | 'bottom' | 'left'

const ARROW_STYLES_PER_PLACEMENT: Record<ArrowPlacement, string> = {
    top: /* tw: */ '-top-1.5 after:rotate-45',
    right: /* tw: */ '-right-1.5 after:-rotate-45',
    bottom: /* tw: */ '-bottom-1.5 after:rotate-45',
    left: /* tw: */ '-left-1.5 after:-rotate-45',
}

const ARROW_PLACEMENT_BY_POPPER_PLACEMENT: Record<ComputedPlacement, ArrowPlacement> = {
    top: 'bottom',
    'top-start': 'bottom',
    'top-end': 'bottom',
    right: 'left',
    'right-start': 'left',
    'right-end': 'left',
    bottom: 'top',
    'bottom-start': 'top',
    'bottom-end': 'top',
    left: 'right',
    'left-start': 'right',
    'left-end': 'right',
}

function getArrowPlacement(
    attributes: ReturnType<typeof usePopper>['attributes'],
    state: ReturnType<typeof usePopper>['state']
) {
    const arrowPlacement =
        ARROW_PLACEMENT_BY_POPPER_PLACEMENT[
            attributes.popper?.['data-popper-placement'] as ComputedPlacement
        ] || 'top'

    const altAxis = arrowPlacement === 'top' || arrowPlacement === 'bottom' ? 'y' : 'x'
    const overflowOnAltAxis = state?.modifiersData.preventOverflow?.[altAxis]
    const shouldHideArrow = Boolean(overflowOnAltAxis)

    return { arrowPlacement, shouldHideArrow }
}

import gql from 'graphql-tag'

export const ME_QUERY = gql`
    query me {
        me {
            Id
            Username
            Name
            FirstName
            LastName
            # Additional
            OrgId
            InstanceUrl
            Email
            CreatedAt
        }
    }
`

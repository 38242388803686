import { ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'

import { getClient } from '@laserfocus/client/replicache'
import { queryRootSyncState, RootSyncState } from '@laserfocus/client/data-access-shared'

export function useSyncState(rootId: string) {
    const rep = getClient()
    return useSubscribe(
        rep,
        (tx: ReadTransaction) =>
            queryRootSyncState(tx, rootId) as Promise<RootSyncState & { initial?: boolean }>,
        { initial: true },
        []
    )
}

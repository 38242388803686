import { SObjectType } from '@laserfocus/shared/models'

export type ActivityStack = 'today' | 'overdue' | 'upcoming'
export const ActivityStackValues: ActivityStack[] = ['today', 'overdue', 'upcoming']

export function isActivityStack(name: string): name is ActivityStack {
    return ActivityStackValues.includes(name as ActivityStack)
}

const OverlayLocations: Overlay[] = ['list_overlay', 'table_overlay']
export function isOverlay(name: string): name is Overlay {
    return OverlayLocations.includes(name as Overlay)
}

const ErrorModalLocations: ErrorModal[] = ['person_details_error_modal', 'table_error_modal']
export function isErrorModal(name: string): name is ErrorModal {
    return ErrorModalLocations.includes(name as ErrorModal)
}

export type TableStacks =
    | 'custom_stack'
    | 'lead_stack'
    | 'opportunity_stack'
    | 'contact_stack'
    | 'account_stack'
export const TableStackValues: TableStacks[] = [
    'lead_stack',
    'opportunity_stack',
    'account_stack',
    'contact_stack',
]
export function isTableStack(name: string): name is TableStacks {
    return TableStackValues.includes(name as TableStacks)
}

export type ErrorModal = 'person_details_error_modal' | 'table_error_modal'

export type ListOverlay = 'list_overlay'
export type TableOverlay = 'table_overlay'

export type Overlay = ListOverlay | TableOverlay

export type StackNames = ActivityStack | TableStacks
export type Home = 'home'
export type PersonDetails = 'person_details'

export type SalesObject = 'lead' | 'opportunity' | 'account' | 'contact'

export type GenericEvent = { event: string; payload?: Record<string, any> }

export function isGenericEvent(e: Events): e is GenericEvent {
    const keys = Object.keys(e)
    const otherKeys = keys.filter((a) => a !== 'event' && a !== 'payload')
    return keys.length <= 2 && keys.includes('event') && otherKeys.length === 0
}

export type Events =
    | StackClicked
    | DetailsClicked
    | BreadcrumbClicked
    | SearchClicked
    | OverlayExpanded
    | OverlayOpened
    | ActivityScheduled
    | ActivityRescheduled
    | ActivityCompleted
    | ActivityCreated
    | ActivityEdited
    | RecordEdited
    | ActivityBulkEdited
    | FieldsCustomized
    | FieldGroupCreated
    | NoteCreated
    | NoteEdited
    | NoteTemplateUsed
    | FilterChanged
    | ColumnsChanged
    | GenericEvent

/** ------- NAVIGATION ------------- */
export interface StackClicked {
    event: 'stack_clicked' | 'timed_stack_clicked'
    location: Home | ActivityStack
    stack: StackNames | (string & {})
    stackName?: string
    isPreset: boolean
    preset?: string
}

export function isStackClicked(e: Events): e is StackClicked {
    return e.event === 'stack_clicked'
}

export interface DetailsClicked {
    event: 'details_clicked'
    location: Home
}
export function isDetailsClicked(e: Events): e is DetailsClicked {
    return (e as DetailsClicked).event === 'details_clicked'
}

export interface OverlayOpened {
    event: 'overlay_opened'
    location: StackNames
}
export function isOverlayOpened(e: Events): e is OverlayOpened {
    return (e as OverlayOpened).event === 'overlay_opened'
}

export interface OverlayExpanded {
    event: 'overlay_expanded'
    location: Overlay
}
export function isOverlayExpanded(e: Events): e is OverlayExpanded {
    return (e as OverlayExpanded).event === 'overlay_expanded'
}

interface HomeBreadCrumbClicked {
    event: 'breadcrumb_clicked'
    level: 'home'
    location: StackNames
}
interface StackBreadcrumbClicked {
    event: 'breadcrumb_clicked'
    level: 'list'
    location: PersonDetails
    stack: StackNames
}
export type BreadcrumbClicked = HomeBreadCrumbClicked | StackBreadcrumbClicked

export function isBreadcrumbClicked(e: Events): e is BreadcrumbClicked {
    return e.event === 'breadcrumb_clicked'
}

export interface SearchClicked {
    event: 'search_clicked'
    location: Home | PersonDetails | StackNames
}
export function isSearchClicked(e: Events): e is SearchClicked {
    return e.event === 'search_clicked'
}

/**
 * ------------- ACTIVITY -------------------
 */

export interface ActivityScheduled {
    event: 'activity_scheduled'
    location: PersonDetails | StackNames | Home | Overlay
}
export function isActivityScheduled(e: Events): e is ActivityScheduled {
    return e.event === 'activity_scheduled'
}

export interface ActivityRescheduled {
    event: 'activity_rescheduled'
    location: StackNames | Home | PersonDetails
}
export function isActivityRescheduled(e: Events): e is ActivityRescheduled {
    return e.event === 'activity_rescheduled'
}

export interface ActivityCompleted {
    event: 'activity_completed'
    location: ActivityStack | Home | PersonDetails
}
export function isActivityCompleted(e: Events): e is ActivityCompleted {
    return e.event === 'activity_completed'
}

export interface ActivityCreated {
    event: 'activity_created'
    location: PersonDetails
}
export function isActivityCreated(e: Events): e is ActivityCreated {
    return e.event === 'activity_created'
}

export interface ActivityEdited {
    event: 'activity_edited'
    location: PersonDetails | TableStacks
}
export function isActivityEdited(e: Events): e is ActivityEdited {
    return e.event === 'activity_edited'
}

export interface RecordEdited {
    event: 'record_edited'
    location: TableStacks | PersonDetails | ErrorModal | Overlay
    recordType: SalesObject
    // fieldType?: string
    // fieldName?: string
    fields?: string[]
    status?: string
}
export function isRecordEdited(e: Events): e is RecordEdited {
    return e.event === 'record_edited'
}

export type BulkEditAction = 'complete' | 'reschedule' | 'add_new'
export interface ActivityBulkEdited {
    event: 'activity_bulk_edited'
    location: ActivityStack
    action: BulkEditAction
    amount: number
}
export function isActivityBulkEdited(e: Events): e is ActivityBulkEdited {
    return e.event === 'activity_bulk_edited'
}

/**
 * ----------- FieldGroup ----------------
 */

export interface FieldGroupCreated {
    event: 'fieldgroup_created'
    location: PersonDetails | Overlay
}
export function isFieldGroupCreated(e: Events): e is FieldGroupCreated {
    return (e as FieldGroupCreated).event === 'fieldgroup_created'
}
export interface FieldsCustomized {
    event: 'fields_customized'
    location: PersonDetails | Overlay
}
export function isFiedsCustomized(e: Events): e is FieldsCustomized {
    return e.event === 'fields_customized'
}

/**
 * ----------- Notes ----------------
 */

export interface NoteCreated {
    event: 'note_created'
    location: PersonDetails | Overlay
    title?: string
}
export function isNoteCreated(e: Events): e is NoteCreated {
    return (e as NoteCreated).event === 'note_created'
}
export interface NoteEdited {
    event: 'note_edited'
    location: PersonDetails | Overlay | 'single_note'
    title?: string
}
export function isNoteEdited(e: Events): e is NoteEdited {
    return (e as NoteEdited).event === 'note_edited'
}

export interface NoteTemplateUsed {
    event: 'note_template_used'
    location: PersonDetails | Overlay
    templateName: string
}
export function isNoteTemplateUsed(e: Events): e is NoteTemplateUsed {
    return (e as NoteTemplateUsed).event === 'note_template_used'
}

/**
 * ----------- Stacks / Filter ----------------
 */

export interface FilterChanged {
    event: 'filter_changed'
    location: TableStacks
    field: string
}
export function isFilterChanged(e: Events): e is FilterChanged {
    return e.event === 'filter_changed'
}

export interface ColumnsChanged {
    event: 'columns_changed'
    location: TableStacks
}
export function isColumnsChanged(e: Events): e is ColumnsChanged {
    return e.event === 'columns_changed'
}

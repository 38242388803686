import { useState } from 'react'

import { Search } from '@laserfocus/client/feature-search'
import { Button, SpinnerInline, toast } from '@laserfocus/ui/beam'
import {
    isAccountResult,
    isContactResult,
    isLeadResult,
    isOpportunityResult,
    isTask,
    SearchResult,
} from '@laserfocus/shared/models'
import { AddContactOutlinedIcon } from '@laserfocus/ui/icons'
import { attachActivity } from '@laserfocus/client/data-access-shared'
import { logger } from '@laserfocus/ui/logger'

export function AttachActivity({ activityId }: { activityId: string }) {
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    function submit(searchResult: SearchResult) {
        setIsLoading(true)
        const input: {
            values: {
                activityId: string
                whoId?: string
                whatId?: string
            }
            optimistic: {
                accountId?: string
            }
        } = {
            values: {
                activityId,
            },
            optimistic: {},
        }

        if (isContactResult(searchResult)) {
            input.values.whoId = searchResult.Id
            input.values.whatId = searchResult.AccountId
            input.optimistic.accountId = searchResult.AccountId
        } else if (isOpportunityResult(searchResult)) {
            input.values.whatId = searchResult.Id
            input.optimistic.accountId = searchResult.AccountId
        } else if (isLeadResult(searchResult)) {
            input.values.whoId = searchResult.Id
        } else if (isAccountResult(searchResult)) {
            input.values.whatId = searchResult.Id
            input.optimistic.accountId = searchResult.Id
        }
        attachActivity(input)
            ?.then(
                () => {},
                (e: any) => {
                    logger.error(e)
                    toast.error({ title: 'Error while attaching activity' })
                }
            )
            .finally(() => {
                setIsLoading(false)
            })
    }
    const sobjectType = isTask(activityId) ? 'Task' : 'Event'
    return (
        <div className="grid grid-flow-row gap-4">
            <p className="text-grey-700/60 px-2 text-sm font-medium">
                This {sobjectType.toLowerCase()} is not attached to an object.
            </p>
            <div className="justify-self-center pt-2">
                <Button
                    variant="primary"
                    size="large"
                    onClick={() => setOpen(true)}
                    iconComponent={isLoading ? SpinnerInline : AddContactOutlinedIcon}
                >
                    Attach
                </Button>
            </div>
            <Search show={open} close={() => setOpen(false)} onSubmit={submit} />
        </div>
    )
}

import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'

import { MutationErrorModal } from '@laserfocus/client/shared-sales-object-form-control'
import { LeadModel } from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { useFailedMutationScope, useCurrentFailedMutation } from '@laserfocus/client/shared-error'
import { mutateSObject } from '@laserfocus/client/data-access-shared'

export function TableErrorModal() {
    const { discard, failedMutations } = useFailedMutationScope()

    const {
        failedMutations: [failedMutation, ...remainingFailedMutations],
        next: selectNextFailedMutation,
    } = useCurrentFailedMutation(failedMutations)

    const salesObject = useSubscribe(
        getClient(),
        async (tx: ReadTransaction) => {
            if (failedMutation?.targetKey) {
                const salesObject = await tx.get(failedMutation.targetKey)
                return salesObject ?? null
            }
            return null
        },
        null,
        [failedMutation]
    )

    function close() {
        if (failedMutation) {
            discard(failedMutation.mutationId)
            mutateSObject.discardFailedMutation(failedMutation.mutationId)
        }
        if (remainingFailedMutations.length) {
            Promise.all(
                remainingFailedMutations.map((mut) => {
                    discard(mut.mutationId)
                    return mutateSObject.discardFailedMutation(mut.mutationId)
                })
            )
        }
    }

    function next() {
        if (failedMutation) {
            discard(failedMutation.mutationId)
            mutateSObject.discardFailedMutation(failedMutation.mutationId)
        }
        selectNextFailedMutation()
    }

    return salesObject ? (
        <MutationErrorModal
            mutation={failedMutation}
            salesObject={salesObject as LeadModel}
            close={close}
            nextMutations={remainingFailedMutations}
            next={next}
        />
    ) : null
}

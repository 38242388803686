import 'reflect-metadata'
import { observable, flow, runInAction, isFlowCancellationError } from 'mobx'
import { CancellablePromise } from 'mobx/lib/api/flow'
import { debounce, DebouncedFunc } from 'lodash'
import { useState } from 'react'

import { useRootStore } from '@laserfocus/client/root-store-context'
import { logger } from '@laserfocus/ui/logger'
import type { SearchResult } from '@laserfocus/shared/models'

import { searchData } from './SearchRepository'

export type RootStoreInjection = {
    searchStore: SearchStore
}

export function useSearchStore(): SearchStore {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.searchStore
}

export function useLocalSearchStore(): SearchStore {
    // We want to make the searchstore state not global, so using a local searchStore
    const [searchStore] = useState(() => new SearchStore())
    return searchStore
}

type SearchSobject = 'Lead' | 'Contact' | 'Opportunity' | 'Account'
export class SearchStore {
    @observable isLoading: boolean = false
    @observable errorMessage?: string
    @observable fetchedData: Array<SearchResult> = []
    @observable currentData: Array<SearchResult> = []
    @observable currentSearchTerm = ''
    debouncedSearch: DebouncedFunc<(searchTerm: string, sobject?: SearchSobject) => void>
    searchData: typeof searchData

    currentFetch?: CancellablePromise<any>

    constructor() {
        this.searchData = searchData
        this.debouncedSearch = debounce(this.search.bind(this), 200)
        this.runCheck = this.runCheck.bind(this)
    }

    async runCheck() {
        await this.searchData('joh')
        return true
    }

    async search(searchTerm: string, sobject?: SearchSobject) {
        try {
            if (this.isLoading && this.currentFetch) {
                this.currentFetch.cancel()
            }
            this.errorMessage = undefined
            const currentFetch = this.doSearch(searchTerm, sobject)
            this.currentFetch = currentFetch
            await currentFetch
        } catch (e: any) {
            if (!isFlowCancellationError(e)) {
                logger.error(e)
                runInAction(() => (this.errorMessage = e.message))
            }
        }
    }

    doSearch = flow(function* (this: SearchStore, searchTerm: string, sobject?: SearchSobject) {
        if (this.isLoading) {
            logger.warn('Triggering a new refetch while still waiting is not great i guess')
        }
        if (searchTerm.length < 2) {
            return
        }

        try {
            this.isLoading = true
            const searchResult: SearchResult[] = yield this.searchData(searchTerm, sobject)

            this.fetchedData = searchResult
            this.currentData = searchResult
        } catch (e: unknown) {
            this.currentData = []
        } finally {
            this.currentSearchTerm = searchTerm
            this.isLoading = false
        }
    })
}

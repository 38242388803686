import { useObserver } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { partition, set } from 'lodash'
import { matchSorter } from 'match-sorter'

import { Button, Modal, SpinnerInline } from '@laserfocus/ui/beam'
import { AddOutlinedIcon, CollapseOutlinedIcon, ExpandOutlinedIcon } from '@laserfocus/ui/icons'
import { FieldMetadata } from '@laserfocus/client/model'
import { ContactModel, MutationErrorResponse, OpportunityModel } from '@laserfocus/shared/models'
import { useObjectPool } from '@laserfocus/client/root-store-context'

import { useAccount } from '../modules/PersonContext'

import { FormErrors } from './FormErrors'
import { FormField } from './FormField/FormField'

type FormControl = UseFormReturn<OpportunityModel | ContactModel>
export type FormControlProps = Pick<FormControl, 'watch' | 'setValue' | 'control'>

interface CreateObjectModalProps {
    sobjectType: 'Lead' | 'Account' | 'Contact' | 'Opportunity'
    sobjectRecordTypeId: string | undefined
    isSubmitting: boolean
    onSubmit(fieldsData: Record<string, any>): Promise<MutationErrorResponse | undefined>
    closeModal(): void
    children?: (props: FormControlProps) => React.ReactNode
}

export function CreateObjectModal({
    sobjectType,
    sobjectRecordTypeId,
    isSubmitting,
    onSubmit,
    closeModal,
    children,
}: CreateObjectModalProps) {
    const [filterValue, setFilterValue] = useState('')
    const [isExpanded, setIsExpanded] = useState(false)
    const [formErrors, setFormErrors] = useState<string[]>([])

    const { visibleFields, hiddenFields, defaultValues } = useFields(sobjectType)

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        setError,
        watch,
        setValue,
    } = useForm<OpportunityModel | ContactModel>({
        defaultValues,
    } as {
        defaultValues: any
    })

    const account = useAccount()

    const fieldsToRender = useFieldsToRender({
        visibleFields,
        hiddenFields,
        isExpanded,
        filterValue,
    })

    function handleInvalidSubmit() {
        setFilterValue('')
    }

    async function handleFormSubmit(data: Record<string, any>) {
        const fieldsToSubmit: Record<string, any> = {
            AccountId: account!.Id,
        }

        visibleFields.concat(hiddenFields).forEach((field) => {
            const value = get(data, field.name)

            if (!value) {
                return
            }

            switch (field.fieldType) {
                case 'int':
                case 'double':
                case 'currency':
                case 'percent':
                    set(fieldsToSubmit, field.name, Number(value))
                    break
                default:
                    set(fieldsToSubmit, field.name, value)
                    break
            }
        })

        const resp = await onSubmit(fieldsToSubmit)
        if (resp?.isError) {
            setFormErrors(resp.formErrors as string[])
            addServerErrors(resp, setError)
            setFilterValue('')
        } else {
            setFormErrors([])
        }
    }

    return (
        <Modal show onClose={isDirty ? () => {} : closeModal}>
            <Modal.Overlay />
            <Modal.Container variableHeight>
                <Modal.Header close={closeModal}>{`New ${sobjectType.toLowerCase()}`}</Modal.Header>
                <form onSubmit={handleSubmit(handleFormSubmit, handleInvalidSubmit)}>
                    <div className="pt-2 pb-4 bg-clip-padding border-b border-grey-700/10">
                        <Modal.SearchInput
                            placeholder="Filter all fields"
                            value={filterValue}
                            onChange={(event) => setFilterValue(event.target.value)}
                            reset={() => setFilterValue('')}
                        />
                    </div>
                    <div className="max-h-[50vh] overflow-y-auto">
                        <FormErrors errors={formErrors} className="mt-4 mx-8" />

                        <div className="pt-4 px-8 grid grid-cols-[minmax(0,2fr),minmax(0,3fr)] items-start gap-y-2">
                            {fieldsToRender.map((field) => {
                                return (
                                    <FormField
                                        key={field.name}
                                        fieldMetadata={field}
                                        recordTypeId={sobjectRecordTypeId}
                                        control={control}
                                    />
                                )
                            })}
                        </div>
                        <div className="grid place-items-center pt-6 pb-4">
                            {!filterValue && (
                                <Button
                                    type="button"
                                    size="small"
                                    iconComponent={
                                        isExpanded ? CollapseOutlinedIcon : ExpandOutlinedIcon
                                    }
                                    onClick={() => setIsExpanded(!isExpanded)}
                                >
                                    {isExpanded ? 'Hide additional fields' : 'Show all fields'}
                                </Button>
                            )}
                        </div>
                    </div>
                    {children &&
                        typeof children === 'function' &&
                        children({ watch, setValue, control })}
                    <Modal.Footer border>
                        <Button
                            type="submit"
                            variant="primary"
                            iconComponent={isSubmitting ? SpinnerInline : AddOutlinedIcon}
                            disabled={isSubmitting}
                            className="justify-self-end"
                        >
                            {`Create ${sobjectType.toLowerCase()}`}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal.Container>
        </Modal>
    )
}

type UseFieldsResult = {
    isLoading?: boolean
    visibleFields: FieldMetadata[]
    hiddenFields: FieldMetadata[]
    defaultValues: Record<string, any>
}

function useFields(sobjectType: CreateObjectModalProps['sobjectType']): UseFieldsResult {
    const objectPool = useObjectPool()
    const allMetadata = useObserver(
        () => Array.from(objectPool.getAll('FieldMetadata').values()) as FieldMetadata[]
    )

    const objectFields = allMetadata.filter((a) => a.objectName === sobjectType)

    const objectDefaultFields = DEFAULT_FIELDS_BY_SOBJECT[sobjectType]
    const objectHidden = DEFAULT_HIDDEN_FIELDS[sobjectType]
    const [requiredFields, notRequiredFields] = partition(objectFields, (f) => {
        if (f.required) {
            if (
                objectHidden.includes(f.name) &&
                f.defaultValue !== null &&
                typeof f.defaultValue !== 'undefined'
            ) {
                return false
            }
            return true
        }
        return objectDefaultFields.includes(f.name)
    })

    const visibleFields = onlyWriteable(requiredFields)
    const hiddenFields = onlyWriteable(notRequiredFields)

    const defaultValues: Record<string, string | boolean | string[]> = {}
    visibleFields
        .concat(hiddenFields)
        .filter((a) => typeof a.defaultValue !== 'undefined' && a.defaultValue !== null)
        .forEach((field) => {
            if (field.name && field.fieldType === 'multipicklist') {
                defaultValues[field.name] = [field.defaultValue].filter(Boolean) as string[]
            } else if (field.name) {
                defaultValues[field.name] = field.defaultValue!
            }
        })

    return {
        isLoading: false,
        visibleFields,
        hiddenFields,
        defaultValues,
    }
}

const DEFAULT_FIELDS_BY_SOBJECT: Record<CreateObjectModalProps['sobjectType'], string[]> = {
    Contact: ['FirstName', 'LastName', 'Title', 'Email', 'Phone'],
    Account: [],
    Opportunity: ['Amount', 'Name', 'CloseDate'],
    Lead: [],
}

const DEFAULT_HIDDEN_FIELDS: Record<CreateObjectModalProps['sobjectType'], string[]> = {
    Contact: ['CurrencyIsoCode'],
    Account: ['CurrencyIsoCode'],
    Opportunity: ['CurrencyIsoCode', 'IsPrivate'],
    Lead: ['CurrencyIsoCode'],
}

const CREATE_MODAL_HIDDEN_FIELDS = ['AccountId']
const ALLOW_LIST_FILEDNAMES = ['RecordTypeId']
/**
 * This logic is quite flawed since it depends on the layout info,
 * which is based on the Layout, but we ONLY fetch the MasterRecortType Layout
 * Should probably just change UI to fieldgroups.
 **/
function onlyWriteable(fields: FieldMetadata[]): FieldMetadata[] {
    return fields
        .filter((a) => !CREATE_MODAL_HIDDEN_FIELDS.includes(a.name))
        .filter(
            ({ editableForNew, createable, name }) =>
                (editableForNew && createable) || ALLOW_LIST_FILEDNAMES.includes(name)
        )
}

function get(object: any, path: string) {
    return path
        .split('.')
        .reduce((currentObject, pathSegment) => currentObject?.[pathSegment], object) as unknown
}

function addServerErrors(
    errResponse: MutationErrorResponse,
    setError: (fieldName: string, error: { type: string; message: string }) => void
) {
    errResponse.fieldErrors.forEach((fieldError) => {
        setError(fieldError.fieldName, {
            type: 'server',
            message: fieldError.message,
        })
    })
}

function useFieldsToRender({
    visibleFields,
    hiddenFields,
    isExpanded,
    filterValue,
}: {
    visibleFields: FieldMetadata[]
    hiddenFields: FieldMetadata[]
    isExpanded: boolean
    filterValue: string
}) {
    const filteredFields = useFilteredFields(visibleFields.concat(hiddenFields), filterValue)
    if (filterValue) {
        return filteredFields
    }
    return isExpanded ? visibleFields.concat(hiddenFields) : visibleFields
}

function useFilteredFields(fields: FieldMetadata[], filterValue: string) {
    return useMemo(() => {
        if (filterValue) {
            return matchSorter(fields, filterValue, { keys: ['label'] })
        }
        return fields
    }, [fields, filterValue])
}

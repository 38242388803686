import React from 'react'
import { twMerge } from 'tailwind-merge'

export type CardColor = 'yellow' | 'blue' | 'green' | 'red' | 'grey'

const colorClass: Record<CardColor, string> = {
    yellow: 'bg-yellow-50',
    blue: 'bg-blue-50',
    green: 'bg-green-50',
    red: 'bg-red-50',
    grey: 'bg-grey-50',
}

export function Card({
    className,
    color,
    ...props
}: React.ComponentPropsWithoutRef<'div'> & { color: CardColor }) {
    //@TODO: This card style was added to the normal card with size=large and color classes with the bg classname
    return (
        <div
            className={twMerge('p-6 rounded-2xl min-h-[260px]', colorClass[color], className)}
            {...props}
        />
    )
}

export function CardTitle({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
    return (
        <div
            className={twMerge('text-sm leading-[1.4] opacity-100 font-semibold', className)}
            {...props}
        />
    )
}

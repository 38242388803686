import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'
import { parseISO } from 'date-fns'

import {
    isGroupId,
    Prefix,
    User,
    Group,
    isAccountModel,
    isContactModel,
    isOpportunityModel,
    isTruthy,
    AccountModel,
    ContactModel,
    OpportunityModel,
    LeadModel,
    isLeadModel,
} from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import {
    AccountFilledIcon,
    ContactFilledIcon,
    LeadFilledIcon,
    OpportunityFilledIcon,
} from '@laserfocus/ui/icons'
import { getDateShort } from '@laserfocus/ui/util-locale'
import { Button } from '@laserfocus/ui/beam'

import { Currency, Name, RecordCard, Value } from './RecordCard'
import { FailedConvertMutation } from './duplicate-utils'

export function DuplicatesSectionBody({
    failedMutation,
}: {
    failedMutation: FailedConvertMutation
}) {
    const duplicateErrors = failedMutation.error.errors.filter(
        (a) => a.errorCode === 'DUPLICATES_DETECTED'
    )
    if (duplicateErrors.length === 0) {
        return null
    }
    return (
        <div className="grid gap-4 py-8 px-8 items-stretch grid-cols-1">
            {duplicateErrors.flatMap((err) => {
                return err.matchRecords
                    .map((match) => {
                        if (isAccountModel(match.record)) {
                            return (
                                <DuplicateAccountCard record={match.record} key={match.record.Id} />
                            )
                        } else if (isContactModel(match.record)) {
                            return (
                                <DuplicateContactCard record={match.record} key={match.record.Id} />
                            )
                        } else if (isOpportunityModel(match.record)) {
                            return (
                                <DuplicateOpportunityCard
                                    record={match.record}
                                    key={match.record.Id}
                                />
                            )
                        } else if (isLeadModel(match.record)) {
                            return <DuplicateLeadCard record={match.record} key={match.record.Id} />
                        }

                        return null
                    })
                    .filter(isTruthy)
            })}
        </div>
    )
}

export function DuplicateAccountCard({
    record,
    selected,
    onSelect,
}: {
    record: Partial<AccountModel>
    selected?: boolean
    onSelect?: () => void
}) {
    const owner = useSubscribe(getClient(), (tx) => queryOwner(tx, record.OwnerId), null, [
        record.OwnerId,
    ])
    return (
        <RecordCard
            title={record.Name || 'Account'}
            icon={<AccountFilledIcon className="w-6 h-6" />}
            action={
                onSelect ? (
                    selected ? (
                        <Button disabled>Selected</Button>
                    ) : (
                        <Button onClick={onSelect}>Select</Button>
                    )
                ) : undefined
            }
        >
            <Name>Owner</Name>
            <Value>{owner?.Name || record.OwnerId}</Value>
        </RecordCard>
    )
}

export function DuplicateContactCard({
    record,
    selected,
    onSelect,
}: {
    record: Partial<ContactModel>
    selected?: boolean
    onSelect?: () => void
}) {
    const [owner, account] = useSubscribe(
        getClient(),
        (tx) => Promise.all([queryOwner(tx, record.OwnerId), queryAccount(tx, record.AccountId)]),
        [null, null],
        [record.OwnerId]
    )
    return (
        <RecordCard
            title={record.Name || 'Contact'}
            icon={<ContactFilledIcon className="w-6 h-6" />}
            action={
                onSelect ? (
                    selected ? (
                        <Button disabled>Selected</Button>
                    ) : (
                        <Button onClick={onSelect}>Select</Button>
                    )
                ) : undefined
            }
        >
            <Name>Account</Name>
            <Value>{account?.Name || record.AccountId}</Value>
            <Name>Owner</Name>
            <Value>{owner?.Name || record.OwnerId}</Value>
            {record.Title && (
                <>
                    <Name>Title</Name>
                    <Value>{record.Title}</Value>
                </>
            )}
            {record.Phone && (
                <>
                    <Name>Phone</Name>
                    <Value>{record.Phone}</Value>
                </>
            )}
            {record.Email && (
                <>
                    <Name>Email</Name>
                    <Value>{record.Email}</Value>
                </>
            )}
        </RecordCard>
    )
}

function DuplicateOpportunityCard({ record }: { record: Partial<OpportunityModel> }) {
    const [owner, account] = useSubscribe(
        getClient(),
        (tx) => Promise.all([queryOwner(tx, record.OwnerId), queryAccount(tx, record.AccountId)]),
        [null, null],
        [record.OwnerId]
    )
    return (
        <RecordCard
            title={record.Name || 'Opportunity'}
            icon={<OpportunityFilledIcon className="w-6 h-6" />}
        >
            <Name>Account</Name>
            <Value>{account?.Name || record.AccountId}</Value>
            <Name>Owner</Name>
            <Value>{owner?.Name || record.OwnerId}</Value>
            {record.Amount && (
                <>
                    <Name>Amount</Name>
                    <Value>
                        <Currency value={record.Amount} currencyIsoCode={record.CurrencyIsoCode} />
                    </Value>
                </>
            )}
            {record.CloseDate && (
                <>
                    <Name>Close Date</Name>
                    <Value>{getDateShort(parseISO(record.CloseDate))}</Value>
                </>
            )}
        </RecordCard>
    )
}

function DuplicateLeadCard({ record }: { record: Partial<LeadModel> }) {
    const owner = useSubscribe(getClient(), (tx) => queryOwner(tx, record.OwnerId), null, [
        record.OwnerId,
    ])
    return (
        <RecordCard title={record.Name || 'Lead'} icon={<LeadFilledIcon className="w-6 h-6" />}>
            <Name>Owner</Name>
            <Value>{owner?.Name || record.OwnerId}</Value>
            {record.Title && (
                <>
                    <Name>Title</Name>
                    <Value>{record.Title}</Value>
                </>
            )}
            {record.Company && (
                <>
                    <Name>Company</Name>
                    <Value>{record.Company}</Value>
                </>
            )}
            {record.Phone && (
                <>
                    <Name>Phone</Name>
                    <Value>{record.Phone}</Value>
                </>
            )}
            {record.Email && (
                <>
                    <Name>Email</Name>
                    <Value>{record.Email}</Value>
                </>
            )}
        </RecordCard>
    )
}

async function queryOwner(tx: ReadTransaction, ownerId?: string): Promise<User | Group | null> {
    if (!ownerId) {
        return null
    }
    const ownerKey = [isGroupId(ownerId) ? Prefix.Group : Prefix.User, ownerId].join('/')
    return tx.get(ownerKey) as Promise<User | Group | null>
}

async function queryAccount(tx: ReadTransaction, accountId?: string) {
    if (!accountId) {
        return null
    }
    const key = [Prefix.Account, accountId].join('/')
    return tx.get(key) as Promise<AccountModel | null>
}

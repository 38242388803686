import clsx from 'clsx'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'
import { useObserver } from 'mobx-react-lite'

import { useTableDataContext } from './table-context'
import { AvatarCell } from './TableCell/AvatarCell'
import { TableCell } from './TableCell/TableCell'
import { useBulkEdit } from './BulkEdit/bulkedit-context'

export const TABLE_ROW_HEIGHT = 54
export const TABLE_ROW_CLASS_NAME =
    'hover:bg-grey-700/5 grid min-w-full border-b border-grey-700/10'
export const TABLE_ROW_CELL_CLASS_NAME = 'px-2 py-0 grid items-center'

interface TableRowProps {
    index: number
    translateY: number
    relative?: boolean
}

export const TableRow = memo(function TableRow({ index, translateY, relative }: TableRowProps) {
    const { columns, rows, isStickyLeftActive, gridTemplateColumns, searchTerm } =
        useTableDataContext()!
    const salesObject = rows[index]!
    const color = useObserver(() => (salesObject as { color?: string }).color)

    const { bulkEditTotal } = useBulkEdit()
    const isBulkEditActive = bulkEditTotal !== 'none'
    const showBorder = isStickyLeftActive || isBulkEditActive

    return (
        <div
            role="row"
            aria-rowindex={index}
            className={clsx(
                'group w-min',
                !relative && 'absolute top-0 left-0',
                TABLE_ROW_CLASS_NAME,
                color && 'hover:filter hover:brightness-95'
            )}
            style={{
                transform: !relative ? `translateY(${translateY}px)` : undefined,
                height: TABLE_ROW_HEIGHT,
                gridTemplateColumns,
                backgroundColor: color ?? undefined,
            }}
        >
            <div
                role="cell"
                className={clsx(
                    TABLE_ROW_CELL_CLASS_NAME,
                    'justify-items-end bg-white group-hover:bg-grey-700-opaque-5 z-[11]',
                    isBulkEditActive && 'sticky left-0',
                    'my-[1px]'
                )}
                style={{
                    backgroundColor: color ? color : undefined,
                }}
            >
                <AvatarCell salesObject={salesObject} color={color ?? null} />
            </div>
            {columns.map((column, colIndex) => (
                <div
                    role="cell"
                    key={colIndex}
                    className={twMerge(
                        TABLE_ROW_CELL_CLASS_NAME,
                        column.isStickyLeft &&
                            'sticky left-0 bg-white group-hover:bg-grey-700-opaque-5 border-grey-700/10 border-b -mb-px z-10',
                        column.isStickyLeft && showBorder && 'border-r',
                        column.isStickyLeft && bulkEditTotal !== 'none' && 'left-[62px]'
                    )}
                    style={{
                        backgroundColor: color ?? undefined,
                    }}
                    data-testid={`table-cell-${column.key}`}
                >
                    <TableCell
                        salesObject={salesObject}
                        column={column}
                        searchTerm={searchTerm}
                        rowIndex={index}
                    />
                </div>
            ))}
        </div>
    )
})

import { ACCOUNT_FIELDS, CONTACT_FIELDS, OPPORTUNITY_FIELDS } from '@laserfocus/shared/models'

export const OPPORTUNITY_FRAGMENT = `
fragment AccountOpportunity on Opportunity {
  ${OPPORTUNITY_FIELDS.join(`\n`)}
  CustomFields
}
`

export const ACCOUNT_FRAGMENT = `
  fragment AccountHeader on Account {
    ${ACCOUNT_FIELDS.join(`\n`)}
    CustomFields
  }
`

export const CONTACT_FRAGMENT = `
  fragment AccountContact on Contact {
    ${CONTACT_FIELDS.join(`\n`)}
    CustomFields
  }
`

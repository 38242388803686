import { createContext, useState, useContext } from 'react'

type ConvertLeadContextType = {
    isConvertModalOpen: boolean
    setIsConvertModalOpen?: (isOpen: boolean) => void
}
export const ConvertLeadContext = createContext<ConvertLeadContextType>({
    isConvertModalOpen: false,
})

export function ConvertLeadContextProvider({ children }: { children: React.ReactNode }) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    return (
        <ConvertLeadContext.Provider
            value={{ isConvertModalOpen: isOpen, setIsConvertModalOpen: setIsOpen }}
        >
            {children}
        </ConvertLeadContext.Provider>
    )
}
export function useConvertLeadModal() {
    return useContext(ConvertLeadContext)
}

export interface PageHeaderProps {
    title: React.ReactNode
    icon: React.ReactNode
    titleActions?: React.ReactNode
    actions?: React.ReactNode
}

export function PageHeader({ title, actions, titleActions, icon }: PageHeaderProps) {
    return (
        <div className="py-8 grid grid-cols-1 grid-flow-col items-center gap-8">
            <div className="grid grid-flow-col gap-1 justify-start items-center">
                {icon}
                <h1 data-testid="pageheader-pagetitle" className="font-serif text-3xl leading-none">
                    {title}
                </h1>
                {titleActions}
            </div>
            {actions && <div className="grid grid-flow-col gap-2">{actions}</div>}
        </div>
    )
}

interface PageHeaderCenteredProps {
    title: string
}

export function PageHeaderCentered({ title }: PageHeaderCenteredProps) {
    return (
        <h1
            data-testid="pageheader-pagetitle"
            className="font-serif text-[32px] leading-tight py-8 text-center"
        >
            {title}
        </h1>
    )
}

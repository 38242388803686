export function makeDeterministicPicker<T>(list: T[], fallback?: T) {
    return (seed: string | null | undefined): T => {
        if (!seed) {
            return fallback || list[0]!
        }
        const index = hash(seed, list.length)
        return list[index]!
    }
}

function hash(string: string, len: number) {
    const H = 37
    let total = 0

    for (let i = 0; i < string.length; i++) {
        total += H * total + string.charCodeAt(i)
        // SF IDS become to long and  they only differ in the last characters:
        // then the result is the same due to rounding
        if (total > 5642998963864) {
            total %= len
        }
    }
    total %= len
    if (total < 1) {
        len = 1
    }

    return total
}

import { memo, useLayoutEffect, useRef, useState } from 'react'
import { chunk } from 'lodash'
import { useVirtual } from 'react-virtual'

import { Dropdown, DropdownInput, DropdownProps } from '@laserfocus/ui/beam'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'

import { getEmojis } from './get-emojis'
import { EmojiDataProvider, useEmojiData } from './emoji-data-context'

interface EmojiPickerProps extends Omit<DropdownProps, 'content'>, EmojiPickerInnerProps {}

export function EmojiPicker({ topContent, onSubmit, ...props }: EmojiPickerProps) {
    return (
        <Dropdown
            {...props}
            content={<EmojiPickerInner topContent={topContent} onSubmit={onSubmit} />}
        />
    )
}

interface EmojiPickerInnerProps {
    topContent?: React.ReactNode
    onSubmit(emoji: string): void
}

const ROW_HEIGHT = 34
// 2 * 8px paddingX + 8 * 30px button width + 7 * 4px gap
const LIST_WIDTH = 284

function EmojiPickerInner({ topContent, onSubmit }: EmojiPickerInnerProps) {
    const [searchValue, setSearchValue] = useState('')
    const [width, setWidth] = useState(LIST_WIDTH)
    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)

    const categories = getEmojis(searchValue).map(({ category, emojis }) => {
        const isEmpty = emojis.length === 0

        return {
            category,
            emojiRows: isEmpty ? [[], [], [], [], []] : chunk(emojis, 8),
            isEmpty,
        }
    })
    const allEmojiRows = categories.flatMap(({ emojiRows }) => [[], ...emojiRows])

    return (
        <>
            {topContent}
            <div className="p-2">
                <DropdownInput
                    ref={inputRef}
                    value={searchValue}
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(event) => setSearchValue(event.target.value)}
                />
            </div>
            <EmojiDataProvider value={{ categories, allEmojiRows, onSubmit, setWidth }}>
                <VirtualizedList itemsLength={allEmojiRows.length} width={width} />
            </EmojiDataProvider>
        </>
    )
}

interface VirtualizedListProps {
    itemsLength: number
    width: number
}

function VirtualizedList({ itemsLength, width }: VirtualizedListProps) {
    const parentRef = useRef<HTMLDivElement>(null)
    const { totalSize, virtualItems } = useVirtual({
        size: itemsLength,
        parentRef,
        estimateSize,
    })
    const containerRef = useFixWidth()

    return (
        <div ref={parentRef} className="overflow-auto h-[12.5rem]" style={{ width }}>
            <div ref={containerRef} className="pb-1 mx-2 -mt-2 relative">
                <div style={{ height: totalSize }}>
                    {virtualItems.map((virtualRow) => (
                        <EmojiRow
                            key={virtualRow.index}
                            index={virtualRow.index}
                            translateY={virtualRow.start}
                        />
                    ))}
                    <CategoryHeadings />
                </div>
            </div>
        </div>
    )
}

function estimateSize() {
    return ROW_HEIGHT
}

// If scrollbar is permanently visible, it creates a vertical scroll container because the scrollbar takes away space and there is not enough space for emoji buttons on x-axis. This hook checks for this scrollbar and corrects the list width appropriately.
function useFixWidth() {
    const { setWidth } = useEmojiData()
    const containerRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const containerNode = containerRef.current

        if (!containerNode) {
            return
        }

        if (containerNode.scrollWidth) {
            setWidth((width) => width + containerNode.scrollWidth - containerNode.offsetWidth)
        } else {
            requestAnimationFrame(() => {
                setWidth((width) => width + containerNode.scrollWidth - containerNode.offsetWidth)
            })
        }
    }, [setWidth])

    return containerRef
}

const CategoryHeadings = memo(function CategoryHeadings() {
    const { categories } = useEmojiData()

    return (
        <>
            {categories.map(({ category, emojiRows, isEmpty }, index) => (
                <div key={index} style={{ height: (emojiRows.length + 1) * ROW_HEIGHT }}>
                    <div
                        style={{ height: ROW_HEIGHT - 2 }}
                        // We use 6px padding instead of 8px so focus ring on buttons in first row are not partially obscured by heading
                        className="pb-1.5 px-2 grid items-end text-xs font-medium leading-tight text-white/60 bg-grey-700/80 sticky -top-2"
                    >
                        {category}
                    </div>
                    {isEmpty && (
                        <div
                            style={{ height: (emojiRows.length - 1) * ROW_HEIGHT }}
                            className="text-center grid content-center text-white/60 text-xs leading-none font-medium"
                        >
                            <div className="text-[2rem] pb-2 text-white">🕵️</div>
                            No emojis found.
                        </div>
                    )}
                </div>
            ))}
        </>
    )
})

interface EmojiRowProps {
    index: number
    translateY: number
}

const EmojiRow = memo(function EmojiRow({ index, translateY }: EmojiRowProps) {
    const { allEmojiRows, onSubmit } = useEmojiData()
    const emojiRow = allEmojiRows[index]!

    if (!emojiRow.length) {
        return null
    }

    return (
        <div
            style={{
                transform: `translateY(${translateY}px)`,
                height: ROW_HEIGHT,
                width: 8 * ROW_HEIGHT - 4,
            }}
            className="absolute top-0 left-0 grid grid-cols-8 gap-1 pb-1"
        >
            {emojiRow.map(({ emoji, name }, index) => (
                <button
                    key={index}
                    title={name}
                    className="w-full h-full pt-px rounded-md text-lg leading-none outline-none overflow-hidden transition-shadow hover:bg-white/10 focus-visible:ring font-emoji"
                    onClick={() => onSubmit(emoji)}
                >
                    {emoji}
                </button>
            ))}
        </div>
    )
})

import { forwardRef } from 'react'

import { HintDisplay, HintDisplayProps } from './HintDisplay'
import { EverboardingHintName, useEverboardingHint } from './EverboardingContext'

interface EverboardingHintProps extends Omit<HintDisplayProps, 'isActive'> {
    name: EverboardingHintName
    /**
     * If the onboarding name counter shuold not automatically be triggered
     */
    manualTrigger?: boolean
    disable?: boolean
}

export const EverboardingHint = forwardRef(function EverboardingHint(
    { name, manualTrigger, children, disable, ...rest }: EverboardingHintProps,
    ref
) {
    const { isActive, trigger } = useEverboardingHint(name)
    return (
        <HintDisplay isActive={isActive && !disable} ref={ref} {...rest}>
            {isActive && !manualTrigger && !disable ? (
                <div onClick={trigger} id={name}>
                    {children}
                </div>
            ) : (
                children
            )}
        </HintDisplay>
    )
})

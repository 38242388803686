import {
    isAccountResult,
    isContactResult,
    isLeadResult,
    isOpportunityResult,
    SearchResult,
    OpportunitySearchResult,
} from '@laserfocus/shared/models'
import { CommandLine } from '@laserfocus/ui/beam'
import { PersonAvatar } from '@laserfocus/client/ui-shared'
import {
    AccountFilledIcon,
    ContactFilledIcon,
    LeadFilledIcon,
    OpportunityFilledIcon,
} from '@laserfocus/ui/icons'
import { useSalesobjectEmoji } from '@laserfocus/client/store-shared'
import { pickEmoji } from '@laserfocus/client/util-formatter'

type SearchResultOptionProps = {
    searchResult: SearchResult
} & Omit<
    React.ComponentPropsWithoutRef<typeof CommandLine.Option>,
    'title' | 'icon' | 'description'
>
export function SearchResultOption({ searchResult, ...rest }: SearchResultOptionProps) {
    const rootId = (searchResult as OpportunitySearchResult).AccountId || searchResult.Id
    const emoji = useSalesobjectEmoji(rootId) || pickEmoji(rootId)
    const optionProps = getCommandLineProps(searchResult, emoji)

    if (!optionProps) {
        return null
    }

    return <CommandLine.Option {...rest} {...optionProps} />
}

function getCommandLineProps(searchResult: SearchResult, emoji: string) {
    if (isAccountResult(searchResult)) {
        return {
            title: searchResult.Name,
            icon: <PersonAvatar emoji={emoji} id={searchResult.Id} size={30} />,
            description: <SearchResultObjectInfo sobjectType={searchResult.SObjectType} />,
        }
    } else if (isContactResult(searchResult) || isOpportunityResult(searchResult)) {
        return {
            title: searchResult.AccountName,
            icon: <PersonAvatar emoji={emoji} id={searchResult.AccountId} size={30} />,
            description: (
                <SearchResultObjectInfo
                    sobjectType={searchResult.SObjectType}
                    name={searchResult.Name}
                />
            ),
        }
    } else if (isLeadResult(searchResult)) {
        return {
            title: searchResult.Company,
            icon: <PersonAvatar emoji={emoji} id={searchResult.Id} size={30} />,
            description: (
                <SearchResultObjectInfo
                    sobjectType={searchResult.SObjectType}
                    name={searchResult.Name}
                />
            ),
        }
    }
}

type SearchResultObjectInfoProps = {
    sobjectType: SearchResult['SObjectType']
    name?: string
    seperator?: string
}
export function SearchResultObjectInfo({
    sobjectType,
    name,
    seperator = '→',
}: SearchResultObjectInfoProps) {
    return (
        <div className="grid grid-cols-[auto,minmax(0,1fr)] items-center">
            <SearchResultObjectIcon sobjectType={sobjectType} />
            <span>
                {sobjectType}
                {name && ` ${seperator}  ${name}`}
            </span>
        </div>
    )
}

function SearchResultObjectIcon({ sobjectType }: { sobjectType: SearchResult['SObjectType'] }) {
    switch (sobjectType) {
        case 'Account':
            return <AccountFilledIcon className="w-3.5 h-3.5 pr-1" />
        case 'Contact':
            return <ContactFilledIcon className="w-3.5 h-3.5 pr-1" />
        case 'Opportunity':
            return <OpportunityFilledIcon className="w-3.5 h-3.5 pr-1" />
        case 'Lead':
            return <LeadFilledIcon className="w-3.5 h-3.5 pr-1" />
    }
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

export function EmailFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M4 17V9.87l6.34 4.22a3 3 0 0 0 3.33 0L20 9.87V17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Z" />
            <path d="m20 7.46-7.44 4.97a1 1 0 0 1-1.11 0L4 7.46V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v.46Z" />
        </SvgIcon>
    )
}

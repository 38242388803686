export const SalesforcePrefix = {
    '001': 'Account',
    '002': 'Note',
    '003': 'Contact',
    '005': 'User',
    '006': 'Opportunity',
    '500': 'Case',
    '701': 'Campaigns',
    '007': 'Activity',
    '00B': 'ListView / View',
    '00D': 'Organization',
    '00E': 'UserRole',
    '00G': 'Group',
    '00I': 'Partner',
    '00O': 'Report',
    '00P': 'Attachment',
    '00Q': 'Lead',
    '00T': 'Task',
    '00U': 'Event',
    '00X': 'EmailTemplate',
    '00Y': 'EmailTemp',
    '00e': 'Profile',
    '00h': 'Layout – Page Layout',
    '00i': 'Pricebook',
    '00j': 'Product',
    '00k': 'OpportunityLineItem',
    '00l': 'Folder',
    '00v': 'CampaignMember',
    '01Z': 'Dashboard',
    '01a': 'DashboardComponent',
    '01s': 'Pricebook2',
    '01t': 'Product2',
    '02c': 'Sharing Rule',
    '03u': 'UserPreference',
    '069': 'ContentNote',
    '800': 'Contract',
    '801': 'Order',
    '802': 'OrderItem',
    '806': 'Approval',
} as const

export const NAME_TO_PREFIX = Object.fromEntries(
    Object.entries(SalesforcePrefix).map(([key, value]) => [value, key])
)

export function getPrefixForSobject(sobject: string) {
    const prefix = NAME_TO_PREFIX[sobject]
    return prefix!
}

export function identifyPrefix(id?: string | null) {
    if (!id) {
        return
    }
    return SalesforcePrefix[id.substring(0, 3) as keyof typeof SalesforcePrefix]
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function AddContactOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M9 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-3.54-.54a5 5 0 1 1 7.08 7.08 5 5 0 0 1-7.08-7.08ZM18 8a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2V9a1 1 0 0 1 1-1Zm-9 7a5 5 0 0 0-5 5h10a5 5 0 0 0-5-5Zm-4.95.05A7 7 0 0 1 16 20v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a7 7 0 0 1 2.05-4.95Z"
                clipRule="evenodd"
            />
        </SvgIcon>
    )
}

import { useEffect, useMemo } from 'react'
import { useObserver } from 'mobx-react-lite'
import { useSearchParams } from 'react-router-dom'

import { StackNavigationProvider, StackNavigationContext } from '@laserfocus/client/store-shared'

import { useActivityStackNavigationStore } from '../store/ActivityStackNavigation'

import PrefetchNext from './PrefetchNext'

interface TodayContainerProps {
    rootId: string
}

export function TodayNavigationProvider({
    rootId,
    children,
}: React.PropsWithChildren<TodayContainerProps>) {
    const todayNavigation = useActivityStackNavigationStore('today')

    const [queryParams] = useSearchParams()
    const activityId = queryParams.get('activityId')
    useEffect(() => {
        todayNavigation.navigated({
            Id: activityId,
            RootId: rootId,
        })
    }, [rootId, activityId, todayNavigation])

    const nextId = useObserver(() => todayNavigation.next?.RootId)

    const value: StackNavigationContext = useMemo(
        () => ({
            navigateList: todayNavigation?.gotoList,
            navigateNext: todayNavigation?.navigateNext,
            navigatePrevious: todayNavigation?.navigatePrevious,
            listPath: todayNavigation?.listPath,
            title: 'Today',
        }),
        [
            todayNavigation?.gotoList,
            todayNavigation?.listPath,
            todayNavigation?.navigateNext,
            todayNavigation?.navigatePrevious,
        ]
    )

    return (
        <StackNavigationProvider value={value}>
            <>
                {children}
                {nextId && <PrefetchNext rootId={nextId} />}
            </>
        </StackNavigationProvider>
    )
}

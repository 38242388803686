import clsx from 'clsx'

type LoadingFieldProps = {
    amount: number
}
export function LoadingFieldsList({ amount }: LoadingFieldProps) {
    const arr = Array(amount).fill('')
    return (
        <div className="pt-3 pb-[0.625rem] pr-2 grid gap-[1.375rem]">
            {arr.map((_, i) => (
                <LoadingField key={i} short={i % 2 === 1} />
            ))}
        </div>
    )
}

function LoadingField({ short }: { short: boolean }) {
    return <div className={clsx('rounded-full bg-grey-700/5 h-2', short ? 'w-[66%]' : 'w-full')} />
}

import { ReadTransaction } from 'replicache'

import {
    AccountModel,
    ContactModel,
    isContact,
    isLead,
    LeadModel,
    Prefix,
} from '@laserfocus/shared/models'

export async function queryActivityRelations(
    tx: ReadTransaction,
    { WhoId, AccountId }: { WhoId?: string | null; AccountId?: string | null }
) {
    const [account, lead, contact] = await Promise.all([
        AccountId
            ? (tx.get([Prefix.Account, AccountId].join('/')) as Promise<AccountModel | undefined>)
            : undefined,
        isLead(WhoId)
            ? (tx.get([Prefix.Lead, WhoId].join('/')) as Promise<LeadModel | undefined>)
            : undefined,
        isContact(WhoId)
            ? (tx.get([Prefix.Contact, WhoId].join('/')) as Promise<ContactModel | undefined>)
            : undefined,
    ])
    return {
        account,
        lead,
        contact,
    }
}

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { CloseOutlinedIcon, SearchOutlinedIcon } from '@laserfocus/ui/icons'

import { Button } from '../button/Button'

interface SearchInputProps extends React.ComponentPropsWithoutRef<'input'> {
    icon?: React.ReactElement
    reset?(): void
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
    { reset, className, icon, ...props },
    ref
) {
    const isResetButtonVisible = Boolean(reset && props.value)

    return (
        <div
            className={twMerge(
                'mx-8 relative overflow-hidden rounded-md transition ring-1 ring-grey-700/10 shadow-[0_1px_3px_rgba(0,0,0,0.05)] focus-within:ring focus-within:!ring-blue-500 hover:ring-grey-700/20 ',
                className
            )}
        >
            {icon || (
                <SearchOutlinedIcon className="absolute top-1/2 -translate-y-1/2 left-2 w-4 h-4 pointer-events-none text-grey-700/60" />
            )}
            <input
                ref={ref as any}
                {...props}
                className={clsx(
                    'py-[0.3125rem] pl-[1.75rem] w-full leading-[1.25] outline-none placeholder-grey-700/40',
                    isResetButtonVisible ? 'pr-[1.875rem]' : 'pr-2'
                )}
            />
            {isResetButtonVisible && (
                <Button
                    variant="tertiary"
                    size="small"
                    iconComponent={CloseOutlinedIcon}
                    onClick={reset}
                    className="absolute top-1/2 right-0.5 -translate-y-1/2"
                />
            )}
        </div>
    )
})

import { platform } from '@todesktop/client-core'

import { NativeNavbarSpacer } from './NativeNavbarSpacer'

export function Window({ children }: { children: React.ReactNode }) {
    return (
        <>
            {typeof window !== 'undefined' && platform.todesktop.isDesktopApp() && (
                <NativeNavbarSpacer />
            )}
            {children}
        </>
    )
}

import React from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { CloseOutlinedIcon } from '@laserfocus/ui/icons'

import { Button } from '../button/Button'

interface ModalHeaderProps {
    children: React.ReactNode
    close?(): void
    border?: boolean
    titleClassName?: string
}

export function ModalHeader({ children, close, border, titleClassName }: ModalHeaderProps) {
    return (
        <div
            // relative: Needed to prevent background of inputs being in front of boxShadow of this element (Why is this happening?)
            className={clsx(
                'relative px-8 pt-8 pb-4 grid grid-flow-col grid-cols-1 items-start gap-4',
                border && 'border-b bg-clip-padding border-grey-700/10'
            )}
        >
            <h1 className={twMerge('font-serif text-[2rem] leading-[1.2]', titleClassName)}>
                {children}
            </h1>
            <Button
                type="button"
                title="Close"
                variant="tertiary"
                iconComponent={CloseOutlinedIcon}
                onClick={close}
                className="my-1"
            />
        </div>
    )
}

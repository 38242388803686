import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { omit } from 'lodash'
import { useSubscribe } from 'replicache-react'

import { Analytics } from '@laserfocus/client/util-analytics'
import { makeStackPath } from '@laserfocus/client/util-routing'
import { logger } from '@laserfocus/ui/logger'
import { getCurrencyFormatter } from '@laserfocus/client/util-formatter'
import { useBridgeStackStore } from '@laserfocus/client/store-shared'
import { StackModel } from '@laserfocus/client/model'
import {
    Delete2OutlinedIcon,
    DuplicateOutlinedIcon,
    ExternalLinkOutlinedIcon,
    FavoriteOutlinedIcon,
} from '@laserfocus/ui/icons'
import { mutateStack, queryCanCreateStack } from '@laserfocus/client/data-access-shared'
import { useCurrency } from '@laserfocus/client/feature-auth'
import { isTruthy } from '@laserfocus/shared/models'
import { useIsLocked, LockedFeatureModal, PlanChip } from '@laserfocus/client/feature-subscription'
import { getClient } from '@laserfocus/client/replicache'

import { ShareStackModal } from '../ShareStackModal'

import { StackCard } from './StackCard'

const { createStack, deleteStack, favoriteStack, unfavoriteStack } = mutateStack

type StackSObject = 'Lead' | 'Account' | 'Opportunity' | 'Contact'
type StackCardContainerProps = {
    stackId: string
}
export const StackCardContainer = observer(function StackCardContainer({
    stackId,
}: StackCardContainerProps) {
    const stackBridge = useBridgeStackStore()
    const [isShareStackModalOpen, setIsShareStackModalOpen] = useState(false)

    const fetchableStack = stackBridge.fetchableStacksById.get(stackId)
    const stack = fetchableStack?.stack

    function handleClick() {
        if (stack) {
            Analytics.trackEvent({
                event: 'stack_clicked',
                location: 'home',
                stack: Analytics.parseStackFromSobject(stack.sobject),
                stackName: stack.title,
                isPreset: StackModel.isPreset(stack),
            })
        }
    }

    const orgCurrency = useCurrency()
    const currencyFormatter = getCurrencyFormatter(fetchableStack?.forecastCurrency || orgCurrency)
    const label = fetchableStack?.forecastValue
        ? currencyFormatter.format(fetchableStack.forecastValue)
        : undefined

    useEffect(() => {
        if (fetchableStack && !fetchableStack.didFirstFetch) {
            logger.debug('StackCardContainer.prefetch', fetchableStack.stack.id)
            fetchableStack.prefetch()
        }
    }, [fetchableStack])

    const canCreateStack = useSubscribe(getClient(), queryCanCreateStack, true, [])
    const { isLocked, subscription } = useIsLocked(!canCreateStack)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)

    if (!stack) {
        return null
    }

    const stackSlug = StackModel.getSlug(stack)

    return (
        <>
            <StackCard
                onClick={handleClick}
                linkTo={makeStackPath(stackSlug)}
                icon={StackModel.getIcon(stack)}
                color={StackModel.getColor(stack)}
                title={stack.title}
                label={label}
                sobject={stackSobject(stack.sobject)}
                count={fetchableStack?.recordCount}
                actions={[
                    {
                        label: 'Share stack',
                        value: 'Share stack',
                        iconComponent: ExternalLinkOutlinedIcon,
                        onClick: () => setIsShareStackModalOpen(true),
                    },
                    {
                        label: isLocked ? (
                            <span className="flex justify-between">
                                Duplicate <PlanChip>PRO</PlanChip>
                            </span>
                        ) : (
                            'Duplicate'
                        ),
                        value: 'Duplicate',
                        iconComponent: DuplicateOutlinedIcon,
                        onClick: () => {
                            if (isLocked) {
                                setShowUpgradeModal(true)
                            } else {
                                createStack({ ...omit(stack, 'id'), title: stack.title + ' Copy' })
                            }
                        },
                    },
                    {
                        label: stack.isFavorite ? 'Unfavorite' : 'Favorite',
                        iconComponent: FavoriteOutlinedIcon,
                        value: stack.isFavorite ? 'Unfavorite' : 'Favorite',
                        onClick: () => {
                            stack.isFavorite ? unfavoriteStack(stack.id) : favoriteStack(stack.id)
                        },
                    },
                    StackModel.canUserDeleteStack(stack) && {
                        value: 'Delete',
                        label: 'Delete',
                        iconComponent: Delete2OutlinedIcon,
                        onClick: () => deleteStack(stack.id),
                        destructive: true,
                    },
                ].filter(isTruthy)}
            />
            <ShareStackModal
                stack={stack}
                show={isShareStackModalOpen}
                closeModal={() => setIsShareStackModalOpen(false)}
            />
            {subscription && (
                <LockedFeatureModal
                    show={showUpgradeModal}
                    closeModal={() => setShowUpgradeModal(false)}
                    subscription={subscription}
                    title="Duplicate Stack"
                    lockedFeature="more than 2 custom stacks"
                />
            )}
        </>
    )
})

function stackSobject(sobject: StackSObject): string {
    switch (sobject) {
        case 'Account':
            return 'Accounts'
        case 'Lead':
            return 'Leads'
        case 'Opportunity':
            return 'Opportunities'
        case 'Contact':
            return 'Contacts'
        default:
            return sobject
    }
}

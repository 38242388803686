import { useState } from 'react'

import { getActivityCreateMessage } from '@laserfocus/client/util-formatter'
import { dateToDateString, isAccount } from '@laserfocus/shared/models'
import { Button, toast } from '@laserfocus/ui/beam'
import { logger } from '@laserfocus/ui/logger'
import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'
import {
    StepModal,
    StepSelectDate,
    StepSetTitle,
    StepSelectOppContact,
} from '@laserfocus/client/shared-step-modal'
import { AddOutlinedIcon } from '@laserfocus/ui/icons'
import { scheduleTask } from '@laserfocus/client/data-access-shared'
import { useUserId } from '@laserfocus/client/feature-auth'

import {
    useAccount,
    useContacts,
    useCurrentOpportunity,
    useOpportunities,
    usePerson,
} from '../modules/PersonContext'

interface AddTaskActionProps {
    className?: string
}

export function AddTaskAction({ className }: AddTaskActionProps) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <>
            <Button
                size="small"
                variant="tertiary"
                iconComponent={AddOutlinedIcon}
                onClick={() => setIsModalOpen(true)}
                className={className}
            >
                Add Task
            </Button>
            {isModalOpen && <AddTaskModalToParent closeModal={() => setIsModalOpen(false)} />}
        </>
    )
}

interface AddTaskModalToParentProps {
    closeModal(): void
}

function AddTaskModalToParent({ closeModal }: AddTaskModalToParentProps) {
    const person = usePerson()
    const account = useAccount()
    const opportunity = useCurrentOpportunity()
    const opportunities = useOpportunities().opportunities || []
    const contacts = useContacts().contacts || []

    const { location } = useAnalyticsContext()

    const userId = useUserId()

    const hasOpps = (opportunities.length || 0) > 1
    const hasContacts = (contacts.length || 0) > 1

    return (
        <StepModal
            onCancel={closeModal}
            onSubmit={(stepResults: any) => {
                const { whoId, whatId, selectedDate, selectedTitle } = getResults(stepResults, {
                    hasOpps,
                    hasContacts,
                })
                closeModal()

                scheduleTask({
                    subject: selectedTitle,
                    date: dateToDateString(selectedDate),
                    dateTime: selectedDate.toISOString(),
                    accountId: isAccount(whatId) ? whatId : account?.Id,
                    whoId: whoId || person?.Id,
                    whatId: whatId || opportunity?.Id || account?.Id,
                    ownerId: userId ?? undefined,
                }).then(
                    () => {
                        const rootName = account?.Name || person?.Name
                        toast.success(getActivityCreateMessage(selectedDate, rootName))
                        Analytics.trackEvent({
                            event: 'activity_scheduled',
                            location: location || 'person_details',
                        })
                    },
                    (error) => {
                        logger.error(error)
                        toast.error({ title: 'Something went wrong' })
                    }
                )
            }}
            steps={
                [
                    hasContacts && {
                        title: 'Task Contact',
                        component: StepSelectOppContact,
                        componentProps: {
                            options: contacts || [],
                            searchPlaceholder: 'Select contact for this task',
                        },
                        defaultStepState: {
                            breadCrumbLabel: person?.Name,
                            WhoId: person?.Id,
                            selectedIndex: person?.Id
                                ? contacts.map((c) => c.Id).indexOf(person?.Id)
                                : 0,
                        },
                    },
                    hasOpps && {
                        title: 'Task Opportunity',
                        component: StepSelectOppContact,
                        componentProps: {
                            options: opportunities || [],
                            searchPlaceholder: 'Select opportunity for this task',
                        },
                        defaultStepState: {
                            breadCrumbLabel: opportunity?.Name,
                            WhatId: opportunity?.Id,
                            selectedIndex: person?.Id
                                ? opportunities.map((c) => c.Id).indexOf(person?.Id)
                                : 0,
                        },
                    },
                    {
                        title: 'Task title',
                        component: StepSetTitle,
                    },
                    {
                        title: 'Task date',
                        component: StepSelectDate,
                    },
                ].filter(Boolean) as any
            }
        />
    )
}

function getResults(
    results: any,
    {
        hasOpps,
        hasContacts,
    }: {
        hasContacts: boolean
        hasOpps: boolean
    }
) {
    let whoId
    let whatId

    let indexCounter = 0
    if (hasContacts && results[0].WhoId) {
        whoId = results[0].WhoId
        indexCounter++
    }
    if (hasOpps) {
        const oppIndex = hasContacts ? 1 : 0
        whatId = results[oppIndex].WhatId
        indexCounter++
    }
    const { selectedTitle } = results[indexCounter]
    const { selectedDate } = results[indexCounter + 1]

    return {
        whoId,
        whatId,
        selectedDate,
        selectedTitle,
    }
}

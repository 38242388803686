import { format, isToday } from 'date-fns'
import { Fragment } from 'react'
import clsx from 'clsx'

import { UseDatepickerResult, useMonthCalendar } from '@laserfocus/vendor/react-datepicker-hooks'
import {
    ArrowLeft2OutlinedIcon,
    ArrowLeftOutlinedIcon,
    ArrowRightOutlinedIcon,
} from '@laserfocus/ui/icons'
import { getFirstDayOfWeek } from '@laserfocus/ui/util-locale'

import { DropdownButton } from '../controls/DropdownButton'
import { Button } from '../../button/Button'

import { overrideTime } from './util'

interface MonthCalendarProps
    extends Pick<
        UseDatepickerResult,
        | 'activeMonth'
        | 'goToPreviousMonth'
        | 'goToNextMonth'
        | 'isDateSelected'
        | 'canSelectDate'
        | 'onDateSelect'
    > {
    dateOnly?: boolean
    goBack?(): void
}

export function MonthCalendar({
    dateOnly,
    activeMonth,
    goToPreviousMonth,
    goToNextMonth,
    isDateSelected,
    canSelectDate,
    onDateSelect,
    goBack,
}: MonthCalendarProps) {
    const { weeks } = useMonthCalendar({
        ...activeMonth,
        firstDayOfWeek: getFirstDayOfWeek(),
    })

    const previousMonthButton = (
        <Button
            title="Go to previous month"
            iconComponent={ArrowLeftOutlinedIcon}
            variant="quaternary"
            size="small"
            onClick={goToPreviousMonth}
        />
    )
    const nextMonthButton = (
        <Button
            title="Go to next month"
            iconComponent={ArrowRightOutlinedIcon}
            variant="quaternary"
            size="small"
            onClick={goToNextMonth}
        />
    )
    const title = format(activeMonth.date, 'MMMM y')

    return (
        <div className="grid gap-4 text-xs font-medium leading-none">
            {goBack ? (
                <div className="grid grid-cols-[3.25rem,1fr,3.25rem] justify-between items-center justify-items-center text-sm">
                    <Button
                        title="Go back"
                        iconComponent={ArrowLeft2OutlinedIcon}
                        variant="quaternary"
                        size="small"
                        className="mr-auto"
                        onClick={goBack}
                    />
                    {title}
                    <div className="grid grid-cols-2">
                        {previousMonthButton}
                        {nextMonthButton}
                    </div>
                </div>
            ) : (
                <div className="grid grid-cols-[1.625rem,1fr,1.625rem] justify-between items-center justify-items-center text-sm">
                    {previousMonthButton}
                    {title}
                    {nextMonthButton}
                </div>
            )}
            <div className="grid grid-cols-[repeat(8,minmax(2rem,1fr))] place-items-center leading-[1.2] tabular-nums">
                <div className="pb-2 text-white/20">CW</div>
                {weeks[0]!.days.map(({ date, day }) => (
                    <div key={day} className="pb-2 text-white/60">
                        {format(date, 'EEEEEE')}
                    </div>
                ))}
                {weeks.map(({ weekNumber, days }, weekIndex) => (
                    <Fragment key={weekIndex}>
                        <div className="text-white/20">{weekNumber}</div>
                        {days.map((day, dayIndex) => (
                            <Day
                                key={dayIndex}
                                {...day}
                                isDateSelected={isDateSelected}
                                canSelectDate={canSelectDate}
                                onDateSelect={overrideTime(onDateSelect, dateOnly)}
                                activeMonth={activeMonth.month}
                            />
                        ))}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}

interface DayProps
    extends Pick<UseDatepickerResult, 'isDateSelected' | 'canSelectDate' | 'onDateSelect'> {
    day: number
    month: number
    activeMonth: number
    date: Date
}

function Day({
    day,
    month,
    activeMonth,
    date,
    isDateSelected,
    canSelectDate,
    onDateSelect,
}: DayProps) {
    const isSelected = isDateSelected(date)
    const canSelect = canSelectDate(date)
    const isCurrentMonth = month === activeMonth

    return (
        <DropdownButton
            onClick={() => onDateSelect(date)}
            isHighlighted={isSelected}
            textCentered
            disabled={!canSelect}
            className={clsx(
                'w-[1.625rem] h-[1.625rem] p-0 rounded-full leading-none relative text-xs',
                !canSelect ? 'text-white/40' : isCurrentMonth ? 'text-white' : 'text-white/60'
            )}
        >
            {day}
            {isToday(date) && (
                <div
                    className={clsx(
                        'absolute bottom-[0.1875rem] left-0 right-0 w-[0.1875rem] h-[0.1875rem] rounded-full m-auto',
                        isSelected ? 'bg-white' : 'bg-blue-500'
                    )}
                />
            )}
        </DropdownButton>
    )
}

import clsx from 'clsx'

interface StackSectionProps {
    title: string
    children: React.ReactNode
    action?: React.ReactNode
}

export function StackSection({ title, children, action }: StackSectionProps) {
    const isUsing3Columns = Boolean(localStorage.getItem('lf:feature:stackreport'))
    return (
        <div className={clsx('grid gap-6 ', 'grid-cols-2')}>
            <div
                className={clsx(
                    'flex justify-between items-center pb-4 px-2',
                    isUsing3Columns ? 'col-span-3' : 'col-span-2'
                )}
            >
                <h2 className="font-semibold">{title}</h2>
                {action}
            </div>
            {children}
        </div>
    )
}

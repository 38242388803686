import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function RescheduleOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M5.095 10.724l1.653-1.888a1 1 0 111.505 1.317l-3.5 4a1 1 0 01-1.585-.104l-3-4.5a1 1 0 111.664-1.11l1.293 1.94C3.905 5.348 8.252 1.5 13.5 1.5 19.3 1.5 24 6.202 24 12c0 5.792-4.7 10.5-10.5 10.5a1 1 0 110-2 8.5 8.5 0 10-8.405-9.776zM13 6.5a1 1 0 011 1V12h4a1 1 0 110 2h-5a1 1 0 01-1-1V7.5a1 1 0 011-1z" />
        </SvgIcon>
    )
}

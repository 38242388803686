import { addMinutes, parseISO } from 'date-fns'

import {
    isOptimisticTaskId,
    NewEvent,
    NewTask,
    isTask as isTaskId,
    isEvent as isEventId,
    isOptimisticEventId,
    NewActivity,
    DateTimeString,
    Identity,
} from '@laserfocus/shared/models'
import { ObjectPool } from '@laserfocus/client/data-layer'

import * as Task from './task-util'
import * as Event from './event-util'
import { TaskModel } from './TaskModel'
import { EventModel } from './EventModel'

export function isOpen(activity: NewActivity) {
    if (isTask(activity)) {
        return !activity.IsClosed
    }
    return !Event.isOver(activity)
}

export function isTask(a: Pick<NewActivity, 'Id'> | { Id: string }): a is NewTask {
    return isTaskId(a.Id) || isOptimisticTaskId(a.Id)
}

export function isEvent(
    a: Pick<NewActivity, 'Id' | '__typename'> | { Id: string; __typename: string }
): a is NewEvent {
    return a.__typename === 'Event' || isEventId(a.Id) || isOptimisticEventId(a.Id)
}

export function isEmail(a: NewActivity): a is NewTask {
    return isTask(a) && a.TaskSubtype === 'Email'
}

export function isReadOnly(a: NewActivity) {
    return isEmail(a) || a.Subject?.indexOf('[Outreach] [Email]') === 0
}

export function getDate(a: NewActivity) {
    if (isEvent(a)) {
        return Event.getSortingDate(a)
    }
    return Task.getDate(a)
}

export function getSortingDate(a: NewActivity) {
    return isTask(a) ? Task.getSortingDate(a) : Event.getSortingDate(a)
}

/**
 * Sorts by default descending (Future -> now -> Past)
 */
export const activitySorter = makeSorter(getSortingDate)

/**
 * @deprecated: THIS IS FUCKING SLOW
 */
export const openActivitySorter = makeSorter((a: NewActivity) =>
    isTask(a) ? Task.getOpenDate(a) : Event.getSortingDate(a)
)

function makeSorter(dateFunction: (a: NewActivity) => Date | undefined | null) {
    return (a: NewActivity, b: NewActivity): number => {
        const aSorting = dateFunction(a)
        const bSorting = dateFunction(b)
        if (aSorting && bSorting && aSorting > bSorting) {
            return -1
        } else if (aSorting && bSorting && aSorting < bSorting) {
            return 1
        } else if (
            a.CreatedDate &&
            b.CreatedDate &&
            parseISO(a.CreatedDate) > parseISO(b.CreatedDate)
        ) {
            return -1
        } else if (
            a.CreatedDate &&
            b.CreatedDate &&
            parseISO(a.CreatedDate) < parseISO(b.CreatedDate)
        ) {
            return 1
        }
        return a.Id > b.Id ? -1 : 1
    }
}

interface LastActivityHolder {
    _LastActivityDate?: DateTimeString
    LastActivity?: {
        SortingDate?: Date
    }
}

export function getLastActivityDate(record: LastActivityHolder) {
    const nowTime = addMinutes(new Date(), 5).toISOString()
    const latest = record.LastActivity?.SortingDate
    if (!record._LastActivityDate) {
        return latest?.toISOString()
    } else if (!latest) {
        return record._LastActivityDate
    }

    const inThePast = [latest.toISOString(), record._LastActivityDate].filter((a) => a <= nowTime)
    // For some reason decorated Dates cant be subtracted from each other
    const sorted = inThePast.sort().reverse()
    return sorted[0]
}

interface NextActivityHolder {
    objectPool: ObjectPool
    OpenActivities: Array<TaskModel | EventModel>
}

export function getNextActivity(record: NextActivityHolder) {
    const identity = record.objectPool.getSingle<Identity>('Identity')
    const myId = identity?.Id

    const nextFirst = [...record.OpenActivities].reverse()

    if (myId) {
        const myNextActivity = nextFirst.find((a) => a.OwnerId === myId)
        if (myNextActivity) {
            return myNextActivity
        }
    }
    return nextFirst[0] ?? null
}

import clsx from 'clsx'
import { forwardRef } from 'react'

import { ArrowDownOutlinedIcon } from '@laserfocus/ui/icons'

import { Button, ButtonProps } from '../../../button/Button'

interface SelectButtonProps extends ButtonProps {
    placeholder: string
    selectedOptionLabel?: string
    badge?: string | number
}

export const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(function SelectButton(
    { placeholder, selectedOptionLabel, badge, className, ...props },
    ref
) {
    const hasBadge = Boolean(badge || badge === 0)

    return (
        <Button
            ref={ref as React.Ref<any>}
            variant="quaternary"
            size="small"
            iconComponent={hasBadge ? undefined : ArrowDownOutlinedIcon}
            iconPosition="right"
            title={placeholder}
            className={clsx(
                'w-full bg-white/5',
                !selectedOptionLabel && 'text-white/60',
                className
            )}
            {...props}
        >
            <span className="w-full text-left overflow-x-hidden whitespace-nowrap text-ellipsis">
                {selectedOptionLabel || placeholder}
            </span>
            {hasBadge && (
                <span className="py-0.5 px-1 ml-1 min-w-[1.125rem] text-white bg-blue-500 rounded-full text-xs leading-[1.2] justify-self-end tabular-nums text-center">
                    {badge}
                </span>
            )}
        </Button>
    )
})

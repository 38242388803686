import { useState } from 'react'
import { useNavigate } from 'react-router'
import clsx from 'clsx'

import { Stack, StackUpdate } from '@laserfocus/shared/models'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { Button, ContentEditable } from '@laserfocus/ui/beam'
import { HelpOutlinedIcon } from '@laserfocus/ui/icons'
import { useSyncedInputState } from '@laserfocus/ui/util-react'
import { StackModel } from '@laserfocus/client/model'
import { SearchInputButton } from '@laserfocus/client/ui-shared'

import { EditColumns } from '../EditColumns/EditColumns'

import { TableIcon } from './TableIcon'
import { TableTitleInput } from './TableTitleInput'
import { TableTitleActions } from './TableTitleActions'
import { MoreActions } from './MoreActions'

export const TABLE_PAGE_HEADER_HEIGHT = 108

export interface PageHeaderProps {
    // Title
    innerTitle: string
    setInnerTitle(title: string): void
    setTitle(title: string): void
    titlePlaceholder: string
    setIsCreatingNewStack(creatingNew: boolean): void
    stack: Stack
    isCreatingNewStack: boolean
    duplicateStack(title: string): void
    resetStack(): void
    setIsFavorite(isFavorite: boolean): void
    hasCountMessage: boolean
    setShowCountMessage(showCountMessage: boolean): void
    isLoadingMore: boolean
    // Actions
    searchTerm: string
    setSearchTerm(searchTerm: string): void
    deleteStack(): Promise<void>
    setShowShareStackModal(showModal: boolean): void
    setShowTimeStackModal(showModal: boolean): void
    updateStack(update: StackUpdate): void
}

export function TablePageHeader({
    innerTitle,
    setInnerTitle,
    setTitle,
    titlePlaceholder,
    isCreatingNewStack,
    setIsCreatingNewStack,
    stack,
    duplicateStack,
    resetStack,
    setIsFavorite,
    hasCountMessage,
    setShowCountMessage,
    isLoadingMore,
    searchTerm,
    setSearchTerm,
    deleteStack,
    setShowShareStackModal,
    setShowTimeStackModal,
    updateStack,
}: PageHeaderProps) {
    const navigate = useNavigate()
    const [innerTitleNewStack, setInnerTitleNewStack] = useState('')

    const {
        onBlur: onInnerBlur,
        onChange,
        onFocus,
        value,
    } = useSyncedInputState(`${stack.description || ''}`, (description: string) => {
        updateStack({ description })
    })

    function onBlur() {
        if (value.indexOf('http') === 0 && !value.trim().includes(' ')) {
            updateStack({ description: '', helpUrl: value })
            onChange({ target: { value: '' } })
        } else {
            onInnerBlur()
        }
    }

    return (
        <div className="grid grid-cols-1 items-start">
            <div className={clsx('pt-4 pb-2 grid grid-cols-1 grid-flow-col items-center gap-8')}>
                <div className="grid grid-flow-col gap-1 justify-start items-center">
                    <TableIcon
                        stackId={stack.id}
                        color={StackModel.getColor(stack)}
                        icon={StackModel.getIcon(stack)}
                    />
                    <h1
                        data-testid="pageheader-pagetitle"
                        className="font-serif text-3xl leading-none"
                    >
                        {isCreatingNewStack ? (
                            <TableTitleInput
                                value={innerTitleNewStack}
                                placeholder={titlePlaceholder}
                                focusOnMount
                                onValueChange={setInnerTitleNewStack}
                                onBlur={() => {
                                    if (innerTitleNewStack) {
                                        duplicateStack(innerTitleNewStack)
                                        setInnerTitleNewStack('')
                                    }
                                    setIsCreatingNewStack(false)
                                }}
                            />
                        ) : (
                            <TableTitleInput
                                value={innerTitle}
                                placeholder={titlePlaceholder}
                                onValueChange={setInnerTitle}
                                onBlur={(event) => {
                                    if (event.currentTarget.innerText === '') {
                                        setInnerTitle(stack.title)
                                    } else if (innerTitle !== stack.title) {
                                        setTitle(innerTitle)
                                    }
                                }}
                            />
                        )}
                    </h1>
                    <TableTitleActions
                        stack={stack}
                        setIsFavorite={setIsFavorite}
                        hasCountMessage={hasCountMessage}
                        openCountMessage={() => setShowCountMessage(true)}
                        isLoadingMore={isLoadingMore}
                    />
                </div>
                <div className="grid grid-flow-col gap-2">
                    <SearchInputButton value={searchTerm} onChange={setSearchTerm} />
                    <EditColumns showHint />
                    <MoreActions
                        canDelete={!(stack.preset || stack.__typename === 'OrgStack')}
                        canReset={Boolean(stack.isExtendedFromOrg)}
                        isFavorite={stack.isFavorite || false}
                        deleteStack={() => deleteStack().then(() => navigate(RoutePaths.Home))}
                        duplicateStack={() => setIsCreatingNewStack(true)}
                        setIsFavorite={setIsFavorite}
                        shareStack={() => setShowShareStackModal(true)}
                        configureTiming={() => setShowTimeStackModal(true)}
                        resetStack={resetStack}
                    />
                </div>
            </div>
            <div className="ml-12 text-sm justify-self-start grid grid-flow-col">
                <ContentEditable
                    maxLength={90}
                    value={value}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder="Add Stack description"
                    onValueChange={(value) => onChange({ target: { value } })}
                    className={clsx(
                        'hover:bg-grey-700/5 focus-within:ring rounded-lg grid grid-flow-col transition',
                        !value && 'text-grey-700/60'
                    )}
                />
                {stack.helpUrl && (
                    <Button
                        iconComponent={HelpOutlinedIcon}
                        variant="tertiary"
                        onClick={() => openLink(stack.helpUrl!)}
                    />
                )}
            </div>
        </div>
    )
}

function openLink(url: string) {
    Object.assign(document.createElement('a'), {
        target: '_blank',
        href: url,
        rel: 'noopener noreferrer',
    }).click()
}

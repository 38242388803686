import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGGOMG
export function LockFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 6a5 5 0 0 1 10 0v3h1a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h1V6Zm8 0v3H9V6a3 3 0 1 1 6 0Zm-4.06 10.06a1.5 1.5 0 1 1 2.12-2.12 1.5 1.5 0 0 1-2.12 2.12Z"
                fill="currentColor"
            />
        </SvgIcon>
    )
}

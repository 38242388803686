import { getClient } from '@laserfocus/client/replicache'
import { FailedMutation, Identity, MutationTarget, Prefix } from '@laserfocus/shared/models'

const NO_ACCESS_CODE = 'INSUFFICIENT_ACCESS_ON_CROSS_REFERENCE_ENTITY'

type Profile = Pick<Identity, 'Id' | 'Name'>

export function shouldShowHelperMessage(mutation: FailedMutation) {
    const errorCode = mutation.code
    return (
        errorCode &&
        [NO_ACCESS_CODE].includes(errorCode) &&
        mutation.target?.object &&
        mutation.target?.id
    )
}

export async function getErrorMessage(mutation: FailedMutation, profile?: Profile | null) {
    const client = getClient()
    if (mutation.code === NO_ACCESS_CODE && mutation.target) {
        const key = getKey(mutation.target)
        if (!key) {
            return
        }
        return client.query(async (tx) => {
            const record = (await tx.get(key)) as Record<string, string> | undefined
            if (record && record.OwnerId && profile && record.OwnerId !== profile.Id) {
                const owner = (await tx.get([Prefix.User, record.OwnerId].join('/'))) as
                    | Record<string, string>
                    | undefined
                if (owner) {
                    return {
                        title: 'Insufficient access right',
                        description: `You can't edit this ${mutation.target?.object?.toLowerCase()} because it belongs to "${
                            owner.Name
                        }".`,
                    }
                }
            }
        })
    }
}

function getKey(target: MutationTarget) {
    switch (target.object) {
        case 'Task':
            return [Prefix.Task, target.id].join('/')
        case 'Event':
            return [Prefix.Event, target.id].join('/')
    }
}

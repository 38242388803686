import { z } from '@laserfocus/shared/decoder'

export const everboardingHintNameSchema = z.enum([
    'everboarding_stack_overlay',
    'everboarding_stack_customize_fields',
    'everboarding_activity_list_overlay',
    'everboarding_activity_list_filter',
    'everboarding_activity_list_activity_action',
    'everboarding_activity_list_bulk_edit',
    'everboarding_activity_list_bulk_edit_select_all',
    'everboarding_home_add_stack',
    'everboarding_home_stack_import_tab',
    'everboarding_stack_lfmethod_reminder',
    'everboarding_stack_pastclosedate_change',
    'everboarding_persondetails_customize_fields',
    'everboarding_overlay_fieldgroup_tab',
    'everboarding_overlay_expand',
    'everboarding_stack_bulk_edit',
])

export const everboardingTrackNameSchema = z.enum([
    'everboarding_stack_overlay',
    'everboarding_stack_view',
    'everboarding_activity_list_view',
    'everboarding_overdue_view',
    'everboarding_stack_lfmethod_view',
    'everboarding_stack_pastclosedate_view',
    'everboarding_home_view',
])

export type EverboardingHintName = z.infer<typeof everboardingHintNameSchema>
export type EverboardingTrackName = z.infer<typeof everboardingTrackNameSchema>

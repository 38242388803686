import { createContext, useContext } from 'react'
import type React from 'react'
import type { ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'

import { getClient } from '@laserfocus/client/replicache'
import { OrgSettings, Prefix, UserSettings } from '@laserfocus/shared/models'

type SettingsContextType = {
    orgSettings?: OrgSettings
    userSettings?: UserSettings
}

const SettingsContext = createContext<SettingsContextType>({})

export function SettingsProvider({ children }: { children: React.ReactNode }) {
    const client = getClient()
    const settings = useSubscribe<SettingsContextType>(
        client,
        async (tx: ReadTransaction) => {
            const [orgSettings, userSettings] = await Promise.all([
                tx.get(Prefix.OrgSettings),
                tx.get(Prefix.UserSettings),
            ])
            return {
                orgSettings: orgSettings as OrgSettings | undefined,
                userSettings: userSettings as UserSettings | undefined,
            }
        },
        {},
        []
    )
    return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>
}

export function useSettings() {
    return useContext(SettingsContext)
}
export function useCurrency() {
    const { orgSettings, userSettings } = useSettings()
    return userSettings?.defaultCurrencyIsoCode || orgSettings?.defaultCurrencyIsoCode
}

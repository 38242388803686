import 'reflect-metadata'
import { flow, observable, reaction, toJS } from 'mobx'

import { auto } from '@laserfocus/shared/util-common'
import { logger, assert } from '@laserfocus/ui/logger'
import { useRootStore } from '@laserfocus/client/root-store-context'

type Runner = () => Promise<any>

type Check = {
    name: string
    runner: Runner
    after: string[]
}

export type RootStoreInjection = {
    onboardingStore: OnboardingStore
}

export function useOnboardingStore(): OnboardingStore {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.onboardingStore
}

type SerializedOnboardingStore = {
    successfulChecks: string[]
}

const PERSIST_KEY = 'lf:onboardingStore'

export class OnboardingStore {
    @observable hasStarted = false
    @observable isFinished = false
    @observable wasSuccessful = false
    @observable successfulChecks: string[] = []
    checks: Check[] = []

    onInit() {
        this.hydrate()
        reaction(
            () => this.toJson(),
            (json) => {
                this._persist(json)
            }
        )
    }

    toJson(): SerializedOnboardingStore {
        return toJS({
            successfulChecks: this.successfulChecks,
        })
    }

    hydrate() {
        if (typeof window !== 'undefined') {
            const fromStore = localStorage.getItem(PERSIST_KEY)
            if (fromStore) {
                const parsed = JSON.parse(fromStore)
                this.fromJson(parsed)
            }
        }
    }
    persist() {
        const toStore = this.toJson()
        this._persist(toStore)
    }
    _persist(json: SerializedOnboardingStore) {
        const asString = JSON.stringify(json)
        localStorage.setItem(PERSIST_KEY, asString)
    }
    clear() {
        localStorage.removeItem(PERSIST_KEY)
    }

    fromJson(json: SerializedOnboardingStore) {
        if (json.successfulChecks) {
            this.successfulChecks = json.successfulChecks
        }
    }

    registerCheck(name: string, runner: Runner, after: string[] = []) {
        assert(!this.hasStarted, 'Trying to register a onboarding check, when already started')
        this.checks.push({
            name,
            runner,
            after,
        })
    }

    runChecks = flow(function* (this: OnboardingStore) {
        if (this.hasStarted) {
            return
        }
        this.hasStarted = true
        try {
            const tasks: any = {}
            const openChecks = this.checks.filter((c) => !this.successfulChecks.includes(c.name))
            openChecks.forEach((check) => {
                const func = async () => {
                    const result = await check.runner()
                    this.successfulChecks.push(check.name)
                    return result
                }
                if (check.after && check.after.length > 0) {
                    tasks[check.name] = [...check.after, func]
                } else {
                    tasks[check.name] = func
                }
            })

            logger.info('Running checks', Object.keys(tasks))
            const results = yield auto(tasks)
            logger.info('Result', results)
            this.isFinished = true
            this.wasSuccessful = true
        } catch (e: any) {
            logger.error(e)
            this.isFinished = true
            this.wasSuccessful = false
        }
    })
}

import { useState } from 'react'

import { DropdownSelect } from '@laserfocus/ui/beam'
import { Operator } from '@laserfocus/shared/models'

import { Column } from '../../Table/table-context'

import { FilterInputActions } from './FilterInputActions'
import { FilterInputProps } from './types'

export function BooleanInput({ column, operator }: FilterInputProps) {
    const [inputValue, setInputvalue] = useState<boolean | undefined>(
        column.filterCondition?.values[0] as boolean | undefined
    )

    function saveFilter() {
        if (inputValue === undefined) {
            column.removeFilterCondition()
            return
        }

        column.setFilterCondition({
            operator,
            values: [inputValue],
        })
    }

    return (
        <>
            <DropdownSelect
                placeholder="is true or false"
                options={BOOLEAN_OPTIONS}
                onChange={({ realValue }) => setInputvalue(realValue)}
                selectedOption={BOOLEAN_OPTIONS.find((option) => option.realValue === inputValue)}
            />
            <FilterInputActions
                column={column}
                saveFilter={isPristine(inputValue, column, operator) ? undefined : saveFilter}
                onRemoveFilterCondition={() => setInputvalue(undefined)}
            />
        </>
    )
}

function isPristine(inputValue: boolean | undefined, column: Column, operator: Operator) {
    const { filterCondition } = column

    if (!filterCondition) {
        return inputValue === undefined
    }

    return inputValue === filterCondition.values[0] && operator === filterCondition.operator
}

const BOOLEAN_OPTIONS = [
    {
        value: 'true',
        label: 'is true',
        realValue: true,
    },
    {
        value: 'false',
        label: 'is false',
        realValue: false,
    },
] as const

import { endOfDay, endOfYesterday } from 'date-fns'
import parseISO from 'date-fns/parseISO'

import { NewTask, dateHasTime, isDateTimeOnDate } from '@laserfocus/shared/models'

export function getDate(t: NewTask): Date | undefined {
    if (!t.IsClosed) {
        return getOpenDate(t)
    }
    return getClosedDate(t)
}

/**
 * Same as  OpenDate, but ignores CreatedDate (for table)
 */
export function getDueDate(
    t: Pick<NewTask, 'ReminderDateTime' | 'ActivityDate' | 'CreatedDate' | 'CompletedDateTime'>
) {
    // Check if on the same day
    if (
        t.ReminderDateTime &&
        t.ActivityDate &&
        isDateTimeOnDate(t.ReminderDateTime, t.ActivityDate)
    ) {
        return parseISO(t.ReminderDateTime)
    }

    const dateString = t.ActivityDate || t.ReminderDateTime || t.CompletedDateTime
    if (dateString) {
        return parseISO(dateString)
    }
}

export function getOpenDate(
    t: Pick<NewTask, 'ReminderDateTime' | 'ActivityDate' | 'CreatedDate' | 'CompletedDateTime'>
): Date | undefined {
    // Check if on the same day
    if (
        t.ReminderDateTime &&
        t.ActivityDate &&
        isDateTimeOnDate(t.ReminderDateTime, t.ActivityDate)
    ) {
        return parseISO(t.ReminderDateTime)
    }

    const dateString = t.ActivityDate || t.ReminderDateTime || t.CompletedDateTime || t.CreatedDate
    if (dateString) {
        return parseISO(dateString)
    }
}

export function isDue(t: Pick<NewTask, 'ReminderDateTime' | 'ActivityDate' | 'IsClosed'>) {
    if (t.IsClosed) {
        return false
    }
    const openDate = getOpenDate(t)
    if (openDate) {
        return new Date() > openDate
    }
    return false
}

export function isOverdue(t: Pick<NewTask, 'ReminderDateTime' | 'ActivityDate' | 'IsClosed'>) {
    if (t.IsClosed) {
        return false
    }
    const openDate = getOpenDate(t)
    if (openDate) {
        return endOfYesterday() > openDate
    }
    return false
}

export function getClosedDate(t: NewTask) {
    if (t.CompletedDateTime) {
        return parseISO(t.CompletedDateTime)
    }
    return getOpenDate(t)
}

export function getSortingDate(t: NewTask) {
    if (!t.IsClosed) {
        const openDate = getOpenDate(t)
        // console.log(t.Id, 'openDate', openDate)
        if (openDate) {
            if (!dateHasTime(openDate)) {
                return endOfDay(openDate)
            }
            return openDate
        }
    }
    return getClosedDate(t)
}

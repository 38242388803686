import { ActivityCounterItem } from './activity-counter.types'

export function sumActivityCounters(items: ActivityCounterItem[]): Record<string, number> {
    return items.reduce((sum, curr) => sumObjects(sum, curr.counters), {})
}

function sumObjects(left: Record<string, number>, right: Record<string, number>) {
    const allKeys = [...new Set([...Object.keys(left), ...Object.keys(right)])]
    return Object.fromEntries(
        allKeys.map((key) => {
            const lv = left[key] || 0
            const rv = right[key] || 0
            return [key, lv + rv]
        })
    )
}

export const CLICK_SAVED_BY_ACTIVITY: Record<string, number> = {
    record_viewed_overlay: 1,
    record_viewed_full: 0,
    saveStack: 0,
    shareStack: 0,
    createStackFromView: 2,
    setTitle: 0,
    showColumn: 3,
    hideColumn: 3,
    setColumnsOrder: 4,
    setColumnWidth: 0,
    setSorting: 3,
    setFilterCondition: 2,
    removeFilter: 0,
    deleteStack: 0,
    favoriteStack: 0,
    unfavoriteStack: 0,
    changeStackColor: 0,
    changeStackIcon: 0,
    completeTask: 0,
    uncompleteTask: 0,
    rescheduleTask: 4,
    rescheduleEvent: 4,
    deleteActivity: 1,
    updateTask: 1,
    updateEvent: 2,
    logActivity: 2,
    scheduleTask: 3,
    pinActivity: 0,
    unpinActivity: 0,
    visitRoot: 0,
    prefetchRoots: 0,
    createFieldGroup: 0,
    shareFieldGroup: 0,
    updateFieldGroup: 0,
    updateTitle: 0,
    updateFieldGroupsOrder: 0,
    setFieldGroupPosition: 0,
    addField: 0,
    removeField: 0,
    setFieldPosition: 0,
    deleteFieldGroup: 0,
    updateEmoji: 0,
    createObject: 0,
    convertLead: 0,
    updateObject: 2,
    discardFailedMutation: 0,
    createNote: 3,
    deleteNote: 2,
    updateNoteTitle: 2,
    updateNoteBody: 3,
    updateContentDocument: 0,
    pinNote: 0,
    unpinNote: 0,
    createNoteTemplate: 0,
    deleteNoteTemplate: 0,
    updateNoteTemplate: 0,
    countActivity: 0,
}

export const TIME_SAVED_BY_ACTIVITY: Record<string, number> = {
    record_viewed_overlay: 11,
    record_viewed_full: 6,
    saveStack: 0,
    shareStack: 0,
    createStackFromView: 10,
    setTitle: 0,
    showColumn: 7,
    hideColumn: 9,
    setColumnsOrder: 9,
    setColumnWidth: 0,
    setSorting: 8,
    setFilterCondition: 17,
    removeFilter: 21,
    deleteStack: 0,
    favoriteStack: 0,
    unfavoriteStack: 0,
    changeStackColor: 0,
    changeStackIcon: 0,
    completeTask: 2,
    uncompleteTask: 2,
    rescheduleTask: 11,
    rescheduleEvent: 11,
    deleteActivity: 9,
    updateTask: 8,
    updateEvent: 8,
    logActivity: 6,
    scheduleTask: 10,
    pinActivity: 0,
    unpinActivity: 0,
    visitRoot: 0,
    prefetchRoots: 0,
    createFieldGroup: 0,
    shareFieldGroup: 0,
    updateFieldGroup: 0,
    updateTitle: 7,
    updateFieldGroupsOrder: 0,
    setFieldGroupPosition: 0,
    addField: 0,
    removeField: 0,
    setFieldPosition: 0,
    deleteFieldGroup: 0,
    updateEmoji: 0,
    createObject: 17,
    convertLead: 35,
    updateObject: 16,
    discardFailedMutation: 0,
    createNote: 161,
    deleteNote: 8,
    updateNoteTitle: 8,
    updateNoteBody: 15,
    updateContentDocument: 0,
    pinNote: 0,
    unpinNote: 0,
    createNoteTemplate: 0,
    deleteNoteTemplate: 0,
    updateNoteTemplate: 0,
    countActivity: 0,
}

export const VALUE_BY_EMOJI: Record<string, number> = {}

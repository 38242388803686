import { EmptyState } from '@laserfocus/ui/beam'
import { useBodyClassName, useTitle } from '@laserfocus/ui/util-react'

interface NotFoundProps {
    title?: string
}

export function NotFoundPage({
    title = 'Maybe you’ve landed in a black hole. Or this page simply doesn’t exist.',
}: NotFoundProps) {
    useTitle('404')
    useBodyClassName('bg-grey-700')

    return (
        <div className="h-screen">
            <EmptyState
                variant="dark"
                emojis={['🕳', '🔎', '👀']}
                title="Oh no!"
                subtitle={title}
                action={{
                    label: 'Back to overview',
                    href: '/home',
                }}
            />
        </div>
    )
}

import { useController, Path } from 'react-hook-form'
import { useEffect } from 'react'

import { Input } from '@laserfocus/ui/beam'

import { FormControlProps, SalesObject } from '../get-form-control'

import { getControllerRules, getInputProps, useStateReducer } from './formcontrol-util'

/**
 *  Salesforce actually only applies the Decimal information only when showing the data.
 *  As soon as you start editing, the numbers are always float.
 *  Example: Field can be defined as (18,0) = 18 Decimals before comma, 0 after. Meaning it is an Int
 *  Salesforce stores floats in the database such as 9.05
 *  Without Editing it shows just the 9, but as soon as the user edits, it becomes a 9.05
 */

export function FormControlDouble<T extends SalesObject = SalesObject>({
    fieldMetadata,
    id,
    variant,
    control,
    submit,
    name,
}: FormControlProps<T>) {
    const { field, fieldState } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })
    const [state, setState] = useStateReducer({
        isFocused: false,
        inputValue: field.value ? field.value.toString() : '',
    })
    useEffect(() => {
        if (!state.isFocused) {
            setState({ inputValue: field.value ? field.value.toString() : '' })
        }
    }, [field.value, setState, state.isFocused])

    return (
        <Input
            id={id}
            variant={variant}
            disabled={!fieldMetadata.updateable}
            type="number"
            {...getInputProps(field, fieldState, state, setState, submit, {
                transformValue: parseFloat,
            })}
        />
    )
}

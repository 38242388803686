import { DateTimeString } from '..'
import {
    Identifier,
    Id,
    ModelCustomFields,
    WithCustomFields,
    WithCustomFieldsDTO,
    BaseFields,
    ToDTO,
    PhoneNumber,
    Url,
    EmailAddress,
    Currency,
    WithType,
} from '../base/model.types'

import { PureAccount, PureAccountDTO } from './account.types'
import { PersonActivity, ActivityDTO } from './activity.types'

export const CONTACT_PREFIX = '003'

export function isContact(id?: string | null): id is string {
    return id ? id.substr(0, 3) === CONTACT_PREFIX : false
}

export type ContactFields = Partial<BaseFields> &
    Partial<{
        readonly Name: string
        FirstName: string
        LastName: string
        Title: string
        Phone: PhoneNumber
        HomePhone: PhoneNumber
        MobilePhone: PhoneNumber
        OtherPhone: PhoneNumber
        AvatarUrl: Url
        Description: string
        Department: string
        Email: EmailAddress
        readonly LastActivityDate?: DateTimeString
        readonly RecordTypeId?: string

        MailingStreet: string
        MailingState: string
        MailingPostalCode: string
        MailingCity: string
        MailingCountry: string
        MailingGeocodeAccuracy: string
        MailingLatitude: number
        MailingLongitude: number

        OtherStreet: string
        OtherState: string
        OtherPostalCode: string
        OtherCity: string
        OtherCountry: string
        OtherGeocodeAccuracy: string
        OtherLatitude: number
        OtherLongitude: number

        CurrencyIsoCode: Currency

        /**
         * RelationShipField
         */
        OwnerId: Id
        AccountId: Id
    }>

type ContactRelationShips = Partial<{
    OpenActivities?: PersonActivity[]
    ActivityHistories?: PersonActivity[]
    Account: PureAccount
}>

export type ContactProps = Identifier & WithType<'Contact'> & ContactFields
export type ContactModel = ContactProps & ModelCustomFields
export function isContactModel(model: WithType<string>): model is ContactModel {
    return model.__typename === 'Contact'
}

export interface PureContact extends ContactProps, WithCustomFields {}
export interface Contact extends PureContact, ContactRelationShips {}

export interface PureContactDTO extends Identifier, ToDTO<ContactFields>, WithCustomFieldsDTO {
    __typename: 'Contact'
}
export interface ContactDTO extends PureContactDTO {
    OpenActivities?: ActivityDTO[]
    ActivityHistories?: ActivityDTO[]
    Account?: PureAccountDTO
}

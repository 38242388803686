import { useLayoutEffect, useState } from 'react'
import clsx from 'clsx'

import { useAutoFocusedRef } from '@laserfocus/ui/util-react'

const MAX_INPUT_LENGTH = 40

interface TableTitleInputProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string
    placeholder?: string
    placeholderClassName?: string
    focusOnMount?: boolean
    onValueChange(value: string): void
}

export function TableTitleInput({
    value,
    placeholder,
    focusOnMount,
    onValueChange,
    ...props
}: TableTitleInputProps) {
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useAutoFocusedRef<HTMLDivElement>(focusOnMount)

    useLayoutEffect(() => {
        if (inputRef.current && inputRef.current.innerText !== value) {
            inputRef.current.innerText = value
        }
    }, [value, inputRef])

    return (
        <div
            className="hover:bg-grey-700/5 focus-within:ring rounded-lg grid grid-flow-col transition"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            <div
                ref={inputRef}
                contentEditable
                className={clsx(
                    'outline-none px-3 py-1 whitespace-nowrap overflow-hidden',
                    !isFocused && 'text-ellipsis'
                )}
                onInput={(event) => {
                    // Fixes exceeding MAX_INPUT_LENGTH on edge cases which onKeyDown handler doesn't catch, like typing option + U, then U. Loses caret position.
                    if (event.currentTarget.innerText.length > MAX_INPUT_LENGTH) {
                        event.currentTarget.innerText = value
                    } else {
                        onValueChange(event.currentTarget.innerText)
                    }
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault()
                        event.currentTarget.blur()
                    }
                    // Prevents typing a character when MAX_INPUT_LENGTH is exceeded without losing caret position.
                    if (
                        event.currentTarget.innerText.length >= MAX_INPUT_LENGTH &&
                        event.key.length === 1 &&
                        // We want to allow things like cmd + A
                        !event.metaKey &&
                        !event.ctrlKey
                    ) {
                        event.preventDefault()
                    }
                }}
                {...props}
            />
            {!value && placeholder && (
                <div
                    className="text-grey-700/20 pr-3 -ml-3 py-1"
                    onClick={() => inputRef.current?.focus()}
                >
                    {placeholder}
                </div>
            )}
        </div>
    )
}

import { createContext, useContext, useMemo } from 'react'
import { useSubscribe } from 'replicache-react'

import { getClient } from '@laserfocus/client/replicache'
import { FieldMetadata, ObjectMetadata, PicklistValue } from '@laserfocus/shared/models'
import {
    SignalLowFilledIcon,
    SignalMediumFilledIcon,
    SignalHighFilledIcon,
    PhoneFilledIcon,
    CalendarFilledIcon,
    EmailFilledIcon,
    LinkedInFilledIcon,
    TaskFilledIcon,
    ChatFilledIcon,
} from '@laserfocus/ui/icons'

type PicklistSelectOption = PicklistValue & {
    iconComponent?: React.ComponentType<{ className?: string }>
}

interface ActivitiesMetadataContextValue {
    taskTypes: PicklistSelectOption[] | undefined
    taskTypeDefaultValue: string | undefined
    taskPriorities: PicklistSelectOption[] | undefined
    taskPriorityDefaultValue: string | undefined
    eventTypes: PicklistSelectOption[] | undefined
    eventTypeDefaultValue: string | undefined
}

const ActivitiesMetadataContext = createContext<ActivitiesMetadataContextValue | null>(null)

interface ActivitiesMetadataProviderProps {
    children: React.ReactNode
}

export function ActivitiesMetadataProvider({ children }: ActivitiesMetadataProviderProps) {
    const metadata = useMetadata()

    const contextValue = useMemo((): ActivitiesMetadataContextValue => {
        const taskTypeField = metadata?.task?.fields.find((field) => field.name === 'Type')
        const defaultTaskTypeValue = taskTypeField?.defaultValue
        const taskTypePicklist = getPicklistValues(taskTypeField)?.map((a) => ({
            ...a,
            iconComponent: getTypeIcon(a.value),
        }))

        const taskPriorityField = metadata?.task?.fields.find((field) => field.name === 'Priority')
        const defaultTaskPriorityValue = taskPriorityField?.defaultValue
        const taskPrioritiesPicklist = getPicklistValues(taskPriorityField)?.map((a) => ({
            ...a,
            iconComponent: getPriorityIcon(a.value),
        }))

        const eventTypeField = metadata?.event?.fields.find((field) => field.name === 'Type')
        const eventTaskTypeValue = eventTypeField?.defaultValue

        const eventTypePicklist = getPicklistValues(eventTypeField)?.map((a) => ({
            ...a,
            iconComponent: getTypeIcon(a.value),
        }))

        return {
            taskTypes: taskTypePicklist,
            taskTypeDefaultValue:
                typeof defaultTaskTypeValue === 'string' ? defaultTaskTypeValue : undefined,
            taskPriorities: taskPrioritiesPicklist,
            taskPriorityDefaultValue:
                typeof defaultTaskPriorityValue === 'string' ? defaultTaskPriorityValue : undefined,
            eventTypes: eventTypePicklist ?? undefined,
            eventTypeDefaultValue:
                typeof eventTaskTypeValue === 'string' ? eventTaskTypeValue : undefined,
        }
    }, [metadata])

    return (
        <ActivitiesMetadataContext.Provider value={contextValue}>
            {children}
        </ActivitiesMetadataContext.Provider>
    )
}

type MetadataBag = {
    event?: ObjectMetadata
    task?: ObjectMetadata
}

function useMetadata(): MetadataBag | null {
    const replicacheClient = getClient()

    return useSubscribe(
        replicacheClient,
        async (tx) => {
            const [eventMetadata, taskMetadata] = (await Promise.all([
                tx.get('objectmetadata/Event'),
                tx.get('objectmetadata/Task'),
            ])) as unknown[] as [ObjectMetadata | undefined, ObjectMetadata | undefined]

            return {
                event: eventMetadata,
                task: taskMetadata,
            } as MetadataBag as any
        },
        null,
        []
    )
}

function getPicklistValues(field: FieldMetadata | undefined) {
    if (
        !field ||
        field.fieldType !== 'picklist' ||
        !field.picklistValues ||
        field.picklistValues.length === 0
    ) {
        return null
    }

    return field.picklistValues
}

function getPriorityIcon(value: string) {
    const v = value.toLowerCase()
    if (v.includes('low')) {
        return SignalLowFilledIcon
    } else if (v.includes('medium') || v.includes('normal')) {
        return SignalMediumFilledIcon
    } else if (v.includes('high') || v.includes('important') || v.includes('urgent')) {
        return SignalHighFilledIcon
    }
    return SignalMediumFilledIcon
}

function getTypeIcon(value: string) {
    const v = value.toLowerCase()
    if (includesAny(v, ['meeting', 'onsite'])) {
        return CalendarFilledIcon
    } else if (includesAny(v, ['phone', 'call', 'outbound'])) {
        return PhoneFilledIcon
    } else if (includesAny(v, ['email', 'mail'])) {
        return EmailFilledIcon
    } else if (includesAny(v, ['linkedin'])) {
        return LinkedInFilledIcon
    } else if (includesAny(v, ['chat', 'text'])) {
        return ChatFilledIcon
    }
    return TaskFilledIcon
}

function includesAny(value: string, options: string[]) {
    return options.some((o) => value.includes(o))
}

export function useActivitiesMetadataContext() {
    return useContext(ActivitiesMetadataContext)
}

import { observer } from 'mobx-react-lite'

import { ActivityUtil } from '@laserfocus/client/model'
import { dateToDateString } from '@laserfocus/shared/models'
import { EverboardingHint, useEverboardingHint } from '@laserfocus/client/feature-everboarding'
import { rescheduleEvent, rescheduleTask } from '@laserfocus/client/data-access-shared'

import { Task, TaskProps } from '../Task/Task'

export const TaskListItem = observer(function TaskListItem({
    listIndex,
    ...props
}: Omit<TaskProps, 'reschedule'> & { listIndex: number }) {
    const taskProps = useTaskProps(props)
    if (listIndex === 0) {
        return (
            <div>
                <EverboardingHint
                    name="everboarding_activity_list_activity_action"
                    label="Complete or reschedule"
                    placement="right"
                    manualTrigger
                    containerClassName="-translate-y-7"
                    labelClassName="rotate-12 translate-y-3 -translate-x-9"
                >
                    <EverboardingHint
                        name="everboarding_activity_list_overlay"
                        label={`Click to see details`}
                        placement="top"
                        containerClassName="translate-y-7"
                        labelClassName="rotate-3 translate-y-3 ml-4"
                        arrowClassName="-rotate-12"
                    >
                        <Task {...taskProps} />
                    </EverboardingHint>
                </EverboardingHint>
            </div>
        )
    }
    if (listIndex === 3) {
        return (
            <EverboardingHint
                name="everboarding_activity_list_bulk_edit"
                label="Bulk edit multiple tasks"
                placement="left"
                manualTrigger
                containerClassName="-translate-y-7"
                labelClassName="-rotate-12 translate-y-3"
            >
                <Task {...taskProps} />
            </EverboardingHint>
        )
    }

    return <Task {...taskProps} />
})

function useTaskProps(props: Omit<TaskProps, 'reschedule'>): TaskProps {
    const { isActive: isActionActive, trigger: actionTrigger } = useEverboardingHint(
        'everboarding_activity_list_activity_action'
    )
    const { isActive: isBulkActive, trigger: bulkTrigger } = useEverboardingHint(
        'everboarding_activity_list_bulk_edit'
    )
    const reschedule = (date: Date) => {
        const { activity } = props
        return ActivityUtil.isTask(activity)
            ? rescheduleTask({
                  taskId: activity.Id,
                  date: dateToDateString(date),
                  dateTime: date.toISOString(),
              })
            : rescheduleEvent({ eventId: activity.Id, dateTime: date.toISOString() })
    }
    return {
        ...props,
        reschedule: isActionActive
            ? (date: Date) => {
                  actionTrigger()
                  return reschedule(date)
              }
            : reschedule,
        completeTask: isActionActive
            ? (id) => {
                  actionTrigger()
                  return props.completeTask(id)
              }
            : props.completeTask,
        toggleSelect: isBulkActive
            ? () => {
                  bulkTrigger()
                  return props.toggleSelect()
              }
            : props.toggleSelect,
    }
}

import { useState } from 'react'

import { LayoutPadding, LayoutPaddingVertical, NavBar, PageHeader } from '@laserfocus/client/layout'
import { Button, Select } from '@laserfocus/ui/beam'
import { EmojiAvatar } from '@laserfocus/client/ui-shared'

import { TIMEFRAMES, useCounterContext } from './CounterContext'

export function StatsLayout({ children }: { children: React.ReactNode }) {
    return (
        <div className={'w-full'}>
            <NavBar breadcrumbs={[{ title: 'Stats' }]} />
            <LayoutPaddingVertical maxWidth="stackWidth">
                <PageHeader
                    title="Your Laserfocus Stats"
                    icon={<EmojiAvatar emoji="🏆" size={44} className="mr-4" />}
                    actions={<TimeframeSelection />}
                />
                <LayoutPadding top bottom>
                    {children}
                </LayoutPadding>
            </LayoutPaddingVertical>
        </div>
    )
}

function TimeframeSelection() {
    const { timeframe, setTimeframe } = useCounterContext()
    const [isOpen, setIsOpen] = useState(false)
    const selected = TIMEFRAMES.find((t) => t.value === timeframe)
    return (
        <Select
            options={TIMEFRAMES}
            isOpen={isOpen}
            onSubmit={({ value }) => {
                setTimeframe(value)
                setIsOpen(false)
            }}
            onCancel={() => setIsOpen(false)}
            initialOptionValue={selected?.value}
        >
            <Button onClick={() => setIsOpen((a) => !a)}>{selected?.label}</Button>
        </Select>
    )
}

import { createContext, useContext, useEffect, useState } from 'react'
import { useObserver } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router'

import { Sorting, Stack, StackUpdate } from '@laserfocus/shared/models'
import { useObjectPool } from '@laserfocus/client/root-store-context'
import { logger } from '@laserfocus/ui/logger'
import { BridgeStackStore, useBridgeStackStore } from '@laserfocus/client/store-shared'
import type { RCFetchableStack } from '@laserfocus/client/store-shared'
import { makeStackPath } from '@laserfocus/client/util-routing'
import { StackModel } from '@laserfocus/client/model'
import { useStackById } from '@laserfocus/client/data-access-shared'

import { getVisibleColumns, getAllColumns } from './get-columns'
import { Column, Row } from './Table/table-context'
import { useStackActions } from './useStackActions'

export type UseTableResult = ResultNoStack | ResultFinalNoStack | ResultsSuccess

interface ResultNoStack {
    type: 'no-stack'
    rows?: undefined
}
interface ResultFinalNoStack {
    type: 'final-no-stack'
    rows?: undefined
}

interface ResultsSuccess {
    type: 'success'
    hasFetchError: boolean
    columns: Column[]
    rows: Row[]
    stackId: string
    stack: Stack
    isLoading: boolean
    isLoadingMore: boolean
    setColumnsOrder(columnKeys: string[]): void
    resetStack(): void
    updateStack(update: StackUpdate): void
    duplicateStack(title: string): void
    setTitle(title: string): void
    setIsFavorite(isFavorite: boolean): void
    deleteStack(): Promise<void>
    setSorting(sorting: Sorting): void
    searchTerm: string
    setSearchTerm: (v: string) => void
    serverCount?: number
    count?: number
}

export function useTable(stackId: string | null, stackBridge: BridgeStackStore): UseTableResult {
    const [searchTerm, setSearchTerm] = useState('')
    const navigate = useNavigate()
    const { stack, fetchableStack, initiallyLoaded } = useStack(stackId, stackBridge)
    const persistedStack = useStackById(stackId!)
    const actions = useStackActions(stack, persistedStack, 'user')

    const objectPool = useObjectPool()
    const { rows, isLoading, isLoadingMore } = useFilteredData(fetchableStack, searchTerm)

    useEffect(() => {
        if (stack) {
            stackBridge.initFetchableStackFromStack(stack)
        }
    }, [stack, stackBridge])

    if (!stack || !actions) {
        if (initiallyLoaded) {
            return {
                type: 'final-no-stack',
            }
        }
        return {
            type: 'no-stack',
        }
    }

    const columns = getVisibleColumns(stack, actions, objectPool)
    return {
        type: 'success',
        // This is not its specific error type, because we need most of these props to render the layout
        hasFetchError: Boolean(fetchableStack?.errorMessage),
        stackId: stack.id!,
        columns,
        rows: rows || [],
        isLoading,
        isLoadingMore,
        setColumnsOrder: (columns: [string, ...string[]]) => actions.setColumnsOrder(columns),
        stack: stack,
        resetStack: actions.reset,
        updateStack: actions.updateStack,
        setSorting: actions.setSorting,
        duplicateStack: (title: string) =>
            actions.duplicate(title)?.then((newStack) => {
                if (newStack) {
                    navigate(makeStackPath(StackModel.getSlug(newStack)))
                }
            }),
        setTitle: (title) => actions.setTitle(title),
        setIsFavorite: actions.setIsFavorite,
        deleteStack: actions.deleteStack,
        searchTerm,
        setSearchTerm,
        count: fetchableStack?.currentRecordIds.length,
        serverCount: fetchableStack?.serverCount ?? undefined,
    }
}

export function useStack(stackId: string | null, stackBridge: BridgeStackStore) {
    return useObserver(() => {
        const fetchableStack = stackId ? stackBridge.fetchableStacksById.get(stackId) : undefined
        const stack = fetchableStack?.stack
        return {
            fetchableStack,
            stack,
            initiallyLoaded: stackBridge.initiallyLoaded,
        }
    })
}

type AllColumns = {
    salesObjectType: string
    columns: Column[]
}[]
export const AllColumnsContext = createContext<AllColumns | null>(null)

export function useAllColumns(): AllColumns {
    const fromContext = useContext(AllColumnsContext)
    const { stackSlug } = useParams()
    const stackId = StackModel.parseIdFromSlug(stackSlug)

    const objectPool = useObjectPool()
    const persistedStack = useStackById(stackId!)

    const stackBridge = useBridgeStackStore()
    const { stack } = useStack(stackId!, stackBridge)
    const actions = useStackActions(stack!, persistedStack, 'user')
    if (fromContext) {
        return fromContext
    }

    if (!stackSlug) {
        logger.error(new Error('trying to get the stack for non existing slug'))
        return []
    }
    if (!stack || !actions || !stackId) {
        return []
    }

    const allColumns = getAllColumns(stack, actions, objectPool)
    return allColumns
}

const minimumSearchTermLength = 3

export function useFilteredData(fetchableStack: RCFetchableStack | undefined, searchTerm?: string) {
    const store = useObserver(() => {
        const cleanedTerm = searchTerm?.trim()
        if (cleanedTerm && fetchableStack) {
            const miniSearch = fetchableStack?.trieSnapshotter.trie
            try {
                if (cleanedTerm.length >= minimumSearchTermLength) {
                    const isDataInitiallyLoaded =
                        fetchableStack?.trieSnapshotter &&
                        fetchableStack?.trieSnapshotter.initiallyLoaded
                    if (!isDataInitiallyLoaded || !miniSearch) {
                        console.log('EARLY EXIT')
                        return { isLoading: true, isLoadingMore: false, data: [] }
                    }
                    const searchResult = miniSearch.search(cleanedTerm)

                    const matches = new Set(searchResult.map((sr) => sr.id))
                    const data = fetchableStack.currentRecords.filter((a) => matches.has(a.Id))

                    return {
                        isLoading: fetchableStack?.isInitiallyLoading || false,
                        isLoadingMore: fetchableStack?.isLoadingMore || false,
                        data,
                    }
                }
            } catch (e: any) {
                console.log('Error during minisearch')
                console.log('With the following term', cleanedTerm)
                console.error(e)
            }
        }
        const rows = (fetchableStack?.sortedData || []) as Row[]
        return {
            isLoading: fetchableStack?.isInitiallyLoading || false,
            isLoadingMore: fetchableStack?.isLoadingMore || false,
            data: rows,
        }
    })

    const { isLoading, isLoadingMore, data } = store

    useEffect(() => {
        if (fetchableStack) {
            return fetchableStack.onMount()
        }
    }, [fetchableStack])

    return {
        rows: data,
        isLoading,
        isLoadingMore,
    }
}

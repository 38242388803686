import { difference, differenceWith } from 'lodash'
import { endOfDay, parseISO, startOfDay } from 'date-fns'

import { FieldType } from '../metadata/metadata.types'

import type { FilterCondition, ConditionDateValue, Operator } from './filter-conditions-schema'
import { getDurationForConstant, isDateConstant } from './date-ranges'

const logger = console
logger.debug = () => {}
const assert = (check: boolean, msg: string) => {
    if (!check) {
        logger.warn(msg)
    }
}

export type FilterConditions = FilterCondition[]

type AddDiff = {
    right: FilterCondition
}
type RemoveDiff = {
    left: FilterCondition
}
type ChangeDiff = {
    left: FilterCondition
    right: FilterCondition
}
type FilterDiff = {
    added: Array<AddDiff>
    removed: Array<RemoveDiff>
    changed: Array<ChangeDiff>
}

export function canContainNewResults(previous: FilterConditions, current: FilterConditions) {
    if (previous.length === 0 && current.length === 0) {
        logger.debug('Both have no filters')
        return false
    }
    // if (previous.length > current.length) {
    //     return true
    // }
    const diff = diffFilters(previous, current)
    if (isFilterDiffEqual(diff)) {
        logger.debug('Filters are the same')
        return false
    }
    if (diff.changed.length === 0) {
        if (diff.removed.length === 0 && diff.added.length > 0) {
            // I only added conditions
            logger.debug('I only added conditions')
            return false
        } else if (diff.added.length === 0 && diff.removed.length > 0) {
            // In only removed conditions
            logger.debug(
                'I only removed conditions',
                'Removed',
                diff.removed.length,
                'Changed',
                diff.changed.length
            )
            return true
        } else if (diff.removed.length > 0) {
            logger.debug(
                'I removed a condition',
                diff.removed.length,
                'but also added',
                diff.added.length
            )
            return true
        }
    }
    for (const change of diff.changed) {
        if (didDiffConditionExtend(change.left, change.right)) {
            return true
        }
    }

    logger.debug('Did not find an extension statement')
    // If we didnt find anything that broadened it
    return false
}

export function isFilterEqual(left: FilterConditions, right: FilterConditions) {
    const diff = diffFilters(left, right)
    return isFilterDiffEqual(diff)
}

function isFilterDiffEqual(diff: FilterDiff) {
    return diff.added.length === 0 && diff.removed.length === 0 && diff.changed.length === 0
}

function diffFilters(left: FilterConditions, right: FilterConditions) {
    const diff: FilterDiff = {
        added: [],
        removed: [],
        changed: [],
    }
    left.forEach((l) => {
        const inRight = right.find((r) => isSameField(l, r))
        if (inRight && isConditionEqual(l, inRight)) {
            // all the same, its not a diff
        } else if (inRight) {
            const changedDiff: ChangeDiff = { left: l, right: inRight }
            diff.changed.push(changedDiff)
        } else {
            diff.removed.push({ left: l })
        }
    })
    right.forEach((r) => {
        const inLeft = left.find((l) => isSameField(r, l))
        if (!inLeft) {
            diff.added.push({ right: r })
        }
    })
    return diff
}

function isSameField(l: FilterCondition, r: FilterCondition) {
    return l.fieldName === r.fieldName
}

function isConditionEqual(a: FilterCondition, b: FilterCondition) {
    return (
        isSameField(a, b) &&
        a.operator === b.operator &&
        // Check this
        difference(a.values as string[], b.values as string[]).length === 0 &&
        difference(b.values as string[], a.values as string[]).length === 0
    )
}

function didDiffConditionExtend(left: FilterCondition, right: FilterCondition) {
    if (didOperatorExtend(left, right)) {
        logger.debug('OP extended')
        return true
    }
    if (didValueExtend(left, right)) {
        return true
    }
    logger.debug('Condition did not extend', left, right)
    return false
}

export function didOperatorExtend(left: FilterCondition, right: FilterCondition) {
    if (left.operator === right.operator) {
        return false
    }
    const gettingSpecific: Record<Operator, Operator[] | boolean> = {
        INCLUDES: ['EQ'],
        NEQ: ['EXCLUDES'],
        LTE: ['LT', 'EQ'],
        GTE: ['GT', 'EQ'],
        // No special cases
        EQ: false,
        EXCLUDES: false,
        LT: false,
        GT: false,
    }
    const sourceOperator: Array<Operator> | boolean = gettingSpecific[left.operator]
    if (sourceOperator) {
        const targets = sourceOperator as Array<Operator>
        if (targets.indexOf(right.operator) > -1) {
            return false
        }
        return true
    }
    return true
}

function didValueExtend(left: FilterCondition, right: FilterCondition) {
    switch (right.fieldType) {
        case 'datetime':
        case 'date':
            return didDateValueExtend(left, right)
        case 'picklist':
        case 'multipicklist':
        case 'reference':
            return didPicklistValueExtend(left, right)
        case 'double':
        case 'int':
        case 'currency':
        case 'percent':
            return didNumberValueExtend(left, right)
        case 'email':
        case 'textarea':
        case 'url':
        case 'phone':
        case 'string':
            return didStringValueExtend(left, right)
        default:
            logger.warn(`didValueExtend: could not find comperator for ${right.fieldType}`)
            return difference(right.values as string[], left.values as string[]).length > 0
    }
}

function didRawValuesExtend(left: FilterCondition, right: FilterCondition) {
    switch (right.operator) {
        case 'EQ': {
            const addedValues = difference(right.values as string[], left.values as string[])
            const canContainNew = addedValues.length > 0
            logger.debug(
                `EXTEND ${right.operator}: ${right.fieldType} ${
                    canContainNew ? 'extended' : 'did not extend'
                } `,
                'left',
                left.values,
                'right',
                right.values,
                'added',
                addedValues
            )
            return canContainNew
        }
        case 'NEQ': {
            const removedValues = difference(left.values as string[], right.values as string[])
            const canContainNew = removedValues.length > 0
            logger.debug(
                `EXTEND ${right.operator}: ${right.fieldType} ${
                    canContainNew ? 'can have new' : 'nothing new'
                }`,
                'left',
                left.values,
                'right',
                right.values,
                'removed',
                removedValues
            )
            return canContainNew
        }
        default:
            logger.warn(`didRawValuesExtend(: missing logic for operator: ${right.operator}`)
            return true
    }
}

function didPicklistValueExtend(left: FilterCondition, right: FilterCondition) {
    return didRawValuesExtend(left, right)
}

function getSingleDateValueForRelativeComparison(
    left: FilterCondition,
    right: FilterCondition,
    operator: Operator
): [Date, Date] {
    const [rawLv, rawRv] = getSingleValues(left, right) as [ConditionDateValue, ConditionDateValue]

    const lv = getReferenceDate(rawLv, operator)
    const rv = getReferenceDate(rawRv, operator)
    return [lv, rv]
}

function getReferenceDate(d: ConditionDateValue | null, operator: Operator): Date {
    if (d && isDateConstant(d)) {
        const [start, end] = getDurationForConstant(d)
        if (['LT', 'LTE'].includes(operator)) {
            return start
        } else if (['GT', 'GTE'].includes(operator)) {
            return end
        }
    }
    if (d) {
        return parseISO(d)
    }
    assert(false, 'Trying to get a reference Date for neither a Date or a DateConstant')
    return new Date()
}

function getSingleValues(left: FilterCondition, right: FilterCondition) {
    assert(
        left.values.length === 1,
        `didValueExtend: multiple values with  ${left.operator} on ${left.fieldType}`
    )
    assert(
        right.values.length === 1,
        `didValueExtend: multiple values with  ${right.operator} on ${right.fieldType}`
    )
    const [lv] = left.values
    const [rv] = right.values
    return [lv, rv]
}

function didDateValueExtend(left: FilterCondition, right: FilterCondition) {
    const operator = right.operator
    switch (operator) {
        case 'GTE':
        case 'GT': {
            const [lv, rv] = getSingleDateValueForRelativeComparison(left, right, operator)
            const gtExtend = rv.getTime() < lv.getTime()
            gtExtend && logger.debug(`EXT Date: ${operator} and value decreased`, lv, rv)
            return gtExtend
        }
        case 'LT':
        case 'LTE': {
            const [lv2, rv2] = getSingleDateValueForRelativeComparison(left, right, operator)
            const ltExtend = lv2.getTime() < rv2.getTime()
            ltExtend && logger.debug(`EXT Date: ${right.operator} and value increased`, lv2, rv2)
            return ltExtend
        }
        case 'NEQ':
        case 'EQ':
            return didEqualCompareDateValuesExtend(left, right)
        default:
            logger.warn(`didDateValueExtend: missing logic for operator: ${right.operator}`)
            return true
    }
}

function didNumberValueExtend(left: FilterCondition, right: FilterCondition) {
    switch (right.operator) {
        case 'GTE':
        case 'GT': {
            const [lv, rv] = getSingleValues(left, right) as [number, number]
            const isIncrease = rv > lv
            const canContainNew = !isIncrease
            logger.debug(
                `Number: ${right.operator} and value ${isIncrease ? 'increased' : 'decraesed'}`,
                lv,
                rv,
                ` => ${canContainNew ? 'can contain new results' : 'can NOT contain new results'}`
            )
            return canContainNew
        }
        case 'LT':
        case 'LTE': {
            const [lv2, rv2] = getSingleValues(left, right) as [number, number]
            const isIncrease = rv2 > lv2
            const canContainNew = isIncrease
            logger.debug(
                `Number: ${right.operator} and value ${isIncrease ? 'increased' : 'increased'}`,
                lv2,
                rv2,
                ` => ${canContainNew ? 'can contain new results' : 'can NOT contain new results'}`
            )
            return canContainNew
        }
        case 'NEQ':
        case 'EQ':
            return didRawValuesExtend(left, right)
        default:
            logger.warn(`didNumberValueExtend: missing logic for operator: ${right.operator}`)
            return true
    }
}

function didStringValueExtend(left: FilterCondition, right: FilterCondition) {
    const leftValues = left.values as Array<string>
    const rightValues = right.values as Array<string>
    switch (right.operator) {
        case 'INCLUDES': {
            const includeDiffArray = stringDiffContains(rightValues, leftValues)
            const canContainNew = includeDiffArray.length > 0
            logger.debug(
                `EXT String: ${right.operator} and value changed`,
                'left',
                leftValues,
                'right',
                rightValues,
                '=',
                includeDiffArray,
                `=> ${canContainNew ? 'can containe new' : 'can NOT contain new'}`
            )
            return canContainNew
        }
        case 'EXCLUDES': {
            const excludeDiffArray = stringDiffContains(leftValues, rightValues)
            const canContainNew = excludeDiffArray.length > 0
            logger.debug(
                `EXT String: ${right.operator} and value changed`,
                'left',
                leftValues,
                'right',
                rightValues,
                '=',
                excludeDiffArray,
                `=> ${canContainNew ? 'can containe new' : 'can NOT contain new'}`
            )
            return canContainNew
        }
        case 'NEQ':
        case 'EQ':
            return didRawValuesExtend(left, right)
        default:
            logger.warn(`didStringValueExtend: missing logic for operator: ${right.operator}`)
            return true
    }
}

function didEqualCompareDateValuesExtend(left: FilterCondition, right: FilterCondition) {
    if (left.values.length !== 1 || right.values.length !== 1) {
        throw new Error('I think this code path does not make sense')
        // return compareDateArrays(left, right)
    }
    const fieldType = right.fieldType
    const operator = right.operator
    const lv = left.values[0] as ConditionDateValue
    const rv = right.values[0] as ConditionDateValue
    const [lStart, lEnd] = getDateBound(lv!, fieldType)
    const [rStart, rEnd] = getDateBound(rv!, fieldType)
    if (operator === 'EQ') {
        logger.debug(
            'Comparing single dates (EQ)',
            'Start',
            lStart,
            '->',
            rStart,
            'End',
            lEnd,
            '->',
            rEnd,
            'startEarlier',
            lStart.getTime() > rStart.getTime(),
            'endLater',
            lEnd.getTime() < rEnd.getTime()
        )
        return lStart.getTime() > rStart.getTime() || lEnd.getTime() < rEnd.getTime()
    } else if (operator === 'NEQ') {
        logger.debug(
            'Comparing single dates (NEQ)',
            'Start',
            lStart,
            '->',
            rStart,
            'End',
            lEnd,
            '->',
            rEnd,
            'startLater',
            lStart.getTime() < rStart.getTime(),
            'endEarlier',
            lEnd.getTime() > rEnd.getTime()
        )
        return lStart.getTime() < rStart.getTime() || lEnd.getTime() > rEnd.getTime()
    }
}

function getDateBound(d: ConditionDateValue, fieldType: FieldType): [Date, Date] {
    if (isDateConstant(d)) {
        return getDurationForConstant(d)
    } else if (fieldType === 'date') {
        return [startOfDay(parseISO(d)), endOfDay(parseISO(d))]
    }
    return [parseISO(d), parseISO(d)]
}

export function stringDiffContains(left: Array<string>, right: Array<string>) {
    return differenceWith(left, right, areStringsContained)
}

function areStringsContained(lString: string, rString: string) {
    return lString?.includes(rString)
}

import { useController, Path } from 'react-hook-form'
import { useEffect } from 'react'

// import {
//     AccountFilledIcon,
//     ContactFilledIcon,
//     LeadFilledIcon,
//     OpportunityFilledIcon,
// } from '@laserfocus/ui/icons'

import { Input } from '@laserfocus/ui/beam'
import { useReferencedDisplayName } from '@laserfocus/client/store-shared'
// import { FieldMetadata } from '@laserfocus/client/model'

import { FormControlProps, SalesObject } from '../get-form-control'

import { getControllerRules, getInputProps, useStateReducer } from './formcontrol-util'

interface FormControlStringProps<T extends SalesObject = SalesObject> extends FormControlProps<T> {
    // This is an extension to use this as a sub component of the Address FormControl
    name?: string
    placeholder?: string
}

export function FormControlReference<T extends SalesObject = SalesObject>({
    fieldMetadata,
    id,
    variant,
    control,
    submit,
    name,
    placeholder,
}: FormControlStringProps<T>) {
    const { field, fieldState } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })
    const [state, setState] = useStateReducer({
        isFocused: false,
        inputValue: field.value ? field.value.toString() : '',
    })
    const { name: displayName } = useReferencedDisplayName(field.value?.toString())

    useEffect(() => {
        if (!state.isFocused) {
            setState({ inputValue: field.value ? field.value.toString() : '' })
        }
    }, [field.value, setState, state.isFocused])
    // const icon = getInputButton(fieldMetadata, field.value?.toString())
    return (
        <Input
            id={id}
            variant={variant}
            placeholder={placeholder}
            {...getInputProps(field, fieldState, state, setState, submit, {
                formatDisplayValue: (v) => displayName || v,
                readOnly: true,
            })}
            readOnly
            disabled
            // button={icon ? <InputButton icon={icon} /> : undefined}
        />
    )
}

// function getInputButton(field: FieldMetadata, id?: string | null) {
//     if (isLead(id)) {
//         return LeadFilledIcon
//     } else if (isContact(id)) {
//         return ContactFilledIcon
//     } else if (isOpportunity(id)) {
//         return OpportunityFilledIcon
//     } else if (isAccount(id)) {
//         return AccountFilledIcon
//     } else if (field.isLeadReference) {
//         return LeadFilledIcon
//     } else if (field.isContactReference) {
//         return ContactFilledIcon
//     } else if (field.isOpportunityReference) {
//         return OpportunityFilledIcon
//     } else if (field.isAccountReference) {
//         return AccountFilledIcon
//     }
// }

import { useState } from 'react'
import { groupBy } from 'lodash'
import clsx from 'clsx'

import { StackModel } from '@laserfocus/client/model'
import { useStacks } from '@laserfocus/client/data-access-shared'
import { Button } from '@laserfocus/ui/beam'
import { AddOutlinedIcon, CollapseOutlinedIcon, ExpandOutlinedIcon } from '@laserfocus/ui/icons'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'
import { Stack } from '@laserfocus/shared/models'

import { ActivityCounterCard } from '../activity-counter/ActivityCounterCard'
import { CreateStackModal } from '../create-stack-modal/CreateStackModal'

import { TodayStackCard } from './today-stack-card/TodayStackCard'
import { OverdueCard } from './OverdueCard'
import { UpcomingCard } from './UpcomingCard'
import { StackCardContainer } from './StackCardContainer'
import { LoadingStackCard } from './LoadingStackCard'
import { StackSection } from './StackSection'

const MAX_CARDS_COLLAPSED = 6

export function StackOverview() {
    const [shouldShowAllCards, setShouldShowAllCards] = useState(false)
    const [showCreateStackModal, setShowCreateStackModal] = useState(false)
    const { didLoad, didPull, stacks } = useStacks()

    const { favorites, rest } = getStackGroups(stacks)

    const favoriteCards = favorites.map((stack) => (
        <StackCardContainer key={stack.id} stackId={stack.id} />
    ))

    const showLoadingStacks = !didLoad || (!didPull && !stacks.length)

    const restCards = [
        ...rest.presets.map((stack) => <StackCardContainer key={stack.id} stackId={stack.id} />),
        ...rest.custom.map((stack) => <StackCardContainer key={stack.id} stackId={stack.id} />),
    ]
    const displayedRestCards = shouldShowAllCards
        ? restCards
        : restCards.slice(0, MAX_CARDS_COLLAPSED)

    return (
        <>
            <CreateStackModal
                show={showCreateStackModal}
                closeModal={() => setShowCreateStackModal(false)}
            />
            <div className="grid gap-12">
                <div className={clsx('grid gap-6', 'grid-cols-3')}>
                    <TodayStackCard className={'col-span-3'} />
                    <OverdueCard key="overdue-card" />
                    <UpcomingCard key="upcoming-card" />
                    <ActivityCounterCard />
                </div>

                {favoriteCards.length > 0 && (
                    <StackSection title="Favorites">{favoriteCards}</StackSection>
                )}
                <StackSection
                    title="Stacks"
                    action={
                        <EverboardingHint
                            disable={showLoadingStacks}
                            name="everboarding_home_add_stack"
                            label="Import your lists from Salesforce"
                            placement="right"
                            containerClassName="-translate-y-7"
                            labelClassName="rotate-6 translate-y-2"
                        >
                            <Button
                                size="small"
                                variant="secondary"
                                iconComponent={AddOutlinedIcon}
                                onClick={() => setShowCreateStackModal(true)}
                            >
                                Add Stack
                            </Button>
                        </EverboardingHint>
                    }
                >
                    {showLoadingStacks ? (
                        <>
                            <LoadingStackCard />
                            <LoadingStackCard />
                            <LoadingStackCard />
                            <LoadingStackCard />
                        </>
                    ) : (
                        displayedRestCards
                    )}
                    {!showLoadingStacks && restCards.length > MAX_CARDS_COLLAPSED && (
                        <div className={clsx('grid place-items-center col-span-2')}>
                            <Button
                                variant="tertiary"
                                iconComponent={
                                    shouldShowAllCards ? CollapseOutlinedIcon : ExpandOutlinedIcon
                                }
                                onClick={() => setShouldShowAllCards((v) => !v)}
                            >
                                {shouldShowAllCards ? 'Collapse' : 'Show all'}
                            </Button>
                        </div>
                    )}
                </StackSection>
            </div>
        </>
    )
}

function getStackGroups(stacks: Stack[]) {
    const stacksByFavorite = groupBy(stacks, (e) => Boolean(e.isFavorite))
    const presets = stacksByFavorite.false?.filter((a) => StackModel.isPreset(a)) || []
    const custom = stacksByFavorite.false?.filter((a) => !StackModel.isPreset(a)) || []

    return {
        favorites: stacksByFavorite.true?.sort(stackSorter) ?? [],
        rest: {
            presets,
            custom,
        },
    }
}

interface PoorMansStack {
    title: string
}

function stackSorter(a: PoorMansStack, b: PoorMansStack) {
    return a.title.localeCompare(b.title)
}

import { JSONObject } from 'replicache'

export async function copyRequestWithQuery(req: Request, query: { modules: string[] }) {
    const qs = new URLSearchParams()
    query.modules.forEach((module) => qs.append('modules', module))

    const copy = await extendRequest(req, {
        queryString: qs.toString(),
    })
    return copy
}

type ExtendQueryProps = {
    queryString?: string
    /**
     * extends the body with additional json values
     */
    additionalBody?: Record<string, any>

    /**
     * Overwrites the body
     */
    body?: string
}

export async function extendRequest(
    urlOrReq: RequestInfo,
    extend: ExtendQueryProps
): Promise<Request> {
    // make sure we deal with a Request object even if we got a URL string
    const req = urlOrReq instanceof Request ? urlOrReq : new Request(urlOrReq)

    const { queryString, additionalBody, body: overWriteBody } = extend

    const {
        cache,
        credentials,
        headers,
        integrity,
        method,
        mode,
        redirect,
        referrer,
        referrerPolicy,
        url,
    } = req

    const inputBody = await req.text()

    // Don't add query string if there's none
    const urlWithQuery = url + (!queryString ? '' : '?' + queryString)

    const body = overWriteBody || extendBody(inputBody, additionalBody)

    return new Request(urlWithQuery, {
        cache,
        credentials,
        headers,
        integrity,
        method,
        mode,
        redirect,
        referrer,
        referrerPolicy,
        body,
    })
}

function extendBody(inputBody: string, additionalBody?: JSONObject) {
    if (!additionalBody) {
        return inputBody
    }
    const parsedInput = JSON.parse(inputBody)
    return JSON.stringify({
        ...parsedInput,
        ...additionalBody,
    })
}

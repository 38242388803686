import { forwardRef } from 'react'

import { Input, InputProps } from '../../input/input'

export const DropdownBaseInput = forwardRef<HTMLDivElement, InputProps>(function DropdownBaseInput(
    props,
    ref
) {
    return (
        <div ref={ref} className="w-full">
            <Input
                readOnly
                placeholder={props.forceFocused ? '—' : undefined}
                {...props}
                inputClassName={props.disabled ? undefined : 'cursor-pointer'}
            />
        </div>
    )
})

import produce from 'immer'

import { FailedMutation } from '@laserfocus/shared/models'
import { assert } from '@laserfocus/ui/logger'
import { captureException } from '@laserfocus/ui/error-reporting'
import { BaseError } from '@laserfocus/shared/util-error'

import { depthFirst } from './traverse'

export type FailedMutationReducerState = {
    dispatched: Array<number>
    nodes: Record<string, ErrorContextNode>
    failedByNode: Record<string, Record<number, FailedMutation>>
}
export type FailedMutationFilter = (f: FailedMutation) => boolean

export interface ErrorContextNode {
    name: string
    parent?: string
    filter?: FailedMutationFilter
}

interface ErrorMutationsLoaded {
    type: 'ErrorMutationsLoaded'
    added: FailedMutation[]
    removed: number[]
}
interface ErrorContextNodeAdded {
    type: 'ErrorContextNodeAdded'
    node: ErrorContextNode
}
interface ErrorContextNodeUnmounted {
    type: 'ErrorContextNodeUnmounted'
    nodeName: string
}

interface DiscardFailedMutation {
    type: 'DiscardFailedMutation'
    mutationId: number
}

export const InitialReducerState: FailedMutationReducerState = {
    dispatched: [],
    failedByNode: {},
    nodes: {
        root: {
            name: 'root',
        },
    },
}

export type MutationErrorAction =
    | ErrorMutationsLoaded
    | ErrorContextNodeAdded
    | ErrorContextNodeUnmounted
    | DiscardFailedMutation

export function mutationErrorReducer(
    state: FailedMutationReducerState,
    action: MutationErrorAction
) {
    switch (action.type) {
        case 'ErrorMutationsLoaded': {
            const nodeList = Object.values(state.nodes)
            return produce(state, (draft) => {
                const missingMutations = action.added.filter(
                    (m) => !state.dispatched.includes(m.mutationId)
                )
                for (const mutation of missingMutations) {
                    const results = depthFirst({
                        root: state.nodes['root'],
                        getChildren(node: ErrorContextNode) {
                            return nodeList.filter((a) => a.parent === node.name)
                        },
                        match(node) {
                            return matchNode(node, mutation)
                        },
                        limit: 1,
                    })
                    if (results.length === 1) {
                        const target = results[0]!.name
                        if (!draft.failedByNode[target]) {
                            draft.failedByNode[target] = {}
                        }
                        draft.failedByNode[target][mutation.mutationId] = mutation
                        draft.dispatched.push(mutation.mutationId)
                    } else {
                        captureException(
                            new FailedMutationContextNotFoundError(
                                'Failedmutation Context not found',
                                {
                                    nodes: state.nodes,
                                    mutation,
                                    results,
                                }
                            )
                        )
                    }
                }
                const failedNodeNames = Object.keys(state.failedByNode)
                for (const nodeName of failedNodeNames) {
                    const nodeFailed = draft.failedByNode[nodeName]
                    for (const deletedMutationId of action.removed) {
                        if (nodeFailed[deletedMutationId]) {
                            nodeFailed[deletedMutationId].deleted = true
                        }
                    }
                }
            })
        }
        case 'ErrorContextNodeAdded': {
            return produce(state, (draft) => {
                draft.nodes[action.node.name] = action.node
            })
        }
        case 'ErrorContextNodeUnmounted': {
            return produce(state, (draft) => {
                delete draft.nodes[action.nodeName]
                delete draft.failedByNode[action.nodeName]
            })
        }
        case 'DiscardFailedMutation': {
            return produce(state, (draft) => {
                const nodesWithErrors = Object.keys(state.failedByNode)
                nodesWithErrors.forEach((nodeName) => {
                    delete draft.failedByNode[nodeName][action.mutationId]
                })
            })
        }
        default:
            if (process.env.NODE_ENV === 'development') {
                throw new Error(`Dont know action of type ${(action as any).type}`)
            }
            return state
    }
}

function matchNode(node: ErrorContextNode, mutation: FailedMutation) {
    if (node.filter) {
        const matched = node.filter(mutation)
        return matched
    }
    return !node.filter
}

class FailedMutationContextNotFoundError extends BaseError {
    isOperational = false
}

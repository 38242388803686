import { useCallback, useEffect, useMemo } from 'react'
import { useForm, UseFormWatch, UseFormSetValue, FormProvider } from 'react-hook-form'
import { useSubscribe } from 'replicache-react'

import {
    PicklistValue,
    AccountModel,
    ContactModel,
    OpportunityModel,
    ConvertLeadMutationInput,
    Prefix,
    LeadModel,
    FailedMutation,
} from '@laserfocus/shared/models'
import { Button, Modal, SpinnerInline } from '@laserfocus/ui/beam'
import { AddOutlinedIcon, ExclamationOutlinedIcon } from '@laserfocus/ui/icons'
import { mutateSObject } from '@laserfocus/client/data-access-shared'
import { getClient } from '@laserfocus/client/replicache'
import { FailedMutationScope, useFailedMutationScope } from '@laserfocus/client/shared-error'

import { useLead } from '../modules/PersonContext'

import { ConvertSectionBody, useLeadStatusPicklist } from './ConvertSection'
import { AccountSectionBody } from './AccountSection'
import { ContactSectionBody, useContactDuplicateForUpdates } from './ContactSection'
import { OpportunitySectionBody } from './OpportunitySection'
import { ConvertedSection } from './ConvertedSection'
import { FailedConvertMutation, getDuplicateRecords, hasDuplicateError } from './duplicate-utils'
import { LoadingConvertBody } from './LoadingConvertBody'

export function ConvertLeadModal({ show, closeModal }: { show: boolean; closeModal: () => void }) {
    return (
        <Modal show={show} onClose={closeModal}>
            <ConvertLeadModalBody closeModal={closeModal} />
        </Modal>
    )
}

function ConvertLeadModalBody({ closeModal }: { closeModal: () => void }) {
    const lead = useLead()

    const { isLoading, convertedStatusOptions } = useLeadStatusPicklist(lead?.RecordTypeId)

    const isReady = !isLoading && lead
    const title = lead?.IsConverted ? `Lead was converted` : `Convert ${lead?.Name || ''}`

    const filterFailedMutation = useCallback(
        (f: FailedMutation) => {
            return Boolean(
                lead?.Id && ['convertLead'].includes(f.mutation.name) && f.target?.id === lead.Id
            )
        },
        [lead?.Id]
    )

    return (
        <FailedMutationScope name="ConvertLeadModal" filter={filterFailedMutation}>
            <Modal.Overlay />
            <Modal.Container variableHeight className="w-[36rem]">
                <Modal.Header close={closeModal}>{title}</Modal.Header>
                {lead?.IsConverted ? (
                    <ConvertedSection lead={lead} closeModal={closeModal} />
                ) : isReady ? (
                    <ConvertLeadForm convertedStatusOptions={convertedStatusOptions} lead={lead} />
                ) : (
                    <LoadingConvertBody />
                )}
            </Modal.Container>
        </FailedMutationScope>
    )
}

export type ConvertLeadFormState = {
    ownerId?: string
    convertedStatus: string
    accountCreateOrChoose: 'create' | 'choose'
    // newAccount?: Partial<AccountModel>
    existingAccount?: {
        Id: string
        Name?: string
    }
    contactCreateOrChoose: 'create' | 'choose'
    // newContact?: Partial<ContactModel>
    existingContact?: {
        Id: string
        Name?: string
        AccountId?: string
        AccountName?: string
    }
    opportunityCreateOrChoose: 'create' | 'choose' | 'none'
    existingOpportunity?: {
        Id: string
        Name?: string
        AccountId?: string
        AccountName?: string
    }
    newOpportunity?: Pick<OpportunityModel, 'Name'>
}

function ConvertLeadForm({
    convertedStatusOptions,
    lead,
}: {
    convertedStatusOptions: PicklistValue[]
    lead: LeadModel
}) {
    const leadId = lead.Id
    const { failedMutations } = useFailedMutationScope()

    const failedMutation = failedMutations.find((a) => !a.deleted)

    const showduplicateSection = hasDuplicateError(failedMutation)
    const offset = failedMutation ? 1 : 0

    const isProcessing = useSubscribe(
        getClient(),
        async (tx) => {
            const process = await tx.get(`process/convertLead/${leadId}`)
            return Boolean(process)
        },
        false,
        [leadId]
    )

    const form = useForm<ConvertLeadFormState>({
        mode: 'onBlur',
        defaultValues: {
            ownerId: lead.OwnerId,
            convertedStatus: convertedStatusOptions[0]?.value,
            accountCreateOrChoose: 'create',
            // newAccount: {
            //     Name: lead.Company,
            //     OwnerId: lead.OwnerId,
            // },
            contactCreateOrChoose: 'create',
            // newContact: {
            //     FirstName: lead.FirstName,
            //     LastName: lead.LastName,
            //     OwnerId: lead.OwnerId,
            // },
            opportunityCreateOrChoose: 'create',
            newOpportunity: {
                Name: `${lead.Company} Opportunity`,
            },
        },
    })
    const { control, handleSubmit, formState, watch, setValue } = form
    const { isSubmitting } = formState

    useFormDependentValues(watch, setValue)

    async function submit(data: ConvertLeadFormState) {
        const parsed = parseForm(data, lead)
        // console.log(parsed)
        await mutateSObject.convertLead(parsed)
    }

    const contactDuplicates = useMemo(
        () => getDuplicateRecords<ContactModel>('Contact', failedMutation),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [failedMutation?.mutationId]
    )
    const accountDuplicates = useMemo(
        () => getDuplicateRecords<AccountModel>('Account', failedMutation),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [failedMutation?.mutationId]
    )
    useContactDuplicateForUpdates(watch, setValue, contactDuplicates)

    return (
        <FormProvider {...form}>
            <div className="max-h-[50vh] overflow-auto">
                {failedMutation && (
                    <>
                        <Modal.StickyHeading
                            amountOfHeadings={4 + offset}
                            headingIndex={0}
                            titleClassName="text-red-500"
                        >
                            {showduplicateSection ? 'Duplicates detected' : 'Error during convert'}
                        </Modal.StickyHeading>
                        <ErrorBody failedMutation={failedMutation} />
                    </>
                )}
                <Modal.StickyHeading amountOfHeadings={4 + offset} headingIndex={offset}>
                    Convert
                </Modal.StickyHeading>
                <ConvertSectionBody control={control} options={convertedStatusOptions} />
                <Modal.StickyHeading amountOfHeadings={4 + offset} headingIndex={1 + offset}>
                    <div className="flex items-center">
                        Account
                        {accountDuplicates.length ? (
                            <ExclamationOutlinedIcon className="h-4 w-4 mx-2 rounded-md text-red-500" />
                        ) : (
                            ''
                        )}
                    </div>
                </Modal.StickyHeading>
                <AccountSectionBody
                    control={control}
                    duplicates={accountDuplicates}
                    watch={watch}
                />
                <Modal.StickyHeading amountOfHeadings={4 + offset} headingIndex={2 + offset}>
                    <div className="flex items-center">
                        Contact
                        {contactDuplicates.length ? (
                            <ExclamationOutlinedIcon className="h-4 w-4 mx-2 rounded-md text-red-500" />
                        ) : (
                            ''
                        )}
                    </div>
                </Modal.StickyHeading>
                <ContactSectionBody control={control} duplicates={contactDuplicates} />
                <Modal.StickyHeading amountOfHeadings={4 + offset} headingIndex={3 + offset}>
                    Opportunity
                </Modal.StickyHeading>
                <OpportunitySectionBody control={control} watch={watch} />
            </div>
            <Modal.Footer border>
                <Button
                    type="submit"
                    variant="primary"
                    iconComponent={isSubmitting || isProcessing ? SpinnerInline : AddOutlinedIcon}
                    disabled={isSubmitting || isProcessing}
                    className="justify-self-end"
                    onClick={handleSubmit(submit)}
                >
                    {`Convert`}
                </Button>
            </Modal.Footer>
        </FormProvider>
    )
}

function ErrorBody({ failedMutation }: { failedMutation: FailedConvertMutation }) {
    const isDuplicateError = hasDuplicateError(failedMutation)
    if (isDuplicateError) {
        return (
            <div className="py-4 px-8">You can't convert because we found duplicate Records.</div>
        )
    }
    return (
        <div className="py-4 px-8">
            <ul className="p-0 text-red-500 text-sm leading-normal grid gap-1">
                {failedMutation.formErrors.map((err) => (
                    <li key={err}>{err}</li>
                ))}
            </ul>
        </div>
    )
}

export function parseForm(
    data: ConvertLeadFormState,
    lead: Partial<LeadModel> & { Id: string }
): ConvertLeadMutationInput {
    return {
        convert: {
            leadId: lead.Id,
            convertedStatus: data.convertedStatus,
            accountId:
                data.accountCreateOrChoose === 'choose' ? data.existingAccount?.Id : undefined,
            contactId:
                data.contactCreateOrChoose === 'choose' ? data.existingContact?.Id : undefined,
            doNotCreateOpportunity: ['choose', 'none'].includes(data.opportunityCreateOrChoose),
            opportunityName:
                data.opportunityCreateOrChoose === 'create' ? data.newOpportunity?.Name : undefined,
            ownerId: data.ownerId,
        },
    }
}

function useFormDependentValues(
    watch: UseFormWatch<ConvertLeadFormState>,
    setValue: UseFormSetValue<ConvertLeadFormState>
) {
    const [opp, contact, contactChooseOrCreate] = watch([
        'existingOpportunity',
        'existingContact',
        'contactCreateOrChoose',
    ])

    useEffect(() => {
        if (opp && opp.AccountId) {
            if (opp.AccountName) {
                setValue('existingAccount', {
                    Id: opp.AccountId,
                    Name: opp.AccountName,
                })
                setValue('accountCreateOrChoose', 'choose')
            } else {
                getClient()
                    .query(
                        (tx) =>
                            tx.get(
                                [Prefix.Account, opp.AccountId].join('/')
                            ) as Promise<AccountModel | null>
                    )
                    .then((account) => {
                        if (account) {
                            setValue('existingAccount', {
                                Id: opp.AccountId!,
                                Name: account.Name,
                            })
                            setValue('accountCreateOrChoose', 'choose')
                        }
                    })
            }
        }
    }, [setValue, opp])
    useEffect(() => {
        if (contact && contact.AccountId) {
            if (contact.AccountName) {
                setValue('existingAccount', {
                    Id: contact.AccountId,
                    Name: contact.AccountName,
                })
                setValue('accountCreateOrChoose', 'choose')
            } else {
                getClient()
                    .query(
                        (tx) =>
                            tx.get(
                                [Prefix.Account, contact.AccountId].join('/')
                            ) as Promise<AccountModel | null>
                    )
                    .then((account) => {
                        if (account) {
                            setValue('existingAccount', {
                                Id: contact.AccountId!,
                                Name: account.Name,
                            })
                            setValue('accountCreateOrChoose', 'choose')
                        }
                    })
            }
        }
    }, [setValue, contact])

    useEffect(() => {
        if (contactChooseOrCreate === 'choose') {
            setValue('accountCreateOrChoose', 'choose')
        }
    }, [setValue, contactChooseOrCreate])
}

interface FilterSectionProps {
    label: string
    children: React.ReactNode
}

export function FilterSection({ label, children }: FilterSectionProps) {
    return (
        <div className="grid gap-2">
            <h2 className="text-xs leading-[1.2] font-medium text-white/60">{label}</h2>
            {children}
        </div>
    )
}

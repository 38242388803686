import clsx from 'clsx'

import { useScrolledIntoViewRef } from '@laserfocus/ui/util-react'

import { Dropdown, DropdownProps } from '../Dropdown/Dropdown'
import { DropdownButton } from '../controls/DropdownButton'
import { Button } from '../../button/Button'

import { SearchableList, SearchKey } from './SearchableList'
import { useDropdownKeyboardNavigation } from './useDropdownKeyboardNavigation'
import { Size, SizeContainer } from './SizeContainer'

type OptionValue = string | number
export type Option<T extends Partial<StaticOption> = Partial<StaticOption>> = T & StaticOption
interface StaticOption {
    value: OptionValue
    label: string | React.ReactNode
    iconComponent?: React.ComponentType<{ className?: string }>
    iconPosition?: 'left' | 'right'
    iconProps?: IconProps
    className?: string
    asideAction?: {
        iconComponent: React.ComponentType<{ className?: string }>
        title: string
        onClick(): void
    }
}

export interface SelectProps<T extends Partial<StaticOption> = {}>
    extends Omit<DropdownProps, 'content'>,
        SelectInnerProps<T> {}

export function Select<T extends Partial<StaticOption>>({
    options,
    initialOptionValue,
    searchKeys,
    size,
    bottomAction,
    fontSize,
    onSubmit,
    renderInput,
    ...props
}: SelectProps<T>) {
    return (
        <Dropdown
            {...props}
            content={
                <SelectInner<T>
                    options={options}
                    initialOptionValue={initialOptionValue}
                    searchKeys={searchKeys}
                    size={props.syncWidth ? 'unset' : size}
                    bottomAction={bottomAction}
                    fontSize={fontSize}
                    onSubmit={onSubmit}
                    renderInput={renderInput}
                />
            }
        />
    )
}

interface SelectInnerProps<T extends Partial<StaticOption>>
    extends Omit<
        OptionsListProps<T>,
        'allOptions' | 'filteredOptions' | 'variableHeightElementRef'
    > {
    options: readonly Option<T>[]
    searchKeys?: SearchKey<Option<T>>[]
    size?: Size
    renderInput?({
        searchValue,
        setSearchValue,
    }: {
        searchValue: string
        setSearchValue: React.Dispatch<React.SetStateAction<string>>
    }): React.ReactNode
}

function SelectInner<T extends Partial<StaticOption>>({
    options,
    searchKeys,
    size,
    renderInput,
    ...props
}: SelectInnerProps<T>) {
    const content = searchKeys ? (
        <SearchableList searchKeys={searchKeys} elements={options} renderInput={renderInput}>
            {(filteredOptions, searchValue) => (
                <OptionsList<T>
                    {...props}
                    allOptions={options}
                    filteredOptions={filteredOptions}
                    searchValue={searchValue}
                />
            )}
        </SearchableList>
    ) : (
        <OptionsList<T> {...props} allOptions={options} filteredOptions={options} />
    )

    return <SizeContainer size={size}>{content}</SizeContainer>
}

interface OptionsListProps<T extends Partial<StaticOption>> {
    allOptions: readonly Option<T>[]
    filteredOptions: readonly Option<T>[]
    initialOptionValue?: OptionValue
    bottomAction?: {
        label: string
        onClick(): void
    }
    fontSize?: 14 | 16
    searchValue?: string
    onSubmit?(option: Option<T>): void
}

function OptionsList<T extends Partial<StaticOption>>({
    allOptions,
    filteredOptions,
    initialOptionValue,
    bottomAction,
    fontSize,
    searchValue,
    onSubmit,
}: OptionsListProps<T>) {
    const { hoveredOptionIndex, setHoveredOptionIndex } = useDropdownKeyboardNavigation({
        optionsLength: filteredOptions.length + (bottomAction ? 1 : 0),
        resetKey: searchValue,
        submit: (index) => {
            if (bottomAction && index === filteredOptions.length) {
                bottomAction.onClick()
            } else {
                onSubmit?.(filteredOptions[index]!)
            }
        },
    })
    const hoveredOptionRef = useScrolledIntoViewRef<HTMLDivElement>(hoveredOptionIndex)

    const results =
        filteredOptions.length === 0 ? (
            <div className="p-2 text-white/60 text-center" style={{ fontSize: fontSize ?? 14 }}>
                {allOptions.length === 0 ? 'No options' : 'Nothing found'}
            </div>
        ) : (
            <div role="listbox" className="p-2 max-h-96 overflow-y-auto grid gap-1">
                {filteredOptions.map((option, index) => {
                    const { value, label, iconComponent, className, iconPosition, iconProps } =
                        option
                    const isHovered = index === hoveredOptionIndex
                    const isHighlighted = value === initialOptionValue
                    const setIsHovered = () => setHoveredOptionIndex(index)

                    return (
                        <div key={value} className="-mb-2">
                            <div
                                ref={isHovered ? hoveredOptionRef : undefined}
                                className="relative pb-2"
                            >
                                <DropdownButton
                                    isHighlighted={isHighlighted}
                                    onClick={() => onSubmit?.(option)}
                                    noHoverStyles
                                    className={clsx(
                                        'transition-none group',
                                        isHovered &&
                                            (isHighlighted ? 'bg-blue-700' : 'bg-white/10'),
                                        fontSize && (fontSize === 14 ? 'text-sm' : 'text-base'),
                                        iconPosition === 'right' && 'pr-1.5',
                                        className
                                    )}
                                    onMouseMove={setIsHovered}
                                    onFocus={setIsHovered}
                                >
                                    <DropdownButtonLabel
                                        iconComponent={iconComponent}
                                        label={label}
                                        iconPosition={iconPosition}
                                        isHovered={isHovered}
                                        iconProps={iconProps}
                                    />
                                </DropdownButton>
                                {option.asideAction && (
                                    <Button
                                        variant="quaternary"
                                        size="small"
                                        className="absolute right-px top-px rounded-[0.3125rem]"
                                        iconClassName="text-white/30 justify-self-center"
                                        onMouseOver={setIsHovered}
                                        {...option.asideAction}
                                    />
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        )

    return (
        <>
            {results}
            {bottomAction
                ? (() => {
                      const isHovered = filteredOptions.length === hoveredOptionIndex
                      const setIsHovered = () => setHoveredOptionIndex(filteredOptions.length)

                      return (
                          <div className="py-2 mx-2 border-t border-white/10">
                              <Button
                                  variant="quaternary"
                                  size="small"
                                  className={clsx(
                                      'w-full',
                                      isHovered
                                          ? 'text-white bg-white/10 hover:bg-white/10'
                                          : 'text-white/60 hover:bg-transparent'
                                  )}
                                  onClick={bottomAction.onClick}
                                  onMouseMove={setIsHovered}
                                  onFocus={setIsHovered}
                              >
                                  {bottomAction.label}
                              </Button>
                          </div>
                      )
                  })()
                : null}
        </>
    )
}

type IconProps = {
    title?: string
    className?: string
}

function DropdownButtonLabel({
    label,
    iconComponent: Icon,
    iconPosition,
    isHovered,
    iconProps,
}: {
    label: React.ReactNode
    iconComponent?: React.ComponentType<{ className?: string; title?: string }>
    iconPosition?: 'left' | 'right'
    iconProps?: IconProps
    isHovered: boolean
}) {
    if (Icon) {
        const { className: iconClassName, ...restIcon } = iconProps || {}
        return (
            <div
                className={clsx(
                    'grid grid-flow-col items-center  gap-[6px]',
                    iconPosition === 'right' ? ' grid-cols-[1fr,auto]' : ' grid-cols-[auto,1fr]'
                )}
            >
                {iconPosition !== 'right' && (
                    <Icon
                        className={clsx(
                            'w-4 h-4 self-start mt-[0.125rem]',
                            !isHovered && 'opacity-60',
                            iconClassName
                        )}
                        {...restIcon}
                    />
                )}
                {typeof label === 'string' ? <span>{label}</span> : label}
                {iconPosition === 'right' && (
                    <Icon
                        className={clsx(
                            'w-4 h-4 self-start mt-[0.125rem]',
                            !isHovered && 'opacity-60',
                            iconClassName
                        )}
                        {...restIcon}
                    />
                )}
            </div>
        )
    }
    return <>{label}</>
}

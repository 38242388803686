import { useMemo } from 'react'

import { Button, CompactDatePicker } from '@laserfocus/ui/beam'
import { CheckOutlinedIcon, RescheduleOutlinedIcon } from '@laserfocus/ui/icons'
import {
    getActivityRescheduleMessage,
    getMutationErrorMessage,
} from '@laserfocus/client/util-formatter'
import { toast } from '@laserfocus/ui/beam'
import { Analytics } from '@laserfocus/client/util-analytics'
import { NewActivity, NewTask, shouldShowErrorMessage, TaskId } from '@laserfocus/shared/models'
import { ActivityUtil } from '@laserfocus/client/model'
import { logger } from '@laserfocus/ui/logger'

interface TaskActionsActionProps {
    isDatepickerOpen: boolean
    openDatepicker(): void
    closeDatepicker(): void
    completeTask(id: TaskId): Promise<void>
    activity: NewActivity
    reschedule(date: Date): Promise<unknown>
}

export function TaskActions({
    isDatepickerOpen,
    closeDatepicker,
    openDatepicker,
    activity,
    completeTask,
    reschedule,
}: TaskActionsActionProps) {
    return (
        <>
            {ActivityUtil.isTask(activity) && (
                <CompleteButton activity={activity} completeTask={completeTask} />
            )}
            <RescheduleButton
                activity={activity}
                isDatepickerOpen={isDatepickerOpen}
                openDatepicker={openDatepicker}
                closeDatepicker={closeDatepicker}
                reschedule={reschedule}
            />
        </>
    )
}

interface CompleteButtonProps {
    activity: NewTask
    completeTask(id: TaskId): Promise<void>
}

function CompleteButton({ activity, completeTask }: CompleteButtonProps) {
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()

        completeTask(activity.Id).then(
            () => {
                const rootName = activity.Who?.Name || activity.Account?.Name
                const msg = rootName
                    ? `Successfully completed ${activity.Subject}`
                    : `Successfully completed ${activity.Subject} from ${rootName}`
                toast.success({ title: msg })
                Analytics.trackEvent({
                    event: 'activity_completed',
                    location: Analytics.getLocation() as any,
                })
            },
            (error: unknown) => {
                if (shouldShowErrorMessage(error)) {
                    logger.warn(error)
                    toast.error(getMutationErrorMessage(error))
                } else {
                    logger.error(error)
                    toast.error({ title: 'Something went wrong' })
                }
            }
        )
    }

    return (
        <Button<'button'>
            iconComponent={CheckOutlinedIcon}
            title="Complete task"
            onClick={handleClick}
        />
    )
}

interface RescheduleButtonProps {
    activity: NewActivity
    className?: string
    isDatepickerOpen: boolean
    openDatepicker(): void
    closeDatepicker(): void
    reschedule(date: Date): Promise<unknown>
}

function RescheduleButton({
    activity,
    className,
    isDatepickerOpen,
    openDatepicker,
    closeDatepicker,
    reschedule,
}: RescheduleButtonProps) {
    function handleDateSubmit(date: Date) {
        closeDatepicker()
        reschedule(date).then(
            () => {
                const rootName = activity.Who?.Name || activity.Account?.Name
                if (date && rootName) {
                    toast.success(getActivityRescheduleMessage(date, rootName))
                }
                Analytics.trackEvent({
                    event: 'activity_rescheduled',
                    location: Analytics.getLocation() as any,
                })
            },
            (e: Error) => {
                logger.error(e)
                toast.error({ title: 'Something went wrong' })
            }
        )
    }

    const suggestOptions = useMemo(() => ({ allow_past_dates: false, min_date: new Date() }), [])

    return (
        <div onClick={(event) => isDatepickerOpen && event.stopPropagation()}>
            <CompactDatePicker
                isOpen={isDatepickerOpen}
                initialDate={ActivityUtil.getDate(activity)}
                onDateSubmit={handleDateSubmit}
                onCancel={closeDatepicker}
                suggestOptions={suggestOptions}
            >
                <div className={className}>
                    <Button<'button'>
                        title="Reschedule task"
                        iconComponent={RescheduleOutlinedIcon}
                        onClick={(event) => {
                            event.stopPropagation()
                            openDatepicker()
                        }}
                    />
                </div>
            </CompactDatePicker>
        </div>
    )
}

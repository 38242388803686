import clsx from 'clsx'
import { forwardRef } from 'react'

import { ButtonProps, Button } from '@laserfocus/ui/beam'

export interface ToolbarButtonProps extends Exclude<ButtonProps, 'className'> {
    icon: React.ComponentType<{ className?: string }>
    title: string
    isActive?: boolean
    iconClassName?: string
    disabled?: boolean
}

export const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
    function ToolbarButton({ icon, isActive, ...props }, ref) {
        return (
            <Button
                ref={ref as any}
                variant="tertiary"
                size="small"
                iconComponent={icon}
                className={clsx('flex-none', isActive && 'bg-grey-700/10 hover:bg-grey-700/10')}
                {...props}
            />
        )
    }
)

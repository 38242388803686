import { useState, useCallback } from 'react'

import { Button, Modal } from '@laserfocus/ui/beam'
import { ImportViewBody } from '@laserfocus/client/feature-stacks'
import { useStacks } from '@laserfocus/client/data-access-shared'

export function ImportFromSalesforceButton() {
    const [show, setIsShow] = useState(false)
    const closeModal = useCallback(() => {
        setIsShow(false)
    }, [])
    return (
        <>
            <Button onClick={() => setIsShow(true)}>Import list from Salesforce</Button>
            <Modal show={show} onClose={closeModal}>
                <ImportFromSalesforceModalInner closeModal={closeModal} />
            </Modal>
        </>
    )
}

function ImportFromSalesforceModalInner({ closeModal }: { closeModal: () => void }) {
    const { stacks } = useStacks()
    return (
        <>
            <Modal.Overlay />
            <Modal.Container variableHeight>
                <Modal.Header close={closeModal}>Import List from Salesforce</Modal.Header>

                <ImportViewBody stacks={stacks} />
            </Modal.Container>
        </>
    )
}

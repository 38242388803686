import { FilterInputProps } from './FilterInput/types'
import { FilterInput } from './FilterInput'

export const textInputs = ['string', 'id', 'url', 'textarea', 'phone', 'email'] as const

export function getFilterInput(props: FilterInputProps) {
    const { type } = props.column

    if (textInputs.includes(type as typeof textInputs[number])) {
        if (['EQ', 'NEQ'].includes(props.operator)) {
            return <FilterInput.Picklist {...props} />
        }
        return <FilterInput.String {...props} />
    }

    if (type === 'boolean') {
        return <FilterInput.Boolean {...props} />
    }

    if (['date', 'datetime'].includes(type)) {
        return <FilterInput.Date {...props} />
    }

    if (['picklist', 'multipicklist'].includes(type)) {
        return <FilterInput.Picklist {...props} />
    }

    if (['int', 'double', 'currency', 'percent'].includes(type)) {
        return <FilterInput.Number {...props} />
    }

    if (type === 'reference') {
        if (props.column.isReferenceList) {
            return <FilterInput.Picklist {...props} />
        }
        return <FilterInput.String {...props} />
    }

    if (type === 'time') {
        return <FilterInput.Time {...props} />
    }

    return null
}

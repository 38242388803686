import { Modal } from '@laserfocus/ui/beam'

export function ResolvedSection({
    title,
    closeAndDiscardUnsaved,
    sectionIndex,
    amountOfSections,
    hasNext,
    next,
}: {
    title: string
    closeAndDiscardUnsaved: () => void
    sectionIndex: number
    amountOfSections: number
    hasNext: boolean
    next?: () => void
}) {
    return (
        <>
            <Modal.StickyHeading headingIndex={sectionIndex} amountOfHeadings={amountOfSections}>
                {title}
            </Modal.StickyHeading>
            <div className="grid gap-4 py-4 px-8">
                <p className="text-sm">
                    {'Saved successfully. '}
                    {hasNext && next ? (
                        <button
                            onClick={next}
                            className="p-0.5 -m-0.5 rounded-[0.25rem] underline outline-none transition cursor-pointer hover:text-grey-700/70 focus-visible:ring"
                        >
                            Next
                        </button>
                    ) : (
                        <button
                            onClick={closeAndDiscardUnsaved}
                            className="p-0.5 -m-0.5 rounded-[0.25rem] underline outline-none transition cursor-pointer hover:text-grey-700/70 focus-visible:ring"
                        >
                            Close
                        </button>
                    )}
                </p>
            </div>
        </>
    )
}

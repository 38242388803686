import { forwardRef, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { LockOutlinedIcon, ExclamationOutlinedIcon } from '@laserfocus/ui/icons'
import { useAutoFocusedRef, useForkRef } from '@laserfocus/ui/util-react'

export const INPUT_BORDER_RADIUS_STYLES = 'rounded-md'
const EM_DASH = '—'

type InputVariant = 'ghost' | 'border'
type Size = 'medium' | 'large'

interface VariantElementsStyles {
    container?: string
    containerHover?: string
}

const VARIANT_STYLES: Record<InputVariant, VariantElementsStyles> = {
    ghost: {
        containerHover: /*tw:*/ 'hover:bg-grey-700/5',
    },
    border: {
        container: /*tw:*/ 'bg-white ring-1 ring-grey-700/20',
        containerHover: /*tw:*/ 'hover:ring-1 hover:ring-grey-700/20',
    },
}

interface SizeElementsStyles {
    input: string
    container: string
    icon: string
    exclamationIcon: string
    textBottom: string
}

const SIZES_STYLES: Record<Size, SizeElementsStyles> = {
    medium: {
        // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
        input: /*tw:*/ 'font-medium text-sm leading-[1.45]',
        container: INPUT_BORDER_RADIUS_STYLES,
        icon: /*tw:*/ 'w-4 h-4',
        exclamationIcon: /*tw:*/ 'p-[0.4375rem]',
        textBottom: /*tw:*/ 'text-xs',
    },
    large: {
        input: /*tw:*/ 'font-normal text-base leading-[1.4]',
        container: /*tw:*/ 'rounded-lg',
        icon: /*tw:*/ 'w-5 h-5',
        exclamationIcon: /*tw:*/ 'p-2.5',
        textBottom: /*tw:*/ 'text-sm',
    },
}

export interface InputProps {
    variant?: InputVariant
    /**
     * Large only works with single line input without buttons
     */
    size?: Size
    type?: React.ComponentPropsWithoutRef<'input'>['type']
    min?: React.ComponentPropsWithoutRef<'input'>['min']
    max?: React.ComponentPropsWithoutRef<'input'>['max']
    button?: React.ReactNode
    className?: string
    defaultValue?: string
    disabled?: boolean
    id?: string
    name?: string
    error?: React.ReactNode
    focusOnMount?: boolean
    hint?: React.ReactNode
    maxRows?: number
    multiline?: boolean
    placeholder?: string
    readOnly?: boolean
    value?: string
    inputClassName?: string
    forceFocused?: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onClick?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onMouseDown?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onMouseUp?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>
    // Necessary for ModalInput
    isSpacious?: boolean
}

export const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(function Input(
    {
        variant = 'ghost',
        size = 'medium',
        type,
        min,
        max,
        button,
        className,
        error,
        focusOnMount,
        hint,
        maxRows,
        multiline,
        placeholder,
        inputClassName,
        forceFocused,
        isSpacious,
        onKeyDown,
        ...props
    },
    parentRef
) {
    const isLocked = props.disabled || props.readOnly
    const variantStyles = VARIANT_STYLES[variant]
    const sizeStyles = SIZES_STYLES[size]

    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useAutoFocusedRef<HTMLInputElement | HTMLTextAreaElement>(focusOnMount)
    const ref = useForkRef(parentRef, inputRef)
    const buttonContainerRef = useRef<HTMLDivElement>(null)

    function handleFocus(event: React.FocusEvent<HTMLDivElement>) {
        // We don't want to focus if input button is clicked
        if (!isFocused && !isLocked && !buttonContainerRef.current?.contains(event.target)) {
            setIsFocused(true)
            inputRef.current?.focus()
        }
    }

    function handleBlur(event: React.FocusEvent<HTMLDivElement>) {
        // We don't want to blur if something within Input is clicked, like the hint
        if (isFocused && !event.currentTarget.contains(event.relatedTarget as Node | null)) {
            setIsFocused(false)
        }
    }

    const shouldAppearFocused = forceFocused || isFocused

    const spacingStyles = getSpacingStyles({
        isSpacious,
        isFocused: shouldAppearFocused,
        hasHintOrError: Boolean(hint || error),
        multiline,
        size,
    })

    const sharedInputProps = {
        ref: ref as any,
        // If there is no content in the input, we want to show an Em-dash (U+2014) in blurred state
        placeholder: placeholder || shouldAppearFocused ? placeholder : EM_DASH,
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onKeyDown && onKeyDown(e)
            if (e.key === 'Enter' && !e.shiftKey) {
                inputRef.current?.blur()
            }
        },
        ...props,
        className: twMerge(
            /*tw:*/ 'transition-colors text-grey-700 tabular-nums tracking-[inherit] block outline-none w-full bg-transparent text-ellipsis resize-none disabled:text-grey-700',
            // Em-dash as placeholder for no content is more muted than usual placeholder
            placeholder && placeholder !== EM_DASH
                ? 'placeholder:text-grey-700/60'
                : 'placeholder:text-grey-700/20',
            sizeStyles.input,
            spacingStyles.input,
            inputClassName
        ),
    }

    return (
        <div
            className={twMerge(
                // items-start: Neccesary for button in multiline input
                'group transition outline-none overflow-hidden flex items-start w-full',
                sizeStyles.container,
                variantStyles.container,
                shouldAppearFocused
                    ? ['bg-white ring', error ? 'ring-red-500' : 'ring-blue-500'].join(' ')
                    : variantStyles.containerHover,
                className
            )}
            onFocus={handleFocus}
            onBlur={handleBlur}
            tabIndex={isLocked ? undefined : 1}
        >
            <div className="w-full">
                {multiline ? (
                    <TextareaAutosize cacheMeasurements maxRows={maxRows} {...sharedInputProps} />
                ) : (
                    <input type={type} min={min} max={max} {...sharedInputProps} />
                )}
                {shouldAppearFocused && (error || hint) && (
                    <div
                        role="alert"
                        className={clsx(
                            sizeStyles.textBottom,
                            spacingStyles.hint,
                            error ? 'text-red-500' : 'text-grey-700/60'
                        )}
                    >
                        {error || hint}
                    </div>
                )}
            </div>
            {props.disabled && (
                <LockOutlinedIcon
                    className={clsx(
                        'transition-colors flex-none box-content p-[0.4375rem] text-grey-700/20 rounded-md group-hover:text-grey-700/60',
                        sizeStyles.icon
                    )}
                />
            )}
            {!shouldAppearFocused &&
                (error ? (
                    <div
                        title={typeof error === 'string' ? error : undefined}
                        className="flex-none"
                    >
                        <ExclamationOutlinedIcon
                            className={clsx(
                                'box-content text-red-500 rounded-md',
                                sizeStyles.icon,
                                sizeStyles.exclamationIcon
                            )}
                        />
                    </div>
                ) : (
                    button && (
                        <div
                            ref={buttonContainerRef}
                            className="flex-none text-grey-700/20 rounded-md overflow-hidden group-hover:text-white group-hover:bg-blue-500"
                        >
                            {button}
                        </div>
                    )
                ))}
        </div>
    )
})

interface GetSpacingStylesProps {
    isSpacious: boolean | undefined
    isFocused: boolean
    hasHintOrError: boolean
    multiline: boolean | undefined
    size: Size
}

interface SpacingStyles {
    input: string
    hint?: string
}

/**
 * Setting padding on input instead of container makes scrollbar aligned with visual border on multiline input and increases click area fpr input.
 */
function getSpacingStyles({
    isSpacious,
    isFocused,
    hasHintOrError,
    multiline,
    size,
}: GetSpacingStylesProps): SpacingStyles {
    const styles = (() => {
        if (isSpacious) {
            return { px: 'px-3', py: 'py-3', pt: 'pt-3', pb: 'pb-3' }
        }
        if (size === 'large') {
            return { px: 'px-3', py: 'py-[0.5625rem]', pt: 'pt-[0.5625rem]', pb: 'pb-[0.5625rem]' }
        }
        return { px: 'px-3', py: 'py-[0.3125rem]', pt: 'pt-[0.3125rem]', pb: 'pb-[0.3125rem]' }
    })()

    if (!isFocused || !hasHintOrError) {
        return {
            input: clsx(styles.px, styles.py),
        }
    }

    if (multiline) {
        return {
            // We want to use more available space while scrolling multiline input
            input: clsx(styles.px, styles.pt, 'pb-2'),
            hint: clsx(styles.px, styles.pb, 'pt-1'),
        }
    }

    return {
        input: clsx(styles.px, styles.pt),
        hint: clsx(styles.px, styles.pb, 'pt-1'),
    }
}

interface GetInputButtinPropsProps {
    icon: React.ComponentType<{ className?: string }>
    size?: Size
}

export function getInputButtonProps({ icon: Icon, size = 'medium' }: GetInputButtinPropsProps) {
    return {
        // When we tab through a list of inputs, we want only the inputs to get focus. Input button should only be clickable.
        tabIndex: -1,
        className:
            'border-none p-[7px] block outline-none cursor-pointer hover:bg-blue-700 active:bg-blue-700',
        children: <Icon className={SIZES_STYLES[size].icon} />,
    }
}

interface InputButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    icon: React.ComponentType<{ className?: string }>
    size?: Size
}

export function InputButton({ icon, size, ...props }: InputButtonProps) {
    return <button {...getInputButtonProps({ icon, size })} {...props} />
}

import { z } from '@laserfocus/shared/decoder'

import { AccountIdSchema, LeadIdSchema } from '../base/id.types'

export const SyncedRootsMutatorSchema = {
    visitRoot: z.object({
        rootId: z.union([LeadIdSchema, AccountIdSchema]),
    }),
    prefetchRoots: z.object({
        rootIds: z.array(z.union([LeadIdSchema, AccountIdSchema])),
    }),
    syncNames: z.object({
        ids: z.array(z.string()),
    }),
    resync: z.object({
        rootId: z.union([LeadIdSchema, AccountIdSchema]),
    }),
    // This is for the admin use case, to make sure ANY object of a type exists
    ensureObjectOfTypeExists: z.object({
        objectType: z.enum(['Lead', 'Account', 'Opportunity', 'Contact']),
    }),
}

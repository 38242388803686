import { useState, cloneElement, useLayoutEffect } from 'react'
import { usePopper } from 'react-popper'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { useBodyScrollLock, useForkRef, useSizeObserver } from '@laserfocus/ui/util-react'
import { Portal } from '@laserfocus/ui/util-react'

import { Input, InputProps, INPUT_BORDER_RADIUS_STYLES } from './input'

interface ModalInputProps extends ModalInputInnerProps {
    isOpen?: boolean
}

export function ModalInput({ isOpen, ...props }: ModalInputProps) {
    return isOpen ? <ModalInputInner {...props} /> : props.children
}

interface ModalInputInnerProps
    extends Pick<
        InputProps,
        | 'value'
        | 'onChange'
        | 'className'
        | 'error'
        | 'hint'
        | 'multiline'
        | 'maxRows'
        | 'placeholder'
        | 'onKeyDown'
        | 'type'
        | 'name'
        | 'inputClassName'
        | 'isSpacious'
    > {
    children: React.ReactElement<RefAttribute> & RefAttribute
    onClose?: () => void
    offsetCorrection?: [x: number, y: number]
    overflowBoundaryRef?: React.RefObject<HTMLElement>
    className?: string
    containerClassName?: string
}
interface RefAttribute {
    ref?: ((element: HTMLElement) => void) | null
}

function ModalInputInner({
    children,
    onClose,
    onKeyDown,
    offsetCorrection,
    overflowBoundaryRef,
    containerClassName,
    ...props
}: ModalInputInnerProps) {
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const clonedChildRef = useForkRef(children.ref, setReferenceElement)
    const { styles, update } = useInputPopper(
        referenceElement,
        popperElement,
        offsetCorrection,
        overflowBoundaryRef
    )
    useBodyScrollLock(true)
    useSizeObserver(popperElement, update)
    // We need to rerender after mount because there is a race condition between react-popper which tries to measure the width and height of the dropdown content and the <TextareaAutosize /> in the <Input /> which changes its size based on its value. The result is that the dropdown is sometimes placed incorrectly sometimes (e.g. partially out of viewport). A simple rerender makes react-popper measure the content again which fixes the issue.
    useRerenderAfterMount(props.multiline)

    return (
        <>
            {cloneElement(children, { ref: clonedChildRef })}
            <Portal>
                <div onClick={onClose} className="fixed inset-0 z-10" />
                <div ref={setPopperElement} style={styles.popper} className="z-10">
                    <div
                        className={twMerge(
                            'bg-white',
                            INPUT_BORDER_RADIUS_STYLES,
                            containerClassName
                        )}
                        style={{
                            // Can't use boxShadow on Input because it uses focus ring as boxShadow.
                            boxShadow:
                                '0px 22px 48px rgba(0, 0, 0, 0.03), 0px 8.03036px 17.5208px rgba(0, 0, 0, 0.0206994), 0px 3.89859px 8.50602px rgba(0, 0, 0, 0.0166887), 0px 1.91116px 4.16981px rgba(0, 0, 0, 0.0133113), 0px 0.755676px 1.64875px rgba(0, 0, 0, 0.00930055)',
                        }}
                    >
                        <Input
                            isSpacious
                            focusOnMount
                            forceFocused
                            onBlur={onClose}
                            onKeyDown={(event) => {
                                event.key === 'Escape' && onClose?.()
                                onKeyDown?.(event)
                            }}
                            {...props}
                        />
                    </div>
                </div>
            </Portal>
        </>
    )
}

function useInputPopper(
    referenceElement: HTMLElement | null,
    popperElement: HTMLDivElement | null,
    offsetCorrection: [x: number, y: number] = [0, 0],
    overflowBoundaryRef?: React.RefObject<HTMLElement>
) {
    const referenceElementHeight = referenceElement?.offsetHeight || 0
    const [offsetX, offsetY] = offsetCorrection

    return usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'arrow',
                enabled: false,
            },
            {
                name: 'flip',
                enabled: false,
            },
            {
                name: 'preventOverflow',
                options: {
                    padding: 8,
                    altAxis: true,
                    boundary: overflowBoundaryRef?.current || undefined,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [offsetX - 4, offsetY + referenceElementHeight * -1 - 8],
                },
            },
        ],
    })
}

function useRerenderAfterMount(isActive: boolean | undefined) {
    const [, rerender] = useState({})

    useLayoutEffect(() => {
        if (isActive) {
            rerender({})
        }
    }, [isActive])
}

import type { GraphQLError } from 'graphql'
import { map } from 'lodash'
import queryString from 'query-string-original'
import { ServerError, ServerParseError } from 'apollo-link-http-common'
import { onError } from 'apollo-link-error'
import type { ErrorResponse } from 'apollo-link-error'
import { Observable } from 'apollo-link'

import { navigate } from '@laserfocus/client/util-routing'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { logger } from '@laserfocus/ui/logger'

import { USER_ERROR_CODE } from './graphql-error-utils'
import { handleUnauthenticatedResponse } from './auth-middleware'

const UNAUTHENTICATED_CODE = 'UNAUTHENTICATED'
const API_DISABLED_CODE = 'API_DISABLED_FOR_ORG'
const BLOCKED_ORG = 'INACTIVE_USER'

export const onErrorHandler = onError((props: ErrorResponse) => {
    const { graphQLErrors, networkError, forward, operation } = props
    logger.debug(
        'onGraphqlError',
        'op',
        props.operation.operationName,
        'headers.authorization',
        props.operation.getContext().headers?.authorization
    )
    let handled
    if (graphQLErrors) {
        const codes = map(graphQLErrors, 'extensions.code')
        logger.debug('handleError', codes)
        if (codes.includes(BLOCKED_ORG)) {
            onUserBlocked(BLOCKED_ORG)
            return new Observable((observer) => {
                // Do nothing, because we want to stall all error handlers
            })
        } else if (codes.includes(UNAUTHENTICATED_CODE)) {
            if (localStorage.getItem('lf:user_id') === '0051i000000EJDYAA4') {
                // eslint-disable-next-line no-debugger
                debugger
            }
            return handleUnauthenticatedResponse(operation, forward)
        } else if (codes.includes(API_DISABLED_CODE)) {
            onApiDisabled(API_DISABLED_CODE)
            return new Observable((observer) => {
                // Do nothing, because we want to stall all error handlers
            })
        } else {
            const cleanedErrors = graphQLErrors.filter(Boolean)
            if (graphQLErrors.length !== cleanedErrors.length) {
                logger.error('I received an falsy GraphqlError', {
                    props,
                })
            }
            cleanedErrors.forEach(({ message, locations, path, extensions }) => {
                const code = extensions && extensions.code
                if (code === USER_ERROR_CODE) {
                    logger.warn(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${code}`
                    )
                } else {
                    /**
                     * We logged this error already in the backend
                     */
                    logger.warn(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${code}`
                    )
                }
            })
        }
    }
    if (networkError && (!handled || handled === 'NONE')) {
        logNetworkError(networkError, graphQLErrors)
    }
})

export function logNetworkError(
    error: Error | ServerError | ServerParseError,
    graphQLErrors?: ReadonlyArray<GraphQLError>
) {
    if (isParseError(error)) {
        logger.error(error, {
            bodyText: error.bodyText,
            graphQLErrors,
        })
    } else {
        logger.error(error, {
            name: error.name,
            isFailedFetch: isFailedToFetch(error),
            graphQLErrors,
        })
    }
}

function isParseError(error: Error | ServerError | ServerParseError): error is ServerParseError {
    return error.name === 'ServerParseError'
}

function isFailedToFetch(error: Error | ServerError) {
    return error.message === 'Failed to fetch'
}

function onApiDisabled(code: string) {
    if (code) {
        const path = RoutePaths.ApiDisabled + '?' + queryString.stringify({ code })
        return navigate(path, { state: { code } })
    }
    // This path should never be called, since we only call this with apiDisabled codes..
    return navigate(RoutePaths.ApiDisabled)
}

function onUserBlocked(code: string) {
    // This path should never be called, since we only call this with apiDisabled codes..
    return navigate(RoutePaths.AccountNotActive)
}

import type { ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'
import { partition } from 'lodash'

import { getClient } from '@laserfocus/client/replicache'
import {
    User,
    Prefix,
    Identity,
    UserInvite,
    UserMutatorSchema,
    applyValidators,
    UserId,
    UserUpdate,
    LFUser,
    Role,
    RoleUpdate,
    slugify,
    isTruthy,
} from '@laserfocus/shared/models'

import { sortUsers } from './sort-colleagues'

export function useUsersSubscription() {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            const users = (await tx
                .scan({ prefix: `${Prefix.User}/` })
                .values()
                .toArray()) as User[]
            return users || []
        },
        [],
        []
    )
}

export function useColleagues(keepMyself?: boolean): User[] {
    const rep = getClient()
    const { me, colleagues } = useSubscribe<{ me: User | null; colleagues: User[] }>(
        rep,
        async (tx: ReadTransaction) => {
            const [users, identity] = (await Promise.all([
                tx
                    .scan({ prefix: `${Prefix.User}/` })
                    .values()
                    .toArray(),
                tx.get('identity'),
            ])) as [User[], Identity]
            // const identity = awa
            const [me, colleagues] = partition(users, (u) => u.Id === identity.Id)
            return {
                me: me[0] ?? null,
                colleagues,
            }
        },
        { me: null, colleagues: [] },
        []
    )
    const sortedColleagues = sortUsers(colleagues, me)
    return keepMyself ? [me, ...sortedColleagues].filter(isTruthy) : sortedColleagues
}

export function useLFUsers(): LFUser[] {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            const users = (await tx
                .scan({ prefix: `${Prefix.LFUser}/` })
                .values()
                .toArray()) as LFUser[]
            return users || []
        },
        [],
        []
    )
}

export function useInvites() {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            const invites = (await tx
                .scan({ prefix: `${Prefix.UserInvite}/` })
                .values()
                .toArray()) as UserInvite[]
            return invites || []
        },
        [],
        []
    )
}

export function useMyRole() {
    return useSubscribe<{
        isLoading: boolean
        role: string | null
    }>(
        getClient(),
        async (tx) => {
            const me = (await tx.get('identity')) as Identity
            if (me) {
                const lfUser = (await tx.get([Prefix.LFUser, me.Id].join('/'))) as
                    | LFUser
                    | undefined

                return {
                    isLoading: false,
                    role: lfUser?.role ?? null,
                }
            }
            return {
                isLoading: false,
                role: null,
            }
        },
        {
            isLoading: true,
            role: null,
        },
        []
    )
}

export function updateUser(userId: UserId, update: UserUpdate) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.updateUser({
        id: userId,
        values: update,
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function createRole(label: string) {
    const rep = getClient<typeof mutators>()
    const name = await getNextSlug(label)

    return rep.mutate.createRole({
        values: {
            name,
            label,
        },
        optimistic: {
            modifiedDate: new Date().toISOString(),
            createdDate: new Date().toISOString(),
        },
    })
}

async function getNextSlug(label: string) {
    const rep = getClient<typeof mutators>()
    const slug = slugify(label)
    let counter = 0
    while (true) {
        const current = counter ? `${slug}-${counter}` : slug
        const exists = await rep.query((tx) => tx.get([Prefix.Role, current].join('/')))
        if (!exists) {
            return current
        }
        counter++
    }
}

export function updateRole(roleName: string, update: RoleUpdate) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.updateRole({
        name: roleName,
        values: update,
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}
export function deleteRole(roleName: string) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.deleteRole({
        name: roleName,
    })
}

export function inviteColleagues(invitees: UserId[], inviter: UserId) {
    const rep = getClient<typeof mutators>()

    return rep.mutate.inviteColleague({
        values: {
            invitees,
            source: 'share',
        },
        optimistic: {
            inviter,
            lastModifiedDate: new Date().toISOString(),
        },
    })
}

export const mutators = applyValidators(UserMutatorSchema, {
    async createRole(tx, args) {
        const key = [Prefix.Role, args.values.name].join('/')
        await tx.put(key, args.values)
    },
    async updateRole(tx, args) {
        const key = [Prefix.Role, args.name].join('/')
        const existing = (await tx.get(key)) as Role | undefined
        tx.put(key, {
            name: args.name,
            ...(existing || {}),
            ...args.values,
            ...args.optimistic,
        })
    },
    async deleteRole(tx, args) {
        const key = [Prefix.Role, args.name].join('/')
        return tx.del(key)
    },
    async updateUser(tx, args) {
        const key = `${Prefix.LFUser}/${args.id}`
        const user = (await tx.get(key)) as LFUser | undefined
        const updated = {
            ...(user || {}),
            id: args.id,
            ...args.optimistic,
            ...args.values,
        }
        tx.put(key, updated)
    },
    async inviteColleague(tx, args) {
        await Promise.all(
            args.values.invitees.map((invitee) => {
                const inviter = args.optimistic.inviter!
                const key = [Prefix.UserInvite, invitee, inviter].join('/')
                const invite: Omit<UserInvite, 'orgId'> = {
                    invitee,
                    inviter,
                    source: 'share',
                }
                return tx.put(key, invite)
            })
        )
    },
})

import { ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'
import { useEffect, useState } from 'react'
import { sortBy } from 'lodash'

import { Config } from '@laserfocus/shared/config-env'
import { Prefix } from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { toast, Notification, Button } from '@laserfocus/ui/beam'
type Release = {
    release: string
    date: string
}

const FRONTEND_VERSION = Config.version

export function UpdateNotification() {
    const [isShowing, setIsShowing] = useState(false)
    const newestRelease = useSubscribe(
        getClient(),
        async (tx: ReadTransaction) => {
            const releases = await tx.scan({ prefix: Prefix.Release }).values().toArray()

            const sorted = sortBy(releases as Release[], 'date').reverse()
            return sorted[0]?.release
        },
        null,
        []
    )

    useEffect(() => {
        if (newestRelease && newestRelease !== FRONTEND_VERSION && !isShowing) {
            setIsShowing(true)
            toast.custom(
                (t) => (
                    <Notification
                        visible={t.visible}
                        dismiss={() => {
                            setIsShowing(false)
                            toast.dismiss(t.id)
                        }}
                    >
                        <Notification.Title>New update available</Notification.Title>
                        <Notification.Description>
                            There is a new version of laserfocus available, please refresh your
                            browser.
                        </Notification.Description>
                        <div className="mt-3 mb-1">
                            <Button
                                size="small"
                                variant="primary"
                                onClick={() =>
                                    // eslint-disable-next-line no-restricted-globals
                                    location.reload()
                                }
                            >
                                Update
                            </Button>
                        </div>
                    </Notification>
                ),
                {
                    duration: Infinity,
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newestRelease])

    return null
}

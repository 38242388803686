import { parse } from 'querystring'

import type { LogSink } from 'replicache'
import { isPlainObject } from 'lodash'

import { logger } from '@laserfocus/ui/logger'
import { captureException, setExtra, setTag } from '@laserfocus/ui/error-reporting'
import { WrappedBaseError, BaseError } from '@laserfocus/shared/util-error'

type ReplicacheExtension = Partial<{
    request_id: string
    name: string
}> &
    Record<string, any>
class WrappedReplicacheError extends WrappedBaseError<ReplicacheExtension> {
    isOperational = true
    name = 'WrappedReplicacheError'
}

class ReplicacheError extends BaseError<ReplicacheExtension> {
    isOperational = true
    name = 'ReplicacheError'
}

export const SentryLogSink: LogSink = {
    log(level, ...args) {
        logger.info(...args)
        if (level === 'error') {
            const parsed = parseLogSinkMessage(args)
            try {
                if (typeof navigator.storage?.estimate === 'function') {
                    navigator.storage.estimate().then((quota) => {
                        if (parsed.extensions?.request_id) {
                            setTag('request_id', parsed.extensions?.request_id)
                        }
                        setExtra('storage', quota)
                        captureException(parsed)
                    })
                } else {
                    if (parsed.extensions?.request_id) {
                        setTag('request_id', parsed.extensions?.request_id)
                    }
                    captureException(parsed)
                }
            } catch (e: unknown) {
                console.error(e)
            }
        }
    },
}

export function parseLogSinkMessage(args: unknown[]): WrappedReplicacheError | ReplicacheError {
    const message: string =
        args.filter(isString).find((a) => !a.includes('=')) || 'Replicache Error'
    const remaining = args.filter((a) => a !== message)
    const initialContext: Record<string, string | string[] | undefined> = {}

    const contextStringArgs: string[] = remaining.filter(isString).filter((a) => a.includes('='))

    const contextValues = contextStringArgs.reduce((context, curr) => {
        const parsed = parse(curr)
        return {
            ...context,
            ...parsed,
        }
    }, initialContext)
    const cause = remaining.find(isCause)
    if (cause) {
        return new WrappedReplicacheError(message, cause, contextValues)
    }
    return new ReplicacheError(message, contextValues)
}

function isString(a: unknown): a is string {
    return typeof a === 'string'
}

function isCause(a: unknown): a is Error {
    return a instanceof Error || isErrorIsh(a)
}

function isErrorIsh(a: any) {
    return isPlainObject(a) && a?.name && a?.stack && a?.message
}

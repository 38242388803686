import { z } from '@laserfocus/shared/decoder'

import { getPrefixForSobject } from './salesforce-prefix'
import type { Brand } from './type-utills'

export const UserIdSchema = makeIdSchema<UserId>('005', 'UserId')

export type UserId = Brand<string, 'UserId'>

export const OrgIdSchema = makeIdSchema<OrgId>('00D', 'OrgId')
export type OrgId = Brand<string, 'OrgId'>

export const LeadIdSchema = makeIdSchema<LeadId>('00Q', 'LeadId')
export type LeadId = Brand<string, 'LeadId'>

export function isLead(id: string | undefined | null): id is LeadId {
    return id ? id.substr(0, 3) === '00Q' : false
}

export const AccountIdSchema = makeIdSchema<AccountId>('001', 'AccountId')
export type AccountId = Brand<string, 'AccountId'>
export function isAccount(id: string | undefined | null): id is AccountId {
    return id ? id.substr(0, 3) === '001' : false
}

export const TaskIdSchema = makeIdSchema<TaskId>('00T', 'TaskId')
export type TaskId = string

export function isTask(id: string | undefined | null): id is TaskId {
    return id ? id.substr(0, 3) === '00T' : false
}

export const EventIdSchema = makeIdSchema<EventId>('00U', 'EventId')
export type EventId = string
export function isEvent(id: string | undefined | null): id is EventId {
    return id ? id.substr(0, 3) === '00U' : false
}

export type ContactId = Brand<string, 'ContactId'>
export const ContactIdSchema = makeIdSchema<ContactId>('003', 'ContactId')

export type OpportunityId = Brand<string, 'ContactId'>
export const OpportunityIdSchema = makeIdSchema<ContactId>('006', 'OpportunityId')

export type ContentNoteId = Brand<string, 'ContentNoteId'>
export const ContentNoteIdSchema = makeIdSchema<ContentNoteId>(
    getPrefixForSobject('ContentNote'),
    'ContentNoteId'
)

export function makeIdSchema<Branded extends string>(prefix: string, label: string) {
    return z
        .string()
        .refine(
            (val: string) => {
                /**
                 * Our tests use a lot of mock IDs, that dont pass the regex
                 */
                if (process.env.NODE_ENV === 'test') {
                    return val.substr(0, 3) === prefix
                }
                const idRegex = new RegExp(/^[a-zA-Z0-9]{15}$|^[a-zA-Z0-9]{18}$/)
                return val.match(idRegex) && val.substr(0, 3) === prefix
            },
            {
                message: `This is not a valid ${label}`,
            }
        )
        .transform((val) => val as Branded)
}

import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, Dropdown } from '@laserfocus/ui/beam'
import { ColumnsOutlinedIcon } from '@laserfocus/ui/icons'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'

import { useAllColumns } from '../useTable'

import { ColumnsList } from './ColumnsList'

export function EditColumns({ showHint }: { showHint?: boolean }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const content = (
        <Dropdown
            isOpen={isDropdownOpen}
            content={<EditColumnsDropdown />}
            onCancel={() => setIsDropdownOpen(false)}
        >
            <Button onClick={() => setIsDropdownOpen(true)} iconComponent={ColumnsOutlinedIcon}>
                Show fields
            </Button>
        </Dropdown>
    )
    if (showHint) {
        return (
            <EverboardingHint
                name="everboarding_stack_customize_fields"
                label="Add more salesforce fields"
                placement="left"
                containerClassName="translate-y-20 -rotate-3"
                arrowClassName="translate-x-16 -translate-y-12 -rotate-[65deg]"
            >
                {content}
            </EverboardingHint>
        )
    }
    return content
}

const EditColumnsDropdown = observer(function EditColumnsDropdown() {
    const allColumns = useAllColumns().map(({ salesObjectType, columns }) => ({
        label: salesObjectType,
        columns,
    }))

    const activeFilters = allColumns.flatMap(({ columns }) =>
        columns.filter((c) => c.filterCondition)
    )
    const allColumnsWithActiveFilters = activeFilters.length
        ? [
              {
                  label: 'Active filters',
                  columns: activeFilters,
              },
              ...allColumns,
          ]
        : allColumns

    return <ColumnsList columnSections={allColumnsWithActiveFilters} />
})

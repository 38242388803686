import clsx from 'clsx'

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ArrowDownOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M5.293 9.293a1 1 0 011.414 0L12 14.586l5.293-5.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414z" />
        </SvgIcon>
    )
}

export function ArrowLeftOutlinedIcon(props: SvgIconProps) {
    return <ArrowDownOutlinedIcon {...props} className={clsx('rotate-90', props.className)} />
}

export function ArrowRightOutlinedIcon(props: SvgIconProps) {
    return <ArrowDownOutlinedIcon {...props} className={clsx('-rotate-90', props.className)} />
}

export function ArrowUpOutlinedIcon(props: SvgIconProps) {
    return <ArrowDownOutlinedIcon {...props} className={clsx('rotate-180', props.className)} />
}

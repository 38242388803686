import clsx from 'clsx'

import { theme } from '@laserfocus/ui/tailwindcss'
interface ColorPickerProps {
    selectedColor: string | undefined
    onSubmit(color: string | null): void
    allowNull?: boolean
}

type ColorOption = {
    label: string
    value: string | null
    displayValue: string
    children?: React.ReactNode
}

export function ColorPicker({ selectedColor, onSubmit, allowNull }: ColorPickerProps) {
    const colors: ColorOption[] = [
        {
            label: 'Grey',
            value: theme.colors.grey[50],
            displayValue: theme.colors.grey['700-opaque-20'],
        },
        {
            label: 'Purple',
            value: theme.colors.purple[50],
            displayValue: theme.colors.purple[500],
        },
        {
            label: 'Blue',
            value: theme.colors.blue[50],
            displayValue: theme.colors.blue[500],
        },
        {
            label: 'Green',
            value: theme.colors.green[50],
            displayValue: theme.colors.green[500],
        },
        {
            label: 'Yellow',
            value: theme.colors.yellow[50],
            displayValue: theme.colors.yellow[300],
        },
        {
            label: 'Red',
            value: theme.colors.red[100],
            displayValue: theme.colors.red[500],
        },
    ]
    if (allowNull) {
        colors.push({
            label: 'None',
            value: null,
            displayValue: theme.colors.white,
            children: (
                <div
                    style={{
                        borderBottom: '1px solid red',
                        transform: 'rotate(45deg) translate(4px, 0px',
                        transformOrigin: 'top left',
                    }}
                />
            ),
        })
    }

    return (
        <div className="py-2 mx-2 grid grid-cols-8 gap-1 border-b border-white/10">
            {colors.map(({ label, value, displayValue, children }) => (
                <button
                    key={value}
                    title={label}
                    className={clsx(
                        'w-[1.875rem] h-[1.875rem] grid place-content-center rounded-md hover:bg-white/10 outline-none focus-visible:ring transition-shadow',
                        value === selectedColor && 'bg-white/20'
                    )}
                    onClick={() => onSubmit(value)}
                >
                    <div className="w-5 h-5 rounded-full" style={{ backgroundColor: displayValue }}>
                        {children}
                    </div>
                </button>
            ))}
        </div>
    )
}

import { useState } from 'react'

import { DropdownInput } from '@laserfocus/ui/beam'
import { Operator } from '@laserfocus/shared/models'

import { Column } from '../../Table/table-context'

import { FilterInputProps } from './types'
import { FilterInputActions } from './FilterInputActions'

export function NumberInput({ column, operator }: FilterInputProps) {
    const [inputValue, setInputValue] = useState(
        (column.filterCondition?.values[0]?.toString() as string | undefined) ?? ''
    )
    const inputValueAsNumber = Number.parseFloat(inputValue)

    function saveFilter() {
        column.setFilterCondition({
            operator,
            values: [inputValueAsNumber],
        })
    }

    return (
        <>
            <DropdownInput
                placeholder="Search"
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
            />
            <FilterInputActions
                column={column}
                saveFilter={
                    Number.isNaN(inputValueAsNumber) ||
                    isPristine(inputValueAsNumber, column, operator)
                        ? undefined
                        : saveFilter
                }
                onRemoveFilterCondition={() => setInputValue('')}
            />
        </>
    )
}

function isPristine(inputValue: number, column: Column, operator: Operator) {
    const { filterCondition } = column

    if (!filterCondition) {
        return false
    }

    return inputValue === filterCondition.values[0] && operator === filterCondition.operator
}

import { action, observable } from 'mobx'

import { assert } from '@laserfocus/shared/util-error'

export type QueryStatusName = 'running' | 'finished' | 'none'

type QueryName =
    | 'all-open-tasks'
    | 'all-upcoming-events'
    | 'my-open-tasks'
    | 'my-events-today'
    | 'users'
    | 'groups'
    | 'stackrows'
    | 'my-upcoming-events'
    | 'all-lead'
    | 'all-opportunity'
    | 'all-contact'
    | 'all-account'

export class QueryStatus {
    @observable status = new Map<QueryName, QueryStatusName>()

    @action
    startOnce(query: QueryName) {
        assert(this.getStatus(query) === 'none', `Trying to run ${query} once, but did already run`)
        this.start(query)
    }

    @action
    start(query: QueryName) {
        if (this.status.get(query) !== 'finished') {
            this.status.set(query, 'running')
        }
    }
    @action
    finish(query: QueryName) {
        this.status.set(query, 'finished')
    }

    @action
    reset(query: QueryName) {
        this.status.set(query, 'none')
    }

    getStatus(query: QueryName): QueryStatusName {
        return this.status.get(query) || 'none'
    }
}

import { useState } from 'react'
import { useSubscribe } from 'replicache-react'

import { Button, Select } from '@laserfocus/ui/beam'
import {
    Delete2OutlinedIcon,
    DuplicateOutlinedIcon,
    FavoriteOutlinedIcon,
    MoreOutlinedIcon,
    ExternalLinkOutlinedIcon,
    ResetOutlinedIcon,
    ClockOutlinedIcon,
} from '@laserfocus/ui/icons'
import { isTruthy } from '@laserfocus/shared/models'
import { PlanChip, useIsLocked, LockedFeatureModal } from '@laserfocus/client/feature-subscription'
import { queryCanCreateStack } from '@laserfocus/client/data-access-shared'
import { getClient } from '@laserfocus/client/replicache'

interface MoreActionsProps {
    canDelete: boolean
    canReset: boolean
    isFavorite: boolean
    duplicateStack(): void
    setIsFavorite(isFavorite: boolean): void
    deleteStack(): void
    shareStack(): void
    configureTiming(): void
    resetStack(): void
}

export function MoreActions({
    canDelete,
    canReset,
    isFavorite,
    duplicateStack,
    setIsFavorite,
    deleteStack,
    shareStack,
    configureTiming,
    resetStack,
}: MoreActionsProps) {
    const [isOpen, setIsOpen] = useState(false)

    const canCreateStack = useSubscribe(getClient(), queryCanCreateStack, true, [])
    const { isLocked, subscription } = useIsLocked(!canCreateStack)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)

    return (
        <>
            <Select
                size={180}
                isOpen={isOpen}
                onCancel={() => setIsOpen(false)}
                options={[
                    {
                        label: isLocked ? (
                            <span className="flex justify-between">
                                Duplicate <PlanChip>PRO</PlanChip>
                            </span>
                        ) : (
                            'Duplicate'
                        ),
                        value: 'duplicate',
                        iconComponent: DuplicateOutlinedIcon,
                        action: isLocked ? () => setShowUpgradeModal(true) : duplicateStack,
                    },
                    {
                        label: 'Share stack',
                        value: 'share',
                        iconComponent: ExternalLinkOutlinedIcon,
                        action: shareStack,
                    },
                    {
                        label: 'Schedule',
                        value: 'schedule',
                        iconComponent: ClockOutlinedIcon,
                        action: configureTiming,
                    },
                    {
                        label: isFavorite ? 'Unfavorite' : 'Favorite',
                        value: 'favorite',
                        iconComponent: FavoriteOutlinedIcon,
                        action: () => setIsFavorite(!isFavorite),
                    },
                    canReset && {
                        label: 'Reset stack',
                        value: 'reset',
                        iconComponent: ResetOutlinedIcon,
                        action: resetStack,
                    },
                    canDelete && {
                        label: 'Delete',
                        value: 'delete',
                        iconComponent: Delete2OutlinedIcon,
                        className: 'text-red-500',
                        action: deleteStack,
                    },
                ].filter(isTruthy)}
                onSubmit={({ action }) => {
                    setIsOpen(false)
                    action()
                }}
            >
                <Button iconComponent={MoreOutlinedIcon} onClick={() => setIsOpen(!isOpen)} />
            </Select>
            {subscription && (
                <LockedFeatureModal
                    show={showUpgradeModal}
                    closeModal={() => setShowUpgradeModal(false)}
                    subscription={subscription}
                    title="Duplicate Stack"
                    lockedFeature="more than 2 custom stacks"
                />
            )}
        </>
    )
}

import { useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { Spinner } from '@laserfocus/ui/beam'

import { useOnboardingStore } from '../store/OnboardingStore'
import { OnboardingLayout } from '../shared/OnboardingLayout'

export const OnboardingLoadingChecks = observer(() => {
    const navigate = useNavigate()
    const onboardingStore = useOnboardingStore()

    useEffect(() => {
        if (!onboardingStore.isFinished) {
            return
        }
        if (onboardingStore.wasSuccessful) {
            navigate('/signup/create-overview', { replace: true })
        } else {
            navigate('/signup/lean-back', { replace: true })
        }
    }, [navigate, onboardingStore.isFinished, onboardingStore.wasSuccessful])

    return (
        <OnboardingLayout>
            <Spinner />
        </OnboardingLayout>
    )
})

import clsx from 'clsx'

export default function LoadingNotes() {
    return (
        <div className="grid gap-4">
            <NoteSkeleton longDescription />
            <NoteSkeleton />
            <NoteSkeleton longDescription />
            <NoteSkeleton />
            <NoteSkeleton />
        </div>
    )
}

export function NoteSkeleton({ longDescription = false }: { longDescription?: boolean }) {
    return (
        <div
            aria-busy="true"
            aria-live="polite"
            className={clsx(
                'w-full rounded-[0.625rem] bg-grey-700/5',
                longDescription ? 'h-[8.25rem]' : 'h-[4.125rem]'
            )}
        />
    )
}

import { useEffect, useState } from 'react'

import { FieldType, Operator } from '@laserfocus/shared/models'
import { DropdownSelect } from '@laserfocus/ui/beam'

import { Column } from '../Table/table-context'

import { FilterSection } from './FilterSection'
import { getFilterInput } from './get-filter-input'

interface FilterProps {
    column: Column
}

export function Filter({ column }: FilterProps) {
    const { availableOperators, selectedOperator, changeOperator } = useOperator(column)
    const filterInput =
        selectedOperator && getFilterInput({ column, operator: selectedOperator.value })

    if (availableOperators.length === 0 || (selectedOperator && !filterInput)) {
        return null
    }

    return (
        <FilterSection label="Filter">
            <DropdownSelect
                placeholder="Select operator"
                options={availableOperators}
                selectedOption={selectedOperator}
                onChange={changeOperator}
            />
            {filterInput}
        </FilterSection>
    )
}

function useOperator(column: Column) {
    // Expression evaluates to stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const availableOperatorValues: Operator[] = FILTER_OPERATORS_BY_TYPE[column.type] ?? []

    const [selectedOperatorValue, setSelectedOperatorValue] = useState<Operator | undefined>(
        column.filterCondition?.operator ?? availableOperatorValues[0]
    )

    // Reset operator if availableOperatorValues change and current operator isn't included in new list
    useEffect(() => {
        setSelectedOperatorValue((operator) => {
            return operator && availableOperatorValues.includes(operator) ? operator : undefined
        })
    }, [availableOperatorValues])

    const selectedOperator = selectedOperatorValue
        ? {
              value: selectedOperatorValue,
              label: OPERATOR_LABELS[selectedOperatorValue],
          }
        : undefined

    const availableOperators = availableOperatorValues.map((operator) => ({
        value: operator,
        label: OPERATOR_LABELS[operator],
    }))

    function changeOperator(operator: { value: Operator; label: string }) {
        setSelectedOperatorValue(operator.value)
    }

    return {
        selectedOperator,
        availableOperators,
        changeOperator,
    }
}

const STRING_OPERATORS: Operator[] = ['EQ', 'NEQ', 'INCLUDES', 'EXCLUDES']
const ID_OPERATORS: Operator[] = ['EQ', 'NEQ']
const NUMBER_OPERATORS: Operator[] = ['EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE']

const FILTER_OPERATORS_BY_TYPE: Partial<Record<FieldType, Operator[]>> = {
    string: STRING_OPERATORS,
    id: ID_OPERATORS,
    reference: ID_OPERATORS,
    url: STRING_OPERATORS,
    textarea: STRING_OPERATORS,
    phone: STRING_OPERATORS,
    email: STRING_OPERATORS,
    combobox: ID_OPERATORS,
    picklist: ID_OPERATORS,
    multipicklist: ID_OPERATORS,
    int: NUMBER_OPERATORS,
    double: NUMBER_OPERATORS,
    currency: NUMBER_OPERATORS,
    percent: NUMBER_OPERATORS,
    date: NUMBER_OPERATORS,
    datetime: NUMBER_OPERATORS,
    time: NUMBER_OPERATORS,
    boolean: ID_OPERATORS,
}

const OPERATOR_LABELS: Record<Operator, string> = {
    EQ: 'Equals',
    NEQ: 'Does not equal',
    GT: 'Greater than',
    GTE: 'Greater than or equals',
    LT: 'Lower than',
    LTE: 'Lower than or equals',
    INCLUDES: 'Includes',
    EXCLUDES: 'Excludes',
}

import { useRef, useState } from 'react'
import { animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import clsx from 'clsx'

import { theme } from '@laserfocus/ui/tailwindcss'

import { Column, useTableDataContext } from '../table-context'
import { useBulkEdit } from '../BulkEdit/bulkedit-context'

import { ColumnFilterButton } from './ColumnFilterButton'

interface TableHeadCellProps {
    column: Column
    animatedProps: Omit<React.ComponentPropsWithoutRef<typeof animated.th>, 'css' | 'sx'>
    widthScaleFactor: number
    isDragging: boolean
    hideResizeIndicator: boolean
}

const MIN_WIDTH = 50
const MAX_WIDTH = 700

export function TableHeadCell({
    column,
    animatedProps,
    widthScaleFactor,
    isDragging,
    hideResizeIndicator,
}: TableHeadCellProps) {
    const [isResizing, setIsResizing] = useState(false)
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
    const { isStickyLeftActive, tableHeadBackgroundColor } = useTableDataContext()!
    const { bulkEditTotal } = useBulkEdit()

    const preGestureWidthRef = useRef(column.width)

    const bindResize = useDrag(({ event, movement: [xOffset], first, last }) => {
        // We need this to prevent triggering column dragging logic
        event.stopPropagation()

        const preGestureWidth = preGestureWidthRef.current
        const newWidthTheoretical = Math.round(preGestureWidth + xOffset)
        const newWidth = Math.min(Math.max(newWidthTheoretical, MIN_WIDTH), MAX_WIDTH)

        column.setWidth(newWidth)

        if (first) {
            setIsResizing(true)
        }
        if (last) {
            setIsResizing(false)
            preGestureWidthRef.current = newWidth
        }
    })

    const shouldRenderButton = !isDragging && !isResizing && !column.hideFilter

    const showBorder = isStickyLeftActive || bulkEditTotal !== 'none'

    return (
        <animated.div
            role="columnheader"
            {...animatedProps}
            className={clsx(
                'group leading-[1.3] font-medium text-grey-700/60 text-left p-2 grid grid-cols-1 grid-flow-col items-center',
                column.isStickyLeft
                    ? 'sticky left-0 border-grey-700/10'
                    : 'absolute top-0 cursor-[grab] rounded-[0.625rem]',
                column.isStickyLeft && showBorder && 'border-r',
                column.isStickyLeft && bulkEditTotal !== 'none' && 'left-[62px]'
            )}
            style={{
                width: column.width * widthScaleFactor,
                ...animatedProps.style,
                ...(column.isStickyLeft && {
                    backgroundColor: tableHeadBackgroundColor,
                    boxShadow: `inset 0 -1px 0 ${theme.colors.grey[700]}1A`,
                }),
            }}
        >
            <div
                className="px-2 py-1 whitespace-nowrap overflow-x-hidden text-ellipsis"
                title={isDragging ? undefined : column.label}
            >
                {column.label}
            </div>
            {shouldRenderButton && (
                <ColumnFilterButton
                    column={column}
                    isFilterDropdownOpen={isFilterDropdownOpen}
                    setIsFilterDropdownOpen={setIsFilterDropdownOpen}
                />
            )}
            {!hideResizeIndicator && (
                <div
                    {...bindResize()}
                    className={clsx(
                        'group-inner absolute z-10 top-0 right-[-0.0625rem] w-0.5 px-1 -mx-1 box-content cursor-[col-resize]',
                        isResizing ? 'h-screen' : 'h-full'
                    )}
                >
                    <div className="group-inner-hover:bg-blue-500 group-inner-hover:transition w-full h-full rounded-full" />
                </div>
            )}
        </animated.div>
    )
}

import ApolloClient from 'apollo-client'
import {
    InMemoryCache,
    defaultDataIdFromObject,
    IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { from } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'

import { Config } from '@laserfocus/shared/config-env'

import introspectionQueryResultData from './fragmentTypes.json'
import { authMiddleware } from './auth-middleware'
import { onErrorHandler } from './handleGlobalErrors'

const { GRAPHQL_ENDPOINT } = Config

const httpLink = createHttpLink({ uri: GRAPHQL_ENDPOINT as string })

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
})

type DomainModelDTO = { Id: string; __typename: string }

const customDataIdFromObject = (object: DomainModelDTO) => {
    return object.Id && `${object.__typename}:${object.Id}`
}

const cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: (object) => {
        return customDataIdFromObject(object as DomainModelDTO) || defaultDataIdFromObject(object)
    },
})

const client = new ApolloClient({
    link: from([onErrorHandler, authMiddleware, httpLink]),
    cache,
})

export default client

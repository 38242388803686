import { useState } from 'react'

import { DropdownInput } from '@laserfocus/ui/beam'
import { Operator } from '@laserfocus/shared/models'
import {
    getDateFromTimeString,
    getDateFromUserTimeString,
    getTimeStringFromDate,
} from '@laserfocus/ui/util-date'
import { getTime } from '@laserfocus/ui/util-locale'

import { Column } from '../../Table/table-context'

import { FilterInputProps } from './types'
import { FilterInputActions } from './FilterInputActions'

export function TimeInput({ column, operator }: FilterInputProps) {
    const [inputValue, setInputValue] = useState(() => {
        const timeString = (column.filterCondition?.values[0] as string | undefined) ?? ''
        const date = getDateFromTimeString(timeString)

        return date ? getTime(date) : ''
    })

    const inputValueAsTimeString = getTimeStringFromDate(getDateFromUserTimeString(inputValue))

    function saveFilter() {
        column.setFilterCondition({
            operator,
            values: [inputValueAsTimeString!],
        })
    }

    return (
        <>
            <DropdownInput
                placeholder="Search"
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
            />
            <FilterInputActions
                column={column}
                saveFilter={
                    !inputValueAsTimeString ||
                    isPristine(inputValue, inputValueAsTimeString, column, operator)
                        ? undefined
                        : saveFilter
                }
                onRemoveFilterCondition={() => setInputValue('')}
            />
        </>
    )
}

function isPristine(
    inputValue: string,
    inputValueAsTimeString: string,
    column: Column,
    operator: Operator
) {
    const { filterCondition } = column

    if (!filterCondition) {
        return false
    }

    const hasSameInput =
        inputValue === filterCondition.values[0] ||
        inputValueAsTimeString === filterCondition.values[0]
    const hasSameOperator = operator === filterCondition.operator

    return hasSameInput && hasSameOperator
}

import { FieldValues, useController, UseControllerProps, FieldPath } from 'react-hook-form'
import { useState } from 'react'

import { Select, SelectProps, SelectOption, DropdownBaseInput } from '@laserfocus/ui/beam'

type FormSelectProps<
    SelectOptionExtend = {},
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> &
    Omit<SelectProps<SelectOption<SelectOptionExtend>>, 'isOpen' | 'onCancel' | 'children'> & {
        id?: string
        variant?: 'ghost' | 'border'
        disabled?: boolean
    }

export function FormSelect<
    SelectOptionExtend = {},
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: FormSelectProps<SelectOptionExtend, TFieldValues, TName>) {
    const [isOpen, setIsOpen] = useState(false)

    const { name, rules, shouldUnregister, defaultValue, control, ...restProps } = props
    const { id, variant, disabled, ...selectProps } = restProps
    const { onSubmit, ...restSelectProps } = selectProps
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({ name, rules, shouldUnregister, defaultValue, control })

    const labelsByValue = Object.fromEntries(
        selectProps.options.map((option) => [option.value, option.label as string])
    )

    const currentValueLabel = value ? labelsByValue[value as string] || (value as string) : ''

    return (
        <Select
            isOpen={isOpen}
            {...restSelectProps}
            onCancel={() => setIsOpen(false)}
            onSubmit={(option) => {
                setIsOpen(false)
                onChange(option.value)
                onSubmit?.(option)
            }}
        >
            <DropdownBaseInput
                id={id}
                variant={variant}
                forceFocused={isOpen}
                onClick={() => setIsOpen(true)}
                disabled={disabled}
                value={currentValueLabel ?? undefined}
                error={(error as any)?.message}
            />
        </Select>
    )
}

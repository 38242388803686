import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ExpandOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M15.793 9.957a1 1 0 001.414-1.414l-4.5-4.5a1 1 0 00-1.414 0l-4.5 4.5a1 1 0 001.414 1.414L12 6.164l3.793 3.793zM8.207 14.045a1 1 0 00-1.414 1.414l4.5 4.5a1 1 0 001.414 0l4.5-4.5a1 1 0 00-1.414-1.414L12 17.838l-3.793-3.793z" />
        </SvgIcon>
    )
}

import { logger } from '@sentry/utils'
import { useLocation } from 'react-router'

import { EmptyState } from '@laserfocus/ui/beam'

import { OnboardingLayout } from '../shared/OnboardingLayout'

const API_DISABLED_CODE = 'API_DISABLED_FOR_ORG'

export function ApiDisabledErrorPage() {
    const location = useLocation()
    const parsedQuery = new URLSearchParams(location?.search)
    const code = parsedQuery.get('code')
    if (code !== API_DISABLED_CODE) {
        logger.warn('Called Api disabled Page without code')
    }

    return (
        <OnboardingLayout>
            <EmptyState
                title="Your Salesforce Instance does not have API access."
                subtitle="API access depends on your Salesforce subscription as well as your configuration."
                emojis={['⚙️', '🌩', '🍭']}
                action={{
                    label: 'Check salesforce knowledge article',
                    href: 'https://help.salesforce.com/articleView?id=000327326&type=1&mode=1',
                }}
            />
        </OnboardingLayout>
    )
}

import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useSubscribe } from 'replicache-react'

import { Button, Card, EmptyState } from '@laserfocus/ui/beam'
import { AddOutlinedIcon, HiddenOutlinedIcon } from '@laserfocus/ui/icons'
import { FieldGroup } from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { makeQueryCanCreateFieldGroup } from '@laserfocus/client/data-access-shared'
import { LockedFeatureModal, useIsLocked } from '@laserfocus/client/feature-subscription'

import { FieldGroupFields } from './FieldGroupFields'
import { FieldGroupMoreActions, FieldSelection } from './FieldGroupActions'
import { FieldGroupNameInput } from './FieldGroupNameInput'
import { ActionOrgContext, useFieldgroupActions } from './useFieldgroupActions'

interface FieldGroupsProps {
    fieldGroups: FieldGroup[]
    isLoadingSobject: boolean
    isInOverlay: boolean
    orgOrUser: 'user' | 'org'
    rootObjectType: 'Lead' | 'Account'
    orgContext?: ActionOrgContext
}

export function FieldGroups({
    fieldGroups,
    isLoadingSobject,
    isInOverlay,
    rootObjectType,
    orgOrUser,
    orgContext,
}: FieldGroupsProps) {
    const { currentFieldGroup, setCurrentFieldGroup } = useCurrentFieldGroup(
        fieldGroups,
        rootObjectType
    )

    const {
        createFieldGroup,
        updateTitle,
        deleteFieldGroup,
        updateFieldGroupsOrder,
        addField,
        removeField,
        setFieldPosition,
        resetFieldGroup,
        activateFieldGroup,
        deactivateFieldGroup,
    } = useFieldgroupActions(rootObjectType, orgOrUser, currentFieldGroup, orgContext)
    const [isCreatingFieldGroup, setIsCreatingFieldGroup] = useState(false)
    const [isRenamingFieldGroup, setIsRenamingFieldGroup] = useState(false)

    const canCreateFieldgroup = useSubscribe(
        getClient(),
        makeQueryCanCreateFieldGroup(rootObjectType),
        true,
        []
    )
    const { isLocked, subscription } = useIsLocked(!canCreateFieldgroup)
    const [showLockedModal, setShowLockedModal] = useState(false)

    return (
        <Card>
            <div className="px-4 py-[0.625rem] border-b border-grey-700/10 grid grid-cols-3 gap-2">
                {fieldGroups.map(({ id, title, deactivated }) =>
                    isRenamingFieldGroup && id === currentFieldGroup?.id ? (
                        <FieldGroupNameInput
                            key={id}
                            fieldGroup={currentFieldGroup}
                            createFieldGroup={createFieldGroup}
                            updateTitle={updateTitle}
                            onEnd={() => setIsRenamingFieldGroup(false)}
                        />
                    ) : (
                        <FieldGroupButton
                            key={id}
                            id={id}
                            title={title}
                            setCurrentFieldGroup={() => setCurrentFieldGroup(id)}
                            fieldGroups={fieldGroups}
                            fieldGroup={currentFieldGroup}
                            startRenaming={() => setIsRenamingFieldGroup(true)}
                            orgOrUser={orgOrUser}
                            deleteFieldGroup={deleteFieldGroup}
                            updateFieldGroupsOrder={updateFieldGroupsOrder}
                            resetFieldGroup={resetFieldGroup}
                            activateFieldGroup={activateFieldGroup}
                            deactivateFieldGroup={deactivateFieldGroup}
                            deactivated={deactivated}
                        />
                    )
                )}
                {isCreatingFieldGroup ? (
                    <FieldGroupNameInput
                        createFieldGroup={createFieldGroup}
                        updateTitle={updateTitle}
                        onCreate={setCurrentFieldGroup}
                        onEnd={() => setIsCreatingFieldGroup(false)}
                    />
                ) : (
                    <Button
                        size="small"
                        variant="tertiary"
                        onClick={() =>
                            isLocked ? setShowLockedModal(true) : setIsCreatingFieldGroup(true)
                        }
                        iconComponent={AddOutlinedIcon}
                        className="justify-center"
                    >
                        Field group
                    </Button>
                )}
            </div>
            {currentFieldGroup ? (
                <>
                    <FieldSelection
                        fieldGroup={currentFieldGroup}
                        isInOverlay={isInOverlay}
                        addField={addField}
                        removeField={removeField}
                    />
                    <FieldGroupFields
                        isLoadingSobject={isLoadingSobject}
                        fieldGroup={currentFieldGroup}
                        setFieldPosition={setFieldPosition}
                        orgOrUser={orgOrUser}
                    />
                </>
            ) : (
                <div className="p-7">
                    <EmptyState title="No field groups" emojis={['🪲', '🐝', '🐛']} size="medium" />
                </div>
            )}
            <LockedFeatureModal
                title="Create Field group"
                lockedFeature="more than 3 Field groups"
                show={showLockedModal}
                closeModal={() => setShowLockedModal(false)}
                subscription={subscription}
            />
        </Card>
    )
}

function FieldGroupButton({
    id,
    title,
    setCurrentFieldGroup,
    fieldGroups,
    fieldGroup,

    startRenaming,
    orgOrUser,
    deleteFieldGroup,
    resetFieldGroup,
    updateFieldGroupsOrder,
    activateFieldGroup,
    deactivateFieldGroup,
    deactivated,
}: {
    id: string
    title: string
    setCurrentFieldGroup: () => void
    fieldGroup?: FieldGroup
    fieldGroups: FieldGroup[]
    startRenaming: () => void
    orgOrUser: 'user' | 'org'
    deleteFieldGroup(): Promise<unknown>
    resetFieldGroup?(): Promise<unknown>
    updateFieldGroupsOrder(reorders: Record<string, string>): Promise<unknown>
    activateFieldGroup?(): Promise<unknown>
    deactivateFieldGroup?(): Promise<unknown>
    deactivated: boolean
}) {
    const isCurrent = id === fieldGroup?.id
    const [isMoreOpen, setIsMoreOpen] = useState(false)
    return (
        <div className="group relative">
            <Button
                key={id}
                size="small"
                className={clsx(
                    'w-full transition-none',
                    isCurrent ? 'bg-grey-50' : 'text-grey-700/60 hover:text-grey-700',
                    deactivated && 'ring-grey-700/5 text-grey-700/40'
                )}
                iconComponent={deactivated ? HiddenOutlinedIcon : undefined}
                iconPosition="right"
                onClick={() => setCurrentFieldGroup()}
                title={title}
            >
                {deactivated && <span className="w-4 h-4" />}
                <span className="text-ellipsis whitespace-nowrap overflow-hidden">{title}</span>
            </Button>
            {isCurrent && (
                <div
                    className={clsx(
                        'group-hover:not-sr-only group-focus-within:not-sr-only',
                        !isMoreOpen && 'sr-only'
                    )}
                >
                    <div className="absolute top-0 right-0">
                        <div className="bg-grey-50 rounded-md">
                            <FieldGroupMoreActions
                                startRenaming={startRenaming}
                                fieldGroup={fieldGroup}
                                fieldGroups={fieldGroups}
                                isOpen={isMoreOpen}
                                setIsOpen={setIsMoreOpen}
                                orgOrUser={orgOrUser}
                                deleteFieldGroup={deleteFieldGroup}
                                resetFieldGroup={resetFieldGroup}
                                updateFieldGroupsOrder={updateFieldGroupsOrder}
                                activateFieldGroup={activateFieldGroup}
                                deactivateFieldGroup={deactivateFieldGroup}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const FIELD_GROUP_ID_LOCAL_STORAGE_KEY = 'lf:currentFieldGroupId'
// We cache the fieldGroupId in a global variable so we don't need to call `localStorage.getItem()` on every mount which is slow.
let currentGlobalFieldGroupIds: Partial<Record<'Lead' | 'Account', string>> = {}

function useCurrentFieldGroup(fieldGroups: FieldGroup[], rootObjectType: 'Lead' | 'Account') {
    const [currentFieldGroupId, setCurrentFieldGroupId] = useState<string | undefined>(() => {
        const id =
            currentGlobalFieldGroupIds[rootObjectType] ||
            localStorage.getItem(FIELD_GROUP_ID_LOCAL_STORAGE_KEY + rootObjectType)

        if (fieldGroups.some((group) => group.id === id)) {
            return id!
        }
        return fieldGroups[0]?.id
    })

    const currentFieldGroup =
        fieldGroups.find((group) => group.id === currentFieldGroupId) || fieldGroups[0]

    // Keeps localStorage and global variable in sync with local state
    useEffect(() => {
        currentGlobalFieldGroupIds[rootObjectType] = currentFieldGroupId
        localStorage.setItem(
            FIELD_GROUP_ID_LOCAL_STORAGE_KEY + rootObjectType,
            currentFieldGroupId || ''
        )
    }, [currentFieldGroupId, rootObjectType])

    // Corrects fieldGroup in local state if fieldGroups change
    useEffect(() => {
        const foundFieldGroup =
            fieldGroups.find((group) => group.id === currentFieldGroupId) || fieldGroups[0]
        if (foundFieldGroup?.id !== currentFieldGroupId) {
            setCurrentFieldGroupId(foundFieldGroup?.id)
        }
    }, [currentFieldGroupId, fieldGroups])

    function setCurrentFieldGroup(id: string) {
        setCurrentFieldGroupId(id)
    }

    return {
        currentFieldGroup,
        setCurrentFieldGroup,
    }
}

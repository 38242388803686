import assert from 'assert'

import { decodeTime, ulid } from 'ulid'

import { z } from '@laserfocus/shared/decoder'

const ULID_LEN = 10 + 16
const OPTIMISTIC_ID_LEN = ULID_LEN + 3

// --- General Optimistic Id
export function createOptimisticId(suffix: string): OptimisticId {
    assert(suffix.length === 3)
    const core = ulid()
    return `${core}${suffix}` as OptimisticId
}

export function isOptimisticId(id: string): id is OptimisticId {
    try {
        const ulid = id.length === OPTIMISTIC_ID_LEN ? id.substr(0, ULID_LEN) : id
        const timePortion = decodeTime(ulid)
        const lowerBound = new Date(2020, 0, 0)
        const upperBound = new Date(2050, 0, 0)
        return lowerBound.getTime() < timePortion && timePortion < upperBound.getTime()
    } catch (e: unknown) {
        return false
    }
}

export const OptimisticIdSchema = z
    .string()
    .refine((val: string) => isOptimisticId(val), {
        message: `This is not a valid OptimisticId`,
    })
    .transform((val: string) => val as OptimisticIdBrand)
export enum OptimisticIdBrand {
    _ = '',
}
export type OptimisticId = OptimisticIdBrand & string

// --- Task
export const isOptimisticTaskId = makeDomainIdCheck<OptimisticTaskId>('00T')
export function createOptimisticTaskId() {
    const core = ulid()
    return `${core}00T` as OptimisticTaskId
}
export const OptimisticTaskIdSchema = makeDomainIdSchema<OptimisticTaskId>('00T', 'Task')
export enum OptimisticTaskIdBrand {
    _ = '',
}
export type OptimisticTaskId = OptimisticTaskIdBrand & string

// --- Event
export const isOptimisticEventId = makeDomainIdCheck<OptimisticEventId>('00U')
export function createOptimisticEventId() {
    const core = ulid()
    return `${core}00U` as OptimisticEventId
}
export const OptimisticEventIdSchema = makeDomainIdSchema<OptimisticTaskId>('00U', 'Event')
export enum OptimisticEventIdBrand {
    _ = '',
}
export type OptimisticEventId = OptimisticEventIdBrand & string

// --- Lead
export const isOptimisticLeadId = makeDomainIdCheck<OptimisticLeadId>('00Q')
export function createOptimisticLeadId() {
    const core = ulid()
    return `${core}00Q` as OptimisticLeadId
}
export const OptimisticLeadIdSchema = makeDomainIdSchema<OptimisticLeadId>('00Q', 'Lead')
export enum OptimisticLeadIdBrand {
    _ = '',
}
export type OptimisticLeadId = OptimisticLeadIdBrand & string

// --- Utility

function splitString(str: string, index: number): [string, string] {
    if (index > str.length) {
        throw new Error(`Can't split string after its end: ${str}. Index: ${index}`)
    }
    return [str.substring(0, index), str.substring(index)]
}

export function makeDomainIdCheck<T extends string>(expectedSuffix: string) {
    return (id: string): id is T => {
        if (id.length === OPTIMISTIC_ID_LEN) {
            const [ulid, suffix] = splitString(id, ULID_LEN)
            return isOptimisticId(ulid) && suffix === expectedSuffix
        }
        return false
    }
}

export function makeDomainIdSchema<T extends string>(suffix: string, label?: string) {
    const isValue = makeDomainIdCheck<T>(suffix)
    return z
        .string()
        .refine((val: string) => isValue(val), {
            message: `This is not a valid Optimistic${label}Id`,
        })
        .transform((val) => val as T)
}

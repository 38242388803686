import { observer } from 'mobx-react-lite'

import { MultiSelectCell as BaseMultiSelectCell } from '@laserfocus/client/ui-shared-datagrid'

import { TableCellProps } from './TableCell'

export const MultiSelectCell = observer(function MultiSelectCell({
    salesObject,
    column,
    searchTerm,
    tableRef,
}: TableCellProps) {
    const options = column
        .getSelectOptions(salesObject)
        .map((option) => ({ ...option, label: option.label || '' }))

    return (
        <BaseMultiSelectCell
            options={options}
            value={column.getValue(salesObject) as string[] | string | null}
            searchTerm={searchTerm}
            name={column.key}
            updateValue={(value) => column.updateValue(salesObject, value)}
            readOnly={!column.isEditable(salesObject)}
            tableRef={tableRef}
        />
    )
})

/**
 * Code from https://github.com/mui-org/material-ui/blob/v5.0.0-alpha.20/packages/material-ui-utils/src/useForkRef.js
 */
import { useMemo } from 'react'

export function useForkRef<T>(
    refA: React.Ref<T> | undefined,
    refB: React.Ref<T> | undefined,
    refC?: React.Ref<T> | undefined
): React.RefCallback<T> | null {
    /**
     * This will create a new function if the ref props change and are defined.
     * This means react will call the old forkRef with `null` and the new forkRef
     * with the ref. Cleanup naturally emerges from this behavior.
     */
    return useMemo(() => {
        if (!refA && !refB && !refC) {
            return null
        }
        return (refValue) => {
            setRef(refA, refValue)
            setRef(refB, refValue)
            setRef(refC, refValue)
        }
    }, [refA, refB, refC])
}

function setRef<T>(
    ref: React.MutableRefObject<T | null> | ((instance: T | null) => void) | null | undefined,
    value: T | null
): void {
    if (typeof ref === 'function') {
        ref(value)
    } else if (ref) {
        ref.current = value
    }
}

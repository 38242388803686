import 'reflect-metadata'
import { computed, observable, runInAction } from 'mobx'
import { ReadTransaction, Replicache } from 'replicache'

import type { Identity } from '@laserfocus/shared/models'
import { Prefix } from '@laserfocus/shared/models'
import { Analytics } from '@laserfocus/client/util-analytics'
import { setUser } from '@laserfocus/ui/error-reporting'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { ObjectPool } from '@laserfocus/client/data-layer'

import { STORAGE_USERID_KEY } from './auth-storage'

export type SerializedSessionStore = {
    version: number
    me: Identity | null
    cachedUserId: string | null
}

export type RootStoreInjection = {
    sessionStore?: SessionStore
}

export function useSessionStore(): SessionStore | undefined {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.sessionStore
}

export class SessionStore {
    replicache: Replicache
    reactions: Array<() => void> = []

    objectPool: ObjectPool

    @observable me: Identity | null
    @observable isAuthenticated: boolean = false
    @observable didTrackUser = false

    constructor(objectPool: ObjectPool, replicache: Replicache) {
        this.replicache = replicache
        this.objectPool = objectPool
    }

    @computed
    get myId() {
        return this.me?.Id || localStorage.getItem(STORAGE_USERID_KEY)
    }

    onInit() {
        this.reactions.push(
            this.replicache.subscribe(
                (tx: ReadTransaction) => tx.get(Prefix.Identity) as Promise<Identity>,
                {
                    onData: (me: Identity | null) => {
                        runInAction(() => {
                            me && this.objectPool.attach(me)
                            if (me && !this.didTrackUser) {
                                this.didTrackUser = true
                                Analytics.identifyFromSF(me)
                                setUser({
                                    id: me.Id,
                                    username: me.Username ?? undefined,
                                    orgId: me.OrgId,
                                    email: me.Email ?? undefined,
                                })
                                Analytics.trackEvent({
                                    event: 'user_login_fe',
                                })
                                this.me = me
                                localStorage.setItem(STORAGE_USERID_KEY, me.Id)
                            }
                        })
                    },
                }
            )
        )
    }

    onDestroy() {
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }
}

import type { Control } from 'react-hook-form'

import type { FieldMetadata } from '@laserfocus/client/model'
import { AccountModel, ContactModel, LeadModel, OpportunityModel } from '@laserfocus/shared/models'

import { FormControl } from './FormControl'

export type SalesObject = ContactModel | LeadModel | OpportunityModel | AccountModel

export interface FormControlProps<T extends SalesObject = SalesObject> {
    fieldMetadata: FieldMetadata
    id: string
    variant?: 'border'
    control: Control<T>
    submit?: () => void
    name?: string
}

export function getFormControl<T extends SalesObject = SalesObject>(props: FormControlProps<T>) {
    switch (props.fieldMetadata.fieldType) {
        case 'id':
            return <FormControl.String {...props} />
        case 'string':
            if (props.fieldMetadata.length && props.fieldMetadata.length > 120) {
                return <FormControl.Textarea {...props} />
            }
            return <FormControl.String {...props} />
        case 'textarea':
            return <FormControl.Textarea {...props} allowLineBreaks />
        case 'email':
            return <FormControl.Email {...props} />
        case 'phone':
            return <FormControl.Phone {...props} />
        case 'url':
            return <FormControl.Url {...props} />
        case 'int':
            return <FormControl.Int {...props} />
        case 'percent':
            return <FormControl.Percent {...props} />
        case 'double':
            return <FormControl.Double {...props} />
        case 'currency':
            return <FormControl.Currency {...props} />
        case 'boolean':
            return <FormControl.Boolean {...props} />
        case 'picklist':
            return <FormControl.Picklist {...props} />
        case 'multipicklist':
            return <FormControl.Multipicklist {...props} />
        case 'reference': {
            // return <FormControl.Picklist {...props} />
            if (props.fieldMetadata.isSearchReference) {
                return <FormControl.Reference {...props} />
            }
            if (props.fieldMetadata.isReferenceList) {
                return <FormControl.Picklist {...props} />
            }
            return <FormControl.String {...props} />
        }
        case 'time':
            return <FormControl.Time {...props} />
        case 'date':
            return <FormControl.Date {...props} />
        case 'datetime':
            return <FormControl.Datetime {...props} />
        case 'address':
            return <FormControl.Address {...props} />
        default:
            console.warn(`Could not find FormControl for ${props.fieldMetadata.fieldType}`)
            return null
    }
}

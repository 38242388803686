import { toast } from '@laserfocus/ui/beam'
import { getActivityCreateMessage } from '@laserfocus/client/util-formatter'
import { Analytics } from '@laserfocus/client/util-analytics'
import { useUserId } from '@laserfocus/client/feature-auth'
import { scheduleTask } from '@laserfocus/client/data-access-shared'
import { dateToDateString } from '@laserfocus/shared/models'
import { logger } from '@laserfocus/ui/logger'

import { StepSelectDate, StepSelectDateState, StepSetTitle, StepSetTitleState } from '../steps'
import { StepModal } from '../StepModal'

interface AddTaskToParentModalProps {
    onCancel(): void
    onSubmit(): void
    whoId?: string
    whatId?: string
    accountId?: string
    name?: string
}

export function AddTaskToParentModal({
    onCancel,
    onSubmit,
    whoId,
    whatId,
    accountId,
    name,
}: AddTaskToParentModalProps) {
    const ownerId = useUserId()

    return (
        <StepModal
            onCancel={onCancel}
            onSubmit={([{ selectedTitle }, { selectedDate }]: readonly [
                StepSetTitleState,
                StepSelectDateState
            ]) => {
                onSubmit()
                scheduleTask({
                    subject: selectedTitle,
                    date: dateToDateString(selectedDate),
                    dateTime: selectedDate.toISOString(),
                    whoId,
                    whatId,
                    accountId,
                    ownerId: ownerId ?? undefined,
                }).then(
                    () => {
                        toast.success(getActivityCreateMessage(selectedDate, name))
                        Analytics.trackEvent({
                            event: 'activity_scheduled',
                            location: Analytics.getLocation() as any,
                        })
                    },
                    (error) => {
                        logger.error(error)
                        toast.error({ title: error.message || error })
                    }
                )
            }}
            steps={
                [
                    {
                        title: 'Task title',
                        component: StepSetTitle,
                    },
                    {
                        title: 'Task date',
                        component: StepSelectDate,
                    },
                ] as const
            }
        />
    )
}

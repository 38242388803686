import { orderBy } from 'lodash'

import { createNote, pinNote } from '@laserfocus/client/data-access-shared'
import { Button } from '@laserfocus/ui/beam'
import { AddOutlinedIcon } from '@laserfocus/ui/icons'
import { OptimisticNoteId } from '@laserfocus/shared/models'
import { useUserId } from '@laserfocus/client/feature-auth'
import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'

import { useCurrentOpportunity, useRootObject } from '../modules/PersonContext'

import { getDraftNoteProps, getNoteProps } from './note-props'
import type { ClientNote } from './useRootNotes'
import { Note } from './Note'
import LoadingNotes from './LoadingNotes'
import { OptimisticClientNote, UpdateNoteValues } from './useNewNote'

interface NotesListProps {
    notes: ClientNote[]
    isLoadingFull: boolean
    remainingNotes: OptimisticClientNote[]
    newNote?: OptimisticClientNote
    deleteNote(id: OptimisticNoteId): void
    updateNote(id: OptimisticNoteId, values: UpdateNoteValues): void
    createNote(): void
    isDisabled?: boolean
}

export function NotesList({
    notes,
    isLoadingFull,
    newNote,
    remainingNotes,
    deleteNote: deleteDraftNote,
    updateNote: updateDraftNote,
    createNote: createDraftNote,
    isDisabled,
}: NotesListProps) {
    const rootObject = useRootObject()
    const currentOpportunity = useCurrentOpportunity()
    const userId = useUserId()
    const { location } = useAnalyticsContext()

    if (isDisabled) {
        return <NotesDisabled />
    }

    if (isLoadingFull || !rootObject) {
        return <LoadingNotes />
    }

    if (notes.length === 0 && !newNote) {
        return (
            <div className="rounded-[0.625rem] hover:bg-grey-700/5 transition-colors px-2 py-4 text-sm text-center grid gap-3 place-items-center">
                You do not have any notes yet.
                <Button
                    inline
                    size="small"
                    iconComponent={AddOutlinedIcon}
                    onClick={createDraftNote}
                >
                    Add first note
                </Button>
            </div>
        )
    }

    const persistedOptimisticIds = notes.map((a) => a.optimisticId).filter(Boolean)
    const remainingUnpersistedNotes = remainingNotes.filter(
        (a) => !persistedOptimisticIds.includes(a.optimisticId)
    )
    const remainingUnpersistedPinned = remainingUnpersistedNotes.filter((a) => a.isPinned)
    const remainingUnpersistedNotPinned = remainingUnpersistedNotes.filter((a) => !a.isPinned)

    const sortedNotes = orderBy(notes, 'CreatedDate', 'desc')
    const newNoteInNotes = notes.find(
        (n) => newNote?.optimisticId && n.optimisticId === newNote?.optimisticId
    )
    const pinnedNotes = sortedNotes.filter(
        (a) => a.isPinned && (a.optimisticId !== newNote?.optimisticId || !a.optimisticId)
    )
    const unpinnedNotes = sortedNotes.filter(
        (a) => !a.isPinned && (a.optimisticId !== newNote?.optimisticId || !a.optimisticId)
    )

    const showDivider =
        (pinnedNotes.length > 0 || remainingUnpersistedPinned.length > 0) &&
        (unpinnedNotes.length > 0 || remainingUnpersistedNotPinned.length > 0)

    return (
        <div className="grid gap-4">
            {newNote &&
                (newNoteInNotes ? (
                    <Note
                        key={newNoteInNotes.optimisticId || newNoteInNotes.Id}
                        currentId={newNoteInNotes.Id}
                        {...getNoteProps(
                            newNoteInNotes,
                            rootObject.Id,
                            deleteDraftNote,
                            location ?? undefined
                        )}
                    />
                ) : (
                    <Note
                        key={newNote.optimisticId}
                        currentId={newNote.optimisticId}
                        {...getDraftNoteProps(
                            newNote,
                            rootObject.Id,
                            updateDraftNote,
                            deleteDraftNote
                        )}
                        setTitle={async (title) => {
                            if (newNote.didSubmitNote || !userId) {
                                updateDraftNote(newNote.optimisticId, { Title: title })
                                return
                            }

                            updateDraftNote(newNote.optimisticId, {
                                didSubmitNote: true,
                                Title: title,
                            })
                            await createNote(
                                {
                                    Title: title,
                                    Content: newNote.Body,
                                    SharingPrivacy: newNote.SharingPrivacy,
                                },
                                currentOpportunity
                                    ? [rootObject.Id, currentOpportunity.Id]
                                    : [rootObject.Id],
                                { userId: userId as any, optimisticId: newNote.optimisticId }
                            )
                            Analytics.trackEvent({
                                event: 'note_created',
                                location: location || 'person_details',
                                title: title,
                            })
                            if (newNote.isPinned) {
                                pinNote(rootObject.Id, newNote.optimisticId)
                            }
                        }}
                        focusOnMount
                    />
                ))}
            {[
                // These all need to be in a single array in order to swap order without unmounting
                ...remainingUnpersistedPinned.map((note) => (
                    <Note
                        key={note.optimisticId}
                        currentId={note.optimisticId}
                        {...getDraftNoteProps(
                            note,
                            rootObject.Id,
                            updateDraftNote,
                            deleteDraftNote
                        )}
                    />
                )),
                ...pinnedNotes.map((note) => {
                    return (
                        <Note
                            key={note.optimisticId || note.Id}
                            currentId={note.Id}
                            {...getNoteProps(
                                note,
                                rootObject.Id,
                                deleteDraftNote,
                                location ?? undefined
                            )}
                        />
                    )
                }),
                showDivider && <div key="divider" className="h-px w-full bg-grey-700/10" />,
                ...remainingUnpersistedNotPinned.map((note) => (
                    <Note
                        key={note.optimisticId}
                        currentId={note.optimisticId}
                        {...getDraftNoteProps(
                            note,
                            rootObject.Id,
                            updateDraftNote,
                            deleteDraftNote
                        )}
                    />
                )),
                ...unpinnedNotes.map((note) => {
                    return (
                        <Note
                            key={note.optimisticId || note.Id}
                            currentId={note.Id}
                            {...getNoteProps(
                                note,
                                rootObject.Id,
                                deleteDraftNote,
                                location ?? undefined
                            )}
                        />
                    )
                }),
            ]}
        </div>
    )
}

function NotesDisabled() {
    return (
        <div className="prose px-2 py-4 ">
            <h4 className="font-semibold leading-[1.4]">
                Notes are disabled for your organization
            </h4>
            <p>
                It seems that your organization needs to enable notes. Contact your salesforce admin
                and provide him with this{' '}
                <a
                    href="https://help.salesforce.com/s/articleView?id=sf.notes_setup_enable.htm&type=5"
                    target="_blank"
                    rel="noreferrer"
                >
                    help article
                </a>{' '}
                to enable notes.
            </p>
            <p>
                If enabling notes is not an option, please reach out to{' '}
                <a href="mailto:founders@laserfocus.io">founders@laserfocus.io</a> so we can enable
                an alternative solution for you.
            </p>
        </div>
    )
}

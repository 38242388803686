import { z } from '@laserfocus/shared/decoder'

import { OrgIdSchema, UserIdSchema } from '../base/id.types'
import { DateTimeStringSchema } from '../base/date.types'

export type User = z.infer<typeof UserSchema> & { Id: string; __typename: 'User' }

export const UserSchema = z.object({
    Id: UserIdSchema,
    Username: z.string().nullable(),
    Name: z.string().nullable(),
    FirstName: z.string().nullable(),
    LastName: z.string().nullable(),
    LastModifiedDate: DateTimeStringSchema,
    CreatedDate: DateTimeStringSchema,
    TimeZoneSidKey: z.string().nullable(),
    IsActive: z.boolean(),
    UserType: z.union([
        z.string(),
        z.literal('Standard'),
        z.literal('PowerPartner'),
        z.literal('CSPLitePortal'),
        z.literal('CustomerSuccess'),
        z.literal('PowerCustomerSuccess'),
        z.literal('CsnOnly'),
    ]),
    // Added for Colleague invites (to sort them)
    CompanyName: z.string().nullish(),
    Title: z.string().nullish(),
    Department: z.string().nullish(),
    Division: z.string().nullish(),
    Country: z.string().nullish(),
    City: z.string().nullish(),

    LastLoginDate: DateTimeStringSchema.nullish(),
    ProfileId: z.string().nullish(),
    UserRoleId: z.string().nullish(),
    __typename: z.literal('User').default('User'),
})

/**
 * Recipient extends the User with the Email, but can only be used on the
 * backend side, to not expose the Email
 */
export const RecipientSchema = UserSchema.extend({
    Email: z.string().nullable(),
    OrgId: OrgIdSchema,
})
export type Recipient = z.infer<typeof RecipientSchema>

export const IdentitySchema = UserSchema.omit({
    TimeZoneSidKey: true,
    LastModifiedDate: true,
    CreatedDate: true,
    IsActive: true,
    UserType: true,
    __typename: true,
}).extend({
    OrgId: OrgIdSchema,
    InstanceUrl: z.string(),
    Email: z.string().email().nullable(),
    CreatedAt: DateTimeStringSchema.nullish(),
    __typename: z.literal('Identity').default('Identity'),
})

export type Identity = z.infer<typeof IdentitySchema> & { Id: string; __typename: 'Identity' }

import { useEffect, useState } from 'react'

import { Column, ColumnsList, TableTitleInput } from '@laserfocus/client/feature-table'
import { Button, Dropdown, Select } from '@laserfocus/ui/beam'
import { ColumnsOutlinedIcon, SaveOutlinedIcon } from '@laserfocus/ui/icons'
import { HintDisplay } from '@laserfocus/client/feature-everboarding'
import { EmojiAvatar } from '@laserfocus/client/ui-shared'

import { Hint, useTableHintsContext } from '../TableHintsContext'

interface ColumnsSection {
    label: string
    columns: Column[]
}

interface TableHeaderProps {
    title: string
    setTitle(title: string): void
    saveView(title: string): void
    columnSections: ColumnsSection[]
}

export function TableHeader({ title, setTitle, saveView, columnSections }: TableHeaderProps) {
    const [innerTitle, setInnerTitle] = useState(title)
    const [innerTitleNewStack, setInnerTitleNewStack] = useState('')
    const [isCreatingNewStack, setIsCreatingNewStack] = useState(false)
    const [isColumnsDropdownOpen, setIsColumnsDropdownOpen] = useState(false)
    const [isSaveStackSelectOpen, setIsSaveStackSelectOpen] = useState(false)

    const { currentHint, hideHint } = useTableHintsContext()

    useEffect(() => {
        setInnerTitle(title)
    }, [title])

    function handleColumnButtonClick() {
        setIsColumnsDropdownOpen((v) => !v)
        hideHint(isColumnsDropdownOpen ? Hint.CloseColumnsDropdown : Hint.AddMoreColumns)
    }

    const shouldShowUnsavedViewButton =
        currentHint === Hint.SaveView || currentHint === Hint.FinishSavingView

    return (
        <div className="pt-6 pl-[0.6875rem] pr-6 pb-4 grid grid-flow-col gap-1 items-center grid-cols-[auto,minmax(0,1fr),auto]">
            <EmojiAvatar
                size={40}
                emoji="🌎"
                className="w-10 h-10 grid place-items-center rounded-full bg-grey-700/5"
            />

            <div className="grid items-center grid-flow-col justify-start gap-3">
                <h2 className="font-serif font-bold text-2xl leading-none mr-auto">
                    {isCreatingNewStack ? (
                        <TableTitleInput
                            value={innerTitleNewStack}
                            placeholder={title}
                            focusOnMount
                            onValueChange={setInnerTitleNewStack}
                            onBlur={() => {
                                const nextTitle = innerTitleNewStack || title
                                setInnerTitle(nextTitle)
                                setInnerTitleNewStack('')
                                setIsCreatingNewStack(false)
                                saveView(nextTitle)
                                hideHint(Hint.FinishSavingView)
                            }}
                        />
                    ) : (
                        <TableTitleInput
                            value={innerTitle}
                            placeholder="Stack name"
                            onValueChange={setInnerTitle}
                            onBlur={(event) => {
                                if (event.currentTarget.innerText === '') {
                                    setInnerTitle(title)
                                } else if (innerTitle !== title) {
                                    setTitle(innerTitle)
                                }
                            }}
                        />
                    )}
                </h2>
                {shouldShowUnsavedViewButton && (
                    <Select
                        isOpen={isSaveStackSelectOpen}
                        options={[
                            {
                                iconComponent: SaveOutlinedIcon,
                                label: 'Save stack',
                                value: 'save-stack',
                                action: () => setIsCreatingNewStack(true),
                            },
                        ]}
                        onCancel={() => setIsSaveStackSelectOpen(false)}
                        onSubmit={(option) => {
                            setIsSaveStackSelectOpen(false)
                            option.action()
                        }}
                    >
                        <HintDisplay
                            isActive={currentHint === Hint.SaveView}
                            label="Save stack"
                            containerClassName="-translate-y-8"
                            labelClassName="rotate-12 translate-y-4 translate-x-4"
                        >
                            <Button
                                size="small"
                                variant="tertiary"
                                onClick={() => {
                                    setIsSaveStackSelectOpen(!isSaveStackSelectOpen)
                                    !isSaveStackSelectOpen && hideHint(Hint.SaveView)
                                }}
                                className="bg-grey-700/5 hover:bg-grey-700/10"
                            >
                                Unsaved stack
                            </Button>
                        </HintDisplay>
                    </Select>
                )}
            </div>
            <Dropdown
                isOpen={isColumnsDropdownOpen}
                content={<ColumnsList columnSections={columnSections} hideIcons />}
                onCancel={() => {
                    setIsColumnsDropdownOpen(false)
                    hideHint(Hint.CloseColumnsDropdown)
                }}
            >
                <HintDisplay
                    isActive={currentHint === Hint.AddMoreColumns}
                    label="Show more fields"
                    containerClassName="-translate-y-8"
                    labelClassName="rotate-[25deg] translate-y-4 -translate-x-4"
                    onClick={handleColumnButtonClick}
                >
                    <Button
                        iconComponent={ColumnsOutlinedIcon}
                        onClick={handleColumnButtonClick}
                        className="relative"
                    >
                        Fields
                    </Button>
                </HintDisplay>
            </Dropdown>
        </div>
    )
}

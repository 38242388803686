import { useObserver } from 'mobx-react-lite'
import { Control } from 'react-hook-form'

import { useMetadataStore } from '@laserfocus/client/store-shared'
import { FieldMetadata } from '@laserfocus/client/model'
import { getFormControl } from '@laserfocus/client/shared-sales-object-form-control'
import { isTruthy } from '@laserfocus/shared/models'

import { ConvertLeadFormState } from './ConvertLeadModal'

export function MetadataField({
    fieldMetadata,
    control,
    name,
    label,
    required,
}: {
    fieldMetadata: FieldMetadata
    control: Control<ConvertLeadFormState>
    name: string
    required?: boolean
    label?: string
}) {
    const id = `convert-${name}`
    const formControl = getFormControl({
        id,
        control: control as Control<any>,
        fieldMetadata,
        name,
        variant: 'border',
    })
    const fieldRequired = typeof required !== 'undefined' ? required : fieldMetadata.required
    return (
        <>
            <label
                htmlFor={id}
                // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
                className="text-sm leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
            >
                {label || fieldMetadata.label}
                {fieldRequired && (
                    <span title="Required" className="px-1 -mr-1 select-none text-red-500">
                        •
                    </span>
                )}
            </label>
            {formControl}
        </>
    )
}

export function useFields(
    sobject: 'Lead' | 'Account' | 'Opportunity' | 'Contact',
    fieldNames: string[]
) {
    const metadataStore = useMetadataStore()
    const fields = useObserver(() =>
        fieldNames
            .map((name) => {
                return metadataStore?.getField(sobject, name)
            })
            .filter(isTruthy)
    )
    return fields
}

export function useField(
    sobject: 'Lead' | 'Account' | 'Opportunity' | 'Contact',
    fieldName: string
) {
    const metadataStore = useMetadataStore()
    const field = useObserver(() => metadataStore?.getField(sobject, fieldName))
    return field
}

import { KeyOption, matchSorter } from 'match-sorter'
import { useState } from 'react'

import { useAutoFocusedRef } from '@laserfocus/ui/util-react'

import { DropdownInput } from '../controls/DropdownInput'

export type SearchKey<T> = KeyOption<T>
interface SearchableListProps<T> {
    elements: readonly T[]
    searchKeys: readonly SearchKey<T>[]
    renderInput?({
        searchValue,
        setSearchValue,
    }: {
        searchValue: string
        setSearchValue: React.Dispatch<React.SetStateAction<string>>
    }): React.ReactNode
    children(filteredElements: readonly T[], searchValue: string): React.ReactNode
}

// Keep Original Order
type ArrayElement = { index: number }
const defaultBaseSorter = (a: ArrayElement, b: ArrayElement) => (a.index < b.index ? -1 : 1)

export function SearchableList<T>({
    elements,
    searchKeys,
    children,
    renderInput,
}: SearchableListProps<T>) {
    const [searchValue, setSearchValue] = useState('')

    const filteredElements = searchValue
        ? matchSorter(elements, searchValue, {
              keys: searchKeys,
              baseSort: defaultBaseSorter,
          })
        : elements

    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)

    return (
        <>
            <div className="px-2 pt-2">
                {renderInput ? (
                    renderInput({ searchValue, setSearchValue })
                ) : (
                    <DropdownInput
                        ref={inputRef}
                        placeholder="Search"
                        aria-label="Search"
                        value={searchValue}
                        onChange={(event) => setSearchValue(event.target.value)}
                        className="w-full"
                    />
                )}
            </div>
            {children(filteredElements, searchValue)}
        </>
    )
}

import { difference, intersection } from 'lodash'

import { Analytics } from '@laserfocus/client/util-analytics'
import { toast } from '@laserfocus/ui/beam'
import { logger } from '@laserfocus/ui/logger'
import { isTruthy } from '@laserfocus/shared/models'
import { useUserId } from '@laserfocus/client/feature-auth'

import { useBulkEdit } from '../bulkedit-context'
import { Column, Row } from '../../table-context'
import { BulkConfirm } from '../BulkEditConfirm'
import {
    getValueLabel,
    hasMultiselectMultipleValues,
    parseMultiSelectValues,
    parseRecordMultiselectValues,
} from '../util-column-value'

import { BulkEditMultiSelectCell } from './BulkEditMultiSelectCell'
import { BulkEditNumberCell } from './BulkEditNumberCell'
import { BulkEditSelectCell } from './BulkEditSelectCell'
import { BulkEditSwitchCell } from './BulkEditSwitchCell'
import { BulkEditTextCell } from './BulkEditTextCell'
import { BulkEditDateCell } from './BulkEditDateCell'
import { BulkEditTimeCell } from './BulkEditTimeCell'
import { makeActivityUpdate, VALID_COLUMNS } from './custom/task-cell-update'

export const MULTIPLE_VALUES = '_multiple_'

export function BulkEditCell({ column }: { column: Column }) {
    const { selectedRecords, setConfirm } = useBulkEdit()
    const props = useBulkEditCellProps(column, selectedRecords, setConfirm)
    if (column.isStickyLeft) {
        return (
            <span className="whitespace-nowrap ml-4 text-sm text-white/60">
                {selectedRecords.length} selected to bulk edit
            </span>
        )
    }

    if (!selectedRecords.length) {
        return <span />
    }
    switch (props.column.key) {
        case 'Account.LF_OpenInSalesforce':
        case 'Lead.LF_OpenInSalesforce':
        case 'Contact.LF_OpenInSalesforce':
        case 'Opportunity.LF_OpenInSalesforce':
        case 'LF_OpenInSalesforce':
            return <span />
    }

    switch (props.column.type) {
        case 'string':
            if (column.fieldLength && column.fieldLength > 120) {
                return <BulkEditTextCell {...props} multiline />
            }
            return <BulkEditTextCell {...props} />
        case 'textarea':
            return <BulkEditTextCell {...props} multiline allowLineBreaks />
        case 'datetime':
            return <BulkEditDateCell {...props} />
        case 'date':
            return <BulkEditDateCell {...props} dateOnly />
        case 'boolean':
            return <BulkEditSwitchCell {...props} />
        case 'picklist':
            return <BulkEditSelectCell {...props} />
        case 'multipicklist':
            return <BulkEditMultiSelectCell {...props} />
        case 'reference':
            return props.column.isReferenceList ? (
                <BulkEditSelectCell {...props} />
            ) : (
                <BulkEditTextCell {...props} isEditable={false} />
            )
        case 'int':
        case 'double':
        case 'currency':
        case 'percent':
            return <BulkEditNumberCell {...props} />
        case 'time':
            return <BulkEditTimeCell {...props} />
        case 'email':
        case 'url':
        case 'phone':
            return <BulkEditTextCell {...props} />
    }

    return <span />
}

export interface BulkEditCellProps {
    updateValue(value: any): Promise<unknown>
    isEditable: boolean
    column: Column
    value: any
    hasMultipleValues: boolean
}

function useBulkEditCellProps(
    column: Column,
    selectedRecords: Row[],
    setConfirm: (confirm: BulkConfirm) => void
): BulkEditCellProps {
    const userId = useUserId()
    const values = [...new Set(selectedRecords.map((record) => column.getValue(record)))]
    const value =
        column.type === 'multipicklist'
            ? parseMultiSelectValues(values as string[])
            : values.length === 1
            ? [...values][0]
            : undefined

    async function updateValue(updatedValue: any) {
        let tasks: Array<() => Promise<unknown>> = []
        let recordCount = 0
        if (column.type === 'multipicklist') {
            const added = difference(updatedValue as string[], value as string[])
            const removed = difference(value as string[], updatedValue as string[])

            const records = selectedRecords
                .filter((record) => column.isEditable(record))
                .map((record) => {
                    const current = parseRecordMultiselectValues(
                        column.getValue(record) as string[] | string | null
                    )
                    let changed = false
                    let update = parseRecordMultiselectValues(current)
                    const addedToThis = difference(added, current)
                    const removedFromThis = intersection(removed, current)
                    if (addedToThis.length) {
                        changed = true
                        update = [...update, ...addedToThis]
                    }
                    if (removedFromThis.length) {
                        changed = true
                        update = [...update].filter((a) => !removedFromThis.includes(a))
                    }
                    if (changed) {
                        return {
                            record,
                            update,
                        }
                    }
                    return null
                })
                .filter(isTruthy)
                .map(
                    ({ record, update }) =>
                        () =>
                            column.updateValue(record, update)
                )
            recordCount = records.length
        } else if (VALID_COLUMNS.includes(column.key)) {
            tasks = selectedRecords
                .filter((record) => column.isEditable(record))
                .filter((record) => {
                    const v = column.getValue(record)
                    if (isEmpty(v) && isEmpty(updatedValue)) {
                        return false
                    }
                    return column.getValue(record) !== updatedValue
                })
                .map((record) => () => makeActivityUpdate(column, record, updatedValue, userId!))
            recordCount = tasks.length
        } else {
            //TODO: potentially filter for recordtype and not allowed updates on picklists
            const records = selectedRecords
                .filter((record) => column.isEditable(record))
                .filter((record) => {
                    const v = column.getValue(record)
                    if (isEmpty(v) && isEmpty(updatedValue)) {
                        return false
                    }
                    return column.getValue(record) !== updatedValue
                })
                .filter(isTruthy)
            // .map((record) => () => column.updateValue(record, updatedValue))
            recordCount = records.length
            tasks.push(() => column.bulkUpdateValue(records, updatedValue))
        }

        if (!tasks.length) {
            return
        }

        async function confirm() {
            try {
                const promises = tasks.map((t) => t())
                const settledPromises = await Promise.allSettled(promises)

                Analytics.trackEvent({
                    event: 'table_bulk_edited',
                    payload: {
                        amount: selectedRecords.length,
                        fieldName: column.key,
                        fieldType: column.type,
                    },
                })

                const rejectedPromises =
                    // This seems to be a bug, since I have to typecast to the type it is. The build script thinks its a { [x: string]: PromiseSettledResult<any>; }
                    (settledPromises as unknown as PromiseSettledResult<unknown>[]).filter(
                        isRejected
                    )
                const rejectedAmount = rejectedPromises.length

                if (rejectedAmount) {
                    logger.error(rejectedPromises)
                    toast.error({
                        title: `Could not complete ${rejectedAmount} ${
                            rejectedAmount === 1 ? 'update' : 'updates'
                        }`,
                    })
                } else {
                    toast.success({ title: `Updating ${recordCount} records` })
                }
            } catch (error: any) {
                logger.error(error)
                toast.error({
                    title: 'Something went wrong',
                    // description: error?.message || error,
                })
            }
        }

        const confirmModal: BulkConfirm = {
            confirm,
            recordCount,
            fieldLabel: column.label,
            valueLabel: getValueLabel(column, updatedValue),
        }
        setConfirm(confirmModal)
    }

    const isEditable = selectedRecords.some((record) => column.isEditable(record))

    return {
        isEditable,
        hasMultipleValues:
            column.type === 'multipicklist'
                ? hasMultiselectMultipleValues(values as string[][] | string[])
                : values.length > 1,
        updateValue,
        column,
        value,
    }
}

function isEmpty(v: unknown) {
    if (typeof v !== 'number' && v !== 'boolean') {
        return !v
    }
    return false
}

function isRejected<T>(p: PromiseSettledResult<T>): p is PromiseRejectedResult {
    return p.status === 'rejected'
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

/** 16px version */
export function UnderlineIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 32 32" width={16} height={16} {...props}>
            <path d="M4 26H28V28H4zM16 23a7 7 0 01-7-7V5h2V16a5 5 0 0010 0V5h2V16A7 7 0 0116 23z" />
        </SvgIcon>
    )
}

import { action, computed, observable, reaction, runInAction } from 'mobx'

import { isTruthy } from '@laserfocus/shared/models'

import { trace } from './util-trace'
import { ObjectPool, StoredModel, getId } from './ObjectPool'

type Matcher<T> = (model: T, context: QueryContext) => boolean
type Sorter<T> = (models: T[], context: QueryContext) => T[]

type QueryContext = {
    userId?: string
}

type QueryConfig<T extends StoredModel> = {
    rootObject: 'Task' | 'Event'
    match: Matcher<T>
    sorter?: Sorter<T>
    name?: string
}

export class PoolQuery<T extends StoredModel> {
    config: QueryConfig<T>

    objectPool: ObjectPool

    reactions: Array<() => void> = []

    @observable matchedIds: Set<string> = new Set()

    constructor(objectPool: ObjectPool, queryConfig: QueryConfig<T>) {
        this.config = queryConfig
        this.objectPool = objectPool
    }

    @computed
    get context() {
        const me = this.objectPool.getSingle<{ Id: string; __typename: 'Identity' }>('Identity')
        return {
            userId: me?.Id,
        }
    }

    onInit() {
        this.reactions.push(
            this.objectPool.onUpdated<T>(this.config.rootObject, (current, previous, changeSet) => {
                //@TODO: could add development checks to find issues
                if (
                    this.matchedIds.has(current.Id) &&
                    !this.config.match(current as T, this.context)
                ) {
                    runInAction(() => {
                        this.matchedIds.delete(current.Id)
                    })
                } else if (
                    !this.matchedIds.has(current.Id) &&
                    this.config.match(current as T, this.context)
                ) {
                    runInAction(() => {
                        this.matchedIds.add(current.Id)
                    })
                }
            })
        )
        this.reactions.push(
            this.objectPool.onCreated(this.config.rootObject, (model) => {
                if (this.config.match(model as T, this.context)) {
                    runInAction(() => {
                        this.matchedIds.add(model.Id)
                    })
                }
            })
        )
        this.reactions.push(
            this.objectPool.onDeleted(this.config.rootObject, (record) => {
                this.removeFromQuery(getId(record))
            })
        )
        this.reactions.push(reaction(() => this.context, this.refresh.bind(this)))
    }

    onDestroy() {
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    @action
    refresh() {
        trace(
            () => {
                const all = this.objectPool.getAll(this.config.rootObject)
                const filtered = Array.from(all.values()).filter((r) =>
                    this.config.match(r as T, this.context)
                )
                const ids = new Set(filtered.map((a) => getId(a)))

                if (!eqSet(this.matchedIds, ids)) {
                    this.matchedIds = ids
                }
            },
            {
                name: `refresh-view-${this.config.name || this.config.rootObject}`,
                itemCount: this.objectPool.getAll(this.config.rootObject).size,
            }
        )
    }

    @action
    removeFromQuery(id: string) {
        this.matchedIds.delete(id)
    }

    @computed
    get data() {
        const models = Array.from(this.matchedIds)
            .map((id) => this.objectPool.get(this.config.rootObject, id))
            .filter(isTruthy) as T[]
        if (this.config.sorter) {
            return this.config.sorter(models, this.context)
        }
        return models
    }
}

function eqSet<T>(a: Set<T>, b: Set<T>) {
    return a.size === b.size && new Set([...a, ...b]).size === a.size
}

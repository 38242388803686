import { chunk } from 'lodash'
import { HTTPRequestInfo, Pusher } from 'replicache'

import { extendRequest } from './request-util'

const CHUNK_SIZE = 50

const LENGTH_THRESHOLD = 10000

export async function splitMutations(request: Request, pusher: Pusher): Promise<HTTPRequestInfo> {
    const req = request.clone()

    const body = await req.text()
    if (body.length < LENGTH_THRESHOLD) {
        return pusher(request)
    }
    const parsed = JSON.parse(body)
    const mutations = parsed.mutations
    if (mutations.length < CHUNK_SIZE) {
        return pusher(request)
    }

    const chunks = chunk(mutations, CHUNK_SIZE)
    let current: HTTPRequestInfo | undefined
    for (const chunk of chunks) {
        const chunkedBody = {
            ...parsed,
            mutations: chunk,
        }
        const chunkReq = await extendRequest(request, { body: JSON.stringify(chunkedBody) })
        current = await pusher(chunkReq)
    }

    return current!
}

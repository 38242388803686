import { OnboardingLayout } from '../shared/OnboardingLayout'
import { OnboardingSteps } from '../shared/OnboardingSteps'

export function SpecifyObjectsPage() {
    return (
        <OnboardingLayout>
            <OnboardingSteps stepKey="select-object" />
        </OnboardingLayout>
    )
}

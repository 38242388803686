import { Card, CardTitle, CardColor } from './Card'

export const STAT_CLASSES =
    'font-serif text-7xl font-bold leading-none m-0 text-center text-grey-500/60'
export const SUBTITLE_CLASSES = 'text-sm mt-1 leading-[1.4] opacity-100 text-grey-500/60'

export function StatsCard({
    title,
    stat,
    subtitle,
    color = 'yellow',
}: {
    title: string
    stat: string
    subtitle: string
    color: CardColor
}) {
    return (
        <Card title={title} color={color} className="flex flex-col justify-between gap-5">
            <CardTitle>{title}</CardTitle>

            <h3 className={STAT_CLASSES}>{stat}</h3>
            <div className={SUBTITLE_CLASSES}>{subtitle}</div>
        </Card>
    )
}

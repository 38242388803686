import { useCallback } from 'react'

import { useCurrency } from '@laserfocus/client/feature-auth'
import { TextCell as BaseTextCell } from '@laserfocus/client/ui-shared-datagrid'
import { getCurrencyFormatter } from '@laserfocus/client/util-formatter'
import { getLocale } from '@laserfocus/ui/util-locale'

import { Column } from '../../table-context'

import { BulkEditCellProps } from './BulkEditCell'

interface BulkEditTextCellProps extends BulkEditCellProps {
    multiline?: boolean
    allowLineBreaks?: boolean
}

export function BulkEditNumberCell({
    column,
    isEditable,
    updateValue,
    value,
    hasMultipleValues,
    ...rest
}: BulkEditTextCellProps) {
    const orgCurrency = useCurrency()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const toDisplayValue = useCallback(makeToDisplayValue(column, orgCurrency), [
        column,
        orgCurrency,
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const parseValue = useCallback(makeParseValue(column), [column])

    return (
        <BaseTextCell
            name={column.key}
            updateValue={updateValue}
            value={value}
            placeholder={hasMultipleValues ? 'multiple' : '—'}
            readOnly={!isEditable}
            theme="dark"
            variant="dense"
            toDisplayValue={toDisplayValue}
            parseValue={parseValue}
            {...rest}
        />
    )
}

function makeParseValue(column: Column) {
    return (value: string) => {
        const parsed = column.type === 'int' ? Number.parseInt(value) : Number.parseFloat(value)

        if (Number.isNaN(parsed)) {
            return null
        }

        return parsed
    }
}

const numberFormatter = new Intl.NumberFormat(getLocale())

function makeToDisplayValue(column: Column, orgCurrency?: string) {
    return (value: number | null | undefined) => {
        if (value === null || value === undefined) {
            return ''
        }

        if (column.type === 'currency') {
            // Actually it would take the record currency into account,
            // but we will show records with different currencies as 'multiple'
            const currencyFormatter = getCurrencyFormatter(orgCurrency)
            return currencyFormatter.format(value)
        }

        if (column.type === 'percent') {
            return `${value}%`
        }

        return numberFormatter.format(value)
    }
}

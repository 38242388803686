import { useEffect, useMemo } from 'react'
import { useObserver } from 'mobx-react-lite'
import { useSearchParams } from 'react-router-dom'

import { StackNavigationProvider, StackNavigationContext } from '@laserfocus/client/store-shared'

import { useActivityStackNavigationStore } from '../store/ActivityStackNavigation'

import PrefetchNext from './PrefetchNext'

interface UpcomingContainerProps {
    rootId: string
}

export function UpcomingNavigationProvider({
    rootId,
    children,
}: React.PropsWithChildren<UpcomingContainerProps>) {
    const nav = useActivityStackNavigationStore('upcoming')

    const [queryParams] = useSearchParams()
    const activityId = queryParams.get('activityId')
    useEffect(() => {
        nav.navigated({
            Id: activityId,
            RootId: rootId,
        })
    }, [rootId, activityId, nav])

    const nextId = useObserver(() => nav.next?.RootId)

    const value: StackNavigationContext = useMemo(
        () => ({
            navigateList: nav?.gotoList,
            navigateNext: nav?.navigateNext,
            navigatePrevious: nav?.navigatePrevious,
            listPath: nav?.listPath,
            title: 'Upcoming',
        }),
        [nav?.gotoList, nav?.listPath, nav?.navigateNext, nav?.navigatePrevious]
    )

    return (
        <StackNavigationProvider value={value}>
            <>
                {children}
                {nextId && <PrefetchNext rootId={nextId} />}
            </>
        </StackNavigationProvider>
    )
}

import { Config } from '@laserfocus/shared/config-env'

import { logger } from './logger'

export function assert(condition: unknown, message: string, context?: Record<string, any>) {
    const err = new AssertError(message, context)
    if (Config.isDev) {
        if (!condition) {
            throw err
        }
    }
    const data = [context].filter(Boolean)
    if (!condition) {
        logger.error(err, ...(data as any))
    }
}

export function never(message: string, context?: Record<string, any>) {
    assert(false, message, context)
}
class AssertError extends Error {
    context?: Record<string, any>

    constructor(message: string, context?: Record<string, any>) {
        super(message)
        this.context = context
        this.name = 'AssertError'
    }
}

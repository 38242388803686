import { observer } from 'mobx-react-lite'

import { getTime } from '@laserfocus/ui/util-locale'
import {
    getDateFromTimeString,
    getDateFromUserTimeString,
    getTimeStringFromDate,
} from '@laserfocus/ui/util-date'
import { TextCell as BaseTextCell } from '@laserfocus/client/ui-shared-datagrid'

import { TableCellProps } from './TableCell'

export function toDisplayValue(v: string | undefined | null) {
    if (!v) {
        return ''
    }
    const valueAsDate = getDateFromTimeString(v)
    const displayValue = valueAsDate ? getTime(valueAsDate) : ''
    return displayValue
}

export function parse(raw: string) {
    return getTimeStringFromDate(getDateFromUserTimeString(raw))
}

export const TimeCell = observer(function TextCell({
    salesObject,
    column,
    ...rest
}: TableCellProps) {
    return (
        <BaseTextCell
            name={column.key}
            updateValue={(value) => column.updateValue(salesObject, value)}
            value={(column.getValue(salesObject) as string | null) ?? ''}
            readOnly={!column.isEditable(salesObject)}
            toDisplayValue={toDisplayValue}
            parseValue={parse}
            useDisplayValueAsInput
            {...rest}
        />
    )
})

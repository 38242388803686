import { useRef, useState } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { InView } from '@laserfocus/ui/util-react'

interface ModalStickyHeadingProps {
    children: React.ReactNode
    amountOfHeadings: number
    headingIndex: number
    transparentWhenFloaty?: boolean
    forcePosition?: Position
    rightSide?: React.ReactNode
    titleClassName?: string
}

enum Position {
    StickyTop = 'StickyTop',
    StickyBottom = 'StickyBottom',
    Floaty = 'Floaty',
}

const HEADING_HEIGHT = 38

export function ModalStickyHeading({
    amountOfHeadings,
    headingIndex,
    transparentWhenFloaty,
    rightSide,
    forcePosition,
    children,
    titleClassName,
    ...props
}: ModalStickyHeadingProps) {
    /**
     * DC: Needs one px less because of transparent border
     * MH: removed the 1px since it made the top sticky one not sticky to the right place. Didn't see issues
     *     when removing it. I think it might have been only in combination with a search field above it.
     *     From this comment: https://linear.app/laserfocus/issue/LF-1486#comment-6726c90f
     */
    const stickyTopOffset = headingIndex * (HEADING_HEIGHT - 1) // - 1
    const stickyBottomOffset = (amountOfHeadings - 1 - headingIndex) * (HEADING_HEIGHT - 1) - 1

    const [internalPosition, setPosition] = useState(Position.StickyTop)
    const position = forcePosition || internalPosition
    const scrollToNodeRef = useRef<HTMLDivElement>(null)

    const zIndex = (() => {
        if (position === Position.StickyTop) {
            return (amountOfHeadings - headingIndex + 20) as any
        }

        if (position === Position.StickyBottom) {
            return 20
        }

        if (headingIndex === 0) {
            return 30
        }

        return 0
    })()

    return (
        <>
            <div
                ref={scrollToNodeRef}
                className="-z-10"
                style={{
                    marginTop: -stickyTopOffset,
                    paddingTop: stickyTopOffset,
                }}
            />
            <div
                onClick={() => scrollToNodeRef.current?.scrollIntoView({ behavior: 'smooth' })}
                {...props}
                className={clsx(
                    'px-8 sticky grid items-center grid-flow-col grid-cols-1 transition cursor-pointer bg-clip-padding border-y border-white hover:bg-grey-700-opaque-5',
                    (!transparentWhenFloaty || position !== Position.Floaty) && 'bg-white',
                    // Fixes sticky position bug in Chrome. More info: https://linear.app/laserfocus/issue/LF-1486#comment-6726c90f
                    position === Position.StickyTop &&
                        'before:absolute before:left-0 before:-top-px before:w-full before:h-px before:bg-white',
                    position === Position.StickyBottom &&
                        'before:absolute before:left-0 before:-bottom-px before:w-full before:h-px before:bg-white',
                    position === Position.StickyBottom
                        ? 'border-t-grey-700/10'
                        : 'border-b-grey-700/10'
                )}
                style={{
                    zIndex,
                    top: stickyTopOffset,
                    bottom: stickyBottomOffset,
                    height: HEADING_HEIGHT,
                }}
            >
                <h2 className={twMerge('text-base leading-[1.4] font-semibold', titleClassName)}>
                    {children}
                </h2>
                {rightSide}
            </div>
            {!forcePosition && (
                <InView
                    root="parentElement"
                    rootMargin={`${
                        -stickyTopOffset - HEADING_HEIGHT
                    }px 0px ${-stickyBottomOffset}px`}
                    onIntersect={(entry) => {
                        if (entry.isIntersecting) {
                            setPosition(Position.Floaty)
                        } else {
                            const distanceTop = Math.abs(
                                entry.boundingClientRect.top - entry.rootBounds!.top
                            )
                            const distanceBottom = Math.abs(
                                entry.boundingClientRect.bottom - entry.rootBounds!.bottom
                            )

                            setPosition(
                                distanceTop < distanceBottom
                                    ? Position.StickyTop
                                    : Position.StickyBottom
                            )
                        }
                    }}
                />
            )}
        </>
    )
}

ModalStickyHeading.Position = Position

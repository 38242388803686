import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { updateEmoji } from '@laserfocus/client/data-access-shared'
import { EmojiPicker } from '@laserfocus/ui/emoji-picker'
import { AccountId, LeadId } from '@laserfocus/shared/models'
import { useSalesobjectEmoji } from '@laserfocus/client/store-shared'

export const PersonIcon = function PersonIcon({
    id,
    className,
}: {
    id: string
    className?: string
}) {
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)

    const emoji = useSalesobjectEmoji(id)

    if (!id) {
        return null
    }

    return (
        <EmojiPicker
            isOpen={isEmojiPickerOpen}
            onSubmit={(emoji) => {
                setIsEmojiPickerOpen(false)
                updateEmoji(id as LeadId | AccountId, emoji)
            }}
            onCancel={() => setIsEmojiPickerOpen(false)}
        >
            <button
                title="Change emoji"
                onClick={() => setIsEmojiPickerOpen(true)}
                className={twMerge(
                    'text-[1.375rem] leading-none rounded-full w-11 h-11 grid place-items-center outline-none transition focus-visible:ring font-emoji bg-grey-700/5',
                    className
                )}
            >
                {emoji}
            </button>
        </EmojiPicker>
    )
}

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { MultiSelect, MultiSelectProps } from '@laserfocus/ui/beam'
import { LockOutlinedIcon } from '@laserfocus/ui/icons'

import { HighlightedCellValue } from './HighlightedCellValue'
import { TableCellProps } from './cell.types'
import {
    CLASS_NAME_INPUT_BUTTON,
    CLASS_NAME_INPUT_BUTTON_DARK,
    CLASS_NAME_INPUT_BUTTON_LIGHT,
    CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK,
    CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT,
    CLASS_NAME_INPUT_BUTTON_SELECTED,
    CLASS_NAME_LOCK_ICON,
    CLASS_NAME_LOCK_ICON_DARK,
} from './shared-class-names'

type MultiSelectCellProps = TableCellProps<string | string[]> &
    Pick<MultiSelectProps, 'size'> & {
        options: Array<{ inactive?: boolean; label: string; value: string }>
        placeholder?: string
        onClose?: () => void
    }

export function MultiSelectCell({
    searchTerm,
    readOnly,
    updateValue,
    value,
    options,
    theme,
    placeholder = '—',
    onClose,
}: MultiSelectCellProps) {
    const [isSelectOpen, setIsSelectOpen] = useState(false)

    const rawValue = value as string[] | string | null
    const selectedOptionValues = parseValue(rawValue)
    const optionsMap = Object.fromEntries(options.map((option) => [option.value, option]))

    const valuesWithoutOption =
        selectedOptionValues
            ?.filter((o) => !optionsMap[o])
            .map((a) => ({ label: `${a}`, value: a })) || []

    const activeOptions = options.filter((a) => !a.inactive)

    const allOptions = [...valuesWithoutOption, ...activeOptions]

    const selectedOptionLabels = selectedOptionValues
        .map((value) => optionsMap[value]?.label || value)
        .filter(Boolean)
        .join('; ')

    return (
        <MultiSelect
            isOpen={isSelectOpen}
            initialOptionValues={selectedOptionValues}
            options={allOptions}
            onSubmit={(options) => {
                // setIsSelectOpen(false)
                const values = options.map((option) => option.value)
                updateValue(values)
            }}
            onCancel={() => {
                onClose?.()
                setIsSelectOpen(false)
            }}
            searchKeys={['label']}
        >
            <button
                title={selectedOptionLabels}
                disabled={readOnly}
                className={twMerge(
                    CLASS_NAME_INPUT_BUTTON,
                    theme === 'dark' ? CLASS_NAME_INPUT_BUTTON_DARK : CLASS_NAME_INPUT_BUTTON_LIGHT,
                    selectedOptionLabels
                        ? null
                        : theme === 'dark'
                        ? CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK
                        : CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT,
                    isSelectOpen && CLASS_NAME_INPUT_BUTTON_SELECTED
                )}
                onClick={() => setIsSelectOpen(true)}
            >
                {selectedOptionValues.length ? (
                    <HighlightedCellValue
                        value={selectedOptionLabels}
                        searchTerm={searchTerm}
                        className={clsx(theme === 'dark' && 'text-white')}
                    />
                ) : (
                    placeholder
                )}

                {readOnly && (
                    <LockOutlinedIcon
                        className={twMerge(
                            CLASS_NAME_LOCK_ICON,
                            theme === 'dark' && CLASS_NAME_LOCK_ICON_DARK
                        )}
                    />
                )}
            </button>
        </MultiSelect>
    )
}

function parseValue(v: string[] | string | null): string[] {
    if (typeof v === 'string') {
        return v.split(';')
    }
    return v || []
}

import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { twMerge } from 'tailwind-merge'
import { runInAction } from 'mobx'

import { Button, CompactDatePicker, toast } from '@laserfocus/ui/beam'
import { getDateShort, getTime } from '@laserfocus/ui/util-locale'
import { AddOutlinedIcon, CheckOutlinedIcon, RescheduleOutlinedIcon } from '@laserfocus/ui/icons'
import {
    isOptimisticTaskId,
    TaskId,
    isTask as isTaskId,
    dateToDateString,
    EventId,
    NewTask,
    isAccount,
    isLead,
    NewEvent,
    NewActivity,
    dateHasTime,
    OpportunityFields,
} from '@laserfocus/shared/models'
import { getActivityRescheduleMessage } from '@laserfocus/client/util-formatter'
import { Analytics } from '@laserfocus/client/util-analytics'
import {
    completeTask,
    rescheduleEvent,
    rescheduleTask,
} from '@laserfocus/client/data-access-shared'
import { ActivityUtil, OpportunityModel, TaskUtil } from '@laserfocus/client/model'
import { logger } from '@laserfocus/ui/logger'
import { HighlightedCellValue } from '@laserfocus/client/ui-shared-datagrid'

import { TableCellProps } from '../TableCell'
import { Row } from '../../table-context'
import {
    CLASS_NAME_INPUT_BUTTON_CONTENT_RED,
    CLASS_NAME_INPUT_BUTTON_EMPTY,
} from '../shared-class-names'

import { AddTaskModal } from './AddTaskModal'

interface DateCellProps extends TableCellProps {
    dateOnly?: boolean
}

export const TaskDateCell = observer(function TaskDateCell({
    salesObject,
    column,
    dateOnly,
    searchTerm,
}: DateCellProps) {
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

    const dateDirty = (column.getValue(salesObject) as Date | string | null) || undefined
    const date = typeof dateDirty === 'string' ? new Date(dateDirty) : dateDirty
    const displayValue = getDisplayValue(date, dateOnly)

    const activity = column.getActual?.(salesObject) as NewActivity

    return (
        <div
            className="w-full h-full grid items-center grid-rows-[minmax(0,1fr),auto] px-[0.625rem] group-inner"
            onMouseMove={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <HighlightedCellValue
                className={twMerge(
                    activity &&
                        ActivityUtil.isTask(activity) &&
                        TaskUtil.isOverdue(activity) &&
                        CLASS_NAME_INPUT_BUTTON_CONTENT_RED,
                    !displayValue && CLASS_NAME_INPUT_BUTTON_EMPTY,
                    'group-inner-hover:sr-only group-inner-focus-within:sr-only',
                    isDatepickerOpen && 'sr-only'
                )}
                value={displayValue}
                searchTerm={searchTerm}
            />

            {isHovered && (
                <div
                    className={clsx(
                        'group-inner-hover:not-sr-only group-inner-focus-within:not-sr-only grid grid-flow-col gap-2 justify-start',
                        !isDatepickerOpen && 'sr-only'
                    )}
                >
                    {activity ? (
                        <>
                            {ActivityUtil.isTask(activity) && (
                                <CompleteButton salesObject={salesObject} activity={activity} />
                            )}
                            <RescheduleButton
                                activity={activity}
                                salesObject={salesObject}
                                isDatepickerOpen={isDatepickerOpen}
                                setIsDatepickerOpen={setIsDatepickerOpen}
                            />
                        </>
                    ) : (
                        <Button
                            size="small"
                            iconComponent={AddOutlinedIcon}
                            onClick={() => {
                                setIsCreateModalOpen((v) => !v)
                            }}
                        >
                            Add Task
                        </Button>
                    )}
                </div>
            )}
            {isCreateModalOpen && (
                <AddTaskModal
                    salesObject={salesObject}
                    closeModal={() => {
                        setIsCreateModalOpen(false)
                    }}
                />
            )}
        </div>
    )
})

function getDisplayValue(date: Date | undefined, dateOnly: boolean | undefined) {
    if (!date) {
        return ''
    }
    if (dateOnly || !dateHasTime(date)) {
        return getDateShort(date)
    }
    return `${getDateShort(date)} ${getTime(date)}`
}

interface CompleteButtonProps {
    salesObject: Row
    activity: NewTask
}

function CompleteButton({ salesObject, activity }: CompleteButtonProps) {
    function handleClick() {
        const taskId = activity.Id as TaskId

        /**
         * If loaded via the table, this could be only in Mobx NOT in RC, so
         * we need to apply the change here optimistically
         */
        const previousStatus = activity.Status
        const previousLastModified = activity.LastModifiedDate
        runInAction(() => {
            activity.CompletedDateTime = new Date().toISOString()
            activity.Status = 'Completed'
            activity.IsClosed = true
            activity.LastModifiedDate = new Date().toISOString()
        })

        completeTask({ taskId: taskId }).then(
            () => {
                const rootName = getRootName(salesObject)
                const msg = rootName
                    ? `Successfully completed ${activity.Subject}`
                    : `Successfully completed ${activity.Subject} from ${rootName}`
                toast.success({ title: msg })
            },
            (error: unknown) => {
                logger.error(error)
                toast.error({ title: 'Something went wrong' })
                runInAction(() => {
                    activity.CompletedDateTime = null
                    activity.Status = previousStatus
                    activity.IsClosed = false
                    activity.LastModifiedDate = previousLastModified
                })
            }
        )
    }

    return (
        <Button
            size="small"
            iconComponent={CheckOutlinedIcon}
            title="Complete task"
            onClick={handleClick}
        />
    )
}

export function updateActivityDate(activity: NewActivity, date: Date) {
    const isTask = isTaskId(activity.Id) || isOptimisticTaskId(activity.Id)

    let rollback: () => void
    const previousLastModifiedDate = activity.LastModifiedDate
    if (isTask) {
        const task = activity as NewTask
        const previousActivityDate = task.ActivityDate
        const previousReminderDate = task.ReminderDateTime
        rollback = () => {
            task.ActivityDate = previousActivityDate
            task.ReminderDateTime = previousReminderDate
        }
        runInAction(() => {
            task.ActivityDate = dateToDateString(date)
            task.ReminderDateTime = date.toISOString()
            task.LastModifiedDate = new Date().toISOString()
        })
    } else {
        const event = activity as NewEvent
        const previousStartDateTime = event.StartDateTime
        rollback = () => {
            event.StartDateTime = previousStartDateTime
            event.LastModifiedDate = previousLastModifiedDate
        }
        runInAction(() => {
            event.StartDateTime = date.toISOString()
            event.LastModifiedDate = new Date().toISOString()
        })
    }

    const reschedulePromise = isTask
        ? rescheduleTask({
              taskId: activity.Id as TaskId,
              date: dateToDateString(date),
              dateTime: date.toISOString(),
          })
        : rescheduleEvent({ eventId: activity.Id as EventId, dateTime: date.toISOString() })
    return reschedulePromise.catch((e) => {
        runInAction(rollback)
        throw e
    })
}

interface RescheduleButtonProps {
    salesObject: Row
    activity: NewActivity
    isDatepickerOpen: boolean
    setIsDatepickerOpen(isOpen: boolean): void
}

const RescheduleButton = observer(function RescheduleButton({
    activity,
    salesObject,
    isDatepickerOpen,
    setIsDatepickerOpen,
}: RescheduleButtonProps) {
    function handleDateSubmit(date: Date) {
        setIsDatepickerOpen(false)

        const reschedulePromise = updateActivityDate(activity, date)
        reschedulePromise.then(
            () => {
                const rootName = getRootName(salesObject)
                if (date && rootName) {
                    toast.success(getActivityRescheduleMessage(date, rootName))
                }
                Analytics.trackEvent({
                    event: 'activity_rescheduled',
                    location: Analytics.parseStackFromSobject(salesObject.__typename),
                })
            },
            (e: Error) => {
                logger.error(e)
                toast.error({ title: 'Something went wrong' })
            }
        )
    }

    const suggestOptions = useMemo(() => ({ allow_past_dates: false, min_date: new Date() }), [])

    return (
        <CompactDatePicker
            isOpen={isDatepickerOpen}
            initialDate={ActivityUtil.getDate(activity)}
            onDateSubmit={handleDateSubmit}
            onCancel={() => setIsDatepickerOpen(false)}
            suggestOptions={suggestOptions}
        >
            <Button
                title="Reschedule task"
                size="small"
                iconComponent={RescheduleOutlinedIcon}
                onClick={() => setIsDatepickerOpen(true)}
            />
        </CompactDatePicker>
    )
})

function getRootName(salesObject: Row) {
    const root =
        isLead(salesObject.Id) || isAccount(salesObject.Id)
            ? salesObject
            : (salesObject as OpportunityModel).Account
    const rootName = root?.Name
    return rootName
}

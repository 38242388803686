import { ExperimentalDiff, Replicache } from 'replicache'

import { SObjectType } from '@laserfocus/shared/models'

import { trace } from './util-trace'
import { ModelProps, ObjectPool } from './ObjectPool'
import { migrateModel } from './migrate-delta'

type SyncedObjectType =
    | Exclude<SObjectType, 'Activity' | 'EmailMessage'>
    | 'stackrows'
    | 'FieldMetadata'
    | 'RecordType'
    | 'SalesObjectExtension'
    | 'Identity'
    | 'Name'

const _SyncedObjects: Record<SyncedObjectType, true> = {
    Account: true,
    Contact: true,
    Lead: true,
    Opportunity: true,
    Event: true,
    Task: true,
    Group: true,
    User: true,
    stackrows: true,
    FieldMetadata: true,
    RecordType: true,
    SalesObjectExtension: true,
    Identity: true,
    Name: true,
}
const SyncedObjects = _SyncedObjects as Record<string, boolean>

export class ReplicacheDeltaSyncer {
    objectPool: ObjectPool
    replicache: Replicache

    reactions: Array<() => void> = []

    constructor(objectPool: ObjectPool, replicache: Replicache) {
        this.objectPool = objectPool
        this.replicache = replicache
    }

    onInit() {
        this.reactions.push(this.replicache.experimentalWatch((diff) => this.processDiff(diff), {}))
    }
    onDestroy() {
        let current
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    processDiff(diff: ExperimentalDiff) {
        trace(
            () => {
                for (const diffOp of diff) {
                    // console.log('Diff', diffOp.op, diffOp.key)
                    switch (diffOp.op) {
                        case 'change':
                        case 'add': {
                            const { newValue, key } = diffOp as unknown as {
                                newValue: ModelProps
                                key: string
                            }
                            const fullProps = migrateModel(newValue, key)
                            if (fullProps.__typename && SyncedObjects[fullProps.__typename]) {
                                this.objectPool.attach(fullProps)
                            }

                            break
                        }
                        case 'del': {
                            const { oldValue } = diffOp as any
                            this.objectPool.detach(oldValue)
                            break
                        }
                    }
                }
            },
            { name: 'DeltaSync.processdiff', itemCount: diff.length }
        )
    }
}

import { createContext, useState, useCallback, useContext } from 'react'

type TableUIState = {
    modalShownFor: Record<string, boolean>
    onModalShown: (stackId: string) => void
    onFilterChanged: (stackId: string) => void
}
const TableUIStateContext = createContext<TableUIState | null>(null)

export function useTooMuchDataShownForStack(stackId: string) {
    const { modalShownFor, onModalShown, onFilterChanged } = useContext(TableUIStateContext)!

    return {
        onModalShown,
        onFilterChanged,
        wasShown: modalShownFor[stackId] ?? false,
    }
}

export function TableUIStateContextProvider({ children }: { children: React.ReactNode }) {
    const [tooMuchDataMap, setTooMuchDataMap] = useState({})

    const onModalShown = useCallback(
        (stackId: string) =>
            setTooMuchDataMap((prev) => ({
                ...prev,
                [stackId]: true,
            })),
        []
    )

    const onFilterChanged = useCallback(
        (stackId: string) =>
            setTooMuchDataMap((prev) => ({
                ...prev,
                [stackId]: false,
            })),
        []
    )

    return (
        <TableUIStateContext.Provider
            value={{
                modalShownFor: tooMuchDataMap,
                onModalShown,
                onFilterChanged,
            }}
        >
            {children}
        </TableUIStateContext.Provider>
    )
}

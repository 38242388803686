import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function OpenOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M5 12.236a1 1 0 011 1V18h4.764a1 1 0 010 2H5a1 1 0 01-1-1v-5.764a1 1 0 011-1zM12.236 5a1 1 0 011-1H19a1 1 0 011 1v5.764a1 1 0 01-2 0V6h-4.764a1 1 0 01-1-1z" />
            <path d="M19.706 4.294a1 1 0 010 1.414L5.707 19.707a1 1 0 11-1.414-1.414l13.999-14a1 1 0 011.414 0z" />
        </SvgIcon>
    )
}

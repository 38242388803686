import { observer } from 'mobx-react-lite'

import { Column } from '../Table/table-context'

import { ShowColumnSwitch } from './ShowColumnSwitch'
import { SortingButtons } from './SortingButtons'
import { Filter } from './Filter'

interface ColumnFiltersProps {
    column: Column
}

export const ColumnFilters = observer(function ColumnFilters({ column }: ColumnFiltersProps) {
    return (
        <div className="grid gap-4">
            {!column.isStickyLeft && !column.columnLocked && <ShowColumnSwitch column={column} />}
            <SortingButtons column={column} />
            {!column.conditionLocked && <Filter column={column} />}
        </div>
    )
})

import { Navigate, Route, Routes } from 'react-router'
import { useEffect } from 'react'

import { RequireAuth } from '@laserfocus/client/feature-auth'

import { SettingsLayout } from './layout/SettingsLayout'
import { SettingsRolesPage } from './roles/SettingsRolesPage'
import { FieldGroupsSettingsPage } from './fieldgroups/FieldGroupsSettingsPage'
import { RoleContextProvider } from './roles/RoleContext'
import { StacksListSettingsPage } from './stacks/StackListSettingsPage'
import { StackSettingsPage } from './stacks/StackSettingsPage'
import { NoteTemplatesPage } from './notes/NoteTemplatesPage'
import { SubscriptionPage } from './subscription/SubscriptionPage'
import { SubscriptionSuccessPage } from './subscription/SuccessPage'
import { ImpersonatePage } from './impersonate/ImpersonatePage'

export const SettingsRoutePaths = {
    Home: '/settings',
    Roles: '/settings/roles',
    FieldGroups: '/settings/fieldgroups',
    StackList: '/settings/stacks',
    StackDetails: '/settings/stacks/:stackId',
    Subscription: '/settings/subscription',
}

export function SettingsRoutes() {
    useEffect(() => {
        localStorage.setItem('lf:feature:settings', 'true')
    }, [])
    return (
        <RequireAuth>
            <SettingsLayout>
                <RoleContextProvider>
                    <Routes>
                        {/* <Route index element={<SettingsIndexPage />} /> */}
                        <Route index element={<Navigate replace to="roles" />} />
                        <Route path="roles" element={<SettingsRolesPage />} />
                        <Route path="fieldgroups" element={<FieldGroupsSettingsPage />} />
                        <Route path="stacks" element={<StacksListSettingsPage />} />
                        <Route path="stacks/:stackId" element={<StackSettingsPage />} />
                        <Route path="impersonate" element={<ImpersonatePage />} />
                        <Route path="notetemplates" element={<NoteTemplatesPage />} />
                        <Route path="subscription/success" element={<SubscriptionSuccessPage />} />
                        <Route path="subscription/*" element={<SubscriptionPage />} />
                    </Routes>
                </RoleContextProvider>
            </SettingsLayout>
        </RequireAuth>
    )
}

import gql from 'graphql-tag'

export const EMAIL_SIGNUP_MUTATION = gql`
    mutation emailSignup($input: EmailSignupInput!) {
        emailSignup(input: $input) {
            success
        }
    }
`

export const CAPTURE_ANONYMOUS_TRAIT_MUTATION = gql`
    mutation captureAnonymousTrait($input: CaptureAnonymousTraitInput!) {
        captureAnonymousTrait(input: $input) {
            success
        }
    }
`

export const CAPTURE_TRAIT_MUTATION = gql`
    mutation captureTrait($input: CaptureTraitInput!) {
        captureTrait(input: $input) {
            success
        }
    }
`

import { twMerge } from 'tailwind-merge'

type CardSize = 'medium' | 'large'
interface CardProps extends React.ComponentPropsWithoutRef<'div'> {
    size?: CardSize
    flat?: boolean
}

const classesBySize: Record<CardSize, string> = {
    medium: 'rounded-[0.625rem]',
    large: 'rounded-2xl',
}

export function Card({ size = 'medium', flat, className, ...props }: CardProps) {
    return (
        <div
            {...props}
            className={twMerge(
                'bg-white',
                !flat &&
                    'ring-1 ring-grey-700/5 shadow-[0px_22px_48px_rgba(0,0,0,0.03),0px_8.03036px_17.5208px_rgba(0,0,0,0.0206994),0px_3.89859px_8.50602px_rgba(0,0,0,0.0166887),0px_1.91116px_4.16981px_rgba(0,0,0,0.0133113),0px_0.755676px_1.64875px_rgba(0,0,0,0.00930055)]',
                classesBySize[size],
                className
            )}
        />
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function CallOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M4.14 18.38l.62.62a3.43 3.43 0 004.35.41 28.5 28.5 0 004.78-3.84l1.67-1.68a28.55 28.55 0 003.9-4.87A3.43 3.43 0 0019 4.76l-.62-.62a.48.48 0 00-.68 0l-2.6 2.6a.48.48 0 000 .68l-.71.7.7-.7a2.48 2.48 0 010 3.5l-4.17 4.18a2.48 2.48 0 01-3.5 0 .48.48 0 00-.67 0l-2.61 2.6a.48.48 0 000 .68zm-1.42 1.41a2.48 2.48 0 010-3.5l2.61-2.6a2.48 2.48 0 013.5 0 .48.48 0 00.68 0l4.17-4.18a.48.48 0 000-.68 2.48 2.48 0 010-3.5l2.6-2.6a2.48 2.48 0 013.51 0l.62.62a5.43 5.43 0 01.74 6.76 30.56 30.56 0 01-4.17 5.2l-1.68 1.67a30.5 30.5 0 01-5.29 4.23 5.43 5.43 0 01-6.66-.8l-.63-.62z" />
        </SvgIcon>
    )
}

import { CommandLineBreadCrumbs } from './CommandLineBreadCrumbs'
import { CommandLineContent } from './CommandLineContent'
import { CommandLineInput } from './CommandLineInput'
import { CommandLineModal } from './CommandLineModal'
import { CommandLineOption } from './CommandLineOption'

export const CommandLine = {
    BreadCrumbs: CommandLineBreadCrumbs,
    Content: CommandLineContent,
    Input: CommandLineInput,
    Modal: CommandLineModal,
    Option: CommandLineOption,
}

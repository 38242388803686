import type { SObjectType } from '@laserfocus/shared/models'

export function getDefaultColumnNames(sobject: SObjectType) {
    const key = sobject as keyof typeof DEFAULT_COLUMNNAMES
    return DEFAULT_COLUMNNAMES[key]
}

const LeadColumns = [
    'Company',
    'Name',
    // 'FirstName', 'LastName',
    'Status',
    'City',
    'NextActivity.Subject',
    'NextActivity.Date',
    'LastActivityDate',
    'CreatedDate',
]

const AccountColumns = [
    'Name',
    'Website',
    'NextActivity.Subject',
    'NextActivity.Date',
    'FirstContact.Name',
    'LastActivityDate',
    'CreatedDate',
]

const ContactColumns = [
    'Account.Name',
    'Name',
    'NextActivity.Subject',
    'NextActivity.Date',
    'Title',
    'LastActivityDate',
]

const OpportunityColumns = [
    'Account.Name',
    'Name',
    'StageName',
    'NextStep',
    // 'Contact.Name', // <-- probably quite difficult
    'NextActivity.Subject',
    'NextActivity.Date',
    'CloseDate',
    'LastActivityDate',
]

export const DEFAULT_COLUMNNAMES = {
    Lead: LeadColumns,
    Account: AccountColumns,
    Contact: ContactColumns,
    Opportunity: OpportunityColumns,
} as const

export const LABEL_OVERWRITES = {
    Lead: {},
    Opportunity: {
        Name: 'Opportunity Name',
    },
    Account: {},
    Contact: {},
} as const

import 'reflect-metadata'
import { action, computed, observable, runInAction, when } from 'mobx'
import type { ReadTransaction, Replicache } from 'replicache'

import type {
    SObjectType,
    RecordType,
    ObjectMetadata,
    FieldMetadataProps,
} from '@laserfocus/shared/models'
import { Prefix } from '@laserfocus/shared/models'
import { ObjectPool } from '@laserfocus/client/data-layer'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { logger } from '@laserfocus/ui/logger'
import { RecordtypeModel, FieldMetadata } from '@laserfocus/client/model'

import { ActivityTableMetadata } from './ClientDefaultMetadata'

export type RootStoreInjection = {
    metadataStore?: MetadataStore
}

export function useMetadataStore() {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.metadataStore
}

export class MetadataStore {
    objectPool: ObjectPool
    replicache: Replicache

    reactions: Array<() => void> = []
    metadataPromise?: Promise<any>
    recordTypePromise?: Promise<any>

    @observable error: any

    constructor(objectPool: ObjectPool, replicache: Replicache) {
        this.objectPool = objectPool
        this.replicache = replicache
    }

    onInit() {
        this.loadRelationMetadata()
        this.observeReplicache()
    }

    onDestroy() {
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    @computed
    get isReady() {
        return this.metadata.size > ActivityTableMetadata.length
    }

    @action
    loadRelationMetadata() {
        this.receiveFields(ActivityTableMetadata)
    }

    runCheck() {
        let timeout: ReturnType<typeof setTimeout>
        return Promise.race([
            when(() => this.isReady).then(() => clearTimeout(timeout)),
            new Promise((resolve, reject) => {
                timeout = setTimeout(
                    () => reject(new Error('MetadataStore check timed out')),
                    1000 * 20
                )
            }),
        ])
    }

    observeReplicache() {
        this.reactions.push(
            this.replicache.subscribe(
                async (tx: ReadTransaction) => {
                    const objectMetadata = await tx
                        .scan({ prefix: `${Prefix.ObjectMetadata}/` })
                        .values()
                        .toArray()
                    return objectMetadata || []
                },
                {
                    onData: (data) => {
                        const asObjectMetadata = data as unknown as ObjectMetadata[]
                        const allFields = mapAllMetadata(asObjectMetadata)
                        this.receiveFields(allFields)
                    },
                }
            )
        )
        this.reactions.push(
            this.replicache.subscribe(
                async (tx: ReadTransaction) => {
                    const recordTypes = await tx
                        .scan({ prefix: `${Prefix.RecordType}/` })
                        .values()
                        .toArray()
                    return recordTypes || []
                },
                {
                    onData: (data) => {
                        const recordTypes = data as unknown as RecordType[]
                        recordTypes.forEach((rt) => this.objectPool.attach(rt))
                    },
                }
            )
        )
        this.reactions.push(
            this.replicache.subscribe(
                async (tx: ReadTransaction) => {
                    const error = await tx.get([Prefix.Error, Prefix.ObjectMetadata].join('/'))
                    return error
                },
                {
                    onData: (data) => {
                        if (data) {
                            runInAction(() => (this.error = data))
                        }
                    },
                }
            )
        )
    }

    async queryMetadataOnce() {}

    @action
    reset() {
        this.objectPool.detachAllOfType('FieldMetadata')
        this.objectPool.detachAllOfType('RecordType')
    }

    @action
    receiveFields(fields: FieldMetadataProps[]) {
        fields.forEach((f) => this.objectPool.attach(f))
    }

    /*
     * ------------ Normal Metadata Api ------------
     */

    getField(objectName: SObjectType, fieldName: string): FieldMetadata | undefined {
        const fullName = `${objectName}.${fieldName}`
        const f = this.metadata.get(fullName)
        if (!f) {
            logger.warn(`Could not find ${objectName}.${fieldName}`)
        }
        return f as FieldMetadata | undefined
    }

    hasField(objectName: SObjectType, fieldName: string): boolean {
        const fullName = `${objectName}.${fieldName}`
        return !!this.metadata.get(fullName)
    }

    @computed
    get metadata() {
        return this.objectPool.getAll('FieldMetadata')
    }

    @computed
    get metadataByObject(): Map<SObjectType, Map<string, FieldMetadata>> {
        const grouped = new Map()
        this.allFields.forEach((m) => {
            if (!grouped.has(m.objectName)) {
                grouped.set(m.objectName, new Map())
            }
            const objectMap = grouped.get(m.objectName)
            objectMap.set(m.name, m)
        })
        return grouped
    }

    @computed
    get allFields(): Array<FieldMetadata> {
        const fieldsArray = Array.from(this.metadata.values()) as FieldMetadata[]
        return fieldsArray
    }

    @computed
    get recordTypes(): Array<RecordtypeModel> {
        return Array.from(this.objectPool.getAll<RecordtypeModel>('RecordType').values())
    }
}

function mapAllMetadata(objectMetadatas: ObjectMetadata[]): FieldMetadataProps[] {
    const withFavorites = objectMetadatas.flatMap((objectMetadata: ObjectMetadata) => {
        return objectMetadata.fields.map((fieldMetadata) => {
            const asProps: FieldMetadataProps = {
                Id: `${fieldMetadata.objectName}.${fieldMetadata.name}`,
                __typename: 'FieldMetadata',
                ...fieldMetadata,
            }
            return asProps
        })
    })
    return withFavorites
}

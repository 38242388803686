import { zipObject } from 'lodash'

export type AutoFunction = WithoutDeps | WithDeps<any>
type WithoutDeps = () => Promise<any> | any
type WithDeps<Deps extends Record<string, any>> = [...string[], (deps: Deps) => Promise<any> | any]

export async function auto(tasks: Record<string, AutoFunction>) {
    const finalChain: Record<string, any> = {}

    function getTaskAndaddTaskToChain(taskKey: string, previousStack?: string[]) {
        const callStack = previousStack ? [...previousStack, taskKey] : [taskKey]
        if (callStack.length > 100) {
            throw new Error(`Circular Dependency: ${callStack.join(' -> ')}`)
        }
        if (finalChain[taskKey]) {
            return finalChain[taskKey]
        }
        const task = tasks[taskKey]
        if (typeof task === 'function') {
            finalChain[taskKey] = task()
            return finalChain[taskKey]
        } else if (Array.isArray(task)) {
            const deps = task.filter((a) => typeof a === 'string') as string[]
            const actualTask = task[task.length - 1]
            if (typeof actualTask !== 'function') {
                throw new Error(`Task '${taskKey}' has the wrong format`)
            }
            const depObject = Object.fromEntries(
                deps.map((dep) => {
                    const originalDepTask = tasks[dep]
                    if (!originalDepTask) {
                        throw new Error(
                            `Defined Dependency '${dep}' in task '${taskKey}' but only found: ${Object.keys(
                                tasks
                            ).join(', ')}`
                        )
                    }
                    const depTask = getTaskAndaddTaskToChain(dep, callStack)
                    return [dep, depTask]
                })
            )
            const taskChain = promiseAllValues(depObject).then((dep) => actualTask(dep))
            finalChain[taskKey] = taskChain
            return finalChain[taskKey]
        } else {
            throw new Error(`Task '${taskKey}' has the wrong format`)
        }
    }

    for (const taskKey in tasks) {
        getTaskAndaddTaskToChain(taskKey)
    }

    const finalResult = await promiseAllValues(finalChain)
    return finalResult
}

async function promiseAllValues(object: Record<string, Promise<any> | any>) {
    return zipObject(Object.keys(object), await Promise.all(Object.values(object)))
}

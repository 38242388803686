import { useParams } from 'react-router'
import { useObserver } from 'mobx-react-lite'
import { useRef } from 'react'

import {
    TableDataProvider,
    TableIcon,
    EditColumns,
    AllColumnsContext,
    TableRowsProvider,
} from '@laserfocus/client/feature-table'
import { StackModel } from '@laserfocus/client/model'
import { useBackgroundColor } from '@laserfocus/client/layout'
import { EmptyState } from '@laserfocus/ui/beam'
import { useWindowSize } from '@laserfocus/ui/util-react'

import { SettingsPageHeader } from '../layout/SettingsPageHeader'

import { StackTable } from './table/StackTable'
import { useSettingsTableContext, useSettingsTableData } from './table/useSettingsTableData'
import { StackMoreActions } from './StackMoreActions'
import { CellsLoading } from './table/CellsLoading'

export function StackSettingsPage() {
    const { stackId } = useParams()
    const settingsTableData = useSettingsTableData(stackId!)

    const {
        rows,
        visibleColumns,
        columnSections,
        stack,
        setColumnsOrder,
        isFetching,
        didFetch,
        errorMessage,
    } = settingsTableData

    const { icon, color, title, sorting } = useObserver(() => {
        return {
            icon: stack ? StackModel.getIcon(stack) : undefined,
            color: stack ? StackModel.getColor(stack) : 'yellow',
            title: stack?.title || '',
            sorting: stack?.sorting,
        }
    })
    const contextValue = useSettingsTableContext({
        color,
        rows,
        setColumnsOrder,
        sorting,
        stackId: stackId!,
        visibleColumns,
    })

    const tableHeadKey = visibleColumns.map((c) => c.key).join()

    useBackgroundColor(color)

    return (
        <div>
            <TableDataProvider value={contextValue}>
                <TableRowsProvider value={{ rows }}>
                    <AllColumnsContext.Provider value={columnSections}>
                        <SettingsPageHeader
                            title={title}
                            icon={
                                icon && color && stack ? (
                                    <TableIcon
                                        icon={icon}
                                        color={color}
                                        stackId={stack.id}
                                        stackType="org"
                                    />
                                ) : undefined
                            }
                            className="gap-2 justify-between"
                            right={
                                stack && (
                                    <div className="grid grid-flow-col gap-2">
                                        <EditColumns showHint />
                                        <StackMoreActions stack={stack} />
                                    </div>
                                )
                            }
                        />

                        {errorMessage ? (
                            <div className="bg-red-100 w-fullpy-20">
                                <EmptyState
                                    variant="light"
                                    emojis={['🌋', '🌪', '💥']}
                                    title=" Error while loading data"
                                    subtitle={errorMessage}
                                    action={{
                                        label: 'Back to List',
                                        href: '/settings/stacks',
                                    }}
                                />
                            </div>
                        ) : stack ? (
                            <StackTable
                                rows={rows}
                                isLoading={isFetching && !didFetch}
                                tableHeadKey={tableHeadKey}
                            />
                        ) : (
                            <div className="grid overflow-x-auto relative bg-white">
                                <CellsLoading />
                            </div>
                        )}
                    </AllColumnsContext.Provider>
                </TableRowsProvider>
            </TableDataProvider>
        </div>
    )
}

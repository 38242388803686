import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { CommandLine } from '@laserfocus/ui/beam'
import { goToPersonPage } from '@laserfocus/client/util-routing'
import { useHotKey, useListNavigation } from '@laserfocus/ui/util-keyboard'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import {
    isAccountResult,
    isContactResult,
    isLeadResult,
    isOpportunityResult,
    SearchResult,
} from '@laserfocus/shared/models'
import { useUserId } from '@laserfocus/client/feature-auth'
import { ensureContactSynced, ensureOpportunitySynced } from '@laserfocus/client/data-access-shared'

import { SearchResultOption } from './SearchResultOption'
import { useSearchStore } from './SearchStore'
import { sortSearchResults } from './search-sort'

interface SearchProps {
    show: boolean
    close(): void
    onSubmit?(searchResult: SearchResult): void
}

export function Search(props: SearchProps) {
    return (
        <CommandLine.Modal show={props.show} close={props.close}>
            <SearchInner {...props} />
        </CommandLine.Modal>
    )
}

export const SearchInner = observer(function SearchInner({
    close,
    onSubmit = navigateToSearchResult,
}: SearchProps) {
    const [inputValue, setInputValue] = useState('')
    const [selectedIndex, setSelectedIndex] = useState(0)
    const searchStore = useSearchStore()
    const userId = useUserId()
    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)

    const shouldSearch = inputValue.length > 1

    useEffect(() => {
        shouldSearch && searchStore.debouncedSearch(inputValue)
    }, [shouldSearch, inputValue, searchStore])

    const {
        currentData,
        currentSearchTerm,
    }: { currentData: SearchResult[]; currentSearchTerm: string } = searchStore

    const sortedOptions = useMemo(() => {
        const options = shouldSearch ? currentData : []

        const sorted = sortSearchResults(options, currentSearchTerm, userId)
        return sorted
    }, [currentData, currentSearchTerm, shouldSearch, userId])

    useListNavigation(setSelectedIndex, sortedOptions.length)
    useHotKey(
        'enter',
        (event) => {
            event.preventDefault()
            const option = sortedOptions[selectedIndex]
            if (option) {
                onSubmit(option)
                close()
            }
        },
        { global: true }
    )

    return (
        <>
            <CommandLine.Input
                ref={inputRef}
                value={inputValue}
                onChange={(event) => {
                    setInputValue(event.target.value)
                    setSelectedIndex(0)
                }}
                placeholder="Search for leads, accounts, opps, etc."
                aria-label="Search for leads, accounts, opps, etc."
                isLoading={searchStore.isLoading}
            />
            {sortedOptions.length > 0 && (
                <CommandLine.Content>
                    {sortedOptions.map((searchResult, index) => {
                        return (
                            <SearchResultOption
                                key={searchResult.Id}
                                searchResult={searchResult}
                                onClick={() => {
                                    onSubmit(searchResult)
                                    close()
                                }}
                                isSelected={index === selectedIndex}
                                select={() => setSelectedIndex(index)}
                            />
                        )
                    })}
                </CommandLine.Content>
            )}
        </>
    )
})

export function navigateToSearchResult(searchResult: SearchResult) {
    if (isAccountResult(searchResult) || isLeadResult(searchResult)) {
        goToPersonPage(searchResult.Id)
    } else if (isContactResult(searchResult)) {
        ensureContactSynced(searchResult.Id, searchResult.AccountId)
        goToPersonPage(searchResult.AccountId, { contactId: searchResult.Id })
    } else if (isOpportunityResult(searchResult)) {
        ensureOpportunitySynced(searchResult.Id, searchResult.AccountId)
        goToPersonPage(searchResult.AccountId, { opportunityId: searchResult.Id })
    }
}

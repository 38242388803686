import { observer } from 'mobx-react-lite'
import { endOfYesterday, parseISO } from 'date-fns'
import clsx from 'clsx'

import { EverboardingHint } from '@laserfocus/client/feature-everboarding'
import { DateCell as BaseDateCell } from '@laserfocus/client/ui-shared-datagrid'
import { OpportunityFields } from '@laserfocus/shared/models'

import { TableCellProps } from './TableCell'

interface DateCellProps extends TableCellProps {
    dateOnly?: boolean
}

export const DateCell = observer(function DateCell({
    salesObject,
    column,
    searchTerm,
    dateOnly,
    rowIndex,
    tableRef,
}: DateCellProps) {
    const rawValue = (column.getValue(salesObject) as string | null | Date) || undefined
    const date = typeof rawValue === 'string' ? parseISO(rawValue) : rawValue
    const isDateInPast = date && date < endOfYesterday()

    return (
        <BaseDateCell
            name={column.key}
            updateValue={(value) => column.updateValue(salesObject, value)}
            value={column.getValue(salesObject) as string | null}
            dateOnly={dateOnly}
            readOnly={!column.isEditable(salesObject)}
            searchTerm={searchTerm}
            tableRef={tableRef}
            buttonClassName={clsx(
                column.key === 'CloseDate' &&
                    isDateInPast &&
                    !(salesObject as OpportunityFields).IsClosed &&
                    'text-red-500'
            )}
            renderButton={({ children }) => {
                return (
                    <>
                        {rowIndex === 0 && column.key === 'CloseDate' ? (
                            <EverboardingHint
                                name="everboarding_stack_pastclosedate_change"
                                label="Change close date"
                                placement="left"
                                containerClassName="-translate-y-14 translate-x-8"
                                labelClassName="-translate-y-1 -rotate-6 translate-x-9"
                                arrowClassName="rotate-45"
                            >
                                {children}
                            </EverboardingHint>
                        ) : (
                            children
                        )}
                    </>
                )
            }}
        />
    )
})

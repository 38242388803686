import { HighlightedCellValue } from '@laserfocus/client/ui-shared-datagrid'

import { TableCellProps } from './TableCell'

export function FallbackCell({ column, salesObject, searchTerm }: TableCellProps) {
    const value = column.getValue(salesObject) as unknown

    if (typeof value === 'string') {
        return <HighlightedCellValue title={value} value={value} searchTerm={searchTerm} />
    }

    if (typeof value === 'boolean' || value instanceof Date || typeof value === 'number') {
        const stringValue = value.toString()
        return (
            <HighlightedCellValue title={stringValue} value={stringValue} searchTerm={searchTerm} />
        )
    }

    return null
}

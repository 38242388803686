export type Optional<T> = T | null
export type Maybe<T> = T | null | undefined

export type UndefinedToOptional<T> = Required<{
    [K in keyof T]: undefined extends T[K] ? T[K] | null : T[K]
}>
export type UndefinedToMaybe<T> = Required<{
    [K in keyof T]: undefined extends T[K] ? T | null | undefined : T[K]
}>

export type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export type NullToUndefined<T> = {
    [K in keyof T]: null extends T[K] ? Exclude<T[K], null> | undefined : T[K]
}

export type Nullable<T> = { [P in keyof T]: Optional<T[P]> }

export type ExcludeMethods<T> = Pick<T, Properties<T>>

export type Properties<T> = NonNullable<
    { [K in keyof T]: T[K] extends (_: any) => any ? never : K }[keyof T]
>

/**
 * Make keys in Object optional
 * @example
 * PartialPick<OriginalType, 'foo' | 'bar'>
 */
export type PartialPick<T, K extends keyof T> = {
    [P in K]?: T[P]
}

export function isTruthy<T>(value: T): value is Exclude<T, null | undefined | false | '' | 0> {
    return Boolean(value)
}

export type Brand<K, T> = K & { __brand: T }

import { Card } from '@laserfocus/ui/beam'
import { useCurrency } from '@laserfocus/client/feature-auth'
import { getCurrencyFormatter } from '@laserfocus/client/util-formatter'

export function RecordCard({
    title,
    icon,
    children,
    action,
}: {
    title: string
    icon: React.ReactNode
    children: React.ReactNode
    action?: React.ReactNode
}) {
    return (
        <Card className="px-3 py-2.5">
            <div className="flex items-center">
                <div>
                    <Avatar>{icon}</Avatar>
                </div>
                <div className="flex-1 w-full ml-3 font-semibold">{title}</div>
                {action && <div>{action}</div>}
            </div>
            <div>
                <div className="py-2 grid gap-2 grid-cols-[minmax(0,2fr),minmax(0,3fr)] justify-start ">
                    {children}
                </div>
            </div>
        </Card>
    )
}

export function Name({ children }: { children: React.ReactNode }) {
    return (
        <div className="text-sm font-medium leading-[1.45] text-grey-700/60 pr-6">{children}</div>
    )
}

export function Value({ children }: { children: React.ReactNode }) {
    return <div className="text-sm font-medium leading-[1.45] text-grey-700">{children}</div>
}

export function Currency({ value, currencyIsoCode }: { value: number; currencyIsoCode?: string }) {
    const orgCurrency = useCurrency()
    const currencyFormatter = getCurrencyFormatter(currencyIsoCode || orgCurrency)
    return <>{currencyFormatter.format(value)}</>
}

export function Avatar({ children }: { children: React.ReactNode }) {
    return (
        <div className="bg-grey-700/5 rounded-full w-11 h-11 grid place-items-center text-[1.5rem] leading-none">
            {children}
        </div>
    )
}

import { platform, app, tray } from '@todesktop/client-core'
import { observable, reaction, runInAction } from 'mobx'
import { now } from 'mobx-utils'
import { addMinutes, format } from 'date-fns'

import { EventUtil, TaskUtil, ActivityUtil } from '@laserfocus/client/model'
import { LeadModel, NewEvent, NewTask } from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { activityQuery } from '@laserfocus/client/data-access-shared'
import { TodayView } from '@laserfocus/client/store-shared'

export class DesktopStore {
    todayView: TodayView
    reactions: Array<() => void> = []

    @observable
    notificationKeys: Set<string> = new Set()

    constructor(todayView: TodayView) {
        this.todayView = todayView
    }

    onInit() {
        if (platform.todesktop.isDesktopApp()) {
            this.initTodayOpenBadge()
            this.initEventNotification()
            this.initTrayReminder()
        }
    }

    onDestroy() {
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    initTodayOpenBadge() {
        this.reactions.push(
            reaction(
                () => {
                    const tasks = this.todayView.data.filter(ActivityUtil.isTask) as NewTask[]
                    return tasks.filter((t) => TaskUtil.isDue(t)).length
                },
                (openCount: number) => {
                    app.dock.setBadge(openCount ? `${openCount}` : '')
                }
            )
        )
    }

    initTrayReminder() {
        this.reactions.push(
            reaction(
                () => {
                    const events = this.todayView.data.filter(ActivityUtil.isEvent) as NewEvent[]
                    const upcomingEvents = events
                        .filter((e) => e.StartDateTime)
                        .filter((e) => !EventUtil.isOver(e, now(30000)))
                        .sort(ActivityUtil.activitySorter)

                    const next = upcomingEvents[0]
                    return next
                },
                (next: NewEvent) => {
                    if (next) {
                        const title = formatTrayTitle(
                            next.Subject ?? undefined,
                            next.StartDateTime!
                        )

                        tray.setTitle(title)
                    } else {
                        tray.setTitle('')
                    }
                }
            )
        )
    }

    initEventNotification() {
        this.reactions.push(
            reaction(
                () => {
                    const in5Minutes = addMinutes(new Date(), 5)
                    const events = this.todayView.data.filter(ActivityUtil.isEvent) as NewEvent[]
                    const eventsStartIn5Minutes = events
                        .filter((e) => this.notificationKeys.has(getNotificationKey(e)))
                        .filter((e) => {
                            const d = EventUtil.getSortingDate(e)
                            return d && d > new Date(now()) && d < in5Minutes
                        })
                    return eventsStartIn5Minutes
                },
                (events: NewEvent[]) => {
                    events.forEach((event) => {
                        this.sendNotification(event)
                    })
                }
            )
        )
    }

    async sendNotification(event: NewEvent) {
        runInAction(() => {
            this.notificationKeys.add(getNotificationKey(event))
        })

        const client = getClient()
        const { contact, lead, account } = await client.query((tx) =>
            activityQuery.queryActivityRelations(tx, event)
        )

        const contactOrLead = contact || lead

        const personName = contactOrLead?.Name || event.Who?.Name
        const companyName =
            (contactOrLead as LeadModel)?.Company ||
            account?.Name ||
            event.Account?.Name ||
            event.Who?.Company

        const { title, options } = getNotificationProps(event, personName, companyName)

        new Notification(title!, options)
    }
}

/**
 * NotificationKey is used to deduplicate them (but also for time)
 */
function getNotificationKey(event: NewEvent) {
    return `${event.Id}-${event.StartDateTime || format(new Date(), 'YYYY-MM-DD HH:mm')}`
}
function getNotificationProps(
    event: NewEvent,
    personName?: string | null,
    companyName?: string | null
) {
    const prefix = companyName ? `${companyName}: ` : ''
    const title = `${prefix}${event.Subject}`
    const options: NotificationOptions = {
        tag: getNotificationKey(event),
    }
    return { title, options }
}

const THIN_SPACE = '\u2009'

function formatTrayTitle(subject: string | undefined, date: string) {
    const title = subject || 'Next Event'
    const trimmedTitle = title.length > 30 ? title.substring(0, 30) + '...' : title
    const now = new Date()
    const ev = new Date(date)

    const msDistance = ev.getTime() - now.getTime()
    const msToHours = 1000 * 60 * 60
    const hours = Math.floor(msDistance / msToHours)
    const remaining = msDistance - hours * msToHours

    const msToMinutes = 1000 * 60
    const minutes = Math.floor(remaining / msToMinutes)

    const when = ['in', hours && `${hours}${THIN_SPACE}h`, minutes && `${minutes}${THIN_SPACE}m`]
        .filter(Boolean)
        .join(' ')
    return [trimmedTitle, when].join(' · ')
}

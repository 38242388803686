import TextareaAutosize from 'react-textarea-autosize'
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import { Button, toast } from '@laserfocus/ui/beam'
import { isMutationErrorResponse, shouldShowErrorMessage } from '@laserfocus/shared/models'
import { getMutationErrorMessage } from '@laserfocus/client/util-formatter'
import { logger } from '@laserfocus/ui/logger'
import { useActivitiesMetadataContext } from '@laserfocus/client/data-access-shared'
import { TaskSelects } from '@laserfocus/client/ui-shared-task'

const DEBOUNCED_SAVE_TIMEOUT = 300

interface ActivityFormProps {
    activityType?: 'Event' | 'Task'
    defaultSubject?: string
    defaultDescription?: string
    onSubmit: (data: {
        subject: string
        description: string
        type: string | null | undefined
        priority: string | undefined
    }) => Promise<void> | void
    onCancel?: React.ReactEventHandler<HTMLButtonElement>
    focusSubjectOnMount?: boolean
    localStorageCacheKey?: string
    defaultType: string | null | undefined
    defaultPriority: string | null | undefined
    noPriority?: boolean
}

export function ActivityForm({
    activityType = 'Task',
    defaultSubject = '',
    defaultDescription = '',
    defaultType = null,
    defaultPriority,
    onSubmit,
    onCancel,
    focusSubjectOnMount,
    localStorageCacheKey,
    noPriority,
}: ActivityFormProps) {
    const subjectKey = localStorageCacheKey && `lf:activityform:${localStorageCacheKey}:subject`

    const [subject, setSubject] = useState(() => {
        if (defaultSubject) {
            return defaultSubject
        } else if (subjectKey) {
            return localStorage.getItem(subjectKey) || ''
        }
        return ''
    })
    useEffect(() => {
        if (subjectKey) {
            const timeout = setTimeout(() => {
                localStorage.setItem(subjectKey, subject)
            }, DEBOUNCED_SAVE_TIMEOUT)
            return () => clearTimeout(timeout)
        }
    }, [subject, subjectKey])

    const descriptionKey =
        localStorageCacheKey && `lf:activityform:${localStorageCacheKey}:description`
    const [description, setDescription] = useState(() => {
        if (defaultDescription) {
            return defaultDescription
        } else if (descriptionKey) {
            return localStorage.getItem(descriptionKey) || ''
        }
        return ''
    })
    useEffect(() => {
        if (descriptionKey) {
            const timeout = setTimeout(() => {
                localStorage.setItem(descriptionKey, description)
            }, DEBOUNCED_SAVE_TIMEOUT)
            return () => clearTimeout(timeout)
        }
    }, [description, descriptionKey])

    const metadata = useActivitiesMetadataContext()
    const metaTypes = activityType === 'Event' ? metadata?.eventTypes : metadata?.taskTypes
    const [shouldAutoSelectType, setShouldAutoSelectType] = useState(!subject && !description)
    const [type, setType] = useStateWithDefault<string | null | undefined>(defaultType)

    useEffect(() => {
        if (shouldAutoSelectType) {
            const subjectLowerCase = subject.toLowerCase()
            const typeToSelect = metaTypes
                ?.map(({ label, value }) => ({
                    value,
                    labelIndex: label ? subjectLowerCase.indexOf(label.toLowerCase()) : -1,
                }))
                .filter((t) => t.labelIndex !== -1)
                .sort((a, b) => a.labelIndex - b.labelIndex)[0]

            setType(typeToSelect?.value || '')
        }
    }, [shouldAutoSelectType, metadata, subject, setType, metaTypes])

    const [priority, setPriority] = useStateWithDefault(defaultPriority)

    const [isFocused, setIsFocused] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const subjectInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (focusSubjectOnMount) {
            subjectInputRef.current?.focus()
        }
    }, [focusSubjectOnMount])

    function handleFocus(event: React.FocusEvent<HTMLFormElement>) {
        if (!isFocused && !event.currentTarget.contains(event.relatedTarget as Node | null)) {
            setIsFocused(true)

            if (event.target === event.currentTarget) {
                subjectInputRef.current?.focus()
            }
        }
    }

    function handleBlur(event: React.FocusEvent<HTMLFormElement>) {
        if (isFocused && !event.currentTarget.contains(event.relatedTarget as Node | null)) {
            setIsFocused(false)
        }
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setIsSubmitting(true)

        try {
            await onSubmit({
                subject,
                description,
                type: metaTypes ? type : undefined,
                priority: metadata?.taskPriorities ? priority ?? undefined : undefined,
            })
            setSubject(defaultSubject)
            setDescription(defaultDescription)
            subjectKey && localStorage.removeItem(subjectKey)
            descriptionKey && localStorage.removeItem(descriptionKey)
        } catch (e: unknown) {
            if (shouldShowErrorMessage(e) || isMutationErrorResponse(e)) {
                logger.warn(e as any)
                toast.error(getMutationErrorMessage(e))
            } else {
                logger.error(e as any)
                toast.error({ title: 'Something went wrong' })
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    const isPristine =
        subject === defaultSubject &&
        description === defaultDescription &&
        type === defaultType &&
        priority === defaultPriority

    return (
        <form
            tabIndex={1}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onSubmit={handleSubmit}
            className="py-3 rounded-lg bg-white ring-1 ring-grey-700/10 w-full outline-none transition hover:ring-grey-700/20 focus-within:ring focus-within:!ring-blue-500"
        >
            <input
                ref={subjectInputRef}
                aria-label="Activity subject"
                placeholder="Subject"
                value={subject}
                className="text-base font-semibold leading-[1.4] block px-3 pb-1 outline-none w-full text-ellipsis placeholder-grey-700/40"
                onChange={(event) => setSubject(event.target.value)}
            />
            <TextareaAutosize
                aria-label="Activity description"
                placeholder="Details"
                maxRows={40}
                cacheMeasurements
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                className="block pb-4 px-3 outline-none w-full resize-none text-sm font-medium leading-[1.4] tabular-nums placeholder-grey-700/40 bg-transparent"
            />
            <div
                className={clsx(
                    'grid grid-flow-col gap-4 justify-between px-3',
                    isPristine && !subject && !description && !isFocused && 'invisible'
                )}
            >
                <TaskSelects
                    activityType={activityType}
                    type={type}
                    setType={(type) => {
                        setType(type)
                        setShouldAutoSelectType(false)
                    }}
                    priority={priority}
                    setPriority={setPriority}
                    className={isFocused ? undefined : 'invisible'}
                    noPriority={noPriority}
                />
                <div className="grid grid-flow-col gap-2">
                    {onCancel && (
                        <Button variant="secondary" size="small" type="button" onClick={onCancel}>
                            Cancel
                        </Button>
                    )}
                    <Button
                        variant="primary"
                        size="small"
                        type="submit"
                        disabled={isPristine || isSubmitting || (!subject && !description)}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </form>
    )
}

function useStateWithDefault<T>(initialState: T): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [state, setState] = useState<T>(initialState)

    useEffect(() => {
        setState((curr) => (curr ? curr : initialState))
    }, [initialState])

    return [state, setState]
}

import { useEffect, useState, createContext, useRef, useContext } from 'react'
import { observer, useObserver } from 'mobx-react-lite'
import { Replicache } from 'replicache'

import { getClient } from '@laserfocus/client/replicache'
import { TaskIdSchema } from '@laserfocus/shared/models'
import { updateActivity } from '@laserfocus/client/data-access-shared'
import { ObjectPool, QueryStatus, ReplicacheDeltaSyncer } from '@laserfocus/client/data-layer'
import { TaskModel, EventModel } from '@laserfocus/client/model'
import { TodayView } from '@laserfocus/client/store-shared'

function update() {
    const subject = Math.random() + 'Subject'
    updateActivity({
        activityId: TaskIdSchema.parse('00T1i000025zOwaEAE'),
        subject,
    })
}
function updateHere() {
    const subject = Math.random() + 'Subject'
    updateActivity({
        activityId: TaskIdSchema.parse('00T1i000025zOwkEAE'),
        subject,
    })
}

class RootStore {
    view: TodayView

    constructor(objectPool: ObjectPool, replicache: Replicache) {
        objectPool.registerModel('Task', TaskModel)
        const syncer = new ReplicacheDeltaSyncer(objectPool, replicache)
        syncer.onInit()
        const deps = {
            objectPool,
            replicache,
            queryStatus: new QueryStatus(),
        }
        this.view = new TodayView(deps)
        this.view.onInit()
    }
}

let store: RootStore
function getRootStore() {
    if (!store) {
        store = new RootStore(new ObjectPool(), getClient())
        ;(window as any).__play = store
        // ;(window as any).__test = new MobxTest()
    }
    return store
}

const PlayContext = createContext<RootStore>(undefined)
function Provider({ children }: { children: React.ReactNode }) {
    const store = useRef(getRootStore())

    return <PlayContext.Provider value={store.current}>{children}</PlayContext.Provider>
}

export function Playground() {
    const [key, setKey] = useState(1)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        const handle = setTimeout(() => setReady(true), 300)
        return () => clearTimeout(handle)
    }, [])
    return (
        <Provider>
            <div style={{ padding: 24 }}>
                <button onClick={() => setKey((a) => a + 1)}>Rerender</button>
                {ready && <PlaygroundInner key={key} />}
            </div>
        </Provider>
    )
}

function PlaygroundInner() {
    const [searchTerm, setSearchTerm] = useState('')
    const { todayTasks: activities, leadIds } = useToday(searchTerm)
    return (
        <div>
            <div className="p-6 grid justify-start">
                <button onClick={update}>Trigger Update</button>
                <button onClick={updateHere}>Trigger Update Here</button>
                Total: {activities.length}
                <br />
                LeadIds: {leadIds.length}
                <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    className="border-2"
                />
            </div>
            {activities.map((task) => {
                return <TaskItem task={task} key={task.Id} />
            })}
        </div>
    )
}

const TaskItem = observer(function TaskItem({ task }: { task: TaskModel | EventModel }) {
    return (
        <li>
            {task.Id}: <Subject>{task.Subject}</Subject> | {task.Who?.Name} ({task.WhoId})
        </li>
    )
})

function Subject({ children }: { children: React.ReactNode }) {
    return <span className="m-2 font-semibold">{children}</span>
}

function useToday(searchTerm: string) {
    const store = useContext(PlayContext)

    useEffect(() => store.view.onMount(), [store])
    return useObserver(() => {
        if (searchTerm) {
            const res = store.view.trie.search(searchTerm)
            const resultIds = new Set(res.map((a) => a.id))
            return {
                todayTasks: store.view.data.filter((a) => resultIds.has(a.Id)),
                leadIds: [], //store.,
            }
        }

        return {
            todayTasks: store.view.data,
            leadIds: [], // store.leadIds,
        }
    })
}

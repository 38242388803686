import { observer } from 'mobx-react-lite'

import { SalesforceLogoIcon } from '@laserfocus/ui/icons'
import { useInstanceUrl } from '@laserfocus/client/feature-auth'

import { TableCellProps } from '../TableCell'

export const OpenInSFCell = observer(function OpenInSFCell({
    salesObject,
    column,
    searchTerm,
    tableRef,
}: TableCellProps) {
    const instanceUrl = useInstanceUrl()
    const id = column.getValue(salesObject)
    const link = `${instanceUrl}/${id}`
    if (!id) {
        return null
    }
    return (
        <div className="grid h-full items-center justify-start grid-flow-col group-inner">
            <a
                title="Open in Salesforce"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-grey-700/20 rounded-md outline-none group-inner-hover:text-salesforce group-inner-hover:ring-1 group-inner-hover:ring-grey-700/10  hover:!ring-grey-700/20 group-inner-hover:bg-white"
            >
                <SalesforceLogoIcon className="w-4 h-4 p-[0.4375rem] box-content" />
            </a>
        </div>
    )
})

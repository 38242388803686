import { pick, sum } from 'lodash'

import { StackOverview } from '@laserfocus/client/feature-stacks'
import { wrapAuthenticated } from '@laserfocus/client/feature-auth'
import { useTitle } from '@laserfocus/ui/util-react'
import {
    EverboardingHintConfig,
    useConditionalTrackUntilCount,
    useRegisterEverboardingHint,
    EverboardingCounters,
} from '@laserfocus/client/feature-everboarding'

import { HomeLayout } from './HomeLayout'
import { useMobileWarning } from './useMobileWarning'

const AuthenticatedHomePage = wrapAuthenticated(HomePage)
export default AuthenticatedHomePage

export function HomePage() {
    useTitle('Home')
    useHomepageEverboarding()
    useMobileWarning()

    return (
        <HomeLayout>
            <StackOverview />
        </HomeLayout>
    )
}

const tableActivitiesNames: Array<keyof EverboardingCounters> = [
    'hideColumn',
    'showColumn',
    'removeFilter',
    'setSorting',
    'setFilterCondition',
]

const addStackHint: EverboardingHintConfig = {
    name: 'everboarding_home_add_stack',
    shouldShow: (counters: EverboardingCounters) => {
        const homeViewCount = counters?.everboarding_home_view || 0
        const importedEverboardingCount = counters?.everboarding_home_stack_import_tab
        const importCount = counters?.createStackFromView || 0

        const tableActivities = sum(Object.values(pick(counters, ...tableActivitiesNames)))

        const homeViewThreshold = tableActivities >= 5 ? 10 : 5
        return homeViewCount > homeViewThreshold && !importedEverboardingCount && !importCount
    },
}

const selectImportFromSalesforceTab: EverboardingHintConfig = {
    name: 'everboarding_home_stack_import_tab',
    shouldShow: (counters: EverboardingCounters) => {
        const importCount = counters?.createStackFromView || 0
        return !importCount
    },
}

function useHomepageEverboarding() {
    useRegisterEverboardingHint(addStackHint)
    useRegisterEverboardingHint(selectImportFromSalesforceTab)
    useConditionalTrackUntilCount('everboarding_home_view', 40)
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function More2OutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M16 12a2 2 0 014 0 2 2 0 11-4 0zm-6 0a2 2 0 014 0 2 2 0 11-4 0zm-6 0a2 2 0 014 0 2 2 0 01-2 2 2 2 0 01-2-2z" />
        </SvgIcon>
    )
}

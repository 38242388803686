import { useIdentity } from '@laserfocus/client/feature-auth'

import { FailedMutationRootContextProvider } from './failed-mutation/FailedMutationContext'
import { useFailedMutationToast } from './failed-mutation/useFailedMutationToast'
import { useGlobalError } from './useGlobalError'

export function ErrorContainer({ children }: { children: React.ReactNode }) {
    return (
        <FailedMutationRootContextProvider>
            <GlobalErrorHooks />
            {children}
        </FailedMutationRootContextProvider>
    )
}

function GlobalErrorHooks() {
    const user = useIdentity()
    useFailedMutationToast(user)
    useGlobalError()
    return null
}

import { SwitchCell as BaseSwitchCell } from '@laserfocus/client/ui-shared-datagrid'

import { BulkEditCellProps } from './BulkEditCell'

export const BulkEditSwitchCell = function SwitchCell({
    column,
    value,
    isEditable,
    hasMultipleValues,
    updateValue,
}: BulkEditCellProps) {
    return (
        <BaseSwitchCell
            value={value}
            readOnly={!isEditable}
            name={column.key}
            updateValue={(value) => updateValue(value)}
            indeterminate={hasMultipleValues}
            onDarkBackground
        />
    )
}

import { createContext, useContext } from 'react'

import { logger } from '@laserfocus/ui/logger'

/**
 * In the future this can be merged with the StackContext
 */

type StackNavigationStackContext = {
    // nextRoot: Account | Lead
    navigateNext: () => Promise<void> | void
    navigatePrevious: () => Promise<void> | void
    navigateList: () => Promise<void> | void
    listPath: string
    title?: string | null
}
type EmptyStackNavigationContext = {
    skip: true
    navigateList: () => Promise<void> | void
}
export type StackNavigationContext = StackNavigationStackContext | EmptyStackNavigationContext

const StackNavigationReactContext = createContext<StackNavigationContext>({
    skip: true,
    navigateList: () => logger.warn('IMPLEMENT ME'),
})

export const StackNavigationProvider = StackNavigationReactContext.Provider

export function shouldSkipStackNavigation(
    context: StackNavigationContext
): context is EmptyStackNavigationContext {
    return (context as EmptyStackNavigationContext).skip
}

export function useStackNavigation() {
    return useContext(StackNavigationReactContext)
}

import { isValid, parse } from 'date-fns'

const regex12h = /^(?<hours>[01]?\d)(:(?<minutes>[0-5]\d))?( ?(?<period>[ap]m?))?$/i
const regex24h = /^(?<hours>[012]?\d)(:(?<minutes>[0-5]\d))?$/i

export function getDateFromUserTimeString(timeString: string, dateToFill: Date = new Date()) {
    const match24h = regex24h.exec(timeString)

    if (match24h) {
        const hours = match24h.groups!.hours!.padStart(2, '0')
        const minutes = match24h.groups!.minutes?.padStart(2, '0') ?? 0
        const newSelectedTime = parse(`${hours}:${minutes}`, 'HH:mm', dateToFill)

        if (isValid(newSelectedTime)) {
            return newSelectedTime
        }
    }

    const match12h = regex12h.exec(timeString)

    if (match12h) {
        const hours = match12h.groups!.hours!.padStart(2, '0')
        const minutes = match12h.groups!.minutes?.padStart(2, '0') ?? 0
        const period = match12h.groups!.period?.padEnd(2, 'm') ?? 'am'
        const newSelectedTime = parse(`${hours}:${minutes} ${period}`, 'hh:mm a', dateToFill)

        if (isValid(newSelectedTime)) {
            return newSelectedTime
        }
    }

    return null
}

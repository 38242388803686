import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ExclamationOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M11 8a1 1 0 112 0v4a1 1 0 11-2 0V8zM12 15a1 1 0 100 2 1 1 0 000-2z" />
            <path d="M2 12a10 10 0 1120 0 10 10 0 01-20 0zm10-8a8 8 0 100 16 8 8 0 000-16z" />
        </SvgIcon>
    )
}

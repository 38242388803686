import clsx from 'clsx'
import { useState } from 'react'
import { matchSorter } from 'match-sorter'

import { Button, Checkbox, Modal, toast, useDropdownKeyboardNavigation } from '@laserfocus/ui/beam'
import { User, UserId } from '@laserfocus/shared/models'
import { logger } from '@laserfocus/ui/logger'
import { CheckFilledIcon } from '@laserfocus/ui/icons'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { Analytics } from '@laserfocus/client/util-analytics'
import { inviteColleagues } from '@laserfocus/client/data-access-shared'

import {
    ShareModalBodyProps,
    UserListItemProps,
    useSelectedUsers,
} from '../share-modal/ShareModalBody'

import { useUsersToInvite } from './useUsersToInvite'

interface InviteCoworkerModalProps extends InviteCoworkerModalInnerProps {
    show: boolean
}
export function InviteCoworkerModal({ show, closeModal, ...rest }: InviteCoworkerModalProps) {
    return (
        <Modal show={show} onClose={closeModal}>
            <InviteCoworkerModalInner closeModal={closeModal} {...rest} />
        </Modal>
    )
}

interface InviteCoworkerModalInnerProps {
    closeModal: () => void
    userId?: UserId
    hideOverlay?: boolean
}

function InviteCoworkerModalInner({
    closeModal,
    userId,
    hideOverlay,
}: InviteCoworkerModalInnerProps) {
    const rows = useUsersToInvite(userId)

    function submit(userIds: string[]) {
        inviteColleagues(userIds as UserId[], userId).then(
            () => {
                Analytics.trackEvent({
                    event: 'coworker_invited_fe',
                    payload: {
                        userCount: userIds.length,
                    },
                })

                let singleUser: User | undefined
                if (userIds.length) {
                    const singleInviteeId = userIds[0]
                    singleUser = rows.find((a) => a.user.Id === singleInviteeId).user
                }

                toast.success({
                    title: `Laserfocus shared`,
                    description: `You just invited ${
                        singleUser?.Name || `${userIds.length} coworker`
                    }`,
                })
                closeModal()
            },
            (e) => {
                logger.error(e)
                toast.error({ title: 'Error while inviting your coworker' })
            }
        )
    }

    return (
        <InviteCoworkerModalBody
            rows={rows}
            submit={submit}
            closeModal={closeModal}
            userId={userId}
            hideOverlay={hideOverlay}
        />
    )
}

type InviteUserRow = {
    user: User
    alreadyUser: boolean
    alreadyInvited: boolean
    inviter?: User
}

interface InviteCoworkerModalBodyProps
    extends Pick<ShareModalBodyProps, 'submit' | 'closeModal' | 'initiallySelected'> {
    rows: InviteUserRow[]
    userId: UserId
    hideOverlay?: boolean
}

export function InviteCoworkerModalBody({
    rows,
    submit,
    closeModal,
    initiallySelected,
    userId,
    hideOverlay,
}: InviteCoworkerModalBodyProps) {
    const [searchValue, setSearchValue] = useState('')
    const searchInputRef = useAutoFocusedRef<HTMLInputElement>(true)

    const { selectedById, toggleUserId } = useSelectedUsers(initiallySelected)
    const selectedCount = Object.keys(selectedById).length

    const filteredOptions = searchValue
        ? matchSorter(rows, searchValue, {
              keys: ['user.Name', 'user.Username'],
          })
        : rows

    const { hoveredOptionIndex, setHoveredOptionIndex } = useDropdownKeyboardNavigation({
        optionsLength: filteredOptions.length,
        resetKey: searchValue,
        submit: (index) => {
            const row = filteredOptions[index]!
            if (!row.alreadyInvited) {
                const userId = row.user.Id
                toggleUserId(userId)
            }
        },
    })

    return (
        <>
            {!hideOverlay && <Modal.Overlay />}
            <Modal.Container>
                <Modal.Header close={closeModal} border>
                    Invite to Laserfocus
                </Modal.Header>

                <div className="px-8 py-4 border-b border-grey-700/10 bg-clip-padding">
                    Invite 3 colleagues and receive 1 month{' '}
                    <span className="text-blue-500 font-semibold">PRO</span> for free when they sign
                    up.
                </div>
                <Modal.SearchInput
                    ref={searchInputRef}
                    placeholder="Search for person"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    reset={() => setSearchValue('')}
                    className="mt-4"
                />
                <div className="min-h-[21.75rem] max-h-[50vh] mx-8 my-3 overflow-y-auto">
                    {filteredOptions.map((row, index) => {
                        const { user, alreadyInvited, inviter, alreadyUser } = row
                        const isHovered = index === hoveredOptionIndex
                        const setIsHovered = () => setHoveredOptionIndex(index)
                        return (
                            <InviteUserItem
                                key={user.Id}
                                isHovered={isHovered}
                                setIsHovered={setIsHovered}
                                onToggle={() => toggleUserId(user.Id)}
                                isSelected={Boolean(selectedById[user.Id])}
                                user={user}
                                alreadyInvited={alreadyInvited}
                                alreadyUser={alreadyUser}
                                userId={userId}
                                inviterUser={inviter}
                            />
                        )
                    })}
                </div>
                <Modal.Footer border className="justify-between">
                    <div className="pl-3 text-sm text-grey-700/60">
                        {selectedCount ? `${selectedCount} selected` : ''}
                    </div>
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={selectedCount === 0}
                        onClick={() => submit(Object.keys(selectedById))}
                    >
                        Invite Colleagues
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </>
    )
}

interface InviteUserItemProps extends UserListItemProps {
    alreadyInvited: boolean
    alreadyUser: boolean
    inviterUser?: User
    userId: UserId
}

function InviteUserItem({
    onToggle,
    isSelected,
    isHovered,
    setIsHovered,
    user,
    alreadyInvited,
    alreadyUser,
    userId,
    inviterUser,
}: InviteUserItemProps) {
    const name = user.Name
    const username = user.Username
    function handleCheckboxKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.currentTarget.blur()
        }
    }

    const secondaryText = (() => {
        if (alreadyUser) {
            // if (alreadyInvited) {
            //     if (inviterUser) {
            //         if (inviterUser.Id === userId) {
            //             return 'Sucessfully invited'
            //         }
            //         return `Sucessfully invited by ${inviterUser?.Name || 'a colleague'}`
            //     }
            // }
            return 'Signed up'
        }
        if (alreadyInvited) {
            if (inviterUser) {
                if (inviterUser.Id === userId) {
                    return 'Pending invite'
                }
                return `Pending invite by ${inviterUser?.Name || 'a colleague'}`
            }
        }
        return username
    })()

    return (
        <div
            onMouseMove={setIsHovered}
            className={clsx(
                'py-1.5 w-full grid grid-flow-col grid-cols-[auto,1fr] items-center rounded-md cursor-pointer',
                isHovered && 'bg-grey-700/5'
            )}
            onClick={alreadyInvited ? undefined : onToggle}
        >
            {alreadyInvited || alreadyUser ? (
                <div className="ml-[6px] items-center mr-1">
                    <CheckFilledIcon
                        className={clsx('w-5 h-5', inviterUser?.Id === userId && 'fill-green-500')}
                    />
                </div>
            ) : (
                <div className="ml-2 mr-2 items-center">
                    <Checkbox
                        checked={isSelected}
                        onChange={onToggle}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={handleCheckboxKeyDown}
                        onFocus={setIsHovered}
                    />
                </div>
            )}

            <div className="flex justify-between ml-1 mr-2 items-center">
                <span className="leading-[1.4] align-left">{name}</span>
                <span className="leading-[1.3] text-sm text-grey-700/60">{secondaryText}</span>
            </div>
        </div>
    )
}

import { memo, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import { InView } from '@laserfocus/ui/util-react'
import { HEADER_HEIGHT } from '@laserfocus/client/layout'

import { AVATAR_COLUMN_WIDTH, Column, useTableDataContext } from '../table-context'
import { TABLE_HEAD_HEIGHT } from '../TableHead/TableHead'
import { TABLE_PAGE_HEADER_HEIGHT } from '../../TablePageHeader/TablePageHeader'
import { useBulkEdit } from '../BulkEdit/bulkedit-context'
import { BulkEditTotalCheckbox } from '../BulkEdit/BulkEditTotalCheckbox'

import { TableFooterCell } from './TableFooterCell'

export const TABLE_FOOTER_HEIGHT = 46
export const TABLE_HEAD_ROW_CLASS_NAME = 'border-b border-grey-700/10 grid'

function getTranslateY(totalSize: number) {
    // -1 offset to make borders overlap
    const notFullTranslate = totalSize - TABLE_FOOTER_HEIGHT - 1
    const translateY =
        window.innerHeight +
        window.scrollY -
        TABLE_HEAD_HEIGHT -
        HEADER_HEIGHT -
        TABLE_FOOTER_HEIGHT -
        TABLE_PAGE_HEADER_HEIGHT -
        1
    return notFullTranslate < translateY ? notFullTranslate : translateY
}

export const TableFooter = memo(function TableFooter({ totalSize }: { totalSize: number }) {
    const { bulkEditTotal } = useBulkEdit()
    const rowRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const handler = () => {
            if (rowRef.current) {
                rowRef.current.style.transform = `translateY(${getTranslateY(totalSize)}px)`
            }
        }
        window.addEventListener('scroll', handler, { passive: true })
        return () => {
            window.removeEventListener('scroll', handler)
        }
    }, [totalSize])

    return (
        <div
            role="rowgroup"
            ref={rowRef}
            className={clsx(
                'absolute min-w-full z-15 select-none w-max border-t border-grey-700/10 ',
                bulkEditTotal !== 'none' ? 'bg-grey-700' : 'bg-white'
            )}
            style={{
                transform: `translateY(${getTranslateY(totalSize)}px)`,
                // +1 in order to have the space for the border
                height: TABLE_FOOTER_HEIGHT + 1,
            }}
        >
            <TableFooterContet isBulkEditActive={bulkEditTotal !== 'none'} />
        </div>
    )
})

export const TableFooterContet = function TableHead({
    isBulkEditActive,
    hideBulkEdit,
}: {
    isBulkEditActive: boolean
    hideBulkEdit?: boolean
}) {
    const [isHoveringRow, setIsHoveringRow] = useState(false)
    const { columns, tableWidth, setIsStickyLeftActive, gridTemplateColumns } =
        useTableDataContext()!

    const widthScaleFactor = getWidthScaleFactor(columns, tableWidth)

    return (
        <div
            role="row"
            style={{ gridTemplateColumns }}
            onMouseEnter={() => setIsHoveringRow(true)}
            onMouseLeave={() => setIsHoveringRow(false)}
            className="grid relative min-w-full"
        >
            <div
                className={clsx(
                    'grid grid-flow-col justify-end items-center p-0 group font-medium text-sm text-grey-700/60 py-3 pr-3 z-10',
                    isBulkEditActive ? 'sticky left-0 bg-grey-700' : 'bg-white'
                )}
            >
                {hideBulkEdit ? null : <BulkEditTotalCheckbox />}
                <InView
                    onIntersect={({ isIntersecting }) => setIsStickyLeftActive(!isIntersecting)}
                />
            </div>
            {columns.map((column, index) => {
                return (
                    <TableFooterCell
                        key={column.key}
                        column={column}
                        widthScaleFactor={widthScaleFactor}
                        isHoveringRow={isHoveringRow}
                        setIsHoveringRow={setIsHoveringRow}
                    />
                )
            })}
        </div>
    )
}

function getWidthScaleFactor(columns: Column[], tableWidth: number) {
    const allColumnsWidthTheoretical = columns.reduce((width, column) => width + column.width, 0)
    const allColumnsWidthPractical = tableWidth - AVATAR_COLUMN_WIDTH

    return Math.max(allColumnsWidthPractical / allColumnsWidthTheoretical, 1)
}

import gql from 'graphql-tag'

import { LEAD_FIELDS } from '@laserfocus/shared/models'

const LeadFields = `
     ${LEAD_FIELDS.join(`\n`)}
`

export const LEAD_FRAGMENT = gql`
  fragment LeadHeader on Lead {
    ${LeadFields}
    CustomFields
   }
`

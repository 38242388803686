import MiniSearch from 'minisearch'

export class LaserSearch<T> extends MiniSearch<T> {
    get options() {
        return this._options
    }
    has(id: string) {
        return Boolean(Object.values(this._documentIds).includes(id))
    }
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function DescendingOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M2 7a1 1 0 011-1h13a1 1 0 110 2H3a1 1 0 01-1-1zm0 4a1 1 0 011-1h9a1 1 0 110 2H3a1 1 0 01-1-1zm15-1a1 1 0 011 1v9.59l2.3-2.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-4-4a1 1 0 011.4-1.42l2.3 2.3V11a1 1 0 011-1zM2 15a1 1 0 011-1h9a1 1 0 110 2H3a1 1 0 01-1-1z" />
        </SvgIcon>
    )
}

import { useEditor, EditorOptions } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { EditorView } from 'prosemirror-view'

import { isTruthy } from '@laserfocus/shared/models'

// Fixes https://github.com/ueberdosis/tiptap/issues/2380
EditorView.prototype.updateState = function updateState(state) {
    const self = this as any
    if (!self.docView) return // This prevents the matchesNode error on hot reloads
    self.updateStateInner(state, this.state.plugins !== state.plugins)
}

interface UseNoteEditorProps extends Partial<Exclude<EditorOptions, 'extensions' | 'editorProps'>> {
    noPlaceholder?: boolean
}

export function useNoteEditor({ noPlaceholder, ...props }: UseNoteEditorProps) {
    return useEditor({
        ...props,
        extensions: [
            StarterKit,
            Underline,
            Image.configure({ inline: true }),
            Link,
            !noPlaceholder &&
                Placeholder.configure({
                    placeholder: ({ pos }) =>
                        pos === 0 ? 'Write something …' : (undefined as any),
                }),
        ].filter(isTruthy),
        editorProps: {
            attributes: {
                class: [
                    /*tw:*/ 'outline-none text-sm font-medium min-h-[4.5em]',
                    // Lists
                    /*tw:*/ 'prose-ul:list-disc prose-ul:pl-6 prose-ol:list-decimal prose-ol:pl-6',
                    // Headings
                    /*tw:*/ 'prose-headings:font-semibold',
                    // Links
                    /*tw:*/ 'prose-a:underline prose-a:cursor-pointer prose-a:text-blue-500',
                    // Placeholder
                    /*tw:*/ 'prose-p:before:content-[attr(data-placeholder)] prose-p:before:absolute prose-p:before:pointer-events-none prose-p:before:text-grey-700/40',
                ].join(' '),
            },
        },
    })
}

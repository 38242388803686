import { z } from '@laserfocus/shared/decoder'

import {
    CreateOptimisticSchema,
    makeBaseRecordSchema,
    UpdateOptimisticSchema,
} from '../base/singletable.types'

export const NoteTemplateBaseSchema = z.object({
    id: z.string(),
    name: z.string(),
    body: z.string().optional(),
    version: z.number().default(1),
})

export const NoteTemplateSchema = NoteTemplateBaseSchema.merge(
    makeBaseRecordSchema('UserNoteTemplate')
)

export const OrgNoteTemplateSchema = NoteTemplateBaseSchema.merge(
    makeBaseRecordSchema('OrgNoteTemplate')
).extend({
    roles: z.array(z.string()).default([]).optional(),
})

export type NoteTemplate = z.infer<typeof OrgNoteTemplateSchema>

export const NoteTemplateValuesSchema = OrgNoteTemplateSchema.pick({
    name: true,
    body: true,
    roles: true,
})

export type NoteTemplateValues = z.infer<typeof NoteTemplateValuesSchema>
export const createNoteTemplateSchema = z.object({
    id: z.string(),
    type: z.enum(['user', 'org']),
    values: NoteTemplateValuesSchema,
    optimistic: CreateOptimisticSchema,
})

export const updateNoteTemplateSchema = z.object({
    id: z.string(),
    type: z.enum(['user', 'org']),
    values: NoteTemplateValuesSchema.partial(),
    optimistic: UpdateOptimisticSchema,
})

export const NoteTemplateMutatorSchema = {
    createNoteTemplate: createNoteTemplateSchema,
    updateNoteTemplate: updateNoteTemplateSchema,

    deleteNoteTemplate: z.object({
        id: z.string(),
        type: z.enum(['user', 'org']),
    }),
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ExternalLinkOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M14 5a1 1 0 110-2h6a1 1 0 011 1v6a1 1 0 11-2 0V6.414l-8.293 8.293a1 1 0 01-1.414-1.414L17.586 5H14zm-10.121.879A3 3 0 016 5h4a1 1 0 110 2H6a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-4a1 1 0 112 0v4a3 3 0 01-3 3H6a3 3 0 01-3-3V8a3 3 0 01.879-2.121z" />
        </SvgIcon>
    )
}

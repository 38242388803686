import { useCallback, useMemo, useState } from 'react'
import { sortBy } from 'lodash'

import { FieldGroup } from '@laserfocus/shared/models'
import { fractional } from '@laserfocus/shared/util-common'

export function useReorderedFieldGroups(fieldGroups: FieldGroup[]) {
    const [reorders, setReorders] = useState<Record<string, string>>(
        fractional.getFillForMissingOrderKeys(fieldGroups, 'orderKey', 'id')
    )

    const sortedFieldGroups = useMemo(() => {
        const withUpdatedSortKey = fieldGroups.map((fg: FieldGroup) => {
            if (reorders[fg.id]) {
                return {
                    ...fg,
                    orderKey: reorders[fg.id],
                }
            }
            return fg
        })
        return sortBy(withUpdatedSortKey, 'orderKey')
    }, [fieldGroups, reorders])

    const updatePosition = useCallback(
        (sourceIndex: number, targetIndex: number) => {
            const source = sortedFieldGroups[sourceIndex]
            if (!source) {
                return
            }
            const id = source.id
            const orderMap = Object.fromEntries(
                sortedFieldGroups.map((fg) => [fg.id, fg.orderKey ?? null])
            )
            const orderKey = fractional.getOrderKeyForMove(orderMap, sourceIndex, targetIndex)
            setReorders((orders) => ({
                ...orders,
                [id]: orderKey,
            }))
        },
        [sortedFieldGroups]
    )

    return {
        sortedFieldGroups,
        reorders,
        updatePosition,
    }
}

import { Modal, toast } from '@laserfocus/ui/beam'
import { UserFieldGroupInput, UserIdSchema } from '@laserfocus/shared/models'
import { useUserId } from '@laserfocus/client/feature-auth'
import { Analytics } from '@laserfocus/client/util-analytics'
import { logger } from '@laserfocus/ui/logger'
import { ShareModalBody } from '@laserfocus/client/ui-shared'
import { mutateFieldGroup, useColleagues } from '@laserfocus/client/data-access-shared'

interface ShareFieldGroupModalProps extends ShareFieldGroupModalInnerProps {
    show: boolean
}
export function ShareFieldGroupModal({ fieldGroup, closeModal, show }: ShareFieldGroupModalProps) {
    return (
        <Modal show={show} onClose={closeModal}>
            <ShareFieldGroupModalInner fieldGroup={fieldGroup} closeModal={closeModal} />
        </Modal>
    )
}

interface ShareFieldGroupModalInnerProps {
    fieldGroup: UserFieldGroupInput
    closeModal: () => void
}

function ShareFieldGroupModalInner({ fieldGroup, closeModal }: ShareFieldGroupModalInnerProps) {
    const users = useColleagues()
    const userId = useUserId()
    const activeWithoutMe = users.filter((u) => u.IsActive !== false && u.Id !== userId)
    function submit(userIds: string[]) {
        mutateFieldGroup
            .shareFieldGroup({
                fieldGroup,
                userIds: userIds.map((id) => UserIdSchema.parse(id)),
            })
            .then(
                () => {
                    Analytics.trackEvent({
                        event: 'fieldgroup_shared',
                        payload: {
                            userCount: userIds.length,
                        },
                    })
                    toast.success({
                        title: `Fieldgroup shared`,
                        description: `The Fieldgroup "${fieldGroup.title}" was shared with ${
                            userIds.length
                        } colleague${userIds.length > 1 ? 's' : ''}`,
                    })
                    closeModal()
                },
                (e) => {
                    logger.error(e)
                    toast.error({ title: 'Error while sharing fieldgroup' })
                }
            )
    }
    return (
        <ShareModalBody
            users={activeWithoutMe}
            submit={submit}
            buttonLabel={`Share ${fieldGroup.title}`}
            modalTitle={`Share ${fieldGroup.title}`}
            closeModal={closeModal}
        />
    )
}

import { twMerge } from 'tailwind-merge'

export function SettingsPageHeader({
    iconComponent,
    title,
    left,
    right,
    className,
    icon,
}: {
    iconComponent?: React.ComponentType<{ className?: string }>
    icon?: React.ReactNode
    title: React.ReactNode
    left?: React.ReactNode
    right?: React.ReactNode
    className?: string
}) {
    const Icon = iconComponent
    return (
        <div
            className={twMerge(
                'px-8 pt-8 pb-4 grid grid-flow-col items-center gap-8 justify-between',
                className
            )}
        >
            <div className="grid grid-flow-col gap-1 justify-start items-center">
                {Icon ? (
                    <div className="w-10 h-10 rounded-full bg-grey-700/10 grid items-center justify-center">
                        <Icon className="w-5 h-5" />
                    </div>
                ) : (
                    icon
                )}

                <h1
                    data-testid="pageheader-pagetitle"
                    className="font-serif text-3xl leading-none ml-3"
                >
                    {title}
                </h1>
                {left && <div className="ml-1">{left}</div>}
            </div>
            {right}
        </div>
    )
}

import { useCallback, useRef } from 'react'

export function useSubscription<Event>() {
    const listeners = useRef(new Set<(e: Event) => void>())

    const subscribe = useCallback((listener: (e: Event) => void) => {
        listeners.current.add(listener)
        return () => {
            listeners.current.delete(listener)
        }
    }, [])
    const emit = useCallback((event) => {
        listeners.current.forEach((listener) => listener(event))
    }, [])
    return { subscribe, emit }
}

import { differenceInCalendarDays, endOfDay } from 'date-fns'

import { UserSubscription } from '@laserfocus/shared/models'

export function daysUntilTrialEnd(subscription: UserSubscription | null): number | null {
    if (!subscription) {
        return null
    }
    if (subscription.plan === 'free' && subscription.trialEnd) {
        const diff = differenceInCalendarDays(endOfDay(new Date(subscription.trialEnd)), new Date())
        if (diff > 0) {
            return diff
        }
    }
    return null
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

export function GlobeOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M19.071 4.929A9.935 9.935 0 0 0 12 2a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 2 12a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 12 22a9.935 9.935 0 0 0 7.071-2.929A9.935 9.935 0 0 0 22 12a9.935 9.935 0 0 0-2.929-7.071Zm-.827.827c.534.534.99 1.125 1.365 1.758h-3.887a15.098 15.098 0 0 0-.662-2.378 8.102 8.102 0 0 0-.848-1.688 8.776 8.776 0 0 1 4.032 2.308ZM14.969 12c0 1.135-.077 2.236-.224 3.262h-5.49A23.167 23.167 0 0 1 9.031 12c0-1.155.08-2.275.232-3.316h5.474c.152 1.041.232 2.161.232 3.316ZM12 3.17c.668 0 1.403.89 1.966 2.38.223.591.412 1.252.563 1.964H9.471c.151-.712.34-1.373.563-1.965.563-1.49 1.298-2.38 1.966-2.38ZM5.756 5.756a8.776 8.776 0 0 1 4.032-2.308 8.105 8.105 0 0 0-.848 1.688c-.269.712-.49 1.514-.662 2.378H4.39a8.886 8.886 0 0 1 1.365-1.758ZM3.17 12c0-1.155.22-2.277.642-3.316h4.27A24.404 24.404 0 0 0 7.862 12c0 1.128.072 2.227.213 3.262H3.79A8.823 8.823 0 0 1 3.17 12Zm2.586 6.244a8.88 8.88 0 0 1-1.397-1.812h3.908c.173.885.399 1.705.673 2.432.25.663.535 1.227.848 1.688a8.776 8.776 0 0 1-4.032-2.308ZM12 20.83c-.668 0-1.403-.89-1.965-2.38a13.677 13.677 0 0 1-.575-2.018h5.08a13.694 13.694 0 0 1-.574 2.019c-.563 1.49-1.298 2.38-1.966 2.38Zm6.244-2.586a8.776 8.776 0 0 1-4.032 2.308c.313-.46.598-1.025.848-1.688.274-.727.5-1.547.673-2.432h3.908a8.886 8.886 0 0 1-1.397 1.812Zm-2.319-2.982c.14-1.035.214-2.134.214-3.262 0-1.147-.076-2.266-.221-3.316h4.27A8.82 8.82 0 0 1 20.83 12a8.823 8.823 0 0 1-.62 3.262h-4.285Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth=".6"
            />
        </SvgIcon>
    )
}

import { ulid } from 'ulid'

import { z } from '@laserfocus/shared/decoder'

import { UserIdSchema, LeadIdSchema, AccountIdSchema, ContentNoteIdSchema } from '../base/id.types'
import { BaseFieldsSchema } from '../base/model.types'
import { DateTimeStringSchema, DateTimeString } from '../base/date.types'
import { makeDomainIdCheck, makeDomainIdSchema } from '../sync/optimistic-id.types'
import { Brand } from '..'

const PREFIX = '069'

export const OptimisticNoteIdSchema = makeDomainIdSchema<OptimisticNoteId>(PREFIX, 'ContentNote')

/**
 *  This is the ContentNote when Fetching the ContentNote
 *  as Root object from salesforce (in Contrast to the LinkedContentNoteSchema)
 */
export const SFContentNoteSchema = BaseFieldsSchema.extend({
    attributes: z.object({
        type: z.literal('ContentNote'),
    }),
    Id: ContentNoteIdSchema,
    Title: z.string().nullish(),
    ContentModifiedDate: DateTimeStringSchema.nullish(),
    LastViewedDate: DateTimeStringSchema.nullish(),

    TextPreview: z.string().nullish(),
    ContentSize: z.number().int().nullish(),
    IsDeleted: z.boolean().nullish(),
    FileType: z.literal('SNOTE'),
    FileExtension: z.literal('snote'),
    LatestPublishedVersionId: z.string().nullish(),
    OwnerId: UserIdSchema,
    LatestContentId: z.string().nullish(),
    /**
     * This is NOT the actual content, just the URL to the content
     * Because the input is base64 encoded and then it is only exposed as URL
     */
    Content: z.string().nullish(),
    IsReadOnly: z.boolean().nullish(),
    /**
     * N: Visible to Anyone With Record Access (default)
     * P: Private on Records
     */
    SharingPrivacy: z.literal('N').or(z.literal('P')),
})

export const ContentNoteSchema = SFContentNoteSchema.omit({
    attributes: true,
    Content: true,
}).extend({
    __typename: z.literal('ContentNote').default('ContentNote'),
    optimisticId: OptimisticNoteIdSchema.optional(),
})

export const ContentNoteInputSchema = ContentNoteSchema.pick({
    Title: true,
    SharingPrivacy: true,
})
    .extend({
        Content: z.string(),
    })
    .partial({
        SharingPrivacy: true,
        Content: true,
    })

const CreateNoteInputSchema = ContentNoteSchema.pick({
    Title: true,
    SharingPrivacy: true,
}).extend({
    Content: z.string().default(''),
})

export type ContentNote = z.infer<typeof ContentNoteSchema>

export const ContentNoteContentSchema = z.object({
    Id: ContentNoteIdSchema,
    Body: z.string(),
    __typename: z.literal('ContentNoteContent').default('ContentNoteContent'),
})

export type ContentNoteContent = z.infer<typeof ContentNoteContentSchema>

export type ContentNoteInput = z.infer<typeof ContentNoteInputSchema>

export const isOptimisticNoteId = makeDomainIdCheck<OptimisticNoteId>(PREFIX)
export function createOptimisticNoteId() {
    const core = ulid()
    return OptimisticNoteIdSchema.parse(`${core}${PREFIX}`)
}
export type OptimisticNoteId = Brand<string, 'OptimisticContentNote'>

const NoteIdWithOptimistic = z.union([ContentNoteIdSchema, OptimisticNoteIdSchema])

const contenDocumentUpdateSchema = ContentNoteInputSchema.pick({ SharingPrivacy: true })
export type ContentDocumentUpdate = z.infer<typeof contenDocumentUpdateSchema>

export const NotesMutatorSchema = {
    updateNoteTitle: z.object({
        id: NoteIdWithOptimistic,
        title: z.string(),
        optimistic: z.object({
            LastModifiedDate: DateTimeStringSchema,
        }),
    }),
    updateNoteBody: z.object({
        id: NoteIdWithOptimistic,
        body: z.string(),
        optimistic: z.object({
            LastModifiedDate: DateTimeStringSchema,
        }),
    }),
    updateContentDocument: z.object({
        id: NoteIdWithOptimistic,
        values: contenDocumentUpdateSchema,
        optimistic: z.object({
            LastModifiedDate: DateTimeStringSchema,
        }),
    }),
    createNote: z.object({
        optimisticId: OptimisticNoteIdSchema,
        values: CreateNoteInputSchema,
        relatedRecords: z.array(z.string()),
        optimistic: z
            .object({
                CreatedDate: DateTimeStringSchema,
                LastModifiedDate: DateTimeStringSchema,
                OwnerId: UserIdSchema,
                LastModifiedById: UserIdSchema,
                CreatedById: UserIdSchema,
            })
            .partial()
            .nullish(),
    }),
    deleteNote: z.object({
        id: NoteIdWithOptimistic,
    }),
    pinNote: z.object({
        rootId: z.union([LeadIdSchema, AccountIdSchema]),
        noteId: NoteIdWithOptimistic,
    }),
    unpinNote: z.object({
        rootId: z.union([LeadIdSchema, AccountIdSchema]),
        noteId: NoteIdWithOptimistic,
    }),
}

export type NoteRelation = {
    sobjectId: string
    noteId: string
    createdDateTime: DateTimeString
    __typename: 'ContentNoteRelation'
}

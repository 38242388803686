import { useState } from 'react'
import { reset } from 'mousetrap'

import { useDatepicker, UseDatepickerProps } from '@laserfocus/vendor/react-datepicker-hooks'
import { RelativeSuggest } from '@laserfocus/client/util-date'

import { Dropdown, DropdownProps } from '../Dropdown/Dropdown'

import { DatepickerSelect } from './DatepickerSelect'
import { DatepickerCalendar } from './DatepickerCalendar'

export interface DatepickerProps extends Omit<DropdownProps, 'content'>, DatepickerInnerProps {}

export function Datepicker({
    initialDate,
    minDate,
    maxDate,
    initialVisibleMonth,
    dateOnly,
    withSelect,
    onDateSubmit,
    onRangeSubmit,
    allowDateRanges,
    allowEmpty,
    ...props
}: DatepickerProps) {
    return (
        <Dropdown
            {...props}
            content={
                <DatepickerInner
                    initialDate={initialDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    initialVisibleMonth={initialVisibleMonth}
                    dateOnly={dateOnly}
                    withSelect={withSelect}
                    onDateSubmit={onDateSubmit}
                    allowDateRanges={allowDateRanges}
                    onRangeSubmit={onRangeSubmit}
                    allowEmpty={allowEmpty}
                />
            }
        />
    )
}

interface DatepickerInnerProps extends Omit<UseDatepickerProps, 'date' | 'onDateChange'> {
    /** Date to select when datepicker is opened */
    initialDate?: Date | null
    /** Whether to not show hours and minutes in datepicker */
    dateOnly?: boolean
    /** Whether to show select with date suggestions */
    withSelect?: boolean
    /** Called with currently selected date when user submits date */
    onDateSubmit?(date: Date | null): void

    /** Wether we allow date ranges (but for now we only allow hardcoded SF ranges ) */
    allowDateRanges?: boolean

    /** Wether we add the option to clear the value */
    allowEmpty?: boolean

    onRangeSubmit?(value: string): void
}

/**
 * We're using DatepickerInner so we only call useDatepicker() when a datepicker is opened.
 */
function DatepickerInner({
    initialDate,
    dateOnly,
    withSelect,
    onDateSubmit = () => {},
    allowDateRanges,
    onRangeSubmit,
    allowEmpty,
    ...props
}: DatepickerInnerProps) {
    const {
        activeMonth,
        selectedDate,
        canSelectDate,
        isDateSelected,
        isTimeSelected,
        onDateSelect,
        onTimeSelect: _onTimeSelect,
        goToNextMonth,
        goToPreviousMonth,
    } = useDatepickerModel(initialDate, props)
    const [selectedRange, setSelectedRange] = useState<string>()

    function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter') {
            if (selectedRange) {
                onRangeSubmit!(selectedRange)
            } else {
                onDateSubmit(selectedDate)
            }
        }
    }

    function onTimeSelect(date: Date) {
        _onTimeSelect(date)
        setSelectedRange(undefined)
    }

    function onRangeSelect(range: RelativeSuggest) {
        reset()
        setSelectedRange(range.value)
    }
    function isSelectedRange(value: string) {
        return selectedRange === value
    }

    return (
        <div onKeyDown={handleKeyDown} className="flex" data-testid="date-picker">
            {withSelect && (
                <DatepickerSelect
                    dateOnly={dateOnly}
                    isTimeSelected={isTimeSelected}
                    onTimeSelect={onTimeSelect}
                    onDateSubmit={onDateSubmit}
                    allowDateRanges={allowDateRanges}
                    onRangeSelect={onRangeSelect}
                    onRangeSubmit={onRangeSubmit}
                    isSelectedRange={isSelectedRange}
                    allowEmpty={allowEmpty}
                    focusOnMount
                    className="p-2 box-content border-r border-white/10"
                />
            )}
            <DatepickerCalendar
                activeMonth={activeMonth}
                selectedDate={selectedDate}
                dateOnly={dateOnly}
                focusOnMount={!withSelect}
                canSelectDate={canSelectDate}
                isDateSelected={isDateSelected}
                goToNextMonth={goToNextMonth}
                goToPreviousMonth={goToPreviousMonth}
                onDateSelect={onDateSelect}
                onTimeSelect={onTimeSelect}
                onDateSubmit={onDateSubmit}
                className="p-2"
            />
        </div>
    )
}

// Functionality is in its own function to prevent accidental use of `setSelectedDate()`
function useDatepickerModel(
    initialDate: Date | null | undefined,
    datepickerProps: Omit<UseDatepickerProps, 'date' | 'onDateChange'>
) {
    const [selectedDate, setSelectedDate] = useState(initialDate || null)
    const datepickerModel = useDatepicker({
        ...datepickerProps,
        date: selectedDate,
        onDateChange: setSelectedDate,
    })

    return {
        selectedDate,
        reset: () => setSelectedDate(null),
        ...datepickerModel,
    }
}

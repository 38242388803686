import { format, isValid, isToday, isThisYear } from 'date-fns'

import { dateHasTime } from '@laserfocus/shared/models'
import { logger } from '@laserfocus/ui/logger'

const NO_TIME_FORMAT = 'd MMM'
const OTHER_YEAR_FORMAT = 'd MMM yy'
// const TIME_FORMAT = 'p'
const TIME_FORMAT = 'HH:mm'

export function renderDateTime(d: Date | undefined | null) {
    if (!d) {
        return ''
    }
    if (isToday(d)) {
        if (dateHasTime(d)) {
            return renderTime(d)
        }
        return ''
    }
    return renderDate(d)
}

export function renderDate(cd: Date | undefined | null) {
    if (!cd) {
        return ''
    }
    if (!isValid(cd)) {
        logger.warn(cd, 'is invalid')
        return ''
    }
    if (isThisYear(cd)) {
        return format(cd, NO_TIME_FORMAT)
    }
    return format(cd, OTHER_YEAR_FORMAT)
}

export function renderTime(cd: Date | null | undefined) {
    if (!cd) {
        logger.warn('trying to render not set time')
        return ''
    }
    if (!dateHasTime(cd)) {
        return ''
    }
    if (!isValid(cd)) {
        logger.warn(cd, 'is invalid')
        return ''
    }
    return format(cd, TIME_FORMAT)
}

export function getActivityRescheduleMessage(date: Date, name?: string | null, isEvent?: boolean) {
    const formattedDate = format(date, 'EEEE, MMM d, y h:mm a')

    return {
        title: `You have rescheduled ${isEvent ? 'an event' : 'a task'}`,
        description: name ? `For ${name} on ${formattedDate}.` : `On ${formattedDate}.`,
    }
}

export function getActivityCreateMessage(date: Date, name?: string | null) {
    const formattedDate = format(date, 'EEEE, MMM d, y h:mm a')

    return {
        title: 'You have created a task',
        description: name ? `For ${name} on ${formattedDate}.` : `On ${formattedDate}.`,
    }
}

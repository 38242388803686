import { omit } from 'lodash'

import type {
    ContactDTO,
    ContactModel,
    Identifier,
    MutationErrorResponse,
    OpportunityDTO,
    OpportunityModel,
    SObjectType,
} from '@laserfocus/shared/models'
import { handleGraphqlError, apolloClient } from '@laserfocus/client/data-access-apollo'
import { CREATE_CONTACT, CREATE_OPPORTUNITY } from '@laserfocus/shared/data-access-gql'

/**
 * This field is not in the UpdateSchema, We need a proper solution to not
 * send this in the first place.
 * Same is done in the ActivityRepository.
 * Should be addressed here: LF-1316
 */
const BLOCKLIST_UPDATE_PROPERTIES = ['LastActivityDate'] as const

export class SalesObjectRepository<PropsType extends Identifier> {
    sobject: SObjectType
    client: typeof apolloClient

    constructor(sobject: SObjectType) {
        this.sobject = sobject
        this.client = apolloClient
    }

    async create(payload: Partial<PropsType>): Promise<Identifier | MutationErrorResponse> {
        const cleaned = omit(payload, ...BLOCKLIST_UPDATE_PROPERTIES)
        switch (this.sobject) {
            case 'Contact':
                return this.createContact(cleaned as unknown as Partial<ContactModel>)
            case 'Opportunity':
                return this.createOpportunity(cleaned as unknown as Partial<OpportunityModel>)
            default:
                throw new Error(`Trying to create a ${this.sobject}. This is not yet implemented `)
        }
    }

    async createContact(
        payload: Partial<ContactModel>
    ): Promise<ContactDTO | MutationErrorResponse> {
        try {
            const res = await this.client.mutate({
                mutation: CREATE_CONTACT,
                variables: {
                    input: payload,
                },
            })
            const contact = res.data?.createContact
            return contact
        } catch (e: any) {
            return handleGraphqlError(e)
        }
    }

    async createOpportunity(
        input: Partial<OpportunityModel>
    ): Promise<OpportunityDTO | MutationErrorResponse> {
        try {
            const res = await this.client.mutate({
                mutation: CREATE_OPPORTUNITY,
                variables: {
                    input,
                },
            })
            const opp = res.data?.createOpportunity
            return opp
        } catch (e: any) {
            return handleGraphqlError(e)
        }
    }
}

import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'

import { getClient } from '@laserfocus/client/replicache'
import {
    Prefix,
    NoteRelation,
    ContentNote,
    ContentNoteContent,
    SyncedRecord,
} from '@laserfocus/shared/models'

export type ClientNote = NoteWithContent & {
    isPinned: boolean
}

type NoteWithContent = ContentNote & {
    Body: string
}

type LoadingState = 'PARTIAL' | 'FULL' | null

type NoteState = {
    loading: LoadingState
    notes: NoteWithContent[]
    disabled?: boolean
}

export function useLeadNotes(leadId: string, pinnedNotes: string[]) {
    const pinnedById = Object.fromEntries(pinnedNotes.map((a) => [a, true]))
    const rep = getClient()
    const noteState = useSubscribe(
        rep,
        async (tx) => {
            const notesSetting = (await tx.get('org/settings/contentnotes')) as {
                disabled: boolean
            }

            if (notesSetting?.disabled) {
                return {
                    loading: null,
                    notes: [],
                    disabled: true,
                }
            }

            const prefix = [Prefix.ContentNote, 'related', leadId].join('/')

            const relationScan = tx.scan({ prefix }).values().toArray() as Promise<NoteRelation[]>
            const statusGet = tx.get(
                [Prefix.SyncStatus, leadId, Prefix.ContentNote].join('/')
            ) as Promise<SyncedRecord | null>
            const [relations, syncStatus] = await Promise.all([relationScan, statusGet])

            const noteIds = [...new Set(relations.map((a) => a.noteId))]
            const notes = await getNotes(tx, noteIds)

            return {
                loading: syncStatus?.didLoad ? null : 'FULL',
                disabled: false,
                notes,
            }
        },
        {
            loading: 'FULL',
            disabled: false,
            notes: [],
        },
        []
    )
    return {
        ...noteState,
        notes: noteState.notes.map((n) => ({ ...n, isPinned: pinnedById[n.Id] })),
    }
}

export function useAccountNotes(
    {
        accountId,
        opportunityIds,
        contactIds,
    }: {
        accountId: string
        opportunityIds: string[]
        contactIds: string[]
    },
    pinnedNotes: string[]
) {
    const relatedIdString = [accountId, ...contactIds, ...opportunityIds].join('_')
    const pinnedById = Object.fromEntries(pinnedNotes.map((a) => [a, true]))

    const rep = getClient()
    const noteState = useSubscribe<NoteState>(
        rep,
        async (tx): Promise<NoteState> => {
            const notesSetting = (await tx.get('org/settings/contentnotes')) as {
                disabled: boolean
            }

            if (notesSetting?.disabled) {
                return {
                    loading: null,
                    notes: [],
                    disabled: true,
                }
            }
            const relatedIds = relatedIdString.split('_')

            const nestedNotes = await Promise.all(
                relatedIds.map(async (relatedId) => {
                    const prefix = [Prefix.ContentNote, 'related', relatedId].join('/')
                    const objectRelation = await tx.scan({ prefix }).values().toArray()
                    return objectRelation as NoteRelation[]
                })
            )
            const relatedSync = await Promise.all(
                relatedIds.map(async (id) => {
                    const key = [Prefix.SyncStatus, id, Prefix.ContentNote].join('/')
                    const syncStatus = (await tx.get(key)) as SyncedRecord | null
                    return syncStatus || { didLoad: false, id }
                })
            )

            const hasUnloaded = relatedSync.filter((a) => !a.didLoad).length > 0

            const allNoteRelations = nestedNotes.flat()
            const noteIds = [...new Set(allNoteRelations.map((r) => r.noteId))]
            const notes = await getNotes(tx, noteIds)

            const loading =
                notes.length === 0 && hasUnloaded ? 'FULL' : hasUnloaded ? 'PARTIAL' : null
            return {
                loading,
                notes,
            }
        },
        {
            loading: 'FULL',
            notes: [],
        },
        [relatedIdString]
    )
    return {
        ...noteState,
        notes: noteState.notes.map((n) => ({ ...n, isPinned: pinnedById[n.Id] })),
    }
}

async function getNotes(tx: ReadTransaction, noteIds: string[]) {
    const notes = await Promise.all(
        noteIds.map(async (id) => {
            const [noteMeta, noteContent] = (await Promise.all([
                tx.get([Prefix.ContentNote, 'meta', id].join('/')),
                tx.get([Prefix.ContentNote, 'content', id].join('/')),
            ])) as [ContentNote, ContentNoteContent]

            const withBody: NoteWithContent = {
                ...noteMeta,
                Body: noteContent.Body,
            }
            return withBody
        })
    )
    return notes
}

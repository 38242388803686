import { twMerge } from 'tailwind-merge'

import { TextPrimary } from '@laserfocus/ui/beam'

import { AddTaskButton } from '../../TaskModal/AddTaskButton'

export function TodayEmptyStackCard({ className }: { className?: string }) {
    return (
        <div
            className={twMerge(
                'col-span-2 p-8 rounded-2xl bg-yellow-50 flex items-center flex-col',
                className
            )}
        >
            <h3 className="font-bold text-[1.375rem] font-serif leading-[1.2] pb-2">
                You have done all the work for today.
            </h3>
            <TextPrimary>Maybe you have other tasks on your mind:</TextPrimary>
            <div className="mt-6">
                <AddTaskButton label="Add Tasks" />
            </div>
        </div>
    )
}

import { AuthContext } from './user/auth.types'

const devOrg = '00D1i0000008fNmEAI'
const cleanOrg = '00D3h000003yzKUEAY'
const ciOrg = '00D6g00000836YlEAI'
// const prodOrg = '00D5J000000l7YlUAI'

const betaOrgs = [devOrg, cleanOrg, ciOrg]

export function isBetaUser(auth: Partial<Pick<AuthContext, 'userId' | 'orgId'>>) {
    return auth.orgId && betaOrgs.includes(auth.orgId)
}

import { cloneElement, forwardRef, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'
import { twMerge } from 'tailwind-merge'

import { Portal, useForkRef } from '@laserfocus/ui/util-react'

export interface HintDisplayProps extends Omit<HintDisplayInnerProps, 'outerRef'> {
    isActive: boolean
    delay?: number
}

export const HintDisplay = forwardRef<unknown, HintDisplayProps>(function HintDisplay(
    { isActive, delay, ...props },
    ref
) {
    const [isDelayOver, setIsDelayOver] = useState(delay ? false : isActive)
    useEffect(() => {
        if (isActive && delay) {
            const timer = setTimeout(() => setIsDelayOver(true), delay)
            return () => clearTimeout(timer)
        }
    }, [delay, isActive])

    const finalShow = delay ? isDelayOver && isActive : isActive
    /**
     * Using a forkedRef for this edgecase
     * <HintDisplay ref={otherRef}><div ref={myRef} /></HintDisplay>
     */
    const clonedChildRef = useForkRef(ref, props.children.ref)

    return finalShow ? (
        <HintDisplayInner {...props} outerRef={ref} />
    ) : (
        cloneElement(props.children, { ref: clonedChildRef })
    )
})

interface HintDisplayInnerProps {
    outerRef: React.ForwardedRef<unknown>
    children: React.ReactElement<RefAttribute> & RefAttribute
    placement?: Placement
    label: string
    containerClassName?: string
    labelClassName?: string
    arrowClassName?: string
    onClick?(): void
    popperClassName?: string
}

interface RefAttribute {
    ref?: ((element: HTMLElement) => void) | null
}

function HintDisplayInner({
    outerRef,
    children,
    placement = 'right',
    label,
    containerClassName,
    labelClassName,
    arrowClassName,
    onClick,
    popperClassName,
}: HintDisplayInnerProps) {
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const clonedChildRef = useForkRef(outerRef, children.ref, setReferenceElement)
    const { styles } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'flip',
                enabled: false,
            },
            {
                name: 'preventOverflow',
                enabled: false,
            },
        ],
    })

    return (
        <>
            {cloneElement(children, { ref: clonedChildRef })}
            <Portal>
                <div
                    ref={setPopperElement}
                    style={styles.popper}
                    className={twMerge('pointer-events-none', popperClassName)}
                >
                    <div
                        title={label}
                        className={twMerge(
                            'font-handwritten font-bold text-2xl flex flex-col',
                            placement === 'left' && 'items-end',
                            containerClassName
                        )}
                        onClick={onClick}
                    >
                        <div
                            className={twMerge(
                                'whitespace-nowrap text-grey-700/80 pointer-events-auto',
                                onClick && 'cursor-pointer',
                                labelClassName
                            )}
                        >
                            {label}
                        </div>
                        <HandwrittenArrow
                            className={twMerge(
                                'pointer-events-auto text-grey-700',
                                onClick && 'cursor-pointer',
                                placement === 'left' && 'scale-x-[-1]',
                                arrowClassName
                            )}
                        />
                    </div>
                </div>
            </Portal>
        </>
    )
}

function HandwrittenArrow(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
        <svg width="45" height="53" fill="currentColor" {...props}>
            <path d="M42.95 12.66c.4-.4 1.22-.77 1.55-.16.34.62-.31 1.53-.73 1.95a121.53 121.53 0 01-35.5 25.13l2.58.56c2.27.5 4.62 1.26 6.98 1.3 1.58.04.14 2.79-1 2.77-2.69-.06-5.31-.85-7.9-1.41l-4.11-.9c-.96-.2-3-.19-3.3-1.36-.28-1.15.8-2.26 1.46-3.07L5.8 34l5.94-7.26c.35-.43 1.15-1.12 1.69-.68.52.44.05 1.43-.3 1.84A227045 227045 0 016 36.66l-1.22 1.48-.5.61.24.06a117.45 117.45 0 0038.43-26.15z" />
        </svg>
    )
}

import { useMemo } from 'react'
import clsx from 'clsx'

import { useScrolledIntoViewRef } from '@laserfocus/ui/util-react'
import { AddOutlinedIcon } from '@laserfocus/ui/icons'

import { Dropdown, DropdownProps } from '../Dropdown/Dropdown'
import { DropdownCheckbox } from '../controls/DropdownCheckbox'
import { DropdownButton } from '../controls/DropdownButton'
import { Button } from '../../button/Button'
import { isTruthy } from '../../util'

import { SearchableList, SearchKey } from './SearchableList'
import { Size, SizeContainer } from './SizeContainer'
import { useDropdownKeyboardNavigation } from './useDropdownKeyboardNavigation'

type Option<T extends Partial<StaticOption>> = T & StaticOption
interface StaticOption {
    value: string
    label: string
}

export interface MultiSelectProps<T extends Partial<StaticOption> = {}>
    extends Omit<DropdownProps, 'content'>,
        MultiSelectInnerProps<T> {}

export function MultiSelect<T extends Partial<StaticOption> = {}>({
    options,
    initialOptionValues,
    searchKeys,
    size,
    bottomAction,
    onSubmit,
    onCreatableSubmit,
    ...props
}: MultiSelectProps<T>) {
    const sortedOptions = useMemo(() => {
        return [...options].sort((a, b) => {
            const aSelected = initialOptionValues?.includes(a.value)
            const bSelected = initialOptionValues?.includes(b.value)
            if (aSelected && !bSelected) {
                return -1
            } else if (bSelected && !aSelected) {
                return 1
            }
            return 0
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen])
    return (
        <Dropdown
            {...props}
            content={
                <MultiSelectInner<T>
                    options={sortedOptions}
                    initialOptionValues={initialOptionValues}
                    searchKeys={searchKeys}
                    size={props.syncWidth ? 'unset' : size}
                    bottomAction={bottomAction}
                    onSubmit={onSubmit}
                    onCreatableSubmit={onCreatableSubmit}
                />
            }
        />
    )
}

interface MultiSelectInnerProps<T extends Partial<StaticOption>>
    extends Omit<
        OptionsListProps<T>,
        'allOptions' | 'filteredOptions' | 'variableHeightElementRef'
    > {
    options: readonly Option<T>[]
    searchKeys?: readonly SearchKey<Option<T>>[]
    size?: Size
}

function MultiSelectInner<T extends Partial<StaticOption>>({
    options,
    size,
    searchKeys,
    ...props
}: MultiSelectInnerProps<T>) {
    const content = searchKeys ? (
        <SearchableList searchKeys={searchKeys} elements={options}>
            {(filteredOptions, searchValue) => (
                <OptionsList<T>
                    {...props}
                    allOptions={options}
                    filteredOptions={filteredOptions}
                    searchValue={searchValue}
                />
            )}
        </SearchableList>
    ) : (
        <OptionsList<T> {...props} allOptions={options} filteredOptions={options} />
    )

    return <SizeContainer size={size}>{content}</SizeContainer>
}

interface OptionsListProps<T extends Partial<StaticOption>> {
    allOptions: readonly Option<T>[]
    filteredOptions: readonly Option<T>[]
    initialOptionValues?: readonly string[]
    searchValue?: string
    bottomAction?: {
        label: string
        onClick(): void
    }
    onSubmit?(options: readonly Option<T>[]): void
    onCreatableSubmit?(value: string): void
}

function OptionsList<T extends Partial<StaticOption>>({
    allOptions,
    filteredOptions,
    initialOptionValues,
    searchValue,
    bottomAction,
    onSubmit,
    onCreatableSubmit,
}: OptionsListProps<T>) {
    const allOptionsByValue = useMemo(
        /**
         * Using a map, in order to keep the order of the options stable. Keys in Objects are not stable, since
         * integers are sorted in the beginning. Problematic Example: ['A','B','0'] => {0:X, A:X, B:X} if not using a Map
         */
        () => new Map(allOptions.map((option) => [option.value, option])),
        [allOptions]
    )
    const selectedOptions = useMemo(
        () => Object.fromEntries((initialOptionValues || []).map((value) => [value, true])),
        [initialOptionValues]
    )

    function handleSubmit(optionIndexToToggle: number) {
        const optionToToggle = filteredOptions[optionIndexToToggle]!
        const selectedValues = Object.entries({
            ...selectedOptions,
            [optionToToggle.value]: !selectedOptions[optionToToggle.value],
        })
            .filter(([, isSelected]) => isSelected)
            .map(([value]) => value)
        const optionsToSubmit: Option<T>[] = [
            ...pickMap(allOptionsByValue, selectedValues).values(),
        ]

        onSubmit?.(optionsToSubmit)
    }

    function handleSelectAllClick() {
        onSubmit?.(hasSelectedAllOptions ? [] : filteredOptions)
    }

    const hasCreatableElement =
        onCreatableSubmit && searchValue && filteredOptions.every((o) => o.label !== searchValue)

    function handleCreatableSubmit() {
        hasCreatableElement && onCreatableSubmit(searchValue)
    }

    const { hoveredOptionIndex, setHoveredOptionIndex } = useDropdownKeyboardNavigation({
        optionsLength:
            filteredOptions.length + (hasCreatableElement ? 1 : 0) + 1 + (bottomAction ? 1 : 0),
        resetKey: searchValue,
        submit: (index) => {
            if (index < filteredOptions.length) {
                handleSubmit(index)
            } else {
                const actions: Array<() => void> = [
                    hasCreatableElement && handleCreatableSubmit,
                    handleSelectAllClick,
                    bottomAction?.onClick,
                ].filter(isTruthy)

                actions[index - filteredOptions.length]?.()
            }
        },
    })

    const hoveredOptionRef = useScrolledIntoViewRef<HTMLDivElement>(hoveredOptionIndex)

    function handleCheckboxKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.currentTarget.blur()
        }
    }

    const hasOptions = filteredOptions.length !== 0
    const hasSelectedAllOptions = (initialOptionValues?.length || 0) === filteredOptions.length

    return (
        <>
            {!hasOptions && !onCreatableSubmit ? (
                <div className="p-2 text-sm text-white/60 text-center">
                    {allOptions.length === 0 ? 'No options' : 'Nothing found'}
                </div>
            ) : (
                <div role="listbox" className="px-2 pb-2 mt-2 max-h-96 overflow-y-auto grid gap-1">
                    {filteredOptions.map((option, index) => {
                        const { value, label } = option
                        const isSelected = selectedOptions[value] || false
                        const isHovered = index === hoveredOptionIndex
                        const setIsHovered = () => setHoveredOptionIndex(index)

                        return (
                            <div
                                key={value}
                                // We use onMouseMove instead of onMouseEnter to prevent select jumping to where mouse is when navigating with keyboard
                                onMouseMove={setIsHovered}
                                className="-mb-2"
                            >
                                <div
                                    ref={isHovered ? hoveredOptionRef : undefined}
                                    className="pb-2"
                                >
                                    <DropdownCheckbox
                                        isHighlighted={isHovered}
                                        checked={isSelected}
                                        onChange={() => handleSubmit(index)}
                                        label={label}
                                        onFocus={setIsHovered}
                                        // Needed to prevent focus ring after click on element and then proceeding with keyboard navigation
                                        onKeyDown={handleCheckboxKeyDown}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    {hasCreatableElement && (
                        <CreatableElement
                            value={searchValue}
                            isHovered={hoveredOptionIndex === filteredOptions.length}
                            setIsHovered={() => setHoveredOptionIndex(filteredOptions.length)}
                            hoveredOptionRef={hoveredOptionRef}
                            onClick={handleCreatableSubmit}
                        />
                    )}
                </div>
            )}
            <div
                className={clsx(
                    'py-2 mx-2 grid gap-2',
                    (hasOptions || hasCreatableElement) && 'border-t border-white/5'
                )}
            >
                {(() => {
                    const elementIndex = filteredOptions.length + (hasCreatableElement ? 1 : 0)
                    const isHovered = hoveredOptionIndex === elementIndex

                    return (
                        <Button
                            variant="quaternary"
                            size="small"
                            onClick={handleSelectAllClick}
                            onMouseMove={() => setHoveredOptionIndex(elementIndex)}
                            className={clsx(
                                'w-full transition-shadow',
                                isHovered ? 'bg-white/10' : 'bg-white/5'
                            )}
                        >
                            {hasSelectedAllOptions ? 'Deselect all' : 'Select all'}
                        </Button>
                    )
                })()}
                {bottomAction &&
                    (() => {
                        const elementIndex =
                            filteredOptions.length + (hasCreatableElement ? 1 : 0) + 1
                        const isHovered = hoveredOptionIndex === elementIndex

                        return (
                            <Button
                                variant="primary"
                                size="small"
                                onClick={bottomAction.onClick}
                                onMouseMove={() => setHoveredOptionIndex(elementIndex)}
                                className={clsx(
                                    'w-full transition-shadow',
                                    isHovered ? 'bg-blue-700' : 'hover:bg-blue-500'
                                )}
                            >
                                {bottomAction.label}
                            </Button>
                        )
                    })()}
            </div>
        </>
    )
}

interface CreatableElementProps {
    value: string
    isHovered: boolean
    setIsHovered(): void
    hoveredOptionRef: React.RefObject<HTMLDivElement>
    onClick(): void
}

function CreatableElement({
    value,
    isHovered,
    setIsHovered,
    hoveredOptionRef,
    onClick,
}: CreatableElementProps) {
    return (
        <div onMouseMove={setIsHovered} className="-mb-2">
            <div ref={isHovered ? hoveredOptionRef : undefined} className="pb-2">
                <DropdownButton
                    noHoverStyles
                    className={clsx('transition-shadow', isHovered && 'bg-white/10')}
                    onClick={onClick}
                >
                    <div className="grid grid-flow-col justify-start gap-1.5 leading-[1.3]">
                        <AddOutlinedIcon className="w-4 h-4 mt-[0.0625rem] text-white/60" />
                        {`Create “${value}”`}
                    </div>
                </DropdownButton>
            </div>
        </div>
    )
}

function pickMap<Value>(m: Map<string, Value>, keys: string[]) {
    return new Map([...m].filter(([k, v]) => keys.includes(k)))
}

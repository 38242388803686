import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { TextCell as BaseTextCell } from '@laserfocus/client/ui-shared-datagrid'
import { useReferencedDisplayName } from '@laserfocus/client/store-shared'
import { WithType, isContactModel, isOpportunityModel } from '@laserfocus/shared/models'

import { TableCellProps } from './TableCell'

interface ReferenceCellProps extends TableCellProps {}

export const ReferenceCell = observer(function TextCell({
    salesObject,
    column,
    ...rest
}: ReferenceCellProps) {
    const id = column.getValue(salesObject) as string | undefined

    const { name } = useReferencedDisplayName(id)
    return (
        <BaseTextCell
            name={column.key}
            updateValue={(value) => column.updateValue(salesObject, value)}
            value={(column.getValue(salesObject) as string | null) ?? ''}
            // readOnly={!column.isEditable(salesObject)}
            readOnly
            toDisplayValue={(v) => {
                return name || v || ''
            }}
            {...rest}
        />
    )
})

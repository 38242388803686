// We dont add history to dependencies because we want to use the one from react-router.
// Otherwise it can lead to detached history objecst
// https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx#L216-L221
import { createBrowserHistory, BrowserHistory } from 'history'
import type { NavigateFunction, NavigateOptions, To } from 'react-router'

let history: BrowserHistory

export function getHistory() {
    if (!history) {
        history = createBrowserHistory({ window })
    }
    return history
}

export const navigate: NavigateFunction = (to: To | number, options: NavigateOptions = {}) => {
    const navigator = history
    if (typeof to === 'number') {
        navigator.go(to)
        return
    }

    ;(!!options.replace ? navigator.replace : navigator.push)(to, options.state)
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function DuplicateOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M14 5H6a1 1 0 00-1 1v8a1 1 0 001 1h1v-5a3 3 0 013-3h5V6c0-.6-.5-1-1-1zm3 2V6a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3h1v1a3 3 0 003 3h8a3 3 0 003-3v-8a3 3 0 00-3-3h-1zm-1 2h2c.5 0 1 .4 1 1v8a1 1 0 01-1 1H10 10a1 1 0 01-1-1v-1.7a1 1 0 000-.6V10c0-.6.4-1 1-1h6z"
            />
        </SvgIcon>
    )
}

import 'reflect-metadata'
import { flow, observable } from 'mobx'

import { ObjectPool } from '@laserfocus/client/data-layer'
import type { Identifier, MutationErrorResponse } from '@laserfocus/shared/models'
import { isMutationErrorResponse } from '@laserfocus/shared/models'

import { SalesObjectRepository } from './SalesObjectRepository'

type SalesDTO = {
    Id: string
    CreatedDate: string
    LastModifiedDate: string
    __typename: 'Contact' | 'Opportunity'
}

type SalesObjectStoreSobject = 'Lead' | 'Account' | 'Contact' | 'Opportunity'

export class SalesObjectStore<PropsType extends Identifier = Identifier> {
    sobject: SalesObjectStoreSobject
    repo: SalesObjectRepository<PropsType>
    objectPool: ObjectPool

    @observable isSyncingObjects = false
    @observable lastSyncDate?: Date

    constructor(sobject: SalesObjectStoreSobject, objectPool: ObjectPool) {
        this.sobject = sobject
        this.objectPool = objectPool
        this.repo = new SalesObjectRepository(sobject)
    }

    create = flow(function* (this: SalesObjectStore<PropsType>, input: PropsType) {
        const created: SalesDTO | MutationErrorResponse = yield this.repo.create(input)
        if (isMutationErrorResponse(created)) {
            return created
        } else {
            this.objectPool.attach(created)

            const createdObject = this.objectPool.get(this.sobject, created.Id)
            return createdObject
        }
    })
}

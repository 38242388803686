import { debounce } from 'lodash'
import { useMemo } from 'react'

import { useNewestRef } from './useNewestRef'

export function useDebounced<Args extends any[], ReturnValue>(
    fn: (...args: Args) => ReturnValue,
    timeout: number
) {
    const newestFnRef = useNewestRef(fn)

    return useMemo(
        () => debounce((...args: Args) => newestFnRef.current(...args), timeout),
        [newestFnRef, timeout]
    )
}

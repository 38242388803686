import { createContext, useContext } from 'react'

type FormControlContextProps = {
    objectType?: 'Lead' | 'Account' | 'Opportunity' | 'Contact'
    currencyIsoCode?: string
    recordTypeId?: string
}
const FormControlContext = createContext<FormControlContextProps>({
    objectType: undefined,
    currencyIsoCode: undefined,
    recordTypeId: undefined,
})

export function FormControlContextProvider(
    props: FormControlContextProps & { children: React.ReactNode }
) {
    const { children, ...value } = props

    return <FormControlContext.Provider value={value}>{children}</FormControlContext.Provider>
}

export function useFormControlContext() {
    return useContext(FormControlContext)
}

import {
    addDays,
    addMonths,
    isSameDay,
    isWeekend,
    lastDayOfMonth,
    nextMonday,
    setDate,
} from 'date-fns'

import { StackTiming } from '@laserfocus/shared/models'

export function isStackRelevantToday(timing: StackTiming): boolean {
    const now = new Date()
    switch (timing.unit) {
        case 'always':
            return true
        case 'week':
            return timing.daysOfTheWeek.includes(now.getDay())
        case 'month':
            const dateThisMonth = setDate(now, timing.dayOfTheMonth)
            const lastDayOfPreviousMonth = lastDayOfMonth(addMonths(now, -1))

            if (isWeekend(lastDayOfPreviousMonth) && timing.dayOfTheMonth > 27) {
                // EDGE case if the last occurence was last month and a weekend
                const checkAgainst = [lastDayOfPreviousMonth]
                if (lastDayOfPreviousMonth.getDay() === 0) {
                    checkAgainst.push(addDays(lastDayOfPreviousMonth, -1))
                }
                const matchesTiming = checkAgainst.find((d) => d.getDate() === timing.dayOfTheMonth)
                if (matchesTiming) {
                    const monday = nextMonday(matchesTiming)
                    return isSameDay(now, monday)
                }
            }

            if (isWeekend(dateThisMonth)) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                const monday = nextMonday(dateThisMonth)
                return isSameDay(now, monday)
            }
            return isSameDay(dateThisMonth, now)
        default:
            return false
    }
}

import { useCallback, useReducer } from 'react'

import { createOptimisticNoteId, OptimisticNoteId } from '@laserfocus/shared/models'

import { ClientNote } from './useRootNotes'

export interface OptimisticClientNote
    extends Pick<ClientNote, 'Title' | 'Body' | 'SharingPrivacy' | 'CreatedDate' | 'isPinned'> {
    optimisticId: OptimisticNoteId
    didSubmitNote: boolean
}

type NewNotesState = {
    topEditingNote: string | null
    notes: OptimisticClientNote[]
}
export function useNewNote() {
    const [state, dispatch] = useReducer(newNoteReducer, { topEditingNote: null, notes: [] })

    const deleteNote = useCallback(
        (id: OptimisticNoteId) => dispatch({ type: 'DeleteNote', id }),
        [dispatch]
    )
    const createNote = useCallback(() => dispatch({ type: 'CreateNote' }), [dispatch])
    const updateNote = useCallback(
        (id: OptimisticNoteId, values: UpdateNoteValues) =>
            dispatch({ type: 'UpdateNote', id, values }),
        []
    )
    const newNote = state.notes.find((a) => a.optimisticId === state.topEditingNote)
    const remainingNotes = state.notes.filter((a) => a.optimisticId !== state.topEditingNote)
    return {
        deleteNote,
        createNote,
        updateNote,
        newNote,
        remainingNotes,
    }
}

type CreateNote = {
    type: 'CreateNote'
}
export type UpdateNoteValues = Partial<
    Pick<OptimisticClientNote, 'Title' | 'Body' | 'SharingPrivacy' | 'isPinned' | 'didSubmitNote'>
>
type UpdateNote = {
    type: 'UpdateNote'
    id: OptimisticNoteId
    values: UpdateNoteValues
}

type DeleteNote = {
    type: 'DeleteNote'
    id: OptimisticNoteId
}

type NewNoteAction = CreateNote | UpdateNote | DeleteNote

function newNoteReducer(state: NewNotesState, action: NewNoteAction): NewNotesState {
    switch (action.type) {
        case 'CreateNote':
            if (state.topEditingNote) {
                const currentEditingNote = state.notes.find(
                    (a) => a.optimisticId === state.topEditingNote
                )
                if (
                    currentEditingNote &&
                    currentEditingNote.Title === '' &&
                    currentEditingNote.Body === ''
                ) {
                    return state
                }
            }
            const optimisticId = createOptimisticNoteId()
            const newNote: OptimisticClientNote = {
                optimisticId,
                Title: '',
                Body: '',
                SharingPrivacy: 'N',
                CreatedDate: new Date().toISOString(),
                didSubmitNote: false,
                isPinned: false,
            }
            return {
                topEditingNote: optimisticId,
                notes: [newNote, ...state.notes],
            }
        case 'UpdateNote':
            return {
                ...state,
                notes: state.notes.map((note) => {
                    if (note.optimisticId === action.id) {
                        return {
                            ...note,
                            ...action.values,
                        }
                    }
                    return note
                }),
            }
        case 'DeleteNote': {
            return {
                topEditingNote: action.id === state.topEditingNote ? null : state.topEditingNote,
                notes: state.notes.filter((note) => note.optimisticId !== action.id),
            }
        }
        default:
            return state
    }
}

import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'

import { toast } from '@laserfocus/ui/beam'
import { useRootStore } from '@laserfocus/client/root-store-context'
import {
    isMutationErrorResponse,
    MutationErrorResponse,
    OpportunityStageAttributes,
    OpportunityModel,
} from '@laserfocus/shared/models'
import { useMetadataStore } from '@laserfocus/client/store-shared'
import { getClient } from '@laserfocus/client/replicache'
import { serializeOpportunityInput } from '@laserfocus/client/model'
import { logger } from '@laserfocus/ui/logger'

import { useOpportunities, useCurrentOpportunity } from '../modules/PersonContext'

import { CreateObjectModal, FormControlProps } from './CreateObjectModal'

interface CreateOpportunityModalProps {
    closeModal(): void
}

export function CreateOpportunityModal({ closeModal }: CreateOpportunityModalProps) {
    const { opportunityStore } = useRootStore()
    const opportunity = useCurrentOpportunity()
    const { setCurrentOpportunityId } = useOpportunities()
    const [isSubmitting, setIsSubmitting] = useState(false)

    function handleSubmit(fieldsToSubmit: Record<string, any>) {
        setIsSubmitting(true)

        const asDTO = serializeOpportunityInput(fieldsToSubmit as any)

        return opportunityStore
            .create(asDTO)
            .then(
                (maybeOpportunity: MutationErrorResponse | OpportunityModel) => {
                    if (isMutationErrorResponse(maybeOpportunity)) {
                        logger.warn(maybeOpportunity)
                        toast.error({ title: 'Something went wrong' })
                        return maybeOpportunity
                    }

                    toast.success({
                        title: `Successfully created opportunity ${
                            (maybeOpportunity as OpportunityModel).Name
                        }`,
                    })
                    closeModal()
                    setCurrentOpportunityId && setCurrentOpportunityId(maybeOpportunity.Id)

                    /**
                     * This is temporary until we create objects via replicache.
                     * It requires migrating the CreateObjectModal to RC + use Failed Transactions
                     */
                    const replicache = getClient()
                    replicache.pullModules(['opportunityResult'])
                },
                (e: any) => {
                    logger.error(e)
                    toast.error({ title: 'Something went wrong' })
                }
            )
            .finally(() => setIsSubmitting(false))
    }

    return (
        <CreateObjectModal
            sobjectType="Opportunity"
            sobjectRecordTypeId={opportunity?.RecordTypeId || undefined}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            closeModal={closeModal}
        >
            {(props: FormControlProps) => <OpportunityFormControl {...props} />}
        </CreateObjectModal>
    )
}

const OpportunityFormControl = observer(function OpportunityFormControl({
    setValue,
    control,
}: FormControlProps) {
    const oppStage = useWatch({ control, name: 'StageName' })
    const metadataStore = useMetadataStore()
    const stageField = metadataStore?.getField('Opportunity', 'StageName')
    useEffect(() => {
        if (oppStage && stageField) {
            const selected = stageField.picklistOptions.find((a) => a.value === oppStage)
            const defaultProbability = (selected?.attributes as OpportunityStageAttributes)
                ?.defaultProbability
            if (defaultProbability) {
                setValue('Probability', defaultProbability)
            }
        }
    }, [oppStage, setValue, stageField])
    return null
})

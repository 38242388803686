import { action, computed, observable, reaction, runInAction } from 'mobx'
import { parseISO } from 'date-fns'

import { isAccount, isContact, isLead, isOpportunity } from '@laserfocus/shared/models'
import type {
    AccountModel,
    ContactModel,
    LeadModel,
    NameRelation,
    OpportunityModel,
    SnapshotListener,
    SnapShotter,
    TaskId,
} from '@laserfocus/shared/models'
import type { DateString, DateTimeString, NewTask } from '@laserfocus/shared/models'
import { ModelExtension, ObjectPool } from '@laserfocus/client/data-layer'

import * as TaskUtil from './task-util'
import * as ActivityUtil from './activity-util'

type TaskProps = Partial<Omit<NewTask, 'Id'>> & { Id: TaskId; __typename: 'Task' }

export class TaskModel implements NewTask, ModelExtension {
    private objectPool: ObjectPool
    __typename = 'Task' as const

    @observable Id: TaskId
    @observable CreatedDate?: DateTimeString
    @observable LastModifiedDate?: DateTimeString
    @observable CreatedById?: string
    @observable LastModifiedById?: string

    @observable Subject?: string
    @observable Description?: string
    @observable OwnerId?: string
    @observable AccountId?: string
    @observable WhoId?: string
    @observable WhatId?: string
    @observable AlternateDetailId?: string
    @observable Type?: string

    @observable RootId?: string
    @observable IsClosed: boolean

    @observable ActivityDate?: DateString
    @observable CompletedDateTime?: DateTimeString
    @observable Status?: string
    @observable ReminderDateTime?: DateTimeString
    @observable TaskSubtype?: string
    @observable Priority?: string

    _previousSnapshot: Map<number, any>
    _snapshotCounter = 0

    constructor(props: TaskProps, objectPool: ObjectPool) {
        this.Id = props.Id

        this.CreatedDate = props.CreatedDate
        this.LastModifiedDate = props.LastModifiedDate
        this.CreatedById = props.CreatedById
        this.LastModifiedById = props.LastModifiedById
        this.Subject = props.Subject ?? undefined
        this.Description = props.Description ?? undefined
        this.OwnerId = props.OwnerId ?? undefined
        this.AccountId = props.AccountId ?? undefined
        this.WhoId = props.WhoId ?? undefined
        this.WhatId = props.WhatId ?? undefined
        this.AlternateDetailId = props.AlternateDetailId ?? undefined
        this.Type = props.Type ?? undefined
        this.RootId = props.RootId ?? undefined
        this.IsClosed = Boolean(props.IsClosed)
        this.ActivityDate = props.ActivityDate ?? undefined
        this.CompletedDateTime = props.CompletedDateTime ?? undefined
        this.Status = props.Status ?? undefined
        this.ReminderDateTime = props.ReminderDateTime ?? undefined
        this.TaskSubtype = props.TaskSubtype ?? undefined
        this.Priority = props.Priority ?? undefined

        this.objectPool = objectPool
        this._previousSnapshot = new Map()
    }

    @action
    onSnapshot<T>(
        makeSnapshot: SnapShotter<TaskModel, T>,
        listener: SnapshotListener<T>,
        options?: {
            fireImmediately?: boolean
        }
    ): () => void {
        const index = this._snapshotCounter++
        const previousSnapshot = makeSnapshot(this)
        this._previousSnapshot.set(index, previousSnapshot)
        const discard = reaction(
            () => makeSnapshot(this),
            (current) => {
                listener(this._previousSnapshot.get(index), current)
                runInAction(() => {
                    this._previousSnapshot.set(index, current)
                })
            },
            options
        )
        return () => {
            this._previousSnapshot.delete(index)
            discard()
        }
    }

    @computed get props(): TaskProps {
        return {
            Id: this.Id,
            __typename: 'Task',
            CreatedDate: this.CreatedDate,
            LastModifiedDate: this.LastModifiedDate,
            CreatedById: this.CreatedById,
            LastModifiedById: this.LastModifiedById,
            Subject: this.Subject,
            Description: this.Description,
            OwnerId: this.OwnerId,
            AccountId: this.AccountId,
            WhoId: this.WhoId,
            WhatId: this.WhatId,
            AlternateDetailId: this.AlternateDetailId,
            Type: this.Type,
            RootId: this.RootId,
            IsClosed: this.IsClosed,
            ActivityDate: this.ActivityDate,
            CompletedDateTime: this.CompletedDateTime,
            Status: this.Status,
            ReminderDateTime: this.ReminderDateTime,
            TaskSubtype: this.TaskSubtype,
            Priority: this.Priority,
        }
    }

    @computed
    get isOpen() {
        return !this.IsClosed
    }

    @computed
    get Date() {
        return ActivityUtil.getDate(this)
    }

    @computed
    get SortingDate() {
        if (this.isOpen) {
            return this.OpenDate
        }
        return
    }

    @computed
    get OpenDate() {
        return TaskUtil.getOpenDate({
            ActivityDate: this.ActivityDate,
            CompletedDateTime: this.CompletedDateTime,
            CreatedDate: this.CreatedDate,
            ReminderDateTime: this.ReminderDateTime,
        })
    }
    @computed
    get ClosedDate() {
        if (this.CompletedDateTime) {
            return parseISO(this.CompletedDateTime)
        }
        return this.OpenDate
    }

    /**
     * ------------------ Relationships --------------------
     */

    @observable _Who?: NameRelation | LeadModel | ContactModel
    @observable _Account?: NameRelation | AccountModel

    @computed get Who() {
        if (this.WhoId && isLead(this.WhoId)) {
            return this.objectPool.get<LeadModel>('Lead', this.WhoId) || this._Who
        } else if (this.WhoId && isContact(this.WhoId)) {
            return this.objectPool.get<ContactModel>('Contact', this.WhoId) || this._Who
        }
        return undefined
    }
    set Who(who: NameRelation | undefined) {
        this._Who = who
    }

    @computed get Account() {
        const accountId = isAccount(this.WhatId) ? this.WhatId : undefined
        const id = this.AccountId || accountId
        if (id) {
            return this.objectPool.get<AccountModel>('Account', id) || this._Account
        }
        return this._Account
    }

    set Account(account: NameRelation | undefined) {
        this._Account = account
    }

    @computed get What() {
        if (this.WhatId && isAccount(this.WhatId)) {
            return this.objectPool.get<AccountModel>('Account', this.WhatId)
        } else if (this.WhatId && isOpportunity(this.WhatId)) {
            return this.objectPool.get<OpportunityModel>('Account', this.WhatId)
        }
        return undefined
    }

    @computed get Lead() {
        if (isLead(this.WhoId)) {
            return this.Who as LeadModel | { Name: string; Id: string; Company?: string }
        }
        return undefined
    }

    @computed get Contact() {
        if (isContact(this.WhoId)) {
            return this.Who
        }
        return undefined
    }

    @computed get Opportunity() {
        if (isContact(this.WhatId)) {
            return this.What
        }
        return undefined
    }
}

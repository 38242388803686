import { Control } from 'react-hook-form'

import { FieldMetadata } from '@laserfocus/client/model'
import { getFormControl } from '@laserfocus/client/shared-sales-object-form-control'
import { ContactModel, OpportunityModel } from '@laserfocus/shared/models'

export interface FormControlProps {
    fieldMetadata: FieldMetadata
    recordTypeId?: string
    control: Control<OpportunityModel | ContactModel>
}

export function FormField(props: FormControlProps) {
    const { fieldMetadata, control } = props
    const name = fieldMetadata.name
    const id = `CreateObject-${name}`
    const formControl = getFormControl<OpportunityModel | ContactModel>({
        id,
        control,
        fieldMetadata,
    })

    return formControl ? (
        <>
            {/* Must return two DOM elements because they are laid out on grid. */}
            <label
                htmlFor={id}
                // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
                className="text-sm font-medium leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
            >
                {fieldMetadata.label}
                {fieldMetadata.required && (
                    <span title="Required" className="px-1 -mr-1 select-none text-red-500">
                        •
                    </span>
                )}
            </label>
            {formControl}
        </>
    ) : null
}

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type VariantElementsStyles = {
    button?: string
    icon?: string
    iconDisabledOverride?: string
}

const variantsStyles: Record<ButtonVariant, VariantElementsStyles> = {
    primary: {
        button: /* tw: */ 'text-white bg-blue-500 hover:bg-blue-700 active:bg-blue-700 focus-visible:bg-blue-700 focus-visible:ring ring-blue-500/10 disabled:text-grey-700/40 disabled:bg-grey-700/5',
        icon: /* tw: */ 'text-white/60',
        iconDisabledOverride: /* tw: */ 'text-grey-700/20',
    },
    secondary: {
        button: /* tw: */ 'text-grey-700 bg-white ring-1 ring-grey-700/10 shadow-[0_1px_3px_rgba(0,0,0,0.05)] hover:ring-grey-700/20 active:ring-grey-700/20 focus-visible:ring focus-visible:ring-blue-500 disabled:text-grey-700/40 disabled:ring-grey-700/5',
        icon: /* tw: */ 'text-grey-700/60',
        iconDisabledOverride: /* tw: */ 'text-grey-700/20',
    },
    tertiary: {
        button: /* tw: */ 'text-grey-700/60 hover:bg-grey-700/5 hover:text-grey-700 active:bg-grey-700/5 active:text-grey-700 focus-visible:ring disabled:text-grey-700/40 disabled:bg-transparent',
        icon: /* tw: */ 'text-grey-700/60',
        iconDisabledOverride: /* tw: */ 'text-grey-700/20',
    },
    quaternary: {
        button: /* tw: */ 'text-white hover:bg-white/10 active:bg-white/10 focus-visible:ring disabled:text-white/40 disabled:bg-transparent',
        icon: /* tw: */ 'text-white/60',
        iconDisabledOverride: /* tw: */ 'text-white/20',
    },
}

type SizeElementStyles = {
    button?: string
    buttonIconOnlyOverride?: string
}

const sizesStyles: Record<ButtonSize, SizeElementStyles> = {
    small: {
        button: /* tw: */ 'text-sm font-medium px-2 py-1',
        buttonIconOnlyOverride: /* tw: */ 'p-[0.3125rem]',
    },
    medium: {
        button: /* tw: */ 'text-base px-2 py-[0.3125rem]',
        buttonIconOnlyOverride: /* tw: */ 'p-[0.4375rem]',
    },
    large: {
        button: /* tw: */ 'text-base font-semibold leading-[1.25] px-5 py-[0.625rem] rounded-lg',
        buttonIconOnlyOverride: /* tw: */ 'p-3',
    },
}

const defaultElement = 'button'

export type ButtonProps<T extends React.ElementType = typeof defaultElement> =
    StaticButtonProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof StaticButtonProps<T>>

interface StaticButtonProps<T> {
    as?: T
    iconComponent?: React.ComponentType<{ className?: string }>
    iconClassName?: string
    iconPosition?: IconPosition
    variant?: ButtonVariant
    size?: ButtonSize
    inline?: boolean
}
type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary'
type ButtonSize = 'large' | 'medium' | 'small'
type IconPosition = 'left' | 'right'

export const Button = forwardRef(function Button<
    T extends React.ElementType = typeof defaultElement
>(
    {
        as,
        children,
        iconComponent: Icon,
        iconClassName,
        iconPosition = 'left',
        variant = 'secondary',
        size = 'medium',
        inline,
        ...restProps
    }: ButtonProps<T>,
    ref: React.ForwardedRef<T>
) {
    const variantStyles = variantsStyles[variant]
    const sizeStyles = sizesStyles[size]
    const displayIcon = Icon && (
        <Icon
            className={twMerge(
                'flex-shrink-0',
                size === 'large' ? 'w-6 h-6' : 'w-4 h-4',
                iconPosition === 'left' ? 'justify-self-start' : 'justify-self-end',
                variantStyles.icon,
                restProps.disabled && variantStyles.iconDisabledOverride,
                children &&
                    (iconPosition === 'left'
                        ? size === 'large'
                            ? 'mr-2'
                            : 'mr-1'
                        : size === 'large'
                        ? 'ml-2'
                        : 'ml-1'),
                iconClassName
            )}
        />
    )
    const Element = as || defaultElement

    return (
        <Element
            ref={ref as any}
            {...restProps}
            className={twMerge(
                'transition outline-none rounded-md leading-[1.3] grid-flow-col items-center',
                restProps.disabled ? 'cursor-default' : 'cursor-pointer',
                inline ? 'inline-grid' : 'grid',
                sizeStyles.button,
                !children && sizeStyles.buttonIconOnlyOverride,
                variantStyles.button,
                restProps.className
            )}
        >
            {displayIcon && iconPosition === 'left' && displayIcon}
            {children}
            {displayIcon && iconPosition === 'right' && displayIcon}
        </Element>
    )
}) as <T extends React.ElementType = typeof defaultElement>(
    props: ButtonProps<T> & { ref?: React.ForwardedRef<T> }
) => JSX.Element

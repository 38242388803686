import {
    Identifier,
    Id,
    PhoneNumber,
    Url,
    ModelCustomFields,
    WithCustomFields,
    WithCustomFieldsDTO,
    BaseFields,
    ToDTO,
    Currency,
    WithType,
} from '../base/model.types'
import { DateTimeString } from '../base/date.types'

import { PureContact, PureContactDTO } from './contact.types'
import { PureOpportunity, PureOpportunityDTO } from './opportunity.types'
import { PersonActivity, ActivityDTO, EventDTO, TaskDTO, OwnerDTO } from './activity.types'

export const ACCOUNT_PREFIX = '001'

export type AccountFields = Partial<BaseFields> &
    Partial<{
        Name?: string
        Phone?: PhoneNumber
        Website?: Url
        readonly LastActivityDate?: DateTimeString
        readonly RecordTypeId?: string

        CurrencyIsoCode: Currency

        BillingStreet: string
        BillingState: string
        BillingPostalCode: string
        BillingCity: string
        BillingCountry: string
        BillingGeocodeAccuracy: string
        BillingLatitude: number
        BillingLongitude: number

        ShippingStreet: string
        ShippingState: string
        ShippingPostalCode: string
        ShippingCity: string
        ShippingCountry: string
        ShippingGeocodeAccuracy: string
        ShippingLatitude: number
        ShippingLongitude: number

        /**
         * RelationShipField
         */
        OwnerId: Id
    }>

export type AccountProps = Identifier & WithType<'Account'> & AccountFields
export type AccountModel = AccountProps & ModelCustomFields

export function isAccountModel(model: WithType<string>): model is AccountModel {
    return model.__typename === 'Account'
}

export type AccountRelationShips = Partial<{
    Tasks?: PersonActivity[]
    Events?: PersonActivity[]
    OpenActivities?: PersonActivity[]
    ActivityHistories?: PersonActivity[]
    Contacts: PureContact[]
    Opportunities: PureOpportunity[]
}>

export interface PureAccount extends Identifier, AccountFields, WithCustomFields {}

export interface Account extends PureAccount, AccountRelationShips {}

export interface PureAccountDTO extends Identifier, ToDTO<AccountFields>, WithCustomFieldsDTO {
    __typename: 'Account'
}
export interface AccountDTO extends PureAccountDTO {
    OpenActivities?: ActivityDTO[]
    ActivityHistories?: ActivityDTO[]
    Contacts?: PureContactDTO[]
    Tasks?: TaskDTO[]
    Events?: EventDTO[]
    Opportunities?: PureOpportunityDTO[]
    Owner?: OwnerDTO
}

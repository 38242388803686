import { ErrorBehaviour } from '@laserfocus/shared-node/util-error'

export type FieldError = {
    message: string
    fieldName: string
    fieldPath?: string
    code?: string
}

export interface MutationErrorResponse {
    isError: boolean
    message: string
    fieldErrors: FieldError[]
    formErrors: string[]
    sourceFields?: string[]
    code?: string
    level: 'warn' | 'error'
}

export function shouldShowErrorMessage(
    e: Error | MutationErrorResponse | unknown
): e is MutationErrorResponse {
    return Boolean(
        ['BAD_USER_INPUT', 'ENTITY_IS_DELETED'].includes((e as { code: string }).code) ||
            (e as ErrorBehaviour).isUserInput
    )
}

export function isMutationErrorResponse(
    resp?: MutationErrorResponse | unknown
): resp is MutationErrorResponse {
    return Boolean(resp && (resp as MutationErrorResponse).isError)
}

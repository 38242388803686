import { useEffect, useMemo } from 'react'
import { useObserver } from 'mobx-react-lite'
import { useSearchParams } from 'react-router-dom'

import { StackNavigationProvider, StackNavigationContext } from '@laserfocus/client/store-shared'

import { useActivityStackNavigationStore } from '../store/ActivityStackNavigation'

import PrefetchNext from './PrefetchNext'

interface OverdueContainerProps {
    rootId: string
}

export function OverdueNavigationProvider({
    rootId,
    children,
}: React.PropsWithChildren<OverdueContainerProps>) {
    const overdueNavigation = useActivityStackNavigationStore('overdue')

    const [queryParams] = useSearchParams()
    const activityId = queryParams.get('activityId')

    useEffect(() => {
        overdueNavigation.navigated({
            Id: activityId,
            RootId: rootId,
        })
    }, [rootId, activityId, overdueNavigation])

    const nextId = useObserver(() => overdueNavigation.next?.RootId)

    const value: StackNavigationContext = useMemo(
        () => ({
            navigateList: overdueNavigation?.gotoList,
            navigateNext: overdueNavigation?.navigateNext,
            navigatePrevious: overdueNavigation?.navigatePrevious,
            listPath: overdueNavigation?.listPath,
            title: 'Overdue',
        }),
        [
            overdueNavigation?.gotoList,
            overdueNavigation?.listPath,
            overdueNavigation?.navigateNext,
            overdueNavigation?.navigatePrevious,
        ]
    )

    return (
        <StackNavigationProvider value={value}>
            <>
                {children}
                {nextId && <PrefetchNext rootId={nextId} />}
            </>
        </StackNavigationProvider>
    )
}

import { z } from '@laserfocus/shared/decoder'

import { UserIdSchema } from '../base/id.types'
import { makeBaseRecordSchema } from '../base/singletable.types'

import { filterConditionSchema } from './filter-conditions-schema'

export const sortingSchema = z.object({
    fieldName: z.string(),
    direction: z.literal('ASC').or(z.literal('DESC')),
})

export const StackConstantTimingSchema = z.object({
    unit: z.literal('always'),
})
export type StackConstantTiming = z.infer<typeof StackConstantTimingSchema>
export const StackWeeklyTimingSchema = z.object({
    unit: z.literal('week'),
    interval: z.number().min(1),
    // 0 = Sunday
    daysOfTheWeek: z.array(z.number().min(0).max(6)),
})
export type StackWeeklyTiming = z.infer<typeof StackWeeklyTimingSchema>
export const StackMonthlyTimingSchema = z.object({
    unit: z.literal('month'),
    interval: z.number().min(1),
    dayOfTheMonth: z.number().min(1).max(31),
})
export type StackMonthlyTiming = z.infer<typeof StackMonthlyTimingSchema>

export const StackTimingSchema =
    StackConstantTimingSchema.or(StackWeeklyTimingSchema).or(StackMonthlyTimingSchema)
export type StackTiming = z.infer<typeof StackTimingSchema>

export const aggregationSchema = z.enum([
    // None needs to be here so we can implement fallbacks client side
    // that are overwritten when somebody sets none
    'none',
    'count-all',
    'count-non-empty',
    'count-unique',
    'count-empty',
    'percent-empty',
    'percent-non-empty',
    'sum',
    'average',
    'min',
    'max',
    'range',
])

export type Sorting = z.infer<typeof sortingSchema>
export type Aggregation = z.infer<typeof aggregationSchema>

const presetSchema = z.enum([
    'past-close-date',
    'laserfocus-method',
    'quarter-forecast',
    'leads-since-lastweek',
    'stalled-accounts',
    'pipeline-review',
])

export type StackPreset = z.infer<typeof presetSchema>

/*
 * When changing anything here, keep in mind to also update the "updater"
 * libs/client/store-shared/src/stack/update-stack.ts
 */
export const BaseStackSchema = z.object({
    id: z.string(),
    version: z.number(),
    title: z.string(),
    description: z.string().nullish(),
    sobject: z.enum(['Lead', 'Account', 'Contact', 'Opportunity']),
    filterConditions: z.record(z.string(), filterConditionSchema),
    columnAggregates: z.record(z.string(), aggregationSchema).default({}),
    columnWidths: z.record(z.string(), z.number().min(0)).default({}),
    visibleColumnMap: z.record(z.string(), z.string()),
    sorting: sortingSchema.optional(),
    color: z.string().optional(),
    icon: z.string().optional(),
    helpUrl: z.string().optional(),
    timing: StackTimingSchema.optional(),
})

const UserStackSchema = BaseStackSchema.merge(makeBaseRecordSchema('UserStack')).extend({
    isFavorite: z.boolean().optional(),
})

const OrgStackSchema = BaseStackSchema.merge(makeBaseRecordSchema('OrgStack'))
    .omit({ deleted: true })
    .extend({
        deactivated: z.boolean().optional(),
        /**
         * Is this a laserfocus template
         */
        preset: presetSchema.optional(),
        lockedColumnNames: z.array(z.string()).default([]).optional(),
        roles: z.array(z.string()).default([]).optional(),
    })

export const StackInputSchema = BaseStackSchema.omit({ id: true, version: true }).extend({
    isFavorite: z.boolean().optional(),
    preset: presetSchema.optional(),
    lockedColumnNames: z.array(z.string()).default([]).optional(),
})

export const OrgStackInputExtensionSchema = OrgStackSchema.pick({ roles: true })

const MergedSchema = UserStackSchema.merge(OrgStackSchema)
export type UserStack = z.infer<typeof UserStackSchema>
export type OrgStack = z.infer<typeof OrgStackSchema>
export type Stack = z.infer<typeof MergedSchema> & { isExtendedFromOrg?: boolean }
export type StackInput = z.input<typeof StackInputSchema>
export type OrgStackInputExtension = z.input<typeof OrgStackInputExtensionSchema>

export const StackRowsSchema = z.object({
    id: z.string(),
    colors: z.record(z.string(), z.string().nullish()).default({}),
    userId: UserIdSchema.nullish(),
    __typename: z.literal('stackrows').default('stackrows'),
})

export const StackRowsRecordSchema = StackRowsSchema.merge(
    makeBaseRecordSchema('stackrows')
).extend({
    userId: UserIdSchema,
})

export type StackRows = z.infer<typeof StackRowsSchema> & { id: string; __typename: 'stackrows' }
export type StackRowsRecord = z.infer<typeof StackRowsRecordSchema> & {
    PK: string
    SK: string
}

import { Modal } from '@laserfocus/ui/beam'
import { DataLimitOutlinedIcon } from '@laserfocus/ui/icons'

type DataLimitModalProps = {
    loadedCount: number
    totalCount: number
    close: () => void
}
export function DataLimitModal({ loadedCount, totalCount, close }: DataLimitModalProps) {
    return (
        <Modal show onClose={close}>
            <Modal.Overlay />
            <Modal.Container>
                <Modal.Header close={close}>
                    <DataLimitOutlinedIcon className="inline w-8 h-8 text-red-500 mr-2" />
                    Data limit reached
                </Modal.Header>
                <Modal.Footer>
                    <p className="mb-4">
                        Only{' '}
                        <strong>
                            {loadedCount} out of {totalCount}
                        </strong>{' '}
                        records are shown. The amount of data Salesforce can load is limited. Adjust
                        your filter to limit the results and reload more data.
                        <br />
                        In order to add a filter click on filter icon next to the column name.
                    </p>
                </Modal.Footer>
            </Modal.Container>
        </Modal>
    )
}

import { useMemo, useState } from 'react'
import { ulid } from 'ulid'
import { omit } from 'lodash'
import { Link } from 'react-router-dom'

import { getStackTemplates, Stack, StackInput } from '@laserfocus/shared/models'
import { Button, Card, SpinnerInline } from '@laserfocus/ui/beam'
import { StackModel } from '@laserfocus/client/model'
import { mutateStack } from '@laserfocus/client/data-access-shared'
import { AddOutlinedIcon, ArrowRight2OutlinedIcon } from '@laserfocus/ui/icons'

import { Avatar } from './StackModalCards'
import { useCreateStackContext } from './CreateStackContext'

type CreatedStackFromtemplate = {
    id: string
    preset: string
}
export function CreateStackFromTemplateBody({ stacks }: { stacks: Stack[] }) {
    const { type, makePath, extension } = useCreateStackContext()
    const [created, setCreated] = useState<Array<CreatedStackFromtemplate>>([])

    const templates = useMemo(() => getStackTemplates(), [])

    const stacksById = Object.fromEntries(stacks.map((a) => [a.id, a]))

    function createStack(template: StackInput & { preset: string }) {
        const id = ulid()
        const toAdd = {
            id,
            preset: template.preset,
        }
        setCreated((prev) => [...prev, toAdd])
        const stack = {
            ...omit(template, 'preset'),
            title: `(Copy of) ${template.title}`,
            isFavorite: type === 'user' ? true : undefined,
        }
        return mutateStack.createStack(stack, { type, id }, extension)
    }

    return (
        <div className="px-8 py-6 min-h-[21.75rem] max-h-[50vh] overflow-y-auto">
            <div className="grid gap-2 w-full">
                {templates.map((template) => {
                    const createdItem = created.find((a) => a.preset === template.preset)
                    const isCreating = Boolean(createdItem)
                    const createdId = createdItem?.id
                    const createdSlug =
                        createdId &&
                        stacksById[createdId] &&
                        StackModel.getSlug(stacksById[createdId])

                    return (
                        <Card
                            className="px-4 py-3 w-full"
                            style={
                                template.color?.startsWith('#') || template.color?.startsWith('rgb')
                                    ? { backgroundColor: template.color }
                                    : undefined
                            }
                            key={template.preset}
                        >
                            <div className="grid grid-flow-col grid-cols-[auto,1fr,auto]  items-center">
                                <div>
                                    <Avatar>{template.icon}</Avatar>
                                </div>
                                <div>
                                    <div className="flex-1 ml-3 font-semibold">
                                        {template.title}
                                    </div>
                                    <div className="ml-3 text-xs leading-[1.2] font-medium text-grey-700/60">
                                        {template.description}
                                    </div>
                                </div>
                                <div>
                                    {createdSlug ? (
                                        <Button
                                            as={Link}
                                            to={makePath(createdSlug)}
                                            iconComponent={ArrowRight2OutlinedIcon}
                                            iconPosition="right"
                                        >
                                            {type === 'user' ? 'Go to Stack' : 'Configure Stack'}
                                        </Button>
                                    ) : (
                                        <Button
                                            iconComponent={
                                                isCreating ? SpinnerInline : AddOutlinedIcon
                                            }
                                            onClick={() => createStack(template)}
                                            disabled={isCreating}
                                        >
                                            Add
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Card>
                    )
                })}
            </div>
        </div>
    )
}

import { useEffect, useState } from 'react'

import { EmptyState } from '@laserfocus/ui/beam'

import { OnboardingLayout } from '../shared/OnboardingLayout'

export function StepWait() {
    const [dotCount, setDotCount] = useState(1)

    useEffect(() => {
        const interval = setInterval(() => setDotCount((count) => (count % 3) + 1), 800)
        return () => clearInterval(interval)
    }, [])

    const dots = new Array(dotCount).fill('.').join('')
    return (
        <OnboardingLayout>
            <EmptyState
                title={
                    <span>
                        Connecting with Salesforce{dots}
                        {dotCount < 2 && <>&nbsp;</>}
                        {dotCount < 3 && <>&nbsp;</>}
                    </span>
                }
                subtitle={`It might take longer, so lean back.
                We’ll notify you via email when everything is ready.`}
                emojis={['🍵', '🕰', '📟']}
            />
        </OnboardingLayout>
    )
}

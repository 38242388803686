import { z } from '@laserfocus/shared/decoder'

import { EventIdSchema, TaskIdSchema, LeadIdSchema, AccountIdSchema } from '../base/id.types'
import { OptimisticTaskIdSchema, OptimisticEventIdSchema } from '../sync/optimistic-id.types'
import { DateStringSchema, DateTimeStringSchema } from '../base/date.types'
import { CreateOptimisticSchema } from '../base/singletable.types'

import { TaskCreateInputSchema, TaskUpdateValuesSchema } from './activity-schema'

const TaskIdWithOptimistic = z.union([TaskIdSchema, OptimisticTaskIdSchema])
const ActivityIdWithOptimistic = z.union([
    TaskIdSchema,
    OptimisticTaskIdSchema,
    EventIdSchema,
    OptimisticEventIdSchema,
])

export const ActivityMutatorSchema = {
    completeTask: z.object({
        taskId: TaskIdWithOptimistic,
        optimistic: z
            .object({
                LastModifiedDate: DateTimeStringSchema.optional(),
                CompletedDateTime: DateTimeStringSchema.optional(),
            })
            .optional(),
    }),
    uncompleteTask: z.object({
        taskId: TaskIdWithOptimistic,
        optimistic: z
            .object({
                LastModifiedDate: DateTimeStringSchema.optional(),
            })
            .optional(),
    }),
    rescheduleTask: z.object({
        taskId: TaskIdWithOptimistic,
        date: DateStringSchema,
        dateTime: DateTimeStringSchema.optional(),
        optimistic: z
            .object({
                LastModifiedDate: DateTimeStringSchema.optional(),
            })
            .optional(),
    }),
    rescheduleEvent: z.object({
        eventId: EventIdSchema,
        dateTime: DateTimeStringSchema,
        optimistic: z
            .object({
                LastModifiedDate: DateTimeStringSchema.optional(),
            })
            .optional(),
    }),

    updateEvent: z.object({
        eventId: EventIdSchema,
        values: z.object({
            Subject: z.string().nullish(),
            Description: z.string().nullish(),
            WhoId: z.string().nullish(),
            WhatId: z.string().nullish(),
            AccountId: z.string().nullish(),
            Type: z.string().nullish(),
        }),
        optimistic: z.object({
            LastModifiedDate: DateTimeStringSchema.optional(),
            AccountId: z.string().optional(),
        }),
    }),
    updateTask: z.object({
        taskId: TaskIdWithOptimistic,
        values: TaskUpdateValuesSchema,
        optimistic: z.object({
            LastModifiedDate: DateTimeStringSchema.optional(),
            AccountId: z.string().optional(),
        }),
    }),
    deleteActivity: z.object({
        activityId: ActivityIdWithOptimistic,
        optimistic: z
            .object({
                LastModifiedDate: DateTimeStringSchema.optional(),
            })
            .optional(),
    }),
    logActivity: z.object({
        optimisticId: OptimisticTaskIdSchema,
        subject: z.string().or(z.null()).optional(),
        description: z.string().or(z.null()).optional(),
        whatId: z.string().optional(),
        whoId: z.string().optional(),
        accountId: z.string().optional(),
        ownerId: z.string().optional(),
        createdDateTime: DateTimeStringSchema.optional(),
        type: z.string().optional(),
        priority: z.string().optional(),
    }),
    createTask: z.object({
        optimisticId: OptimisticTaskIdSchema,
        values: TaskCreateInputSchema,
        optimistic: CreateOptimisticSchema,
    }),
    scheduleTask: z.object({
        optimisticId: OptimisticTaskIdSchema,
        subject: z.string(),
        date: DateStringSchema,
        dateTime: DateTimeStringSchema.optional(),
        whatId: z.string().optional(),
        whoId: z.string().optional(),
        accountId: z.string().optional(),
        ownerId: z.string().optional(),
        type: z.string().optional(),
        priority: z.string().optional(),
        createdDateTime: DateTimeStringSchema.optional(),
    }),
    pinActivity: z.object({
        rootId: z.union([LeadIdSchema, AccountIdSchema]),
        activityId: z.string(),
    }),
    unpinActivity: z.object({
        rootId: z.union([LeadIdSchema, AccountIdSchema]),
        activityId: z.string(),
    }),
}

import 'reflect-metadata'
import { observable, computed, action } from 'mobx'
import { last } from 'lodash'

import {
    PersonQueryParams,
    goToPersonDetails,
    goToActivityStackPage,
} from '@laserfocus/client/util-routing'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { logger } from '@laserfocus/ui/logger'

type AllActivity = {
    Id?: string | null
    RootId?: string | null
    WhoId?: string | null
}

type NavigatableActivity = {
    Id?: string | null
    RootId: string
    WhoId?: string
}

type ActivityGetter = () => NavigatableActivity[]

type NavigateListType = typeof goToActivityStackPage
type NavigateDetailsType = typeof goToPersonDetails

type ActivityStore = {
    todayActivities: AllActivity[]
    overdueActivities: AllActivity[]
    futureOpenActivities: AllActivity[]
}
export type RootStoreInjection = {
    activityStackNavigation?: ActivityStackNavigation
}

type NavigatableStack = 'today' | 'overdue' | 'upcoming'
export function useActivityStackNavigationStore(
    stack: NavigatableStack
): ActivityStackNavigationState {
    const rootStore = useRootStore<RootStoreInjection>()
    const store = rootStore.activityStackNavigation
    if (stack === 'today') {
        return store!.todayNavigation
    } else if (stack === 'overdue') {
        return store!.overdueNavigation
    } else if (stack === 'upcoming') {
        return store!.upcomingNavigation
    }
    throw new Error(`Trying to get the ActivityNavigationState for ${stack}`)
}
export class ActivityStackNavigation {
    todayNavigation: ActivityStackNavigationState
    overdueNavigation: ActivityStackNavigationState
    upcomingNavigation: ActivityStackNavigationState
    constructor(activityStore: ActivityStore) {
        this.todayNavigation = new ActivityStackNavigationState({
            stackName: 'today' as const,
            getData: () =>
                activityStore.todayActivities.filter((a) => a.RootId) as NavigatableActivity[],
        })
        this.overdueNavigation = new ActivityStackNavigationState({
            stackName: 'overdue' as const,
            getData: () =>
                activityStore.overdueActivities.filter((a) => a.RootId) as NavigatableActivity[],
        })
        this.upcomingNavigation = new ActivityStackNavigationState({
            stackName: 'upcoming' as const,
            getData: () =>
                activityStore.futureOpenActivities.filter((a) => a.RootId) as NavigatableActivity[],
        })
    }
}

export class ActivityStackNavigationState {
    @observable _next?: NavigatableActivity
    @observable _previous?: NavigatableActivity
    @observable current?: NavigatableActivity

    stackName: NavigatableStack

    private navigateList: NavigateListType
    private navigateDetails: NavigateDetailsType

    getData: ActivityGetter
    constructor({
        getData,
        stackName,
        navigateList = goToActivityStackPage,
        navigateDetails = goToPersonDetails,
    }: {
        getData: ActivityGetter
        stackName: NavigatableStack
        navigateList?: NavigateListType
        navigateDetails?: NavigateDetailsType
    }) {
        this.getData = getData
        this.stackName = stackName
        this.navigateList = navigateList
        this.navigateDetails = navigateDetails
    }

    @computed
    get data() {
        return this.getData()
    }

    @computed
    get listPath() {
        return `/${this.stackName}`
    }

    @action
    navigated(current: NavigatableActivity) {
        this.current = current
        this._next = findNext(this.data, current.RootId, current.Id)
        this._previous = findPrevious(this.data, current.RootId, current.Id)
    }

    @action.bound
    gotoList() {
        return this.navigateList(this.stackName)
    }

    @computed
    get next() {
        if (this.current) {
            const forceToFind = !this._next
            const currentNext = findNext(
                this.data,
                this.current.RootId,
                this.current.Id,
                forceToFind
            )
            if (currentNext) {
                return currentNext
            }
        }
        return this._next
    }

    @computed
    get previous() {
        if (this.current) {
            const forceToFind = !this._previous
            const currentPrevious = findPrevious(
                this.data,
                this.current.RootId,
                this.current.Id,
                forceToFind
            )
            if (currentPrevious) {
                return currentPrevious
            }
        }
        return this._previous
    }

    @action.bound
    navigateNext() {
        const next = this.next
        if (next && next.RootId) {
            const params: PersonQueryParams = {
                activityId: next.Id,
            }
            if (next.WhoId && next.WhoId !== next.RootId) {
                params.contactId = next.WhoId
            }
            logger.debug('goToPersonDetails', next.RootId, params)
            return this.navigateDetails(next.RootId, params, this.stackName)
        }
        return this.gotoList()
    }

    @action.bound
    navigatePrevious() {
        const prev = this.previous
        if (prev && prev.RootId) {
            const params: PersonQueryParams = {
                activityId: prev.Id,
            }

            if (prev.WhoId && prev.WhoId !== prev.RootId) {
                params.contactId = prev.WhoId
            }
            return this.navigateDetails(prev.RootId, params, this.stackName)
        }
        return this.gotoList()
    }
}

function findNext(
    list: NavigatableActivity[],
    rootId: string | undefined,
    activityId: string | undefined | null,
    forceToFind: boolean = true
) {
    if (activityId) {
        const ids = list.map((a) => a.Id)
        const current = ids.indexOf(activityId)
        if (!forceToFind && current === -1) {
            return
        }
        return findRealNext(list, current, rootId)
    }
    const rootIds = list.map((a) => a.RootId)
    const currentIndex = rootId ? rootIds.indexOf(rootId) : 0
    return findRealNext(list, currentIndex, rootId)
}

function findRealNext(
    list: NavigatableActivity[],
    currentIndex: number,
    rootId: string | undefined
) {
    const upcoming = list.slice(currentIndex)
    const remaining = upcoming.filter((a) => getRoot(a) !== rootId)
    return remaining[0]
}

function findPrevious(
    list: NavigatableActivity[],
    rootId: string | undefined,
    activityId: string | undefined | null,
    forceToFind: boolean = true
) {
    const ids = list.map((a) => a.Id)
    if (activityId) {
        const current = ids.indexOf(activityId)
        if (!forceToFind && current === -1) {
            return
        }
        return findRealPrev(list, current, rootId)
    }
    const indexes = list.map((i) => getRoot(i))
    const currentIndex = indexes.indexOf(rootId)
    return findRealPrev(list, currentIndex, rootId)
}

function findRealPrev(
    list: NavigatableActivity[],
    currentIndex: number,
    rootId: string | undefined
) {
    const upcoming = list.slice(0, currentIndex)
    const remaining = upcoming.filter((a) => getRoot(a) !== rootId)
    return last(remaining)
}

function getRoot(ac: NavigatableActivity) {
    if (!ac) {
        return
    }
    return ac.RootId
}

import { Transition } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

interface ModalContainerProps extends React.ComponentPropsWithoutRef<'div'> {
    variableHeight?: boolean
}

export function ModalContainer({ variableHeight, ...props }: ModalContainerProps) {
    const positionCorrection = window.innerHeight * 0.13 - Math.floor(window.innerHeight * 0.13)

    return (
        <Transition.Child
            enter="duration-300 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="duration-100 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
            {...props}
            className={twMerge(
                'fixed left-1/2 w-[32.5rem] rounded-2xl z-10 overflow-hidden bg-white -translate-x-1/2',
                !variableHeight && '-translate-y-1/2',
                props.className
            )}
            style={{
                top: variableHeight ? `calc(13vh - ${positionCorrection.toFixed(3)}px)` : '50%',
                ...props.style,
            }}
        />
    )
}

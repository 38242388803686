import { ReadTransaction, Replicache } from 'replicache'

import { Prefix } from '@laserfocus/shared/models'

export async function runReplicacheOnboardingCheck(replicache: Replicache) {
    let timeout: ReturnType<typeof setTimeout>
    let subscription: ReturnType<typeof replicache.subscribe>
    await Promise.race([
        new Promise<void>((resolve, reject) => {
            subscription = replicache.subscribe(
                async (tx: ReadTransaction) => {
                    const errors = (await tx
                        .scan({ prefix: `${Prefix.Error}` })
                        .values()
                        .toArray()) as Array<{ message: string; scope: string }>

                    // Proxy to know when a pull has happened
                    const prefixes = [
                        // First Pull
                        Prefix.Stack,
                        Prefix.ObjectMetadata,
                        // Second Pull
                        Prefix.User,
                        Prefix.Group,
                        Prefix.FieldGroup,
                        Prefix.OrgSettings,
                        Prefix.Identity,
                    ]
                    const tasks = prefixes.map((prefix) =>
                        tx.scan({ prefix, limit: 1 }).values().toArray()
                    )
                    const results = await Promise.all(tasks)
                    const loadedModuleCount = results
                        .map((a) => a.length)
                        .reduce((prev, curr) => prev + curr, 0)
                    return {
                        errors,
                        loaded: loadedModuleCount >= 4,
                    }
                },
                {
                    onData: ({ errors, loaded }) => {
                        if (loaded) {
                            clearTimeout(timeout)
                            subscription()
                            if (errors.length > 0) {
                                const firstErr = errors[0]
                                reject(new Error(`${firstErr.scope}: ${firstErr.message}`))
                            } else {
                                resolve()
                            }
                        }
                    },
                }
            )
        }),
        new Promise((resolve, reject) => {
            timeout = setTimeout(() => {
                subscription?.()
                reject(new Error('RCOnboardingStoretimed out'))
            }, 1000 * 25)
        }),
    ])
}

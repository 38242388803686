import { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { matchSorter } from 'match-sorter'

import { useHotKey, useListNavigation } from '@laserfocus/ui/util-keyboard'
import { CommandLine } from '@laserfocus/ui/beam'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { ContactProps, isContact, isOpportunity, OpportunityProps } from '@laserfocus/shared/models'

import { StepComponentProps, StaticStepState } from '../step-component-types'
import { useSelectOnMount } from '../useSelectOnMount'

export interface StepSelectOppContactState extends StaticStepState {
    inputValue: string
    selectedIndex: number
    WhatId?: string | undefined
    WhoId?: string | undefined
}

type StepSelectOppContactProps = StepComponentProps<StepSelectOppContactState> & {
    options: OpportunityProps[] | ContactProps[]
    searchPlaceholder: string
}

export const StepSelectOppContact = observer(function StepSelectParent({
    stepState,
    submit,
    options,
    searchPlaceholder,
}: StepSelectOppContactProps) {
    const [inputValue, setInputValue] = useState(stepState?.inputValue || '')
    const [selectedIndex, setSelectedIndex] = useState(stepState?.selectedIndex || 0)

    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)

    useSelectOnMount(inputRef)

    const sortedOptions = useMemo(
        () => matchSorter<OpportunityProps | ContactProps>(options, inputValue, { keys: ['Name'] }),
        [options, inputValue]
    )

    function handleSubmit(index: number) {
        const optionToSubmit = sortedOptions[index]

        if (!optionToSubmit) {
            return
        }

        submit({
            breadCrumbLabel: optionToSubmit.Name!,
            inputValue,
            selectedIndex,
            ...getObjectIds(optionToSubmit),
        })
    }

    useListNavigation(setSelectedIndex, sortedOptions.length)
    useHotKey(
        'enter',
        (event) => {
            event.preventDefault()
            handleSubmit(selectedIndex)
        },
        { global: true }
    )

    return (
        <>
            <CommandLine.Input
                ref={inputRef}
                placeholder={searchPlaceholder}
                aria-label={searchPlaceholder}
                value={inputValue}
                onChange={(event) => {
                    setInputValue(event.target.value)
                    setSelectedIndex(0)
                }}
            />
            {sortedOptions.length > 0 && (
                <CommandLine.Content>
                    {sortedOptions.map((oppOrContact, index) => {
                        return (
                            <CommandLine.Option
                                key={oppOrContact.Id}
                                title={oppOrContact.Name!}
                                onClick={() => handleSubmit(index)}
                                isSelected={index === selectedIndex}
                                select={() => setSelectedIndex(index)}
                            />
                        )
                    })}
                </CommandLine.Content>
            )}
        </>
    )
})

function getObjectIds(oppOrContact: OpportunityProps | ContactProps) {
    if (isOpportunity(oppOrContact.Id)) {
        return {
            WhatId: oppOrContact.Id || oppOrContact.AccountId,
        }
    } else if (isContact(oppOrContact.Id)) {
        return {
            WhoId: oppOrContact.Id,
        }
    }
    return {}
}

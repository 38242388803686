import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { LockOutlinedIcon } from '@laserfocus/ui/icons'
import { Button } from '@laserfocus/ui/beam'
import { UserSubscription } from '@laserfocus/shared/models'

import { ProPlanModal } from '../PlanModal/ProPlanModal'
import { TeamPlanModal } from '../PlanModal/TeamPlanModal'

export type LockedFeatureOverlayProps = {
    children: React.ReactNode
    isLocked: boolean
    subscription?: UserSubscription | null
    plan?: 'team' | 'pro'
    lockedFeature: string
    lockedClassName?: string
    hideOverlay?: boolean
    onModalClose?: () => void
}

export function LockedFeatureOverlay({
    isLocked,
    lockedFeature,
    children,
    subscription,
    lockedClassName,
    hideOverlay,
    onModalClose,
    plan = 'pro',
}: LockedFeatureOverlayProps) {
    const [isPlanOpen, setIsPlanOpen] = useState(false)

    useEffect(() => {
        if (isLocked) {
            if (typeof (document as any).activeElement?.blur === 'function') {
                ;(document as any).activeElement?.blur()
            }
        }
    }, [isLocked])
    return (
        <div className="relative">
            {isLocked && subscription && (
                <div
                    className={twMerge(
                        'bg-grey-700/5 absolute w-full h-full top-0 left-0 z-10 backdrop-blur-sm rounded-md flex justify-center items-center flex-col',
                        lockedClassName
                    )}
                >
                    <LockOutlinedIcon className="w-10 h-10" />
                    <div className="mt-4 mx-4">
                        Upgrade to{' '}
                        <span className="text-blue-500 font-semibold uppercase">{plan}</span> to
                        unlock {lockedFeature}
                    </div>
                    <Button
                        className="mt-4"
                        onClick={() => {
                            setIsPlanOpen(true)
                        }}
                    >
                        Unlock Feature
                    </Button>
                    {plan === 'team' ? (
                        <TeamPlanModal show={isPlanOpen} closeModal={() => setIsPlanOpen(false)} />
                    ) : (
                        <ProPlanModal
                            show={isPlanOpen}
                            openModal={() => setIsPlanOpen(true)}
                            closeModal={() => {
                                setIsPlanOpen(false)
                            }}
                            subscription={subscription}
                            hideOverlay={hideOverlay}
                            onClose={onModalClose}
                        />
                    )}
                </div>
            )}
            <div className={clsx(isLocked && 'p-2')}>{children}</div>
        </div>
    )
}

import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { toast } from '@laserfocus/ui/beam'
import { useRootStore } from '@laserfocus/client/root-store-context'
import {
    isMutationErrorResponse,
    MutationErrorResponse,
    ContactModel,
} from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { serializeContactInput } from '@laserfocus/client/model'
import { logger } from '@laserfocus/ui/logger'

import { useContacts, useCurrentContact } from '../modules/PersonContext'

import { CreateObjectModal } from './CreateObjectModal'

interface CreateContactModalProps {
    closeModal(): void
}

export const CreateContactModal = observer(function CreateContactModal({
    closeModal,
}: CreateContactModalProps) {
    const { contactStore } = useRootStore()
    const contact = useCurrentContact()
    const { setCurrentContactId } = useContacts()
    const [isSubmitting, setIsSubmitting] = useState(false)

    function handleSubmit(fieldsToSubmit: Record<string, any>) {
        setIsSubmitting(true)

        const asDTO = serializeContactInput(fieldsToSubmit as any)

        return contactStore
            .create(asDTO)
            .then(
                (maybeContact: MutationErrorResponse | ContactModel) => {
                    if (isMutationErrorResponse(maybeContact)) {
                        logger.warn(maybeContact)
                        toast.error({ title: 'Something went wrong' })
                        return maybeContact
                    } else {
                        toast.success({
                            title: `Successfully created contact ${
                                (maybeContact as ContactModel).Name
                            }`,
                        })
                        closeModal()
                        setCurrentContactId && setCurrentContactId(maybeContact.Id)

                        /**
                         * This is temporary until we create objects via replicache.
                         * It requires migrating the CreateObjectModal to RC + use Failed Transactions
                         */
                        const replicache = getClient()
                        replicache.pullModules(['contactResult'])
                    }
                },
                (e: any) => {
                    logger.error(e)
                    toast.error({ title: 'Something went wrong' })
                }
            )
            .finally(() => setIsSubmitting(false))
    }

    return (
        <CreateObjectModal
            sobjectType="Contact"
            sobjectRecordTypeId={contact?.RecordTypeId || undefined}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            closeModal={closeModal}
        />
    )
})

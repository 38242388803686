import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Card, EmptyState } from '@laserfocus/ui/beam'

import { useAllColumns } from '../useTable'

import { ColumnFilterButton } from './TableHead/ColumnFilterButton'
import { Column } from './table-context'

export function TableNoResults() {
    return (
        <EmptyState
            size="medium"
            emojis={['🌵', '🏜', '🐪']}
            title="There is nothing in here."
            subtitle={
                <>
                    Looks like you’re done with your work.
                    <br />
                    Or you would have to adjust your filters:
                </>
            }
        >
            <EmptyFilterEdit />
        </EmptyState>
    )
}

const EmptyFilterEdit = observer(function EmptyFilterEdit() {
    const allColumns = useAllColumns().map(({ salesObjectType, columns }) => ({
        label: salesObjectType,
        columns,
    }))

    const activeFilters = allColumns.flatMap(({ columns }) =>
        columns.filter((c) => c.filterCondition)
    )

    return (
        <Card className="flex flex-col mt-4">
            <div className="border-b border-grey-700/10 p-3 w-full">
                <h3 className="font-semibold text-grey-700 text-left">Your current filters</h3>
            </div>
            <div className="p-3 grid grid-flow-row gap-2">
                {activeFilters.map((f) => (
                    <div key={f.key} className="flex flex-row items-center justify-between">
                        <div>{f.label}</div>
                        <EmptyColumnFilterButton column={f} />
                    </div>
                ))}
            </div>
        </Card>
    )
})

function EmptyColumnFilterButton({ column }: { column: Column }) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <ColumnFilterButton
            isFilterDropdownOpen={isOpen}
            setIsFilterDropdownOpen={setIsOpen}
            column={column}
        />
    )
}

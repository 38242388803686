const HiddenAll = ['CloneSourceId']

export const DefaultLeadPinned = [
    'Company',
    'FirstName',
    'LastName',
    'Title',
    'Phone',
    'Email',
    'Website',
    'Status',
]
export const DefaultAccountPinned = ['Name', 'Website']
export const DefaultContactPinned = ['FirstName', 'LastName', 'Title', 'Phone']
export const DefaultOpportunityPinned = ['Name', 'StageName', 'Amount', 'CloseDate', 'NextStep']

export const HiddenOpportunityFields = [
    ...HiddenAll,
    'Id',
    'IsDeleted',
    'AccountId',
    'IsPrivate',
    'TotalOpportunityQuantity',
    'Type',
    'IsClosed',
    'IsWon',
    'ForecastCategory',
    'HasOpportunityLineItem',
    'Pricebook2Id',
    'SystemModstamp',
    'LastReferencedDate',
    'HasOpenActivity',
    'HasOverdueTask',
    'DeliveryInstallationStatus__c',
    'TrackingNumber__c',
    'OrderNumber__c',
    'CurrentGenerators__c',
    'MainCompetitors__c',
    'LastAmountChangedHistoryId',
    'LastCloseDateChangedHistoryId',
]
export const HiddenOpportunityRightSide = [
    ...HiddenOpportunityFields,
    'LeadSource',
    'ForecastCategory',
    'CampaignId',
    'FiscalQuarter',
    'FiscalYear',
    'Fiscal',
]

export const HiddenOpportunityFilter = [...HiddenOpportunityFields, 'ContactId']

export const OpportunityFieldOrder = [
    'Name',
    'NextStep',
    'StageName',
    'CloseDate',
    'Probability',
    'ExpectedRevenue',
    'Amount',
    //
    'NextActivity.Subject',
    'NextActivity.Date',
    //
    'Description',
    'CreatedDate',
    'LastModifiedDate',
    'LastActivityDate',
    'LastViewedDate',
]

const HiddenAccountFields = [
    ...HiddenAll,
    'Id',
    'IsDeleted',
    'MasterRecordId',
    'Type',
    'RecordTypeId',
    'ParentId',
    'BillingLatitude',
    'BillingLongitude',
    'BillingGeocodeAccuracy',
    'ShippingLatitude',
    'ShippingLongitude',
    'ShippingGeocodeAccuracy',
    'AccountNumber',
    'PhotoUrl',
    'Sic',
    'Ownership',
    'TickerSymbol',
    'Site',
    'SystemModstamp',
    'LastReferencedDate',
    'Jigsaw',
    'JigsawCompanyId',
    'CleanStatus',
    'AccountSource',
    'DunsNumber',
    'Tradestyle',
    'NaicsCode',
    'NaicsDesc',
    'YearStarted',
    'SicDesc',
    'DandbCompanyId',
    'OperatingHoursId',
    'CustomerPriority__c',
    'SLA__c',
    'Active__c',
    'NumberofLocations__c',
    'UpsellOpportunity__c',
    'SLASerialNumber__c',
    'SLAExpirationDate__c',
]

export const HiddenAccountRightSide = [...HiddenAccountFields, 'BillingAddress', 'ShippingAddress']

export const HiddenAccountFilter = ['Fax', 'LastViewedDate']

export const AccountFieldOrder = [
    'Name',
    'Website',
    //
    'NextActivity.Subject',
    'NextActivity.Date',
    //
    'Phone',
    'Fax',
    'Industry',
    'AnnualRevenue',
    'NumberOfEmployees',
    'BillingStreet',
    'BillingCity',
    'BillingState',
    'BillingPostalCode',
    'BillingCountry',
    'ShippingStreet',
    'ShippingCity',
    'ShippingState',
    'ShippingPostalCode',
    'ShippingCountry',
    'Description',
    'Rating',
    'CreatedDate',
    'LastModifiedDate',
    'LastActivityDate',
    'LastViewedDate',
]

export const ContactFieldOrder = [
    'Salutation',
    'Name',
    'FirstName',
    'LastName',
    'Title',
    'Department',
    'Email',
    'Description',
    'Phone',
    'MobilePhone',
    'Fax',
    'HomePhone',
    'OtherPhone',
    'MailingStreet',
    'MailingCity',
    'MailingState',
    'MailingPostalCode',
    'MailingCountry',
    'LeadSource',
    'CreatedDate',
    'LastModifiedDate',
    'LastActivityDate',
    'LastViewedDate',
]
const HiddenContactFields = [
    ...HiddenAll,
    'Id',
    //
    'NextActivity.Subject',
    'NextActivity.Date',
    //
    'IsDeleted',
    'MasterRecordId',
    'AccountId',
    'OtherStreet',
    'OtherCity',
    'OtherState',
    'OtherPostalCode',
    'OtherCountry',
    'OtherLatitude',
    'OtherLongitude',
    'OtherGeocodeAccuracy',
    'OtherAddress',
    'MailingLatitude',
    'MailingLongitude',
    'MailingGeocodeAccuracy',
    'AssistantPhone',
    'ReportsToId',
    'AssistantName',
    'SystemModstamp',
    'LastCURequestDate',
    'LastCUUpdateDate',
    'LastReferencedDate',
    'EmailBouncedReason',
    'EmailBouncedDate',
    'IsEmailBounced',
    'PhotoUrl',
    'Jigsaw',
    'JigsawContactId',
    'CleanStatus',
    'IndividualId',
    'Level__c',
    'Languages__c',
]
export const HiddenContactRightSide = [...HiddenContactFields, 'MailingAddress', 'Birthdate']

export const HiddenContactFilter = [
    'Fax',
    'LastModifiedDate',
    'LastViewedDate',
    'LeadSource',
    'MobilePhone',
]

export const LeadFieldOrder = [
    'Company',
    'Status',
    'Website',
    'Name',
    //
    'NextActivity.Subject',
    'NextActivity.Date',
    //
    'Salutation',
    'FirstName',
    'LastName',
    'Title',
    'Email',
    'Phone',
    'MobilePhone',
    'Fax',
    'Street',
    'City',
    'State',
    'PostalCode',
    'Country',
    'Industry',
    'Rating',
    'AnnualRevenue',
    'NumberOfEmployees',
    'Description',
    'LeadSource',
    'IsUnreadByOwner',
    'CreatedDate',
    'LastModifiedDate',
    'LastActivityDate',
    'LastViewedDate',
]

const HiddenLeadFields = [
    ...HiddenAll,
    'Id',
    'IsDeleted',
    'MasterRecordId',
    'RecordTypeId',
    'Latitude',
    'Longitude',
    'GeocodeAccuracy',
    'PhotoUrl',
    'IsConverted',
    'ConvertedDate',
    'ConvertedAccountId',
    'ConvertedContactId',
    'ConvertedOpportunityId',
    'SystemModstamp',
    'LastReferencedDate',
    'Jigsaw',
    'JigsawContactId',
    'CleanStatus',
    'CompanyDunsNumber',
    'DandbCompanyId',
    'EmailBouncedReason',
    'EmailBouncedDate',
    'IndividualId',
    'ProductInterest__c',
]

export const HiddenLeadRightSide = [
    ...HiddenLeadFields,
    'Address',
    'SICCode__c',
    'Primary__c',
    'CurrentGenerators__c',
    'NumberofLocations__c',
]

export const HiddenLeadFilter = [...HiddenLeadFields, 'AnnualRevenue', 'Fax', 'IsUnreadByOwner']

export const HiddenByObject = {
    Lead: HiddenLeadFields,
    Account: HiddenAccountFields,
    Opportunity: HiddenOpportunityFields,
    Contact: HiddenContactFields,
}

export const OrderByObject = {
    Lead: LeadFieldOrder,
    Account: AccountFieldOrder,
    Opportunity: OpportunityFieldOrder,
    Contact: ContactFieldOrder,
}

type ViewObject = 'Lead' | 'Account' | 'Opportunity' | 'Contact'

export function isDefaultHidden(objectName: ViewObject, fieldName: string) {
    const hidden = HiddenByObject[objectName]
    if (!hidden) {
        return false
    }
    return hidden.includes(fieldName)
}

export const HIDDEN_ORDER_INDEX = 9999
export const UNKNOWN_ORDER_BASE = 1000

interface SortableField {
    name: string
}
export function makeSorter(objectName: ViewObject) {
    return function sorter(a: SortableField, b: SortableField) {
        const aVal = defaultOrder(objectName, a.name)
        const bVal = defaultOrder(objectName, b.name)
        return aVal - bVal
    }
}

export function defaultOrder(objectName: ViewObject, fieldName: string) {
    if (isDefaultHidden(objectName, fieldName)) {
        return HIDDEN_ORDER_INDEX
    }
    const order = OrderByObject[objectName]
    if (!order) {
        return UNKNOWN_ORDER_BASE
    }
    const index = order.indexOf(fieldName)
    if (index === -1) {
        return UNKNOWN_ORDER_BASE
    }
    return index + 10
}

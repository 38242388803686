import { observer } from 'mobx-react-lite'

import { SwitchCell as BaseSwitchCell } from '@laserfocus/client/ui-shared-datagrid'

import { TableCellProps } from './TableCell'

export const SwitchCell = observer(function SwitchCell({ salesObject, column }: TableCellProps) {
    const checked = (column.getValue(salesObject) as boolean | null) || false
    const isEditable = column.isEditable(salesObject)

    return (
        <BaseSwitchCell
            value={checked}
            readOnly={!isEditable}
            name={column.key}
            updateValue={(value) => column.updateValue(salesObject, value)}
        />
    )
})

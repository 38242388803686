import { BaseError, ErrorBehaviour } from './base-error'

export class AssertError<ExtensionType extends Record<string, any> = Record<string, any>>
    extends BaseError<ExtensionType>
    implements ErrorBehaviour
{
    // overwritten by the constructor anyways
    isOperational: boolean | undefined = false
    constructor(
        message: string,
        extensions?: ExtensionType,
        behaviour: ErrorBehaviour = { isOperational: false }
    ) {
        super(message, extensions)
        this.isOperational = behaviour.isOperational
    }
    get code(): string | undefined {
        return this.extensions?.['code']
    }
}

export function bail(
    message: string,
    behaviour: ErrorBehaviour = { isOperational: false },
    extensions?: Record<string, any>
): never {
    throw new AssertError(message, extensions, behaviour)
}

export function assert(
    check: any,
    message: string,
    behaviour: ErrorBehaviour = { isOperational: false },
    extensions?: Record<string, any>
): asserts check {
    if (!Boolean(check)) {
        throw new AssertError(message, extensions, behaviour)
    }
}

export function assertEqual<T>(
    actual: T,
    expected: T,
    message: string,
    behaviour: ErrorBehaviour = { isOperational: false },
    extensions: Record<string, any> = {}
) {
    if (actual !== expected) {
        const fullMessage = `${message}: {actual} !== {expected}`
        const fullExtensions = {
            ...extensions,
            actual,
            expected,
        }
        throw new AssertError(fullMessage, fullExtensions, behaviour)
    }
}

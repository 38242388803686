import { z } from '@laserfocus/shared/decoder'

import type { WithType, Identifier } from '../base/model.types'

/**
 * https://developer.salesforce.com/docs/atlas.en-us.api.meta/api/sforce_api_calls_convertlead.htm
 */
export const ConvertLeadSchema = z.object({
    convertedStatus: z.string(),
    leadId: z.string(),
    accountId: z.string().optional(),
    contactId: z.string().optional(),
    doNotCreateOpportunity: z.boolean().optional(),
    opportunityName: z.string().optional(),
    overwriteLeadSource: z.boolean().optional(),
    ownerId: z.string().optional(),
    sendNotificationEmail: z.boolean().optional(),
    // relatedPersonAccountId: z.string().optional()
    // relatedPersonAccountRecord: z.any().optional()
})

export type ConvertLead = z.infer<typeof ConvertLeadSchema>

type Difference = 'DIFFERENT' | 'SAME' | 'NULL'
type MatchRecord = {
    fieldDiffs: Array<{ difference: Difference; name: string }>
    matchConfidence: number
    record: WithType<string> & Identifier & Record<string, string>
}

export type ConvertError = {
    message: string
    errorCode: 'DUPLICATES_DETECTED'
    matchRecords: MatchRecord[]
}

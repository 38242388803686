import { isLead, isAccount } from '@laserfocus/shared/models'

import WrongIdPage from './WrongIdPage'
import AccountContainer from './AccountContainer'
import LeadContainer from './LeadContainer'
import { ConvertLeadContextProvider } from './ConvertLeadModal/ConvertLeadContext'

type PersonPageContainerProps = {
    personId: string
}

export function PersonPageContainer({ personId }: PersonPageContainerProps) {
    if (!isLead(personId) && !isAccount(personId)) {
        return <WrongIdPage />
    }
    if (isLead(personId)) {
        // Key is just for remounting the tree and starting with a clean state
        return (
            <ConvertLeadContextProvider>
                <LeadContainer personId={personId} key={`lead-${personId}`} />
            </ConvertLeadContextProvider>
        )
    }
    return <AccountContainer accountId={personId} key={`account-${personId}`} />
}

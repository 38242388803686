import { omit } from 'lodash'
import {
    createContext,
    MouseEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'

import {
    countActivity,
    useActivityCounterForUserBetween,
} from '@laserfocus/client/data-access-shared'
import { useUserId } from '@laserfocus/client/feature-auth'
import { sumActivityCounters } from '@laserfocus/shared/models'
import type { EverboardingHintName, CountedMutationName } from '@laserfocus/shared/models'
import { usePrevious } from '@laserfocus/ui/hooks'

export type { EverboardingHintName }

export type EverboardingCounters = Partial<Record<CountedMutationName, number>>

export type EverboardingHintConfig = {
    name: EverboardingHintName
    shouldShow: (
        counts: EverboardingCounters,
        previousHint?: EverboardingHintConfig | null
    ) => boolean
}

type EverboardingContextType = {
    register(hint: EverboardingHintConfig): () => void
    hints: Partial<Record<EverboardingHintName, EverboardingHintConfig>>
    currentHint?: EverboardingHintConfig
    counters: EverboardingCounters
    didLoad: boolean
}

const EverboardingContext = createContext<EverboardingContextType>({
    register: () => () => {},
    hints: {},
    counters: {},
    didLoad: false,
})

export function EverboardingContextProvider({ children }: { children: React.ReactNode }) {
    const [hints, setHints] = useState<
        Partial<Record<EverboardingHintName, EverboardingHintConfig>>
    >({})
    const [previousHint, setPreviousHint] = useState<EverboardingHintConfig | null>(null)
    const { sum: counters, itemCount } = useCounters()

    const register = useCallback(
        (hint: EverboardingHintConfig) => {
            setHints(
                (
                    existing: Partial<Record<EverboardingHintName, EverboardingHintConfig>>
                ): Partial<Record<EverboardingHintName, EverboardingHintConfig>> => ({
                    ...existing,
                    [hint.name]: hint,
                })
            )
            return () => {
                setHints((existing) => omit(existing, hint.name))
                if (previousHint?.name === hint.name) {
                    setPreviousHint(null)
                }
            }
        },
        [previousHint?.name]
    )
    /**
     * @TODO:
     * Currently we are not filtering for hints that are actually rendered. So a not rendered hint could be blocking
     * another hint, that is actually rendered
     */
    const currentHint = useMemo(() => {
        return Object.values(hints).find(
            (hint) => hint.shouldShow(counters, previousHint) && !counters[hint.name]
        )
    }, [hints, counters, previousHint])
    const hintInPreviousRender = usePrevious(currentHint, null)
    useEffect(() => {
        if (
            hintInPreviousRender &&
            currentHint &&
            currentHint.name !== hintInPreviousRender.name &&
            hints[hintInPreviousRender.name]
        ) {
            setPreviousHint(hintInPreviousRender ?? null)
        }
    }, [currentHint, hintInPreviousRender, hints])
    const didLoad = Boolean(itemCount)
    const value = useMemo(
        () => ({ register, hints, counters, didLoad, currentHint }),
        [register, hints, counters, didLoad, currentHint]
    )
    return <EverboardingContext.Provider value={value}>{children}</EverboardingContext.Provider>
}

export function useEverboardingContext() {
    return useContext(EverboardingContext)
}

function useCounters() {
    const userId = useUserId()
    const counterItems = useActivityCounterForUserBetween(userId)
    return {
        sum: useMemo(() => sumActivityCounters(counterItems), [counterItems]),
        itemCount: counterItems.length,
    }
}

export function useRegisterEverboardingHint(hint: EverboardingHintConfig) {
    const { register } = useContext(EverboardingContext)

    useEffect(() => {
        return register(hint)
    }, [hint, register])
}

export function useEverboardingHint(name: EverboardingHintName): {
    trigger: (e?: MouseEvent) => void
    isActive: boolean
} {
    const { currentHint } = useContext(EverboardingContext)

    return useMemo(() => {
        if (!currentHint || currentHint.name !== name) {
            return { trigger: () => false, isActive: false }
        }
        const trigger = (e?: MouseEvent) => {
            if (currentHint.name) {
                countActivity(currentHint.name)
            }
        }
        return {
            trigger,
            isActive: true,
        }
    }, [currentHint, name])
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function PinFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M8 3a1 1 0 000 2v4.76l-1.9 3.8A1 1 0 006 14v2a1 1 0 001 1h3.5v3.5c0 .55.5 1.5 1.5 1.5s1.5-.95 1.5-1.5V17H17a1 1 0 001-1v-2a1 1 0 00-.1-.45L16 9.76V5a1 1 0 100-2H8z" />
        </SvgIcon>
    )
}

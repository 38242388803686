import { ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'

import { getClient } from '@laserfocus/client/replicache'
import { PinnedActivities, Prefix } from '@laserfocus/shared/models'

export function useRootPinned(rootId: string) {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            const pinned = (await tx.get(
                [Prefix.PinnedActivities, rootId].join('/')
            )) as PinnedActivities | null

            return {
                pinnedActivities: pinned?.activities || [],
                pinnedNotes: pinned?.notes || [],
            }
        },
        {
            pinnedActivities: [],
            pinnedNotes: [],
        },
        [rootId]
    )
}

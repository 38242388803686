import { partition, keyBy, isEqual, omit, pickBy } from 'lodash'

import { Stack, OrgStack, UserStack } from '@laserfocus/shared/models'

export function combineUserOrgStacks(stacks: Array<UserStack | OrgStack>): Stack[] {
    const [user, org] = partition(stacks, (fg) => fg.__typename === 'UserStack') as [
        UserStack[],
        OrgStack[]
    ]

    const userById: Record<string, UserStack> = keyBy(user, 'id')
    const combined: Record<string, Stack> = {}

    org.forEach((fg) => {
        const userStack = userById[fg.id]
        if (userStack) {
            combined[fg.id] = combineStacks(fg, userStack)
        } else if (!fg.deactivated) {
            combined[fg.id] = fg
        }
    })

    user.forEach((fg) => {
        if (!combined[fg.id]) {
            combined[fg.id] = fg
        }
    })

    return Object.values(combined).map(cleanStack)
}

export function combineStacks(orgStack: OrgStack, userStack: UserStack): Stack {
    return cleanStack({
        isExtendedFromOrg: isModifiedUserStack(userStack),
        ...orgStack,
        ...userStack,
        filterConditions: {
            ...orgStack.filterConditions,
            ...userStack.filterConditions,
        },
        columnAggregates: {
            ...orgStack.columnAggregates,
            ...userStack.columnAggregates,
        },
        columnWidths: {
            ...orgStack.columnWidths,
            ...userStack.columnWidths,
        },
        visibleColumnMap: {
            ...orgStack.visibleColumnMap,
            ...userStack.visibleColumnMap,
        },
        timing: userStack.timing || orgStack.timing,
        version: (orgStack.version || 0) + (userStack.version || 0),
    })
}

export function cleanStack(stack: Stack) {
    return {
        ...stack,
        visibleColumnMap: pickBy(stack.visibleColumnMap),
    }
}

function isModifiedUserStack(stack: UserStack): boolean {
    return Boolean(stack.version)
}

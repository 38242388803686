import {
    ClientStateNotFoundResponse,
    PullerResult,
    PullResponse,
    PullResponseOK,
    Replicache,
    ReplicacheOptions,
    TEST_LICENSE_KEY,
} from 'replicache'
import type { MutatorDefs } from 'replicache'

import { Config } from '@laserfocus/shared/config-env'
import { getToken, redirectToLogin, reauthenticate } from '@laserfocus/client/data-access-apollo'
import { logger } from '@laserfocus/ui/logger'
import { ReplicacheSchemaVersion, QuerySchedule } from '@laserfocus/shared/models'

import { SentryLogSink } from './log-sink'
import { splitMutations } from './split-mutations'
import { copyRequestWithQuery } from './request-util'

const { REPLICACHE_ENDPOINT_PATH } = Config

export interface ModuleReplicache<MD extends MutatorDefs = {}> extends Replicache<MD> {
    pullModules: (modules: string[]) => void
}

let client: ModuleReplicache
let didReauthenticate = false

export function makeClient<MD extends MutatorDefs>(
    userId: string,
    mutators: MD,
    shouldQuerySpecific?: (mutations: any[]) => QuerySchedule,
    paginateResult?: (resp: PullerResult) => Promise<PullerResult>
) {
    if (!userId) {
        throw new Error("Can't instantiate replicache without a userid")
    }

    if (client) {
        return client
    }

    const isTest =
        Boolean((window as any).Cypress || process.env.NODE_ENV === 'test') ||
        window.location.host === 'localhost:3002'

    const dbName = `lf-${userId}`

    let nextModuleQuery: string[] | undefined
    let includeNextModuleQuery: string[] | undefined
    let lastPull: Date

    const options: ReplicacheOptions<MD> = {
        licenseKey:
            (isTest ? TEST_LICENSE_KEY : Config.REPLICACHE_LICENSE_KEY!) || TEST_LICENSE_KEY,
        name: dbName,
        pullURL: `${REPLICACHE_ENDPOINT_PATH}/replicache-pull`,
        pushURL: `${REPLICACHE_ENDPOINT_PATH}/replicache-push`,
        auth: getToken()!,
        schemaVersion: ReplicacheSchemaVersion,
        logLevel: 'error',
        mutators,
        logSinks: [SentryLogSink], //[Config.isDev ? consoleLogSink : SentryLogSink],
    }
    const rc = new Replicache<MD>(options) as ModuleReplicache<MD>
    rc.pullModules = (modules: string[]) => {
        nextModuleQuery = modules
        return rc.pull()
    }
    rc.getAuth = reauthenticateWithFlag

    if (process.env.NODE_ENV === 'development') {
        rc.pullInterval = null
    }

    // Make replicache pull after sending mutations
    const defaultPusher = rc.pusher

    rc.pusher = async (request: Request) => {
        const result = await splitMutations(request.clone(), defaultPusher)
        if (shouldQuerySpecific) {
            try {
                const mutations = await getMutations(request.clone())
                const specificQuery = shouldQuerySpecific(mutations)
                if (specificQuery.current === 'ALL') {
                    rc.pull()
                } else if (specificQuery.current === 'SKIP') {
                    includeNextModuleQuery = specificQuery.next
                    // NOTHING
                } else if (specificQuery.current.length) {
                    const nextQuery = nextModuleQuery
                        ? [...new Set([...nextModuleQuery, ...specificQuery.current])]
                        : specificQuery.current
                    rc.pullModules(nextQuery)
                } else {
                    rc.pull()
                }
            } catch (e: any) {
                logger.error(e)
            }
        } else {
            rc.pull()
        }

        return result
    }

    const defaultPuller = rc.puller
    rc.puller = async (request: Request) => {
        lastPull = new Date()
        let finalRequest = request
        const queryModule = nextModuleQuery
            ? [...nextModuleQuery, ...(includeNextModuleQuery || [])]
            : undefined
        if (queryModule) {
            const r2 = await copyRequestWithQuery(request, { modules: queryModule })
            finalRequest = r2
        } else {
            finalRequest = request.clone()
        }

        if (queryModule) {
            nextModuleQuery = undefined
            includeNextModuleQuery = undefined
        }

        storeCurrentCookie(finalRequest)
        const result = await defaultPuller(finalRequest)

        if (didReauthenticate) {
            if (result.httpRequestInfo.httpStatusCode === 401) {
                redirectToLogin()
            } else {
                didReauthenticate = false
            }
        }

        const paginatedResponse = paginateResult ? await paginateResult(result) : result

        const response = paginatedResponse.response

        if (response && isRCOKReponse(response)) {
            const cookie = response?.cookie as Record<string, string | boolean | number> | undefined
            if (cookie?.hasMore) {
                if (Array.isArray(cookie.hasMore)) {
                    if (cookie.hasMore.includes('ALL')) {
                        rc.pull()
                    } else {
                        const modules = cookie.hasMore as string[]
                        rc.pullModules(modules)
                    }
                } else {
                    rc.pull()
                }
            }
        }

        return paginatedResponse
    }

    document.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'hidden') {
            // rc.pullInterval = 30 * 60 * 1000
            rc.pullInterval = null
        } else if (document.visibilityState === 'visible') {
            const fiveMinutes = 5 * 60 * 1000
            rc.pullInterval = fiveMinutes
            const fiveMinutesAgo = Date.now() - fiveMinutes
            if (lastPull?.getTime() < fiveMinutesAgo) {
                rc.pull()
            }
        }
    })

    rc.query(async (tx) => {
        const identity = await tx.get('identity')
        if (identity && (identity as any).Id !== userId) {
            logger.error(
                `Replicache instantiated with a different userId then in the identity:${
                    (identity as any).Id
                }. Provided: ${userId}`
            )
        }
    }).then(() => {})

    setClient(rc)
    return rc
}

async function storeCurrentCookie(req: Request) {
    const body = await req.clone().json()
    window.$cookie = body
}

export function getClient<MD extends MutatorDefs = {}>(options?: {
    ignoreMissingClient?: boolean
}) {
    try {
        if (window.opener && window.opener.$replicache) {
            return window.opener.$replicache as ModuleReplicache<MD>
        }
    } catch (e: unknown) {
        // This seems to be triggering Blocked a frame with origin "https://app.laserfocus.io" from accessing a cross-origin frame.
        // So just skipping that scenario for now
        logger.warn(e)
    }

    if (!client && !options?.ignoreMissingClient) {
        throw new Error(
            "Can't access the client before the user is not authenticated and the client was initialized"
        )
    }
    return client as ModuleReplicache<MD>
}

async function reauthenticateWithFlag(): Promise<string> {
    const accessToken = await reauthenticate()
    didReauthenticate = true
    // In case we did not get an accesstoken, we redirected already
    return accessToken!
}

function setClient<MD extends MutatorDefs = {}>(rep: ModuleReplicache<MD>) {
    client = rep
    window.$replicache = rep
}

async function getMutations(req: Request) {
    const body = await req.json()
    if (body) {
        return body.mutations
    }
}

function isRCOKReponse(response: PullResponse): response is PullResponseOK {
    return !(response as ClientStateNotFoundResponse).error
}

declare global {
    interface Window {
        $replicache: Replicache<{}>
        $cookie: any
    }
}

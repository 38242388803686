import { Control, useController, useFormContext, UseFormWatch } from 'react-hook-form'

import { AccountModel } from '@laserfocus/shared/models'

import { ConvertLeadFormState } from './ConvertLeadModal'
import { ModeRadioGroup } from './ModeRadioGroup'
import { SObjectSearchSelect } from './SObjectSelect'
// import { useFields, MetadataField } from './MetadataField'
import { DuplicateAccountCard } from './DuplicatesSection'

export function AccountSectionBody({
    control,
    watch,
    duplicates,
}: {
    control: Control<ConvertLeadFormState>
    watch: UseFormWatch<ConvertLeadFormState>
    duplicates: AccountModel[]
}) {
    const { field } = useController({ control, name: 'accountCreateOrChoose' })
    const contactChooseOrCreate = watch('contactCreateOrChoose')

    const tooltip =
        contactChooseOrCreate === 'choose'
            ? 'When providing an existing contact, also an Account needs to be provided'
            : undefined

    return (
        <div className="grid gap-4 py-4 px-8">
            <ModeRadioGroup
                value={field.value}
                onChange={field.onChange}
                disabled={Boolean(duplicates.length) || contactChooseOrCreate === 'choose'}
                options={[
                    { value: 'create', label: 'Create new' },
                    { value: 'choose', label: 'Choose existing' },
                ]}
                tooltip={tooltip}
            />
            {/* {field.value === 'create' && <CreateAccountSection control={control} />} */}

            {field.value === 'choose' && (
                <>
                    <SObjectSearchSelect
                        control={control}
                        name="existingAccount"
                        label="Account"
                        sobject="Account"
                        required="You need to select the account that this should be attached to"
                    />
                    <DuplicateAccountSelect duplicates={duplicates} />
                </>
            )}
        </div>
    )
}

function DuplicateAccountSelect({ duplicates }: { duplicates: AccountModel[] }) {
    const { setValue, watch } = useFormContext<ConvertLeadFormState>()
    const existingAccount = watch('existingAccount')
    if (duplicates.length > 1) {
        return (
            <>
                {duplicates.map((duplicate) => (
                    <DuplicateAccountCard
                        key={duplicate.Id}
                        record={duplicate}
                        onSelect={() => {
                            setValue('existingAccount', {
                                Id: duplicate.Id,
                                Name: duplicate.Name,
                            })
                        }}
                        selected={existingAccount && existingAccount.Id === duplicate.Id}
                    />
                ))}
            </>
        )
    }
    return null
}

// function CreateAccountSection({ control }: { control: Control<ConvertLeadFormState> }) {
//     const fields = useFields('Account', ['Name', 'OwnerId'])
//     return (
//         <div className="grid gap-2 grid-cols-[1fr,2fr]">
//             {fields.map((field) => (
//                 <MetadataField
//                     key={field.fullName}
//                     control={control}
//                     fieldMetadata={field}
//                     name={`newAccount.${field.name}`}
//                 />
//             ))}
//         </div>
//     )
// }

import { toast as reactHotToast, ToastOptions } from 'react-hot-toast'

import { CloseOutlinedIcon, ExclamationFilledIcon } from '@laserfocus/ui/icons'

import { DropdownButton } from '../dropdowns/controls/DropdownButton'

type ReactHotToastMessage = Extract<Parameters<typeof reactHotToast>[0], Function>
type ToastInstance = Parameters<ReactHotToastMessage>[0]
interface Message {
    title: Exclude<React.ReactNode, null | undefined | boolean>
    description?: React.ReactNode
}

export function toast(message: Message) {
    return reactHotToast((toastInstance) => <Toast {...message} toastInstance={toastInstance} />)
}

toast.success = toastSuccess
toast.error = toastError
toast.warn = toastWarn

toast.custom = reactHotToast.custom
toast.dismiss = reactHotToast.dismiss

function toastSuccess(message: Message) {
    return reactHotToast.success((toastInstance) => (
        <Toast {...message} toastInstance={toastInstance} />
    ))
}

function toastError(message: Message) {
    return reactHotToast.error((toastInstance) => (
        <Toast {...message} toastInstance={toastInstance} />
    ))
}

function toastWarn(message: Message, options?: ToastOptions) {
    return reactHotToast.error(
        (toastInstance) => <Toast {...message} toastInstance={toastInstance} />,
        {
            icon: <ExclamationFilledIcon className="min-w-[1.5rem] w-6 h-6 text-yellow-300 mr-3" />,
            duration: 80000,
            ...options,
        }
    )
}

interface ToastProps extends Message {
    toastInstance: ToastInstance
}

function Toast({ title, description, toastInstance }: ToastProps) {
    // All elements need to be <span> before the fix for https://github.com/timolins/react-hot-toast/issues/5 is released
    return (
        <span
            data-testid={`toast-${toastInstance.type}`}
            // -mx-2.5: Compensating for react-hot-toast <Message> component which wraps Toast
            className="flex -mx-2.5 -my-1"
        >
            <span className="w-64 flex flex-col justify-center">
                <span className="block text-sm leading-[1.3]">{title}</span>
                {description && (
                    <span className="block pt-1 text-xs leading-[1.3] text-white/60">
                        {description}
                    </span>
                )}
            </span>

            <span>
                <DropdownButton
                    onClick={() => reactHotToast.dismiss(toastInstance.id)}
                    className="flex-none w-[unset] p-1 ml-3"
                >
                    <CloseOutlinedIcon className="w-4 h-4" />
                </DropdownButton>
            </span>
        </span>
    )
}

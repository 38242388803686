import { WriteTransaction } from 'replicache'
import { differenceInDays } from 'date-fns'

import { getClient } from '@laserfocus/client/replicache'
import {
    applyValidators,
    Prefix,
    SyncedRootsMutatorSchema,
    isLead,
    ContactModel,
    OpportunityModel,
    SyncedRecord,
    getPrefixForSobject,
    AccountId,
    isTruthy,
} from '@laserfocus/shared/models'
import { z } from '@laserfocus/shared/decoder'

import { getSobject } from '../sales/sobject-repository'

import { queryRootSyncState, RootSyncKeys } from './synced-root-model'

export async function ensureContactSynced(contactId: string, accountId: string) {
    const rep = getClient<typeof mutators>()

    const { accountContactSynced, contact } = await rep.query(async (tx) => {
        const [accountContactSynced, contact] = await Promise.all([
            tx.get([Prefix.SyncStatus, accountId, Prefix.Contact].join('/')) as Promise<
                SyncedRecord | undefined
            >,
            tx.get([Prefix.Contact, contactId].join('/')) as Promise<ContactModel | undefined>,
        ])

        return {
            accountContactSynced,
            contact,
        }
    })
    if (!contact && accountContactSynced?.didLoad) {
        return rep.mutate.visitRoot({ rootId: accountId })
    }
    return visitRoot({ rootId: accountId as AccountId })
}
export async function ensureOpportunitySynced(opportunityId: string, accountId: string) {
    const rep = getClient<typeof mutators>()

    const { accountContactSynced, opp } = await rep.query(async (tx) => {
        const [accountContactSynced, opp] = await Promise.all([
            tx.get([Prefix.SyncStatus, accountId, Prefix.Opportunity].join('/')) as Promise<
                SyncedRecord | undefined
            >,
            tx.get([Prefix.Contact, opportunityId].join('/')) as Promise<
                OpportunityModel | undefined
            >,
        ])

        return {
            accountContactSynced,
            opp,
        }
    })
    if (!opp && accountContactSynced?.didLoad) {
        return rep.mutate.visitRoot({ rootId: accountId })
    }
    return visitRoot({ rootId: accountId as AccountId })
}

export async function ensureActivitiesSynced(rootId: string) {
    const rep = getClient<typeof mutators>()
    const sobjectType = isLead(rootId) ? 'Lead' : 'Account'
    const { root, hasActivities } = await rep.query(async (tx) => {
        const [root, lastTask, lastEvent] = await Promise.all([
            getSobject(tx, sobjectType, rootId),
            tx.scan({ indexName: 'tasksByRootId', prefix: rootId, limit: 1 }).keys().toArray(),
            tx.scan({ indexName: 'eventsByRootId', prefix: rootId, limit: 1 }).keys().toArray(),
        ])
        return {
            root,
            hasActivities: lastTask.length || lastEvent.length,
        }
    })
    if ('LastActivityDate' in root && root.LastActivityDate && !hasActivities) {
        return rep.mutate.resync({ rootId })
    }
}

export async function visitRoot(input: z.infer<typeof SyncedRootsMutatorSchema.visitRoot>) {
    const rep = getClient<typeof mutators>()

    const rootSyncState = await rep.query((tx) => queryRootSyncState(tx, input.rootId))
    const syncedItems: SyncedRecord[] = Object.values(rootSyncState)

    const isLoading = syncedItems.some((a) => a.isLoading)
    if (isLoading) {
        return
    }

    if (Object.keys(rootSyncState).length === 0) {
        return rep.mutate.visitRoot(input)
    }

    const requiredKeys = getRequiredKeys(input.rootId)
    const missingKeys = requiredKeys.filter((key) => !Boolean(rootSyncState[key]))
    if (missingKeys.length > 0) {
        await rep.mutate.visitRoot(input)
    }

    // Maybe do an ocassional resync of records every 4 weeks. Just to be safe
    const latestDate = Object.values(syncedItems)
        .map((a) => (a.didLoad ? a.date : undefined))
        .filter(isTruthy)
        .sort()[0]

    if (latestDate) {
        const asDate = new Date(latestDate)
        const diff = differenceInDays(new Date(), asDate)
        if (diff > 31) {
            return rep.mutate.resync({ rootId: input.rootId })
        }
    }

    await ensureActivitiesSynced(input.rootId)
}

export function prefetchRoots(input: z.infer<typeof SyncedRootsMutatorSchema.prefetchRoots>) {
    const rep = getClient<typeof mutators>()
    return rep.mutate.prefetchRoots(input)
}

export async function syncNames(input: z.infer<typeof SyncedRootsMutatorSchema.syncNames>) {
    const rep = getClient<typeof mutators>()
    const existing = await rep.query(async (tx) => {
        const exists = await Promise.all(input.ids.map((id) => tx.get([Prefix.Name, id].join('/'))))
        return exists.filter(isTruthy) as Array<{ Id: string }>
    })
    const toFetch = input.ids.filter((id) => !existing.find((a) => a.Id === id))
    if (toFetch.length > 0) {
        return rep.mutate.syncNames({
            ids: toFetch,
        })
    }
}

export async function ensureObjectOfTypeExists(
    objectType: 'Lead' | 'Account' | 'Contact' | 'Opportunity'
) {
    const rep = getClient<typeof mutators>()
    const idPrefix = getPrefixForSobject(objectType)
    const prefix = [Prefix.SyncStatus, idPrefix].join('/')
    const existingSynced = await rep.query((tx) => tx.scan({ prefix, limit: 1 }).keys().toArray())
    if (!existingSynced.length) {
        await rep.mutate.ensureObjectOfTypeExists({ objectType })
    }
}

export const mutators = applyValidators(SyncedRootsMutatorSchema, {
    async visitRoot(tx, args) {
        await potentiallyAddSyncLoading(tx, args.rootId)
    },
    async prefetchRoots(tx, args) {
        const processAll = args.rootIds.map((rootId) => potentiallyAddSyncLoading(tx, rootId))
        await Promise.all(processAll)
    },
    async resync(tx, args) {
        const { rootId } = args
        const prefix = [Prefix.SyncStatus, rootId].join('/')
        console.log('prefix', prefix)
        const rootSynced = await tx.scan({ prefix }).entries().toArray()
        console.log('rootSynced', rootSynced)
    },
    async ensureObjectOfTypeExists(tx, args) {
        // Put down a fake synced item
        const { objectType } = args
        const idPrefix = getPrefixForSobject(objectType)
        const fakeId = `${idPrefix}000000000`
        await tx.put([Prefix.SyncStatus, fakeId].join('/'), {
            id: fakeId,
            isLoading: true,
            didLoad: false,
        })
    },
    async syncNames(tx, args) {
        const { ids } = args
        await Promise.all(
            ids.map((id) => {
                return tx.put([Prefix.Name, id].join('/'), {
                    Id: id,
                    isLoading: true,
                    __typename: 'Name',
                })
            })
        )
    },
})

async function potentiallyAddSyncLoading(tx: WriteTransaction, rootId: string) {
    const rootSyncState = await queryRootSyncState(tx, rootId)
    const requiredKeys = getRequiredKeys(rootId)
    const missingKeys = requiredKeys.filter((key) => !Boolean(rootSyncState[key]))
    const putTasks = missingKeys.map((key) =>
        tx.put(getSyncStateKey(key, rootId), { id: rootId, isLoading: true, didLoad: false })
    )
    return Promise.all(putTasks)
}

export function getRequiredKeys(rootId: string) {
    const objectSpecificKeys: RootSyncKeys[] = isLead(rootId)
        ? ['lead']
        : ['account', 'opportunity', 'contact']
    const requiredKeys: RootSyncKeys[] = ['task', 'event', ...objectSpecificKeys]
    return requiredKeys
}

function getSyncStateKey(key: RootSyncKeys, rootId: string) {
    return [Prefix.SyncStatus, rootId, key].join('/')
}

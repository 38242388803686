import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema } from '../base/date.types'
import { OrgIdSchema, UserIdSchema } from '../base/id.types'

export const InviteSourceSchema = z.enum(['stack', 'fieldgroup', 'share'])
export const UserInviteSchema = z.object({
    orgId: OrgIdSchema,
    inviter: UserIdSchema,
    invitee: UserIdSchema,
    source: InviteSourceSchema.nullish(),
    attributed: z.boolean().nullish().default(false),
    redeemed: z.boolean().nullish().default(false),
})

export const UserInviteRecordSchema = UserInviteSchema.extend({
    createdDate: DateTimeStringSchema,
    modifiedDate: DateTimeStringSchema,
    __typename: z.literal('userinvite'),
})

export type UserInvite = z.infer<typeof UserInviteSchema>
export type UserInviteRecord = z.infer<typeof UserInviteRecordSchema> & { PK: string; SK: string }

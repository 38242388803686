/// <reference types="resize-observer-browser" />

import { useEffect, useState } from 'react'

import { useNewestRef } from './useNewestRef'

export function useSizeObserver<T extends HTMLElement>(
    element: T | null,
    onResize: (() => void) | null
) {
    const resizeRef = useNewestRef(onResize)

    const [observer] = useState<ResizeObserver | undefined>(
        () => window.ResizeObserver && new ResizeObserver(() => resizeRef.current?.())
    )

    useEffect(() => {
        if (observer && element) {
            observer.observe(element)
            return () => observer.unobserve(element)
        }
    }, [element, observer])
}

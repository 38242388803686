import { Control, useWatch, useController } from 'react-hook-form'
import clsx from 'clsx'
import { addDays, addMonths, isWeekend, nextMonday, setDate } from 'date-fns'
import { Fragment } from 'react'

import { Button } from '@laserfocus/ui/beam'
import { FormSelect, FieldLabel } from '@laserfocus/client/shared-form'
import { getDateShort, getWeekDay } from '@laserfocus/ui/util-locale'
import { bail } from '@laserfocus/shared/util-error'
import { StackTiming } from '@laserfocus/shared/models'

const UNIT_OPTIONS = [
    { value: 'always', label: 'Always' },
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' },
    { value: 'never', label: 'Never' },
]

const WEEKDAY_OPTIONS = [
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
]

export type FormState = {
    unit: 'always' | 'week' | 'month' | 'never'
    // For monthly
    dayOfTheMonth: number | undefined
    daysOfTheWeek: number[]
}

export function parseState(state: FormState): StackTiming | null {
    switch (state.unit) {
        case 'always':
            return {
                unit: state.unit,
            }
        case 'week':
            return {
                unit: state.unit,
                daysOfTheWeek: state.daysOfTheWeek,
                interval: 1,
            }
        case 'month':
            return {
                unit: state.unit,
                dayOfTheMonth: state.dayOfTheMonth!,
                interval: 1,
            }
        case 'never':
            return null
        default:
            bail('Need a unit to submit')
    }
}

export function isValid(state: Partial<FormState>) {
    switch (state?.unit) {
        case 'always':
            return true
        case 'week':
            return Boolean(state?.daysOfTheWeek?.length)
        case 'month':
            return Boolean(state.dayOfTheMonth)
        case 'never':
            return true
        default:
            return false
    }
}

export function TimeStackForm({ control }: { control: Control<FormState> }) {
    return (
        <div className="group grid grid-cols-[minmax(0,2fr),minmax(0,3fr)] gap-2 items-start outline-none focus-visible:ring pr-2 rounded-md pt-2">
            <FieldLabel htmlFor="timed-stack-unit">Schedule</FieldLabel>
            <FormSelect
                control={control as any}
                initialOptionValue="weekly"
                options={UNIT_OPTIONS}
                size="large"
                name="unit"
                id="timed-stack-unit"
                variant="border"
            />
            <MonthlyForm control={control} />
            <WeeklyForm control={control} />
            <Prediction control={control} />
        </div>
    )
}

function MonthlyForm({ control }: { control: Control<FormState> }) {
    const unit = useWatch({ control, name: 'unit' })
    const {
        field: { value, onChange },
    } = useController({ control, name: 'dayOfTheMonth' })
    if (unit !== 'month') {
        return null
    }
    return (
        <>
            <FieldLabel htmlFor="monthly-day">Day of the month</FieldLabel>
            <div className="grid-col-2 rounded-md p-2 ring-1 ring-grey-700/20">
                <div className="grid grid-cols-[repeat(7,minmax(2rem,1fr))] place-items-center leading-[1.2] tabular-nums">
                    {new Array(31).fill('').map((_, idx) => (
                        <Day
                            key={`${idx}`}
                            day={idx + 1}
                            select={() => onChange(idx + 1)}
                            isSelected={value === idx + 1}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

function WeeklyForm({ control }: { control: Control<FormState> }) {
    const unit = useWatch({ control, name: 'unit' })

    const {
        field: { value, onChange },
    } = useController({ control, name: 'daysOfTheWeek', defaultValue: [] })

    if (unit !== 'week') {
        return null
    }

    return (
        <>
            <FieldLabel htmlFor="weekly-days">Weekdays</FieldLabel>
            <div className="grid-col-2 rounded-md p-2 ring-1 ring-grey-700/20">
                <div className="grid grid-cols-[repeat(5,minmax(2rem,1fr))] place-items-center leading-[1.2] tabular-nums">
                    {WEEKDAY_OPTIONS.map((day) => (
                        <Day
                            key={day.value}
                            day={day.label.substring(0, 3)}
                            isSelected={value.includes(day.value)}
                            select={() => {
                                if (value.includes(day.value)) {
                                    onChange(value.filter((a) => a !== day.value))
                                } else {
                                    onChange([...value, day.value])
                                }
                            }}
                            size="large"
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

function Prediction({ control }: { control: Control<FormState> }) {
    const state = useWatch({ control })
    const upcomings = calculateNext(state)
    const unit = useWatch({ control, name: 'unit' })

    if (unit === 'never') {
        return null
    }

    return (
        <>
            <FieldLabel>Next 5 Occurences</FieldLabel>
            <div className="p-2 ring-1 ring-grey-700/20 rounded-md text-sm font-medium leading-[1.45] text-grey-700 pr-6 py-[0.3125rem] grid grid-cols-2 justify-start gap-1">
                {upcomings.length ? (
                    upcomings.map((date) => {
                        return (
                            <Fragment key={date.toISOString()}>
                                <span>{getDateShort(date)}</span>
                                <span className="text-grey-700/60">{getWeekDay(date)}</span>
                            </Fragment>
                        )
                    })
                ) : (
                    <span className="text-grey-700/40 col-span-2">Select a timeframe</span>
                )}
            </div>
        </>
    )
}

function calculateNext(state: Partial<FormState>, amount: number = 5) {
    const now = new Date()
    if (!state.unit) {
        return []
    }
    if (state.unit === 'always') {
        let matches: Date[] = []
        let counter = 0
        while (matches.length < amount) {
            const current = addDays(now, counter)
            if (!isWeekend(current)) {
                matches.push(current)
            }
            counter++
        }
        return matches
    } else if (state.unit === 'week') {
        if (!state.daysOfTheWeek?.length) {
            return []
        }
        let matches = []
        let counter = 0
        while (matches.length < amount) {
            const current = addDays(now, counter)
            if (state.daysOfTheWeek.includes(current.getDay())) {
                matches.push(current)
            }
            counter++
        }
        return matches
    } else if (state.unit === 'month') {
        if (!state.dayOfTheMonth) {
            return []
        }
        const offset = now.getDate() < state.dayOfTheMonth ? 1 : 0
        const start = setDate(addMonths(now, offset), state.dayOfTheMonth)
        return Array.from({ length: amount }, (v, i) => i).map((idx) => {
            const inThisMonth = addMonths(start, idx)
            if (isWeekend(inThisMonth)) {
                return nextMonday(inThisMonth)
            }
            return inThisMonth
        })
    }
    return []
}

function Day({
    day,
    isSelected,
    select,
    size = 'medium',
}: {
    day: number | string
    isSelected?: boolean
    select: () => void
    size?: 'medium' | 'large'
}) {
    return (
        <Button
            onClick={select}
            variant={isSelected ? 'primary' : 'tertiary'}
            className={clsx(
                'p-0 rounded-full leading-none relative text-xs font-medium',
                size === 'medium' ? 'w-[1.625rem] h-[1.625rem]' : 'w-8 h-8'
            )}
        >
            {day}
        </Button>
    )
}

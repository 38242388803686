import { SelectCell } from '@laserfocus/client/ui-shared-datagrid'

import { BulkEditCellProps } from './BulkEditCell'

export function BulkEditSelectCell({
    column,
    isEditable,
    updateValue,
    value,
    hasMultipleValues,
}: BulkEditCellProps) {
    const options = column
        .getSelectOptions()
        .map((option) => ({ ...option, label: option.label || '' }))

    return (
        <SelectCell
            name={column.key}
            value={value}
            updateValue={(value) => updateValue(value)}
            options={options}
            readOnly={!isEditable}
            placeholder={hasMultipleValues ? 'multiple' : undefined}
            theme="dark"
        />
    )
}

import {
    Control,
    useController,
    UseFormSetValue,
    UseFormWatch,
    useFormContext,
} from 'react-hook-form'
import { useEffect } from 'react'

import { ContactModel, ContactSearchResult } from '@laserfocus/shared/models'

import { ConvertLeadFormState } from './ConvertLeadModal'
import { ModeRadioGroup } from './ModeRadioGroup'
import { SObjectSearchSelect } from './SObjectSelect'
import { DuplicateContactCard } from './DuplicatesSection'

export function ContactSectionBody({
    control,
    duplicates,
}: {
    control: Control<ConvertLeadFormState>
    duplicates: ContactModel[]
}) {
    const { field } = useController({ control, name: 'contactCreateOrChoose' })

    return (
        <div className="grid gap-4 py-4 px-8">
            <ModeRadioGroup
                value={field.value}
                onChange={field.onChange}
                disabled={Boolean(duplicates.length)}
                options={[
                    { value: 'create', label: 'Create new' },
                    { value: 'choose', label: 'Choose existing' },
                ]}
            />
            {field.value === 'choose' && (
                <>
                    <SObjectSearchSelect
                        control={control}
                        name="existingContact"
                        label="Contact"
                        sobject="Contact"
                        required="You need to select the contact the lead should be merged with"
                    />
                    <DuplicateContactSelect duplicates={duplicates} />
                </>
            )}
            {/* {field.value === 'create' && <CreateContactSection control={control} />} */}
        </div>
    )
}

function DuplicateContactSelect({ duplicates }: { duplicates: ContactModel[] }) {
    const { setValue, watch } = useFormContext<ConvertLeadFormState>()
    const existingContact = watch('existingContact')
    if (duplicates.length > 1) {
        return (
            <>
                {duplicates.map((duplicate) => (
                    <DuplicateContactCard
                        key={duplicate.Id}
                        record={duplicate}
                        onSelect={() => {
                            setValue('existingContact', {
                                Id: duplicate.Id,
                                Name: duplicate.Name,
                                AccountId: duplicate.AccountId,
                            })
                        }}
                        selected={existingContact && existingContact.Id === duplicate.Id}
                    />
                ))}
            </>
        )
    }
    return null
}

export function useContactDuplicateForUpdates(
    watch: UseFormWatch<ConvertLeadFormState>,
    setValue: UseFormSetValue<ConvertLeadFormState>,
    duplicates: ContactModel[]
) {
    const [mode] = watch(['contactCreateOrChoose'])
    useEffect(() => {
        if (duplicates && duplicates.length >= 1 && mode === 'create') {
            const [duplicate] = duplicates
            if (duplicates.length === 1 && duplicate) {
                const existingContact: ContactSearchResult = {
                    SObjectType: duplicate.__typename,
                    Id: duplicate.Id,
                    Name: duplicate.Name!,
                    Email: duplicate.Email,
                    AccountId: duplicate.AccountId,
                    OwnerId: duplicate.OwnerId,
                }
                setValue('existingContact', existingContact)
            }
            setValue('contactCreateOrChoose', 'choose')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duplicates])
}

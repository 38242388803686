export const pastelleColors = [
    '#596e79',
    '#c7b198',
    '#dfd3c3',
    '#f0ece3',
    '#8bcdcd',
    '#e5edb7',
    '#faf0af',
    '#f1c5c5',
    '#d4b5b0',
    '#eedad1',
    '#f69e7b',
    '#383e56',
    '#e36387',
    '#f2aaaa',
    '#a6dcef',
    '#ddf3f5',
    '#f6def6',
    '#e5cfe5',
    '#cfe5cf',
    '#cff6cf',
    '#f4ebc1',
    '#a0c1b8',
    '#709fb0',
    '#726a95',
    '#aacdbe',
    '#f4f7c5',
    '#fbc687',
    '#ea907a',
    '#abc2e8',
    '#dbc6eb',
    '#d1eaa3',
    '#efee9d',
    '#679b9b',
    '#aacfcf',
    '#d291bc',
    '#ffcbcb',
    '#f5a7a7',
    '#f9d89c',
    '#82c4c3',
    '#bc658d',
    '#e19999',
    '#f5a8a8',
    '#fab7b7',
    '#dddddd',
    '#faf2f2',
    '#f3e1e1',
    '#f1d1d1',
    '#7d5a5a',
    '#6886c5',
    '#ffacb7',
    '#ffe0ac',
    '#f9f9f9',
    '#ffecc7',
    '#fdd998',
    '#f5b971',
    '#85a392',
    '#d8345f',
    '#e58a8a',
    '#ccafaf',
    '#588da8',
    '#679b9b',
    '#aacfcf',
    '#fde2e2',
    '#ffb6b6',
    '#ffeb99',
    '#a4c5c6',
    '#d4ebd0',
    '#856c8b',
    '#f19292',
    '#cbe2b0',
    '#fcf7bb',
    '#f6d186',
    '#f3ecb8',
    '#f5cab3',
    '#a8d3da',
    '#b590ca',
    '#424874',
    '#a6b1e1',
    '#dcd6f7',
    '#f4eeff',
    '#ffaaa5',
    '#ffd3b6',
    '#dcedc1',
    '#a8e6cf',
    '#e9e1cc',
    '#ea9085',
    '#d45079',
    '#6e5773',
    '#f6eec7',
    '#ffb6b9',
    '#f4dada',
    '#beebe9',
    '#30475e',
    '#ba6b57',
    '#f1935c',
    '#e7b2a5',
    '#6c567b',
    '#c06c84',
    '#f67280',
    '#f8b195',
    '#81f5ff',
    '#a0ffe6',
    '#ffffdd',
    '#ffd5e5',
    '#feb377',
    '#f7e8f0',
    '#f1c6de',
    '#eab0d9',
    '#c295d8',
    '#e4a3d4',
    '#f1c6d3',
    '#fce2db',
    '#6a8caf',
    '#75b79e',
    '#a7e9af',
    '#eef9bf',
    '#b9cced',
    '#f6e7e6',
    '#fbf4f9',
    '#f6e5f5',
    '#e9e2d0',
    '#ea9085',
    '#d45d79',
    '#6e5773',
    '#f8c3af',
    '#fea5ad',
    '#ea9abb',
    '#be8abf',
    '#8ac6d1',
    '#bbded6',
    '#fae3d9',
    '#ffb6b9',
    '#f1f1f6',
    '#e1ccec',
    '#c9b6e4',
    '#be9fe1',
    '#c9753d',
    '#eb8242',
    '#e6a157',
    '#9dab86',
    '#f5cdaa',
    '#cfb495',
    '#758184',
    '#5d5b6a',
    '#32afa9',
    '#a4d4ae',
    '#e7f0c3',
    '#f0cf85',
    '#e5e4cc',
    '#bac7a7',
    '#889e81',
    '#698474',
    '#6c567b',
    '#c06c84',
    '#f67280',
    '#f8b195',
    '#d6e5fa',
    '#fef6fb',
    '#e6b2c6',
    '#d77fa1',
]

import { addMinutes, parseISO } from 'date-fns'

import { NewEvent } from '@laserfocus/shared/models'

export function isOver(e: NewEvent, nowArg: Date | number = new Date()) {
    const now = new Date(nowArg)
    if (e.EndDateTime) {
        return parseISO(e.EndDateTime).getTime() < now.getTime()
    }
    if (e.StartDateTime && e.DurationInMinutes) {
        const endDate = addMinutes(parseISO(e.StartDateTime), e.DurationInMinutes)
        return endDate.getTime() < now.getTime()
    }
    if (e.ActivityDateTime) {
        return parseISO(e.ActivityDateTime).getTime() < now.getTime()
    }
    return true
}

export function getSortingDate(
    e: Pick<NewEvent, 'StartDateTime' | 'ActivityDateTime' | 'EndDateTime'>
) {
    if (e.StartDateTime) {
        return parseISO(e.StartDateTime)
    } else if (e.ActivityDateTime) {
        return parseISO(e.ActivityDateTime)
    } else if (e.EndDateTime) {
        return parseISO(e.EndDateTime)
    }
}

import { Analytics } from '@laserfocus/client/util-analytics'
import { RoutePaths } from '@laserfocus/client/util-routing'

import { useUpcomingActivities } from '../activity/useActivityStack'

import { StackCard } from './StackCard'

export const UpcomingCard = function UpcomingCard() {
    const { activities, loading } = useUpcomingActivities()

    function handleClick() {
        Analytics.trackEvent({
            event: 'stack_clicked',
            location: 'home',
            stack: 'overdue',
        })
    }

    return (
        <StackCard
            icon="🔔"
            onClick={handleClick}
            linkTo={RoutePaths.Upcoming}
            count={loading ? undefined : activities.length}
            color="yellow"
            title="Upcoming"
            sobject="Tasks"
        />
    )
}

import { twMerge } from 'tailwind-merge'
import { useState } from 'react'

import { Select, Button } from '@laserfocus/ui/beam'
import { useActivitiesMetadataContext } from '@laserfocus/client/data-access-shared'
import { PicklistValue } from '@laserfocus/shared/models'

interface TaskSelectsProps extends Omit<TypeSelectProps, 'options'>, PrioritySelectProps {
    activityType: 'Task' | 'Event'
    className?: string
    noPriority?: boolean
}

export function TaskSelects({
    activityType,
    type,
    setType,
    priority,
    setPriority,
    className,
    noPriority,
}: TaskSelectsProps) {
    const metadata = useActivitiesMetadataContext()

    const isEvent = activityType === 'Event'
    const selectOptions = isEvent ? metadata?.eventTypes : metadata?.taskTypes

    return (
        <div className={twMerge('grid grid-flow-col gap-2', className)}>
            {!isEvent && !noPriority && metadata?.taskPriorities && (
                <PrioritySelect priority={priority} setPriority={setPriority} />
            )}
            {selectOptions && <TypeSelect type={type} setType={setType} options={selectOptions} />}
        </div>
    )
}

type PicklistSelectOption = PicklistValue & {
    iconComponent?: React.ComponentType<{ className?: string }>
}
interface TypeSelectProps {
    type: string | null | undefined
    setType(type: string | null | undefined): void
    options: PicklistSelectOption[]
}

function TypeSelect({ type, setType, options }: TypeSelectProps) {
    const [isSelectOpen, setIsSelectOpen] = useState(false)

    const selectedType = options.find((t) => t.value === type)
    const label = selectedType?.label || 'No Type'

    return (
        <Select
            isOpen={isSelectOpen}
            options={[{ label: '—', value: '' }, ...options]}
            initialOptionValue={selectedType?.value || ''}
            onCancel={() => setIsSelectOpen(false)}
            onSubmit={({ value }) => {
                setIsSelectOpen(false)
                setType(value || null)
            }}
        >
            <Button<'button'>
                title={label}
                size="small"
                variant="tertiary"
                iconComponent={selectedType?.iconComponent}
                type="button"
                onClick={(event) => {
                    event.stopPropagation()
                    setIsSelectOpen((v) => !v)
                }}
                className={isSelectOpen ? 'ring ring-blue-500' : undefined}
            >
                {label}
            </Button>
        </Select>
    )
}

interface PrioritySelectProps {
    priority: string | undefined | null
    setPriority(priority: string | null | undefined): void
}

function PrioritySelect({ priority, setPriority }: PrioritySelectProps) {
    const metadata = useActivitiesMetadataContext()
    const [isSelectOpen, setIsSelectOpen] = useState(false)

    if (!metadata?.taskPriorities) {
        return null
    }

    const selectedType = metadata.taskPriorities.find((t) => t.value === priority)
    const label = selectedType?.label || 'No Priority'

    return (
        <>
            <Select
                isOpen={isSelectOpen}
                options={metadata.taskPriorities}
                initialOptionValue={selectedType?.value}
                onCancel={() => setIsSelectOpen(false)}
                onSubmit={({ value }) => {
                    setIsSelectOpen(false)
                    setPriority(value)
                }}
            >
                <Button<'button'>
                    title={label}
                    size="small"
                    variant="tertiary"
                    type="button"
                    onClick={(event) => {
                        event.stopPropagation()
                        setIsSelectOpen((v) => !v)
                    }}
                    iconComponent={selectedType?.iconComponent}
                    className={isSelectOpen ? 'ring ring-blue-500' : undefined}
                >
                    {label}
                </Button>
            </Select>
        </>
    )
}

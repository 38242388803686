import { Prefix } from '@laserfocus/shared/models'

import { ModelProps } from './ObjectPool'

const UntypedKeys = new Set([
    'activitycounter',
    'syncstatus',
    'contentnote',
    'pinnedactivity',
    'salesextension',
])

/**
 *
 * This is for migrating to the new mobx based
 * models. But we want to expose the typename
 * from the endpoints
 *
 *
 * @param props
 * @param key
 */
export function migrateModel(props: ModelProps, key: string): ModelProps {
    if (props.__typename) {
        return props
    }
    for (let [prefix, typename] of Object.entries(PrefixToTypename)) {
        if (key.startsWith(prefix)) {
            return {
                ...props,
                __typename: props.__typename || typename,
            }
        }
    }
    const preKey = key.split('/')[0]
    if (!UntypedKeys.has(preKey)) {
        console.warn('Could not get Typename for', key)
    }

    return props
}

const PrefixToTypename = {
    [Prefix.StackRow]: 'stackrows',
}

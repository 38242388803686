import { useLayoutEffect, useState } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { useAutoFocusedRef } from '@laserfocus/ui/util-react'

interface ContentEditableProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string
    placeholder?: string
    // placeholderClassName?: string
    focusOnMount?: boolean
    onValueChange(value: string): void
    maxLength?: number
    formatDisplayValue?: (v: string) => string
}

export function ContentEditable({
    value,
    placeholder,
    focusOnMount,
    onValueChange,
    maxLength,
    // placeholderClassName,
    className,
    onFocus,
    onBlur,
    formatDisplayValue,
    ...props
}: ContentEditableProps) {
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useAutoFocusedRef<HTMLDivElement>(focusOnMount)
    const finalValue = !isFocused && formatDisplayValue ? formatDisplayValue(value) : value
    useLayoutEffect(() => {
        if (inputRef.current && inputRef.current.innerText !== finalValue) {
            inputRef.current.innerText = finalValue
        }
    }, [finalValue, inputRef])
    return (
        <div
            className={twMerge(
                'hover:bg-grey-700/5 focus-within:ring rounded-lg grid grid-flow-col transition',
                className
            )}
            onFocus={(e) => {
                setIsFocused(true)
                onFocus?.(e)
            }}
            onBlur={(e) => {
                setIsFocused(false)
                onBlur?.(e)
            }}
        >
            <div
                ref={inputRef}
                contentEditable
                className={clsx(
                    'outline-none px-3 py-1 whitespace-nowrap overflow-hidden',
                    !isFocused && 'text-ellipsis'
                )}
                onInput={(event) => {
                    // Fixes exceeding MAX_INPUT_LENGTH on edge cases which onKeyDown handler doesn't catch, like typing option + U, then U. Loses caret position.
                    if (maxLength && event.currentTarget.innerText.length > maxLength) {
                        event.currentTarget.innerText = value
                    } else {
                        onValueChange(event.currentTarget.innerText)
                    }
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault()
                        event.currentTarget.blur()
                    }

                    const isTextSelected =
                        document.getSelection()?.anchorOffset !==
                        document.getSelection()?.focusOffset

                    // Prevents typing a character when MAX_INPUT_LENGTH is exceeded without losing caret position.
                    if (
                        maxLength &&
                        event.currentTarget.innerText.length >= maxLength &&
                        event.key.length === 1 &&
                        // We want to allow things like cmd + A
                        !event.metaKey &&
                        !event.ctrlKey &&
                        !isTextSelected
                    ) {
                        event.preventDefault()
                    }
                }}
                {...props}
            />
            {!value && placeholder && (
                <div
                    className="text-grey-700/20 pr-3 -ml-3 py-1"
                    onClick={() => inputRef.current?.focus()}
                >
                    {placeholder}
                </div>
            )}
        </div>
    )
}

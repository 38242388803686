import 'reflect-metadata'
import { configure, action, when, observable, runInAction, computed } from 'mobx'
import type { Replicache } from 'replicache'

import {
    MetadataStore,
    RootStoreInjection as SharedStoreInjection,
    SalesObjectStore,
    TodayView,
    UpcomingView,
    OverdueView,
    SalesObjectExtensionBridge,
    runReplicacheOnboardingCheck,
    PrefetchStore,
    NameStore,
} from '@laserfocus/client/store-shared'
import {
    HistoryStore,
    SearchStore,
    SearchRootInjection,
    HistoryRootInjection,
} from '@laserfocus/client/feature-search'
import { ObjectPool, QueryStatus, ReplicacheDeltaSyncer } from '@laserfocus/client/data-layer'
import {
    SessionStore,
    RootStoreInjection as AuthStoreInjection,
    STORAGE_USERID_KEY,
} from '@laserfocus/client/feature-auth'
import {
    ActivityStackNavigation,
    ActivityStackNavigationInjection,
} from '@laserfocus/client/feature-stacks'
import {
    OnboardingStore,
    RootStoreInjection as OnboardingStoreInjection,
} from '@laserfocus/client/feature-onboarding'
import { UserNotificationStore } from '@laserfocus/ui/user-notification-store'
import {
    RootStoreInjection as UserNotificationStoreInjection,
    SendUserNotification,
} from '@laserfocus/ui/user-notification-store'
// Since RC
import { BridgeStackStore } from '@laserfocus/client/store-shared'
import { BridgeUserStore } from '@laserfocus/client/feature-auth'
import { DesktopStore } from '@laserfocus/client/desktop'
import {
    AccountModel,
    ContactModel,
    EventModel,
    FieldMetadata,
    LeadModel,
    OpportunityModel,
    RecordtypeModel,
    TaskModel,
} from '@laserfocus/client/model'

import { makeReplicacheClient } from './makeReplicacheClient'

export interface UiDependencies {
    sendUserNotification: SendUserNotification
}

interface RootStoreInjection
    extends SharedStoreInjection,
        SearchRootInjection,
        HistoryRootInjection,
        AuthStoreInjection,
        OnboardingStoreInjection,
        ActivityStackNavigationInjection,
        UserNotificationStoreInjection {}

configure({ enforceActions: 'observed' })
export class RootStore implements RootStoreInjection {
    metadataStore?: MetadataStore

    todayView?: TodayView
    upcomingView?: UpcomingView
    overdueView?: OverdueView

    searchStore: SearchStore
    historyStore: HistoryStore

    objectPool: ObjectPool
    queryStatus: QueryStatus
    deltaSync?: ReplicacheDeltaSyncer

    sessionStore?: SessionStore

    // We only need it to create Objects (for now)
    contactStore: SalesObjectStore
    opportunityStore: SalesObjectStore
    prefetchStore: PrefetchStore
    nameStore: NameStore

    activityStackNavigation?: ActivityStackNavigation
    onboardingStore: OnboardingStore
    userNotificationStore: UserNotificationStore
    desktopStore?: DesktopStore

    userBridge?: BridgeUserStore
    stackBridge?: BridgeStackStore
    salesExtentionBridge?: SalesObjectExtensionBridge
    replicache?: Replicache

    /**
     * This is set, when we have an issue from Graphql in particular the API is disabled
     */
    @observable isGlobalError = false
    @observable hasLoadedReplicacheStores = false
    @observable isReplicacheSyncing = false
    @observable didReplicacheSync = false
    @observable isAuthenticated = false

    constructor(uiDeps: UiDependencies) {
        const { sendUserNotification } = uiDeps
        this.userNotificationStore = new UserNotificationStore(sendUserNotification)
        this.objectPool = new ObjectPool()
        this.prefetchStore = new PrefetchStore()
        this.nameStore = new NameStore(this.objectPool)
        this.initObjectPool()
        this.queryStatus = new QueryStatus()

        this.searchStore = new SearchStore()
        this.historyStore = new HistoryStore()

        this.contactStore = new SalesObjectStore('Contact', this.objectPool)
        this.opportunityStore = new SalesObjectStore('Opportunity', this.objectPool)
        this.onboardingStore = new OnboardingStore()

        this.onboardingStore.registerCheck('root', this.waitToBeReady.bind(this))
        this.onboardingStore.registerCheck('replicacheSync', this.replicacheSynced.bind(this))
        this.onboardingStore.registerCheck('metadata', () => this.metadataStore!.runCheck(), [
            'root',
        ])
        this.onboardingStore.registerCheck('stack', () => this.stackBridge!.runCheck(), [
            'metadata',
        ])
        this.onboardingStore.registerCheck('search', this.searchStore.runCheck, ['metadata'])

        this.onboardingStore.registerCheck(
            'replicacheQueries',
            () => {
                if (!this.replicache) {
                    throw new Error(
                        'Onboarding Error: Replicache not loaded when running replicacheQuerycheck'
                    )
                }
                return runReplicacheOnboardingCheck(this.replicache)
            },
            ['root']
        )
        this.onboardingStore.onInit()
        const userId = localStorage.getItem(STORAGE_USERID_KEY)
        if (userId) {
            this.authenticate(userId)
        }
    }

    waitToBeReady() {
        let timeout: ReturnType<typeof setTimeout>
        return Promise.race([
            when(() => this.hasLoadedReplicacheStores).then(() => {
                clearTimeout(timeout)
                return true
            }),
            new Promise((resolve, reject) => {
                timeout = setTimeout(() => {
                    reject(new Error('Rootstore ready timed out'))
                }, 1000 * 25)
            }),
        ])
    }
    replicacheSynced() {
        let timeout: ReturnType<typeof setTimeout>
        return Promise.race([
            when(() => Boolean(this.didReplicacheSync)).then(() => {
                clearTimeout(timeout)
                return true
            }),
            new Promise((resolve, reject) => {
                timeout = setTimeout(() => {
                    reject(new Error('replicacheSynced timed out'))
                }, 1000 * 25)
            }),
        ])
    }

    authenticate(userId: string) {
        runInAction(() => {
            this.isAuthenticated = true
        })

        localStorage.setItem(STORAGE_USERID_KEY, userId)
        const replicache = makeReplicacheClient(userId)

        runInAction(() => {
            this.isAuthenticated = true
            this.replicache = replicache
            this.historyStore.onInit()
            this.sessionStore = new SessionStore(this.objectPool, this.replicache)
            this.sessionStore.onInit()
            this.replicache.onSync = (syncing: boolean) => {
                runInAction(() => {
                    if (this.isReplicacheSyncing && !syncing) {
                        this.didReplicacheSync = true
                    }
                    this.isReplicacheSyncing = syncing
                })
            }

            const deps = { objectPool: this.objectPool, replicache, queryStatus: this.queryStatus }
            this.deltaSync = new ReplicacheDeltaSyncer(this.objectPool, replicache)
            this.deltaSync.onInit()

            this.stackBridge = new BridgeStackStore(deps)
            this.stackBridge.onInit()

            this.userBridge = new BridgeUserStore(deps)
            this.userBridge.onInit()

            this.metadataStore = new MetadataStore(this.objectPool, replicache)
            this.metadataStore.onInit()

            this.todayView = new TodayView(deps)
            this.todayView.onInit()
            this.upcomingView = new UpcomingView(deps)
            this.upcomingView.onInit()
            this.overdueView = new OverdueView(deps)
            this.overdueView.onInit()

            this.prefetchStore.onInit()
            this.nameStore.onInit()

            this.activityStackNavigation = new ActivityStackNavigation(this.activityStore)

            // this.desktopStore = new DesktopStore(this.activityStore)
            // this.desktopStore.onInit()

            this.salesExtentionBridge = new SalesObjectExtensionBridge(this.objectPool, replicache)
            this.salesExtentionBridge.onInit()

            this.hasLoadedReplicacheStores = true
        })
    }

    @computed
    get activityStore() {
        return {
            todayActivities: this.todayView?.data || [],
            overdueActivities: this.overdueView?.data || [],
            futureOpenActivities: this.upcomingView?.data || [],
        }
    }

    @action
    setGlobalError() {
        this.isGlobalError = true
    }

    onInit() {}

    initObjectPool() {
        this.objectPool.registerModel('Task', TaskModel)
        this.objectPool.registerModel('Event', EventModel)
        this.objectPool.registerModel('FieldMetadata', FieldMetadata)
        this.objectPool.registerModel('RecordType', RecordtypeModel)
        this.objectPool.registerModel('Lead', LeadModel)
        this.objectPool.registerModel('Account', AccountModel)
        this.objectPool.registerModel('Contact', ContactModel)
        this.objectPool.registerModel('Opportunity', OpportunityModel)
    }

    @action
    onDestroy() {
        this.stackBridge?.onDestroy()
        // this.focusStore.onDestroy();
        this.metadataStore?.onDestroy()
        // this.personPrefetchStore?.onDestroy()
        this.userBridge?.onDestroy()
        this.todayView?.onDestroy()
        this.overdueView?.onDestroy()
        this.upcomingView?.onDestroy()

        this.deltaSync?.onDestroy()

        this.salesExtentionBridge?.onDestroy()
        this.desktopStore?.onDestroy()

        this.prefetchStore.onDestroy()
    }
}

import { ApolloLink, fromPromise } from 'apollo-link'
import type { NextLink, Operation } from 'apollo-link'

import { logger } from '@laserfocus/ui/logger'

import { refreshToken, redirectToLogin, getToken, setToken } from './auth-utils'

export const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const authorization = getToken()

    operation.setContext({
        headers: {
            authorization,
        },
    })

    return forward(operation)
})

let isRefreshing = false
let pendingRequests: Array<(dtoken: string) => void> = []
let pendingRefresh: Promise<string | null> | null

const resolvePendingRequests = (accessToken: string) => {
    pendingRequests.map((callback) => callback(accessToken))
    pendingRequests = []
}

export function reauthenticate() {
    if (!pendingRefresh) {
        logger.info('Refreshing')
        pendingRefresh = refreshToken()
            .then((accessToken) => {
                if (!accessToken) {
                    if (localStorage.getItem('lf:user_id') === '0051i000000EJDYAA4') {
                        // eslint-disable-next-line no-debugger
                        debugger
                    }
                    redirectToLogin()
                    return null
                } else {
                    setToken(accessToken)
                    resolvePendingRequests(accessToken)
                    return accessToken
                }
            })
            .catch((error) => {
                if (
                    /**
                     * If we are already on the login route, we don't
                     * need to log this. The tab is probably not focused, which is why we havent
                     * reauthenticated
                     */
                    error &&
                    error.code === 'MISSING_REFRESH_TOKEN' &&
                    window.location.pathname.indexOf('/login') === 0 &&
                    error.tabVisible === 'hidden'
                ) {
                    return null
                }
                logger.error(error)
                pendingRequests = []
                if (localStorage.getItem('lf:user_id') === '0051i000000EJDYAA4') {
                    // eslint-disable-next-line no-debugger
                    debugger
                }
                redirectToLogin()
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                return null
            })
            .finally(() => {
                isRefreshing = false
                pendingRefresh = null
            })
    }
    return pendingRefresh
}

export function handleUnauthenticatedResponse(operation: Operation, forward: NextLink) {
    let forward$

    if (!isRefreshing) {
        isRefreshing = true
        forward$ = fromPromise(reauthenticate()).filter((value) => Boolean(value))
    } else {
        // Will only emit once the Promise is resolved
        forward$ = fromPromise(
            new Promise((resolve) => {
                pendingRequests.push((token: string) => resolve(token))
            })
        )
    }

    return forward$.flatMap(() => forward(operation))
}

import { useEffect, useState } from 'react'

export function useWindowSize() {
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        function handleResize() {
            setSize((size) => {
                if (size.width === window.innerWidth && size.height === window.innerHeight) {
                    return size
                }
                return { width: window.innerWidth, height: window.innerHeight }
            })
        }

        window.addEventListener('resize', handleResize, { passive: true })
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return size
}

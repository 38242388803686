import { z } from 'zod'

import { collectFallbacks } from './fallbacks-store'

export function reportFallbacks<Result extends object>(fn: () => Result) {
    const previousFallbacks = collectFallbacks()
    const result = fn()
    const fallbacks = collectFallbacks(previousFallbacks)
    const issues = fallbacks.flatMap((error) => error.issues)
    const fallback = issues.length ? new z.ZodError(issues) : null

    return {
        ...result,
        fallback,
    }
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function MoreOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M12 8a2 2 0 010-4 2 2 0 110 4zm0 6a2 2 0 010-4 2 2 0 110 4zm0 6a2 2 0 010-4 2 2 0 012 2 2 2 0 01-2 2z" />
        </SvgIcon>
    )
}

import { Confetti, FeatureList } from '@laserfocus/client/ui-shared'
import { ExternalLinkOutlinedIcon, OpportunityFilledIcon } from '@laserfocus/ui/icons'
import { Button, Card } from '@laserfocus/ui/beam'

import { SettingsPageHeader } from '../layout/SettingsPageHeader'

export function SubscriptionSuccessPage() {
    localStorage.setItem('lf:feature:subscription', 'true')
    return (
        <div>
            <SettingsPageHeader
                title={<span>Welcome to Laserfocus </span>}
                iconComponent={OpportunityFilledIcon}
            />
            <Confetti show delay={1000} />
            <div className="p-6">
                <Card className="max-w-md px-6 py-4">
                    <div className="mb-2">
                        Thanks for Purchasing our <span className="font-semibold">PRO</span> Plan.
                        These are all the features you can enjoy now:
                    </div>
                    <FeatureList
                        features={[
                            'Custom stacks',
                            'Schedule your stacks',
                            'Note templates',
                            'Custom field groups',
                            'Bulk Edit',
                        ]}
                    />
                    <div className="mt-6 mb-4">
                        We want you to get the full value out of Laserfocus. Please schedule an
                        onboarding so we can personalize your experience and address all the
                        struggles you currently have with Salesforce.
                    </div>
                    <div>
                        <Button
                            type="submit"
                            className="w-full justify-center"
                            as="a"
                            href="https://calendly.com/lasertalk/onboarding"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Personalized Onboarding
                            <ExternalLinkOutlinedIcon className="ml-1 w-3 h-3" />
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

import { apolloClient } from '@laserfocus/client/data-access-apollo'
import type { SearchResult } from '@laserfocus/shared/models'
import { SEARCH_QUERY } from '@laserfocus/shared/data-access-gql'

export async function searchData(
    searchTerm: string,
    sobject?: 'Lead' | 'Contact' | 'Opportunity' | 'Account'
): Promise<SearchResult[]> {
    const result = await apolloClient.query({
        query: SEARCH_QUERY,
        variables: { searchTerm, sobject },
    })
    if (result.errors && result.errors[0]) {
        throw new Error(result.errors[0].message)
    }
    return result.data.search
}

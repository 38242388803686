import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'
import { useEffect } from 'react'

import { PersonContextProvider } from '@laserfocus/client/feature-person-details'
import { getClient } from '@laserfocus/client/replicache'
import {
    AccountModel,
    ContactModel,
    isTruthy,
    LeadModel,
    OpportunityModel,
    Prefix,
} from '@laserfocus/shared/models'
import { ensureObjectOfTypeExists } from '@laserfocus/client/data-access-shared'

export function FieldGroupContainer({
    rootObject,
    children,
}: {
    children: React.ReactNode
    rootObject: 'Lead' | 'Account'
}) {
    const { lead, account, opportunity, contact, isLoading } =
        useExamplePersonContextData(rootObject)

    useEffect(() => {
        if (isLoading) {
            return
        }
        if (rootObject === 'Lead' && !lead) {
            ensureObjectOfTypeExists('Lead')
        } else {
            if (!account) {
                ensureObjectOfTypeExists('Account')
            }
            if (!opportunity) {
                ensureObjectOfTypeExists('Opportunity')
            }
            if (!contact) {
                ensureObjectOfTypeExists('Contact')
            }
        }
    }, [account, contact, isLoading, lead, opportunity, rootObject])

    return (
        <PersonContextProvider
            rootId={rootObject === 'Lead' ? lead?.Id : account?.Id}
            lead={lead}
            account={account}
            opportunities={[opportunity].filter(isTruthy)}
            contacts={[contact].filter(isTruthy)}
        >
            {children}
        </PersonContextProvider>
    )
}

type ExampleData = Partial<{
    lead: LeadModel
    account: AccountModel
    contact: ContactModel
    opportunity: OpportunityModel
    isLoading: boolean
}>
function useExamplePersonContextData(rootObject: 'Lead' | 'Account'): ExampleData {
    const { lead, account, opportunity, contact, isLoading } = useSubscribe(
        getClient(),
        async (tx: ReadTransaction) => {
            const [leads, accounts, contacts, opportunities] = await Promise.all([
                tx.scan({ prefix: Prefix.Lead, limit: 1 }).values().toArray(),
                tx.scan({ prefix: Prefix.Account, limit: 1 }).values().toArray(),
                tx.scan({ prefix: Prefix.Contact, limit: 1 }).values().toArray(),
                tx.scan({ prefix: Prefix.Opportunity, limit: 1 }).values().toArray(),
            ])
            return {
                lead: leads[0],
                account: accounts[0],
                contact: contacts[0],
                opportunity: opportunities[0],
                isLoading: false,
            }
        },
        { isLoading: true } as any,
        []
    ) as ExampleData
    if (rootObject === 'Lead') {
        return {
            lead,
            isLoading,
        }
    }
    return {
        account,
        opportunity,
        contact,
        isLoading,
    }
}

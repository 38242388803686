import { useState } from 'react'

import { EmojiPicker, ColorPicker } from '@laserfocus/ui/emoji-picker'
import { mutateStack } from '@laserfocus/client/data-access-shared'

const { changeStackColor, changeStackIcon } = mutateStack

interface TableIconProps {
    icon: string
    color: string
    stackId: string
    stackType?: 'user' | 'org'
}

export function TableIcon({ icon, color, stackId, stackType }: TableIconProps) {
    const [isPickerOpen, setIsPickerOpen] = useState(false)

    return (
        <EmojiPicker
            isOpen={isPickerOpen}
            topContent={
                <ColorPicker
                    selectedColor={color}
                    onSubmit={(color) => changeStackColor(stackId, color!, stackType)}
                />
            }
            onSubmit={(emoji) => {
                setIsPickerOpen(false)
                changeStackIcon(stackId, emoji, stackType)
            }}
            onCancel={() => setIsPickerOpen(false)}
        >
            <button
                className="rounded-full overflow-hidden w-11 h-11 bg-grey-700/5 hover:bg-grey-700/10 outline-none focus-visible:ring transition grid place-content-center text-[1.5rem] leading-none"
                onClick={() => setIsPickerOpen(true)}
            >
                {icon}
            </button>
        </EmojiPicker>
    )
}

import React, { MutableRefObject, useReducer } from 'react'
import type {
    UseControllerProps,
    FieldValues,
    ControllerRenderProps,
    // Validate,
    // FieldPathValue,
    // FieldPath,
    ControllerFieldState,
    RefCallBack,
} from 'react-hook-form'

// import { canParseTimeString } from '@laserfocus/ui/util-date'
import { PRERENDER_REVALIDATE_ONLY_GENERATED_HEADER } from 'next/dist/server/api-utils'

import type { FieldMetadata } from '@laserfocus/client/model'
// import { z } from '@laserfocus/shared/decoder'

export interface StateComponentProps<T extends object> {
    children: (state: T, setState: (v: Partial<T>) => void) => JSX.Element
    initialState: T
}

export function useStateReducer<T>(initialState: T) {
    return useReducer((a: T, b: Partial<T>): T => ({ ...a, ...b }), initialState)
}

export function StateComponent<T extends object>({
    children,
    initialState,
}: StateComponentProps<T>) {
    return children(...useStateReducer(initialState))
}

// function makeValidateFromSchema<ValueType>(schema: z.ZodType<ValueType>) {
//     return (value: ValueType) => {
//         const valueToParse = typeof value === 'string' && value === '' ? null : value
//         const result = schema.safeParse(valueToParse)
//         if (result.success === false) {
//             const message = result.error.format()._errors[0]
//             return message
//         }
//     }
// }

// const emailSchema = z.string().email({ message: 'Invalid email address' }).nullish()
// const timeSchema = z
//     .string()
//     .refine(canParseTimeString, 'Valid Time format is 13:37 or 1:37pm')
//     .nullish()
// const requiredBooleanSchema = z.boolean({ required_error: 'Field is required' }).nullish()

export function getControllerRules<
    TFieldValues extends FieldValues = FieldValues
    // TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(fieldMetadata: FieldMetadata): UseControllerProps<TFieldValues>['rules'] {
    // const validate: Record<string, Validate<FieldPathValue<TFieldValues, TFieldName>>> = {}
    // Disable Sync Validation, we rather want the error modal (for now)
    // if (fieldMetadata.fieldType === 'email') {
    //     validate.email = makeValidateFromSchema(emailSchema)
    // }
    // if (fieldMetadata.fieldType === 'time') {
    //     validate.time = makeValidateFromSchema(timeSchema)
    // }
    // if (fieldMetadata.fieldType === 'boolean' && fieldMetadata.required) {
    //     validate.required = makeValidateFromSchema(requiredBooleanSchema)
    // }

    return {
        // required:
        //     // Cant use required with booleans: https://github.com/react-hook-form/react-hook-form/issues/6993
        //     fieldMetadata.required && fieldMetadata.fieldType !== 'boolean'
        //         ? `${fieldMetadata.label} is required`
        //         : undefined,
        validate: {},
    }
}

export type StateComponentState = {
    inputValue: string
    isFocused: boolean
}

type InputPropsOptions = {
    /**
     * Format the Value while not editing
     */
    formatDisplayValue?: (a: any) => any
    /**
     * Used to parse from model to inputValue
     */
    parseValue?: (a: any) => string | null
    /**
     * Transforms the inputValue to model value
     * If transformvalue is provided, data is only sent to the form
     * onBlur
     */
    transformValue?: (a: string) => any
    ref?: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>
    onBlur?: () => void
    onFocus?: () => void
    readOnly?: boolean
}
export function getInputProps<TFieldValues extends FieldValues = FieldValues>(
    field: ControllerRenderProps<TFieldValues>,
    fieldState: ControllerFieldState,
    state: StateComponentState,
    setState: (v: Partial<StateComponentState>) => void,
    submit?: () => void,
    options: InputPropsOptions = {}
) {
    const { value, onBlur, onChange, ref: fieldRef, ...restField } = field
    const { formatDisplayValue, ref: optionsRef, transformValue, parseValue, readOnly } = options
    const { isFocused, inputValue } = state
    const parsedValue = parseValue ? parseValue(value) : defaultParse(value)

    const ref: RefCallBack = optionsRef
        ? (ref) => {
              fieldRef(ref)
              optionsRef.current = ref
          }
        : fieldRef

    const displayValue = fieldState.invalid
        ? inputValue
        : formatDisplayValue
        ? formatDisplayValue(value)
        : parsedValue

    return {
        value: isFocused && !readOnly ? inputValue : displayValue,
        error: (fieldState.error as any)?.message,
        onFocus: () => {
            options.onFocus?.()
            const newInputValue = fieldState.invalid ? inputValue : parsedValue || ''
            setState({ isFocused: true, inputValue: newInputValue })
        },
        onBlur() {
            options.onBlur?.()
            onBlur()
            setState({ isFocused: false })
            submit?.()
        },
        onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            const transformed = transformValue?.(e.target.value)
            transformValue ? onChange(transformed) : onChange(e)
            setState({ inputValue: e.target.value })
        },
        autoComplete: 'off',
        ref,
        ...restField,
    }
}

export function defaultParse(a: string | Date | number | null | undefined): string {
    if (a === null || a === undefined) {
        return ''
    }
    if (a instanceof Date || typeof a === 'number') {
        return a.toString() || ''
    }
    return a
}

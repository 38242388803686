import { format, isValid, parse } from 'date-fns'

const TIME_STRING_FORMAT = 'HH:mm:ss.SSSXX'
const TIME_STRING_FORMAT_INTERNAL = 'HH:mm:ss.SSS'

/**
 * Converts time string to date.
 * @param timeString String in format `HH:mm:ss.SSSXX`. Timezone information is deleted from timeString.
 * @param dateToFill Date to fill with data from timeString
 * @returns Date with time from timeString
 */
export function getDateFromTimeString(timeString: string, dateToFill = new Date()) {
    const rawString = timeString.replace(/(Z|[+-]\d{4})$/, '')
    const date = parse(rawString, TIME_STRING_FORMAT_INTERNAL, dateToFill)
    return isValid(date) ? date : null
}

export function canParseTimeString(timeString: string) {
    const rawString = timeString.replace(/(Z|[+-]\d{4})$/, '')
    const date = parse(rawString, TIME_STRING_FORMAT_INTERNAL, new Date())
    return isValid(date)
}

/**
 * Converts date to time string.
 * @param date Date with time to be converted. Timezone information is deleted from date
 * @returns timeString in format `HH:mm:ss.SSSXX`. Timezone is set to Z.
 */
export function getTimeStringFromDate(date: Date | null) {
    return date && format(date, TIME_STRING_FORMAT).replace(/[+-]\d{4}$/, 'Z')
}

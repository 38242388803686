import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface DropdownButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    isHighlighted?: boolean
    textCentered?: boolean
    noHoverStyles?: boolean
    noFocusStyles?: boolean
}

export const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
    function DropdownButton(
        { isHighlighted, textCentered, noHoverStyles, noFocusStyles, className, ...props },
        ref
    ) {
        return (
            <button
                ref={ref}
                {...props}
                className={twMerge(
                    'block transition w-full text-sm font-medium rounded-md py-1 px-1.5 outline-none',
                    textCentered ? 'text-center' : 'text-left pr-4',
                    props.disabled && 'cursor-default text-white/40',
                    isHighlighted && 'bg-blue-500',
                    !props.disabled &&
                        !noHoverStyles &&
                        (isHighlighted ? 'hover:bg-blue-700' : 'hover:bg-white/10'),
                    !noFocusStyles &&
                        (isHighlighted
                            ? 'focus-visible:ring focus-visible:bg-blue-700 ring-blue-500/30'
                            : 'focus-visible:ring'),
                    className
                )}
            />
        )
    }
)

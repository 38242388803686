import gql from 'graphql-tag'

export const CREATE_CHECKOUT_SESSION_MUTATION = gql`
    mutation createCheckoutSession($input: RedirectInput) {
        createCheckoutSession(input: $input) {
            error
            url
        }
    }
`

export const CREATE_PORTAL_LINK_MUTATION = gql`
    mutation createPortalLink($input: RedirectInput) {
        createPortalLink(input: $input) {
            error
            url
        }
    }
`

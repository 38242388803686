import { useEffect, useState } from 'react'

import { ConfirmPrompt } from '@laserfocus/ui/beam'

export type BulkConfirm = {
    recordCount: number
    fieldLabel: string
    valueLabel: string
    confirm: () => void
}

type BulkConfirmProps = {
    show: boolean
    confirm?: BulkConfirm
    close: () => void
}

export function BulkEditConfirmModal({ show, confirm, close }: BulkConfirmProps) {
    const [lastConfirm, setLastConfirm] = useState<BulkConfirm | undefined>(confirm)

    /**
     * To still have values after confirm is unset to close the modal
     */
    useEffect(() => {
        if (confirm) {
            setLastConfirm(confirm)
        }
    }, [confirm])

    const c = confirm || lastConfirm

    return (
        <ConfirmPrompt
            show={show}
            title={`Update ${c?.recordCount} selected items`}
            description={
                <>
                    Do you want to change <span className="text-white/80">{c?.fieldLabel}</span> to{' '}
                    <span className="text-white/80">{c?.valueLabel || '—'}</span>
                </>
            }
            submitButtonTitle={`Update ${c?.recordCount} items`}
            onSubmit={() => {
                confirm?.confirm()
                // This closes
                close()
            }}
            onCancel={close}
        />
    )
}

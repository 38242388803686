import { useForm, useWatch } from 'react-hook-form'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { Stack } from '@laserfocus/shared/models'
import { Button, Modal } from '@laserfocus/ui/beam'
import { mutateStack } from '@laserfocus/client/data-access-shared'

import { FormState, isValid, parseState, TimeStackForm } from './ScheduleForm'

interface TimeStackModalProps extends TimeStackModalInnerProps {
    show: boolean
}
export function TimeStackModal({ stack, closeModal, show }: TimeStackModalProps) {
    return (
        <Modal show={show} onClose={closeModal}>
            <TimeStackModalInner stack={stack} closeModal={closeModal} />
        </Modal>
    )
}

interface TimeStackModalInnerProps {
    stack: Stack
    closeModal: () => void
}

const TimeStackModalInner = observer(function TimeStackModalInner({
    stack,
    closeModal,
}: TimeStackModalInnerProps) {
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<FormState>({
        defaultValues: {
            unit: stack.timing?.unit ?? 'never',
            ...stack.timing,
        },
    })
    useEffect(() => {
        reset(stack.timing || { unit: 'never' })
    }, [stack.timing, reset])
    async function submit(values: FormState) {
        const parsed = parseState(values)
        await mutateStack.setTiming(
            stack.id,
            parsed,
            stack.__typename === 'OrgStack' ? 'org' : 'user'
        )
    }

    const state = useWatch({ control })
    const canSubmit = isValid(state)

    return (
        <>
            <Modal.Overlay />
            <Modal.Container>
                <Modal.Header border close={closeModal}>
                    Schedule Stack
                </Modal.Header>
                <div className="min-h-[21.75rem] max-h-[50vh] mx-8 my-3 overflow-y-auto">
                    <TimeStackForm control={control} />
                </div>
                <Modal.Footer border className="justify-end">
                    <Button type="submit" variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        onClick={handleSubmit(submit)}
                        disabled={!canSubmit || !isDirty}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </>
    )
})

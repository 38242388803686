import { LayoutPadding, LayoutPaddingVertical, NavBar, PageHeader } from '@laserfocus/client/layout'
import {
    shouldSkipStackNavigation,
    StackNavigationContext,
    useStackNavigation,
} from '@laserfocus/client/store-shared'
import { Analytics } from '@laserfocus/client/util-analytics'
import { useFillHeightRef, useTitle } from '@laserfocus/ui/util-react'
import { isContactModel, isOpportunityModel, WithType } from '@laserfocus/shared/models'

import PersonNavigation from './Person/PersonNavigation'
import { useRootObject } from './modules/PersonContext'
import { PersonIcon } from './PersonIcon'

interface PersonLayoutProps {
    title: string
    left: React.ReactNode
    right: React.ReactNode
}

export default function PersonLayout({ left, right, title }: PersonLayoutProps) {
    const navContext = useStackNavigation()
    const breadCrumbs = [
        ...stackCrumb(navContext),
        {
            title,
        },
    ]
    const containerRef = useFillHeightRef<HTMLDivElement>()
    useTitle(title)
    const salesObject = useRootObject()
    const rootId = getRootId(salesObject)

    return (
        <>
            <NavBar breadcrumbs={breadCrumbs} bg="yellow" />
            <LayoutPaddingVertical maxWidth="detailWidth">
                <PageHeader
                    title={title}
                    actions={<PersonNavigation />}
                    icon={<PersonIcon id={rootId} />}
                />
            </LayoutPaddingVertical>
            <div ref={containerRef} className="bg-white border-t border-grey-700/10">
                <LayoutPaddingVertical maxWidth="detailWidth">
                    <LayoutPadding top bottom className="flex justify-between pt-11">
                        <div className="w-[60%] pr-16">{left}</div>
                        <div className="w-[40%]">{right}</div>
                    </LayoutPadding>
                </LayoutPaddingVertical>
            </div>
        </>
    )
}

function stackCrumb(navContext: StackNavigationContext) {
    if (shouldSkipStackNavigation(navContext)) {
        return []
    }

    return [
        {
            title: navContext.title!,
            path: navContext.listPath!,
            onClick: () =>
                Analytics.trackEvent({
                    event: 'breadcrumb_clicked',
                    level: 'list',
                    location: Analytics.getLocation() as any,
                }),
        },
    ]
}

function getRootId(
    salesObject: (WithType<string> & { Id: string }) | undefined
): string | undefined {
    if (salesObject) {
        if (isOpportunityModel(salesObject) || isContactModel(salesObject)) {
            return salesObject.AccountId
        }
        return salesObject.Id
    }
}

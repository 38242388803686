import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'
import { useCallback, useEffect, useMemo } from 'react'
import { get } from 'lodash'

import { getClient } from '@laserfocus/client/replicache'
import {
    Prefix,
    AccountModel,
    OpportunityModel,
    ContactModel,
    SearchResult,
    FailedMutation,
    isTruthy,
    AccountId,
} from '@laserfocus/shared/models'
import { MutationErrorModal } from '@laserfocus/client/shared-sales-object-form-control'
import { useUrlQueryState } from '@laserfocus/client/util-routing'
import { useHistoryStore } from '@laserfocus/client/feature-search'
import {
    FailedMutationScope,
    useCurrentFailedMutation,
    useFailedMutationScope,
} from '@laserfocus/client/shared-error'
import { countActivity, mutateSObject, visitRoot } from '@laserfocus/client/data-access-shared'

import usePersonActivities from './modules/usePersonActivitiesRCQuery'
import { PersonContextProvider } from './modules/PersonContext'
import PersonPage from './PersonPage'
import { useSyncState } from './modules/useRootSyncState'
import { useAccountNotes } from './notes/useRootNotes'
import { useRootPinned } from './modules/useRootPinned'
import { IsInOverlayContext } from './IsInOverlayContext'

interface AccountContainerProps {
    accountId: string
    isInOverlay?: boolean
    isInTable?: boolean
}
export default function AccountContainer({
    accountId,
    isInOverlay,
    isInTable,
}: AccountContainerProps) {
    const syncState = useSyncState(accountId)
    const {
        account,
        contacts,
        setCurrentContactId,
        currentContactId,
        opportunities,
        currentOpportunityId,
        setCurrentOpportunityId,
    } = useAccountPage(accountId)
    const loadingRoot = !syncState.account?.didLoad
    const { pinnedActivities, pinnedNotes } = useRootPinned(accountId)
    const { open, history, isLoadingHistory } = usePersonActivities(
        accountId,
        syncState,
        pinnedActivities
    )
    const contactIds = contacts.map((c) => c.Id)
    const oppIds = opportunities.map((o) => o.Id)
    const {
        notes,
        loading: loadingNotesState,
        disabled,
    } = useAccountNotes(
        {
            accountId,
            contactIds,
            opportunityIds: oppIds,
        },
        pinnedNotes
    )
    useEffect(() => {
        visitRoot({ rootId: accountId as AccountId })
    }, [accountId])

    useEffect(() => {
        if (!loadingRoot) {
            const handle = setTimeout(() => {
                countActivity(isInOverlay ? 'record_viewed_overlay' : 'record_viewed_full')
            }, 5000)
            return () => clearTimeout(handle)
        }
    }, [accountId, loadingRoot, isInOverlay])

    const filterFailedMutation = useCallback(
        (f: FailedMutation) => {
            const relevantIds = [accountId, ...contactIds, ...oppIds].filter(isTruthy)
            const matched =
                ['updateObject'].includes(f.mutation.name) &&
                f.target?.id &&
                relevantIds.includes(f.target.id)
            return !!matched
        },
        [accountId, contactIds, oppIds]
    )

    return (
        <IsInOverlayContext.Provider value={{ isInOverlay: !!isInOverlay }}>
            <PersonContextProvider
                rootId={accountId}
                account={account}
                contacts={contacts}
                setCurrentContactId={setCurrentContactId}
                currentContactId={currentContactId}
                opportunities={opportunities}
                currentOpportunityId={currentOpportunityId}
                setCurrentOpportunityId={setCurrentOpportunityId}
            >
                <FailedMutationScope
                    name={isInOverlay ? 'AccountContainerOverlay' : 'AccountContainer'}
                    filter={filterFailedMutation}
                >
                    {account && (
                        <AccountErrorContainer
                            account={account}
                            contacts={contacts}
                            opportunities={opportunities}
                        />
                    )}
                    <PersonPage
                        rootId={accountId}
                        loadingRoot={loadingRoot}
                        openActivities={open}
                        historyActivities={history}
                        loadingActivityHistory={isLoadingHistory}
                        notes={notes}
                        loadingNotes={loadingNotesState}
                        notesDisabled={disabled}
                        isInOverlay={isInOverlay}
                    />
                </FailedMutationScope>
            </PersonContextProvider>
        </IsInOverlayContext.Provider>
    )
}

function AccountErrorContainer({
    account,
    contacts,
    opportunities,
}: {
    account: AccountModel
    contacts: ContactModel[]
    opportunities: OpportunityModel[]
}) {
    const { failedMutations, discard } = useFailedMutationScope()
    const {
        failedMutations: [failedMutation, ...remainingFailedMutations],
        next: selectNextFailedMutation,
    } = useCurrentFailedMutation(failedMutations)

    const salesObject = useMemo(() => {
        const openId = failedMutation?.target?.id
        if (!openId) {
            return null
        }
        return (
            opportunities.find((a) => a.Id === openId) ||
            contacts.find((c) => c.Id === openId) ||
            account
        )
    }, [account, contacts, failedMutation, opportunities])

    function close() {
        if (failedMutation) {
            discard(failedMutation.mutationId)
            mutateSObject.discardFailedMutation(failedMutation.mutationId)
        }
        if (remainingFailedMutations.length) {
            Promise.all(
                remainingFailedMutations.map((mut) => {
                    discard(mut.mutationId)
                    return mutateSObject.discardFailedMutation(mut.mutationId)
                })
            )
        }
    }
    function next() {
        if (failedMutation) {
            discard(failedMutation.mutationId)
            mutateSObject.discardFailedMutation(failedMutation.mutationId)
        }
        selectNextFailedMutation()
    }

    return salesObject ? (
        <MutationErrorModal
            mutation={failedMutation}
            nextMutations={remainingFailedMutations}
            salesObject={salesObject!}
            close={close}
            next={next}
        />
    ) : null
}

export function useAccountPage(accountId: string) {
    const { account, opportunities, contacts } = useAccountData(accountId)

    const oldContactKey = `account#${accountId}:recent:contact`
    const contactKey = `lf:${oldContactKey}`
    const firstContactId = contacts[0]?.Id
    const getDefaultContact = useCallback(() => {
        return (
            localStorage.getItem(contactKey) ||
            localStorage.getItem(oldContactKey) ||
            firstContactId
        )
    }, [oldContactKey, contactKey, firstContactId])
    const [currentContactId, setCurrentContactId] = useUrlQueryState('contactId', {
        replace: true,
        defaultValue: getDefaultContact,
    })
    useEffect(() => {
        if (currentContactId) {
            localStorage.setItem(contactKey, currentContactId)
        }
    }, [contactKey, currentContactId])

    const oldOpportunityKey = `account#${accountId}:recent:opportunity`
    const opportunityKey = `lf:${oldOpportunityKey}`
    const firstOpportunityId = opportunities[0]?.Id
    const getDefaultOpportunity = useCallback(() => {
        return (
            localStorage.getItem(opportunityKey) ||
            localStorage.getItem(oldOpportunityKey) ||
            firstOpportunityId
        )
    }, [oldOpportunityKey, opportunityKey, firstOpportunityId])
    const [currentOpportunityId, setCurrentOpportunityId] = useUrlQueryState('opportunityId', {
        replace: true,
        defaultValue: getDefaultOpportunity,
    })
    useEffect(() => {
        if (currentOpportunityId) {
            localStorage.setItem(opportunityKey, currentOpportunityId)
        }
    }, [currentOpportunityId, opportunityKey])

    const contact = contacts.find((c) => c.Id === currentContactId) || contacts[0]
    const opportunity = opportunities.find((c) => c.Id === currentOpportunityId) || opportunities[0]

    const historyStore = useHistoryStore()
    const historyItem = useMemo(
        () => ({
            Id: get(account, 'Id'),
            Name: get(contact, 'Name'),
            AvatarUrl: get(contact, 'AvatarUrl'),
            Company: get(account, 'Name'),
            ContactId: get(contact, 'Id'),
            SObjectType: 'Account',
            Website: get(account, 'Website'),
        }),
        [account, contact]
    )
    useEffect(() => {
        if (historyItem.Id) {
            historyStore.updateSearchNavigationHistory(historyItem as SearchResult)
        }
    }, [historyItem, historyStore])

    return {
        contact,
        account,
        contacts,
        setCurrentContactId,
        currentContactId: contact?.Id || currentContactId,
        opportunity,
        opportunities,
        setCurrentOpportunityId,
        currentOpportunityId: opportunity?.Id || currentOpportunityId,
    }
}

type AccountPageData = {
    account: AccountModel | null
    opportunities: OpportunityModel[]
    contacts: ContactModel[]
    initial: boolean
}
function useAccountData(id: string) {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            const [account, opps, contacts] = await Promise.all([
                tx.get([Prefix.Account, id].join('/')),
                tx.scan({ indexName: 'opportunitiesByAccountId', prefix: id }).values().toArray(),
                tx.scan({ indexName: 'contactsByAccountId', prefix: id }).values().toArray(),
            ])

            return {
                account: account ?? null,
                opportunities: opps,
                contacts: contacts,
                initial: false as boolean,
            }
        },
        { account: null, opportunities: [], contacts: [], initial: true },
        [id]
    ) as AccountPageData
}

import { Button } from '@laserfocus/ui/beam'
import { AddOutlinedIcon } from '@laserfocus/ui/icons'

interface AddNoteButtonProps {
    createNewNote(): void
    className?: string
}

export function AddNoteButton({ createNewNote, className }: AddNoteButtonProps) {
    return (
        <Button
            size="small"
            variant="tertiary"
            iconComponent={AddOutlinedIcon}
            className={className}
            onClick={createNewNote}
        >
            Add Note
        </Button>
    )
}

import { useCallback } from 'react'

import { logActivity } from '@laserfocus/client/data-access-shared'
import { useUserId } from '@laserfocus/client/feature-auth'

import {
    usePerson,
    useCurrentOpportunity,
    useAccount,
    useRootId,
} from '../../modules/PersonContext'

type LogActivityProps = {
    subject?: string
    description?: string
    type?: string | null
    priority?: string
}

export default function useLogContactActivity() {
    const person = usePerson()
    const account = useAccount()
    const opportunity = useCurrentOpportunity()
    const rootId = useRootId()

    const whatId = opportunity?.Id || account?.Id
    const whoId = person?.Id
    const accountId = account?.Id
    const ownerId = useUserId()

    const action = useCallback(
        (update: LogActivityProps) => {
            const input = {
                ...update,
                type: update.type || undefined,
                rootId,
                whatId,
                whoId,
                accountId,
                ownerId,
            }
            return logActivity(input)
        },
        [rootId, whatId, whoId, accountId, ownerId]
    )
    return action
}

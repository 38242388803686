import { MessageScreen } from '@laserfocus/client/ui-shared'
import { useBodyClassName } from '@laserfocus/ui/util-react'

export function AccountNotActivePage() {
    useBodyClassName('transition-colors duration-[3s] bg-red-100')

    return (
        <MessageScreen title="Account not active">
            It seems that your account is not active. Please reach out to{' '}
            <pre>jarvis@laserfocus.io</pre> if you want to activate your account.
        </MessageScreen>
    )
}

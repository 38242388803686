import { useReducer } from 'react'
import { isSameMinute } from 'date-fns'

import { useAutoFocusedRef, useScrolledIntoViewRef } from '@laserfocus/ui/util-react'
import { CommandLine, DatepickerCalendar, DropdownButton } from '@laserfocus/ui/beam'
import { useDatepicker } from '@laserfocus/vendor/react-datepicker-hooks'
import { create, DateSuggest } from '@laserfocus/vendor/time-suggest'
import { getLocale } from '@laserfocus/ui/util-locale'
import { DEFAULT_TIME } from '@laserfocus/vendor/react-datepicker-hooks'
import { useHotKey, useListNavigation } from '@laserfocus/ui/util-keyboard'

import { useSelectOnMount } from '../useSelectOnMount'
import { StepComponentProps, StaticStepState } from '../step-component-types'

export interface StepSelectDateState extends StaticStepState {
    inputValue: string
    selectedDate: Date
}
enum SelectionAction {
    SearchtextChange = 'searchtextChange',
    SelectedDateChage = 'selectedDateChage',
    SelectedSuggestionIndexChange = 'selectedSuggestionIndexChange',
}

export function StepSelectDate({ stepState, submit }: StepComponentProps<StepSelectDateState>) {
    const [{ searchText, selectedDate, selectedSuggestionIndex, suggestions }, dispatchSelection] =
        useReducer(selectionReducer, stepState, getInitialSelection)

    const datepickerModel = useDatepicker({
        date: selectedDate,
        onDateChange: (date) =>
            dispatchSelection({
                type: SelectionAction.SelectedDateChage,
                nextDate: date,
            }),
    })

    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)
    useSelectOnMount(inputRef)

    function handleSubmit(date: Date | null) {
        if (!selectedDate) {
            return
        }

        submit({
            breadCrumbLabel: '',
            inputValue: searchText,
            selectedDate,
        })
    }

    useListNavigation(
        (updater) =>
            dispatchSelection({
                type: SelectionAction.SelectedSuggestionIndexChange,
                nextIndex: updater,
            }),
        suggestions.length
    )
    useHotKey(
        'enter',
        (event) => {
            event.preventDefault()
            handleSubmit(selectedDate)
        },
        { global: true }
    )

    const scrolledIntoViewRef = useScrolledIntoViewRef<HTMLButtonElement>(selectedSuggestionIndex)

    return (
        <>
            <CommandLine.Input
                ref={inputRef}
                placeholder="Try 9am, 3 days, mar 13"
                aria-label="Search date suggestions"
                value={searchText}
                onChange={(event) =>
                    dispatchSelection({
                        type: SelectionAction.SearchtextChange,
                        nextSearchText: event.target.value,
                    })
                }
            />
            <CommandLine.Content
                // Height of DatepickerCalendar is 301px
                className="grid grid-cols-[1fr,auto] box-content h-[18.8125rem]"
            >
                <div className="overflow-y-auto pr-2 py-2 -my-2 border-r border-white/10 content-start">
                    <div className="grid gap-1">
                        {suggestions.map(({ formatted, date }, index) => {
                            const isSelected = index === selectedSuggestionIndex
                            const select = () =>
                                dispatchSelection({
                                    type: SelectionAction.SelectedSuggestionIndexChange,
                                    nextIndex: index,
                                })

                            return (
                                <DropdownButton
                                    ref={isSelected ? scrolledIntoViewRef : undefined}
                                    key={formatted}
                                    isHighlighted={isSelected}
                                    onClick={() => handleSubmit(date)}
                                    onMouseMove={isSelected ? undefined : select}
                                    onFocus={select}
                                    noHoverStyles
                                    className="transition-none"
                                >
                                    {formatted}
                                </DropdownButton>
                            )
                        })}
                    </div>
                </div>
                <DatepickerCalendar
                    {...datepickerModel}
                    selectedDate={selectedDate}
                    onDateSubmit={handleSubmit}
                    className="flex-none pl-2"
                />
            </CommandLine.Content>
        </>
    )
}

interface SelectionState {
    searchText: string
    selectedDate: Date | null
    selectedSuggestionIndex: number
    suggestions: DateSuggest[]
}
interface SearchtextAction {
    type: SelectionAction.SearchtextChange
    nextSearchText: string
}
interface SelectedDateAction {
    type: SelectionAction.SelectedDateChage
    nextDate: Date
}
interface SelectedSuggestionIndexAction {
    type: SelectionAction.SelectedSuggestionIndexChange
    nextIndex: number | ((index: number) => number)
}
type SelectionActionPayload = SearchtextAction | SelectedDateAction | SelectedSuggestionIndexAction

function selectionReducer(state: SelectionState, action: SelectionActionPayload) {
    const { selectedDate, selectedSuggestionIndex, suggestions } = state

    switch (action.type) {
        case SelectionAction.SearchtextChange: {
            const { nextSearchText } = action

            const suggestions = getDateSuggestions(nextSearchText)

            return {
                searchText: nextSearchText,
                selectedDate: suggestions[0]?.date || selectedDate,
                selectedSuggestionIndex: 0,
                suggestions,
            }
        }

        case SelectionAction.SelectedDateChage: {
            const { nextDate } = action

            if (selectedDate && isSameMinute(nextDate, selectedDate)) {
                return state
            }

            return {
                ...state,
                selectedDate: nextDate,
                selectedSuggestionIndex: suggestions.findIndex(({ date }) =>
                    isSameMinute(date, nextDate)
                ),
            }
        }

        case SelectionAction.SelectedSuggestionIndexChange: {
            const nextIndexUpdater = action.nextIndex
            const nextIndex =
                typeof nextIndexUpdater === 'function'
                    ? nextIndexUpdater(selectedSuggestionIndex)
                    : nextIndexUpdater

            if (nextIndex === selectedSuggestionIndex) {
                return state
            }

            return {
                ...state,
                selectedDate: suggestions[nextIndex]?.date || selectedDate,
                selectedSuggestionIndex: nextIndex,
            }
        }

        default: {
            return state
        }
    }
}

function getInitialSelection(stepState: StepSelectDateState | undefined): SelectionState {
    const { inputValue = '', selectedDate } = stepState || {}

    const suggestions = getDateSuggestions(inputValue)
    const nextSelectedDate = selectedDate || suggestions[0]?.date || null

    return {
        searchText: inputValue,
        selectedDate: nextSelectedDate,
        selectedSuggestionIndex: nextSelectedDate
            ? suggestions.findIndex(({ date }) => isSameMinute(date, nextSelectedDate))
            : -1,
        suggestions,
    }
}

function getDateSuggestions(value: string) {
    const suggestDates = create({
        locale: getLocale(),
        default_time: DEFAULT_TIME,
        allow_past_dates: false,
        allow_someday: false,
    })

    if (value) {
        return suggestDates(value)
    }

    return [
        suggestDates('in 1 hour')[0],
        suggestDates('today')[0],
        suggestDates('tomorrow')[0],
        suggestDates('next week')[0],
        suggestDates('in a month')[0],
    ]
}

import { useMemo, useState } from 'react'
import { pick, sum } from 'lodash'
import { observer } from 'mobx-react-lite'

import { ActivityUtil, EventModel, TaskModel } from '@laserfocus/client/model'
import { NewActivity, TaskId } from '@laserfocus/shared/models'
import { Button } from '@laserfocus/ui/beam'
import { ArrowDownOutlinedIcon } from '@laserfocus/ui/icons'
import {
    EverboardingHintConfig,
    useRegisterEverboardingHint,
} from '@laserfocus/client/feature-everboarding'

import { BulkEditBar } from './BulkEditBar'
import { TaskListItem } from './TaskListItem'

interface TaskListProps {
    activities: Array<TaskModel | EventModel>
    pathPrefix?: 'today' | 'overdue' | 'upcoming'
    overlayActivity?: NewActivity
    toggleOverlayForActivity?(id: string): void
    completeTask: (id: TaskId) => Promise<void>
    dismissTask: (id: TaskId) => void
    showBulkEditHint?: boolean
    searchTerm?: string
}

export const TaskList = observer(function TaskList({
    searchTerm,
    activities,
    pathPrefix,
    overlayActivity,
    toggleOverlayForActivity,
    completeTask,
    dismissTask,
}: TaskListProps) {
    const {
        selectedActivities,
        areAllActivitiesSelected,
        isActivitySelected,
        toggleActivitySelection,
        selectAll,
        unselectAll,
    } = useActivitySelection(activities)
    const [maxAmountOfTasks, setMaxAmountOfTasks] = useState(50)

    const openActivitiesCount = activities.filter((a) => ActivityUtil.isOpen(a)).length
    useBulkEditEverboarding(
        openActivitiesCount,
        Boolean(overlayActivity),
        selectedActivities.length
    )

    return (
        <div className="grid gap-2 grid-cols-1">
            {activities.slice(0, maxAmountOfTasks).map((activity, idx) => {
                return (
                    <TaskListItem
                        key={activity.Id}
                        activity={activity}
                        pathPrefix={pathPrefix}
                        isSelected={isActivitySelected(activity)}
                        isInOverlay={activity.Id === overlayActivity?.Id}
                        toggleSelect={() => toggleActivitySelection(activity)}
                        completeTask={completeTask}
                        dismissTask={dismissTask}
                        toggleOverlayForActivity={toggleOverlayForActivity}
                        listIndex={idx}
                        searchTerm={searchTerm}
                    />
                )
            })}
            {maxAmountOfTasks < activities.length && (
                <div className="py-4 grid justify-center">
                    <Button
                        variant="tertiary"
                        iconComponent={ArrowDownOutlinedIcon}
                        iconPosition="right"
                        onClick={() => setMaxAmountOfTasks((amount) => amount + 50)}
                    >
                        Show more
                    </Button>
                </div>
            )}
            {selectedActivities.length > 0 && (
                <BulkEditBar
                    selectedActivities={selectedActivities}
                    areAllActivitiesSelected={areAllActivitiesSelected}
                    selectAll={selectAll}
                    unselectAll={unselectAll}
                    completeTask={completeTask}
                />
            )}
        </div>
    )
})

function useActivitySelection(activities: NewActivity[]) {
    const [selectedActivityIds, setSelectedActivityIds] = useState<
        Partial<Record<string, boolean>>
    >({})

    const taskActivities = activities.filter((a) => ActivityUtil.isTask(a))

    function isActivitySelected(activity: NewActivity) {
        return Boolean(selectedActivityIds[activity.Id])
    }

    function toggleActivitySelection(activity: NewActivity) {
        setSelectedActivityIds((activities) => ({
            ...activities,
            [activity.Id]: !activities[activity.Id],
        }))
    }

    function selectAll() {
        setSelectedActivityIds(Object.fromEntries(taskActivities.map(({ Id }) => [Id, true])))
    }

    function unselectAll() {
        setSelectedActivityIds({})
    }

    const selectedActivities = taskActivities.filter(isActivitySelected)
    const areAllActivitiesSelected =
        taskActivities.length > 0 && selectedActivities.length === taskActivities.length

    return {
        selectedActivities,
        areAllActivitiesSelected,
        isActivitySelected,
        toggleActivitySelection,
        selectAll,
        unselectAll,
    }
}

function useBulkEditEverboarding(
    activityCount: number,
    overlayOpen: boolean,
    selectedCount: number
) {
    const bulkEditHint = useMemo((): EverboardingHintConfig => {
        return {
            name: 'everboarding_activity_list_bulk_edit',
            shouldShow: (counters) => {
                const activityNames: Array<keyof typeof counters> = [
                    'completeTask',
                    'rescheduleTask',
                    'rescheduleEvent',
                ]
                const didSelectAll = counters?.everboarding_activity_list_bulk_edit_select_all || 0
                const listActivity = sum(Object.values(pick(counters, ...activityNames)))
                return (
                    Boolean(activityCount >= 5) && !overlayOpen && listActivity > 5 && !didSelectAll
                )
            },
        }
    }, [activityCount, overlayOpen])
    const bulkEditSelectAllHint = useMemo((): EverboardingHintConfig => {
        return {
            name: 'everboarding_activity_list_bulk_edit_select_all',
            shouldShow: (counters) => {
                return !overlayOpen && selectedCount >= 2
            },
        }
    }, [overlayOpen, selectedCount])
    useRegisterEverboardingHint(bulkEditHint)
    useRegisterEverboardingHint(bulkEditSelectAllHint)
}

import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

import { Button } from '@laserfocus/ui/beam'
import { SalesforceLogoIcon } from '@laserfocus/ui/icons'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { useRootStore } from '@laserfocus/client/root-store-context'

import { OnboardingLayout } from '../shared/OnboardingLayout'
import { EnterpriseSecureLink } from '../shared/EnterpriseSecureLink'

export function LoginLandingPage() {
    const navigate = useNavigate()
    const rootStore = useRootStore<{ isAuthenticated: boolean }>()

    useEffect(() => {
        if (rootStore.isAuthenticated) {
            navigate(RoutePaths.Home)
        }
    }, [navigate, rootStore.isAuthenticated])

    if (rootStore.isAuthenticated) {
        return null
    }
    return (
        <OnboardingLayout
            showSignupNotice
            headerButton={
                <Button as={Link} variant="tertiary" size="small" to={RoutePaths.Signup}>
                    Not a user yet? Sign up instead
                </Button>
            }
        >
            <div className="grid gap-8">
                <div className="grid gap-2 text-center">
                    <h1 className="font-bold text-3xl font-serif">Welcome back to Laserfocus 🚀</h1>
                    <p className="text-xl">
                        Log in and bring a fresh breeze of air to your daily life.
                    </p>
                </div>
                <div className="grid gap-4 justify-items-center">
                    <Button
                        as={Link}
                        to="/login"
                        variant="primary"
                        size="large"
                        className="w-full max-w-xs"
                    >
                        <div className="grid grid-flow-col gap-2 items-center justify-center">
                            <SalesforceLogoIcon className="h-4" />
                            Log in with Salesforce
                        </div>
                    </Button>
                    <div className="text-grey-700/60 text-center">or</div>
                    <Button
                        as={Link}
                        to="/signup"
                        size="large"
                        variant="secondary"
                        className="w-full max-w-xs text-center"
                    >
                        Sign up
                    </Button>
                    <EnterpriseSecureLink />
                </div>
            </div>
        </OnboardingLayout>
    )
}

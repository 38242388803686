import { twMerge } from 'tailwind-merge'

import {
    AccountFilledIcon,
    ContactFilledIcon,
    InfoFilledIcon,
    LeadFilledIcon,
    OpportunityFilledIcon,
} from '@laserfocus/ui/icons'

export function LoadingCards({ amount = 7 }: { amount?: number }) {
    return (
        <>
            {new Array(amount).fill('').map((a, idx) => (
                <div
                    key={idx}
                    aria-busy="true"
                    aria-live="polite"
                    className="bg-grey-700-opaque-5 rounded-[0.625rem] w-full h-[68px]"
                />
            ))}
        </>
    )
}

interface SObjectAvatarProps {
    sobject: 'Opportunity' | 'Lead' | 'Account' | 'Contact' | string
}
export function SObjectAvatar({ sobject }: SObjectAvatarProps) {
    switch (sobject) {
        case 'Opportunity':
            return (
                <Avatar className="bg-green-50">
                    <OpportunityFilledIcon className="w-[20px] h-[20px]" />
                </Avatar>
            )
        case 'Account':
            return (
                <Avatar className="bg-blue-50">
                    <AccountFilledIcon className="w-[20px] h-[20px]" />
                </Avatar>
            )
        case 'Lead':
            return (
                <Avatar>
                    <LeadFilledIcon className="w-[20px] h-[20px]" />
                </Avatar>
            )
        case 'Contact':
            return (
                <Avatar className="bg-yellow-50">
                    <ContactFilledIcon className="w-[20px] h-[20px]" />
                </Avatar>
            )
        default:
            return (
                <Avatar>
                    <InfoFilledIcon className="w-[20px] h-[20px]" />
                </Avatar>
            )
    }
}

export function Avatar({ children, className }: { children: React.ReactNode; className?: string }) {
    return (
        <div
            className={twMerge(
                'bg-grey-700/5 rounded-full w-11 h-11 grid place-items-center text-[1.5rem] leading-none',
                className
            )}
        >
            {children}
        </div>
    )
}

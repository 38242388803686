import { Link } from 'react-router-dom'

import { MessageScreen } from '@laserfocus/client/ui-shared'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { Button } from '@laserfocus/ui/beam'
import { useBodyClassName } from '@laserfocus/ui/util-react'

export function WrongIdPage() {
    useBodyClassName('transition-colors duration-[3s] bg-red-100')

    return (
        <MessageScreen
            title="Wrong Id"
            action={
                <Button as={Link} to={RoutePaths.Home} variant="primary">
                    Go to the beginning of your worklist
                </Button>
            }
        >
            Sorry, we haven't found what you were looking for. 👀
        </MessageScreen>
    )
}

export default WrongIdPage

import type { ObjectPool } from '@laserfocus/client/data-layer'

import { useRootStore } from './RootStoreContext'

export type RootStoreDependencies = {
    objectPool: ObjectPool
}

export function useObjectPool(): ObjectPool {
    const rootStore = useRootStore<RootStoreDependencies>()
    return rootStore.objectPool
}

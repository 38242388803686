import { set, forEach } from 'lodash'

import {
    LeadDTO,
    AccountDTO,
    ContactDTO,
    ActivityDTO,
    OpportunityDTO,
    NewTask,
    NewEvent,
    isLead,
    TaskDTO,
    NewTaskSchema,
    NewEventSchema,
    EventDTO,
    LeadModel,
    NullToUndefined,
    OpportunityModel,
    ContactModel,
    AccountModel,
    CustomFieldsDTO,
} from '@laserfocus/shared/models'
import { d } from '@laserfocus/shared/decoder'
import { logger } from '@laserfocus/ui/logger'

type ActivityHolderDTO = {
    LastActivityDate?: string | null
    OpenActivities?: ActivityDTO[]
    ActivityHistories?: ActivityDTO[]
    Tasks?: ActivityDTO[]
    Events?: ActivityDTO[]
}

export type NormalizedData = Record<
    string,
    | ReturnType<typeof deserializeLead>
    | ReturnType<typeof deserializeAccount>
    | ReturnType<typeof deserializeOpportunity>
    | ReturnType<typeof deserializeContact>
>

export function normalizeLeads(leads: LeadDTO[], combined: NormalizedData = {}): NormalizedData {
    leads.forEach((l) => normalizeLead(l, combined))
    return combined
}

export function normalizeLead(input: LeadDTO, result: NormalizedData = {}): NormalizedData {
    const lead = deserializeLead(input)
    set(result, lead.Id, lead)
    normalizeAddActivities(input, result)
    return result
}

export function normalizeActivity(input: ActivityDTO, result: NormalizedData = {}): NormalizedData {
    if (input.__typename === 'Task') {
        const task = taskDTOtoTask(input)
        if (task) {
            set(result, task.Id, task)
        }
    } else if (input.__typename === 'Event') {
        const event = eventDTOtoEvent(input)
        if (event) {
            set(result, event.Id, event)
        }
    }
    return result
}

export function normalizeActivities(
    input: ActivityDTO[],
    result: NormalizedData = {}
): NormalizedData {
    forEach(input, (a: ActivityDTO) => normalizeActivity(a, result))
    return result
}

export function normalizeAddActivities(
    input: ActivityHolderDTO,
    result: NormalizedData = {}
): NormalizedData {
    input.OpenActivities && normalizeActivities(input.OpenActivities, result)
    input.ActivityHistories && normalizeActivities(input.ActivityHistories, result)
    input.Tasks && normalizeActivities(input.Tasks, result)
    input.Events && normalizeActivities(input.Events, result)
    return result
}

export function normalizeAccount(input: AccountDTO, result: NormalizedData = {}): NormalizedData {
    const account = deserializeAccount(input)
    set(result, account.Id, account)
    normalizeAddActivities(input, result)
    input.Contacts && normalizeContacts(input.Contacts, result)
    input.Opportunities && normalizeOpportunities(input.Opportunities, result)
    return result
}

export function normalizeAccounts(
    accounts: AccountDTO[],
    combined: NormalizedData = {}
): NormalizedData {
    accounts.forEach((a) => normalizeAccount(a, combined))
    return combined
}
export function normalizeContact(input: ContactDTO, result: NormalizedData = {}): NormalizedData {
    const contact = deserializeContact(input)
    set(result, contact.Id, contact)
    input.Account && normalizeAccount(input.Account, result)
    normalizeAddActivities(input, result)
    return result
}

export function normalizeContacts(
    contacts: ContactDTO[],
    combined: NormalizedData = {}
): NormalizedData {
    contacts.forEach((l) => normalizeContact(l, combined))
    return combined
}

export function normalizeOpportunity(
    input: OpportunityDTO,
    result: NormalizedData = {}
): NormalizedData {
    const opportunity = deserializeOpportunity(input)
    set(result, opportunity.Id, opportunity)
    input.Account && normalizeAccount(input.Account, result)
    normalizeAddActivities(input, result)
    return result
}

export function normalizeOpportunities(
    opps: OpportunityDTO[],
    combined: NormalizedData = {}
): NormalizedData {
    opps.forEach((l) => normalizeOpportunity(l, combined))
    return combined
}

function taskDTOtoTask(dto: TaskDTO): NewTask | undefined {
    const leadId = isLead(dto.WhoId) ? dto.WhoId : undefined

    const data = {
        ...dto,
        ActivityDate: dto.ActivityDateTime,
        ReminderDateTime: dto.ReminderDateTime || dto.ActivityDateTime,
        RootId: dto.AccountId || leadId,
        CreatedById: dto.CreatedById ?? undefined,
        LastModifiedById: dto.LastModifiedById ?? undefined,
    }

    const parseResult = d.decode(data, NewTaskSchema)
    if (d.isSuccess(parseResult)) {
        return parseResult.data as NewTask
    }
    if (d.isFailure(parseResult)) {
        const enriched = d.enrichZodError(data, parseResult.error)
        logger.error(enriched)
    }
}

function eventDTOtoEvent(dto: EventDTO): NewEvent | undefined {
    const leadId = isLead(dto.WhoId) ? dto.WhoId : undefined

    const data = {
        ...dto,
        RootId: dto.AccountId || leadId,
    }
    const parseResult = d.decode(data, NewEventSchema)
    if (d.isSuccess(parseResult)) {
        return parseResult.data as NewEvent
    }
    if (d.isFailure(parseResult)) {
        const enriched = d.enrichZodError(data, parseResult.error)
        logger.error(enriched)
    }
}

function deserializeLead(dto: LeadDTO): LeadModel {
    const transformed = dto as NullToUndefined<LeadDTO>
    const {
        __typename,
        CustomFields,
        ActivityHistories,
        OpenActivities,
        Tasks,
        Events,
        Owner,
        ...fields
    } = transformed

    const model: LeadModel = {
        __typename: 'Lead' as const,
        ...fields,
        ...CustomFields,
    }
    return model
}

function deserializeOpportunity(dto: OpportunityDTO): OpportunityModel {
    const transformed = dto as NullToUndefined<OpportunityDTO>
    const {
        __typename,
        CustomFields,
        ActivityHistories,
        OpenActivities,
        Owner,
        Account,
        ...fields
    } = transformed

    const model: OpportunityModel = {
        __typename: 'Opportunity' as const,
        ...fields,
        ...CustomFields,
    }
    return model
}

function deserializeContact(dto: ContactDTO): ContactModel {
    const transformed = dto as NullToUndefined<ContactDTO>
    const { __typename, CustomFields, ActivityHistories, OpenActivities, Account, ...fields } =
        transformed

    const model: ContactModel = {
        __typename: 'Contact' as const,
        ...fields,
        ...CustomFields,
    }
    return model
}

export function serializeOpportunityInput(
    model: Omit<OpportunityModel, 'Id'>
) /*: Omit<OpportunityDTO, '__typename' | 'Id'>*/ {
    const {
        // Id,
        LastModifiedDate,
        attributes,
        AccountId,
        Amount,
        CloseDate,
        CreatedById,
        CreatedDate,
        CurrencyIsoCode,
        ExpectedRevenue,
        IsClosed,
        LastActivityDate,
        LastModifiedById,
        Name,
        OwnerId,
        Probability,
        RecordTypeId,
        StageName,
        UnqualifiedReason,
        ...CustomFields
    } = model
    return {
        // Id,
        LastModifiedDate,
        AccountId,
        Amount,
        CloseDate,
        CreatedById,
        CreatedDate,
        CurrencyIsoCode,
        ExpectedRevenue,
        IsClosed,
        LastActivityDate,
        LastModifiedById,
        Name,
        OwnerId,
        Probability,
        RecordTypeId,
        StageName,
        UnqualifiedReason,
        CustomFields: CustomFields as CustomFieldsDTO,
    }
}

export function serializeContactInput(
    model: ContactModel
) /*: Omit<ContactDTO, '__typename' | 'Id'> */ {
    const {
        Id,
        attributes,
        RecordTypeId,
        OwnerId,
        Name,
        LastModifiedById,
        LastActivityDate,
        CurrencyIsoCode,
        CreatedDate,
        CreatedById,
        AccountId,
        LastModifiedDate,
        AvatarUrl,
        Department,
        Description,
        Email,
        FirstName,
        HomePhone,
        LastName,
        MailingCity,
        MailingCountry,
        MailingGeocodeAccuracy,
        MailingLatitude,
        MailingLongitude,
        MailingPostalCode,
        MailingState,
        MailingStreet,
        MobilePhone,
        OtherCity,
        OtherCountry,
        OtherGeocodeAccuracy,
        OtherLatitude,
        OtherLongitude,
        OtherPhone,
        OtherPostalCode,
        OtherState,
        OtherStreet,
        Phone,
        Title,
        ...CustomFields
    } = model
    return {
        Id,
        RecordTypeId,
        OwnerId,
        Name,
        LastModifiedById,
        LastActivityDate,
        CurrencyIsoCode,
        CreatedDate,
        CreatedById,
        AccountId,
        LastModifiedDate,
        AvatarUrl,
        Department,
        Description,
        Email,
        FirstName,
        HomePhone,
        LastName,
        MailingCity,
        MailingCountry,
        MailingGeocodeAccuracy,
        MailingLatitude,
        MailingLongitude,
        MailingPostalCode,
        MailingState,
        MailingStreet,
        MobilePhone,
        OtherCity,
        OtherCountry,
        OtherGeocodeAccuracy,
        OtherLatitude,
        OtherLongitude,
        OtherPhone,
        OtherPostalCode,
        OtherState,
        OtherStreet,
        Phone,
        Title,
        CustomFields: CustomFields as CustomFieldsDTO,
    }
}

function deserializeAccount(dto: AccountDTO): AccountModel {
    const transformed = dto as NullToUndefined<AccountDTO>
    const {
        __typename,
        CustomFields,
        ActivityHistories,
        OpenActivities,
        Opportunities,
        Contacts,
        Owner,
        Tasks,
        Events,
        ...fields
    } = transformed

    const model: AccountModel = {
        __typename: 'Account' as const,
        ...fields,
        ...CustomFields,
    }
    return model
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ClockOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon fill="none" {...props}>
            <path
                d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 6v6l3 3"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    )
}

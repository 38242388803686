import { generateKeyBetween, generateNKeysBetween } from 'fractional-indexing'
import { sortBy } from 'lodash'
export function sortByOrderKey(map: Record<string, string | null>): string[] {
    return Object.entries(map)
        .sort(([_1, aOrderKey], [_2, bOrderKey]) => {
            if (aOrderKey === bOrderKey) {
                // shuold never happen
                return 0
            }
            if (!aOrderKey && !bOrderKey) {
                return 0
            }
            if (aOrderKey && !bOrderKey) {
                return -1
            }
            if (!aOrderKey && bOrderKey) {
                return 1
            }
            return aOrderKey! < bOrderKey! ? -1 : 1
        })
        .map(([value]) => value)
}

export function getFillForMissingOrderKeys<
    OrderKey extends keyof T,
    Identifier extends keyof T,
    T extends Partial<Record<OrderKey | Identifier, string | null | undefined>>
>(list: T[], orderKey: OrderKey, idField: Identifier) {
    const sorted = sortBy(list, orderKey)
    const firstNullIndex = sorted.findIndex((a) => !a[orderKey])
    const remaining = sorted.slice(firstNullIndex)

    let fixes: Record<string, string> = {}
    // Fix nulls
    if (remaining.length && firstNullIndex !== -1) {
        const lastValue = firstNullIndex > 0 ? sorted[firstNullIndex - 1]![orderKey] : null

        const newSortKeys = generateNKeysBetween(lastValue ?? null, null, remaining.length)

        const nullFixes = Object.fromEntries(
            remaining.map((v, idx) => {
                const newOrderKey = newSortKeys[idx]
                return [v[idField], newOrderKey]
            })
        )
        fixes = nullFixes
    }

    // Fix duplicates
    for (let i = 0; i < sorted.length; i++) {
        const current = sorted[i]
        const previous = sorted[i - 1]
        if (
            current?.[orderKey] &&
            previous?.[orderKey] &&
            current[orderKey] === previous?.[orderKey]
        ) {
            const currentId = current[idField] as string
            const previousId = previous[idField] as string
            const realPrevious = fixes[previousId] || previous?.[orderKey]
            const upcoming = sorted.slice(i)
            const nextDifferent = upcoming.find((rec) => rec[orderKey] !== current[orderKey])
            const nextOrderKey = nextDifferent?.[orderKey] ?? null
            const newKey = generateKeyBetween(realPrevious ?? null, nextOrderKey)
            fixes[currentId] = newKey
        }
    }

    return fixes
}

export function getOrderKeyForMove(
    map: Record<string, string | null>,
    sourceIndex: number,
    targetIndex: number
): string {
    const sorted = sortByOrderKey(map)
    if (sourceIndex === targetIndex) {
        return sorted[sourceIndex]!
    }

    const startOffset = sourceIndex < targetIndex ? 0 : -1

    const before = sorted[targetIndex + startOffset]
    const after = sorted[targetIndex + startOffset + 1]

    const beforeKey = before ? map[before] : null
    const afterKey = after ? map[after] : null

    return generateKeyBetween(beforeKey ?? null, afterKey ?? null)
}

export function getOrderedMap(list: string[]): Record<string, string> {
    const orderKeys = generateNKeysBetween(null, null, list.length)
    return Object.fromEntries(list.map((id, idx) => [id, orderKeys[idx] as string]))
}

export function getOrderKeyForStart(orderMap: Record<string, string>) {
    const sorted = sortByOrderKey(orderMap)
    const firstElement = sorted[0]
    const firstOrderKey = firstElement ? orderMap[firstElement] : null
    return generateKeyBetween(null, firstOrderKey ?? null)
}

export function getOrderKeyForEnd(orderMap: Record<string, string>) {
    const sorted = sortByOrderKey(orderMap)
    const lastItem = sorted[sorted.length - 1]
    const lastItemOrderKey = lastItem ? orderMap[lastItem] : null
    return generateKeyBetween(lastItemOrderKey ?? null, null)
}

/**
 * returns the order key for the position after the provided element
 * if after is not provided, it will be inserted in the beginning
 * if after is provided but not found, it will be inserted in the end
 */
export function getOrderKeyForElementAfter(
    orderMap: Record<string, string>,
    after: string | null | undefined
) {
    const sorted = sortByOrderKey(orderMap)
    if (!after) {
        const firstElement = sorted[0]
        const firstOrderKey = firstElement ? orderMap[firstElement] : null
        return generateKeyBetween(null, firstOrderKey ?? null)
    }
    const previousOrderKey = orderMap[after] ?? null

    if (!previousOrderKey) {
        // By default at the end
        const lastItem = sorted[sorted.length - 1]
        const lastItemOrderKey = lastItem ? orderMap[lastItem] : null
        return generateKeyBetween(lastItemOrderKey ?? null, null)
    }

    const currentIndex = sorted.indexOf(after)
    const nextItem = sorted[currentIndex + 1]

    const nextOrderKey = nextItem ? orderMap[nextItem] : null

    return generateKeyBetween(previousOrderKey, nextOrderKey ?? null)
}

import { useState } from 'react'

import { CommandLine } from '@laserfocus/ui/beam'

import { StaticStepState, StepComponent } from './step-component-types'
interface CommandLineBreadCrumb {
    key: string | number
    label: string
    onClick?(): void
}
interface StepModalProps<StepStates extends readonly StaticStepState[]> {
    /**
     * Add steps as tuple to get decent types out of onSubmit callback.
     * @example
     * <StepModal
     *     steps={[…] as const} // <- insert tuple here
     *     onSubmit={stepStates => …} // <- get tuple out here
     *     …
     * />
     */
    steps: {
        [Key in keyof StepStates]: StepStates[Key] extends StepStates[number]
            ? Step<StepStates[Key]>
            : never
    }
    titleBreadcrumb?: string
    onSubmit(stepStates: StepStates): void
    onCancel(): void
}

export interface Step<StepState extends StaticStepState> {
    title: string
    defaultStepState?: StepState
    component: StepComponent<StepState>
    componentProps?: React.ComponentProps<StepComponent<StepState>>
}

// WARNING! This component is quite messy with types inside in order to have clean types on the outside. Make sure you double check your changes.
export function StepModal<StepStates extends readonly StaticStepState[]>({
    titleBreadcrumb,
    steps,
    onSubmit,
    onCancel,
}: StepModalProps<StepStates>) {
    const [stepStates, setStepStates] = useState(() => steps.map((step) => step.defaultStepState))
    const [currentStepIndex, setCurrentStepIndex] = useState(getInitialStepIndex(stepStates))

    const currentStep = steps[currentStepIndex]

    const CurrentStepComponent = currentStep.component

    function handleStepSubmit(stepState: StaticStepState) {
        const nextStepStates = [...stepStates]
        nextStepStates[currentStepIndex] = stepState

        setStepStates(nextStepStates)

        if (currentStepIndex < steps.length - 1) {
            setCurrentStepIndex(currentStepIndex + 1)
            return
        }

        onSubmit(nextStepStates as unknown as StepStates)
    }

    const breadCrumbs: CommandLineBreadCrumb[] = Array.from(Array(currentStepIndex + 1).keys()).map(
        (element, index, array) => ({
            key: index,
            label:
                index < array.length - 1
                    ? stepStates[index]?.breadCrumbLabel || steps[index].title
                    : steps[index].title,
            onClick: () => setCurrentStepIndex(index),
        })
    )
    titleBreadcrumb &&
        breadCrumbs.unshift({
            key: 'title',
            label: titleBreadcrumb,
        })

    return (
        <CommandLine.Modal
            show
            close={onCancel}
            /**
             * w-[36.25rem]:
             * Currently the only use case over 3 is 4 with adding tasks
             * The breadcrumb has a max-width of 150px and the seperator of 13px
             * The last Breadcrumb is always 78px. This in total can be covered with 580px
             */
            className={steps.length > 3 ? 'w-[36.25rem]' : undefined}
        >
            {breadCrumbs.length > 0 && <CommandLine.BreadCrumbs breadCrumbs={breadCrumbs} />}
            <CurrentStepComponent
                {...currentStep.componentProps}
                stepState={stepStates[currentStepIndex]}
                submit={handleStepSubmit}
            />
        </CommandLine.Modal>
    )
}

function getInitialStepIndex(stepStates: (StaticStepState | undefined)[]) {
    return () => {
        return stepStates.reduce((stepsWithDefaultState, currentDefaultState, index) => {
            const allPrecedingHaveState = stepsWithDefaultState === index
            const hasState = currentDefaultState
            const isLast = index === stepStates.length - 1

            if (allPrecedingHaveState && hasState && !isLast) {
                return stepsWithDefaultState + 1
            }

            return stepsWithDefaultState
        }, 0)
    }
}

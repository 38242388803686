/**
 * We are scoring different categories and then combine a result with the weighted categories
 * Negative results of a category ar boosted (2x) since it indicates an strong non match
 * - Position (Company/Department/Division/Title) 50%
 * - Geo (Country / City) 20%
 * - UserType (Usertype/ProfileId/UserRole) 10%
 * @TODO: score username in usertype if '@' is part of username
 * - LastLogin 20%
 */

import leven from 'leven'
import { subWeeks } from 'date-fns'
import { max, min, sortBy, sum } from 'lodash'

import { User } from '@laserfocus/shared/models'
import { assert } from '@laserfocus/ui/logger'

const DEFAULT_TRESHHOLD = -0.5

export function sortUsers(users: User[], me?: User | null, threshold: number = DEFAULT_TRESHHOLD) {
    const withScore = users.map((u) => ({
        user: u,
        score: score(me ?? null, u),
    }))

    const withoutRejected = withScore.filter(({ score }) => score >= threshold)

    const sorted = sortBy(withoutRejected, 'score').reverse()
    return sorted.map((u) => u.user)
}

/**
 * Scorer scores the distance with [score, maxScore]
 * maxScore is needed to set them later all in relation (and discount 0 points when there are no values)
 */
type Scorer = (me: User | null, coll: User) => [number, number]

type ScoringGroup = {
    score: number
    weight: number
}

export function score(me: User | null, colleague: User): number {
    if (!colleague.IsActive || colleague.UserType !== 'Standard') {
        return REJECT_WEIGHT
    }

    const positionScore = scoreGroup(positionScorers, me, colleague)
    const geoScore = scoreGroup(geoScorer, me, colleague)
    const userTypeScore = scoreGroup(userTypeScorer, me, colleague)
    const lastLoginScore = scoreLastLoginDate(me, colleague)

    const groups = [
        { score: positionScore, weight: 0.5 },
        { score: geoScore, weight: 0.2 },
        { score: userTypeScore, weight: 0.1 },
        { score: lastLoginScore, weight: 0.2 },
    ]
    return getCombinedResult(groups)
}

export function getCombinedResult(groups: ScoringGroup[]) {
    return groups.reduce((total, group) => {
        if (group.score < 0) {
            return total + group.score * group.weight * 2
        }
        return total + group.score * group.weight
    }, 0)
}

const positionScorers: Scorer[] = [
    makePropertySimilarityPenalize('CompanyName', -3),
    makePropertySimilarityScorer('Department', 6, true),
    makePropertySimilarityScorer('Division', 4),
    makePropertySimilarityScorer('Title', 10, true),

    boostUsernameDomain(2),
    boostWords(['Title', 'Username', 'Name'], 'integration', -10),
    boostWords(['Title', 'Username', 'Name'], 'security', -10),
    boostWords(['Title', 'Username', 'Name'], 'api', -10),
    boostWords(['Title', 'Username', 'Name'], 'test', -5),

    boostWords(['Title', 'Department'], 'marketing', -3),
    boostWords(['Title', 'Department'], 'care', -3),
    boostWords(['Title', 'Department'], 'success', -3),
    boostWords(['Title'], 'ceo', -5),

    boostWords(['Title'], 'sales', 6),
]
const userTypeScorer: Scorer[] = [
    // makePropertySimilarityScorer('UserType', 4),
    makePropertySimilarityScorer('ProfileId', 2),
    makePropertySimilarityScorer('UserRoleId', 2),
]

const geoScorer: Scorer[] = [
    makePropertySimilarityScorer('Country', 7),
    makePropertySimilarityScorer('City', 3),
]

export function scoreGroup(scorers: Scorer[], me: User | null, colleague: User) {
    const [score, maxScore] = scorers.reduce(
        ([score, maxScore], scorer) => {
            const [addedScore, addedMaxScore] = scorer(me, colleague)
            return [score + addedScore, maxScore + addedMaxScore]
        },
        [0, 0]
    )
    if (maxScore === 0) {
        if (score < 0) {
            return clamp(score / 10, -1, 0)
        }
        return 0
    }
    return score / maxScore
}

/**
 * Used to score similarity between properties
 * fuzzy => levelstein string distance
 */
export function makePropertySimilarityScorer(
    property: keyof User,
    weight: number,
    fuzzy: boolean = false
) {
    return (me: User | null, colleague: User): [number, number] => {
        const meValue = me?.[property] as string
        const otherValue = colleague[property] as string
        if (!meValue && !otherValue) {
            return [0, 0]
        }

        if ((!meValue && otherValue) || (meValue && !otherValue)) {
            // If I dont have a value, but my colleague only score negative slightly
            return [0, 0.2 * weight]
        }

        if (meValue === otherValue) {
            return [weight, weight]
        }
        if (fuzzy) {
            const distanceScore = fuzzyScore(meValue, otherValue)
            const score = distanceScore * weight
            return [score, weight]
        }
        return [0, weight]
    }
}

/**
 * If matching a property does not mean anything, but a mismatch is quite important
 */
export function makePropertySimilarityPenalize(property: keyof User, weight: number): Scorer {
    assert(weight < 0, 'Penalize should be negative')
    return (me: User | null, coll: User): [number, number] => {
        const meValue = me?.[property] as string | undefined
        const otherValue = coll[property] as string | undefined
        if (!meValue && !otherValue) {
            return [0, 0]
        }
        if (meValue && otherValue && meValue !== otherValue) {
            return [weight, 0]
        }
        // Dont fully penalize if one of them is null
        if ((meValue || otherValue) && meValue !== otherValue) {
            return [weight * 0.1, 0]
        }
        return [0, 0]
    }
}

export function boostWords(properties: Array<keyof User>, word: string, weight: number): Scorer {
    return (me: User | null, colleague: User): [number, number] => {
        if (
            properties.some((prop) => {
                const value = colleague[prop] as string | undefined
                return value?.toLowerCase().includes(word.toLowerCase())
            })
        ) {
            return [weight, 0]
        }
        return [0, 0]
    }
}

export function boostUsernameDomain(weight: number) {
    return (me: User | null, colleague: User): [number, number] => {
        const meUsername = me?.Username
        const otherUsername = colleague?.Username
        if (extractDomain(meUsername) === extractDomain(otherUsername)) {
            return [weight, 0]
        }
        return [0, 0]
    }
}

export function scoreLastLoginDate(me: User | null, colleague: User): number {
    const now = new Date()
    const fourWeeks = subWeeks(now, 4)
    const threeWeeks = subWeeks(now, 3)
    const twoWeeks = subWeeks(now, 2)
    const oneWeeks = subWeeks(now, 1)

    if (!colleague.LastLoginDate) {
        return 0
    }
    const lastLogin = new Date(colleague.LastLoginDate)
    if (lastLogin > oneWeeks) {
        return 1
    } else if (lastLogin > twoWeeks) {
        return 0.6
    } else if (lastLogin > threeWeeks) {
        return 0.3
    } else if (lastLogin > fourWeeks) {
        return 0
    }
    return -1
}

const REJECT_WEIGHT = -1000

export function fuzzyScore(term1: string, term2: string) {
    const terms1 = term1
        .toLowerCase()
        .split(' ')
        .filter((a) => a.length >= 3)
    const terms2 = term2
        .toLowerCase()
        .split(' ')
        .filter((a) => a.length >= 3)

    if (terms1.length === 0 || terms2.length === 0) {
        return 0
    }

    const term1Scores = terms1.map((term1) => {
        const against2 = terms2.map((term2) => {
            const distance = leven(term1, term2)
            const maxDistance = max([term1.length, term2.length]) as number
            return distance / maxDistance
        })
        const bestDistance = min(against2) as number
        return (1 - bestDistance) * term1.length
    })
    const scoreSum = sum(term1Scores)
    const result = scoreSum / terms1.join('').length
    return result
}

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)

function extractDomain(email?: string | null) {
    if (email && email.indexOf('@') > -1) {
        return email.split('@')[1]
    }
}

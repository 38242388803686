import { z } from '@laserfocus/shared/decoder'

import { UserIdSchema } from '../base/id.types'
import { CreateOptimisticSchema, UpdateOptimisticSchema } from '../base/singletable.types'

import { filterConditionSchema } from './filter-conditions-schema'
import {
    aggregationSchema,
    OrgStackInputExtensionSchema,
    sortingSchema,
    StackInputSchema,
    StackTimingSchema,
} from './stack.types'

export const StackUpdateSchema = StackInputSchema.pick({
    title: true,
    color: true,
    description: true,
    icon: true,
    helpUrl: true,
}).partial()

export type StackUpdate = z.infer<typeof StackUpdateSchema>

const BaseUpdate = z.object({
    id: z.string(),
    type: z.enum(['user', 'org']),
    optimistic: UpdateOptimisticSchema,
})
const UserStackBaseUpdate = z.object({
    id: z.string(),
    type: z.literal('user'),
    optimistic: UpdateOptimisticSchema,
})

const OrgStackBaseUpdate = z.object({
    id: z.string(),
    type: z.literal('org'),
    optimistic: UpdateOptimisticSchema,
})

export const StackMutatorSchema = {
    updateStack: BaseUpdate.extend({
        values: StackUpdateSchema,
    }),
    saveStack: z.object({
        id: z.string(),
        type: z.enum(['user', 'org']),
        values: StackInputSchema,
        extension: OrgStackInputExtensionSchema.optional(),
        optimistic: CreateOptimisticSchema,
    }),
    shareStack: z.object({
        userIds: z.array(UserIdSchema),
        stack: StackInputSchema,
    }),

    showColumn: BaseUpdate.extend({
        values: z.object({
            columnName: z.string(),
            position: z.string(),
        }),
    }),
    hideColumn: BaseUpdate.extend({
        values: z.object({
            columnName: z.string(),
        }),
    }),

    deleteStack: UserStackBaseUpdate,
    resetStack: UserStackBaseUpdate,
    deactivateStack: OrgStackBaseUpdate,

    setColumnsOrder: BaseUpdate.extend({
        values: z.object({
            visibleColumnMap: z.record(z.string(), z.string()),
        }),
    }),

    setColumnWidth: BaseUpdate.extend({
        values: z.object({
            columnName: z.string(),
            width: z.number().int().positive(),
        }),
    }),
    setSorting: BaseUpdate.extend({
        values: z.object({
            sorting: sortingSchema,
        }),
    }),
    setTiming: BaseUpdate.extend({
        values: z.object({
            timing: StackTimingSchema.nullable(),
        }),
    }),
    setFilterCondition: BaseUpdate.extend({
        values: z.object({
            condition: filterConditionSchema,
        }),
    }),
    removeFilter: BaseUpdate.extend({
        values: z.object({
            columnName: z.string(),
        }),
    }),
    favoriteStack: UserStackBaseUpdate,
    unfavoriteStack: UserStackBaseUpdate,
    setColumnAggregate: BaseUpdate.extend({
        values: z.object({
            columnName: z.string(),
            aggregation: aggregationSchema,
        }),
    }),
    createStackFromView: z.object({
        stackId: z.string(),
        sobjectType: z.enum(['Lead', 'Account', 'Opportunity', 'Contact']),
        viewId: z.string(),
    }),
    setStackRowColor: UserStackBaseUpdate.extend({
        values: z.object({
            recordId: z.string(),
            color: z.string().nullable(),
        }),
    }),
    setStackRows: UserStackBaseUpdate.extend({
        values: z.object({
            colors: z.record(z.string(), z.string()),
        }),
    }),
}

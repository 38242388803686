// The code in this file originates primarily from jQuery.timeSuggest and secondarily from the jDate Plugin for jQuery. It has been modified by SH.
// `jQuery.timeSuggest.js`: https://gist.github.com/raphaelschaad/2421f51d9cfc0bd5f91b067d0f0bd27d
// `jQuery.date.js`: https://gist.github.com/raphaelschaad/d3959f8ec59411a6d5e9ee395d2c5014

import { format, subDays, subHours, subMinutes, subMonths, subYears } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import capitalize from 'lodash/capitalize'
import clone from 'lodash/clone'
import _each from 'lodash/each'
import flatten from 'lodash/flatten'
import head from 'lodash/head'
import last from 'lodash/last'
import merge from 'lodash/merge'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import sortedUniqBy from 'lodash/sortedUniqBy'
import uniqBy from 'lodash/uniqBy'

const TRACE = false

var timezoneService = require('./timezones')

// Tue, 02 Oct 2096 07:06:40 GMT
// Far enough in the future that no one will realistically set a reminder to this time.
// Also has the nice property that it is easy to detect.
const SOMEDAY = 4000000000000

// Jquery.each inverses cb arguments
const each = function (obj, cb) {
    _each(obj, (val, key) => cb(key, val))
}

var suffixes = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    21: 'st',
    22: 'nd',
    23: 'rd',
    31: 'st',
}

/**
 * The purpose of jQuery.timeSuggest is to, given a string, suggest what
 * dates the user might be in the middle of typing.
 *
 * For example: "m" is probably "on Monday", though in may be "in March"
 *
 * The suggestor is divided up into four phases:
 *
 *  $t.tokenize converts the user's string into a list of token objects,
 *  it does so by using $t.suggestors to suggest values and parses of
 *  each chunk of the input stream.
 *
 *  $t.parse converts the token objects into valid parses, using $t.grammar
 *  as the basis for what may be specified and in which order.
 *
 *  $t.interpreters.* then converts each parse into an actual time, and
 *  expands the partial input from the user into a pleasant string that can
 *  be used in an autocompleter, a date time object that represents the
 *  time we think they meant, and also a confidence score that represents
 *  how likely it is that this is the case.
 *
 *  $t.output then selects the most confident of these, and shows the user
 *  the few unique suggestions that are closest to the current time.
 *
 */

// Export jQuery.timeSuggest, and also grab it so
// that we can use it as a namespace to expose
// functions for testing.
//
// opts is an object that can optionally contain:
//  relative_to: A timestamp relative to which parsing will be done.
//  month_before_day: Switch the interpretation of "4/5" to "April 5th" instead
//  of "4th of May"

const $t = function (input, opts) {
    if (!input) {
        input = ''
    }
    $t.opts = opts = merge(merge({}, $t.options), opts)

    // For purposes of consistency
    $t.now = new Date()

    if (opts.relative_to) {
        $t.now.setTime(opts.relative_to)
    }

    if (opts.locale) {
        // If it's just "en", default to "en-US" behavior.
        $t.opts.month_before_day = opts.locale.match(/en/i) || opts.locale.match(/en-US/i)
    }

    if (!input.trim() && opts.default_suggestions.length) {
        return opts.default_suggestions.reduce((acc, input_value) => {
            const suggestions = $t(input_value, merge(opts, { default_suggestions: [] }))
            if (suggestions.length) {
                return [...acc, suggestions[0]]
            }
            return acc
        }, [])
    }

    // Hack around some slow code paths.
    if ((input || '').match(/^[a12]? *$/)) {
        input = 'in ' + input
    }

    // Input overrides
    // 1) Weds is just one off synonym that doesnt prefix match its full name.
    // 2) "weds" returns 0 results without this hack, and hence it totally doesnt create any side effect.
    // 3) "12 noon | 12 midnight" should strip out the number to avoid side effects.

    var input_overrides = {
        Weds: 'wed',
        WEDS: 'wed',
        weds: 'wed',
        '12 noon': 'noon',
        '12 midnight': 'midnight',
        '0000': 'midnight',
        '000': 'midnight',
        q1: 'qone',
        q2: 'qtwo',
        q3: 'qthree',
        q4: 'qfour',
    }
    Object.keys(input_overrides).forEach(function (key) {
        input = input.toLowerCase().replace(key, input_overrides[key])
    })

    // eslint-disable-next-line default-case
    switch (input.toLowerCase().trim()) {
        case 'tod':
        case 'toda':
            input = 'today'
            break
        case 'nig':
        case 'nigh':
        case 'night':
        case 'tn':
            input = 'tonight'
            break
        case 'yes':
        case 'yest':
        case 'yester':
        case 'ys':
            input = 'yesterday'
            break
        default:
        // NOP
    }

    // Snooze autofill for "after" should show "afternoon" #7518
    if ('after'.startsWith(input) && input !== 'a') {
        input = 'afternoon'
    }

    // When typed-in 2018, first suggestion should be 08:18am/pm.
    // We also want to make sure we show the year 2018 as one of the suggestions.
    // This is why we are changing input even before tokenization because
    // 08:18 will create 2 set of tokens.
    // whilst 2018 will create one set of tokens.
    // ref. #4727
    var firstOutput = ''
    if (input.match(/(^|\s)([\d]{3,4})($|[^\d])/)) {
        var firstInput = input
            .split(' ')
            .map(function (token) {
                var match = token.match(/(?:^|\s)([\d]{3,4})(?:$|[^\d])/)
                if (match) {
                    var number = match[1]
                    var splitAt = 1
                    if (number.length === 4) {
                        splitAt = 2
                    }
                    var firstPart = number.substring(0, splitAt),
                        secondPart = number.substring(splitAt),
                        firstPartNumber = parseInt(firstPart),
                        secondPartNumber = parseInt(secondPart)

                    if (
                        firstPartNumber < 24 &&
                        firstPartNumber >= 0 &&
                        secondPartNumber < 60 &&
                        secondPartNumber >= 0
                    ) {
                        return token.replace(match[1], firstPart + ':' + secondPart)
                    }
                }
                return token
            })
            .join(' ')

        if (firstInput !== input) {
            let tokens = $t.tokenize(firstInput)
            let grammar = $t.grammar(opts.month_before_day)
            let parses = $t.parse(tokens, grammar)
            firstOutput = $t.output(parses, grammar).slice(0, 2)
        }
    }

    let tokens = $t.tokenize(input)
    let grammar = $t.grammar(opts.month_before_day)
    let parses = $t.parse(tokens, grammar)
    var secondOutput = $t.output(parses, grammar).slice(0, 3)

    if (firstOutput) {
        secondOutput = uniqBy(
            uniqBy(firstOutput.concat(secondOutput.slice(0, 1)), 'date'),
            'formatted'
        )
    }

    return secondOutput
}

$t.options = {
    // Format results as durations, using 'to' or 'for' as prefixes instead of 'on' or 'in'
    // 'for 2 minutes' - 'in 2 minutes'
    describe_as_duration: false,
    // Custom reference date to be used instead of the value provided by `new Date()`
    relative_to: null,
    // Flag used to determine the date format priority when evaluating absolute dates
    month_before_day: false,
    // Flag used to exclude number suffixes in the formatted result (st, nd, rd, th)
    hide_suffixes: false,
    // Flag used to exclude 'to'/'on' prepositions on absolute dates
    hide_preposition: false,
    // Flag used to include past dates in the final results
    allow_past_dates: false,
    // Array of strings that will be used as input values for suggestions that are computed when no input value is provided
    // For each input the first suggestion value is used in the final result
    default_suggestions: [],
    // Default time value used when no time tokens are parsed from the input
    default_time: { hours: 7, minutes: 0 },
    // Flag used to enable/disable results for queries matching `someday` grammar strings ('someday', 'whenever')
    allow_someday: true,
    //
    minute_resolution: 1,
}

// $t.opts is the options for the current invovation of jQuery.timeSuggest
$t.opts = clone($t.options)

// Ensure this is set, for testing, it will be replaced by a new Date()
// every time jQuery.timeSuggest is called.
$t.now = new Date()

function toTitleCase(string) {
    return string.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

// TODO morning/afternoon, the "monday after next"
// this coming monday, "third", next autumn
// (31st sunday of trinity)
//
// The rules of this grammar consist of space separated tokens,
// where each token must be associated with a suggestor (see
// below).
//
// For a parse to be considered valid, it must be a prefix of a
// rule.
//
// In order to make the matching more flexible, tokens can be
// marked as optional by suffixing them with a ?. This has two
// effects:
//    1, a valid parse may skip over them
//    2, they will not be guessed until the user starts typing
//       a prefix of that token.
//
// Tokens can also be marked as "implying a missing token" by
// suffixing them with a > (must be after the ? if both are present)
//
// This will cause the suggester to suggest the subsequent token,
// even if it would normally be optional. If the subsequent token
// also implies a missing token, then the token after that will be
// guessed.
//

$t.grammar = function (month_before_day) {
    var grammar = {
        // Relative times.
        'in? quantity unit and? quantity?> unit? time?': ['relative', 0.9],
        'in? quantity unit and?> a> unit': ['relative', 0.9],
        'in? a? unit and? quantity unit': ['relative', 0.9],
        'in? a? unit and?> a?> unit?': ['relative', 0.9],

        'quantity unit and? quantity?> unit? time? ago': ['relative', 0.9],
        // Mixed...
        'on? next? dayname daylight?': ['subsequent', 0.9],
        'on? last? dayname daylight?': ['subsequent', 0.9],
        'tomorrow daylight?': ['subsequent', 1.2],
        'this?> daylight?': ['subsequent', 0.3],
        'today>': ['subsequent', 1.2],
        'yesterday>': ['subsequent', 1.0],
        tonight: ['subsequent', 0.8],
        'at?> hour> am?> today': ['subsequent', 1.0, false],
        'last timespan': ['subsequent', 0.9, false],
        'last monthname': ['subsequent', 0.4, false],
        'last quartername': ['subsequent', 0.4, false],
        'next monthname': ['subsequent', 0.4, false],
        'next quartername': ['subsequent', 0.4, false],
        someday: ['subsequent', 0.5],
        'next timespan': ['subsequent', 0.9, false],
        'in? monthname? year': ['subsequent', 0.1, false],
        'in? quartername> year?': ['subsequent', 0.1, false],

        // Absolute time.
        'on? dayname? the? day th? slash? of? month slash?> year?': [
            'absolute',
            month_before_day ? 0.05 : 0.5,
        ],
        'on? dayname? month slash? day th? slash?> year?': [
            'absolute',
            month_before_day ? 0.5 : 0.05,
        ],
        'on? year slash? month slash? day th?': ['absolute', 0.01],
    }

    // Allow most rules to be prefixed or suffixed by a time specification.
    //
    // This can be something like: 7pm 7:30 , at 7, 0730.
    var upgraded = {}
    each(grammar, function (rule, info) {
        if (info[2] === false) {
            upgraded[rule] = info
        } else {
            upgraded['at?> hour colon?> minute? am? ' + rule] = info
            upgraded[rule + ' at?> hour? colon?> minute? am? in? timezone?'] = info
        }
    })
    return upgraded
}

// Each suggestor accepts a token (which is a chunk of the input stream)
// and a number (the same thing through parseInt(,10)) and returns a list
// of token objects.
//
// The first category of tokens are the mixed ones, these have both
// numeric and also textual possible components.
$t.suggestors = {
    // in <quantity> days.
    quantity: function (token, number) {
        if (isNaN(number) && token) {
            var labels = (
                    'one two three four five six seven eight nine ' +
                    'ten eleven twelve thirteen fourteen fifteen sixteen ' +
                    'seventeen eighteen nineteen twenty'
                ).split(' '),
                exact_match = labels.includes(token)
            labels.unshift('')
            var tens = 'ten twenty thirty forty fifty sixty seventy eighty ninety hundred'.split(
                ' '
            )
            tens.forEach((s, i) => (labels[(i + 1) * 10] = s))

            return $t.prefix_filter(token, labels).map(function (label) {
                return {
                    value: label,
                    parsed: labels.indexOf(label),
                    type: 'quantity',
                    // Confidence should be much reduced if the user has typed an exact
                    // shorter token (i.e. "four" is probably not "fourteen").
                    confidence: exact_match && token !== label ? 0.0005 : 0.05,
                }
            })
        } else if (!isNaN(number) && number < 1000 && token.length < 4 && token.charAt(0) !== '0') {
            return [
                {
                    value: token,
                    parsed: number,
                    type: 'quantity',
                },
            ]
        } else {
            return []
        }
    },

    // tomorrow at <hour>
    hour: function (token, number) {
        function intToken(value) {
            return {
                value: value,
                parsed: parseInt(value, 10),
                type: 'hour',
            }
        }
        if (isNaN(number) && token) {
            var named_hours = {
                dawn: '0600',
                breakfast: '0800',
                midday: '1200',
                noon: '1200',
                lunch: '1200',
                teatime: '1500',
                dinner: '1900',
                dusk: '2100',
                midnight: '2400',
            }
            const res = $t.prefix_filter(token, Object.keys(named_hours)).map(function (name) {
                return intToken(named_hours[name])
            })
            TRACE && console.log('suggestor.hour', 1, res)
            return res
        } else {
            // The user has typed 0730, no more completion is needed.
            if (token.length >= 4) {
                if (number <= 2400 && number % 100 < 60) {
                    TRACE && console.log('suggestor.hour', 2, [intToken(token)])
                    return [intToken(token)]
                } else {
                    return []
                }
                // The user has typed 073, this is probably the start of 0730, or 0735
            } else if (token.length === 3) {
                if (number > 240) {
                    return []
                }
                if (number === 240) {
                    TRACE && console.log('suggestor.hour', 3, [intToken(token + '0')])
                    return [intToken(token + '0')]
                } else {
                    TRACE &&
                        console.log('suggestor.hour', 4, [
                            intToken(token + '0'),
                            intToken(token + '5'),
                        ])
                    return [intToken(token + '0'), intToken(token + '5')]
                }
                // If someone ends with at 1, or at 2, we don't want to sneakily
                // autocomplete to at 10 or at 20. i.e. we don't do any prefix
                // matching in that case.
            } else if (token === '1' || token === '2') {
                TRACE &&
                    console.log('suggestor.hour', 5, token, [
                        intToken(number),
                        intToken(number + 12),
                    ])
                return [intToken(number), intToken(number + 12)]
            }

            const first = $t.prefix_filter(
                token,
                (
                    '1 2 3 4 5 6 7 8 9 00 ' +
                    '01 02 03 04 05 06 07 08 09 10 ' +
                    '11 12 13 14 15 16 17 18 19 20 ' +
                    '21 22 23 24'
                ).split(' ')
            )
            const concat = first.concat([((number + 12) % 24).toString()])
            const fallback = concat.map(intToken)
            TRACE && console.log('suggestor.hour', 6, first, concat, fallback)
            return fallback
        }
    },

    // on the <day> of february
    day: function (token, number) {
        if (isNaN(number) && token) {
            var labels = (
                'first second third fourth fifth sixth seventh eighth ninth ' +
                'tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth ' +
                'seventeenth eighteenth nineteenth twentieth'
            ).split(' ')
            return $t.prefix_filter(token, labels).map(function (label) {
                return {
                    value: label,
                    parsed: labels.indexOf(label) + 1,
                    type: 'day',
                    confidence: 0.05,
                }
            })
        } else if (!isNaN(number)) {
            return $t
                .prefix_filter(
                    token,
                    (
                        '1 2 3 4 5 6 7 8 9 ' +
                        '01 02 03 04 05 06 07 08 09 10 ' +
                        '11 12 13 14 15 16 17 18 19 20 ' +
                        '21 22 23 24 25 26 27 28 29 30 31'
                    ).split(' ')
                )
                .map(function (value) {
                    return {
                        value: value,
                        parsed: parseInt(value, 10),
                        type: 'day',
                    }
                })
        } else {
            return []
        }
    },

    // the 3/<month>/2012
    month: function (token, number) {
        if (isNaN(number) || !token) {
            return $t.suggestors.monthname(token, number).map(function (token) {
                return {
                    value: token.value,
                    parsed: token.parsed,
                    type: 'month',
                }
            })
        } else {
            return $t
                .prefix_filter(
                    token,
                    '1 2 3 4 5 6 7 8 9 10 11 12 01 02 03 04 05 06 07 08 09'.split(' ')
                )
                .map(function (value) {
                    return {
                        value: value,
                        parsed: parseInt(value, 10) - 1,
                        type: 'month',
                    }
                })
        }
    },
}

// Number-only types.
each(
    {
        minute: function (token, number) {
            if (token.length > 2 || number >= 60 || number !== Math.round(number)) {
                // disallow 79 or 0045 or .5 as minutes.
                return []
            } else if (token.length === 2) {
                return [token]
            } else if (token.length === 0) {
                return ['00', '30']
            } else if (number < 6) {
                return [token + '0', token + '5']
            } else {
                return ['0' + token]
            }
        },
        year: function (token, number) {
            var this_full_year = $t.now.getFullYear(),
                this_year = this_full_year % 100,
                next_years = [
                    this_year,
                    this_year + 1,
                    this_year + 2,
                    this_full_year,
                    this_full_year + 1,
                    this_full_year + 2,
                ]

            const defaultYearDeviation = 10
            const minYearDeviation = $t.opts.min_date
                ? this_full_year - $t.opts.min_date.getFullYear()
                : defaultYearDeviation
            const maxYearDeviation = $t.opts.max_date
                ? $t.opts.max_date.getFullYear() - this_full_year
                : defaultYearDeviation

            if (
                token.length === 4 &&
                ((number >= this_full_year && number - this_full_year < maxYearDeviation) ||
                    (number <= this_full_year && this_full_year - number < minYearDeviation))
            ) {
                return [token]
            }

            next_years = next_years.map(function (x) {
                return x.toString()
            })
            return $t.prefix_filter(token, next_years)
        },
    },
    function (type, suggestor) {
        $t.suggestors[type] = function (token, number) {
            if (isNaN(number)) {
                return []
            } else {
                return (suggestor(token, number) || []).map(function (value) {
                    return {
                        type: type,
                        value: value,
                        parsed: parseInt(value, 10),
                    }
                })
            }
        }
    }
)

// map one token to another
var tokenAliases = {
    hrs: 'hours',
    mins: 'minutes',
}

// timezone lists
var placePrefixes = []
var timezones = Object.keys(timezoneService.abbreviations).map(function (key) {
    return key.toLowerCase()
})

var priorityTimezones = ['new_york', 'san_francisco', 'paris']

priorityTimezones = priorityTimezones.concat(timezones)

var timezonePlaces = Object.keys(timezoneService.places).map(function (key) {
    var split = key.split(' ')
    if (split.length > 1) {
        placePrefixes.push(split[0].toLowerCase())
    }

    return key.replace(/ /g, '_').toLowerCase()
})

// extract places from timezones and set them as place: timezone in an object, easier to check this later on
var timezonesValid = timezoneService.timezones.reduce(function (result, item, index, array) {
    var match = item
        .match(/\/([^/]*)$/)[1]
        .replace('-', '_')
        .toLowerCase()
    result[match] = item

    // extract prefix if there is one
    var split = match.split('_')
    split.pop()

    placePrefixes.push(split[0])
    return result
}, {})

placePrefixes = Array.from(new Set(placePrefixes))
timezonePlaces = timezonePlaces.concat(Object.keys(timezonesValid))
timezones = timezones.concat(timezonePlaces)

// token only types.
// NOTE: If you want to add symbols here, you also need to add them to $t.split
each(
    {
        unit: ['minutes', 'mins', 'hours', 'hrs', 'days', 'weeks', 'fortnights', 'months', 'years'],
        timespan: ['week', 'month', 'quarter', 'year'],
        dayname: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
        monthname: [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
        ],
        quartername: ['qone', 'qtwo', 'qthree', 'qfour'],
        am: ['am', 'pm'],
        timezone: timezones,
        th: ['st', 'nd', 'rd', 'th'],
        in: ['in', 'after', 'for'],
        ago: ['ago'],
        on: ['on', 'this', 'until', 'to'],
        this: ['this'],
        at: ['at', '@'],
        a: ['a', 'an'],
        and: ['and', '&'],
        the: ['the'],
        next: ['next'],
        last: ['last', 'past', 'previous'],
        yesterday: ['yesterday', 'yes', 'ystr'],
        tomorrow: ['tomorrow', 'tomorow', 'tmrw'],
        today: ['today'],
        tonight: ['tonight'],
        daylight: ['morning', 'afternoon', 'evening', 'night'],
        colon: [':'],
        of: ['of'],
        time: ['time'],
        slash: ['/', '\\', '-'], // date separators.
        someday: ['someday', 'whenever', 'meh', 'shrug'],
    },
    function (type, possibilities) {
        $t.suggestors[type] = function (prefix) {
            return $t.prefix_filter(prefix, possibilities).map(function (value) {
                // support aliasing one token to another
                value = tokenAliases[value] || value

                return {
                    type: type,
                    value: value,
                    parsed: possibilities.indexOf(value),
                }
            })
        }
    }
)

// Given a prefix and a list of items, return those that start with
// the prefix.
$t.prefix_filter = function (prefix, items) {
    return items.filter(function (item) {
        return item.startsWith(prefix)
    })
}

// This creates a time interpreter, which the other interpreters (below)
// use to extract the time information from a timestamp.
$t.timeInterpreter = function (date) {
    var index = {},
        parsed = clone($t.opts.default_time), // {hours: 7, minutes: 0}
        hour_length

    function outputMinutes() {
        var minutes = parsed.hours === 24 ? 0 : parsed.minutes
        return minutes
    }

    return {
        // Add the interpretation of this token to the current time.
        // Returns true if the token was a "time" token, and false
        // if the token was unrelated.
        //
        // In order to make our confidence scoring better, this also
        // keeps track of the positions of some other tokens.
        interpret: function (token, i) {
            // NOTE: We're storing i + 1 here to simplify later code,
            // it's much easier to differentiate n > 0 from undefined than n >= 0.
            if (token.value) {
                var number = token.parsed

                // eslint-disable-next-line default-case
                switch (token.type) {
                    case 'hour':
                        index.hour = i + 1
                        hour_length = token.value.length
                        if (token.value.length === 4) {
                            // 0045
                            parsed.hours = Math.floor(number / 100)
                            parsed.minutes = number % 100
                            if (parsed.minutes) {
                                index.minute = index.minute || index.hour
                            }
                        } else {
                            if (token.value.length === 2) {
                                // 24-hour hour. (07, 23)
                                parsed.hours = number
                            } else {
                                // 12-hour hour, (7, 11) guess am/pm.
                                parsed.hours = number < 8 ? number + 12 : number
                            }
                            parsed.minutes = 0
                        }
                        return true
                    case 'minute':
                        if (index.minute) {
                            return false
                        }
                        index.minute = i + 1
                        parsed.minutes = number
                        return true
                    case 'am':
                        index.am = i + 1
                        if (token.value === 'pm' && parsed.hours < 12) {
                            parsed.hours = parsed.hours + 12
                        } else if (token.value === 'am' && parsed.hours >= 12) {
                            parsed.hours = parsed.hours - 12
                        }
                        return true
                    case 'timezone':
                        index.timezone = i + 1
                        parsed.timezone = token.value
                        return true
                    case 'dayname':
                    case 'day':
                        if (!token.guessed) {
                            index.day = i + 1
                        }
                        break
                    case 'yesterday':
                        index.day = i - 1
                        break
                    case 'tomorrow':
                        index.day = i + 1
                        break
                    case 'this':
                    case 'today':
                        index.day = i + 1
                        break
                    case 'tonight':
                        parsed.daylight = 'night'
                        if (!index.hour) {
                            parsed.hours = 20
                            index.hour = i + 1
                        }
                        index.day = i + 1
                        index.daylight = i + 1
                        break
                    case 'unit':
                        if (token.value === 'days' && !token.guessed) {
                            index.day = i + 1
                        } else if (token.value === 'minutes' || token.value === 'hours') {
                            index.time = i + 1
                        }
                        break
                    case 'daylight':
                        index.daylight = i + 1
                        parsed.daylight = token.value
                        if (!index.hour) {
                            index.hour = i + 1
                            if (parsed.daylight === 'morning') {
                                parsed.hours = 8
                            } else if (parsed.daylight === 'afternoon') {
                                parsed.hours = 13
                            } else if (parsed.daylight === 'evening') {
                                parsed.hours = 20
                            } else if (parsed.daylight === 'night') {
                                parsed.hours = 20
                            }
                        }
                        return true
                }
            }
            return false
        },
        // Adjust the given date so that it represents the information found by
        // the successive calls to interpret. If no time information is found, the
        // time is set to 7am (which is well before the working day starts).
        apply: function (date) {
            if (index.hour && parsed.hours === 24) {
                date.setHours(23)
                date.setMinutes(59)
            } else {
                if (parsed.daylight === 'night' && parsed.hours < 6) {
                    date.setDate(date.getDate() + 1)
                }

                date.setHours(parsed.hours)
                date.setMinutes(outputMinutes())
            }

            if (parsed.timezone) {
                // set hour offset based on timezone date conversion
                var timezoneOffset = $t.getTimezoneOffset(date, parsed.timezone)
                date.setHours(parsed.hours + timezoneOffset)

                // consider decimals in timezoneOffset and update minutes
                var minutes = 60 * (timezoneOffset - Math.floor(timezoneOffset))
                if (minutes > 0) {
                    date.setMinutes(parsed.minutes + parseInt(minutes))
                }

                // we are parsing a date that is not in our local timezone, we need this for formatting the date.
                if (timezoneOffset !== 0) {
                    parsed.timezoneConverted = true
                }
            }

            date.setSeconds(0)
            date.setMilliseconds(0)

            return date
        },
        // Adjust a string of formatted date/time by appending a representation of the
        // time.
        format: function (formatted) {
            var timezoneText = null
            if (parsed.timezone) {
                if ($t.ianaTimezone(parsed.timezone).isPlaceNameMatch) {
                    // this will be a string like san_francisco or hoh_chi_minh
                    timezoneText = parsed.timezone.replace(/_/g, ' ')
                    timezoneText = 'in ' + toTitleCase(timezoneText)
                } else {
                    // this will be abbreviation
                    timezoneText = parsed.timezone.toUpperCase()
                }
            }

            if (index.hour && parsed.hours === 24) {
                return formatted + ' at midnight' + (timezoneText ? ' ' + timezoneText : '')
            }

            if (parsed.daylight) {
                if (formatted === 'today' && parsed.daylight === 'night') {
                    formatted = 'tonight'
                } else if (formatted === 'today') {
                    formatted = 'this ' + parsed.daylight
                } else {
                    formatted = formatted + ' ' + parsed.daylight
                }
            }

            if (index.hour || index.minute) {
                return (
                    formatted +
                    ' at ' +
                    [
                        parsed.hours % 12 ? parsed.hours % 12 : 12,
                        index.minute || index.colon
                            ? ':' + (outputMinutes() < 10 ? '0' + outputMinutes() : outputMinutes())
                            : '',
                        parsed.hours < 12 ? ' am' : ' pm',
                        timezoneText ? ' ' + timezoneText : '',
                    ].join('')
                )
            } else {
                return formatted
            }
        },

        // Return bool indicating that the parsed date is in our local timezone
        timezone: function () {
            return parsed.timezoneConverted
        },

        // Return a factor that represents how confident we are that this time interpretation
        // was valid. This is multiplied into the confidence of the underlying interpreter.
        confidence: function () {
            var confidence = 1
            // There's no way we'll have indicators of time, but no hour.
            if (!index.hour && (index.minute || index.colon || index.am || index.at)) {
                confidence *= 0
            }
            // 0730 does not need minutes as well.
            if (hour_length === 4 && index.colon) {
                confidence *= 0
            }

            if (index.daylight) {
                if (parsed.daylight === 'morning' && parsed.hours > 12) {
                    confidence *= 0
                } else if (
                    parsed.daylight === 'afternoon' &&
                    (parsed.hours < 12 || parsed.hours > 20)
                ) {
                    confidence *= 0
                } else if (parsed.daylight === 'evening') {
                    if (parsed.hours < 15) {
                        confidence *= 0
                    } else if (parsed.hours !== 20) {
                        confidence *= 0.9
                    }
                } else if (parsed.daylight === 'night' && parsed.hours > 6 && parsed.hours < 15) {
                    confidence *= 0
                }
            }

            // Timezone would interfere with am/pm, lets set a low confidence.
            // If we have a priority timezone match, lets bump it up the list.
            // If no hour specified, don't show timezones.
            if (index.timezone) {
                if (!index.hour) {
                    confidence *= 0
                } else if (priorityTimezones.includes(parsed.timezone)) {
                    confidence *= 0.025
                } else {
                    confidence *= 0.01
                }
            }

            // Disallow "in 6 hours at 7 pm"
            if (index.time && index.hour) {
                confidence *= 0
            }

            // "in 2 weeks at 4 pm" seems unlikely without an explicit day.
            if (index.hour && !index.day) {
                confidence *= 0.1
            }

            if (![0, 15, 30, 45].includes(parsed.minutes)) {
                confidence *= 0.1
            }

            // Sleep time!
            if (parsed.hours < 7 || parsed.hours > 19) {
                confidence *= 0.5
            }

            if (hour_length === 4) {
                return confidence * 0.1
            } else if (index.hour) {
                return (
                    confidence *
                    (0.1 +
                        0.15 * (!!index.at + !!index.minute + !!index.am + (index.on > index.hour)))
                )
            } else {
                return confidence
            }
        },
    }
}

// Each interpreter takes a valid parse (emitted by $t.parse)
// and converts it into an output object.
//
// object: {
//     formatted:  // a human-readable representation of the datetime.
//     iso8601:    // a computer-readable representation.
//     date:       // A javascript representation.
//     confidence: // A confidence score (for debugging)
//     rule:       // Which grammar rule (for debugging)
//     tokens:     // The underlying tokens (for debugging)
// }
//
$t.interpreters = {
    // Relative times are things like "in 1 day", "in 10 minutes".
    relative: function (parse) {
        var date = new Date($t.now.getTime()),
            timer = $t.timeInterpreter(date),
            quantity = 1,
            quantity_type = 'a',
            formatted = [],
            target_month,
            iso8601,
            delta = { minutes: 0, hours: 0, days: 0, months: 0, years: 0 },
            confidence = 1,
            last_unguessed_type = null,
            previous_unit = null,
            is_past_date = false
        if (parse.tokens[parse.tokens.length - 1].type === 'ago') {
            is_past_date = true
        }
        parse.tokens.forEach(function (token, index) {
            if (!token.guessed) {
                last_unguessed_type = token.type
            }
            timer.interpret(token, index)
            // eslint-disable-next-line default-case
            switch (token.type) {
                case 'a':
                    quantity = 1
                    quantity_type = 'a'
                    break
                case 'quantity':
                    quantity = token.parsed
                    quantity_type = '1'
                    break
                case 'unit':
                    if (!isNaN(quantity)) {
                        // eslint-disable-next-line default-case
                        switch (token.value) {
                            case 'minutes':
                                delta.minutes += quantity
                                break
                            case 'hours':
                                delta.hours += quantity
                                break
                            case 'days':
                                delta.days += quantity
                                break
                            case 'weeks':
                                delta.days += quantity * 7
                                break
                            case 'fortnights':
                                confidence *= 0.1 // HACK to remove fortnights from the suggestions list.
                                delta.days += quantity * 14
                                break
                            case 'months':
                                delta.months += quantity
                                break
                            case 'years':
                                delta.years += quantity
                                break
                        }
                        var prefix = 'in '
                        if (is_past_date) {
                            prefix = ''
                        }
                        if ($t.opts.describe_as_duration) {
                            prefix = 'for '
                        }
                        formatted.push(
                            formatted.length === 0
                                ? prefix
                                : parse.tokens.length === index + 1
                                ? ' and '
                                : ', '
                        )

                        if (quantity_type === 'a') {
                            formatted.push(token.value === 'hours' ? 'an ' : 'a ')
                        } else {
                            formatted.push(quantity + ' ')
                        }

                        if (quantity === 1 || quantity_type === 'a') {
                            formatted.push(token.value.replace(/s$/, ''))
                        } else {
                            formatted.push(token.value)
                        }
                        quantity_type = 'a'
                    }
                    // Disallow "silly" suggestions, 1 week and 1 minute.
                    if (
                        previous_unit &&
                        ![
                            'hours:minutes',
                            'days:hours',
                            'weeks:days',
                            'months:days',
                            'months:weeks',
                            'years:weeks',
                            'years:months',
                        ].find((item) => item === previous_unit + ':' + token.value)
                    ) {
                        confidence *= 0.01
                    }

                    previous_unit = token.value
                    break
            }
        })

        // if (parse.rule === "at?> hour colon?> minute? am? today>") {
        //   debugger;
        // }

        // It doesn't make much sense to have "in 1 week and", so we remove that
        // from the suggestions list.
        if (last_unguessed_type === 'and') {
            confidence *= 0.01
        }
        // In 1 <nothing> doesn't make any sense on its own.
        if (last(parse.tokens).type === 'quantity') {
            confidence *= 0.01
        }

        // If there's no relativeness, then this is not a valid parse.
        if (!formatted.length) {
            confidence *= 0
        }

        if (is_past_date) {
            const dateWithYearsSubtracted = subYears(date, delta.years)
            const dateWithMonthsSubtracted = subMonths(dateWithYearsSubtracted, delta.months)
            const dateWithDaysSubtracted = subDays(dateWithMonthsSubtracted, delta.days)
            const dateWithHoursSubtracted = subHours(dateWithDaysSubtracted, delta.days)
            const dateWithMinutesSubtracted = subMinutes(dateWithHoursSubtracted, delta.days)
            formatted.push(' ago')
            date = dateWithMinutesSubtracted
        } else {
            target_month = (date.getMonth() + delta.months) % 12
            date.setMonth(date.getMonth() + delta.months + 12 * delta.years)
            var month_decimal = (delta.months % 1).toFixed(1).substring(2)
            if (parseInt(month_decimal) !== 0) {
                // partial month
                var days_in_target_month = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    0
                ).getDate()

                // convert month decimal to days
                delta.days = parseInt((month_decimal / 10) * days_in_target_month)
            } else {
                // no partial month
                // If we start on the 31st of a month (and there is no partial month), and the month we are aimed at
                // has fewer days, then javascript will "overflow" and we'll end up
                // at the beginning of the next month.
                // This is not what humans expect (and not what ActiveSupport does) so
                // we fix it by noticing that the overflow has happened and jumping
                // backwards to the last day of the previous month.

                if (date.getMonth() !== target_month) {
                    date.setDate(0) // 0 is the day before the 1st of the month.
                }
            }
            date.setDate(date.getDate() + delta.days)

            // Now set the day and the time, noting that the overflow behaviour
            // works in our favour here.
            // Handle partial months like <1.3 months>
            if (delta.minutes || delta.hours) {
                date.setMinutes(date.getMinutes() + delta.minutes + 60 * delta.hours)
                date.setSeconds(0)
                date.setMilliseconds(0)
            } else {
                date = timer.apply(date)
            }
        }
        if (delta.minutes || delta.hours) {
            iso8601 =
                'P' +
                (delta.years ? delta.years + 'Y' : '') +
                (delta.months ? delta.months + 'M' : '') +
                (delta.days ? delta.days + 'D' : '') +
                (delta.hours || delta.minutes ? 'T' : '') +
                (delta.hours ? delta.hours + 'H' : '') +
                (delta.minutes ? delta.minutes + 'M' : '')
        } else {
            iso8601 = date.toISOString()
        }

        return {
            formatted: timer.format(formatted.join('')),
            iso8601: iso8601,
            date: date,
            confidence: confidence * timer.confidence(),
            rule: parse.rule,
            tokens: parse.tokens,
            timezoneConverted: timer.timezone(),
        }
    },

    // Subsequent dates are like: "on Monday", "next week"
    subsequent: function (parse) {
        var date = new Date($t.now.getTime()),
            timer = $t.timeInterpreter(date),
            next = false,
            is_last = false,
            current,
            target,
            seen_date = false,
            seen_time = false,
            formatted = [],
            confidence = 1

        // if (parse.rule === "at?> hour colon?> minute? am? today>") {
        //   debugger;
        // }
        parse.tokens.forEach(function (token, index) {
            seen_time = timer.interpret(token, index) || seen_time
            // eslint-disable-next-line default-case
            switch (token.type) {
                case 'next':
                    next = true
                    break
                case 'last':
                    is_last = true
                    break
                case 'timespan':
                    if (next) {
                        // eslint-disable-next-line default-case
                        switch (token.value) {
                            case 'week':
                                // getDate() - getDay() -> last sunday, + 8 is next monday.
                                date.setDate(date.getDate() - date.getDay() + 8)
                                break
                            case 'month':
                                date.setDate(1)
                                date.setMonth(date.getMonth() + 1)
                                break
                            case 'quarter':
                                let nextQuarterMonth =
                                    ((Math.floor(date.getMonth() / 3) + 1) * 3) % 12
                                if (nextQuarterMonth === 0) {
                                    date.setFullYear(date.getFullYear() + 1)
                                }
                                date.setDate(1)
                                date.setMonth(nextQuarterMonth)
                                break
                            case 'year':
                                date.setDate(1) // 1st
                                date.setMonth(0) // January
                                date.setFullYear(date.getFullYear() + 1)
                                break
                        }
                        formatted.push('next ' + token.value)
                    } else if (is_last) {
                        switch (token.value) {
                            case 'day':
                                date = subDays(date, 1)
                                break
                            case 'week':
                                date = subDays(date, 7)
                                break
                            case 'month':
                                date = subMonths(date, 1)
                                break
                            case 'quarter':
                                date = subMonths(date, 3 - ((date.getMonth() + 1) % 3))
                                break
                            case 'year':
                                date = subYears(date, 1)
                                break
                        }
                        formatted.push('last ' + token.value)
                    }
                    seen_date = true
                    break
                case 'dayname':
                    // Make Sunday the last day of the week not the 0th.
                    // (this means that getDate() - current always points to last Sunday)
                    current = $t.now.getDay() || 7
                    target = token.parsed || 7

                    if (next) {
                        // Next <today> is always exactly one week away,
                        // and any day that hasn't yet happened this week, next <day>
                        // is the occurrence of that day over one week from now.
                        if (target >= current) {
                            date.setDate(date.getDate() - current + target + 7)

                            // Every next <day> from Sunday is in the subsequent week,
                            // except for Saturday (as this Saturday is this weekend).
                        } else if (current === 7) {
                            date.setDate(date.getDate() + (target === 6 ? 6 : target + 7))

                            // On Friday and Saturday, Monday and Tuesday feel pretty
                            // close, so next Monday and Tuesday are well over a week
                            // away.
                        } else if (current - target > 3) {
                            date.setDate(date.getDate() - current + target + 14)

                            // Otherwise the next occurrence of this day is between
                            // 4 and 7 days away:
                        } else {
                            date.setDate(date.getDate() - current + target + 7)
                        }
                        formatted.push('next ')
                    } else if (is_last) {
                        if (current > target) {
                            date = subDays(date, current - target)
                        } else {
                            date = subDays(date, current + (7 - target))
                        }
                        formatted.push('last ')
                    } else {
                        if (current < target) {
                            date.setDate(date.getDate() - current + target)
                        } else {
                            date.setDate(date.getDate() + 7 - current + target)
                        }
                        if ($t.opts.describe_as_duration) {
                            formatted.push('to ')
                        } else {
                            formatted.push('on ')
                        }
                    }
                    formatted.push(capitalize(token.value))

                    seen_date = true
                    break

                case 'monthname':
                    current = $t.now.getMonth()
                    target = token.parsed

                    // Always the first of the month.
                    date.setDate(1)
                    date.setMonth(target)

                    if (next) {
                        // If we haven't had the target month this year,
                        // jump to next year.
                        if (target >= current) {
                            date.setFullYear(date.getFullYear() + 1)

                            // If we're close to the end of the year, jump
                            // over the immediately subsequent month to the
                            // next one.
                        } else if (current - target > 8) {
                            date.setFullYear(date.getFullYear() + 2)

                            // The next <foo> is the immediately subsequent <foo>,
                            // but it feels like the next one because it's several
                            // months away.
                        } else {
                            date.setFullYear(date.getFullYear() + 1)
                        }

                        formatted.push('next ')
                        formatted.push(capitalize(token.value))
                    } else if (is_last) {
                        if (current <= target) {
                            date.setFullYear(date.getFullYear() - 1)
                        }
                        formatted.push('last ')
                        formatted.push(capitalize(token.value))
                    } else {
                        // If we're in the second half of the year, "in january" means
                        // next year.
                        if (target <= current) {
                            date.setFullYear(date.getFullYear() + 1)
                        }

                        formatted.push('in ')
                        formatted.push(capitalize(token.value) + ' ')
                    }
                    seen_date = true
                    break

                case 'quartername':
                    var currQ = Math.floor($t.now.getMonth() / 3 + 1)
                    var targetQuarter = token.parsed + 1

                    const currentQuarter = Math.floor((date.getMonth() + 1) / 3)
                    date.setDate(1)
                    date.setMonth((targetQuarter - 1) * 3)
                    if (next) {
                        date.setFullYear(date.getFullYear() + 1)
                        formatted.push('next ')
                        formatted.push('Q' + targetQuarter.toString())
                    } else if (is_last) {
                        if (currentQuarter <= targetQuarter) {
                            date = subYears(date, 1)
                        }
                        formatted.push('last ')
                        formatted.push('Q' + targetQuarter.toString())
                    } else {
                        if (currQ >= targetQuarter) {
                            date.setFullYear(date.getFullYear() + 1)
                        }
                        formatted.push('in ')
                        formatted.push('Q' + targetQuarter.toString() + ' ')
                    }
                    seen_date = true
                    break

                case 'year':
                    date.setFullYear(token.value)
                    if (!seen_date) {
                        date.setMonth(0)
                        date.setDate(1)
                    }
                    formatted.push(token.value)
                    break
                case 'yesterday':
                    date.setDate(date.getDate() - 1)
                    formatted.push('yesterday')
                    seen_date = true
                    break
                case 'tomorrow':
                    date.setDate(date.getDate() + 1)
                    formatted.push('tomorrow')

                    seen_date = true
                    break
                case 'tonight':
                case 'today':
                    formatted.push('today')
                    seen_date = true
                    break
                case 'someday':
                    formatted.push(token.value)
                    date.setTime(SOMEDAY)
            }
        })

        date = timer.apply(date)

        // If someone has only typed a time, it's go
        // ing to be that time today or tomorrow.
        if (seen_time && !seen_date) {
            var hoursDifference = Math.ceil(
                Math.abs(date.getTime() - $t.now.getTime()) / (1000 * 3600)
            )
            if (hoursDifference > 24) {
                // if there is more than a day difference between two dates we need to
                // push 2 days to the selected date
                formatted.push('tomorrow')
                date.setDate(date.getDate() + 2)
            } else if (date.getTime() <= $t.now.getTime()) {
                // if absolute time is in the past and we are in a non-local timezone
                // we consider the date as tomorrow
                formatted.push('tomorrow')
                date.setDate(date.getDate() + 1)
            } else {
                formatted.push('today')
            }
        }

        // If the date is an implicit "today" then we don't want to add the
        // dateConfidence modifier as it seems to fight against you.
        if (seen_time && last(formatted) === 'today' && date >= $t.now) {
            confidence /= $t.dateConfidence(date)
        }
        const finalFormatted = timer.format(formatted.join(''))
        const finalConfidence = confidence * timer.confidence()

        return {
            formatted: finalFormatted,
            date: date,
            iso8601: date.toISOString(),
            confidence: finalConfidence,
            rule: parse.rule,
            tokens: parse.tokens,
            timezoneConverted: timer.timezone(),
        }
    },

    // on <day> <month> <year> etc.
    absolute: function (parse) {
        var date = new Date($t.now.getTime()),
            timer = $t.timeInterpreter(date),
            index = {},
            year_specified,
            date_given,
            day_given,
            suffix_given,
            seen_date = false,
            overflown = false,
            correct_suffix,
            target,
            formatted_day,
            formatted_date,
            formatted_month,
            formatted,
            confidence = 1

        parse.tokens.forEach(function (token, i) {
            timer.interpret(token, i)
            if (token.value) {
                var number = token.parsed
                // 1-based indexes for ease of boolean checking.
                index[token.type] = i + 1
                // eslint-disable-next-line default-case
                switch (token.type) {
                    case 'year':
                        if (number < 1000) {
                            number += 2000
                        }
                        // If we were in a month with 30 days, and
                        // we move to the 31st and then to december,
                        // we'll end up on the 1st of January of the
                        // next year. Preserve the overflow by keeping
                        // the year off-by-one.
                        if (overflown && date.getMonth() === 0) {
                            number = number + 1
                        }
                        target = date.getDate()
                        date.setFullYear(number)
                        if (date.getDate() !== target) {
                            overflown = true
                        }
                        year_specified = !token.guessed
                        break
                    case 'month':
                        if (overflown) {
                            // preserve the overflow by setting it to the month after.
                            date.setMonth(number + 1)
                        } else {
                            // If date.getDate() is set to 31, and we set the
                            // month to a month with 30 days then javascript will
                            // automatically overflow to the following month.
                            //
                            // If the date.getDate() (e.g. 31) is set from a
                            // previous token then overflow behavior is
                            // desireable.
                            //
                            // Otherwise we reset the date to prevent overflow
                            // with the expectation that it will be corrected
                            // later.
                            if (!seen_date) {
                                date.setDate(1)
                            }
                            date.setMonth(number)
                            if (date.getMonth() !== number) {
                                overflown = true
                            }
                        }
                        break
                    case 'day':
                        date_given = number
                        date.setDate(number)
                        if (date.getDate() !== number) {
                            overflown = true
                        }
                        seen_date = true
                        break
                    case 'dayname':
                        day_given = token.parsed
                        break
                    case 'th':
                        suffix_given = token.value
                        break
                }
            }
        })

        // if (parse.rule === "at?> hour colon?> minute? am? today>") {
        //   debugger;
        // }

        // If the date is today, jump.
        if (date.getTime() < $t.now.getTime()) {
            if (index.day && index.month && !year_specified) {
                target = date.getDate()
                date.setFullYear(date.getFullYear() + 1)
                if (date.getDate() !== target) {
                    overflown = true
                }
            } else if (!index.day && !index.month) {
                date.setDate(date.getDate() + 1)
            }
        }

        // Javascript has a tendency when setting date or month to jump to the beginning
        // of the next month. Luckily we can notice this, and when we do, we reset the day
        // to the day before the 1st of the month.
        if (overflown) {
            date.setDate(0)
        }

        // If the overflow made the date different from what the user typed.
        // (i.e they said "31st June") we need to reduce the confidence of
        // the "30th June" (which is what we're suggesting).
        // This will remove it from the list of suggestions when no month is
        // specified, but suggest the right date when they explicit specify
        // the wrong date.
        if (date_given && date.getDate() !== date_given) {
            confidence *= 0.1
        }

        // If someone wants to set a reminder on Monday the 30th, let them
        // not do so.
        if (!isNaN(day_given) && date.getDay() !== day_given) {
            confidence *= 0.1
        }

        // If there's a year, but no more detail, then we reset the date
        // to January the first.
        if (year_specified && !index.day) {
            date.setDate(1)
            date.setMonth(0)
        }

        correct_suffix = suffixes[date.getDate()] || 'th'

        // If we have a correct suffix (like 4th) then that's a pretty strong
        // indicator that this is a correct parse.
        // (i.e. 4th is not "4pm on Thursday")
        if (suffix_given && suffix_given === correct_suffix) {
            confidence *= 12
        }

        formatted_date = date.getDate()
        if (!$t.opts.hide_suffixes) {
            formatted_date += correct_suffix
        }

        var preposition = 'on '
        if ($t.opts.describe_as_duration) {
            preposition = 'to '
        }
        if ($t.opts.hide_preposition) {
            preposition = ''
        }

        formatted_month = format(date, 'LLLL')
        if (day_given) {
            formatted_day = preposition + format(date, 'dddd') + ' '
        } else {
            formatted_day = preposition
        }

        // Show the date the same way as it was input. (This is to keep en-US and en-GB happy without the locale setting).
        formatted =
            formatted_day +
            (index.dayname || index.day < index.month
                ? [formatted_date, formatted_month]
                : [formatted_month, formatted_date]
            ).join(' ')

        if (year_specified || date.getFullYear() !== $t.now.getFullYear()) {
            formatted = formatted + ' ' + date.getFullYear()
        }

        // No-one would refer to tomorrow as May 25th.
        // Update, we prioritize tomorrow dates by bringing all non-tomorrow a bit down; ref: https://github.com/superhuman/superhuman/issues/7047
        if (date.getTime() - $t.now.getTime() > 3 * 86400000) {
            confidence *= 0.8
        }

        if (index.hour || index.minute) {
            confidence *= 0.1
        }

        return {
            formatted: timer.format(formatted, date),
            date: timer.apply(date),
            iso8601: date.toISOString(),
            confidence: confidence * timer.confidence(),
            rule: parse.rule,
            tokens: parse.tokens,
            timezoneConverted: timer.timezone(),
        }
    },
}

// Given a date, how likely is it that the user wishes to specify that.
$t.dateConfidence = function (date) {
    // x is the number of days from now,
    var x = (date.getTime() - $t.now.getTime()) / 86400000.0
    // Return a moderated exponential decay (max about 5.5 days)
    // At 365 days, ~ 0.05,
    // At 60 days, ~ 0.3
    // At 30 days, ~ 0.4
    // At 14 days, ~ 0.5
    // At 7 days, ~ 0.55
    // At 1 day, ~ 0.45
    // 12 hours, ~ 0.4
    // <= now, <= 0

    // If you query for a past date on some instances you end up with a really small confidence values
    // So in the end those values will be ignored when filtering is performed
    // Ex: Input - '04 04 2020'
    // Rule Confidence - 0.05 | Interpreter Confidence - 1 | Token Confidence - 0.7 || Date Confidence - 0.00001
    // Final Confidence - 3.5e-7
    if ($t.opts.allow_past_dates) {
        x = Math.abs(x)
    }

    if (x < 0) {
        return 0.00001
    } else if (x === 0) {
        return 0.8
    } else if (x < 5) {
        return 0.8 * Math.exp(-1 * 0.08 * x)
    } else {
        return Math.pow(x, 0.25) * Math.exp(1 - Math.pow(x + 6, 0.25))
    }
}

// Given a set of tokens, how confident are we that we'll have interpreted
// them correctly.
$t.tokenConfidence = function (tokens) {
    var total = 0.0,
        guessed = 0.0,
        optional = 0.0,
        moderated = 1.0

    tokens.forEach(function (token) {
        total += 1
        guessed += token.guessed ? 0.75 : 0
        optional += token.optional ? 0.9 : 0
        moderated *= token.confidence || 1.0
    })
    return (moderated * (total - guessed - optional)) / total
}

// Extract a list of string tokens from a string.
//
// This strips out anything that is not a word or a number,
// and splits numbers from the words adjacent to them.
//
// Preserve colons as they are a strong indicator for time:
// http://unicode.org/repos/cldr-tmp/trunk/diff/by_type/calendar-gregorian.pattern.html
$t.split = function (string) {
    // string.split with a capture creates an array of
    //   <separator> <token> <separator> <token> <separator>

    /*
     * Marc Höffl:
     * Original:
     * var match = string.toLowerCase().match(/(\d*\.\d)(?!\d)|\d+|[a-z]+|[:/\\\-@&]/g);
     *
     * The first capturing group (\d*\.\d) leads to capturing 10.8 as a single group
     * -> I dont know the original intention of this.
     *
     */

    const match = string.toLowerCase().match(/\d+|[a-z]+|[:/\\\-@&]/g)

    return $t.formatSplitTokens(match)
}

// Used for formatting timezone names such as "ho chi minh"
$t.formatSplitTokens = function (match) {
    if (!match) {
        return []
    }

    // Prefix in ho_chi_minh wll be "ho", there is no way we can autocomplete St. Something since it will clash with 31st March.
    // In case any new places get added that might start with ambiguous time prefixes such as 'th' it needs to be added in this array.
    var ambiguousTimePrefixes = ['st']
    var prefix = match
        .filter((item) => !ambiguousTimePrefixes.includes(item))
        .find((item) => placePrefixes.includes(item))

    if (prefix) {
        var matchSplit = match.splice(match.indexOf(prefix), match.length)
        if (matchSplit) {
            // we already have a complete place match, allow optional word 'time' after this
            if (timezonePlaces.includes(matchSplit.slice(0, matchSplit.length - 1).join('_'))) {
                if ('time'.startsWith(matchSplit[matchSplit.length - 1])) {
                    matchSplit.pop()
                }
            }
            // join everything after matched timezone as a complete place name
            match.push(matchSplit.join('_'))
        }
    } else {
        if (timezonePlaces.includes(match[match.length - 2])) {
            // allow optional word 'time' after place match
            if ('time'.startsWith(match[match.length - 1])) {
                match.pop()
            }
        }
    }

    return match
}

// Given an array of tokens, return an array of arrays of possibilities.
//
// Each array of possibilities represents the possible strings that this
// token may grow to encompass if more letters are added.
//
// This returns a list of lists:
//
// for "23 th" it might be something like:
//
// [  [{type: 'day', value: '23'}, {type: 'quantity', value: '23'}, {type: 'hour', value: '23'}],
//    [{type: 'on', value: 'this'}, {type: 'dayname', value: 'thursday'}, {type: 'th', value: 'th'}]
// ]
$t.tokenize = function (string) {
    var chunks = $t.split(string)
    return chunks.map(function (chunk, i) {
        var number = parseFloat(chunk)

        var things = []

        each($t.suggestors, function (type, expander) {
            things.push(
                expander(chunk, number)
                    .map(function (value) {
                        // value.value can be (number + 12) or (number) IF type == "hour" (AM vs PM)
                        var validHour =
                            type === 'hour' &&
                            (parseFloat(value.value) === number ||
                                parseFloat(value.value) === number + 12)
                        if (
                            isNaN(number) ||
                            parseFloat(value.value) === number ||
                            i + 1 === chunks.length ||
                            validHour
                        ) {
                            return value
                        }
                        return false
                    })
                    .filter((a) => Boolean(a))
            )
        })

        return flatten(things)
    })
}

// The list of input_tokens is a list of possible tokens at each position.
// This takes the output of tokenize above, and the grammar, and returns a list
// of possible parses which may include a guessed token:
//
// for "23 th", this might return something like:
//
// [ {
//     rule: 'day th? monthname',
//     tokens: [{type: 'day', value: '23', optional: false},
//              {type: 'th', value: 'th', optional: false},
//              {type: 'monthname', value: 'january', guessed: true}]
// }, {
//     rule: 'hour next? dayname',
//     tokens: [{type: 'hour', value: '23', optional: false},
//              {type: 'dayname', value: 'thursday', optional: false}]
// } ]
//
$t.parse = function (input_tokens, grammar) {
    var things = []
    each(grammar, function (rule, meta) {
        // Convert a line of our grammar into a list of rule tokens, which can
        // then be passed to recurse to generate all possibilities.
        //
        // e.g.
        // 'on? day' =>  [{type: 'on', optional: true}, {type: 'day', optional: false}]
        var parsed = rule.split(' ').map(function (spec) {
            var type_and_optional = spec.match(/([a-z]+)(\?)?(>)?/)
            return {
                type: type_and_optional[1],
                optional: !!type_and_optional[2],
                force_suggest: !!type_and_optional[3],
            }
        })
        things.push(
            $t.recursiveParse(parsed, input_tokens).map(function (tokens) {
                return {
                    rule: rule,
                    tokens: tokens,
                }
            })
        )
    })
    return flatten(things)
}

// Recurse along a rule in the grammar generating all possible parses
// for all the tokens given so far, and finally generating a suggestion
// for missing tokens using $t.recursiveSuggest.
//
// The first parameter is the rule, derived from the grammar by $t.parse
//
// For example the rule 'dayname at?> hour?' would be represented as:
// rule: [{type: 'dayname', optional: false, force_suggest: false},
//        {type: 'at', optional: true, force_suggest: true},
//        {type: 'hour', optional: true, force_suggest: false}]
//
// tokens is the output of $t.tokenize
//
// force_suggest represents the previous rule-item's force_suggest value,
// which can be passed to $t.recursiveSuggest.
//
$t.recursiveParse = function (rule, tokens, force_suggest) {
    if (tokens.length === 0) {
        return $t.recursiveSuggest(rule, force_suggest)
    } else {
        var next = head(rule) || {},
            ret = []

        if (next.optional) {
            ret = ret.concat($t.recursiveParse(rule.slice(1), tokens, next.force_suggest))
        }

        head(tokens).forEach(function (token) {
            if (token.type === next.type) {
                ret = ret.concat(
                    $t
                        .recursiveParse(rule.slice(1), tokens.slice(1), next.force_suggest)
                        .map(function (tokens) {
                            token = merge({ optional: next.optional }, token)
                            return [token].concat(tokens)
                        })
                )
            }
        })

        return ret
    }
}

// Given the remainder of a rule (after having exhausted all the user-provided tokens in $t.recursiveParse)
// suggest some more tokens.
//
// If force_suggest is true, then the user typed an indicator, like the "at" in "at 7pm", so in order
// that the autocompleter shows something useful, we pretend they typed the next token too.
//
// If there's a chain of "indicator" tokens, then we travel along it, so that a rule like "unit and?> a?> unit"
// will suggest "a week and a day" when the user types "a week and" or "a week and a".
//
$t.recursiveSuggest = function (rule, force_suggest) {
    var next = head(rule)
    while (next && next.force_suggest && force_suggest) {
        rule = rule.slice(1)
        next = head(rule)
    }
    if (!next) {
        return [[]]
    } else if (next.optional && !force_suggest) {
        return $t.recursiveSuggest(rule.slice(1))
    } else {
        return $t.suggestors[next.type]('', 0).map(function (value) {
            return [
                {
                    type: next.type,
                    value: value.value,
                    guessed: !force_suggest,
                    parsed: value.parsed,
                },
            ]
        })
    }
}

// Given a list of parses, sorts and uniques them, by confidence and
// by date so that a few suggestions can be passed to the user.
$t.output = function (raw_parses, grammar) {
    const parsed = raw_parses.map(function (raw_parse) {
        const meta = grammar[raw_parse.rule]
        const interpreter = meta[0]
        const confidence = meta[1]
        const parse = $t.interpreters[interpreter](raw_parse)

        parse.confidence *= confidence
        parse.confidence *= $t.dateConfidence(parse.date)
        parse.confidence *= $t.tokenConfidence(parse.tokens)
        parse.confidence = round(parse.confidence)
        return parse
    })
    const confidences = parsed.map(function (x) {
        return x.confidence || 0
    })
    const max_confidence = Math.max.apply(null, confidences) || 0

    const filtered = parsed.filter(function (parse) {
        parse.in_future = parse.date && parse.date.getTime() >= $t.now.getTime()
        const is_someday = parse.tokens[0] && parse.tokens[0].type === 'someday'

        return (
            parse.date &&
            !$t.textualOverlapParse(parse) &&
            (!$t.opts.allow_someday ? !is_someday : true) &&
            ($t.opts.allow_past_dates || parse.in_future) &&
            parse.confidence > max_confidence / 3
        )
    })

    const filteredWithDateRanges = filtered.filter((parse) => {
        let excludeDueToMinDate = false
        let excludeDueToMaxDate = false
        if ($t.opts.min_date) {
            excludeDueToMinDate = $t.opts.min_date.getTime() > parse.date.getTime()
        }
        if ($t.opts.max_date) {
            excludeDueToMaxDate = $t.opts.max_date.getTime() < parse.date.getTime()
        }

        return !(excludeDueToMinDate || excludeDueToMaxDate)
    })
    // console.log(
    //   "parsed",
    //   parsed.map((a) => a.formatted)
    // );
    // console.log(
    //   "Filtered",
    //   filtered.map((a) => a.formatted)
    // );
    // console.log(
    //   "FilteredWitHDateRanges",
    //   filteredWithDateRanges.map((a) => a.formatted)
    // );

    const sortedByDate = reverse(sortedUniqBy(sortBy(filteredWithDateRanges, 'date'), 'date'))
    const uniqFormatted = uniqBy(sortedByDate, 'formatted')
    const sortByConfidence = reverse(sortBy(uniqFormatted, 'confidence'))

    return sortByConfidence
}

$t.textualOverlapParse = function (parse) {
    // wed 6a would parse 'after' which can return 6pm
    // since nobody would expect typing 6a and getting 6pm we should skip this parse
    // but only skip 'after' if it comes where the pm would be, and if there's no explicit pm/am
    var isAmInParse = parse.rule.includes('am? in?')
    var isPmParse = parse.formatted.includes('pm')

    if (isAmInParse && isPmParse) {
        // the after if it comes where the pm would be, and if there's no explicit pm/am?
        return (
            parse.tokens.find((token) => token.value === 'after') &&
            !parse.tokens.find((token) => token.type === 'am')
        )
    }

    return false
}

$t.getTimezoneOffset = function (date, timezone) {
    var iana = $t.ianaTimezone(timezone)
    // Iceland conveniently uses ISO8601 and is always in UTC
    const diff = (a, b) => (a.getTime() - b.getTime()) / (60 * 60 * 1000)
    const t1 = utcToZonedTime(date, iana.timezone)

    return diff(date, t1)
}

$t.ianaTimezone = function (timezone) {
    // abbreviation match
    var isPlaceNameMatch = false
    if (timezoneService.abbreviations[timezone.toUpperCase()]) {
        timezone = timezoneService.abbreviations[timezone.toUpperCase()]
    }
    // valid timezone place name match
    else if (timezonesValid[timezone]) {
        timezone = timezonesValid[timezone]
        isPlaceNameMatch = true
    } else {
        // place name match
        timezone =
            timezoneService.places[
                Object.keys(timezoneService.places).find(
                    (key) => key.toLowerCase() === timezone.replace(/_/g, ' ').toLowerCase()
                )
            ]
        isPlaceNameMatch = true
    }

    return {
        timezone: timezone,
        isPlaceNameMatch: isPlaceNameMatch,
    }
}

export const timeSuggest = $t

export default timeSuggest

function round(num, precision = 8) {
    return parseFloat(num.toFixed(precision))
}

import { action, computed, extendObservable, reaction, runInAction } from 'mobx'
import { now } from 'mobx-utils'
import { omit, sortBy } from 'lodash'
import { differenceInDays, parseISO } from 'date-fns'

import { ModelExtension, ObjectPool } from '@laserfocus/client/data-layer'
import type {
    OpportunityFields,
    OpportunityProps,
    DateTimeString,
    SnapshotListener,
    SnapShotter,
    DateString,
    AccountModel,
} from '@laserfocus/shared/models'

import { TaskModel } from '../activity/TaskModel'
import { EventModel } from '../activity/EventModel'
import { getLastActivityDate, getNextActivity } from '../activity/activity-util'

const OpportunityPropKeys = new Set<string>()

export class OpportunityModel implements OpportunityFields, ModelExtension {
    objectPool: ObjectPool
    __typename = 'Opportunity' as const
    Id: string
    Name?: string
    StageName?: string
    IsClosed?: boolean
    IsWon?: boolean
    Probability?: number
    Amount?: number
    CloseDate?: DateString
    ExpectedRevenue?: number
    UnqualifiedReason?: string
    OwnerId?: string
    AccountId?: string
    LastStageChangeDate?: DateTimeString
    CurrencyIsoCode?: string

    constructor(props: OpportunityProps, objectPool: ObjectPool) {
        this.objectPool = objectPool

        extendObservable(this, omit(props, 'LastActivityDate'))
        this._LastActivityDate = props.LastActivityDate
        this.Id = props.Id
        const keys = Object.keys(props)
        keys.forEach((key) => OpportunityPropKeys.add(key))
    }

    @computed get props(): OpportunityProps {
        const keys = Array.from(OpportunityPropKeys.values())
        const props = Object.fromEntries(
            keys.map((key) => [key, this[key as keyof OpportunityModel]])
        )
        return {
            Id: this.Id,
            __typename: 'Opportunity',
            ...props,
        }
    }

    _previousSnapshot: Map<number, any> = new Map()
    _snapshotCounter = 0

    @action
    onSnapshot<Snapshot>(
        makeSnapshot: SnapShotter<OpportunityModel, Snapshot>,
        listener: SnapshotListener<Snapshot>,
        options?: {
            fireImmediately?: boolean
        }
    ): () => void {
        const index = this._snapshotCounter++
        const previousSnapshot = makeSnapshot(this as any)
        this._previousSnapshot.set(index, previousSnapshot)
        const discard = reaction(
            () => makeSnapshot(this as any),
            (current) => {
                listener(this._previousSnapshot.get(index), current)
                runInAction(() => {
                    this._previousSnapshot.set(index, current)
                })
            },
            options
        )
        return () => {
            this._previousSnapshot.delete(index)
            discard()
        }
    }

    /**
     * ------------------ Activity API --------------------
     */
    _LastActivityDate?: DateTimeString | undefined

    @computed
    get Activities(): Array<TaskModel | EventModel> {
        const objectActivities = this.objectPool.activitiesByOpp[this.Id] || {}
        return Object.values(objectActivities) as Array<TaskModel | EventModel>
    }

    @computed
    get OpenActivities() {
        const openActivitiees = this.Activities.filter((a) => a.isOpen)
        return sortBy(openActivitiees, 'OpenDate', 'CreatedDate').reverse()
    }

    @computed
    get ActivityHistory() {
        const activityHistory = this.Activities.filter((a) => !a.isOpen)
        return sortBy(activityHistory, 'ClosedDate', 'CreatedDate').reverse()
    }

    @computed
    get NextActivity() {
        return getNextActivity(this)
    }

    @computed
    get LastActivity() {
        return this.ActivityHistory[0]
    }

    @computed
    get LastActivityDate() {
        return getLastActivityDate(this)
    }

    set LastActivityDate(d) {
        this._LastActivityDate = d
    }

    /**
     * ------------------ Computed Columns --------------------
     */

    @computed
    get StageAge() {
        const nowTimer = now(1000 * 60)
        const lastChangeDateStr = this.LastStageChangeDate
        if (lastChangeDateStr) {
            const lastStageChangeDate = parseISO(lastChangeDateStr as string)
            return differenceInDays(new Date(nowTimer), lastStageChangeDate)
        }
    }

    /**
     * ------------------ Relationships --------------------
     */

    @computed
    get Account() {
        return this.AccountId
            ? this.objectPool.get<AccountModel>('Account', this.AccountId)
            : undefined
    }
}

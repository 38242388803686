import { toJS } from 'mobx'
import { Options } from 'minisearch'
import { get } from 'lodash'

import { LeadModel } from '@laserfocus/shared/models'

import { EventModel } from './EventModel'
import { TaskModel } from './TaskModel'

export const miniSearchOptions: Options<ActivitySearchIndex> = {
    idField: 'Id',
    fields: ['Subject', 'Description', 'Who.Company', 'Who.Name', 'Type', 'Priority', '__typename'],
    extractField(document: ActivitySearchIndex, fieldName: string) {
        if (fieldName.includes('.')) {
            return get(document, fieldName)
        }
        return document[fieldName as keyof ActivitySearchIndex]
    },
    searchOptions: {
        // fuzzy: true,
        prefix: true,
        combineWith: 'AND',
    },
}

export function makeActivitySnapshot(task: TaskModel | EventModel): ActivitySearchIndex {
    //@TODO: check if we actually need toJS
    return toJS({
        Id: task.Id,
        Subject: task.Subject,
        Description: task.Description,
        Who: {
            Company: (task.Who as LeadModel)?.['Company'] || task.Account?.Name,
            Name: task.Who?.['Name'],
        },
        Type: task.Type,
        Priority: (task as TaskModel).Priority,
        __typename: task.__typename,
    })
}

export type ActivitySearchIndex = {
    Id: string
    Subject?: string
    Description?: string
    Who?: {
        Company?: string
        Name?: string
    }
    Type?: string
    Priority?: string
    __typename: 'Task' | 'Event'
}

import { toast } from '@laserfocus/ui/beam'
import { getActivityCreateMessage } from '@laserfocus/client/util-formatter'
import { Analytics } from '@laserfocus/client/util-analytics'
import { scheduleTask } from '@laserfocus/client/data-access-shared'
import { useUserId } from '@laserfocus/client/feature-auth'
import { dateToDateString } from '@laserfocus/shared/models'
import { logger } from '@laserfocus/ui/logger'

import { StepModal } from '../StepModal'
import { StepSelectDate, StepSelectDateState } from '../steps/StepSelectDate'
import { StepSelectParent, StepSelectParentState } from '../steps/StepSelectParent'
import { StepSetTitle, StepSetTitleState } from '../steps/StepSetTitle'

interface AddTaskModalProps {
    closeModal(): void
}

export function AddTaskModal({ closeModal }: AddTaskModalProps) {
    const ownerId = useUserId()

    return (
        <StepModal
            onCancel={closeModal}
            onSubmit={([
                { selectedTitle },
                { WhatId, WhoId, AccountId, Name },
                { selectedDate },
            ]: readonly [StepSetTitleState, StepSelectParentState, StepSelectDateState]) => {
                closeModal()
                scheduleTask({
                    subject: selectedTitle,
                    date: dateToDateString(selectedDate),
                    dateTime: selectedDate.toISOString(),
                    whoId: WhoId,
                    whatId: WhatId ?? undefined,
                    accountId: AccountId ?? undefined,
                    ownerId: ownerId ?? undefined,
                }).then(
                    () => {
                        toast.success(getActivityCreateMessage(selectedDate, Name))
                        Analytics.trackEvent({
                            event: 'activity_scheduled',
                            location: Analytics.getLocation() as any,
                        })
                    },
                    (error) => {
                        logger.error(error)
                        toast.error({ title: error.message || error })
                    }
                )
            }}
            steps={
                [
                    {
                        title: 'Task title',
                        component: StepSetTitle,
                    },
                    {
                        title: 'Create task for …',
                        component: StepSelectParent,
                    },
                    {
                        title: 'Task date',
                        component: StepSelectDate,
                    },
                ] as const
            }
        />
    )
}

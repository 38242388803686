import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function FolderFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M2.4 7.2c0-1.3 1-2.4 2.4-2.4h4.8L12 7.2h4.8c1.3 0 2.4 1 2.4 2.4v1.2H9.6c-2 0-3.6 1.6-3.6 3.6v1.8c0 1-.9 1.8-1.8 1.8-1 0-1.8-.8-1.8-1.8v-9Z" />
            <path d="M7.2 14.4c0-1.3 1-2.4 2.4-2.4h9.6c1.3 0 2.4 1 2.4 2.4v2.4c0 1.3-1.1 2.4-2.4 2.4H2.4h2.4c1.3 0 2.4-1.1 2.4-2.4v-2.4Z" />
        </SvgIcon>
    )
}

import { padStart } from 'lodash'

import {
    ActivityCounterItem,
    sumActivityCounters,
    TIME_SAVED_BY_ACTIVITY,
    CLICK_SAVED_BY_ACTIVITY,
} from '@laserfocus/shared/models'

// import { Card } from '@laserfocus/ui/beam'
import { StatLabel, StatsCard, StatValue } from './StatsCard'

export function TimeSavedCard({ counters }: { counters: ActivityCounterItem[] }) {
    const saved = getSavedTime(counters)
    return (
        <StatsCard className="bg-blue-50">
            <StatLabel>Saved time with Laserfocus</StatLabel>
            <StatValue>{formatTimeSaved(saved)}</StatValue>
        </StatsCard>
    )
}

export function ClicksSavedCard({ counters }: { counters: ActivityCounterItem[] }) {
    const aggregated = Object.entries(sumActivityCounters(counters)).reduce(
        (total, [name, amount]: [string, number]) => {
            const timePerUnit = CLICK_SAVED_BY_ACTIVITY[name] || 0
            return total + amount * timePerUnit
        },
        0
    )
    return (
        <StatsCard className="bg-purple-50">
            <StatLabel>Saved number of clicks</StatLabel>
            <StatValue>{aggregated}</StatValue>
        </StatsCard>
    )
}

export function getSavedTime(counters: ActivityCounterItem[]) {
    return Object.entries(sumActivityCounters(counters)).reduce(
        (total, [name, amount]: [string, number]) => {
            const timePerUnit = TIME_SAVED_BY_ACTIVITY[name] || 0
            return total + amount * timePerUnit
        },
        0
    )
}

const ONE_MINUTE = 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24

export function formatTimeSaved(seconds: number) {
    if (seconds > ONE_HOUR * 100) {
        const days = Math.floor(seconds / ONE_DAY)
        // const remainingSeconds = seconds % ONE_DAY
        // const hours = Math.floor(remainingSeconds / ONE_HOUR)
        return `${days} days`
        // return `${pad(`${days}`, 2, '0')}:${pad(`${minutes}`, 2, '0')} days`
    } else if (seconds > ONE_HOUR) {
        const hours = Math.floor(seconds / ONE_HOUR)
        const remainingSeconds = seconds % ONE_HOUR
        const minutes = Math.floor(remainingSeconds / ONE_MINUTE)
        return `${hours}:${padStart(`${minutes}`, 2, '0')}h`
    } else {
        const minutes = Math.floor(seconds / ONE_MINUTE)
        const remainingSeconds = seconds % ONE_MINUTE
        return `${minutes}:${padStart(`${remainingSeconds}`, 2, '0')}m`
    }
}

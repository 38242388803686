import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'

import { getClient } from '@laserfocus/client/replicache'
import { NoteTemplate, OrgNoteTemplateSchema, Prefix } from '@laserfocus/shared/models'
import { FolderFilledIcon, EditOutlinedIcon, AddOutlinedIcon } from '@laserfocus/ui/icons'
import { z, d, parseAndReport } from '@laserfocus/shared/decoder'
import { logger } from '@laserfocus/ui/logger'
import { Button, Card } from '@laserfocus/ui/beam'
import { useIsLocked, LockedFeatureOverlay } from '@laserfocus/client/feature-subscription'

import { SettingsPageHeader } from '../layout/SettingsPageHeader'
import { RoleSelect } from '../roles/RoleSelect'
import { useRoleContext } from '../roles/RoleContext'

import {
    EditOrgNoteTemplateModal,
    TemplateData,
    useTemplateModalState,
} from './EditOrgNoteTemplateModal'

export function NoteTemplatesPage() {
    const { state, close, openTemplate, open } = useTemplateModalState()
    return (
        <div>
            <SettingsPageHeader
                title="Note Templates"
                iconComponent={FolderFilledIcon}
                left={
                    <div className="grid grid-flow-col gap-2 items-center mt-1">
                        <span>for</span>
                        <RoleSelect className="min-w-[220px]" />
                    </div>
                }
                right={
                    <Button iconComponent={AddOutlinedIcon} onClick={open}>
                        Create Template
                    </Button>
                }
            />
            <TemplateList openTemplate={openTemplate} />
            <EditOrgNoteTemplateModal state={state} close={close} />
        </div>
    )
}

function TemplateList({ openTemplate }: { openTemplate: (template: TemplateData) => void }) {
    const { selectedRole } = useRoleContext()
    const templates = useTemplates()
    const forRole = templates.filter(
        (t) => !t.roles || !t.roles.length || (selectedRole && t.roles.includes(selectedRole))
    )
    const { isLocked, subscription } = useIsLocked(true, 'team')
    return (
        <div className="max-w-xl p-6">
            <LockedFeatureOverlay
                isLocked={isLocked}
                subscription={subscription}
                lockedFeature="organisation Note Templates"
                plan="team"
            >
                <div className="grid grid-flow-row gap-2">
                    {forRole.map((t) => (
                        <TemplateCard
                            key={t.id}
                            id={t.id}
                            name={t.name}
                            open={() => openTemplate({ id: t.id, name: t.name, body: t.body })}
                        />
                    ))}
                </div>
            </LockedFeatureOverlay>
        </div>
    )
}

function TemplateCard({ id, name, open }: { id: string; name: string; open: () => void }) {
    return (
        <Card className="px-4 py-3 w-full">
            <div className="grid grid-flow-col grid-cols-[1fr,auto]  items-center">
                <div className="flex-1 ml-3 font-semibold">{name}</div>
                <div>
                    <Button iconComponent={EditOutlinedIcon} onClick={open}>
                        Edit
                    </Button>
                </div>
            </div>
        </Card>
    )
}

const TemplatesSchema = z.array(d.withNullFallback(OrgNoteTemplateSchema))

function useTemplates(): NoteTemplate[] {
    const rep = getClient()
    return useSubscribe<NoteTemplate[]>(
        rep,
        async (tx: ReadTransaction) => {
            const templates = await tx.scan({ prefix: Prefix.NoteTemplateOrg }).values().toArray()

            const parsed = parseAndReport(templates, TemplatesSchema, logger)
            return parsed
        },
        []
    )
}

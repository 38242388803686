import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { CardColor } from '../Cards/Card'

//global unique key for every gauge (needed for SVG groups to stay separated)
let uniqueId = 0

export interface GaugeProps {
    value: number
    min?: number
    max?: number
    radius?: number
    width?: number
    height?: number
    minMaxLabelsOffset?: number
    minLabel?: string
    maxLabel?: string
    color?: GaugeColor
    valueLabelClassName?: string
    minMaxLabelClassName?: string
    valueFormatter?: (value: number) => string
}

type GaugeColor = CardColor

const ColorSchemes: Record<GaugeColor, string> = {
    grey: 'fill-grey-700/80 hover:fill-grey-700',
    blue: 'fill-blue-500/80 hover:fill-blue-500',
    green: 'fill-green-500/80 hover:fill-green-500',
    red: 'fill-red-500/80 hover:fill-red-500',
    yellow: 'fill-yellow-400/80 hover:yellow-400',
}

export function Gauge({
    value,
    minMaxLabelClassName,
    valueLabelClassName,
    valueFormatter = (value: number) => `${value}`,
    min = 0,
    max = 100,
    radius = 160,
    minLabel,
    maxLabel,
    minMaxLabelsOffset = 25, // Should be derived but not sure from what yet,
    color = 'grey',
}: GaugeProps) {
    const [uniqueFilterId] = useState(() => `filter_${++uniqueId}`)

    const width = radius * 2
    const height = clamp(radius * 1.2, radius + 28)

    function getPathValues(value: number) {
        if (value < min) value = min
        if (value > max) value = max

        const dx = 0
        const dy = 0

        const alpha = (1 - (value - min) / (max - min)) * Math.PI
        const Ro = radius
        const Ri = Ro - width / 6.666666666666667

        const Cx = radius + dx
        const Cy = radius + dy

        const Xo = radius + dx + Ro * Math.cos(alpha)
        const Yo = height - (height - Cy) - Ro * Math.sin(alpha)
        const Xi = radius + dx + Ri * Math.cos(alpha)
        const Yi = height - (height - Cy) - Ri * Math.sin(alpha)

        return { alpha, Ro, Ri, Cx, Cy, Xo, Yo, Xi, Yi }
    }

    function getPath(value: number) {
        const { Ro, Ri, Cx, Cy, Xo, Yo, Xi, Yi } = getPathValues(value)

        let path = `M${Cx - Ri},${Cy} `
        path += `L${Cx - Ro},${Cy} `
        path += `A${Ro},${Ro} 0 0 1 ${Xo},${Yo} `
        path += `L${Xi},${Yi} `
        path += `A${Ri},${Ri} 0 0 0 ${Cx - Ri},${Cy} `
        path += 'Z '

        return path
    }

    const { Cx, Ro, Ri, Xo, Cy, Xi } = getPathValues(max)
    return (
        <svg
            height="100%"
            version="1.1"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                width: width,
                height: height,
                overflow: 'hidden',
                position: 'relative',
                left: 0,
                top: 0,
            }}
        >
            <defs>
                <filter id={uniqueFilterId}>
                    <feOffset dx="0" dy="3" />
                    <feGaussianBlur result="offset-blur" stdDeviation="5" />
                    <feComposite
                        operator="out"
                        in="SourceGraphic"
                        in2="offset-blur"
                        result="inverse"
                    />
                    <feFlood floodColor="black" floodOpacity="0.2" result="color" />
                    <feComposite operator="in" in="color" in2="inverse" result="shadow" />
                    <feComposite operator="over" in="shadow" in2="SourceGraphic" />
                </filter>
            </defs>
            <path
                className="fill-gray-700/10"
                fill="currentColor"
                stroke="none"
                d={getPath(max)}
                filter={`url(#${uniqueFilterId})`}
            />
            <path
                className={ColorSchemes[color]}
                fill="currentColor"
                stroke="none"
                d={getPath(value)}
                filter={`url(#${uniqueFilterId})`}
            />

            <text
                x={width / 2}
                y={radius}
                textAnchor="middle"
                className={twMerge('fill-grey-700 text-4xl', valueLabelClassName)}
            >
                {valueFormatter(value)}
            </text>
            <text
                x={(Cx - Ro + (Cx - Ri)) / 2}
                y={Cy + minMaxLabelsOffset}
                textAnchor="middle"
                className={twMerge('fill-grey-700/60 text-lg', minMaxLabelClassName)}
            >
                {minLabel || min}
            </text>
            <text
                x={(Xo + Xi) / 2}
                y={Cy + minMaxLabelsOffset}
                textAnchor="middle"
                className={twMerge('fill-grey-700/60 text-lg', minMaxLabelClassName)}
            >
                {maxLabel || max}
            </text>
        </svg>
    )
}

const clamp = (num: number, min: number = 0, max: number = Infinity) =>
    num <= min ? min : num >= max ? max : num

import { useController, Path } from 'react-hook-form'
import { useEffect } from 'react'

import { getInputButtonProps, Input } from '@laserfocus/ui/beam'
import { CallOutlinedIcon } from '@laserfocus/ui/icons'

import { FormControlProps, SalesObject } from '../get-form-control'

import { getControllerRules, getInputProps, useStateReducer } from './formcontrol-util'

export function FormControlPhone<T extends SalesObject = SalesObject>({
    fieldMetadata,
    id,
    variant,
    control,
    submit,
    name,
}: FormControlProps<T>) {
    const { field, fieldState } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })
    const [state, setState] = useStateReducer({
        isFocused: false,
        inputValue: field.value ? field.value.toString() : '',
    })
    useEffect(() => {
        if (!state.isFocused) {
            setState({ inputValue: field.value ? field.value.toString() : '' })
        }
    }, [field.value, setState, state.isFocused])
    const value = field.value
    return (
        <Input
            id={id}
            variant={variant}
            disabled={!fieldMetadata.updateable}
            {...getInputProps(field, fieldState, state, setState, submit)}
            button={
                value ? (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                        {...getInputButtonProps({ icon: CallOutlinedIcon })}
                        title={`Call ${value}`}
                        href={`tel:${value}`}
                        rel="noopener noreferrer"
                    />
                ) : undefined
            }
        />
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function DocumentFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M4.8 4.8a2.4 2.4 0 0 1 2.39-2.4h5.5c.64 0 1.24.25 1.7.7l4.09 4.09-.02-.02c.45.45.7 1.06.7 1.7v10.3a2.4 2.4 0 0 1-2.4 2.4H7.15a2.41 2.41 0 0 1-2.4-2.42V4.74l.05.06ZM7.2 12a1.19 1.19 0 0 1 1.19-1.21h7.2-.01c.66-.01 1.2.53 1.2 1.19 0 .66-.54 1.2-1.2 1.2H8.36a1.2 1.2 0 0 1-1.2-1.22l.04.04Zm1.2 3.6h-.01c-.67 0-1.2.53-1.2 1.2 0 .66.53 1.2 1.2 1.19h7.2-.01a1.2 1.2 0 0 0 0-2.41h-7.2l.02.02Z"
            />
        </SvgIcon>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function CollapseOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M15.793 19.957a1 1 0 001.414-1.414l-4.5-4.5a1 1 0 00-1.414 0l-4.5 4.5a1 1 0 101.414 1.414L12 16.164l3.793 3.793zM8.207 4.045a1 1 0 10-1.414 1.414l4.5 4.5a1 1 0 001.414 0l4.5-4.5a1 1 0 00-1.414-1.414L12 7.838 8.207 4.045z" />
        </SvgIcon>
    )
}

import { z } from '@laserfocus/shared/decoder'

import { UserIdSchema, OrgIdSchema } from '../base/id.types'

export const authContextSchema = z.object({
    userId: UserIdSchema,
    orgId: OrgIdSchema,
    accessToken: z.string(),
})

export const authSchema = authContextSchema.extend({
    instanceUrl: z.string().url(),
    sandbox: z.boolean().optional().default(false),
})

export type AuthToken = Required<z.infer<typeof authSchema>>
/**
 * There is an issue with TS that the AuthContext
 * comes in with Optional parameters into other modules
 */
export type AuthContext = Required<z.infer<typeof authContextSchema>>

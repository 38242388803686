import { useEffect, useState } from 'react'
import { EditorContent } from '@tiptap/react'
import { useSubscribe } from 'replicache-react'

import { Button, ConfirmPrompt, FormInput, Modal } from '@laserfocus/ui/beam'
import { AddOutlinedIcon, Delete2OutlinedIcon, SaveOutlinedIcon } from '@laserfocus/ui/icons'
import { LockedFeatureOverlay, useIsLocked } from '@laserfocus/client/feature-subscription'
import { queryCanCreateNoteTemplate } from '@laserfocus/client/data-access-shared'
import { getClient } from '@laserfocus/client/replicache'

import { useNoteEditor } from '../useNoteEditor'

import { EditorFormatButtons } from './NoteToolbar'

interface EditNoteTemplateModalProps {
    show: boolean
    initialTemplate?: TemplateData
    createTemplate(data: Omit<TemplateData, 'id'>): void
    updateTemplate(data: Partial<Omit<TemplateData, 'id'>>): void
    deleteTemplate(): void
    close(): void
}

export interface TemplateData {
    id: string
    name: string
    body: string | undefined
}

export function EditNoteTemplateModal(props: EditNoteTemplateModalProps) {
    return (
        <Modal show={props.show} onClose={props.close}>
            <Modal.Overlay />
            <Modal.Container variableHeight className="w-[40rem]">
                <EditNoteTemplateModalInner {...props} />
            </Modal.Container>
        </Modal>
    )
}

function EditNoteTemplateModalInner({
    close,
    initialTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,
}: EditNoteTemplateModalProps) {
    const hasInitialTemplate = Boolean(initialTemplate)
    const [name, setName] = useState(initialTemplate?.name || '')
    const [hasTouchedTitleInput, setHasTouchedTitleInput] = useState(false)
    const [isDelteConfirmPromptOpen, setIsDelteConfirmPromptOpen] = useState(false)
    const editor = useNoteEditor({
        content: initialTemplate?.body,
    })

    useEffect(() => {
        if (hasInitialTemplate) {
            editor?.commands.focus('end')
        }
    }, [editor, hasInitialTemplate])

    const canCreateNoteTemplate = useSubscribe(getClient(), queryCanCreateNoteTemplate, true, [])
    const { isLocked, subscription } = useIsLocked(!initialTemplate && !canCreateNoteTemplate)

    return (
        <>
            <Modal.Header close={close} border>
                {hasInitialTemplate ? 'Edit template' : 'Add template'}
            </Modal.Header>
            <div className="px-8 pb-4 max-h-[50vh] overflow-y-scroll">
                <LockedFeatureOverlay
                    isLocked={isLocked}
                    lockedClassName="mt-2"
                    subscription={subscription}
                    lockedFeature="more than 2 note templates"
                >
                    <FormInput
                        label="Name"
                        id="edit-note-template-modal-name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        focusOnMount={!hasInitialTemplate}
                        className="grid-cols-[auto,1fr] pt-4 pb-3"
                        onBlur={() => setHasTouchedTitleInput(true)}
                        error={hasTouchedTitleInput && !name && 'Name is required'}
                    />
                    {/* z-[9] is necessary so it is under the blur of LockedFeatureOverlay which is z-10*/}
                    <div className="grid grid-flow-col gap-1 justify-start sticky top-0 z-[9] bg-white/90 pt-1">
                        <EditorFormatButtons editor={editor} />
                    </div>
                    <EditorContent className="pt-2" editor={editor} />
                </LockedFeatureOverlay>
            </div>
            <Modal.Footer border className={initialTemplate ? 'justify-between' : 'justify-end'}>
                {initialTemplate && (
                    <>
                        <Button
                            variant="tertiary"
                            iconComponent={Delete2OutlinedIcon}
                            iconClassName="text-red-500"
                            onClick={() => setIsDelteConfirmPromptOpen(true)}
                        >
                            Delete
                        </Button>
                        <ConfirmPrompt
                            show={isDelteConfirmPromptOpen}
                            onSubmit={() => {
                                setIsDelteConfirmPromptOpen(false)
                                window.setTimeout(deleteTemplate, 300)
                            }}
                            onCancel={() => setIsDelteConfirmPromptOpen(false)}
                            title="Delete Note Template"
                            description="Are you sure you want to delete the note template?"
                            submitButtonTitle="Delete"
                        />
                    </>
                )}
                <Button
                    type="submit"
                    variant="primary"
                    iconComponent={hasInitialTemplate ? SaveOutlinedIcon : AddOutlinedIcon}
                    disabled={!name || isLocked}
                    onClick={() => {
                        const body = editor?.getHTML()
                        if (initialTemplate) {
                            const diff: Partial<Omit<TemplateData, 'id'>> = {}

                            if (name !== initialTemplate.name) {
                                diff.name = name
                            }
                            if (body !== initialTemplate.body) {
                                diff.body = body
                            }

                            updateTemplate(diff)
                        } else {
                            createTemplate({ name, body })
                        }
                    }}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </>
    )
}

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export const DropdownInput = forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<'input'>>(
    function DropdownInput(props, ref) {
        return (
            <input
                {...props}
                ref={ref}
                // Placeholder color: Contrast of placeholder on disabled input is really low, but needs to be distinguishable from value on disabled input. Placeholder on disabled input should not be important, so this is fine.
                className={twMerge(
                    'transition-colors px-2 py-[0.3125rem] bg-white/5 rounded-md outline-none text-sm font-medium leading-[1.4] text-ellipsis w-full block tabular-nums',
                    props.disabled
                        ? 'text-white/60 placeholder-white/20'
                        : 'text-white hover:bg-white/10 placeholder-white/40',
                    props.className
                )}
            />
        )
    }
)

import { useEffect, useMemo } from 'react'
import { useObserver } from 'mobx-react-lite'
import { take } from 'lodash'

import {
    AVATAR_COLUMN_WIDTH,
    Column,
    getAllColumns,
    getVisibleColumns,
    TableDataContextValue,
    useStackActions,
} from '@laserfocus/client/feature-table'
import { useObjectPool } from '@laserfocus/client/root-store-context'
import {
    OrgStack,
    Sorting,
    OpportunityProps,
    AccountProps,
    LeadProps,
    ContactProps,
} from '@laserfocus/shared/models'
import { useBridgeStackStore } from '@laserfocus/client/store-shared'
import { mutateStack } from '@laserfocus/client/data-access-shared'
import { useWindowSize } from '@laserfocus/ui/util-react'

import { SIDEBAR_WIDTH } from '../../layout/SettingsLayout'

export type Row = OpportunityProps | AccountProps | LeadProps | ContactProps

const MAX_SHOWN_ROWS = 10

export interface SettingsTableData {
    stack: OrgStack | undefined
    // updateStack(update: StackUpdate): void
    rows: Row[]
    visibleColumns: Column[]
    columnSections: {
        salesObjectType: string
        columns: Column[]
    }[]
    isFetching: boolean
    didFetch: boolean
    totalCount?: number
    setColumnsOrder(columnKeys: string[]): void
    errorMessage?: string
}

export function useSettingsTableData(stackId: string): SettingsTableData {
    const objectPool = useObjectPool()
    const bridgeStackStore = useBridgeStackStore()

    const { rows, stack, isFetching, errorMessage, didFetch, totalCount, fetchableStack } =
        useObserver(() => {
            const stack = bridgeStackStore.orgStacksById.get(stackId)
            const fetchableStack = bridgeStackStore.fetchableStacksById.get(stackId)

            if (!fetchableStack) {
                return {
                    rows: [] as Row[],
                    stack,
                    isFetching: false,
                    didFetch: false,
                    count: 0,
                }
            }

            return {
                rows: take(fetchableStack.sortedData, MAX_SHOWN_ROWS) as Row[],
                stack,
                isFetching: fetchableStack.isInitiallyLoading,
                didFetch: fetchableStack.didFirstFetch,
                errorMessage: fetchableStack.errorMessage,
                totalCount: fetchableStack.serverCount ?? undefined,
                fetchableStack,
            }
        })
    useEffect(() => {
        if (stack && !didFetch && !isFetching) {
            bridgeStackStore.initFetchableStackFromOrgStack(stack)
        }
    }, [stack, didFetch, isFetching, bridgeStackStore, stackId])
    useEffect(() => {
        if (fetchableStack) {
            return fetchableStack.onMount()
        }
    }, [fetchableStack])

    const actions = useStackActions(stack, stack ?? null, 'org')

    const { visibleColumns, columnSections } = useObserver(() => {
        if (!stack || !actions) {
            return {
                visibleColumns: [],
                columnSections: [],
            }
        }
        const visibleColumns = getVisibleColumns(stack, actions, objectPool)
        const columnSections = getAllColumns(stack, actions, objectPool)
            .map((columnsObject) => ({
                salesObjectType: columnsObject.salesObjectType,
                columns: columnsObject.columns,
            }))
            .filter(({ columns }) => columns.length)
        return { visibleColumns, columnSections }
    })

    return {
        stack,
        rows,
        visibleColumns,
        columnSections,
        setColumnsOrder: (columnKeys: string[]) => actions?.setColumnsOrder(columnKeys),
        isFetching,
        didFetch,
        totalCount,
        errorMessage,
    }
}

interface SettingsTableContextProps
    extends Pick<SettingsTableData, 'visibleColumns' | 'rows' | 'setColumnsOrder'> {
    stackId: string
    color: string
    sorting?: Sorting
}
export function useSettingsTableContext({
    visibleColumns,
    rows,
    setColumnsOrder,
    stackId,
    sorting,
    color,
}: SettingsTableContextProps) {
    const gridTemplateColumns = `${AVATAR_COLUMN_WIDTH}px ${visibleColumns
        .map((column) => `minmax(${column.width}px, ${column.width}fr)`)
        .join(' ')}`
    const { width } = useWindowSize()
    const tableContext: TableDataContextValue = useMemo(() => {
        return {
            columns: visibleColumns,
            rows,
            gridTemplateColumns,
            isStickyLeftActive: false,
            searchTerm: '',
            setColumnsOrder,
            setIsStickyLeftActive(isStickyLeftActive: boolean) {},
            setSorting(sorting: Sorting) {
                mutateStack.setSorting(stackId, sorting, 'org')
            },
            tableWidth: width - SIDEBAR_WIDTH,
            stackId,
            sorting,
            tableHeadBackgroundColor: color,
        }
    }, [color, gridTemplateColumns, rows, setColumnsOrder, sorting, stackId, visibleColumns, width])
    return tableContext
}

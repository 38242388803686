import { z } from 'zod'

let fallbacks: z.ZodError<any>[] = []

export function setFallback(error: z.ZodError<any>) {
    fallbacks.push(error)
}

export function collectFallbacks(nextFallbacks: z.ZodError<any>[] = []) {
    const collectedFallbacks = fallbacks

    fallbacks = nextFallbacks

    return collectedFallbacks
}

import { observer } from 'mobx-react-lite'

import { SelectCell as BaseSelectCell } from '@laserfocus/client/ui-shared-datagrid'

import { TableCellProps } from './TableCell'

export const SelectCell = observer(function SelectCell({
    salesObject,
    column,
    searchTerm,
}: TableCellProps) {
    const options = column
        .getSelectOptions(salesObject)
        .map((option) => ({ ...option, label: option.label || '' }))

    return (
        <BaseSelectCell
            name={column.key}
            value={column.getValue(salesObject) as string | null}
            updateValue={(value) => column.updateValue(salesObject, value)}
            options={options}
            readOnly={!column.isEditable(salesObject)}
            searchTerm={searchTerm}
        />
    )
})

import { useState } from 'react'

import type { SuggestOptions } from '@laserfocus/vendor/time-suggest'

import { Dropdown, DropdownProps } from '../Dropdown/Dropdown'

import { CompactDatePickerCalendar } from './CompactDatePickerCalendar'
import { CompactDatePickerSelect } from './CompactDatePickerSelect'

export interface CompactDatePickerProps
    extends Omit<DropdownProps, 'content'>,
        CompactDatePickerInnerProps {}

export function CompactDatePicker({
    initialDate,
    dateOnly,
    onDateSubmit,
    suggestOptions,
    allowEmpty,
    ...props
}: CompactDatePickerProps) {
    return (
        <Dropdown
            // Height of CompactDatePickerCalendar with date and time
            minHeight={dateOnly ? undefined : 317}
            {...props}
            content={
                <CompactDatePickerInner
                    initialDate={initialDate}
                    dateOnly={dateOnly}
                    onDateSubmit={onDateSubmit}
                    suggestOptions={suggestOptions}
                    allowEmpty={allowEmpty}
                />
            }
        />
    )
}

interface CompactDatePickerInnerProps {
    /** Date to select when datepicker is opened */
    initialDate?: Date | null
    /** Whether to not show hours and minutes in datepicker */
    dateOnly?: boolean
    /** Called with currently selected date when user submits date */
    onDateSubmit(date: Date): void

    /** Wether the user can submit an empty value */
    allowEmpty?: boolean

    suggestOptions?: SuggestOptions
}

function CompactDatePickerInner({
    initialDate,
    dateOnly,
    onDateSubmit,
    suggestOptions,
    allowEmpty,
}: CompactDatePickerInnerProps) {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)

    return (
        <div className="p-2 w-80">
            {isCalendarOpen ? (
                <CompactDatePickerCalendar
                    initialDate={initialDate}
                    dateOnly={dateOnly}
                    onDateSubmit={onDateSubmit}
                    openSelect={() => setIsCalendarOpen(false)}
                />
            ) : (
                <CompactDatePickerSelect
                    initialDate={initialDate}
                    dateOnly={dateOnly}
                    onDateSubmit={onDateSubmit}
                    openCalendar={() => setIsCalendarOpen(true)}
                    suggestOptions={suggestOptions}
                    allowEmpty={allowEmpty}
                />
            )}
        </div>
    )
}

import { z } from 'zod'

import { reportFallbacks } from './report-fallbacks'
import { enrichZodArrayError } from './investigate-error'

export interface DecodeSuccess<Output, Input> {
    success: true
    data: Output
    fallback: Fallback<Input>
}

export interface DecodeFailure<Input> {
    success: false
    fallback: Fallback<Input>
    error: z.ZodError<Input>
}

type Fallback<Input> = z.ZodError<Input> | null

export function parseAndReport<Schema extends z.ZodArray<any>, Input extends any[]>(
    data: Input,
    schema: Schema,
    logger: {
        error(err: any): void
    },
    params?: Partial<z.ParseParams>
) {
    const decoded = decode(data, schema, params)

    if (isFailure(decoded)) {
        throw new Error(decoded.error.toString())
    }
    if (decoded.fallback) {
        enrichZodArrayError(data, decoded.fallback).forEach((err) => logger.error(err))
    }

    const result = decoded.data.filter(isTruthy)
    return result
}

export function decode<Schema extends z.Schema<any>, Input>(
    data: Input,
    schema: Schema,
    params?: Partial<z.ParseParams>
) {
    return reportFallbacks(() => schema.safeParse(data, params)) as
        | DecodeSuccess<z.infer<Schema>, Input>
        | DecodeFailure<Input>
}

export function isSuccess<Schema extends z.Schema<any>, Input>(
    result: DecodeSuccess<z.infer<Schema>, Input> | DecodeFailure<Input>
): result is DecodeSuccess<z.infer<Schema>, Input> {
    return result.success
}

export function isFailure<Schema extends z.Schema<any>, Input>(
    result: DecodeSuccess<z.infer<Schema>, Input> | DecodeFailure<Input>
): result is DecodeFailure<Input> {
    return !result.success
}

type Truthy<T> = Exclude<T, null | undefined | false | '' | 0>

export function isTruthy<T>(value: T): value is Truthy<T> {
    return Boolean(value)
}

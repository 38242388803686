import { JSONValue, ReadTransaction, WriteTransaction } from 'replicache'
import { z } from 'zod'

import { getClient } from '@laserfocus/client/replicache'
import {
    applyValidators,
    Prefix,
    NotesMutatorSchema,
    ContentNote,
    ContentNoteContent,
    OptimisticNoteId,
    NoteRelation,
    ContentNoteInput,
    createOptimisticNoteId,
    UserId,
    ContentNoteId,
    ContentDocumentUpdate,
    PinnedActivities,
} from '@laserfocus/shared/models'

interface OptimisticContentNoteContent extends Omit<ContentNoteContent, 'Id'> {
    Id: OptimisticNoteId
}

interface OptimisticContentNote extends Omit<ContentNote, 'Id' | 'CreatedById'> {
    Id: OptimisticNoteId
}

type CreateNoteOptimistic = {
    userId: UserId
    optimisticId?: string
}
export async function createNote(
    values: ContentNoteInput,
    related: string[],
    { userId, optimisticId }: CreateNoteOptimistic
) {
    const createdDate = new Date().toISOString()
    const args: z.infer<typeof NotesMutatorSchema.createNote> = {
        optimisticId: (optimisticId as any) || createOptimisticNoteId(),
        relatedRecords: related,
        values,
        optimistic: {
            CreatedById: userId,
            OwnerId: userId,
            LastModifiedById: userId,
            CreatedDate: createdDate,
            LastModifiedDate: createdDate,
        },
    }
    const client = getClient<typeof mutators>()
    return client.mutate.createNote(args)
}
export async function deleteNote(id: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.deleteNote({ id })
}
export async function updateNoteTitle(id: ContentNoteId, title: string) {
    const args: z.infer<typeof NotesMutatorSchema.updateNoteTitle> = {
        id,
        title,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    }
    const client = getClient<typeof mutators>()
    return client.mutate.updateNoteTitle(args)
}
export async function updateNoteBody(id: ContentNoteId, body: string) {
    const args: z.infer<typeof NotesMutatorSchema.updateNoteBody> = {
        id,
        body,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    }
    const client = getClient<typeof mutators>()
    return client.mutate.updateNoteBody(args)
}

export async function updateContentDocument(
    id: ContentNoteId | OptimisticNoteId,
    values: ContentDocumentUpdate
) {
    const args: z.infer<typeof NotesMutatorSchema.updateContentDocument> = {
        id,
        values,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    }
    const client = getClient<typeof mutators>()
    return client.mutate.updateContentDocument(args)
}

export function pinNote(rootId: string, noteId: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.pinNote({ rootId, noteId })
}
export function unpinNote(rootId: string, noteId: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.unpinNote({ rootId, noteId })
}

export const mutators = applyValidators(NotesMutatorSchema, {
    async createNote(tx, args) {
        const { Content, SharingPrivacy, ...values } = args.values
        const content: OptimisticContentNoteContent = {
            Id: args.optimisticId!,
            Body: args.values.Content!,
            __typename: 'ContentNoteContent',
        }
        const meta: OptimisticContentNote = {
            Id: args.optimisticId,
            FileType: 'SNOTE',
            FileExtension: 'snote',
            SharingPrivacy: SharingPrivacy || 'N',
            __typename: 'ContentNote',
            ...values,
            ...args.optimistic,
        }
        const relations: NoteRelation[] = args.relatedRecords.map((relatedId) => ({
            sobjectId: relatedId,
            noteId: args.optimisticId,
            createdDateTime: args.optimistic?.CreatedDate,
            __typename: 'ContentNoteRelation',
        }))
        await Promise.all([
            putContent(tx, content),
            putMeta(tx, meta),
            ...relations.map((r) => putRelation(tx, r)),
        ])
    },
    async deleteNote(tx, args) {
        const existingLinks = await tx
            .scan({ indexName: 'notesRelationByNote', prefix: args.id })
            .keys()
            .toArray()

        await Promise.all([
            ...existingLinks.map(([index, primaryKey]) => tx.del(primaryKey)),
            tx.del(getMetaKey(args.id)),
            tx.del(getContentKey(args.id)),
        ])
    },
    async updateNoteTitle(tx, args) {
        const note = await getMeta(tx, args.id)
        if (note) {
            note.Title = args.title
            note.LastModifiedDate = args.optimistic.LastModifiedDate
            await putMeta(tx, note)
        }
    },
    async updateNoteBody(tx, args) {
        const content = await getContent(tx, args.id)
        if (content) {
            content.Body = args.body
            await putContent(tx, content)
        }
    },
    async updateContentDocument(tx, args) {
        let note = await getMeta(tx, args.id)
        if (note) {
            note = {
                ...note,
                ...args.optimistic,
                ...args.values,
            }
            await putMeta(tx, note)
        }
    },
    async pinNote(tx, args) {
        const rootPinned = await getPinned(tx, args.rootId)
        const updatedPinned: PinnedActivities = rootPinned
            ? {
                  ...rootPinned,
                  notes: [...rootPinned.notes, args.noteId],
              }
            : {
                  id: args.rootId,
                  lastModifiedDate: new Date().toISOString(),
                  activities: [],
                  notes: [args.noteId],
              }
        await putPinned(tx, updatedPinned)
    },
    async unpinNote(tx, args) {
        const rootPinned = await getPinned(tx, args.rootId)
        if (!rootPinned) {
            return
        }
        const updatedPinned: PinnedActivities = {
            ...rootPinned,
            notes: rootPinned.notes.filter((a) => a !== args.noteId),
        }
        await putPinned(tx, updatedPinned)
    },
})

async function getContent(tx: ReadTransaction, id: string) {
    return tx.get(getContentKey(id)) as Promise<ContentNoteContent | null>
}
async function putContent(
    tx: WriteTransaction,
    content: ContentNoteContent | OptimisticContentNoteContent
) {
    return tx.put(getContentKey(content.Id), content as JSONValue)
}
async function getMeta(tx: ReadTransaction, id: string) {
    return tx.get(getMetaKey(id)) as Promise<ContentNote | null>
}
async function putMeta(tx: WriteTransaction, meta: ContentNote | OptimisticContentNote) {
    return tx.put(getMetaKey(meta.Id), meta as JSONValue)
}
async function putRelation(tx: WriteTransaction, relation: NoteRelation) {
    const idKey = [relation.sobjectId, relation.noteId].join('#')
    const key = [Prefix.ContentNote, 'relation', idKey].join('/')
    return tx.put(key, relation)
}

function getContentKey(id: string) {
    return [Prefix.ContentNote, 'content', id].join('/')
}
function getMetaKey(id: string) {
    return [Prefix.ContentNote, 'meta', id].join('/')
}

async function getPinned(tx: ReadTransaction, rootId: string) {
    return tx.get([Prefix.PinnedActivities, rootId].join('/')) as Promise<
        PinnedActivities | undefined
    >
}

async function putPinned(tx: WriteTransaction, input: PinnedActivities) {
    tx.put([Prefix.PinnedActivities, input.id].join('/'), input)
}

import { createContext, useContext, useMemo, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useSubscribe } from 'replicache-react'

import {
    DateString,
    DATE_FORMAT,
    Prefix,
    MonthStringSchema,
    Quota,
} from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { setQuota as updateQuota } from '@laserfocus/client/data-access-shared'

export const FALLBACK_QUOTA = 20000

type Timeframe = 'last-7-days' | 'last-30-days' | 'all-time'

type TimeframeConfig = {
    value: Timeframe
    label: string
    getStart(): DateString | undefined
    getEnd(): DateString | undefined
}

function getDateTimeString(date: Date): DateString {
    return format(date, DATE_FORMAT) as DateString
}

export const TIMEFRAMES: Array<TimeframeConfig> = [
    {
        value: 'last-7-days',
        label: 'Last 7 days',
        getStart() {
            return getDateTimeString(addDays(new Date(), -7))
        },
        getEnd() {
            return getDateTimeString(new Date())
        },
    },
    {
        value: 'last-30-days',
        label: 'Last 30 days',
        getStart() {
            return getDateTimeString(addDays(new Date(), -30))
        },
        getEnd() {
            return getDateTimeString(new Date())
        },
    },
    {
        value: 'all-time',
        label: 'All Time',
        getStart() {
            return undefined
        },
        getEnd() {
            return undefined
        },
    },
]

export const CounterContext = createContext<{
    timeframe: Timeframe
    setTimeframe: React.Dispatch<React.SetStateAction<Timeframe>>
    quota: number | null
    setQuota: (rate: number) => void
}>({
    timeframe: 'last-7-days',
    setTimeframe: () => {},
    quota: 20000,
    setQuota: () => {},
})

function setQuota(quota: number) {
    const date = MonthStringSchema.parse(new Date().toISOString())
    updateQuota({ amount: quota, date })
}

export function CounterContextProvider({ children }: { children: React.ReactNode }) {
    const [timeframe, setTimeframe] = useState<Timeframe>('last-7-days')
    const quota = useSubscribe<number | null>(
        getClient(),
        async (tx) => {
            const quotas = await tx.scan({ prefix: Prefix.Quota }).values().toArray()
            const mostRecent = quotas.reverse()[0] as Quota | undefined

            return mostRecent?.amount ?? null
        },
        null,
        []
    )

    const value = useMemo(
        () => ({
            timeframe,
            setTimeframe,
            quota,
            setQuota,
        }),
        [quota, timeframe]
    )
    return <CounterContext.Provider value={value}>{children}</CounterContext.Provider>
}

export function useCounterContext() {
    return useContext(CounterContext)
}

import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { makePersonDetailsPath, PersonQueryParams } from '@laserfocus/client/util-routing'
import { LeadId, Prefix, LeadModel, SearchResult, FailedMutation } from '@laserfocus/shared/models'
import { useHistoryStore } from '@laserfocus/client/feature-search'
import { getClient } from '@laserfocus/client/replicache'
import { countActivity, mutateSObject, RootSyncState } from '@laserfocus/client/data-access-shared'
import { MutationErrorModal } from '@laserfocus/client/shared-sales-object-form-control'
import { FailedMutationScope, useFailedMutationScope } from '@laserfocus/client/shared-error'
import { visitRoot } from '@laserfocus/client/data-access-shared'

import NotFoundPage from './NotFoundPage'
import usePersonActivities from './modules/usePersonActivitiesRCQuery'
import { PersonContextProvider } from './modules/PersonContext'
import PersonPage from './PersonPage'
import { useSyncState } from './modules/useRootSyncState'
import { useLeadNotes } from './notes/useRootNotes'
import { useRootPinned } from './modules/useRootPinned'
import { useConvertLeadModal } from './ConvertLeadModal/ConvertLeadContext'
import { ConvertLeadModal } from './ConvertLeadModal/ConvertLeadModal'
import { IsInOverlayContext } from './IsInOverlayContext'

interface LeadContainerProps {
    personId: LeadId
    isInOverlay?: boolean
    isInTable?: boolean
}

export default function LeadContainer({ personId, isInOverlay, isInTable }: LeadContainerProps) {
    const navigate = useNavigate()
    const syncState = useSyncState(personId)
    const { isConvertModalOpen, setIsConvertModalOpen } = useConvertLeadModal()
    const { lead, loadingRoot } = useLeadPage(personId, syncState)
    const { pinnedActivities, pinnedNotes } = useRootPinned(personId)
    const { open, history, isLoadingHistory } = usePersonActivities(
        personId,
        syncState,
        pinnedActivities
    )

    const { notes, loading: loadingNotesState, disabled } = useLeadNotes(personId, pinnedNotes)
    useEffect(() => {
        visitRoot({ rootId: personId as LeadId })
    }, [personId])
    useEffect(() => {
        return () => setIsConvertModalOpen?.(false)
    }, [personId, setIsConvertModalOpen])

    useEffect(() => {
        if (!loadingRoot) {
            const handle = setTimeout(() => {
                countActivity(isInOverlay ? 'record_viewed_overlay' : 'record_viewed_full')
            }, 5000)
            return () => clearTimeout(handle)
        }
    }, [personId, loadingRoot, isInOverlay])
    useEffect(() => {
        if (!isInOverlay && lead && lead.ConvertedAccountId && !isConvertModalOpen) {
            const params: PersonQueryParams = {}
            if (lead.ConvertedContactId) {
                params.contactId = lead.ConvertedContactId
            }
            if (lead.ConvertedOpportunityId) {
                params.opportunityId = lead.ConvertedOpportunityId
            }
            //@TODO: check for activityId
            const convertedPath = makePersonDetailsPath(lead.ConvertedAccountId, params)
            navigate(convertedPath)
        }
    }, [isConvertModalOpen, isInOverlay, lead, navigate])

    const filterFailedMutation = useCallback(
        (f: FailedMutation) => {
            return Boolean(
                lead?.Id && ['updateObject'].includes(f.mutation.name) && f.target?.id === lead.Id
            )
        },
        [lead?.Id]
    )

    if (!loadingRoot && !lead) {
        return <NotFoundPage />
    }
    if (!isInOverlay && lead && lead.ConvertedAccountId && !isConvertModalOpen) {
        // Waiting for redirect
        return null
    }

    return (
        <IsInOverlayContext.Provider value={{ isInOverlay: !!isInOverlay }}>
            <PersonContextProvider rootId={personId} lead={lead}>
                <FailedMutationScope
                    name={isInOverlay ? 'LeadContainerOverlay' : 'LeadContainer'}
                    filter={filterFailedMutation}
                >
                    {lead && !isConvertModalOpen && <LeadErrorContainer lead={lead} />}
                    <PersonPage
                        rootId={personId}
                        loadingRoot={loadingRoot}
                        loadingActivityHistory={isLoadingHistory}
                        openActivities={open}
                        historyActivities={history}
                        notes={notes}
                        loadingNotes={loadingNotesState}
                        notesDisabled={disabled}
                        isInOverlay={isInOverlay}
                    />

                    {setIsConvertModalOpen && (
                        <ConvertLeadModal
                            show={isConvertModalOpen}
                            closeModal={() => setIsConvertModalOpen(false)}
                        />
                    )}
                </FailedMutationScope>
            </PersonContextProvider>
        </IsInOverlayContext.Provider>
    )
}

function LeadErrorContainer({ lead }: { lead: LeadModel }) {
    const { failedMutations, discard } = useFailedMutationScope()
    const failedMutation = failedMutations[0]

    function close() {
        if (failedMutation) {
            discard(failedMutation.mutationId)
            mutateSObject.discardFailedMutation(failedMutation.mutationId)
        }
    }

    return failedMutation ? (
        <MutationErrorModal
            mutation={failedMutation.deleted ? undefined : failedMutation}
            salesObject={lead}
            close={close}
        />
    ) : null
}

function useLeadPage(personId: LeadId, syncState: RootSyncState) {
    const lead = useLead(personId)
    const sync = syncState.lead

    const historyStore = useHistoryStore()

    const isConverted = lead?.IsConverted
    const historyItem = useMemo(
        () => ({
            Id: lead?.Id,
            Name: lead?.Name,
            AvatarUrl: lead?.AvatarUrl,
            Company: lead?.Company,
            SObjectType: 'Lead',
            Website: lead?.Website,
            Email: lead?.Email,
        }),
        [lead?.Id, lead?.Name, lead?.AvatarUrl, lead?.Company, lead?.Website, lead?.Email]
    )

    useEffect(() => {
        if (historyItem.Id && historyItem.Name && !isConverted) {
            historyStore.updateSearchNavigationHistory(historyItem as SearchResult)
        }
    }, [historyItem, historyStore, isConverted])

    return {
        loadingRoot: !sync?.didLoad,
        lead,
    }
}

function useLead(id: LeadId): LeadModel | null {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx: ReadTransaction) => {
            const lead = await tx.get([Prefix.Lead, id].join('/'))
            return lead ?? null
        },
        null,
        [id]
    ) as LeadModel | null
}

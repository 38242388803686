import { parseISO } from 'date-fns'
import { intersection } from 'lodash'

import { getDateShort, getTime } from '@laserfocus/ui/util-locale'

import { Column } from '../table-context'
import { toDisplayValue as timeCellDisplayValue } from '../TableCell/TimeCell'

export function getValueLabel(column: Column, value: any) {
    switch (column.type) {
        case 'time':
            return timeCellDisplayValue(value)
        case 'date':
            return getDateDisplayValue(value, true)
        case 'datetime':
            return getDateDisplayValue(value, false)
        case 'picklist':
        case 'reference':
            const options = column.getSelectOptions()
            const optionLabel = options?.find((a) => a.value === value)?.label
            return optionLabel || value
        case 'multipicklist':
            const multiOptions = column.getSelectOptions()
            const valueAsArray = parseRecordMultiselectValues(value)
            const valueLabels = valueAsArray.map((value) => {
                return multiOptions?.find((a) => a.value === value)?.label || value
            })
            return valueLabels.join(', ')
        default:
            return value
    }
}

export function parseMultiSelectValues(values: string[][] | string[]) {
    const asArrays = values.map(parseRecordMultiselectValues)
    const inter = intersection(...asArrays)
    return inter
}

export function parseRecordMultiselectValues(v: string[] | string | null) {
    if (typeof v === 'string') {
        return v.split(';')
    }
    return v || []
}

export function hasMultiselectMultipleValues(values: Array<string[] | string>): boolean {
    const asString = values.map((v) => {
        if (typeof v === 'string') {
            return v
        }
        return (v || []).join(';')
    })
    const unique = new Set(...asString)
    return unique.size > 1
}

function getDateDisplayValue(rawDate: string | Date | undefined, dateOnly: boolean | undefined) {
    const date = typeof rawDate === 'string' ? parseISO(rawDate) : rawDate
    if (!date) {
        return ''
    }
    if (dateOnly) {
        return getDateShort(date)
    }
    return `${getDateShort(date)} ${getTime(date)}`
}

import { get } from 'lodash'

export function sortByReference<Item>(
    list: Item[],
    key: string,
    referenceList: string[],
    nullsFirst?: boolean
): Item[] {
    return list.sort((a, b) => {
        const aValue = get(a, key) as unknown as string
        const bValue = get(b, key) as unknown as string
        const aIndex = referenceList.indexOf(aValue)
        const bIndex = referenceList.indexOf(bValue)
        if (aIndex === -1 && bIndex !== -1) {
            return nullsFirst ? -1 : 1
        } else if (bIndex === -1 && aIndex !== -1) {
            return nullsFirst ? 1 : -1
        }
        return aIndex - bIndex
    })
}

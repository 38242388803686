import { useState } from 'react'

import { Button, ButtonGroup, ConfirmPrompt } from '@laserfocus/ui/beam'
import { EditOutlinedIcon, ClockOutlinedIcon, Delete2OutlinedIcon } from '@laserfocus/ui/icons'
import { TimeStackModal } from '@laserfocus/client/feature-stacks'
import { Stack } from '@laserfocus/shared/models'
import { mutateStack } from '@laserfocus/client/data-access-shared'
import { navigate } from '@laserfocus/client/util-routing'

import { EditStackModal } from './EditStackModal'

export function StackMoreActions({ stack }: { stack: Stack }) {
    return (
        <ButtonGroup>
            <EditStackButton stack={stack} />
            <TimeStackButton stack={stack} />
            <DeleteButton stackId={stack.id} />
        </ButtonGroup>
    )
}

function EditStackButton({ className, stack }: { className?: string; stack: Stack }) {
    const [showModal, setShowModal] = useState(false)
    return (
        <>
            <Button
                iconComponent={EditOutlinedIcon}
                className={className}
                onClick={() => setShowModal(true)}
            />
            <EditStackModal show={showModal} stack={stack} closeModal={() => setShowModal(false)} />
        </>
    )
}

function TimeStackButton({ className, stack }: { className?: string; stack: Stack }) {
    const [showModal, setShowModal] = useState(false)
    return (
        <>
            <Button
                iconComponent={ClockOutlinedIcon}
                className={className}
                onClick={() => setShowModal(true)}
                iconClassName={stack.timing && 'text-blue-500'}
            />
            <TimeStackModal stack={stack} show={showModal} closeModal={() => setShowModal(false)} />
        </>
    )
}

function DeleteButton({ className, stackId }: { className?: string; stackId: string }) {
    const [showConfirm, setShowConfirm] = useState(false)

    return (
        <>
            <Button
                iconComponent={Delete2OutlinedIcon}
                className={className}
                iconClassName="text-red-500"
                onClick={() => setShowConfirm(true)}
                title="Deactivate stack"
            />
            <ConfirmPrompt
                show={showConfirm}
                onCancel={() => setShowConfirm(false)}
                onSubmit={() => {
                    mutateStack.deactivateStack(stackId)
                    navigate('/settings/stacks')
                }}
                title="Do you want to deactivate this stack?"
                submitButtonTitle="Deactivate"
                description="Users that extended this stack still have access to it."
            />
        </>
    )
}

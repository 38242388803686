import { MutationErrorResponse } from '@laserfocus/shared/models'

export function getMutationErrorMessage(error: MutationErrorResponse) {
    let title: string
    let descriptionParts: string[] = []
    const [mainError, ...restFormErrors] = error.formErrors
    if (mainError) {
        title = mainError
    }
    if (restFormErrors) {
        restFormErrors.forEach((message) => {
            descriptionParts.push(message)
        })
    }
    error.fieldErrors.forEach((fieldError) => {
        const msg = `${fieldError.message}`
        if (!title) {
            title = msg
        } else {
            descriptionParts.push(`- ${msg}`)
        }
    })

    return {
        title,
        description: descriptionParts.join('\n'),
    }
}

import { useState } from 'react'

import { useSubscription } from '@laserfocus/client/data-access-shared'
import { SubscriptionUtil } from '@laserfocus/client/model'
import { Button } from '@laserfocus/ui/beam'

import { ProPlanModal } from './PlanModal/ProPlanModal'

export function PlanButton() {
    const [isPlanOpen, setIsPlanOpen] = useState(false)

    const { isLoading, subscription, orgSubscription } = useSubscription()

    if (
        isLoading ||
        !subscription ||
        orgSubscription?.plan === 'team' ||
        subscription?.plan === 'pro'
    ) {
        return null
    }

    const trialDays = SubscriptionUtil.daysUntilTrialEnd(subscription)

    return (
        <>
            <Button variant="tertiary" size="small" onClick={() => setIsPlanOpen(true)}>
                {trialDays ? (
                    <>
                        <span className="bg-blue-500 rounded-md px-2 py-1 leading-[1.3] font-bold text-[10px] text-white mr-1 my-1">
                            Pro
                        </span>{' '}
                        <span className={trialDays <= 3 ? 'text-red-500' : undefined}>
                            Trial expires in {trialDays} days
                        </span>
                    </>
                ) : (
                    <>
                        <span className="bg-blue-500 rounded-md px-2 py-1 leading-[1.3] font-bold text-[10px] text-white mr-1 my-1">
                            Free
                        </span>
                        Plan
                    </>
                )}
            </Button>

            <ProPlanModal
                show={isPlanOpen}
                closeModal={() => setIsPlanOpen(false)}
                openModal={() => setIsPlanOpen(true)}
                subscription={subscription}
            />
        </>
    )
}

/**
 * `group-inner` class is for lock icon
 */
export const CLASS_NAME_INPUT_BUTTON =
    'grid grid-cols-[minmax(0,1fr),auto,auto] items-center px-2 py-[0.3125rem] rounded-md cursor-pointer outline-none text-left tabular-nums transition  disabled:cursor-default group-inner w-full'

export const CLASS_NAME_INPUT_BUTTON_LIGHT =
    'hover:bg-grey-700/5 focus-visible:ring disabled:text-grey-700'
export const CLASS_NAME_INPUT_BUTTON_DARK =
    'hover:bg-white/10 active:bg-white/10 focus-visible:ring disabled:text-white/40 disabled:bg-transparent text-white/60'

// export const CLASS_NAME_INPUT_BUTTON_EMPTY =
//     'before:content-["—"] before:text-grey-700/40 before:font-medium before:leading-[1.45]'
// export const CLASS_NAME_INPUT_BUTTON_EMPTY_DARK =
//     'before:content-["—"] before:text-white/40 before:font-medium before:leading-[1.45]'

export const CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT =
    'text-grey-700/40 font-medium leading-[1.45]'
export const CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK = 'text-white/40 font-medium leading-[1.45]'

export const CLASS_NAME_INPUT_BUTTON_CONTENT =
    'text-ellipsis whitespace-nowrap overflow-hidden text-grey-700 font-medium leading-[1.45]'
export const CLASS_NAME_INPUT_BUTTON_SELECTED = 'ring'
export const CLASS_NAME_INPUT_BUTTON_SELECTED_WITH_ERROR = 'ring ring-red-500'
export const CLASS_NAME_LOCK_ICON =
    'hidden group-inner-hover:block w-4 h-4 text-grey-700/60 ml-[0.3125rem]'
export const CLASS_NAME_LOCK_ICON_DARK = 'text-white/60'
export const CLASS_NAME_EXCLAMATION_ICON =
    'w-4 h-4 text-red-500 p-[0.3125rem] my-[-0.3125rem] mr-[-0.3125rem] box-content'

export const CLASS_NAME_MODALINPUT_DARK = 'bg-white/5 text-white'

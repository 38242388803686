import { JSONValue, ReadTransaction, WriteTransaction } from 'replicache'
import { z } from 'zod'
import { useSubscribe } from 'replicache-react'

import { NoteTemplate } from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { applyValidators, NoteTemplateMutatorSchema, Prefix } from '@laserfocus/shared/models'
import { toast } from '@laserfocus/ui/beam'

import { shouldAllowProAction, FREE_PLAN_LIMITS } from '../subscription/subscription-repository'

type CreateNoteTemplateValues = z.infer<
    typeof NoteTemplateMutatorSchema['createNoteTemplate']
>['values']
type CreateNoteTemplateOptimistic = z.infer<
    typeof NoteTemplateMutatorSchema['createNoteTemplate']
>['optimistic']

export async function createNoteTemplate(
    id: string,
    values: CreateNoteTemplateValues,
    type: 'user' | 'org' = 'user'
) {
    const rep = getClient<typeof mutators>()
    const optimistic: CreateNoteTemplateOptimistic = {
        createdDate: new Date().toISOString(),
        modifiedDate: new Date().toISOString(),
    }
    const canCreate = await checkCanCreateNoteTemplate()
    if (canCreate) {
        return rep.mutate.createNoteTemplate({
            id,
            type,
            values,
            optimistic,
        })
    }
}

async function checkCanCreateNoteTemplate() {
    const rep = getClient<typeof mutators>()

    const allowed = await rep.query(queryCanCreateNoteTemplate)

    if (!allowed) {
        toast.warn({
            title: `You can only create ${FREE_PLAN_LIMITS.NOTE_TEMPLATES} Note Templates in your current plan`,
        })
    }
    return allowed
}
export async function queryCanCreateNoteTemplate(tx: ReadTransaction) {
    const [templates, hasPro] = await Promise.all([
        tx.scan({ prefix: Prefix.NoteTemplateUser }).values().toArray() as Promise<NoteTemplate[]>,
        shouldAllowProAction(tx),
    ])
    if (hasPro) {
        return true
    }
    const notDeleted = templates.filter((a) => !a.deleted)
    if (notDeleted.length < FREE_PLAN_LIMITS.NOTE_TEMPLATES) {
        return true
    }
    return false
}

type UpdateNoteTemplateValues = z.infer<
    typeof NoteTemplateMutatorSchema['updateNoteTemplate']
>['values']
type UpdateNoteTemplateOptimistic = z.infer<
    typeof NoteTemplateMutatorSchema['updateNoteTemplate']
>['optimistic']

export function updateNoteTemplate(
    id: string,
    values: UpdateNoteTemplateValues,
    type: 'user' | 'org' = 'user'
) {
    const rep = getClient<typeof mutators>()
    const optimistic: UpdateNoteTemplateOptimistic = {
        modifiedDate: new Date().toISOString(),
    }
    return rep.mutate.updateNoteTemplate({
        id,
        type,
        values,
        optimistic,
    })
}

export function deleteNoteTemplate(id: string, type: 'user' | 'org' = 'user') {
    const rep = getClient<typeof mutators>()
    return rep.mutate.deleteNoteTemplate({ id, type })
}

export const mutators = applyValidators(NoteTemplateMutatorSchema, {
    createNoteTemplate(tx: WriteTransaction, args) {
        const id: Identity = {
            type: args.type,
            id: args.id,
        }
        const template = {
            id: args.id,
            ...args.values,
            ...args.optimistic,
            __typename: args.type === 'org' ? 'OrgNoteTemplate' : 'UserNoteTemplate',
            version: 1,
        }
        return putNoteTemplate(tx, id, template)
    },
    deleteNoteTemplate(tx: WriteTransaction, args) {
        const id: Identity = {
            type: args.type,
            id: args.id,
        }
        return tx.del(getKey(id))
    },
    async updateNoteTemplate(tx: WriteTransaction, args) {
        const id: Identity = {
            type: args.type,
            id: args.id,
        }
        const template = await getNoteTemplate(tx, id)
        if (template) {
            const updated = {
                ...template,
                ...args.values,
                ...args.optimistic,
            }
            await putNoteTemplate(tx, id, updated)
        }
    },
})

interface Identity {
    type: 'user' | 'org'
    id: string
}

async function getNoteTemplate(tx: ReadTransaction, id: Identity) {
    return tx.get(getKey(id)) as Promise<NoteTemplate | null>
}
async function putNoteTemplate(tx: WriteTransaction, identity: Identity, template: NoteTemplate) {
    return tx.put(getKey(identity), template as JSONValue)
}

function getKey({ type, id }: Identity) {
    const key =
        type === 'user'
            ? [Prefix.NoteTemplateUser, id].join('/')
            : [Prefix.NoteTemplateOrg, id].join('/')
    return key
}

interface UseNoteTemplatesResult {
    isLoading: boolean
    noteTemplates: NoteTemplate[]
}

export function useNoteTemplates(): UseNoteTemplatesResult {
    const replicacheClient = getClient()
    return useSubscribe(
        replicacheClient,
        async (tx) => {
            return {
                isLoading: false,
                noteTemplates: await tx.scan({ prefix: Prefix.NoteTemplate }).values().toArray(),
            } as any
        },
        { isLoading: true, noteTemplates: [] },
        []
    )
}

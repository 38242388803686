import { DropdownCheckbox } from '@laserfocus/ui/beam'
import {
    AscendingOutlinedIcon,
    DescendingOutlinedIcon,
    FilterOutlinedIcon,
    LockOutlinedIcon,
} from '@laserfocus/ui/icons'

import { Column } from '../Table/table-context'

interface ColumnsListCheckboxProps {
    column: Column
    isHovered: boolean
    setIsHovered(): void
    hoveredOptionRef?: React.RefObject<HTMLLabelElement>
    hideIcons?: boolean
}

export function ColumnsListCheckbox({
    column,
    isHovered,
    setIsHovered,
    hideIcons,
}: ColumnsListCheckboxProps) {
    return (
        <DropdownCheckbox
            disabled={column.isStickyLeft || column.columnLocked}
            isHighlighted={isHovered}
            checked={column.isShown}
            onChange={column.toggleIsShown}
            onFocus={setIsHovered}
            label={
                <div className="grid grid-flow-col gap-1 grid-cols-1 items-center">
                    <span
                        title={column.label}
                        className="overflow-x-hidden whitespace-nowrap text-ellipsis"
                    >
                        {column.label}
                    </span>
                    {!hideIcons &&
                        column.sorting &&
                        (column.sorting === 'ASC' ? (
                            <div title={`${column.label} column is sorted in ascending order`}>
                                <AscendingOutlinedIcon className="w-4 h-4 text-white/60" />
                            </div>
                        ) : (
                            <div title={`${column.label} column is sorted in descending order`}>
                                <DescendingOutlinedIcon className="w-4 h-4 text-white/60" />
                            </div>
                        ))}
                    {!hideIcons && column.filterCondition && column.conditionLocked ? (
                        <div title={`You cant remove the filter for ${column.label}`}>
                            <LockOutlinedIcon className="w-4 h-4 text-blue-500" />
                        </div>
                    ) : !hideIcons && column.filterCondition ? (
                        <div title={`${column.label} column has active filter`}>
                            <FilterOutlinedIcon className="w-4 h-4 text-blue-500" />
                        </div>
                    ) : null}
                </div>
            }
        />
    )
}

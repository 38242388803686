import {
    activityMutators,
    syncedRootsMutators,
    fieldGroupMutators,
    salesExtensionMutators,
    salesObjectMutators,
    noteMutators,
    noteTemplateMutators,
    stackMutators,
    activityCounterMutators,
    withCounters,
    settingsMutators,
    userMutators,
} from '@laserfocus/client/data-access-shared'
import { makeClient } from '@laserfocus/client/replicache'
import { Prefix } from '@laserfocus/shared/models'

import { shouldQuerySpecific } from './pull-after-push'
import { paginatePullResponse } from './paginate-pull-response'

const mutators = withCounters({
    ...stackMutators,
    ...activityMutators,
    ...syncedRootsMutators,
    ...fieldGroupMutators,
    ...salesExtensionMutators,
    ...salesObjectMutators,
    ...noteMutators,
    ...noteTemplateMutators,
    ...activityCounterMutators,
    ...settingsMutators,
    ...userMutators,
})

const indexes = [
    {
        name: 'eventsByRootId',
        prefix: `${Prefix.Event}/`,
        jsonPointer: '/RootId',
    },
    {
        name: 'tasksByRootId',
        prefix: `${Prefix.Task}/`,
        jsonPointer: '/RootId',
    },
    {
        name: 'openTasksByUser',
        prefix: `${Prefix.Task}/`,
        jsonPointer: '/idxOpenByUser',
    },
    {
        name: 'eventsByOwnerAndStartDateTime',
        prefix: `${Prefix.Event}/`,
        jsonPointer: '/idxByUserAndStartDateTime',
    },
    {
        name: 'openTasks',
        prefix: `${Prefix.Task}/`,
        jsonPointer: '/idxOpen',
    },
    {
        name: 'eventsByStartDateTime',
        prefix: `${Prefix.Event}/`,
        jsonPointer: '/StartDateTime',
    },
    {
        name: 'opportunitiesByAccountId',
        prefix: `${Prefix.Opportunity}/`,
        jsonPointer: '/AccountId',
    },
    {
        name: 'contactsByAccountId',
        prefix: `${Prefix.Contact}/`,
        jsonPointer: '/AccountId',
    },
    {
        name: 'failedTransactionByTarget',
        prefix: `${Prefix.FailedTransaction}/`,
        jsonPointer: '/targetKey',
    },
    {
        name: 'notesRelationByNote',
        prefix: `${Prefix.ContentNote}/related/`,
        jsonPointer: '/noteId',
    },
    {
        name: 'orgActivityCounterByDate',
        prefix: `${Prefix.ActivityCounter}/`,
        jsonPointer: '/date',
    },
]

export function makeReplicacheClient(userId: string) {
    const client = makeClient(userId, mutators, shouldQuerySpecific, paginatePullResponse)

    indexes.forEach((index) => client.createIndex(index))

    return client
}

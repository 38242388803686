import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ReorderOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M6.293 3.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414L8 6.414V16a1 1 0 1 1-2 0V6.414L3.707 8.707a1 1 0 0 1-1.414-1.414l4-4ZM18 17.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L16 17.586V8a1 1 0 1 1 2 0v9.586Z" />
        </SvgIcon>
    )
}

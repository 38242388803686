import { observer } from 'mobx-react-lite'
import { useSubscribe } from 'replicache-react'
import type { ReadTransaction } from 'replicache'

import {
    Step,
    StepModal,
    StepSelectDate,
    StepSelectDateState,
    StepSetTitle,
    StepSetTitleState,
} from '@laserfocus/client/shared-step-modal'
import { getActivityCreateMessage } from '@laserfocus/client/util-formatter'
import {
    ContactModel,
    dateToDateString,
    isAccountModel,
    isContactModel,
    isLeadModel,
    isOpportunityModel,
    Prefix,
} from '@laserfocus/shared/models'
import { toast } from '@laserfocus/ui/beam'
import { logger } from '@laserfocus/ui/logger'
import { Analytics } from '@laserfocus/client/util-analytics'
import { scheduleTask } from '@laserfocus/client/data-access-shared'
import { useUserId } from '@laserfocus/client/feature-auth'
import { getClient } from '@laserfocus/client/replicache'

import { SalesObject } from '../../table-context'

interface AddTaskModalProps {
    closeModal(): void
    salesObject: SalesObject
}

const titleStep: Step<StepSetTitleState> = {
    title: 'Task title',
    component: StepSetTitle,
}
const dateStep: Step<StepSelectDateState> = {
    title: 'Task date',
    component: StepSelectDate,
}

export const AddTaskModal = observer(function AddTaskModal({
    salesObject,
    closeModal,
}: AddTaskModalProps) {
    const { name, whoId, whatId, accountId } = useGetCreateTaskData(salesObject)
    const ownerId = useUserId()

    return (
        <StepModal
            titleBreadcrumb={name || undefined}
            onCancel={closeModal}
            onSubmit={([{ selectedTitle }, { selectedDate }]: readonly [
                StepSetTitleState,
                StepSelectDateState
            ]) => {
                closeModal()
                scheduleTask({
                    subject: selectedTitle,
                    date: dateToDateString(selectedDate),
                    dateTime: selectedDate.toISOString(),
                    whoId,
                    whatId: whatId ?? undefined,
                    accountId: accountId ?? undefined,
                    ownerId: ownerId ?? undefined,
                }).then(
                    () => {
                        toast.success(getActivityCreateMessage(selectedDate, name))
                        Analytics.trackEvent({
                            event: 'activity_scheduled',
                            location: Analytics.parseStackFromSobject(salesObject.__typename),
                        })
                    },
                    (error) => {
                        logger.error(error)
                        toast.error({ title: 'Something went wrong' })
                    }
                )
            }}
            steps={[titleStep, dateStep] as const}
        />
    )
})

function useGetCreateTaskData(
    salesObject: SalesObject
): Partial<{ name: string; whoId: string; whatId: string; accountId: string }> {
    const contact = useSubscribe(
        getClient(),
        async (tx: ReadTransaction) => {
            if (salesObject.__typename !== 'Account') {
                return null
            }

            // In case we have a contact selected in Account Detail page
            const accountId = salesObject.Id
            const oldContactKey = `account#${accountId}:recent:contact`
            const contactKey = `lf:${oldContactKey}`
            const contactId = localStorage.getItem(contactKey)
            if (contactId) {
                const contact = await tx.get([Prefix.Contact, contactId].join('/'))
                if (contact) {
                    return contact
                }
            }
            const accountContacts = await tx
                .scan({ indexName: 'contactsByAccountId', prefix: accountId, limit: 1 })
                .values()
                .toArray()
            return accountContacts[0] ?? null
        },
        null,
        [salesObject.__typename, salesObject.Id]
    ) as ContactModel | null

    if (isOpportunityModel(salesObject)) {
        return {
            name: salesObject.Name,
            whoId: undefined,
            whatId: salesObject.Id,
            accountId: salesObject.AccountId,
        }
    } else if (isAccountModel(salesObject)) {
        return {
            name: contact?.Name || salesObject.Name,
            whoId: contact?.Id,
            whatId: salesObject.Id,
            accountId: salesObject.Id,
        }
    } else if (isLeadModel(salesObject)) {
        return {
            name: salesObject.Name,
            whoId: salesObject.Id,
            whatId: undefined,
            accountId: undefined,
        }
    } else if (isContactModel(salesObject)) {
        return {
            name: salesObject.Name,
            whoId: salesObject.Id,
            whatId: salesObject.AccountId,
            accountId: salesObject.AccountId,
        }
    }
    return {}
}

import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema } from '../base/date.types'
import { makeIdSchema } from '../base/id.types'
import { Brand } from '../base/type-utills'

export const GroupIdSchema = makeIdSchema<GroupId>('00G', 'GroupId')
export type GroupId = Brand<string, 'GroupId'>
export function isGroupId(id: string | undefined | null): id is GroupId {
    return id ? id.substr(0, 3) === '00G' : false
}

export const GroupSchema = z.object({
    Id: z.string(),
    DeveloperName: z.string(),
    Name: z.string().nullable(),
    Type: z.string(),
    LastModifiedDate: DateTimeStringSchema,
    CreatedDate: DateTimeStringSchema,
    QueueSobjects: z.array(z.string()),
    __typename: z.literal('Group'),
})

export type Group = z.infer<typeof GroupSchema> & { Id: string; __typename: 'Group' }

import { Replicache } from 'replicache'

import { ObjectPool } from '@laserfocus/client/data-layer'
import { Prefix, SalesObjectExtension } from '@laserfocus/shared/models'

export interface PoolSalesExtension extends SalesObjectExtension {
    Id: string
    LastModifiedDate: string
    __typename: 'SalesObjectExtension'
}

export class SalesObjectExtensionBridge {
    objectPool: ObjectPool
    cleanups: Array<() => void> = []
    replicache: Replicache

    constructor(objectPool: ObjectPool, replicache: Replicache) {
        this.objectPool = objectPool
        this.replicache = replicache
    }

    onInit() {
        this.cleanups.push(
            this.replicache.subscribe(
                async (tx) => {
                    const salesExtension = (await tx
                        .scan({ prefix: `${Prefix.SalesExtension}/` })
                        .values()
                        .toArray()) as SalesObjectExtension[]
                    return salesExtension || []
                },
                {
                    onData: (salesExtension: SalesObjectExtension[]) => {
                        const ext = salesExtension.map(
                            (a): PoolSalesExtension => ({
                                ...a,
                                Id: a.id,
                                LastModifiedDate: a.lastModifiedDate,
                                __typename: 'SalesObjectExtension',
                            })
                        )
                        this.objectPool.attachAll(ext)
                    },
                }
            )
        )
    }

    onDestroy() {
        let current
        while ((current = this.cleanups.pop())) {
            current()
        }
    }
}

import { isFinite, isNumber, get } from 'lodash'
import { parseISO } from 'date-fns'

import {
    isDateConstant,
    SFDateConstantValue,
    getDurationForConstant,
    FilterCondition,
} from '@laserfocus/shared/models'
import { logger as rootLogger } from '@laserfocus/ui/logger'

const logger = rootLogger.child({
    name: 'make-filter',
    level: 'error',
})

export type ValueComparableCondition = FilterCondition & {
    path: string
}

type RecordValue = string | number | Date | boolean | null | undefined | string[]
export type RecordRow = Record<string, RecordValue>

type FilterFunc = (record: RecordRow, id?: number) => boolean
type CompareFunc = (recordVal?: RecordValue, checkVal?: RecordValue) => boolean
type ValueGetter = (val: RecordValue) => RecordValue

export function makeFilter(conditions: ValueComparableCondition[]): FilterFunc {
    const checkers: Array<FilterFunc> = conditions.map((cond) => buildCheck(cond))

    return (record: RecordRow, idx?: number): boolean => {
        for (const check of checkers) {
            if (!check(record)) {
                return false
            }
        }
        return true
    }
}

function buildCheck(cond: ValueComparableCondition): FilterFunc {
    let getter
    if (['date', 'datetime'].includes(cond.fieldType) && isDateConstant(cond.values[0])) {
        return buildDateDurationCheck(cond)
    }

    switch (cond.fieldType) {
        case 'date':
            getter = dateGetter
            break
        case 'datetime':
            getter = dateTimeGetter
            break
        case 'int':
            getter = intGetter
            break
        case 'double':
            getter = doubleGetter
            break
        case 'multipicklist':
            getter = arrayGetter
            break
    }

    switch (cond.operator) {
        case 'EQ':
            return buildOrCheck(cond, eqCompare, getter as ValueGetter)
        case 'NEQ':
            return buildAndCheck(cond, neqCompare, getter as ValueGetter)
        case 'GT':
            return buildSingleCheck(cond, (a, b) => a! > b!, getter as ValueGetter)
        case 'GTE':
            return buildSingleCheck(cond, (a, b) => a! >= b!, getter as ValueGetter)
        case 'LT':
            return buildSingleCheck(
                cond,
                (a, b) => {
                    return a! < b!
                },
                getter as ValueGetter
            )
        case 'LTE':
            return buildSingleCheck(cond, (a, b) => a! <= b!, getter as ValueGetter)
        case 'INCLUDES':
            return buildOrCheck(
                cond,
                includeCompare as CompareFunc,
                caseInsensitiveGetter as ValueGetter
            )
        case 'EXCLUDES':
            return buildAndCheck(
                cond,
                excludeCompare as CompareFunc,
                caseInsensitiveGetter as ValueGetter
            )
        default:
            throw new Error(`Could not build a check for ${(cond as any).operator}`)
    }
}

function eqCompare(recordValue: RecordValue, searchValue: RecordValue): boolean {
    if (Array.isArray(recordValue)) {
        return recordValue.some((v) => eqCompare(v, searchValue))
    }
    if (searchValue === '' || searchValue === null || searchValue === undefined) {
        return !recordValue && recordValue !== false && recordValue !== 0
    }
    if (typeof recordValue === 'string' && typeof searchValue === 'string') {
        return recordValue.toLowerCase() === searchValue.toLowerCase()
    }
    return recordValue === searchValue
}

function neqCompare(recordValue: RecordValue, searchValue: RecordValue): boolean {
    if (Array.isArray(recordValue)) {
        const allNeq = recordValue.every((v) => neqCompare(v, searchValue))
        return allNeq
    }
    if (searchValue === '' || searchValue === null || searchValue === undefined) {
        return !!recordValue || recordValue === 0 || recordValue === false
    }
    if (typeof recordValue === 'string' && typeof searchValue === 'string') {
        return recordValue.toLowerCase() !== searchValue.toLowerCase()
    }
    return recordValue !== searchValue
}

function includeCompare(recordValue?: string | null, searchValue?: string | null): boolean {
    if (!searchValue) {
        return true
    } else if (!recordValue) {
        return false
    }
    return recordValue.toLowerCase().includes(searchValue.toLowerCase())
}
function excludeCompare(recordValue?: string | null, searchValue?: string | null): boolean {
    if (!recordValue || !searchValue) {
        return true
    }
    return !recordValue.toLowerCase().includes(searchValue.toLowerCase())
}

function dateGetter(d?: Date | string | null) {
    if (d instanceof Date) {
        return d.getTime()
    }
    return d ? parseISO(d).getTime() : undefined
}

function dateTimeGetter(d: Date | string | null): number | undefined {
    if (d instanceof Date) {
        return d.getTime()
    }
    return d ? parseISO(d).getTime() : undefined
}

function intGetter(d: string | null): number | string {
    if (isNumber(d)) {
        return d
    }
    const canBeParsed = Boolean(d && isFinite(parseInt(d)))
    if (canBeParsed) {
        return parseInt(d!)
    }
    return d!
}
function doubleGetter(d: string): number | string {
    if (!d || isNumber(d)) {
        return d
    }
    const parsed = parseLocaleNumber(d)
    return parsed
}

function arrayGetter(d: null | string | string[]): string | string[] {
    /**
     * This is ambiguous, it allows parsing of values provided with a;b; syntax,
     * but does not make a nested array for values provided with values: ['a']
     */
    if (typeof d === 'string' && d.includes(';')) {
        return d?.split(';')
    }
    return d || []
}

function caseInsensitiveGetter(d: string): string {
    return d ? d.toLowerCase() : d
}

function wrapGetter(v: RecordValue, getter: ValueGetter | undefined) {
    return getter ? getter(v) : v
}

function buildOrCheck(
    cond: ValueComparableCondition,
    compare: CompareFunc,
    getter?: ValueGetter
): FilterFunc {
    return (item) => {
        const path = cond.path
        const val = wrapGetter(get(item, path), getter)
        const hasTrue = cond.values.some((v) => {
            return compare(val, wrapGetter(v, getter))
        })
        logger.debug(
            'query',
            cond.values.map((v) => wrapGetter(v, getter)),
            'value',
            val,
            '-->',
            hasTrue
        )
        return hasTrue
    }
}

function buildAndCheck(
    cond: ValueComparableCondition,
    compare: CompareFunc,
    getter?: ValueGetter
): FilterFunc {
    return (item) => {
        const path = cond.path
        const val = wrapGetter(get(item, path), getter)
        const hasAll = cond.values.every((v) => compare(val, wrapGetter(v, getter)))
        logger.debug(
            'query',
            cond.values.map((v) => wrapGetter(v, getter)),
            'value',
            val,
            '-->',
            hasAll
        )
        return hasAll
    }
}

function buildSingleCheck(
    cond: ValueComparableCondition,
    compare: CompareFunc,
    getter?: ValueGetter
): FilterFunc {
    return (item) => {
        const path = cond.path
        const rawVal = get(item, path)
        const val = wrapGetter(rawVal, getter)
        const v = wrapGetter(cond.values[0], getter)
        const res = compare(val, v)
        logger.debug('query', v, 'rawVal', rawVal, '-->', res)
        return res
    }
}

function parseLocaleNumber(stringNumber: string): number {
    const thousandSeparator = (1111).toLocaleString().replace(/1/g, '')
    const decimalSeparator = (1.1).toLocaleString().replace(/1/g, '')

    return parseFloat(
        stringNumber
            .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
            .replace(new RegExp('\\' + decimalSeparator), '.')
    )
}

function buildDateDurationCheck(cond: ValueComparableCondition) {
    const dateConst = cond.values[0]
    const duration = getDurationForConstant(dateConst as SFDateConstantValue)
    if (!duration) {
        return () => true
    }
    const [startD, endD] = duration
    const start = startD.getTime()
    const end = endD.getTime()
    switch (cond.operator) {
        case 'EQ':
            return buildSingleCheck(
                cond,
                (a) => {
                    const result = a! >= start && a! <= end
                    return result
                },
                dateTimeGetter as ValueGetter
            )
        case 'NEQ':
            return buildAndCheck(cond, (a) => a! < start || a! > end, dateTimeGetter as ValueGetter)
        case 'GT':
            return buildSingleCheck(cond, (a) => a! > end, dateTimeGetter as ValueGetter)
        case 'GTE':
            return buildSingleCheck(cond, (a) => a! > start, dateTimeGetter as ValueGetter)
        case 'LT':
            return buildSingleCheck(cond, (a) => a! < start, dateTimeGetter as ValueGetter)
        case 'LTE':
            return buildSingleCheck(cond, (a) => a! < end, dateTimeGetter as ValueGetter)
        default:
            throw new Error(`Could not build a check for ${cond.operator}`)
    }
}

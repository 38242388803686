import { OnboardingLayout } from '../shared/OnboardingLayout'
import { OnboardingSteps } from '../shared/OnboardingSteps'

export function SelectRolePage() {
    return (
        <OnboardingLayout>
            <OnboardingSteps stepKey="role" />
        </OnboardingLayout>
    )
}

import { useSubscription } from '@laserfocus/client/data-access-shared'
import { SubscriptionUtil } from '@laserfocus/client/model'

type RequiredPlan = 'pro' | 'team'

export function useIsLocked(requiresPro: boolean, requiredPlan: RequiredPlan = 'pro') {
    const { isLoading, subscription, orgSubscription } = useSubscription()

    const planCondition =
        requiredPlan === 'pro'
            ? orgSubscription?.plan !== 'team' && subscription && subscription.plan === 'free'
            : orgSubscription?.plan !== 'team'

    const isLocked = Boolean(
        requiresPro &&
            !isLoading &&
            planCondition &&
            !SubscriptionUtil.daysUntilTrialEnd(subscription)
    )
    return {
        isLocked,
        subscription,
    }
}

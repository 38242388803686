import { useState } from 'react'

import { MultiSelectCell } from '@laserfocus/client/ui-shared-datagrid'

import { BulkEditCellProps } from './BulkEditCell'

export function BulkEditMultiSelectCell({
    column,
    isEditable,
    updateValue,
    value,
    hasMultipleValues,
}: BulkEditCellProps) {
    const [values, setValues] = useState(value)

    function submit() {
        const previous = value.sort().join(',')
        const current = values.sort().join(',')
        if (previous !== current) {
            updateValue(values)
        }
    }

    const options = column
        .getSelectOptions()
        .map((option) => ({ ...option, label: option.label || '' }))

    return (
        <MultiSelectCell
            name={column.key}
            value={values}
            updateValue={(value) => setValues(value)}
            options={options}
            readOnly={!isEditable}
            placeholder={hasMultipleValues ? 'multiple' : undefined}
            onClose={submit}
            theme="dark"
        />
    )
}

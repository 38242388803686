import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { Button, Checkbox, toast } from '@laserfocus/ui/beam'
import { AddOutlinedIcon, CheckOutlinedIcon, RescheduleOutlinedIcon } from '@laserfocus/ui/icons'
import {
    StepModal,
    StepSelectDate,
    StepSelectDateState,
    StepSetTitle,
    StepSetTitleState,
} from '@laserfocus/client/shared-step-modal'
import { Analytics } from '@laserfocus/client/util-analytics'
import {
    scheduleTask,
    rescheduleTask,
    rescheduleEvent,
} from '@laserfocus/client/data-access-shared'
import { ActivityUtil } from '@laserfocus/client/model'
import { useUserId } from '@laserfocus/client/feature-auth'
import { dateToDateString, NewActivity, TaskId } from '@laserfocus/shared/models'
import { logger } from '@laserfocus/ui/logger'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'

interface BulkEditBarProps {
    selectedActivities: NewActivity[]
    areAllActivitiesSelected: boolean
    selectAll(): void
    unselectAll(): void
    completeTask(id: TaskId): Promise<void>
}

export const BulkEditBar = observer(function BulkEditBar({
    selectedActivities,
    areAllActivitiesSelected,
    selectAll,
    unselectAll,
    completeTask,
}: BulkEditBarProps) {
    const hasUncompletedTasks = selectedActivities.some(
        (a) => ActivityUtil.isTask(a) && !a.IsClosed
    )

    return (
        <div className="bg-grey-700 rounded-md sticky bottom-1 py-2 px-6 shadow-[0px_24px_156px_rgba(0,0,0,0.08),0px_10.0266px_65.1731px_rgba(0,0,0,0.0575083),0px_5.36071px_34.8446px_rgba(0,0,0,0.0476886),0px_3.00517px_19.5336px_rgba(0,0,0,0.04),0px_1.59602px_10.3742px_rgba(0,0,0,0.0323114),0px_0.664142px_4.31692px_rgba(0,0,0,0.0224916)] grid grid-cols-[auto,auto,1fr] gap-4 items-center text-sm leading-[1.3] text-white/60">
            <div className="grid grid-flow-col items-center">
                <EverboardingHint
                    name="everboarding_activity_list_bulk_edit_select_all"
                    label="Select all to bulk edit"
                    placement="left"
                    containerClassName="-translate-y-7 -translate-x-5"
                    labelClassName="-rotate-12 translate-y-3"
                >
                    <Checkbox
                        checked={areAllActivitiesSelected}
                        indeterminate={!areAllActivitiesSelected}
                        onChange={(event) => (event.target.checked ? selectAll() : unselectAll())}
                        title="Select all activities"
                        variant="dark"
                        id="BulkEditBar-select-all"
                        className="flex"
                    />
                </EverboardingHint>
                <label htmlFor="BulkEditBar-select-all" className="pl-2">
                    Select all tasks
                </label>
            </div>
            <div className="tabular-nums">{`${selectedActivities.length} selected`}</div>
            <div className="grid grid-flow-col justify-end -mr-2">
                {hasUncompletedTasks && (
                    <CompleteButton
                        selectedActivities={selectedActivities}
                        unselectAll={unselectAll}
                        completeTask={completeTask}
                    />
                )}
                <RescheduleButton
                    selectedActivities={selectedActivities}
                    unselectAll={unselectAll}
                />
                <AddTaskButton selectedActivities={selectedActivities} unselectAll={unselectAll} />
            </div>
        </div>
    )
})

interface BulkEditButtonProps {
    selectedActivities: NewActivity[]
    unselectAll(): void
}

interface CompleteButtonProps extends BulkEditButtonProps {
    completeTask(id: TaskId): Promise<void>
}

function CompleteButton({ selectedActivities, unselectAll, completeTask }: CompleteButtonProps) {
    return (
        <Button
            variant="quaternary"
            size="small"
            iconComponent={CheckOutlinedIcon}
            onClick={() =>
                Promise.allSettled(
                    selectedActivities
                        .filter((a) => ActivityUtil.isTask(a) && !a.IsClosed)
                        .map((activity) => {
                            return completeTask(activity.Id as TaskId)
                        })
                ).then((settledPromises) => {
                    Analytics.trackEvent({
                        event: 'activity_bulk_edited',
                        location: Analytics.getLocation() as any,
                        amount: selectedActivities.length,
                        action: 'complete',
                    })
                    const rejectedPromises = settledPromises.filter(
                        (promise) => promise.status === 'rejected'
                    )
                    const rejectedAmount = rejectedPromises.length

                    if (rejectedAmount) {
                        logger.error(rejectedPromises)
                        toast.error({
                            title: `Could not complete ${rejectedAmount} ${
                                rejectedAmount === 1 ? 'activity' : 'activities'
                            }`,
                        })
                    } else {
                        unselectAll()
                    }
                }, showGenericErrorToast)
            }
        >
            Set as done
        </Button>
    )
}

function RescheduleButton({ selectedActivities, unselectAll }: BulkEditButtonProps) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    function rescheduleActivities(date: Date) {
        Promise.allSettled(
            selectedActivities
                .filter((a) => ActivityUtil.isTask(a) || ActivityUtil.isEvent(a))
                .map((activity) => {
                    if (ActivityUtil.isEvent(activity)) {
                        return rescheduleEvent({
                            eventId: activity.Id,
                            dateTime: date.toISOString(),
                        })
                    }
                    return rescheduleTask({
                        taskId: activity.Id,
                        date: dateToDateString(date),
                        dateTime: date.toISOString(),
                    })
                })
        ).then((settledPromises) => {
            const fulfilledAmount = settledPromises.filter(
                (promise) => promise.status === 'fulfilled'
            ).length
            const rejectedAmount = settledPromises.filter(
                (promise) => promise.status === 'rejected'
            ).length

            Analytics.trackEvent({
                event: 'activity_bulk_edited',
                location: Analytics.getLocation() as any,
                amount: selectedActivities.length,
                action: 'reschedule',
            })

            if (fulfilledAmount) {
                toast.success({
                    title: `Rescheduled ${fulfilledAmount} ${
                        fulfilledAmount === 1 ? 'activity' : 'activities'
                    }`,
                })
            }

            if (rejectedAmount) {
                toast.error({
                    title: `Could not reschedule ${rejectedAmount} ${
                        rejectedAmount === 1 ? 'activity' : 'activities'
                    }`,
                })
            }

            if (fulfilledAmount && !rejectedAmount) {
                unselectAll()
            }
        }, showGenericErrorToast)
    }

    return (
        <>
            <Button
                variant="quaternary"
                size="small"
                iconComponent={RescheduleOutlinedIcon}
                onClick={() => setIsModalOpen(true)}
            >
                Reschedule
            </Button>
            {isModalOpen && (
                <StepModal
                    onCancel={() => setIsModalOpen(false)}
                    onSubmit={([{ selectedDate }]: readonly [StepSelectDateState]) => {
                        setIsModalOpen(false)
                        rescheduleActivities(selectedDate)
                    }}
                    steps={
                        [
                            {
                                title: 'Reschedule tasks',
                                component: StepSelectDate,
                            },
                        ] as const
                    }
                />
            )}
        </>
    )
}

function AddTaskButton({ selectedActivities, unselectAll }: BulkEditButtonProps) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const ownerId = useUserId()

    function addTasks(title: string, date: Date) {
        Promise.allSettled(
            selectedActivities.map(({ WhoId, WhatId, AccountId }) =>
                scheduleTask({
                    subject: title,
                    date: dateToDateString(date),
                    dateTime: date.toISOString(),
                    whoId: WhoId ?? undefined,
                    whatId: WhatId ?? undefined,
                    accountId: AccountId ?? undefined,
                    ownerId: ownerId ?? undefined,
                })
            )
        ).then((settledPromises) => {
            const fulfilledAmount = settledPromises.filter(
                (promise) => promise.status === 'fulfilled'
            ).length
            const rejectedAmount = settledPromises.filter(
                (promise) => promise.status === 'rejected'
            ).length

            Analytics.trackEvent({
                event: 'activity_bulk_edited',
                location: Analytics.getLocation() as any,
                amount: selectedActivities.length,
                action: 'add_new',
            })

            if (fulfilledAmount) {
                toast.success({
                    title: `Added task to ${fulfilledAmount} ${
                        fulfilledAmount === 1 ? 'activity' : 'activities'
                    }`,
                })
            }

            if (rejectedAmount) {
                toast.error({
                    title: `Could not add task to ${rejectedAmount} ${
                        rejectedAmount === 1 ? 'activity' : 'activities'
                    }`,
                })
            }

            if (fulfilledAmount && !rejectedAmount) {
                unselectAll()
            }
        }, showGenericErrorToast)
    }

    return (
        <>
            <Button
                variant="quaternary"
                size="small"
                iconComponent={AddOutlinedIcon}
                onClick={() => setIsModalOpen(true)}
            >
                Add task
            </Button>
            {isModalOpen && (
                <StepModal
                    onCancel={() => setIsModalOpen(false)}
                    onSubmit={([{ selectedTitle }, { selectedDate }]: readonly [
                        StepSetTitleState,
                        StepSelectDateState
                    ]) => {
                        setIsModalOpen(false)
                        addTasks(selectedTitle, selectedDate)
                    }}
                    steps={
                        [
                            {
                                title: 'Task title',
                                component: StepSetTitle,
                            },
                            {
                                title: 'Task date',
                                component: StepSelectDate,
                            },
                        ] as const
                    }
                />
            )}
        </>
    )
}

function showGenericErrorToast(error: any) {
    toast.error({
        title: 'Something went wrong',
        description: error.message || error,
    })
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

export function GiftOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.9375 3.875C7.07455 3.875 6.375 4.57455 6.375 5.4375C6.375 6.30045 7.07455 7 7.9375 7H10.8234C10.5322 5.97805 10.1458 5.24865 9.7257 4.75855C9.18815 4.13141 8.58264 3.875 7.9375 3.875V3.875ZM4.5 5.4375C4.5 6.00014 4.63517 6.53121 4.87481 7H4.1875C2.97938 7 2 7.97938 2 9.1875V11.6875C2 12.5601 2.51095 13.3134 3.25 13.6645V19.8125C3.25 21.0206 4.22938 22 5.4375 22H18.5625C19.7706 22 20.75 21.0206 20.75 19.8125V13.6645C21.489 13.3134 22 12.5601 22 11.6875V9.1875C22 7.97938 21.0206 7 19.8125 7H19.1251C19.3649 6.53121 19.5 6.00014 19.5 5.4375C19.5 3.53903 17.961 2 16.0625 2C14.8326 2 13.7194 2.52483 12.8507 3.53832C12.5309 3.91137 12.2472 4.34654 12 4.8413C11.7528 4.34654 11.4691 3.91137 11.1493 3.53832C10.2806 2.52483 9.16736 2 7.9375 2C6.03903 2 4.5 3.53903 4.5 5.4375V5.4375ZM13.1766 7H16.0625C16.9255 7 17.625 6.30045 17.625 5.4375C17.625 4.57455 16.9255 3.875 16.0625 3.875C15.4174 3.875 14.8119 4.13141 14.2743 4.75855C13.8542 5.24865 13.4678 5.97805 13.1766 7ZM4.1875 8.875C4.01491 8.875 3.875 9.01491 3.875 9.1875V11.6875C3.875 11.8601 4.01491 12 4.1875 12H11.0625V8.875H4.1875ZM12.9375 8.875V12H19.8125C19.9851 12 20.125 11.8601 20.125 11.6875V9.1875C20.125 9.01491 19.9851 8.875 19.8125 8.875H12.9375ZM12.9375 13.875H18.875V19.8125C18.875 19.9851 18.7351 20.125 18.5625 20.125H12.9375V13.875ZM11.0625 13.875V20.125H5.4375C5.26491 20.125 5.125 19.9851 5.125 19.8125V13.875H11.0625Z"
                fill="currentColor"
            />
        </SvgIcon>
    )
}

import { UseDatepickerResult } from '@laserfocus/vendor/react-datepicker-hooks'

import { DropdownButton } from '../controls/DropdownButton'

import { MonthCalendar } from './MonthCalendar'
import { DatepickerInputs } from './DatepickerInputs'

interface DatepickerCalendarProps
    extends Pick<
        UseDatepickerResult,
        | 'activeMonth'
        | 'goToPreviousMonth'
        | 'goToNextMonth'
        | 'isDateSelected'
        | 'canSelectDate'
        | 'onDateSelect'
        | 'onTimeSelect'
    > {
    dateOnly?: boolean
    className?: string
    focusOnMount?: boolean
    onDateSubmit(date: Date): void
    selectedDate: Date | null
}

export function DatepickerCalendar({
    className,
    dateOnly,
    activeMonth,
    selectedDate,
    focusOnMount,
    canSelectDate,
    isDateSelected,
    goToNextMonth,
    goToPreviousMonth,
    onDateSelect,
    onTimeSelect,
    onDateSubmit,
}: DatepickerCalendarProps) {
    return (
        <div className={className}>
            <MonthCalendar
                activeMonth={activeMonth}
                canSelectDate={canSelectDate}
                isDateSelected={isDateSelected}
                goToNextMonth={goToNextMonth}
                goToPreviousMonth={goToPreviousMonth}
                onDateSelect={dateOnly ? onDateSubmit : onDateSelect}
                dateOnly={dateOnly}
            />
            <DatepickerInputs
                selectedDate={selectedDate}
                dateOnly={dateOnly}
                focusOnMount={focusOnMount}
                onDateSelect={dateOnly ? onDateSubmit : onDateSelect}
                onTimeSelect={onTimeSelect}
                className="mt-4"
            />
            {!dateOnly && (
                <DropdownButton
                    isHighlighted
                    textCentered
                    onClick={() => selectedDate && onDateSubmit(selectedDate)}
                    className="mt-2"
                >
                    Done
                </DropdownButton>
            )}
        </div>
    )
}

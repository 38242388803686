import { observer, useObserver } from 'mobx-react-lite'
import { groupBy } from 'lodash'
import { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import arrayMove from 'array-move'
import { useForm } from 'react-hook-form'

import { FieldMetadata } from '@laserfocus/client/model'
import { useObjectPool } from '@laserfocus/client/root-store-context'
import {
    AccountModel,
    ContactModel,
    FieldGroup,
    isLeadModel,
    isOpportunityModel,
    isTruthy,
    LeadModel,
    OpportunityModel,
} from '@laserfocus/shared/models'
import {
    FormControlContextProvider,
    getFormControl,
} from '@laserfocus/client/shared-sales-object-form-control'
import { DragOutlinedIcon } from '@laserfocus/ui/icons'
import { mutateSObject } from '@laserfocus/client/data-access-shared'
import { getDirtyValues } from '@laserfocus/client/util-form'
import { Analytics, RecordEdited, useAnalyticsContext } from '@laserfocus/client/util-analytics'
import { fractional } from '@laserfocus/shared/util-common'

import {
    useAccount,
    useCurrentContact,
    useCurrentOpportunity,
    useLead,
    useRootObjectType,
} from '../../modules/PersonContext'
import { LoadingFieldsList } from '../LoadingFields'
import { ObjectFieldsHeader } from '../ObjectFieldHeader'

interface FieldGroupFieldsProps {
    fieldGroup: FieldGroup
    isLoadingSobject: boolean
    orgOrUser: 'org' | 'user'
    setFieldPosition(fieldName: string, orderKey: string): Promise<unknown>
}

export function FieldGroupFields({
    fieldGroup,
    isLoadingSobject,
    setFieldPosition,
    orgOrUser,
}: FieldGroupFieldsProps) {
    const fields = useFields(fieldGroup)

    if (isLoadingSobject) {
        return (
            <div className="p-2 pl-6">
                <LoadingFieldsList
                    amount={Object.keys(fieldGroup.visibleFieldOrderMap).length || 5}
                />
            </div>
        )
    }

    if (fields.length === 0) {
        return (
            <div className="p-2 pl-4 text-sm font-medium text-grey-700/60 text-center">
                No fields
            </div>
        )
    }

    const fieldGroupSections = Object.entries(groupBy(fields, 'fieldMetadata.objectName')).sort(
        ([salesObjectTypeA], [salesObjectTypeB]) => salesObjectTypeA.localeCompare(salesObjectTypeB)
    )

    return (
        <>
            {fieldGroupSections.map(([salesObjectType, fields], index, array) =>
                fields.some((f) => f.salesObject) ? (
                    <FieldGroupSection
                        key={salesObjectType}
                        fields={fields}
                        salesObjectType={salesObjectType as any}
                        fieldGroup={fieldGroup}
                        orgOrUser={orgOrUser}
                        setFieldPosition={setFieldPosition}
                    />
                ) : (
                    // Needs to be wrapped in div because of position: sticky
                    <div key={salesObjectType}>
                        <ObjectFieldsHeader
                            sobject={salesObjectType as any}
                            isLast={index === array.length - 1}
                            orgOrUser={orgOrUser}
                        />
                    </div>
                )
            )}
        </>
    )
}

function useFields(fieldGroup: FieldGroup) {
    const objectPool = useObjectPool()
    const salesObjects = useSalesObjects()

    return useObserver(() => {
        const allFields = Array.from(objectPool.getAll('FieldMetadata').values()) as FieldMetadata[]
        const allFieldsMap = Object.fromEntries(allFields.map((f) => [f.fullName, f]))

        const fields = fractional
            .sortByOrderKey(fieldGroup.visibleFieldOrderMap)
            .map((fieldName) => allFieldsMap[fieldName])
            .filter(isTruthy)

        const fieldsWithObject = fields.map((field) => ({
            fieldMetadata: field,
            salesObject: salesObjects[field.objectName as keyof typeof salesObjects],
        }))

        return fieldsWithObject
    })
}

function useSalesObjects() {
    const rootType = useRootObjectType()!
    const lead = useLead()
    const account = useAccount()
    const contact = useCurrentContact()
    const opportunity = useCurrentOpportunity()

    if (rootType === 'Lead') {
        return {
            Lead: lead,
        }
    }

    return {
        Contact: contact,
        Account: account,
        Opportunity: opportunity,
    }
}

type SalesObjectType = 'Lead' | 'Account' | 'Contact' | 'Opportunity'

interface FieldGroupSectionProps {
    fields: ReturnType<typeof useFields>
    salesObjectType: SalesObjectType
    fieldGroup: FieldGroup
    orgOrUser: 'org' | 'user'
    setFieldPosition(fieldName: string, orderKey: string): Promise<unknown>
}

const FieldGroupSection = observer(function FieldGroupSection({
    fields: fieldsFromProps,
    salesObjectType,
    fieldGroup,
    setFieldPosition,
    orgOrUser,
}: FieldGroupSectionProps) {
    const { location } = useAnalyticsContext()
    const salesObject = fieldsFromProps[0]?.salesObject!
    const {
        control,
        handleSubmit,
        formState: { dirtyFields, isValid, isDirty },
        reset,
    } = useForm<typeof salesObject>({
        defaultValues: salesObject,
        criteriaMode: 'all',
        // Since we validate on Blur, this would Lock the focus
        shouldFocusError: false,
        // resolver,
        mode: 'onBlur',
    })
    useEffect(() => {
        reset(salesObject)
    }, [salesObject, reset])

    const submit = handleSubmit(async (data) => {
        const changeSet = getDirtyValues(dirtyFields, data)
        // FormControlTime is not triggering onChange, which does not update isDirty
        const realDirty = isDirty || Object.keys(changeSet).length
        if (!realDirty || !isValid) {
            return
        }
        return mutateSObject
            .updateSObject(salesObject.__typename, salesObject.Id, changeSet)
            .then(() => {
                const status = getTrackingStatus(salesObject)
                const trackPayload: RecordEdited = {
                    event: 'record_edited',
                    location: location || ('person_details' as const),
                    recordType: Analytics.parseSalesObject(salesObject.__typename)!,
                    fields: Object.keys(changeSet),
                }
                if (status) {
                    trackPayload.status = status
                }
                Analytics.trackEvent(trackPayload)
            })
    })

    // Necessary because Replicache is too slow and causes a flicker in field positions
    const [fields, setFields] = useState(fieldsFromProps)

    useEffect(() => {
        setFields(fieldsFromProps)
    }, [fieldsFromProps])

    return (
        <FormControlContextProvider
            objectType={salesObjectType}
            recordTypeId={salesObject.RecordTypeId}
            currencyIsoCode={salesObject.CurrencyIsoCode}
        >
            <div>
                <ObjectFieldsHeader sobject={salesObjectType} orgOrUser={orgOrUser} />
                <DragDropContext
                    onDragEnd={({ source, destination }) => {
                        if (!destination || source.index === destination.index) {
                            return
                        }

                        const nextFields = arrayMove(fields, source.index, destination.index)

                        setFields(nextFields)

                        const sourceFieldName = fields[source.index]?.fieldMetadata.fullName
                        if (sourceFieldName) {
                            const orderKey = fractional.getOrderKeyForMove(
                                fieldGroup.visibleFieldOrderMap,
                                source.index,
                                destination.index
                            )
                            setFieldPosition(sourceFieldName, orderKey)
                        }
                    }}
                >
                    <Droppable droppableId={salesObjectType}>
                        {(droppableProvided) => (
                            <div
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}
                                className="py-2"
                            >
                                {fields.map((field, index) => {
                                    const inputId = `FieldGroup-${field.fieldMetadata.Id}`

                                    const formControl = getFormControl({
                                        fieldMetadata: field.fieldMetadata,
                                        id: inputId,
                                        control,
                                        submit,
                                    })

                                    if (!formControl) {
                                        return null
                                    }

                                    return (
                                        <Draggable
                                            key={inputId}
                                            draggableId={field.fieldMetadata.Id}
                                            index={index}
                                        >
                                            {(draggableProvided) => (
                                                <div
                                                    ref={draggableProvided.innerRef}
                                                    {...draggableProvided.draggableProps}
                                                    {...draggableProvided.dragHandleProps}
                                                    className="group grid grid-cols-[auto,minmax(0,2fr),minmax(0,3fr)] items-start outline-none focus-visible:ring pr-2 rounded-md"
                                                >
                                                    <div className="px-1 py-[0.4375rem] text-grey-700/40 opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100">
                                                        <DragOutlinedIcon className="w-4 h-4" />
                                                    </div>
                                                    <label
                                                        htmlFor={inputId}
                                                        // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
                                                        className="text-sm font-medium leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
                                                    >
                                                        {field.fieldMetadata.label}
                                                    </label>
                                                    {formControl}
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </FormControlContextProvider>
    )
})

function getTrackingStatus(
    salesObject: LeadModel | AccountModel | ContactModel | OpportunityModel
): string | undefined {
    if (isLeadModel(salesObject)) {
        return salesObject.Status
    } else if (isOpportunityModel(salesObject)) {
        return salesObject.StageName
    }
}

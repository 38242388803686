import { get, values } from 'lodash'
import { ApolloError, isApolloError } from 'apollo-client'
import { GraphQLError } from 'graphql'

import { logger } from '@laserfocus/ui/logger'
import type { FieldError, MutationErrorResponse } from '@laserfocus/shared/models'

export const USER_ERROR_CODE = 'BAD_USER_INPUT'

export function getQueryError(e: ReadonlyArray<GraphQLError>) {
    return get(e, '0.message')
}

export function getMutationErrors(e: ApolloError) {
    return getQueryError(e.graphQLErrors)
}

export function handleGraphqlError(e: ApolloError | Error): MutationErrorResponse {
    if (isUserInputError(e)) {
        console.warn(e)
        return transformToUpdateErrorResponse(e)
    }
    throw e
}

export function isUserInputError(e: ApolloError | Error): e is ApolloError {
    if (isApolloError(e)) {
        return e.graphQLErrors?.some((a) => a.extensions?.code === USER_ERROR_CODE)
    }
    return false
}

function getCode(e: ApolloError) {
    return get(e, 'graphQLErrors.0.extensions.0.code')
}

function transformToUpdateErrorResponse(e: ApolloError): MutationErrorResponse {
    const formError = getMutationErrors(e)
    const fieldErrors = getSObjectFieldValidationErrors(e)
    const formErrorIsFieldError = fieldErrors.find(
        (fe: { message: string }) => fe.message === formError
    )
    return {
        isError: true,
        code: getCode(e) || USER_ERROR_CODE,
        formErrors: formErrorIsFieldError ? [] : [formError],
        fieldErrors,
        message: formError || fieldErrors[0]?.message || 'Error from Salesforce',
        level: 'error',
    }
}

function getSObjectFieldValidationErrors(e: ApolloError): FieldError[] {
    const { graphQLErrors } = e
    return graphQLErrors.reduce<FieldError[]>((acc, error) => {
        if (error.extensions?.code !== USER_ERROR_CODE) return acc
        values(error.extensions).forEach((extension) => {
            if (!extension?.message) return false
            if (!extension.fields) return false
            if (!Array.isArray(extension.fields)) {
                logger.warn('graphql-utils:extension.fields is not an Array', extension.fields)
                return false
            }
            extension.fields.forEach((fieldPath: string) => {
                const fieldName = fieldPath.includes('CustomFields')
                    ? fieldPath.split('.')[1]
                    : fieldPath
                acc.push({ fieldName, fieldPath, message: extension.message })
            })
        })
        return acc
    }, [])
}

import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'

import { getClient } from '@laserfocus/client/replicache'
import { Prefix, UserSubscription, OrgSubscription } from '@laserfocus/shared/models'

export function useSubscription() {
    const rep = getClient()
    return useSubscribe<{
        isLoading: boolean
        subscription: null | UserSubscription
        orgSubscription: null | OrgSubscription
    }>(
        rep,
        async (tx) => {
            const [subscription, orgSubscription] = await Promise.all([
                tx.get(Prefix.UserSubscription) as Promise<UserSubscription | undefined>,
                tx.get(Prefix.OrgSubscription) as Promise<OrgSubscription | undefined>,
            ])

            return {
                subscription: subscription ?? null,
                orgSubscription: orgSubscription ?? null,
                isLoading: false,
            }
        },
        {
            isLoading: true,
            subscription: null,
            orgSubscription: null,
        },
        []
    )
}

export const FREE_PLAN_LIMITS = {
    FIELDGROUP_PER_OBJECT: 3,
    NOTE_TEMPLATES: 2,
    STACKS: 2,
}

export async function shouldAllowProAction(tx: ReadTransaction) {
    const [sub, orgSubscription] = await Promise.all([
        tx.get(Prefix.UserSubscription) as Promise<UserSubscription | undefined>,
        tx.get(Prefix.OrgSubscription) as Promise<OrgSubscription | undefined>,
    ])
    if (!sub) {
        return true
    }
    if (orgSubscription && orgSubscription.plan === 'team') {
        return true
    }
    if (sub.plan === 'free' && sub.trialEnd) {
        const endDate = new Date(sub.trialEnd)
        return endDate > new Date()
    }
    return true
}

import { getClient } from '@laserfocus/client/replicache'
import { ObjectMetadata } from '@laserfocus/shared/models'

export async function proposeTaskType(subject: string) {
    const rep = getClient()
    const metadata = (await rep.query((tx) => tx.get('objectmetadata/Task'))) as unknown as
        | ObjectMetadata
        | undefined
    if (metadata) {
        const taskTypeField = metadata?.fields.find((field) => field.name === 'Type')
        const option = taskTypeField?.picklistValues?.find(
            (v) =>
                subject.toLowerCase().includes(v.value.toLowerCase()) ||
                (v.label && subject.toLowerCase().includes(v.label?.toLowerCase()))
        )
        return option?.value
    }
}

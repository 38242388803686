import React, { useEffect, useState } from 'react'

import { FieldGroup, OrgFieldGroupInput, UserFieldGroupInput } from '@laserfocus/shared/models'
import { toast } from '@laserfocus/ui/beam'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'
import { logger } from '@laserfocus/ui/logger'

interface FieldGroupNameInputProps {
    fieldGroup?: FieldGroup
    updateTitle(title: string): Promise<unknown>
    createFieldGroup(
        input: Omit<UserFieldGroupInput | OrgFieldGroupInput, 'id' | 'rootObject'>
    ): Promise<string>
    onCreate?(id: string): void
    onEnd(): void
}

export function FieldGroupNameInput({
    fieldGroup,
    updateTitle,
    createFieldGroup,
    onCreate,
    onEnd,
}: FieldGroupNameInputProps) {
    const [fieldGroupName, setFieldGroupName] = useState(fieldGroup?.title || '')
    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)

    const { location } = useAnalyticsContext()

    useEffect(() => inputRef.current?.select(), [inputRef])

    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
        if (!fieldGroupName) {
            onEnd()
            return
        }

        const input = event.currentTarget

        input.disabled = true

        if (fieldGroup) {
            updateTitle(fieldGroupName)
                .catch((e) => {
                    logger.error(e)
                    toast.error({
                        title: 'Something went wrong',
                        description: 'Could not save field group.',
                    })
                })
                .finally(onEnd)
        } else {
            createFieldGroup({
                title: fieldGroupName,
                visibleFieldOrderMap: {},
            })
                .then(
                    (id) => {
                        Analytics.trackEvent({
                            event: 'fieldgroup_created',
                            location: location || 'person_details',
                        })
                        return onCreate?.(id)
                    },
                    (e) => {
                        logger.error(e)
                        toast.error({
                            title: 'Something went wrong',
                            description: 'Could not create field group.',
                        })
                    }
                )
                .finally(onEnd)
        }
    }

    return (
        <input
            ref={inputRef}
            aria-label="Field group name"
            placeholder="Name"
            value={fieldGroupName}
            onChange={(event) => setFieldGroupName(event.target.value)}
            onBlur={handleBlur}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    event.preventDefault()
                    event.currentTarget.blur()
                }
            }}
            className="rounded-md outline-none focus-visible:ring text-sm font-medium leading-[1.3] py-1 px-2 text-center transition-shadow"
        />
    )
}

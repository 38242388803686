import { useCallback, useEffect, createContext, useContext, useReducer, useState } from 'react'
import { useObserver } from 'mobx-react-lite'
import { intersection } from 'lodash'

import { useObjectPool } from '@laserfocus/client/root-store-context'
import { isTruthy } from '@laserfocus/shared/models'
import { toast } from '@laserfocus/ui/beam'
import { LockFilledIcon } from '@laserfocus/ui/icons'

import { Row } from '../table-context'

import { BulkConfirm, BulkEditConfirmModal } from './BulkEditConfirm'

export type BulkEditContextType = {
    bulkEditTotal: BulkEditStateTotal
    toggleBulkEditTotal(): void
    toggleEditingRecord(id: string): void
    selectedRecordIds: string[]
    selectedRecords: Array<Row>
    confirm?: BulkConfirm
    setConfirm(confirm?: BulkConfirm): void
}

const BulkEditContext = createContext<BulkEditContextType>({
    bulkEditTotal: 'none',
    toggleBulkEditTotal: () => {},
    toggleEditingRecord: () => {},
    selectedRecordIds: [],
    selectedRecords: [],
    setConfirm: () => {},
})

interface BulkEditProviderProps {
    children: React.ReactNode
    sobject: 'Lead' | 'Account' | 'Contact' | 'Opportunity' | null
    rows: Array<{ Id: string }>
}
export function BulkEditContextProvider({ children, sobject, rows }: BulkEditProviderProps) {
    const objectPool = useObjectPool()
    const [confirm, setConfirm] = useState<BulkConfirm>()

    //@TODO: fetch this for real
    const hasTeamLicense = true
    const [state, dispatch] = useReducer(bulkEditStateReducer, {
        total: 'none',
        allSelectedRecordIds: [],
        selectedRecordIds: [],
        rowIds: rows.map((a) => a.Id),
    })
    useEffect(() => {
        dispatch({ type: 'rows_loaded', rowIds: rows.map((a) => a.Id) })
    }, [rows])

    const selectedRecords = useObserver(() => {
        if (!sobject) {
            return []
        }
        return state.selectedRecordIds.map((id) => objectPool.get(sobject, id)) as Row[]
    }).filter(isTruthy)

    function toggleEditingRecord(id: string) {
        const alreadyContains = state.selectedRecordIds.includes(id)
        if (!alreadyContains && state.selectedRecordIds.length > 2 && !hasTeamLicense) {
            toast.warn(
                {
                    title: (
                        <div>
                            Unlock bulk editing more than 3 records with the{' '}
                            <a
                                href="https://www.laserfocus.io/pricing"
                                className="bg-blue-500  rounded-md px-2 py-1 leading-[1.3] font-bold text-[10px]"
                            >
                                TEAM
                            </a>{' '}
                            plan.
                        </div>
                    ),
                },
                {
                    duration: 8000,
                    id: 'bulk-edit-warn',
                    icon: (
                        <LockFilledIcon className="min-w-[1.5rem] w-6 h-6 text-yellow-300 mr-3" />
                    ),
                }
            )
        } else {
            dispatch({ type: 'toggle_record', recordId: id })
        }
    }
    const toggleBulkEditTotal = useCallback(() => {
        dispatch({ type: 'toggle_total' })
    }, [])

    return (
        <BulkEditContext.Provider
            value={{
                bulkEditTotal: state.total,
                toggleBulkEditTotal,
                toggleEditingRecord,
                selectedRecords,
                selectedRecordIds: state.selectedRecordIds,
                setConfirm,
            }}
        >
            {children}
            <BulkEditConfirmModal
                show={Boolean(confirm)}
                confirm={confirm}
                close={() => setConfirm(undefined)}
            />
        </BulkEditContext.Provider>
    )
}

type BulkEditStateTotal = 'none' | 'on' | 'some' | 'all'
type BulkEditState = {
    total: BulkEditStateTotal
    allSelectedRecordIds: string[]
    rowIds: string[]
    selectedRecordIds: string[]
}
type ToggleTotalAction = {
    type: 'toggle_total'
}
type ToggleRecordAction = {
    type: 'toggle_record'
    recordId: string
}
type LoadRowsAction = {
    type: 'rows_loaded'
    rowIds: string[]
}
function bulkEditStateReducer(
    state: BulkEditState,
    action: ToggleTotalAction | ToggleRecordAction | LoadRowsAction
): BulkEditState {
    switch (action.type) {
        case 'toggle_record': {
            const isAdding = state.allSelectedRecordIds.includes(action.recordId)
            const allSelectedRecordIds = isAdding
                ? state.allSelectedRecordIds.filter((a) => a !== action.recordId)
                : [...state.allSelectedRecordIds, action.recordId]
            const selectedRecordIds = intersection(allSelectedRecordIds, state.rowIds)
            return {
                ...state,
                allSelectedRecordIds,
                selectedRecordIds,
                total: !selectedRecordIds.length
                    ? 'none'
                    : selectedRecordIds.length === state.rowIds.length
                    ? 'all'
                    : 'some',
            }
        }
        case 'rows_loaded': {
            const selectedRecordIds = intersection(state.allSelectedRecordIds, action.rowIds)

            return {
                ...state,
                rowIds: action.rowIds,
                selectedRecordIds,
                total:
                    selectedRecordIds.length && selectedRecordIds.length === action.rowIds.length
                        ? 'all'
                        : selectedRecordIds.length
                        ? 'some'
                        : state.total,
            }
        }
        case 'toggle_total': {
            switch (state.total) {
                case 'none':
                    return {
                        ...state,
                        total: 'on',
                    }
                case 'on':
                case 'some':
                    return {
                        ...state,
                        total: 'all',
                        allSelectedRecordIds: state.rowIds,
                        selectedRecordIds: state.rowIds,
                    }
                case 'all':
                    return {
                        ...state,
                        total: 'none',
                        allSelectedRecordIds: [],
                        selectedRecordIds: [],
                    }
            }
        }
    }
    return state
}

export function useBulkEdit() {
    return useContext(BulkEditContext)
}

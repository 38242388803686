import { useEffect, useRef } from 'react'

export function useAutoFocusedRef<Element extends HTMLElement>(isActive: boolean | undefined) {
    const elementRef = useRef<Element>(null)

    useEffect(() => {
        if (isActive) {
            // Timeout necessary to prevent issues with changed scroll possition when element is in react-popper.
            const timeout = setTimeout(() => {
                elementRef.current?.focus()
            })
            return () => clearTimeout(timeout)
        }
    }, [isActive])

    return elementRef
}

import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useObserver } from 'mobx-react-lite'
import { twMerge } from 'tailwind-merge'
import Highlighter from 'react-highlight-words'

import { isTruthy, Stack } from '@laserfocus/shared/models'
import { Card } from '@laserfocus/ui/beam'
import { PersonAvatar } from '@laserfocus/client/ui-shared'
import { makeStackPath } from '@laserfocus/client/util-routing'
import { StackModel } from '@laserfocus/client/model'
import { Analytics } from '@laserfocus/client/util-analytics'
import { useBridgeStackStore } from '@laserfocus/client/store-shared'

export function StackTasks({
    stacks,
    className,
    searchTerm,
}: {
    stacks: Stack[]
    className?: string
    searchTerm?: string
}) {
    if (stacks.length === 0) {
        return null
    }
    return (
        <div className={twMerge('grid gap-2 grid-cols-1', className)}>
            {stacks.map((s) => (
                <StackTask key={s.id} stack={s} searchTerm={searchTerm} />
            ))}
        </div>
    )
}

function StackTask({ stack, searchTerm }: { stack: Stack; searchTerm?: string }) {
    const stackBridge = useBridgeStackStore()
    const fetchable = stackBridge.fetchableStacksById.get(stack.id)
    const recordCount = useObserver(() => fetchable?.recordCount)
    return (
        <Link
            to={makeStackPath(StackModel.getSlug(stack))}
            onClick={() => {
                Analytics.trackEvent({
                    event: 'timed_stack_clicked',
                    location: 'today',
                    stack: Analytics.parseStackFromSobject(stack.sobject),
                    stackName: stack.title,
                    preset: stack.preset,
                })
            }}
        >
            <Card
                className={clsx(
                    'px-3 py-2.5 transition outline-none focus-visible:ring focus-visible:ring-blue-500'
                )}
                style={{
                    backgroundColor: stack.color,
                }}
                role="button"
                tabIndex={0}
            >
                <div className="flex items-center">
                    <div className="relative flex-none">
                        <PersonAvatar emoji={stack.icon} id={stack.id} />
                    </div>
                    <h4
                        title={stack.title}
                        className="grow text-base leading-[1.4] font-medium pl-2 pr-3.5 overflow-hidden text-ellipsis whitespace-nowrap"
                    >
                        <Highlighter
                            autoEscape
                            highlightClassName="bg-blue-500/20 text-blue-700 rounded"
                            searchWords={[searchTerm].filter(isTruthy)}
                            textToHighlight={stack.title}
                        />
                    </h4>
                    <div className="flex-none text-xs leading-[1.2] font-medium text-grey-700/60 group-hover:opacity-0 group-focus-within:opacity-0">
                        {renderCount(recordCount, stack.sobject)}
                    </div>
                </div>

                <div className="pt-0.5 pl-10 text-grey-700/60 text-sm leading-[1.4] font-medium">
                    <Highlighter
                        autoEscape
                        highlightClassName="bg-blue-500/20 text-blue-700 rounded"
                        searchWords={[searchTerm].filter(isTruthy)}
                        textToHighlight={stack.description || ''}
                    />
                </div>
            </Card>
        </Link>
    )
}

function renderCount(count?: number, sobject?: 'Lead' | 'Opportunity' | 'Account' | 'Contact') {
    if (!count || !sobject) {
        return null
    }
    if (count === 1) {
        return `${count} ${sobject}`
    }
    switch (sobject) {
        case 'Lead':
            return `${count} Leads`
        case 'Opportunity':
            return `${count} Opportunities`
        case 'Account':
            return `${count} Accounts`
        case 'Contact':
            return `${count} Contacts`
    }
}

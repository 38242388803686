import { JSONValue, ReadTransaction, WriteTransaction } from 'replicache'

import { getClient } from '@laserfocus/client/replicache'
import {
    applyValidators,
    SObjectMutatorSchema,
    createOptimisticId,
    getPrefixForSobject,
    ModelCustomFields,
    Prefix,
    ConvertLeadMutationInput,
} from '@laserfocus/shared/models'

type SalesObjectType = 'Lead' | 'Account' | 'Opportunity' | 'Contact'
type SalesObjectValues = ModelCustomFields
type SalesObject = { Id: string } & SalesObjectValues

type SobjectUpdateMetadata = {
    failedMutationId: number
}

export async function updateSObject(
    sobjectType: SalesObjectType,
    id: string,
    values: SalesObjectValues,
    meta?: SobjectUpdateMetadata
) {
    const client = getClient<typeof mutators>()
    const result = await client.mutate.updateObject({
        id,
        values,
        sobject: sobjectType,
        meta,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    })
    return result
}

export async function bulkUpdateSObject(
    sobjectType: SalesObjectType,
    ids: string[],
    values: SalesObjectValues,
    meta?: SobjectUpdateMetadata
) {
    const client = getClient<typeof mutators>()
    const result = await client.mutate.bulkUpdateObjects({
        sobject: sobjectType,
        ids,
        values,
        meta,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    })
    return result
}
export async function createSObject(
    sobjectType: SalesObjectType,
    values: SalesObjectValues,
    meta?: SobjectUpdateMetadata
) {
    const client = getClient<typeof mutators>()
    const optimisticId = getOptimisticId(sobjectType)
    await client.mutate.createObject({
        sobject: sobjectType,
        values,
        optimisticId,
        meta,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    })
    return optimisticId
}

export async function discardFailedMutation(mutationId: number) {
    const client = getClient<typeof mutators>()
    await client.mutate.discardFailedMutation({ mutationId })
}

function getOptimisticId(sobject: SalesObjectType) {
    return createOptimisticId(getPrefixForSobject(sobject))
}

export function convertLead(input: ConvertLeadMutationInput) {
    const client = getClient<typeof mutators>()
    const inputWithOptimistic = {
        ...input,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    }
    return client.mutate.convertLead(inputWithOptimistic)
}

export const mutators = applyValidators(SObjectMutatorSchema, {
    async createObject(tx, args) {
        const sobject = {
            Id: args.optimisticId,
            ...args.optimistic,
            ...args.values,
        }
        await putSobject(tx, args.sobject, sobject)
    },
    async convertLead(tx, args) {
        const tasks: Array<Promise<unknown>> = [
            tx.put(`process/convertLead/${args.convert.leadId}`, args),
        ]

        if (args.meta?.failedMutationId) {
            tasks.push(deleteFailedTransaction(tx, args.meta?.failedMutationId))
        }
        await Promise.all(tasks)
        // not sure if this is worth doing optimistically
    },
    async updateObject(tx, args) {
        const existing = await getSobject(tx, args.sobject, args.id)
        const updated: SalesObject = {
            ...existing,
            ...args.optimistic,
            ...args.values,
            hasUncommittedChanges: true,
        }
        if (args.sobject === 'Lead' || args.sobject === 'Contact') {
            updated.Name = [updated.FirstName, updated.LastName].join(' ')
        }
        await putSobject(tx, args.sobject, updated)
    },
    async bulkUpdateObjects(tx, args) {
        const previous = await Promise.all(args.ids.map((id) => getSobject(tx, args.sobject, id)))

        await Promise.all(
            previous.map((existing) => {
                const updated: SalesObject = {
                    ...existing,
                    ...args.optimistic,
                    ...args.values,
                    hasUncommittedChanges: true,
                }
                if (args.sobject === 'Lead' || args.sobject === 'Contact') {
                    updated.Name = [updated.FirstName, updated.LastName].join(' ')
                }
                return putSobject(tx, args.sobject, updated)
            })
        )
    },
    async discardFailedMutation(tx, args) {
        const { mutationId } = args
        return deleteFailedTransaction(tx, mutationId)
    },
})

export async function getSobject(tx: ReadTransaction, sobjectType: SalesObjectType, id: string) {
    const sobject = await tx.get(getKey(sobjectType, id))
    return sobject as SalesObject
}

async function putSobject(tx: WriteTransaction, sobject: SalesObjectType, values: SalesObject) {
    await tx.put(getKey(sobject, values.Id), values as JSONValue)
}
function getKey(sobject: SalesObjectType, id: string) {
    return [sobject.toLowerCase(), id].join('/')
}

function deleteFailedTransaction(tx: WriteTransaction, mutationId: number) {
    const key = [Prefix.FailedTransaction, `${mutationId}`.padStart(8, '0')].join('/')
    return tx.del(key)
}

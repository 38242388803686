import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { isContactModel, isOpportunityModel, LeadId, AccountId } from '@laserfocus/shared/models'
import {
    ensureContactSynced,
    ensureOpportunitySynced,
    visitRoot,
} from '@laserfocus/client/data-access-shared'
import { logger } from '@laserfocus/ui/logger'
import { Analytics } from '@laserfocus/client/util-analytics'
import {
    EverboardingHint,
    useConditionalTrackTrigger,
} from '@laserfocus/client/feature-everboarding'
import { HighlightedCellValue } from '@laserfocus/client/ui-shared-datagrid'

import { SalesObject, useTableDataContext } from '../table-context'

import { TableCellProps } from './TableCell'
import {
    CLASS_NAME_INPUT_BUTTON_CONTENT,
    CLASS_NAME_INPUT_BUTTON_EMPTY,
} from './shared-class-names'

export function StickyLeftCell({ salesObject, column, searchTerm, rowIndex }: TableCellProps) {
    const tableData = useTableDataContext()
    const [didPrefetch, setDidPrefetch] = useState(false)
    const trackEverboardingOverlayOpened = useConditionalTrackTrigger(
        'everboarding_stack_overlay',
        (counters) => {
            const currentCount = counters.everboarding_stack_overlay || 0
            return currentCount < 10
        }
    )

    // We assume that StickyLeftCell values are strings
    const value = (column.getValue(salesObject) as string | null) || ''
    const isInOverlay = tableData?.overlayObjectId === salesObject.Id

    const linkRef = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        if (isInOverlay) {
            linkRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [isInOverlay])

    // We render this component in the onboarding table where we it isn't wrapped with a TableDataProvider. There we also don't want to link to the person details page, this is why this is here.
    if (!tableData) {
        return (
            <span
                title={value}
                className={twMerge(
                    'mr-auto text-ellipsis whitespace-nowrap overflow-x-hidden max-w-full py-1 px-2',
                    !value && CLASS_NAME_INPUT_BUTTON_EMPTY
                )}
            >
                {value}
            </span>
        )
    }

    function onHover() {
        if (!didPrefetch) {
            const rootId = getRootId(salesObject)
            if (!rootId) {
                return
            }
            setDidPrefetch(true)

            if (salesObject.__typename === 'Contact') {
                ensureContactSynced(salesObject.Id, rootId)
            } else if (salesObject.__typename === 'Opportunity') {
                ensureOpportunitySynced(salesObject.Id, rootId)
            } else {
                visitRoot({ rootId }).catch(logger.error)
            }
        }
    }

    const content = (
        <Link
            ref={linkRef}
            to={isInOverlay ? '.' : `?selectedObject=${salesObject.Id}`}
            replace
            title={`Go to ${value}`}
            className={twMerge(
                CLASS_NAME_INPUT_BUTTON_CONTENT,
                'outline-none transition-shadow focus-visible:ring focus-visible:ring-blue-500 rounded-md',
                isInOverlay && 'ring ring-blue-500/60'
            )}
            onClick={() => {
                if (!isInOverlay) {
                    Analytics.trackEvent({
                        event: 'overlay_opened',
                        location: Analytics.parseStackFromSobject(salesObject.__typename),
                    })
                    trackEverboardingOverlayOpened()
                }
            }}
            onMouseEnter={onHover}
            onKeyDown={
                isInOverlay
                    ? (event) =>
                          (event.key === 'ArrowDown' || event.key === 'ArrowUp') &&
                          event.currentTarget.blur()
                    : undefined
            }
        >
            <HighlightedCellValue
                className={twMerge(
                    'mr-auto font-bold max-w-full outline-none transition rounded-md py-1 px-2 hover:underline focus-visible:ring focus-visible:underline',
                    !value && CLASS_NAME_INPUT_BUTTON_EMPTY
                )}
                value={value}
                searchTerm={searchTerm}
            />
        </Link>
    )

    if (rowIndex === 0) {
        return (
            <EverboardingHint
                name="everboarding_stack_overlay"
                label="Click here to see more"
                placement="right"
                containerClassName="-translate-y-9 -translate-x-4"
                labelClassName="-translate-y-4 -rotate-6 translate-x-9"
                arrowClassName=" -rotate-6"
            >
                {content}
            </EverboardingHint>
        )
    }

    return content
}

function getRootId(salesObject: SalesObject): LeadId | AccountId | null {
    if (isOpportunityModel(salesObject) || isContactModel(salesObject)) {
        return salesObject.AccountId as AccountId | null
    }
    return salesObject.Id as AccountId | LeadId | null
}

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useToggleQueryParam() {
    const [searchParam, setSearchParam] = useSearchParams()
    return useCallback(
        (name: string, value: string) => {
            if (searchParam.get(name) === value) {
                searchParam.delete(name)
            } else {
                searchParam.set(name, value)
            }
            setSearchParam(searchParam)
        },
        [searchParam, setSearchParam]
    )
}

import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useState } from 'react'
import clsx from 'clsx'

import { z } from '@laserfocus/shared/decoder'
import { Analytics } from '@laserfocus/client/util-analytics'
import { logger } from '@laserfocus/ui/logger'
import { Button, Input, SpinnerInline } from '@laserfocus/ui/beam'
import { SalesforceLogoIcon } from '@laserfocus/ui/icons'

import { emailSignup } from '../store/onboarding-analytics'
import { OnboardingLayout } from '../shared/OnboardingLayout'
import { EnterpriseSecureLink } from '../shared/EnterpriseSecureLink'

interface FormData {
    email: string
}

const emailSchema = z.string().email()
const BLOCKED_DOMAINS = [
    'gmail.com',
    'googlemail.com',
    'mail.ru',
    'web.de',
    't-online.de',
    'gmx.de',
    'gmx.net',
    'protonmail.com',
]

export function SignUpPage() {
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            email: '',
        },
    })

    async function submit({ email }: FormData) {
        setIsSubmitting(true)
        try {
            localStorage.setItem('lf:onboarding:email', email)
            const anonymousId = await Analytics.getAnonymousId()
            await emailSignup({
                anonymousId,
                traits: {
                    email,
                },
            })
            Analytics.identifyAnonymous({ email, anonymousId })
            Analytics.trackEvent({
                event: 'user_signup_email_fe',
                payload: {
                    email,
                },
            })
        } catch (e: unknown) {
            logger.error(e)
        } finally {
            navigate('/signup/select-role')
        }
    }

    return (
        <OnboardingLayout
            headerButton={
                <Button as={Link} variant="tertiary" size="small" to="/login">
                    Log in instead
                </Button>
            }
            showSignupNotice
        >
            <div className="w-full max-w-[22rem]">
                <div className="grid gap-8">
                    <div className="grid gap-2 text-center">
                        <h1 className="font-bold text-3xl font-serif">Let’s get started! 🚀</h1>
                        <p className="text-xl">Sign up for Laserfocus</p>
                    </div>
                    <div className="grid gap-4">
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    if (
                                        !emailSchema.safeParse(value).success ||
                                        BLOCKED_DOMAINS.some((domain) => value.includes(domain))
                                    ) {
                                        return 'Please enter a business email'
                                    }
                                    return true
                                },
                            }}
                            render={({ field }) => (
                                <Input
                                    size="large"
                                    onChange={(event) => field.onChange(event.target.value)}
                                    value={field.value}
                                    onBlur={field.onBlur}
                                    error={errors[field.name]?.message}
                                    placeholder="Your email"
                                    aria-label="Your email"
                                    variant="border"
                                    name="email"
                                />
                            )}
                        />
                        <Button
                            variant="primary"
                            size="large"
                            onClick={handleSubmit(submit)}
                            disabled={isSubmitting}
                            iconComponent={isSubmitting ? SpinnerInline : undefined}
                            iconPosition="right"
                            iconClassName="w-full pl-2 justify-start"
                            className={clsx('w-full', isSubmitting && 'grid-cols-[1fr,auto,1fr]')}
                        >
                            {isSubmitting && <span />}
                            Sign up
                        </Button>
                        <div className="text-grey-700/60 text-center">or</div>
                        <Button
                            as={Link}
                            to="/login"
                            onClick={() => {
                                Analytics.trackEvent({
                                    event: 'signup_salesforce_clicked',
                                })
                            }}
                            size="large"
                            className="w-full"
                        >
                            <div className="grid grid-flow-col gap-2 items-center justify-center">
                                <SalesforceLogoIcon className="h-4 text-salesforce" />
                                Sign up with Salesforce
                            </div>
                        </Button>
                        <EnterpriseSecureLink />
                    </div>
                </div>
            </div>
        </OnboardingLayout>
    )
}

import clsx from 'clsx'

import { useScrolledIntoViewRef } from '@laserfocus/ui/util-react'

export interface CommandLineOptionProps {
    icon?: React.ReactNode
    title: string
    description?: React.ReactNode | string
    isSelected?: boolean
    select(): void
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export function CommandLineOption({
    icon,
    title,
    description,
    isSelected,
    select,
    onClick,
}: CommandLineOptionProps) {
    const scrolledIntoViewRef = useScrolledIntoViewRef<HTMLDivElement>(isSelected)

    return (
        // `display: grid` is necessary to prevent collapsing margins in list
        <div className="grid">
            <div className="-mt-2 -mb-2 pointer-events-none">
                <div
                    ref={scrolledIntoViewRef}
                    role="option"
                    aria-selected={isSelected}
                    className="py-2"
                >
                    <button
                        onMouseMove={isSelected ? undefined : select}
                        onClick={onClick}
                        className={clsx(
                            'grid items-center gap-3 grid-flow-col grid-cols-[auto,minmax(0,1fr)] cursor-pointer w-full p-4 rounded-[0.625rem] pointer-events-auto outline-none text-left',
                            isSelected
                                ? 'bg-blue-500 focus-visible:ring focus-visible:ring-blue-500/10 focus-visible:bg-blue-700'
                                : 'focus-visible:ring'
                        )}
                    >
                        {icon}
                        <div>
                            <h2
                                title={title}
                                className="font-bold text-base leading-[1.4] whitespace-nowrap text-ellipsis overflow-x-hidden"
                            >
                                {title}
                            </h2>
                            {description && (
                                <div className="text-sm text-white/60">{description}</div>
                            )}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

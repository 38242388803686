import { useState } from 'react'

import { Button, Select, SelectOption } from '@laserfocus/ui/beam'
import { MoreOutlinedIcon, ExternalLinkOutlinedIcon } from '@laserfocus/ui/icons'
import { useInstanceUrl } from '@laserfocus/client/feature-auth'
import { Account, Contact, isTruthy, Opportunity } from '@laserfocus/shared/models'

import {
    useAccount,
    useCurrentContact,
    useCurrentOpportunity,
    useLead,
    useRootId,
} from '../modules/PersonContext'
import { useConvertLeadModal } from '../ConvertLeadModal/ConvertLeadContext'

export function ObjectActions({
    sobject,
    openCreateModal,
}: {
    sobject: 'Lead' | 'Account' | 'Contact' | 'Opportunity'
    openCreateModal: () => void
}) {
    if (sobject === 'Lead') {
        return <LeadActions>{(options) => <MoreButton options={options} />}</LeadActions>
    } else if (sobject === 'Account') {
        return <AccountActions>{(options) => <MoreButton options={options} />}</AccountActions>
    } else if (sobject === 'Contact') {
        return (
            <ContactActions openCreateModal={openCreateModal}>
                {(options) => <MoreButton options={options} />}
            </ContactActions>
        )
    } else if (sobject === 'Opportunity') {
        return (
            <OpportunityActions openCreateModal={openCreateModal}>
                {(options) => <MoreButton options={options} />}
            </OpportunityActions>
        )
    }
    return null
}

type ObjectActionProps = { children: (options: SelectOption<MoreOption>[]) => React.ReactNode }

function LeadActions({ children }: ObjectActionProps): React.ReactElement {
    const { setIsConvertModalOpen } = useConvertLeadModal()
    const lead = useLead()
    const rootId = useRootId()
    const leadLink = useSalesforceLink(rootId)

    const options: SelectOption<MoreOption>[] = [
        leadLink && {
            label: 'Open in Salesforce',
            value: 'open-in-salesforce',
            iconComponent: ExternalLinkOutlinedIcon,
            iconPosition: 'right' as const,
            action: () => openLink(leadLink),
        },
        lead &&
            !lead.IsConverted &&
            setIsConvertModalOpen && {
                label: 'Convert Lead',
                value: 'convert-lead',
                action: () => {
                    setIsConvertModalOpen(true)
                },
            },
        lead &&
            (typeof lead.Outreach_ID__c === 'string' ||
                typeof lead.Outreach_Id__c === 'number') && {
                label: 'Open in Outreach',
                value: 'open-lead-in-salesforce',
                iconComponent: ExternalLinkOutlinedIcon,
                iconPosition: 'right' as const,
                action: () => openInOutreach('prospects', lead.Outreach_ID__c as string | number),
            },
    ].filter(isTruthy)
    return <>{children(options)}</>
}

function AccountActions({ children }: ObjectActionProps): React.ReactElement {
    const rootId = useRootId()
    const objectLink = useSalesforceLink(rootId)
    const sobject = useAccount()

    const options: SelectOption<MoreOption>[] = [
        objectLink && {
            label: 'Open in Salesforce',
            value: 'open-in-salesforce',
            iconComponent: ExternalLinkOutlinedIcon,
            iconPosition: 'right' as const,
            action: () => openLink(objectLink),
        },
        sobject &&
            (typeof sobject.Outreach_ID__c === 'string' ||
                typeof sobject.Outreach_Id__c === 'number') && {
                label: 'Open in Outreach',
                value: 'open-lead-in-salesforce',
                iconComponent: ExternalLinkOutlinedIcon,
                iconPosition: 'right' as const,
                action: () => openInOutreach('accounts', sobject.Outreach_ID__c as string | number),
            },
    ].filter(isTruthy)
    return <>{children(options)}</>
}

function ContactActions({
    children,
    openCreateModal,
}: ObjectActionProps & { openCreateModal: () => void }): React.ReactElement {
    const sobject = useCurrentContact()
    const objectLink = useSalesforceLink(sobject?.Id)

    const options: SelectOption<MoreOption>[] = [
        objectLink && {
            label: 'Open in Salesforce',
            value: 'open-in-salesforce',
            iconComponent: ExternalLinkOutlinedIcon,
            iconPosition: 'right' as const,
            action: () => openLink(objectLink),
        },
        sobject &&
            (typeof sobject.Outreach_ID__c === 'string' ||
                typeof sobject.Outreach_Id__c === 'number') && {
                label: 'Open in Outreach',
                value: 'open-lead-in-salesforce',
                iconComponent: ExternalLinkOutlinedIcon,
                iconPosition: 'right' as const,
                action: () =>
                    openInOutreach('prospects', sobject.Outreach_ID__c as string | number),
            },
        {
            label: 'Create new Contact',
            value: 'create-contact',
            action: openCreateModal,
        },
    ].filter(isTruthy)
    return <>{children(options)}</>
}

function OpportunityActions({
    children,
    openCreateModal,
}: ObjectActionProps & { openCreateModal: () => void }): React.ReactElement {
    const sobject = useCurrentOpportunity()
    const contact = useCurrentContact()
    const objectLink = useSalesforceLink(sobject?.Id)
    const account = useAccount()
    const quoteLink = useSalesforceLink(getQuoteSuffix(sobject, contact, account ?? undefined))

    const options: SelectOption<MoreOption>[] = [
        objectLink && {
            label: 'Open in Salesforce',
            value: 'open-in-salesforce',
            action: () => openLink(objectLink),
            iconComponent: ExternalLinkOutlinedIcon,
            iconPosition: 'right' as const,
        },
        sobject &&
            (typeof sobject.Outreach_ID__c === 'string' ||
                typeof sobject.Outreach_Id__c === 'number') && {
                label: 'Open in Outreach',
                value: 'open-lead-in-salesforce',
                action: () =>
                    openInOutreach('opportunities', sobject.Outreach_ID__c as string | number),
            },
        {
            label: 'Create new Opportunity',
            value: 'create-opportunity',
            action: openCreateModal,
        },
        quoteLink && {
            label: 'Create Quote',
            value: 'create-quote',
            iconComponent: ExternalLinkOutlinedIcon,
            iconPosition: 'right' as const,
            action: () => {
                openLink(quoteLink)
            },
        },
    ].filter(isTruthy)
    return <>{children(options)}</>
}

type MoreOption = {
    label: string
    action: () => void
}
function MoreButton({ options }: { options: SelectOption<MoreOption>[] }) {
    const [isOpen, setIsOpen] = useState(false)

    if (!options.length) {
        return null
    }

    return (
        <Select
            size={200}
            isOpen={isOpen}
            onCancel={() => setIsOpen(false)}
            options={options}
            onSubmit={({ action }) => {
                setIsOpen(false)
                action()
            }}
        >
            <Button
                title="More"
                variant="tertiary"
                size="small"
                iconComponent={MoreOutlinedIcon}
                onClick={() => setIsOpen(true)}
            />
        </Select>
    )
}

function useSalesforceLink(suffix?: string) {
    const SFInstanceUrl = useInstanceUrl()
    if (!suffix) {
        return null
    }
    const SFUrl = `${SFInstanceUrl}/${suffix}`
    return SFUrl
}

function getQuoteSuffix(opportunity?: Opportunity, contact?: Contact, account?: Account) {
    if (!opportunity) {
        return undefined
    }
    const params: Record<string, string | undefined> = {
        AccountId: opportunity.AccountId,
        OpportunityId: opportunity.Id,
    }
    if (contact) {
        params.ContactId = contact.Id
        params.Email = contact.Email
        params.Phone = contact.Phone
    }
    if (account) {
        params.BillingCity = account.BillingCity
        params.BillingStreet = account.BillingStreet
        params.BillingState = account.BillingState
        params.BillingPostalCode = account.BillingPostalCode
        params.BillingCountry = account.BillingCountry

        params.ShippingStreet = account.ShippingStreet
        params.ShippingState = account.ShippingState
        params.ShippingPostalCode = account.ShippingPostalCode
        params.ShippingCity = account.ShippingCity
        params.ShippingCountry = account.ShippingCountry

        params.BillingName = account.Name
        params.ShippingName = account.Name
    }
    const query = Object.entries(params)
        .filter(([key, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join(',')
    // https://laserfoucs-dev-ed.lightning.force.com/lightning/o/Quote/new?count=1&defaultFieldValues=OpportunityId=0061i00000Hd63aAAB
    return `lightning/o/Quote/new?defaultFieldValues=${query}`
    // https://laserfoucs-dev-ed.lightning.force.com/lightning/o/Quote/new?count=1&nooverride=1&useRecordTypeCheck=1&navigationLocation=RELATED_LIST&uid=164814579258578590
}

function openLink(url: string) {
    Object.assign(document.createElement('a'), {
        target: '_blank',
        href: url,
        rel: 'noopener noreferrer',
    }).click()
}

function openInOutreach(type: 'prospects' | 'opportunities' | 'accounts', id: string | number) {
    const url = `https://app2a.outreach.io/${type}/${id}`
    openLink(url)
}

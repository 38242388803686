import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ExclamationFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M21.6 12c0 5.292-4.308 9.6-9.6 9.6-5.304 0-9.6-4.308-9.6-9.6-.012-5.304 4.296-9.612 9.588-9.612 5.292-.012 9.6 4.296 9.6 9.588L21.6 12zm-8.4 4.8c0 .66-.54 1.2-1.2 1.2-.672 0-1.2-.54-1.2-1.2a1.188 1.188 0 011.188-1.212c.66-.012 1.2.528 1.2 1.188l.012.024zM12 6h-.012c-.672 0-1.2.528-1.2 1.2V12c0 .66.528 1.188 1.2 1.188a1.204 1.204 0 001.188-1.212v-4.8c0-.672-.54-1.2-1.2-1.2L12 6z"
            />
        </SvgIcon>
    )
}

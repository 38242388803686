import { useHotKey } from './useHotKey'

type SetIndex = (updater: Updater) => void
type Updater = (index: number) => number

export function useListNavigation(setIndex: SetIndex, listLength: number) {
    useHotKey(
        ['up', 'down'],
        (event, combo) => {
            // We need to prevent default to prevent cursor in inputs jumping to start or end of input
            event.preventDefault()

            const offset = combo === 'up' ? -1 : 1

            setIndex((dirtyIndex) => {
                const index = dirtyIndex >= 0 && dirtyIndex < listLength ? dirtyIndex : -1

                // If we don't have a currently selected list element (index === -1) and we want to go up, we want to go to the last list element.
                const indexCorrection = index === -1 && offset < 0 ? 1 : 0
                const nextIndex = index + offset + indexCorrection

                if (nextIndex >= -1 && nextIndex <= listLength) {
                    return (nextIndex + listLength) % listLength
                }

                return 0
            })
        },
        { global: true }
    )
}

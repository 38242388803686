import 'reflect-metadata'
import { action, computed, observable } from 'mobx'
import { findIndex } from 'lodash'

import { useRootStore } from '@laserfocus/client/root-store-context'
import { isContactResult, isOpportunityResult } from '@laserfocus/shared/models'
import type { SearchResult } from '@laserfocus/shared/models'

export const PERSIST_KEY = 'lf:historyStore'

export type RootStoreInjection = {
    historyStore: HistoryStore
}

export function useHistoryStore(): HistoryStore {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.historyStore
}

export class HistoryStore {
    @observable searchNavigationHistory: Array<SearchResult> = []

    onInit() {
        this.hydrate()
    }

    hydrate() {
        const storeState = localStorage.getItem(PERSIST_KEY)
        if (storeState) {
            const parsed = JSON.parse(storeState)
            if (parsed.searchNavigationHistory) {
                this.searchNavigationHistory = parsed.searchNavigationHistory
            }
        }
    }

    persist() {
        localStorage.setItem(
            PERSIST_KEY,
            JSON.stringify({
                searchNavigationHistory: this.searchNavigationHistory,
            })
        )
    }

    @computed
    get previouslyAccessedResults() {
        return this.searchNavigationHistory.reverse()
    }

    @action
    clear() {
        this.searchNavigationHistory = []
        this.persist()
    }

    @action
    updateSearchNavigationHistory(person: SearchResult) {
        const previouslyAccessedPersonIndex = findIndex(
            this.searchNavigationHistory,
            (p: SearchResult) => {
                if (isOpportunityResult(p) || isContactResult(p)) {
                    return p.AccountId === (person as { AccountId: string }).AccountId
                }
                return p.Id === person.Id
            }
        )
        if (previouslyAccessedPersonIndex !== -1) {
            this.searchNavigationHistory.splice(previouslyAccessedPersonIndex, 1)
        }
        if (this.searchNavigationHistory.length > 19) {
            this.searchNavigationHistory.shift()
        }
        this.searchNavigationHistory.push(person)
        this.persist()
    }
}

import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useSubscribe } from 'replicache-react'

import {
    LeadStatusAttributes,
    MainRecordType,
    PicklistValue,
    Prefix,
    RecordType,
} from '@laserfocus/shared/models'
import { DropdownBaseInput, Select } from '@laserfocus/ui/beam'
import { getClient } from '@laserfocus/client/replicache'

import { ConvertLeadFormState } from './ConvertLeadModal'
import { MetadataField, useField } from './MetadataField'

export function ConvertSectionBody({
    control,
    options,
}: {
    control: Control<ConvertLeadFormState>
    options: PicklistValue[]
}) {
    const showLeadStatusSelect = options.length > 1

    return (
        <div className="grid gap-4 py-4 px-8">
            <div className="grid gap-2 grid-cols-[1fr,2fr]">
                {showLeadStatusSelect && <LeadStatusSelect control={control} options={options} />}
                <OwnerSelect control={control} />
            </div>
        </div>
    )
}

function OwnerSelect({ control }: { control: Control<ConvertLeadFormState> }) {
    const field = useField('Lead', 'OwnerId')
    if (!field) {
        return null
    }
    return (
        <MetadataField
            control={control}
            fieldMetadata={field}
            name="ownerId"
            label="Record Owner"
            required={false}
        />
    )
}

function LeadStatusSelect({
    control,
    options,
}: {
    control: Control<ConvertLeadFormState>
    options: PicklistValue[]
}) {
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const id = 'convert-convertedStatus'

    const labelsByValue = Object.fromEntries(options.map((o) => [o.value, o.label]))
    return (
        <>
            <label
                htmlFor={id}
                // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
                className="text-sm font-medium leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
            >
                Lead Status
                <span title="Required" className="px-1 -mr-1 select-none text-red-500">
                    •
                </span>
            </label>
            <Controller
                name="convertedStatus"
                rules={{ required: 'Converted Lead Status is required' }}
                control={control}
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
                    const currentValueLabel = value
                        ? labelsByValue[value as string] || (value as string)
                        : ''
                    return (
                        <Select
                            isOpen={isSelectOpen}
                            options={options}
                            initialOptionValue={(value as string) || undefined}
                            onCancel={() => setIsSelectOpen(false)}
                            onSubmit={(option) => {
                                setIsSelectOpen(false)
                                onChange(option.value)
                            }}
                            size="large"
                        >
                            <DropdownBaseInput
                                id={id}
                                variant="border"
                                forceFocused={isSelectOpen}
                                onClick={() => setIsSelectOpen(true)}
                                value={currentValueLabel ?? undefined}
                                error={(error as any)?.message}
                            />
                        </Select>
                    )
                }}
            />
        </>
    )
}

export function useLeadStatusPicklist(recordTypeId?: string) {
    const rep = getClient()
    const statusOptions = useSubscribe<{
        isLoading: boolean
        convertedStatusOptions: PicklistValue[]
    }>(
        rep,
        async (tx) => {
            const key = [Prefix.RecordType, 'Lead', recordTypeId || MainRecordType].join('/')
            const recordType = (await tx.get(key)) as unknown as RecordType | null
            const statusPicklist = recordType?.picklists.find((a) => a.fieldName === 'Status')
            const convertedStatusOptions = statusPicklist?.picklistValues.filter(
                (a) => (a.attributes as LeadStatusAttributes)?.converted
            )
            return {
                isLoading: false,
                convertedStatusOptions: convertedStatusOptions || [],
            }
        },
        {
            isLoading: true,
            convertedStatusOptions: [],
        },
        [recordTypeId]
    )
    return statusOptions
}

import gql from 'graphql-tag'

export const IMPERSONATE_MUTATION = gql`
    mutation impersonate($userId: String!) {
        impersonate(userId: $userId) {
            token
            success
            error
        }
    }
`

import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

interface SpinnerProps {
    id?: string
    fullscreen?: boolean
    size?: number
    className?: string
    backgroundStrokeColor?: keyof typeof STROKE_COLOR_STYLES
    spinnerStrokeColor?: keyof typeof STROKE_COLOR_STYLES
    strokeWidth?: number
}

export function Spinner({
    fullscreen,
    id,
    size = 100,
    className,
    backgroundStrokeColor,
    spinnerStrokeColor,
    strokeWidth,
}: SpinnerProps) {
    return (
        <div
            className={twMerge(
                'w-full flex justify-center items-center',
                fullscreen ? 'h-screen' : 'h-full',
                className
            )}
            id={id}
        >
            <SpinnerSvg
                strokeWidth={strokeWidth}
                backgroundStrokeColor={backgroundStrokeColor}
                spinnerStrokeColor={spinnerStrokeColor}
                size={size}
            />
        </div>
    )
}

type SpinnerInlineProps = Omit<SpinnerSvgProps, 'size' | 'strokeWidth'>

export function SpinnerInline({
    className,
    backgroundStrokeColor = 'current',
    spinnerStrokeColor,
}: SpinnerInlineProps) {
    return (
        <Spinner
            size={16}
            strokeWidth={6}
            backgroundStrokeColor={backgroundStrokeColor}
            spinnerStrokeColor={spinnerStrokeColor}
            className={className}
        />
    )
}

const STROKE_COLOR_STYLES = {
    'grey-700/5': /*tw:*/ 'text-grey-700/5',
    'grey-700/20': /*tw:*/ 'text-grey-700/20',
    'grey-700/40': /*tw:*/ 'text-grey-700/40',
    'blue-500': /*tw:*/ 'text-blue-500',
    'blue-500/40': /*tw:*/ 'text-blue-500/40',
    current: /*tw:*/ 'text-current',
}

interface SpinnerSvgProps
    extends Pick<React.ComponentPropsWithoutRef<'svg'>, 'strokeWidth' | 'className'> {
    backgroundStrokeColor?: keyof typeof STROKE_COLOR_STYLES
    spinnerStrokeColor?: keyof typeof STROKE_COLOR_STYLES
    size: number
}

function SpinnerSvg({
    backgroundStrokeColor = 'grey-700/5',
    spinnerStrokeColor = 'blue-500',
    strokeWidth,
    className,
    size,
}: SpinnerSvgProps) {
    return (
        <svg
            viewBox="0 0 50 50"
            aria-busy="true"
            aria-live="polite"
            strokeWidth={strokeWidth ?? 4}
            strokeMiterlimit={10}
            strokeLinecap="round"
            fill="none"
            className={twMerge('animate-spinner-rotate', className)}
            style={{ width: size, height: size }}
        >
            <circle
                cx={25}
                cy={25}
                r={20}
                className={clsx('stroke-current', STROKE_COLOR_STYLES[backgroundStrokeColor])}
            />
            <circle
                cx={25}
                cy={25}
                r={20}
                className={clsx(
                    'animate-spinner-dash stroke-current',
                    STROKE_COLOR_STYLES[spinnerStrokeColor]
                )}
            />
        </svg>
    )
}

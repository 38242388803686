import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { getCurrencyFormatter } from '@laserfocus/client/util-formatter'
import { useCurrency } from '@laserfocus/client/feature-auth'
import { getLocale } from '@laserfocus/ui/util-locale'
import { TextCell } from '@laserfocus/client/ui-shared-datagrid'

import { Column, SalesObject } from '../table-context'

import { TableCellProps } from './TableCell'

export const NumberCell = observer(function NumberCell({
    salesObject,
    column,
    searchTerm,
    tableRef,
}: TableCellProps) {
    const value = column.getValue(salesObject) as number | null

    const orgCurrency = useCurrency()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const toDisplayValue = useCallback(makeToDisplayValue(column, salesObject, orgCurrency), [
        column,
        salesObject,
        orgCurrency,
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const parseValue = useCallback(makeParseValue(column), [column])

    return (
        <TextCell<number>
            name={column.key}
            updateValue={(value) => column.updateValue(salesObject, value)}
            value={value}
            toDisplayValue={toDisplayValue}
            parseValue={parseValue}
            tableRef={tableRef}
            searchTerm={searchTerm}
            hint={column.getHint?.(salesObject)}
            readOnly={!column.isEditable(salesObject)}
        />
    )
})

function makeParseValue(column: Column) {
    return (value: string) => {
        const parsed = column.type === 'int' ? Number.parseInt(value) : Number.parseFloat(value)

        if (Number.isNaN(parsed)) {
            return null
        }

        return parsed
    }
}

const numberFormatter = new Intl.NumberFormat(getLocale())

function makeToDisplayValue(column: Column, salesObject: SalesObject, orgCurrency?: string) {
    return (value: number | null | undefined) => {
        if (value === null || value === undefined) {
            return ''
        }

        if (column.type === 'currency') {
            const currency = salesObject?.CurrencyIsoCode
            const currencyFormatter = getCurrencyFormatter(currency || orgCurrency)
            return currencyFormatter.format(value)
        }

        if (column.type === 'percent') {
            return `${value}%`
        }

        return numberFormatter.format(value)
    }
}

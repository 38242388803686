import { action, computed, observable, runInAction } from 'mobx'

import { syncNames } from '@laserfocus/client/data-access-shared'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { getClient } from '@laserfocus/client/replicache'
import { ModelProps, ObjectPool } from '@laserfocus/client/data-layer'
import { Prefix } from '@laserfocus/shared/models'

export type RootStoreInjection = {
    nameStore: NameStore
}

export function useSyncName() {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.nameStore.syncNames
}

export class NameStore {
    objectPool: ObjectPool
    @observable idsToFetch: string[] = []
    @observable isRunning: boolean = false

    @observable loadingIds: string[] = []
    @observable didLoadIds: string[] = []
    @observable reactions: Array<() => void> = []

    constructor(objectPool: ObjectPool) {
        this.objectPool = objectPool
    }

    stopProcess?: () => void

    @computed
    get remaining() {
        return this.idsToFetch
            .filter((a) => !this.loadingIds.includes(a))
            .filter((a) => !this.didLoadIds.includes(a))
    }

    @action.bound
    start() {
        this.isRunning = true
        let handle: NodeJS.Timeout
        const runTick = async () => {
            // console.log('🚀 Running prefetch while loading', this.loadingRootIds.length)
            if (this.loadingIds.length < 10) {
                const ids = this.remaining.slice(0, 10)
                if (ids.length) {
                    await syncNames({ ids })
                    runInAction(() => {
                        this.didLoadIds.push(...ids)
                    })
                }
            }
            handle = setTimeout(runTick, 3 * 1000)
        }

        this.stopProcess = () => {
            clearTimeout(handle)
        }
        runTick()
        return () => this.stop()
    }

    onInit() {
        this.loadInitialReplicache()
        setTimeout(() => this.start(), 5000)
    }

    loadInitialReplicache() {
        const client = getClient()
        client.query(async (tx) => {
            const names = (await tx
                .scan({ prefix: `${Prefix.Name}/` })
                .values()
                .toArray()) as ModelProps[]
            this.objectPool.attachAll(names, 5)
        })
    }

    onDestroy() {
        this.stop()
        this.reactions.forEach((reaction) => reaction())
    }

    @action
    stop() {
        this.isRunning = false
        this.stopProcess?.()
    }

    @action.bound
    syncNames(ids: string[]) {
        ids.forEach((id) => {
            if (!this.idsToFetch.includes(id)) {
                this.idsToFetch.push(id)
            }
        })
    }
}

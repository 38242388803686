import { SFDateConstants, SFDateConstantValue } from '@laserfocus/shared/models'

export const DateConstantLabel: Record<SFDateConstantValue, string> = {
    [SFDateConstants.TODAY]: 'today',
    [SFDateConstants.TOMORROW]: 'tomorrow',
    [SFDateConstants.THIS_WEEK]: 'this week',
    [SFDateConstants.NEXT_WEEK]: 'next week',
    NEXT_2_WEEKS: 'next two weeks',
    [SFDateConstants.LAST_WEEK]: 'last week',
    LAST_2_WEEKS: 'last two weeks',
    [SFDateConstants.THIS_MONTH]: 'this month',
    [SFDateConstants.NEXT_MONTH]: 'next month',
    NEXT_4_MONTHS: 'next 4 months',
    [SFDateConstants.LAST_MONTH]: 'last month',
    [SFDateConstants.THIS_QUARTER]: 'this quarter',
    [SFDateConstants.NEXT_QUARTER]: 'next quarter',
    [SFDateConstants.LAST_QUARTER]: 'last quarter',
    [SFDateConstants.THIS_YEAR]: 'this year',
    [SFDateConstants.NEXT_YEAR]: 'next year',
    [SFDateConstants.LAST_YEAR]: 'last year',
    [SFDateConstants.LAST_90_DAYS]: 'last 90 days',
    [SFDateConstants.NEXT_90_DAYS]: 'next 90 days',
    // Not used as options
    [SFDateConstants.THIS_FISCAL_QUARTER]: 'this fiscal quarter',
    [SFDateConstants.NEXT_FISCAL_QUARTER]: 'next fiscal quarter',
    [SFDateConstants.LAST_FISCAL_QUARTER]: 'last fiscal quarter',
    [SFDateConstants.THIS_FISCAL_YEAR]: 'this fiscal year',
    [SFDateConstants.LAST_FISCAL_YEAR]: 'last fiscal year',
    [SFDateConstants.NEXT_FISCAL_YEAR]: 'next fiscal year',
}

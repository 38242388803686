import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Card } from '@laserfocus/ui/beam'

export function StatsCard({
    children,
    className,
}: {
    children: React.ReactNode
    className: string
}) {
    return (
        <Card size="large" flat className={twMerge('p-6', className)}>
            {children}
        </Card>
    )
}

export function StatLabel({
    children,
    className,
}: {
    children: React.ReactNode
    className?: string
}) {
    return (
        <div className={twMerge('text-sm text-grey-700/60 font-medium', className)}>{children}</div>
    )
}

export function StatValue({
    children,
    className,
}: {
    children: React.ReactNode
    className?: string
}) {
    return (
        <div className={twMerge('text-5xl font-bold font-serif mt-3', className)}>{children}</div>
    )
}

export function StatDescription({
    children,
    className,
}: {
    children: React.ReactNode
    className?: string
}) {
    return <div className={twMerge('text-xs text-grey-700/60', className)}>{children}</div>
}

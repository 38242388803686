export type StackInfoProps = {
    title: string
    sobject: string
    count?: number
    label?: string
    className?: string
}

export function StackInfo({ title, sobject, count, label, ...rest }: StackInfoProps) {
    return (
        <div {...rest}>
            <h3 className="font-serif text-[1.375rem] font-bold leading-[1.3]">{title}</h3>
            <div
                className="text-sm leading-[1.4] opacity-100 text-grey-700/60"
                data-testid="stackinfo-count-and-sobject"
            >
                {renderSubtitle(count, sobject, label)}
            </div>
        </div>
    )
}
export default StackInfo

function renderSubtitle(count: number | undefined, object: string, label?: string) {
    const objectLabel = [count, object].filter((a) => a || typeof a === 'number').join(' ')
    return [objectLabel, label].filter(Boolean).join(' · ')
}

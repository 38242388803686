import { useMemo } from 'react'
import clsx from 'clsx'

import {
    ActivityCounterItem,
    sumActivityCounters,
    TIME_SAVED_BY_ACTIVITY,
} from '@laserfocus/shared/models'
import { getLocale } from '@laserfocus/ui/util-locale'

import { useCounterContext, FALLBACK_QUOTA } from './CounterContext'
import { StatLabel, StatsCard, StatValue } from './StatsCard'

const ONE_HOUR = 60 * 60
export const WORK_HOURS_PER_MONTH = 8 * 21

const currencyFormatter = new Intl.NumberFormat(getLocale(), {
    // style: 'currency',
    // currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
})

export function ROICard({ counters }: { counters: ActivityCounterItem[] }) {
    const { quota } = useCounterContext()
    const aggregated = Object.entries(sumActivityCounters(counters)).reduce(
        (total, [name, amount]: [string, number]) => {
            const timePerUnit = TIME_SAVED_BY_ACTIVITY[name] || 0
            return total + amount * timePerUnit
        },
        0
    )
    const calcQuota = typeof quota === 'number' ? quota : FALLBACK_QUOTA
    const valuePerHour = calcQuota / WORK_HOURS_PER_MONTH
    const value = (aggregated / ONE_HOUR) * valuePerHour
    const formattedValue = useMemo(() => {
        return currencyFormatter.format(value)
    }, [value])
    return (
        <StatsCard className="bg-yellow-50 row-span-2 flex flex-col justify-between">
            <div>
                <StatLabel>Return on investment with Laserfocus</StatLabel>
                <StatValue className={clsx(!quota && 'text-grey-700/60')}>
                    $ {formattedValue}
                </StatValue>
            </div>
            <div>
                <div className="font-bold text-xl font-serif mb-4">or:</div>
                <div className="text-6xl font-emoji grid grid-flow-col gap-1">
                    {getEmojisForValue(value).map((item, idx) => (
                        <span key={idx} title={item.label}>
                            {item.emoji}
                        </span>
                    ))}
                </div>
            </div>
        </StatsCard>
    )
}

type ValueEmoji = {
    emoji: string
    value: number
    label: string
}
const VALUE_BY_EMOJI: ValueEmoji[] = [
    {
        emoji: '🏎',
        value: 200000,
        label: 'Ferrari',
    },
    {
        emoji: '🧑‍🎓',
        value: 100000,
        label: 'Degree',
    },
    {
        emoji: '🚘',
        value: 30000,
        label: 'Toyota Camry',
    },
    {
        emoji: '🏍',
        value: 15000,
        label: 'Motorcycle',
    },
    {
        emoji: '💍',
        value: 12000,
        label: 'Wedding Ring',
    },
    {
        emoji: '🛵',
        value: 5000,
        label: 'Vespa',
    },
    {
        emoji: '💻',
        value: 2000,
        label: 'Laptop',
    },
    {
        emoji: '💻',
        value: 2000,
        label: 'Laptop',
    },
    {
        emoji: '🛴',
        value: 300,
        label: 'Scooter',
    },
    {
        emoji: '👟',
        value: 200,
        label: 'Sneaker',
    },
    {
        emoji: '🍾',
        value: 80,
        label: 'Bottle of Champaign',
    },
    {
        emoji: '🍱',
        value: 60,
        label: 'Deluxe Sushi Box',
    },
    {
        emoji: '🍔',
        value: 20,
        label: 'Burger Menu',
    },
    {
        emoji: '☕️',
        value: 8,
        label: 'Cofffee',
    },
    {
        emoji: '🧀',
        value: 4,
        label: 'Cheese',
    },
    {
        emoji: '🫧',
        value: 1,
        label: 'Soap Bubbles',
    },
]

function getEmojisForValue(value: number) {
    let emojis: ValueEmoji[] = []
    let remainingValue = value
    while (remainingValue > 1 && emojis.length < 3) {
        // eslint-disable-next-line no-loop-func
        const highestMatch = VALUE_BY_EMOJI.find((item) => {
            return (
                remainingValue > item.value &&
                (remainingValue < 100 || !emojis.map((a) => a.emoji).includes(item.emoji))
            )
        })
        if (highestMatch) {
            emojis.push(highestMatch)
            remainingValue = remainingValue - highestMatch.value
        } else {
            break
        }
    }
    return emojis
}

import { Modal, toast } from '@laserfocus/ui/beam'
import { Stack, UserIdSchema } from '@laserfocus/shared/models'
import { useUserId } from '@laserfocus/client/feature-auth'
import { Analytics } from '@laserfocus/client/util-analytics'
import { logger } from '@laserfocus/ui/logger'
import { ShareModalBody } from '@laserfocus/client/ui-shared'
import { mutateStack, useColleagues } from '@laserfocus/client/data-access-shared'

const { shareStack } = mutateStack
interface ShareStackModalProps extends ShareStackModalInnerProps {
    show: boolean
}
export function ShareStackModal({ stack, closeModal, show }: ShareStackModalProps) {
    return (
        <Modal show={show} onClose={closeModal}>
            <ShareStackModalInner stack={stack} closeModal={closeModal} />
        </Modal>
    )
}

interface ShareStackModalInnerProps {
    stack: Stack
    closeModal: () => void
}

function ShareStackModalInner({ stack, closeModal }: ShareStackModalInnerProps) {
    const users = useColleagues()
    const userId = useUserId()
    const activeWithoutMe = users.filter((u) => u.IsActive !== false && u.Id !== userId)
    function submit(userIds: string[]) {
        shareStack({
            stack: stack,
            userIds: userIds.map((id) => UserIdSchema.parse(id)),
        }).then(
            () => {
                Analytics.trackEvent({
                    event: 'stack_shared',
                    payload: {
                        userCount: userIds.length,
                    },
                })
                toast.success({
                    title: `Stack shared`,
                    description: `The stack "${stack.title}" was shared with ${
                        userIds.length
                    } colleague${userIds.length > 1 ? 's' : ''}`,
                })
                closeModal()
            },
            (e) => {
                logger.error(e)
                toast.error({ title: 'Error while sharing stack' })
            }
        )
    }
    return (
        <ShareModalBody
            users={activeWithoutMe}
            submit={submit}
            buttonLabel={`Share ${stack.title}`}
            modalTitle={`Share ${stack.title}`}
            closeModal={closeModal}
        />
    )
}

import { observer } from 'mobx-react-lite'

import { wrapAuthenticated } from '@laserfocus/client/feature-auth'
import { LayoutPadding, LayoutPaddingVertical, NavBar, PageHeader } from '@laserfocus/client/layout'
import { TaskList } from '@laserfocus/client/ui-shared-task'
import { useTitle } from '@laserfocus/ui/util-react'
import { isContact, isOpportunity, NewActivity, TaskId } from '@laserfocus/shared/models'
import { useIsMounted } from '@laserfocus/ui/hooks'
import { useOverlay, Overlay } from '@laserfocus/client/feature-overlay'
import { SearchInputButton } from '@laserfocus/client/ui-shared'
import { EventModel, TaskModel } from '@laserfocus/client/model'
import { Button } from '@laserfocus/ui/beam'

import { AddTaskButton } from '../TaskModal/AddTaskButton'
import { StackIconAvatar } from '../stack-overview/StackIconAvatar'

import { EmptyStack } from './EmptyStack'
import { LoadingStack } from './LoadingStack'
import { useUpcomingActivities } from './useActivityStack'
import { FilterButton, useFilter } from './FilterButton'
import { useActivitystackEverboarding } from './useActivitystackEverboarding'

const UpcomingLayout = observer(function UpcomingLayout() {
    const isMounted = useIsMounted()
    const { activities, loading, completeTask, dismissTask, searchTerm, setSearchTerm } =
        useUpcomingActivities()
    const { filteredActivities, ...filterButtonProps } = useFilter(activities)

    useTitle(`Upcoming${activities.length ? ` - ${activities.length}` : ''}`)

    const { overlayObject: overlayActivity, ...overlayBag } = useOverlay(
        filteredActivities,
        'selectedActivity'
    )
    useActivitystackEverboarding(activities, Boolean(overlayActivity))

    return (
        <>
            <div className={overlayActivity ? 'w-[calc(100%-38rem)]' : 'w-full'}>
                <NavBar breadcrumbs={[{ title: 'Upcoming' }]} bg="yellow" />
                <LayoutPaddingVertical maxWidth="stackWidth">
                    <PageHeader
                        title="Upcoming"
                        icon={<StackIconAvatar icon="🔔" />}
                        actions={
                            <>
                                <SearchInputButton value={searchTerm} onChange={setSearchTerm} />
                                <FilterButton {...filterButtonProps} />
                                <AddTaskButton />
                            </>
                        }
                    />
                    <LayoutPadding top bottom>
                        {isMounted && (
                            <UpcomingPage
                                setSearchTerm={setSearchTerm}
                                searchTerm={searchTerm}
                                activities={filteredActivities}
                                loading={loading}
                                overlayActivity={overlayActivity}
                                toggleOverlayForActivity={overlayBag.toggleOverlayForActivity}
                                completeTask={completeTask}
                                dismissTask={dismissTask}
                            />
                        )}
                    </LayoutPadding>
                </LayoutPaddingVertical>
            </div>
            <Overlay
                {...overlayBag}
                overlayData={
                    overlayActivity
                        ? {
                              overlayKey: overlayActivity.Id,
                              rootId: overlayActivity.RootId ?? undefined,
                              contactId: isContact(overlayActivity.WhoId)
                                  ? overlayActivity.WhoId
                                  : undefined,
                              opportunityId: isOpportunity(overlayActivity.WhatId)
                                  ? overlayActivity.WhatId
                                  : undefined,
                              activityId: overlayActivity.Id,
                          }
                        : undefined
                }
            />
        </>
    )
})

function UpcomingPageWrapper() {
    return <UpcomingLayout />
}

const AuthenticatedOverduePage = wrapAuthenticated(UpcomingPageWrapper)
export default AuthenticatedOverduePage

interface UpcomingPageProps {
    activities: Array<TaskModel | EventModel>
    loading: boolean
    overlayActivity?: NewActivity
    toggleOverlayForActivity?(id: string): void
    completeTask: (id: TaskId) => Promise<void>
    dismissTask: (id: TaskId) => void
    searchTerm?: string
    setSearchTerm: (searchTerm: string) => void
}

export function UpcomingPage({
    activities,
    loading,
    overlayActivity,
    toggleOverlayForActivity,
    completeTask,
    dismissTask,
    searchTerm,
    setSearchTerm,
}: UpcomingPageProps) {
    if (loading) {
        return <LoadingStack />
    }

    if (activities.length === 0) {
        if (searchTerm) {
            return (
                <EmptyStack
                    heading="No Tasks or Events found"
                    description="Clear your search to see which other tasks are available"
                    button={<Button onClick={() => setSearchTerm('')}>Clear Search</Button>}
                />
            )
        }
        return (
            <EmptyStack
                heading={
                    <>
                        You are up to date,
                        <br />
                        that's pretty great.
                    </>
                }
                description={
                    <>
                        If you have nothing else to do,
                        <br />
                        you can set quick reminders, too …
                    </>
                }
                button={<AddTaskButton label="Add Tasks" />}
            />
        )
    }

    return (
        <TaskList
            searchTerm={searchTerm}
            activities={activities}
            pathPrefix="upcoming"
            overlayActivity={overlayActivity}
            toggleOverlayForActivity={toggleOverlayForActivity}
            completeTask={completeTask}
            dismissTask={dismissTask}
        />
    )
}

import { useState } from 'react'

import { Button } from '@laserfocus/ui/beam'
import { AddOutlinedIcon } from '@laserfocus/ui/icons'
import { AddTaskToParentModal } from '@laserfocus/client/shared-step-modal'
import { NewTask, TaskId } from '@laserfocus/shared/models'

interface TaskActionsAfterCompleteProps {
    activity: NewTask
    dismissTask(id: TaskId): void
}

export function TaskActionsAfterComplete({ activity, dismissTask }: TaskActionsAfterCompleteProps) {
    const [isOpen, setIsOpen] = useState(false)

    function dismiss() {
        dismissTask(activity.Id)
    }
    function submitFollowUp() {
        dismiss()
        setIsOpen(false)
    }
    return (
        <>
            <Button onClick={dismiss}>Dismiss</Button>
            <Button iconComponent={AddOutlinedIcon} onClick={() => setIsOpen(true)}>
                Add Task
            </Button>
            {isOpen && (
                <AddTaskToParentModal
                    onSubmit={submitFollowUp}
                    onCancel={() => setIsOpen(false)}
                    whoId={activity.WhoId || undefined}
                    whatId={activity.WhatId || undefined}
                    accountId={activity.AccountId || undefined}
                    name={activity.Who?.Name || activity.Account?.Name || undefined}
                />
            )}
        </>
    )
}

import { Button } from '@laserfocus/ui/beam'
import { AscendingOutlinedIcon, DescendingOutlinedIcon } from '@laserfocus/ui/icons'

import { Column } from '../Table/table-context'

import { FilterSection } from './FilterSection'

interface SortingButtonsProps {
    column: Pick<Column, 'sorting' | 'sortAscending' | 'sortDescending'>
    sectionTitle?: string
}

export function SortingButtons({ column, sectionTitle = 'Sort' }: SortingButtonsProps) {
    const isSortedAscending = column.sorting === 'ASC'
    const isSortedDescending = column.sorting === 'DESC'

    return (
        <FilterSection label={sectionTitle}>
            <div className="grid gap-2 grid-flow-col">
                <Button
                    variant={isSortedAscending ? 'primary' : 'quaternary'}
                    size="small"
                    iconComponent={AscendingOutlinedIcon}
                    iconPosition="right"
                    onClick={column.sortAscending}
                    className={isSortedAscending ? undefined : 'bg-white/5'}
                >
                    <div className="w-full text-left">Asc</div>
                </Button>
                <Button
                    variant={isSortedDescending ? 'primary' : 'quaternary'}
                    size="small"
                    iconComponent={DescendingOutlinedIcon}
                    iconPosition="right"
                    onClick={column.sortDescending}
                    className={isSortedDescending ? undefined : 'bg-white/5'}
                >
                    <div className="w-full text-left">Desc</div>
                </Button>
            </div>
        </FilterSection>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function OpportunityFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M4.38 6.94L7.93 9.3l3.53-4.94h-.01a.84.84 0 011.37-.01l3.52 4.94 3.55-2.36a.83.83 0 011.3.79l-1.4 11.78v-.01a.85.85 0 01-.84.74H5.25a.86.86 0 01-.84-.75L3 7.69a.83.83 0 01.73-.94c.2-.03.4.01.57.13l.07.06zm7.77 8.27h-.01a1.7 1.7 0 000-3.39h-.01a1.68 1.68 0 100 3.37l.02.02z" />
        </SvgIcon>
    )
}

import { useMemo } from 'react'

import {
    useRegisterEverboardingHint,
    EverboardingHintConfig,
    useConditionalTrackUntilCount,
} from '@laserfocus/client/feature-everboarding'

const overlayHint: EverboardingHintConfig = {
    name: 'everboarding_activity_list_overlay',
    shouldShow: (counters) => {
        const count = counters?.record_viewed_overlay || 0
        if (count > 10) {
            return false
        }
        return true
    },
}

export function useActivitystackEverboarding(activities: unknown[], overlayOpen: boolean) {
    const filterHint = useMemo((): EverboardingHintConfig => {
        return {
            name: 'everboarding_activity_list_filter',
            shouldShow: (counters) => {
                const count = counters?.['everboarding_activity_list_view'] || 0
                return Boolean(activities.length >= 15 && count >= 10) && !overlayOpen
            },
        }
    }, [activities.length, overlayOpen])

    useConditionalTrackUntilCount('everboarding_activity_list_view', 30)
    useRegisterEverboardingHint(overlayHint)
    useRegisterEverboardingHint(filterHint)
}

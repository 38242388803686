import type { Options as MiniSearchOptions } from 'minisearch'
import { get, set } from 'lodash'

import type { FieldMetadata } from '@laserfocus/client/model'
import {
    AccountModel,
    ContactModel,
    Identifier,
    isTruthy,
    LeadModel,
    OpportunityModel,
} from '@laserfocus/shared/models'
import { Model } from '@laserfocus/client/data-layer'

import { RCFetchableStack } from './RCFetchableStack'

export function getTrieOptions<Document extends Model>(
    fetchableStack: RCFetchableStack
): MiniSearchOptions {
    const { extractField, fieldPaths } = getConfig<Document>(fetchableStack)

    const boost = SEARCHBOOST_BY_SOBJECT[fetchableStack.stack.sobject]

    return {
        idField: 'Id',
        fields: fieldPaths,
        extractField,
        searchOptions: {
            prefix: true,
            combineWith: 'AND',
            boost,
            // fuzzy: true,
        },
    }
}

export function makeSnapshotter<Document extends Model>(fetchableStack: RCFetchableStack) {
    const { extractField, fieldPaths } = getConfig<Document>(fetchableStack)
    return (document: Document) => {
        const result: Identifier = {
            Id: document.Id,
        }
        for (const path of fieldPaths) {
            const v = extractField(document, path)
            set(result, path, v)
        }
        return result
    }
}

/**
 * This could be simplified to just Stack, but would need to connect
 * the stack with the objectpool.
 */
function getConfig<M extends Model = Model>(fetchableStack: RCFetchableStack) {
    const columnNames = Object.keys(fetchableStack.stack.visibleColumnMap)
    const fieldColumns = columnNames
        .map((columnName) => fetchableStack.getField(columnName))
        .filter<FieldMetadata>(isTruthy)

    const fieldColumnPaths = fieldColumns.map((field) => fetchableStack.getPath(field))
    const computedColumnNames = columnNames.filter((a) => !fetchableStack.getField(a))

    const fieldPaths = [...fieldColumnPaths, ...computedColumnNames]

    // Create a lookup table
    const referenceFields = fieldColumns.filter((a) => a.isReferenceList || a.isPicklist)
    const byFieldByValue: Record<string, Record<string, string>> = {}
    referenceFields.forEach((f) => {
        f.getPicklistOptions().forEach((pl) => {
            set(byFieldByValue, `${f.fullName}.${pl.value}`, pl.label)
        })
    })

    function extractField(document: M, fieldName: string) {
        const value = get(document, fieldName)
        const fullFieldName = fieldName.includes('.')
            ? fieldName
            : `${document.__typename}.${fieldName}`
        const fieldPicklist = get(byFieldByValue, fullFieldName)
        if (Array.isArray(value) && fieldPicklist) {
            return value.map((v) => fieldPicklist[v] || v).join(' ')
        }
        if (Array.isArray(value)) {
            return value.join(' ')
        }
        if (fieldPicklist) {
            return fieldPicklist[value as string] || (value as string)
        }
        if (value && typeof value !== 'string') {
            return `${value}`
        }
        //@TODO expose dates as strings

        return (value as string) || ''
    }
    return {
        extractField,
        columnNames,
        fieldPaths,
    }
}

const SEARCHBOOST_BY_SOBJECT = {
    Opportunity: {
        Name: 2,
        'Account.Name': 2,
        Stage: 1.5,
    },
    Lead: {
        Name: 2,
        Status: 1.5,
        Company: 2,
    },
    Account: {
        Name: 2,
    },
    Contact: {
        Name: 2,
    },
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function LeadFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M10.75 9.96h8.94c1.14 0 1.55 1.54.6 2.22l-3.33 2.42h-.01c-.43.3-.6.84-.45 1.33l1.28 3.92c.36 1.1-.9 2-1.84 1.32l-3.33-2.43-.02-.01a1.18 1.18 0 00-1.4 0l-3.34 2.42A1.2 1.2 0 016 19.81L7.3 15.9h-.01a1.2 1.2 0 00-.44-1.34L3.5 12.12a1.2 1.2 0 01.69-2.16H10.75z" />
            <circle cx="11.94" cy="5.68" r="2.68" />
        </SvgIcon>
    )
}

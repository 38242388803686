import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function LockOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M8.46 3.46A5 5 0 0117 7v3h1a3 3 0 013 3v6a3 3 0 01-3 3H6a3 3 0 01-3-3v-6a3 3 0 013-3h1V7a5 5 0 011.46-3.54zM9 10h6V7a3 3 0 00-6 0v3zm-3 2a1 1 0 00-1 1v6a1 1 0 001 1h12a1 1 0 001-1v-6a1 1 0 00-1-1H6z" />
        </SvgIcon>
    )
}

import { RoutePaths } from '@laserfocus/client/util-routing'
import { Analytics } from '@laserfocus/client/util-analytics'

import { useOverdueActivities } from '../activity/useActivityStack'

import { StackCard } from './StackCard'

export const OverdueCard = function OverdueCard() {
    const { activities, loading } = useOverdueActivities()

    function onClick() {
        Analytics.trackEvent({
            event: 'stack_clicked',
            location: 'home',
            stack: 'overdue',
        })
    }
    return (
        <StackCard
            icon="🔥"
            onClick={onClick}
            linkTo={RoutePaths.Overdue}
            count={loading ? undefined : activities.length}
            color="red"
            title="Overdue"
            sobject="Tasks"
        />
    )
}

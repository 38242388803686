import { v4 as uuidv4 } from 'uuid'
import { AnalyticsBrowser, Options } from '@segment/analytics-next'

import { Config } from '@laserfocus/shared/config-env'
import { logger as rootLogger } from '@laserfocus/ui/logger'
import { getLocation as getRoutingLocation } from '@laserfocus/client/util-routing'
import type { Identity } from '@laserfocus/shared/models'
import { apolloClient } from '@laserfocus/client/data-access-apollo'
import { USER_ACTIVE_MUTATION } from '@laserfocus/shared/data-access-gql'

import { Events, SalesObject } from './domain-events'
import { mapEvent, parseStackFromSobject } from './event-mapping'
import { trackLinkedInConversion } from './linkedin-tracking'

const { SEGMENT_TOKEN } = Config

const logger = rootLogger.child({
    name: 'analytics',
    level: 'debug',
})

export let analytics =
    SEGMENT_TOKEN && typeof window !== 'undefined'
        ? AnalyticsBrowser.load({ writeKey: SEGMENT_TOKEN })
        : undefined

const TRACK = typeof window !== 'undefined' && window.location.hostname !== 'localhost'

let isTrackCallPending = false
let trackCount = 0

export function identifyFromSF(user: Identity) {
    trackCount = 0
    const accountName =
        extractDomain(user.Email) || extractDomain(user.Username) || user.InstanceUrl
    identify(
        user.Id,
        {
            orgId: user.OrgId,
            company: user.OrgId,
            username: user.Username,
            name: user.Name,
            email: user.Email,
            firstName: user.FirstName,
            lastName: user.LastName,
            createdAt: user.CreatedAt,
        },
        {
            name: accountName,
        }
    )
}

export function trackEvent(baseEvent: Events) {
    try {
        potentiallyTrackActive()
        const { event, payload } = mapEvent(baseEvent)
        track(event, payload)
    } catch (e: any) {
        logger.error(e)
    }
}
export function getLocation() {
    return getRoutingLocation()
}

export { parseStackFromSobject }

export function parseSalesObject(
    sobject: 'Lead' | 'Opportunity' | 'Contact' | 'Account'
): SalesObject {
    return sobject.toLowerCase() as SalesObject
}

async function potentiallyTrackActive() {
    if (window.location.host.includes('localhost')) {
        return
    }
    if (trackCount > 10) {
        return
    }
    try {
        const isAuthenticated = (window as any).$store?.isAuthenticated
        if (!isAuthenticated) {
            return
        }

        const lastActive = localStorage.getItem('lf:lastActive')
        if (lastActive) {
            const lastActiveDate = new Date(lastActive)
            const now = new Date()
            const diff = now.getTime() - lastActiveDate.getTime()
            const fourHours = 4 * 60 * 60 * 1000
            if (diff < fourHours) {
                return
            }
        }

        trackCount++
        if (!isTrackCallPending) {
            track('user_active_fe', {})
        }
        isTrackCallPending = true
        const res = await apolloClient.mutate({
            mutation: USER_ACTIVE_MUTATION,
        })
        isTrackCallPending = false

        if (res.errors && res.errors[0]) {
            throw new Error(res.errors[0].message)
        }
        localStorage.setItem('lf:lastActive', new Date().toString())
    } catch (e: any) {
        logger.error(e)
    }
}

export function identify(
    id: string,
    traits: Record<string, string | Date>,
    groupTraits?: Record<string, string>,
    options?: Options
) {
    if (analytics && TRACK) {
        logger.debug('Identify', id)
        analytics.identify(id, traits, options)
        // analytics.identify(id, traits, options);
        if (traits.orgId) {
            analytics.group(traits.orgId as string, groupTraits, options)
        }
    }
}

export function identifyAnonymous(traits: Record<string, string | Date>) {
    if (analytics && TRACK) {
        analytics.identify(traits)
    }
}

export async function getAnonymousId() {
    const anonymousFromLocalStorage = localStorage.getItem('lf:anonymousId')
    if (anonymousFromLocalStorage && analytics) {
        analytics.setAnonymousId(anonymousFromLocalStorage)
        return anonymousFromLocalStorage
    }

    if (analytics) {
        const [lytics] = await analytics
        const idFromAnalytics = lytics.user?.()?.anonymousId()

        if (idFromAnalytics) {
            return idFromAnalytics
        }
    }
    logger.warn('We should not be required to generate our own anonymousIds')

    const idFromLocalStorage = localStorage.getItem('lf:anonymousId')

    if (idFromLocalStorage) {
        return idFromLocalStorage
    }

    const newId = uuidv4()

    localStorage.setItem('lf:anonymousId', newId)
    if (analytics) {
        const [lytics] = await analytics
        lytics?.user?.()?.anonymousId(newId)
    }

    return newId
}

function track(event: string, properties: Record<string, string>, options?: Options) {
    logger.debug(event, properties)
    console.log('TRACK', event, properties)
    const finalOptions = {
        ...options,
        integrations: {
            Slack: false,
        },
    }
    if (analytics && TRACK) {
        analytics.track(event, properties, finalOptions)
        trackLinkedInConversion(event)
    }
}

function extractDomain(email?: string) {
    if (email && email.indexOf('@') > -1) {
        return email.split('@')[1]
    }
}

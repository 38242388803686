import { AccountModel } from './account.types'
import { ContactModel } from './contact.types'
import { LeadModel, isLeadModel } from './lead.types'
import { OpportunityModel } from './opportunity.types'

export function getDisplayName(
    sobject: LeadModel | AccountModel | ContactModel | OpportunityModel
) {
    if (isLeadModel(sobject)) {
        return sobject.Name || sobject.Company || sobject.Email
    } else {
        return sobject.Name
    }
}

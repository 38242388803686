import { memo } from 'react'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { InView } from '@laserfocus/ui/util-react'
import { HEADER_HEIGHT } from '@laserfocus/client/layout'
import { theme } from '@laserfocus/ui/tailwindcss'

import { AVATAR_COLUMN_WIDTH, Column, useTableDataContext } from '../table-context'
import { useBulkEdit } from '../BulkEdit/bulkedit-context'

import { TableHeadCell } from './TableHeadCell'
import { useColumnReorder } from './useColumnReorder'
import { ColorSortHeaderCell } from './ColorSortHeaderCell'

export const TABLE_HEAD_HEIGHT = 42
export const TABLE_HEAD_ROW_CLASS_NAME = 'border-b border-grey-700/10 grid'

interface TableHeadProps {
    top?: number
}

export const TableHead = memo(function TableHead({ top = HEADER_HEIGHT }: TableHeadProps) {
    const {
        columns,
        tableWidth,
        setColumnsOrder,
        setIsStickyLeftActive,
        gridTemplateColumns,
        tableHeadBackgroundColor,
    } = useTableDataContext()!

    const widthScaleFactor = getWidthScaleFactor(columns, tableWidth)

    const { isDragging, springs, bindDrag } = useColumnReorder({
        columns,
        setColumnsOrder,
        widthScaleFactor,
    })

    const { bulkEditTotal } = useBulkEdit()
    const isBulkEditActive = bulkEditTotal !== 'none'

    return (
        <div
            role="rowgroup"
            className="sticky z-20 select-none w-max"
            style={{ backgroundColor: tableHeadBackgroundColor, top }}
        >
            <div
                role="row"
                style={{ height: TABLE_HEAD_HEIGHT, gridTemplateColumns }}
                className={twMerge(TABLE_HEAD_ROW_CLASS_NAME, 'min-w-full')}
            >
                <div
                    role="columnheader"
                    className={clsx('grid justify-end p-0 z-', isBulkEditActive && 'sticky left-0')}
                    style={{
                        ...(isBulkEditActive && {
                            backgroundColor: tableHeadBackgroundColor,
                            boxShadow: `inset 0 -1px 0 ${theme.colors.grey[700]}1A`,
                        }),
                        // This is the animated columns will all get a soorted zIndexes, and this needs to be higher
                        zIndex: columns.length + 1,
                    }}
                >
                    <ColorSortHeaderCell />
                    <InView
                        onIntersect={({ isIntersecting }) => setIsStickyLeftActive(!isIntersecting)}
                    />
                </div>
                {columns.map((column, index) => {
                    const { shadow, ...animatedStyles } = springs[index]!

                    return (
                        <TableHeadCell
                            key={column.key}
                            animatedProps={{
                                ...(!column.isStickyLeft && bindDrag(index)),
                                style: column.isStickyLeft
                                    ? { zIndex: animatedStyles.zIndex }
                                    : {
                                          ...animatedStyles,
                                          boxShadow: shadow.to((s) => {
                                              return `rgba(0, 0, 0, ${s === 0 ? 0 : 0.1}) 0px ${
                                                  1.5 * s
                                              }px ${2 * s}px 0px`
                                          }),
                                      },
                            }}
                            column={column}
                            isDragging={isDragging}
                            widthScaleFactor={widthScaleFactor}
                            hideResizeIndicator={index === columns.length - 1}
                        />
                    )
                })}
            </div>
        </div>
    )
})

function getWidthScaleFactor(columns: Column[], tableWidth: number) {
    const allColumnsWidthTheoretical = columns.reduce((width, column) => width + column.width, 0)
    const allColumnsWidthPractical = tableWidth - AVATAR_COLUMN_WIDTH

    return Math.max(allColumnsWidthPractical / allColumnsWidthTheoretical, 1)
}

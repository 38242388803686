import type { FieldMetadataProps } from '@laserfocus/shared/models'
import { FieldMetadata } from '@laserfocus/client/model'
import { ObjectPool } from '@laserfocus/client/data-layer'

import { MetadataStore } from '../metadata/MetadataStore'

import metadataJSON from './fixtures/getMetadata.json'

const metadata = metadataJSON.map((a) => ({
    ...a,
    id: `${a.objectName}.${a.name}`,
    __typename: 'FieldMetadata',
})) as unknown as FieldMetadataProps[]

export function makeMetadataStore() {
    const objectPool = new ObjectPool()
    objectPool.registerModel('FieldMetadata', FieldMetadata)
    const metaStore = new MetadataStore(objectPool, {} as any)
    metadata.forEach((meta) =>
        objectPool.attach({
            ...meta,
            __typename: 'FieldMetadata',
        })
    )

    return metaStore
}

export function addMetadata(pool: ObjectPool) {
    pool.registerModel('FieldMetadata', FieldMetadata)
    const metaStore = new MetadataStore(pool, {} as any)
    metadata.forEach((meta) =>
        pool.attach({
            ...meta,
            __typename: 'FieldMetadata',
        })
    )
    metaStore.loadRelationMetadata()
}

import { useState } from 'react'

import { SubscriptionUtil } from '@laserfocus/client/model'
import { FeatureList, InviteCoworkerModal } from '@laserfocus/client/ui-shared'
import { UserSubscription } from '@laserfocus/shared/models'
import { Button, Modal } from '@laserfocus/ui/beam'
import { ExternalLinkOutlinedIcon } from '@laserfocus/ui/icons'
import { useUserId } from '@laserfocus/client/feature-auth'

import { CheckoutButton } from '../LinkButtons/CheckoutButton'

export const CALENDLY_LINK = 'https://calendly.com/lasertalk/democall'

type PlanModalProps = Omit<TrialModalInnerProps, 'openInviteModal'> & {
    show: boolean
    openModal: () => void
    onClose?: () => void
}

export function ProPlanModal({
    closeModal,
    openModal,
    show,
    hideOverlay,
    onClose,
    ...rest
}: PlanModalProps) {
    const [isInviteOpen, setIsInviteOpen] = useState(false)
    const userId = useUserId()
    function openInvite() {
        closeModal()
        setTimeout(() => {
            setIsInviteOpen(true)
        }, 150)
    }

    function closeInvite() {
        setIsInviteOpen(false)
        setTimeout(() => {
            openModal()
        }, 100)
    }

    function close() {
        onClose?.()
        closeModal()
    }

    return (
        <>
            <Modal show={show} onClose={close}>
                <TrialModalInner
                    closeModal={close}
                    openInviteModal={openInvite}
                    hideOverlay={hideOverlay}
                    {...rest}
                />
            </Modal>
            <InviteCoworkerModal
                show={isInviteOpen}
                closeModal={closeInvite}
                userId={userId}
                hideOverlay={hideOverlay}
            />
        </>
    )
}

type TrialModalInnerProps = {
    closeModal: () => void
    openInviteModal: () => void
    subscription: UserSubscription
    hideOverlay?: boolean
}

function TrialModalInner({
    closeModal,
    subscription,
    openInviteModal,
    hideOverlay,
}: TrialModalInnerProps) {
    const daysLeft = SubscriptionUtil.daysUntilTrialEnd(subscription)
    const stillInTrial = Boolean(daysLeft)
    const message = daysLeft === 1 ? '1 day' : `${daysLeft} days`
    return (
        <>
            {!hideOverlay && <Modal.Overlay />}
            <Modal.Container>
                <Modal.Header close={closeModal}>
                    {stillInTrial ? <span>Your trial lasts {message}</span> : 'Upgrade to Pro'}
                </Modal.Header>

                <div className="mx-8 my-3 grid gap-2">
                    <div className="mt-2 border-2 border-grey-700/10 p-4 pt-6">
                        {stillInTrial && (
                            <div className="mb-2">You have access to these features:</div>
                        )}
                        <PlanDetails
                            planName="PRO"
                            planPrice="$30"
                            planPriceDetails="per month"
                            features={[
                                'Custom stacks',
                                'Schedule your stacks',
                                'Note templates',
                                'Custom field groups',
                                'Bulk Edit',
                            ]}
                        />

                        <div className="mt-4 px-2">
                            {stillInTrial
                                ? "Don't want to lose the features?"
                                : 'Want to get the features?'}
                        </div>

                        <div className="grid grid-flow-col gap-2 p-2">
                            <CheckoutButton
                                returnUrl="/home"
                                variant="secondary"
                                className="w-full justify-center"
                            >
                                <span>
                                    Upgrade to <span className="font-semibold">PRO</span>
                                </span>
                                <ExternalLinkOutlinedIcon className="ml-1 w-3 h-3" />
                            </CheckoutButton>
                            <Button
                                type="submit"
                                variant="primary"
                                onClick={openInviteModal}
                                className="w-full"
                            >
                                Invite Colleagues
                            </Button>
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className="text-center">
                            Do you want to use Laserfocus for your Team?
                        </div>
                    </div>
                    <div className="mt-2">
                        <Button
                            variant="tertiary"
                            type="submit"
                            className="w-full justify-center"
                            as="a"
                            href={CALENDLY_LINK}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Schedule a Demo
                            <ExternalLinkOutlinedIcon className="ml-1 w-3 h-3" />
                        </Button>
                    </div>
                </div>
            </Modal.Container>
        </>
    )
}

function PlanDetails({
    features,
    planName,
    planPrice,
    planPriceDetails,
}: {
    features: string[]
    planName: string
    planPrice: string
    planPriceDetails?: string
}) {
    return (
        <div className="grid grid-flow-col p-2">
            <FeatureList features={features} />
            <div className="min-h-[5.65rem] pl-12">
                <h2 className="font-bold">{planName}</h2>
                <p className="font-bold text-[1.5rem] leading-[1.2] md:text-[1.7rem] pt-1">
                    {planPrice}
                </p>
                {planPriceDetails && <p className="text-grey-700/60">{planPriceDetails}</p>}
            </div>
        </div>
    )
}

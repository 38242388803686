import { useState } from 'react'

import { Button, Dropdown } from '@laserfocus/ui/beam'
import { FilterOutlinedIcon } from '@laserfocus/ui/icons'
import { NewActivity } from '@laserfocus/shared/models'
import { ActivityUtil } from '@laserfocus/client/model'
import { useActivitiesMetadataContext } from '@laserfocus/client/data-access-shared'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'

type SortBy = 'date' | 'priority'
type FilterBy = 'none' | 'event' | 'task'

interface FilterButtonProps {
    sortBy: SortBy
    setSortBy(sortBy: SortBy): void
    filterBy: FilterBy
    setFilterBy: React.Dispatch<React.SetStateAction<FilterBy>>
}

export function FilterButton(props: FilterButtonProps) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    return (
        <EverboardingHint
            name="everboarding_activity_list_filter"
            label={`Show only tasks or events`}
            placement="left"
            containerClassName="-translate-y-7"
            labelClassName="-rotate-3 translate-y-3"
        >
            <Dropdown
                isOpen={isDropdownOpen}
                content={<FilterDropdownContent {...props} />}
                onCancel={() => setIsDropdownOpen(false)}
            >
                <Button
                    title="Open filters"
                    variant="secondary"
                    iconComponent={FilterOutlinedIcon}
                    onClick={() => setIsDropdownOpen((v) => !v)}
                />
            </Dropdown>
        </EverboardingHint>
    )
}

function FilterDropdownContent({ sortBy, setSortBy, filterBy, setFilterBy }: FilterButtonProps) {
    const sortButtons = [
        { value: 'date', label: 'Date' },
        { value: 'priority', label: 'Priority' },
    ] as const

    const filterButtons = [
        { value: 'task', label: 'Task' },
        { value: 'event', label: 'Event' },
    ] as const

    return (
        <div role="dialog" className="p-2 w-[14.375rem] grid gap-4">
            <div className="grid gap-2">
                <h2 className="text-xs leading-[1.2] font-medium text-white/60">Sort by</h2>
                <div className="grid gap-2 grid-flow-col">
                    {sortButtons.map(({ value, label }) => (
                        <Button
                            key={value}
                            variant={sortBy === value ? 'primary' : 'quaternary'}
                            size="small"
                            onClick={() => setSortBy(value)}
                            className={sortBy === value ? undefined : 'bg-white/5'}
                        >
                            {label}
                        </Button>
                    ))}
                </div>
            </div>
            <div className="grid gap-2">
                <h2 className="text-xs leading-[1.2] font-medium text-white/60">Filter by</h2>
                <div className="grid gap-2 grid-flow-col">
                    {filterButtons.map(({ value, label }) => (
                        <Button
                            key={value}
                            variant={filterBy === value ? 'primary' : 'quaternary'}
                            size="small"
                            onClick={() => setFilterBy((f) => (f === value ? 'none' : value))}
                            className={filterBy === value ? undefined : 'bg-white/5'}
                        >
                            {label}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export function useFilter<T extends NewActivity>(activities: T[], dateReverted?: boolean) {
    const [filterBy, setFilterBy] = useState<FilterBy>('none')
    const [sortBy, setSortBy] = useState<SortBy>('date')
    const metadata = useActivitiesMetadataContext()

    const sortByDate = sortBy === 'date'

    const filteredActivities =
        filterBy === 'none'
            ? activities
            : activities.filter(filterBy === 'task' ? ActivityUtil.isTask : ActivityUtil.isEvent)
    const sortedActivities = filteredActivities
        .slice()
        .sort(sortByDate ? ActivityUtil.openActivitySorter : createPrioritySorter(metadata))

    if (sortByDate && !dateReverted) {
        sortedActivities.reverse()
    }

    return {
        filteredActivities: sortedActivities,
        sortBy,
        setSortBy,
        filterBy,
        setFilterBy,
    }
}

function createPrioritySorter(metadata: ReturnType<typeof useActivitiesMetadataContext>) {
    if (!metadata || !metadata.taskPriorities) {
        return ActivityUtil.openActivitySorter
    }

    const prioritiesLength = metadata.taskPriorities.length

    const priorityIndices = Object.fromEntries(
        metadata.taskPriorities.map(({ value }, index) => [value, prioritiesLength - index])
    )

    return (a: NewActivity, b: NewActivity) => {
        const aPriorityIndex = priorityIndices[(a as any).Priority] || 0
        const bPriorityIndex = priorityIndices[(b as any).Priority] || 0
        return bPriorityIndex - aPriorityIndex
    }
}

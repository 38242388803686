import { useState } from 'react'

import { CreateStackModal, CreateStackContext } from '@laserfocus/client/feature-stacks'
import { Button } from '@laserfocus/ui/beam'

import { makeStackUrl } from './stack-settings-util'

export function CreateStackButton({ role }: { role: string | null }) {
    const [showModal, setShowModal] = useState(false)

    return (
        <div className="mt-1">
            <Button onClick={() => setShowModal(true)}>Add Stack</Button>
            <CreateStackContext.Provider
                value={{
                    type: 'org',
                    allowImport: false,
                    makePath: makeStackUrl,
                    showHints: false,
                    extension: role ? { roles: [role] } : undefined,
                }}
            >
                <CreateStackModal show={showModal} closeModal={() => setShowModal(false)} />
            </CreateStackContext.Provider>
        </div>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function DataLimitOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g fillRule="evenodd" clipPath="url(#data-limit-outlined-path)">
                <path d="M3.33 8.07c.32.2.66.39 1.03.55 1.48.66 3.48 1.05 5.64 1.05 2.16 0 4.16-.4 5.64-1.05.37-.16.71-.35 1.03-.55v.76a.83.83 0 0 0 1.66 0V5.5c0-.73-.33-1.37-.82-1.87a5.86 5.86 0 0 0-1.87-1.25A14.28 14.28 0 0 0 10 1.33c-2.16 0-4.16.4-5.64 1.05-.74.33-1.4.75-1.87 1.25-.49.5-.82 1.14-.82 1.87v9.17c0 1.2.9 2.13 1.95 2.74a11.4 11.4 0 0 0 4.2 1.29.83.83 0 0 0 .2-1.66 9.77 9.77 0 0 1-3.57-1.07c-.87-.51-1.12-1-1.12-1.3v-2.02l.29.17a11.4 11.4 0 0 0 4.2 1.3.83.83 0 0 0 .2-1.66 9.77 9.77 0 0 1-3.57-1.08c-.87-.5-1.12-.99-1.12-1.3V8.07Zm.36-3.3c-.27.3-.36.54-.36.73 0 .19.09.43.36.72.28.3.73.6 1.35.88 1.22.54 2.98.9 4.96.9s3.74-.36 4.96-.9a4.23 4.23 0 0 0 1.35-.88c.27-.29.36-.53.36-.72 0-.19-.09-.43-.36-.72-.28-.3-.73-.6-1.35-.88C13.74 3.36 11.98 3 10 3s-3.74.36-4.96.9c-.62.28-1.07.58-1.35.88Z" />
                <path d="M10 15.5a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm5-3.33a3.33 3.33 0 0 0-2.87 5.02l4.56-4.56c-.5-.3-1.07-.46-1.69-.46Zm2.87 1.64-4.56 4.56a3.33 3.33 0 0 0 4.56-4.57Z" />
            </g>
            <defs>
                <clipPath id="data-limit-outlined-path">
                    <path fill="#fff" d="M0 0h20v20H0z" transform="translate(0 .5)" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

import { keyBy, omit, partition, sortBy } from 'lodash'

import { OrgFieldGroup, UserFieldGroup, FieldGroup } from '@laserfocus/shared/models'

export function parseFieldGroups(fieldGroups: Array<UserFieldGroup | OrgFieldGroup>): FieldGroup[] {
    const [user, org] = partition(fieldGroups, (fg) => fg.__typename === 'UserFieldGroup') as [
        UserFieldGroup[],
        OrgFieldGroup[]
    ]

    const userById: Record<string, UserFieldGroup> = keyBy(user, 'id')

    const combined: Record<string, FieldGroup> = {}

    org.forEach((fg) => {
        const userFG = userById[fg.id]
        if (userFG) {
            combined[fg.id] = mergeFieldGroup(fg, userFG)
        } else if (!fg.deactivated) {
            combined[fg.id] = fromOrgFieldGorup(fg)
        }
    })
    user.forEach((fg) => {
        if (!combined[fg.id]) {
            combined[fg.id] = fg
        }
    })
    const sorted = sortBy(Object.values(combined), 'orderKey').filter((fg) => !fg.deleted)
    return sorted
}

function fromOrgFieldGorup(orgFieldGroup: OrgFieldGroup): FieldGroup {
    return {
        ...orgFieldGroup,
        lockedFieldNames: new Set(Object.keys(orgFieldGroup.visibleFieldOrderMap)),
    }
}

function mergeFieldGroup(orgFieldGroup: OrgFieldGroup, userFieldGroup: UserFieldGroup): FieldGroup {
    return {
        // If we are mergin, it should not be deactivated
        ...omit(orgFieldGroup, 'deactivated'),
        ...userFieldGroup,
        visibleFieldOrderMap: {
            ...orgFieldGroup.visibleFieldOrderMap,
            ...userFieldGroup.visibleFieldOrderMap,
        },
        lockedFieldNames: new Set(Object.keys(orgFieldGroup.visibleFieldOrderMap)),
        orgDeactivated: orgFieldGroup.deactivated,
        isExtended: true,
        __typename: orgFieldGroup.__typename,
        deleted: orgFieldGroup.deleted,
    }
}

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { SearchOutlinedIcon, LaserfocusLogo, GiftOutlinedIcon } from '@laserfocus/ui/icons'
import { Button } from '@laserfocus/ui/beam'
import { Search } from '@laserfocus/client/feature-search'
import { Analytics } from '@laserfocus/client/util-analytics'
import { useHotKey } from '@laserfocus/ui/util-keyboard'
import { InView } from '@laserfocus/ui/util-react'
import { InviteCoworkerModal } from '@laserfocus/client/ui-shared'
import { useSettings, useUserId } from '@laserfocus/client/feature-auth'
import { useSubscription } from '@laserfocus/client/data-access-shared'

import { Breadcrumb, BreadCrumbs } from './Breadcrumbs'
import { BACKGROUND_COLOR_CLASSES, useBackgroundColor } from './useBackgroundColor'

export const HEADER_HEIGHT = 78

export interface NavBarProps {
    breadcrumbs?: readonly Breadcrumb[]
    bg?: keyof typeof BACKGROUND_COLOR_CLASSES | (string & {})
    className?: string
    rightSide?: React.ReactNode
    children?: React.ReactNode
}

export function NavBar({ breadcrumbs, bg = 'white', className, rightSide, children }: NavBarProps) {
    const [isSticky, setIsSticky] = useState(false)

    const { backgroundColorClass, backgroundColorLiteral } = useBackgroundColor(bg)

    return (
        <>
            <InView onIntersect={({ isIntersecting }) => setIsSticky(!isIntersecting)} />
            <nav
                className={twMerge(
                    'max-w-[100vw] grid grid-flow-col justify-between items-center px-6 sticky top-0 left-0 transition-colors z-10 border-b',
                    isSticky ? 'border-grey-700/10' : 'border-transparent',
                    backgroundColorClass,
                    className
                )}
                style={{ height: HEADER_HEIGHT, backgroundColor: backgroundColorLiteral }}
                data-testid="header"
            >
                <div className="grid grid-flow-col gap-2 items-center">
                    <LaserfocusLogo className="w-5 h-5" />
                    <BreadCrumbs childBreadCrumbs={breadcrumbs} />
                </div>

                {children}

                <div className="grid grid-flow-col gap-2 pl-2 items-center">
                    {rightSide}
                    <SearchSection />
                    <InviteSection />
                </div>
            </nav>
        </>
    )
}

function SearchSection() {
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    useHotKey('/', () => setIsSearchOpen(true))
    const closeModal = () => setIsSearchOpen(false)

    return (
        <>
            <Button
                title="Search"
                variant="tertiary"
                className="text-current p-1.5"
                onClick={() => {
                    setIsSearchOpen(true)
                    Analytics.trackEvent({
                        event: 'search_clicked',
                        location: Analytics.getLocation() as any,
                    })
                }}
            >
                <SearchOutlinedIcon className="w-5 h-5" />
            </Button>
            <Search show={isSearchOpen} close={closeModal} />
        </>
    )
}

function InviteSection() {
    const userId = useUserId()
    const { isLoading, subscription, orgSubscription } = useSubscription()
    const [isOpen, setIsOpen] = useState(false)
    const closeModal = () => setIsOpen(false)

    if (isLoading || subscription?.plan === 'pro' || orgSubscription?.plan === 'team') {
        return null
    }

    return (
        <>
            <Button
                title="Invite coworker"
                variant="tertiary"
                className="text-current p-1.5"
                onClick={() => {
                    setIsOpen(true)
                    Analytics.trackEvent({
                        event: 'share_clicked',
                        location: Analytics.getLocation() as any,
                    })
                }}
            >
                <GiftOutlinedIcon className="w-5 h-5" />
            </Button>
            <InviteCoworkerModal show={isOpen} closeModal={closeModal} userId={userId} />
        </>
    )
}

import { useEffect } from 'react'

import { EmptyState } from '@laserfocus/ui/beam'
import { useBodyClassName } from '@laserfocus/ui/util-react'

export function LogoutPage() {
    useBodyClassName('bg-blue-500')
    useEffect(() => {
        localStorage.clear()
        setTimeout(() => localStorage.clear(), 300)
    }, [])

    return (
        <div className="h-screen">
            <EmptyState
                title="You successfully logged out"
                emojis={['👋', '😘', '🥺']}
                action={{
                    label: 'Login again',
                    href: '/home',
                }}
            />
        </div>
    )
}

export default function LogoutContainer() {
    return <LogoutPage />
}

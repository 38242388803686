import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function FilterOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M2 6c0-.6.4-1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm3 6c0-.6.4-1 1-1h12a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm4 6c0-.6.4-1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
        </SvgIcon>
    )
}

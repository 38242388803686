import { Column, Row } from '../table-context'

import { StickyLeftCell } from './StickyLeftCell'
import { TextCell } from './TextCell'
import { DateCell } from './DateCell'
import { SwitchCell } from './SwitchCell'
import { SelectCell } from './SelectCell'
import { MultiSelectCell } from './MultiSelectCell'
import { FallbackCell } from './FallbackCell'
import { NumberCell } from './NumberCell'
import { TimeCell } from './TimeCell'
import { TextCellWithAction } from './TextCellWithAction'
import { TaskDateCell } from './task-cells/TaskDateCell'
import { TaskTitleCell } from './task-cells/TaskTitleCell'
import { OpenInSFCell } from './custom/OpenInSFCell'
import { ReferenceCell } from './ReferenceCell'

export interface TableCellProps {
    salesObject: Row
    column: Column
    searchTerm?: string
    tableRef?: React.RefObject<HTMLElement>
    rowIndex: number
}

export function TableCell(props: TableCellProps) {
    if (props.column.isStickyLeft) {
        return <StickyLeftCell {...props} />
    }

    switch (props.column.key) {
        case 'NextActivity.Date':
            return <TaskDateCell {...props} />
        case 'NextActivity.Subject':
            return <TaskTitleCell {...props} />
        case 'Account.LF_OpenInSalesforce':
        case 'Lead.LF_OpenInSalesforce':
        case 'Contact.LF_OpenInSalesforce':
        case 'Opportunity.LF_OpenInSalesforce':
        case 'LF_OpenInSalesforce':
            return <OpenInSFCell {...props} />
    }

    switch (props.column.type) {
        case 'string':
            if (props.column.fieldLength && props.column.fieldLength > 120) {
                return <TextCell {...props} multiline />
            }
            return <TextCell {...props} />
        case 'textarea':
            return <TextCell {...props} multiline allowLineBreaks />
        case 'datetime':
            return <DateCell {...props} />
        case 'date':
            return <DateCell {...props} dateOnly />
        case 'boolean':
            return <SwitchCell {...props} />
        case 'picklist':
            return <SelectCell {...props} />
        case 'multipicklist':
            return <MultiSelectCell {...props} />
        case 'reference':
            if (props.column.isSearchReference) {
                return <ReferenceCell {...props} />
            } else if (props.column.isReferenceList) {
                return <SelectCell {...props} />
            }
            return <TextCell {...props} />

        case 'int':
        case 'double':
        case 'currency':
        case 'percent':
            return <NumberCell {...props} />
        case 'time':
            return <TimeCell {...props} />
        case 'url':
        case 'phone':
        case 'email':
            return <TextCellWithAction {...props} />
    }

    return <FallbackCell {...props} />
}

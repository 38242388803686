import { confetti } from 'dom-confetti'
import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { theme } from '@laserfocus/ui/tailwindcss'

export function Confetti({
    show,
    className,
    delay,
}: {
    show: boolean
    className?: string
    delay?: number
}) {
    const confettiContainer = useRef<HTMLDivElement>(null)
    const hasFiredConfettiRef = useRef(false)

    useEffect(() => {
        if (show) {
            const handle = setTimeout(() => {
                if (!hasFiredConfettiRef.current && confettiContainer.current) {
                    hasFiredConfettiRef.current = true
                    confetti(confettiContainer.current, {
                        angle: 90,
                        spread: 360,
                        startVelocity: 40,
                        elementCount: 70,
                        dragFriction: 0.12,
                        duration: 3000,
                        stagger: 3,
                        width: '10px',
                        height: '10px',
                        perspective: '500px',
                        colors: [
                            theme.colors.red[500],
                            theme.colors.blue[500],
                            theme.colors.green[500],
                            theme.colors.yellow[300],
                        ],
                    })
                }
            }, delay || 0)
            return () => clearTimeout(handle)
        }
    }, [show])
    return show ? (
        <div ref={confettiContainer} className={twMerge('absolute left-1/2 top-1/6', className)} />
    ) : null
}

import { createContext, useContext } from 'react'

import { Overlay } from './domain-events'

type AnalyticsContextType = {
    // Right now we are using this context only for the overlay
    location: Overlay | null
}
export const AnalyticsContext = createContext<AnalyticsContextType>({
    location: null,
})

export function useAnalyticsContext() {
    return useContext(AnalyticsContext)
}

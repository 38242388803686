import { useLayoutEffect, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import clsx from 'clsx'
import { Transition } from '@headlessui/react'

import { InView, useTitle } from '@laserfocus/ui/util-react'
import { HEADER_HEIGHT, LayoutPaddingVertical, NavBar } from '@laserfocus/client/layout'
import { Stack, StackUpdate } from '@laserfocus/shared/models'
import { StackModel } from '@laserfocus/client/model'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { STACK_MAX_FETCH_RECORDS } from '@laserfocus/client/store-shared'
import { ShareStackModal, TimeStackModal } from '@laserfocus/client/feature-stacks'
import { SearchInputButton } from '@laserfocus/client/ui-shared'

import { EditColumns } from './EditColumns/EditColumns'
import { MoreActions } from './TablePageHeader/MoreActions'
import { Row, TableRowsProvider } from './Table/table-context'
import { DataLimitModal } from './DataLimitModal'
import { useTooMuchDataShownForStack } from './TableUIStateContext'
import { TablePageHeader, TABLE_PAGE_HEADER_HEIGHT } from './TablePageHeader/TablePageHeader'

interface TablePageLayoutProps {
    children: React.ReactNode
    stack: Stack
    hasUnsavedChanges?: boolean
    resetStack(): void
    duplicateStack(title: string): void
    setTitle(title: string): void
    setIsFavorite(isFavorite: boolean): void
    deleteStack(): Promise<void>
    rows: Row[]
    searchTerm: string
    setSearchTerm: (v: string) => void
    loadedCount?: number
    totalCount?: number
    isLoading: boolean
    isLoadingMore: boolean
    isOverlayOpen: boolean
    updateStack(update: StackUpdate): void
}

const TITLE_PLACEHOLDER = 'Stack name'
const TITLE_PLACEHOLDER_NEW_STACK = 'New stack name'

export const TablePageLayout = function TablePageLayout({
    children,
    resetStack,
    updateStack,
    duplicateStack,
    setIsFavorite,
    setTitle,
    deleteStack,
    stack,
    rows,
    searchTerm,
    setSearchTerm,
    isLoading,
    isLoadingMore,
    loadedCount,
    totalCount,
    isOverlayOpen,
}: TablePageLayoutProps) {
    const navigate = useNavigate()
    const { title, id: stackId } = stack

    const { wasShown, onFilterChanged, onModalShown } = useTooMuchDataShownForStack(stackId)
    const [innerTitle, setInnerTitle] = useState(title)

    const [isCreatingNewStack, setIsCreatingNewStack] = useState(false)
    const [showShareStackModal, setShowShareStackModal] = useState(false)
    const [showTimeStackModal, setShowTimeStackModal] = useState(false)
    const [showCountMessage, setShowCountMessage] = useState(false)
    const [isPageHeaderVisible, setIsPageHeaderVisible] = useState(true)
    const hasCountMessage = Boolean(
        totalCount &&
            totalCount > STACK_MAX_FETCH_RECORDS &&
            loadedCount &&
            loadedCount < totalCount
    )

    const titlePlaceholder = isCreatingNewStack ? TITLE_PLACEHOLDER_NEW_STACK : TITLE_PLACEHOLDER

    useTitle(innerTitle)

    useLayoutEffect(() => {
        setInnerTitle(title)
    }, [title])
    useEffect(() => {
        if (isLoading) {
            onFilterChanged(stackId)
        }
    }, [isLoading, onFilterChanged, stackId])
    useEffect(() => {
        if (hasCountMessage && !wasShown) {
            setShowCountMessage(true)
            onModalShown(stackId)
        }
    }, [hasCountMessage, onModalShown, setShowCountMessage, stackId, wasShown])

    return (
        <TableRowsProvider value={{ rows }}>
            <NavBar
                breadcrumbs={[{ title: innerTitle || titlePlaceholder }]}
                bg={StackModel.getColor(stack) as any}
                className={clsx('bg-clip-border', isOverlayOpen && 'w-[calc(100vw-38rem)]')}
                rightSide={
                    <Transition
                        show={!isPageHeaderVisible}
                        enter="transition-opacity"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="grid grid-flow-col gap-2 items-center"
                    >
                        <SearchInputButton value={searchTerm} onChange={setSearchTerm} />
                        <EditColumns />
                        <MoreActions
                            canDelete={!(stack.preset || stack.__typename === 'OrgStack')}
                            canReset={Boolean(stack.isExtendedFromOrg)}
                            isFavorite={stack.isFavorite || false}
                            deleteStack={() => deleteStack().then(() => navigate(RoutePaths.Home))}
                            duplicateStack={() => setIsCreatingNewStack(true)}
                            setIsFavorite={setIsFavorite}
                            shareStack={() => setShowShareStackModal(true)}
                            resetStack={resetStack}
                            configureTiming={() => setShowTimeStackModal(true)}
                        />
                    </Transition>
                }
            />
            <InView
                className={clsx(
                    'sticky left-0',
                    isOverlayOpen ? 'w-[calc(100vw-38rem)]' : 'w-screen'
                )}
                style={{ height: TABLE_PAGE_HEADER_HEIGHT }}
                onIntersect={({ isIntersecting }) => setIsPageHeaderVisible(isIntersecting)}
                rootMargin={`-${HEADER_HEIGHT}px 0px 0px 0px`}
            >
                <LayoutPaddingVertical>
                    <TablePageHeader
                        innerTitle={innerTitle}
                        setInnerTitle={setInnerTitle}
                        setTitle={setTitle}
                        setIsCreatingNewStack={setIsCreatingNewStack}
                        isCreatingNewStack={isCreatingNewStack}
                        titlePlaceholder={titlePlaceholder}
                        stack={stack}
                        duplicateStack={duplicateStack}
                        resetStack={resetStack}
                        setIsFavorite={setIsFavorite}
                        setShowCountMessage={setShowCountMessage}
                        hasCountMessage={hasCountMessage}
                        isLoadingMore={isLoadingMore}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        deleteStack={deleteStack}
                        setShowShareStackModal={setShowShareStackModal}
                        setShowTimeStackModal={setShowTimeStackModal}
                        updateStack={updateStack}
                    />
                </LayoutPaddingVertical>
            </InView>
            <ShareStackModal
                stack={stack}
                show={showShareStackModal}
                closeModal={() => setShowShareStackModal(false)}
            />
            <TimeStackModal
                stack={stack}
                show={showTimeStackModal}
                closeModal={() => setShowTimeStackModal(false)}
            />
            {hasCountMessage && showCountMessage && (
                <DataLimitModal
                    loadedCount={loadedCount!}
                    totalCount={totalCount!}
                    close={() => setShowCountMessage(false)}
                />
            )}
            <div data-testid="page-table" className="w-min">
                {children}
            </div>
        </TableRowsProvider>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function Delete2OutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M8.59 2.59A2 2 0 0110 2h4a2 2 0 012 2v2h4a1 1 0 110 2h-.07l-.8 11.21a3 3 0 01-3 2.79H7.87a3 3 0 01-2.99-2.79L4.07 8H4a1 1 0 010-2h4V4a2 2 0 01.59-1.41zM10 6h4V4h-4v2zM6.07 8l.8 11.07a1 1 0 001 .93h8.27a1 1 0 001-.93L17.93 8H6.07zM10 10a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1zm4 0a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1z" />
        </SvgIcon>
    )
}

import { useLayoutEffect, useRef } from 'react'

export function useFillHeightRef<Element extends HTMLElement>() {
    const elementRef = useRef<Element>(null)

    useLayoutEffect(() => {
        const node = elementRef.current

        if (node) {
            const heightCss = `calc(100vh - ${getDocumentOffsetTop(node)}px)`

            node.style.minHeight = heightCss
        }
    })

    return elementRef
}

function getDocumentOffsetTop(node: HTMLElement) {
    let currentOffsetTop = 0

    for (
        let currentNode: HTMLElement | null = node;
        currentNode;
        currentNode = currentNode.offsetParent as HTMLElement | null
    ) {
        currentOffsetTop += currentNode.offsetTop
    }

    return currentOffsetTop
}

import { generateNKeysBetween } from 'fractional-indexing'
import { zip, omit } from 'lodash'

import { SFDateConstants } from './date-ranges'
import { FilterCondition } from './filter-conditions-schema'
import { StackInput } from './stack.types'

const iAmTheOwner: FilterCondition = {
    fieldName: 'OwnerId',
    fieldType: 'reference',
    operator: 'EQ',
    values: ['$me'],
}
const openOpportunity: FilterCondition = {
    fieldName: 'IsClosed',
    fieldType: 'boolean',
    operator: 'EQ',
    values: [false],
}

const closingThigsQuarter: FilterCondition = {
    fieldName: 'CloseDate',
    fieldType: 'date',
    operator: 'EQ',
    values: ['THIS_QUARTER'],
}

const notConvertedLead: FilterCondition = {
    fieldName: 'IsConverted',
    fieldType: 'boolean',
    operator: 'EQ',
    values: [false],
}
const createdSinceLastWeek: FilterCondition = {
    fieldName: 'CreatedDate',
    fieldType: 'datetime',
    operator: 'GT',
    values: ['LAST_WEEK' as const],
}
const noActivitySinceLastMonth: FilterCondition = {
    fieldName: 'LastActivityDate',
    fieldType: 'date',
    operator: 'LT',
    values: ['LAST_MONTH' as const],
}

const noNextTask: FilterCondition = {
    fieldName: 'NextActivity.Subject',
    fieldType: 'string',
    operator: 'EQ',
    isComputed: true,
    values: [null],
}

/**
 * Presets include timing and lock things
 * Templates are just a bare template without timing and locks
 */
type StackType = 'preset' | 'template'

export function getPastCloseDatesStack(type?: StackType) {
    const rawConditions = [
        iAmTheOwner,
        openOpportunity,
        {
            fieldName: 'CloseDate',
            fieldType: 'date',
            operator: 'LT',
            values: [SFDateConstants.TODAY],
        } as FilterCondition,
    ]

    const conditions =
        type === 'preset'
            ? rawConditions.map(
                  (cond: FilterCondition): FilterCondition => ({
                      ...cond,
                      isLocked: true,
                  })
              )
            : rawConditions

    const pastCloseDates: StackInput = {
        sobject: 'Opportunity',
        title: 'Past Close Dates',
        color: '#FFE6E2', //red-50
        // order: 1,
        visibleColumnMap: toSortingMap([
            'Account.Name',
            'Name',
            'NextStep',
            'NextActivity.Subject',
            'NextActivity.Date',
            'StageName',
            'CloseDate',
            'OwnerId',
            'IsClosed',
        ]),
        filterConditions: toConditionMap(conditions),
        lockedColumnNames: type === 'preset' ? ['CloseDate'] : undefined,
        preset: type ? 'past-close-date' : undefined,
        icon: '🧹',
        description: 'Update the Close Dates in the past of your Opportunities',
        timing:
            type === 'preset'
                ? {
                      unit: 'week',
                      interval: 1,
                      daysOfTheWeek: [2],
                  }
                : undefined,
    }
    return pastCloseDates
}

export function getMyOpenOppsStack() {
    const myOpenOpps: StackInput = {
        sobject: 'Opportunity',
        title: 'My Opportunities',
        color: '#E4FDF0', //'green-50',
        // order: 2,
        visibleColumnMap: toSortingMap([
            'Account.Name',
            'Name',
            'StageName',
            'NextStep',
            'NextActivity.Subject',
            'NextActivity.Date',
            'CloseDate',
            'Amount',
            'OwnerId',
            'IsClosed',
        ]),
        filterConditions: toConditionMap([iAmTheOwner, openOpportunity]),
    }
    return myOpenOpps
}

export function getLaserfocusMethodStack(type?: StackType) {
    const rawConditions = [iAmTheOwner, openOpportunity, noNextTask]

    const conditions =
        type === 'preset'
            ? rawConditions.map(
                  (cond: FilterCondition): FilterCondition => ({
                      ...cond,
                      isLocked: true,
                  })
              )
            : rawConditions

    const laserFocusMethod: StackInput = {
        sobject: 'Opportunity',
        title: 'Laserfocus Method',
        icon: '🧿',
        color: '#EBF1FE', //'blue-50',
        description: 'You have Opportunities without the next task.',
        visibleColumnMap: toSortingMap([
            'Account.Name',
            'Name',
            'NextActivity.Subject',
            'NextActivity.Date',
            'OwnerId',
            'IsClosed',
        ]),
        filterConditions: toConditionMap(conditions),
        lockedColumnNames:
            type === 'preset' ? ['NextActivity.Subject', 'NextActivity.Date'] : undefined,
        preset: type ? 'laserfocus-method' : undefined,
        timing:
            type === 'preset'
                ? {
                      unit: 'week',
                      interval: 1,
                      daysOfTheWeek: [4],
                  }
                : undefined,
    }
    return laserFocusMethod
}

export function getMyOpportunityStack() {
    const myOpenOpps: StackInput = {
        sobject: 'Opportunity',
        title: 'My Open Opportunities',
        color: '#E4FDF0', //'green-50',
        // order: 2,
        visibleColumnMap: toSortingMap([
            'Account.Name',
            'Name',
            'StageName',
            'NextStep',
            'NextActivity.Subject',
            'NextActivity.Date',
            'CloseDate',
            'OwnerId',
        ]),
        filterConditions: toConditionMap([iAmTheOwner]),
    }
    return myOpenOpps
}

export function getPipelineReview() {
    const myOpenOpps: StackInput = {
        sobject: 'Opportunity',
        icon: '🔎',
        title: 'Pipeline Review',
        preset: 'pipeline-review',
        color: '#FFF7E8',

        visibleColumnMap: {
            'Account.Name': 'a0',
            'NextActivity.Date': 'a5',
            'NextActivity.Subject': 'a4',
            StageName: 'a2',
            CloseDate: 'a3',
            Name: 'a1',
        },
        sorting: {
            fieldName: 'CloseDate',
            direction: 'ASC',
        },
        timing: {
            dayOfTheMonth: 1,
            unit: 'month',
            interval: 1,
        },
        description: 'For your 1:1 Pipeline Review',
        filterConditions: toConditionMap([iAmTheOwner, openOpportunity]),
        // helpUrl: 'https://laserfocus.notion.site/Pipeline-Review-9c236f7852d4490d8ee3a4fda2890a27',
    }
    return myOpenOpps
}

export function getMyQuarterForecast() {
    const stack: StackInput = {
        sobject: 'Opportunity',
        title: 'My Forecast',
        icon: '🔮',
        color: '#EBE2F6', //'blue-50',
        description: 'What is your forecast for this quarter?',
        // helpUrl: 'https://laserfocus.notion.site/My-Forecast-d6c522a4b8f541dd8ea970b6ce39a015',
        visibleColumnMap: {
            StageName: 'a3',
            Probability: 'a4',
            'Account.Name': 'a0',
            ForecastCategoryName: 'a2',
            CloseDate: 'a6',
            Name: 'a1',
            NextStep: 'a5',
        },
        filterConditions: toConditionMap([iAmTheOwner, closingThigsQuarter, openOpportunity]),
        preset: 'quarter-forecast',
        sorting: {
            fieldName: 'CloseDate',
            direction: 'ASC',
        },
        columnWidths: {
            'NextActivity.Date': 204,
            StageName: 278,
            Probability: 224,
            'NextActivity.Subject': 248,
            ForecastCategoryName: 252,
            NextStep: 198,
            Name: 271,
        },
        columnAggregates: {
            Probability: 'average',
        },
    }
    return stack
}

export function getFreshLeads() {
    const stack: StackInput = {
        sobject: 'Lead',
        title: 'Fresh Leads',
        icon: '🍀',
        filterConditions: toConditionMap([iAmTheOwner, notConvertedLead, createdSinceLastWeek]),
        visibleColumnMap: {
            Status: 'a1',
            'NextActivity.Date': 'a5',
            Company: 'a0',
            Description: 'a2',
            CreatedDate: 'a6',
            Website: 'a3',
            'NextActivity.Subject': 'a4',
        },

        color: '#E4FDF0',
        description: 'These leads were created in the last week.',
        preset: 'leads-since-lastweek',
        columnAggregates: {},
        columnWidths: {},
        // helpUrl: 'https://laserfocus.notion.site/Fresh-Leads-67d5ed2d9ae941a69442bf6312a99f21',
    }
    return stack
}

export function getStalledAccounts() {
    const stack: StackInput = {
        sobject: 'Account',
        title: 'Stalled Accounts',
        icon: '🛌',
        filterConditions: toConditionMap([iAmTheOwner, noActivitySinceLastMonth, noNextTask]),
        visibleColumnMap: {
            LastActivityDate: 'a6',
            'NextActivity.Date': 'a3',
            'NextActivity.Subject': 'a2',
            OwnerId: 'a5',
            Name: 'a0',
        },

        color: '#F6F6F6',
        description: 'Did these Accounts fell asleep?',
        preset: 'stalled-accounts',
        columnAggregates: {},
        columnWidths: {},
        // helpUrl: 'https://laserfocus.notion.site/Stalled-Accounts-c0680269205541a78bdb8f02f1192ff1',
    }
    return stack
}

export function getMyLeadsStack() {
    const allMyLeads: StackInput = {
        sobject: 'Lead',
        title: 'All my Leads',
        color: '#F6F6F6', //'grey-50',

        // order: 3,
        visibleColumnMap: toSortingMap([
            'Company',
            'Status',
            'Website',
            'NextActivity.Subject',
            'NextActivity.Date',
            'CreatedDate',
            'OwnerId',
            'IsConverted',
        ]),
        filterConditions: toConditionMap([
            iAmTheOwner,
            {
                fieldName: 'IsConverted',
                fieldType: 'boolean',
                operator: 'EQ',
                values: [false],
            },
        ]),
    }
    return allMyLeads
}

export function getMyAccountsStack(): StackInput {
    const myOpenAccounts: StackInput = {
        sobject: 'Account',
        title: 'My Accounts',
        color: 'rgba(235,226,246)', //'purple-50',
        // order: 4,
        visibleColumnMap: toSortingMap([
            'Name',
            'Website',
            'NextActivity.Subject',
            'NextActivity.Date',
            'CreatedDate',
            'OwnerId',
        ]),
        filterConditions: toConditionMap([iAmTheOwner]),
        columnAggregates: {},
    }
    return myOpenAccounts
}

export function getMyContactsStack() {
    const allMyLeads: StackInput = {
        sobject: 'Contact',
        title: 'All my Contacts',
        color: '#F6F6F6', //'grey-50',
        // order: 3,
        visibleColumnMap: toSortingMap([
            'Account.Name',
            'FirstName',
            'LastName',
            'Email',
            'Phone',
            'NextActivity.Subject',
            'NextActivity.Date',
            'CreatedDate',
            'OwnerId',
        ]),
        filterConditions: toConditionMap([iAmTheOwner]),
    }
    return allMyLeads
}

export function getPipelineChartStack(id: string) {
    throw new Error('Needs to fix')
    // const next4Months: SFDateConstantValue = 'NEXT_4_MONTHS'
    // const dateCondition: FilterCondition = {
    //     fieldName: 'CloseDate',
    //     fieldType: 'date',
    //     operator: 'EQ',
    //     values: [SFDateConstants.THIS_QUARTER],
    // }
    // const myOpenOpps: StackInput = {
    //     id: id,
    //     sobject: 'Opportunity',
    //     title: 'Current Pipeline',
    //     color: '#E4FDF0', //'green-50',
    //     // order: 2,
    //     isChart: true,
    //     visibleColumnNames: [
    //         'Account.Name',
    //         'Name',
    //         'StageName',
    //         'Amount',
    //         'CloseDate',
    //         'LastActivityDate',
    //     ],
    //     filterConditions: toConditionMap([iAmTheOwner, dateCondition]),
    // }
    // return myOpenOpps
}

function toConditionMap(conditions: FilterCondition[]): Record<string, FilterCondition> {
    return Object.fromEntries(conditions.map((c) => [c.fieldName, c]))
}
function toSortingMap(list: string[]) {
    return Object.fromEntries(zip(list, generateNKeysBetween(null, null, list.length)))
}

type StackTemplate = StackInput & { preset: string }

export function getStackTemplates(): StackTemplate[] {
    return [
        getMyQuarterForecast(),
        getFreshLeads(),
        getStalledAccounts(),
        getPipelineReview(),
        getLaserfocusMethodStack('template'),
        getPastCloseDatesStack('template'),
    ] as StackTemplate[]
}

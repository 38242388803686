import { createContext, useState, useContext } from 'react'

type ReportsContextType = {
    selectedUsers: string[]
    setSelectedUsers: (userIds: string[]) => void
}

const ReportsContext = createContext<ReportsContextType>({
    selectedUsers: [],
    setSelectedUsers: () => {},
})

export function ReportsContextProvider({ children }: { children: React.ReactChild }) {
    const [selectedUsers, setSelectedUsers] = useState<string[]>([])

    return (
        <ReportsContext.Provider value={{ selectedUsers, setSelectedUsers }}>
            {children}
        </ReportsContext.Provider>
    )
}

export function useReportsContext() {
    return useContext(ReportsContext)
}

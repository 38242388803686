import { useState } from 'react'
import clsx from 'clsx'

import { Button, Select } from '@laserfocus/ui/beam'
import {
    Delete2OutlinedIcon,
    EditOutlinedIcon,
    MoreOutlinedIcon,
    MultiSelectOutlinedIcon,
    ReorderOutlinedIcon,
    ExternalLinkOutlinedIcon,
    HiddenOutlinedIcon,
    VisibleOutlinedIcon,
} from '@laserfocus/ui/icons'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'
import { FieldGroup, isTruthy } from '@laserfocus/shared/models'

import { FieldsSelect } from './FieldsSelect'
import { FieldGroupsReorderModal } from './FieldGroupsReorderModal'
import { ShareFieldGroupModal } from './ShareFieldGroupModal'

export function FieldSelection({
    fieldGroup,
    isInOverlay,
    removeField,
    addField,
}: {
    fieldGroup: FieldGroup
    isInOverlay: boolean
    removeField(fieldName: string): Promise<unknown>
    addField(fieldName: string): Promise<unknown>
}) {
    const [openDropdown, setOpenDropdown] = useState(false)

    return (
        <div className="p-2 grid grid-flow-col gap-2 justify-end">
            <EverboardingHint
                name="everboarding_persondetails_customize_fields"
                label="Show more Salesforce fields in your layout"
                placement="top-end"
                popperClassName={isInOverlay ? 'z-30' : ''}
                containerClassName="translate-x-12"
                labelClassName={clsx(
                    'max-w-[200px]  whitespace-normal',
                    isInOverlay ? '-translate-y-8 rotate-12 -translate-x-2' : 'rotate-3'
                )}
                arrowClassName={clsx(
                    isInOverlay
                        ? 'scale-[1.3] -rotate-45 translate-x-24 -translate-y-4'
                        : 'translate-x-24 translate-y-2 -rotate-[60deg]'
                )}
            >
                <FieldsSelect
                    isOpen={openDropdown}
                    fieldGroup={fieldGroup}
                    onCancel={() => setOpenDropdown(false)}
                    removeField={removeField}
                    addField={addField}
                >
                    <Button
                        variant="tertiary"
                        size="small"
                        iconComponent={MultiSelectOutlinedIcon}
                        onClick={() => setOpenDropdown((v) => !v)}
                    >
                        Show fields
                    </Button>
                </FieldsSelect>
            </EverboardingHint>
        </div>
    )
}

export function FieldGroupMoreActions({
    fieldGroup,
    fieldGroups,
    startRenaming,
    setIsOpen,
    isOpen,
    orgOrUser,
    deleteFieldGroup,
    resetFieldGroup,
    updateFieldGroupsOrder,
    activateFieldGroup,
    deactivateFieldGroup,
}: {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    fieldGroup: FieldGroup
    fieldGroups: FieldGroup[]
    startRenaming(): void
    orgOrUser: 'user' | 'org'
    deleteFieldGroup(): Promise<unknown>
    resetFieldGroup?(): Promise<unknown>
    updateFieldGroupsOrder(reorders: Record<string, string>): Promise<unknown>
    activateFieldGroup?(): Promise<unknown>
    deactivateFieldGroup?(): Promise<unknown>
}) {
    const [isReorderingModalOpen, setIsReorderingModalOpen] = useState(false)
    const [isShareModalOpen, setIsShareModalOpen] = useState(false)
    return (
        <>
            <Select
                isOpen={isOpen}
                onCancel={() => setIsOpen(false)}
                options={[
                    {
                        iconComponent: EditOutlinedIcon,
                        label: 'Rename',
                        value: 'rename',
                        action: startRenaming,
                    },
                    {
                        iconComponent: ReorderOutlinedIcon,
                        label: 'Reorder',
                        value: 'reorder',
                        action: () => setIsReorderingModalOpen(true),
                    },
                    orgOrUser === 'user' && {
                        label: 'Share',
                        value: 'share',
                        iconComponent: ExternalLinkOutlinedIcon,
                        action: () => setIsShareModalOpen(true),
                    },

                    orgOrUser === 'org' &&
                        fieldGroup.deactivated &&
                        activateFieldGroup && {
                            iconComponent: VisibleOutlinedIcon,
                            label: 'Activate',
                            value: 'activate',
                            action: activateFieldGroup,
                        },
                    orgOrUser === 'org' &&
                        !fieldGroup.deactivated &&
                        deactivateFieldGroup && {
                            iconComponent: HiddenOutlinedIcon,
                            label: 'Deactivate',
                            value: 'deactivate',
                            className: 'text-red-500',
                            action: deactivateFieldGroup,
                        },
                    canDelete(fieldGroup, orgOrUser) && {
                        iconComponent: Delete2OutlinedIcon,
                        label: 'Delete',
                        value: 'delete',
                        className: 'text-red-500',
                        action: deleteFieldGroup,
                    },

                    fieldGroup.isExtended &&
                        resetFieldGroup &&
                        !fieldGroup.resetToDelete && {
                            iconComponent: Delete2OutlinedIcon,
                            label: 'Reset',
                            value: 'reset',
                            className: 'text-red-500',
                            action: resetFieldGroup,
                        },
                ].filter(isTruthy)}
                onSubmit={({ action }) => {
                    setIsOpen(false)
                    action()
                }}
            >
                <Button
                    title="More"
                    variant="tertiary"
                    size="small"
                    iconComponent={MoreOutlinedIcon}
                    onClick={() => setIsOpen((v) => !v)}
                />
            </Select>
            <FieldGroupsReorderModal
                show={isReorderingModalOpen}
                close={setIsReorderingModalOpen}
                fieldGroups={fieldGroups}
                updateFieldGroupsOrder={updateFieldGroupsOrder}
            />
            {orgOrUser === 'user' && (
                <ShareFieldGroupModal
                    show={isShareModalOpen}
                    closeModal={() => setIsShareModalOpen(false)}
                    fieldGroup={fieldGroup}
                />
            )}
        </>
    )
}

export function canDelete(fieldGroup: FieldGroup, orgOrUser: 'user' | 'org') {
    if (orgOrUser === 'org') {
        return fieldGroup.deactivated
    }

    if (fieldGroup.orgDeactivated) {
        return true
    }
    if (fieldGroup.resetToDelete) {
        return true
    }
    if (fieldGroup.__typename === 'OrgFieldGroup') {
        return false
    }
    return !fieldGroup.isExtended
}

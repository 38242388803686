import { useCallback, useEffect } from 'react'

import { countActivity } from '@laserfocus/client/data-access-shared'
import { EverboardingTrackName, CountedMutationName } from '@laserfocus/shared/models'

import { useEverboardingContext } from './EverboardingContext'

export function useConditionalTrackTrigger(
    name: EverboardingTrackName,
    condition: (counters: Partial<Record<CountedMutationName, number>>) => boolean
) {
    const { counters, didLoad } = useEverboardingContext()
    const trigger = useCallback(() => {
        if (didLoad && condition(counters)) {
            countActivity(name)
        }
    }, [condition, counters, didLoad, name])
    return trigger
}

export function useConditionalTrack(
    name: EverboardingTrackName,
    condition: (counters: Partial<Record<CountedMutationName, number>>) => boolean
) {
    const { didLoad } = useEverboardingContext()
    const trigger = useConditionalTrackTrigger(name, condition)
    useEffect(() => {
        trigger()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, didLoad])
}

export function useConditionalTrackUntilCount(name: EverboardingTrackName, amount: number = 30) {
    return useConditionalTrack(name, (counters) => {
        const currentCount = counters?.[name] || 0
        return currentCount < amount
    })
}

import { useState } from 'react'

import { Button } from '@laserfocus/ui/beam'
import { AddOutlinedIcon } from '@laserfocus/ui/icons'
import { AddTaskModal } from '@laserfocus/client/shared-step-modal'

type AddTaskButtonProps = {
    label?: string
    className?: string
}

export function AddTaskButton({ label, className }: AddTaskButtonProps) {
    const [isOpen, setIsOpen] = useState(false)

    const closeModal = () => setIsOpen(false)

    return (
        <>
            <Button
                title="Add new Task"
                iconComponent={AddOutlinedIcon}
                onClick={() => setIsOpen(true)}
                data-focuskey="AddTask"
                className={className}
            >
                {label || 'Add Task'}
            </Button>
            {isOpen && <AddTaskModal closeModal={closeModal} />}
        </>
    )
}

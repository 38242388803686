import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Spinner } from '../spinner/Spinner'

interface CommandLineInputProps extends React.ComponentPropsWithoutRef<'input'> {
    isLoading?: boolean
}

export const CommandLineInput = forwardRef<HTMLInputElement, CommandLineInputProps>(
    function CommandLineInput({ isLoading, ...props }, ref) {
        return (
            <div className="flex items-center" aria-busy={isLoading}>
                <input
                    ref={ref}
                    onKeyDown={(event) => {
                        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                            // Necessary to prevent cursor jumping to beginning or end while navigating through options
                            event.preventDefault()
                        }
                    }}
                    {...props}
                    className={twMerge(
                        'block w-full text-xl leading-[1.15] outline-none p-4 placeholder-white/40 bg-transparent',
                        props.className
                    )}
                />
                {isLoading && (
                    <Spinner
                        size={20}
                        strokeWidth={7}
                        backgroundStrokeColor="blue-500/40"
                        className="flex-none w-[unset] h-[unset] pr-4"
                    />
                )}
            </div>
        )
    }
)

import { TableFooterContet } from '@laserfocus/client/feature-table'

export function TableFooter() {
    return (
        <div
            role="rowgroup"
            className="min-w-full z-15 select-none w-max border-t border-grey-700/10 bg-white"
        >
            <TableFooterContet isBulkEditActive={false} hideBulkEdit />
        </div>
    )
}

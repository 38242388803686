import { useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { UseDatepickerResult } from '@laserfocus/vendor/react-datepicker-hooks'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import {
    FilterDateSuggest,
    RelativeSuggest,
    isRelative,
    createDateSuggest,
    getDefaultDateSuggestions,
} from '@laserfocus/client/util-date'

import { DropdownInput } from '../controls/DropdownInput'
import { DropdownButton } from '../controls/DropdownButton'

interface DatepickerSelectProps extends Pick<UseDatepickerResult, 'onTimeSelect'> {
    className?: string
    focusOnMount?: boolean
    dateOnly: boolean | undefined
    isTimeSelected(date: Date | null): boolean
    onDateSubmit(date: Date | null): void
    allowDateRanges: boolean | undefined
    onRangeSelect?(range: RelativeSuggest): void
    onRangeSubmit?(value: string): void
    isSelectedRange?(value: string): boolean
    allowEmpty?: boolean
}

export function DatepickerSelect({
    className,
    dateOnly,
    focusOnMount,
    isTimeSelected,
    onTimeSelect,
    onDateSubmit,

    onRangeSelect,
    onRangeSubmit,
    isSelectedRange,
    allowDateRanges,
    allowEmpty,
}: DatepickerSelectProps) {
    const suggest = useMemo(
        () => createDateSuggest({ allowDateRanges, allowEmpty }),
        [allowDateRanges, allowEmpty]
    )

    // const suggest = allowDateRanges ? filterDateSuggest : suggestDate
    const [{ inputValue, dateSuggestions, lastSelectedSuggestion }, setSelectOptions] = useState(
        () => ({
            inputValue: '',
            dateSuggestions: getDefaultDateSuggestions(
                dateOnly,
                // Hack to check if the current value is empty
                !isTimeSelected(null) && allowEmpty
            ) as FilterDateSuggest[],
            lastSelectedSuggestion: null as FilterDateSuggest | null,
        })
    )
    const inputRef = useAutoFocusedRef<HTMLInputElement>(focusOnMount)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newInputValue = event.target.value
        const newDateSuggestions = newInputValue
            ? suggest(newInputValue)
            : getDefaultDateSuggestions(dateOnly, !isTimeSelected(null) && allowEmpty)

        // `getDefaultDateSuggestions()` and `suggestDate()` return unstable results. We need to `useMemo()` or store dateSuggestions in state in order to get a stable result while user navigates through list with arrow keys.
        setSelectOptions({
            inputValue: newInputValue,
            dateSuggestions: newDateSuggestions,
            lastSelectedSuggestion: newDateSuggestions[0] || null,
        })

        if (newDateSuggestions[0]) {
            if (isRelative(newDateSuggestions[0])) {
                onRangeSelect && onRangeSelect(newDateSuggestions[0])
            } else {
                onTimeSelect(newDateSuggestions[0].date)
            }
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            const currentSelectedIndex = getSelectedSuggestionIndex(
                dateSuggestions,
                lastSelectedSuggestion,
                isTimeSelected,
                isSelectedRange
            )
            const offset = event.key === 'ArrowDown' ? 1 : -1
            // Fixing incorrect index when going back and no preselected option
            const correctSelectedIndex =
                currentSelectedIndex === -1 && offset < 0 ? 0 : currentSelectedIndex
            const nextIndex =
                (correctSelectedIndex + offset + dateSuggestions.length) % dateSuggestions.length
            const nextSuggestion = dateSuggestions[nextIndex]

            setSelectOptions((previous) => ({
                ...previous,
                lastSelectedSuggestion: nextSuggestion ?? null,
            }))

            if (isRelative(nextSuggestion)) {
                onRangeSelect && onRangeSelect(nextSuggestion)
            } else {
                onTimeSelect(nextSuggestion?.date ?? null)
            }
        }
    }

    const selectedSuggestionIndex = getSelectedSuggestionIndex(
        dateSuggestions,
        lastSelectedSuggestion,
        isTimeSelected,
        isSelectedRange
    )

    return (
        <div className={twMerge('w-[10.625rem]', className)}>
            <DropdownInput
                ref={inputRef}
                aria-label={dateOnly ? 'Date' : 'Date and time'}
                placeholder={dateOnly ? 'Try tmr, in 3d, mar 13' : 'Try 9am, in 3d, mar 13'}
                value={inputValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            <div className="grid gap-1 py-2">
                {dateSuggestions.map((suggestion, index) => {
                    const { formatted } = suggestion
                    function onClick() {
                        if (isRelative(suggestion)) {
                            onRangeSubmit?.(suggestion.value)
                        } else {
                            onDateSubmit(suggestion.date)
                        }
                    }
                    return (
                        <DropdownButton
                            key={formatted}
                            isHighlighted={index === selectedSuggestionIndex}
                            onClick={onClick}
                        >
                            {formatted}
                        </DropdownButton>
                    )
                })}
            </div>
        </div>
    )
}

function getSelectedSuggestionIndex(
    suggestions: FilterDateSuggest[],
    lastSelectedSuggestion: FilterDateSuggest | null,
    isTimeSelected: (date: Date | null) => boolean,
    isRangeSelected?: (value: string) => boolean
) {
    const selectables = suggestions
        .map((suggestion, index) => ({ suggestion, index }))
        .filter(({ suggestion }) => {
            if (isRelative(suggestion)) {
                return isRangeSelected?.(suggestion.value)
            }
            return isTimeSelected(suggestion.date)
        })

    if (selectables.length === 0) {
        return -1
    }

    if (selectables.length === 1) {
        return selectables[0]?.index
    }

    if (lastSelectedSuggestion) {
        const matchingSelectable = selectables.find(
            ({ suggestion }) => suggestion.formatted === lastSelectedSuggestion.formatted
        )

        if (matchingSelectable) {
            return matchingSelectable.index
        }
    }

    return selectables[0].index
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

/** 16px version */
export function ItalicIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 32 32" width={16} height={16} {...props}>
            <path d="M25 9L25 7 12 7 12 9 17.14 9 12.77 23 7 23 7 25 20 25 20 23 14.86 23 19.23 9 25 9z" />
        </SvgIcon>
    )
}

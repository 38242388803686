import { useEffect } from 'react'
import { deleteAllReplicacheData } from 'replicache'
import { useNavigate } from 'react-router'

import { Spinner } from '@laserfocus/ui/beam'
import { logger } from '@laserfocus/ui/logger'

export function ResetPage() {
    const navigate = useNavigate()

    useEffect(() => {
        async function reset() {
            const { dropped, errors } = await deleteAllReplicacheData()
            if (errors && errors.length) {
                errors.forEach((err) => logger.error(err))
            }
            logger.info('Successfully dropped', dropped)
            window.location.replace('/')
        }
        reset()
    }, [navigate])
    return <Spinner fullscreen />
}

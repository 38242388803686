import { useController, Path } from 'react-hook-form'

import { Switch } from '@laserfocus/ui/beam'

import { FormControlProps, SalesObject } from '../get-form-control'

import { getControllerRules } from './formcontrol-util'

export function FormControlBoolean<T extends SalesObject = SalesObject>({
    fieldMetadata,
    id,
    control,
    submit,
    name,
}: FormControlProps<T>) {
    const {
        field: { value, onChange, ...field },
        fieldState: { error },
    } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })

    return (
        <Switch
            id={id}
            checked={value as boolean}
            onChange={(event) => {
                onChange(event.target.checked)
                submit?.()
            }}
            disabled={!fieldMetadata.updateable}
            error={(error as any)?.message}
            className="pl-0"
            {...field}
        />
    )
}

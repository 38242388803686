import { action, computed, extendObservable, reaction, runInAction } from 'mobx'
import { omit, sortBy } from 'lodash'

import { ModelExtension, ObjectPool } from '@laserfocus/client/data-layer'
import type {
    AccountFields,
    AccountProps,
    DateTimeString,
    SnapshotListener,
    SnapShotter,
} from '@laserfocus/shared/models'

import { TaskModel } from '../activity/TaskModel'
import { EventModel } from '../activity/EventModel'
import { getLastActivityDate, getNextActivity } from '../activity/activity-util'

const AccountPropKeys = new Set<string>()

export class AccountModel implements AccountFields, ModelExtension {
    objectPool: ObjectPool
    __typename = 'Account' as const
    Id: string

    Name?: string
    RecordTypeId?: string
    Phone?: string
    Website?: string
    BillingStreet?: string
    BillingState?: string
    BillingPostalCode?: string
    BillingCity?: string
    BillingCountry?: string
    BillingGeocodeAccuracy?: string
    BillingLatitude?: number
    BillingLongitude?: number
    ShippingStreet?: string
    ShippingState?: string
    ShippingPostalCode?: string
    ShippingCity?: string
    ShippingCountry?: string
    ShippingGeocodeAccuracy?: string
    ShippingLatitude?: number
    ShippingLongitude?: number
    CurrencyIsoCode?: string
    OwnerId?: string

    constructor(props: AccountProps, objectPool: ObjectPool) {
        this.objectPool = objectPool

        extendObservable(this, omit(props, 'LastActivityDate'))
        this._LastActivityDate = props.LastActivityDate
        this.Id = props.Id
        const keys = Object.keys(props) as Array<keyof AccountProps>
        keys.forEach((key) => AccountPropKeys.add(key))
    }

    @computed get props(): AccountProps {
        const keys = Array.from(AccountPropKeys.values())
        const props = Object.fromEntries(keys.map((key) => [key, this[key as keyof AccountModel]]))
        return {
            Id: this.Id,
            __typename: 'Account',
            ...props,
        }
    }

    /**
     * ------------------ Snapshot API --------------------
     */

    _previousSnapshot: Map<number, any> = new Map()
    _snapshotCounter = 0

    @action
    onSnapshot<Snapshot>(
        makeSnapshot: SnapShotter<AccountModel, Snapshot>,
        listener: SnapshotListener<Snapshot>,
        options?: {
            fireImmediately?: boolean
        }
    ): () => void {
        const index = this._snapshotCounter++
        const previousSnapshot = makeSnapshot(this as any)
        this._previousSnapshot.set(index, previousSnapshot)
        const discard = reaction(
            () => makeSnapshot(this as any),
            (current) => {
                listener(this._previousSnapshot.get(index), current)
                runInAction(() => {
                    this._previousSnapshot.set(index, current)
                })
            },
            options
        )
        return () => {
            this._previousSnapshot.delete(index)
            discard()
        }
    }

    /**
     * ------------------ Activity API --------------------
     */
    _LastActivityDate?: DateTimeString | undefined

    @computed
    get Activities(): Array<TaskModel | EventModel> {
        const accountAtivities = this.objectPool.activitiesByAccount[this.Id] || {}
        return Object.values(accountAtivities) as Array<TaskModel | EventModel>
    }

    @computed
    get OpenActivities() {
        const openActivitiees = this.Activities.filter((a) => a.isOpen)
        return sortBy(openActivitiees, 'OpenDate', 'CreatedDate').reverse()
    }

    @computed
    get ActivityHistory() {
        const activityHistory = this.Activities.filter((a) => !a.isOpen)
        return sortBy(activityHistory, 'ClosedDate', 'CreatedDate').reverse()
    }

    @computed
    get NextActivity() {
        return getNextActivity(this)
    }

    @computed
    get LastActivity() {
        return this.ActivityHistory[0]
    }

    @computed
    get LastActivityDate() {
        return getLastActivityDate(this)
    }

    set LastActivityDate(d) {
        this._LastActivityDate = d
    }

    /**
     * ------------------ Relationships --------------------
     */
}

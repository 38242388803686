import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function CalendarFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M8 4a1 1 0 00-1 1v1H6a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-1V5a1 1 0 00-2 0v1H9V5a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 000-2H8z"
            />
        </SvgIcon>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function VisibleOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M9.88 9.88a3 3 0 114.24 4.24 3 3 0 01-4.24-4.24zM12 11a1 1 0 100 2 1 1 0 000-2z" />
            <path d="M3.16 12c2.5 4.12 5.45 6 8.84 6 3.39 0 6.34-1.87 8.84-6-2.5-4.13-5.45-6-8.84-6-3.39 0-6.34 1.87-8.84 6zm-2.03-.5C3.91 6.64 7.52 4 12 4c4.48 0 8.09 2.65 10.87 7.5a1 1 0 010 1C20.09 17.34 16.48 20 12 20c-4.48 0-8.1-2.65-10.87-7.5a1 1 0 010-1z" />
        </SvgIcon>
    )
}

import { formatISO, parseISO } from 'date-fns'

import { DateString, DateStringSchema, DateTimeString } from './date.types'

export function dateToDateString(d: Date): DateString {
    return DateStringSchema.parse(formatISO(d, { representation: 'date' }))
}

export function isDateTimeOnDate(dateTime: DateTimeString, date: DateString) {
    const dateTimeAsDate = formatISO(parseISO(dateTime), { representation: 'date' })
    return date === dateTimeAsDate
}

export function dateHasTime(d: Date) {
    const noTime =
        d.getHours() === 0 &&
        d.getMinutes() === 0 &&
        d.getSeconds() === 0 &&
        d.getMilliseconds() === 0
    return !noTime
}

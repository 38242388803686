import { Link } from 'react-router-dom'

import { Button } from '@laserfocus/ui/beam'
import { Analytics } from '@laserfocus/client/util-analytics'

export interface Breadcrumb {
    title: string
    path?: string
    onClick?: () => void
}
interface BreadCrumbsProps {
    childBreadCrumbs?: readonly Breadcrumb[]
}

export function BreadCrumbs({ childBreadCrumbs }: BreadCrumbsProps) {
    const allBreadcrumbs: Breadcrumb[] = [
        {
            title: 'Overview',
            path: '/home',
            onClick: () =>
                Analytics.trackEvent({
                    event: 'breadcrumb_clicked',
                    level: 'home',
                    location: Analytics.getLocation() as any,
                }),
        },
        ...(childBreadCrumbs || []),
    ].filter((f) => f.title)
    return (
        <div className="font-medium whitespace-nowrap overflow-hidden text-ellipsis">
            {allBreadcrumbs
                .map((bc) => (
                    <BreadCrumb
                        key={bc.title}
                        path={bc.path}
                        onClick={bc.onClick}
                        title={bc.title}
                    />
                ))
                .reduce<JSX.Element[]>((prev, curr) => {
                    if (prev.length === 0) {
                        return [curr]
                    }
                    return [...prev, <Seperator key={`${curr.key}Seperator`} />, curr]
                }, [])}
        </div>
    )
}

interface BreadCrumbProps {
    path?: string
    title: string
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLSpanElement>
}

function BreadCrumb({ path, onClick, title }: BreadCrumbProps) {
    if (path) {
        return (
            <Button
                as={Link}
                variant="tertiary"
                inline
                className="text-current font-medium"
                to={path}
                onClick={onClick}
            >
                {title}
            </Button>
        )
    }
    return (
        <span key={title} className="mx-2">
            {title}
        </span>
    )
}

function Seperator() {
    return <span className="opacity-40 mx-1">/</span>
}

import React from 'react'

import { LaserfocusLogo } from '@laserfocus/ui/icons'

interface OnboardingLayoutProps {
    children: React.ReactNode
    headerButton?: React.ReactNode
    showSignupNotice?: boolean
}

export function OnboardingLayout({
    children,
    headerButton,
    showSignupNotice,
}: OnboardingLayoutProps) {
    return (
        <div className="min-h-screen grid grid-rows-[auto,minmax(0,1fr),auto] place-items-center px-8">
            <nav className="w-full grid grid-flow-col justify-between h-24 items-center">
                <div className="grid grid-flow-col gap-2 items-center font-bold font-serif">
                    <LaserfocusLogo className="w-6 h-6" />
                    laserfocus
                </div>
                {headerButton}
            </nav>
            {children}
            {showSignupNotice && (
                <footer className="w-full pb-7 flex justify-center  text-grey-700/60">
                    <div>
                        By signing up, your confirm that you have read and accepted our{' '}
                        <a
                            href="https://www.laserfocus.io/terms-of-service"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline outline-none focus-visible:ring rounded"
                        >
                            Terms of service
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://laserfocus.io/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline outline-none focus-visible:ring rounded"
                        >
                            Privacy Policy
                        </a>
                    </div>
                </footer>
            )}
        </div>
    )
}

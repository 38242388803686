import { observer } from 'mobx-react-lite'

import { LayoutPadding, LayoutPaddingVertical, NavBar, PageHeader } from '@laserfocus/client/layout'
import { wrapAuthenticated } from '@laserfocus/client/feature-auth'
import { TaskList } from '@laserfocus/client/ui-shared-task'
import { useTitle } from '@laserfocus/ui/util-react'
import { isContact, isOpportunity, NewActivity, Stack, TaskId } from '@laserfocus/shared/models'
import { useOverlay, Overlay } from '@laserfocus/client/feature-overlay'
import { SearchInputButton } from '@laserfocus/client/ui-shared'
import { EventModel, TaskModel } from '@laserfocus/client/model'
import { Button } from '@laserfocus/ui/beam'

import { AddTaskButton } from '../TaskModal/AddTaskButton'
import { StackIconAvatar } from '../stack-overview/StackIconAvatar'

import { LoadingStack } from './LoadingStack'
import { EmptyStack } from './EmptyStack'
import { useTodayActivities } from './useActivityStack'
import { FilterButton, useFilter } from './FilterButton'
import { StackTasks } from './StackTasks'
import { useActivitystackEverboarding } from './useActivitystackEverboarding'

const TodayLayout = observer(function TodayLayout() {
    const {
        activities: todayActivities,
        loading,
        dismissTask,
        completeTask,
        todayStacks,
        searchTerm,
        setSearchTerm,
        totalCount,
    } = useTodayActivities()
    const { filteredActivities, ...filterButtonProps } = useFilter(todayActivities)

    useTitle(`Today${totalCount ? ` - ${totalCount}` : ''}`)

    const { overlayObject: overlayActivity, ...overlayBag } = useOverlay(
        filteredActivities,
        'selectedActivity'
    )
    useActivitystackEverboarding(todayActivities, Boolean(overlayActivity))

    return (
        <>
            <div className={overlayActivity ? 'w-[calc(100%-38rem)]' : 'w-full'}>
                <NavBar breadcrumbs={[{ title: 'Today' }]} bg="yellow" />
                <LayoutPaddingVertical maxWidth="stackWidth">
                    <PageHeader
                        title="Today"
                        icon={<StackIconAvatar icon="🗓" />}
                        actions={
                            <>
                                <SearchInputButton value={searchTerm} onChange={setSearchTerm} />
                                <FilterButton {...filterButtonProps} />
                                <AddTaskButton />
                            </>
                        }
                    />
                    <LayoutPadding top bottom>
                        <TodayPageContainer
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            todayStacks={todayStacks}
                            today={filteredActivities}
                            loading={loading}
                            overlayActivity={overlayActivity}
                            toggleOverlayForActivity={overlayBag.toggleOverlayForActivity}
                            completeTask={completeTask}
                            dismissTask={dismissTask}
                        />
                    </LayoutPadding>
                </LayoutPaddingVertical>
            </div>
            <Overlay
                {...overlayBag}
                overlayData={
                    overlayActivity
                        ? {
                              overlayKey: overlayActivity.Id,
                              rootId: overlayActivity.RootId ?? undefined,
                              contactId: isContact(overlayActivity.WhoId)
                                  ? overlayActivity.WhoId
                                  : undefined,
                              opportunityId: isOpportunity(overlayActivity.WhatId)
                                  ? overlayActivity.WhatId
                                  : undefined,
                              activityId: overlayActivity.Id,
                          }
                        : undefined
                }
            />
        </>
    )
})

function TodayPageWrapper() {
    return <TodayLayout />
}

const AuthenticatedTodayPage = wrapAuthenticated(TodayPageWrapper)
export default AuthenticatedTodayPage

interface TodayPageContainerProps {
    searchTerm?: string
    setSearchTerm: (searchTerm: string) => void
    todayStacks: Stack[]
    today: Array<TaskModel | EventModel>
    loading: boolean
    overlayActivity?: NewActivity
    toggleOverlayForActivity?(id: string): void
    completeTask: (id: TaskId) => Promise<void>
    dismissTask: (id: TaskId) => void
}

function TodayPageContainer({
    todayStacks,
    today,
    loading,
    overlayActivity,
    toggleOverlayForActivity,
    completeTask,
    dismissTask,
    searchTerm,
    setSearchTerm,
}: TodayPageContainerProps) {
    if (loading) {
        return <LoadingStack />
    }

    if (today.length === 0 && todayStacks.length === 0) {
        if (searchTerm) {
            return (
                <EmptyStack
                    heading="No tasks found"
                    description="Clear your search to see which other tasks are available"
                    button={<Button onClick={() => setSearchTerm('')}>Clear Search</Button>}
                />
            )
        }
        return (
            <EmptyStack
                heading="You have done all the work for today."
                description="Maybe you have other tasks on your mind:"
                button={<AddTaskButton label="Add Tasks" />}
            />
        )
    }

    return (
        <>
            <StackTasks
                stacks={todayStacks}
                className={today.length ? 'mb-2' : undefined}
                searchTerm={searchTerm}
            />
            <TaskList
                searchTerm={searchTerm}
                activities={today}
                pathPrefix="today"
                overlayActivity={overlayActivity}
                toggleOverlayForActivity={toggleOverlayForActivity}
                completeTask={completeTask}
                dismissTask={dismissTask}
            />
        </>
    )
}

import { Link } from 'react-router-dom'

import { Analytics } from '@laserfocus/client/util-analytics'
import { Button, ButtonGroup } from '@laserfocus/ui/beam'
import {
    ArrowDownOutlinedIcon,
    ArrowUpOutlinedIcon,
    CloseOutlinedIcon,
    OpenOutlinedIcon,
} from '@laserfocus/ui/icons'
import { makePersonDetailsPath, makeStackPersonPath } from '@laserfocus/client/util-routing'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'

import { OverlayData } from './Overlay'

interface OverlayNavbarProps {
    pathPrefix?: 'today' | 'overdue' | 'upcoming'
    stackSlug?: string
    overlayData: OverlayData
    closeOverlay(): void
    goToPreviousObject(): void
    goToNextObject(): void
}

export function OverlayNavbar({
    pathPrefix,
    stackSlug,
    overlayData,
    closeOverlay,
    goToPreviousObject,
    goToNextObject,
}: OverlayNavbarProps) {
    const { rootId, contactId, opportunityId, activityId } = overlayData
    return (
        <div className="px-2 grid grid-flow-col justify-between">
            <div className="grid gap-2 grid-flow-col">
                <Button
                    iconComponent={CloseOutlinedIcon}
                    size="small"
                    title="Close (Esc)"
                    onClick={closeOverlay}
                />
                <ButtonGroup>
                    <Button
                        iconComponent={ArrowUpOutlinedIcon}
                        size="small"
                        title="Go to previous (↑)"
                        onClick={goToPreviousObject}
                    />
                    <Button
                        iconComponent={ArrowDownOutlinedIcon}
                        size="small"
                        title="Go to next (↓)"
                        onClick={goToNextObject}
                    />
                </ButtonGroup>
            </div>
            {rootId && (
                <div className="grid gap-2 grid-flow-col">
                    <EverboardingHint
                        name="everboarding_overlay_expand"
                        label="Expand to see details"
                        placement="left"
                        popperClassName={'z-30'}
                        labelClassName="rotate-6 translate-y-14"
                        arrowClassName="-translate-y-2 scale-y-[-1] rotate-12"
                        delay={500}
                    >
                        <Button
                            as={Link}
                            iconComponent={OpenOutlinedIcon}
                            size="small"
                            onClick={() => {
                                Analytics.trackEvent({
                                    event: 'overlay_expanded',
                                    location: stackSlug ? 'table_overlay' : 'list_overlay',
                                })
                            }}
                            to={
                                stackSlug
                                    ? makeStackPersonPath(stackSlug, rootId, {
                                          contactId,
                                          opportunityId,
                                          activityId,
                                      })
                                    : makePersonDetailsPath(
                                          rootId,
                                          { contactId, opportunityId, activityId },
                                          pathPrefix
                                      )
                            }
                        >
                            Expand
                        </Button>
                    </EverboardingHint>
                </div>
            )}
        </div>
    )
}

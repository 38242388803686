export const EVENT_FIELDS = `
Id
Subject
Description
StartDateTime
EndDateTime
ActivitySubtype
ReminderDateTime
Outcome
CreatedDate
LastModifiedDate
Phone
IsClosed
WhoId
AccountId
WhatId
OwnerId
Owner {
  Id
  Name
}
`

export const PERSON_EVENT_FRAGMENT = `
  fragment PersonEvent on Event {
    ${EVENT_FIELDS}
  }
`

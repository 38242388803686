import { observer } from 'mobx-react-lite'

import { TextCell as BaseTextCell } from '@laserfocus/client/ui-shared-datagrid'

import { TableCellProps } from './TableCell'

interface TextCellProps extends TableCellProps {
    multiline?: boolean
    allowLineBreaks?: boolean
}

export const TextCell = observer(function TextCell({
    salesObject,
    column,
    ...rest
}: TextCellProps) {
    return (
        <BaseTextCell
            name={column.key}
            updateValue={(value) => column.updateValue(salesObject, value)}
            value={(column.getValue(salesObject) as string | null) ?? ''}
            readOnly={!column.isEditable(salesObject)}
            {...rest}
        />
    )
})

import { endOfDay, isAfter, isBefore, isEqual, isValid, startOfDay } from 'date-fns'

export function isWithinDayRange(
    date: Date,
    minDate: Date | undefined,
    maxDate: Date | undefined
): boolean {
    const compareMinDate = minDate && startOfDay(minDate)
    const compareMaxDate = maxDate && endOfDay(maxDate)

    return (
        isValid(date) &&
        (!compareMinDate || isAfter(date, compareMinDate) || isEqual(date, compareMinDate)) &&
        (!compareMaxDate || isBefore(date, compareMaxDate) || isEqual(date, compareMaxDate))
    )
}

import { useState } from 'react'
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DraggableRubric,
} from 'react-beautiful-dnd'
import clsx from 'clsx'

import { Button, Modal, SpinnerInline } from '@laserfocus/ui/beam'
import { DragOutlinedIcon, ReorderOutlinedIcon } from '@laserfocus/ui/icons'
import { FieldGroup } from '@laserfocus/shared/models'
import { IS_SAFARI } from '@laserfocus/ui/util-constants'

import { useReorderedFieldGroups } from './useReorderFieldGroups'

interface FieldGroupsReorderModalProps extends Omit<FieldGroupsReorderModalInnerProps, 'close'> {
    show: boolean
    close(isOpen: boolean): void
}

export function FieldGroupsReorderModal({
    show,
    close,
    fieldGroups,
    updateFieldGroupsOrder,
}: FieldGroupsReorderModalProps) {
    return (
        <Modal show={show} onClose={close}>
            <FieldGroupsReorderModalInner
                fieldGroups={fieldGroups}
                close={() => close(false)}
                updateFieldGroupsOrder={updateFieldGroupsOrder}
            />
        </Modal>
    )
}

interface FieldGroupsReorderModalInnerProps {
    fieldGroups: FieldGroup[]
    close(): void
    updateFieldGroupsOrder(reorders: Record<string, string>): Promise<unknown>
}

function FieldGroupsReorderModalInner({
    close,
    fieldGroups: fieldGroupsFromProps,
    updateFieldGroupsOrder,
}: FieldGroupsReorderModalInnerProps) {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {
        sortedFieldGroups: fieldGroups,
        updatePosition,
        reorders,
    } = useReorderedFieldGroups(fieldGroupsFromProps)

    async function handleSubmit() {
        setIsSubmitting(true)
        if (Object.keys(reorders).length) {
            await updateFieldGroupsOrder(reorders)
        }
        setIsSubmitting(false)
        close()
    }

    function getDraggableRenderItem(
        draggableProvided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        rubric: DraggableRubric
    ) {
        const { index } = rubric.source
        const fieldGroup = fieldGroups[index]!

        const isBeingDragged = snapshot.isDragging && !snapshot.isDropAnimating
        const isFirst = index === 0
        const isLast = index === fieldGroups.length - 1

        return (
            <div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                className={clsx(
                    'grid grid-cols-[auto,minmax(0,1fr)] items-start outline-none focus-visible:ring focus-visible:ring-blue-500 gap-3 bg-white p-2 transition-[box-shadow,border-radius] focus-visible:z-10 relative ring-1 ring-grey-700/10',
                    isFirst && 'rounded-t-lg',
                    isLast && 'rounded-b-lg',
                    isBeingDragged && 'rounded-lg'
                )}
            >
                <DragOutlinedIcon className="w-4 h-4 text-grey-700/40 py-0.5 box-content" />
                <div className="text-sm font-medium">{fieldGroup.title}</div>
            </div>
        )
    }

    return (
        <>
            <Modal.Overlay />
            <Modal.Container variableHeight>
                <Modal.Header close={close}>Reorder field groups</Modal.Header>

                <DragDropContext
                    onDragEnd={({ source, destination }) => {
                        if (!destination || source.index === destination.index) {
                            return
                        }
                        updatePosition(source.index, destination.index)
                    }}
                >
                    <Droppable droppableId="fieldgroup-fields" renderClone={getDraggableRenderItem}>
                        {(droppableProvided) => (
                            <div
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}
                                className="mx-8 mb-4 rounded-lg"
                                style={
                                    IS_SAFARI
                                        ? undefined
                                        : {
                                              // This filter causes a >10s paint calculation in Safari, so we need to remove it there
                                              filter: 'drop-shadow(0px 22px 48px rgba(0, 0, 0, 0.03)) drop-shadow(0px 8px 17.5px rgba(0, 0, 0, 0.02)) drop-shadow(0px 4px 8.5px rgba(0, 0, 0, 0.015)) drop-shadow(0px 2px 4.15px rgba(0, 0, 0, 0.015)) drop-shadow(0px 0.75px 1.655px rgba(0, 0, 0, 0.01))',
                                          }
                                }
                            >
                                {fieldGroups.map((fieldGroup, index) => (
                                    <Draggable
                                        key={fieldGroup.id}
                                        draggableId={fieldGroup.id}
                                        index={index}
                                    >
                                        {getDraggableRenderItem}
                                    </Draggable>
                                ))}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <Modal.Footer border>
                    <Button
                        type="submit"
                        variant="primary"
                        iconComponent={isSubmitting ? SpinnerInline : ReorderOutlinedIcon}
                        disabled={isSubmitting}
                        className="justify-self-end"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </>
    )
}

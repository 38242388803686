import { useNavigate } from 'react-router'
import queryString from 'query-string-original'
import { useObserver } from 'mobx-react-lite'
import { useEffect } from 'react'

import { useRootStore } from '@laserfocus/client/root-store-context'
import { Spinner } from '@laserfocus/ui/beam'

function WaitAuth({ children }: { children: React.ReactNode }) {
    const rootStore = useRootStore<{ hasLoadedReplicacheStores: boolean }>()
    const loading = useObserver(() => !rootStore.hasLoadedReplicacheStores)

    if (loading) {
        return <Spinner fullscreen />
    }
    return <>{children}</>
}

export default function RequireAuth({ children }: { children: React.ReactNode }) {
    const navigate = useNavigate()
    const rootStore = useRootStore<{ isAuthenticated: boolean }>()
    const isAuthenticated = useObserver(() => rootStore.isAuthenticated)
    useEffect(() => {
        if (!isAuthenticated) {
            const source = window.location.pathname
            const query = queryString.stringify({ source })
            const to = `/login?${query}`
            /**
             * We have flaky tests where for some reason we run into unauthenticated requests during e2e.
             * The reauthentication redirect leads to Cypress hanging until timeout. This is so we can exit early
             * and have a better understanding where the unauthenticated request comes from.
             * The localStorage check is to have an escape hatch from this behaviour from within Cypress.
             */
            if ((window as any).Cypress) {
                throw new Error(
                    `RequireAuth: I am unauthenticated, and my current token is ${shortenToken(
                        localStorage.getItem('lf:token')
                    )}, message. Source: ${source}. `
                )
            }
            navigate(to)
        }
    }, [isAuthenticated, navigate])

    return <WaitAuth>{children}</WaitAuth>
}

function shortenToken(token: string | null) {
    if (token) {
        return token.substring(0, 6) + '...' + token.substr(-6)
    }
    return 'NO_TOKEN'
}

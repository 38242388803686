import { useSubscription } from '@laserfocus/client/data-access-shared'
import { CheckoutButton, PortalButton } from '@laserfocus/client/feature-subscription'
import { UserSubscription } from '@laserfocus/shared/models'
import { Card, Spinner } from '@laserfocus/ui/beam'
import { OpportunityFilledIcon } from '@laserfocus/ui/icons'

import { SettingsPageHeader } from '../layout/SettingsPageHeader'

import { PlanInfo } from './PlanInfo'

export function SubscriptionPage() {
    localStorage.setItem('lf:feature:subscription', 'true')
    const { isLoading, subscription } = useSubscription()
    return (
        <div>
            <SettingsPageHeader title="Subscription" iconComponent={OpportunityFilledIcon} />
            {isLoading ? <Spinner /> : <SubscriptionPageInner subscription={subscription} />}
        </div>
    )
}

type SubscriptionProps = { subscription: UserSubscription | null }

function SubscriptionPageInner({ subscription }: SubscriptionProps) {
    return (
        <>
            <div className="p-6">
                <Card className="max-w-md">
                    <div className="px-3 py-2">
                        {subscription ? (
                            <PlanInfo subscription={subscription} />
                        ) : (
                            <div>
                                There is currently no active subscription. In order to use
                                Laserfocus with all features, please purchase to the{' '}
                                <code>pro</code> plan.
                            </div>
                        )}
                    </div>
                    <div className="w-full bg-grey-700/10 h-[1px]" />
                    <div className="px-3 py-2 flex justify-end">
                        {hasActiveSubscription(subscription) ? (
                            <PortalButton variant="primary">Manage Subscription</PortalButton>
                        ) : (
                            <CheckoutButton variant="primary">Upgrade to PRO</CheckoutButton>
                        )}
                    </div>
                </Card>
            </div>
        </>
    )
}

function hasActiveSubscription(subscription: UserSubscription | null) {
    if (!subscription) return false
    return subscription.subscriptionStatus === 'active'
}

import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Divider, Button } from '@laserfocus/ui/beam'
import { AddOutlinedIcon } from '@laserfocus/ui/icons'
import { ActivityUtil } from '@laserfocus/client/model'

import { ActivityItem } from '../Activity/ActivityItem'
import type { ActivityData } from '../modules/usePersonActivitiesRCQuery'

import LoadingActivities from './LoadingActivities'

const dividerFormatter = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' })

interface ActivityListProps {
    openActivities: ActivityData[]
    historyActivities: ActivityData[]
    loadingActivityHistory: boolean
}

export const ActivityList = observer(function ActivityList({
    openActivities,
    historyActivities,
    loadingActivityHistory,
}: ActivityListProps) {
    const [maxAmountOfHistoryActivities, setMaxAmountOfHistoryActivities] = useState(10)

    const pinnedActivities = historyActivities.filter((a) => a.isPinned)

    const remainingHistory = historyActivities.filter((a) => !a.isPinned)

    return (
        <>
            <div className="grid gap-3">
                {openActivities.map((activity) => (
                    <ActivityItem key={activity.Id} activity={activity} />
                ))}

                {pinnedActivities.length > 0 && (
                    <Divider className="pl-[3.375rem] pr-2">Pinned</Divider>
                )}
                {pinnedActivities.map((activity) => (
                    <ActivityItem key={activity.Id} activity={activity} />
                ))}
                {loadingActivityHistory ? (
                    <LoadingActivities />
                ) : (
                    remainingHistory
                        .slice(0, maxAmountOfHistoryActivities)
                        .flatMap((activity, index, activities) => {
                            const date = ActivityUtil.getDate(activity)
                            const previousActivity = activities[index - 1]
                            const previousDate =
                                (previousActivity && ActivityUtil.getDate(previousActivity)) ||
                                new Date(0)

                            if (date && isNewMonth(date, previousDate)) {
                                return [
                                    <Divider
                                        key={`divider-${date.getFullYear()}-${date.getMonth()}`}
                                        className="pl-[3.375rem] pr-2"
                                    >
                                        {dividerFormatter.format(date)}
                                    </Divider>,
                                    <ActivityItem key={activity.Id} activity={activity} />,
                                ]
                            }

                            return <ActivityItem key={activity.Id} activity={activity} />
                        })
                )}
            </div>
            {!loadingActivityHistory && maxAmountOfHistoryActivities < remainingHistory.length && (
                <div className="pt-4 grid justify-center">
                    <Button
                        variant="tertiary"
                        size="small"
                        iconComponent={AddOutlinedIcon}
                        iconPosition="right"
                        onClick={() => setMaxAmountOfHistoryActivities((amount) => amount + 10)}
                    >
                        Show more activities
                    </Button>
                </div>
            )}
        </>
    )
})

function isNewMonth(date: Date | null, previousDate: Date): date is Date {
    if (!date) {
        return false
    }
    return (
        date.getMonth() !== previousDate.getMonth() ||
        date.getFullYear() !== previousDate.getFullYear()
    )
}

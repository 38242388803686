import { trim, min, map, set } from 'lodash'

export function isEmail(activity) {
    return (
        activity.TaskSubtype === 'Email' ||
        activity.ActivitySubtype === 'Email' ||
        emailRegexes.some((reg) => reg.test(activity.Description)) ||
        EMAIL_THREAD_REGEX.test(activity.Description) ||
        quoteHeadersRegex.some((reg) => reg.test(activity.Description))
    )
}

const emailRegexes = [
    /From:*[^]*To:*[^]*CC:*[^]*BCC*[^]*Attachment:*[^]*Subject:*[^]*Body:*[^]*/m,
    /To:*[^]*CC:*[^]*BCC*[^]*Attachment:*[^]*Subject:*[^]*Body:*[^]*/m,
    /From:*[^]*To:*[^]*Subject:*[^]*Body*[^]*/m,
    /To:*[^]*Subject:*[^]*Body*[^]*/m,
    /From:*[^]*To:*[^]*/m,
]

type EmailHeaders = {
    To?: string
    CC?: string
    BCC?: string
    Attachment?: string
    Subject?: string
}

export function parseDescription(input: string): { headers: EmailHeaders; body: string } {
    const KeyValueHeadersRegex = /^([a-zA-Z0-9 ]*):(.*)/gm
    const IsKeyValueBody = /^([a-zA-Z0-9 ]*):(.*)/

    if (!input.match(IsKeyValueBody)) {
        return {
            headers: {},
            body: input.trim(),
        }
    }

    let match: RegExpExecArray
    let lastMatch = 0
    let foundBody = false
    let headers = {}
    while ((match = KeyValueHeadersRegex.exec(input)) && !foundBody) {
        const [full, fullKey, fullValue] = match
        const key = fullKey.trim()
        const value = fullValue.trim()
        const sinceLast = input.slice(lastMatch, match.index)
        // const linesSinceLast = (sinceLast.match(/\\R/gm) || []).length

        if (sinceLast.trim().length > 0) {
            foundBody = true
        } else if (key === 'Body') {
            lastMatch = match.index + full.length
            foundBody = true
        } else {
            lastMatch = match.index + full.length
            set(headers, key, value)
        }
    }

    const fromLastMatch = input.slice(lastMatch)
    const body = fromLastMatch.trim()
    const result = {
        headers,
        body,
    }
    return result
}

export function isEmailBody(body) {
    return (
        emailRegexes.some((reg) => reg.test(body)) ||
        EMAIL_THREAD_REGEX.test(body) ||
        quoteHeadersRegex.some((reg) => reg.test(body))
    )
}

export function isActualEmail(activity) {
    return activity.ActivitySubtype === 'Email'
}

interface ParsedBodyRecursive {
    main: string
    quote?: string | ParsedBodyRecursive
}

export function parseBody(
    body: string,
    avoidRecursiveness?: false
): { main: string; quote?: string }
export function parseBody(body: string, avoidRecursiveness: true): ParsedBodyRecursive
export function parseBody(body: string, avoidRecursiveness?: boolean): ParsedBodyRecursive {
    const content = body

    const singleMatches = quoteHeadersRegex
        .map((r) => r.exec(content))
        .filter((a) => !!a && a.index > -1)

    if (singleMatches.length > 0) {
        const indexes = map(singleMatches, 'index')
        const firstIndex = min(indexes)
        // In case we have a forwarded a message with no additional content
        // we want to return an empty main with the rest quoted.
        // That means we need to skip this step
        if (firstIndex === 0 && !avoidRecursiveness) {
            const [first, rest] = splitFirst(body)
            const { main: subMain, quote: subQuote } = parseBody(rest)
            return { main: [first, subMain].join('\n'), quote: subQuote }
        }
        const main = content.slice(0, firstIndex)
        const quote = content.slice(firstIndex)
        return { main: trim(main), quote: trim(quote) }
    }

    return {
        main: content,
    }
}

const htmlReg = new RegExp('<([A-Za-z][A-Za-z0-9]*)\\b[^>]*>(.*?)</\\1>')

export function containsHTML(str: string) {
    return htmlReg.test(str)
}

export function cleanHTML(str: string) {
    const withoutCompleteScriptTags = str.replace(
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gim,
        ''
    )
    const withoutNotClosingScriptTags = withoutCompleteScriptTags.replace(/<script.*>/gim, '')
    return withoutNotClosingScriptTags
}

const EMAIL_THREAD_REGEX = /\n(.*<[^@]+@[^.]+\..+>:\n)/m

export const FORWARD_DELIMITER = '----------'
// const OPTIMISTIC_FWD_DELIMETER = "---------- Forwarded message ---------";

export function parseSubject(subject) {
    if (subject && subject.indexOf('Email:') === 0) {
        return trim(subject.replace('Email:', ''))
    }
    return trim(subject)
}

function splitFirst(text) {
    const [first, ...rest] = text.split('\n')
    return [first, rest.join('\n')]
}

const quoteHeadersRegex = [
    /(-){2,100}(\s)*Forwarded(\s)+message(\s)*(-){2,100}/m, // This is for the forwarded messages
    /^\s*(On(?:(?!^>*\s*On\b|\bwrote(:)?)[\s\S]){0,1000}wrote:?)($|\\n)/m, // On DATE, NAME <EMAIL> wrote:
    /^\s*(Le(?:(?!^>*\s*Le\b|\bécrit:)[\s\S]){0,1000}écrit :)($|\\n)/m, // Le DATE, NAME <EMAIL> a écrit :
    /^\s*(El(?:(?!^>*\s*El\b|\bescribió:)[\s\S]){0,1000}escribió:)($|\\n)/m, // El DATE, NAME <EMAIL> escribió:
    /^\s*(Il(?:(?!^>*\s*Il\b|\bscritto:)[\s\S]){0,1000}scritto:)($|\\n)/m, // Il DATE, NAME <EMAIL> ha scritto:
    /^\s*(Em(?:(?!^>*\s*Em\b|\bescreveu:)[\s\S]){0,1000}escreveu:)($|\\n)/m, // Em DATE, NAME <EMAIL>escreveu:
    /^\s*(Am\s.+\s)schrieb.+\s?(\[|<).+(\]|>):($|\\n)/m, // Am DATE schrieb NAME <EMAIL>:

    /^\s*(Op\s[\s\S]+?schreef[\s\S]+:)($|\\n)/m, // Il DATE, schreef NAME <EMAIL>:
    /^\s*((W\sdniu|Dnia)\s[\s\S]+?(pisze|napisał(\(a\))?):)($|\\n)/mu, // W dniu DATE, NAME <EMAIL> pisze|napisał:
    /^\s*(Den\s.+\sskrev\s.+:)($|\\n)/m, // Den DATE skrev NAME <EMAIL>:
    /^\s*(pe\s.+\s.+kirjoitti:)($|\\n)/m, // pe DATE NAME <EMAIL> kirjoitti:
    /^\s*(Am\s.+\sum\s.+\sschrieb\s.+:)($|\\n)/m, // Am DATE um TIME schrieb NAME:
    /^(在[\s\S]+写道：)($|\\n)/m, // > 在 DATE, TIME, NAME 写道：
    /^(20[0-9]{2}\..+\s작성:)($|\\n)/m, // DATE TIME NAME 작성:
    /^(20[0-9]{2}\/.+のメッセージ:)($|\\n)/m, // DATE TIME、NAME のメッセージ:
    /^(.+\s<.+>\sschrieb:)($|\\n)/m, // NAME <EMAIL> schrieb:
    /^(.+\s<.+>\swrote:)($|\\n)/m, // NAME <EMAIL> wrote:
    /^(.+\son.*at.*wrote:)($|\\n)/m, // NAME on DATE wrote:
    /^\s*(From\s?:.+\s?\n?\s*[[|<].+[\]|>])/m, // "From: NAME <EMAIL>" OR "From : NAME <EMAIL>" OR "From : NAME<EMAIL>"(With support whitespace before start and before <)
    /^\s*(De\s?:.+\s?\n?\s*(\[|<).+(\]|>))/m, // "De: NAME <EMAIL>" OR "De : NAME <EMAIL>" OR "De : NAME<EMAIL>"  (With support whitespace before start and before <)
    /^\s*(Van\s?:.+\s?\n?\s*(\[|<).+(\]|>))/m, // "Van: NAME <EMAIL>" OR "Van : NAME <EMAIL>" OR "Van : NAME<EMAIL>"  (With support whitespace before start and before <)
    /^\s*(Da\s?:.+\s?\n?\s*(\[|<).+(\]|>))/m, // "Da: NAME <EMAIL>" OR "Da : NAME <EMAIL>" OR "Da : NAME<EMAIL>"  (With support whitespace before start and before <)
    /^(20[0-9]{2})-([0-9]{2}).([0-9]{2}).([0-9]{2}):([0-9]{2})*.(.*)?\n?(.*)>:($|\\n)/m, // 20YY-MM-DD HH:II GMT+01:00 NAME <EMAIL>:
    /^\s*([a-z]{3,4}\.\s[\s\S]+\sskrev\s[\s\S]+:)($|\\n)/m, // DATE skrev NAME <EMAIL>:
    /^([0-9]{2}).([0-9]{2}).(20[0-9]{2})(.*)(([0-9]{2}).([0-9]{2}))(.*)"( *)<(.*)>( *):($|\\n)/m, // DD.MM.20YY HH:II NAME <EMAIL>
]

// const signatureRegex = [
//   /^\s*-{2,4}$/,
//   /^\s*_{2,4}$/,
//   /^—/,
//   /^—\w/,
//   /^-\w/,
//   /^-- $/,
//   /^-- \s*.+$/,
//   /^________________________________$/,
//   /^Sent from (?:\s*.+)$/,
//   /^Envoyé depuis (?:\s*.+)$/,
//   /^Enviado desde (?:\s*.+)$/,
//   /^\+{2,4}$/,
//   /^\={2,4}$/,
//   /^Get Outlook for iOS.*/m
// ];

import Highlighter from 'react-highlight-words'
import { twMerge } from 'tailwind-merge'

import { CLASS_NAME_INPUT_BUTTON_CONTENT } from './shared-class-names'

type CellValueProps = {
    value: string
    searchTerm?: string
    className?: string
    toDisplayValue?: (a: any) => string
} & React.ComponentPropsWithoutRef<'span'>

export function HighlightedCellValue({
    value,
    searchTerm,
    className,
    toDisplayValue,
    ...rest
}: CellValueProps) {
    const shouldHighlight = searchTerm ? searchTerm.length >= 3 : false
    let searchPhrases = shouldHighlight ? splitTerms(searchTerm!, toDisplayValue) : []

    if (shouldHighlight) {
        return (
            <Highlighter
                className={twMerge(CLASS_NAME_INPUT_BUTTON_CONTENT, 'px-0.5 -mx-0.5', className)}
                autoEscape={true}
                highlightClassName="bg-blue-500/20 text-blue-700 rounded px-0.5 -mx-0.5"
                searchWords={searchPhrases}
                textToHighlight={value}
                {...rest}
            />
        )
    }
    return (
        <span className={twMerge(CLASS_NAME_INPUT_BUTTON_CONTENT, className)} {...rest}>
            {value}
        </span>
    )
}

function splitTerms(searchTerm: string, toDisplayValue?: (a: any) => string): string[] {
    const terms = [searchTerm]
    if (!toDisplayValue) {
        return terms
    }
    const displaySearchTerms = terms
        .map((a) => toDisplayValue(a))
        .filter((displayValue) => displayValue && !terms.includes(displayValue))
    const withDisplayTerms = [...terms, ...displaySearchTerms]
    return withDisplayTerms
}

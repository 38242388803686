import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { twMerge } from 'tailwind-merge'
import { runInAction } from 'mobx'

import { Button, ModalInput } from '@laserfocus/ui/beam'
import { Analytics } from '@laserfocus/client/util-analytics'
import { updateActivity } from '@laserfocus/client/data-access-shared'
import { NewActivity, NewEvent, NewTask, TaskId } from '@laserfocus/shared/models'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'
import { AddOutlinedIcon } from '@laserfocus/ui/icons'
import { HighlightedCellValue } from '@laserfocus/client/ui-shared-datagrid'

import { TableCellProps } from '../TableCell'
import { Row } from '../../table-context'
import {
    CLASS_NAME_INPUT_BUTTON,
    CLASS_NAME_INPUT_BUTTON_CONTENT,
    CLASS_NAME_INPUT_BUTTON_EMPTY,
} from '../shared-class-names'

import { AddTaskModal } from './AddTaskModal'

export async function updateActivityTitle(activity: NewTask | NewEvent, value: string) {
    const previousValue = activity.Subject
    runInAction(() => {
        activity.Subject = value
    })
    return updateActivity({
        activityId: activity.Id as TaskId,
        subject: value,
    }).catch((e) => {
        runInAction(() => {
            activity.Subject = previousValue
        })
        throw e
    })
}

export const TaskTitleCell = observer(function TaskTitleCell({
    salesObject,
    column,
    searchTerm,
    tableRef,
    rowIndex,
}: TableCellProps) {
    const [isInputOpen, setIsInputOpen] = useState(false)

    const value = (column.getValue(salesObject) as string | null) ?? ''
    const [currentValue, setCurrentValue] = useState(value)
    const nextTask = column.getActual?.(salesObject) as NewActivity

    function openInput() {
        setCurrentValue(value)
        setIsInputOpen(true)
    }

    function close() {
        setIsInputOpen(false)

        if (nextTask && value !== currentValue) {
            updateActivityTitle(nextTask, currentValue)
                .then(() => {
                    Analytics.trackEvent({
                        event: 'activity_edited',
                        location: Analytics.parseStackFromSobject(salesObject.__typename),
                    })
                })
                .catch(() => {
                    // I just rolled back but dont want to explode
                })
        }
    }

    return nextTask ? (
        <ModalInput
            isOpen={isInputOpen}
            overflowBoundaryRef={tableRef}
            value={currentValue}
            onChange={(event) => setCurrentValue(event.target.value)}
            onClose={close}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault()
                    /**
                     * Blur indirectly triggers close
                     * Otherwise it happens twice
                     */
                    event.currentTarget.blur()
                }
            }}
            inputClassName="w-[18.75rem]"
        >
            <button
                title={value}
                className={twMerge(
                    CLASS_NAME_INPUT_BUTTON,
                    !value && CLASS_NAME_INPUT_BUTTON_EMPTY
                )}
                onClick={openInput}
            >
                <HighlightedCellValue value={value} searchTerm={searchTerm} />
            </button>
        </ModalInput>
    ) : (
        <EmptyTaskTitleCell salesObject={salesObject} showHint={rowIndex === 0} />
    )
})

interface EmptyTaskTitleCellProps {
    salesObject: Row
    showHint: boolean
}

function EmptyTaskTitleCell({ salesObject, showHint }: EmptyTaskTitleCellProps) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const content = (
        <div
            data-testid="empty-task-title-cell"
            className="group-inner w-full h-full grid items-center grid-rows-[minmax(0,1fr),auto] px-[0.625rem]"
            onMouseMove={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <span
                className={twMerge(
                    CLASS_NAME_INPUT_BUTTON_CONTENT,
                    CLASS_NAME_INPUT_BUTTON_EMPTY,
                    'group-inner-hover:sr-only group-inner-focus-within:sr-only'
                )}
            />
            {isHovered && (
                <div className="grid grid-flow-col gap-2 justify-start sr-only group-inner-hover:not-sr-only group-inner-focus-within:not-sr-only">
                    <Button
                        size="small"
                        iconComponent={AddOutlinedIcon}
                        onClick={() => {
                            setIsModalOpen((v) => !v)
                        }}
                    >
                        Add Task
                    </Button>
                </div>
            )}
            {isModalOpen && (
                <AddTaskModal
                    salesObject={salesObject}
                    closeModal={() => {
                        setIsModalOpen(false)
                    }}
                />
            )}
        </div>
    )
    if (showHint) {
        return (
            <EverboardingHint
                name="everboarding_stack_lfmethod_reminder"
                label="Set a task reminder"
                placement="bottom-start"
                containerClassName="flex flex-row-reverse -translate-y-1 translate-x-5"
                arrowClassName="rotate-90"
                labelClassName="rotate-12 translate-y-11 -translate-x-2 flex-shrink-0"
            >
                {content}
            </EverboardingHint>
        )
    }
    return content
}

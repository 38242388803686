import { getClient } from '@laserfocus/client/replicache'
import {
    Contact,
    dateToDateString,
    isAccountModel,
    isContactModel,
    isLeadModel,
    isOpportunityModel,
    Prefix,
} from '@laserfocus/shared/models'
import { createTask } from '@laserfocus/client/data-access-shared'

import { updateActivityDate } from '../../../TableCell/task-cells/TaskDateCell'
import { updateActivityTitle } from '../../../TableCell/task-cells/TaskTitleCell'
import { Column, Row } from '../../../table-context'

export const VALID_COLUMNS = ['NextActivity.Date', 'NextActivity.Subject']

export async function makeActivityUpdate(
    column: Column,
    salesObject: Row,
    value: string,
    userId: string
) {
    if (!VALID_COLUMNS.includes(column.key)) {
        throw new Error(`Cant update ${column.key} in here`)
    }

    const activity = column.getActual?.(salesObject)

    if (activity) {
        if (column.key === 'NextActivity.Subject') {
            return updateActivityTitle(activity as any, value)
        } else if (column.key === 'NextActivity.Date' && value) {
            return updateActivityDate(activity as any, new Date(value))
        }
    } else {
        const assignments = await getObjectAssignment(salesObject)
        const baseInput =
            column.key === 'NextActivity.Subject'
                ? {
                      Subject: value,
                      OwnerId: userId,
                  }
                : {
                      ActivityDate: value ? dateToDateString(new Date(value)) : undefined,
                      ReminderDateTime: value ? new Date(value).toISOString() : undefined,
                      OwnerId: userId,
                  }
        return createTask({
            ...baseInput,
            ...assignments,
        })
    }
}

async function getObjectAssignment(salesObject: Row) {
    const contact = await queryContact(salesObject)
    if (isOpportunityModel(salesObject)) {
        return {
            WhatId: salesObject.Id || salesObject.AccountId,
        }
    } else if (isAccountModel(salesObject)) {
        return {
            WhoId: contact?.Id,
            WhatId: salesObject.Id,
        }
    } else if (isLeadModel(salesObject)) {
        return {
            WhoId: salesObject.Id,
        }
    } else if (isContactModel(salesObject)) {
        return {
            WhoId: salesObject.Id,
            WhatId: salesObject.AccountId,
        }
    }
}

async function queryContact(salesObject: Row) {
    const rep = getClient()

    const contact = rep.query(async (tx) => {
        if (salesObject.__typename !== 'Account') {
            return null
        }

        // In case we have a contact selected in Account Detail page
        const accountId = salesObject.Id
        const oldContactKey = `account#${accountId}:recent:contact`
        const contactKey = `lf:${oldContactKey}`
        const contactId = localStorage.getItem(contactKey)
        if (contactId) {
            const contact = await tx.get([Prefix.Contact, contactId].join('/'))
            if (contact) {
                return contact
            }
        }
        const accountContacts = await tx
            .scan({ indexName: 'contactsByAccountId', prefix: accountId, limit: 1 })
            .values()
            .toArray()
        return accountContacts[0] ?? null
    })
    return contact as unknown as Contact | null
}

import queryString from 'query-string-original'
import { useEffect } from 'react'

import { Spinner } from '@laserfocus/ui/beam'
import { Config } from '@laserfocus/shared/config-env'
import { logger } from '@laserfocus/ui/logger'
import { Analytics } from '@laserfocus/client/util-analytics'
import { getClient } from '@laserfocus/client/replicache'
import { useRootStore } from '@laserfocus/client/root-store-context'

import { clearUser } from '../store/auth-storage'

const { SF_OAUTH_CLIENT_ID, STAGE, SANDBOX } = Config

type LoginRedirectProps = { debug?: boolean; sandbox?: boolean }

export function LoginPage({ debug, sandbox }: LoginRedirectProps) {
    const rootStore = useRootStore<{ onDestroy(): void }>()
    useEffect(() => {
        try {
            rootStore.onDestroy()
            const client = getClient({ ignoreMissingClient: true })
            if (client) {
                logger.info('Closing Replicache')
                client
                    .close()
                    .then(() => {
                        logger.info('Replicache Closed')
                    })
                    .catch((e) => {
                        logger.error(e)
                    })
            }
        } catch (e: any) {
            logger.error(e)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    login(debug)
    return <Spinner fullscreen />
}

function getRedirectUri(debug?: boolean) {
    // if (debug) {
    //   return "http://localhost:5000/auth/oauth-callback";
    // }
    return `https://${STAGE}.api.laserfocus.io/backend/auth/oauth-callback`
}

type OauthParams = {
    client_id: string
    redirect_uri: string
    response_type: 'code'
    state: string
}

type OauthState = {
    final_url: string
    source: string
    anonymousId?: string
    sandbox?: boolean
}

async function makeUrl(debug?: boolean, sandbox?: boolean) {
    const clientId = SF_OAUTH_CLIENT_ID
    const redirectUri = getRedirectUri(debug)
    logger.info('RedirectUri', redirectUri, 'clientId', SF_OAUTH_CLIENT_ID)
    const responseType = 'code'
    // @TODO: add immediate=true, but its somhow not working
    // Docs: https://developer.salesforce.com/docs/atlas.en-us.api_rest.meta/api_rest/intro_understanding_web_server_oauth_flow.htm
    // There is some more going on: https://stackoverflow.com/questions/38905313/using-immediate-true-in-salesforce-oauth-flow

    const { source } = queryString.parse(window.location.search)
    const domain = SANDBOX ? 'https://test.salesforce.com' : 'https://login.salesforce.com'

    const baseUrl = `${domain}/services/oauth2/authorize`
    const finalSource = source as string
    const state: OauthState = cleanObject({
        final_url: encodeURIComponent(`${window.location.origin}/oauth/callback`),
        source: finalSource,
        anonymousId: await Analytics.getAnonymousId(),
    })
    if (SANDBOX) {
        state.sandbox = true
    }
    const params: OauthParams = {
        client_id: clientId!,
        redirect_uri: redirectUri,
        response_type: responseType,
        state: queryString.stringify(state),
    }
    const query = queryString.stringify(params)
    return baseUrl + '?' + query
}

export async function login(debug?: boolean, sandbox?: boolean) {
    if (localStorage.getItem('lf:user_id') === '0051i000000EJDYAA4') {
        // eslint-disable-next-line no-debugger
        debugger
    }
    const redirectUrl = await makeUrl(debug)
    if (debug) {
        return setTimeout(() => {
            clearUser()
            window.location.href = redirectUrl
        }, 10000)
    }
    clearUser()
    window.location.href = redirectUrl
}

function cleanObject<T extends Record<string, any>>(obj: T): T {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null || v !== undefined)
    ) as T
}

import { useParams } from 'react-router'

import { PersonPageContainer } from '@laserfocus/client/feature-person-details'
import { wrapAuthenticated } from '@laserfocus/client/feature-auth'
import { StackNavigationProvider } from '@laserfocus/client/store-shared'
import { goToHome } from '@laserfocus/client/util-routing'

export function PersonPage() {
    const { personId } = useParams<{ personId: string }>()
    return (
        <StackNavigationProvider value={{ skip: true, navigateList: goToHome }}>
            <PersonPageContainer personId={personId} />
        </StackNavigationProvider>
    )
}

export default wrapAuthenticated(PersonPage)

import { useState } from 'react'
import { useObserver } from 'mobx-react-lite'

import { Button, Select } from '@laserfocus/ui/beam'
import { ArrowDownOutlinedIcon } from '@laserfocus/ui/icons'
import { Aggregation, FieldType } from '@laserfocus/shared/models'
import { useCurrency } from '@laserfocus/client/feature-auth'
import { getCurrencyFormatter } from '@laserfocus/client/util-formatter'
import { getLocale } from '@laserfocus/ui/util-locale'

import { Column, useTableDataContext } from '../table-context'

type AggregateOptions = {
    label: string
    value: Aggregation
}
const aggregateOptions: AggregateOptions[] = [
    { value: 'none', label: 'None' },
    { value: 'count-all', label: 'Count all' },
    { value: 'count-unique', label: 'Count unique' },
    { value: 'count-empty', label: 'Count empty' },
    { value: 'count-non-empty', label: 'Count non empty' },
    // { value: 'percent-empty', label: 'Percent empty' },
    // { value: 'percent-non-empty', label: 'Percent not empty' },

    // { value: 'min', label: 'Min' },
    // { value: 'max', label: 'Max' },
]

const numberAggregateOptions: AggregateOptions[] = [
    { value: 'sum', label: 'Sum' },
    { value: 'average', label: 'Average' },
]

const AGGREGATION_TYPES: Aggregation[] = ['min', 'max', 'sum', 'average']

const NUMBER_COLUMN_TYPS: FieldType[] = ['currency', 'double', 'int', 'percent']

export function AggregateSelect({
    column,
    showEmpty,
    setIsHoveringRow,
}: {
    column: Pick<Column, 'setAggregation' | 'aggregation' | 'type' | 'getAggregatedValue'>
    showEmpty: boolean
    setIsHoveringRow: (hovering: boolean) => void
}) {
    const { rows } = useTableDataContext()!
    const [isOpen, setIsOpen] = useState(false)

    const value = useObserver(() => column.getAggregatedValue(rows))

    const options = NUMBER_COLUMN_TYPS.includes(column.type)
        ? [...aggregateOptions, ...numberAggregateOptions]
        : aggregateOptions

    const showButton = (column.aggregation && column.aggregation !== 'none') || showEmpty
    if (!showButton) {
        return null
    }
    return (
        <Select
            size="small"
            isOpen={isOpen}
            options={options}
            onCancel={() => {
                setIsHoveringRow(false)
                setIsOpen(false)
            }}
            onSubmit={(option) => {
                setIsOpen(false)
                setIsHoveringRow(false)
                column.setAggregation(option.value)
            }}
        >
            <Button
                variant="tertiary"
                iconComponent={column.aggregation ? undefined : ArrowDownOutlinedIcon}
                iconPosition="right"
                size="small"
                className="w-full flex"
                onClick={() => setIsOpen(true)}
            >
                <AggregateLabel type={column.type} aggregation={column.aggregation} value={value} />
            </Button>
        </Select>
    )
}

type AggregatinonsWithoutNone = Exclude<Aggregation, 'none'>
const ValueLabelByAggregation: Record<AggregatinonsWithoutNone, string> = {
    'count-all': 'Count',
    'count-non-empty': 'Not empty',
    'count-empty': 'Empty',
    'count-unique': 'Unique',
    'percent-empty': '% Empty',
    'percent-non-empty': '% Not Empty',
    average: 'Average',
    sum: 'Sum',
    max: 'Max',
    min: 'Min',
    range: 'Range',
}

function AggregateLabel({
    type,
    aggregation,
    value,
}: {
    type: FieldType
    aggregation?: Aggregation
    value?: number | null
}) {
    if (!aggregation || aggregation === 'none') {
        return <>Calculate</>
    }

    const label = ValueLabelByAggregation[aggregation]
    return (
        <>
            <span>{label}</span>
            {NUMBER_COLUMN_TYPS.includes(type) && AGGREGATION_TYPES.includes(aggregation) ? (
                <NumberValue type={type} value={value} />
            ) : (
                <span className="pl-1 text-black">{value}</span>
            )}
        </>
    )
}

const numberFormatter = new Intl.NumberFormat(getLocale())

function NumberValue({ value, type }: { value?: number | null; type: FieldType }) {
    if (type === 'currency') {
        return <CurrencyValue value={value} />
    }
    return <span className="pl-1 text-black">{numberFormatter.format(value || 0)}</span>
}

function CurrencyValue({ value }: { value?: number | null }) {
    const currency = useCurrency()
    const currencyFormatter = getCurrencyFormatter(currency)

    return <span className="pl-1 text-black">{currencyFormatter.format(value || 0)}</span>
}

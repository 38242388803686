import clsx from 'clsx'

import { Modal } from '@laserfocus/ui/beam'

export function LoadingConvertBody() {
    return (
        <>
            <div className="max-h-[60vh] overflow-y-auto">
                <Modal.StickyHeading amountOfHeadings={4} headingIndex={0}>
                    Convert
                </Modal.StickyHeading>
                <LoadingConvertSection />
                <Modal.StickyHeading amountOfHeadings={4} headingIndex={1}>
                    Account
                </Modal.StickyHeading>
                <LoadingConvertSection />
                <Modal.StickyHeading amountOfHeadings={4} headingIndex={2}>
                    Contact
                </Modal.StickyHeading>
                <LoadingConvertSection />
                <Modal.StickyHeading amountOfHeadings={4} headingIndex={3}>
                    Opportunity
                </Modal.StickyHeading>
                <LoadingConvertSection rows={2} />
            </div>
            <Modal.Footer border>
                <div
                    aria-busy="true"
                    aria-live="polite"
                    className="w-[80px] h-[2rem] rounded-[0.625rem] bg-grey-700/5 justify-self-end"
                />
            </Modal.Footer>
        </>
    )
}

function LoadingConvertSection({ rows = 1 }: { rows?: number }) {
    const arr = Array(rows).fill('')
    return (
        <div className="pt-4 pb-3 px-8 grid gap-2" aria-busy="true" aria-live="polite">
            {arr.map((_, i) => (
                <div
                    key={i}
                    className={clsx('w-full rounded-[0.625rem] bg-grey-700/5', 'h-[2rem]')}
                />
            ))}
        </div>
    )
}

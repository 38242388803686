import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Input, InputProps } from './input'

interface FormInputProps extends InputProps {
    label: React.ReactNode
    id: string
    required?: boolean
}

export const FormInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, FormInputProps>(
    function FormInput({ label, id, className, required, ...inputProps }: FormInputProps, ref) {
        return (
            <div
                className={twMerge(
                    'grid grid-cols-[minmax(0,2fr),minmax(0,3fr)] items-start',
                    className
                )}
            >
                <label
                    htmlFor={id}
                    // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
                    className="text-sm leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
                >
                    {label}
                    {required && (
                        <span title="Required" className="px-1 -mr-1 select-none text-red-500">
                            •
                        </span>
                    )}
                </label>
                <Input id={id} {...inputProps} ref={ref} />
            </div>
        )
    }
)

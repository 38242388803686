import { computed, observable, reaction, runInAction, toJS } from 'mobx'
import { Options } from 'minisearch'
import { get } from 'lodash'

import type { EventId, NameRelation } from '@laserfocus/shared/models'
import { isAccount, isContact, isLead, isOpportunity } from '@laserfocus/shared/models'
import type { DateTimeString, NewEvent } from '@laserfocus/shared/models'
import type { Model, ObjectPool } from '@laserfocus/client/data-layer'

import { OpportunityModel } from '../sales/OpportunityModel'
import { LeadModel } from '../sales/LeadModel'
import { AccountModel } from '../sales/AccountModel'

import * as EventUtil from './event-util'

export type SnapShotter<Model, Snapshot> = (model: Model) => Snapshot
export type SnapshotListener<Snapshot> = (previous: Snapshot, next: Snapshot) => void

type EventProps = Partial<Omit<NewEvent, 'Id'>> & { Id: EventId; __typename: 'Event' }

export class EventModel implements NewEvent, Model {
    private objectPool: ObjectPool
    __typename = 'Event' as const

    @observable Id: EventId
    @observable CreatedDate?: DateTimeString
    @observable LastModifiedDate?: DateTimeString
    @observable CreatedById?: string
    @observable LastModifiedById?: string

    @observable Subject?: string
    @observable Description?: string
    @observable OwnerId?: string
    @observable AccountId?: string
    @observable WhoId?: string
    @observable WhatId?: string
    @observable AlternateDetailId?: string
    @observable Type?: string

    @observable RootId?: string

    @observable StartDateTime?: DateTimeString
    @observable EndDateTime?: DateTimeString
    @observable ActivityDateTime?: DateTimeString
    @observable DurationInMinutes?: number
    @observable EventSubtype?: string

    _previousSnapshot: any

    constructor(props: EventProps, objectPool: ObjectPool) {
        this.Id = props.Id

        this.CreatedDate = props.CreatedDate
        this.LastModifiedDate = props.LastModifiedDate
        this.CreatedById = props.CreatedById
        this.LastModifiedById = props.LastModifiedById
        this.Subject = props.Subject ?? undefined
        this.Description = props.Description ?? undefined
        this.OwnerId = props.OwnerId ?? undefined
        this.AccountId = props.AccountId ?? undefined
        this.WhoId = props.WhoId ?? undefined
        this.WhatId = props.WhatId ?? undefined
        this.AlternateDetailId = props.AlternateDetailId ?? undefined
        this.Type = props.Type ?? undefined
        this.RootId = props.RootId ?? undefined

        //
        this.StartDateTime = props.StartDateTime ?? undefined
        this.EndDateTime = props.EndDateTime ?? undefined
        this.DurationInMinutes = props.DurationInMinutes ?? undefined
        this.ActivityDateTime = props.ActivityDateTime ?? undefined
        this.EventSubtype = props.EventSubtype ?? undefined

        this.objectPool = objectPool
    }

    @computed
    get SortingDate() {
        return EventUtil.getSortingDate({
            ActivityDateTime: this.ActivityDateTime,
            EndDateTime: this.EndDateTime,
            StartDateTime: this.StartDateTime,
        })
    }

    @computed
    get OpenDate() {
        return this.SortingDate
    }
    @computed
    get ClosedDate() {
        return this.SortingDate
    }

    onSnapshot<T>(
        makeSnapshot: SnapShotter<EventModel, T>,
        listener: SnapshotListener<T>,
        options?: {
            fireImmediately?: boolean
        }
    ): () => void {
        this._previousSnapshot = makeSnapshot(this)
        return reaction(
            () => makeSnapshot(this),
            (current) => {
                listener(this._previousSnapshot, current)
                runInAction(() => {
                    this._previousSnapshot = current
                })
            },
            options
        )
    }

    @computed get props(): EventProps {
        return {
            Id: this.Id,
            __typename: 'Event',
            CreatedDate: this.CreatedDate,
            LastModifiedDate: this.LastModifiedDate,
            CreatedById: this.CreatedById,
            LastModifiedById: this.LastModifiedById,
            Subject: this.Subject,
            Description: this.Description,
            OwnerId: this.OwnerId,
            AccountId: this.AccountId,
            WhoId: this.WhoId,
            WhatId: this.WhatId,
            AlternateDetailId: this.AlternateDetailId,
            Type: this.Type,

            EventSubtype: this.EventSubtype,
            RootId: this.RootId,

            StartDateTime: this.StartDateTime,
            EndDateTime: this.EndDateTime,
            DurationInMinutes: this.DurationInMinutes,
            ActivityDateTime: this.ActivityDateTime,
        }
    }

    @computed
    get isOpen() {
        return !EventUtil.isOver(this)
    }

    /**
     * ------------------ Relationships --------------------
     */

    @observable _Who?: NameRelation | LeadModel
    @observable _Account?: NameRelation | AccountModel

    @computed get Who() {
        if (this.WhoId) {
            return this.objectPool.get<LeadModel>('Lead', this.WhoId) || this._Who
        }
        return undefined
    }
    set Who(who: NameRelation | undefined) {
        this._Who = who
    }

    @computed get Account() {
        const accountId = isAccount(this.WhatId) ? this.WhatId : undefined
        const id = this.AccountId || accountId
        if (id) {
            return this.objectPool.get<AccountModel>('Account', id) || this._Account
        }
        return this._Account
    }

    set Account(account: NameRelation | undefined) {
        this._Account = account
    }

    @computed get What() {
        if (this.WhatId && isAccount(this.WhatId)) {
            return this.objectPool.get<AccountModel>('Account', this.WhatId)
        } else if (this.WhatId && isOpportunity(this.WhatId)) {
            return this.objectPool.get<OpportunityModel>('Account', this.WhatId)
        }
        return undefined
    }

    @computed get Lead() {
        if (isLead(this.WhoId)) {
            return this.Who
        }
        return undefined
    }

    @computed get Contact() {
        if (isContact(this.WhoId)) {
            return this.Who
        }
        return undefined
    }

    @computed get Opportunity() {
        if (isContact(this.WhatId)) {
            return this.What
        }
        return undefined
    }
}

export const miniSearchOptions: Options<EventSearchIndex> = {
    idField: 'Id',
    fields: ['Subject', 'Description', 'Who.Company', 'Who.Name'],
    extractField(document: EventSearchIndex, fieldName: string) {
        if (fieldName.includes('.')) {
            return get(document, fieldName)
        }
        return document[fieldName as keyof EventSearchIndex]
    },
    searchOptions: {
        fuzzy: true,
        prefix: true,
        combineWith: 'AND',
    },
}

export function makeEventSnapshot(Event: EventModel): EventSearchIndex {
    //@TODO: check if we actually need toJS
    return toJS({
        Id: Event.Id,
        Subject: Event.Subject,
        Description: Event.Description,
        Who: {
            Company: (Event.Who as LeadModel)?.['Company'],
            Name: Event.Who?.['Name'],
        },
    })
}

export type EventSearchIndex = {
    Id: string
    Subject?: string
    Description?: string
    Who?: {
        Company?: string
        Name?: string
    }
}

import { set } from 'date-fns'

type SelectFn = (date: Date) => void
export function overrideTime(select: SelectFn, dateOnly: boolean | undefined): SelectFn {
    if (dateOnly) {
        return (date) =>
            select(
                set(date, {
                    hours: 8,
                    minutes: 0,
                    // We set milliseconds so we can detect dates which were created with dateOnly
                    milliseconds: 100,
                })
            )
    }
    return select
}

import { useState } from 'react'
import { matchSorter } from 'match-sorter'
import { useSubscribe } from 'replicache-react'
import { groupBy, mapValues } from 'lodash'

import { AddOutlinedIcon, Delete2OutlinedIcon } from '@laserfocus/ui/icons'
import { Button, Table, ConfirmPrompt } from '@laserfocus/ui/beam'
import { TextCell } from '@laserfocus/client/ui-shared-datagrid'
import { getClient } from '@laserfocus/client/replicache'
import { LFUser, Prefix, Role } from '@laserfocus/shared/models'
import { createRole, deleteRole, updateRole } from '@laserfocus/client/data-access-shared'
import { useIsUnit21 } from '@laserfocus/client/feature-auth'

export function DefineRolesTab({ searchTerm }: { searchTerm?: string }) {
    const roles = useRoles()

    const filteredRoles = searchTerm
        ? matchSorter(roles, searchTerm, { keys: ['name', 'label'] })
        : roles
    return (
        <div className="m-6">
            <Table.Frame className="max-w-4xl">
                <Table className="min-w-full divide-y divide-grey-700/10">
                    <Table.Head>
                        <tr>
                            <Table.HeadCell className="w-5" />
                            <Table.HeadCell className="pl-[14px]">Role</Table.HeadCell>
                            <Table.HeadCell className="w-52">Name</Table.HeadCell>
                            <Table.HeadCell># Users with this Role</Table.HeadCell>
                            <Table.HeadCell className="grid justify-end pr-4">
                                Action
                            </Table.HeadCell>
                        </tr>
                    </Table.Head>
                    <Table.Body>
                        {filteredRoles.map((r) => (
                            <RoleRow key={r.name} role={r} searchTerm={searchTerm} />
                        ))}
                        <CreateNewRoleRow />
                    </Table.Body>
                </Table>
            </Table.Frame>
        </div>
    )
}

function useRoles() {
    const rep = getClient()
    return useSubscribe(
        rep,
        async (tx) => {
            const [user, roles] = (await Promise.all([
                tx.scan({ prefix: Prefix.LFUser }).values().toArray(),
                tx.scan({ prefix: Prefix.Role }).values().toArray(),
            ])) as [LFUser[], Role[]]
            const userCountByRole = mapValues(groupBy(user, 'role'), (a) => a.length)
            return roles.map((r) => ({
                ...r,
                userCount: userCountByRole[r.name] ?? 0,
            }))
        },
        [],
        []
    )
}

function RoleRow({
    role,
    searchTerm,
}: {
    role: Role & { userCount: number }
    searchTerm?: string
}) {
    const [showDelete, setShowDelete] = useState(false)
    const { isLoading, isUnit21 } = useIsUnit21()
    return (
        <>
            <Table.Row>
                <Table.Cell />
                <Table.Cell>
                    <TextCell
                        name="label"
                        value={role.label}
                        updateValue={(label) => {
                            if (label) {
                                updateRole(role.name, { label })
                            }
                        }}
                        variant="dense"
                        searchTerm={searchTerm}
                    />
                </Table.Cell>
                <Table.Cell className="w-52">
                    <TextCell
                        name="rolename"
                        value={role.name}
                        readOnly
                        searchTerm={searchTerm}
                        updateValue={() => {}}
                    />
                </Table.Cell>
                <Table.Cell>{role.userCount}</Table.Cell>
                <Table.Cell className="grid justify-end">
                    {(role.name === 'admin' || role.name === 'sales-admin') && isUnit21 ? null : (
                        <Button
                            iconClassName="text-red-500"
                            variant="tertiary"
                            title="Delete role"
                            iconComponent={Delete2OutlinedIcon}
                            onClick={() => setShowDelete(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <ConfirmPrompt
                show={showDelete}
                title="Delete Role"
                description="Are you sure you want to delete this role?"
                submitButtonTitle="Delete"
                onCancel={() => setShowDelete(false)}
                onSubmit={() => {
                    setShowDelete(false)
                    deleteRole(role.name)
                }}
            />
        </>
    )
}

function CreateNewRoleRow() {
    return (
        <Table.Row>
            <Table.Cell className="pr-0">
                <AddOutlinedIcon className="w-5 h-5 text-grey-700/40" />
            </Table.Cell>
            <Table.Cell>
                <TextCell
                    name="rolename"
                    placeholder="Add new role"
                    value=""
                    updateValue={(value) => {
                        if (value) {
                            createRole(value)
                        }
                    }}
                    variant="dense"
                />
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
        </Table.Row>
    )
}

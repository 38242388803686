import { useLayoutEffect, useRef } from 'react'

export function useScrolledIntoViewRef<Element extends HTMLElement = HTMLElement>(
    key: string | number | boolean | undefined
) {
    const elementRef = useRef<Element>(null)

    useLayoutEffect(() => {
        if ((key || key === 0) && elementRef.current) {
            const scrollIntoView = () => elementRef.current?.scrollIntoView({ block: 'nearest' })

            // There might be situations in which effect is executed but ref node is not yet committed into the DOM, e.g. when ref is node in mounting popper
            if (!document.body.contains(elementRef.current)) {
                const timeout = setTimeout(scrollIntoView)
                return () => clearTimeout(timeout)
            }

            scrollIntoView()
        }
    }, [key])

    return elementRef
}

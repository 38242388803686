import { action, computed, observable, runInAction } from 'mobx'
import { chunkProcessor } from 'mobx-utils'
import { ReadTransaction } from 'replicache'

import { prefetchRoots } from '@laserfocus/client/data-access-shared'
import { LeadId, Prefix, SyncedRecord } from '@laserfocus/shared/models'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { getClient } from '@laserfocus/client/replicache'

export type RootStoreInjection = {
    prefetchStore: PrefetchStore
}

export function usePrefetch() {
    const rootStore = useRootStore<RootStoreInjection>()
    return rootStore.prefetchStore.prefetch
}

export class PrefetchStore {
    @observable rootsToFetch: string[] = []
    @observable isRunning: boolean = false

    @observable loadingRootIds: string[] = []
    @observable didLoadRootIds: string[] = []
    @observable reactions: Array<() => void> = []

    stopProcess?: () => void

    @computed
    get remaining() {
        return this.rootsToFetch
            .filter((a) => !this.loadingRootIds.includes(a))
            .filter((a) => !this.didLoadRootIds.includes(a))
    }

    @action.bound
    start() {
        this.isRunning = true
        let handle: NodeJS.Timeout
        const runTick = async () => {
            // console.log('🚀 Running prefetch while loading', this.loadingRootIds.length)
            if (this.loadingRootIds.length < 10) {
                const rootIds = this.remaining.slice(0, 10)
                if (rootIds.length) {
                    // console.log(
                    //     '🚀 Prefetching',
                    //     rootIds.length,
                    //     'records',
                    //     rootIds.includes('0014W000029YEfdQAG') ? 'WITH AscendantFX' : false
                    // )
                    await prefetchRoots({ rootIds: rootIds as LeadId[] })
                    runInAction(() => {
                        this.didLoadRootIds.push(...rootIds)
                    })
                }
            }
            handle = setTimeout(runTick, 5 * 1000)
        }

        this.stopProcess = () => {
            clearTimeout(handle)
        }
        runTick()
        return () => this.stop()
    }

    onInit() {
        const client = getClient()
        this.reactions.push(
            client.subscribe(
                async (tx: ReadTransaction) => {
                    const syncStatus = (await tx
                        .scan({ prefix: `${Prefix.SyncStatus}/` })
                        .values()
                        .toArray()) as Array<SyncedRecord>

                    const isLoading = Array.from(
                        new Set(syncStatus.filter((a) => a.isLoading).map((a) => a.id))
                    )
                    return isLoading
                },
                {
                    onData: (rootIds) => {
                        this.loadingRootIds = rootIds
                    },
                }
            )
        )
        setTimeout(() => this.start(), 10000)
    }

    onDestroy() {
        this.stop()
        this.reactions.forEach((reaction) => reaction())
    }

    @action
    stop() {
        this.isRunning = false
        this.stopProcess?.()
    }

    @action.bound
    prefetch(rootIds: string[]) {
        rootIds.forEach((rootId) => {
            if (!this.rootsToFetch.includes(rootId)) {
                this.rootsToFetch.push(rootId)
            }
        })
    }
}

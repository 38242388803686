import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSubscribe } from 'replicache-react'

import { getClient } from '@laserfocus/client/replicache'
import { Prefix, Role } from '@laserfocus/shared/models'

type RoleContextType = {
    roles: Role[]
    selectedRole: string | null
    setSelectedRole(role: string): void
}
const RoleContext = createContext<RoleContextType>({
    selectedRole: null,
    setSelectedRole: () => {},
    roles: [],
})

export function useRoleContext() {
    return useContext(RoleContext)
}

const STORAGE_KEY = 'lf:settings:selectedRole'

export function RoleContextProvider({ children }: { children: React.ReactNode }) {
    const rep = getClient()
    const roles = useSubscribe(
        rep,
        async (tx) => {
            return tx.scan({ prefix: Prefix.Role }).values().toArray() as Promise<Role[]>
        },
        [],
        []
    )

    const [selectedRole, setSelectedRole] = useState(() => {
        const stored = localStorage.getItem(STORAGE_KEY)
        return stored || roles[0]?.name
    })

    const selectRole = useCallback((role: string) => {
        setSelectedRole(role)
        localStorage.setItem(STORAGE_KEY, role)
    }, [])
    useEffect(() => {
        if (!selectedRole && roles.length) {
            selectRole(roles[0].name)
        }
    }, [roles, selectRole, selectedRole])

    const value = useMemo(
        () => ({
            roles,
            selectedRole,
            setSelectedRole: selectRole,
        }),
        [roles, selectedRole, selectRole]
    )

    return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>
}

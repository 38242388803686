import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function HelpOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon fill="currentColor" {...props}>
            <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C16.41 20 20 16.41 20 12C20 7.58 16.41 4 12 4C7.58 4 4 7.58 4 12C4 16.41 7.58 20 12 20ZM11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16ZM13 13.35C13 13.7035 12.7135 13.99 12.36 13.99H12C11.4477 13.99 11 13.5423 11 12.99V12.49C11 11.93 11.44 11.49 12 11.49C12.82 11.48 13.49 10.81 13.49 9.98C13.48 9.15 12.81 8.48 11.98 8.48C11.6108 8.48 11.2678 8.61149 11.0025 8.83401C10.5784 9.1896 10.052 9.58903 9.50962 9.47891C8.97144 9.36963 8.60203 8.83358 8.85423 8.34575C9.55125 6.99752 11.082 6.21676 12.64 6.53C14.53 6.9 15.76 8.75 15.38 10.64C15.12 11.91 14.18 12.93 12.94 13.3L13 13.35Z" />
        </SvgIcon>
    )
}

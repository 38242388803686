import { apolloClient } from '@laserfocus/client/data-access-apollo'
import type {
    CaptureTraitsInput,
    AnonymousCaptureTraitsInput,
    EmailSignupInput,
} from '@laserfocus/shared/models'
import {
    EMAIL_SIGNUP_MUTATION,
    CAPTURE_ANONYMOUS_TRAIT_MUTATION,
    CAPTURE_TRAIT_MUTATION,
} from '@laserfocus/shared/data-access-gql'

export async function emailSignup(input: EmailSignupInput) {
    const res = await apolloClient.mutate({
        mutation: EMAIL_SIGNUP_MUTATION,
        variables: {
            input,
        },
    })
    if (res.errors && res.errors[0]) {
        throw new Error(res.errors[0].message)
    }
    return res.data.emailSignup
}

export async function captureAnonymousTrait(input: AnonymousCaptureTraitsInput) {
    const res = await apolloClient.mutate({
        mutation: CAPTURE_ANONYMOUS_TRAIT_MUTATION,
        variables: {
            input,
        },
    })
    if (res.errors && res.errors[0]) {
        throw new Error(res.errors[0].message)
    }
    return res.data.captureAnonymousTrait
}

export async function captureTrait(input: CaptureTraitsInput) {
    const res = await apolloClient.mutate({
        mutation: CAPTURE_TRAIT_MUTATION,
        variables: {
            input,
        },
    })
    if (res.errors && res.errors[0]) {
        throw new Error(res.errors[0].message)
    }
    return res.data.captureTrait
}

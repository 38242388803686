import { useMemo, useState } from 'react'

import { Button, Dropdown } from '@laserfocus/ui/beam'
import { FilterOutlinedIcon } from '@laserfocus/ui/icons'

import { SortingButtons } from '../../ColumnFilters/SortingButtons'
import { useTableDataContext } from '../table-context'

export function ColorSortHeaderCell() {
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
    const { setSorting, sorting } = useTableDataContext()

    const column = useMemo(() => {
        return {
            sorting: sorting?.fieldName === 'color' ? sorting.direction : null,
            sortAscending: () => {
                setSorting({ fieldName: 'color', direction: 'ASC' })
            },
            sortDescending: () => {
                setSorting({ fieldName: 'color', direction: 'DESC' })
            },
        }
    }, [sorting, setSorting])

    return (
        // pt-[9px] this column has 1px offset to the others (not sure why, but this was the easy fix)
        <div className="group leading-[1.3] font-medium text-grey-700/60 text-left p-2 pt-[9px] grid grid-cols-1 grid-flow-col items-center rounded-[0.625rem]">
            <Dropdown
                isOpen={isFilterDropdownOpen}
                onCancel={() => setIsFilterDropdownOpen(false)}
                content={
                    <div role="dialog" className="p-2 w-[14.375rem] grid gap-4">
                        <SortingButtons column={column} sectionTitle="Sort by color" />
                    </div>
                }
            >
                <Button
                    title="Open column filters"
                    variant="tertiary"
                    iconComponent={FilterOutlinedIcon}
                    onClick={() => setIsFilterDropdownOpen(true)}
                    iconClassName={sorting?.fieldName === 'color' ? 'text-blue-500' : undefined}
                    className="-my-1"
                />
            </Dropdown>
        </div>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function LightningOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M13.34 2.06A1 1 0 0114 3v6h6a1 1 0 01.77 1.63l-9 11A1 1 0 0110 21v-6H4a1 1 0 01-.77-1.63l9-11a1 1 0 011.1-.31zM6.1 13H11a1 1 0 011 1v4.2l5.89-7.2H13a1 1 0 01-1-1V5.8L6.11 13z" />
        </SvgIcon>
    )
}

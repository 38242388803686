import gql from 'graphql-tag'

import { OPPORTUNITY_FRAGMENT } from './account-fragments'

export const CREATE_OPPORTUNITY = gql`
    ${OPPORTUNITY_FRAGMENT}
    mutation createOpportunity($input: OpportunityInput!) {
        createOpportunity(input: $input) {
            ...AccountOpportunity
        }
    }
`

import { useLocation } from 'react-router'
import { useRef } from 'react'

import { useHotKey } from '@laserfocus/ui/util-keyboard'
import { useToggleQueryParam } from '@laserfocus/client/util-routing'

interface StaticObject {
    Id?: string
}

export function useOverlay<SalesObject extends StaticObject>(
    objects: SalesObject[],
    queryParamKey: string
) {
    const toggleQueryParam = useToggleQueryParam()
    const { overlayObjectId, overlayObject, getPreviousObject, getNextObject } = useOverlayObject(
        objects,
        queryParamKey
    )

    useHotKey(
        'up',
        (event) => {
            event.preventDefault()
            goToPreviousObject()
        },
        { disabled: !overlayObject }
    )
    useHotKey(
        'down',
        (event) => {
            event.preventDefault()
            goToNextObject()
        },
        { disabled: !overlayObject }
    )
    useHotKey(
        'esc',
        (event) => {
            event.preventDefault()
            closeOverlay()
        },
        { disabled: !overlayObject }
    )

    function goToPreviousObject() {
        if (overlayObject) {
            const previousActivity = getPreviousObject()
            if (previousActivity) {
                toggleQueryParam(queryParamKey, previousActivity.Id!)
            }
        }
    }

    function goToNextObject() {
        if (overlayObject) {
            const nextActivity = getNextObject()
            if (nextActivity) {
                toggleQueryParam(queryParamKey, nextActivity.Id!)
            }
        }
    }

    function closeOverlay() {
        if (overlayObjectId) {
            toggleQueryParam(queryParamKey, overlayObjectId)
        }
    }

    return {
        overlayObject,
        closeOverlay,
        goToPreviousObject,
        goToNextObject,

        toggleOverlayForActivity(id: string) {
            toggleQueryParam(queryParamKey, id)
        },
    }
}

function useOverlayObject<SalesObject extends StaticObject>(
    objects: SalesObject[],
    queryParamKey: string
) {
    const location = useLocation()
    const lastObjectsInOverlayRef = useRef<SalesObject[] | undefined>()

    const overlayObjectId = new URLSearchParams(location.search).get(queryParamKey)

    if (!overlayObjectId) {
        if (lastObjectsInOverlayRef.current) {
            lastObjectsInOverlayRef.current = undefined
        }

        return {
            overlayObjectId,
            overlayObject: undefined,
            getNextObject() {
                return objects[0]
            },
            getPreviousObject() {
                return objects[0]
            },
        }
    }

    const overlayObjectIndex = objects.findIndex((a) => a.Id === overlayObjectId)

    if (overlayObjectIndex === -1) {
        const lastObjectIndex =
            lastObjectsInOverlayRef.current?.findIndex((a) => a.Id === overlayObjectId) ?? -1

        if (lastObjectIndex === -1) {
            return {
                overlayObjectId,
                overlayObject: undefined,
                getNextObject() {
                    return objects[0]
                },
                getPreviousObject() {
                    return objects[0]
                },
            }
        }

        const lastObjectsInOverlay = lastObjectsInOverlayRef.current!

        return {
            overlayObjectId,
            overlayObject: lastObjectsInOverlay[lastObjectIndex]!,
            getNextObject() {
                const seenObjectIds = new Set(
                    lastObjectsInOverlay.slice(0, lastObjectIndex + 1).map((a) => a.Id)
                )
                return objects.find((a) => !seenObjectIds.has(a.Id)) || objects[0]
            },
            getPreviousObject() {
                const seenObjectIds = new Set(
                    lastObjectsInOverlay.slice(lastObjectIndex).map((a) => a.Id)
                )
                return (
                    objects
                        .slice()
                        .reverse()
                        .find((a) => !seenObjectIds.has(a.Id)) || objects[0]
                )
            },
        }
    }

    const overlayObject = objects[overlayObjectIndex]!

    lastObjectsInOverlayRef.current = objects

    return {
        overlayObjectId,
        overlayObject,
        getNextObject() {
            return objects[(overlayObjectIndex + 1) % objects.length]!
        },
        getPreviousObject() {
            return objects[(overlayObjectIndex - 1 + objects.length) % objects.length]!
        },
    }
}

import { ReactNode } from 'react'
import { action } from 'mobx'

import { RootStoreProvider as RootStoreContextProvider } from '@laserfocus/client/root-store-context'
import { RootStore } from '@laserfocus/client/root-store'
import type { UiDependencies } from '@laserfocus/client/root-store'
import { toast } from '@laserfocus/ui/beam'

let rootStore: RootStore
function getRootStore() {
    if (!rootStore) {
        const uiDeps: UiDependencies = {
            sendUserNotification: toast,
        }
        rootStore = new RootStore(uiDeps)
        window.$store = rootStore
        window.$mobx = {
            action,
        }
    }
    return rootStore
}

declare global {
    interface Window {
        $store: RootStore
        $mobx: {
            action: typeof action
        }
    }
}

export function RootStoreProvider({ children }: { children: ReactNode }) {
    // useEffect(() => {
    //     rootStore.onInit()
    // }, [])
    // const isReady = useIsReady()
    // // These are the exceptions where the user might be authenticated,
    // // but we don't need to wait for stores to be ready
    // const isOauthCallback = window.location.pathname.startsWith('/oauth')
    // const isOnboarding = window.location.pathname.startsWith('/signup')
    // const renderChildren = isReady || isOnboarding || isOauthCallback
    return <RootStoreContextProvider value={getRootStore()}>{children}</RootStoreContextProvider>
}

import clsx from 'clsx'

import { HEADER_HEIGHT } from '@laserfocus/client/layout'

import { useIsInOverlay } from '../IsInOverlayContext'

interface CardHeaderProps {
    title?: string
    shouldShowBorder?: boolean
    children?: React.ReactNode
    action?: React.ReactNode
}

export function CardHeader({ title, shouldShowBorder, children, action }: CardHeaderProps) {
    const isInOverlay = useIsInOverlay()
    return (
        <div
            className={clsx(
                'sticky h-[2.875rem] z-[5]',
                shouldShowBorder && 'border-b border-grey-700/10'
            )}
            style={{
                /**
                 * DC: The layout header has a transparent border, if the `- 1` part isn't there, the letters are visible between layout header and ObjectFieldsHeader.
                 * MH: Replaced the -1 with -0.5 since the first sticky element was different positioned than the next (e.g. account vs contact)
                 *     And that seemed to fix it: https://twitter.com/youyuxi/status/1436026679059787781?lang=en
                 *
                 * MH: -2rem is coming from the overlay <article className="py-8">
                 */
                top: isInOverlay ? '-2rem' : HEADER_HEIGHT - 0.5,
            }}
        >
            <div className="pr-2 pl-6 bg-white grid grid-cols-[max-content,minmax(0,1fr)] grid-flow-col items-center justify-items-start gap-2 h-full">
                {title && <h3 className="text-base font-semibold leading-[1.2]">{title}</h3>}
                {children}
                {action && <div className="justify-self-end">{action}</div>}
            </div>
        </div>
    )
}

import { Routes, Route } from 'react-router'

import { NotFoundPage } from '@laserfocus/client/ui-shared'

import { SignUpPage } from './pages/SignUpPage'
import { SelectRolePage } from './pages/SelectRolePage'
import { ConnectSalesforcePage } from './pages/ConnectSalesforcePage'
import { SpecifyObjectsPage } from './pages/SpecifyObjectsPage'
import { TablePage } from './pages/TablePage/TablePage'
import { OnboardingProvider } from './OnboardingContext'
import { ApiDisabledErrorPage } from './pages/ApiDisabledErrorPage'
import { OnboardingLoadingChecks } from './pages/OnboardingLoadingChecks'
import { StepWait } from './pages/OnboardingWaitPage'

export function OnboardingRouter() {
    return (
        <OnboardingProvider>
            <Routes>
                <Route path="/" element={<SignUpPage />} />

                <Route element={<SelectRolePage />} path="select-role" />
                <Route element={<ConnectSalesforcePage />} path="connect-salesforce" />
                <Route element={<SpecifyObjectsPage />} path="specify-relevant-objects" />
                <Route element={<OnboardingLoadingChecks />} path="load-create-overview" />
                <Route element={<TablePage />} path="create-overview" />
                <Route element={<ApiDisabledErrorPage />} path="api-disabled" />
                <Route element={<StepWait />} path="lean-back" />

                <Route element={<NotFoundPage />} path="*" />
            </Routes>
        </OnboardingProvider>
    )
}

import { useCallback, useState } from 'react'
import { useSubscribe } from 'replicache-react'

import { Modal } from '@laserfocus/ui/beam'
import { queryCanCreateStack, useStacks } from '@laserfocus/client/data-access-shared'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'
import { getClient } from '@laserfocus/client/replicache'
import { LockedFeatureOverlay, useIsLocked } from '@laserfocus/client/feature-subscription'

import { ImportViewBody } from './ImportViewBody'
import { CreateStackBody } from './CreateStackBody'
import { CreateStackFromTemplateBody } from './CreateStackFromTemplateBody'
import { useCreateStackContext } from './CreateStackContext'

interface CreateStackModalProps extends CreateStackModalInnerProps {
    show: boolean
}
export function CreateStackModal({ closeModal, show }: CreateStackModalProps) {
    return (
        <Modal show={show} onClose={closeModal}>
            <CreateStackModalInner closeModal={closeModal} />
        </Modal>
    )
}

interface CreateStackModalInnerProps {
    closeModal: () => void
}

type Tab = 'Create new stack' | 'Import from Salesforce' | 'Templates'
const allTabs: Tab[] = ['Create new stack', 'Import from Salesforce', 'Templates']

function CreateStackModalInner({ closeModal }: CreateStackModalInnerProps) {
    const { showHints, allowImport } = useCreateStackContext()
    const [currentTab, setCurrentTab] = useState<Tab>('Create new stack')
    const { stacks } = useStacks(true)
    const selectTab = useCallback((tab: Tab) => {
        setCurrentTab(tab)
    }, [])

    const canCreateStack = useSubscribe(getClient(), queryCanCreateStack, true, [])
    const { isLocked, subscription } = useIsLocked(!canCreateStack)

    const tabs = allowImport ? allTabs : allTabs.filter((a) => a !== 'Import from Salesforce')
    return (
        <>
            <Modal.Overlay />
            <Modal.Container variableHeight>
                <Modal.Header close={closeModal}>Add stack</Modal.Header>
                <LockedFeatureOverlay
                    isLocked={isLocked}
                    subscription={subscription}
                    lockedFeature="more than 2 custom stacks"
                >
                    {showHints ? (
                        <EverboardingHint
                            name="everboarding_home_stack_import_tab"
                            label="Import your lists from Salesforce"
                            placement="top"
                            popperClassName="z-30"
                            delay={150}
                            containerClassName="translate-x-20 translate-y-2"
                            labelClassName="-translate-x-4 rotate-3 translate-y-2"
                            arrowClassName="translate-x-12 -rotate-[20deg]"
                        >
                            <Modal.Tabs
                                tabs={tabs}
                                currentTab={currentTab}
                                onTabClick={selectTab}
                            />
                        </EverboardingHint>
                    ) : (
                        <Modal.Tabs tabs={tabs} currentTab={currentTab} onTabClick={selectTab} />
                    )}
                    {currentTab === 'Create new stack' && <CreateStackBody stacks={stacks} />}
                    {currentTab === 'Import from Salesforce' && <ImportViewBody stacks={stacks} />}
                    {currentTab === 'Templates' && <CreateStackFromTemplateBody stacks={stacks} />}
                </LockedFeatureOverlay>
            </Modal.Container>
        </>
    )
}

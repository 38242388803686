import { EverboardingHint } from '@laserfocus/client/feature-everboarding'
import { Checkbox } from '@laserfocus/ui/beam'

import { useBulkEdit } from '../BulkEdit/bulkedit-context'

export function BulkEditTotalCheckbox() {
    const { bulkEditTotal, toggleBulkEditTotal } = useBulkEdit()

    return (
        <div className="pr-0.5">
            <EverboardingHint
                name="everboarding_stack_bulk_edit"
                label="Change multiple records"
                placement="right"
                containerClassName="rotate-6"
                arrowClassName="-translate-y-5 rotate-3"
                labelClassName="translate-x-6"
                // containerClassName="translate-x-20 -rotate-3"
                // arrowClassName="translate-x-16 -translate-y-12 -rotate-[65deg]"
            >
                <Checkbox
                    title={
                        bulkEditTotal === 'none'
                            ? 'Enable bulk editing'
                            : bulkEditTotal === 'all'
                            ? 'Deselect all'
                            : 'Select all'
                    }
                    checked={bulkEditTotal === 'all'}
                    indeterminate={bulkEditTotal === 'some' || bulkEditTotal === 'on'}
                    onChange={toggleBulkEditTotal}
                />
            </EverboardingHint>
        </div>
    )
}

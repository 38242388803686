import { FieldType } from '../metadata/metadata.types'

import { FilterQuery, FilterQueryDTO } from './filter-query.types'
import {
    FilterCondition,
    FilterConditionDTO,
    ConditionDateValue,
    ConditionValue,
} from './filter-conditions-schema'

export class FilterMapper {
    static serializeFilter(filter: FilterQuery<any>): FilterQueryDTO {
        return {
            sobject: filter.sobject,
            conditions: FilterMapper.serializeConditions(filter.conditions),
        }
    }
    static serializeConditions(conditions: FilterCondition[]): FilterConditionDTO[] {
        const forGQL = conditions.map((c) => {
            return {
                ...c,
                values: c.values.map((v) =>
                    FilterMapper.serializeValue(v as ConditionDateValue, c.fieldType)
                ),
            } as FilterConditionDTO
        })
        return forGQL
    }
    static serializeValue(value: ConditionDateValue, type: FieldType): string {
        return `${value}`
    }
    static deserializeFilter(filterDTO: FilterQueryDTO): FilterQuery<any> {
        return {
            sobject: filterDTO.sobject,
            conditions: FilterMapper.deserializeConditions(filterDTO.conditions),
        }
    }
    static deserializeConditions(conditions: FilterConditionDTO[]): FilterCondition[] {
        const deserialized = conditions.map((c): FilterCondition => {
            return {
                ...c,
                values: c.values.map((v) => FilterMapper.deserializeValue(v, c.fieldType)),
            } as FilterCondition
        })
        return deserialized
    }
    static deserializeValue(v: string, type: FieldType): ConditionValue {
        if (v === 'null') {
            return null
        }
        if (v === 'undefined') {
            return null
        }
        switch (type) {
            case 'boolean':
                return v === 'true'
            case 'double':
            case 'currency':
                return parseFloat(v)
            case 'int':
                return parseInt(v)
            default:
                return v
        }
    }
}

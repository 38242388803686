import { pickEmoji } from '@laserfocus/client/util-formatter'

import { EmojiAvatar, EmojiAvatarProps } from './EmojiAvatar'

interface PersonAvatarProps extends React.ComponentPropsWithoutRef<'div'> {
    id: string
    emoji?: string
    size?: EmojiAvatarProps['size']
}

export function PersonAvatar({ id, emoji: emojiFromProps, ...props }: PersonAvatarProps) {
    const emoji = emojiFromProps || pickEmoji(id)

    return <EmojiAvatar emoji={emoji} className="PersonAvatar" {...props} />
}

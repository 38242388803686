import { groupBy, sortBy } from 'lodash'
import { useObserver } from 'mobx-react-lite'

import { useObjectPool } from '@laserfocus/client/root-store-context'
import { FieldGroup, isTruthy } from '@laserfocus/shared/models'
import { Dropdown, DropdownProps } from '@laserfocus/ui/beam'
import { FieldMetadata } from '@laserfocus/client/model'
import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'

import { FieldsMultiSelectList } from './FieldsMultiSelectList'

interface FieldsSelectProps
    extends Pick<DropdownProps, 'children' | 'isOpen' | 'onCancel'>,
        FieldsSelectPanelProps {}

export function FieldsSelect({ fieldGroup, addField, removeField, ...props }: FieldsSelectProps) {
    return (
        <Dropdown
            {...props}
            content={
                <FieldsSelectPanel
                    fieldGroup={fieldGroup}
                    addField={addField}
                    removeField={removeField}
                />
            }
        />
    )
}

interface FieldsSelectPanelProps {
    fieldGroup: FieldGroup
    removeField(fieldName: string): Promise<unknown>
    addField(fieldName: string): Promise<unknown>
}

function FieldsSelectPanel({ fieldGroup, addField, removeField }: FieldsSelectPanelProps) {
    const fieldsSections = useFields(fieldGroup, { addField, removeField })

    return <FieldsMultiSelectList fieldsSections={fieldsSections} />
}

type FieldActions = {
    removeField(fieldName: string): Promise<unknown>
    addField(fieldName: string): Promise<unknown>
}

function useFields(fieldGroup: FieldGroup, actions: FieldActions) {
    const objectPool = useObjectPool()

    const availableSalesObjectTypes =
        fieldGroup.rootObject === 'Lead'
            ? (['Lead'] as const)
            : (['Account', 'Contact', 'Opportunity'] as const)

    const { location } = useAnalyticsContext()

    return useObserver(() => {
        const fieldGroupFieldsSet = new Set(Object.keys(fieldGroup.visibleFieldOrderMap))
        const allFields = Array.from(objectPool.getAll('FieldMetadata').values()) as FieldMetadata[]
        const allFieldsGrouped = groupBy(allFields, 'objectName')

        const fieldsSections = availableSalesObjectTypes
            .map(
                (salesObjectType) =>
                    allFieldsGrouped[salesObjectType] && {
                        name: salesObjectType,
                        fields: sortBy(allFieldsGrouped[salesObjectType], 'label').map((field) => ({
                            key: field.fullName,
                            label: field.label,
                            isSelected: fieldGroupFieldsSet.has(field.fullName),
                            toggle: () => {
                                if (fieldGroup.lockedFieldNames?.has(field.fullName)) {
                                    return
                                }
                                const toggle = fieldGroupFieldsSet.has(field.fullName)
                                    ? actions.removeField(field.fullName)
                                    : actions.addField(field.fullName)
                                return toggle.then(() =>
                                    Analytics.trackEvent({
                                        event: 'fields_customized',
                                        location: location || 'person_details',
                                    })
                                )
                            },
                            isLocked: fieldGroup.lockedFieldNames?.has(field.fullName),
                        })),
                    }
            )
            .filter(isTruthy)
        return fieldsSections
    })
}

interface EmptyStackProps {
    heading: React.ReactNode
    description: React.ReactNode
    button?: React.ReactNode
}

export function EmptyStack({ heading, description, button }: EmptyStackProps) {
    return (
        <div className="grid gap-10 place-content-center text-center justify-items-center">
            <h2 className="font-serif text-[2.5rem] leading-[1.2]">{heading}</h2>
            <p className="text-2xl leading-normal">{description}</p>
            {button}
        </div>
    )
}

import { JSONValue, ReadTransaction, WriteTransaction } from 'replicache'

import {
    AccountId,
    applyValidators,
    LeadId,
    Prefix,
    SalesObjectExtension,
    SalesObjectExtensionMutatorSchema,
} from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'

export function updateEmoji(id: LeadId | AccountId, emoji: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.updateEmoji({ id, emoji })
}

export const mutators = applyValidators(SalesObjectExtensionMutatorSchema, {
    async updateEmoji(tx, args) {
        const storedExtension = await getExtension(tx, args.id)
        const extension: SalesObjectExtension = {
            ...storedExtension,
            ...args,
            lastModifiedDate: new Date().toISOString(),
        }
        await putExtension(tx, extension as SalesObjectExtension)
    },
})

async function getExtension(tx: ReadTransaction, id: string) {
    const key = getKey(id)
    const extension = await tx.get(key)
    return extension as SalesObjectExtension | undefined
}

async function putExtension(tx: WriteTransaction, input: SalesObjectExtension) {
    const key = getKey(input.id)
    await tx.put(key, input as unknown as JSONValue)
}
function getKey(id: string) {
    return [Prefix.SalesExtension, id].join('/')
}

import { useState } from 'react'

import { CreateContactModal } from '../CreateObjectModal/CreateContactModal'
import { useContacts, useOpportunities } from '../modules/PersonContext'
import { CreateOpportunityModal } from '../CreateObjectModal/CreateOpportunityModal'

import { CardHeader } from './CardHeader'
import { CardHeaderSelect } from './CardHeaderSelect'
import { ObjectActions } from './ObjectActions'

interface ObjectFieldsHeaderProps {
    sobject: 'Lead' | 'Account' | 'Contact' | 'Opportunity'
    orgOrUser: 'org' | 'user'
    isLast?: boolean
}

export function ObjectFieldsHeader({ sobject, isLast, orgOrUser }: ObjectFieldsHeaderProps) {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    return (
        <CardHeader
            title={sobject}
            shouldShowBorder={!isLast}
            action={
                orgOrUser === 'org' ? null : (
                    <ObjectActions
                        sobject={sobject}
                        openCreateModal={() => setIsCreateModalOpen(true)}
                    />
                )
            }
        >
            {sobject === 'Contact' && orgOrUser !== 'org' && (
                <ContactSelectionMenu
                    isCreateModalOpen={isCreateModalOpen}
                    setIsCreateModalOpen={setIsCreateModalOpen}
                />
            )}
            {sobject === 'Opportunity' && orgOrUser !== 'org' && (
                <OpportunitySelectionMenu
                    isCreateModalOpen={isCreateModalOpen}
                    setIsCreateModalOpen={setIsCreateModalOpen}
                />
            )}
        </CardHeader>
    )
}

function ContactSelectionMenu({
    isCreateModalOpen,
    setIsCreateModalOpen,
}: {
    isCreateModalOpen: boolean
    setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const { contacts, currentContactId, setCurrentContactId } = useContacts()

    const options = contacts?.map((o) => ({ value: o.Id, label: o.Name! })) || []
    const selectedOption = options.find((o) => o.value === currentContactId)

    return (
        <>
            <CardHeaderSelect
                elementName="Contact"
                options={options}
                selectedOption={selectedOption}
                addElement={() => setIsCreateModalOpen(true)}
                onSelect={setCurrentContactId}
            />
            {isCreateModalOpen && (
                <CreateContactModal closeModal={() => setIsCreateModalOpen(false)} />
            )}
        </>
    )
}

function OpportunitySelectionMenu({
    isCreateModalOpen,
    setIsCreateModalOpen,
}: {
    isCreateModalOpen: boolean
    setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const { opportunities, currentOpportunityId, setCurrentOpportunityId } = useOpportunities()

    const options = opportunities?.map((o) => ({ value: o.Id, label: o.Name! })) || []
    const selectedOption = options.find((o) => o.value === currentOpportunityId)

    return (
        <>
            <CardHeaderSelect
                elementName="Opportunity"
                options={options}
                selectedOption={selectedOption}
                addElement={() => setIsCreateModalOpen(true)}
                onSelect={setCurrentOpportunityId}
            />
            {isCreateModalOpen && (
                <CreateOpportunityModal closeModal={() => setIsCreateModalOpen(false)} />
            )}
        </>
    )
}

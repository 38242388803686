import { useLayoutEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { CheckOutlinedIcon, LineOutlinedIcon } from '@laserfocus/ui/icons'

interface VariantElementsStyles {
    unchecked?: string
    uncheckedHover?: string
    uncheckedDisabled?: string
    checked?: string
    checkedHover?: string
    checkedDisabled?: string
}

const variantsStyles: Record<CheckboxVariant, VariantElementsStyles> = {
    light: {
        unchecked: /* tw: */ 'text-grey-700/20 ring-1 ring-grey-700/20 bg-transparent',
        uncheckedHover: /* tw: */ 'peer-hover:ring-grey-700/40',
        uncheckedDisabled: /* tw: */ 'ring-grey-700/10',
        checked: /* tw: */ 'text-white bg-blue-500',
        checkedHover: /* tw: */ 'peer-hover:bg-blue-700',
        checkedDisabled: /* tw: */ 'bg-blue-500/20',
    },
    dark: {
        unchecked: /* tw: */ 'bg-white/10 text-transparent',
        uncheckedHover: /* tw: */ 'peer-hover:bg-white/20',
        uncheckedDisabled: /* tw: */ 'bg-white/5',
        checked: /* tw: */ 'text-white bg-blue-500',
        checkedHover: /* tw: */ 'peer-hover:bg-blue-700',
        checkedDisabled: /* tw: */ 'text-grey-700 bg-blue-500/20',
    },
    ghost: {
        unchecked: /* tw: */ 'text-grey-700/20 bg-white ring-1 ring-grey-700/20',
        uncheckedHover: /* tw: */ 'peer-hover:ring-grey-700/40',
        uncheckedDisabled: /* tw: */ 'ring-grey-700/10',
        checked: /* tw: */ 'text-blue-500 bg-white',
        checkedHover: /* tw: */ 'bg-white peer-hover:ring-1 peer-hover:ring-grey-700/40',
    },
}

interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
    /** `checked` property is required because it determines the UI */
    checked: boolean
    indeterminate?: boolean
    error?: string
    variant?: CheckboxVariant
}
type CheckboxVariant = 'light' | 'dark' | 'ghost'

export function Checkbox({
    className,
    variant = 'light',
    indeterminate,
    error,
    ...props
}: CheckboxProps) {
    const inputRef = useRef<HTMLInputElement>(null)

    // Must run after every render to make sure that `checked` property doesn't override indeterminate state
    useLayoutEffect(() => {
        if (indeterminate && inputRef.current) {
            inputRef.current.indeterminate = true
        }
    })

    const variantStyles = variantsStyles[variant]
    const defaultStyles =
        props.checked || indeterminate ? variantStyles.checked : variantStyles.unchecked
    const hoverStyles =
        props.checked || indeterminate ? variantStyles.checkedHover : variantStyles.uncheckedHover
    const disabledStyles =
        props.checked || indeterminate
            ? variantStyles.checkedDisabled
            : variantStyles.uncheckedDisabled

    return (
        <div className={twMerge('relative w-4 h-4 p-1 -m-1 box-content', className)}>
            <input
                ref={inputRef}
                type="checkbox"
                title={error}
                className="absolute opacity-0 top-0 left-0 w-full h-full cursor-pointer disabled:cursor-default peer"
                {...props}
            />
            <div
                className={twMerge(
                    'peer-focus-visible:ring peer-focus-visible:ring-blue-500 peer-focus-visible:peer-checked:bg-blue-700 peer-focus-visible:peer-checked:ring-blue-500/10 transition rounded w-full h-full grid place-items-center',
                    defaultStyles,
                    props.disabled ? disabledStyles : hoverStyles,
                    error && 'ring ring-red-500 peer-hover:ring peer-hover:ring-red-500'
                )}
            >
                {indeterminate ? (
                    <LineOutlinedIcon className="w-3 h-3" />
                ) : (
                    <CheckOutlinedIcon data-testid="check-icon" className="w-3 h-3" />
                )}
            </div>
        </div>
    )
}

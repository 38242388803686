import { forwardRef } from 'react'

import { Button, Dropdown } from '@laserfocus/ui/beam'
import { FilterOutlinedIcon } from '@laserfocus/ui/icons'

import { Column } from '../table-context'
import { ColumnFilters } from '../../ColumnFilters/ColumnFilters'

interface ColumnFilterButtonProps {
    column: Column
    isFilterDropdownOpen: boolean
    setIsFilterDropdownOpen(isOpen: boolean): void
}

export const ColumnFilterButton = forwardRef<HTMLDivElement, ColumnFilterButtonProps>(
    function ColumnFilterButton({ column, isFilterDropdownOpen, setIsFilterDropdownOpen }, ref) {
        const hasActiveFilter = column.filterCondition || column.sorting

        return (
            <div
                ref={ref}
                // Necessary to prevent drag handlers to get triggered on button click or interacting with dropdown content
                onPointerDown={(event) => event.stopPropagation()}
            >
                <Dropdown
                    isOpen={isFilterDropdownOpen}
                    onCancel={() => setIsFilterDropdownOpen(false)}
                    content={
                        <div role="dialog" className="p-2 w-[14.375rem]">
                            <ColumnFilters column={column} />
                        </div>
                    }
                >
                    <Button
                        title="Open column filters"
                        variant="tertiary"
                        iconComponent={FilterOutlinedIcon}
                        onClick={() => setIsFilterDropdownOpen(true)}
                        iconClassName={hasActiveFilter ? 'text-blue-500' : undefined}
                        className="-my-1"
                    />
                </Dropdown>
            </div>
        )
    }
)

import { useMemo } from 'react'

import {
    useRegisterEverboardingHint,
    EverboardingHintConfig,
    EverboardingCounters,
} from '@laserfocus/client/feature-everboarding'

export function usePersonPageEverboarding(isInOverlay: boolean) {
    const overlayExpandHint: EverboardingHintConfig = useMemo(
        () => ({
            name: 'everboarding_overlay_expand',
            shouldShow: (counters: EverboardingCounters, previous) => {
                const overlayViewCount = counters?.record_viewed_overlay || 0
                return isInOverlay && overlayViewCount >= 6 && !previous
            },
        }),
        [isInOverlay]
    )
    const customizeFieldsHint: EverboardingHintConfig = useMemo(
        () => ({
            name: 'everboarding_persondetails_customize_fields',
            shouldShow: (counters: EverboardingCounters) => {
                const overlayViewCount = counters?.record_viewed_overlay || 0
                if (isInOverlay) {
                    return overlayViewCount >= 3
                }
                return true
            },
        }),
        [isInOverlay]
    )

    const showFieldGroupsHint = useMemo(
        (): EverboardingHintConfig => ({
            name: 'everboarding_overlay_fieldgroup_tab',
            shouldShow: (counters: EverboardingCounters) => {
                return isInOverlay
            },
        }),
        [isInOverlay]
    )

    useRegisterEverboardingHint(showFieldGroupsHint)
    useRegisterEverboardingHint(customizeFieldsHint)
    useRegisterEverboardingHint(overlayExpandHint)
}

import { createContext, useContext, useMemo } from 'react'

import {
    AccountModel,
    ContactModel,
    isAccount,
    isLead,
    LeadModel,
    OpportunityModel,
} from '@laserfocus/shared/models'
import { assert } from '@laserfocus/ui/logger'

type ContactsContextType = {
    contacts?: Array<ContactModel>
    setCurrentContactId?: (id: string) => void
    currentContactId?: string
}

type OpportunitiesContextType = {
    opportunities?: Array<OpportunityModel>
    setCurrentOpportunityId?: (id: string) => void
    currentOpportunityId?: string
}

const LeadContext = createContext<LeadModel | undefined | null>(undefined)
const AccountContext = createContext<AccountModel | undefined | null>(undefined)

const OpportunitiesContext = createContext<OpportunitiesContextType>({
    opportunities: [],
    setCurrentOpportunityId: () => null,
})
const ContactsContext = createContext<ContactsContextType>({
    contacts: [],
    setCurrentContactId: () => null,
})
const RootIdContext = createContext<string | undefined>(undefined)

type PersonProviderProps = {
    rootId?: string
    lead?: LeadModel | null
    account?: AccountModel | null
} & ContactsContextType &
    OpportunitiesContextType

export function PersonContextProvider({
    rootId,
    lead,
    account,
    children,
    contacts,
    setCurrentContactId,
    currentContactId,
    opportunities,
    setCurrentOpportunityId,
    currentOpportunityId,
}: React.PropsWithChildren<PersonProviderProps>) {
    const contactContext = useMemo(
        () => ({
            contacts,
            currentContactId,
            setCurrentContactId,
        }),
        [contacts, setCurrentContactId, currentContactId]
    )
    const oppContext = useMemo(
        () => ({
            opportunities,
            currentOpportunityId,
            setCurrentOpportunityId,
        }),
        [opportunities, currentOpportunityId, setCurrentOpportunityId]
    )

    const contexts = [useContext(LeadContext), useContext(AccountContext)].filter(Boolean)

    if (contexts.length !== 0) {
        return <>{children}</>
    }

    return (
        <RootIdContext.Provider value={rootId}>
            <LeadContext.Provider value={lead}>
                <AccountContext.Provider value={account}>
                    <OpportunitiesContext.Provider value={oppContext}>
                        <ContactsContext.Provider value={contactContext}>
                            {children}
                        </ContactsContext.Provider>
                    </OpportunitiesContext.Provider>
                </AccountContext.Provider>
            </LeadContext.Provider>
        </RootIdContext.Provider>
    )
}

export function useContacts() {
    return useContext(ContactsContext)
}

export function useCurrentContact() {
    const { contacts, currentContactId } = useContacts()
    return contacts?.find((a) => a.Id === currentContactId) || contacts?.[0]
}

export function usePerson() {
    const lead = useContext(LeadContext)
    const contact = useCurrentContact()
    return lead || contact
}
export function useLead() {
    const lead = useContext(LeadContext)
    return lead
}

export function useAccount() {
    return useContext(AccountContext)
}

export function useOpportunities() {
    return useContext(OpportunitiesContext)
}

export function useCurrentOpportunity(): OpportunityModel | undefined {
    const { opportunities, currentOpportunityId } = useOpportunities()
    const currentOpp = opportunities?.find((o) => o.Id === currentOpportunityId)
    return currentOpp || opportunities?.[0]
}

export function useRootObject(): AccountModel | LeadModel {
    const person = useLead()
    const account = useAccount()
    if (account) {
        return account
    }
    return person as LeadModel
}

export function useRootId() {
    return useContext(RootIdContext)
}

export function usePersonId() {
    return usePerson()?.Id
}

type SalesObjectType = 'Lead' | 'Account' | 'Contact' | 'Opportunity'

export function useSalesObject(salesObjectType: SalesObjectType) {
    const lead = useLead()
    const contact = useCurrentContact()
    const account = useAccount()
    const opportunity = useCurrentOpportunity()

    switch (salesObjectType) {
        case 'Lead':
            return lead
        case 'Contact':
            return contact
        case 'Account':
            return account
        case 'Opportunity':
            return opportunity
    }
}

export function useRootObjectType() {
    const rootId = useRootId()
    if (isLead(rootId)) {
        return 'Lead'
    }
    if (isAccount(rootId)) {
        return 'Account'
    }
    assert(!rootId, 'Using a rootId that is neither a Lead nor Account')
    return null
}

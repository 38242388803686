import { twMerge } from 'tailwind-merge'

export function TextPrimary(props: React.ComponentPropsWithoutRef<'span'>) {
    return (
        <span
            {...props}
            className={twMerge('text-base text-grey-700 leading-[1.5] font-sans', props.className)}
        />
    )
}

export function TextPrimarySmall(props: React.ComponentPropsWithoutRef<'span'>) {
    return (
        <span
            {...props}
            className={twMerge(
                'text-sm font-medium text-grey-700 leading-[1.5] font-sans',
                props.className
            )}
        />
    )
}

export function TextSecondarySmall(props: React.ComponentPropsWithoutRef<'span'>) {
    return (
        <span
            {...props}
            className={twMerge(
                'text-sm font-medium font-sans text-grey-700 leading-[1.5]',
                props.className
            )}
        />
    )
}

import { getLocale } from '@laserfocus/ui/util-locale'

/**
 *  Workaround since we do not know the corresponding field currency.
 *  We use USD for US customers and EUR for the rest
 *  If we would want to do it right:
 *  https://developer.salesforce.com/forums/?id=906F00000008jY2IAI
 */
const getCurrency = () => {
    const timezoneOffset = new Date().getTimezoneOffset()
    const USOffsets = [300, 360, 420, 480, 540]
    if (USOffsets.includes(timezoneOffset)) {
        return 'USD'
    }
    return 'EUR'
}

let currencyFormatters = {}

export function getCurrencyFormatter(currency?: string | null): Intl.NumberFormat {
    if (!currency) {
        currency = getCurrency()
    }
    if (!currencyFormatters[currency]) {
        currencyFormatters[currency] = new Intl.NumberFormat(getLocale(), {
            style: 'currency',
            currency,
        })
    }
    return currencyFormatters[currency]
}

import { Children, cloneElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button, ButtonProps } from '../button/Button'

interface ButtonGroupProps {
    'data-pendo'?: string
    children: [
        React.ReactElement<ButtonProps, typeof Button>,
        React.ReactElement<ButtonProps, typeof Button>,
        ...React.ReactElement<ButtonProps, typeof Button>[]
    ]
}

/** Meant to wrap <Button />s */
export function ButtonGroup({ children, ...props }: ButtonGroupProps) {
    return (
        <div {...props} className="flex bg-white rounded-md isolate">
            {Children.map(children, (child, index) => {
                // const isLeft = index === 0
                // const isRight = index === children.length - 1
                // const isMiddle = !isLeft && !isRight
                return cloneElement(child, {
                    className: twMerge(
                        child.props.className,
                        'focus-visible:z-10',
                        index !== 0 &&
                            /*tw:*/ 'rounded-l-none ml-px relative before:absolute before:top-0 before:-left-px before:w-px before:h-full before:bg-white/40 focus-visible:before:hidden',
                        index !== children.length - 1 && /*tw:*/ 'rounded-r-none'
                    ),
                })
            })}
        </div>
    )
}

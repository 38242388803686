import { useEffect, useRef, useState } from 'react'
import { EditorContent } from '@tiptap/react'
import { Editor } from '@tiptap/core'
import clsx from 'clsx'

import { useAutoFocusedRef, useDebounced, useSyncedInputState } from '@laserfocus/ui/util-react'
import { ConfirmPrompt } from '@laserfocus/ui/beam'
import { usePrevious } from '@laserfocus/ui/hooks'

import { NoteToolbar } from './NoteToolbar/NoteToolbar'
import { useNoteEditor } from './useNoteEditor'

const UPDATE_DELAY = 5000

export interface NoteProps {
    title: string
    bodyHtml: string
    isPrivate: boolean
    isPinned: boolean
    setTitle(title: string): void
    setBodyHtml(bodyHtml: string): void
    deleteNote(): void
    toggleIsPrivate(): void
    toggleIsPinned(): void
    focusOnMount?: boolean
    canOpenNote: boolean
    openNote(): void
    /**
     * CurrentId switches from optimisticId to Id, when
     * the note is persisted
     */
    currentId?: string
    applyTemplate(body: string): void
}

export function Note({
    title,
    bodyHtml,
    isPrivate,
    isPinned,
    setTitle,
    setBodyHtml,
    deleteNote,
    toggleIsPrivate,
    toggleIsPinned,
    focusOnMount,
    currentId,
    applyTemplate,
    canOpenNote,
    openNote,
}: NoteProps) {
    const debouncedSetBodyHtml = useDebounced(
        (editor: Editor) => setBodyHtml(editor.getHTML()),
        UPDATE_DELAY
    )
    const previousCurrentId = usePrevious(currentId, currentId)
    const isFocusedRef = useRef(false)
    const focusFlushTimeoutRef = useRef<number>()
    const [wantsToDeleteNote, setWantsToDeleteNote] = useState(false)
    const [isNoteTemplateSelectOpen, setIsNoteTemplateSelectOpen] = useState(false)

    const editor = useNoteEditor({
        content: bodyHtml,
        noPlaceholder: true,

        onUpdate({ editor }) {
            window.clearTimeout(focusFlushTimeoutRef.current)
            debouncedSetBodyHtml(editor)
        },
        onFocus() {
            window.clearTimeout(focusFlushTimeoutRef.current)
            isFocusedRef.current = true
        },
        onBlur() {
            // We need to flush in a timeout which gets cleared on focus because toolbar button click causes blur and focus of editor in quick succesion
            focusFlushTimeoutRef.current = window.setTimeout(debouncedSetBodyHtml.flush, 250)
            isFocusedRef.current = false
        },
    })

    useEffect(() => {
        /**
         * CurrentId is changed, when the note switches from non persisted to persisted
         * When this happens, the persisted note has usually an old value of the body,
         * so we need to set update the new note with the current body
         */
        if (currentId && previousCurrentId !== currentId && editor) {
            setBodyHtml(editor.getHTML())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentId, editor])

    useEffect(() => {
        /**
         * When the persisted note is received and the editor is not focused, we would temporarily set
         * the body empty until the previous effect updating the body from in memory did his roundtrip.
         * This leads to a flicker, in order to prevent this, we are skipping this update by checking if the currentId
         * has changed.
         */
        if (!isFocusedRef.current && previousCurrentId === currentId) {
            editor?.commands.setContent(bodyHtml)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, bodyHtml])

    useEffect(() => debouncedSetBodyHtml.flush, [debouncedSetBodyHtml])
    if (!editor) {
        return null
    }

    return (
        <div
            className={clsx(
                'group rounded-[0.625rem] p-2 transition',
                isNoteTemplateSelectOpen ? 'bg-grey-700/5' : 'hover:bg-grey-700/5 focus-within:ring'
            )}
        >
            <div className="flex flex-col-reverse justify-between pb-1 mb-1 sm:items-center sm:flex-row sm:border-0 border-b-[1px] border-grey-700/10 ">
                <NoteTitle
                    title={title}
                    setTitle={setTitle}
                    focusOnMount={focusOnMount}
                    currentId={currentId}
                    previousId={previousCurrentId}
                />
                <NoteToolbar
                    className="self-end"
                    editor={editor}
                    deleteNote={() => {
                        const currentBody = editor.getText()
                        if (!title && !currentBody) {
                            deleteNote()
                        } else {
                            setWantsToDeleteNote(true)
                        }
                    }}
                    isPrivate={isPrivate}
                    toggleIsPrivate={toggleIsPrivate}
                    isPinned={isPinned}
                    toggleIsPinned={toggleIsPinned}
                    applyTemplate={applyTemplate}
                    isNoteTemplateSelectOpen={isNoteTemplateSelectOpen}
                    setIsNoteTemplateSelectOpen={setIsNoteTemplateSelectOpen}
                    canOpenNote={canOpenNote}
                    openNote={openNote}
                />
            </div>
            <div className="relative">
                <EditorContent editor={editor} />
                {editor?.isEmpty && (
                    <div className="text-sm font-medium text-grey-700/40 absolute top-0 pointer-events-none">
                        {'Write something or '}
                        <span
                            className="underline pointer-events-auto hover:text-grey-700 transition outline-none focus-visible:ring rounded-md p-0.5 -m-0.5"
                            role="button"
                            tabIndex={0}
                            onClick={() => setIsNoteTemplateSelectOpen(true)}
                        >
                            apply a template
                        </span>
                        .
                    </div>
                )}
            </div>
            <ConfirmPrompt
                show={wantsToDeleteNote}
                title="Delete Note"
                description="Are you sure you want to delete the note?"
                submitButtonTitle="Delete"
                onCancel={() => setWantsToDeleteNote(false)}
                onSubmit={() => {
                    setWantsToDeleteNote(false)
                    deleteNote()
                }}
            />
        </div>
    )
}

interface NoteTitleProps {
    title: string
    setTitle(title: string): void
    focusOnMount?: boolean
    currentId?: string
    previousId?: string
}

function NoteTitle({ title, setTitle, focusOnMount, previousId, currentId }: NoteTitleProps) {
    const previousTitle = usePrevious(title, title)
    const inputState = useSyncedInputState(
        currentId === previousId ? title : previousTitle,
        setTitle,
        {
            debounceTimeout: UPDATE_DELAY,
        }
    )

    useEffect(() => {
        /**
         * CurrentId is changed, when the note switches from non persisted to persisted
         * When this happens, the persisted note has usually an old value of the body,
         * so we need to set update the new note with the current body
         */
        if (currentId && previousId !== currentId) {
            setTitle(previousTitle)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentId])

    const inputRef = useAutoFocusedRef<HTMLInputElement>(focusOnMount)

    return (
        <input
            ref={inputRef}
            className="font-semibold leading-[1.4] outline-none w-full bg-transparent placeholder:text-grey-700/40"
            placeholder="Add a title"
            aria-label="Add a title"
            {...inputState}
            onFocus={() => {
                inputState.onFocus()
                if (inputState.value === '<untitled>') {
                    inputState.onChange({ target: { value: '' } })
                }
            }}
            onBlur={() => {
                if (!inputState.value) {
                    inputState.onChange({ target: { value: '<untitled>' } })
                }
                inputState.onBlur()
            }}
        />
    )
}

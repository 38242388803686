import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema } from './date.types'

export function makeBaseRecordSchema(name: string) {
    return z.object({
        createdDate: DateTimeStringSchema,
        modifiedDate: DateTimeStringSchema,
        deleted: z.boolean().nullish(),
        __typename: z.literal(name),
    })
}

export const CreateOptimisticSchema = z.object({
    createdDate: DateTimeStringSchema,
    modifiedDate: DateTimeStringSchema,
})

export const UpdateOptimisticSchema = z.object({
    modifiedDate: DateTimeStringSchema,
})

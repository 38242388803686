import { Fragment, useState } from 'react'
import { Control } from 'react-hook-form'
import { matchSorter } from 'match-sorter'

import { Button, Modal } from '@laserfocus/ui/beam'
import { FieldMetadata } from '@laserfocus/client/model'
import { CollapseOutlinedIcon, ExpandOutlinedIcon } from '@laserfocus/ui/icons'
import { AccountModel, ContactModel, LeadModel, OpportunityModel } from '@laserfocus/shared/models'

import { getFormControl } from '../get-form-control'

type SalesObject = LeadModel | AccountModel | ContactModel | OpportunityModel

interface FieldList {
    heading?: React.ReactNode
    fields: FieldMetadata[]
    isMandatory?: boolean
}

interface FieldsSectionProps {
    name: string
    control: Control<SalesObject>
    children?: React.ReactNode
    fieldsLists: FieldList[]
    sectionIndex: number
    amountOfSections: number
    salesObject: SalesObject
    isExpanded: boolean | undefined
    allowFieldSearch?: boolean
    toggleExpanded?(): void
}

export function FieldsSection({
    name,
    control,
    fieldsLists,
    sectionIndex,
    amountOfSections,
    salesObject,
    allowFieldSearch,
    isExpanded,
    toggleExpanded,
}: FieldsSectionProps) {
    const [filterValue, setFilterValue] = useState('')

    const fieldsListsToRender = isExpanded ? getFieldsLists(fieldsLists, control, filterValue) : []

    return (
        <>
            <Modal.StickyHeading
                headingIndex={sectionIndex}
                amountOfHeadings={amountOfSections}
                rightSide={
                    toggleExpanded ? (
                        <Button<'button'>
                            variant="tertiary"
                            size="small"
                            iconComponent={isExpanded ? CollapseOutlinedIcon : ExpandOutlinedIcon}
                            onClick={(event) => {
                                event.stopPropagation()
                                toggleExpanded()
                            }}
                        >
                            {isExpanded ? 'Collapse' : 'Expand'}
                        </Button>
                    ) : null
                }
                forcePosition={isExpanded ? undefined : Modal.StickyHeading.Position.StickyBottom}
            >
                {name}
            </Modal.StickyHeading>
            {allowFieldSearch && isExpanded && (
                <Modal.SearchInput
                    placeholder={`Filter ${salesObject.__typename} fields`}
                    value={filterValue}
                    onChange={(event) => setFilterValue(event.target.value)}
                    reset={() => setFilterValue('')}
                    className="mt-4 mb-2"
                />
            )}
            {fieldsListsToRender.length !== 0 && (
                <div className="grid gap-4 py-4 px-8">{fieldsListsToRender}</div>
            )}
        </>
    )
}

function getFieldsLists(
    fieldsLists: FieldList[],
    control: Control<SalesObject>,
    filterValue: string
) {
    return fieldsLists
        .map(({ heading, isMandatory, fields }, index) => {
            const formFields = getFormFields(fields, control, filterValue)

            if (!isMandatory && formFields.length === 0) {
                return null
            }

            return (
                <Fragment key={index}>
                    {heading}
                    {fields.length !== 0 && (
                        <div className="grid grid-cols-[1fr,2fr] items-start gap-y-2">
                            {formFields}
                        </div>
                    )}
                </Fragment>
            )
        })
        .filter(Boolean) as JSX.Element[]
}

function getFormFields(
    fields: FieldMetadata[],
    control: Control<SalesObject>,
    filterValue: string
) {
    const relevantFields = filterValue
        ? matchSorter(fields, filterValue, { keys: ['label'] })
        : fields
    return relevantFields
        .map((field) => {
            const id = `ErrorForm-${field.Id}`
            const formControl = getFormControl({
                fieldMetadata: field,
                id,
                variant: 'border',
                control,
            })

            return formControl ? (
                <Fragment key={field.Id}>
                    <label
                        htmlFor={id}
                        // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
                        className="text-sm leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
                    >
                        {field.label}
                    </label>
                    {formControl}
                </Fragment>
            ) : null
        })
        .filter(Boolean) as JSX.Element[]
}

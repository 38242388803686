import { useLocation } from 'react-router'
import { Component } from 'react'

import { logger } from '@laserfocus/ui/logger'

import { UnexpectedError } from './UnexpectedError'

type ErrorBoundaryProps = React.PropsWithChildren<{ path: string }>
type ErrorBoundaryState = {
    hasError: boolean
    path?: string
    error?: Error
}

export default function ErrorBoundaryContainer({ children }: { children: React.ReactNode }) {
    const location = useLocation()
    return <ErrorBoundary path={location.pathname}>{children}</ErrorBoundary>
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, path: window.location.pathname }
    }
    componentDidCatch(error: Error, errorInfo) {
        // Display fallback UI
        // if (isRedirect(error)) {
        //   return true;
        // }
        logger.fatal(error, errorInfo)
    }
    componentDidUpdate() {
        if (this.state.hasError && this.props.path !== this.state.path) {
            this.setState({ hasError: false, path: null, error: null })
        }
    }

    render() {
        if (this.state.hasError) {
            return <UnexpectedError />
        }
        return this.props.children
    }
}

export function CellsLoading({ amount = 5 }: { amount?: number }) {
    const r = new Array(amount).fill('')
    return (
        <div className="w-full grid gap-6 py-3">
            {r.map((a, idx) => (
                <div className="flex" key={idx}>
                    <div className="w-[1.875rem] h-[1.875rem] rounded-full bg-grey-700/5 mx-6" />
                    <div
                        className="rounded-lg bg-grey-700/5 h-[30px] w-full"
                        aria-busy="true"
                        aria-live="polite"
                    ></div>
                </div>
            ))}
        </div>
    )
}

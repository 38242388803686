import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function SettingsOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8 4a1 1 0 00-2 0v6.13a4 4 0 000 7.74V20a1 1 0 102 0v-2.13a4 4 0 000-7.74V4zm1 10a2 2 0 11-4 0 2 2 0 014 0zM16 13.87V20a1 1 0 102 0v-6.13a4 4 0 000-7.74V4a1 1 0 10-2 0v2.13a4 4 0 000 7.74zM17 12a2 2 0 100-4 2 2 0 000 4z"
                fillRule="evenodd"
            />
        </SvgIcon>
    )
}

export const STORAGE_TOKEN_KEY = 'lf:token'
export const STORAGE_USERID_KEY = 'sf:user_id'
export const STORAGE_REFRESH_TOKEN_KEY = 'lf:refreshToken'

export function receiveToken(token: string, refreshToken?: string) {
    localStorage.setItem(STORAGE_TOKEN_KEY, token)
    if (refreshToken) {
        localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, refreshToken)
    }
}

export function clearUser() {
    clearTokens()
    if ((window as any).Cypress) {
        throw new Error('clearLocalStorage is called and it should not during e2e tests.')
    }
    const keys = Object.keys(localStorage)
    const lfKeys = keys.filter((key) => key.indexOf('lf:') === 0)
    lfKeys.forEach((key) => {
        localStorage.removeItem(key)
    })
}

export function clearTokens() {
    localStorage.removeItem(STORAGE_TOKEN_KEY)
    localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY)
    localStorage.removeItem(STORAGE_USERID_KEY)
}

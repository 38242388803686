import { isPlainObject } from 'lodash'
export type ErrorBehaviour = {
    /**
     * Operational Errors are expected errors,
     * that are not caused by the programmer.
     */
    isOperational?: boolean
    // isTemporary: boolean
    isUserInput?: boolean
    // isHandled: boolean
    /**
     * Indicates whether the error is temprorary and the caller can retry it
     */
    isUnauthenticated?: boolean

    /**
     * Should have the same effect as isUnauthenticated,
     * just that we want to log it to be more aware of it
     */
    isBlocked?: boolean
}

export function isUnauthenticated(e: Error | ErrorBehaviour) {
    return (e as ErrorBehaviour).isUnauthenticated
}

export abstract class BaseError<ExtensionType extends Record<string, any> = Record<string, any>>
    extends Error
    implements ErrorBehaviour
{
    abstract isOperational?: boolean
    extensions?: ExtensionType
    constructor(message: string, extensions?: ExtensionType) {
        const formattedMessage = printf(message, flattenObj(extensions ?? {}))
        super(formattedMessage)
        this.extensions = extensions
        this.name = ['BaseError', 'WrappedBaserror'].includes(this.constructor.name)
            ? 'Error'
            : this.constructor.name
    }
}

type Cause = Error & ErrorBehaviour

export abstract class WrappedBaseError<
        ExtensionType extends Record<string, any> = Record<string, any>
    >
    extends BaseError<ExtensionType>
    implements ErrorBehaviour
{
    isOperational?: boolean
    isUnauthenticated?: boolean
    cause: Error
    constructor(message: string, cause: Cause, extensions?: ExtensionType) {
        super(message, extensions)
        this.cause = cause
        Object.defineProperty(this, 'cause', {
            writable: false,
            enumerable: false,
            configurable: false,
        })

        this.isUnauthenticated = cause.isUnauthenticated
        this.isOperational = cause.isOperational
    }
    serialize() {
        const obj = Object.assign({}, this)
        obj.stack = fullStack(this)
        return obj
    }
}

function printf(str: string, args: Record<string, any>): string {
    for (let key in args) {
        const value = args[key]
        str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), value)
    }
    return str
}

function flattenObj(obj: Record<string, any>, keys: string[] = []): Record<string, any> {
    return Object.keys(obj).reduce((acc, key) => {
        return Object.assign(
            acc,
            isPlainObject(obj[key])
                ? flattenObj(obj[key], keys.concat(key))
                : { [keys.concat(key).join('.')]: obj[key] }
        )
    }, {})
}

const SEPARATOR_TEXT = `\n\nThe following exception was the direct cause of the above exception:\n\n`

function fullStack(error: Error | BaseError) {
    const chain: Error[] = []
    let cause: Error | undefined = error

    while (cause) {
        chain.push(cause)
        cause = (cause as WrappedBaseError).cause
    }

    // @TODO: also clean those https://github.com/sindresorhus/clean-stack
    return chain.map((err) => err.stack).join(SEPARATOR_TEXT)
}

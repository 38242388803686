import { Link } from 'react-router-dom'
import { useSubscribe } from 'replicache-react'
import { ReadTransaction } from 'replicache'
import { parseISO } from 'date-fns'

import {
    AccountModel,
    ContactModel,
    LeadModel,
    OpportunityModel,
    User,
    isGroupId,
    Prefix,
    Group,
} from '@laserfocus/shared/models'
import { Button, Modal } from '@laserfocus/ui/beam'
import { makePersonDetailsPath } from '@laserfocus/client/util-routing'
import { getClient } from '@laserfocus/client/replicache'
import { AccountFilledIcon, ContactFilledIcon, OpportunityFilledIcon } from '@laserfocus/ui/icons'
import { getDateShort } from '@laserfocus/ui/util-locale'

import { PersonIcon } from '../PersonIcon'

import { Currency, Name, RecordCard, Value } from './RecordCard'

export function ConvertedSection({
    lead,
    closeModal,
}: {
    lead: LeadModel
    closeModal: () => void
}) {
    return (
        <>
            <div className="max-h-[60vh] overflow-y-auto grid gap-4 py-8 px-8 items-stretch">
                <div className="w-full flex flex-col items-center">
                    {lead.ConvertedAccountId ? (
                        <PersonIcon
                            id={lead.ConvertedAccountId}
                            className="w-32 h-32 min-h-32 text-7xl"
                        />
                    ) : (
                        <>
                            <PersonIcon id={lead.Id} className="w-32 h-32 min-h-32 text-7xl" />
                        </>
                    )}
                </div>
                <div className="grid gap-4 columns-1">
                    {lead.ConvertedAccountId && <AccountCard accountId={lead.ConvertedAccountId} />}
                    {lead.ConvertedContactId && <ContactCard contactId={lead.ConvertedContactId} />}
                    {lead.ConvertedOpportunityId && (
                        <OpportunityCard opportunityId={lead.ConvertedOpportunityId} />
                    )}
                </div>
            </div>
            <Modal.Footer className="justify-end">
                <Button onClick={closeModal}>Close</Button>
                {lead.ConvertedAccountId && (
                    <Button
                        as={Link}
                        variant="primary"
                        to={makePersonDetailsPath(lead.ConvertedAccountId!, {
                            contactId: lead.ConvertedContactId,
                            opportunityId: lead.ConvertedOpportunityId,
                        })}
                    >
                        Go to new Record
                    </Button>
                )}
            </Modal.Footer>
        </>
    )
}

function AccountCard({ accountId }: { accountId: string }) {
    const { record, owner } = useSubscribe(
        getClient(),
        async (tx: ReadTransaction) => queryRecordWithOwner<AccountModel>(tx, accountId, 'Account'),
        {},
        [accountId]
    )
    if (!record) {
        return null
    }
    return (
        <RecordCard title="Account" icon={<AccountFilledIcon className="w-6 h-6" />}>
            <Name>Name</Name>
            <Value>{record.Name}</Value>
            <Name>Owner</Name>
            <Value>{owner?.Name || record.OwnerId}</Value>
        </RecordCard>
    )
}

export function ContactCard({ contactId }: { contactId: string }) {
    const { record, owner } = useSubscribe(
        getClient(),
        async (tx: ReadTransaction) => queryRecordWithOwner<ContactModel>(tx, contactId, 'Contact'),
        {},
        [contactId]
    )
    if (!record) {
        return null
    }
    return (
        <RecordCard title="Contact" icon={<ContactFilledIcon className="w-6 h-6" />}>
            <Name>Name</Name>
            <Value>{record.Name}</Value>
            <Name>Owner</Name>
            <Value>{owner?.Name || record.OwnerId}</Value>
        </RecordCard>
    )
}

export function OpportunityCard({ opportunityId }: { opportunityId: string }) {
    const { record, owner } = useSubscribe(
        getClient(),
        async (tx: ReadTransaction) =>
            queryRecordWithOwner<OpportunityModel>(tx, opportunityId, 'Opportunity'),
        {},
        [opportunityId]
    )
    if (!record) {
        return null
    }
    return (
        <RecordCard title="Opportunity" icon={<OpportunityFilledIcon className="w-6 h-6" />}>
            <Name>Name</Name>
            <Value>{record.Name}</Value>
            {record.Amount && (
                <>
                    <Name>Amount</Name>
                    <Value>
                        <Currency value={record.Amount} currencyIsoCode={record.CurrencyIsoCode} />
                    </Value>
                </>
            )}
            {record.CloseDate && (
                <>
                    <Name>Close Date</Name>
                    <Value>{getDateShort(parseISO(record.CloseDate))}</Value>
                </>
            )}
            <Name>Owner</Name>
            <Value>{owner?.Name || record.OwnerId}</Value>
        </RecordCard>
    )
}

async function queryRecordWithOwner<
    RecordModel extends OpportunityModel | ContactModel | AccountModel
>(tx: ReadTransaction, recordId: string, recordType: 'Opportunity' | 'Contact' | 'Account') {
    const recordKey = [recordType.toLowerCase(), recordId].join('/')
    const record = await (tx.get(recordKey) as Promise<RecordModel | null>)
    if (!record) {
        return {}
    }
    if (!record.OwnerId) {
        return {
            record,
        }
    }
    const ownerKey = [isGroupId(record.OwnerId) ? Prefix.Group : Prefix.User, record.OwnerId].join(
        '/'
    )
    const owner = await (tx.get(ownerKey) as Promise<User | Group | null>)
    return {
        record,
        owner,
    }
}

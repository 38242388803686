import { ExperimentalDiffOperation } from 'replicache'

export type DeleteOp<T = { Id: string }> = {
    op: 'del'
    key: string
    oldValue: T
}

export type ChangeOp<T = { Id: string }> = {
    op: 'change'
    key: string
    oldValue: T
    newValue: T
}

export type AddOp<T = { Id: string }> = {
    op: 'add'
    key: string
    newValue: T
}

export function isDeleteOp<T extends { Id: string } = { Id: string }>(
    op: ExperimentalDiffOperation<string>
): op is DeleteOp<T> {
    return op.op === 'del'
}

export function isChangeOp<T extends { Id: string } = { Id: string }>(
    op: ExperimentalDiffOperation<string>
): op is ChangeOp<T> {
    return op.op === 'change'
}
export function isAddOp<T extends { Id: string } = { Id: string }>(
    op: ExperimentalDiffOperation<string>
): op is AddOp<T> {
    return op.op === 'add'
}

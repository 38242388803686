import { useState } from 'react'

import { Button } from '@laserfocus/ui/beam'
import { useBodyClassName } from '@laserfocus/ui/util-react'

export function CrashScreen() {
    const [shouldCrash, setShouldCrash] = useState(false)
    useBodyClassName('bg-grey-700')

    if (shouldCrash) {
        throw Error('Someone pressed the crash button')
    }

    return (
        <div className="h-screen bg-grey-700 text-white grid place-items-center">
            <Button onClick={() => setShouldCrash(!shouldCrash)}>Crash 💣</Button>
        </div>
    )
}

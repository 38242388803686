import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useController, Path, useWatch } from 'react-hook-form'

import { MultiSelect, DropdownBaseInput } from '@laserfocus/ui/beam'

import { FormControlProps, SalesObject } from '../get-form-control'
import { useFormControlContext } from '../FormControlContext'

import { getControllerRules } from './formcontrol-util'

export const FormControlMultipicklist = observer(function FormControlMultipicklist<
    T extends SalesObject = SalesObject
>({ fieldMetadata, id, variant, control, submit, name }: FormControlProps<T>) {
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false)
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })
    const { recordTypeId } = useFormControlContext()
    const formRecordType = useWatch({
        name: 'RecordTypeId' as Path<T>,
        control,
    }) as string | undefined

    const labelsByValue = fieldMetadata.labelsByValue

    const options = fieldMetadata
        .getPicklistOptions(formRecordType || recordTypeId)
        .map((option) => ({ ...option, label: option.label ?? '' }))

    const currentValues = parseValue(value as string[] | string | null)

    const currentLabels = currentValues.map((v) => labelsByValue[v])

    const allOptionsByValue = Object.fromEntries(
        fieldMetadata.valueOptions.map((o) => [o.value, o])
    )
    const existingOptionValues = options.map((o) => o.value)
    const missingOptions = currentValues
        .filter((value) => !existingOptionValues.includes(value))
        .map((missingValue) => {
            const option = allOptionsByValue[missingValue]
            if (option) {
                return { ...option, label: option.label ?? '' }
            }
            return {
                label: missingValue ?? '',
                value: missingValue,
            }
        })
    const allOptions = [...options, ...missingOptions]

    return (
        <MultiSelect
            isOpen={isMultiSelectOpen}
            options={allOptions}
            initialOptionValues={currentValues}
            onCancel={() => setIsMultiSelectOpen(false)}
            onSubmit={(options) => {
                onChange(options.map(({ value }) => value))
                submit?.()
            }}
            searchKeys={['label']}
            size="large"
        >
            <DropdownBaseInput
                id={id}
                variant={variant}
                forceFocused={isMultiSelectOpen}
                onClick={() => setIsMultiSelectOpen(true)}
                disabled={!fieldMetadata.updateable}
                value={[...currentLabels].join('; ')}
                error={(error as any)?.message}
            />
        </MultiSelect>
    )
})

function parseValue(v: string[] | string | null | undefined): string[] {
    if (typeof v === 'string') {
        return v.split(';')
    }
    return v || []
}

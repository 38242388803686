import { useLocation, useResolvedPath } from 'react-router'

type IsActiveProps = {
    caseSensitive?: boolean
    end?: boolean
}

export function useIsLinkActive(href: string, options?: IsActiveProps) {
    let location = useLocation()
    let path = useResolvedPath(href)
    let locationPathname = location.pathname
    let toPathname = path.pathname

    const { caseSensitive = false, end = false } = options || {}

    if (!caseSensitive) {
        locationPathname = locationPathname.toLowerCase()
        toPathname = toPathname.toLowerCase()
    }

    let isActive =
        locationPathname === toPathname ||
        (!end &&
            locationPathname.startsWith(toPathname) &&
            locationPathname.charAt(toPathname.length) === '/')

    return isActive
}

import { Fragment } from 'react'
import clsx from 'clsx'

interface CommandLineBreadCrumbsProps {
    breadCrumbs: CommandLineBreadCrumb[]
}
export interface CommandLineBreadCrumb {
    key: string | number
    label: string
    onClick?(): void
}

const BREADCRUMB_STYLES = 'py-0.5 px-1 inline-block'

export function CommandLineBreadCrumbs({ breadCrumbs }: CommandLineBreadCrumbsProps) {
    return (
        <div
            data-testid="commandline-breadcrumbs"
            className="pt-[0.875rem] px-3 -mb-0.5 text-white/40 text-sm leading-[1.4]"
        >
            {breadCrumbs.map(({ key, label, onClick }, index) =>
                index < breadCrumbs.length - 1 ? (
                    <Fragment key={key}>
                        <BreadCrumbButton onClick={onClick} disabled={!onClick} title={label}>
                            {label}
                        </BreadCrumbButton>
                        <span key={`separator-after-${key}`} className="px-1 inline-block">
                            ›
                        </span>
                    </Fragment>
                ) : (
                    <h1 key={key} className={clsx(BREADCRUMB_STYLES, 'text-white/60')}>
                        {label}
                    </h1>
                )
            )}
        </div>
    )
}

function BreadCrumbButton(props: React.ComponentPropsWithoutRef<'button'>) {
    return (
        <button
            {...props}
            className={clsx(
                BREADCRUMB_STYLES,
                'overflow-hidden max-w-[9.375rem] whitespace-nowrap text-ellipsis rounded-md outline-none z-10 focus-visible:ring',
                !props.disabled && 'hover:bg-white/5'
            )}
        />
    )
}

import { isMobile } from 'is-mobile'
import { useEffect } from 'react'

import { toast } from '@laserfocus/ui/beam'

let didShow = false
export function useMobileWarning() {
    useEffect(() => {
        if (isMobile() && window.screen.width < 700 && !didShow) {
            didShow = true
            toast.warn({
                title: "We don't support mobile yet.",
                description:
                    'Please consider using laserfocus on your work machine, in order to get the full value.',
            })
        }
    }, [])
}

import { useState } from 'react'

import { Column, ColumnFilterButton } from '@laserfocus/client/feature-table'
import { HintDisplay } from '@laserfocus/client/feature-everboarding'

import { Hint, useTableHintsContext } from '../TableHintsContext'

interface TableHeadCellProps {
    column: Column
    shouldShowHint: boolean
}

export function TableHeadCell({ column, shouldShowHint }: TableHeadCellProps) {
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
    const { currentHint, hideHint } = useTableHintsContext()

    const shouldShowHintForReal = shouldShowHint && currentHint === Hint.SetFilter

    function handleSetFilterDropdownOpen(isOpen: boolean) {
        setIsFilterDropdownOpen(isOpen)
        hideHint(isOpen ? Hint.SetFilter : Hint.CloseFilterDropdown)
    }

    return (
        <th
            data-testid={`table-head-${column.key}`}
            className="group leading-[1.3] font-medium text-grey-700/60 text-left p-2 grid grid-cols-1 grid-flow-col items-center"
        >
            <div
                title={column.label}
                className="px-2 py-1 whitespace-nowrap overflow-x-hidden text-ellipsis"
            >
                {column.label}
            </div>
            <HintDisplay
                isActive={shouldShowHintForReal}
                label="Set filter"
                containerClassName="-translate-y-8 -translate-x-1"
                labelClassName="rotate-12 translate-y-3 ml-3"
                onClick={() => handleSetFilterDropdownOpen(true)}
            >
                <ColumnFilterButton
                    column={column}
                    isFilterDropdownOpen={isFilterDropdownOpen}
                    setIsFilterDropdownOpen={handleSetFilterDropdownOpen}
                />
            </HintDisplay>
        </th>
    )
}

// import { omit } from 'lodash'

type TraceGroup = {
    count: number
    time: number
    itemCount: number
    handle?: NodeJS.Timeout
}

const traces: Record<string, TraceGroup> = {}
type TraceOptions = {
    name: string
    itemCount?: number
}

export function trace<T>(fn: () => T, options: TraceOptions) {
    const { name, itemCount } = options
    const start = Date.now()
    const res = fn()
    const end = Date.now()
    const timing = end - start
    if (!traces[name]) {
        traces[name] = {
            count: 1,
            time: timing,
            // handle: setTimeout(() => console.log(name, traces[name]), 3000),
            itemCount: itemCount || 0,
        }
    } else {
        traces[name].count++
        traces[name].time += timing
        traces[name].itemCount += itemCount || 0
        traces[name].handle && clearTimeout(traces[name].handle)
        // traces[name].handle = setTimeout(
        //     () =>
        //         console.log(
        //             name,
        //             'avg',
        //             Math.round(traces[name].time / traces[name].count),
        //             omit(traces[name], 'handle')
        //         ),
        //     3000
        // )
    }
    return res
}

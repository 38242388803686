import { useLayoutEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import { Button } from '@laserfocus/ui/beam'
import { CollapseOutlinedIcon, ExpandOutlinedIcon } from '@laserfocus/ui/icons'
import { theme } from '@laserfocus/ui/tailwindcss'

interface ExpandableTextProps {
    children: React.ReactNode
    className?: string
}

const COLLAPSED_HTML_HEIGHT = 100

export function ExpandableText({ children, className }: ExpandableTextProps) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [canExpand, setCanExpand] = useState(false)
    const containerRef = useRef<HTMLParagraphElement>(null)

    useLayoutEffect(() => {
        const clientHeight = containerRef.current?.clientHeight || 0
        if (clientHeight > COLLAPSED_HTML_HEIGHT) {
            setCanExpand(true)
        }
    }, [])

    return (
        <div className={clsx('relative', className)}>
            <div
                className={clsx(
                    'overflow-hidden text-grey-700/70',
                    canExpand &&
                        !isExpanded &&
                        'bg-clip-text [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] bg-[linear-gradient(theme(colors.grey.700)_40%,transparent)] opacity-70'
                )}
                style={
                    canExpand && !isExpanded
                        ? {
                              maxHeight: COLLAPSED_HTML_HEIGHT,
                          }
                        : undefined
                }
            >
                <div ref={containerRef} className="text-sm whitespace-pre-wrap tabular-nums">
                    {children}
                </div>
            </div>
            {canExpand &&
                (isExpanded ? (
                    <div className="grid place-items-center pt-2">
                        <Button
                            size="small"
                            iconComponent={CollapseOutlinedIcon}
                            onClick={() => setIsExpanded(false)}
                            className="transition-shadow"
                            style={{ WebkitTextFillColor: theme.colors.grey[700] }}
                        >
                            Collapse
                        </Button>
                    </div>
                ) : (
                    <div className="sr-only group-hover:not-sr-only group-focus-within:not-sr-only">
                        <Button
                            size="small"
                            iconComponent={ExpandOutlinedIcon}
                            onClick={() => setIsExpanded(true)}
                            className="absolute bottom-0 left-1/2 -translate-x-1/2 transition-shadow"
                            style={{ WebkitTextFillColor: theme.colors.grey[700] }}
                        >
                            {isExpanded ? 'Collapse' : 'Expand'}
                        </Button>
                    </div>
                ))}
        </div>
    )
}

import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema } from '../base/date.types'
import { AccountIdSchema, LeadIdSchema } from '../base/id.types'

const emojiRegex = require('emoji-regex/RGI_Emoji.js')

/**
 * For some reason, the normal 'g' flag does not allow a second match for the
 * same emoji
 */
export const emojiMatch = new RegExp(emojiRegex().source)

export const salesExtensionSchema = z.object({
    id: z.union([LeadIdSchema, AccountIdSchema]),
    emoji: z.string().regex(emojiMatch, 'This is not an emoji'),
    lastModifiedDate: DateTimeStringSchema,
    __typename: z.literal('SalesObjectExtension').default('SalesObjectExtension'),
})

export type SalesObjectExtension = z.infer<typeof salesExtensionSchema>

export const pinnedActivitiesSchema = z.object({
    id: z.union([LeadIdSchema, AccountIdSchema]),
    activities: z.array(z.string()),
    notes: z.array(z.string()),
    lastModifiedDate: DateTimeStringSchema,
})

export type PinnedActivities = z.infer<typeof pinnedActivitiesSchema>

export const SalesObjectExtensionMutatorSchema = {
    updateEmoji: z.object({
        id: z.union([LeadIdSchema, AccountIdSchema]),
        emoji: z.string().regex(emojiMatch, 'This is not an emoji'),
    }),
}

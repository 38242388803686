import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type HeadingWithIconProps = PropsWithChildren<{
    as?: `h${1 | 2 | 3 | 4 | 5 | 6}`
    iconComponent: React.ComponentType<{ className?: string }>
    className?: string
}>

export function HeadingWithIcon({
    as: Element = 'h2',
    iconComponent: Icon,
    children,
    ...props
}: HeadingWithIconProps) {
    return (
        <div {...props} className={twMerge('flex items-start', props.className)}>
            <div className="p-[0.4375rem] rounded-[0.625rem] bg-grey-700/5">
                <Icon className="w-5 h-5" />
            </div>
            <Element className="pl-3 pt-[0.375rem] text-lg font-bold font-serif leading-[1.2]">
                {children}
            </Element>
        </div>
    )
}

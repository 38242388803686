import clsx from 'clsx'

import { FavoriteFilledIcon, DataLimitOutlinedIcon } from '@laserfocus/ui/icons'
import { Stack } from '@laserfocus/shared/models'
import { Button, SpinnerInline } from '@laserfocus/ui/beam'

interface TableTitleActionsProps {
    stack: Stack
    setIsFavorite(isFavorite: boolean): void
    hasCountMessage: boolean
    openCountMessage: () => void
    isLoadingMore: boolean
}

export function TableTitleActions({
    stack,
    setIsFavorite,
    hasCountMessage,
    openCountMessage,
    isLoadingMore,
}: TableTitleActionsProps) {
    return (
        <div className="grid grid-flow-col gap-3 items-center">
            <div
                className={clsx(
                    'relative transition-colors',
                    stack.isFavorite
                        ? 'text-yellow-300 hover:text-yellow-400'
                        : 'text-grey-700/10 hover:text-grey-700/20'
                )}
            >
                <FavoriteFilledIcon className="absolute inset-0 w-full h-full pointer-events-none" />
                <input
                    title="Favorite"
                    type="checkbox"
                    className="block cursor-pointer w-6 h-6 rounded-md outline-none focus-visible:ring transition appearance-none"
                    checked={stack.isFavorite || false}
                    onChange={(event) => setIsFavorite(event.target.checked)}
                />
            </div>
            {isLoadingMore ? (
                <SpinnerInline
                    backgroundStrokeColor="grey-700/20"
                    spinnerStrokeColor="grey-700/40"
                />
            ) : hasCountMessage ? (
                <Button
                    title="Could not load all records"
                    variant="tertiary"
                    onClick={openCountMessage}
                    iconComponent={DataLimitOutlinedIcon}
                    iconClassName="text-red-500"
                />
            ) : undefined}
        </div>
    )
}

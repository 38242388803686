import { Link } from 'react-router-dom'
import clsx from 'clsx'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Button, ButtonProps } from '../button/Button'

export interface EmptyStateProps {
    variant?: 'light' | 'dark'
    size?: 'large' | 'medium'
    emojis: [string, string, string]
    title: React.ReactNode
    subtitle?: React.ReactNode
    action?: {
        label: string
        href?: string
        icon?: ButtonProps['iconComponent']
        onClick?(): void
    }
    className?: string
    children?: React.ReactNode
}

export function EmptyState({
    variant = 'light',
    size = 'large',
    emojis,
    title,
    subtitle,
    action,
    className,
    children,
}: EmptyStateProps) {
    const isLightVariant = variant === 'light'
    const isLargeSize = size === 'large'
    const isExternal = action && action.href && /^https?:\/\//.test(action.href)

    return (
        <div
            className={twMerge(
                'w-full h-full grid place-items-center',
                isLightVariant ? 'text-grey-700' : 'text-white bg-grey-700',
                className
            )}
        >
            <div
                className={clsx(
                    'p-4 box-content text-center grid justify-items-center',
                    isLargeSize ? 'max-w-[29.375rem] gap-6' : 'max-w-[19.5625rem] gap-4'
                )}
            >
                <div
                    className={clsx(
                        'font-emoji grid gap-y-[0.4375rem] gap-x-4 grid-cols-[70fr,53fr] grid-rows-[40fr,80fr]',
                        isLargeSize ? 'pb-2' : 'pb-4'
                    )}
                >
                    <span
                        className={clsx(
                            'col-span-full text-[1.5625rem] w-10 h-10 rounded-[0.625rem] grid place-items-center ml-[2.3125rem] ring-1 ring-grey-700/5 overflow-hidden',
                            isLightVariant ? 'bg-white' : 'bg-white/10'
                        )}
                        style={
                            {
                                '--tw-shadow': isLightVariant
                                    ? '0px 42.3601px 42.3601px -8.47201px rgba(48, 51, 58, 0.07), 0px 9.46167px 7.56934px rgba(48, 51, 58, 0.0417275), 0px 2.81699px 2.25359px rgba(48, 51, 58, 0.0282725)'
                                    : '0px 56.383px 56.383px -11.2766px rgba(36, 40, 49, 0.17), 0px 12.5939px 10.0751px rgba(36, 40, 49, 0.2417), 0px 3.74953px 2.99962px #242831',
                            } as any
                        }
                    >
                        {emojis[2]}
                    </span>
                    <span
                        className={clsx(
                            'text-[2.8125rem] w-[4.375rem] h-[4.375rem] rounded-2xl grid place-items-center mt-[0.5625rem] ring-1 ring-grey-700/5 overflow-hidden',
                            isLightVariant ? 'bg-white' : 'bg-white/10'
                        )}
                        style={
                            {
                                '--tw-shadow': isLightVariant
                                    ? '0px 74.4681px 74.4681px -14.8936px rgba(48, 51, 58, 0.07), 0px 16.6334px 13.3067px rgba(48, 51, 58, 0.0417275), 0px 4.9522px 3.96176px rgba(48, 51, 58, 0.0282725)'
                                    : '0px 56.383px 56.383px -11.2766px rgba(36, 40, 49, 0.17), 0px 12.5939px 10.0751px rgba(36, 40, 49, 0.2417), 0px 3.74953px 2.99962px #242831',
                            } as any
                        }
                    >
                        {emojis[0]}
                    </span>
                    <span
                        className={clsx(
                            'text-[2.125rem] w-[3.3125rem] h-[3.3125rem] rounded-[0.8125rem] grid place-items-center ring-1 ring-grey-700/5 overflow-hidden',
                            isLightVariant ? 'bg-white' : 'bg-white/10'
                        )}
                        style={
                            {
                                '--tw-shadow': isLightVariant
                                    ? '0px 56.383px 56.383px -11.2766px rgba(48, 51, 58, 0.07), 0px 12.5939px 10.0751px rgba(48, 51, 58, 0.0417275), 0px 3.74953px 2.99962px rgba(48, 51, 58, 0.0282725)'
                                    : '0px 56.383px 56.383px -11.2766px rgba(36, 40, 49, 0.17), 0px 12.5939px 10.0751px rgba(36, 40, 49, 0.2417), 0px 3.74953px 2.99962px #242831',
                            } as any
                        }
                    >
                        {emojis[1]}
                    </span>
                </div>
                <h1 className={clsx('font-serif', isLargeSize ? 'text-3xl' : 'text-xl')}>
                    {title}
                </h1>
                {subtitle && (
                    <p
                        className={clsx(
                            isLargeSize ? 'px-6 text-xl' : 'text-base',
                            isLightVariant ? 'text-grey-700/60' : 'text-white/60'
                        )}
                    >
                        {subtitle}
                    </p>
                )}
                {children}
                {action &&
                    (action.href ? (
                        isExternal ? (
                            <Button
                                as="a"
                                href={action.href}
                                iconComponent={action.icon}
                                onClick={action.onClick}
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {action.label}
                            </Button>
                        ) : (
                            <Button
                                as={Link}
                                to={action.href}
                                iconComponent={action.icon}
                                onClick={action.onClick}
                            >
                                {action.label}
                            </Button>
                        )
                    ) : (
                        <Button iconComponent={action.icon} onClick={action.onClick}>
                            {action.label}
                        </Button>
                    ))}
            </div>
        </div>
    )
}

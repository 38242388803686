import { Checkbox } from '@laserfocus/ui/beam'

interface BulkEditCellProps {
    checked: boolean
    toggle(): void
}

export function BulkEditToggleCell({ checked, toggle }: BulkEditCellProps) {
    return (
        <div className="pr-[6px]">
            <Checkbox
                title="Select activity"
                checked={checked}
                onChange={toggle}
                // We only want to blur when the checkbox was clicked with the mouse as opposed to with the keyboard. We don't want to blur onClick because onClick is triggered by pressing space as well
                onMouseUp={(event) => event.currentTarget.blur()}
            />
        </div>
    )
}

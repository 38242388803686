import { EmptyState } from '@laserfocus/ui/beam'
import { useBodyClassName, useTitle } from '@laserfocus/ui/util-react'

export function UnexpectedError() {
    useTitle('Error')
    useBodyClassName('bg-grey-700')

    return (
        <div className="h-screen">
            <EmptyState
                variant="dark"
                emojis={['🌋', '🌪', '💥']}
                title="Oh no!"
                subtitle="An unexpected error happened. We’re working on fixing this!"
                action={{
                    label: 'Back to overview',
                    href: '/home',
                }}
            />
        </div>
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function SaveOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M5.78 3A2.78 2.78 0 003 5.78v12.44A2.78 2.78 0 005.78 21h12.44A2.78 2.78 0 0021 18.22V8.44a1 1 0 00-.3-.7l-4.44-4.45a1 1 0 00-.7-.29H5.78zm0 2a.78.78 0 00-.78.78v12.44a.78.78 0 00.78.78H7v-6a1 1 0 011-1h8a1 1 0 011 1v6h1.22a.78.78 0 00.78-.78V8.86L15.14 5H9v2h6a1 1 0 110 2H8a1 1 0 01-1-1V5H5.78zM9 14v5h6v-5H9z"
            />
        </SvgIcon>
    )
}

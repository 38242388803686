import clsx from 'clsx'
import { Link } from 'react-router-dom'

import {
    LogoutOutlinedIcon,
    UserGroupOutlinedIcon,
    LaserfocusLogo,
    FolderOutlinedIcon,
    UserOutlinedIcon,
    CopyTextOutlinedIcon,
    TableOutlinedIcon,
    OpportunityOutlinedIcon,
} from '@laserfocus/ui/icons'
import { Button } from '@laserfocus/ui/beam'
import { useIsLinkActive } from '@laserfocus/client/util-routing'
import { useIsUnit21 } from '@laserfocus/client/feature-auth'

import { useRoleIssueCount } from '../roles/useUserWithoutRoleCount'

export function SettingsSidebar({ hideAdmin }: { hideAdmin?: boolean }) {
    return (
        <nav className="h-full flex flex-col flex-grow border-r border-grey-700/20 pt-6 pb-4 bg-white">
            <div className="flex items-center flex-shrink-0 px-6">
                <Link
                    to="/home"
                    className="font-serif inline-grid gap-2 grid-flow-col justify-start items-center font-bold text-base leading-none rounded-[0.7rem] p-1 -m-1"
                >
                    <LaserfocusLogo className="h-6 w-6" />
                    laserfocus
                </Link>
            </div>
            <div className="mt-5 flex-grow flex flex-col">
                <div className="flex-1 px-2 bg-white space-y-1" aria-label="Sidebar">
                    {hideAdmin ? null : <AdminMenu />}
                </div>
            </div>
            <div className="flex-shrink-0 block w-full px-2">
                <SecondaryMenuItem
                    label="Logout"
                    iconComponent={LogoutOutlinedIcon}
                    href="/logout"
                />
            </div>
        </nav>
    )
}

function AdminMenu() {
    const roleIssueCount = useRoleIssueCount()
    const { isLoading, isUnit21 } = useIsUnit21()
    return (
        <>
            {/* <MenuItem label="Home" href="/settings" iconComponent={SettingsOutlinedIcon} /> */}
            {/* <h3
                        className="pl-5 pr-3 pt-2 text-sm font-medium text-gray-500"
                        id="projects-headline"
                    >
                        Team
                    </h3> */}
            <MenuItem
                label="Roles"
                href="roles"
                iconComponent={UserGroupOutlinedIcon}
                badgeCount={roleIssueCount}
            />
            <MenuItem
                label="Field Groups"
                href="fieldgroups"
                iconComponent={CopyTextOutlinedIcon}
            />
            <MenuItem label="Stacks" href="stacks" iconComponent={TableOutlinedIcon} />
            <MenuItem
                label="Note Templates"
                href="notetemplates"
                iconComponent={FolderOutlinedIcon}
            />
            {!isLoading && isUnit21 && (
                <MenuItem label="Impersonate" href="impersonate" iconComponent={UserOutlinedIcon} />
            )}
            {localStorage.getItem('lf:feature:subscription') && (
                <>
                    <div className="w-full bg-grey-700/10 h-[1px]" />
                    <MenuItem
                        label="Subscription"
                        href="subscription"
                        iconComponent={OpportunityOutlinedIcon}
                    />
                </>
            )}
        </>
    )
}

function MenuItem({
    label,
    href,
    iconComponent,
    badgeCount,
}: {
    label: string
    href: string
    iconComponent: React.ComponentType<{ className?: string }>
    badgeCount?: number
}) {
    const isActive = useIsLinkActive(href, { end: true })
    return (
        <Button
            variant="tertiary"
            iconComponent={iconComponent}
            size="large"
            className={clsx(
                'w-full grid-cols-[auto,1fr,auto]',
                isActive && 'bg-grey-700/10 hover:bg-grey-700/10'
            )}
            as={Link}
            to={href}
        >
            <span className="align-left">{label}</span>
            {badgeCount ? (
                <span
                    className={clsx(
                        isActive ? 'bg-grey-50' : 'bg-grey-700/10 text-grey-700/40',
                        'py-0.5 px-3 rounded-full w-full'
                    )}
                >
                    {badgeCount}
                </span>
            ) : null}
        </Button>
    )
}

function SecondaryMenuItem({
    label,
    href,
    iconComponent,
}: {
    label: string
    href: string
    iconComponent: React.ComponentType<{ className?: string }>
    isActive?: boolean
}) {
    return (
        <Button
            variant="tertiary"
            iconComponent={iconComponent}
            className={clsx('w-full justify-start')}
            iconClassName="mr-2"
            as={Link}
            to={href}
        >
            {label}
        </Button>
    )
}

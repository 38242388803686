import { FormControlString } from './FormControlString'
import { FormControlTextarea } from './FormControlTextarea'
import { FormControlEmail } from './FormControlEmail'
import { FormControlPhone } from './FormControlPhone'
import { FormControlUrl } from './FormControlUrl'
import { FormControlInt } from './FormControlInt'
import { FormControlDouble } from './FormControlDouble'
import { FormControlBoolean } from './FormControlBoolean'
import { FormControlPicklist } from './FormControlPicklist'
import { FormControlMultipicklist } from './FormControlMultipicklist'
import { FormControlDate } from './FormControlDate'
import { FormControlDatetime } from './FormControlDatetime'
import { FormControlPercent } from './FormControlPercent'
import { FormControlAddress } from './FormControlAddress'
import { FormControlCurrency } from './FormControlCurrency'
import { FormControlTime } from './FormControlTime'
import { FormControlReference } from './FormControlReference'

export const FormControl = {
    Address: FormControlAddress,
    Boolean: FormControlBoolean,
    Currency: FormControlCurrency,
    Date: FormControlDate,
    Datetime: FormControlDatetime,
    Time: FormControlTime,
    Double: FormControlDouble,
    Email: FormControlEmail,
    Int: FormControlInt,
    Multipicklist: FormControlMultipicklist,
    Percent: FormControlPercent,
    Picklist: FormControlPicklist,
    Phone: FormControlPhone,
    String: FormControlString,
    Textarea: FormControlTextarea,
    Url: FormControlUrl,
    Reference: FormControlReference,
}

import gql from 'graphql-tag'

import { CONTACT_FRAGMENT } from './account-fragments'

export const CREATE_CONTACT = gql`
    ${CONTACT_FRAGMENT}
    mutation createContact($input: ContactInput!) {
        createContact(input: $input) {
            ...AccountContact
        }
    }
`

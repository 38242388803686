import { useMemo, useState, memo } from 'react'
import clsx from 'clsx'

import { Button, Checkbox, toast } from '@laserfocus/ui/beam'
import {
    parseDescription,
    containsHTML,
    parseBody,
    cleanHTML,
    getMutationErrorMessage,
} from '@laserfocus/client/util-formatter'
import { More2OutlinedIcon } from '@laserfocus/ui/icons'
import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'
import { shouldShowErrorMessage } from '@laserfocus/shared/models'
import { completeTask } from '@laserfocus/client/data-access-shared'
import { ActivityUtil } from '@laserfocus/client/model'
import { ActivityTags } from '@laserfocus/client/ui-shared-task'
import { logger } from '@laserfocus/ui/logger'

import type { ActivityData } from '../modules/usePersonActivitiesRCQuery'

import { ActivityActions } from './ActivityActions'
import { ExpandableText } from './ExpandableText'

interface ActivityBodyProps {
    activity: ActivityData
    startEditing(): void
}

export function ActivityBody(props: ActivityBodyProps) {
    if (ActivityUtil.isTask(props.activity)) {
        return <ActivityBodyTask {...props} />
    }

    if (ActivityUtil.isEmail(props.activity)) {
        return <ActivityBodyMail {...props} />
    }

    return <ActivityBodyItem {...props} />
}

function ActivityBodyItem({ activity, startEditing }: ActivityBodyProps) {
    const description = activity.Description

    return (
        <div className="pt-1.5 relative w-full">
            <ActivityHeading>{activity.Subject}</ActivityHeading>
            {description && <ExpandableText className="pt-1">{description}</ExpandableText>}
            <ActivityActions activity={activity} startEditing={startEditing} />
        </div>
    )
}

function ActivityBodyTask({ activity, startEditing }: ActivityBodyProps) {
    const description = activity.Description
    const isOpen = ActivityUtil.isOpen(activity)
    const { location } = useAnalyticsContext()

    const completeActivity = async () => {
        try {
            if (ActivityUtil.isTask(activity)) {
                await completeTask({ taskId: activity.Id })
                Analytics.trackEvent({
                    event: 'activity_completed',
                    location: location || 'person_details',
                })
            }
        } catch (e: unknown) {
            if (shouldShowErrorMessage(e)) {
                logger.warn(e)
                toast.error(getMutationErrorMessage(e))
            } else {
                logger.error(e)
                toast.error({ title: 'Something went wrong' })
            }
        }
    }

    return (
        <div
            className={clsx(
                'py-1.5 relative w-full rounded-lg grid grid-flow-col items-start',
                isOpen && 'bg-white grid-cols-[auto,1fr] ring-1 ring-grey-700/10 px-2'
            )}
            style={
                {
                    '--tw-shadow': '0px 1px 3px rgba(0, 0, 0, 0.05)',
                } as any
            }
        >
            {isOpen && (
                <Checkbox
                    title="Complete Task"
                    checked={!isOpen}
                    onChange={completeActivity}
                    className="mr-1 my-[-0.0625rem]"
                />
            )}
            <div>
                <ActivityHeading>{activity.Subject?.trim() || '<No Subject>'}</ActivityHeading>
                {description && <ExpandableText className="pt-1">{description}</ExpandableText>}
                <ActivityTags activity={activity} hidePriority={!isOpen} />
                <ActivityActions activity={activity} startEditing={startEditing} />
            </div>
        </div>
    )
}

function ActivityBodyMail({ activity, startEditing }: ActivityBodyProps) {
    const description = activity.Description

    const { to, subject, main, quote } = useMemo(() => {
        const { headers, body } = parseDescription(description || '')
        const { main, quote } = parseBody(body)
        return {
            to: headers.To,
            subject: headers.Subject || activity.Subject,
            main,
            quote,
        }
    }, [activity.Subject, description])

    const toName = activity.Owner?.Name
    const heading = toName ? `Email to ${toName}` : 'Email'

    return (
        <div className="pt-1.5 relative w-full">
            <ActivityHeading>{heading}</ActivityHeading>
            {to && <div className="pt-0.5 text-sm font-medium">{to}</div>}
            {subject || main.length ? (
                <div className="pt-4 text-sm font-medium text-grey-700/70">
                    {subject && <div className="pb-3 font-bold">{subject}</div>}
                    {main.length ? <EmailTextBody main={main} quote={quote} /> : null}
                </div>
            ) : null}

            <ActivityActions activity={activity} startEditing={startEditing} />
        </div>
    )
}

interface EmailTextBodyProps {
    main: string
    quote?: string
}
function EmailTextBody({ main, quote }: EmailTextBodyProps) {
    const [isHistoryExpanded, setIsHistoryExpanded] = useState(false)

    return (
        <ExpandableText>
            <EmailText text={main} />

            {quote && (
                <div className="pt-2 whitespace-pre-wrap">
                    <Button
                        variant="tertiary"
                        size="small"
                        iconComponent={More2OutlinedIcon}
                        title={isHistoryExpanded ? 'Hide history' : 'Show history'}
                        className="py-0"
                        onClick={() => setIsHistoryExpanded((isExpanded) => !isExpanded)}
                    />
                    {isHistoryExpanded && (
                        <div className="border-l border-grey-700/10 pl-4 mt-2">
                            <EmailText text={quote} />
                        </div>
                    )}
                </div>
            )}
        </ExpandableText>
    )
}

interface EmailTextProps {
    text: string
}

const EmailText = memo(function EmailText({ text }: EmailTextProps) {
    if (containsHTML(text)) {
        const cleanedHtml = cleanHTML(text)
        return (
            <div className="whitespace-normal" dangerouslySetInnerHTML={{ __html: cleanedHtml }} />
        )
    }
    return <>{text}</>
})

interface ActivityHeadingProps {
    children: React.ReactNode
}

function ActivityHeading(props: ActivityHeadingProps) {
    // eslint-disable-next-line jsx-a11y/heading-has-content
    return <h3 {...props} className="text-base font-semibold leading-[1.4]" />
}

import { isValid, parseISO, parse, format } from 'date-fns'

import { z } from '@laserfocus/shared/decoder'

import { Brand } from './type-utills'

// declare module ''
// interface Date {
//     toISOString(): DateTimeString
// }

declare global {
    interface Date {
        constructor(input: DateTimeString): Date
        toISOString(): DateTimeString
    }
}

export const DATE_FORMAT = 'yyyy-MM-dd'

export const MONTH_FORMAT = 'yyyy-MM'

export function isValidDateString(val: string) {
    const d = parseISO(val)
    return isValid(d) && d.getFullYear() >= 1700 && d.getFullYear() <= 4000
}

export function isValidDateTimeString(val: string) {
    const d = parseISO(val)
    return isValid(d) && d.getFullYear() >= 1700 && d.getFullYear() <= 4000
}

export function isValidMonthString(val: string) {
    const d = parseISO(val)
    return isValid(d)
}

export function isValidTimeString(val: string) {
    return timeFormats.some((format) => isValid(parse(val, format, new Date())))
}

export const DateStringSchema = z
    .string()
    .refine(isValidDateString, {
        message:
            'Invalid date string or outside supported date range of salesforce (Year 1700 - 4000)',
    })
    .transform((val) => val as DateString)

export type DateString = Brand<string, 'DateString'>

export const MonthStringSchema = z
    .string()
    .refine(isValidMonthString, {
        message: 'Invalid Month String',
    })
    .transform((val) => {
        const d = parseISO(val)
        return format(d, MONTH_FORMAT) as MonthString
    })

export type MonthString = Brand<string, 'MonthString'>

export const DateTimeStringSchema = z
    .string()
    .refine(isValidDateTimeString, {
        message:
            'Invalid datetime string or outside supported date range of salesforce (Year 1700 - 4000)',
    })
    .transform<DateTimeString>((val) => val as DateTimeString)

export type DateTimeString = Brand<string, 'DateTimeString'>

const timeFormats = ['HH:mm:ss.SSSXX', 'HH:mm:ss.SSSXXX']

export const TimeStringSchema = z
    .string()
    .refine(isValidTimeString)
    .transform((val) => val as TimeString)

export type TimeString = Brand<string, 'TimeString'>

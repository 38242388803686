import { useCallback, useMemo } from 'react'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

type UrlQueryStateOptions<T> = {
    replace?: boolean
    defaultValue?: T | (() => T | undefined)
}

export function useUrlQueryState<T extends string | string[] = string>(
    name: string,
    options: UrlQueryStateOptions<T> = {}
): [T | undefined, (v: T) => void] {
    const { defaultValue } = options
    const finalDefaultValue = useMemo(() => {
        if (typeof defaultValue === 'function') {
            return defaultValue()
        }
        return defaultValue
    }, [defaultValue])
    const initValue: URLSearchParamsInit = useMemo(
        () => (finalDefaultValue ? { [name]: finalDefaultValue } : undefined),
        [finalDefaultValue, name]
    ) as URLSearchParamsInit

    const [searchParams, setSearchParams] = useSearchParams(initValue)

    const update = useCallback(
        (value: T, replace?: boolean) => {
            setSearchParams({ [name]: value }, { replace })
        },
        [name, setSearchParams]
    )
    const current = searchParams.get(name) as T | undefined

    // useEffect(() => {
    //     if (!queryValue && finalDefaultValue) {
    //         update(finalDefaultValue, true)
    //     }
    // }, [finalDefaultValue, queryValue, update])

    return [current, update]
}

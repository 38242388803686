import { Severity, addBreadcrumb } from '@sentry/react'
import type { Breadcrumb } from '@sentry/types'
import { isPlainObject } from 'lodash'

import { captureException, CaptureExtraInfo } from '@laserfocus/ui/error-reporting'
import { Config } from '@laserfocus/shared/config-env'

import { Logger, LevelLabel, levelMapping } from './custom-logger'

export const logger = new Logger({
    level: getLogLevel(),
    /**
     * We now also send errors in the console to sentry.
     * So don't send twice
     */
    transmit: {
        level: 'info',
        send: function (level: LevelLabel, ...messages: any[]) {
            const levelNumber = levelMapping[level]

            if (levelNumber >= 50) {
                const [rootErr, ...rest] = messages
                const properties: CaptureExtraInfo = {
                    level: level as Severity,
                }

                if (rest && rest.length === 1) {
                    if (isPlainObject(rest[0])) {
                        properties.extra = rest[0]
                    } else {
                        properties.extra = rest
                    }
                } else if (rest && rest.length > 1) {
                    properties.extra = { messages: rest }
                }
                captureException(rootErr, properties)
            } else if (levelNumber >= 30) {
                const breadcrumb: Breadcrumb = {
                    level: level as Severity,
                    message: getMainMessage(messages),
                    data: {
                        messages,
                    },
                    category: 'log',
                }
                addBreadcrumb(breadcrumb)
            }
        },
    },
})

type Primitive = string | number | boolean | undefined | null
function getMainMessage(messages: any[]): string {
    const main: Primitive[] = []
    for (const m of messages) {
        if (['string', 'number', 'boolean', 'undefined'].includes(typeof m) || m === null) {
            main.push(m)
        } else {
            break
        }
    }
    return main.join(' ')
}

function getLogLevel() {
    // return 'error' as LevelLabel
    /**
     * Lets run cypress with debug, to investigate WHEN
     * we have more issues
     */
    if (typeof window !== 'undefined' && window?.Cypress) {
        return 'debug'
    }
    if (Config.isTest) {
        return 'error'
    }
    if (Config.STAGE === 'dev') {
        return (process.env.NX_LOG_LEVEL as LevelLabel) || 'debug'
    }
    return 'info'
}

declare global {
    interface Window {
        $logger: typeof logger
        Cypress?: unknown
    }
}

if (typeof window !== 'undefined') {
    window.$logger = logger
}

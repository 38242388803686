import { z } from '@laserfocus/shared/decoder'

import { FieldType } from '../metadata/metadata.types'
import {
    DateStringSchema,
    DateTimeStringSchema,
    isValidDateString,
    isValidDateTimeString,
    isValidTimeString,
    TimeStringSchema,
    DateString,
    DateTimeString,
} from '../base/date.types'
import { isDateConstant } from '..'

import { SFDateConstants } from './date-ranges'

export type ConditionDateValue = keyof typeof SFDateConstants | DateString | DateTimeString
export type ConditionValue = string | number | boolean | ConditionDateValue | null | undefined

export type ConditionFieldType = Exclude<
    FieldType,
    'base64' | 'anyType' | 'location' | 'encryptedstring' | 'address' | 'complexvalue'
>

export type FilterConditionDTO = {
    fieldName: string
    fieldType: ConditionFieldType
    operator: Operator
    values: string[]
}

export type FilterCondition = z.infer<typeof filterConditionSchema>

export type FilterConditionInput = z.input<typeof filterConditionSchema>

const filedTypeSchema = z.enum([
    'boolean',
    // Strings
    'picklist',
    'multipicklist',
    'combobox',
    'id',
    'reference',
    'string',
    'textarea',
    'url',
    'phone',
    'email',
    'int',
    // Numbers
    'double',
    'currency',
    'percent',
    'date',
    'datetime',
    'time',
])

const operatorValues = ['EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE', 'INCLUDES', 'EXCLUDES'] as const
const operatorSchema = z.enum(operatorValues)

export type Operator = z.infer<typeof operatorSchema>

export const filterConditionSchema = z
    .object({
        fieldName: z.string(),
        fieldType: filedTypeSchema,
        operator: operatorSchema,
        values: z.array(
            z
                .union([
                    z.string(),
                    z.number(),
                    z.boolean(),
                    DateStringSchema,
                    DateTimeStringSchema,
                    TimeStringSchema,
                ])
                .nullable()
        ),
        isComputed: z.boolean().default(false).nullish(),
        isLocked: z.boolean().default(false).nullish(),
    })
    .superRefine((val, ctx) => {
        const { fieldType, values, operator } = val

        const validOperators = validOperatorsMap[fieldType]

        if (!validOperators.includes(operator)) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_enum_value,
                options: validOperators,
                path: ['operator'],
                message: `Invalid enum value. Expected ${validOperators
                    .map((o) => `'${o}'`)
                    .join(' | ')}, received ${operator}`,
                received: operator,
            })
        }

        const valueCheck = valueTypeCheckMap[fieldType]
        const valueErrorIndex = values.findIndex((v) => !valueCheck(v) && v !== null)
        if (valueErrorIndex !== -1) {
            ctx.addIssue(getValueTypeIssue(fieldType, valueErrorIndex, values))
        }

        const specificCheck = getSpecificCheck(fieldType, operator)
        if (specificCheck) {
            const operatorValueErrorIndex = values.findIndex((v) => !specificCheck(v))
            if (operatorValueErrorIndex > -1) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: {
                        fieldType,
                        operator,
                        values,
                    },
                    path: ['values', operatorValueErrorIndex],
                    message: `Condition with fieldType ${fieldType} and  operator ${operator} received ${values[operatorValueErrorIndex]} as value`,
                })
            }
        }

        if (singleValueOperator.includes(operator) && values.length !== 1) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    fieldType,
                    operator,
                    values,
                },
                path: ['values', 1],
                message: `Condition with fieldType ${fieldType} and  operator ${operator} has ${values.length} values, but only a single value is allowed`,
            })
        }
    })

function getValueTypeIssue(
    fieldType: ConditionFieldType,
    valueErrorIndex: number,
    values: any[]
): z.IssueData {
    const zodType = fieldTypeToType[fieldType]
    return {
        code: z.ZodIssueCode.invalid_type,
        expected: zodType,
        received: typeof values[valueErrorIndex],
        path: ['values', valueErrorIndex],
        message: `Condition with type '${fieldType}' received '${values[valueErrorIndex]}' as value`,
    }
}

const eqNeq: Operator[] = ['EQ', 'NEQ']
const includesExcludes: Operator[] = ['INCLUDES', 'EXCLUDES']
const numberOperator: Operator[] = ['GT', 'GTE', 'LT', 'LTE']

const stringLike = [...eqNeq, ...includesExcludes]
const numberLike = [...eqNeq, ...numberOperator]

const singleValueOperator = [...numberOperator]

const validOperatorsMap: Record<ConditionFieldType, Operator[]> = {
    boolean: eqNeq,
    id: eqNeq,
    reference: eqNeq,
    picklist: eqNeq,
    combobox: eqNeq,
    multipicklist: eqNeq,
    string: stringLike,
    textarea: stringLike,
    url: stringLike,
    phone: stringLike,
    email: stringLike,
    int: numberLike,
    double: numberLike,
    currency: numberLike,
    percent: numberLike,
    date: numberLike,
    datetime: numberLike,
    time: numberLike,
}

function getSpecificCheck(fieldType: FieldType, operator: Operator) {
    if (['INCLUDES', 'EXCLUDES', 'LT', 'LTE', 'GTE', 'GT'].includes(operator)) {
        return (v: any) => v !== null
    } else if (['EQ', 'NEQ'].includes(operator) && fieldType === 'int') {
        return (v: any) => Number.isInteger(v) || v === null
    }
}

const stringCheck = (v: any) => typeof v === 'string'
const numberCheck = (v: any) => typeof v === 'number' || typeof v === 'bigint'

const valueTypeCheckMap: Record<ConditionFieldType, (v: any) => boolean> = {
    boolean: (v) => v === false || v === true,
    id: stringCheck,
    reference: stringCheck,
    picklist: stringCheck,
    combobox: stringCheck,
    multipicklist: stringCheck,
    string: stringCheck,
    textarea: stringCheck,
    url: stringCheck,
    phone: stringCheck,
    email: stringCheck,
    int: numberCheck,
    double: numberCheck,
    currency: numberCheck,
    percent: numberCheck,
    date: (v) => isValidDateString(v) || isDateConstant(v),
    datetime: (v) => isValidDateTimeString(v) || isDateConstant(v),
    time: isValidTimeString,
}

const fieldTypeToType: Record<ConditionFieldType, z.ZodParsedType> = {
    boolean: 'boolean',
    id: 'string',
    reference: 'string',
    picklist: 'string',
    combobox: 'string',
    multipicklist: 'string',
    string: 'string',
    textarea: 'string',
    url: 'string',
    phone: 'string',
    email: 'string',
    int: 'integer',
    double: 'float',
    currency: 'float',
    percent: 'float',
    date: 'date',
    datetime: 'date',
    time: 'date',
}

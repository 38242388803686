import { getCountry } from '../shared'

export function getDateLong(date: Date) {
    return formatDate(date, { year: 'numeric', month: 'long', day: 'numeric' })
}

export function getDateShort(date: Date) {
    return formatDate(date, { year: 'numeric', month: 'short', day: 'numeric' })
}

export function getDateWithoutYearLong(date: Date) {
    return formatDate(date, { month: 'long', day: 'numeric' })
}

export function getDateNarrow(date: Date) {
    return formatDate(date, { year: 'numeric', month: '2-digit', day: '2-digit' })
}

export function getTime(date: Date) {
    return formatDate(date, { hour: 'numeric', minute: '2-digit' })
}

export function getTimeTabular(date: Date) {
    return formatDate(date, { hour: '2-digit', minute: '2-digit' })
}

export function getWeekDay(date: Date) {
    return formatDate(date, { weekday: 'short' })
}

function formatDate(date: Date, options: Intl.DateTimeFormatOptions) {
    const country = getCountry()
    const locale = country ? `en-${country}` : 'en'

    return new Intl.DateTimeFormat(locale, options).format(date)
}

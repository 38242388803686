import { Link } from 'react-router-dom'
import { addDays, format } from 'date-fns'
import { useMemo } from 'react'

import { useActivityCounterForUserBetween } from '@laserfocus/client/data-access-shared'
import { useUserId } from '@laserfocus/client/feature-auth'
import { DateString, DATE_FORMAT } from '@laserfocus/shared/models'
import { Analytics } from '@laserfocus/client/util-analytics'

import { StatDescription, StatLabel, StatValue } from './StatsCard'
import { getSavedTime, formatTimeSaved } from './SavedCard'

export function ActivityCounterCard() {
    const userId = useUserId()
    const since = format(addDays(new Date(), -7), DATE_FORMAT) as DateString
    const today = format(new Date(), DATE_FORMAT) as DateString
    const counters = useActivityCounterForUserBetween(userId, since, today)

    const savedTime = getSavedTime(counters)

    return (
        <Link
            to="/stats"
            className="bg-grey-700-opaque-5 rounded-2xl p-6 pb-4 hover:bg-grey-700/10 transition-colors cursor-pointer h-full grid items-end"
            onClick={() => {
                Analytics.trackEvent({
                    event: 'time_saved_clicked',
                    location: 'home',
                })
            }}
        >
            <StatLabel>Time Saved</StatLabel>
            <StatValue className="text-4xl">{formatTimeSaved(savedTime)}</StatValue>
            <StatDescription>In the last 7 days</StatDescription>
        </Link>
    )
}

import { ChatWidget } from '@papercups-io/chat-widget'

import {
    LayoutPadding,
    LayoutPaddingVertical,
    NavBar,
    PageHeaderCentered,
} from '@laserfocus/client/layout'
import { useIdentity } from '@laserfocus/client/feature-auth'
import { theme } from '@laserfocus/ui/tailwindcss'

export function ReportsLayout({ children }: { children: React.ReactChild }) {
    const user = useIdentity()
    const greeting = getGreeting()
    const title = !user?.FirstName ? greeting : `${greeting}, ${user.FirstName}.`
    return (
        <>
            <NavBar breadcrumbs={[{ title: 'Dashboard', path: '/dashboard' }]} />
            <LayoutPaddingVertical data-testid="page-Home" maxWidth="reportWidth">
                <PageHeaderCentered title={title} />
                <LayoutPadding top bottom>
                    {children}
                </LayoutPadding>
            </LayoutPaddingVertical>
            {user && (
                <ChatWidget
                    accountId="6e2bfe40-7b24-4502-90ea-a6a20a81c2f6"
                    title="Chat with a real person from Laserfocus."
                    subtitle="Ask us anything in the chat window below 😊"
                    primaryColor={theme.colors.blue[500]}
                    greeting="Hey There, chat with us if you have any questions. We don't use chat bots."
                    awayMessage=""
                    newMessagePlaceholder="Start typing..."
                    showAgentAvailability={false}
                    agentAvailableText="We're online right now!"
                    agentUnavailableText="We're online right now!"
                    requireEmailUpfront={false}
                    iconVariant="outlined"
                    baseUrl="https://app.papercups.io"
                    // Optionally include data about your customer here to identify them
                    customer={{
                        name: user.Name,
                        email: user.Email || user.Username,
                        external_id: user.Id,
                        metadata: {
                            orgId: user.OrgId,
                        },
                    }}
                    styles={{
                        toggleContainer: {
                            zIndex: 0,
                        },
                    }}
                />
            )}
        </>
    )
}

/*
    5AM - 11:59AM = Good Morning, Daniel.
    12PM - 5:59 PM = Good Afternoon, Daniel.
    6PM - 11:59 PM = Good Evening, Daniel.
    12:00AM - 4:59 AM = Enjoy the late nighter, Daniel.
*/
function getGreeting() {
    const now = new Date()
    const hour = now.getHours()
    if (hour >= 5 && hour < 12) {
        return 'Good Morning'
    } else if (hour >= 12 && hour < 18) {
        return 'Good Afternoon'
    } else if (hour >= 18) {
        return 'Good Evening'
    } else if (hour < 5) {
        return 'Enjoy the late nighter'
    }
    return 'Hi'
}

import { DateTimeString } from '..'
import {
    Identifier,
    Id,
    EmailAddress,
    PhoneNumber,
    Url,
    Currency,
    Country,
    WithCustomFields,
    WithCustomFieldsDTO,
    BaseFields,
    ToDTO,
    WithType,
} from '../base/model.types'

import {
    PersonActivity,
    Task,
    Event,
    Owner,
    EventDTO,
    TaskDTO,
    ActivityDTO,
    OwnerDTO,
} from './activity.types'

export const LEAD_PREFIX = '00Q'

export type LeadFields = Partial<BaseFields> &
    Partial<{
        Status: string
        FirstName: string
        LastName: string
        Name: string
        Title: string
        Company: string
        Email: EmailAddress
        Phone: PhoneNumber
        MobilePhone: PhoneNumber
        Website: Url
        LeadSource: string
        Description: string
        Rating: string
        IsConverted: boolean
        LastActivityDate: DateTimeString
        AvatarUrl: Url
        CurrencyIsoCode: Currency
        CountryCode: Country
        RecordTypeId: string

        Street: string
        State: string
        PostalCode: string
        City: string
        Country: string
        GeocodeAccuracy: string
        Latitude: number
        Longitude: number

        /**
         * RelationShipField
         */
        OwnerId: Id
        ConvertedAccountId: Id
        ConvertedContactId: Id
        ConvertedOpportunityId: Id
    }>

export type LeadProps = Identifier & WithType<'Lead'> & LeadFields
export type LeadModel = LeadProps & Record<string, string | boolean | number | null | undefined>

export function isLeadModel(model: WithType<string>): model is LeadModel {
    return model.__typename === 'Lead'
}

type LeadRelationShips = Partial<{
    OpenActivities: PersonActivity[]
    ActivityHistories: PersonActivity[]
    Tasks: Task[]
    Events: Event[]
    Owner: Owner
}>

export interface Lead extends Identifier, LeadFields, LeadRelationShips, WithCustomFields {}

export interface LeadDTO extends Identifier, ToDTO<LeadFields>, WithCustomFieldsDTO {
    OpenActivities?: ActivityDTO[]
    ActivityHistories?: ActivityDTO[]
    Tasks?: TaskDTO[]
    Events?: EventDTO[]
    Owner?: OwnerDTO
    Id: string
    __typename: 'Lead'
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function ColumnsOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M9 5H5v14h4V5ZM5 3a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5ZM19 5h-4v14h4V5Zm-4-2a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-4Z"
            />
        </SvgIcon>
    )
}

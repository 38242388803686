import { twMerge } from 'tailwind-merge'

export function Table({ className, ...props }: React.ComponentPropsWithoutRef<'table'>) {
    return <table className={twMerge('text-sm', className)} {...props} />
}

function Frame({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
    return (
        <div
            className={twMerge(
                'overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg',
                className
            )}
            {...props}
        />
    )
}

function TableHead(props: React.ComponentPropsWithoutRef<'thead'>) {
    return <thead {...props} />
}

function TableHeadCell({ className, ...props }: React.ComponentPropsWithoutRef<'th'>) {
    return (
        <th
            className={twMerge(
                'group leading-[1.3] font-medium text-grey-700/60 text-left p-2',
                className
            )}
            {...props}
        />
    )
}

function TableBody({ className, ...props }: React.ComponentPropsWithoutRef<'tbody'>) {
    return <tbody className={twMerge('bg-white', className)} {...props} />
}

function TableRow({ className, ...props }: React.ComponentPropsWithoutRef<'tr'>) {
    return (
        <tr
            className={twMerge('hover:bg-grey-700/5 border-b border-grey-700/10', className)}
            {...props}
        />
    )
}

function TableCell({ className, ...props }: React.ComponentPropsWithoutRef<'td'>) {
    return <td className={twMerge('p-2 items-center text-sm', className)} {...props} />
}

Table.Head = TableHead
Table.HeadCell = TableHeadCell
Table.Body = TableBody
Table.Row = TableRow
Table.Cell = TableCell
Table.Frame = Frame

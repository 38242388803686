import { createContext, useContext } from 'react'

import {
    ConditionValue,
    FieldType,
    Operator,
    Sorting,
    Aggregation,
    LeadProps,
    AccountProps,
    ContactProps,
    OpportunityProps,
} from '@laserfocus/shared/models'
import { LeadModel, AccountModel, ContactModel, OpportunityModel } from '@laserfocus/client/model'
import { StoredModel } from '@laserfocus/client/data-layer'

export type RowData = LeadModel | AccountModel | ContactModel | OpportunityModel
export type Row = LeadProps | AccountProps | ContactProps | OpportunityProps
export type SalesObject = Row
export type Rows = Row[]

export interface Column<Row extends StoredModel = StoredModel> {
    key: string
    isStickyLeft: boolean
    label: string
    type: FieldType
    fieldLength?: number
    fieldPrecision?: number
    fieldScale?: number
    isReferenceList: boolean
    isSearchReference: boolean
    isShown: boolean
    width: number
    sorting: 'ASC' | 'DESC' | null
    filterCondition: ColumnFilterCondition | null
    setFilterCondition(filterCondition: ColumnFilterCondition): void
    removeFilterCondition(): void
    setWidth(width: number): void
    toggleIsShown(): void
    isEditable(salesObject: Row): boolean
    getHint?(salesObject: Row): string | undefined
    getValue(salesObject: Row): unknown
    getActual?(salesObject: Row): unknown
    updateValue(
        salesObject: Row,
        value: string | number | string[] | boolean | null
    ): Promise<unknown>
    bulkUpdateValue(
        salesObjects: Row[],
        value: string | number | string[] | boolean | null
    ): Promise<unknown>
    getSelectOptions(salesObject?: Row): SelectOption[]
    getFilterOptions(rows: Row[]): SelectOption[]
    sortAscending(): void
    sortDescending(): void
    aggregation?: Aggregation
    setAggregation(aggregation: Aggregation): void
    getAggregatedValue(rows: Row[]): number | null | undefined
    columnLocked: boolean
    conditionLocked: boolean
    hideFilter?: boolean
    hideAggregation?: boolean
}

interface ColumnFilterCondition {
    operator: Operator
    values: ConditionValue[]
}

export interface SelectOption {
    label: string | null
    value: string
    inactive?: boolean
}

export interface TableDataContextValue {
    columns: Column[]
    rows: Row[]
    stackId: string
    tableWidth: number
    sorting?: Sorting
    setSorting(sorting: Sorting): void
    setColumnsOrder(columnKeys: string[]): void
    isStickyLeftActive: boolean
    setIsStickyLeftActive(isStickyLeftActive: boolean): void
    gridTemplateColumns: string
    tableHeadBackgroundColor: string
    searchTerm: string
    overlayObjectId?: string
}

export const AVATAR_COLUMN_WIDTH = 62

const TableDataContext = createContext<TableDataContextValue | null>(null)
export const TableDataProvider = TableDataContext.Provider
export function useTableDataContext(): TableDataContextValue {
    return useContext(TableDataContext)!
}

const TableRowsContext = createContext<Pick<TableDataContextValue, 'rows'>>(null!)
export const TableRowsProvider = TableRowsContext.Provider
export function useTableRows() {
    return useContext(TableRowsContext)
}

import { useState } from 'react'
import { format, isValid } from 'date-fns'
import { useController, Path } from 'react-hook-form'

import { Datepicker, DropdownBaseInput } from '@laserfocus/ui/beam'

import { FormControlProps, SalesObject } from '../get-form-control'

import { getControllerRules } from './formcontrol-util'

export function FormControlDatetime<T extends SalesObject = SalesObject>({
    fieldMetadata,
    id,
    variant,
    control,
    submit,
    name,
}: FormControlProps<T>) {
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false)

    const currentDate = parseDate(value as string | undefined) ?? undefined
    return (
        <Datepicker
            isOpen={isDatepickerOpen}
            initialDate={currentDate}
            onCancel={() => setIsDatepickerOpen(false)}
            onDateSubmit={(date) => {
                setIsDatepickerOpen(false)
                onChange(transformDate(date))
                submit?.()
            }}
            withSelect
            allowEmpty
        >
            <DropdownBaseInput
                id={id}
                variant={variant}
                forceFocused={isDatepickerOpen}
                onClick={() => setIsDatepickerOpen(true)}
                disabled={!fieldMetadata.updateable}
                value={currentDate ? format(currentDate, 'E, MMM d, y, h:mm a') : ''}
                error={(error as any)?.message}
            />
        </Datepicker>
    )
}

function parseDate(dateString: string | undefined): Date | null {
    if (!dateString) {
        return null
    }
    const parsed = new Date(dateString)
    if (isValid(parsed)) {
        return parsed
    }
    return null
}

function transformDate(date: Date | null) {
    return date?.toISOString()
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function TaskFilledIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M11 3a1 1 0 100 2h2a1 1 0 100-2h-2z" />
            <path d="M4 6a2 2 0 012-2 3 3 0 003 3h5a3 3 0 003-3 2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V6zm11.707 5.707a1 1 0 00-1.414-1.414L11 13.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" />
        </SvgIcon>
    )
}

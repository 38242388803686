export type SingleTableModule = SingleTableOrgModule | SingleTableUserModule
export type SingleTableUserModule = 'quota' | 'settings' | 'stackrows'
export type SingleTableOrgModule = 'userinvite' | 'lfuser' | 'settings'

export type SyncModule =
    | SingleTableModule
    | 'stacks'
    | 'fieldGroups'
    | 'users'
    | 'nonStandardUsers'
    | 'groups'
    | 'metadata'
    | 'recordtypes'
    | 'settings'
    | 'failedTransactions'
    | 'openTaskResult'
    | 'openEventResult'
    | 'taskHistoryResult'
    | 'eventHistoryResult'
    | 'taskDeletedResult'
    | 'eventDeletedResult'
    | 'sharedActivitiesSetting'
    | 'salesExtension'
    | 'pinned'
    | 'leadResult'
    | 'accountResult'
    | 'opportunityResult'
    | 'contactResult'
    | 'contentnotes'
    | 'contentnotesDeleted'
    | 'identity'
    | 'sfListviews'
    | 'noteTemplates'
    | 'activityCounter'
    | 'orgSingleTableRecords'
    | 'userSingleTableRecords'
    | 'release'
    | 'roles'
    | 'subscription'
    | 'nameResult'

export const SINGLE_TABLE_USER_MODULES: SingleTableUserModule[] = ['quota']
export const SINGLE_TABLE_ORG_MODULES: SingleTableOrgModule[] = ['userinvite', 'lfuser']
export function isSingleTableUserModule(module: string): module is SingleTableUserModule {
    return (SINGLE_TABLE_USER_MODULES as string[]).includes(module)
}
export function isSingleTableOrgModule(module: string): module is SingleTableOrgModule {
    return (SINGLE_TABLE_ORG_MODULES as string[]).includes(module)
}

/**
 * Replicache Schema Version
 * - minor difference -> only new client state
 * - major difference -> client needs to refresh (signal sent from backend)
 */
export const ReplicacheSchemaVersion = '2.0'

export type QuerySchedule =
    | {
          current: SyncModule[] | 'ALL'
      }
    | {
          current: 'SKIP'
          next?: SyncModule[]
      }

import { matchSorter } from 'match-sorter'
import type { RankingInfo } from 'match-sorter'

import type { SearchResult } from '@laserfocus/shared/models'

export type SearchOptions = {
    forTaskCreation: boolean
}

export function sortSearchResults(
    options: SearchResult[],
    searchTerm: string,
    userId?: string,
    searchOptions?: SearchOptions
) {
    const baseSort = makeBaseSort(userId, searchOptions)

    if (searchTerm) {
        const matched = matchSorter<SearchResult>(options, searchTerm, {
            keys: ['Name', 'Company', 'Email', 'Website'],
            sorter: (matchedItems) => matchedItems.sort((a, b) => sortRankedValues(a, b, baseSort)),
        })
        const matchIds = new Set(matched.map((a) => a.Id))
        const remainingResults = options.filter((a) => !matchIds.has(a.Id))
        return [...matched, ...remainingResults]
    }
    return options
}

export function makeBaseSort(myId: string, searchOptions?: SearchOptions) {
    const objectOrder = searchOptions?.forTaskCreation
        ? ['Opportunity', 'Account', 'Lead', 'Contact'].reverse()
        : ['Account', 'Lead', 'Opportunity', 'Contact'].reverse()

    //
    /**
     * Order from LF-1474
     * 
        My Open Accounts
        My Open Leads
        My Open Opportunities
        My Closed Opportunities
        My Contacts
        Other Accounts
        Other Leads
        Other Open Opportunities
        Other Closed Opportunities
        Other Contacts
     */
    function getScore(r: SearchResult) {
        let score = (objectOrder.indexOf(r.SObjectType) + 1) * 10
        if (r.OwnerId === myId) {
            score += 100
        }
        if (r.SObjectType === 'Opportunity' && !r.IsClosed) {
            score += 1
        }

        return score
    }
    function baseSort(a: SearchResult, b: SearchResult) {
        return getScore(b) - getScore(a)
    }

    return baseSort
}

/**
 * Sorts items that have a rank, index, and keyIndex
 * Taken from match-sorter to ignore the keyIndex
 * @param {Object} a - the first item to sort
 * @param {Object} b - the second item to sort
 * @return {Number} -1 if a should come first, 1 if b should come first, 0 if equal
 */
function sortRankedValues<ItemType extends SearchResult>(
    a: RankedItem<ItemType>,
    b: RankedItem<ItemType>,
    baseSort: ReturnType<typeof makeBaseSort>
): number {
    const aFirst = -1
    const bFirst = 1
    const { rank: aRank } = a
    const { rank: bRank } = b
    const same = aRank === bRank
    if (same) {
        return baseSort(a.item, b.item)
    } else {
        return aRank > bRank ? aFirst : bFirst
    }
}

interface IndexedItem<ItemType> {
    item: ItemType
    index: number
}
interface RankedItem<ItemType> extends RankingInfo, IndexedItem<ItemType> {}

import {
    isLeadResult,
    SearchResult,
    ContactSearchResult,
    isOpportunityResult,
    isContactResult,
} from '@laserfocus/shared/models'
import { CommandLine } from '@laserfocus/ui/beam'
import { PersonAvatar } from '@laserfocus/client/ui-shared'
import { SearchResultObjectInfo } from '@laserfocus/client/feature-search'
import { useObjectPool } from '@laserfocus/client/root-store-context'

type SearchResultOptionProps = {
    searchResult: SearchResult
} & Omit<
    React.ComponentPropsWithoutRef<typeof CommandLine.Option>,
    'title' | 'icon' | 'description'
>
export function SelectParentOption({ searchResult, ...rest }: SearchResultOptionProps) {
    const objectPool = useObjectPool()

    const seperator =
        isOpportunityResult(searchResult) || isContactResult(searchResult) ? '·' : undefined
    return (
        <CommandLine.Option
            {...rest}
            title={isLeadResult(searchResult) ? searchResult.Company : searchResult.Name}
            icon={
                <PersonAvatar
                    emoji={
                        (objectPool.get(searchResult.SObjectType, searchResult.Id) as any)?.emoji
                    }
                    id={(searchResult as ContactSearchResult).AccountId || searchResult.Id}
                    size={30}
                />
            }
            description={
                <SearchResultObjectInfo
                    sobjectType={searchResult.SObjectType}
                    name={
                        isLeadResult(searchResult)
                            ? searchResult.Name
                            : (searchResult as ContactSearchResult).AccountName
                    }
                    seperator={seperator}
                />
            }
        />
    )
}

import { pick } from 'lodash'
import { useForm } from 'react-hook-form'

import { Button, Modal } from '@laserfocus/ui/beam'
import { Stack } from '@laserfocus/shared/models'
import { getDirtyValues } from '@laserfocus/client/util-form'
import { mutateStack } from '@laserfocus/client/data-access-shared'

import { EditStackForm, FormState } from './EditStackForm'

interface EditStackModalProps extends EditStackModalInnerProps {
    show: boolean
}
export function EditStackModal({ show, closeModal, stack }: EditStackModalProps) {
    return (
        <Modal show={show} onClose={closeModal}>
            <EditStackModalInner stack={stack} closeModal={closeModal} />
        </Modal>
    )
}

interface EditStackModalInnerProps {
    stack: Stack
    closeModal: () => void
}

function EditStackModalInner({ stack, closeModal }: EditStackModalInnerProps) {
    const {
        control,
        handleSubmit,
        formState: { isDirty, dirtyFields },
        reset,
    } = useForm<FormState>({
        defaultValues: pick(stack, 'title', 'description', 'helpUrl'),
        reValidateMode: 'onBlur',
    })

    const hasDirtyFields = Object.keys(dirtyFields).length > 0
    async function submit(values: FormState) {
        const changeSet = getDirtyValues(dirtyFields, values)
        await mutateStack.updateStack(stack.id, changeSet, 'org')
        const newState = {
            ...pick(stack, 'title', 'description', 'helpUrl'),
            ...changeSet,
        }
        reset(newState)
    }

    return (
        <>
            <Modal.Overlay />
            <Modal.Container>
                <Modal.Header border close={closeModal}>
                    Edit {stack.title}
                </Modal.Header>
                <div className="min-h-[21.75rem] max-h-[50vh] mx-8 my-3 overflow-y-auto">
                    <EditStackForm control={control} />
                </div>
                <Modal.Footer border className="justify-end">
                    <Button type="submit" variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        onClick={handleSubmit(submit)}
                        disabled={!isDirty || !hasDirtyFields}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </>
    )
}

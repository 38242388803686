import { twMerge } from 'tailwind-merge'

export type EmojiAvatarProps = {
    size?: number
    emoji: string
} & React.ComponentPropsWithoutRef<'div'>

export const EmojiAvatar = ({ size = 32, emoji, color, className, ...rest }: EmojiAvatarProps) => {
    return (
        <div
            {...rest}
            className={twMerge(
                'font-emoji bg-grey-700/5 rounded-full flex items-center justify-center text-center',
                className
            )}
            style={{
                minWidth: size,
                minHeight: size,
                maxHeight: size,
                maxWidth: size,
            }}
        >
            <span
                role="img"
                style={{
                    //https://stackoverflow.com/questions/59558227/how-to-visually-horizontally-center-an-emoji-in-chrome
                    fontSize: size / 2,
                }}
            >
                {emoji}
            </span>
        </div>
    )
}

import { addMinutes } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import { observer } from 'mobx-react-lite'

import { Button } from '@laserfocus/ui/beam'
import { goToTodayPage } from '@laserfocus/client/util-routing'
import { Analytics } from '@laserfocus/client/util-analytics'
import { TaskList } from '@laserfocus/client/ui-shared-task'
import { ActivityUtil } from '@laserfocus/client/model'

import StackInfo from '../StackInfo'
import { AddTaskButton } from '../../TaskModal/AddTaskButton'
import { StackIconAvatar } from '../StackIconAvatar'
import { useTodayActivities } from '../../activity/useActivityStack'
import { LoadingStack } from '../../activity/LoadingStack'
import { StackTasks } from '../../activity/StackTasks'

import { TodayEmptyStackCard } from './TodayEmptyStackCard'

export const TodayStackCard = observer(function TodayStackCard({
    className,
}: {
    className?: string
}) {
    const {
        activities: allActivities,
        loading,
        completeTask,
        dismissTask,
        todayStacks,
    } = useTodayActivities()
    const upcomingToday = allActivities.filter((a) => {
        if (ActivityUtil.isTask(a)) {
            return !a.IsClosed
        }
        // Show only future events
        const date = ActivityUtil.getDate(a)
        return date && date.getTime() > addMinutes(new Date(), -15).getTime()
    })

    const activities = upcomingToday.slice(0, 3 - todayStacks.length)
    const totalCount = allActivities.length + todayStacks.length

    const hasActivities =
        Boolean(activities.length && activities.length > 0) || Boolean(todayStacks.length)
    function showToday() {
        Analytics.trackEvent({
            event: 'stack_clicked',
            location: 'home',
            stack: 'today',
        })
        goToTodayPage()
    }
    if (!hasActivities && !loading) {
        return <TodayEmptyStackCard className={className} />
    }
    return (
        <div
            data-testid="todaystackcard"
            className={twMerge('col-span-2 row-span-2 p-6 rounded-2xl bg-yellow-50', className)}
        >
            <div className="flex justify-between items-center">
                <div className="flex">
                    <StackIconAvatar icon="🗓" />
                    <StackInfo
                        title="Today"
                        sobject="Tasks"
                        className="ml-[20px]"
                        count={loading ? undefined : totalCount}
                    />
                </div>
                <div className="grid grid-flow-col gap-2 flex-none">
                    <AddTaskButton />
                    <Button onClick={showToday}>Show All</Button>
                </div>
            </div>
            {loading && <LoadingStack amount={2} className="mt-4" />}
            {hasActivities && (
                <div className="mt-4">
                    <StackTasks
                        stacks={todayStacks || []}
                        className={activities.length ? 'mb-2' : undefined}
                    />
                    {activities.length ? (
                        <TaskList
                            activities={activities}
                            pathPrefix="today"
                            completeTask={completeTask}
                            dismissTask={dismissTask}
                        />
                    ) : null}
                </div>
            )}
        </div>
    )
})

import { useState } from 'react'
import { isValid } from 'date-fns'
import clsx from 'clsx'
import { parseISO, formatISO } from 'date-fns'

import { isDateConstant, Operator } from '@laserfocus/shared/models'
import { Button, Datepicker } from '@laserfocus/ui/beam'
import { getDateLong, getTime } from '@laserfocus/ui/util-locale'

import { Column } from '../../Table/table-context'

import { FilterInputProps } from './types'
import { FilterInputActions } from './FilterInputActions'

export function DateInput({ column, operator }: FilterInputProps) {
    const [inputValue, setInputValue] = useState<Date | string | null>(() => {
        const value = column.filterCondition?.values[0] as Date | string | undefined
        if (typeof value === 'string' && isDateConstant(value)) {
            return value
        }
        if (typeof value === 'string') {
            const date = parseISO(value)
            return isValid(date) ? date : null
        }

        return value ?? null
    })
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false)

    function saveFilter() {
        const value =
            inputValue instanceof Date
                ? column.type === 'date'
                    ? formatISO(inputValue, { representation: 'date' })
                    : inputValue.toISOString()
                : inputValue
        column.setFilterCondition({
            operator,
            values: [value],
        })
    }

    return (
        <>
            <Datepicker
                isOpen={isDatepickerOpen}
                onCancel={() => setIsDatepickerOpen(false)}
                onDateSubmit={(date) => {
                    setInputValue(date)
                    setIsDatepickerOpen(false)
                }}
                onRangeSubmit={(range: string) => {
                    setInputValue(range)
                    setIsDatepickerOpen(false)
                }}
                dateOnly={column.type === 'date'}
                withSelect
                allowDateRanges
                className="bg-grey-650"
                arrowClassName="after:bg-grey-650"
            >
                <Button
                    title="Select date"
                    variant="quaternary"
                    size="small"
                    onClick={() => setIsDatepickerOpen((v) => !v)}
                    className={clsx('w-full bg-white/5', !inputValue && 'text-white/40')}
                >
                    <span className="text-ellipsis overflow-x-hidden whitespace-nowrap text-left">
                        {getDateInputText(inputValue, column)}
                    </span>
                </Button>
            </Datepicker>
            <FilterInputActions
                column={column}
                saveFilter={isPristine(inputValue, column, operator) ? undefined : saveFilter}
                onRemoveFilterCondition={() => setInputValue(undefined)}
            />
        </>
    )
}

function getDateInputText(inputValue: Date | string | undefined, column: Column) {
    if (!inputValue) {
        if (column.filterCondition) {
            return 'Empty'
        }
        return 'Select date'
    }

    if (typeof inputValue === 'string') {
        return inputValue.replace(/_/g, ' ')
    }

    if (column.type === 'date') {
        return getDateLong(inputValue)
    }

    return `${getDateLong(inputValue)} ${getTime(inputValue)}`
}

function isPristine(inputValue: Date | string | undefined, column: Column, operator: Operator) {
    const { filterCondition } = column

    if (!filterCondition) {
        return false //inputValue === undefined
    }

    const filterConditionValue = filterCondition.values[0] as Date | string | undefined
    if (typeof filterConditionValue === 'string' || typeof inputValue === 'string') {
        return filterConditionValue === inputValue && operator === filterCondition.operator
    }

    return (
        inputValue?.getTime() === filterConditionValue?.getTime() &&
        operator === filterCondition.operator
    )
}

import { z } from '@laserfocus/shared/decoder'

import { DateTimeStringSchema } from '../base/date.types'

import { ConvertLeadSchema } from './convert-schema'

const sobjectSchema = z.enum(['Lead', 'Account', 'Contact', 'Opportunity'])

const metaSchema = z
    .object({
        failedMutationId: z.number(),
    })
    .partial()

const optimisticSchema = z.object({
    LastModifiedDate: DateTimeStringSchema.optional(),
})

const ConvertLeadMutationInputSchema = z.object({
    convert: ConvertLeadSchema,
    meta: metaSchema.optional(),
    optimistic: optimisticSchema.optional(),
    // accountUpdate: z.record(z.string(), z.any()).optional(),
    // contactUpdate: z.record(z.string(), z.any()).optional(),
    // opportunityUpdate: z.record(z.string(), z.any()).optional(),
})

export type ConvertLeadMutationInput = z.infer<typeof ConvertLeadMutationInputSchema>

export const SObjectMutatorSchema = {
    bulkUpdateObjects: z.object({
        sobject: sobjectSchema,
        ids: z.array(z.string()),
        values: z.record(z.string(), z.any()),
        meta: metaSchema.optional(),
        optimistic: optimisticSchema.optional(),
    }),
    updateObject: z.object({
        sobject: sobjectSchema,
        id: z.string(),
        values: z.record(z.string(), z.any()),
        meta: metaSchema.optional(),
        optimistic: optimisticSchema.optional(),
    }),
    createObject: z.object({
        sobject: sobjectSchema,
        optimisticId: z.string(),
        values: z.record(z.string(), z.any()),
        meta: metaSchema.optional(),
        optimistic: optimisticSchema.optional(),
    }),
    convertLead: ConvertLeadMutationInputSchema,
    discardFailedMutation: z.object({
        mutationId: z.number(),
    }),
}

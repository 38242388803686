import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function AscendingOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M2 7a1 1 0 011-1h13a1 1 0 110 2H3a1 1 0 01-1-1zm0 4a1 1 0 011-1h9a1 1 0 110 2H3a1 1 0 01-1-1zm15.38-.92a1 1 0 01.32.21l4 4a1 1 0 01-1.4 1.42L18 13.4V23a1 1 0 11-2 0v-9.59l-2.3 2.3a1 1 0 01-1.4-1.42l4-4a1 1 0 011.08-.21zM2 15a1 1 0 011-1h6a1 1 0 110 2H3a1 1 0 01-1-1z" />
        </SvgIcon>
    )
}

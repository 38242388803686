import { isBefore } from 'date-fns'

import { UserSubscription } from '@laserfocus/shared/models'
import { getDateLong } from '@laserfocus/ui/util-locale'

const LABEL_CLASSES = 'leading-[1.3] text-sm text-grey-700/60'
const VALUE_CLASSES = 'leading-[1.4]'

export function PlanInfo({ subscription }: { subscription: UserSubscription }) {
    return (
        <div className="group grid grid-cols-[minmax(0,2fr),minmax(0,3fr)] gap-2 items-start outline-none focus-visible:ring pr-2 rounded-md pt-2">
            <PlanRow subscription={subscription} />
            <TrialRow subscription={subscription} />
        </div>
    )
}

function PlanRow({ subscription }: { subscription: UserSubscription }) {
    return (
        <>
            <span className={LABEL_CLASSES}>Plan</span>
            <span className={VALUE_CLASSES}>{getPlanName(subscription)}</span>
        </>
    )
}

function TrialRow({ subscription }: { subscription: UserSubscription }) {
    if (!isStillInTrial(subscription) || !subscription.trialEnd || subscription.plan === 'pro') {
        return null
    }
    return (
        <>
            <span className={LABEL_CLASSES}>Trial End</span>
            <span className={VALUE_CLASSES}>{getDateLong(new Date(subscription.trialEnd))}</span>
        </>
    )
}

function getPlanName(sub: UserSubscription) {
    if (sub.plan && sub.plan !== 'free') {
        return sub.plan
    }
    if (isStillInTrial(sub)) {
        return 'trial'
    }
    return 'free'
}
function isStillInTrial(sub: UserSubscription) {
    return sub.trialEnd ? isBefore(new Date(), new Date(sub.trialEnd)) : false
}

import { useState } from 'react'

import { Button, Select } from '@laserfocus/ui/beam'

import { useRoleContext } from './RoleContext'

export function RoleSelect({ className }: { className?: string }) {
    const { roles, selectedRole, setSelectedRole } = useRoleContext()
    const [isOpen, setIsOpen] = useState(false)

    const options = roles.map((o) => ({ ...o, value: o.name, label: o.label || o.name }))
    const selected = roles.find((r) => r.name === selectedRole)
    return (
        <Select
            options={options}
            isOpen={isOpen}
            onSubmit={({ value }) => {
                setSelectedRole(value)
                setIsOpen(false)
            }}
            onCancel={() => setIsOpen(false)}
            initialOptionValue={selectedRole ?? undefined}
        >
            <Button className={className} onClick={() => setIsOpen((a) => !a)}>
                {selected?.label || selectedRole}
            </Button>
        </Select>
    )
}

import { DateString, DateTimeString } from '..'
import {
    Identifier,
    Id,
    ModelCustomFields,
    WithCustomFields,
    WithCustomFieldsDTO,
    BaseFields,
    ToDTO,
    Currency,
    WithType,
} from '../base/model.types'

import { PureAccount, PureAccountDTO } from './account.types'
import { PersonActivity, ActivityDTO, OwnerDTO } from './activity.types'

export const OPPORTUNITY_PREFIX = '006'

export function isOpportunity(id?: string | null): id is string {
    return id ? id.substr(0, 3) === OPPORTUNITY_PREFIX : false
}

export type OpportunityFields = Partial<BaseFields> &
    Partial<{
        Name: string
        StageName: string
        readonly IsClosed: boolean
        readonly IsWon: boolean
        Probability: number
        Amount: number
        CloseDate: DateString
        ExpectedRevenue: number
        readonly LastActivityDate: DateTimeString

        CurrencyIsoCode: Currency
        LastStageChangeDate: DateTimeString
        HasOpportunityLineItem: boolean

        /**
         * RelationShipField
         */
        OwnerId: Id
        AccountId: Id
    }>

type OpportunityRelationShips = Partial<{
    OpenActivities: PersonActivity[]
    ActivityHistories: PersonActivity[]
    Account: PureAccount
}>

export type OpportunityProps = Identifier & OpportunityFields & WithType<'Opportunity'>
export type OpportunityModel = OpportunityProps & ModelCustomFields

export function isOpportunityModel(model: WithType<string>): model is OpportunityModel {
    return model.__typename === 'Opportunity'
}

export interface PureOpportunity extends OpportunityProps, WithCustomFields {}

export interface Opportunity extends PureOpportunity, OpportunityRelationShips {}

export interface PureOpportunityDTO
    extends Identifier,
        ToDTO<OpportunityFields>,
        WithCustomFieldsDTO {
    __typename: 'Opportunity'
}
export interface OpportunityDTO extends PureOpportunityDTO {
    OpenActivities?: ActivityDTO[]
    ActivityHistories?: ActivityDTO[]
    Account?: PureAccountDTO
    Owner?: OwnerDTO
}

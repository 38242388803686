import { useState } from 'react'
import { Control, Controller, FieldPath } from 'react-hook-form'
import { useObserver } from 'mobx-react-lite'
import clsx from 'clsx'

import { useLocalSearchStore } from '@laserfocus/client/feature-search'
import { Select, DropdownBaseInput, DropdownInput, SpinnerInline } from '@laserfocus/ui/beam'
import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { OpportunitySearchResult } from '@laserfocus/shared/models'

import { ConvertLeadFormState } from './ConvertLeadModal'

export function SObjectSearchSelect({
    control,
    sobject,
    label,
    name,
    required,
}: {
    control: Control<ConvertLeadFormState>
    sobject: 'Lead' | 'Account' | 'Contact' | 'Opportunity'
    label: string
    name: FieldPath<
        Pick<ConvertLeadFormState, 'existingOpportunity' | 'existingAccount' | 'existingContact'>
    >
    required?: string
}) {
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const id = `convert-${name}`
    const searchStore = useLocalSearchStore()

    const { options, isLoading } = useObserver(() => {
        const options = searchStore.currentData.map((a) => ({
            ...a,
            value: a.Id,
            label: a.Name,
        }))
        return {
            options,
            isLoading: searchStore.isLoading,
        }
    })

    return (
        <div className="grid grid-cols-[minmax(0,2fr),minmax(0,3fr)] items-start gap-y-2">
            <label
                htmlFor={id}
                // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
                className="text-sm font-medium leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
            >
                {label}
                {required && (
                    <span title="Required" className="px-1 -mr-1 select-none text-red-500">
                        •
                    </span>
                )}
            </label>
            <Controller
                name={name}
                control={control}
                rules={{ required }}
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
                    const label = typeof value === 'object' && value !== null ? value.Name : ''
                    return (
                        <Select
                            isOpen={isSelectOpen}
                            options={options}
                            initialOptionValue={(value as string) || undefined}
                            onCancel={() => setIsSelectOpen(false)}
                            onSubmit={(option) => {
                                setIsSelectOpen(false)
                                onChange(option)
                                // setSelectedOption(option)
                            }}
                            searchKeys={['Name', 'Company', 'Email', 'Website', 'AccountName']}
                            size="large"
                            renderInput={({ searchValue, setSearchValue }) => (
                                <SearchInput
                                    searchValue={searchValue}
                                    setSearchValue={(value) => {
                                        setSearchValue(value)
                                        searchStore.debouncedSearch(value, sobject)
                                    }}
                                    isLoading={isLoading}
                                />
                            )}
                        >
                            <DropdownBaseInput
                                id={id}
                                variant="border"
                                forceFocused={isSelectOpen}
                                onClick={() => setIsSelectOpen(true)}
                                value={label}
                                error={(error as any)?.message}
                            />
                        </Select>
                    )
                }}
            />
        </div>
    )
}

export function SObjectSelect({
    control,
    name,
    label,
    options,
}: {
    control: Control<ConvertLeadFormState>
    name: FieldPath<Pick<ConvertLeadFormState, 'existingOpportunity' | 'existingContact'>>
    label: string
    options: Partial<Pick<OpportunitySearchResult, 'Id' | 'AccountId' | 'Name'>>[]
}) {
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const id = `convert-${name}`

    const parsedOptions = options.map((a) => ({
        label: a.Name || a.Id,
        value: a.Id,
        ...a,
    }))
    return (
        <div className="grid grid-cols-[minmax(0,2fr),minmax(0,3fr)] items-start gap-y-2">
            <label
                htmlFor={id}
                // leading-[1.45]: Per design it's 1.4 but that doesn't add up to the correct height
                className="text-sm font-medium leading-[1.45] text-grey-700/60 pr-6 py-[0.3125rem]"
            >
                {label}
            </label>
            <Controller
                name={name}
                control={control}
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
                    const label = typeof value === 'object' && value !== null ? value.Name : ''
                    return (
                        <Select
                            isOpen={isSelectOpen}
                            options={parsedOptions}
                            initialOptionValue={(value as string) || undefined}
                            onCancel={() => setIsSelectOpen(false)}
                            onSubmit={(option) => {
                                setIsSelectOpen(false)
                                onChange(option)
                            }}
                            size="large"
                        >
                            <DropdownBaseInput
                                id={id}
                                variant="border"
                                forceFocused={isSelectOpen}
                                onClick={() => setIsSelectOpen(true)}
                                value={label}
                                error={(error as any)?.message}
                            />
                        </Select>
                    )
                }}
            />
        </div>
    )
}

function SearchInput({
    searchValue,
    setSearchValue,
    isLoading,
}: {
    searchValue: string
    setSearchValue: React.Dispatch<string>
    isLoading: boolean
}) {
    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)
    return (
        <div className="relative">
            <DropdownInput
                ref={inputRef}
                placeholder="Search"
                aria-label="Search"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                className={clsx('w-full', isLoading && 'pr-7')}
            />
            {isLoading && (
                <div className="absolute right-2 top-[calc(50%-8px)]">
                    <SpinnerInline />
                </div>
            )}
        </div>
    )
}

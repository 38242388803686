import { ReadTransaction } from 'replicache'
import { useEffect } from 'react'
import { useSubscribe } from 'replicache-react'
import { groupBy, mapValues } from 'lodash'

import { getClient } from '@laserfocus/client/replicache'
import {
    Prefix,
    SyncedRecord,
    isTruthy,
    isLead,
    NewTask,
    NewEvent,
} from '@laserfocus/shared/models'
import { getRequiredKeys } from '@laserfocus/client/data-access-shared'

import { usePrefetch } from './PrefetchStore'

type SyncStatusWithKey = SyncedRecord & { key: string }

export function usePrefetchOpenActivityRoots(userId?: string) {
    const prefetch = usePrefetch()
    const client = getClient()

    const rootsToFetch = useSubscribe(
        client,
        async (tx: ReadTransaction) => {
            if (!userId) {
                return []
            }
            const openTaskPrefix = [userId, 'OPEN'].join('#')
            const datePrefix = new Date().toISOString().split('T')[0]
            const eventPrefix = [userId, datePrefix].join('#')

            const [syncStatusEntries, openTasks, upcomingEvents] = await Promise.all([
                tx.scan({ prefix: Prefix.SyncStatus }).entries().toArray() as Promise<
                    [string, SyncedRecord][]
                >,
                tx
                    .scan({ indexName: 'openTasksByUser', prefix: openTaskPrefix })
                    .values()
                    .toArray() as Promise<NewTask[]>,
                tx
                    .scan({
                        indexName: 'eventsByOwnerAndStartDateTime',
                        start: {
                            key: eventPrefix,
                        },
                    })
                    .values()
                    .toArray() as Promise<NewEvent[]>,
            ])

            const syncStatusWithKey: SyncStatusWithKey[] = syncStatusEntries.map(
                ([key, status]) => ({ ...status, key })
            )
            const syncStatusByRoot = groupBy(syncStatusWithKey, 'id')
            const syncedStatusKeyByRoot = mapValues(
                syncStatusByRoot,
                (syncStatis: SyncStatusWithKey[]) => {
                    return Object.fromEntries(
                        syncStatis.map((a) => {
                            const splitted = a.key.split('/') as [string, string, string]
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const [syncPrefix, rootId, relation] = splitted
                            return [relation, true]
                        })
                    )
                }
            )

            const openTaskRoots = openTasks.map(getRootId).filter(isTruthy)

            const upcomingEventRoots = upcomingEvents.map(getRootId).filter(isTruthy)

            const unfetchedRoots = [...openTaskRoots, ...upcomingEventRoots].filter((id) => {
                const fetchedForRoot = syncedStatusKeyByRoot[id]
                if (!fetchedForRoot) {
                    return true
                }
                const requiredKeys = getRequiredKeys(id)
                const missingKeys = requiredKeys.filter((key) => !Boolean(fetchedForRoot[key]))
                return missingKeys.length > 0
            })
            return [...new Set(unfetchedRoots)].sort()
        },
        [],
        [userId]
    )

    useEffect(() => {
        prefetch(rootsToFetch)
    }, [rootsToFetch, prefetch])
}

function getRootId(a: NewTask | NewEvent) {
    if (a.RootId) {
        return a.RootId
    }
    if (isLead(a.WhoId)) {
        return a.WhoId
    }
    return a.AccountId ?? null
}

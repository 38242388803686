import { useState } from 'react'

import { Button, Select } from '@laserfocus/ui/beam'
import { AddOutlinedIcon, ArrowDownOutlinedIcon } from '@laserfocus/ui/icons'

interface CardHeaderSelectProps {
    elementName: string
    options: Option[]
    selectedOption: Option | undefined
    addButtonLabel?: string
    addElement?(): void
    onSelect?(value: string): void
}

interface Option {
    value: string
    label: string
}

export function CardHeaderSelect(props: CardHeaderSelectProps) {
    if (props.options.length === 0) {
        return (
            <CardHeaderAddButton
                elementName={props.elementName}
                label={props.addButtonLabel}
                onClick={props.addElement}
            />
        )
    }
    return <CardHeaderSelectInner {...props} />
}

interface CardHeaderAddButtonProps {
    elementName: string
    label?: string
    onClick?(): void
}

function CardHeaderAddButton({ elementName, label, onClick }: CardHeaderAddButtonProps) {
    return (
        <Button
            title={label ? undefined : `Add ${elementName}`}
            variant="tertiary"
            size="small"
            iconComponent={AddOutlinedIcon}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

function CardHeaderSelectInner({
    elementName,
    options,
    selectedOption,
    addElement,
    onSelect,
}: CardHeaderSelectProps) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Select
            isOpen={isOpen}
            onCancel={() => setIsOpen(false)}
            options={options}
            initialOptionValue={selectedOption?.value}
            searchKeys={['label']}
            bottomAction={{
                label: `Add ${elementName}`,
                onClick: () => {
                    setIsOpen(false)
                    addElement?.()
                },
            }}
            onSubmit={(option) => {
                onSelect?.(option.value)
                setIsOpen(false)
            }}
        >
            <Button
                onClick={() => setIsOpen(true)}
                variant="tertiary"
                size="small"
                iconComponent={ArrowDownOutlinedIcon}
                iconPosition="right"
                className="max-w-full"
            >
                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                    {selectedOption?.label || `Select ${elementName}`}
                </span>
            </Button>
        </Select>
    )
}

import { useEffect } from 'react'
import { useObserver } from 'mobx-react-lite'

import { getDisplayName, identifyPrefix } from '@laserfocus/shared/models'
import { useObjectPool } from '@laserfocus/client/root-store-context'

import { useSyncName } from './NameStore'

export function useReferencedDisplayName(id?: string | null) {
    const objectPool = useObjectPool()
    const syncNames = useSyncName()

    const { isLoading, name, didLoad } = useObserver(() => {
        const type = identifyPrefix(id)
        if (!type || !id) {
            return {
                isLoading: false,
                didLoad: false,
                name: null,
            }
        }
        const sobject = objectPool.get(type, id)
        if (sobject) {
            return {
                isLoading: false,
                didLoad: true,
                name: getDisplayName(sobject as any),
            }
        }
        const nameObject = objectPool.get('Name', id) as unknown as
            | { Name: string; isLoading: boolean }
            | undefined
        if (nameObject) {
            return {
                isLoading: nameObject.isLoading,
                didLoad: !nameObject.isLoading,
                name: nameObject.Name,
            }
        }
        return {
            isLoading: false,
            didLoad: false,
            name: null,
        }
    })

    useEffect(() => {
        if (!isLoading && !didLoad && id) {
            syncNames([id])
        }
    }, [isLoading, didLoad, id, syncNames])

    return {
        isLoading,
        name,
        didLoad,
    }
}

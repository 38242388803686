import 'reflect-metadata'
import { observable, computed, toJS } from 'mobx'
import { createTransformer } from 'mobx-utils'
import { keyBy } from 'lodash'

import { Account, Contact, isTruthy, Lead, Opportunity } from '@laserfocus/shared/models'
import type {
    FieldType,
    SObjectType,
    ReferenceType,
    FieldMetadataProps,
    PicklistValue,
    Group,
    User,
} from '@laserfocus/shared/models'
import { ObjectPool } from '@laserfocus/client/data-layer'
import { assert, logger } from '@laserfocus/ui/logger'

import { RecordtypeModel } from './RecordtypeModel'

type PicklistOption = PicklistValue

export interface MetadataSerialized extends Omit<FieldMetadataProps, 'isCustomField'> {
    Id: string
}

// Those two types are in the frontend and backend (need to be moved somewhere sensible)
export type ViewObject = 'Lead' | 'Account' | 'Opportunity' | 'Contact'

type SelectOption = {
    label: string | null
    value: string
    inactive?: boolean
}

export type FieldView = {
    viewObject: ViewObject
    fields: string[]
    viewName?: string
}

export type FieldRecordType = {
    fieldName: string
    recordTypeId: string
    recordTypeName: string
    defaultValue?: string
    picklistValues: PicklistValue[]
}

export class FieldMetadata implements MetadataSerialized {
    __typename = 'FieldMetadata' as const

    @observable objectName: SObjectType
    @observable name: string
    @observable fieldType: FieldType

    @observable label: string
    @observable updateable: boolean
    @observable createable: boolean
    @observable required: boolean

    @observable referenceTo: ReferenceType[] = []
    @observable controllerFieldName?: string | null
    @observable inlineHelpText?: string | null
    @observable picklistValues: PicklistValue[] = []
    @observable defaultValue?: string | boolean | null

    @observable precision?: number
    @observable scale?: number
    @observable length?: number
    @observable editableForNew?: boolean
    @observable editableForUpdate?: boolean
    @observable inCreateLayout?: boolean
    @observable deprecated?: boolean

    objectPool!: ObjectPool

    constructor(props: Omit<FieldMetadataProps, 'isCustomField'>, objectPool: ObjectPool) {
        if (objectPool) {
            this.objectPool = objectPool
        }
        this.objectName = props.objectName
        this.name = props.name
        this.fieldType = props.fieldType

        this.label = props.label
        this.picklistValues = props.picklistValues || []
        this.updateable = props.updateable
        this.createable = props.createable
        this.required = props.required

        this.referenceTo = props.referenceTo
        this.controllerFieldName = props.controllerFieldName
        this.inlineHelpText = props.inlineHelpText
        this.defaultValue = props.defaultValue

        this.length = props.length
        this.scale = props.scale
        this.precision = props.precision

        this.editableForNew = props.editableForNew
        this.editableForUpdate = props.editableForUpdate
        this.inCreateLayout = props.inCreateLayout
        this.deprecated = props.deprecated
    }

    toJSON(): MetadataSerialized {
        return {
            Id: this.fullName,
            __typename: 'FieldMetadata',
            objectName: this.objectName,
            name: this.name,
            fieldType: this.fieldType,
            label: this.label,
            referenceTo: this.referenceTo,
            controllerFieldName: this.controllerFieldName,
            inlineHelpText: this.inlineHelpText,
            picklistValues: toJS(this.picklistValues),
            defaultValue: this.defaultValue,
            precision: this.precision,
            length: this.length,
            scale: this.scale,
            updateable: this.updateable,
            createable: this.createable,
            required: this.required,
            editableForNew: this.editableForNew,
            editableForUpdate: this.editableForUpdate,
            inCreateLayout: this.inCreateLayout,
            deprecated: this.deprecated,
        }
    }

    serialize() {
        return toJS(this.toJSON(), { recurseEverything: true })
    }

    get props() {
        return this.serialize()
    }

    @computed
    get Id() {
        return this.fullName
    }

    @computed
    get fullName() {
        return `${this.objectName}.${this.name}`
    }

    @computed
    get readOnly() {
        return !this.updateable
    }

    @computed
    get labelsByValue() {
        return Object.fromEntries(this.valueOptions.map((v) => [v.value, v.label || v.value]))
    }

    renderLabel = createTransformer((value: string) => {
        const plv = this.valueOptions?.find((v) => v.value === value)
        return plv ? plv.label : value
    })

    @computed
    get valueOptions(): SelectOption[] {
        const allOptions = []
        if (this.isPicklist) {
            return this.picklistValues as SelectOption[]
        }
        if (this.isRecordTypeReference) {
            return this.recordTypeOptions
        }

        if (this.isUserReference) {
            allOptions.push(...this.userOptions)
        }
        if (this.isGroupReference) {
            allOptions.push(...this.groupOptions)
        }
        // if (this.isAccountReference) {
        //     allOptions.push(...this.accountOptions)
        // }
        // if (this.isContactReference) {
        //     allOptions.push(...this.contactOptions)
        // }
        // if (this.isOpportunityReference) {
        //     allOptions.push(...this.opportunityOptions)
        // }
        // if (this.isLeadReference) {
        //     allOptions.push(...this.leadOptions)
        // }
        return allOptions
    }

    @computed
    get picklistOptions(): Array<PicklistOption> {
        const options = this.picklistValues || []
        return options
    }

    @computed
    get userOptions() {
        assert(this.objectPool, 'I am trying to get the options for a userfield')
        const users = Array.from(this.objectPool.getAll('User').values()) as unknown as User[]
        const userOptions = users.map((u) => ({
            value: u.Id,
            label: [u.FirstName, u.LastName].filter(Boolean).join(' '),
            inactive: !u.IsActive,
        }))
        return userOptions
    }

    // @computed
    // get accountOptions() {
    //     assert(this.objectPool, 'I am trying to get the options for a userfield')
    //     const accounts = Array.from(
    //         this.objectPool.getAll('Account').values()
    //     ) as unknown as Account[]
    //     const accountOptions = accounts.map((u) => ({
    //         value: u.Id,
    //         label: [u.Name].filter(Boolean).join(' '),
    //     }))
    //     return accountOptions
    // }

    // @computed
    // get opportunityOptions() {
    //     assert(this.objectPool, 'I am trying to get the options for a userfield')
    //     const accounts = Array.from(
    //         this.objectPool.getAll('Opportunity').values()
    //     ) as unknown as Opportunity[]
    //     const opportunityOptions = accounts.map((u) => ({
    //         value: u.Id,
    //         label: [u.Name].filter(Boolean).join(' '),
    //     }))
    //     return opportunityOptions
    // }

    // @computed
    // get contactOptions() {
    //     assert(this.objectPool, 'I am trying to get the options for a userfield')
    //     const contacts = Array.from(
    //         this.objectPool.getAll('Opportunity').values()
    //     ) as unknown as Contact[]
    //     const contactsOptions = contacts.map((u) => ({
    //         value: u.Id,
    //         label: [u.FirstName, u.LastName].filter(Boolean).join(' '),
    //     }))
    //     return contactsOptions
    // }

    // @computed
    // get leadOptions() {
    //     assert(this.objectPool, 'I am trying to get the options for a userfield')
    //     const leads = Array.from(this.objectPool.getAll('Lead').values()) as unknown as Lead[]
    //     const leadOptions = leads.map((u) => ({
    //         value: u.Id,
    //         label: [u.FirstName, u.LastName].filter(Boolean).join(' '),
    //     }))
    //     return leadOptions
    // }

    @computed
    get groupOptions() {
        const groups = Array.from(this.objectPool.getAll('Group').values()) as unknown as Group[]
        return groups
            .filter((g) => g.QueueSobjects.includes(this.objectName))
            .map((g) => ({
                value: g.Id,
                label: g.Name || g.DeveloperName,
            }))
    }

    @computed
    get recordTypeOptions() {
        assert(this.objectPool, 'I am trying to get the options for a userfield')
        const records = Array.from(this.objectPool.getAll<RecordtypeModel>('RecordType').values())
        const myRecordTypes = records.filter(
            (recordType) => recordType.objectName === this.objectName
        )
        const options = myRecordTypes.map((u) => u.toOption())
        return options
    }

    /**
     * @deprecated not sure if this logic makes sense. Need to double check
     */
    @computed
    get controllerField(): FieldMetadata | null | undefined {
        if (!this.controllerFieldName) {
            return null
        }
        const fullName = `${this.objectName}.${this.controllerFieldName}`
        const controllingField = this.objectPool?.get<FieldMetadata>('FieldMetadata', fullName)
        return controllingField
    }

    @computed
    get recordTypesById(): Record<string, FieldRecordType> {
        const allRecordTypes = Array.from(
            this.objectPool.getAll<RecordtypeModel>('RecordType').values()
        )
        const objectRecordTypes = allRecordTypes.filter((rt) => rt.objectName === this.objectName)

        const picklists = objectRecordTypes
            .map((rt): FieldRecordType | false => {
                const picklist = rt.picklistsByField[this.name]
                if (!picklist) {
                    return false
                }
                return {
                    fieldName: this.name,
                    recordTypeId: rt.id,
                    recordTypeName: rt.name,
                    defaultValue: picklist.defaultValue,
                    picklistValues: picklist.picklistValues,
                }
            })
            .filter(isTruthy)
        const byRecordTypeId = keyBy(picklists, 'recordTypeId')
        return byRecordTypeId
    }

    getPicklistOptions(recordTypeId?: string): SelectOption[] {
        assert(
            ['picklist', 'reference', 'multipicklist'].includes(this.fieldType),
            `Can only get picklist options for picklists or references, but I am a ${this.fieldType}`
        )

        if (recordTypeId && this.recordTypesById[recordTypeId]) {
            const recordType = this.recordTypesById[recordTypeId]
            if (recordType) {
                if (!this.required && this.fieldType !== 'multipicklist') {
                    return [
                        {
                            label: '—',
                            value: '',
                        },
                        ...recordType.picklistValues,
                    ]
                }
                return recordType.picklistValues as SelectOption[]
            }
        }
        if (recordTypeId && ['picklist', 'multipicklist'].includes(this.fieldType)) {
            logger.warn(`Did not find FieldRecordType for ${this.fullName}:${recordTypeId}`)
        }
        if (!this.required && this.fieldType !== 'multipicklist') {
            return [
                {
                    label: '—',
                    value: '',
                },
                ...this.valueOptions,
            ]
        }
        return this.valueOptions
    }

    @computed
    get isUserReference() {
        return Boolean(this.fieldType === 'reference' && this.referenceTo?.includes('User'))
    }

    @computed
    get isAccountReference() {
        return Boolean(this.fieldType === 'reference' && this.referenceTo?.includes('Account'))
    }

    @computed
    get isContactReference() {
        return Boolean(this.fieldType === 'reference' && this.referenceTo?.includes('Contact'))
    }

    @computed
    get isOpportunityReference() {
        return Boolean(this.fieldType === 'reference' && this.referenceTo?.includes('Opportunity'))
    }

    @computed
    get isLeadReference() {
        return Boolean(this.fieldType === 'reference' && this.referenceTo?.includes('Lead'))
    }

    @computed
    get isGroupReference() {
        return Boolean(this.fieldType === 'reference' && this.referenceTo?.includes('Group'))
    }

    @computed
    get isRecordTypeReference() {
        return this.fieldType === 'reference' && this.referenceTo?.includes('RecordType')
    }

    @computed
    get isReferenceList() {
        return Boolean(this.isUserReference || this.isRecordTypeReference || this.isGroupReference)
    }

    @computed
    get isSearchReference() {
        return Boolean(
            this.isAccountReference ||
                this.isLeadReference ||
                this.isOpportunityReference ||
                this.isContactReference
        )
    }

    @computed
    get isPicklist() {
        return this.picklistValues && this.picklistValues.length > 0
    }

    @computed
    get addressChildMetadata(): Record<string, FieldMetadata> {
        if (this.fieldType === 'address') {
            const [prefixPath] = this.name.split('Address')
            const fields = Object.fromEntries(
                ['Street', 'PostalCode', 'City', 'State', 'Country'].map((name) => {
                    const fullFieldName = `${this.objectName}.${prefixPath}${name}`
                    const field = this.objectPool.get<FieldMetadata>(
                        'FieldMetadata',
                        fullFieldName
                    )!
                    return [name, field]
                })
            )
            return fields
        }
        return {}
    }
}

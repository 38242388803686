import { z } from '@laserfocus/shared/decoder'

import { EventId, EventIdSchema, TaskId, TaskIdSchema } from '../base/id.types'
import { DateStringSchema, DateTimeStringSchema } from '../base/date.types'

const BaseFields = z.object({
    CreatedDate: DateTimeStringSchema,
    LastModifiedDate: DateTimeStringSchema,
    CreatedById: z.string(),
    LastModifiedById: z.string(),
})

const BaseActivityFields = z.object({
    Subject: z.string().nullish(),
    Description: z.string().nullish(),
    OwnerId: z.string().nullish(),
    AccountId: z.string().nullish(),
    WhoId: z.string().nullish(),
    WhatId: z.string().nullish(),
    AlternateDetailId: z.string().nullish(),
    Type: z.string().nullish(),
})

const LaserfocusBaseActivityFields = z.object({
    RootId: z.string().nullish(),
})

const LaserfocusRelationshipFields = z.object({
    Who: z
        .object({
            Name: z.string().nullish(),
            Company: z.string().nullish(),
        })
        .partial()
        .nullish(),
    Account: z
        .object({
            Name: z.string().or(z.null()),
        })
        .partial()
        .nullish(),
})

const ReadOnlyFields = z.object({
    IsClosed: z.boolean(),
})

const TaskFields = z.object({
    ActivityDate: DateStringSchema.nullish(),
    CompletedDateTime: DateTimeStringSchema.nullish(),
    /**
     * The ReminderDateTime is our escape hatch to get Times on the tasks
     */
    ReminderDateTime: DateTimeStringSchema.nullish(),
    Status: z.union([
        z.literal('Not Started'),
        z.literal('In Progress'),
        z.literal('Completed'),
        z.literal('Waiting on someone else'),
        z.literal('Deferred'),
        z.string(),
        z.null(),
    ]),
    TaskSubtype: z.string().nullish(),

    Priority: z.string().nullish(),
})

const TaskInput = BaseFields.merge(BaseActivityFields).merge(TaskFields).partial()

export const TaskUpdateValuesSchema = TaskInput
export const TaskUpdateInputSchema = TaskInput.extend({ Id: TaskIdSchema })
export const TaskCreateInputSchema = TaskInput

export const NewTaskSchema = TaskInput.merge(ReadOnlyFields)
    .merge(LaserfocusBaseActivityFields)
    .merge(LaserfocusRelationshipFields)
    .extend({ Id: TaskIdSchema, __typename: z.literal('Task').default('Task') })

export type NewTask = z.infer<typeof NewTaskSchema> & { Id: TaskId; __typename: 'Task' }
export type TaskUpdateInput = z.infer<typeof TaskUpdateInputSchema>
export type TaskCreateInput = z.infer<typeof TaskCreateInputSchema>

const EventFields = z.object({
    StartDateTime: DateTimeStringSchema.nullish(),
    EndDateTime: DateTimeStringSchema.nullish(),
    DurationInMinutes: z.number().nullish(),
    ActivityDateTime: DateTimeStringSchema.nullish(),
    EventSubtype: z.string().nullish(),
})

const EventInput = BaseFields.merge(BaseActivityFields).merge(EventFields).partial()

export const EventUpdateInputSchema = EventInput.extend({ Id: EventIdSchema })
export const EventCreateInputSchema = EventInput

export const NewEventSchema = EventInput.merge(LaserfocusBaseActivityFields)
    .merge(LaserfocusRelationshipFields)
    .extend({
        Id: EventIdSchema,
        __typename: z.literal('Event').default('Event'),
    })

export type NewEvent = z.infer<typeof NewEventSchema> & { Id: EventId; __typename: 'Event' }

export type EventUpdateInput = z.infer<typeof EventUpdateInputSchema>
export type EventCreateInput = z.infer<typeof EventCreateInputSchema>

export type NewActivity = NewEvent | NewTask

export function getOpenByUserIdx(task: Pick<NewTask, 'OwnerId' | 'IsClosed' | 'ActivityDate'>) {
    const idxOpenByUser = [
        task.OwnerId ? task.OwnerId : 'UNASSIGNED',
        task.IsClosed ? 'COMPLETE' : 'OPEN',
        task.ActivityDate,
    ]
        .filter(Boolean)
        .join('#')
    return idxOpenByUser
}

export function getEventStartDateIdx(event: Pick<NewEvent, 'OwnerId' | 'StartDateTime'>) {
    const idxOwnerStartDate = [event.OwnerId ? event.OwnerId : 'UNASSIGNED', event.StartDateTime]
        .filter(Boolean)
        .join('#')
    return idxOwnerStartDate
}

import { twMerge } from 'tailwind-merge'

import { DropdownButton } from './dropdowns/controls/DropdownButton'
import { DropdownCheckbox } from './dropdowns/controls/DropdownCheckbox'

interface StaticSelectProps<Value extends string> {
    options: Option<Value>[]
    selectedOptionValue?: string
    className?: string
    onChange(option: Option<Value>): void
}

interface Option<Value extends string> {
    label: string
    value: Value
}

export function StaticSelect<Value extends string>({
    options,
    selectedOptionValue,
    className,
    onChange,
}: StaticSelectProps<Value>) {
    return (
        <Container className={className}>
            {options.map((option) => (
                <DropdownButton
                    key={option.value}
                    isHighlighted={option.value === selectedOptionValue}
                    onClick={() => onChange(option)}
                    className="transition-none"
                >
                    {option.label}
                </DropdownButton>
            ))}
        </Container>
    )
}

interface StaticMultiSelectProps<Value extends string> {
    options: Option<Value>[]
    selectedOptionValues?: Value[]
    className?: string
    onChange(optionsValues: Value[]): void
}

export function StaticMultiSelect<Value extends string>({
    options,
    selectedOptionValues = [],
    className,
    onChange,
}: StaticMultiSelectProps<Value>) {
    const selectedOptionsValuesByKey = Object.fromEntries(
        selectedOptionValues.map((value) => [value, true])
    )

    return (
        <Container className={className}>
            {options.map((option) => (
                <DropdownCheckbox
                    key={option.value}
                    label={option.label}
                    checked={Boolean(selectedOptionsValuesByKey[option.value])}
                    highlightOnHover
                    onChange={(event) => {
                        const nextOptionValues = selectedOptionValues.filter(
                            (value) => value !== option.value
                        )

                        if (event.target.checked) {
                            nextOptionValues.push(option.value)
                        }

                        onChange(nextOptionValues)
                    }}
                />
            ))}
        </Container>
    )
}

interface ContainerProps {
    className?: string
    children: React.ReactNode
}

function Container({ className, children }: ContainerProps) {
    return (
        <div className={twMerge('p-2 bg-grey-700 text-white grid gap-1 rounded-xl', className)}>
            {children}
        </div>
    )
}

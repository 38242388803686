import { matchSorter } from 'match-sorter'

import { create, SuggestOptions } from '@laserfocus/vendor/time-suggest'
import { getLocale } from '@laserfocus/ui/util-locale'
import type { DateSuggest } from '@laserfocus/vendor/time-suggest'

import {
    EmptySuggest,
    EMPTY_DATE_SUGGEST_OPTION,
    FilterDateSuggest,
    isDateSuggest,
    relativeSuggest,
} from './sf-date-suggest'

export function dateSuggest(input: string) {
    return create({
        // future_offset: 60 * 1000,
        locale: getLocale(),
        default_time: { hours: 8, minutes: 0, seconds: 0 },
        allow_past_dates: true,
        allow_someday: false,
    })(input)
}

type ExpandedSuggestOptions = SuggestOptions & {
    allowEmpty?: boolean
    allowDateRanges?: boolean
}
export function createDateSuggest(options: ExpandedSuggestOptions) {
    const { allowEmpty, allowDateRanges, ...rest } = options
    const finalOptions = {
        // future_offset: 60 * 1000,
        locale: getLocale(),
        default_time: { hours: 8, minutes: 0, seconds: 0 },
        allow_past_dates: true,
        allow_someday: false,
        min_date: rest?.allow_past_dates === false ? new Date() : new Date(1950, 0, 0, 0),
        ...rest,
    }
    const baseSuggest = create(finalOptions)
    return (input: string) => {
        const baseDateOptions: FilterDateSuggest[] = baseSuggest(input)

        const dateOptions = finalOptions.allow_past_dates
            ? baseDateOptions
            : baseDateOptions.filter((a) => {
                  /**
                   * This is a bug in the base sugesst, that I am compensating for in here
                   */
                  if (isDateSuggest(a)) {
                      return !a.tokens.some((a) => a.type === 'ago')
                  }
                  return true
              })
        if (allowEmpty) {
            const emptyAddition = matchSorter([EMPTY_DATE_SUGGEST_OPTION], input, {
                keys: ['formatted', 'tags'],
            })
            dateOptions.push(...emptyAddition)
        }
        if (allowDateRanges) {
            const relativeOptions = relativeSuggest(input)

            const relativeFormatted = relativeOptions.map((o) => o.formatted)
            const cleanedDateOptions = dateOptions.filter(
                (o) => !relativeFormatted.includes(o.formatted)
            )

            return [...relativeOptions, ...cleanedDateOptions]
        }
        return dateOptions
    }
}

if (typeof window !== 'undefined' && window) {
    ;(window as any).$createDateSuggest = createDateSuggest
}

export function getDefaultDateSuggestions(
    dateOnly?: boolean,
    allowEmpty?: boolean,
    amount?: number
) {
    const suggestionValues = dateOnly
        ? ['tomorrow', 'next week', 'in a month', 'next quarter', 'in a year']
        : ['in 1 hour', 'today', 'tomorrow', 'next week', 'in a month']

    const suggestions: Array<DateSuggest | EmptySuggest> = suggestionValues.map(
        (v) => dateSuggest(v)[0]!
    )
    const sliced = amount ? suggestions.slice(0, allowEmpty ? amount - 1 : amount) : suggestions
    if (allowEmpty) {
        sliced.push(EMPTY_DATE_SUGGEST_OPTION)
    }

    return sliced
}

import { useMemo } from 'react'

import { mutateFieldGroup } from '@laserfocus/client/data-access-shared'
import {
    FieldGroup,
    isTruthy,
    OrgFieldGroupInput,
    UserFieldGroupInput,
} from '@laserfocus/shared/models'

type FieldGroupActions = {
    updateTitle(title: string): Promise<unknown>
    createFieldGroup(
        input: Omit<UserFieldGroupInput | OrgFieldGroupInput, 'id' | 'rootObject'>
    ): Promise<string>
    deleteFieldGroup(): Promise<unknown>
    resetFieldGroup?(): Promise<unknown>
    updateFieldGroupsOrder(reorders: Record<string, string>): Promise<unknown>
    removeField(fieldName: string): Promise<unknown>
    addField(fieldName: string): Promise<unknown>
    setFieldPosition(fieldName: string, orderKey: string): Promise<unknown>
    deactivateFieldGroup?(): Promise<unknown>
    activateFieldGroup?(): Promise<unknown>
}

export type ActionOrgContext = {
    selectedRole?: string
}

export function useFieldgroupActions(
    rootObject: 'Account' | 'Lead',
    orgOrUser: 'org' | 'user',
    fieldGroup?: FieldGroup,
    orgContext?: ActionOrgContext
) {
    return useMemo(() => {
        if (orgOrUser === 'user' || fieldGroup?.__typename === 'UserFieldGroup') {
            return createUserFieldgroupActions(rootObject, fieldGroup)
        }
        return createOrgFieldgroupActions(rootObject, fieldGroup, orgContext)
    }, [orgOrUser, fieldGroup, rootObject, orgContext])
}

function createUserFieldgroupActions(
    rootObject: 'Account' | 'Lead',
    fieldGroup?: FieldGroup
): FieldGroupActions {
    return {
        updateTitle(title: string) {
            if (fieldGroup) {
                return mutateFieldGroup.updateOrgFieldGroup({ id: fieldGroup.id, title })
            }
            return Promise.reject('No FieldGroup selected')
        },
        createFieldGroup(input) {
            return mutateFieldGroup.createFieldGroup({
                ...input,
                rootObject,
            })
        },
        deleteFieldGroup() {
            if (!fieldGroup) {
                return Promise.reject('No FieldGroup selected')
            }
            const resetForDelete =
                fieldGroup.isExtended && !fieldGroup.orgDeactivated && fieldGroup.resetToDelete
            if (resetForDelete) {
                return mutateFieldGroup.resetFieldGroup(fieldGroup.id)
            }
            return mutateFieldGroup.deleteFieldGroup(fieldGroup.id)
        },
        resetFieldGroup() {
            if (!fieldGroup) {
                return Promise.reject('No FieldGroup selected')
            }
            return mutateFieldGroup.resetFieldGroup(fieldGroup.id)
        },
        async updateFieldGroupsOrder(reorders: Record<string, string>) {
            if (Object.keys(reorders).length) {
                await mutateFieldGroup.updateFieldGroupsOrder(reorders)
            }
        },
        addField(fieldName: string) {
            return fieldGroup
                ? mutateFieldGroup.addField(fieldGroup.id, fieldName)
                : Promise.reject('No FieldGroup selected')
        },
        removeField(fieldName: string) {
            return fieldGroup
                ? mutateFieldGroup.removeField(fieldGroup.id, fieldName)
                : Promise.reject('No FieldGroup selected')
        },
        setFieldPosition(fieldName: string, orderKey: string) {
            return fieldGroup
                ? mutateFieldGroup.setFieldPosition(fieldGroup.id, fieldName, orderKey)
                : Promise.reject('No FieldGroup selected')
        },
    }
}

function createOrgFieldgroupActions(
    rootObject: 'Account' | 'Lead',
    fieldGroup: FieldGroup | undefined,
    orgContext?: ActionOrgContext
): FieldGroupActions {
    return {
        updateTitle(title: string) {
            if (fieldGroup) {
                return mutateFieldGroup.updateOrgFieldGroup({ id: fieldGroup.id, title })
            }
            return Promise.reject('No FieldGroup selected')
        },
        createFieldGroup(input) {
            return mutateFieldGroup.createOrgFieldGroup({
                ...input,
                rootObject,
                roles: [orgContext?.selectedRole].filter(isTruthy),
            })
        },
        deactivateFieldGroup() {
            return fieldGroup
                ? mutateFieldGroup.deactivateOrgFieldGroup(fieldGroup.id)
                : Promise.reject('No FieldGroup selected')
        },
        deleteFieldGroup() {
            return fieldGroup
                ? mutateFieldGroup.deleteOrgFieldGroup(fieldGroup.id)
                : Promise.reject('No FieldGroup selected')
        },
        async updateFieldGroupsOrder(reorders: Record<string, string>) {
            if (Object.keys(reorders).length) {
                await mutateFieldGroup.updateOrgFieldGroupsOrder(reorders)
            }
        },
        addField(fieldName: string) {
            return fieldGroup
                ? mutateFieldGroup.addFieldToOrgFieldGroup(fieldGroup.id, fieldName)
                : Promise.reject('No FieldGroup selected')
        },
        removeField(fieldName: string) {
            return fieldGroup
                ? mutateFieldGroup.removeOrgFieldGroupField(fieldGroup.id, fieldName)
                : Promise.reject('No FieldGroup selected')
        },
        setFieldPosition(fieldName: string, orderKey: string) {
            return fieldGroup
                ? mutateFieldGroup.setOrgFieldGroupFieldPosition(fieldGroup.id, fieldName, orderKey)
                : Promise.reject('No FieldGroup selected')
        },
        activateFieldGroup() {
            return fieldGroup
                ? mutateFieldGroup.activateOrgFieldGroup(fieldGroup.id)
                : Promise.reject('No FieldGroup selected')
        },
    }
}

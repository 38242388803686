import { SvgIcon, SvgIconProps } from '../svg-icon'

export default function ClipboardListIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path d="M9.00121 2.00147H8.99121C8.43121 2.00147 7.99121 2.44147 7.99121 3.00147C7.99121 3.55147 8.43121 4.00147 8.99121 3.99147H10.9912L10.9812 3.99047C11.5312 3.99047 11.9812 3.54047 11.9812 2.99047C11.9812 2.43047 11.5312 1.99047 10.9812 1.98047H8.97121L9.00121 2.00147Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.99996 5.00155C3.98996 3.89155 4.88996 2.99155 5.98996 2.99155C5.98996 4.64155 7.32996 5.98155 8.98996 5.98155H10.99L10.98 5.98055C12.63 5.98055 13.98 4.63055 13.98 2.98055H13.97C15.07 2.97055 15.97 3.87055 15.97 4.97055V15.9706C15.97 17.0706 15.07 17.9706 13.97 17.9706H5.96996L5.95996 17.9696C4.84996 17.9596 3.95996 17.0696 3.95996 15.9596V4.95955L3.99996 5.00155ZM6.99996 9.00155H6.98996C6.42996 9.00155 5.98996 9.44155 5.98996 10.0016C5.98996 10.5516 6.42996 11.0016 6.98996 10.9916H6.99996L6.98996 10.9906C7.53996 10.9906 7.98996 10.5406 7.98996 9.99055C7.98996 9.43055 7.53996 8.99055 6.98996 8.98055H6.96996L6.99996 9.00155ZM9.99996 9.00155H9.98996C9.42996 9.00155 8.98996 9.44155 8.98996 10.0016C8.98996 10.5516 9.42996 11.0016 9.98996 10.9916H12.99L12.98 10.9906C13.53 10.9906 13.98 10.5406 13.98 9.99055C13.98 9.43055 13.53 8.99055 12.98 8.98055H9.97996L9.99996 9.00155ZM6.99996 13.0016H6.98996C6.42996 13.0016 5.98996 13.4416 5.98996 14.0016C5.98996 14.5516 6.42996 15.0016 6.98996 14.9916H6.99996L6.98996 14.9906C7.53996 14.9906 7.98996 14.5406 7.98996 13.9906C7.98996 13.4306 7.53996 12.9906 6.98996 12.9806H6.96996L6.99996 13.0016ZM9.99996 13.0016H9.98996C9.42996 13.0016 8.98996 13.4416 8.98996 14.0016C8.98996 14.5516 9.42996 15.0016 9.98996 14.9916H12.99L12.98 14.9906C13.53 14.9906 13.98 14.5406 13.98 13.9906C13.98 13.4306 13.53 12.9906 12.98 12.9806H9.97996L9.99996 13.0016Z"
            />
        </SvgIcon>
    )
}

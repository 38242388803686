import { SvgIcon, SvgIconProps } from '../svg-icon'

/** 16px version */
export function ListUnorderedIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 16 16" width={16} height={16} {...props}>
            <circle cx={3.5} cy={4.5} r={1.5} />
            <circle cx={3.5} cy={11.5} r={1.5} />
            <path d="M8 11H15V12H8zM8 4H15V5H8z" />
        </SvgIcon>
    )
}

import { useStackNavigation, shouldSkipStackNavigation } from '@laserfocus/client/store-shared'
import { ButtonGroup, Button } from '@laserfocus/ui/beam'
import { ArrowLeftOutlinedIcon, ArrowRightOutlinedIcon } from '@laserfocus/ui/icons'
import { useHotKey } from '@laserfocus/ui/util-keyboard'

export default function PersonNavigation() {
    const nav = useStackNavigation()
    const shouldSkip = shouldSkipStackNavigation(nav)

    const navigateNext = (nav as any).navigateNext || noop
    const navigatePrevious = (nav as any).navigatePrevious || noop

    useHotKey('j', navigateNext, { disabled: shouldSkip })
    useHotKey('k', navigatePrevious, { disabled: shouldSkip })

    if (shouldSkip) {
        return null
    }

    return (
        <ButtonGroup data-pendo="person-details-navigation">
            <Button
                onClick={navigatePrevious}
                iconComponent={ArrowLeftOutlinedIcon}
                title="Previous (K)"
            />
            <Button
                onClick={navigateNext}
                iconComponent={ArrowRightOutlinedIcon}
                title="Next (J)"
            />
        </ButtonGroup>
    )
}

function noop() {}

import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useController, Path, useWatch } from 'react-hook-form'

import { Select, DropdownBaseInput } from '@laserfocus/ui/beam'

import { FormControlProps, SalesObject } from '../get-form-control'
import { useFormControlContext } from '../FormControlContext'

import { getControllerRules } from './formcontrol-util'

export const FormControlPicklist = observer(function FormControlPicklist<
    T extends SalesObject = SalesObject
>({ fieldMetadata, id, variant, control, submit, name }: FormControlProps<T>) {
    const { recordTypeId } = useFormControlContext()
    const formRecordType = useWatch({
        name: 'RecordTypeId' as Path<T>,
        control,
    }) as string | undefined
    const [isSelectOpen, setIsSelectOpen] = useState(false)

    const options = fieldMetadata
        .getPicklistOptions(formRecordType || recordTypeId)
        .map((option) => ({ ...option, label: option.label ?? '' }))

    const labelsByValue = fieldMetadata.labelsByValue

    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })

    const currentValueLabel = value ? labelsByValue[value as string] || (value as string) : ''

    return (
        <Select
            isOpen={isSelectOpen}
            options={options}
            initialOptionValue={(value as string) || undefined}
            onCancel={() => setIsSelectOpen(false)}
            onSubmit={(option) => {
                setIsSelectOpen(false)
                onChange(option.value)
                submit?.()
            }}
            searchKeys={['label']}
            size="large"
        >
            <DropdownBaseInput
                id={id}
                variant={variant}
                forceFocused={isSelectOpen}
                onClick={() => setIsSelectOpen(true)}
                disabled={!fieldMetadata.updateable}
                value={currentValueLabel ?? undefined}
                error={(error as any)?.message}
            />
        </Select>
    )
})

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function TableOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                d="M2 8a3 3 0 013-3h14a3 3 0 013 3v1.9a1 1 0 010 .2V14a1 1 0 010 .2V16a3 3 0 01-3 3H5h-.2A3 3 0 012 16V8zm11 9v-2h7v1a1 1 0 01-1 1h-6zm7-4h-7v-2h7v2zm0-4H4V8c0-.6.4-1 1-1h14c.5 0 1 .4 1 1v1zM4 11h7v2H4v-2zm0 4h7v2H5.1 5a1 1 0 01-1-1v-1z"
            />
        </SvgIcon>
    )
}

import { useSubscribe } from 'replicache-react'
import { useEffect, useState } from 'react'

import { PersonContextProvider } from '@laserfocus/client/feature-person-details'
import { getClient } from '@laserfocus/client/replicache'
import {
    AccountModel,
    ContactModel,
    isLead,
    LeadModel,
    OpportunityModel,
    Prefix,
} from '@laserfocus/shared/models'

interface OverlayContainerProps {
    rootId?: string
    contactId?: string
    opportunityId?: string
    children: React.ReactNode
}

export function OverlayContainer(props: OverlayContainerProps) {
    const { rootId, children } = props
    if (!rootId) {
        return <>{children}</>
    }
    return <OverlayContainerInner {...(props as OverlayContainerInnerProps)} />
}

interface OverlayContainerInnerProps extends Omit<OverlayContainerProps, 'rootId'> {
    rootId: string
}

export function OverlayContainerInner({
    rootId,
    contactId,
    opportunityId,
    children,
}: OverlayContainerInnerProps) {
    const { isLoading, lead, account, opportunities, contacts } = useSalesObjects(rootId)

    const [currentContactId, setCurrentContactId] = useState(() => {
        if (contactId) {
            return contacts?.find((c) => c.Id === contactId)?.Id || contacts?.[0]?.Id
        }
        return contacts?.[0]?.Id
    })
    useEffect(() => {
        setCurrentContactId(contactId)
    }, [contactId])
    useEffect(() => {
        const currentContact = contacts?.find((c) => c.Id === currentContactId)
        if (currentContact) {
            return
        }
        if (contactId) {
            return setCurrentContactId(
                contacts?.find((c) => c.Id === contactId)?.Id || contacts?.[0]?.Id
            )
        }
        return setCurrentContactId(contacts?.[0]?.Id)
    }, [contactId, contacts, currentContactId])

    const [currentOpportunityId, setCurrentOpportunityId] = useState(() => {
        if (opportunityId) {
            return opportunities?.find((c) => c.Id === opportunityId)?.Id || opportunities?.[0]?.Id
        }
        return opportunities?.[0]?.Id
    })
    useEffect(() => {
        setCurrentOpportunityId(opportunityId)
    }, [opportunityId])
    useEffect(() => {
        const currentOpp = opportunities?.find((c) => c.Id === currentOpportunityId)
        if (currentOpp) {
            return
        }
        if (opportunityId) {
            return setCurrentOpportunityId(
                opportunities?.find((c) => c.Id === opportunityId)?.Id || opportunities?.[0]?.Id
            )
        }
        return setCurrentOpportunityId(opportunities?.[0]?.Id)
    }, [opportunityId, opportunities, currentOpportunityId])

    if (isLoading) {
        return null
    }

    return (
        <PersonContextProvider
            rootId={rootId}
            lead={lead}
            account={account}
            opportunities={opportunities}
            contacts={contacts}
            currentContactId={currentContactId}
            setCurrentContactId={setCurrentContactId}
            currentOpportunityId={currentOpportunityId}
            setCurrentOpportunityId={setCurrentOpportunityId}
        >
            {children}
        </PersonContextProvider>
    )
}

type SalesObjects = {
    isLoading: boolean
    lead?: LeadModel
    account?: AccountModel
    opportunities?: OpportunityModel[]
    contacts?: ContactModel[]
}

function useSalesObjects(rootId: string) {
    const rep = getClient()
    return useSubscribe<SalesObjects>(
        rep,
        async (tx) => {
            if (isLead(rootId)) {
                const lead = (await tx.get([Prefix.Lead, rootId].join('/'))) as
                    | LeadModel
                    | undefined

                return {
                    isLoading: false,
                    lead,
                }
            }

            const [account, opportunities, contacts] = (await Promise.all([
                tx.get([Prefix.Account, rootId].join('/')),
                tx
                    .scan({ indexName: 'opportunitiesByAccountId', prefix: rootId })
                    .values()
                    .toArray(),
                tx.scan({ indexName: 'contactsByAccountId', prefix: rootId }).values().toArray(),
            ])) as [AccountModel | undefined, OpportunityModel[], ContactModel[]]

            return {
                isLoading: false,
                account,
                opportunities,
                contacts,
            }
        },
        { isLoading: true },
        [rootId]
    )
}

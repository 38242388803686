import { BooleanInput } from './BooleanInput'
import { DateInput } from './DateInput'
import { NumberInput } from './NumberInput'
import { PicklistInput } from './PicklistInput'
import { StringInput } from './StringInput'
import { TimeInput } from './TimeInput'

export const FilterInput = {
    Boolean: BooleanInput,
    Date: DateInput,
    Number: NumberInput,
    Picklist: PicklistInput,
    String: StringInput,
    Time: TimeInput,
}

import { useParams } from 'react-router'

import { PersonPageContainer } from '@laserfocus/client/feature-person-details'
import { StackNavigationContainer } from '@laserfocus/client/feature-table'
import { wrapAuthenticated } from '@laserfocus/client/feature-auth'

export function StackPersonPage() {
    const { personId, stackSlug } = useParams<{ personId: string; stackSlug: string }>()
    return (
        <StackNavigationContainer personId={personId} stackSlug={stackSlug}>
            <PersonPageContainer personId={personId} />
        </StackNavigationContainer>
    )
}
export default wrapAuthenticated(StackPersonPage)

import type { WriteTransaction, JSONValue, ReadTransaction } from 'replicache'
import { useSubscribe } from 'replicache-react'
import { ulid } from 'ulid'
import produce from 'immer'
import { max } from 'lodash'

import {
    FieldGroupMutatorSchema,
    applyValidators,
    Prefix,
    UserId,
    UserFieldGroup,
    UserFieldGroupInput,
    OrgFieldGroupInput,
    OrgFieldGroup,
    FieldGroup,
} from '@laserfocus/shared/models'
import { getClient } from '@laserfocus/client/replicache'
import { z } from '@laserfocus/shared/decoder'
import { fractional } from '@laserfocus/shared/util-common'
import { toast } from '@laserfocus/ui/beam'

import { FREE_PLAN_LIMITS, shouldAllowProAction } from '../subscription/subscription-repository'

import { parseFieldGroups } from './user-org-fieldgroup'

export function useFieldGroups() {
    const rep = getClient()
    return useSubscribe<any>(
        rep,
        async (tx) => {
            const fieldGroups = (await tx
                .scan({ prefix: `${Prefix.FieldGroup}/` })
                .values()
                // Right now we only have user fieldgroups
                .toArray()) as UserFieldGroup[]
            const rawFieldGroups = fieldGroups || []

            return {
                fieldGroups: parseFieldGroups(rawFieldGroups),
                isLoading: false,
            }
        },
        {
            fieldGroups: [],
            isLoading: true,
        }
    ) as {
        fieldGroups: FieldGroup[]
        isLoading: boolean
    }
}

async function checkCanCreateFieldGroup(rootObject: 'Lead' | 'Account') {
    const rep = getClient<typeof mutators>()

    const allowed = await rep.query(makeQueryCanCreateFieldGroup(rootObject))

    if (!allowed) {
        toast.warn({
            title: `You can only create ${FREE_PLAN_LIMITS.FIELDGROUP_PER_OBJECT} Note Templates in your current plan`,
        })
    }
    return allowed
}
export function makeQueryCanCreateFieldGroup(rootObject: 'Lead' | 'Account') {
    return async (tx: ReadTransaction) => {
        const [fieldGroups, hasPro] = await Promise.all([
            tx.scan({ prefix: Prefix.FieldGroupUser }).values().toArray() as Promise<
                UserFieldGroup[]
            >,
            shouldAllowProAction(tx),
        ])
        if (hasPro) {
            return true
        }
        const notDeleted = fieldGroups
            .filter((a) => !a.deleted)
            .filter((a) => a.rootObject === rootObject)
        if (notDeleted.length < FREE_PLAN_LIMITS.FIELDGROUP_PER_OBJECT) {
            return true
        }
        return false
    }
}

export async function createFieldGroup(fieldGroup: Omit<UserFieldGroupInput, 'id'>) {
    const client = getClient<typeof mutators>()
    const values: UserFieldGroupInput = {
        id: ulid(),
        ...fieldGroup,
    }
    const canCreate = await checkCanCreateFieldGroup(fieldGroup.rootObject)
    if (canCreate) {
        await client.mutate.createFieldGroup({
            values,
            optimistic: {
                modifiedDate: new Date().toISOString(),
                createdDate: new Date().toISOString(),
            },
        })
        return values.id
    }
}

export async function createOrgFieldGroup(fieldGroup: Omit<OrgFieldGroupInput, 'id'>) {
    const client = getClient<typeof mutators>()
    const values: UserFieldGroupInput = {
        id: ulid(),
        ...fieldGroup,
    }
    await client.mutate.createOrgFieldGroup({
        values,
        optimistic: {
            modifiedDate: new Date().toISOString(),
            createdDate: new Date().toISOString(),
        },
    })
    return values.id
}

export async function updateFieldGroup(
    fieldGroup: Pick<UserFieldGroupInput, 'id' | 'orderKey' | 'title'>
) {
    const client = getClient<typeof mutators>()
    const { id, ...values } = fieldGroup
    return client.mutate.updateFieldGroup({
        id,
        values,
        optimistic: { modifiedDate: new Date().toISOString() },
    })
}

export async function updateOrgFieldGroup(
    fieldGroup: Partial<Pick<OrgFieldGroupInput, 'orderKey' | 'title'>> & { id: string }
) {
    const client = getClient<typeof mutators>()
    const { id, ...values } = fieldGroup
    return client.mutate.updateOrgFieldGroup({
        id,
        values,
        optimistic: { modifiedDate: new Date().toISOString() },
    })
}

export async function deleteFieldGroup(id: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.deleteFieldGroup({ id })
}

export async function resetFieldGroup(id: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.resetFieldGroup({ id })
}

export async function deleteOrgFieldGroup(id: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.deleteOrgFieldGroup({ id })
}

export async function deactivateOrgFieldGroup(id: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.deactivateOrgFieldGroup({ id })
}

export async function activateOrgFieldGroup(id: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.activateOrgFieldGroup({ id })
}

export async function updateFieldGroupsOrder(orderUpdates: Record<string, string>) {
    const client = getClient<typeof mutators>()
    return client.mutate.updateFieldGroupsOrder({
        values: orderUpdates,
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function updateOrgFieldGroupsOrder(orderUpdates: Record<string, string>) {
    const client = getClient<typeof mutators>()
    return client.mutate.updateOrgFieldGroupsOrder({
        values: orderUpdates,
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function setFieldPosition(id: string, fieldName: string, fieldOrderKey: string) {
    const client = getClient<typeof mutators>()
    await client.mutate.setFieldGroupFieldPosition({
        id,
        values: { fieldName, fieldOrderKey },
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function setOrgFieldGroupFieldPosition(
    id: string,
    fieldName: string,
    fieldOrderKey: string
) {
    const client = getClient<typeof mutators>()
    await client.mutate.setOrgFieldGroupFieldPosition({
        id,
        values: { fieldName, fieldOrderKey },
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function addField(id: string, fieldName: string) {
    const client = getClient<typeof mutators>()
    const fieldOrderKey = await client.query(async (tx: ReadTransaction) => {
        const [orgFieldGroup, userFieldGroup] = await Promise.all([
            tx.get(getOrgKey(id)) as Promise<OrgFieldGroup | undefined>,
            tx.get(getUserKey(id)) as Promise<UserFieldGroup | undefined>,
        ])

        const visibleFieldOrderMap = {
            ...orgFieldGroup?.visibleFieldOrderMap,
            ...userFieldGroup?.visibleFieldOrderMap,
        }
        const afterThisField = max(Object.values(visibleFieldOrderMap))
        const fieldOrderKey = fractional.getOrderKeyForElementAfter(
            visibleFieldOrderMap,
            afterThisField
        )
        return fieldOrderKey
    })

    await client.mutate.setFieldGroupFieldPosition({
        id,
        values: { fieldName, fieldOrderKey },
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function addFieldToOrgFieldGroup(id: string, fieldName: string) {
    const client = getClient<typeof mutators>()
    const fieldOrderKey = await client.query(async (tx: ReadTransaction) => {
        const fieldGroup = (await tx.get(getOrgKey(id))) as OrgFieldGroup
        const afterThisField = max(Object.values(fieldGroup.visibleFieldOrderMap))
        const fieldOrderKey = fractional.getOrderKeyForElementAfter(
            fieldGroup.visibleFieldOrderMap,
            afterThisField
        )
        return fieldOrderKey
    })

    await client.mutate.setOrgFieldGroupFieldPosition({
        id,
        values: { fieldName, fieldOrderKey },
        optimistic: {
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function removeField(id: string, fieldName: string) {
    const client = getClient<typeof mutators>()
    await client.mutate.removeFieldGroupField({
        id,
        values: { fieldName },
        optimistic: { modifiedDate: new Date().toISOString() },
    })
}

export async function removeOrgFieldGroupField(id: string, fieldName: string) {
    const client = getClient<typeof mutators>()
    await client.mutate.removeOrgFieldGroupField({
        id,
        values: { fieldName },
        optimistic: { modifiedDate: new Date().toISOString() },
    })
}

interface ShareFieldGroupArgs {
    fieldGroup: UserFieldGroupInput
    userIds: UserId[]
}
export async function shareFieldGroup(input: ShareFieldGroupArgs) {
    const client = getClient<typeof mutators>()
    const args: z.infer<typeof FieldGroupMutatorSchema.shareFieldGroup> = {
        userIds: input.userIds as UserId[],
        fieldGroup: input.fieldGroup,
    }
    await client.mutate.shareFieldGroup(args)
}

export const mutators = applyValidators(FieldGroupMutatorSchema, {
    async createFieldGroup(tx, args) {
        const value = {
            ...args.values,
            ...args.optimistic,
        }
        await putUserFieldGroup(tx, value)
    },
    async shareFieldGroup(tx, args) {
        // NOOP
    },
    async updateFieldGroup(tx, args) {
        const fg = await getUserFieldGroup(tx, args.id)
        const updated = {
            ...fg,
            ...args.values,
            ...args.optimistic,
        }
        await putUserFieldGroup(tx, updated)
    },
    async setFieldGroupFieldPosition(tx, args) {
        const fieldGroup = await getUserFieldGroup(tx, args.id)
        const updated = produce(
            fieldGroup || { visibleFieldOrderMap: {} },
            (draft: UserFieldGroup) => {
                draft.visibleFieldOrderMap[args.values.fieldName] = args.values.fieldOrderKey
                draft.modifiedDate = args.optimistic.modifiedDate || draft.modifiedDate
            }
        )
        await putUserFieldGroup(tx, updated)
    },
    async setOrgFieldGroupFieldPosition(tx, args) {
        const fieldGroup = await getOrgFieldGroup(tx, args.id)
        const updated = produce(fieldGroup, (draft: UserFieldGroup) => {
            draft.visibleFieldOrderMap[args.values.fieldName] = args.values.fieldOrderKey
            draft.modifiedDate = args.optimistic.modifiedDate || draft.modifiedDate
        })
        await putOrgFieldGroup(tx, updated)
    },
    async removeFieldGroupField(tx, args) {
        const fieldGroup = await getUserFieldGroup(tx, args.id)
        const updated = produce(fieldGroup, (draft: UserFieldGroup) => {
            delete draft.visibleFieldOrderMap[args.values.fieldName]
            draft.modifiedDate = args.optimistic.modifiedDate || draft.modifiedDate
        })
        await putUserFieldGroup(tx, updated)
    },
    async removeOrgFieldGroupField(tx, args) {
        const fieldGroup = await getOrgFieldGroup(tx, args.id)
        const updated = produce(fieldGroup, (draft: UserFieldGroup) => {
            delete draft.visibleFieldOrderMap[args.values.fieldName]
            draft.modifiedDate = args.optimistic.modifiedDate || draft.modifiedDate
        })
        await putOrgFieldGroup(tx, updated)
    },
    async deleteFieldGroup(tx, args) {
        await tx.del(getUserKey(args.id))
    },
    async deleteOrgFieldGroup(tx, args) {
        await tx.del(getOrgKey(args.id))
    },
    async createOrgFieldGroup(tx, args) {
        const value = {
            ...args.values,
            ...args.optimistic,
        }
        await putOrgFieldGroup(tx, value)
    },
    async deactivateOrgFieldGroup(tx, args) {
        const fg = await getOrgFieldGroup(tx, args.id)
        const updated = produce(fg, (draft) => {
            draft.deactivated = true
        })
        await putOrgFieldGroup(tx, updated)
    },
    async activateOrgFieldGroup(tx, args) {
        const fg = await getOrgFieldGroup(tx, args.id)
        const updated = produce(fg, (draft) => {
            draft.deactivated = false
        })
        await putOrgFieldGroup(tx, updated)
    },
    async updateOrgFieldGroup(tx, args) {
        const fg = await getOrgFieldGroup(tx, args.id)
        const updated = {
            ...fg,
            ...args.values,
            ...args.optimistic,
        }
        await putOrgFieldGroup(tx, updated)
    },
    async updateFieldGroupsOrder(tx, args) {
        const entries = Object.entries(args.values)
        const tasks = entries.map(async ([id, orderKey]) => {
            const fg = await getUserFieldGroup(tx, id)
            const updated = produce(fg || {}, (draft) => {
                draft.orderKey = orderKey
                draft.modifiedDate = args.optimistic.modifiedDate || draft.modifiedDate
            })
            await putUserFieldGroup(tx, updated)
        })
        await Promise.all(tasks)
    },
    async updateOrgFieldGroupsOrder(tx, args) {
        const entries = Object.entries(args.values)
        const tasks = entries.map(async ([id, orderKey]) => {
            const fg = await getOrgFieldGroup(tx, id)
            if (fg) {
                const updated = produce(fg, (draft) => {
                    draft.orderKey = orderKey
                    draft.modifiedDate = args.optimistic.modifiedDate || draft.modifiedDate
                })
                await putOrgFieldGroup(tx, updated)
            }
        })
        await Promise.all(tasks)
    },
    async resetFieldGroup(tx, args) {
        return tx.del(getUserKey(args.id))
    },
})

async function getUserFieldGroup(tx: ReadTransaction, id: string): Promise<UserFieldGroup> {
    const fieldGroup = await tx.get(getUserKey(id))

    return fieldGroup as UserFieldGroup
}
async function getOrgFieldGroup(tx: ReadTransaction, id: string): Promise<OrgFieldGroup> {
    const fieldGroup = await tx.get(getOrgKey(id))

    return fieldGroup as OrgFieldGroup
}

async function putUserFieldGroup(tx: WriteTransaction, input: UserFieldGroupInput) {
    await tx.put(getUserKey(input.id), input as unknown as JSONValue)
}
async function putOrgFieldGroup(tx: WriteTransaction, input: OrgFieldGroupInput) {
    await tx.put(getOrgKey(input.id), input as unknown as JSONValue)
}
function getUserKey(id: string) {
    return [Prefix.FieldGroupUser, id].join('/')
}
function getOrgKey(id: string) {
    return [Prefix.FieldGroupOrg, id].join('/')
}

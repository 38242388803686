import { Button } from '@laserfocus/ui/beam'

import { Column } from '../../Table/table-context'

interface FilterInputActionsProps {
    column: Column
    saveFilter?(): void
    onRemoveFilterCondition?(): void
}

export function FilterInputActions({
    column,
    saveFilter,
    onRemoveFilterCondition,
}: FilterInputActionsProps) {
    return (
        <>
            {column.filterCondition && (
                <Button
                    variant="quaternary"
                    size="small"
                    onClick={() => {
                        column.removeFilterCondition()
                        onRemoveFilterCondition?.()
                    }}
                    className="bg-white/5"
                >
                    Clear filter
                </Button>
            )}
            {saveFilter && (
                <Button variant="primary" size="small" onClick={saveFilter}>
                    Set filter
                </Button>
            )}
        </>
    )
}

import { useEffect, useRef, useState } from 'react'
import { useObserver } from 'mobx-react-lite'

import { FieldMetadata } from '@laserfocus/client/model'
import { Button, Modal, SpinnerInline } from '@laserfocus/ui/beam'
import { useObjectPool } from '@laserfocus/client/root-store-context'
import { FailedMutation, FieldError } from '@laserfocus/shared/models'
import { mutateSObject } from '@laserfocus/client/data-access-shared'
import { ConfirmPrompt } from '@laserfocus/ui/beam'

import { FormControlContextProvider } from '../FormControlContext'

import { useErrorForm, SalesObject, SObjectMutation } from './useErrorForm'
import { FieldsSection } from './FieldsSection'
import { ResolvedSection } from './ResolvedSection'

interface MutationErrorModalProps {
    mutation?: FailedMutation<SObjectMutation>
    salesObject: SalesObject
    close(): void
    nextMutations?: Array<FailedMutation<SObjectMutation>>
    next?: () => void
}

export function MutationErrorModal({
    salesObject,
    mutation: lastFailedMutation,
    close,
    nextMutations,
    next,
}: MutationErrorModalProps) {
    const [showConfirm, setShowConfirm] = useState(false)
    const [didSubmitInitial, setHasSubmittedInitial] = useState(false)
    const lastPayload = lastFailedMutation?.mutation.args.values || {}

    const hasMultipleIssues = Boolean(nextMutations?.length)
    const [hadMultipleErrors, setHadMultipleIssues] = useState(hasMultipleIssues)

    useEffect(() => {
        if (hasMultipleIssues) {
            setHadMultipleIssues(true)
        }
    }, [hasMultipleIssues])

    const { control, isDirty, submit, isSubmitting } = useErrorForm(salesObject, lastFailedMutation)

    const { formErrors, fieldErrors } = lastFailedMutation || { formErrors: [], fieldErrors: [] }

    const { visibleFields, updatedFields, suggestedErrorFields, errorFields, amountOfFieldErrors } =
        useFields(salesObject, formErrors, fieldErrors, lastPayload)

    const nextMutationCount = nextMutations?.length || 0

    function submitInitial() {
        setHasSubmittedInitial(true)
        submit()
    }

    const hasErrors = formErrors.length !== 0 || amountOfFieldErrors !== 0
    const [areAllFieldsExpanded, setAreAllFieldsExpanded] = useState(errorFields.length === 0)
    const waitingForConfirmedChanges = Boolean(isSubmitting || salesObject.hasUncommittedChanges)
    const showIssues =
        (!didSubmitInitial || hasErrors || waitingForConfirmedChanges) &&
        !lastFailedMutation?.deleted

    async function closeAndDiscardUnsaved() {
        const tasks = []
        if (lastFailedMutation) {
            tasks.push(mutateSObject.discardFailedMutation(lastFailedMutation.mutationId))
        }
        if (nextMutations && nextMutations.length) {
            nextMutations.forEach((mut) =>
                tasks.push(mutateSObject.discardFailedMutation(mut.mutationId))
            )
        }
        await Promise.all(tasks)
        close()
    }

    function tryClosing() {
        if (nextMutationCount) {
            setShowConfirm(true)
        } else if (!showIssues) {
            closeAndDiscardUnsaved()
        } else if (!isDirty) {
            closeAndDiscardUnsaved()
        } else {
            setShowConfirm(true)
        }
    }

    return (
        <Modal show onClose={tryClosing}>
            <ConfirmPrompt
                show={showConfirm}
                title="Unsaved Changes"
                description={
                    nextMutationCount
                        ? `Are you sure you want to discard the changes you made to ${
                              nextMutationCount + 1
                          } Records?`
                        : 'Are you sure you want to discard the changes you made?'
                }
                submitButtonTitle="Discard"
                onCancel={() => setShowConfirm(false)}
                onSubmit={closeAndDiscardUnsaved}
            />
            <Modal.Overlay />
            <Modal.Container variableHeight>
                <FormControlContextProvider
                    objectType={salesObject.__typename}
                    currencyIsoCode={salesObject.CurrencyIsoCode}
                    recordTypeId={salesObject.RecordTypeId}
                >
                    <Modal.Header close={tryClosing}>{salesObject.Name!}</Modal.Header>
                    <div className="relative max-h-[50vh] overflow-y-auto">
                        {showIssues ? (
                            <FieldsSection
                                name={'Issues to be solved'}
                                fieldsLists={[
                                    {
                                        heading: (
                                            <ul className="p-0 text-red-500 text-sm leading-normal grid gap-1">
                                                {formErrors.map((formError) => (
                                                    <li key={formError}>{formError}</li>
                                                ))}
                                                {amountOfFieldErrors !== 0 && (
                                                    <li>{`${amountOfFieldErrors} ${
                                                        amountOfFieldErrors === 1
                                                            ? 'field contains'
                                                            : 'fields contain'
                                                    } an issue`}</li>
                                                )}
                                            </ul>
                                        ),
                                        fields: errorFields,
                                        isMandatory: true,
                                    },
                                    {
                                        heading: (
                                            <p className="text-sm">
                                                You might want to also look into these fields:
                                            </p>
                                        ),
                                        fields: suggestedErrorFields,
                                    },
                                    {
                                        heading: (
                                            <p className="text-sm">
                                                These are the fields you have changed:
                                            </p>
                                        ),
                                        fields: updatedFields,
                                    },
                                ]}
                                sectionIndex={0}
                                amountOfSections={3}
                                salesObject={salesObject}
                                isExpanded
                                control={control}
                            />
                        ) : (
                            <ResolvedSection
                                sectionIndex={0}
                                amountOfSections={2}
                                title="Issues to be solved ✓"
                                closeAndDiscardUnsaved={closeAndDiscardUnsaved}
                                next={next}
                                hasNext={Boolean(nextMutationCount)}
                            />
                        )}
                        <FieldsSection
                            name={salesObject.__typename}
                            fieldsLists={[{ fields: visibleFields }]}
                            sectionIndex={1}
                            amountOfSections={2}
                            salesObject={salesObject}
                            isExpanded={areAllFieldsExpanded}
                            allowFieldSearch
                            toggleExpanded={() =>
                                setAreAllFieldsExpanded((isExpanded) => !isExpanded)
                            }
                            control={control}
                        />
                    </div>
                    <Modal.Footer border className="justify-between">
                        <div>
                            {nextMutationCount ? (
                                <span className="text-red-500 text-sm leading-normal">
                                    {nextMutationCount} more{' '}
                                    {nextMutationCount > 1 ? 'Records have' : 'Record has'} issues
                                </span>
                            ) : null}
                        </div>
                        <div className="grid gap-2 grid-flow-col items-center">
                            {hadMultipleErrors && showIssues ? (
                                <Button
                                    variant="secondary"
                                    onClick={next}
                                    disabled={waitingForConfirmedChanges}
                                >
                                    Skip
                                </Button>
                            ) : hasMultipleIssues ? (
                                <Button
                                    variant="secondary"
                                    onClick={next}
                                    disabled={waitingForConfirmedChanges}
                                >
                                    Next
                                </Button>
                            ) : !showIssues ? (
                                <Button variant="secondary" onClick={closeAndDiscardUnsaved}>
                                    Close
                                </Button>
                            ) : null}
                            <Button
                                variant="primary"
                                iconComponent={
                                    waitingForConfirmedChanges ? SpinnerInline : undefined
                                }
                                disabled={
                                    (!isDirty && didSubmitInitial) || waitingForConfirmedChanges
                                }
                                onClick={submitInitial}
                            >
                                Update
                            </Button>
                        </div>
                    </Modal.Footer>
                </FormControlContextProvider>
            </Modal.Container>
        </Modal>
    )
}

function useFields(
    salesObject: SalesObject,
    formErrors: string[],
    fieldErrors: FieldError[],
    payload: Record<string, any>
) {
    const objectPool = useObjectPool()
    const fieldsWhichHadErrors = useRef(new Set<string>())
    const fieldsWhichHadErrorSuggestions = useRef(new Set<string>())

    const changedFieldsPaths = Object.keys(payload)

    const objectFields = useObserver(() =>
        Array.from(objectPool.getAll<FieldMetadata>('FieldMetadata').values())
            .filter((a) => a.objectName === salesObject.__typename)
            .filter((f) => f.updateable)
    )

    const fieldErrorsByField = Object.fromEntries(fieldErrors.map((fe) => [fe.fieldName, fe]))

    let amountOfFieldErrors = 0

    const errorFields = objectFields.filter((field) => {
        const error = fieldErrorsByField[field.name]
        if (error) {
            fieldsWhichHadErrors.current.add(field.Id)
            amountOfFieldErrors += 1
        }
        return fieldsWhichHadErrors.current.has(field.Id)
    })

    const formErrorsText = formErrors.join(' ').trim().toLowerCase()

    const suggestedErrorFields = formErrorsText
        ? objectFields.filter((field) => {
              if (fieldsWhichHadErrors.current.has(field.Id)) {
                  return false
              }
              const hasErrorSuggestion = formErrorsText.includes(field.label.toLowerCase())

              if (hasErrorSuggestion) {
                  fieldsWhichHadErrorSuggestions.current.add(field.Id)
              }

              return fieldsWhichHadErrorSuggestions.current.has(field.Id)
          })
        : []

    const updatedFields = objectFields.filter(
        (field) =>
            changedFieldsPaths.includes(field.name) &&
            !fieldsWhichHadErrors.current.has(field.Id) &&
            !fieldsWhichHadErrorSuggestions.current.has(field.Id)
    )

    return {
        visibleFields: objectFields,
        errorFields,
        updatedFields,
        suggestedErrorFields,
        amountOfFieldErrors,
    }
}

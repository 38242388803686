import { Control } from 'react-hook-form'

import { Stack } from '@laserfocus/shared/models'
import { FieldLabel, FormInput } from '@laserfocus/client/shared-form'

export type FormState = Pick<Stack, 'title' | 'description' | 'helpUrl'>

export function EditStackForm({ control }: { control: Control<FormState> }) {
    return (
        <div className="group grid grid-cols-[minmax(0,2fr),minmax(0,3fr)] gap-2 items-start outline-none focus-visible:ring pr-2 rounded-md pt-2">
            <FieldLabel required htmlFor="settings-stack-title">
                Title
            </FieldLabel>
            <FormInput
                control={control}
                name="title"
                id="settings-stack-title"
                rules={{ required: 'required' }}
            />
            <FieldLabel htmlFor="settings-stack-description">Description</FieldLabel>
            <FormInput control={control} name="description" id="settings-stack-description" />
            <FieldLabel htmlFor="settings-stack-helpurl">Help URL</FieldLabel>
            <FormInput
                control={control}
                name="helpUrl"
                id="settings-stack-helpurl"
                defaultErrorMessage="Please enter a valid URL"
                rules={{
                    pattern:
                        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                }}
            />
        </div>
    )
}

import { useLayoutEffect } from 'react'

export function useBodyScrollLock(isEnabled: boolean) {
    useLayoutEffect(() => {
        if (!isEnabled) {
            return
        }

        // If the browser is set up to always show scrollbars, adding `overflow: hidden` to the body will remove that scrollbar, resulting in the website layout jumping to the right. This feels especially bad if it happens when the user opens a small dropdown which locks body scroll. Therefore we need to fill in the space the scrollbar usually occupies.
        // More info to this solution: https://github.com/willmcpo/body-scroll-lock/blob/fe6bfea3a134f337e77e62ae380cdf29eac7216b/src/bodyScrollLock.js
        const scrollBarGap = window.innerWidth - document.body.clientWidth

        const bodyStyle = document.body.style
        const { overflow: originalOverflow, paddingRight: originalPaddingRight } = bodyStyle

        bodyStyle.overflow = 'hidden'

        if (scrollBarGap) {
            const computedBodyPaddingRight = parseInt(
                getComputedStyle(document.body).getPropertyValue('padding-right'),
                10
            )

            bodyStyle.paddingRight = `${computedBodyPaddingRight + scrollBarGap}px`
        }

        return () => {
            bodyStyle.overflow = originalOverflow
            bodyStyle.paddingRight = originalPaddingRight
        }
    }, [isEnabled])
}

import { matchSorter } from 'match-sorter'
import { useMemo, useState } from 'react'

import { DropdownCheckbox, DropdownInput, useDropdownKeyboardNavigation } from '@laserfocus/ui/beam'
import { useAutoFocusedRef, useScrolledIntoViewRef } from '@laserfocus/ui/util-react'

export interface FieldsSection {
    name: 'Account' | 'Contact' | 'Opportunity' | 'Lead'
    fields: {
        key: string
        label: string
        isSelected: boolean
        toggle(): void
        isLocked?: boolean
    }[]
}

interface FieldsMultiSelectListProps {
    fieldsSections: FieldsSection[]
}

export function FieldsMultiSelectList({ fieldsSections }: FieldsMultiSelectListProps) {
    const [searchValue, setSearchValue] = useState('')

    const searchInputRef = useAutoFocusedRef<HTMLInputElement>(true)

    const filteredFieldsSections = useMemo(() => {
        if (!searchValue) {
            return fieldsSections
        }
        return fieldsSections
            .map((section) => ({
                ...section,
                fields: matchSorter(section.fields, searchValue, { keys: ['label'] }),
            }))
            .filter(({ fields }) => fields.length)
    }, [fieldsSections, searchValue])

    const filteredFields = useMemo(
        () => filteredFieldsSections.flatMap((section) => section.fields),
        [filteredFieldsSections]
    )

    const { hoveredOptionIndex, setHoveredOptionIndex } = useDropdownKeyboardNavigation({
        optionsLength: filteredFields.length,
        resetKey: searchValue,
        submit: (index) => filteredFields[index]!.toggle(),
    })

    const hoveredOptionRef = useScrolledIntoViewRef<HTMLDivElement>(hoveredOptionIndex)

    return (
        <div role="listbox" className="w-60">
            <div className="p-2">
                <DropdownInput
                    ref={searchInputRef}
                    placeholder="Search fields"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                />
            </div>
            {filteredFieldsSections.length > 0 && (
                <div className="px-2 pb-2 max-h-[25rem] box-content overflow-y-auto">
                    {filteredFieldsSections.map(({ name, fields }, index) => {
                        const previousColumnsLength = filteredFieldsSections
                            .slice(0, index)
                            .reduce((a, c) => a + c.fields.length, 0)

                        return (
                            <div key={index} className="relative grid gap-1">
                                <div className="sticky top-0 py-1 px-1.5 text-xs font-medium leading-[1.2] text-white/60 bg-grey-700/80 z-10">
                                    {name}
                                </div>
                                {fields.map((field, fieldIndex) => {
                                    const hoverIndex = previousColumnsLength + fieldIndex
                                    const isHovered =
                                        previousColumnsLength + fieldIndex === hoveredOptionIndex
                                    const setIsHovered = () => setHoveredOptionIndex(hoverIndex)

                                    return (
                                        <div
                                            key={fieldIndex}
                                            className="-mb-2"
                                            onMouseMove={setIsHovered}
                                        >
                                            <div
                                                ref={isHovered ? hoveredOptionRef : undefined}
                                                className="pb-2"
                                            >
                                                <DropdownCheckbox
                                                    disabled={field.isLocked}
                                                    isHighlighted={isHovered}
                                                    checked={field.isSelected}
                                                    onChange={field.toggle}
                                                    onFocus={setIsHovered}
                                                    label={field.label}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

import { useState } from 'react'

import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { useHotKey } from '@laserfocus/ui/util-keyboard'
import { CommandLine } from '@laserfocus/ui/beam'
import { EditOutlinedIcon } from '@laserfocus/ui/icons'

import { StepComponentProps, StaticStepState } from '../step-component-types'
import { useSelectOnMount } from '../useSelectOnMount'

export interface StepSetTitleState extends StaticStepState {
    inputValue: string
    selectedTitle: string
}

const DEFAULT_TASK_TITLE = 'Follow Up'

export function StepSetTitle({ stepState, submit }: StepComponentProps<StepSetTitleState>) {
    const [inputValue, setInputValue] = useState(stepState?.inputValue || '')
    const inputRef = useAutoFocusedRef<HTMLInputElement>(true)

    useSelectOnMount(inputRef)

    function handleSubmit() {
        submit({
            breadCrumbLabel: inputValue || DEFAULT_TASK_TITLE,
            inputValue,
            selectedTitle: inputValue || DEFAULT_TASK_TITLE,
        })
    }

    useHotKey(
        'enter',
        (event) => {
            event.preventDefault()
            handleSubmit()
        },
        { global: true }
    )

    return (
        <>
            <CommandLine.Input
                ref={inputRef}
                placeholder="Follow Up"
                aria-label="Task title"
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
            />
            <CommandLine.Content>
                <CommandLine.Option
                    icon={
                        <div className="h-[1.875rem] w-[1.875rem] rounded-full bg-white/10 grid">
                            <EditOutlinedIcon className="w-4 h-4 m-auto" />
                        </div>
                    }
                    title={`Set task title to “${inputValue || DEFAULT_TASK_TITLE}”`}
                    onClick={handleSubmit}
                    isSelected
                    select={() => {}}
                />
            </CommandLine.Content>
        </>
    )
}

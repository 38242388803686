import { useEffect, useRef } from 'react'
import Mousetrap from 'mousetrap'

type ModifierKey = 'shift' | 'command' | 'option' | 'ctrl' | 'alt' | 'meta'
type SpecialKey =
    | 'backspace'
    | 'tab'
    | 'enter'
    | 'return'
    | 'capslock'
    | 'esc'
    | 'escape'
    | 'space'
    | 'pageup'
    | 'pagedown'
    | 'end'
    | 'home'
    | 'ins'
    | 'del'
    | 'plus'
type ArrowKey = 'down' | 'up' | 'left' | 'right'
type NumberKey = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '0'
type LetterKey =
    | 'q'
    | 'w'
    | 'e'
    | 'r'
    | 't'
    | 'y'
    | 'u'
    | 'i'
    | 'o'
    | 'p'
    | 'a'
    | 's'
    | 'd'
    | 'f'
    | 'g'
    | 'h'
    | 'j'
    | 'k'
    | 'l'
    | 'z'
    | 'x'
    | 'c'
    | 'v'
    | 'b'
    | 'n'
    | 'm'
type SingleKey = ArrowKey | ModifierKey | SpecialKey | NumberKey | LetterKey
type KeyCombination = `${SingleKey}+${SingleKey}`
type KeySequence = `${SingleKey} ${SingleKey}`
// We're doing this to get neat autocompletion when typing the key string. But I suggest to replace this with just a string if TypeScript should get too slow. In the end this is a union of 7500 string literals.
type Key = SingleKey | KeyCombination | KeySequence | (string & {})

type Keys = Key | Key[]
type Action = 'keypress' | 'keydown' | 'keyup'
type EventHandler = (event: KeyboardEvent, combo: string) => void

interface Options {
    action?: Action
    global?: boolean
    disabled?: boolean
}

export function useHotKey(
    keys: Keys,
    eventHandler: EventHandler,
    { action, global = false, disabled = false }: Options = {}
) {
    const eventHandlerRef = useRef(eventHandler)
    eventHandlerRef.current = eventHandler

    useEffect(
        () => {
            if (disabled) {
                return
            }

            const mousetrap = global ? getMousetrapGlobal() : Mousetrap
            mousetrap.bind(
                keys,
                // LF-2882: setImmediate since if the action focuses an input (e.g. search) the same keyboard event is also dispatched to there
                (event: KeyboardEvent, combo: string) =>
                    setTimeout(() => eventHandlerRef.current(event, combo), 0),
                action
            )
            return () => {
                mousetrap.unbind(keys, action)
            }
        },
        // @ts-ignore Union is too complex for TypeScript
        [disabled, keys, action, global]
    )
}

let MousetrapGlobal
function getMousetrapGlobal() {
    if (!MousetrapGlobal) {
        MousetrapGlobal = new Mousetrap()
        MousetrapGlobal.stopCallback = () => false
    }
    return MousetrapGlobal
}

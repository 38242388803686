import { JSONValue, MaybePromise, WriteTransaction } from 'replicache'
import { logger } from '@sentry/utils'

import { MutatorInputFns, COUNTER_IGNORED_MUTATIONS } from '@laserfocus/shared/models'

import { updateCounter } from './activity-counter-repo'

type Mutator = (tx: WriteTransaction, args: any) => MaybePromise<JSONValue | void>

export function withCounters<T extends MutatorInputFns<any>>(mutators: T): T {
    return Object.fromEntries(
        Object.entries(mutators).map(([name, mutator]) => {
            const wrappedMutator: Mutator = async (tx, args) => {
                const counterUpdate = COUNTER_IGNORED_MUTATIONS.includes(name)
                    ? Promise.resolve()
                    : updateCounter(tx, name, getActivityTime(args))

                const result = await mutator(tx, args)
                try {
                    await counterUpdate
                } catch (e: any) {
                    logger.error(e)
                }
                return result
            }
            return [name, wrappedMutator]
        })
    ) as T
}

function getActivityTime(args: any) {
    return (
        args.optimistic?.LastModifiedDate ||
        args.createdDateTime ||
        args.LastModifiedDate ||
        new Date().toISOString()
    )
}

import { useParams } from 'react-router'

import { PersonPageContainer } from '@laserfocus/client/feature-person-details'
import { TodayNavigationProvider } from '@laserfocus/client/feature-stacks'
import { wrapAuthenticated } from '@laserfocus/client/feature-auth'

export function TodayPersonPage() {
    const { personId } = useParams<{ personId: string }>()
    return (
        <TodayNavigationProvider rootId={personId}>
            <PersonPageContainer personId={personId} />
        </TodayNavigationProvider>
    )
}

export default wrapAuthenticated(TodayPersonPage)

import { Link } from 'react-router-dom'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { confetti } from 'dom-confetti'

import { Authenticated, useIdentity } from '@laserfocus/client/feature-auth'
import { Button, EmptyState, Spinner, StaticMultiSelect } from '@laserfocus/ui/beam'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { theme } from '@laserfocus/ui/tailwindcss'

import { RelevantObject, RELEVANT_OBJECT_OPTIONS, useOnboarding } from '../../OnboardingContext'

import { useOnboardingTableData } from './useOnboardingTableData'
import { BrowserWindow } from './BrowserWindow'
import { Table } from './Table/Table'
import { Hint, TableHintsContextProvider, useTableHintsContext } from './TableHintsContext'

export function TablePage() {
    useBodyClassName('transition-colors duration-1000 bg-blue-50')

    return (
        <Authenticated>
            <TableHintsContextProvider>
                <TablePageInner />
            </TableHintsContextProvider>
        </Authenticated>
    )
}

function TablePageInner() {
    const { isLoading, data } = useOnboardingTableData()
    const user = useIdentity()
    const { currentHint } = useTableHintsContext()
    const confettiContainer = useRef<HTMLDivElement>(null)
    const hasFiredConfettiRef = useRef(false)

    useEffect(() => {
        if (!hasFiredConfettiRef.current && confettiContainer.current) {
            hasFiredConfettiRef.current = true
            confetti(confettiContainer.current, {
                angle: 90,
                spread: 360,
                startVelocity: 40,
                elementCount: 70,
                dragFriction: 0.12,
                duration: 3000,
                stagger: 3,
                width: '10px',
                height: '10px',
                perspective: '500px',
                colors: [
                    theme.colors.red[500],
                    theme.colors.blue[500],
                    theme.colors.green[500],
                    theme.colors.yellow[300],
                ],
            })
        }
    })

    if (isLoading) {
        return <Spinner fullscreen />
    }

    if (!data) {
        return <NoDataScreen />
    }

    const shouldGoToOverview = currentHint === Hint.GoToOverview

    return (
        <div className="min-h-screen grid place-content-center py-32 px-24 gap-11 grid-cols-1 justify-items-center">
            <div className="relative text-center grid gap-4 max-w-[45.25rem]">
                {shouldGoToOverview && (
                    <div ref={confettiContainer} className="absolute left-1/2 top-0" />
                )}
                <h1 className="font-serif font-bold text-3xl">
                    {getHeadingMessage(user?.FirstName, shouldGoToOverview)}
                </h1>
                <p className="text-xl">
                    {shouldGoToOverview ? (
                        <>
                            You created your first custom stack.
                            <br />
                            You can always assess it via your overview.
                        </>
                    ) : (
                        <>
                            Your stacks show everything you need to speed up your workflows.
                            <br />
                            Here is how you create your own.
                        </>
                    )}
                </p>
            </div>
            <BrowserWindow>
                <Table {...data} />
            </BrowserWindow>
            <Button
                as={Link}
                variant={shouldGoToOverview ? 'primary' : 'tertiary'}
                size="large"
                to={RoutePaths.Home}
            >
                {shouldGoToOverview ? 'Go to overview' : 'Skip onboarding'}
            </Button>
        </div>
    )
}

function useBodyClassName(classNames: string) {
    useLayoutEffect(() => {
        const { classList } = document.body

        const classNamesToRemove = classNames.split(' ').filter((className) => {
            const hasClassName = classList.contains(className)

            if (hasClassName) {
                return false
            }

            classList.add(className)

            return true
        })

        return () => classNamesToRemove.forEach((className) => classList.remove(className))
    }, [classNames])
}

function NoDataScreen() {
    const { relevantObjects, setRelevantObjects } = useOnboarding()
    const [localRelevantObjects, setLocalRelevantObjects] = useState(relevantObjects)

    return (
        <div className="min-h-screen grid justify-items-center place-content-center gap-4">
            <EmptyState
                title="No data"
                subtitle="We could not find any relevant data in your Salesforce account for now."
                emojis={['🕵️‍♀️', '👀', '🙈']}
            />
            <StaticMultiSelect
                options={RELEVANT_OBJECT_OPTIONS}
                selectedOptionValues={localRelevantObjects}
                className="w-[13.25rem]"
                onChange={setLocalRelevantObjects}
            />
            <Button
                variant="primary"
                size="large"
                className="w-[13.25rem]"
                disabled={
                    localRelevantObjects.length === 0 ||
                    isPristine(relevantObjects, localRelevantObjects)
                }
                onClick={() => setRelevantObjects(localRelevantObjects)}
            >
                Change Objects
            </Button>
            <div>or</div>
            <Button
                variant="secondary"
                size="large"
                className="w-[13.25rem] text-center"
                as={Link}
                to={RoutePaths.Home}
            >
                Enter Laserfocus
            </Button>
        </div>
    )
}

function isPristine(relevantObjects: RelevantObject[], localRelevantObjects: RelevantObject[]) {
    const relevantObjectsSet = new Set(relevantObjects)
    const localRelevantObjectsSet = new Set(localRelevantObjects)

    return (
        relevantObjects.every((o) => localRelevantObjectsSet.has(o)) &&
        localRelevantObjects.every((o) => relevantObjectsSet.has(o))
    )
}

function getHeadingMessage(firstName: string | undefined | null, shouldGoToOverview: boolean) {
    if (shouldGoToOverview) {
        if (firstName) {
            return `Congratulations, ${firstName}!`
        }
        return 'Congratulations!'
    }

    if (firstName) {
        return `Create your first stack, ${firstName}!`
    }

    return 'Create your first stack!'
}

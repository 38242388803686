import { useLayoutEffect } from 'react'

export function useBodyClassName(className: string | undefined) {
    useLayoutEffect(() => {
        if (!className) {
            return
        }

        const bodyClassList = document.body.classList
        const filteredClassNames = className
            .split(/\s+/)
            .filter((c) => c && !bodyClassList.contains(c))

        if (filteredClassNames.length) {
            bodyClassList.add(...filteredClassNames)
            return () => bodyClassList.remove(...filteredClassNames)
        }
    }, [className])
}

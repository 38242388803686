import type { ZodError, ZodIssue } from 'zod'
import { get } from 'lodash'

export type ZodReportingError<T> = {
    message: string
    code: string
    path: string
    valueAtPath: any
    record?: T | string
    errors: ZodIssue[]
}

export function enrichZodArrayError<T>(data: T[], fallback: ZodError): Array<ZodReportingError<T>> {
    return fallback.errors.map((e) => {
        const path = e.path.join('.')
        const valueAtPath = get(data, path)
        const recordIndex = e.path[0]

        const record =
            typeof recordIndex === 'number' ? data[recordIndex] : 'path[0] is not a number'
        const simplifiedError: ZodReportingError<T> = {
            message: e.message,
            code: e.code,
            path,
            valueAtPath,
            record: cleanRecord(record),
            errors: fallback.errors,
        }
        return simplifiedError
    })
}

export function enrichZodError<T>(data: T, error: ZodError) {
    Object.assign(error, {
        record: cleanRecord(data),
        issues: error.issues.map((issue) => {
            const path = issue.path.join('.')
            const valueAtPath = get(data, path)
            return {
                ...issue,
                valueAtPath,
            }
        }),
    })
    Object.defineProperty(error, 'message', {
        get: function () {
            return JSON.stringify(
                {
                    issues: this.issues,
                    record: this.record,
                },
                null,
                2
            )
        },
    })
    return error
}

const fieldsToRedact = ['Name', 'FirstName', 'LastName', 'Email', 'Phone']

type RedactableRecord = Record<string, any>
function cleanRecord<T extends RedactableRecord>(
    r: T | string | undefined
): T | string | undefined {
    if (!r || typeof r === 'string') {
        return r
    }
    const cleaned: Record<string, any> = {
        ...r,
    }
    const existingKeys = new Set(Object.keys(cleaned))
    fieldsToRedact.forEach((fieldName) => {
        if (existingKeys.has(fieldName)) {
            cleaned[fieldName as keyof typeof cleaned] = '***REDACTED***'
        }
    })
    return cleaned as T
}

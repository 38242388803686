import { twMerge } from 'tailwind-merge'

interface ModalFooterProps extends React.ComponentPropsWithoutRef<'div'> {
    border?: boolean
}

export function ModalFooter({ border, className, ...props }: ModalFooterProps) {
    return (
        <div
            {...props}
            className={twMerge(
                'px-8 py-4 grid grid-flow-col items-center gap-2',
                border && 'border-t bg-clip-padding border-grey-700/10',
                className
            )}
        />
    )
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function SearchOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M10 2a8 8 0 104.58 14.56 1 1 0 00.13.15l4.58 4.58a1 1 0 101.42-1.41l-4.59-4.59a1.04 1.04 0 00-.07-.06A8 8 0 0010 2zm-6 8a6 6 0 1112 0 6 6 0 01-12 0z" />
        </SvgIcon>
    )
}

export function getValidUrl(value: string) {
    const url = /^https?:\/\//.test(value) ? value : `https://${value}`

    try {
        new URL(url)
        return url
    } catch {
        return ''
    }
}

import { SvgIcon, SvgIconProps } from '../svg-icon'

// Optimized with SVGOMG
export function AddOutlinedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M12 5a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H6a1 1 0 110-2h5V6a1 1 0 011-1z" />
        </SvgIcon>
    )
}

import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { CheckOutlinedIcon } from '@laserfocus/ui/icons'

interface DropdownCheckboxProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'type'> {
    /** `checked` property is required because it determines the UI */
    checked: boolean
    label: React.ReactNode
    isHighlighted?: boolean
    highlightOnHover?: boolean
}

export function DropdownCheckbox({
    label,
    isHighlighted,
    highlightOnHover,
    className,
    ...props
}: DropdownCheckboxProps) {
    return (
        <label
            className={twMerge(
                'grid grid-flow-col grid-cols-[auto,minmax(0,1fr)] gap-1.5 w-full text-sm font-medium leading-[1.3] rounded-md py-1 px-1.5 pr-4',
                isHighlighted && 'bg-white/10',
                props.disabled ? 'text-white/40' : 'text-white cursor-pointer',
                highlightOnHover && 'hover:bg-white/10',
                className
            )}
        >
            <div
                className={clsx(
                    // mt-[0.0625rem]: We are using margin-top instead of centering the checkbox to keep the checkbox at the top if label has linebreak
                    'transition-colors mt-[0.0625rem] w-4 h-4 rounded relative',
                    props.checked
                        ? props.disabled
                            ? 'bg-blue-500/40'
                            : 'bg-blue-500'
                        : 'bg-white/10'
                )}
            >
                <input
                    {...props}
                    type="checkbox"
                    className={clsx(
                        'transition outline-none absolute inset-0 w-full appearance-none rounded focus-visible:ring',
                        props.disabled ? null : 'cursor-pointer',
                        props.checked ? 'focus-visible:bg-blue-700 ring-blue-500/30' : null
                    )}
                />
                {props.checked && (
                    <div className="absolute inset-0 w-full grid">
                        <CheckOutlinedIcon
                            className={clsx(
                                'w-3 h-3 m-auto',
                                props.disabled ? 'text-white/60' : 'text-white'
                            )}
                        />
                    </div>
                )}
            </div>
            <div className="select-none">{label}</div>
        </label>
    )
}

import { ulid } from 'ulid'
import { useState } from 'react'

import {
    createNoteTemplate,
    deleteNoteTemplate,
    updateNoteTemplate,
} from '@laserfocus/client/data-access-shared'
import { EditNoteTemplateModal } from '@laserfocus/client/feature-person-details'
import { isTruthy } from '@laserfocus/shared/models'

import { useRoleContext } from '../roles/RoleContext'

export interface TemplateModalState {
    isOpen: boolean
    templateData?: TemplateData
}

export interface TemplateData {
    id: string
    name: string
    body: string | undefined
}

export function useTemplateModalState() {
    const [state, setState] = useState<TemplateModalState>({ isOpen: false })
    function close() {
        setState({ isOpen: false })
    }
    function openTemplate(template: TemplateData) {
        setState({ isOpen: true, templateData: template })
    }
    function open() {
        setState({ isOpen: true })
    }
    return {
        state,
        setState,
        close,
        open,
        openTemplate,
    }
}

export function EditOrgNoteTemplateModal({
    state,
    close,
}: {
    state: TemplateModalState
    close: () => void
}) {
    const { selectedRole } = useRoleContext()
    return (
        <EditNoteTemplateModal
            show={state.isOpen}
            close={close}
            createTemplate={(data) =>
                createNoteTemplate(
                    ulid(),
                    {
                        ...data,
                        roles: [selectedRole].filter(isTruthy),
                    },
                    'org'
                ).then(close)
            }
            updateTemplate={(data) =>
                updateNoteTemplate(state.templateData!.id, data, 'org').then(close)
            }
            deleteTemplate={() => {
                deleteNoteTemplate(state.templateData!.id, 'org')
                close()
            }}
            initialTemplate={state.templateData}
        />
    )
}

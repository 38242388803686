export function getLocale() {
    if (typeof navigator === 'undefined') {
        return 'en'
    }
    return navigator.languages?.[0] || navigator.language || 'en'
}

export function getCountry() {
    return /^([a-z]{2}-)?(?<country>[A-Z]{2})/.exec(getLocale())?.groups!.country
}

import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'

import { LeadModel } from '@laserfocus/shared/models'
import { CalendarFilledIcon, DocumentFilledIcon, FolderFilledIcon } from '@laserfocus/ui/icons'
import { SpinnerInline } from '@laserfocus/ui/beam'
import { EverboardingHint } from '@laserfocus/client/feature-everboarding'

import { LogActivity } from './Activities/LogActivity'
import PersonLayout from './PersonLayout'
import { usePerson, useAccount } from './modules/PersonContext'
import { ActivityList } from './Activities/ActivityList'
import { ActivityData } from './modules/usePersonActivitiesRCQuery'
import { RightSide } from './RightSide/RightSide'
import { AddTaskAction } from './Activities/AddTaskAction'
import { NotesList } from './notes/NotesList'
import { ClientNote } from './notes/useRootNotes'
import { AddNoteButton } from './notes/AddNoteButton'
import { useNewNote } from './notes/useNewNote'
import { usePersonPageEverboarding } from './usePersonPageEverboarding'

interface PersonPageProps {
    rootId: string
    loadingRoot: boolean
    loadingActivityHistory: boolean
    openActivities: ActivityData[]
    historyActivities: ActivityData[]
    notes: ClientNote[]
    loadingNotes: 'FULL' | 'PARTIAL' | null
    notesDisabled?: boolean
    isInOverlay?: boolean
}

export default function PersonPageContainer(props: PersonPageProps) {
    const person = usePerson()
    const account = useAccount()

    if (props.isInOverlay) {
        return <PersonPage {...props} />
    }

    return (
        <PersonLayout
            title={account?.Name || (person as LeadModel)?.Company || person?.Name || ''}
            left={<PersonPage {...props} />}
            right={<RightSide isLoading={props.loadingRoot} />}
        />
    )
}

const TAB_INDEX_LOCAL_STORAGE_KEY = 'lf:personDetailsPageTabIndex'
const OVERLAY_TAB_INDEX_LOCAL_STORAGE_KEY = 'lf:overlayTabIndex'
let cachedLocalStorageTabIndex: number | null = null
let cachedLocalStorageOverlayTabIndex: number | null = null

export function PersonPage(props: PersonPageProps) {
    const { isInOverlay } = props

    usePersonPageEverboarding(!!isInOverlay)

    const [tabIndex, setTabIndex] = useState(() => {
        const cachedIndex = isInOverlay
            ? cachedLocalStorageOverlayTabIndex
            : cachedLocalStorageTabIndex
        if (cachedIndex !== null) {
            return cachedIndex
        }

        const indexFromLocalStorage = Number.parseInt(
            localStorage.getItem(
                isInOverlay ? OVERLAY_TAB_INDEX_LOCAL_STORAGE_KEY : TAB_INDEX_LOCAL_STORAGE_KEY
            ) || ''
        )
        return Number.isNaN(indexFromLocalStorage) ? 0 : indexFromLocalStorage
    })

    const { createNote, ...restNewNote } = useNewNote()

    useEffect(() => {
        const cachedIndex = isInOverlay
            ? cachedLocalStorageOverlayTabIndex
            : cachedLocalStorageTabIndex
        if (tabIndex !== cachedIndex) {
            localStorage.setItem(
                isInOverlay ? OVERLAY_TAB_INDEX_LOCAL_STORAGE_KEY : TAB_INDEX_LOCAL_STORAGE_KEY,
                tabIndex.toString()
            )

            if (isInOverlay) {
                cachedLocalStorageOverlayTabIndex = tabIndex
            } else {
                cachedLocalStorageTabIndex = tabIndex
            }
        }
    }, [tabIndex, isInOverlay])

    return (
        <Tab.Group defaultIndex={tabIndex} onChange={setTabIndex}>
            <div className="flex justify-between items-center pb-4 px-2">
                <Tab.List className="grid grid-flow-col gap-2 justify-start">
                    <TabHeader
                        label="Activity"
                        iconComponent={CalendarFilledIcon}
                        isActive={tabIndex === 0}
                    />
                    <TabHeader
                        label="Notes"
                        iconComponent={DocumentFilledIcon}
                        isActive={tabIndex === 1}
                        isLoading={props.loadingNotes === 'PARTIAL'}
                    />
                    {isInOverlay && (
                        <EverboardingHint
                            name="everboarding_overlay_fieldgroup_tab"
                            label="See your Salesforce fields"
                            placement="top-start"
                            popperClassName="z-30"
                            containerClassName="mt-2"
                            labelClassName="rotate-6 translate-y-2"
                            arrowClassName="-rotate-[24deg] translate-x-14"
                            delay={400}
                            disable={tabIndex === 2}
                        >
                            <TabHeader
                                label="Field Groups"
                                iconComponent={FolderFilledIcon}
                                isActive={tabIndex === 2}
                            />
                        </EverboardingHint>
                    )}
                </Tab.List>
                {tabIndex === 0 && <AddTaskAction className="ml-2 flex-none" />}
                {tabIndex === 1 && props.notes.length !== 0 && (
                    <AddNoteButton createNewNote={createNote} className="ml-2 flex-none" />
                )}
            </div>
            <Tab.Panels>
                <Tab.Panel className="outline-none focus-visible:ring rounded-[0.625rem] transition-shadow">
                    <ActivitiesTab {...props} />
                </Tab.Panel>
                <Tab.Panel className="outline-none focus-visible:ring rounded-[0.625rem] transition-shadow">
                    <NotesList
                        notes={props.notes}
                        isLoadingFull={props.loadingNotes === 'FULL'}
                        isDisabled={props.notesDisabled}
                        createNote={createNote}
                        {...restNewNote}
                    />
                </Tab.Panel>
                {isInOverlay && (
                    <Tab.Panel className="outline-none focus-visible:ring rounded-[0.625rem] transition-shadow">
                        <RightSide isLoading={props.loadingRoot} isInOverlay />
                    </Tab.Panel>
                )}
            </Tab.Panels>
        </Tab.Group>
    )
}

function TabHeader({
    iconComponent,
    label,
    isActive,
    isLoading,
}: {
    iconComponent: React.ComponentType<{ className?: string }>
    label: string
    isActive: boolean
    isLoading?: boolean
}) {
    const Icon = iconComponent
    return (
        <Tab
            className={clsx(
                'grid items-center grid-flow-col gap-1 pr-4 rounded-[0.625rem] hover:bg-grey-700/5 transition text-lg leading-[1.4] font-serif outline-none focus-visible:ring',
                isActive ? 'bg-grey-700/5' : 'text-grey-700/60'
            )}
        >
            <Icon className="w-5 h-5 p-[0.4375rem] box-content" />
            <span className="text-ellipsis whitespace-nowrap overflow-hidden" title={label}>
                {label}
            </span>
            {isLoading && (
                <div className="ml-2 flex-none text-grey-700/10">
                    <SpinnerInline />
                </div>
            )}
        </Tab>
    )
}

function ActivitiesTab({
    loadingActivityHistory,
    openActivities,
    historyActivities,
    rootId,
}: PersonPageProps) {
    return (
        <>
            <div className="px-2">
                <LogActivity rootId={rootId} />
            </div>

            <div className="mt-3">
                <ActivityList
                    openActivities={openActivities}
                    historyActivities={historyActivities}
                    loadingActivityHistory={loadingActivityHistory}
                />
            </div>
        </>
    )
}

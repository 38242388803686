import { useState } from 'react'
import { ulid } from 'ulid'

import {
    createNoteTemplate,
    deleteNoteTemplate,
    updateNoteTemplate,
    useNoteTemplates,
} from '@laserfocus/client/data-access-shared'
import { Analytics, useAnalyticsContext } from '@laserfocus/client/util-analytics'
import { Select } from '@laserfocus/ui/beam'
import { DuplicateOutlinedIcon, EditOutlinedIcon, GlobeOutlinedIcon } from '@laserfocus/ui/icons'

import { EditNoteTemplateModal, TemplateData } from './EditNoteTemplateModal'
import { ToolbarButton } from './ToolbarButton'

interface NoteTemplateSelectProps {
    submit(body: string): void
    isNoteTemplateSelectOpen: boolean
    setIsNoteTemplateSelectOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function NoteTemplateSelect({
    submit,
    isNoteTemplateSelectOpen,
    setIsNoteTemplateSelectOpen,
}: NoteTemplateSelectProps) {
    const [editTemplateModal, setEditTemplateModal] = useState<{
        isOpen: boolean
        templateData?: TemplateData
    }>({ isOpen: false })
    const { isLoading, noteTemplates } = useNoteTemplates()
    const { location } = useAnalyticsContext()

    const button = (
        <ToolbarButton
            icon={DuplicateOutlinedIcon}
            title="Select template"
            onClick={() => {
                setIsNoteTemplateSelectOpen((v) => !v)
            }}
            isActive={isNoteTemplateSelectOpen}
        />
    )

    if (isLoading) {
        return button
    }

    function closeTemplateModal() {
        setEditTemplateModal({ isOpen: false })
        setIsNoteTemplateSelectOpen(true)
    }

    return (
        <>
            <Select
                isOpen={isNoteTemplateSelectOpen}
                options={noteTemplates!
                    .map((template) => ({
                        value: template.id,
                        label: template.name,
                        body: template.body,
                        ...(template.__typename === 'OrgNoteTemplate'
                            ? {
                                  iconComponent: GlobeOutlinedIcon,
                                  iconPosition: 'right' as const,
                                  iconProps: {
                                      title: `Global Templates can't be edited`,
                                      className: 'text-white/30',
                                  },
                              }
                            : {
                                  asideAction: {
                                      iconComponent: EditOutlinedIcon,
                                      title: 'Edit template',
                                      onClick: () => {
                                          setIsNoteTemplateSelectOpen(false)
                                          setEditTemplateModal({
                                              isOpen: true,
                                              templateData: {
                                                  id: template.id,
                                                  name: template.name,
                                                  body: template.body,
                                              },
                                          })
                                      },
                                  },
                              }),
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                onCancel={() => {
                    setIsNoteTemplateSelectOpen(false)
                }}
                onSubmit={({ body, label }) => {
                    submit(body || '')
                    setIsNoteTemplateSelectOpen(false)
                    Analytics.trackEvent({
                        event: 'note_template_used',
                        location: location || 'person_details',
                        templateName: label,
                    })
                }}
                searchKeys={['label']}
                size="large"
                bottomAction={{
                    label: 'Add template',
                    onClick: () => {
                        setIsNoteTemplateSelectOpen(false)
                        setEditTemplateModal({ isOpen: true })
                    },
                }}
            >
                {button}
            </Select>
            <EditNoteTemplateModal
                show={editTemplateModal.isOpen}
                close={closeTemplateModal}
                createTemplate={(data) => createNoteTemplate(ulid(), data).then(closeTemplateModal)}
                updateTemplate={(data) =>
                    updateNoteTemplate(editTemplateModal.templateData!.id, data).then(
                        closeTemplateModal
                    )
                }
                deleteTemplate={() => {
                    deleteNoteTemplate(editTemplateModal.templateData!.id)
                    closeTemplateModal()
                }}
                initialTemplate={editTemplateModal.templateData}
            />
        </>
    )
}

import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useSearchParams } from 'react-router-dom'

import {
    RCFetchableStack,
    StackNavigationProvider,
    useBridgeStackStore,
} from '@laserfocus/client/store-shared'
import {
    goToStackPersonPage,
    goToStackPage,
    makeStackPath,
    PersonQueryParams,
} from '@laserfocus/client/util-routing'
import { StackModel } from '@laserfocus/client/model'
import { ContactModel, LeadId, LeadModel, OpportunityModel, Stack } from '@laserfocus/shared/models'
import { visitRoot } from '@laserfocus/client/data-access-shared'

import { useStack, useFilteredData } from './useTable'

type StackNavigationGuardProps = PropsWithChildren<{ stackSlug: string; personId: string }>
type StackNavigationContainerProps = PropsWithChildren<{
    personId: string
    stack: Stack
    fetchableStack: RCFetchableStack
}>

const StackNavigationGuard = observer(function StackNavigationGuard({
    stackSlug,
    ...props
}: StackNavigationGuardProps) {
    const stackId = StackModel.parseIdFromSlug(stackSlug)
    const stackBridge = useBridgeStackStore()
    const { stack, fetchableStack } = useStack(stackId, stackBridge)

    if (!stack || !fetchableStack) {
        return null
    }
    return <StackNavigationContainer stack={stack} fetchableStack={fetchableStack} {...props} />
})

export default StackNavigationGuard

const StackNavigationContainer = observer(
    ({ children, stack, fetchableStack, personId }: StackNavigationContainerProps) => {
        const rootId = personId
        const { rows, isLoading } = useFilteredData(fetchableStack)

        const [searchParams] = useSearchParams()
        const contactId = searchParams.get('contactId')
        const opportunityId = searchParams.get('contactId')

        const currentObject = stack?.sobject

        const findPersonByIndex = useMemo(() => {
            if (currentObject === 'Contact' || currentObject === 'Opportunity') {
                return (person: any) => person.AccountId === rootId
            }
            return (person: any) => person.Id === rootId || person.ConvertedAccountId === rootId
        }, [currentObject, rootId])

        const getPersonId = useMemo(() => {
            if (currentObject === 'Contact' || currentObject === 'Opportunity') {
                return (person: ContactModel | OpportunityModel) => person && person.AccountId
            }
            return (person: LeadModel) => person && person.Id
        }, [currentObject])

        const getRouteParams = useCallback(
            (row): [string] | [string, PersonQueryParams] => {
                const rootId = getPersonId(row)
                if (currentObject === 'Contact') {
                    return [rootId!, { contactId: row?.Id }]
                } else if (currentObject === 'Opportunity') {
                    return [rootId!, { opportunityId: row?.Id }]
                }
                return [rootId!]
            },
            [currentObject, getPersonId]
        )

        const [prevPerson, nextPerson] = useMemo(() => {
            if (isLoading) {
                return []
            }
            const sortedData = rows
            let detailedFindIndex = -1
            if (contactId && currentObject === 'Contact') {
                const findWithContactId = (record: any) =>
                    findPersonByIndex(record) && record.Id === contactId
                detailedFindIndex = sortedData.findIndex(findWithContactId)
            } else if (opportunityId && currentObject === 'Opportunity') {
                const findWithOpportunityId = (record: any) =>
                    findPersonByIndex(record) && record.Id === opportunityId
                detailedFindIndex = sortedData.findIndex(findWithOpportunityId)
            }
            const personIndex =
                detailedFindIndex > -1 ? detailedFindIndex : sortedData.findIndex(findPersonByIndex)
            let nextPerson
            let prevPerson
            const sortedDataLength = sortedData.length
            if (personIndex === -1 || !sortedDataLength) {
                return [prevPerson, nextPerson]
            }
            if (personIndex === sortedDataLength - 1) {
                nextPerson = sortedData[0]
            } else {
                nextPerson = sortedData[personIndex + 1]
            }
            if (personIndex === 0) {
                prevPerson = sortedData[sortedDataLength - 1]
            } else {
                prevPerson = sortedData[personIndex - 1]
            }
            return [prevPerson, nextPerson]
        }, [isLoading, rows, contactId, currentObject, opportunityId, findPersonByIndex])
        const [nextPersonId, nextParams] = getRouteParams(nextPerson)
        const [prevPersonId, prevParams] = getRouteParams(prevPerson)

        const stackSlug = StackModel.getSlug(stack)

        const navigateNext = useCallback(() => {
            if (nextPersonId) {
                return goToStackPersonPage(stackSlug, nextPersonId, nextParams)
            }
            return goToStackPage(stackSlug)
        }, [nextPersonId, stackSlug, nextParams])
        const navigatePrevious = useCallback(() => {
            if (prevPersonId) {
                return goToStackPersonPage(stackSlug, prevPersonId, prevParams)
            }
            return goToStackPage(stackSlug)
        }, [prevPersonId, stackSlug, prevParams])

        const navigateList = useCallback(() => {
            goToStackPage(stackSlug)
        }, [stackSlug])
        const navigationProps = {
            navigateList,
            navigateNext,
            navigatePrevious,
            listPath: makeStackPath(stackSlug),
            title: stack.title,
        }

        return (
            <StackNavigationProvider value={navigationProps}>
                {children}
                {nextPersonId && <PrefetchNext rootId={nextPersonId} />}
            </StackNavigationProvider>
        )
    }
)

function PrefetchNext({ rootId }: { rootId: string }) {
    useEffect(() => {
        visitRoot({ rootId } as { rootId: LeadId })
    }, [rootId])

    return null
}

import 'reflect-metadata'
import { computed, observable, toJS } from 'mobx'
import { keyBy } from 'lodash'

import {
    ClientTypes,
    RecordType as RecordTypeProps,
    RecordTypePicklist,
} from '@laserfocus/shared/models'
import { ObjectPool } from '@laserfocus/client/data-layer'

export interface RecordtypeSerialized extends RecordTypeProps {
    Id: string
}

export class RecordtypeModel implements RecordtypeSerialized {
    static ObjectType: ClientTypes = 'RecordType'
    __typename: 'RecordType' = 'RecordType'

    @observable objectName: string
    @observable id: string
    @observable name: string
    @observable picklists: RecordTypePicklist[]

    objectPool: ObjectPool

    constructor(props: RecordTypeProps, objectPool: ObjectPool) {
        if (objectPool) {
            this.objectPool = objectPool
        }
        this.id = props.id
        this.objectName = props.objectName
        this.name = props.name
        this.picklists = props.picklists
    }

    serialize() {
        return toJS(this.toJSON(), { recurseEverything: true })
    }

    get props() {
        return this.serialize()
    }

    toJSON(): RecordtypeSerialized {
        return {
            Id: this.id,
            id: this.id,
            objectName: this.objectName,
            name: this.name,
            picklists: this.picklists,
            __typename: 'RecordType',
        }
    }

    @computed
    get ObjectType() {
        return RecordtypeModel.ObjectType
    }

    @computed
    get Id() {
        return this.id
    }

    @computed
    get picklistsByField(): Record<string, RecordTypePicklist> {
        return keyBy(this.picklists, 'fieldName')
    }

    toOption() {
        return {
            value: this.id,
            label: this.name,
        }
    }
}

import { getLocale } from '@laserfocus/ui/util-locale'

import { ReportsLayout } from './ReportsLayout'
// import { TeamSelectionCard } from './TeamSelectionCard'
import { ReportsContextProvider } from './ReportsContext'
// import { NoNextTaskCard } from './NoNextTaskCard'
// import { StatsCard, PercentCard, GaugeCard } from './_StatsCard'
import { StatsCard } from './Cards/StatsCard'
import { Card, CardTitle } from './Cards/Card'
import { PercentCircle } from './Diagrams/PercentCircle'
import { Gauge } from './Diagrams/Gauge'

export function ReportsPage() {
    return (
        <ReportsContextProvider>
            <ReportsLayout>
                <div className="grid gap-12">
                    <div className="grid gap-6 grid-cols-3">
                        <h3 className="col-span-3 text-lg font-bold font-serif">
                            Your Salesforce Instance Health
                        </h3>
                        <StatsCard
                            title="Fields in Salesforce"
                            stat="476"
                            subtitle="Fields on Leads, Accounts, Opportunities and Contacts"
                            color="yellow"
                        />
                        <StatsCard
                            title="Unused fields"
                            stat="86"
                            subtitle="How many fields are used in your last 100 Deals"
                            color="yellow"
                        />
                        <Card className="flex flex-col justify-between gap-5" color="yellow">
                            <CardTitle>Unused fields (percent)</CardTitle>
                            <PercentCircle percent={28} radius={70} color="yellow" />
                        </Card>

                        <StatsCard
                            color="blue"
                            title="Inactive Users"
                            stat="3"
                            subtitle="Users with almost no activity in the last month"
                        />
                        <StatsCard
                            color="blue"
                            title="Money wasted"
                            stat="3,700$"
                            subtitle="On unutilized salesforce licenses per month"
                        />
                        <Card className="flex flex-col justify-between gap-5" color="blue">
                            <CardTitle>Time to load Salesforce Data</CardTitle>
                            <div className="self-center">
                                <Gauge
                                    min={0}
                                    max={10000}
                                    maxLabel="10s"
                                    value={7372}
                                    radius={100}
                                    valueFormatter={formatTime}
                                    valueLabelClassName="font-serif"
                                    color="blue"
                                />
                            </div>
                            <div />
                        </Card>
                    </div>

                    {/* <div className="grid gap-6">
                        <h3 className="col-span-2 font-semibold">Your Team</h3>
                        <div className="col-span-2">
                            <TeamSelectionCard />
                        </div>

                        <div className="grid gap-6 grid-cols-2">
                            <NoNextTaskCard />
                            <NoNextTaskCard />
                        </div>
                    </div>

                    <Section title="Your Salesforce Hygene">
                        <NoNextTaskCard />
                        <NoNextTaskCard />
                        <NoNextTaskCard />
                        <NoNextTaskCard />
                    </Section> */}
                </div>
            </ReportsLayout>
        </ReportsContextProvider>
    )
}

function formatTime(ms: number) {
    const formatter = new Intl.NumberFormat(getLocale())
    return formatter.format(Math.round(ms / 10) / 100) + 's'
}

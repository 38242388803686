type DepthFirstConfig<T> = {
    root: T
    getChildren(node: T): T[]
    match(node: T): boolean
    limit?: number
}

export function depthFirst<T>({ root, getChildren, match, limit }: DepthFirstConfig<T>) {
    let results: T[] = []
    function processNode(node: T) {
        if (!limit || results.length <= limit) {
            const children = getChildren(node)

            children.forEach(processNode)
            if (limit && results.length >= limit) {
                return
            }
            for (const child of children) {
                if (limit && results.length >= limit) {
                    return
                }
                if (match(child)) {
                    results.push(child)
                }
            }
        }
    }
    processNode(root)
    if (limit && results.length >= limit) {
        return results
    }
    if (match(root)) {
        results.push(root)
    }
    return results
}

/* eslint-disable no-case-declarations */
import {
    addDays,
    startOfDay,
    endOfDay,
    addWeeks,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfQuarter,
    endOfQuarter,
    addQuarters,
    startOfYear,
    endOfYear,
    addYears,
} from 'date-fns'

import type { ConditionValue } from './filter-conditions-schema'

function never(msg: string) {
    if (process.env.NODE_ENV === 'production') {
        console.error(new Error(msg))
    } else {
        throw new Error(msg)
    }
}

/**
 * I am not sure if this makes sense as ENUM, or wether a string union would be better
 * (enums are always more work)
 * There are also "dynamic" ones
 * @see https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql_select_dateformats.htm
 */
export enum SFDateConstants {
    'TODAY' = 'TODAY',
    'TOMORROW' = 'TOMORROW',
    'LAST_WEEK' = 'LAST_WEEK',
    'LAST_2_WEEKS' = 'LAST_N_WEEKS:2',
    'THIS_WEEK' = 'THIS_WEEK',
    'NEXT_WEEK' = 'NEXT_WEEK',
    'NEXT_2_WEEKS' = 'NEXT_N_WEEKS:2',
    'LAST_MONTH' = 'LAST_MONTH',
    'THIS_MONTH' = 'THIS_MONTH',
    'NEXT_MONTH' = 'NEXT_MONTH',
    'NEXT_4_MONTHS' = 'NEXT_N_MONTHS:4',
    'LAST_90_DAYS' = 'LAST_90_DAYS',
    'NEXT_90_DAYS' = 'NEXT_90_DAYS',
    'THIS_QUARTER' = 'THIS_QUARTER',
    'LAST_QUARTER' = 'LAST_QUARTER',
    'NEXT_QUARTER' = 'NEXT_QUARTER',
    'THIS_YEAR' = 'THIS_YEAR',
    'LAST_YEAR' = 'LAST_YEAR',
    'NEXT_YEAR' = 'NEXT_YEAR',
    'THIS_FISCAL_QUARTER' = 'THIS_FISCAL_QUARTER',
    'LAST_FISCAL_QUARTER' = 'LAST_FISCAL_QUARTER',
    'NEXT_FISCAL_QUARTER' = 'NEXT_FISCAL_QUARTER',
    'THIS_FISCAL_YEAR' = 'THIS_FISCAL_YEAR',
    'LAST_FISCAL_YEAR' = 'LAST_FISCAL_YEAR',
    'NEXT_FISCAL_YEAR' = 'NEXT_FISCAL_YEAR',
}

export type SFDateConstantValue = keyof typeof SFDateConstants

export const SFDateConstantValues = Object.keys(SFDateConstants) as [string, string, ...string[]]

export type SFDateConstantValueDTO = SFDateConstantValue

export function isDateConstant(v: ConditionValue): v is keyof typeof SFDateConstants {
    return Object.keys(SFDateConstants).includes(v as string)
}

type TimeDuration = [Date, Date]

export function getDurationForConstant(d: SFDateConstantValue): TimeDuration {
    if (d.includes('FISCAL')) {
        console.warn('I am not yet handling fiscal years properly', d)
    }
    const baseNow = new Date()
    switch (d) {
        case SFDateConstants.TODAY:
            return [startOfDay(baseNow), endOfDay(baseNow)]
        case SFDateConstants.TOMORROW:
            const baseTomorrow = addDays(baseNow, 1)
            return [startOfDay(baseTomorrow), endOfDay(baseTomorrow)]
        case SFDateConstants.LAST_WEEK:
            const baseLastWeek = addWeeks(baseNow, -1)
            return [startOfWeek(baseLastWeek), endOfWeek(baseLastWeek)]
        case 'LAST_2_WEEKS':
            const twoWeeksAgo = addWeeks(baseNow, -2)
            return [startOfWeek(twoWeeksAgo), endOfWeek(new Date())]
        case SFDateConstants.THIS_WEEK:
            return [startOfWeek(baseNow), endOfWeek(baseNow)]
        case SFDateConstants.NEXT_WEEK:
            const baseNextWeek = addWeeks(baseNow, 1)
            return [startOfWeek(baseNextWeek), endOfWeek(baseNextWeek)]
        case 'NEXT_2_WEEKS':
            const inTwoWeeks = addWeeks(baseNow, 2)
            return [startOfWeek(new Date()), endOfWeek(inTwoWeeks)]
        case SFDateConstants.THIS_MONTH:
            return [startOfMonth(baseNow), endOfMonth(baseNow)]
        case SFDateConstants.NEXT_MONTH:
            const baseNextMonth = addMonths(baseNow, 1)
            return [startOfMonth(baseNextMonth), endOfMonth(baseNextMonth)]
        case 'NEXT_4_MONTHS': {
            const in4Months = addMonths(baseNow, 4)
            return [startOfMonth(baseNow), endOfMonth(in4Months)]
        }
        case SFDateConstants.LAST_MONTH:
            return [startOfMonth(addMonths(baseNow, -1)), endOfMonth(addMonths(baseNow, -1))]
        case SFDateConstants.LAST_90_DAYS:
            return [startOfDay(addDays(baseNow, -90)), endOfDay(baseNow)]
        case SFDateConstants.NEXT_90_DAYS:
            return [startOfDay(baseNow), endOfDay(addDays(baseNow, 90))]
        case SFDateConstants.THIS_FISCAL_QUARTER:
        case SFDateConstants.THIS_QUARTER:
            return [startOfQuarter(baseNow), endOfQuarter(baseNow)]
        case SFDateConstants.NEXT_FISCAL_QUARTER:
        case SFDateConstants.NEXT_QUARTER:
            return [startOfQuarter(addQuarters(baseNow, 1)), endOfQuarter(addQuarters(baseNow, 1))]
        case SFDateConstants.LAST_FISCAL_QUARTER:
        case SFDateConstants.LAST_QUARTER:
            return [
                startOfQuarter(addQuarters(baseNow, -1)),
                endOfQuarter(addQuarters(baseNow, -1)),
            ]
        case SFDateConstants.THIS_YEAR:
        case SFDateConstants.THIS_FISCAL_YEAR:
            return [startOfYear(baseNow), endOfYear(baseNow)]
        case SFDateConstants.NEXT_YEAR:
        case SFDateConstants.NEXT_FISCAL_YEAR:
            const nextYear = addYears(baseNow, 1)
            return [startOfYear(nextYear), endOfYear(nextYear)]
        case SFDateConstants.LAST_YEAR:
        case SFDateConstants.LAST_FISCAL_YEAR:
            const lastYear = addYears(baseNow, -1)
            return [startOfYear(lastYear), endOfYear(lastYear)]
        default:
            never(`Trying to get the duration for ${d}, which I don't know how to handle`)
            return [new Date(), new Date()]
    }
}

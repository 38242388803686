import { isNull } from 'util'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { ExclamationOutlinedIcon } from '@laserfocus/ui/icons'

export interface SwitchProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'type'> {
    checked?: boolean
    onDarkBackground?: boolean
    indeterminate?: boolean
    error?: string
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(props, ref) {
    const { className, error, onDarkBackground, indeterminate, ...inputProps } = props
    return (
        <div
            className={twMerge(
                'px-2 py-[0.4375rem] grid grid-flow-col items-center justify-start gap-2',
                className
            )}
            title={error}
        >
            <div className="relative w-7 h-4 rounded-[1.25rem]">
                <input
                    ref={ref}
                    {...inputProps}
                    type="checkbox"
                    className={clsx(
                        'w-full h-full appearance-none rounded-[1.25rem] outline-none transition block',
                        !inputProps.disabled && 'cursor-pointer',
                        getInputColorClassName(props)
                    )}
                />
                <div
                    className={clsx(
                        'pointer-events-none absolute top-0.5 left-0.5 transition w-3 h-3 bg-white rounded-full',
                        typeof inputProps !== 'boolean' && indeterminate
                            ? 'translate-x-[6px]'
                            : inputProps.checked
                            ? 'translate-x-3'
                            : isNull
                    )}
                />
            </div>
            {error && <ExclamationOutlinedIcon className="w-4 h-4 text-red-500" />}
        </div>
    )
})

function getInputColorClassName({ checked, disabled, error, onDarkBackground }: SwitchProps) {
    if (checked) {
        if (disabled) {
            return error ? 'bg-red-500/20' : 'bg-blue-500/20'
        }
        return error
            ? 'bg-red-500 hover:bg-red-600 focus-visible:bg-red-600 focus-visible:ring ring-red-500/10'
            : 'bg-blue-500 hover:bg-blue-700 focus-visible:bg-blue-700 focus-visible:ring ring-blue-500/10'
    }

    if (disabled) {
        return onDarkBackground ? 'bg-white/10' : 'bg-grey-700/10'
    }

    return onDarkBackground
        ? clsx('bg-white/20 hover:bg-white/30 focus-visible:ring', error && 'ring-red-500')
        : clsx('bg-grey-700/20 hover:bg-grey-700/30 focus-visible:ring', error && 'ring-red-500')
}

import { useEffect } from 'react'

import { useBodyClassName } from '@laserfocus/ui/util-react'

export const BACKGROUND_COLOR_CLASSES = {
    white: /*tw:*/ 'bg-white',
    yellow: /*tw:*/ 'bg-yellow-50',
    red: /*tw:*/ 'bg-red-100',
}

export function useBackgroundColor(bg?: string) {
    const backgroundColorClass =
        BACKGROUND_COLOR_CLASSES[bg as keyof typeof BACKGROUND_COLOR_CLASSES]
    const backgroundColorLiteral = bg?.startsWith('#') || bg?.startsWith('rgb') ? bg : undefined

    useBodyClassName(backgroundColorClass)

    useEffect(() => {
        if (backgroundColorLiteral) {
            const originalBackgroundColor = document.body.style.backgroundColor
            document.body.style.backgroundColor = backgroundColorLiteral
            return () => {
                document.body.style.backgroundColor = originalBackgroundColor
            }
        }
    }, [backgroundColorLiteral])
    return {
        backgroundColorClass,
        backgroundColorLiteral,
    }
}

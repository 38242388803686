import { ConvertError, FailedMutation, Identifier, WithType } from '@laserfocus/shared/models'

export type FailedConvertMutation = FailedMutation<any, { errors: ConvertError[] }>

export function hasDuplicateError(failedMutation?: FailedConvertMutation) {
    return failedMutation?.error.errors?.some((a) => a.errorCode === 'DUPLICATES_DETECTED')
}

export function hasRecordDuplicate(recordType: string, failedMutation?: FailedConvertMutation) {
    return getDuplicateRecords(recordType, failedMutation).length > 0
}
type MatchedRecord = WithType<string> &
    Identifier &
    Record<string, string | string[] | number | boolean | null | undefined>
export function getDuplicateRecords<ModelType extends MatchedRecord>(
    recordType: string,
    failedMutation?: FailedConvertMutation
) {
    return (failedMutation?.error.errors
        ?.filter((a) => a.errorCode === 'DUPLICATES_DETECTED')
        .flatMap((a) =>
            a.matchRecords.map((a) => a.record).filter((record) => record.__typename === recordType)
        ) || []) as ModelType[]
}

import { useState } from 'react'
import { formatISO, parseISO } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { Datepicker } from '@laserfocus/ui/beam'
import { getDateShort, getTime } from '@laserfocus/ui/util-locale'
import { LockOutlinedIcon } from '@laserfocus/ui/icons'

import { HighlightedCellValue } from './HighlightedCellValue'
import { TableCellProps } from './cell.types'
import {
    CLASS_NAME_INPUT_BUTTON,
    CLASS_NAME_INPUT_BUTTON_SELECTED,
    CLASS_NAME_LOCK_ICON,
    CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK,
    CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT,
    CLASS_NAME_INPUT_BUTTON_DARK,
    CLASS_NAME_INPUT_BUTTON_LIGHT,
    CLASS_NAME_LOCK_ICON_DARK,
} from './shared-class-names'

interface DateCellProps extends TableCellProps {
    dateOnly?: boolean
    placeholder?: string
    buttonClassName?: string
    renderButton?({
        children,
    }: {
        children: React.ReactElement<RefAttribute> & RefAttribute
    }): React.ReactElement<RefAttribute> & RefAttribute
}

interface RefAttribute {
    ref?: ((element: HTMLElement) => void) | null
}

export const DateCell = function DateCell({
    value,
    readOnly,
    updateValue,
    searchTerm,
    theme,
    placeholder = '—',
    dateOnly,
    buttonClassName,
    renderButton,
}: DateCellProps) {
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false)

    const initialDateDirty = value || undefined
    const initialDate =
        typeof initialDateDirty === 'string' ? parseISO(initialDateDirty) : initialDateDirty

    const displayValue = getDisplayValue(initialDate, dateOnly)

    const content = (
        <button
            title={displayValue}
            disabled={readOnly}
            className={twMerge(
                CLASS_NAME_INPUT_BUTTON,
                theme === 'dark' ? CLASS_NAME_INPUT_BUTTON_DARK : CLASS_NAME_INPUT_BUTTON_LIGHT,
                isDatepickerOpen && CLASS_NAME_INPUT_BUTTON_SELECTED,
                displayValue
                    ? null
                    : theme === 'dark'
                    ? CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_DARK
                    : CLASS_NAME_INPUT_BUTTON_PLACEHOLDER_LIGHT,
                buttonClassName
            )}
            onClick={() => setIsDatepickerOpen(true)}
        >
            {displayValue ? (
                <HighlightedCellValue
                    value={displayValue}
                    searchTerm={searchTerm}
                    className={clsx(theme === 'dark' && 'text-white', buttonClassName)}
                />
            ) : (
                placeholder
            )}
            {readOnly && (
                <LockOutlinedIcon
                    className={twMerge(
                        CLASS_NAME_LOCK_ICON,
                        theme === 'dark' && CLASS_NAME_LOCK_ICON_DARK
                    )}
                />
            )}
        </button>
    )

    return (
        <Datepicker
            isOpen={isDatepickerOpen}
            initialDate={initialDate}
            dateOnly={dateOnly}
            withSelect
            allowEmpty
            onDateSubmit={(date) => {
                setIsDatepickerOpen(false)
                const value = date
                    ? dateOnly
                        ? formatISO(date, { representation: 'date' })
                        : date.toISOString()
                    : null

                const currentValue = initialDate?.toISOString() ?? null
                if (value !== currentValue) {
                    updateValue(value)
                }
            }}
            onCancel={() => setIsDatepickerOpen(false)}
        >
            <div>
                {typeof renderButton === 'function' ? renderButton({ children: content }) : content}
            </div>
        </Datepicker>
    )
}

function getDisplayValue(date: Date | undefined, dateOnly: boolean | undefined) {
    if (!date) {
        return ''
    }
    if (dateOnly) {
        return getDateShort(date)
    }
    return `${getDateShort(date)} ${getTime(date)}`
}

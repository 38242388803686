import { useObserver } from 'mobx-react-lite'
import { useEffect, useCallback, useState } from 'react'
import { matchSorter } from 'match-sorter'

import { Stack } from '@laserfocus/shared/models'
import { completeTask, useStacks } from '@laserfocus/client/data-access-shared'
import { isStackRelevantToday } from '@laserfocus/client/model'
import {
    useTodayView,
    useBridgeStackStore,
    ActivityView,
    useOverdueView,
    useUpcomingView,
} from '@laserfocus/client/store-shared'

export function useTodayActivities() {
    const todayView = useTodayView()
    const { activities, isLoading, searchTerm, setSearchTerm, totalCount } =
        useFilteredData(todayView)
    const todayStacks = useTodayStacks(searchTerm)

    useEffect(() => {
        return todayView.onMount()
    }, [todayView])

    const { dismiss: dismissTask, complete: completeTask } = useCompletedTasks(todayView)

    return {
        todayStacks,
        activities,
        loading: isLoading,
        completeTask,
        dismissTask,
        searchTerm,
        setSearchTerm,
        totalCount,
    }
}

export function useTodayStacks(searchTerm: string): Stack[] {
    const { stacks } = useStacks()

    const todayStacks = stacks.filter((a) => a.timing && isStackRelevantToday(a.timing))
    const stackBridge = useBridgeStackStore()

    const stacksWithMeta = useObserver(() => {
        return todayStacks.map((stack) => {
            const fetchableStack = stackBridge.fetchableStacksById.get(stack.id)
            return {
                stack,
                fetchableStack,
                isLoading: fetchableStack?.isInitiallyLoading,
                count: fetchableStack?.recordCount,
            }
        })
    })

    useEffect(() => {
        stacksWithMeta.forEach(({ fetchableStack, stack }) => {
            if (
                fetchableStack &&
                !fetchableStack.didFirstFetch &&
                !fetchableStack.isLoading &&
                !fetchableStack.isLoadingMore &&
                !fetchableStack.errorMessage
            ) {
                fetchableStack.prefetch()
            }
        })
    }, [stacksWithMeta])

    const filteredStacks = useObserver(() =>
        stacksWithMeta
            .filter((a) => {
                if (!a.fetchableStack || a.isLoading || !a.count) {
                    return false
                }
                return true
            })
            .map((a) => a.stack)
    )

    return searchTerm
        ? matchSorter(filteredStacks, searchTerm, {
              keys: ['title', 'description'],
          })
        : filteredStacks
}

export function useOverdueActivities() {
    const view = useOverdueView()
    const { activities, isLoading, searchTerm, setSearchTerm } = useFilteredData(view)
    useEffect(() => {
        return view.onMount()
    }, [view])

    const { dismiss: dismissTask, complete: completeTask } = useCompletedTasks(view)

    return {
        activities,
        loading: isLoading,
        completeTask,
        dismissTask,
        searchTerm,
        setSearchTerm,
    }
}

export function useUpcomingActivities() {
    const view = useUpcomingView()
    const { activities, isLoading, searchTerm, setSearchTerm } = useFilteredData(view)
    useEffect(() => {
        return view.onMount()
    }, [view])

    const { dismiss: dismissTask, complete: completeTask } = useCompletedTasks(view)

    return {
        activities,
        loading: isLoading,
        completeTask,
        dismissTask,
        searchTerm,
        setSearchTerm,
    }
}

export function useFilteredData(view: ActivityView) {
    const [searchTerm, setSearchTerm] = useState('')
    const { isLoading, totalCount, activities } = useObserver(() => {
        if (searchTerm.trim()) {
            const searchResult = view.trie.search(searchTerm)
            const matches = new Set(searchResult.map((sr) => sr.id))
            const filtered = view.data.filter((activity) => matches.has(activity.Id))
            return {
                activities: filtered,
                isLoading: view.isLoading,
                totalCount: view.data.length,
            }
        }

        return {
            activities: view.data,
            isLoading: view.isLoading,
            totalCount: view.data.length,
        }
    })
    return {
        searchTerm,
        setSearchTerm,
        activities,
        isLoading,
        totalCount,
    }
}

function useCompletedTasks(view: ActivityView) {
    const complete = useCallback(
        (taskId: string) => {
            view.completeTask(taskId)
            return completeTask({ taskId })
        },
        [view]
    )

    const dismiss = useCallback(
        (taskId: string) => {
            view.dismissTask(taskId)
        },
        [view]
    )
    return { complete, dismiss }
}

import { useEffect } from 'react'

import { visitRoot } from '@laserfocus/client/data-access-shared'
import { LeadId } from '@laserfocus/shared/models'
export default function PrefetchNext({ rootId }: { rootId: string }) {
    useEffect(() => {
        visitRoot({ rootId } as { rootId: LeadId })
    }, [rootId])
    return null
}

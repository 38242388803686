import { useController, Path } from 'react-hook-form'
import { useEffect } from 'react'

import { getInputButtonProps, Input } from '@laserfocus/ui/beam'
import { MailOutlinedIcon } from '@laserfocus/ui/icons'

import { FormControlProps, SalesObject } from '../get-form-control'

import { getControllerRules, getInputProps, useStateReducer } from './formcontrol-util'

export function FormControlEmail<T extends SalesObject = SalesObject>({
    fieldMetadata,
    id,
    variant,
    control,
    submit,
    name,
}: FormControlProps<T>) {
    const { field, fieldState } = useController({
        name: (name || fieldMetadata.name) as Path<T>,
        control,
        rules: getControllerRules(fieldMetadata),
    })
    const [state, setState] = useStateReducer({
        isFocused: false,
        inputValue: field.value ? field.value.toString() : '',
    })
    useEffect(() => {
        if (!state.isFocused) {
            setState({ inputValue: field.value ? field.value.toString() : '' })
        }
    }, [field.value, setState, state.isFocused])
    const value = field.value
    return (
        <Input
            /**
             * The _search suffix disables 1Password Icon he field.
             * Source: https://1password.community/discussion/comment/606453/#Comment_606453
             */
            id={`${id}_search`}
            variant={variant}
            disabled={!fieldMetadata.updateable}
            {...getInputProps(field, fieldState, state, setState, submit)}
            button={
                value ? (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                        {...getInputButtonProps({ icon: MailOutlinedIcon })}
                        title={`Mail to ${value}`}
                        href={`mailto:${value}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    />
                ) : undefined
            }
        />
    )
}

import { matchSorter } from 'match-sorter'
import { useState } from 'react'
import clsx from 'clsx'

import { useAutoFocusedRef } from '@laserfocus/ui/util-react'
import { Button, Checkbox, Modal, useDropdownKeyboardNavigation } from '@laserfocus/ui/beam'
import type { User, UserId } from '@laserfocus/shared/models'

export interface ShareModalBodyProps {
    users: User[]
    submit: (userIds: string[]) => void
    closeModal: () => void
    modalTitle: string
    buttonLabel: string
    initiallySelected?: string[]
    children?: React.ReactNode
    renderUserItem?: (props: UserListItemProps) => JSX.Element
    canToggle?(id: UserId): Boolean
}

export type UserListItemProps = {
    onToggle: () => void
    isSelected: boolean
    isHovered: boolean
    setIsHovered: () => void
    user: User
}

export function ShareModalBody({
    users,
    submit,
    closeModal,
    buttonLabel,
    modalTitle,
    initiallySelected,
    children,
    renderUserItem = UserListItem,
    canToggle,
}: ShareModalBodyProps) {
    const [searchValue, setSearchValue] = useState('')
    const searchInputRef = useAutoFocusedRef<HTMLInputElement>(true)

    const { selectedById, toggleUserId } = useSelectedUsers(initiallySelected)
    const selectedCount = Object.keys(selectedById).length

    const filteredOptions = searchValue
        ? matchSorter(users, searchValue, {
              keys: ['Name', 'Username'],
          })
        : users

    const { hoveredOptionIndex, setHoveredOptionIndex } = useDropdownKeyboardNavigation({
        optionsLength: filteredOptions.length,
        resetKey: searchValue,
        submit: (index) => {
            const id = filteredOptions[index]!.Id
            if (!canToggle || canToggle(id)) {
                toggleUserId(id)
            }
        },
    })

    return (
        <>
            <Modal.Overlay />
            <Modal.Container>
                <Modal.Header close={closeModal}>{modalTitle}</Modal.Header>
                {children}
                <Modal.SearchInput
                    ref={searchInputRef}
                    placeholder="Search for person"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    reset={() => setSearchValue('')}
                    className="mt-4"
                />
                <div className="min-h-[21.75rem] max-h-[50vh] mx-8 my-3 overflow-y-auto">
                    {filteredOptions.map((user, index) => {
                        const isHovered = index === hoveredOptionIndex
                        const setIsHovered = () => setHoveredOptionIndex(index)
                        return renderUserItem({
                            isHovered,
                            setIsHovered,
                            onToggle: () => toggleUserId(user.Id),
                            isSelected: Boolean(selectedById[user.Id]),
                            user,
                        })
                    })}
                </div>
                <Modal.Footer border className="justify-between">
                    <div className="pl-3 text-sm text-grey-700/60">
                        {selectedCount ? `${selectedCount} selected` : ''}
                    </div>
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={selectedCount === 0}
                        onClick={() => submit(Object.keys(selectedById))}
                    >
                        {buttonLabel}
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </>
    )
}

function UserListItem({ onToggle, isSelected, isHovered, setIsHovered, user }: UserListItemProps) {
    const name = user.Name
    const username = user.Username
    function handleCheckboxKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.currentTarget.blur()
        }
    }
    return (
        <div
            onMouseMove={setIsHovered}
            className={clsx(
                'py-1.5 w-full grid grid-flow-col grid-cols-[auto,1fr] items-center rounded-md cursor-pointer',
                isHovered && 'bg-grey-700/5'
            )}
            onClick={onToggle}
            key={user.Id}
        >
            <div className="ml-2 items-center">
                <Checkbox
                    checked={isSelected}
                    onChange={onToggle}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={handleCheckboxKeyDown}
                    onFocus={setIsHovered}
                />
            </div>
            <div className="flex justify-between mx-2 items-center">
                <span className="leading-[1.4] align-left">{name}</span>
                <span className="leading-[1.3] text-sm text-grey-700/60">{username}</span>
            </div>
        </div>
    )
}

export function useSelectedUsers(initiallySelected = []) {
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>(initiallySelected || [])
    const selectedById: Record<string, boolean> = Object.fromEntries(
        selectedUserIds.map((id) => [id, true])
    )
    const toggleUserId = (id: string) => {
        setSelectedUserIds((userIds) =>
            userIds.includes(id) ? userIds.filter((pre) => pre !== id) : [...userIds, id]
        )
    }
    return {
        selectedById,
        toggleUserId,
    }
}

import { twMerge } from 'tailwind-merge'

export function StackIconAvatar({ icon, className }: { icon: string; className?: string }) {
    return (
        <div
            className={twMerge(
                'bg-grey-700/5 rounded-full w-11 h-11 grid place-items-center text-[1.5rem] leading-none',
                className
            )}
        >
            {icon}
        </div>
    )
}

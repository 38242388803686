import { action, computed, extendObservable, reaction, runInAction } from 'mobx'
import { omit, sortBy } from 'lodash'

import { ModelExtension, ObjectPool } from '@laserfocus/client/data-layer'
import type {
    DateTimeString,
    LeadFields,
    LeadProps,
    SnapshotListener,
    SnapShotter,
} from '@laserfocus/shared/models'

import { TaskModel } from '../activity/TaskModel'
import { EventModel } from '../activity/EventModel'
import * as ActivityUtil from '../activity/activity-util'

const LeadPropKeys = new Set<string>()

export class LeadModel implements LeadFields, ModelExtension {
    objectPool: ObjectPool
    __typename = 'Lead' as const
    Id: string
    Status?: string | undefined
    FirstName?: string | undefined
    LastName?: string | undefined
    Name?: string | undefined
    Title?: string | undefined
    Company?: string | undefined
    Email?: string | undefined
    Phone?: string | undefined
    MobilePhone?: string | undefined
    Website?: string | undefined
    LeadSource?: string | undefined
    Description?: string | undefined
    Rating?: string | undefined
    IsConverted?: boolean | undefined
    AvatarUrl?: string | undefined
    CountryCode?: string | undefined
    Street?: string | undefined
    State?: string | undefined
    PostalCode?: string | undefined
    City?: string | undefined
    Country?: string | undefined
    GeocodeAccuracy?: string | undefined
    Latitude?: number | undefined
    Longitude?: number | undefined
    OwnerId?: string | undefined
    ConvertedAccountId?: string | undefined
    ConvertedContactId?: string | undefined
    ConvertedOpportunityId?: string | undefined
    CurrencyIsoCode?: string

    _LastActivityDate?: DateTimeString | undefined

    _previousSnapshot: Map<number, any> = new Map()
    _snapshotCounter = 0

    constructor(props: LeadProps, objectPool: ObjectPool) {
        this.objectPool = objectPool

        extendObservable(this, omit(props, 'LastActivityDate'))
        this._LastActivityDate = props.LastActivityDate
        this.Id = props.Id
        const keys = Object.keys(props) as Array<keyof LeadProps>
        keys.forEach((key) => LeadPropKeys.add(key))
    }

    @computed get props(): LeadProps {
        const keys = Array.from(LeadPropKeys.values())
        const props = Object.fromEntries(keys.map((key) => [key, this[key as keyof LeadModel]]))
        return {
            Id: this.Id,
            __typename: 'Lead',
            ...props,
        }
    }

    @action
    onSnapshot<Snapshot>(
        makeSnapshot: SnapShotter<LeadModel, Snapshot>,
        listener: SnapshotListener<Snapshot>,
        options?: {
            fireImmediately?: boolean
        }
    ): () => void {
        const index = this._snapshotCounter++
        const previousSnapshot = makeSnapshot(this as any)
        this._previousSnapshot.set(index, previousSnapshot)
        const discard = reaction(
            () => makeSnapshot(this as any),
            (current) => {
                listener(this._previousSnapshot.get(index), current)
                runInAction(() => {
                    this._previousSnapshot.set(index, current)
                })
            },
            options
        )
        return () => {
            this._previousSnapshot.delete(index)
            discard()
        }
    }

    @computed
    get Activities(): Array<TaskModel | EventModel> {
        const leadActivities = this.objectPool.activitiesByLead[this.Id] || {}
        return Object.values(leadActivities) as Array<TaskModel | EventModel>
    }

    @computed
    get OpenActivities() {
        const openActivitiees = this.Activities.filter((a) => a.isOpen)
        return sortBy(openActivitiees, 'OpenDate', 'CreatedDate').reverse()
    }

    @computed
    get ActivityHistory() {
        const activityHistory = this.Activities.filter((a) => !a.isOpen)
        return sortBy(activityHistory, 'ClosedDate', 'CreatedDate').reverse()
    }

    @computed
    get NextActivity() {
        return ActivityUtil.getNextActivity(this)
    }

    @computed
    get LastActivity() {
        return this.ActivityHistory[0]
    }

    @computed
    get LastActivityDate() {
        return ActivityUtil.getLastActivityDate(this)
    }

    set LastActivityDate(d) {
        this._LastActivityDate = d
    }

    /**
     * ------------------ Relationships --------------------
     */
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { last, partition, sortBy } from 'lodash'

import { FailedMutation } from '@laserfocus/shared/models'

export function useCurrentFailedMutation(failedMutations: FailedMutation[]) {
    const [currentTarget, setCurrentTarget] = useState<string | null>(null)

    useEffect(() => {
        if (failedMutations.length) {
            const targetIndex = failedMutations.findIndex((f) => f.targetKey === currentTarget)
            if (!currentTarget || targetIndex === -1) {
                const firstTarget = failedMutations[0].targetKey
                if (firstTarget) {
                    setCurrentTarget(firstTarget)
                }
            }
        } else if (currentTarget) {
            setCurrentTarget(null)
        }
    }, [currentTarget, failedMutations])

    const next = useCallback(() => {
        const notCurrent = failedMutations.filter(
            (a) => a.targetKey !== currentTarget && a.targetKey
        )
        if (notCurrent.length) {
            setCurrentTarget(notCurrent[0].targetKey!)
        }
    }, [failedMutations, currentTarget])

    const currentTargetFirst: FailedMutation[] = useMemo(() => {
        if (!currentTarget) {
            return failedMutations.filter((a) => !a.deleted)
        }
        const [forTarget, otherTargets] = partition(
            failedMutations,
            (f) => f.targetKey === currentTarget
        )
        if (!forTarget.length) {
            return failedMutations.filter((a) => !a.deleted)
        }

        const mostRecentCurrent = sortBy(forTarget, 'mutationId')

        const remaining = otherTargets.filter((a) => !a.deleted)
        return [last(mostRecentCurrent)!, ...remaining]
    }, [currentTarget, failedMutations])
    return {
        failedMutations: currentTargetFirst,
        next,
    }
}

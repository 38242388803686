import type { ReadTransaction, Replicache } from 'replicache'
import { computed, observable, reaction, runInAction } from 'mobx'

import { Identifier, isTruthy } from '@laserfocus/shared/models'
import { assert } from '@laserfocus/shared/util-error'

import { StoredModel, ObjectPool } from './ObjectPool'

type RelationshipConfig<MainModel extends Identifier> = {
    foreignModel: 'Lead' | 'Account' | 'Opportunity' | 'Contact'
    getForeignKey(model: MainModel): string | undefined
    getData: () => MainModel[]
}

export class RelationshipLoader<MainModel extends StoredModel, RelatedModel extends StoredModel> {
    objectPool: ObjectPool
    replicache: Replicache

    isMounted = false

    config: RelationshipConfig<MainModel>

    reactions: Array<() => void> = []

    @observable initiallyLoaded = false

    constructor(
        deps: { objectPool: ObjectPool; replicache: Replicache },
        config: RelationshipConfig<MainModel>
    ) {
        this.objectPool = deps.objectPool
        this.replicache = deps.replicache
        this.config = config

        if (!this.objectPool.loadingIds.has(config.foreignModel)) {
            this.objectPool.loadingIds.set(config.foreignModel, new Set())
        }
        if (!this.objectPool.notInReplicacheIds.has(config.foreignModel)) {
            this.objectPool.notInReplicacheIds.set(config.foreignModel, new Set())
        }
    }

    onMount() {
        this.isMounted = true
        this.reactions.push(
            reaction(
                () => this.currentMissing,
                (missing: Set<string>) => {
                    if (missing.size) {
                        this.loadRelation(Array.from(missing))
                    }
                },
                {
                    fireImmediately: !this.initiallyLoaded,
                    delay: 5,
                }
            )
        )
        runInAction(() => {
            this.initiallyLoaded = true
        })
    }

    onUnmount() {
        this.isMounted = false
        let current
        // eslint-disable-next-line no-cond-assign
        while ((current = this.reactions.pop())) {
            current()
        }
    }

    get loadingIds(): Set<string> {
        const loadingIds = this.objectPool.loadingIds.get(this.config.foreignModel)
        assert(loadingIds, 'Need to initialize')
        return loadingIds
    }

    get notInReplicache(): Set<string> {
        const notInRc = this.objectPool.notInReplicacheIds.get(this.config.foreignModel)
        assert(notInRc, 'Need to initialize')
        return notInRc
    }

    @computed
    get foreignKeys() {
        return new Set(this.config.getData().map(this.config.getForeignKey).filter(isTruthy))
    }

    @computed get currentMissing() {
        const all = new Set(this.foreignKeys)
        this.loadingIds.forEach((id) => all.delete(id))
        this.notInReplicache.forEach((id) => all.delete(id))

        const existing = this.objectPool.getAll(this.config.foreignModel)
        all.forEach((key) => {
            if (existing.has(key)) {
                all.delete(key)
            }
        })
        return all
    }

    get foreignPrefix() {
        return this.config.foreignModel.toLowerCase()
    }

    async loadRelation(ids: string[]) {
        const needsToLoad = ids.filter(
            (id) => !this.loadingIds.has(id) && !this.notInReplicache.has(id)
        )
        if (needsToLoad.length) {
            runInAction(() => {
                needsToLoad.forEach((id) => this.loadingIds.add(id))
            })
            const models = await this.replicache.query(async (tx: ReadTransaction) => {
                console.log('Query RelationShip', this.config.foreignModel, needsToLoad)
                const models = await Promise.all(
                    needsToLoad.map((id) => tx.get([this.foreignPrefix, id].join('/')))
                )
                return models.filter(isTruthy) as unknown as RelatedModel[]
            })
            runInAction(() => {
                this.objectPool.attachAll(models, this.isMounted ? 20 : 7)
                const loadedIds = new Set(models.map((a) => a.Id))
                needsToLoad.forEach((id) => {
                    this.loadingIds.delete(id)
                    if (!loadedIds.has(id)) {
                        this.notInReplicache.add(id)
                    }
                })
            })
        }
    }
}

import { twMerge } from 'tailwind-merge'

export function LoadingStack({ amount = 12, className }: { amount?: number; className?: string }) {
    return (
        <div className={twMerge('grid gap-2', className)} aria-busy="true">
            {new Array(amount).fill('').map((a, idx) => (
                <LoadingActivity key={idx} />
            ))}
        </div>
    )
}

function LoadingActivity() {
    return (
        <div
            aria-busy="true"
            aria-live="polite"
            className="bg-grey-700/5 h-[4.5rem] rounded-[0.625rem] opacity-90 w-full"
        />
    )
}

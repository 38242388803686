import type { WriteTransaction, JSONValue, ReadTransaction } from 'replicache'
import { addMinutes } from 'date-fns'
import produce from 'immer'

import { getClient } from '@laserfocus/client/replicache'
import {
    ActivityMutatorSchema,
    applyValidators,
    Prefix,
    NewTask,
    OptimisticTaskId,
    NewEvent,
    TaskCreateInput,
    isLead,
    isAccount,
    AccountId,
    LeadId,
    createOptimisticTaskId,
    getOpenByUserIdx,
    DateTimeString,
    getEventStartDateIdx,
    PinnedActivities,
    DateString,
    TaskId,
    EventId,
    isOptimisticTaskId,
    isTask,
    isOptimisticEventId,
    isEvent,
    TaskCreateInputSchema,
} from '@laserfocus/shared/models'
import { z } from '@laserfocus/shared/decoder'

import { proposeTaskType } from '../ActivitiesMetadata/propose-type'

type OptimisticTask = TaskCreateInput & {
    Id: OptimisticTaskId
    RootId?: LeadId | AccountId
    IsClosed: boolean
    CreatedDateTime: DateTimeString
    __typename: 'Task'
}

export async function completeTask(input: z.infer<typeof ActivityMutatorSchema.completeTask>) {
    if ((window as any).__throw_on_complete) {
        throw new Error('Error on completetask. log me in fullstory')
    }
    const rep = getClient<typeof mutators>()
    const args: z.infer<typeof ActivityMutatorSchema.completeTask> = {
        ...input,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
            CompletedDateTime: new Date().toISOString(),
            ...input.optimistic,
        },
    }
    await rep.mutate.completeTask(args)
}

export async function uncompleteTask(input: z.infer<typeof ActivityMutatorSchema.uncompleteTask>) {
    const rep = getClient<typeof mutators>()
    const args: z.infer<typeof ActivityMutatorSchema.uncompleteTask> = {
        ...input,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
            ...input.optimistic,
        },
    }
    await rep.mutate.uncompleteTask(args)
}

type UpdateActivityInput = {
    activityId: TaskId | EventId
    subject?: string
    description?: string
    type?: string | null
    priority?: string
}
export async function updateActivity(input: UpdateActivityInput) {
    const rep = getClient<typeof mutators>()
    if (isOptimisticTaskId(input.activityId) || isTask(input.activityId)) {
        return rep.mutate.updateTask({
            taskId: input.activityId,
            values: {
                Subject: input.subject,
                Description: input.description,
                Type: input.type,
                Priority: input.priority,
            },
            optimistic: {
                LastModifiedDate: new Date().toISOString(),
            },
        })
    } else if (isOptimisticEventId(input.activityId) || isEvent(input.activityId)) {
        const values: UpdateEventArgs['values'] = {
            Subject: input.subject,
            Description: input.description,
        }
        if (input.type) {
            values.Type = input.type
        }
        return rep.mutate.updateEvent({
            eventId: input.activityId,
            values,
            optimistic: {
                LastModifiedDate: new Date().toISOString(),
            },
        })
    }
}

type AttachActivityInput = {
    values: {
        activityId: string
        whoId?: string
        whatId?: string
    }
    optimistic: {
        accountId?: string
    }
}
export function attachActivity(input: AttachActivityInput) {
    const rep = getClient<typeof mutators>()

    const { values } = input
    const update = {
        WhoId: values.whoId,
        WhatId: values.whatId,
    }
    const optimistic: { LastModifiedDate: string; AccountId?: string } = {
        LastModifiedDate: new Date().toISOString(),
    }
    if (input.optimistic.accountId) {
        optimistic.AccountId = input.optimistic.accountId
    }
    if (isOptimisticTaskId(values.activityId) || isTask(values.activityId)) {
        return rep.mutate.updateTask({
            taskId: values.activityId,
            values: {
                ...update,
            },
            optimistic,
        })
    } else if (isOptimisticEventId(values.activityId) || isEvent(values.activityId)) {
        return rep.mutate.updateEvent({
            eventId: values.activityId,
            values: {
                ...update,
            },
            optimistic,
        })
    }
}

export type UpdateTaskArgs = Omit<z.infer<typeof ActivityMutatorSchema.updateTask>, 'optimistic'>
export async function updateTask(input: UpdateTaskArgs) {
    const rep = getClient<typeof mutators>()
    const args = {
        ...input,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    }
    await rep.mutate.updateTask(args)
}

export type UpdateEventArgs = Omit<z.infer<typeof ActivityMutatorSchema.updateEvent>, 'optimistic'>
export async function updateEvent(input: UpdateEventArgs) {
    const rep = getClient<typeof mutators>()
    const args = {
        ...input,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
        },
    }
    await rep.mutate.updateEvent(args)
}

export async function deleteActivity(activityId: string) {
    const rep = getClient<typeof mutators>()

    await rep.mutate.deleteActivity({
        activityId,
        optimistic: { LastModifiedDate: new Date().toISOString() },
    })
}

export async function rescheduleTask(
    rawInput: z.infer<typeof ActivityMutatorSchema.rescheduleTask>
) {
    const rep = getClient<typeof mutators>()
    const input: z.infer<typeof ActivityMutatorSchema.rescheduleTask> = {
        ...rawInput,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
            ...rawInput.optimistic,
        },
    }
    await rep.mutate.rescheduleTask(input)
}

export async function rescheduleEvent(
    rawInput: z.infer<typeof ActivityMutatorSchema.rescheduleEvent>
) {
    const rep = getClient<typeof mutators>()
    const input: z.infer<typeof ActivityMutatorSchema.rescheduleEvent> = {
        ...rawInput,
        optimistic: {
            LastModifiedDate: new Date().toISOString(),
            ...rawInput.optimistic,
        },
    }
    await rep.mutate.rescheduleEvent(input)
}

export async function logActivity(
    input: Omit<
        z.infer<typeof ActivityMutatorSchema.logActivity>,
        'optimisticId' | 'createdDateTime'
    >
) {
    const rep = getClient<typeof mutators>()
    const fullInput = {
        optimisticId: createOptimisticTaskId(),
        createdDateTime: new Date().toISOString(),
        ...input,
    }
    await rep.mutate.logActivity(fullInput)
}

export async function createTask(input: z.infer<typeof TaskCreateInputSchema>) {
    const rep = getClient<typeof mutators>()
    const values: z.infer<typeof TaskCreateInputSchema> = {
        ...input,
    }
    if (typeof input.Type === 'undefined' && input.Subject) {
        const proposedType = await proposeTaskType(input.Subject)
        if (proposedType) {
            values.Type = proposedType
        }
    }
    await rep.mutate.createTask({
        optimisticId: createOptimisticTaskId(),
        values,
        optimistic: {
            createdDate: new Date().toISOString(),
            modifiedDate: new Date().toISOString(),
        },
    })
}

export async function scheduleTask(
    input: Omit<
        z.infer<typeof ActivityMutatorSchema.scheduleTask>,
        'optimisticId' | 'createdDateTime'
    >
) {
    const rep = getClient<typeof mutators>()
    const fullInput: z.infer<typeof ActivityMutatorSchema.scheduleTask> = {
        optimisticId: createOptimisticTaskId(),
        createdDateTime: new Date().toISOString(),
        ...input,
    }
    if (typeof input.type === 'undefined' && input.subject) {
        const proposedType = await proposeTaskType(input.subject)
        if (proposedType) {
            fullInput.type = proposedType
        }
    }
    await rep.mutate.scheduleTask(fullInput)
}

export function pinActivity(rootId: string, activityId: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.pinActivity({ rootId, activityId })
}
export function unpinActivity(rootId: string, activityId: string) {
    const client = getClient<typeof mutators>()
    return client.mutate.unpinActivity({ rootId, activityId })
}

export const mutators = applyValidators(ActivityMutatorSchema, {
    async completeTask(tx, args) {
        const task = await getTask(tx, args.taskId)
        // Only temporarily, because this can happen while we have tasks in the table, that are not in Replicache
        if (!task) {
            return
        }
        const newTask = {
            ...task,
            ...args.optimistic,
            Status: 'Completed',
            IsClosed: true,
        }
        await putTask(tx, newTask)
    },
    async uncompleteTask(tx, args) {
        const task = await getTask(tx, args.taskId)
        const newTask = {
            ...task,
            ...args.optimistic,
            CompletedDateTime: null,
            Status: 'Open',
            IsClosed: false,
        }
        await putTask(tx, newTask)
    },
    async rescheduleTask(tx, args) {
        const task = await getTask(tx, args.taskId)
        // Only temporarily, because this can happen while we have tasks in the table, that are not in Replicache
        if (!task) {
            return
        }
        const updated = produce(task, (draft) => {
            draft.ActivityDate = args.date
            draft.LastModifiedDate = args.optimistic?.LastModifiedDate || new Date().toISOString()
            if (args.dateTime) {
                draft.ReminderDateTime = args.dateTime
            }
        })

        await putTask(tx, updated)
    },
    async rescheduleEvent(tx, args) {
        const event = await getEvent(tx, args.eventId)
        // Only temporarily, because this can happen while we have tasks in the table, that are not in Replicache
        if (!event) {
            return
        }
        const updated = produce(event, (draft) => {
            draft.StartDateTime = args.dateTime
            draft.LastModifiedDate = args.optimistic?.LastModifiedDate || new Date().toISOString()
            if (draft.DurationInMinutes) {
                const startDate = new Date(args.dateTime)
                draft.EndDateTime = addMinutes(startDate, draft.DurationInMinutes).toISOString()
            }
        })

        await putEvent(tx, updated)
    },
    async deleteActivity(tx, args) {
        const { activityId } = args
        if (isEvent(activityId) || isOptimisticEventId(activityId)) {
            await tx.del(getEventId(activityId))
        } else {
            await tx.del(getTaskId(activityId))
        }
    },
    async updateTask(tx, args) {
        const task = await getTask(tx, args.taskId)
        if (task) {
            const RootId = args.values.AccountId
                ? args.values.AccountId
                : args.optimistic.AccountId
                ? args.optimistic.AccountId
                : isLead(args.values.WhoId)
                ? args.values.WhoId
                : isAccount(args.values.WhatId)
                ? args.values.WhatId
                : undefined
            const newTask = {
                ...task,
                ...args.values,
                ...args.optimistic,
                RootId: RootId || task.RootId,
            }
            await putTask(tx, newTask)
        }
    },
    async updateEvent(tx, args) {
        const event = await getEvent(tx, args.eventId)

        if (event) {
            const RootId = args.values.AccountId
                ? args.values.AccountId
                : args.optimistic.AccountId
                ? args.optimistic.AccountId
                : isLead(args.values.WhoId)
                ? args.values.WhoId
                : isAccount(args.values.WhatId)
                ? args.values.WhatId
                : undefined
            const newEvent = {
                ...event,
                ...args.values,
                ...args.optimistic,
                RootId: RootId || event.RootId,
            }
            await putEvent(tx, newEvent)
        }
    },
    async logActivity(tx, args) {
        const RootId = isAccount(args.accountId)
            ? args.accountId
            : isLead(args.whoId)
            ? args.whoId
            : isAccount(args.whatId)
            ? args.whatId
            : undefined
        const task: OptimisticTask = {
            Id: args.optimisticId!,
            Subject: args.subject,
            Description: args.description,
            WhatId: args.whatId,
            WhoId: args.whoId,
            AccountId: args.accountId,
            RootId,
            IsClosed: true,
            CompletedDateTime: args.createdDateTime,
            ActivityDate: args.createdDateTime as any as DateString,
            OwnerId: args.ownerId,
            CreatedDateTime: args.createdDateTime!,
            LastModifiedDate: args.createdDateTime,
            Type: args.type,
            Priority: args.priority,
            __typename: 'Task',
        }
        await putTask(tx, task)
    },
    async scheduleTask(tx, args) {
        const RootId = isAccount(args.accountId)
            ? args.accountId
            : isLead(args.whoId)
            ? args.whoId
            : isAccount(args.whatId)
            ? args.whatId
            : undefined
        const task: OptimisticTask = {
            Id: args.optimisticId!,
            Subject: args.subject,
            WhatId: args.whatId,
            WhoId: args.whoId,
            AccountId: args.accountId,
            RootId,
            IsClosed: false,
            ActivityDate: args.date,
            ReminderDateTime: args.dateTime,
            OwnerId: args.ownerId,
            CreatedDateTime: args.createdDateTime!,
            LastModifiedDate: args.createdDateTime,
            Type: args.type,
            Priority: args.priority,
            __typename: 'Task',
        }
        await putTask(tx, task)
    },
    async createTask(tx, args) {
        const val = args.values
        const RootId = isAccount(val.AccountId)
            ? val.AccountId
            : isLead(val.WhoId)
            ? val.WhoId
            : isAccount(val.WhatId)
            ? val.WhatId
            : undefined
        const task: OptimisticTask = {
            Id: args.optimisticId!,
            __typename: 'Task',
            RootId,
            IsClosed: false,
            CreatedDateTime: args.optimistic.createdDate || new Date().toISOString(),
            LastModifiedDate: args.optimistic.modifiedDate || new Date().toISOString(),
            ...args.optimistic,
            ...args.values,
        }
        await putTask(tx, task)
    },
    async pinActivity(tx, args) {
        const rootPinned = await getPinned(tx, args.rootId)
        const updatedPinned: PinnedActivities = rootPinned
            ? {
                  ...rootPinned,
                  activities: [...rootPinned.activities, args.activityId],
              }
            : {
                  id: args.rootId,
                  lastModifiedDate: new Date().toISOString(),
                  notes: [],
                  activities: [args.activityId],
              }
        await putPinned(tx, updatedPinned)
    },
    async unpinActivity(tx, args) {
        const rootPinned = await getPinned(tx, args.rootId)
        if (!rootPinned) {
            return
        }
        const updatedPinned: PinnedActivities = {
            ...rootPinned,
            activities: rootPinned.activities.filter((a) => a !== args.activityId),
        }
        await putPinned(tx, updatedPinned)
    },
})

async function getTask(tx: WriteTransaction, id: string): Promise<NewTask> {
    const task = await tx.get(getTaskId(id))
    return task as unknown as NewTask
}
async function putTask(tx: WriteTransaction, input: NewTask | OptimisticTask) {
    const fullTask = {
        ...input,
        idxOpenByUser: getOpenByUserIdx(input),
        idxOpen: input.IsClosed ? 'COMPLETE' : 'OPEN',
    }
    await tx.put(getTaskId(input.Id), fullTask as unknown as JSONValue)
}

function getTaskId(id: string) {
    return [Prefix.Task, id].join('/')
}

async function getEvent(tx: WriteTransaction, id: string): Promise<NewEvent> {
    const event = await tx.get(getEventId(id))
    return event as unknown as NewEvent
}

async function putEvent(tx: WriteTransaction, input: NewEvent) {
    const eventWithIndex = {
        ...input,
        idxByUserAndStartDateTime: getEventStartDateIdx(input),
    }
    await tx.put(getEventId(input.Id), eventWithIndex as unknown as JSONValue)
}

function getEventId(id: string) {
    return [Prefix.Event, id].join('/')
}

async function getPinned(tx: ReadTransaction, rootId: string) {
    return tx.get([Prefix.PinnedActivities, rootId].join('/')) as Promise<
        PinnedActivities | undefined
    >
}

async function putPinned(tx: WriteTransaction, input: PinnedActivities) {
    tx.put([Prefix.PinnedActivities, input.id].join('/'), input)
}

import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import {
    TABLE_HEAD_HEIGHT,
    TABLE_ROW_HEIGHT,
    TABLE_ROW_CLASS_NAME,
    AvatarCell,
    TABLE_ROW_CELL_CLASS_NAME,
    TableCell,
    TABLE_HEAD_ROW_CLASS_NAME,
    Column,
    TableRowsProvider,
} from '@laserfocus/client/feature-table'

import { TableData } from '../useOnboardingTableData'
import { RelevantObject } from '../../../OnboardingContext'

import { TableHeadCell } from './TableHeadCell'
import { TableHeader } from './TableHeader'

export function Table({
    title,
    setTitle,
    saveView,
    visibleColumns,
    salesObjectType,
    columnSections,
    rows,
}: TableData) {
    const gridTemplateColumns = `54px repeat(${visibleColumns.length}, minmax(150px, 1fr))`
    const rowsToDisplay = rows.slice(0, 3)

    const tableRef = useRef<HTMLTableElement>(null)

    const columnKeyToShowHint = getColumnKeyToShowHint(salesObjectType, visibleColumns)

    return (
        <TableRowsProvider value={{ rows: rows as any }}>
            <TableHeader
                title={title}
                setTitle={setTitle}
                saveView={saveView}
                columnSections={columnSections}
            />
            <table
                ref={tableRef}
                className="grid overflow-x-auto text-sm leading-[1.4] font-medium relative"
            >
                <thead className="block w-min min-w-full">
                    <tr
                        className={TABLE_HEAD_ROW_CLASS_NAME}
                        style={{ height: TABLE_HEAD_HEIGHT, gridTemplateColumns }}
                    >
                        <th />
                        {visibleColumns.map((column, columnIndex) => (
                            <TableHeadCell
                                key={columnIndex}
                                column={column}
                                shouldShowHint={columnKeyToShowHint === column.key}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white block" style={{ minHeight: 3 * TABLE_ROW_HEIGHT }}>
                    {rowsToDisplay.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            className={TABLE_ROW_CLASS_NAME}
                            style={{ height: TABLE_ROW_HEIGHT, gridTemplateColumns }}
                            role="row"
                        >
                            <td className={`${TABLE_ROW_CELL_CLASS_NAME} justify-items-end`}>
                                <AvatarCell salesObject={row} />
                            </td>
                            {visibleColumns.map((column, columnIndex) => {
                                return (
                                    <td
                                        key={columnIndex}
                                        className={twMerge(TABLE_ROW_CELL_CLASS_NAME, 'relative')}
                                        data-testid={`table-cell-${column.key}`}
                                    >
                                        <TableCell
                                            salesObject={row}
                                            column={column}
                                            tableRef={tableRef}
                                            rowIndex={rowIndex}
                                        />
                                    </td>
                                )
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="bg-white rounded-b-xl text-sm text-grey-700-opaque-60 pt-2 pb-4 px-4 text-center">
                {getHintText(rows.length, rowsToDisplay.length)}
            </div>
        </TableRowsProvider>
    )
}

function getHintText(rowsLength: number, rowsToDisplayLength: number) {
    if (rowsLength === 0) {
        return 'There is nothing in here. 👀'
    }

    if (rowsLength > rowsToDisplayLength) {
        const additionalRows = rowsLength - rowsToDisplayLength
        const isSingular = additionalRows === 1

        return isSingular
            ? 'There is 1 more row not shown here.'
            : `There are ${additionalRows} more rows not shown here.`
    }
}

function getColumnKeyToShowHint(salesObjectType: RelevantObject, visibleColumns: Column[]) {
    const visibleColumnsKeys = new Set(visibleColumns.map((c) => c.key))

    const hintFields = FILTER_HINT_COLUMNS[salesObjectType]

    return hintFields.find((key) => visibleColumnsKeys.has(key))
}

const FILTER_HINT_COLUMNS = {
    Opportunity: ['StageName', 'NextActivity.Subject', 'OwnerId'],
    Lead: ['Status', 'NextActivity.Subject', 'OwnerId'],
    Account: ['Industry', 'NextActivity.Subject', 'OwnerId'],
}

import { useLayoutEffect, useRef, useState } from 'react'
import { useSprings } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import arrayMove from 'array-move'

import { AVATAR_COLUMN_WIDTH, Column } from '../table-context'

interface UseColumnOrderProps {
    columns: Column[]
    setColumnsOrder(columnKeys: string[]): void
    widthScaleFactor: number
}

export function useColumnReorder({
    columns,
    setColumnsOrder,
    widthScaleFactor,
}: UseColumnOrderProps) {
    const [isDragging, setIsDragging] = useState(false)

    const visualOrderRef = useRef(getVisualOrder(columns, widthScaleFactor))

    const [springs, springsRef] = useSprings(
        columns.length,
        getPosition({
            order: visualOrderRef.current,
            isDragging: false,
            draggedDomIndex: -1,
            draggedX: -1,
            immediate: true,
        })
    )

    // Recompute the visual order positions whenever the widthScaleFactor changes
    useLayoutEffect(() => {
        const previousVisualOrder = visualOrderRef.current

        visualOrderRef.current = getVisualOrder(columns, widthScaleFactor)

        const previousLast = previousVisualOrder[previousVisualOrder.length - 1]
        const currentLast = visualOrderRef.current[visualOrderRef.current.length - 1]

        if (previousLast && currentLast && previousLast.x !== currentLast.x) {
            springsRef.start(
                getPosition({
                    order: visualOrderRef.current,
                    isDragging: false,
                    draggedDomIndex: -1,
                    draggedX: -1,
                    immediate: true,
                })
            )
        }
    }, [springsRef, columns, widthScaleFactor])

    function handleRest() {
        setIsDragging(false)

        if (visualOrderRef.current.some(({ domOrderIndex }, index) => domOrderIndex !== index)) {
            const nextDomOrderKeys = visualOrderRef.current.map(
                ({ domOrderIndex }) => columns[domOrderIndex]!.key
            )
            setColumnsOrder(nextDomOrderKeys)
        }
    }

    const bind = useDrag((state) => {
        const {
            args: [draggedDomIndex],
            down: isDragging,
            first,
            movement: [xOffset],
        } = state

        if (first) {
            setIsDragging(true)
        }

        const currentOrder = visualOrderRef.current
        const elementIndex = currentOrder.findIndex(
            ({ domOrderIndex }) => domOrderIndex === draggedDomIndex
        )
        const element = currentOrder[elementIndex]!

        const currentX = element.x + xOffset
        const currentXMiddle = currentX + element.width / 2

        const newElementIndex = currentOrder.findIndex(({ x, width, domOrderIndex }, index) => {
            return (
                (currentXMiddle < x + width && !columns[domOrderIndex]!.isStickyLeft) ||
                index === currentOrder.length - 1
            )
        })

        const nextOrder =
            newElementIndex === -1 || newElementIndex === elementIndex
                ? currentOrder
                : recomputePositions(arrayMove(currentOrder, elementIndex, newElementIndex))

        if (isDragging) {
            springsRef.start(
                getPosition({
                    order: nextOrder,
                    isDragging,
                    draggedDomIndex,
                    draggedX: currentX,
                })
            )
        } else {
            visualOrderRef.current = nextOrder
            springsRef.start(
                getPosition({
                    order: nextOrder,
                    isDragging,
                    draggedDomIndex,
                    draggedX: currentX,
                    onRest: handleRest,
                })
            )
        }
    })

    return {
        isDragging,
        springs,
        bindDrag: bind,
        columns,
        widthScaleFactor,
    }
}

interface VisualOrderElement {
    domOrderIndex: number
    x: number
    width: number
}

function getVisualOrder(columns: Column[], widthScaleFactor: number): VisualOrderElement[] {
    let currentX = AVATAR_COLUMN_WIDTH

    return columns.map((column, domOrderIndex) => {
        const x = currentX
        const width = column.width * widthScaleFactor

        currentX += width

        return { domOrderIndex, x, width }
    })
}

function recomputePositions(visualOrder: VisualOrderElement[]): VisualOrderElement[] {
    let currentX = AVATAR_COLUMN_WIDTH

    return visualOrder.map((element) => {
        const x = currentX

        currentX += element.width

        return { ...element, x }
    })
}

function getPosition({
    order,
    isDragging,
    draggedDomIndex,
    draggedX,
    immediate = false,
    onRest,
}: {
    order: {
        domOrderIndex: number
        x: number
    }[]
    isDragging: boolean
    draggedDomIndex: number
    draggedX: number
    immediate?: boolean
    onRest?(): void
}) {
    return (domIndex: number) => {
        if (isDragging && domIndex === draggedDomIndex) {
            return {
                x: draggedX,
                zIndex: order.length + 1,
                shadow: 15,
                immediate: (key: string) => ['x', 'y', 'zIndex'].includes(key),
                onRest,
            }
        }

        const element = order.find(({ domOrderIndex }) => domOrderIndex === domIndex)

        return {
            // There seems to be a bug in react-spring which causes it to run this function for domIndex values which existed in the past (a column was added) but don't exist anymore (volumns was removed) and thus not being present int the order array.
            x: element?.x || 0,
            // We need to set z-index in reversed order so resize indicators are clickable
            zIndex: order.length - domIndex,
            shadow: 0,
            immediate,
            onRest: domIndex === draggedDomIndex ? onRest : undefined,
        }
    }
}

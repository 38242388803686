import { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { Button, Select } from '@laserfocus/ui/beam'
import { MoreOutlinedIcon } from '@laserfocus/ui/icons'

import StackInfo from './StackInfo'
import { StackIconAvatar } from './StackIconAvatar'

const STACK_CARD_BG_COLORS = {
    red: /*tw:*/ 'bg-red-100',
    yellow: /*tw:*/ 'bg-yellow-50',
}

interface StackCardProps {
    icon: string
    title: string
    count?: number
    label?: string
    sobject: string
    color: keyof typeof STACK_CARD_BG_COLORS | (string & {})
    linkTo: string
    actions?: Action[]
    onClick?(): void
}

interface Action {
    label: string | React.ReactNode
    value: string
    onClick(): void
    iconComponent: React.ComponentType<{ className?: string }>
    destructive?: boolean
}

export function StackCard({
    icon,
    title,
    count,
    label,
    sobject,
    color,
    linkTo,
    actions,
    onClick,
}: StackCardProps) {
    const [isActionsSelectOpen, setIsActionsSelectOpen] = useState(false)

    return (
        <Link
            to={linkTo}
            onClick={onClick}
            className={clsx(
                'block transition p-6 cursor-pointer rounded-2xl outline-none focus-visible:ring group hover:filter hover:brightness-95 hover:saturate-200',
                STACK_CARD_BG_COLORS[color as keyof typeof STACK_CARD_BG_COLORS],
                isActionsSelectOpen && 'filter brightness-95 saturate-200'
            )}
            style={
                color.startsWith('#') || color.startsWith('rgb')
                    ? { backgroundColor: color }
                    : undefined
            }
        >
            <div className="grid grid-flow-col justify-between items-center">
                {icon ? <StackIconAvatar icon={icon} /> : <div className="w-11 h-11" />}
                {actions && actions.length !== 0 && (
                    <div
                        className={clsx('group-hover:not-sr-only group-focus-within:not-sr-only', {
                            'sr-only': !isActionsSelectOpen,
                        })}
                    >
                        <div
                            className="p-2 -m-2 cursor-default"
                            onClick={(event) => event.preventDefault()}
                        >
                            <Select<Action>
                                size={180}
                                isOpen={isActionsSelectOpen}
                                options={actions.map((action) => ({
                                    ...action,
                                    className: action.destructive ? 'text-red-500' : undefined,
                                }))}
                                onCancel={() => setIsActionsSelectOpen(false)}
                                onSubmit={(option) => {
                                    setIsActionsSelectOpen(false)
                                    option.onClick()
                                }}
                            >
                                <Button
                                    variant="tertiary"
                                    iconComponent={MoreOutlinedIcon}
                                    onClick={() => setIsActionsSelectOpen((v) => !v)}
                                />
                            </Select>
                        </div>
                    </div>
                )}
            </div>
            <StackInfo
                title={title}
                sobject={sobject}
                count={count}
                label={label}
                className="mt-5"
            />
        </Link>
    )
}

import { useNavigate } from 'react-router'
import queryString from 'query-string-original'
import { useEffect, useRef, useState } from 'react'
import { random, replace } from 'lodash'

import { EmptyState, Spinner } from '@laserfocus/ui/beam'
import { RoutePaths } from '@laserfocus/client/util-routing'
import { logger } from '@laserfocus/ui/logger'
import { Analytics } from '@laserfocus/client/util-analytics'
import { useRootStore } from '@laserfocus/client/root-store-context'
import { useBodyClassName } from '@laserfocus/ui/util-react'
import { identifyUser } from '@laserfocus/client/data-access-apollo'

import { receiveToken } from '../store/auth-storage'

type OauthCallbackProps = { debug?: boolean }

export function OAuthCallbackPage({ debug }: OauthCallbackProps) {
    const navigate = useNavigate()
    const didRedirect = useRef(false)
    const [error, setError] = useState('')
    const rootStore = useRootStore<{ authenticate: (userId: string) => Promise<unknown> }>()
    const query = parse(window.location.search)
    logger.info('Callback', 'Source', query.source, 'refresh', Boolean(query.refreshToken))
    useEffect(() => {
        if (didRedirect.current || window.location.pathname !== '/oauth/callback') {
            return
        }
        if (query && query.token && query.userId) {
            const token = query.token as string
            receiveToken(token, query.refreshToken as string | undefined)
            rootStore.authenticate(query.userId as string)
            if (query.isNewUser && query.isNewUser !== 'false') {
                Analytics.trackEvent({
                    event: 'user_signup_fe',
                })
                const startedOnboardingFlowAuthenticated = localStorage.getItem(
                    'lf:onboarding:startedConnectedWithSalesforce'
                )
                localStorage.removeItem('lf:onboarding:email')
                if (startedOnboardingFlowAuthenticated === 'false') {
                    didRedirect.current = true
                    navigate(RoutePaths.OnboardingSpecifyObject, { replace: true })
                } else {
                    didRedirect.current = true
                    navigate(RoutePaths.Onboarding, { replace: true })
                }
            } else if (query.source && !query.source.includes('login')) {
                const target = Array.isArray(query.source) ? query.source[0] : query.source
                if (target.indexOf('/signup') === 0) {
                    didRedirect.current = true
                    navigate(RoutePaths.Home)
                } else {
                    didRedirect.current = true
                    navigate(target)
                }
            } else {
                //signup
                didRedirect.current = true
                navigate(RoutePaths.Home)
            }
        } else if (!query.userId) {
            const obfuscatedQuery = obfuscateQuery(window.location.search)
            logger.error('Error with the Authentication Flow: UserId missing', {
                search: obfuscatedQuery,
                query: parse(obfuscatedQuery),
            })
            if (query.token) {
                identifyUser(query.token as string)
                    .then((userId) => {
                        const token = query.token as string
                        receiveToken(token, query.refreshToken as string | undefined)
                        rootStore.authenticate(userId)
                        navigate(RoutePaths.Home)
                    })
                    .catch((e) => {
                        logger.error(e)
                        setError(
                            'Error during Authentication, please refresh your browser and try again.'
                        )
                    })
            } else {
                setError('Error during Authentication, please refresh your browser and try again.')
            }
        }
    }, [navigate, query, rootStore])
    if (error) {
        return <ErrorPage msg={error} />
    }

    return <Spinner fullscreen />
}

export function parse(hash: string) {
    return queryString.parse(hash)
}

export function getState(hash: Record<string, string>) {
    const state = decodeURIComponent(hash.state)
    const decoded = queryString.parse(state)
    return decoded
}

function obfuscateQuery(query: string) {
    let obfuscatedQuery = query
    for (let i = 0; i < 10; i++) {
        obfuscatedQuery = replace(obfuscatedQuery, `${random(0, 9)}`, `${random(0, 9)}`)
    }
    return obfuscatedQuery
}

export function buildNewUrl(hash: Record<string, string>) {
    const state = getState(hash)
    if (!state.dev_redirect_url) {
        return false
    }

    const base = state.dev_redirect_url
    const tokens = queryString.stringify({
        refresh_token: hash.refresh_token,
        access_token: hash.access_token,
    })
    return base + '#' + tokens
}

function ErrorPage({ msg }: { msg: string }) {
    useBodyClassName('bg-grey-700')

    return (
        <div className="h-screen">
            <EmptyState
                variant="dark"
                emojis={['🌋', '🌪', '💥']}
                title="Oh no!"
                subtitle={msg}
                action={{
                    label: 'Try to login again',
                    href: '/login',
                }}
            />
        </div>
    )
}

import suggest from './time-suggest'

export type DateSuggest = {
    formatted: string
    iso8601: string
    date: Date
    confidence: number
    rule: string
    tokens: Array<ParseToken>
    timezoneConverted: any
    in_future: boolean
}

export type ParseToken = {
    optional?: boolean
    type: string // should be enum
    value: string // should be enum
    parsed: number
    guessed?: boolean
}

type TimeObject = {
    hours: number
    minutes: number
    seconds: number
}

export type Locale = string

export type SuggestOptions = {
    month_before_day?: boolean
    default_time?: TimeObject
    locale?: Locale
    relative_to?: any
    hide_suffixes?: boolean
    allow_past_dates?: boolean
    allow_someday?: boolean
    default_suggestions?: Array<string>
    hide_preposition?: boolean
    /**
     * Minimum date that is allowed to be found
     * Note: There is a default deviation of 10 years which implies a default min_date if nothing
     * is provided
     */
    min_date?: Date
    /**
     * Maximum date that is allowed to be found
     * Note: There is a default deviation of 10 years which implies a default max_date if nothing
     * is provided
     */
    max_date?: Date
}

export type Suggestor = (input: string) => Array<DateSuggest>

export function create(options: SuggestOptions): (input: string) => Array<DateSuggest> {
    return (input) => suggest(input, options)
}

export default suggest

import { useRef } from 'react'

import { SearchInput } from '@laserfocus/ui/beam'
import { LightningOutlinedIcon } from '@laserfocus/ui/icons'

export function SearchInputButton({
    value,
    onChange,
}: {
    value: string
    onChange: (v: string) => void
}) {
    const inputRef = useRef<HTMLInputElement>(null)
    return (
        <div className="w-[13.75rem]">
            <SearchInput
                ref={inputRef}
                icon={
                    <LightningOutlinedIcon className="absolute top-1/2 -translate-y-1/2 left-2 w-4 h-4 pointer-events-none text-grey-700/60" />
                }
                className="w-full mx-0"
                placeholder="Quick Search"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                reset={() => {
                    onChange('')
                    inputRef.current?.focus()
                }}
            />
        </div>
    )
}

import { useState } from 'react'

import { useDatepicker } from '@laserfocus/vendor/react-datepicker-hooks'

import { MonthCalendar } from '../Datepicker/MonthCalendar'
import { DatepickerInputs } from '../Datepicker/DatepickerInputs'
import { DropdownButton } from '../controls/DropdownButton'

interface CompactDatePickerCalendarProps {
    initialDate: Date | null | undefined
    dateOnly: boolean | undefined
    onDateSubmit(date: Date): void
    openSelect(): void
}

export function CompactDatePickerCalendar({
    initialDate,
    dateOnly,
    onDateSubmit,
    openSelect,
}: CompactDatePickerCalendarProps) {
    const {
        activeMonth,
        canSelectDate,
        isDateSelected,
        goToNextMonth,
        goToPreviousMonth,
        onDateSelect,
        selectedDate,
        onTimeSelect,
    } = useDatepickerModel(initialDate)

    return (
        <>
            <MonthCalendar
                activeMonth={activeMonth}
                canSelectDate={canSelectDate}
                isDateSelected={isDateSelected}
                goToNextMonth={goToNextMonth}
                goToPreviousMonth={goToPreviousMonth}
                onDateSelect={dateOnly ? onDateSubmit : onDateSelect}
                dateOnly={dateOnly}
                goBack={openSelect}
            />
            {!dateOnly && (
                <>
                    <DatepickerInputs
                        selectedDate={selectedDate}
                        onDateSelect={dateOnly ? onDateSubmit : onDateSelect}
                        onTimeSelect={onTimeSelect}
                        className="mt-4"
                    />
                    <DropdownButton
                        isHighlighted
                        textCentered
                        onClick={() => selectedDate && onDateSubmit(selectedDate)}
                        className="mt-2"
                    >
                        Done
                    </DropdownButton>
                </>
            )}
        </>
    )
}

// Functionality is in its own function to prevent accidental use of `setSelectedDate()`
function useDatepickerModel(initialDate: Date | null | undefined) {
    const [selectedDate, setSelectedDate] = useState(initialDate || null)
    const datepickerModel = useDatepicker({
        date: selectedDate,
        onDateChange: setSelectedDate,
    })

    return {
        selectedDate,
        ...datepickerModel,
    }
}

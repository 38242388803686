import type { JSONValue, MaybePromise, WriteTransaction } from 'replicache'

import { z, d } from '@laserfocus/shared/decoder'
import { WrappedBaseError } from '@laserfocus/shared/util-error'

import { OrgIdSchema, UserIdSchema } from './id.types'

export type ZodMutationSchemaMap = Record<string, z.ZodObject<any>>

type StringObjectKeys<T> = Extract<keyof T, string>
type ObjectValues<T> = {
    [K in StringObjectKeys<T>]: T[K]
}[StringObjectKeys<T>]
type Tuplify<T> = [T, T, ...T[]]

/**
 * for an object mapping [key: mutator name] -> validation schema,
 * get the type of the functions that should be provided to replicache `mutators` key
 */
export type MutatorInputFns<Schema extends ZodMutationSchemaMap> = {
    [K in StringObjectKeys<Schema>]: (
        tx: WriteTransaction,
        args: z.input<Schema[K]>
    ) => MaybePromise<JSONValue | void>
}

export type MutatorOutputFns<Schema extends ZodMutationSchemaMap> = {
    [K in StringObjectKeys<Schema>]: (
        tx: WriteTransaction,
        args: z.output<Schema[K]>
    ) => MaybePromise<JSONValue | void>
}

class MutationValidationError extends WrappedBaseError<{ name: string; mutationArgs: any }> {}

/**
 * For each mutator function, returns a function that will first check that
 * the provided args pass validation and then call the mutator.
 *
 * @param schema the schema of [key: name] -> zod validator
 * @param mutators functions taking tx and args and applying a transformation
 * @returns an object of mutators that check their associated validator before applying a transformation
 */
export const applyValidators = <S extends ZodMutationSchemaMap>(
    schema: S,
    mutators: MutatorOutputFns<S>
): MutatorInputFns<S> =>
    Object.fromEntries(
        Object.keys(mutators).map((name) => {
            const fn = mutators[name] as MutatorInputFns<S>[keyof MutatorInputFns<S>]
            const validator = schema[name] as S[keyof S]

            const validationFn: typeof fn = (tx, args) => {
                const parsed = d.decode(args, validator)
                if (d.isFailure(parsed)) {
                    const enriched = d.enrichZodError(args, parsed.error)
                    console.warn(args)
                    throw new MutationValidationError(`Error parsing Mutation: ${name}`, enriched, {
                        name,
                        mutationArgs: args,
                    })
                }
                return fn(tx, parsed.data)
            }

            return [name, validationFn] as const
        })
    ) as MutatorInputFns<S>

type SerializedMutationValidator<K extends string, T extends z.ZodObject<any>> = z.ZodObject<{
    name: z.ZodLiteral<K>
    id: z.ZodNumber
    args: T
}>

export const mutationContextSchema = z.object({
    userId: UserIdSchema,
    orgId: OrgIdSchema,
    clientId: z.string(),
    profileId: z.string(),
    mutationId: z.number().int().min(0),
})

export type MutationContext<Extension = {}> = z.infer<typeof mutationContextSchema> & Extension

type MutationFunction<T extends z.ZodObject<any>, ContextExtension = {}> = (
    context: MutationContext<ContextExtension>,
    args: z.infer<T>
) => Promise<unknown>

/**
 * for an object mapping [key: mutator name] -> handler function (userId, clientId, args),
 */
export type MutationFns<Schema extends ZodMutationSchemaMap, ContextExtension = {}> = {
    [K in StringObjectKeys<Schema>]: MutationFunction<Schema[K], ContextExtension>
}

export type SerializedMutations<Schema extends ZodMutationSchemaMap> = {
    [K in StringObjectKeys<Schema>]: SerializedMutationValidator<K, Schema[K]>
}

export type Mutations<Schema extends ZodMutationSchemaMap> = {
    [K in StringObjectKeys<Schema>]: z.infer<SerializedMutationValidator<K, Schema[K]>>
}[StringObjectKeys<Schema>]

/**
 * This transforms the MutationSchema to the format the replicache push endpoint receives the
 * Mutation in
 * @param schema T
 * @returns
 */
export const toSerializedMutations = <Schema extends ZodMutationSchemaMap>(schema: Schema) => {
    return Object.keys(schema)
        .map(
            (name) =>
                [
                    name,
                    z.object({
                        name: z.literal(name),
                        id: z.number(),
                        args: schema[name]!,
                    }),
                ] as const
        )
        .reduce(
            (acc, [name, z]) => ({ ...acc, [name]: z }),
            {} as Record<StringObjectKeys<Schema>, z.ZodObject<any>>
        ) as SerializedMutations<Schema>
}

import { useMemo, useState } from 'react'

import { Button, Select } from '@laserfocus/ui/beam'
import { useColleagues, useLFUsers } from '@laserfocus/client/data-access-shared'

export function UserSelect({
    className,
    selectedUser,
    setSelectedUser,
}: {
    className?: string
    selectedUser?: string
    setSelectedUser: (value: string) => void
}) {
    const [isOpen, setIsOpen] = useState(false)
    const users = useColleagues()
    const lfUsers = useLFUsers()

    const availableUsers = useMemo(() => {
        const usersById = Object.fromEntries(users.map((u) => [u.Id, u]))
        return lfUsers
            .filter((a) => a.signedUp)
            .map((u) => usersById[u.userId])
            .filter(Boolean)
    }, [users, lfUsers])

    const options = availableUsers.map((o) => ({ value: o.Id, label: o.Name }))
    const selected = availableUsers.find((r) => r.Id === selectedUser)
    return (
        <Select
            options={options}
            isOpen={isOpen}
            onSubmit={({ value }) => {
                setSelectedUser(value)
                setIsOpen(false)
            }}
            searchKeys={['label']}
            onCancel={() => setIsOpen(false)}
            initialOptionValue={selectedUser ?? undefined}
        >
            <Button className={className} onClick={() => setIsOpen((a) => !a)}>
                {selected?.Name || selectedUser || 'Select User'}
            </Button>
        </Select>
    )
}

import { format } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'

import { PinFilledIcon } from '@laserfocus/ui/icons'

export type ActivityDateIconProps = {
    date: Date | undefined
    overdue?: boolean
    className?: string
    pinned?: boolean
}

export function ActivityDateIcon({ date, overdue, pinned, className }: ActivityDateIconProps) {
    return (
        <div
            className={twMerge(
                'rounded-[0.625rem] grid justify-items-center place-content-center h-[34px] w-[34px] relative',
                overdue ? 'bg-red-100' : 'bg-grey-700/5',
                className
            )}
        >
            {date ? (
                <>
                    <div
                        className={clsx(
                            'text-[0.625rem] font-semibold leading-[1.15]',
                            overdue ? 'text-red-500' : 'text-grey-700/60'
                        )}
                    >
                        {format(date, 'MMM')}
                    </div>
                    <div
                        className={clsx(
                            'text-sm font-medium leading-[1.15]',
                            overdue ? 'text-red-500' : 'text-grey-700'
                        )}
                    >
                        {format(date, 'd')}
                    </div>
                </>
            ) : (
                <div className="text-[0.625rem] text-center font-medium leading-[1.15] text-grey-700/60">
                    No Date
                </div>
            )}
            {pinned && (
                <div className="bg-blue-500 text-white p-0.5 rounded-full absolute right-[-0.3125rem] top-[-0.3125rem]">
                    <PinFilledIcon className="w-[0.625rem] h-[0.625rem]" />
                </div>
            )}
        </div>
    )
}
